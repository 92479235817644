module.exports = {
    INSIDE_PLANT: "Inside Plant",
    INTRANSIT: "Intransit",
    INTRANSIT_TRANSPORT_YARD: "Intransit Transporter Yard",
    AT_TRANSPORTER_YARD: "At Transporter Yard",
    NEAR_DESTINATION:"Near Destination",
    REACH_DESTINATION:"Reached Destination",
    MANUAL_CLOSE:"Manual Closure",
    FORCE_CLOSE:"Force Closed",
    PROXIMATE_TRIP_CLOSE:"Proximity Trip Closure",

    COL_CONSIGNMENT_CODE:"Consignment Code",
    COL_STATUS: "Status",
    INSIDE_TPA : "Inside Plant",
    INSIDE_MY_PMILL: "Intransit",
    INTRANSIT_TO_MY: "Intransit To Plant",
    INSIDE_TPA_AFTER_LOADING: "Inside Destination After Loading",
    INTRANSIT: "Intransit To Destination",
    PROXIMITY_TRIP: "Proximity Trip Closure",
    NEAR_CUSTOMER:"Near Destination",
    REACHED_CUSTOMER:"Reached Destination Location",
    LEFT_CUSTOMER:"Left Destination Vicinity",
    COL_DELIVERY_NO: "Delivery No",
    COL_SALE_ORDER: "Invoice No",
    COL_SHIPMENT_DOC_NO : "Shipment No",
    COL_TRUCK_NO: "Truck No",
    COL_TRUCK_TYPE: "Vehicle Type",
    COL_TPT_CODE: "Transporter Code",
    COL_TPT_NAME: "Transporter Name",
    COL_TPT_ZONE: "Transportation Zone",
    COL_DRIVER_NAME: "Driver Name",
    COL_DRIVER_CODE: "Driver Code",
    COL_DRIVER_NUMBER : "Driver Number",
    COL_PARKING_ENTRY_NO: "Parking Entry No",
    COL_PARKING_DATE: "Parking Date",
    COL_SHIPMENT_MODE: "Mode",
    COL_RAILYARD_SOURCE: "Source Railyard",
    COL_RAILYARD_DESTINATION: "Destination Railyard",
    COL_SHIPMENT_DATE: "Shipment Date",
    COL_INVOICE_DATE: "Invoice Date",
    COL_SALE_ORDER: "Invoice No",
    COL_ITEM_NO: "Item No",
    COL_ITEM_DESC: "Item Description",
    COL_ITEM_QTY: "Quantity",
    COL_CUSTOMER_NAME: "Destination Name",
    COL_CUSTOMER_CODE: "Destination Code",
    COL_CUSTOMER_CITY: "Destination City",
    MATERIAL_INFO:"Material Info",
    SR_GRADE:"SR Grade",
    COL_CONSIGNEE_STATE:"Destination State",
    COL_CONSIGNEE_ADDRESS:"Destination Address",
    COL_EXPECTED_DISTANCE_KM: "Distance From Plant (Miles)",
    UNITS_OF_MEASUREMENTS:"Miles",
    UNITS_OF_MEASUREMENTS_SHORT:"miles",
    COUNTRY:"USA",
    
    




    

    




}