import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import MyHeaderComponent from "./deviationheaderaction";
import Showtolls from "./showtolls";
import ShowChokePoints from "./showchokepoints.js";
import ShowTollsList from "./showtollslist";
import TollGrid from "./deviationtollgrid.js";
import ChokePointsGrid from "./deviationchokepoints.js";
import ApprovalDeviationRoute from "./approvaldeviationroute";
import DateDeviationRoute from "./datedeviationroute.js";
import ViewDeviationRouteData from "./viewdeviationroutedata.js";
import RouteGrid from "./deviationroutedata.js";
import redirectURL from "../redirectURL";
import $ from 'jquery';
import { getHyphenYYYYMMDD } from '../common/utils';
import Favourites from "../common/favouritesComponent";
import moment from 'moment'
// var googleAnalytics = require("../common/googleanalytics");
var aggriddata = [];
var originaldata;
export default class DeviationRoutes extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-n',
            overly: 'show-n',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                autoHeight: true,
                resizable: true
            },
            rowData: [],
            headerHeight: 60,
            rowHeight: 50,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            griddata: [],
            rowSelection: "single",
            context: { componentParent: this },
            frameworkComponents: {
                MyHeaderComponent: MyHeaderComponent,
                Showtolls: Showtolls,
                ShowTollsList: ShowTollsList,
                ApprovalDeviationRoute: ApprovalDeviationRoute,
                DateDeviationRoute: DateDeviationRoute,
                ViewDeviationRouteData: ViewDeviationRouteData,
                ShowChokePoints: ShowChokePoints
            },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "Route Deviations",
            originaldata: "",
            orignalresponse: "",
            mapinfo: "",
            origins:[],
            originoption: { "value": "", "label": "Select Origin" },
            destinationoption: { "value": "", "label": "Select Destination" },
            destinationarr: [],
            selectedorigin: '',
            selecteddestination: '',
            columnDefs: [],
            checkedinputs: [],
            uncheckedinputs: [],
            noofroutes: 3,
            tolls: [],
            tollshow: false,
            plantcoords: "",
            plantname: "",
            sliderTranslate: "",
            viewtolls: [],
            tollrowid: "",
            routeid: "",
            routeapprovestatus: "",
            open: false,
            route_reason: "",
            gridapi: [],
            viewroutedata: [],
            sliderRouteTranslate: "",
            sliderChokeTranslate: "",
            chokepoints: [],
            chokepointdata: []
        };
        this.origins = this.origins.bind(this);
        this.destinations = this.destinations.bind(this);
        this.onClickShowRoute = this.onClickShowRoute.bind(this);
        this.onClickHideRoute = this.onClickHideRoute.bind(this);
        this.onClickShowRouteWsie = this.onClickShowRouteWsie.bind(this);
        this.onClickHideRouteWise = this.onClickHideRouteWise.bind(this);
        this.onClickShowTollsList = this.onClickShowTollsList.bind(this);
        this.onClickRouteApproval = this.onClickRouteApproval.bind(this);
        this.onLoadFormRequest = this.onLoadFormRequest.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickRouteRevert = this.onClickRouteRevert.bind(this);
        this.onSelectRouteDate = this.onSelectRouteDate.bind(this);
        this.onClickViewRouteData = this.onClickViewRouteData.bind(this);
        this.onClickShowChokePoints = this.onClickShowChokePoints.bind(this);
        this.onClickHideChokePoints = this.onClickHideChokePoints.bind(this);
        this.onClickViewChokePointsGrid = this.onClickViewChokePointsGrid.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    componentDidMount() {
        // console.log("this.macth ", this.props.location.search)
        var s = window.location.search
       
        if (s != "") {
            var spl = s.split("?")
            var s1 = spl[1].split("&")
            var sorce = s1[0].split("=")
            var source = sorce[1]
            var srcname = s1[1].split("=")
            var sourcename = srcname[1].replace(/%20/g, " ")
            var dest = s1[2].split("=")
            var destination = dest[1].replace(/%20/g, " ")
            //var fromparent = this.props.match.params
            var sourceopt = source
            var sourcename = sourcename
            var destinationopt = destination
            if (source == "H") {
                var srcopt = "F"
            }
            else {
                var srcopt = source
            }
            this.setState({
                originoption: { "value": srcopt, "label": srcopt },
                destinationoption: { "value": destination, "label": destination }
            })
            // var p = {
            //     plant: srcopt
            // }
            // redirectURL.post("/consignments/plantcoordinates", p)
            //     .then((response) => {
            //         console.log("Ress ", response.data)
            //         var plantdata = response.data
            //         //console.log("plantcoords ", JSON.parse(plantdata[0].plant_coordinates))
            //         var plantcoords = JSON.parse(plantdata[0].plant_coordinates)
            //         this.setState({
            //             plantcoords: { coord: plantcoords, name: this.state.originoption.label },
            //             plantname: this.state.originoption.label,
            //             origins:plantdata
            //         })
            //     })
            var formdata = {
                origin: srcopt,
                destination: destination,
            }
            this.onLoadFormRequest(formdata)
        }
        // else{
            redirectURL.post("/consignments/plantcoordinates", {})
            .then((response) => {
                // console.log("Ress ", response.data)
                var plantdata = response.data
                //console.log("plantcoords ", JSON.parse(plantdata[0].plant_coordinates))
                this.setState({
                    origins:plantdata
                })
            })
        // }

        //this.onClickShowRoute()
        // console.log("path ", this.props.match.path)
        // this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({ eventAction: eventAction });
        redirectURL.post("/consignments/deviationconsignees")
            .then((response) => {
                //console.log("Consignees ", response.data)
                var records = response.data;
                var destinationarr = []
                if (records.length > 0) {
                    records.map((item) => {
                        if (item != "") {
                            destinationarr.push(item)
                        }
                    })
                }
                this.setState({
                    destinationarr: destinationarr
                })
            })
        var routescnt = this.state.noofroutes;
        var columnDefs = [];
        columnDefs.push({
            headerName: "Metric",
            headerClass: function (params) {
                return ["f14", "graygridbg"]
            },
            field: "metric",
            width: 230,
            cellClass: ["f14", "graygridbg"]
        })
        var classlist = ["greengridbg", "bluegridbg", "redgridbg"]
        for (var i = 0; i < routescnt; i++) {
            // if(i == 0)
            // {
            //     var rtitle = "Route"
            //     var dfield = "route"+(i+1)
            // }
            // else
            // {
            var rtitle = "Route " + (i + 1)
            var dfield = "route" + (i + 1)
            // }
            columnDefs.push({
                headerName: rtitle,
                headerClass: [classlist[i], "f14", "wraptext"],
                headerHeight: 50,
                field: dfield,
                colId: i,
                width: 120,
                wrapText: true,
                cellClass: function (params) {

                    if (params.data.metric == "Difference Distance") {
                        //console.log("params.data.route1 ", params)
                        if (params.colDef.field == "route1") {
                            var classrt1 = "";
                            var r1 = params.data.route1;
                            var sr1 = r1.split(" ")
                            //console.log("sr1 ", sr1)
                            var rt1 = parseInt(sr1[0])
                            //console.log("rt1 ", rt1)
                            if (parseInt(rt1) > 0) {
                                classrt1 = "redfont"
                                var cclass = ["f14", "wraptext", "cellstylegridG", classrt1]
                            }
                            if (parseInt(rt1) < 0) {
                                classrt1 = "greenfont"
                                var cclass = ["f14", "wraptext", "cellstylegridG", classrt1]
                            }
                            return cclass
                        }
                        // else{
                        //     classrt1 = ""
                        //     var cclass =  ["f14", "wraptext" , "cellstylegridG", classrt1]
                        // }
                        //
                        if (params.colDef.field == "route2") {
                            var classrt2 = "";

                            var r2 = params.data.route2;
                            var sr2 = r2.split(" ")
                            //console.log("sr2 ", sr2)
                            var rt2 = parseInt(sr2[0])
                            //console.log("rt2 ", rt2)

                            if (parseInt(rt2) > 0) {
                                classrt2 = "redfont"
                                var cclass = ["f14", "wraptext", "cellstylegridG", classrt2]
                            }
                            if (parseInt(rt2) < 0) {
                                classrt2 = "greenfont"
                                var cclass = ["f14", "wraptext", "cellstylegridG", classrt2]
                            }
                            return cclass
                        }
                        // else{
                        //     classrt2=""
                        //     var cclass =  ["f14", "wraptext" , "cellstylegridG", classrt2]
                        // }

                        if (params.colDef.field == "route3") {
                            var classrt3 = "";

                            var r3 = params.data.route3;
                            var sr3 = r3.split(" ")
                            //console.log("sr2 ", sr3)
                            var rt3 = parseInt(sr3[0])
                            // console.log("rt3 ", rt3)

                            if (parseInt(rt3) > 0) {
                                classrt3 = "redfont"
                                var cclass = ["f14", "wraptext", "cellstylegridG", classrt3]
                            }
                            if (parseInt(rt3) < 0) {
                                classrt3 = "greenfont"
                                var cclass = ["f14", "wraptext", "cellstylegridG", classrt3]
                            }
                            return cclass
                        }
                        // else{
                        //     classrt3=""
                        //     var cclass = ["f14", "wraptext" , "cellstylegridG", classrt3]
                        // }
                        // var cc= classrt1+" "+classrt2+" "+classrt3
                        // console.log("cccc ",cc)
                        // console.log("cclass =  ", cclass)
                        // return cclass
                        //return ["f14", "wraptext" , "cellstylegridG", classrt1, classrt2, classrt3]
                    }
                    else if (params.data.metric == 'Approx. Cost Saving per alto') {
                        // console.log(" HEre params.colDef.field ", params.colDef.field)
                        if (params.colDef.field == "route1") {
                            var classrt1 = "";
                            var r1 = params.data.route1;
                            var sr1 = r1.split(" ")
                            // console.log("sr1 ", sr1)
                            var rt1 = parseInt(sr1[1])
                            //console.log("rt1 ", rt1)
                            if (parseInt(rt1) > 0) {
                                classrt1 = "greenfont"
                                var cclass = ["f14", "wraptext", classrt1]
                            }
                            if (parseInt(rt1) < 0) {
                                classrt1 = "redfont"
                                var cclass = ["f14", "wraptext", classrt1]
                            }
                            return cclass
                        }
                        if (params.colDef.field == "route2") {
                            var classrt2 = "";

                            var r2 = params.data.route2;
                            var sr2 = r2.split(" ")
                            //console.log("sr2 ", sr2)
                            var rt2 = parseInt(sr2[1])
                            //console.log("rt2 ", rt2)

                            if (parseInt(rt2) > 0) {
                                classrt2 = "greenfont"
                                var cclass = ["f14", "wraptext", classrt2]
                            }
                            if (parseInt(rt2) < 0) {
                                classrt2 = "redfont"
                                var cclass = ["f14", "wraptext", classrt2]
                            }
                            return cclass
                        }

                        if (params.colDef.field == "route3") {
                            var classrt3 = "";

                            var r3 = params.data.route3;
                            var sr3 = r3.split(" ")
                            //console.log("sr2 ", sr3)
                            var rt3 = parseInt(sr3[1])
                            // console.log("rt3 ", rt3)

                            if (parseInt(rt3) > 0) {
                                classrt3 = "greenfont"
                                var cclass = ["f14", "wraptext", classrt3]
                            }
                            if (parseInt(rt3) < 0) {
                                classrt3 = "redfont"
                                var cclass = ["f14", "wraptext", classrt3]
                            }
                            return cclass
                        }
                    }
                    else if (params.data.metric == 'Chokepoints') {
                        return ["f11", "wraptext"];
                    }
                    else {
                        return ["f14", "wraptext"]
                    }

                },
                //headerCheckboxSelection:true,
                headerComponentFramework: MyHeaderComponent,
                //cellEditor: DateEditor,
                editable: function (params) {

                    // if(params.data.metric == "Route Effective From")
                    // {
                    //     console.log("if",params.data.metric)
                    //     return true;
                    // }
                    // else
                    // {
                    //     return false;
                    //     console.log("else",params.data.metric)
                    // }
                    return false;
                },
                //headerCellRenderer: selectAllRenderer
                cellRendererSelector: function (params) {
                    if (params.data.metric == "" && params.data.route1 == ""
                        && params.data.route2 == "" && params.data.route3 == "") {
                        var rendComponent = {
                            component: Showtolls
                        };
                        return rendComponent;
                    }
                    else {
                        if (params.data.metric == "Chokepoints") {
                            var rendComponent = {
                                component: ShowChokePoints
                            };
                            return rendComponent;
                        }
                        if (params.data.metric == "No of Tolls") {
                            var rendComponent = {
                                component: ShowTollsList
                            };
                            return rendComponent;
                        }
                        if (params.data.metric == "Implement Route") {
                            var rendComponent = {
                                component: ApprovalDeviationRoute
                            };
                            return rendComponent;
                        }
                        if (params.data.metric == "View Data") {
                            var rendComponent = {
                                component: ViewDeviationRouteData
                            };
                            return rendComponent;
                        }

                        // if(params.data.metric == "Route Effective From")
                        // {
                        //     var rendComponent = {
                        //         component: 'DateDeviationRoute'
                        //     };
                        //     return rendComponent;
                        // }

                    }
                },
                // valueSetter: function(params)
                // {
                //     if(params.data.metric == "Route Effective From")
                //     {
                //         if(params.oldValue != params.newValue)
                //         {
                //             var id = originaldata[params.colDef.colId]._id;
                //             redirectURL.post("/consignments/setRouteEffectDateInDeviation",{"id":id,"effective_date":params.newValue}).then((response)=>{

                //             }).catch((e)=>{

                //             })
                //             params.data[dfield] = params.newValue
                //             return true;
                //         }
                //     }
                //     else{
                //         return true;
                //     }

                //     //return false
                // },
                // valueGetter : function(params)
                // {
                //     return params.data[dfield]
                // }
            })
        }
        this.setState({
            columnDefs: columnDefs
        })


    }

    // logPageView = () => {
    //     try {
    //         if (googleAnalytics.page.enableGA) {
    //             googleAnalytics.logPageView();
    //             let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    //             let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
    //             let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
    //             this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
    //         } else {
    //             console.log("GA disabled...");
    //         }
    //     } catch (error) {
    //         console.error("Error occured while logging to GA, error = ", error);
    //     }
    // }
    onGridReady = params => {
        this.gridApi = params.api;
        this.setState({
            gridapi: params.api
        })
        this.gridColumnApi = params.columnApi;

        this.gridApi.addEventListener('paginationChanged', (e) => {
            //Reset rows selection based on current page
            // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode((node) => {
            // console.log("node here ",node)
            // node.setSelected(true);

        });

    };


    onGridState = () => {
        console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);




        //
    }

    changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
    }
    changeDestinationHandler = destinationoption => {
        this.setState(
            { destinationoption }
        );
    }

    formHandler = (event) => {
        event.preventDefault();
        // loadchecked()
        // if (googleAnalytics.page.enableGA) {
        //     let pageTitle = this.state.pagetitle;
        //     let eventOptions = {
        //         "category": pageTitle,
        //         "action": this.state.eventGridAction,
        //         "label": googleAnalytics.page.action.formAction,
        //     }
        //     googleAnalytics.logEvent(eventOptions);
        // }
        try {
            var tcheck = document.getElementById("tollcheck")
            if (tcheck.checked == true) {
                tcheck.checked = false;
            }
        }
        catch (e) {

        }
        this.setState({
            loadshow: 'show-m',
            tolllist: []
        });
        if (this.state.originoption.value != "" && this.state.destinationoption.value != "") {

            var formdata = {
                origin: this.state.originoption.value,
                destination: this.state.destinationoption.value,
            }
            var p = {
                plant: this.state.originoption.value
            }
            redirectURL.post("/consignments/plantcoordinates", p)
                .then((response) => {
                    //console.log("Ress ", response.data)
                    var plantdata = response.data
                    if (plantdata[0].plant_coordinates) {
                        //console.log("plantcoords ", JSON.parse(plantdata[0].plant_coordinates))
                        var plantcoords = JSON.parse(plantdata[0].plant_coordinates)
                        this.setState({
                            plantcoords: { coord: plantcoords, name: this.state.originoption.label },
                            plantname: this.state.originoption.label,
                        })
                    }
                })
            this.onLoadFormRequest(formdata)


        }


    }

    onLoadFormRequest(formdata) {
        formdata.email = localStorage.getItem("email")
        redirectURL.post("/consignments/getnewdeviationroutescoords", formdata)
            .then(async (response) => {
                // console.log("coordinates resonse ", response.data)
                var recordsarr = response.data
                var data = response.data.complete_coords
                //console.log("records ", recordsarr)
                var baseroutes = []
                if (recordsarr.length > 0) {
                    recordsarr.map((item) => {
                        baseroutes.push(item.base_route_id)
                    })
                }
                var prms = {
                    base_route_id: baseroutes
                }
                // console.log(prms)
                await redirectURL.post("/consignments/routechokepoints", prms)
                    .then((response) => {
                        // console.log("response ", response.data)
                        var snds = response.data;

                        for (var r = 0; r < recordsarr.length; r++) {
                            if (snds.length > 0) {
                                for (var j = 0; j < snds.length; j++) {
                                    if (recordsarr[r].base_route_id == snds[j].base_route_id) {
                                        recordsarr[r].chokepoints_break_clusters = snds[j].break_clusters;
                                        recordsarr[r].chokepoints_no_of_trips_travelled = snds[j].no_of_trips_travelled;
                                        recordsarr[r].chokepoints_base_route_id = snds[j].base_route_id;
                                        recordsarr[r].chokepoints_consigner_code = snds[j].consigner_code;
                                        recordsarr[r].chokepoints_consignee_city = snds[j].consignee_city;
                                    }

                                }
                            }
                            else {
                                recordsarr[r].chokepoints_break_clusters = [];
                                recordsarr[r].chokepoints_no_of_trips_travelled = 0;
                                recordsarr[r].chokepoints_base_route_id = "";
                                recordsarr[r].chokepoints_consigner_code = "";
                                recordsarr[r].chokepoints_consignee_city = "";
                            }
                        }

                    })
                var records = recordsarr;
                console.log("recordsrecords ", records)
                var coordinates = []
                var rowdata = []
                var totadistance = []
                var orignalresponse = []
                var metrics = [
                    "Chokepoints",
                    "Standard Distance",
                    "Average Distance",
                    "Difference Distance",
                    "Average Trip Time",
                    "Average Stoppage Time",
                    "No of Tolls",
                    "Total Tolls Cost",
                    "No of trips travelled",
                    "Recent Alto Rate",
                    "Alto Rate Per Miles",
                    "Approx. Cost Saving per alto",
                    "Estimated Annual Savings",
                    "Implement Route",
                    "Route Effective From",
                    "Reason",
                    "Done by",
                    "Base Route Google Distance (Miles)",
                    //"No. of outliers",
                    "View Data"
                ]
                var tolls = []

                if (data.length > 0) {
                    for (var na = 0; na < this.state.noofroutes; na++) {
                        orignalresponse.push(records[na]);
                        try {
                            if (records[na].tolls.length > 0) {
                                records[na].tolls.map((toll) => {
                                    tolls.push({ lat: toll.lat, lon: toll.lon, location: toll.location })
                                })
                            }
                        }
                        catch (e) {

                        }

                    }
                    records = JSON.parse(data)
                    
                    console.log(typeof records,'711711')
                    records.map((item, index) => {
                        if(item.route_coords !== "" && item.route_coords !== undefined)
                        {
                            var routecoords = eval(item.route_coords);
                        }
                        else{
                            var routecoords = []
                        }
                        // console.log("routecoords ",routecoords)
                        if (item.missing_coords != undefined) {
                            // console.log("added")
                            // console.log(eval(item.route_coords).length, item.missing_coords.length, "length of ")

                            item.missing_coords.map(function (e) {
                                e['city_center_coords'] = 1
                                routecoords.push(e)
                            })
                        }

                        coordinates.push(routecoords)
                        if (item.tolls.length > 0) {
                            item.tolls.map((toll) => {
                                //tolls.push({lat:toll.lat, lon:toll.lon, location:toll.location})
                            })
                        }
                        //console.log("item ", item)

                    })
                    // rowdata.push(totadistance)
                }
                var rows1 = {}
                rows1["metric"] = "";
                for (var nn = 0; nn < this.state.noofroutes; nn++) {
                    rows1["route" + (nn + 1)] = "";
                }
                rowdata.push(rows1)
                metrics.forEach(itm => {
                    var rows = {}

                    rows["metric"] = itm;
                    for (var n = 0; n < this.state.noofroutes; n++) {
                        // if(itm == 'Chokepoints')
                        // {
                        //     rows["route"+(n+1)]="choke_"+records[n]._id;

                        // }              
                        if (itm == 'Standard Distance') {
                            try {
                                rows["route" + (n + 1)] = records[n].expected_distance + " Miles";
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = 0 + " Miles";
                            }
                        }
                        if (itm == 'Average Distance') {
                            try {
                                rows["route" + (n + 1)] = records[n].ref_route_avg_google_distance + " Miles";
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = 0 + " Miles";
                            }
                        }
                        if (itm == 'Difference Distance') {
                            try {
                                if (records[n].base_route_google_distance_difference != undefined) {
                                    rows["route" + (n + 1)] = records[n].base_route_google_distance_difference + " Miles";
                                }
                                else {
                                    rows["route" + (n + 1)] = "0 Miles";
                                }
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = 0 + " Miles";
                            }
                        }
                        if (itm == 'Average Trip Time') {
                            try {
                                var t = SplitTime(records[n].ref_route_avg_trip_duration_mins / 60);
                                //console.log("T split ",t)
                                var avgtrip = t.Days + "days " + t.Hours + "hrs " + t.Minutes + "mins"
                                //rows["route"+(n+1)]=Math.round(records[n].ref_route_avg_trip_duration_mins/60)+" (Hrs)";
                                rows["route" + (n + 1)] = avgtrip;
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = " -- ";
                            }

                        }
                        if (itm == 'Average Stoppage Time') {
                            try {
                                var t = SplitTime(records[n].ref_route_break_time_secs / 3600);
                                //console.log("T split ",t)
                                var avgstrip = t.Days + "days " + t.Hours + "hrs " + t.Minutes + "mins"
                                //rows["route"+(n+1)]=Math.round(records[n].ref_route_break_time_secs/3600)+" (Hrs)";
                                rows["route" + (n + 1)] = avgstrip
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = 0 + " (Hrs)";
                            }
                        }
                        if (itm == "Estimated Annual Savings") {
                            try {
                                if (records[n].aprox_annual_savings != "" && records[n].aprox_annual_savings != undefined) {
                                    rows["route" + (n + 1)] = "$ " + Math.round(records[n].aprox_annual_savings)
                                }
                                else {
                                    rows["route" + (n + 1)] = "--";
                                }

                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "--";
                            }
                        }
                        if (itm == 'No of Tolls') {
                            try {
                                rows["route" + (n + 1)] = records[n].no_of_tolls;
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = 0;
                            }
                        }

                        if (itm == 'Total Tolls Cost') {
                            try {
                                rows["route" + (n + 1)] = "$ " + records[n].total_tolls_cost;
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = 0;
                            }
                        }

                        if (itm == 'No of trips travelled') {
                            try {
                                if (records[n].no_of_trips_percentage != '' && records[n].no_of_trips_percentage != undefined) {
                                    var no_of_trips_percentage = "(" + parseInt(records[n].no_of_trips_percentage) + "%)"
                                }
                                else {
                                    var no_of_trips_percentage = ""
                                }
                                rows["route" + (n + 1)] = records[n].no_of_trips_travelled + " " + no_of_trips_percentage;
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "--";
                            }
                        }
                        if (itm == "Implement Route") {
                            try {
                                // if(records[n].route_approval_status == 1)
                                // {
                                //     var stat = "Approved"
                                // }
                                // else if(records[n].route_approval_status == 0)
                                // {
                                //     var stat = "Rejected"
                                // }
                                // else{
                                //     var stat = "";
                                // }
                                //console.log("records[n] ", records[n])
                                if (records[n] != undefined) {
                                    rows["route" + (n + 1)] = records[n].route_approval_status_value;
                                }
                                else {
                                    rows["route" + (n + 1)] = "--"
                                }
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "";
                            }
                        }

                        if (itm == "Route Effective From") {
                            try {
                                // console.log("route"+(n+1))
                                // console.log(typeof(records[n]['route_effective_from']),"route effective")
                                if (records[n].route_effective_from != undefined && records[n].route_effective_from != "undefined") {
                                    // console.log("effect ",records[n])
                                    // console.log("records[n]['route_effective_from'] ", records[n]['route_effective_from'])
                                    rows["route" + (n + 1)] = moment.parseZone(records[n]['route_effective_from']).format("YYYY-MM-DD")
                                }
                                else {
                                    rows["route" + (n + 1)] = "--"
                                }
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "";
                            }
                        }
                        if (itm == 'Reason') {
                            // console.log("itm ", itm)
                            try {
                                // console.log("route", "route"+(n+1))
                                // console.log("records[n].route_reason ", records[n].route_reason)
                                rows["route" + (n + 1)] = records[n].route_reason;
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "--";
                            }
                        }

                        if (itm == 'Done by') {
                            try {
                                rows["route" + (n + 1)] = records[n].route_approved_by;
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "--";
                            }
                        }
                        if (itm == 'Base Route Google Distance (Miles)') {
                            try {
                                rows["route" + (n + 1)] = records[n].google_distance_via_waypoints;
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "--";
                            }
                        }
                        // if(itm == 'No. of outliers')
                        // {
                        //     try{
                        //         rows["route"+(n+1)]=records[n].no_of_outliers;
                        //     }
                        //     catch(e){
                        //         rows["route"+(n+1)]="--";
                        //     }
                        // }

                        if (itm == 'View Data') {
                            try {
                                if (records[n] != undefined) {
                                    rows["route" + (n + 1)] = records[n].base_route_id;
                                }
                                else {
                                    rows["route" + (n + 1)] = "--"
                                }
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = ""

                            }
                        }

                        // "Alto Rate Per Miles",
                        // "Current Alto Rate",
                        // "Cost Saving",

                        if (itm == 'Alto Rate Per Miles') {
                            try {
                                if (records[n] != undefined) {
                                    if (records[n].alto_rate_per_km != "undefined" && records[n].alto_rate_per_km != undefined) {
                                        rows["route" + (n + 1)] = "$ " + Math.round(records[n].alto_rate_per_km);
                                    }
                                    else {
                                        rows["route" + (n + 1)] = "--";
                                    }
                                    // rows["route"+(n+1)]="$ "+records[n].alto_rate_per_km;
                                }
                                else {
                                    rows["route" + (n + 1)] = "--"
                                }
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "--"

                            }
                        }

                        if (itm == 'Recent Alto Rate') {
                            try {
                                if (records[n] != undefined) {
                                    if (records[n].current_alto_rate != "undefined" && records[n].current_alto_rate != undefined) {
                                        rows["route" + (n + 1)] = "$ " + records[n].current_alto_rate;
                                    }
                                    else {
                                        rows["route" + (n + 1)] = "--";
                                    }
                                }
                                else {
                                    rows["route" + (n + 1)] = "--"
                                }
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "--"

                            }
                        }

                        if (itm == 'Approx. Cost Saving per alto') {
                            try {
                                if (records[n] != undefined) {
                                    if (records[n].cost_saving != "undefined" && records[n].cost_saving != undefined) {
                                        rows["route" + (n + 1)] = "$ " + Math.round(records[n].cost_saving);
                                    }
                                    else {
                                        rows["route" + (n + 1)] = "--";
                                    }

                                }
                                else {
                                    rows["route" + (n + 1)] = "--"
                                }
                            }
                            catch (e) {
                                rows["route" + (n + 1)] = "--"

                            }
                        }
                    }
                    //console.log("rows ", rows)
                    rowdata.push(rows)
                })
                //console.log("rowdata ", rowdata)

                var allcoords = []
                if (coordinates.length > 0) {
                    for (var i = 0; i < parseInt(this.state.noofroutes); i++) {
                        if (coordinates[i] != undefined) {
                            allcoords.push(coordinates[i])
                        }
                    }
                }
                console.log(coordinates,'coordinates')
                console.log("coordinates ",allcoords)
                aggriddata = rowdata;
                originaldata = orignalresponse;
                this.setState({
                    rowData: rowdata,
                    griddata: rowdata,
                    loadshow: 'show-n',
                    orignalresponse: orignalresponse,
                    originaldata: { breaks: [], routes: allcoords },
                    mapinfo: { breaks: [], routes: allcoords },
                    tolls: tolls,
                    selectedorigin: this.state.originoption.value,
                    selecteddestination: this.state.destinationoption.value
                })
            })
    }
    origins() {
        var items = []
        var origins = this.state.origins;
        if(origins.length > 0)
        {
            origins.map((item) => {
                items.push({
                    value:item.consigner_code,
                    label:item.consigner_name +" - "+item.consigner_code
                })
            })
        }
        return items;
    }

    destinations() {
        var items = []
        if (this.state.destinationarr.length > 0) {
            var destinationarr = this.state.destinationarr;
            destinationarr.map((item) => {
                items.push({
                    value: item, label: item
                })
            })
        }
        return items;
    }

    onClickShowRoute(params, uncheckedlst) {
        //var tollcheck = document.getElementById("tollcheck")
        // console.log("tollcheck ", tollcheck)
        // console.log("tollcheck inp ",tollcheck.checked);
        //console.log("Show Params ",uncheckedlst)
        var uncheckedinputs = this.state.uncheckedinputs;
        uncheckedinputs.push(parseInt(params.column.colId))
        //console.log("uncheckedinputs", uncheckedinputs)
        var checkeditem = this.state.checkedinputs;
        //console.log("checkeditem ", checkeditem)
        var popchecked = []
        if (checkeditem.length > 0) {
            checkeditem.map((item) => {
                if (uncheckedinputs.indexOf(item) >= 0) {

                }
                else {
                    popchecked.push(item)
                }
            })
        }
        //console.log("popchecked", popchecked)
        this.setState({
            checkedinputs: popchecked
        })
        var remveid = params.column.colId
        var data = this.state.originaldata.routes;
        var arr = []
        var tollss = []
        if (data.length > 0) {
            data.map((item, index) => {
                if (popchecked.indexOf(index) >= 0) {
                    arr.push([])
                }
                else {
                    tollss.push(this.state.orignalresponse[index].tolls)
                    arr.push(item)
                }
            })
        }
        var alltolls = []
        //console.log("tollss  ", tollss)
        // if(tollcheck.checked == true)
        // {
        //     if(tollss.length > 0)
        //     {
        //     // console.log("tollss ", tollss)
        //         tollss.map((item) => {
        //             if(item.length > 0)
        //             {
        //                 item.map((itm) => {
        //                     alltolls.push(itm)
        //                 })
        //             }
        //         })
        //     }
        // }
        // else{
        //     alltolls = []
        // }
        //console.log("arr ",arr)
        this.setState({
            mapinfo: { breaks: [], routes: arr },
            tolllist: alltolls
        })
    }
    onClickHideRoute(params) {
        //var tollcheck = document.getElementById("tollcheck")
        //console.log(tollcheck)
        //var tollcheck1 = document.getElementsByClassName("tollcheck");

        //console.log("tollcheck1 ", tollcheck.checked)
        //console.log("Hide Params ",params)
        var checkedinputs = this.state.checkedinputs;
        checkedinputs.push(parseInt(params.column.colId))
        //console.log("checkedinputs", checkedinputs)
        var tollss = [];
        var uncheckeditem = this.state.uncheckedinputs;
        // console.log("checkeditem ", uncheckeditem)
        var popunchecked = []
        if (uncheckeditem.length > 0) {
            uncheckeditem.map((item) => {
                if (checkedinputs.indexOf(item) >= 0) {

                }
                else {
                    popunchecked.push(item)
                }
            })
        }
        //  console.log("popunchecked", popunchecked)
        this.setState({
            uncheckedinputs: popunchecked
        })

        var remveid = params.column.colId
        var data = this.state.originaldata.routes;
        var arr = []
        if (data.length > 0) {
            data.map((item, index) => {
                if (checkedinputs.indexOf(index) >= 0) {
                    arr.push([])
                }
                else {
                    //  console.log("item ", index)
                    // console.log("item ", this.state.orignalresponse[index])
                    tollss.push(this.state.orignalresponse[index].tolls)
                    arr.push(item)
                }
            })
        }
        //console.log("tollss  ", tollss)
        var alltolls = []
        // if(tollcheck.checked == true)
        // {
        //     if(tollss.length > 0)
        //     {
        //     // console.log("tollss ", tollss)
        //         tollss.map((item) => {
        //             if(item.length > 0)
        //             {
        //                 item.map((itm) => {
        //                     alltolls.push(itm)
        //                 })
        //             }
        //         })
        //     }
        // }
        // else{
        //     alltolls=[]
        // }
        // console.log("alltolls ",alltolls)
        this.setState({
            mapinfo: { breaks: [], routes: arr },
            tolllist: alltolls
        })
    }
    onClickShowRouteWsie(params) {
        //    console.log("ColID ", params.colDef.colId)
        //    console.log("Show ColID ", params)
        //     console.log("this.state.orignalresponse ", this.state.orignalresponse)

        var tolls = [];
        var cnt = this.state.noofroutes;
        try {
            for (var t = 0; t < cnt; t++) {
                var c = document.getElementById("c_" + t);
                if (c && c.checked === true && this.state.orignalresponse[t] && this.state.orignalresponse[t].tolls) {
                    tolls.push(this.state.orignalresponse[t].tolls);
                }
            }
        } catch (error) {

            console.error('Error in retrieving tolls:', error);
            tolls = [];
        }


        var alltolls = []
        if (tolls.length > 0) {
            tolls.map((im) => {
                if (im.length > 0) {
                    im.map((m) => {
                        alltolls.push(m)
                    })
                }
            })
        }
        //console.log("tolls ", alltolls)
        this.setState({
            tolllist: alltolls
        })
        // var tlist = this.state.orignalresponse;
        // var tollarr = []
        // if(tlist.length > 0)
        // {
        //     tlist.map((item, index) => {
        //         if(index == params.colDef.colId)
        //         {

        //         }
        //         else
        //         {
        //             tollarr.push(item)
        //         }
        //     })
        // }
        // console.log("tollarr ", tollarr)
    }
    onClickHideRouteWise(params) {
        // console.log("Hide ColID ", params.colDef.colId)
        // console.log("this.state.orignalresponse ", this.state.orignalresponse)
        // console.log(this.state.orignalresponse[params.colDef.colId].tolls)
        var tlist = this.state.orignalresponse;
        // var tollarr = []
        // if(tlist.length > 0)
        // {
        //     tlist.map((item, index) => {
        //         if(index == params.colDef.colId)
        //         {

        //         }
        //         else
        //         {
        //             tollarr.push(item)
        //         }
        //     })
        // }
        // console.log("tollarr ", tollarr)
        var tolls = [];
        var cnt = this.state.noofroutes;
        for (var t = 0; t < cnt; t++) {
            var c = document.getElementById("c_" + t);
            if (c && c.checked === true && this.state.orignalresponse[t] && this.state.orignalresponse[t].tolls) {
                tolls.push(this.state.orignalresponse[t].tolls);
            }
        }

        var alltolls = [];
        if (tolls.length > 0) {
            tolls.map((im) => {
                if (im && im.length > 0) {
                    im.map((m) => {
                        alltolls.push(m);
                    });
                }
            });
        }

        this.setState({
            tolllist: alltolls
        })
    }
    onClickShowTolls() {
        var tollcheck = document.getElementById("tollcheck")
        //console.log("tollcheck ", tollcheck.checked);
        if (tollcheck.checked == true) {
            tollcheck.setAttribute("checked", true)
            var tolls = this.state.tolls;
            // console.log("tolllist 123 ", this.state.tolls)
            var checkeditem = this.state.checkedinputs;
            //this.state.orignalresponse
            if (checkeditem.length > 0) {
                //console.log("checkeditem ", checkeditem)
                var tolllist = []
                this.state.orignalresponse.map((item, index) => {
                    if (checkeditem.indexOf(index) >= 0) {

                    }
                    else {
                        if (item.tolls.length > 0) {
                            item.tolls.map((itm) => {
                                tolllist.push(itm)
                            })
                        }
                    }
                })
                //console.log("tolllist ", tolllist)
                this.setState({
                    tolllist: tolllist
                })
            }
            else {
                this.setState({
                    tolllist: tolls
                })
            }
            var cnt = this.state.noofroutes;
            for (var t = 0; t < cnt; t++) {
                var c = document.getElementById("c_" + t);
                c.checked = true

            }
        }
        else {
            tollcheck.setAttribute("checked", false)
            var tolls = this.state.tolls;
            var cnt = this.state.noofroutes;
            for (var t = 0; t < cnt; t++) {
                var c = document.getElementById("c_" + t);
                c.checked = false

            }
            this.setState({
                tolllist: []
            })
        }
    }

    onClickShowTollsList(params) {
        //    console.log("Tolls list ", params.colDef.colId)
        //    console.log("tolllist ", this.state.orignalresponse)
        var rec = this.state.orignalresponse
        var clickedcol = params.colDef.colId
        // console.log("col click ", clickedcol) 

        // sliderRouteTranslate:"slider-translate-50p",
        this.setState({
            sliderTranslate: "slider-translate-50p",
            viewtolls: this.state.orignalresponse[clickedcol].tolls,
            tollrowid: this.state.orignalresponse[clickedcol]._id,
            overly: "show-m"
        });
    }

    onCloseUploadDiv = () => {
        this.setState({
            sliderTranslate: "",
            sliderRouteTranslate: '',
            sliderChokeTranslate: "",
            showDiv: 'show-n',
            overly: 'show-n'
        });
    }


    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderTranslate: '',
            sliderRouteTranslate: '',
            sliderChokeTranslate: ""
        });

    }

    onClickRouteApproval(params, status) {
        // console.log("parmas ", params)
        // console.log("status ", status)
        var clickedcol = params.colDef.colId
        aggriddata.map(function (e) {
            e['route' + (clickedcol + 1).toString()] = status
        });
        try {
            console.log(this.state.orignalresponse)
            var routeid = this.state.orignalresponse[clickedcol]._id

            this.setState({
                routeid: routeid,
                routeapprovestatus: status,
                open: true
            })

        }
        catch (e) {
            //    var routeid = this.state.orignalresponse[clickedcol]._id
            this.setState({
                //      routeid:routeid,
                routeapprovestatus: status,
                open: true
            })

        }

    }
    changeHandler(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    onSubmitApprovalRoute() {
        var routeid = this.state.routeid
        var routeapprovestatus = this.state.routeapprovestatus
        var parameters = {
            routeid: routeid,
            status: routeapprovestatus,
            email: localStorage.getItem("email"),
            username: localStorage.getItem("username"),
            reason: this.state.route_reason
        }
        redirectURL.post("/consignments/approvalroutes", parameters)
            .then((response) => {
                //console.log("res ", response.data)
                var formdata = {
                    origin: this.state.originoption.value,
                    destination: this.state.destinationoption.value,
                }
                this.onLoadFormRequest(formdata)
                this.setState({
                    open: false,
                    route_reason: ""
                })
            })
    }
    onClickRouteRevert(params) {
        console.log("Revert ", params)
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onSelectRouteDate(params) {
        //console.log("Route Date ", params)

        var clickedcol = params.colDef.colId

        //console.log("orignalresponse ",this.state.orignalresponse[clickedcol].route_approval_status)
        var routeid = this.state.orignalresponse[clickedcol]._id

        if (params.data[params.colDef.field] != "1970-01-01"
            && params.data[params.colDef.field] != "" && params.data[params.colDef.field] != "--") {
            if (this.state.orignalresponse[clickedcol].route_approval_status_value != "undefined" &&
                this.state.orignalresponse[clickedcol].route_approval_status_value != undefined && this.state.orignalresponse[clickedcol].route_approval_status_value != "r") {
                var pp = {
                    "id": routeid,
                    "effective_date": params.data[params.colDef.field]
                }
                // console.log("PP ", pp)
                redirectURL.post("/consignments/setRouteEffectDateInDeviation", pp).then((response) => {

                }).catch((e) => {

                })
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: "Select Approve Route option for the route " + (parseInt(clickedcol) + 1),
                    basicType: 'danger'
                })
                var formdata = {
                    origin: this.state.originoption.value,
                    destination: this.state.destinationoption.value,
                }

                this.onLoadFormRequest(formdata)
            }
        }


    }
    onClickViewRouteData(params) {
        console.log("Parmas ", params.colDef.colId)
        this.setState({
            loadshow: 'show-m'
        })
        var parameters = {
            reference_route: params.value,
            email: localStorage.getItem("email"),
        }

        redirectURL.post("/consignments/deviationroutedata", parameters)
            .then((response) => {
                var records = response.data.records;
                // console.log("records ",records)
                if (records.length > 0) {
                    this.setState({
                        sliderRouteTranslate: "slider-translate-50p",
                        viewroutedata: records,
                        overly: "show-m",
                        loadshow: 'show-n'
                    })
                }
                else {
                    this.setState({
                        sliderRouteTranslate: "",
                        viewroutedata: records,
                        overly: "show-n",
                        loadshow: 'show-n',
                        show: true,
                        basicTitle: "No data found.",
                        basicType: "danger"
                    })
                }

            })
    }

    //ChokePoints

    onClickShowChokePoints(params) {
        //console.log("ColID ", params)
        console.log("this.state.orignalresponse ", this.state.orignalresponse)
        var tolls = [];
        var cnt = this.state.noofroutes;
        try {

            for (var t = 0; t < cnt; t++) {
                var c = document.getElementById("ch_" + t);
                //console.log("Ccheck ",c.checked)

                if (c.checked == true) {
                    tolls.push(this.state.orignalresponse[t].chokepoints_break_clusters);
                }


            }
        }
        catch (e) {
            tolls = [];
        }

        var alltolls = [];
        if (tolls && tolls.length > 0) {
            tolls.map((im) => {
                if (im && im.length > 0) {
                    im.map((m) => {
                        alltolls.push(m);
                    });
                }
            });
        }
        console.log("chokepoints ", alltolls)
        this.setState({
            chokepoints: alltolls
        })

    }
    onClickHideChokePoints(params) {
        // console.log("Hide ColID ", params)
        var tlist = this.state.orignalresponse;

        var tolls = []
        var cnt = this.state.noofroutes;
        for (var t = 0; t < cnt; t++) {
            var c = document.getElementById("ch_" + t);
            //console.log("Ccheck ",c.checked)
            if (c.checked == true) {
                try {
                    tolls.push(this.state.orignalresponse[t].chokepoints_break_clusters);
                } catch (e) { }
            }

        }
        var alltolls = []
        if (tolls.length > 0) {
            tolls.map((im) => {
                if (im.length > 0) {
                    im.map((m) => {
                        alltolls.push(m)
                    })
                }
            })
        }
        this.setState({
            chokepoints: alltolls
        })
    }
    onClickViewChokePointsGrid(params) {
        //console.log("params ", params)
        var chokepoints = []
        var colid = params.colDef.colId;
        try {
            chokepoints = this.state.orignalresponse[colid].chokepoints_break_clusters;
        }
        catch (e) {
            chokepoints = []
        }
        // console.log("chokepoints ", chokepoints)
        this.setState({
            chokepointdata: chokepoints,
            sliderChokeTranslate: "slider-translate-50p",
            overly: "show-m"
        })
    }
    render() {
        const modalStyles = {
            width: '500px !important',
        }
        const { open } = this.state;
        const dStyles = {
            width: '100%',
            marginLeft: '0px',
            paddingLeft: '0px',
            height: '500px'
        }
        const columnDefs = [
            {
                headerName: "Metric",
                field: "metric",
                width: 160,
                cellClass: ["f15"]
            }, {
                headerName: "Route",
                field: "preferred_route",
                width: 140
            }, {
                headerName: "Route2",
                field: "route2",
                width: 120
            }, {
                headerName: "Route3",
                field: "route3",
                width: 120
            },

        ]
        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Base Route Summary</span>
                        <Favourites />
                    </h5>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-body row">

                                <div className="row col-xl-12 col-lg-12">
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Origin </label>
                                        <Select
                                            name="originoption"
                                            closeMenuOnSelect={true}
                                            value={this.state.originoption}
                                            className="border-radius-0"
                                            onChange={this.changeOriginHandler.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.origins()} required
                                            styles={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontSize: '14px',
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Destination </label>
                                        <Select
                                            name="destinationoption"
                                            closeMenuOnSelect={true}
                                            value={this.state.destinationoption}
                                            className="border-radius-0"
                                            onChange={this.changeDestinationHandler.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontSize: '14px',
                                                }),
                                            }}
                                            options={this.destinations()} required
                                        />
                                    </div>

                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button style={{ marginTop: "30px" }} onClick={this.formHandler.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>

                                </div>

                                {(this.state.mapinfo != "") ?
                                    <div className="row col-xl-12 col-lg-12">

                                        <div className="col-xl-6 col-lg-6" style={{ height: "500px" }}>

                                            {/* <div className="col-xl-12 col-lg-12">
                                            <input type="checkbox" className="tollcheck" id="tollcheck" onClick={this.onClickShowTolls.bind(this)} /> Show Tolls
                                        </div> */}
                                            <div id="myGrid" style={{ height: "560px", width: "100%" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    columnDefs={this.state.columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={false}
                                                    // enableRangeSelection={false}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    enableRangeSelection={true}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                    onCellClicked={this.popmarker}
                                                    headerHeight={this.state.headerHeight}
                                                    rowHeight={this.state.rowHeight}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6">
                                            <DrawMap
                                                context={this}
                                                mapFor={"deviation"}
                                                mapinfo={this.state.mapinfo}
                                                tolls={this.state.tolllist}
                                                tollshow={this.state.tollshow}
                                                chokepoints={this.state.chokepoints}
                                                plantcoords={this.state.plantcoords}
                                                plantname={this.state.plantname}
                                                checkedlist={this.state.checkedinputs}
                                                selectedorigin={this.state.selectedorigin}
                                                selecteddestination={this.state.selecteddestination}
                                            />


                                        </div>

                                        <div className="row mt-50p">
                                            <div className="f11 col-xl-12 col-lg-12" style={{ fontWeight: "600" }}>Disclaimer:</div>
                                            <div className="f11 col-xl-12 col-lg-12" style={{ lineHeight: "20px" }}>
                                                * 30 Miles has been added to Avg Trip Distance of Haryana Trips for accomodating club loads
                                            </div>
                                            <div className="f11 col-xl-12 col-lg-12" style={{ lineHeight: "20px" }}>
                                                * All Trips from May 1st 2020 that have delivered within 5Miles of the dealer location have been considered for the analysis
                                            </div>
                                        </div>
                                    </div> : ""}

                            </div>
                        </div>
                    </div>
                </div>


                {
                    (this.state.viewtolls.length > 0) ?
                        <div className={"slide-r " + (this.state.sliderTranslate)} style={{ overflow: "auto" }}>
                            <div className="slide-r-title">
                                <h4>
                                    View Tolls
                                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

                                </h4>
                            </div>
                            <div className="slide-r-body" style={{ position: "relative" }}>

                                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">

                                    <TollGrid
                                        rowData={this.state.viewtolls}
                                        tollrowid={this.state.tollrowid}
                                    />

                                </div>
                            </div>

                        </div>
                        : ""}

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                <Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
                    <div className={"row"} style={{ padding: '5px 16px', overflow: 'hidden', zIndex: '9999', }} >
                        <form method="post" class="row col-xl-12 col-lg-12">
                            <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                <div className="form-group">
                                    <label className="col-form-label">Reason:</label>
                                    <textarea required
                                        onBlur="javascript:return CheckForHtmlTag()"
                                        id="route_reason"
                                        className="form-control"
                                        rows="6"
                                        name="route_reason"
                                        value={this.state.route_reason}
                                        onChange={this.changeHandler}
                                        placeholder="Enter Reason" />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                <div className="form-group">
                                    <button type="button" className="btn btn-success" name="submit" onClick={this.onSubmitApprovalRoute.bind(this)}>Submit</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </Modal>

                {
                    (this.state.sliderRouteTranslate != "") ?
                        <div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
                            <div className="slide-r-title">
                                <h4>
                                    View Route Data
                                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

                                </h4>
                            </div>
                            <div className="slide-r-body" style={{ position: "relative" }}>

                                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">

                                    <RouteGrid
                                        rowData={this.state.viewroutedata}
                                    />

                                </div>
                            </div>

                        </div>
                        : ""}


                {
                    (this.state.sliderChokeTranslate != "") ?
                        <div className={"slide-r " + (this.state.sliderChokeTranslate)} style={{ overflow: "auto" }}>
                            <div className="slide-r-title">
                                <h4>
                                    View Chokepoints
                                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

                                </h4>
                            </div>
                            <div className="slide-r-body" style={{ position: "relative" }}>

                                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">

                                    <ChokePointsGrid
                                        rowData={this.state.chokepointdata}
                                    />

                                </div>
                            </div>

                        </div>
                        : ""}

            </div>

        );
    }
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function loadchecked() {
    // var defaultin = document.getElementById("Route 1").checked = true;
    //    var checkBox = document.getElementById("Route 1").checked = true
    //    var checkBox = document.getElementById("Route 2").checked = true
    //    var checkBox = document.getElementById("Route 3").checked = true
    var check = document.getElementsByClassName("checklists");
    console.log("check here ", check)
    if (check.length > 0) {
        for (var c = 0; c < check.length; c++) {
            //check[c].setAttribute("checked", true)
            document.getElementById(check[c]).checked = true;
        }
    }
}
window.onload = function () {
    // var checkBox = document.getElementById("Route 1").checked = true
    // var checkBox = document.getElementById("Route 2").checked = true
    // var checkBox = document.getElementById("Route 3").checked = true

};

function selectAllRenderer(params) {
    var cb = document.createElement('input');
    cb.setAttribute('type', 'checkbox');

    var eHeader = document.createElement('label');
    var eTitle = document.createTextNode(params.colDef.headerName);
    eHeader.appendChild(cb);
    eHeader.appendChild(eTitle);
    params.api.selectAll();
    cb.addEventListener('change', function (e) {

        params.api.selectAll();

    });
    return eHeader;
}

function CheckForHtmlTag() {
    var rs = document.getElementById("route_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function SplitTime(numberOfHours) {
    var Days = Math.floor(numberOfHours / 24);
    var Remainder = numberOfHours % 24;
    var Hours = Math.floor(Remainder);
    var Minutes = Math.floor(60 * (Remainder - Hours));
    return ({ "Days": Days, "Hours": Hours, "Minutes": Minutes })
}
