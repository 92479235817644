// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-card {
  width: 49.3%;
  /* padding: 1rem 2rem 0rem 2rem; */
  min-height: 60vh;
  border: 1px solid black;
}
`, "",{"version":3,"sources":["webpack://./src/components/procurement/organiserProcureDashboard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kCAAkC;EAClC,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".bottom-card {\n  width: 49.3%;\n  /* padding: 1rem 2rem 0rem 2rem; */\n  min-height: 60vh;\n  border: 1px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
