import React, { Component } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import "./rfqComparitiveAnalysis.css";
import redirectURL from "../redirectURL";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import moment from "moment";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class RfqComparitiveAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkArray: ["", null, undefined],
      userName: "",
      rfqVersion: "",
      getAllInvitedVendorData: [],
      allRfqResponses: [],
      analysisReportVisibility: false,
      commercialCompBtn: "btn-active",
      technicalCompBtn: "btn-default",
      rfqAttachmentsBtn: "btn-default",
      commercialData: "",
      technicalData: "",
      rfqAttachmentsData: "",
      pageId: 0,
      rfq_subject: "",
      rfq_description: "",
      rfq_event_id: "",
      currency: "",
      conversion_rate: "",
      rfq_start_time: "",
      rfq_end_time: "",
      created_by: "",
      technical_approval: "",
      is_form_submitted: 0,
      tc_doc: null,
      eventItems: {},
      itemIds: [],
      commercialTerms: {},
      commercialTermIds: [],
      rfqQuestions: {},
      rfqQuestionsIds: [],
      allVendorEmails: [],
      allResponsedVendorEmails: [],
      approvers: [],
      approverEmails: [],
      userEmail: "",
      isRfqApproved: 0,
      loadshow: "show-n",
    };
  }

  componentDidMount = async () => {
    this.setState({
      loadshow: "show-m",
    });
    var userEmail = localStorage.getItem("email");
    var username = localStorage.getItem("email");
    username = username.split("@")[0];
    let { id } = this.props.params;

    this.setState({
      pageId: id,
      userEmail: userEmail,
      userName: username,
      rfq_event_id: id,
      commercialData: "This is commercial Data",
      technicalData: "This is technical data",
      rfqAttachmentsData: "This is rfq data",
    });

    var payload = {
      rfq_event_id: id,
    };

    await redirectURL
      .post("/procurement/getRfqEventById", payload)
      .then((event) => {
        var eventData = event.data.eventDetails[0];
        console.log("getRfqEventById compAnalysis", eventData);
        this.setState({
          rfq_subject: eventData.rfq_subject,

          rfq_description: eventData.rfq_description,

          rfq_event_id: eventData.rfq_event_id,

          currency: eventData.currency,

          conversion_rate: eventData.conversion_rate,

          rfq_start_time: moment
            .parseZone(new Date(eventData.rfq_start_time))
            .format("DD MMMM YYYY hh:mm"),

          rfq_end_time: moment
            .parseZone(new Date(eventData.rfq_end_time))
            .format("DD MMMM YYYY hh:mm"),
          created_by: eventData.created_by,
          technical_approval: eventData.technical_approval,
          is_form_submitted: eventData.is_form_submitted,
        });
        if (eventData.hasOwnProperty("tc_doc")) {
          this.setState({
            tc_doc: eventData.tc_doc,
          });
        }
        redirectURL
          .post("procurement/getItemsForEvent", payload)
          .then((resp) => {
            console.log("getItemsForEvent compAna", resp.data.eventItemDetails);
            var allItemsData = resp.data.eventItemDetails;
            var allItems = {};
            var itemIds = [];
            // allItemsData.forEach((eachItem) => {
            for (let eachItem of allItemsData) {
              var {
                _id,
                rfq_item,
                rfq_item_uom,
                rfq_item_quantity,
                // rfq_item_code,
                rfq_item_delivery_location,
                // rfq_item_description,
                // rfq_item_target_price,
                // rfq_item_unit_rate,
              } = eachItem;

              allItems[_id] = {
                rfq_item,
                rfq_item_uom,
                rfq_item_quantity,
                rfq_item_delivery_location,
              };
              itemIds.push(_id);
            }
            this.setState({
              eventItems: allItems,
              itemIds,
            });
            redirectURL
              .post("procurement/getRfqCommercialTermsByEventId", payload)
              .then((resp) => {
                console.log(
                  "getRfqCommercialTermsByEventId",
                  resp.data.commercialTerms
                );
                var allCommercialTermsData = resp.data.commercialTerms;
                var allCommercialTerms = {};
                let commercialTermIds = [];

                // allCommercialTermsData.forEach((each) => {
                for (let each of allCommercialTermsData) {
                  var {
                    rfq_commercial_terms_doc,
                    rfq_commercial_terms_title,
                    _id,
                  } = each;
                  allCommercialTerms[_id] = {
                    rfq_commercial_terms_title,
                    rfq_commercial_terms_doc,
                  };
                  commercialTermIds.push(_id);
                }

                this.setState({
                  commercialTerms: allCommercialTerms,
                  commercialTermIds,
                });
                redirectURL
                  .post("procurement/getRfqQuestionsByEventId", payload)
                  .then((resp) => {
                    console.log(
                      "getRfqQuestionsByEventId compAna",
                      resp.data.rfqQuestions
                    );
                    var allRfqQuestionsData = resp.data.rfqQuestions;
                    var allRfqQuestions = {};
                    let rfqQuestionsIds = [];
                    // allRfqQuestionsData.forEach((each) => {
                    for (let each of allRfqQuestionsData) {
                      var { rfq_questions_title, _id } = each;
                      allRfqQuestions[_id] = rfq_questions_title;
                      rfqQuestionsIds.push(_id);
                    }

                    this.setState({
                      rfqQuestions: allRfqQuestions,
                      rfqQuestionsIds,
                    });
                    redirectURL
                      .post("/procurement/getApproversForRfqEvent", payload)
                      .then((resp) => {
                        console.log(
                          "/procurement/getApproversForRfqEvent",
                          resp.data
                        );
                        let allApprovers = resp.data.allApprovers;
                        let approverEmails = [];
                        for (let each of allApprovers) {
                          let { email } = each;
                          approverEmails.push(email);
                        }
                        // isRfqApproved
                        var approver = resp.data.allApprovers.filter((each) => {
                          if (each.email === this.state.userEmail) {
                            return each;
                          }
                        });
                        if (approver.length > 0) {
                          console.log("approver", approver);
                          this.setState({
                            isRfqApproved: approver[0].is_event_approved,
                          });
                        }
                        // this.state.approverEmails
                        this.setState({
                          approvers: resp.data.allApprovers,
                          approverEmails,
                        });
                        redirectURL
                          .post(
                            "/procurement/getAllVendorsAndTheirResponses",
                            payload
                          )
                          .then((resp) => {
                            console.log(
                              "getAllVendorsAndTheirResponses rfpCompAalysis",
                              resp.data
                            );
                            var allRfqResponses = resp.data.allRfqResponses;
                            var getAllInvitedVendorData =
                              resp.data.getAllInvitedVendorData;
                            var allResponsedVendorEmails = [];
                            for (let each of allRfqResponses) {
                              allResponsedVendorEmails.push(each.vendor_email);
                            }
                            var allVendorEmails = [];
                            for (let each of getAllInvitedVendorData) {
                              allVendorEmails.push(each.vendor.email);
                            }
                            this.setState({
                              allRfqResponses,
                              getAllInvitedVendorData,
                              allResponsedVendorEmails,
                              allVendorEmails,
                              loadshow: "show-n",
                            });
                          })
                          .catch((err) => {
                            console.log(
                              "getAllVendorsAndTheirResponses err",
                              err
                            );
                          });
                      })
                      .catch((err) => {
                        console.log("getApproversForRfqEvent err", err);
                      });
                  })
                  .catch((err) => {
                    console.log("Event details err", err);
                  });
              })
              .catch((err) => {
                console.log("Event details err", err);
              });
          })
          .catch((err) => {
            console.log("Event details err", err);
          });
      })
      .catch((err) => {
        console.log("Event details err", err);
      });
  };

  newFilter = (filterType, selOption) => {
    this.setState({ [filterType]: selOption, analysisReportVisibility: false });
  };

  showTabView = (oVal) => {
    if (oVal === 1) {
      this.setState({
        commercialCompBtn: "btn-active",
        technicalCompBtn: "btn-default",
        rfqAttachmentsBtn: "btn-default",
      });
    }
    if (oVal === 2) {
      this.setState({
        commercialCompBtn: "btn-default",
        technicalCompBtn: "btn-active",
        rfqAttachmentsBtn: "btn-default",
      });
    }
    if (oVal === 3) {
      this.setState({
        commercialCompBtn: "btn-default",
        technicalCompBtn: "btn-default",
        rfqAttachmentsBtn: "btn-active",
      });
    }
  };

  approveRfq = () => {
    let payload = {
      rfq_event_id: this.state.rfq_event_id,
      email: this.state.userEmail,
    };
    redirectURL.post("/procurement/approveRfq", payload).then((resp) => {
      var response = resp.data.updatedData;
      if (response.length > 0) {
        this.setState({
          isRfqApproved: response[0].is_event_approved,
        });
      }
    });
  };
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className={"dataLoadpage " + this.state.loadshow}></div>
          <div className={"dataLoadpageimg " + this.state.loadshow}>
            <div class="spinner-grow text-primary mr-2" role="status"></div>
            <div class="spinner-grow text-primary mr-2" role="status"></div>
            <div class="spinner-grow text-primary" role="status"></div>
          </div>
          <div className="card shadow row border border-primary pb-3">
            <div className="col-12 bg-primary text-white">
              <div className="row justify-content-between">
                <div className="col-4 p-2">RFQ Comparitive Analysis</div>
              </div>
            </div>

            <div className="col-12">
              <div className="row px-5 pt-3 align-items-center">
                <div className="col-2">
                  <label className="float-right">Select RFQ Version: </label>
                </div>
                <div className="col-4 pl-0">
                  <Select
                    closeMenuOnSelect={true}
                    name="rfqVersion"
                    className="border-radius-0"
                    onChange={this.newFilter.bind(this, "rfqVersion")}
                    options={[{ value: "1 Quote", label: "1 Quote" }]}
                    value={this.state.rfqVersion}
                    required
                  ></Select>
                </div>
                <div className="col-6">
                  <div>
                    <button
                      className="btn btn-warning"
                      onClick={() => {
                        if (this.state.rfqVersion.hasOwnProperty("value")) {
                          if (this.state.rfqVersion.value.length > 0) {
                            this.setState({
                              analysisReportVisibility: true,
                            });
                          }
                        }
                      }}
                    >
                      Go
                    </button>
                    {this.state.analysisReportVisibility ? (
                      <>
                        <button className="btn btn-primary ml-2">Export</button>
                        <button className="btn btn-info ml-2">PDF</button>
                        <button className="btn btn-primary ml-2">
                          Download
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className={`${
                this.state.analysisReportVisibility ? "show-m" : "show-n"
              }`}
            > */}
            <div
              className={
                this.state.analysisReportVisibility ? "show-m" : "show-n"
              }
              style={{ height: "38vh" }}
            >
              <div className="col-12 mt-2 px-5">
                <div className="row form-group no-gutters align-items-center justify-content-between">
                  <div className="col-2">
                    <label className="float-right">RFQ Subject :</label>
                  </div>
                  <div className="col-3">
                    <label>{this.state.rfq_subject}</label>
                  </div>
                  <div className="col-3">
                    <label className="float-right">Ref No. :</label>
                  </div>
                  <div className="col-3">
                    <label>{this.state.rfq_event_id}</label>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2 px-5">
                <div className="row form-group no-gutters align-items-center justify-content-between">
                  <div className="col-2">
                    <label className="float-right">RFQ Description :</label>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">{this.state.rfq_description}</label>
                  </div>
                  <div className="col-3">
                    <label className="float-right">
                      Terms and conditions :
                    </label>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">
                      <a href={`${this.state.tc_doc}`}>Terms.pdf</a>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2 px-5">
                <div className="row form-group no-gutters align-items-center justify-content-between">
                  <div className="col-2">
                    <label className="float-right">Currency :</label>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">{this.state.currency}</label>
                  </div>
                  <div className="col-3">
                    <label className="float-right">Conversion Rate :</label>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">{this.state.conversion_rate}</label>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2 px-5">
                <div className="row form-group no-gutters align-items-center justify-content-between">
                  <div className="col-2">
                    <label className="float-right">RFQ Start Date :</label>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">{this.state.rfq_start_time}</label>
                  </div>
                  <div className="col-3">
                    <label className="float-right">RFQ Deadline :</label>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">{this.state.rfq_end_time}</label>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2 px-5">
                <div className="row form-group no-gutters align-items-center  justify-content-between">
                  <div className="col-2">
                    <label className="float-right" htmlFor="">
                      RFQ Confiqured by :
                    </label>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">
                      <strong>{this.state.created_by}</strong>
                    </label>
                  </div>
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            this.state.analysisReportVisibility ? "show-m" : "show-n"
          }`}
        >
          <div className="row mt-2 ml-2 mr-2 mb-0">
            <div className="col-xl-12 col-md-12 ">
              <ul className={"navtabs "}>
                {localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "approver" ? (
                  <>
                    <li>
                      <button
                        onClick={this.showTabView.bind(this, 1)}
                        type="button"
                        className={"btn " + this.state.commercialCompBtn}
                      >
                        Commercial Comparison
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={this.showTabView.bind(this, 2)}
                        type="button"
                        className={"btn " + this.state.technicalCompBtn}
                      >
                        Technical Comparison
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={this.showTabView.bind(this, 3)}
                        type="button"
                        className={"btn " + this.state.rfqAttachmentsBtn}
                      >
                        RFQ Attachments
                      </button>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
          <div className="pl-2 mb-3">
            <div className="col">
              {this.state.getAllInvitedVendorData.map((eachVendor) => {
                var { contact_person, mobile_no, email } = eachVendor.vendor;
                var vendorResponse = this.state.allRfqResponses.filter(
                  (eachResp) => {
                    if (eachResp.vendor_email === email) {
                      return eachResp;
                    }
                  }
                );
                if (vendorResponse.length > 0) {
                  var response = vendorResponse[0];
                  if (response.hasOwnProperty("input_parameters")) {
                    var inputParameters = response.input_parameters;
                  }
                  if (response.hasOwnProperty("comment_responses")) {
                    var comments = response.comment_responses;
                  }

                  if (
                    response.hasOwnProperty("rfq_commercial_terms_response")
                  ) {
                    var commercialTermsResponse =
                      response.rfq_commercial_terms_response;
                  }

                  if (response.hasOwnProperty("final_amounts")) {
                    var { total_amount_with_gst, total_amount_without_gst } =
                      response.final_amounts;
                  }
                  if (response.hasOwnProperty("rfq_vendor_remarks")) {
                    var remarks = response.rfq_vendor_remarks;
                  }
                  if (response.hasOwnProperty("rfq_desired_responses")) {
                    var questions = response.rfq_desired_responses;
                  }
                }

                return (
                  <div className="accContainer col mb-2">
                    <div
                      role="button"
                      className="accTitleContainer row justify-content-between align-items-center p-2"
                      onClick={() =>
                        this.setState({
                          [contact_person]: !this.state[contact_person],
                        })
                      }
                    >
                      <div
                        className="text-gray-900 h5"
                        style={{ fontSize: "1rem" }}
                      >{`${contact_person} (${mobile_no}, ${email})`}</div>
                      <div className="h5 text-gray-900">
                        {this.state.contact_person ? "-" : "+"}
                      </div>
                    </div>

                    {this.state[contact_person] && (
                      <div className="col bg-white">
                        <div className="row">
                          {this.state.commercialCompBtn === "btn-active" ? (
                            <>
                              <div>
                                <table>
                                  <tr
                                    style={{ background: "grey" }}
                                    className="text-white"
                                  >
                                    <td className="td-2">Item/Category</td>
                                    <td className="td-2">UOM</td>
                                    <td className="td-2">Qty</td>
                                    <td className="td-2">
                                      Landed Unit Price &#40; without GST &#41;
                                    </td>
                                    <td className="td-2">
                                      Landed Unit Price &#40; with GST &#41;
                                    </td>
                                    <td className="td-2">
                                      Amount &#40; without GST &#41;
                                    </td>
                                    <td className="td-2">
                                      Amount &#40; with GST &#41;
                                    </td>
                                    <td className="td-2">Delivery location</td>
                                    <td className="td-2">Comments</td>
                                  </tr>
                                  {this.state.itemIds.map((eachId) => {
                                    var {
                                      rfq_item,
                                      rfq_item_uom,
                                      rfq_item_quantity,
                                      rfq_item_delivery_location,
                                    } = this.state.eventItems[eachId];
                                    if (
                                      !this.state.checkArray.includes(
                                        inputParameters
                                      )
                                    ) {
                                      console.log(
                                        "inputParameters",
                                        inputParameters
                                      );
                                      console.log("eachId", eachId);
                                      if (
                                        inputParameters.hasOwnProperty(eachId)
                                      ) {
                                        if (
                                          inputParameters[
                                            eachId
                                          ].hasOwnProperty(
                                            "landed_unit_price_without_gst"
                                          )
                                        ) {
                                          var priceWithoutGst =
                                            inputParameters[eachId]
                                              .landed_unit_price_without_gst;
                                        }
                                        if (
                                          inputParameters[
                                            eachId
                                          ].hasOwnProperty(
                                            "landed_unit_price_with_gst"
                                          )
                                        ) {
                                          var priceWithGst =
                                            inputParameters[eachId]
                                              .landed_unit_price_with_gst;
                                        }
                                        if (
                                          inputParameters[
                                            eachId
                                          ].hasOwnProperty("amount_without_gst")
                                        ) {
                                          var amounteWithoutGst =
                                            inputParameters[eachId]
                                              .amount_without_gst;
                                        }
                                        if (
                                          inputParameters[
                                            eachId
                                          ].hasOwnProperty("amount_with_gst")
                                        ) {
                                          var amountWithGst =
                                            inputParameters[eachId]
                                              .amount_with_gst;
                                        }
                                      }
                                    }
                                    return (
                                      <React.Fragment key={eachId}>
                                        <tr>
                                          <td className="td-2">{rfq_item}</td>
                                          <td className="td-2">
                                            {rfq_item_uom}
                                          </td>
                                          <td className="td-2">
                                            {rfq_item_quantity}
                                          </td>
                                          <td className="td-2">
                                            {!this.state.checkArray.includes(
                                              priceWithoutGst
                                            )
                                              ? priceWithoutGst
                                              : "0"}
                                          </td>
                                          <td className="td-2">
                                            {!this.state.checkArray.includes(
                                              priceWithGst
                                            )
                                              ? priceWithGst
                                              : "0"}
                                          </td>
                                          <td className="td-2">
                                            {!this.state.checkArray.includes(
                                              amounteWithoutGst
                                            )
                                              ? amounteWithoutGst
                                              : "0"}
                                          </td>
                                          <td className="td-2">
                                            {!this.state.checkArray.includes(
                                              amountWithGst
                                            )
                                              ? amountWithGst
                                              : "0"}
                                          </td>
                                          <td className="td-2">
                                            {rfq_item_delivery_location}
                                          </td>
                                          <td className="td-2">
                                            {!this.state.checkArray.includes(
                                              comments
                                            )
                                              ? comments[eachId]
                                              : ""}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })}
                                  <tr>
                                    <td className="td-2" colSpan="5">
                                      Total
                                    </td>
                                    <td className="td-2">
                                      {!this.state.checkArray.includes(
                                        total_amount_without_gst
                                      )
                                        ? total_amount_without_gst
                                        : "0"}
                                    </td>
                                    <td className="td-2">
                                      {!this.state.checkArray.includes(
                                        total_amount_with_gst
                                      )
                                        ? total_amount_with_gst
                                        : "0"}
                                    </td>
                                    <td className="td-2" colSpan="2"></td>
                                  </tr>
                                </table>
                              </div>

                              <div className="mt-3 bottomTable">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr
                                      style={{ background: "grey" }}
                                      className="text-white"
                                    >
                                      <td className="td-2">
                                        Other Commercial Terms{" "}
                                      </td>
                                      <td className="td-2">Our Requirement</td>
                                      <td className="td-2">Vendor's Offer</td>
                                    </tr>
                                    {this.state.commercialTermIds.map(
                                      (eachId) => {
                                        var fileName = "";
                                        var {
                                          rfq_commercial_terms_doc,
                                          rfq_commercial_terms_title,
                                        } = this.state.commercialTerms[eachId];
                                        var index =
                                          rfq_commercial_terms_doc.lastIndexOf(
                                            "/"
                                          );
                                        fileName =
                                          rfq_commercial_terms_doc.slice(
                                            index + 1
                                          );
                                        return (
                                          <>
                                            <tr>
                                              <td className="td-2">
                                                {!this.state.checkArray.includes(
                                                  rfq_commercial_terms_title
                                                )
                                                  ? rfq_commercial_terms_title
                                                  : ""}
                                              </td>
                                              <td className="td-2">
                                                {!this.state.checkArray.includes(
                                                  fileName
                                                ) ? (
                                                  <a
                                                    href={`${rfq_commercial_terms_doc}`}
                                                  >
                                                    {fileName}
                                                  </a>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td className="td-2">
                                                {this.state.checkArray.includes(
                                                  commercialTermsResponse
                                                )
                                                  ? ""
                                                  : commercialTermsResponse[
                                                      eachId
                                                    ]}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div
                                className="row m-2"
                                style={{ width: "100%" }}
                              >
                                <div className="col-2">
                                  <label htmlFor="">Vendor Remarks</label>
                                </div>
                                <div className="col-9">
                                  <label htmlFor="">
                                    {this.state.checkArray.includes(remarks)
                                      ? "NA"
                                      : remarks}
                                  </label>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {this.state.technicalCompBtn === "btn-active" ? (
                            <>
                              <div
                                className="mt-3 card shadow"
                                style={{ minHeight: "20vh", width: "100%" }}
                              >
                                <h6
                                  style={{ background: "grey" }}
                                  className="p-2 text-white"
                                >
                                  Specific Desired Response
                                </h6>
                                <table>
                                  <tbody>
                                    {this.state.rfqQuestionsIds.map(
                                      (eachId) => {
                                        return (
                                          <React.Fragment key={eachId}>
                                            <tr>
                                              <td>
                                                {
                                                  this.state.rfqQuestions[
                                                    eachId
                                                  ]
                                                }
                                              </td>
                                              <td>
                                                {this.state.checkArray.includes(
                                                  questions
                                                )
                                                  ? "NA"
                                                  : questions[eachId]}
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                    <tr>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {this.state.rfqAttachmentsBtn === "btn-active" ? (
                            <>
                              <div>
                                <label htmlFor="">RFQ Attachments</label>
                                <br />
                                <label htmlFor="">
                                  {this.state.rfqAttachmentsData}
                                </label>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="card shadow py-4 px-3 mx-3">
            <div className="row justify-content-center">
              {this.state.approvers.map((each, index) => {
                var uniqueKey = uuidv4();
                // this.state.approvers
                var color = "text-danger";
                var borderValue = "1px solid red";
                if (each.is_event_approved === 1) {
                  color = "text-success";
                  borderValue = "1px solid green";
                }

                return (
                  <div className={`col-3 ${color}`} key={uniqueKey}>
                    <div
                      className="numberCircle"
                      style={{ border: borderValue }}
                    >
                      {parseInt(index) + 1}
                    </div>
                    <div className="">{each.name}</div>
                    <div className="">{each.status}</div>
                    <div className=""></div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            {this.state.approverEmails.includes(this.state.userEmail) &&
            this.state.isRfqApproved === 0 ? (
              <button
                className="btn btn-info mr-2"
                disabled={this.state.isRfqApproved === 0 ? false : true}
                onClick={this.approveRfq.bind(this)}
              >
                Approve
              </button>
            ) : (
              <></>
            )}

            <button className="btn btn-success mr-2">Export to excel</button>
            <button className="btn btn-danger mr-2">Cancel RFQ</button>

            <button className="btn btn-primary">
              <Link to={"/organiser-dashboard"}
              style={{color: "white",
                textDecoration: "none"}}>Back</Link>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withParams(RfqComparitiveAnalysis);
