import { React, useEffect, useState } from 'react';
import AgGrid from "./AgGrid"
// import { columnwithDefs } from "./columnDefs_vehicles";
// import Constants from "./Masters_constants";
import redirectURL from "../redirectURL";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Favourites from "../common/favouritesComponent";
// import EditItem from './EditItem'
// import SweetAlert from 'react-bootstrap-sweetalert';
// import { getHyphenDDMMMYYYYHHMM } from '../common/utils';


const niprocols = [
    {
        headerName: "Consigner Code",
        field: "consigner_code",
        width: 160,
        editable: false
    },
    {
        headerName: "Consignee Code",
        field: "consignee_code",
        width: 160,
        editable: false

    },
    {
        headerName: "Consignee Name",
        field: "consignee_name",
        width: 160,
        editable: false
    },
    // {
    //     headerName: "Consigner Type",
    //     field: "consigner_type",
    //     width: 160,
    //     editable: false
    // },
    {
        headerName: "Truck Transit Time",
        field: "transit_time",
        width: 160,
        editable: false
    },
    // {
    //     headerName: "Trailer Transit Time",
    //     field: "trailer_transit_time",
    //     width: 160,
    //     editable: false
    // },
    {
        headerName: "Distance (Miles)",
        field: "distance_in_miles",
        width: 160,
        editable: false,
        valueGetter:function(params){
            try {
                if(params.data.distance_in_km != "" && params.data.distance_in_km != undefined)
                {
                    return Math.round(params.data.distance_in_km/1.60934);
                }
                else{
                    return 0;
                }
            } catch (error) {
                
            }
        }
    },
    
    

];

function TransitTimes() {
    var [rowData, setRowData] = useState([])
    var [columnDefs] = useState(niprocols)
    // var [showloaderbg, setshowloaderbg] = useState("show-m");
    // var [showloader, setshowloader] = useState("show-m");
    // var [showAddSlider, setshowAddSlider] = useState("");
    var [overlay, setoverlay] = useState("show-n");
    // var [sliderText, setSliderText] = useState("");
    // const [isEditMode, setIsEditMode] = useState(false);
    // var [showEditSlider, setshowEditSlider] = useState("");
    // var [transporter_name, settransportername] = useState("");
    // var [transporter_code, settransportercode] = useState("");
    // var [is_active, setstatus] = useState("");
    // var [show, setShow] = useState(false);
    // var [basicType, setBasicType] = useState("");
    // var [basicTitle, setBasicTitle] = useState("");





    useEffect(() => {
        setTimeout(() => {
            // setshowloaderbg("show-n")
            // setshowloader("show-n")
        }, 2000)
        loadData();

        // 
    }, [])

    var loadData = async () => {
        var parameters = {}
        await redirectURL.post('app/sndtransittimes', parameters)
            .then(response => {
                if (response.data.status == "success") {
                    setRowData(response.data.records);
                }
                // setshowloader("show-n");
            })
            .catch(error => console.error('Error fetching data:', error));
    }

    var hideAll = () => {
        setoverlay("show-n");
        // setshowEditSlider("");
        // setshowAddSlider("");
    }

    return (

        <div className="container-fluid">
            <div className="row">
                <h5 class="mb-15p ml-15p mt-15p fbold f20">
                    <i class="icofont icofont-vehicle-delivery-van cus-i"></i>&nbsp;
                    <span>Transit Times</span>
                    <Favourites />
                </h5>
                <div className="col-xl-12 col-md-12 xl-60">
                    <div className="card">
                        <div className="card-body">
                            <AgGrid
                                rowData={rowData}
                                // columnDefs={columnwithDefs}
                                columnDefs={columnDefs}
                                currentPage={window.location.pathname}
                                others={""}
                                // onClickEditBlock={onClickShowEditItem}
                                saveoptions={"N"}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className={"overlay-part " + (overlay)} onClick={hideAll}></div>
        </div>
    );
}

export default TransitTimes;

