import React, { Component } from "react";
import { SideNav, Nav } from "react-sidenav";
import { Link } from "react-router-dom";
import { MENUITEMS2 } from "./menu22";
import MetisMenu from "react-metismenu";
import RouterLink from "react-metismenu-router-link";
import redirectURL from "../../redirectURL";
//import {MENUITEMS} from '../../../constants/menu'

// Import custom components
//import UserPanel from './user-panel.component';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPath: "1",
      mainmenu: null,

      searchQuery: "",
      filteredMenu: null,
    };
  }
  // state = {  };
  onItemSelection = (arg, e) => {
    this.setState({ selectedPath: arg.path });
  };

  componentWillMount() {}
  componentDidMount() {
    var currentUrl = window.location.pathname;
    if (!this.state.mainmenu) {
      //console.log("IN componentDidMount, this.state.mainmenu ", this.state.mainmenu);
      let menuslist = "menussss";
      //console.log("IN componentDidMount, menuslist = ", menuslist);
      // let body = {
      //     role: localStorage.getItem("role")
      // }
      // redirectURL.post('/getMenus', body)
      //     .then(res => {
      //         menuslist = res.data.menus
      //         localStorage.setItem('usermenus', JSON.stringify(res.data.menus))
      //     })
      //     .catch(err => console.log(err))
      if (menuslist) {
        const MENUITEMS = MENUITEMS2;
        this.setState(
          {
            mainmenu: MENUITEMS,
            filteredMenu: MENUITEMS,
          },
          () => {
            this.setActiveMenu(currentUrl);
          }
        );
      }
    }
    if (this.state.mainmenu) {
      this.state.mainmenu.filter((items) => {
        if (!items.children) {
          if (items.path === currentUrl) this.setNavActive(items);
          return false;
        }
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) this.setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) this.setNavActive(subSubItems);
          });
        });
      });
    }
  }

  setActiveMenu = (currentUrl) => {
    const { mainmenu } = this.state;

    if (mainmenu) {
      const updatedMenu = this.findAndActivateMenu(mainmenu, currentUrl);
      this.setState({ mainmenu: updatedMenu });
    }
  };

  // Recursive function to find the correct item and activate it
  findAndActivateMenu = (menuItems, currentUrl) => {
    return menuItems.map((item) => {
      item.active = false; // Reset active state for all items

      // Check if the item's path matches the current URL
      if (item.path === currentUrl) {
        item.active = true; // Set the item as active if the path matches
        return item; // Return the modified item
      }

      // If the item has children, search recursively within its children
      if (item.children) {
        item.children = this.findAndActivateMenu(item.children, currentUrl);

        // If any of the children are active, the parent should also be active
        const isAnyChildActive = item.children.some((child) => child.active);
        if (isAnyChildActive) {
          item.active = true; // Set the parent as active if a child is active
        }
      }

      return item; // Return the modified item
    });
  };

  // setNavActive(item) {
  //   let MENUITEMS = [...this.state.mainmenu];
  //   if (this.state.mainmenu) {
  //     MENUITEMS.filter((menuItem) => {
  //       if (menuItem != item) menuItem.active = false;
  //       if (menuItem.children && menuItem.children.includes(item))
  //         menuItem.active = true;
  //       if (menuItem.children) {
  //         // menuItem.children.filter(submenuItems => {
  //         //     if(submenuItems.children && submenuItems.children.includes(item))
  //         //         menuItem.active = true
  //         //     if(submenuItems != item)
  //         //         submenuItems.active = false
  //         // })

  //         menuItem.children.filter((submenuItems) => {
  //           if (submenuItems.children && submenuItems.children.includes(item)) {
  //             //console.log("Step1")
  //             menuItem.active = true;
  //           }

  //           if (submenuItems != item) {
  //             //console.log("Step2")
  //             submenuItems.active = false;
  //             //this.props.context.openCloseSidebar();
  //           }
  //           if (submenuItems.children && submenuItems.children.length > 0) {
  //             //console.log("Step3")
  //             if (submenuItems.children.includes(item) == true) {
  //               //console.log("Step4")
  //               this.onHideSidebar();
  //             }
  //           } else {
  //             // console.log("Step5")
  //             if (item.is_link == true) {
  //               // console.log("Step6")
  //               if (menuItem.children.includes(item) == true) {
  //                 //console.log("Step7")
  //                 this.onHideSidebar();
  //               }
  //             }
  //           }
  //           //console.log("submenuItems ", submenuItems)
  //         });
  //       }
  //     });
  //   }
  //   item.active = !item.active;

  //   // this.setState({
  //   //   mainmenu: MENUITEMS,
  //   //   filteredMenu: MENUITEMS,
  //   // });

  //   if (shouldCloseSidebar) {
  //     this.onHideSidebar(); // Close sidebar when nav link is clicked
  //   }

  //   this.setState({ mainmenu: MENUITEMS, filteredMenu: MENUITEMS });
  // }

  setNavActive = (item, shouldCloseSidebar = false) => {
    let MENUITEMS = [...this.state.mainmenu];
    // console.log("item ",item);
    // if(item.children.length === 0)
    // {
    //   window.location.href=item.path;
    // }
    MENUITEMS.forEach((menuItem) => {
      if (menuItem !== item) menuItem.active = false;

      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;

      if (menuItem.children) {
        menuItem.children.forEach((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item))
            menuItem.active = true;

          if (submenuItems !== item) submenuItems.active = false;

          if (submenuItems.children && submenuItems.children.length > 0) {
            if (submenuItems.children.includes(item)) {
              this.onHideSidebar(); // Close sidebar when an item is clicked
            }
          }
        });
      }
    });

    item.active = !item.active;

    if (shouldCloseSidebar) {
      this.onHideSidebar(); // Close sidebar when nav link is clicked
    }

    this.setState({ mainmenu: MENUITEMS });
  };

  filterMenuItems = () => {
    const { searchQuery, mainmenu } = this.state;

    // Reset active state of all menu items, children, and grandchildren
    const resetMenu = (menuItems) => {
      return menuItems.map((menuItem) => {
        menuItem.active = false;
        if (menuItem.children) {
          menuItem.children = resetMenu(menuItem.children);
        }
        return menuItem;
      });
    };
    const resetMainMenu = resetMenu(mainmenu);

    if (!searchQuery) {
      this.setState({ filteredMenu: resetMainMenu }); // Reset to the full menu if the search query is empty
      return;
    }

    const filteredMenu = resetMainMenu
      .map((menuItem) => {
        let newItem = { ...menuItem }; // Clone the menu item

        if (menuItem.name.toLowerCase().includes(searchQuery)) {
          return newItem; // If the top-level menu matches, include all its children
        }

        // Check if any child matches the search query
        if (menuItem.children) {
          newItem.children = menuItem.children
            .map((child) => {
              let newChild = { ...child }; // Clone the child item

              // Check if the child matches the search query
              if (child.name.toLowerCase().includes(searchQuery)) {
                newChild.active = true; // Mark the child as active
                newItem.active = true; // Ensure parent is active to show the child
                return newChild;
              }

              // Check if any grandchild matches the search query
              if (child.children) {
                newChild.children = child.children.filter((subChild) => {
                  if (subChild.name.toLowerCase().includes(searchQuery)) {
                    subChild.active = true; // Mark grandchild as active
                    newChild.active = true; // Ensure child is active
                    newItem.active = true; // Ensure parent is active
                    return subChild;
                  }
                  return null;
                });
              }

              return newChild;
            })
            .filter((child) => child.active); // Only keep active children
        }

        return newItem.active ? newItem : null; // Only include active items
      })
      .filter((item) => item !== null); // Remove null items from the filtered results

    this.setState({ filteredMenu }); // Update the filteredMenu state with the new list
  };

  onCloseSidebar() {
    document.querySelector(".page-body-wrapper").classList.add("sidebar-close");

    //document.querySelector(".sidebaricon").classList.remove('show-n');
    //document.querySelector(".sidebaricon").classList.add('show-m');
    document.querySelector(".siconbg").classList.remove("show-m");
    document.querySelector(".siconbg").classList.add("show-n");
    document.querySelector(".sidemenu").classList.remove("l20n");
    document.querySelector(".sidemenu").classList.add("whgb");
    document.querySelector(".sidebrclose").classList.remove("show-n");
    document.querySelector(".sidebrclose").classList.add("show-m");
    //console.log(document.querySelector(".sidebrclose").classList);
    var sidebaricon = document.querySelector(".sidebaricon");
    // if(sidebaricon.classList.contains('show-n'))
    // {
    //console.log("Yr")
    document.querySelector(".sidebaricon").classList.remove("show-n");
    document.querySelector(".sidebaricon").classList.add("show-m");
    // }
    // if(sidebaricon.classList.contains('show-m'))
    // {
    //     document.querySelector(".sidebaricon").classList.remove('show-m');
    //     document.querySelector(".sidebaricon").classList.add('show-n');
    // }
    // if(document.querySelector(".sidebrclose").hasClass('show-m'))
    // {
    //     document.querySelector(".sidebrclose").classList.remove('show-m');
    //     document.querySelector(".sidebrclose").classList.add('show-n');
    // }
  }
  onHideSidebar() {
    document.querySelector(".page-body-wrapper").classList.add("sidebar-close");
    document.querySelector(".sidebaricon").classList.remove("show-n");
    document.querySelector(".sidebaricon").classList.add("show-m");
    document.querySelector(".siconbg").classList.remove("show-m");
    document.querySelector(".siconbg").classList.add("show-n");
    document.querySelector(".sidemenu").classList.remove("l20n");
    document.querySelector(".sidemenu").classList.add("whgb");
    document.querySelector(".sidebrclose").classList.remove("show-n");
    document.querySelector(".sidebrclose").classList.add("show-m");
  }
  // Handle search input changes
  onSearchChange = (event) => {
    const searchQuery = event.target.value.toLowerCase(); // Get the search query and convert to lowercase
    this.setState({ searchQuery }, this.filterMenuItems); // Update searchQuery state and filter menu items
  };
  // Filter menu items based on the search query
  filterMenuItems = () => {
    const { searchQuery, mainmenu } = this.state;

    // Reset active state of all menu items, children, and grandchildren
    const resetMenu = (menuItems) => {
      return menuItems.map((menuItem) => {
        menuItem.active = false;
        if (menuItem.children) {
          menuItem.children = resetMenu(menuItem.children);
        }
        return menuItem;
      });
    };
    const resetMainMenu = resetMenu(mainmenu);

    if (!searchQuery) {
      this.setState({ filteredMenu: resetMainMenu }); // Reset to the full menu if the search query is empty
      return;
    }

    const filteredMenu = resetMainMenu
      .map((menuItem) => {
        let newItem = { ...menuItem }; // Clone the menu item

        if (menuItem.name.toLowerCase().includes(searchQuery)) {
          return newItem; // If the top-level menu matches, include all its children
        }

        // Check if any child matches the search query
        if (menuItem.children) {
          newItem.children = menuItem.children
            .map((child) => {
              let newChild = { ...child }; // Clone the child item

              // Check if the child matches the search query
              if (child.name.toLowerCase().includes(searchQuery)) {
                newChild.active = true; // Mark the child as active
                newItem.active = true; // Ensure parent is active to show the child
                return newChild;
              }

              // Check if any grandchild matches the search query
              if (child.children) {
                newChild.children = child.children.filter((subChild) => {
                  if (subChild.name.toLowerCase().includes(searchQuery)) {
                    subChild.active = true; // Mark grandchild as active
                    newChild.active = true; // Ensure child is active
                    newItem.active = true; // Ensure parent is active
                    return subChild;
                  }
                  return null;
                });
              }

              return newChild;
            })
            .filter((child) => child.active); // Only keep active children
        }

        return newItem.active ? newItem : null; // Only include active items
      })
      .filter((item) => item !== null); // Remove null items from the filtered results

    this.setState({ filteredMenu }); // Update the filteredMenu state with the new list
  };
  // clearSearchAndCloseSidebar = () => {
  //   this.setState({ searchQuery: "" }, this.filterMenuItems);
  // };
  clearSearchAndCloseSidebar = () => {
    this.setState({ searchQuery: "" }, this.filterMenuItems);
  };
  clearSearch = () => {
    this.setState({ searchQuery: "" }, this.filterMenuItems);
  };
  render() {
    const theme = {
      selectionColor: "#C51162",
    };
    //console.log("IN render, 1 this.state.mainmenu ", this.state.mainmenu);
    let mainmenu = null;
    if (!this.state.mainmenu) {
      // console.log("IN Render, 2 this.state.mainmenu ", this.state.mainmenu);
      let menuslist = localStorage.getItem("usermenus");
      if (menuslist) {
        // const MENUITEMS = JSON.parse(menuslist);
        const MENUITEMS = MENUITEMS2;
        this.setState({
          mainmenu: MENUITEMS,
        });
      }
    }
    // console.log("this.state.mainmenu ", this.state.mainmenu);
    var sideTitlecls = "";
    var sideHeadercls = "";
    if (localStorage.getItem("is_enmovil_transporter") == 1) {
      var sideTitlecls = "sideTitlecls";
      var sideHeadercls = "sideHeadercls";
    }
    if (this.state.mainmenu) {
      mainmenu = this.state.mainmenu.map((menuItem, i) => (
        <li className={`${menuItem.active ? "active" : ""}`} key={i}>
          {menuItem.is_group_header === true ? (
            <div className={"sidebar-title " + sideTitlecls}>
              {menuItem.group_heading}
            </div>
          ) : (
            ""
          )}
          {menuItem.is_link === false ? (
            <a
              className={"sidebar-header " + sideHeadercls}
              href="javascript:void(0)"
              onClick={() => this.setNavActive(menuItem)}
            >
              <i className={`${menuItem.icon}`}></i>
              <span>{menuItem.name}</span>
              <i className="fa fa-angle-right pull-right"></i>
            </a>
          ) : (
            ""
          )}
          {menuItem.is_link === true ? (
            <Link
              to={`${process.env.PUBLIC_URL}${menuItem.path}`}
              className={
                `sidebar-header ${menuItem.active ? "Active " : " "}` +
                sideHeadercls
              }
              onClick={() => this.setNavActive(menuItem)}
            >
              <i className={`${menuItem.icon}`}></i>
              <span>{menuItem.name}</span>
              {menuItem.children ? (
                <i className="fa fa-angle-right pull-right"></i>
              ) : (
                ""
              )}
            </Link>
          ) : (
            ""
          )}
          {menuItem.children ? (
            <ul
              className={`sidebar-submenu ${
                menuItem.active ? "menu-open" : ""
              }`}
              style={
                menuItem.active
                  ? { opacity: 1, transition: "opacity 500ms ease-in" }
                  : {}
              }
            >
              {menuItem.children.map((childrenItem, index) => (
                <li
                  key={index}
                  className={
                    childrenItem.children
                      ? childrenItem.active
                        ? "active"
                        : ""
                      : ""
                  }
                >
                  {childrenItem.is_link === false ? (
                    <a
                      href="javascript:void(0)"
                      onClick={() => this.setNavActive(childrenItem)}
                    >
                      {/* <i className="fa fa-angle-right"></i> */}
                      &nbsp;&nbsp; {childrenItem.name}
                      <span className="submenugroup">
                        <i className="fa fa-angle-right"></i>
                      </span>
                    </a>
                  ) : (
                    ""
                  )}

                  {childrenItem.is_link === true ? (
                    childrenItem.path.startsWith("http") ||
                    childrenItem.path.startsWith("https") ? (
                      <a
                        href={childrenItem.path}
                        className={childrenItem.active ? "active" : ""}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-angle-right"></i>
                        {childrenItem.name}
                      </a>
                    ) : (
                      <Link
                        to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                        className={childrenItem.active ? "active" : ""}
                        onClick={() => this.setNavActive(childrenItem)}
                      >
                        {/* <i className="fa fa-angle-right"></i> */}
                        &nbsp;&nbsp; {childrenItem.name}{" "}
                      </Link>
                    )
                  ) : (
                    ""
                  )}
                  {childrenItem.children ? (
                    <ul
                      className={`sidebar-submenu ${
                        childrenItem.active ? "menu-open" : ""
                      }`}
                    >
                      {childrenItem.children.map((childrenSubItem, key) => (
                        <li
                          className={childrenSubItem.active ? "active" : ""}
                          key={key}
                        >
                          {/* { (childrenSubItem.is_link === true )?
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                        className={childrenSubItem.active?'active':''}
                                                    >
                                                        <i className="fa fa-angle-right"></i>{childrenSubItem.name}</Link>
                                                :''} */}

                          {childrenSubItem.is_link === false ? (
                            <a
                              href="javascript:void(0)"
                              onClick={() => this.setNavActive(childrenSubItem)}
                            >
                              {/* <i className="fa fa-angle-right"></i> */}
                              &nbsp;&nbsp; {childrenSubItem.name}
                            </a>
                          ) : (
                            ""
                          )}
                          {childrenSubItem.is_link === true ? (
                            <Link
                              to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                              className={childrenSubItem.active ? "active" : ""}
                              onClick={() => this.setNavActive(childrenSubItem)}
                            >
                              {/* <i className="fa fa-angle-right"></i> */}
                              &nbsp;&nbsp;{childrenSubItem.name}
                            </Link>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </li>
      ));
    }
    var sideMenuCls = "";
    if (localStorage.getItem("is_enmovil_transporter") == 1) {
      var sideMenuCls = "sideMenuCls";
    }
    const { filteredMenu, searchQuery } = this.state; // Use the filteredMenu state for rendering
    var cpath = window.location.pathname;
    return (
      <div
        className={
          "page-sidebar custom-scrollbar page-sidebar-open " + sideMenuCls
        }
        onMouseEnter={this.props.openSidebar}
        onMouseLeave={this.props.closeSidebar}
      >
        {/* <ul className="sidebar-menu">
                    {mainmenu}
                </ul> */}

        <ul className="sidebar-menu">
          <div style={{ position: "relative", marginBottom: "20px" }}>
            <input
              style={{
                borderRadius: "0px",
                fontSize: "12px",
                padding: "8px 35px 8px 8px",
                width: "230px",
                outline: "0",
                margin: "30px 0px 0px 0px",
                border: "0",
                borderBottom: "2px solid rgb(219 219 219)",
              }}
              type="text"
              placeholder="Search menu..."
              value={this.state.searchQuery}
              onChange={this.onSearchChange}
            />
            {searchQuery && (
              <i
                className="fa fa-times"
                style={{
                  position: "absolute",
                  right: "40px",
                  top: "75%",
                  transform: "translateY(-50%)",
                  color: "#9b9191",
                  cursor: "pointer",
                }}
                onClick={this.clearSearchAndCloseSidebar}
              ></i>
            )}
          </div>
          {filteredMenu &&
            filteredMenu.map((menuItem, i) => (
              <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                {/* {menuItem.group_heading && (
                  <div className="sidebar-title">{menuItem.group_heading}</div>
                )} */}
                {!menuItem.is_link ? (
                  <a
                    className="sidebar-header"
                    href="javascript:void(0)"
                    onClick={() => this.setNavActive(menuItem)}
                  >
                    <i className={`${menuItem.icon}`}></i>
                    <span>{menuItem.name}</span>
                    {menuItem.children && (
                      <i className="fa fa-angle-right pull-right"></i>
                    )}
                  </a>
                ) : (
                  <Link
                    to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                    className={`sidebar-header ${
                      menuItem.active ? "active" : ""
                    }`}
                    onClick={() => this.setNavActive(menuItem, true)}
                  >
                    <i className={`icon-${menuItem.icon}`}></i>
                    <span>{menuItem.name}</span>
                    {menuItem.children && (
                      <i className="fa fa-angle-right pull-right"></i>
                    )}
                  </Link>
                )}
                {menuItem.children && (
                  <ul
                    className={`sidebar-submenu ${
                      menuItem.active ? "menu-open" : ""
                    }`}
                  >
                    {menuItem.children.map((child, index) => (
                      <li
                        key={index}
                        className={`${
                          child.children ? (child.active ? "active" : "") : ""
                        }`}
                      >
                        {!child.is_link ? (
                          <a
                            href="javascript:void(0)"
                            onClick={() => this.setNavActive(child)}
                          >
                            {/* <i className="fa fa-angle-right"></i> */}
                            {child.name}
                          </a>
                        ) : (
                          <Link
                            to={`${process.env.PUBLIC_URL}${child.path}`}
                            className={`${child.active ? "active" : ""}`}
                            onClick={() => this.setNavActive(child, true)}
                          >
                            {/* <i className="fa fa-angle-right"></i> */}
                            {child.name}
                          </Link>
                        )}
                        {child.children && (
                          <ul
                            className={`sidebar-submenu ${
                              child.active ? "menu-open" : ""
                            }`}
                          >
                            {child.children.map((subChild, subIndex) => (
                              <li
                                key={subIndex}
                                className={`${
                                  subChild.children
                                    ? subChild.active
                                      ? "active"
                                      : ""
                                    : ""
                                }`}
                              >
                                {!subChild.is_link ? (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => this.setNavActive(subChild)}
                                  >
                                    {/* <i className="fa fa-angle-right"></i> */}
                                    {subChild.name}
                                  </a>
                                ) : (
                                  <Link
                                    to={`${process.env.PUBLIC_URL}${subChild.path}`}
                                    className={subChild.active ? "active" : ""}
                                    onClick={() =>
                                      this.setNavActive(subChild, true)
                                    }
                                  >
                                    {/* <i className="fa fa-angle-right"></i> */}
                                    {subChild.name}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
        <a
          href="javascript:void(0)"
          className="sidebar-blueicon"
          onClick={this.onCloseSidebar.bind(this)}
        >
          <span
            id="ssinnerOpen"
            className={"show-m"}
            onClick={this.onCloseSidebar.bind(this)}
          ></span>
          <img
            src={require("../../../assets/icons/SIDE-NAV.png")}
            className="sidebaropen-icon"
            onClick={this.onCloseSidebar.bind(this)}
          />
        </a>
      </div>
    );
  }
}

export default Sidebar;
