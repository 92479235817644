import React, { Component } from "react";
import "./vendorProcureDashboard.css";
import "./organiserProcureDashboard.css";
import { Link } from "react-router-dom";
import redirectURL from "../redirectURL";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export default class OrganiserProcureDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openBidsCount: 0,
      openRfxCount: 0,
      notForwardedBidsCount: 0,
      notForwardedRfxCount: 0,
      bidsUnderApprovalCount: 0,
      rfxUnderApprovalCount: 0,
      approvedBidsCount: 0,
      approvedRfxCount: 0,
      pastEventsLength: 0,
      pastEvents: [],
      ongoingEvents: [],
      upcomingEvents: [],
      rightBottomCardHeading: "Pending Bids",
      rightBoxCounter: 0,
      pendingBidEvents: [],
      eventID: 12345,
      termsAndCondtionsCheckboxStatus: false,
      openModal: false,
      loadshow:"show-n"
    };
  }

  componentDidMount = () => {
    this.setState({
      loadshow:"show-m"
    })
    redirectURL
      .get("/procurement/getAllEvents")
      .then((resp) => {
        console.log(
          "getAllEvents resp",
          resp.data.ongoingEvents.length + resp.data.upcomingEvents.length
        );

        if (resp.data.success) {
          this.setState({
            pastEventsLength: resp.data.pastEvents.length,
            pastEvents: resp.data.pastEvents,

            ongoingEvents: resp.data.ongoingEvents,
            rightBoxCounter:
              resp.data.ongoingEvents.length + resp.data.upcomingEvents.length,

            upcomingEvents: resp.data.upcomingEvents,
            pendingBidEvents: [
              ...resp.data.upcomingEvents,
              ...resp.data.ongoingEvents,
            ],
            loadshow: "show-n"
          });
        }
      })
      .catch((err) => {
        console.log("getAllEvents resp", err);
      });
  };

  openModal = () => {
    this.setState({
      openModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  changeTermsAndCondtionsCheckboxStatus = () => {
    this.setState({
      termsAndCondtionsCheckboxStatus:
        !this.state.termsAndCondtionsCheckboxStatus,
    });
  };

  handleBottomRightEventsBox = (boxTitle) => {
    this.setState({ rightBottomCardHeading: boxTitle });
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className={"dataLoadpage " + this.state.loadshow}></div>
          <div className={"dataLoadpageimg " + this.state.loadshow}>
            <div class="spinner-grow text-primary mr-2" role="status"></div>
            <div class="spinner-grow text-primary mr-2" role="status"></div>
            <div class="spinner-grow text-primary" role="status"></div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12 mb-4">
              <div className="card shadow h-100 py-2">
                <div className="row justify-content-between topCardBody">
                  <div className="card text-white h-75 eventsCard bg-info">
                    <div
                      className="row justify-content-between"
                      onClick={this.handleBottomRightEventsBox.bind(
                        this,
                        // `Open Bids (${this.state.openBidsCount})`
                        `Open Bids`
                      )}
                    >
                      <label>Open Bids</label>
                      <label>{this.state.openBidsCount}</label>
                    </div>
                    <div
                      className="row justify-content-between"
                      onClick={this.handleBottomRightEventsBox.bind(
                        this,
                        `Open RFx`
                      )}
                    >
                      <label>Open RFx</label>
                      <label>{this.state.openRfxCount}</label>
                    </div>
                  </div>
                  <div className="card text-white h-75 eventsCard bg-success" style={{display:"none"}}>
                    <div
                      className="row justify-content-between"
                      onClick={this.handleBottomRightEventsBox.bind(
                        this,
                        `Not Forwarded Bids`
                      )}
                    >
                      <label>Not Forwarded Bids</label>
                      <label>{this.state.notForwardedBidsCount}</label>
                    </div>
                    <div
                      className="row justify-content-between"
                      onClick={this.handleBottomRightEventsBox.bind(
                        this,
                        `Not Forwared RFx`
                      )}
                    >
                      <label>Not Forwared RFx</label>
                      <label> {this.state.notForwardedRfxCount} </label>
                    </div>
                  </div>
                  <div
                    className="card text-white h-75 eventsCard"
                    style={{ background: "#563d7c" }}
                  >
                    <div
                      className="row justify-content-between"
                      onClick={this.handleBottomRightEventsBox.bind(
                        this,
                        `Bids Under Approval`
                      )}
                    >
                      <label>Bids Under Approval</label>
                      <label> {this.state.bidsUnderApprovalCount} </label>
                    </div>
                    <div
                      className="row justify-content-between"
                      onClick={this.handleBottomRightEventsBox.bind(
                        this,
                        `RFx Under Approval`
                      )}
                    >
                      <label>RFx Under Approval</label>
                      <label> {this.state.rfxUnderApprovalCount} </label>
                    </div>
                  </div>
                  <div className="card text-white h-75 eventsCard bg-primary">
                    <div
                      className="row justify-content-between"
                      onClick={this.handleBottomRightEventsBox.bind(
                        this,
                        `Approved Bids`
                      )}
                    >
                      <label>Approved Bids</label>
                      <label> {this.state.approvedBidsCount} </label>
                    </div>
                    <div
                      className="row justify-content-between"
                      onClick={this.handleBottomRightEventsBox.bind(
                        this,
                        `Approved RFx`
                      )}
                    >
                      <label>Approved RFx</label>
                      <label> {this.state.approvedRfxCount} </label>
                    </div>
                  </div>
                </div>
                <div className="bottomCardBody shadow">
                  <div className="row px-1 justify-content-between">
                    <div className="bottom-card">
                      <div className="bottom-card-heading text-white bg-primary d-flex flex-row justify-content-between align-items-center p-1">
                        <div>
                          <h6 htmlFor="">
                            Pending Activities
                            {/* &#40;
                            {this.state.pastEventsLength}&#41; */}
                          </h6>
                        </div>
                        <div>
                          <input
                            type="search"
                            className="form-control rounded"
                            placeholder="Search"
                            aria-label="Search"
                          />
                        </div>
                      </div>
                      <div className="column justify-content-between">
                        {this.state.pastEvents.map((eachEvent) => {
                          const {
                            rfq_event_id,
                            rfq_subject,
                            rfq_start_time,
                            rfq_end_time,
                          } = eachEvent;
                          var uniqueKey = uuidv4();
                          return (
                            <Link
                              key={uniqueKey}
                              className="eachEvent row justify-content-between align-items-center"
                              //   to={`/procure/event-details/${rfq_event_id}`}
                              to={`/rfqComparitiveAnalysis/${rfq_event_id}`}
                            >
                              <label>{rfq_subject}</label>
                              <label className="text-red">
                                <i>
                                  {moment
                                    .parseZone(rfq_start_time)
                                    .format("Do MMM YYYY hh:mm")}
                                </i>{" "}
                                {/* -
                                <i>
                                  {moment
                                    .parseZone(rfq_end_time)
                                    .format("Do MMM'YY hh:mm")}
                                </i> */}
                              </label>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                    {/*Bottom Right Card*/}
                    <div className="bottom-card">
                      <div className="bottom-card-heading text-white bg-secondary d-flex flex-row justify-content-between align-items-center p-1">
                        <div>
                          <h6 htmlFor="">
                            {this.state.rightBottomCardHeading}
                            {/* &#40;
                            {this.state.rightBoxCounter} &#41; */}
                          </h6>
                        </div>
                        <div>
                          <input
                            type="search"
                            className="form-control rounded"
                            placeholder="Search"
                            aria-label="Search"
                          />
                        </div>
                      </div>
                      <div className="column justify-content-between">
                        {this.state.pendingBidEvents.map((eachEvent) => {
                          const {
                            rfq_event_id,
                            rfq_subject,
                            rfq_start_time,
                            rfq_end_time,
                          } = eachEvent;

                          var sTime = moment.parseZone(
                            new Date(rfq_start_time)
                          ).utcOffset("-05:30")._d;
                          var nowTime = moment.parseZone(new Date())._d;
                          console.log("nowTime < sTime", nowTime < sTime);
                          var uniqueKey = uuidv4();
                          return (
                            <Link
                              key={uniqueKey}
                              className="eachEvent row justify-content-between align-items-center"
                              to={
                                nowTime > sTime
                                  ? `/event-details/${rfq_event_id}`
                                  : null
                              }
                            >
                              <label>{rfq_subject}</label>
                              <label className="text-red">
                                <i>
                                  {moment
                                    .parseZone(rfq_start_time)
                                    .format("Do MMM YYYY hh:mm")}
                                </i>{" "}
                                {/* -
                                <i>
                                  {moment
                                    .parseZone(rfq_end_time)
                                    .format("Do MMM'YY hh:mm")}
                                </i> */}
                              </label>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
