import React, { useState } from "react";
import moment from "moment";
import "./ProgressbarCard.css";
import { useEffect } from "react";
import redirectURL from "../redirectURL";

const ExceptionsCard = (props) => {
  //console.log("ExceptionsCard", props);
  let { searchType, shipmentNo } = props
  const[exceptionData,setExceptionData]=useState()
  
  useEffect(() => {
    let promise=new Promise(
        function(myResolve,myReject){
          redirectURL.post("/exim/getExceptionData",{shipmentNo:shipmentNo})
          .then(response=>{
            myResolve(response.data)
          })
          .catch(error=>myReject(error))
        })
        promise.then(function(data) {
            setExceptionData(data)           
        },
        function(error) {
          console.log(error);
        })
  }, [props]);
  if(!exceptionData || exceptionData.length === 0 || exceptionData===undefined)
  return <></>
  return <>
    <div
    className="align-content-center justify-content-start mt-3 mx-3 row"
    style={{
      minHeight: "50px",
      background: "#e4edfc",
      borderRadius: "10px",
      width:"100%"
    }}
  >
    <div class="col-2">
      <label for="">Exceptions</label>
    </div>
    <div class="col-2">
      <label for="">Status</label>
    </div>
    <div class="col-2">
      <label for="">Detected At</label>
    </div>
    <div class="col-4">
      <label for="">Description</label>
    </div>
    <div class="col-2">
      <label for="">Details</label>
    </div>
  </div>
  {exceptionData.exceptions.map((each, i) => {
         let currentdate=moment();
         const delay=currentdate.diff(each.created_at,"days")
        return (
          <div
            class="justify-content-start mx-3 row"
            key={each._id}
            style={{ minHeight: "50px", borderBottom: "1px solid #dadada" }}
          >
            <div class="col-2">
              <label for="" style={{ fontWeight: "normal" }}>
              {each.title}
              </label>
            </div>
            <div class="col-2">
              <label for="" style={{ fontWeight: "normal" }}>
              {each.status}
              </label>
            </div>
            <div class="col-2">
              <label for="" style={{ fontWeight: "normal" }}>
              {each.created_at}
              </label>
            </div>
            <div class="col-4">
              <label for="" style={{ fontWeight: "normal" }}>
              {each.description}
              </label>
            </div>
            <div class="col-2">
              <label for="" style={{ fontWeight: "normal" }}>
              {delay} Day (s)
              </label>
            </div>
          </div>
        );
      })}
  </>
};

export default ExceptionsCard;
