
import React, { Component } from 'react';


const ViewDeviationRouteData = (props) => {
   
    const handleClick = (e) => {
        e.stopPropagation();
       // console.log("eee ", e.target.value)
        props.context.componentParent.onClickViewRouteData(props);
    };
    //console.log("Props ", props)
   
   return (
        <div>
            <span>
                {(props.data[props.colDef.field] != "" && props.data[props.colDef.field] != "undef")?
                    <button type="button" className="btn btn-warning" onClick={handleClick}>View Data</button>
                :""}

            </span>
        </div>
    );
};

export default ViewDeviationRouteData;
