import React, { Component } from 'react';
import AgGrid from "./AgGrid";
import Constants from "./Masters_constants";
import redirectURL from "../redirectURL";
import SweetAlert from 'react-bootstrap-sweetalert';
import EditButtonRenderer from './EditButtonRenderer';
import { param } from 'jquery';

const niprocols = [

    {
        headerName: "Vehicle Number",
        field: "vehicle_no",
        width: 220,
        filter: true,
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code",
        width: 220,
        filter: true,
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        width: 240,
        filter: true,
    },
    {
        headerName: "Location Name",
        field: "location_name",
        width: 220,
        filter: true,
    },
   
   
    // {
    //     headerName: "Actions",
    //     field: "actions",
    //     // params: {
    //     // actionfor: "editButton",
    //     // viewmode: "show",
    //     // },

    //     cellRenderer: EditButtonRenderer,
    //     // cellRenderer: editButtonRenderer,
    //     width: 100,
    // },
];

class Masters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            columnDefs: niprocols,
            showloader: "show-m",
            showAddSlider: "",
            overlay: "show-n",
            sliderText: "",
            isEditMode: false,
            showEditSlider: "",
            transporter_name: "",
            transporter_code: "",
            vehicle_no: "",
            vehicle_type: "",
            show: false,
            basicType: "",
            basicTitle: "",
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showloader: "show-n" });
        }, 2000);
        this.loadData();
    }

    loadData = async () => {
        const parameters = {};
        await redirectURL.post('tracking/getVehicleDetails', parameters)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({ rowData: response.data.records });
                }
                this.setState({ showloader: "show-n" });
            })
            .catch(error => console.error('Error fetching data:', error));
    }

    showAddBlock = () => {
        this.setState({
            showAddSlider: "slider-translate-30p",
            overlay: "show-m",
            sliderText: "Add Vehicle",
            isEditMode: false,
            transporter_name: "",
            transporter_code: "",
            vehicle_no: "",
            vehicle_type: "",
        });
    }

    showEditBlock = (data) => {
        this.setState({
            showAddSlider: "slider-translate-30p",
            overlay: "show-m",
            sliderText: "Edit Vehicle",
            isEditMode: true,
            transporter_name: data.transporter_name,
            transporter_code: data.transporter_code,
            vehicle_no: data.vehicle_no,
            vehicle_type: data.vehicle_type,
        });
    }

    hideAll = () => {
        this.setState({
            overlay: "show-n",
            showEditSlider: "",
            showAddSlider: "",
        });
    }

    saveMasterForm = async (e) => {
        e.preventDefault();
        const user_email = localStorage.getItem('email');
        this.setState({ showloader: "show-m" });
        const params = {
            transporter_name: this.state.transporter_name,
            transporter_code: this.state.transporter_code,
            vehicle_no: this.state.vehicle_no,
            vehicle_type: this.state.vehicle_type,
        };

        if (this.state.isEditMode) {
            await redirectURL.post('/updateVehicleMaster', params).then((resp) => {
                if (resp.data.status === "Success") {
                    this.hideAll();
                    this.loadData();
                    this.setState({
                        transporter_code: "",
                        transporter_name: "",
                        vehicle_no: "",
                        vehicle_type: "",
                        isEditMode: false,
                        show: true,
                        basicTitle: "Updated data",
                        basicType: "success",
                    });
                }
            });
        } else {
            await redirectURL.post('tracking/addVehicle', params).then((resp) => {
                if (resp.data.status === "success") {
                    this.hideAll();
                    this.loadData();
                    this.setState({
                        transporter_code: "",
                        transporter_name: "",
                        vehicle_no: "",
                        vehicle_type: "",
                        isEditMode: false,
                        show: true,
                        basicTitle: "Added data",
                        basicType: "success",
                    });
                } else if (resp.data.status === "failure") {
                    this.hideAll();
                    this.setState({
                        showloader: "show-n",
                        isEditMode: false,
                        transporter_code: "",
                        transporter_name: "",
                        show: true,
                        basicTitle: resp.data.message,
                        basicType: "danger",
                    });
                }
            }).catch(err => { });
        }
    }

    handleTransporterName = (e) => {
        const inputValue = e.target.value;
        if (inputValue !== "") {
            const isValid = /^[A-Za-z ]*$/;
            if (isValid.test(inputValue)) {
                if (inputValue.length <= 100) {
                    this.setState({ transporter_name: inputValue });
                }
            }
        } else {
            this.setState({ transporter_name: "" });
        }
    };

    handleVehicleNumberChange = (e) => {
        const { value } = e.target;
        const vehicleNumberRegex = /^[A-Z0-9-]+$/;

        if (!vehicleNumberRegex.test(value)) {
            this.setState({ vehicle_no: 'Vehicle number can only contain uppercase letters, numbers, and dashes.' });
        } else {
            this.setState({ vehicle_no: value });
        }
    };

    handleTransporterCode = (e) => {
        if (this.state.isEditMode) return;

        const inputValue = e.target.value;
        const numericInput = inputValue.replace(/[^0-9A-Z]/g, '');

        if (numericInput !== "") {
            if (numericInput.length <= 10) {
                this.setState({ transporter_code: numericInput });
            } else {
                this.setState({ transporter_code: numericInput.substring(0, 10) });
            }
        } else {
            this.setState({ transporter_code: "" });
        }
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <SweetAlert
                        show={this.state.show}
                        type={this.state.basicType}
                        title={this.state.basicTitle}
                        onConfirm={() => {
                            this.setState({ show: false });
                        }}>
                    </SweetAlert>

                </div>
                <div className="row">

                    <div className="col-xl-12 col-md-12 xl-60">
                        <div className="card">
                            <div className="col-lg-12 d-flex justify-content-between align-items-center" style={{ marginTop: "20px" }}>
                                <h4 className="screen-name">Vehicle Master</h4>
                                {/* {Constants.VEHICLES.BTN_ADD === 1 && (
                                    <button className="btn btn-warning pull-right" onClick={this.showAddBlock}>Add Vehicle</button>
                                )} */}
                            </div>
                            <div className="card-body">
                                <AgGrid
                                    rowData={this.state.rowData}
                                    columnDefs={this.state.columnDefs}
                                    frameworkComponents={{ editButtonRenderer: EditButtonRenderer }}
                                    context={{ componentParent: this }}
                                    currentPage={window.location.pathname}
                                    others={""}
                                    saveoptions={"N"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"overlay-part " + (this.state.overlay)} onClick={this.hideAll}></div>

                <div className={"slide-r " + this.state.showAddSlider}>
                    <div className="slide-r-title">
                        <h4 className="">{this.state.sliderText}
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.hideAll}>X</span>
                        </h4>
                    </div>

                    <div className="slide-r-body">
                        <form className="" style={{ padding: "20px" }} onSubmit={this.saveMasterForm}>
                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>Transporter Name<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" name="transporter_name" value={this.state.transporter_name} style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px"
                                }} onChange={this.handleTransporterName} required />
                            </div>
                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>Transporter Code<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" name="transporter_code" value={this.state.transporter_code} style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px"
                                }} onChange={this.handleTransporterCode} required disabled={this.state.isEditMode} />
                            </div>
                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>Vehicle Number<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" name="vehicle_no" value={this.state.vehicle_no} style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px"
                                }} onChange={this.handleVehicleNumberChange} required />
                            </div>
                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>Vehicle Type<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" name="vehicle_type" value={this.state.vehicle_type} style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px"
                                }} onChange={(e) => this.setState({ vehicle_type: e.target.value })} required />
                            </div>
                            <button type="submit" className="btn btn-primary">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Masters;
