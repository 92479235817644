import React, { Component } from "react";
import Constant from "../common/constants";
import redirectURL from "../redirectURL";
import Select from "react-select";
import getToolConfiguration from "./commonFunctions";
// import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import GridButton from "./gridButton";
import $ from "jquery";
import * as XLSX from "xlsx";
import CSVFileValidator from "csv-file-validator";
import SweetAlert from "react-bootstrap-sweetalert";

export default class Ordermanagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      locationNames: [],
      location: "",
      fixedCostTab: "",
      fixedCostData: false,
      unplannedordersData: true,
      unplannedordersTab: "activet",
      plannedordersData: false,
      plannedordersTab: "",
      allordersTab: "",
      allordersData: false,
      order_status: [1,2],
      updatedRowData: [],
      showSlideBlockUpload: "",
      plantCodes: [],
      overly: "show-m",
      show: false,
      basicTitle: "",
      basicType: "default",
      inputXlFile: false,
      loadFile: "",
    };
  }

  async componentDidMount() {
    var curl = window.location.search;
    var ul = curl.split("?");
    // console.log("ul ",ul)
    // let url_type = window.location.href
    let url_type = ul[parseInt(ul.length) - 1];
    // console.log("url_type ", url_type)
    // let path_type = url_type.split('/')
    let path_type = url_type;
    let data_type;
    // let path = path_type[path_type.length-1]
    let path = url_type;

    if (path == "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    await this.setState({
      data_type: data_type,
      loadshow: "show-n",
      overly: "show-n",
    });

    let params = {
      data_type: data_type,
    };
    await getToolConfiguration(params);
    await this.boundTypeLocations(params);
    await this.getOrderManagementData();
  }

  boundTypeLocations = async (param) => {
    var plantCodes = [];
    let locationNames = this.state.locationNames;
    locationNames.push({ label: "ALL", value: "" });
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              locationNames.push({
                value: item.location_code,
                label: item.location_name,
              });
              plantCodes.push(item.location_name);
            });
            await this.setState({
              locationNames: locationNames,
              location: locationNames[0],
              plantCodes,
            });
          }
        }
      });
  };

  locationChanged = async (location) => {
    this.setState({
      location: location,
    });
    await getToolConfiguration({ plant_code: location.value });
  };

  onClickTab = (pageType) => {
    let fullData = this.state.rowData;

    if (pageType == "fixedCostData") {
      var fixedCostTab = "activet";
      var fixedCostData = true;
      var unplannedordersData = false;
      var unplannedordersTab = "";
      var plannedordersData = false;
      var plannedordersTab = "";
      var allordersTab = "";
      var allordersData = false;
      var order_status = [];
    } else if (pageType == "unplannedordersData") {
      var fixedCostTab = "";
      var fixedCostData = false;
      var unplannedordersData = true;
      var unplannedordersTab = "activet";
      var plannedordersData = false;
      var plannedordersTab = "";
      var allordersTab = "";
      var allordersData = false;
      var order_status = [1,2];
      var filteredOrderData = fullData.filter((item) =>
        order_status.includes(item.status)
      );
    } else if (pageType == "plannedordersData") {
      var fixedCostTab = "";
      var fixedCostData = false;
      var unplannedordersData = false;
      var unplannedordersTab = "";
      var plannedordersData = true;
      var plannedordersTab = "activet";
      var allordersTab = "";
      var allordersData = false;
      var order_status = [1,2];
      var filteredOrderData = fullData.filter((item) =>
        !order_status.includes(item.status)
      );
    } else if (pageType == "allordersData") {
      var fixedCostTab = "";
      var fixedCostData = false;
      var unplannedordersData = false;
      var unplannedordersTab = "";
      var plannedordersData = false;
      var plannedordersTab = "";
      var allordersTab = "activet";
      var allordersData = true;
      var order_status = [];
      var filteredOrderData = fullData;
    }
    this.setState({
      fixedCostTab: fixedCostTab,
      fixedCostData: fixedCostData,
      unplannedordersData: unplannedordersData,
      unplannedordersTab: unplannedordersTab,
      plannedordersData: plannedordersData,
      plannedordersTab: plannedordersTab,
      allordersTab: allordersTab,
      allordersData: allordersData,
      order_status: order_status,
      updatedRowData: filteredOrderData,
    });
  };

  getOrderManagementData = () => {
    redirectURL.post("master/getOrderManagementData").then((response) => {
      if (response.data.status == "success") {
        let orData = response.data.orderManagementData;
        let order_status = [1,2];
        let filteredOrData = orData.filter((item) =>
          order_status.includes(item.status)
        );
        this.setState({
          rowData: response.data.orderManagementData,
          updatedRowData: filteredOrData,
        });
      }
    });
  };

  onClickUploadFile = () => {
    this.setState({ showSlideBlockUpload: "slide25", overly: "show-m" });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on Upload Input file Button",
      event: "Opened Upload file Slider",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onClickDownloadSample = () => {
    let logParams = {
      // "location_code" : this.state.location.value,
      // "location_name" : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Run New Plan",
      activity: "clicked on Download Sample Data Button in the slider",
      event: "Sample template has been Downloaded",
      // "data_type" : this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  changeFileHandler = (e) => {
    this.setState({ loadFile: e.target.files[0] });
    document.getElementById("inValidDataInfo").innerHTML = "";

    var extentions = [
      "config",
      "exe",
      "js",
      "jsx",
      "svg",
      "JPG",
      "jpg",
      "jpeg",
      "GIF",
      "gif",
      "PNG",
      "png",
      "BMP",
      "bmp",
      "html",
      "xls",
      "doc",
      "docx",
      "ppt",
      "pptx",
      "pdf",
      "pdfx",
      "html",
      "css",
      "sh",
      "php5",
      "pht",
      "phtml",
      "shtml",
      "asa",
      "cer",
      "asax",
      "swf",
      "xap",
      "php",
      "htaccess",
      "xml",
      "xds",
      "asp",
      "aspx",
      "java",
      "c",
      "c++",
      "ctl",
      "conf",
      "qewe",
      "encrypted",
      "enc",
      "crypted",
      "locked",
      "crypto",
      "crypt",
      "txt",
    ];
    var check = !extentions.includes(e.target.files[0].name);
    if (check) {
      // console.log("hit");
      if (
        e.target.files[0].type === "" ||
        e.target.files[0].type === "text/csv" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // console.log("hit");
        // console.log(fileData);
        // console.log(typeof fileData);

        if (
          e.target.files[0].type === "" ||
          e.target.files[0].type === "text/csv"
        ) {
          this.setState({ inputXlFile: false });
          // console.log('hit')
          this.csvFileValidationsForInputFile(e.target.files[0]);
        } else if (
          e.target.files[0].type === "application/vnd.ms-excel" ||
          e.target.files[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const file = e.target.files[0];
          const reader = new FileReader();
          var jsondata = [];
          reader.onload = async (evt) => {
            const data = evt.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false });
            // console.log(jsonData, "json data");
            // jsonData.map((item)=>{
            var result = this.validateAllValues(jsonData);
            // })
            // Convert JSON data to CSV format with custom date formatting
            var csvData = jsonData.reduce((csv, row, index) => {
              if (index === 0) {
                // Add header row
                csv += Object.keys(row).join(",") + "\n";
              }

              csv +=
                Object.values(row)
                  .map((value) => {
                    // Check if the value is a number and convert it to a number type
                    if (!isNaN(value)) {
                      if (value.includes(".")) {
                        return parseFloat(value);
                      } else {
                        return parseInt(value, 10);
                      }
                    }
                    return `"${value}"`;
                  })
                  .join(",") + "\n";
              return csv;
            }, "");
            // console.log(csvData,'csv')
            await this.setState({
              loadFile: csvData,
              inputXlFile: true,
            });
            if (result) {
              // console.log('hit after vcalidation')
              this.csvFileValidationsForInputFile(csvData);
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle:
            "Please upload file having extensions .csv or .xlsx only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv or .xlsx only.",
      });
    }

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on Choose file Button",
      event: "Chosen Input file ",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  csvFileValidationsForInputFile = (csvData) => {
    var { plantCodes } = this.state;
    const config = {
      headers: [
        {
          name: "Division",
          inputName: "pickup_location_code",
          required: true,
          validate: function (code) {
            return isPlantValid(code, plantCodes);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Customer Code",
          inputName: "drop_location_code",
          required: true,
          validate: function (code) {
            return isDropCodeValid(code, plantCodes);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Customer Name",
          inputName: "drop_location_name",
        },
        {
          name: "D.O. Number",
          inputName: "order_number",
          required: true,
          // unique: true,
          // uniqueError: function (headerName) {
          //   return `${headerName} is not unique`;
          // },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        // {
        //   name: "Invoicing Date",
        //   inputName: "Invoicing Date",
        //   required: true,
        //   requiredError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        //   validate: function (date) {
        //     return isDateFormatValid(date);
        //   },
        //   validateError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        // },
        {
          name: "D.O. Date",
          inputName: "invoicing_date",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (date) {
            return isDateFormatValid(date);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Item Code",
          inputName: "item_code",
        },
        {
          name: "Item Details",
          inputName: "item_details",
        },
        {
          name: "Last Updated Date",
          inputName: "last_updated_date",
        },
        {
          name: "Last Updated by",
          inputName: "last_updated_by",
        },
        {
          name: "D.O. Details",
          inputName: "do_details",
        },
        {
          name: "D.O. Type",
          inputName: "do_type",
        },
        {
          name: "D.O. Type Name",
          inputName: "do_type_name",
        },
        {
          name: "D.O. Quantity",
          inputName: "do_quantity",
        },
        {
          name: "Cancelled Quantity",
          inputName: "cancelled_do_quantity",
        },
        {
          name: "Net D.O. Quantity",
          inputName: "net_do_quantity",
        },
        {
          name: "Executed Qty (Advice)",
          inputName: "executed_do_quantity",
        },
        {
          name: "Balance D.O. Quantity",
          inputName: "balance_do_quantity",
        },
      ],
    };
    CSVFileValidator(csvData, config)
      .then(async (csvData) => {
        this.setState({ loadFile: csvData.data });
        // console.log(csvData,'csvdata')
        document.getElementById("bulkUploadBtn").type = "button";
        document.getElementById("bulkUploadBtn").classList.remove("btn-info");
        document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
        // Assuming 'csvData' is an array of objects representing CSV rows
        var totalData = groupBy(
          csvData.data,
          (rdata) => rdata["pickup_location_code"]
        );
        var plantsWithDrops = {};
        totalData.forEach((rec, key) => {
          var dropCodes = [];
          rec.map((item) => {
            dropCodes.push(item["drop_location_code"]);
            return "";
          });
          // console.log("key", key);
          plantsWithDrops[key] = dropCodes;
        });
        var invalidMsg = [];

        document.getElementById("inValidDataInfo").innerHTML = "";
        if (invalidMsg.length) {
          this.setState({
            basicTitle: "All Drop Locations are Invalid for some Plants",
            basicType: "danger",
            show: true,
          });
        }
        csvData.inValidData = [...csvData.inValidData, ...invalidMsg];
        if (csvData.inValidData.length > 0) {
          $("#uploadFile").val("");
          $("#vehiclesFile").val("");
          document.getElementById("bulkUploadBtn").type = "button";
          document.getElementById("bulkUploadBtn").classList.remove("btn-info");
          document
            .getElementById("bulkUploadBtn")
            .classList.add("btn-secondary");
          let invalidData = csvData.inValidData;
          let element = document.getElementById("inValidDataInfo");
          invalidData.map((item) => {
            let row, column;
            if (item.rowIndex == undefined) {
              row = "NA";
            } else {
              row = item.rowIndex;
            }
            if (item.columnIndex == undefined) {
              column = "NA";
            } else {
              column = item.columnIndex;
            }

            element.innerHTML +=
              "Column : " +
              column +
              "," +
              " Row : " +
              row +
              "--" +
              item.message +
              "<br></br>";
          });
          let throwMsg = 0;
          invalidData.map((itm) => {
            if (itm.columnIndex == 17) {
              throwMsg = 1;
            }
          });
          // if (throwMsg) {
          //   this.setState({
          //     uploadFile: "",
          //     show: true,
          //     basicType: "danger",
          //     basicTitle:
          //       "Please Give Valid Input Data and also Ensure Date is in this Format only DD-MM-YYYY",
          //   });
          // }
        } else {
          document.getElementById("bulkUploadBtn").type = "submit";
          document
            .getElementById("bulkUploadBtn")
            .classList.remove("btn-secondary");
          document.getElementById("bulkUploadBtn").classList.add("btn-info");
        }
      })
      .catch((err) => {
        var errDetails = {
          url: "CSVFileValidator",
          err,
          screen: "Run New Plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  validateAllValues = (jsonData) => {
    // console.log(jsonData,'jasondata')
    var inValidData = [];
    jsonData.map((rec, idx) => {
      if (rec["Division"] == "" || rec["Division"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Division is required",
        });
      }
      if (rec["Customer Code"] == "" || rec["Customer Code"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 2,
          message: "Customer Code is required",
        });
      }
      if (rec["Customer Name"] == "" || rec["Customer Name"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 3,
          message: "Customer Name is required",
        });
      }
      if (rec["D.O. Number"] == "" || rec["D.O. Number"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 4,
          message: "D.O. Number is required",
        });
      }
      if (rec["D.O. Date"] && rec["D.O. Date"].includes("-")) {
        if (rec["D.O. Date"] === "" || rec["D.O. Date"] === undefined) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 5,
            message: "D.O. Date is required",
          });
        } else if (!isDateFormatValid(rec["D.O. Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 5,
            message: "D.O. Date Format is not Valid",
          });
        }
      } else {
        if (rec["D.O. Date"] === "" || rec["D.O. Date"] === undefined) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 5,
            message: "D.O. Date is required",
          });
        } else if (!isDateFormatValid(rec["D.O. Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 5,
            message: "D.O. Date Format is not Valid",
          });
        }
      }

      if (rec["Item Code"] == "" || rec["Item Code"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 6,
          message: "Item Code is required",
        });
      }
      if (rec["Item Details"] == "" || rec["Item Details"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 7,
          message: "Item Details is required",
        });
      }
      if (
        rec["Last Updated Date"] == "" ||
        rec["Last Updated Date"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 8,
          message: "Last Updated Date is required",
        });
      }
      if (rec["Last Updated by"] == "" || rec["Last Updated by"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 9,
          message: "Last Updated by is required",
        });
      }
      if (rec["D.O. Details"] == "" || rec["D.O. Details"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 10,
          message: "D.O. Details is required",
        });
      }
      if (rec["D.O. Type"] == "" || rec["D.O. Type"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 11,
          message: "D.O. Type is required",
        });
      }
      if (rec["D.O. Type Name"] == "" || rec["D.O. Type Name"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 12,
          message: "D.O. Type Name is required",
        });
      }
      if (rec["D.O. Quantity"] == "" || rec["D.O. Quantity"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 13,
          message: "D.O. Quantity is required",
        });
      }
      if (
        rec["Cancelled Quantity"] == "" ||
        rec["Cancelled Quantity"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 14,
          message: "Cancelled Quantity is required",
        });
      }
      if (
        rec["Net D.O. Quantity"] == "" ||
        rec["Net D.O. Quantity"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 15,
          message: "Net D.O. Quantity is required",
        });
      }
      if (
        rec["Executed Qty (Advice)"] == "" ||
        rec["Executed Qty (Advice)"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 16,
          message: "Executed Qty (Advice) is required",
        });
      }
      if (
        rec["Balance D.O. Quantity"] == "" ||
        rec["Balance D.O. Quantity"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 17,
          message: "Balance D.O. Quantity is required",
        });
      }
    });
    document.getElementById("inValidDataInfo").innerHTML = "";
    if (inValidData.length > 0) {
      $("#uploadFile").val("");
      document.getElementById("bulkUploadBtn").type = "button";
      document.getElementById("bulkUploadBtn").classList.remove("btn-info");
      document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
      let invalidData = inValidData;
      let element = document.getElementById("inValidDataInfo");
      invalidData.map((item) => {
        let row, column;
        if (item.rowIndex == undefined) {
          row = "NA";
        } else {
          row = item.rowIndex;
        }
        if (item.columnIndex == undefined) {
          column = "NA";
        } else {
          column = item.columnIndex;
        }

        element.innerHTML +=
          "Column : " +
          column +
          "," +
          " Row : " +
          row +
          "--" +
          item.message +
          "<br></br>";
      });
      let throwMsg = 0;
      invalidData.map((itm) => {
        if (itm.columnIndex == 17) {
          throwMsg = 1;
        }
      });
      if (throwMsg) {
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle: "Please Input Valid Data",
        });
      }
      return false;
    } else {
      document.getElementById("bulkUploadBtn").type = "submit";
      document
        .getElementById("bulkUploadBtn")
        .classList.remove("btn-secondary");
      document.getElementById("bulkUploadBtn").classList.add("btn-info");
      return true;
    }
  };

  uploadOrderInputData = (e) => {
    e.preventDefault();
    let data = this.state.loadFile;
    let module_type = 1;
    let data_type = 2;
    let useremail = localStorage.getItem("email");
    let client = localStorage.getItem("client");
    // let params = new FormData();
    let cleandata = trimValues(data);
    let params = {
      loadFile: cleandata,
      module_type,
      data_type,
      useremail,
      client,
    };
    redirectURL.post("/mlapis/storeObdRawData", params).then(async (res) => {
      if (res.data.status === 1) {
        await this.setState({
          showSlideBlockUpload: "show-n",
          basicTitle: "File Uploaded Successfully",
          basicType: "success",
          show: true,
          loadFile: "",
          loadshow: "show-n",
          overly: "show-n",
        });
      } else {
        this.setState({
          showSlideBlockUpload: "show-n",
          basicTitle: "File Upload Failed",
          basicType: "danger",
          show: true,
          loadFile: "",
          loadshow: "show-n",
          overly: "show-n",
        });
      }
    });
  };

  hideSlideBlock = () => {
    this.setState({
      showSlideBlockUpload: "",
      overly: "show-n",
      loadFile: "",
      showInputSlider: "",
      simulationTime: "",
    });
    $("#uploadFile").val("");
    $("#vehiclesFile").val("");
    document.getElementById("inValidDataInfo").innerHTML = "";

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "Clicked on Cancel Button",
      event: "Closed Upload File Slider",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onclickUploadFileButton = () => {
    $("#uploadFile").val("");
    let logParams = {
      // location_code: this.state.location.value,
      // location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan",
      activity: "clicked on Upload File Option in the slide",
      event: "File Upload slide opened",
      // data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  runPlanFromUI = () =>{
    redirectURL.post("/mlapis/orderManagementAPI");
    this.setState({
      showSlideBlockUpload: "show-n",
      basicTitle: "Plan Triggered",
      basicType: "success",
      show: true,
      loadFile: "",
      loadshow: "show-n",
      overly: "show-n",
    });
  }

  closeAlert = () => {
    this.setState({ show: false, overly: "show-n", loadshow: "show-n" });
  };

  render() {
    var colsDefs = [
      {
        headerName: "Order number",
        field: "order_number",
        width: "130",
      },
      {
        headerName: Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION,
        field: "pickup_location_code",
        width: "180",
      },
      {
        headerName: "Drop Location",
        field: "drop_location_name",
        width: "130",
      },
      {
        headerName: "Item code",
        field: "item_code",
        width: "130",
      },
      {
        headerName: "Item details",
        field: "item_details",
        width: "130",
      },
      {
        headerName: "DO details",
        field: "do_details",
        width: "130",
      },
      {
        headerName: "Last updated by",
        field: "last_updated_by",
        width: "130",
      },
      {
        headerName: "DO Type name",
        field: "do_type_name",
        width: "130",
      },
      {
        headerName: "DO quantity",
        field: "do_quantity",
        width: "130",
      },
      {
        headerName: "Cancelled DO",
        field: "cancelled_do_quantity",
        width: "130",
      },
      {
        headerName: "Net DO",
        field: "net_do_quantity",
        width: "130",
      },
      {
        headerName: "Executed DO",
        field: "executed_do_quantity",
        width: "130",
      },
      {
        headerName: "Balance DO",
        field: "balance_do_quantity",
        width: "130",
      },
      {
        headerName: "Uploaded by",
        field: "useremail",
        width: "130",
      },
      {
        headerName: "Uploaded Date",
        field: "created_on",
        width: "130",
      },

      // {
      //     headerName:"Actions",
      //     field:"cluster",
      //     width:"80",
      //     pinned:'left',
      //     params:{buttonName:'Edit',
      //             onClickFunction:this.editMaterialConfiguration},
      //     cellRendererSelector:function(params){

      //         var rendComponent = {
      //             component: "GridButton"
      //         };
      //         return rendComponent

      //     },
      // },
      // {
      //     headerName:"",
      //     field:"cluster",
      //     width:"80",
      //     pinned:'left',
      //     params:{buttonName:'Delete',iconName:"fa fa-trash",
      //             onClickFunction:this.deleteFixedVehicleCostDetails},
      //     cellRendererSelector:function(params){

      //         var rendComponent = {
      //             component: "GridButton"
      //         };
      //         return rendComponent

      //     },
      // },
    ];

    var templatePath = require("../../assets/json/cpil_new_input_data_template.csv");
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    var plant_wise_freight_configuration = 0;
    var plant_wise_freight_fixed_cost = 0;
    var plant_wise_freight_vehicle_type_variable_cost = 0;
    var plant_wise_freight_city_level_vehicle_cost = 0;
    var plant_wise_freight_zone_level_cost = 0;

    if (toolConfig != undefined && toolConfig != "") {
      if (toolConfig.length > 0) {
        plant_wise_freight_configuration =
          toolConfig[0].plant_wise_freight_configuration;
        plant_wise_freight_fixed_cost =
          toolConfig[0].plant_wise_freight_fixed_cost;
        plant_wise_freight_vehicle_type_variable_cost =
          toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
        plant_wise_freight_city_level_vehicle_cost =
          toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
        plant_wise_freight_zone_level_cost = toolConfig[0].zone_level_cost;
      }
    }
    const {
      location,
      locationNames,
      fixedCostData,
      fixedCostTab,
      unplannedordersData,
      unplannedordersTab,
      plannedordersData,
      plannedordersTab,
      allordersData,
      allordersTab,
    } = this.state;

    var userCheck = localStorage.getItem("role") === "admin";

    let data = this.state.updatedRowData;
    let locValue = this.state.location.label;

    let filteredData;
    if (locValue == "ALL") {
      filteredData = data;
    } else {
      filteredData = data.filter(
        (item) => item.pickup_location_code == locValue
      );
    }

    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="">
          <div className="row mb-5p pt-5p">
            <div className="col-sm-12">
              <h5 className="fbold  d-flex justify-content-between">
                <span> Order Management</span>
              </h5>
            </div>
          </div>
          <div className="row">
          <div className="col-md-6 col-sm-6">
              <ul className="d-tabs">
                {plant_wise_freight_fixed_cost == 1 ? (
                  <li
                    onClick={this.onClickTab.bind(this, "fixedCostData")}
                    className={"lttabs " + fixedCostTab}
                  >
                    <button type="button" className="btn">
                      Fixed Vehicle Cost
                    </button>
                  </li>
                ) : (
                  ""
                )}

                {/* <li onClick={this.onClickTab.bind(this,"customercostData")} className={"lttabs "+(customercostTab)} >
                                <button type="button" className="btn">Cost Details</button>
                            </li> */}
                <li
                  onClick={this.onClickTab.bind(this, "unplannedordersData")}
                  className={"lttabs " + unplannedordersTab}
                >
                  <button type="button" className="btn">
                    Unplanned Orders
                  </button>
                </li>
                <li
                  onClick={this.onClickTab.bind(this, "plannedordersData")}
                  className={"lttabs " + plannedordersTab}
                >
                  <button type="button" className="btn">
                    Planned Orders
                  </button>
                </li>
                <li
                  onClick={this.onClickTab.bind(this, "allordersData")}
                  className={"lttabs " + allordersTab}
                >
                  <button type="button" className="btn">
                    All Orders
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group row">
                <label className="col-form-label col-sm-4 f12 textAlignRight">
                  {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}
                </label>
                <div className="col-sm-8">
                  <Select
                    placeholder={"Select"}
                    onChange={this.locationChanged}
                    name="location"
                    value={location}
                    options={locationNames}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-md-2 textAlignRight">
            {unplannedordersData ? 
              <span className='float-right'>
                  <a href="javascript:;"  onClick={this.runPlanFromUI} className="btn btn-danger f12 mr-10p nbold">Run Plan</a>
              </span>
              :<span className=""></span>}
              <span className=" float-right mr-5p">
                <button
                  onClick={this.onClickUploadFile}
                  className="btn btn-warning f12"
                  style={{ fontWeight: "bold", borderRadius: "2px" }}
                >
                  Upload Input File
                </button>
              </span>
            </div>
          </div>
          <div
            id="myGrid"
            style={{ width: "100%", height: "70vh" }}
            className="ag-theme-balham dropdown"
          >
            <AgGridReact
              // modules={this.state.modules}
              rowData={filteredData}
              columnDefs={colsDefs}
              gridOptions={{ context: { componentParent: this } }}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              paginationPageSize={this.state.paginationPageSize}
              pagination={true}
              statusBar={this.state.statusBar}
              sideBar={this.state.sideBar}
              enableRangeSelection={true}
            />
          </div>

          {/* <div className={"loader " + this.state.loadshow}></div> */}
          <div
            style={{ color: "black" }}
            className={"sliderBlock2 " + this.state.showSlideBlockUpload}
          >
            <h5 className="crd-bg p-10p">Upload Input File</h5>
            <div className="row">
              <form onSubmit={this.uploadOrderInputData}>
                <div className="col-sm-12">
                  <div className="form-group ml-2">
                    <a
                      className="btn btn-warning"
                      href={templatePath}
                      target="_blank"
                      onClick={this.onClickDownloadSample}
                    >
                      Download Sample Template
                    </a>
                  </div>

                  <div className="form-group col-sm-12 mb-10p">
                    <label style={{ color: "#000" }}>Upload File</label>
                    <input
                      type="file"
                      name="uploadFile"
                      id="uploadFile"
                      onChange={this.changeFileHandler}
                      className="form-control"
                      onClick={this.onclickUploadFileButton}
                      required
                    />
                  </div>

                  <div className="form-group col-sm-12 note-font">
                    Disclaimer : Date Format for D.o Date s/b DD-MM-YYYY
                  </div>
                </div>

                <div className="form-group col-sm-12 mb-20p ml-3 mt-40p">
                  <div className="mt-10p">
                    <button
                      // disabled
                      type="submit"
                      id="bulkUploadBtn"
                      className="btn btn-info"
                    >
                      Upload
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={this.hideSlideBlock.bind(this)}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
                <div
                  id="inValidDataInfo"
                  className="col-sm-12 pl-15p"
                  style={{ color: "red" }}
                ></div>
              </form>
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.hideSlideBlock.bind(this)}
        ></div>
      </>
    );
  }
}

function isDropCodeValid(code, plantCodes) {
  if (plantCodes.includes(code)) {
    return false;
  } else {
    return true;
  }
}

function isPlantValid(code, plantCodes) {
  if (plantCodes.includes(code)) {
    return true;
  } else {
    return false;
  }
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function trimValues(arr) {
  return arr.map((obj) => {
    const trimmedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        trimmedObj[key] = obj[key].trim();
      }
    }
    return trimmedObj;
  });
}

function isDateFormatValid(date) {
  const dateParts = date.split("-");
  if (dateParts.length !== 3) {
    return false;
  }

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  const formattedDate = new Date(year, month - 1, day);

  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}
