import './ToggleSwitch.css'

export const ToggleSwitch = ({ toggled, onClick }) => {

    return (
        <label className='toggle-label'>
            <input className='toggle-checkbox' readOnly type="checkbox" checked={toggled} onClick={onClick} />
            <span className='toggle-span' />
        </label>
    )
}
