import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TrackingShipGreen from "../../assets/icons/tracking-ship-green.svg";
import TrackingShipRed from "../../assets/icons/tracking-ship-red.svg";
import TrackingShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import FlagGrey from "../../assets/icons/flag-grey.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import ExportIcon from "../../assets/icons/export-arrow.svg";
import infoIcon from "../../assets/icons/info.png";
import ImportIcon from "../../assets/icons/import-arrow.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CompletedIcon from "../../assets/icons/completed-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import RefreshIcon from "../../assets/icons/refresh-icon.svg";
import { countries } from "country-flags-svg";
import Modal from "react-responsive-modal";
import redirectURL from "../redirectURL";
import moment from "moment";
import copyIcon from "../../assets/icons/copyIcon.png";
import $ from "jquery";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./trackingTile.css";
import ModalEditShipment from "./ModalEditShipment";
import EmissionCloud from '../../assets/icons/co2-1.svg'
import InfoIcon from '../../assets/icons/info.png';

const TrackingTile2 = (props) => {
  console.log("TrackingTile2", props);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useState({
    openCloseTrackingModal: false,
    openEditShipmentDetailsModal: false,
    openDeleteModal: false,
    openShareModal: false,
    modalOpened: "",
    trackingDeletionReason: "",
    shipmentToBeDeleted: "",
    sharingType: "",
    tracking_url: "",
    tracking_value: "",
    emailID: "",
    shareEmailButton: "disabled",
    responseMessage: "",
    responseMessageClass: "",
    tabType: "",
    trackingToBeClosedType: "",
    trackingToBeClosedTypeValue: "",
    forceCloseTrackingReason: "",
    info_msg: "",
    mostDelayedContainerEmission: "",
  });

  // useEffect(()=>{
  //   $("#info_card").text = state.info_msg
  // },[state.info_msg])

  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return {
        img: srcFlag[0]?.flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };

  const closeTrackingModalOpen = (serialType, serialNumber) => {
    setState({
      ...state,
      openCloseTrackingModal: true,
      modalOpened: "closeTracking",
      trackingToBeClosedType: serialType,
      trackingToBeClosedTypeValue: serialNumber,
    });
  };

  const editShipmentDetails = (bookingNo) => {
    console.log(bookingNo);
    setState({
      ...state,
      openEditShipmentDetailsModal: true,
      modalOpened: "editTracking",
    });
  };

  const openDeleteTrackingModal = (tabType, trackingNum) => {
    setState({
      ...state,
      openDeleteModal: true,
      modalOpened: "deleteTracking",
      shipmentToBeDeleted: trackingNum,
      tabType: tabType,
    });
  };

  const deleteTracking = () => {
    // console.log(state.shipmentToBeDeleted);
    let payload = {};
    if (state.tabType === "BL") {
      payload = {
        mbl_number: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
      };
    } else {
      payload = {
        shipment_no: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
      };
    }

    if (state.shipmentToBeDeleted && state.trackingDeletionReason) {
      redirectURL
        .post("/exim/deleteTracking", payload)
        .then((resp) => {
          if (resp.data.status === "success") {
            closeModal();
            props.trackingDataFun();
            setState({
              ...state,
              trackingDeletionReason: "",
              shipmentToBeDeleted: "",
            });
          }
        })
        .catch((err) => {
          console.log("deleteTracking err", err);
        });
    } else {
    }
  };

  const refreshData = () => {
    props.trackingDataFun();
  };
  function getValue(inputString) {
    var words = inputString.slice(0, 14) + "...";
    return words;
  }
  const forceCloseTracking = () => {
    console.log(
      state.trackingToBeClosedTypeValue,
      state.forceCloseTrackingReason,
      state.trackingToBeClosedType
    );

    let payload = {
      [state.trackingToBeClosedType]: state.trackingToBeClosedTypeValue, //shipment_no or mbl_number
      forceCloseTrackingReason: state.forceCloseTrackingReason,
    };

    redirectURL
      .post("/exim/forceCloseTracking", payload)
      .then((resp) => {
        if (resp.data.status === "success") {
          closeModal();
          props.trackingDataFun();
        }
      })
      .catch((err) => {
        console.log("forceCloseTracking err", err);
      });
  };
  const handleCopyClick = () => {
    $("#clipboardMsg").removeClass("show-n").addClass("clipboard-msg");
    let url = document.getElementById("trackingURL").value;
    navigator.clipboard.writeText(url);
    setInterval(() => {
      $("#clipboardMsg").addClass("show-n").removeClass("clipboard-msg");
    }, 2000);
  };

  const shareTrackingDetails = (sharingType, tracking_value, tracking_url) => {
    // "Container",url, container_no
    // tracking_type, tracking_value, tracking_url
    let live_url = `http://164.52.218.255:3006/${tracking_url}`;
    setState({
      ...state,
      openShareModal: true,
      modalOpened: "shareTracking",
      sharingType,
      tracking_url: live_url,
      tracking_value,
    });
  };

  const closeModal = () => {
    if (state.modalOpened === "closeTracking") {
      setState({
        ...state,
        openCloseTrackingModal: false,
        trackingToBeClosedType: "",
        forceCloseTrackingReason: "",
        trackingToBeClosedTypeValue: "",
      });
    } else if (state.modalOpened === "editTracking") {
      setState({
        ...state,
        openEditShipmentDetailsModal: false,
      });
    } else if (state.modalOpened === "deleteTracking") {
      setState({
        ...state,
        openDeleteModal: false,
        trackingDeletionReason: "",
        shipmentToBeDeleted: "",
      });
    } else if (state.modalOpened === "shareTracking") {
      setState({
        ...state,
        openShareModal: false,
      });
    }
  };

  const sendTrackingUrl = () => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (emailPattern.test(state.emailID)) {
      //   sharingType, tracking_value, tracking_url, mail

      let payload = {
        sharingType: state.sharingType,
        tracking_value: state.tracking_value,
        tracking_url: state.tracking_url,
        mail: state.emailID,
      };

      redirectURL
        .post("/exim/shareTrackingURL", payload)
        .then((resp) => {
          console.log("shareTrackingURL data", resp.data);
          if (resp.data.status === "success") {
            setState({
              ...state,
              responseMessage: "Mail has been sent",
              responseMessageClass: "txt-info",
            });

            setTimeout(() => {
              setState({
                ...state,
                openShareModal: false,
                responseMessage: "",
                responseMessageClass: "",
                sharingType: "",
                tracking_url: "",
                tracking_value: "",
                emailID: "",
              });
            }, 2000);
          }
        })
        .catch((err) => {
          console.log("shareTrackingURL err", err);
        });

      //     sharingType,
      //   tracking_url,
      //   tracking_value,
    } else {
      setState({
        ...state,
        responseMessage: "Invalid Email ID",
        responseMessageClass: "txt-danger",
      });
      setTimeout(() => {
        setState({
          ...state,
          responseMessage: "",
          responseMessageClass: "",
        });
      }, 2000);
    }
  };

  const changeTextStyle = () => {
    // $("#info_card").text = state.info_msg
  };

  return (
    <>
      <div
      // className={`${props.tabActive === "BL/Booking" ? "show-m" : "show-n"}`}
      >
        {
          (!props.bl_data || props.bl_data.length === 0 || props.bl_data === undefined) ?
            <>
              <div
                key={""}
                className="card py-2 pr-3"
                style={{
                  borderRadius: "18px",
                  textAlign: "center",
                  height: "50px",
                  marginTop: "40px",
                  marginBottom: "100px"
                }}
              >
                No data found.
              </div>
            </> :
            <>
              {props.bl_data.map((each, index) => {
                // let {shipment_no, sub_status1, mbl_number } = each;
                let {
                  booking_no,
                  container_no,
                  discharge_port_code,
                  last_event_data,
                  last_port_ata,
                  last_port_eta,
                  loading_port_atd,
                  loading_port_code,
                  loading_port_etd,
                  mbl_number,
                  movement_type,
                  shipment_no,
                  shipping_line,
                  tracking_updated_at,
                  total_containers,
                  url,
                  delay_message,
                  delay_status, // ["On Time", "Early", "Delay"]
                  last_3_events,
                  last_port,
                  loading_port, // current_port(comes from last_event_location) === last_port, it does mean that it reached the port
                } = each;

                let info_msg = "|";
                last_3_events.forEach((eachEve) => {
                  let { event_name, actual_time } = eachEve;
                  let time = moment(actual_time, "YYYY-MM-DD hh:mm:ss A").utcOffset(0).format(
                    "Do MMM, YYYY"
                  );
                  let eachMsg = `| ${event_name}: ${time} |`;
                  info_msg = `${info_msg}\n${eachMsg}\n`;
                  // info_msg = info_msg + "/n"
                });
                info_msg = info_msg + "|";

                // setState({
                //   ...state,
                //   info_msg
                // })
                // document.getElementById("info_card").innerText = info_msg;

                let image = 'NA';
                let imgStatus = true;
                try {
                  let MyModule = require(`../../../public/static/images/Gocomet/${shipping_line}.png`)
                } catch (err) {
                  imgStatus = false
                }

                let shipIcon;
                let progressLine;
                let textDecoration;
                let eventLabel = each.hasOwnProperty("force_close")
                  ? each.force_close
                  : "NA";
                if (eventLabel === 1) {
                  eventLabel = "Force Closed";
                } else if (each.status === "COMPLETED") {
                  eventLabel =
                    each.events_data[each.events_data.length - 1].event_name;
                } else {
                  eventLabel = last_event_data[0].event_name;
                }
                if (delay_status === "On Time") {
                  shipIcon = TrackingShipBlue;
                  progressLine = "tracking-progress-blue-line";
                  textDecoration = "ontime-text";
                } else if (delay_status === "Early") {
                  shipIcon = TrackingShipGreen;
                  progressLine = "tracking-progress-green-line";
                  textDecoration = "early-text";
                } else {
                  //Delay
                  shipIcon = TrackingShipRed;
                  progressLine = "tracking-progress-red-line";
                  textDecoration = "delay-text";
                }

                let updated_at = "";
                let hours = moment().diff(
                  moment(tracking_updated_at).utcOffset("-05:30"),
                  "hours"
                );

                if (hours > 24) {
                  let dayTime = moment().diff(
                    moment(tracking_updated_at).utcOffset("-05:30"),
                    "days"
                  );
                  if (dayTime === 1) {
                    updated_at = `Updated ${dayTime} day ago`;
                  } else {
                    updated_at = `Updated ${dayTime} days ago`;
                  }
                } else {
                  if (hours === 1) {
                    updated_at = `Updated ${hours} hour ago`;
                  } else {
                    updated_at = `Updated ${hours} hours ago`;
                  }
                }

                let { event_group, status, location } = last_event_data[0];

                let imgPos = "0%";
                let barPos = "0%";

                if (status != "COMPLETED") {
                  if (event_group === "origin") {
                    barPos = "0%";
                    imgPos = "0%";
                  } else if (
                    event_group === "in_transit" ||
                    event_group === "transshipment"
                  ) {
                    barPos = "33%";
                    imgPos = "31%";
                  } else if (event_group === "destination") {
                    barPos = "66%";
                    imgPos = "62%";
                  } else {
                    barPos = "100%";
                    imgPos = "95%";
                  }
                }
                // console.log(each,"eacheacheacheacheacheach");
                if (
                  status === "COMPLETED" ||
                  last_port === location ||
                  (each.hasOwnProperty("force_close") ? each.force_close : "") === 1
                ) {
                  barPos = "100%";
                  imgPos = "95%";
                }
                let s_etd = "NA";
                let a_etd = "NA";
                let p_eta = "NA";
                let a_eta = "NA";
                let delay_text = "";
                let delay_state = "";

                // if (loading_port_etd) {
                //   s_etd = moment(loading_port_etd).format("Do MMM, YYYY");
                // }
                // if (loading_port_atd) {
                //   a_etd = moment(loading_port_atd).format("Do MMM, YYYY");
                // }
                // if (last_port_eta) {
                //   p_eta = moment(last_port_eta).format("Do MMM, YYYY");
                // }
                // if (last_port_ata) {
                //   a_eta = moment(last_port_ata).format("Do MMM, YYYY");
                // }

                //const aData=actualDuration.find(item=>item.container_no===container_no)
                // console.log("aData.loading_port_etd ", aData)
                if (each !== undefined) {
                  if (each.loading_port_etd) {
                    s_etd = moment(each.loading_port_etd).utcOffset(0).format("Do MMM, YYYY");
                  }

                  if (each.loading_port_atd) {
                    a_etd = moment(each.loading_port_atd).utcOffset(0).format("Do MMM, YYYY");
                  }

                  if (each.last_port_eta) {
                    p_eta = moment(each.last_port_eta).utcOffset(0).format("Do MMM, YYYY");
                  }

                  if (each.last_port_ata) {
                    a_eta = moment(each.last_port_ata).utcOffset(0).format("Do MMM, YYYY");
                  }

                  try {
                    const delay = moment(each.last_port_eta).diff(
                      moment(each.last_port_ata)
                    );
                    const duration = moment.duration(delay);
                    const days = duration.days();
                    const hours2 = duration.hours();
                    if (each.last_port_eta === "" || each.last_port_ata === "") {
                      delay_state = "NA";
                      delay_text = "NA";
                    }
                    if (days < 0) {
                      if (Math.abs(days) === 1) {
                        delay_text += "Delay by " + Math.abs(days) + " day";
                        delay_state = "Delay";
                      } else {
                        delay_text += "Delay by " + Math.abs(days) + " days";
                        delay_state = "Delay";
                      }
                      if (hours2 !== 0)
                        delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)";
                    } else if (days > 0) {
                      if (Math.abs(days) === 1) {
                        delay_text += "Early by " + Math.abs(days) + " day";
                        delay_state = "Early";
                      } else {
                        delay_text += "Early by " + Math.abs(days) + " days";
                        delay_state = "Early";
                      }
                      if (hours2 !== 0)
                        delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)";
                    } else {
                      if (days === 0) {
                        if (hours2 < 0) {
                          delay_text += "Delay by " + Math.abs(hours2) + " hour(s)";
                          delay_state = "Delay";
                        } else if (hours2 > 0) {
                          delay_text += "Early by " + Math.abs(hours2) + " hour(s)";
                          delay_state = "Early";
                        } else if (hours2 === 0) delay_text += "On Time";
                      }
                    }
                  } catch (err) {
                    console.log(err, "errorrrrrrr");
                  }
                  if (each.hasOwnProperty("force_close") && each.force_close === 1) {
                    delay_text = "Force Closed"
                    delay_state = "Delay"
                  }
                  if (delay_state === "Early") {
                    shipIcon = TrackingShipGreen;
                    progressLine = "tracking-progress-green-line";
                    textDecoration = "early-text";
                  } else if (delay_state === "Delay") {
                    //Delay
                    shipIcon = TrackingShipRed;
                    progressLine = "tracking-progress-red-line";
                    textDecoration = "delay-text";
                  } else {
                    shipIcon = TrackingShipBlue;
                    progressLine = "tracking-progress-blue-line";
                    textDecoration = "ontime-text";
                  }
                }

                let srcIcon = {};
                if (loading_port_code) {
                  srcIcon = getFlagIcon(loading_port_code.slice(0, 2));
                }

                let dstnIcon = {};
                if (discharge_port_code) {
                  dstnIcon = getFlagIcon(discharge_port_code.slice(0, 2));
                }
                return (
                  <div
                    key={shipment_no}
                    className="card py-2 pr-3 d-flex flex-row justify-content-between align-items-center mx-3"
                    style={{
                      borderRadius: "18px",
                      //   cursor: "pointer"
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-start">
                      {movement_type === "Import" ? (
                        <>
                          <div
                            className="d-flex flex-column justify-content-center align-items-center p-2"
                            style={{ background: "#407bff2e" }}
                          >
                            <img
                              style={{
                                width: "10px",
                                marginBottom: "5px",
                              }}
                              src={ImportIcon}
                            />
                            <div
                              style={{
                                color: "#407bff",
                                fontSize: "10px",
                                fontWeight: 700,
                              }}
                            >
                              IM
                            </div>

                            {/* <div style={{ color: "white", fontSize: "10px", fontWeight: 700 }}>{props.trackingType}</div> */}
                          </div>
                        </>
                      ) : movement_type === "Export" ? (
                        <>
                          <div
                            className="d-flex flex-column justify-content-center align-items-center p-2"
                            style={{ background: "#407bff" }}
                          >
                            <img
                              style={{
                                width: "10px",
                                marginBottom: "5px",
                              }}
                              src={ExportIcon}
                            />
                            <div
                              style={{
                                color: "white",
                                fontSize: "10px",
                                fontWeight: 700,
                              }}
                            >
                              EX
                            </div>

                            {/* <div style={{ color: "white", fontSize: "10px", fontWeight: 700 }}>{props.trackingType}</div> */}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="" style={{ width: "7%" }}>
                      {(imgStatus) ?
                        <img
                          src={require(`../../../public/static/images/Gocomet/${shipping_line}.png`)}
                          alt={`${shipping_line}`}
                          style={{ width: "100%" }}
                        /> :
                        <CustomSVG text={shipping_line} height="5vw" width="7vw" />
                      }
                      <br />
                      {props.tabActive === "BL/Booking" ? (
                        <label for="" style={{ whiteSpace: "nowrap" }}>{total_containers} Container(s)</label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex flex-column justify-content-start eachCard mr-5">
                      <div className="d-flex flex-row justify-content-between">
                        <div style={{ color: "#858796", fontSize: "10px" }}>
                          BL No:
                        </div>
                        {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            fontWeight: 600,
                            marginLeft: "8px",
                          }}
                        >
                          {mbl_number!=""?mbl_number:"NA"}
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <div style={{ color: "#858796", fontSize: "10px" }}>
                          Booking No:
                        </div>
                        {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            fontWeight: 600,
                            marginLeft: "8px",
                          }}
                        >
                          {booking_no!=""?booking_no:"NA"}
                        </div>
                      </div>
                      {props.tabActive === "Container" ? (
                        <div className="d-flex flex-row justify-content-between">
                          <div style={{ color: "#858796", fontSize: "10px" }}>
                            Container No:
                          </div>
                          <div
                            style={{
                              fontSize: "10px",
                              fontWeight: 600,
                              marginLeft: "8px",
                            }}
                          >
                            {container_no == "" ? "NA" : container_no}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex flex-row justify-content-between"></div>
                    </div>

                    <div className="d-flex flex-row justify-content-center align-items-center cursor-class tilebl">
                      <div className="align-items-center ml-1 portpos">
                        <div className="row textAlignCenter">
                          <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                            <img className="portind" src={LocationBlue}></img>
                            <br />
                            {srcIcon.hasOwnProperty("img") &&
                              srcIcon.hasOwnProperty("name") ? (
                              <>
                                <img
                                  src={srcIcon?.img}
                                  alt="SRC"
                                  className="flag_class"
                                  style={{
                                    transform: `${srcIcon.name === "China" ? "rotateX(45deg)" : ""
                                      }`,
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            <br />
                            {(loading_port !== "" &&
                              loading_port !== undefined &&
                              loading_port !== null
                              ? loading_port.length
                              : 0) +
                              (srcIcon.name != "" &&
                                srcIcon.name !== undefined &&
                                srcIcon.name !== null
                                ? srcIcon.name.length
                                : 0) >
                              27 ? (
                              <>
                                <Tooltip id="info_name" />
                                <div
                                  className="col-12 portxt info_name"
                                  data-tooltip-id="info_name"
                                  data-tooltip-content={
                                    loading_port +
                                    ", " +
                                    (srcIcon.hasOwnProperty("name")
                                      ? srcIcon.name
                                      : "")
                                  }
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    marginTop: "0px",
                                  }}
                                >
                                  {getValue(loading_port)}
                                  <br />
                                  {getValue(
                                    srcIcon.hasOwnProperty("name") ? srcIcon.name : ""
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="col-12 portxt"
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    marginTop: "0px",
                                  }}
                                >
                                  {loading_port}
                                  <br />
                                  {srcIcon.hasOwnProperty("name")
                                    ? srcIcon.name
                                    : ""}
                                </div>
                              </>
                            )}
                          </div>
                          {/* <div className="row">
                        <div className="col-12">
                        <img
                          src={srcIcon?.img}
                          alt="SRC"
                          className="flag_class portsimg"
                          style={{
                            transform: `${
                              srcIcon.name === "China" ? "rotateX(45deg)" : ""
                            }`,
                          }}
                        />
                        </div>
                        <div
                          className="portxt col-12"
                        >
                          <div className="portTxtpos">
                          {loading_port}, {srcIcon?.name}
                          </div>
                        </div>
                      </div> */}
                        </div>
                      </div>

                      <div className="current-activity">
                        <span
                          className={`${textDecoration}`}
                          style={{
                            fontSize: "10px",
                            padding: "2px 5px",
                            position: "relative",
                            top: "-11px",
                            borderRadius: "3px",
                            fontWeight: "bolder",
                            left: "-53px",
                          }}
                        >
                          {eventLabel}
                        </span>
                      </div>
                      <div className="d-flex flex-row tracking-background-progress">
                        <div
                          // style={{ height: "2px", backgroundColor: "#407bff" }}
                          style={{ "--highlight": barPos }}
                          // className="tracking-progress-green-line"
                          className={progressLine}
                        ></div>
                        <div
                          className="tracking-image-background"
                          style={{ "--highlight2": imgPos }}
                        // style={{ background: "white", position: "absolute", left: "100px", bottom: "-5px", paddingLeft: "5px", paddingRight: "5px" }}
                        >
                          <img
                            className=""
                            // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                            // src={TrackingShipGreen}
                            src={shipIcon}
                            width="23px"
                          // ["On Time", "Early", "On Time"]
                          ></img>
                        </div>
                      </div>
                      {/* <div className="d-flex flex-row" style={{ position: "absolute", top: "20px", left: "95px" }}>
                        <img
                          className=""
                          // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                          // src={TrackingShipGreen}
                          src={EmissionCloud}
                          width="40px"
                        // ["On Time", "Early", "On Time"]
                        ></img>
                        <span style={{ marginLeft: "10px", lineHeight: "30px", fontSize: "12px" }}> */}
                          {/* {props.most_delayed_container_emission ? parseFloat(props.most_delayed_container_emission).toFixed(2) + "kg": "0kg" } */}
                          {/* {!(props.hasOwnProperty("most_delayed_container_emission")) ? (props.bl_data ? (props.bl_data[0].emissions.co2e.value == "" ? "NA" : parseFloat(props.bl_data[0].emissions.co2e.value).toFixed(2) + "Kg") : "NA") : (
                            props.most_delayed_container_emission ? parseFloat(props.most_delayed_container_emission).toFixed(2) + " " + each.emissions.co2e.unit : "NA")
                          }
                        </span>

                        <Tooltip id="info_card_tracking" style={{ zIndex: "9999" }} />
                        <img src={InfoIcon} class="info_card_tracking" data-tooltip-id="info_card_tracking" alt="" width="15px" data-tooltip-content={"Port to Port emission calculation based on GLEC framework using default factors."} /> */}
                        {/* <div class="info_card_tracking" data-tooltip-id="info_card_tracking" data-tooltip-content={info_msg}>
                      <img src={InfoIcon} alt="" width="15px" />
                    </div> */}

                      {/* </div> */}
                      <div className="d-flex flex-column justify-content-center align-items-center ml-1 portdes">
                        <div className="row textAlignCenter location-flag">
                          <div className="col-12">
                            <img src={(each.status === "COMPLETED") ? FlagBlue : FlagGrey} className="desflg"></img>
                            <br />
                            {dstnIcon.hasOwnProperty("img") &&
                              dstnIcon.hasOwnProperty("name") ? (
                              <>
                                <img
                                  src={dstnIcon?.img}
                                  alt="DSTN"
                                  className="flag_class"
                                  style={{
                                    transform: `${dstnIcon.name === "China"
                                        ? "rotateX(45deg)"
                                        : ""
                                      }`,
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                            {/* <img src={props.destinationFlag}></img> */}
                            {/* <img
                          src={dstnIcon?.img}
                          alt="DSTN"
                          className="flag_class portsimg"
                          style={{
                            transform: `${
                              dstnIcon.name === "China" ? "rotateX(45deg)" : ""
                            }`,
                          }}
                        /> */}
                            {/* <div style={{ fontSize: "11px" }}>{props.destination}</div> */}
                            {(last_port != "" && last_port != undefined
                              ? last_port.length
                              : 0) +
                              (dstnIcon.name != "" && dstnIcon.name != undefined
                                ? dstnIcon.name.length
                                : 0) >
                              27 ? (
                              <>
                                <Tooltip id="info_name" />
                                <div
                                  className="col-12 portxt"
                                  class="info_name"
                                  data-tooltip-id="info_name"
                                  data-tooltip-content={
                                    last_port +
                                    ", " +
                                    (dstnIcon.hasOwnProperty("name")
                                      ? dstnIcon.name
                                      : "")
                                  }
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    marginTop: "0px",
                                  }}
                                >
                                  {getValue(last_port)}
                                  <br />
                                  {getValue(
                                    dstnIcon.hasOwnProperty("name")
                                      ? dstnIcon.name
                                      : ""
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="col-12 portxt"
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    marginTop: "0px",
                                  }}
                                >
                                  {last_port}
                                  <br />
                                  {(dstnIcon.hasOwnProperty("name")
                                    ? dstnIcon.name
                                    : "")
                                  }
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex flex-column justify-content-start"
                    //style={{ width: "171.575px" }}
                    >
                      <div
                        className="d-flex flex-row justify-content-between mb-1"
                        title="Estimated Time of Departure"
                      >
                        <div style={{ color: "#858796", fontSize: "10px" }}>ETD:</div>
                        {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.scheduledETD}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            marginLeft: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {s_etd}
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between mb-1"
                        title="Actual Time of Departure"
                      >
                        <div style={{ color: "#858796", fontSize: "10px" }}>ATD:</div>
                        {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETD}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            marginLeft: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {a_etd}
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between mb-1"
                        title="Estimated Time of Arrival"
                      >
                        <div style={{ color: "#858796", fontSize: "10px" }}>ETA:</div>
                        {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.plannedETA}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            marginLeft: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {p_eta}
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between"
                        title="Actual Time of Arrival"
                      >
                        <div style={{ color: "#858796", fontSize: "10px" }}>ATA:</div>
                        {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETA}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            marginLeft: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {a_eta}
                        </div>
                      </div>
                    </div>
                    {/* <Tooltip id="info_card" />
              <div
                class="info_card"
                data-tooltip-id="info_card"
                data-tooltip-content={info_msg}
              >
                <img src={infoIcon} alt="" width="15px" />
              </div> */}
                    <div className="d-flex flex-column justify-content-between">
                      <div style={{ width: "120px", textAlign: "center" }}>
                        <div
                          className={`${textDecoration} py-1 px-3`}
                          style={{
                            fontSize: "10px",
                            fontWeight: 600,
                            borderRadius: "14px",
                          }}
                        >
                          {delay_text}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
        }
      </div>

      {/* Container Tile */}
      <div
        className={`${props.tabActive === "Container" ? "show-m" : "show-n"}`}
      >
        {
          (!props.bl_data || props.bl_data.length === 0 || props.bl_data === undefined) ?
            <>
              <div
                key={""}
                className="card py-2 pr-3"
                style={{
                  borderRadius: "18px",
                  textAlign: "center",
                  height: "50px",
                  marginTop: "40px",
                  marginBottom: "100px"
                }}
              >
                No data found.
              </div>
            </> :
            <>
              {props.container_data.map((each, index) => {
                // let {shipment_no, sub_status1, mbl_number } = each;
                let {
                  booking_no,
                  container_no,
                  discharge_port_code,
                  last_event_data,
                  last_port_ata,
                  last_port_eta,
                  loading_port_atd,
                  loading_port_code,
                  loading_port_etd,
                  mbl_number,
                  movement_type,
                  shipment_no,
                  shipping_line,
                  tracking_updated_at,
                  url,
                  delay_message,
                  delay_status, // ["On Time", "Early", "Delay"]
                  last_port,
                  loading_port, // current_port(comes from last_event_location) === last_port, it does mean that it reached the port
                } = each;

                let shipIcon;
                let progressLine;
                let textDecoration;

                if (delay_status === "On Time") {
                  shipIcon = TrackingShipBlue;
                  progressLine = "tracking-progress-blue-line";
                  textDecoration = "ontime-text";
                } else if (delay_status === "Early") {
                  shipIcon = TrackingShipGreen;
                  progressLine = "tracking-progress-green-line";
                  textDecoration = "early-text";
                } else {
                  //Delay
                  shipIcon = TrackingShipRed;
                  progressLine = "tracking-progress-red-line";
                  textDecoration = "delay-text";
                }

                let updated_at = "";
                let hours = moment().diff(tracking_updated_at, "hours");

                if (hours > 24) {
                  let dayTime = moment().diff(tracking_updated_at, "days");
                  if (dayTime === 1) {
                    updated_at = `Updated ${dayTime} day ago`;
                  } else {
                    updated_at = `Updated ${dayTime} days ago`;
                  }
                } else {
                  if (hours === 1) {
                    updated_at = `Updated ${hours} hour ago`;
                  } else {
                    updated_at = `Updated ${hours} hours ago`;
                  }
                }

                let { event_group, status, location } = last_event_data[0];

                let imgPos = "0%";
                let barPos = "0%";

                if (status != "COMPLETED") {
                  if (event_group === "origin") {
                    barPos = "0%";
                    imgPos = "0%";
                  } else if (
                    event_group === "in_transit" ||
                    event_group === "transshipment"
                  ) {
                    barPos = "33%";
                    imgPos = "31%";
                  } else if (event_group === "destination") {
                    barPos = "66%";
                    imgPos = "62%";
                  } else {
                    barPos = "100%";
                    imgPos = "95%";
                  }
                }
                if (
                  status === "COMPLETED" ||
                  last_port === location ||
                  (each.hasOwnProperty("force_close") ? each.force_close : "") === 1
                ) {
                  barPos = "100%";
                  imgPos = "95%";
                }

                let s_etd = "NA";
                let a_etd = "NA";
                let p_eta = "NA";
                let a_eta = "NA";

                if (loading_port_etd) {
                  s_etd = moment(loading_port_etd).utcOffset(0).format("Do MMMM YYYY");
                }
                if (loading_port_atd) {
                  a_etd = moment(loading_port_atd).utcOffset(0).format("Do MMMM YYYY");
                }
                if (last_port_eta) {
                  p_eta = moment(last_port_eta).utcOffset(0).format("Do MMMM YYYY");
                }
                if (last_port_ata) {
                  a_eta = moment(last_port_ata).utcOffset(0).format("Do MMMM YYYY");
                }

                let srcIcon = {};
                if (loading_port_code) {
                  srcIcon = getFlagIcon(loading_port_code.slice(0, 2));
                }

                let dstnIcon = {};
                if (discharge_port_code) {
                  dstnIcon = getFlagIcon(discharge_port_code.slice(0, 2));
                }
                return (
                  <div
                    key={shipment_no}
                    className="card py-2 pr-3 d-flex flex-row justify-content-between align-items-center"
                    style={{
                      borderRadius: "18px",
                      //   cursor: "pointer"
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-start">
                      <div
                        className="d-flex flex-column justify-content-center align-items-center p-2"
                        style={{ background: "#407bff" }}
                      >
                        <img
                          style={{
                            width: "10px",
                            marginBottom: "5px",
                            transform: `${movement_type === "Import" ? "rotate(183deg)" : ""
                              }`,
                          }}
                          src={ExportIcon}
                        ></img>
                        {/* <div style={{ color: "white", fontSize: "10px", fontWeight: 700 }}>{props.trackingType}</div> */}
                        <div
                          style={{
                            color: "white",
                            fontSize: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {movement_type === "Import" ? "IM" : "EX"}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div>
                        {/* <img src={props.carrierIcon}></img> */}
                        <div
                          style={{
                            fontSize: "18px",
                            color: "black",
                            fontWeight: 800,
                          }}
                        >
                          {shipping_line}
                        </div>
                      </div>
                      {/* <div style={{ fontSize: "10px", fontWeight: 700 }}>
                                    <div>{props.containerCount}</div>
                                    <div>{" Containers"}</div>
                                </div> */}
                    </div>
                    <div className="d-flex flex-column justify-content-start eachCard">
                      <div className="d-flex flex-row justify-content-between">
                        <div style={{ color: "#858796", fontSize: "10px" }}>
                          BL No:
                        </div>
                        {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            fontWeight: 600,
                            marginLeft: "8px",
                          }}
                        >
                          {mbl_number == "" ? "NA" : mbl_number}
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <div style={{ color: "#858796", fontSize: "10px" }}>
                          Booking No:
                        </div>
                        {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            fontWeight: 600,
                            marginLeft: "8px",
                          }}
                        >
                          {booking_no == "" ? "NA" : booking_no}
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        {/* <div style={{ color: "#858796", fontSize: "10px" }}>
                    Container No:
                  </div> */}
                        {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.containerNo}</div> */}
                        {/* <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      marginLeft: "8px",
                    }}
                  >
                    {container_no}
                  </div> */}
                      </div>
                    </div>
                    <Tooltip id={`tracking_line_${shipment_no}`} />
                    <div
                      className="d-flex flex-row justify-content-center align-items-center cursor-class"
                      style={{ width: "448.538px" }}
                      data-tooltip-id={`tracking_line_${shipment_no}`}
                      data-tooltip-content="Click for Tracking details"
                    >
                      <div
                        className="d-flex flex-column justify-content-center align-items-center ml-1"
                        style={{ position: "absolute", left: "347px" }}
                      >
                        <div>
                          <img style={{ width: "14px" }} src={LocationBlue}></img>
                        </div>
                        <div className="d-flex flex-row">
                          {/* <img src={props.sourceFlag}></img> */}
                          <img
                            src={srcIcon.img}
                            alt="SRC"
                            style={{
                              width: "16px",
                              transform: `${srcIcon.name === "China" ? "rotateX(45deg)" : ""
                                }`,
                            }}
                          />
                          {/* <div style={{ fontSize: "11px" }}>{props.source}</div>  */}
                          <div
                            className="ml-1"
                            style={{ fontSize: "11px", fontWeight: 600 }}
                          >
                            {srcIcon.name}
                          </div>
                        </div>
                      </div>

                      <div class="current-activity">
                        <span>{last_event_data[0].event_name}</span>
                      </div>
                      <div className="d-flex flex-row tracking-background-progress">
                        <div
                          // style={{ height: "2px", backgroundColor: "#407bff" }}
                          style={{ "--highlight": barPos }}
                          className={progressLine}
                        // className="tracking-progress-green-line"
                        ></div>
                        <div
                          className="tracking-image-background"
                          style={{ "--highlight2": imgPos }}
                        // style={{ background: "white", position: "absolute", left: "100px", bottom: "-5px", paddingLeft: "5px", paddingRight: "5px" }}
                        >
                          <img
                            className=""
                            // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                            src={shipIcon}
                            width="23px"
                          ></img>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center ml-1"
                        style={{ position: "absolute", left: "780px" }}
                      >
                        <div>
                          <img src={(each.status === "COMPLETED") ? FlagBlue : FlagGrey}></img>
                        </div>
                        <div className="d-flex flex-row">
                          {/* <img src={props.destinationFlag}></img> */}
                          {dstnIcon.hasOwnProperty("img") &&
                            dstnIcon.hasOwnProperty("name") ? (
                            <>
                              <img
                                src={dstnIcon?.img}
                                alt="DSTN"
                                className="flag_class"
                                style={{
                                  transform: `${dstnIcon.name === "China" ? "rotateX(45deg)" : ""
                                    }`,
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {/* <div style={{ fontSize: "11px" }}>{props.destination}</div> */}
                          <div
                            className="ml-1"
                            style={{ fontSize: "11px", fontWeight: 600 }}
                          >
                            {last_port},{" "}
                            {dstnIcon.hasOwnProperty("name") ? dstnIcon.name : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex flex-column justify-content-start"
                      style={{ width: "171.575px" }}
                    >
                      <div
                        className="d-flex flex-row justify-content-between mb-1"
                        title="Actual Time of Departure"
                      >
                        <div style={{ color: "#858796", fontSize: "10px" }}>ETD:</div>
                        {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.scheduledETD}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            marginLeft: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {s_etd}
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between mb-1"
                        title="Actual Time of Departure"
                      >
                        <div style={{ color: "#858796", fontSize: "10px" }}>ATD:</div>
                        {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETD}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            marginLeft: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {a_etd}
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between mb-1"
                        title="Estimated Time of Arrival"
                      >
                        <div style={{ color: "#858796", fontSize: "10px" }}>ETA:</div>
                        {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.plannedETA}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            marginLeft: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {p_eta}
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between"
                        title="Actual Time of Arrival"
                      >
                        <div style={{ color: "#858796", fontSize: "10px" }}>ATA:</div>
                        {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETA}</div> */}
                        <div
                          style={{
                            fontSize: "10px",
                            marginLeft: "10px",
                            fontWeight: 700,
                          }}
                        >
                          {a_eta}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="d-flex flex-row justify-content-between mb-3">
                        <div
                          className={
                            "cursor-class " +
                            `${last_event_data[0].status === "COMPLETED" ||
                              (each.hasOwnProperty("force_close")
                                ? each.force_close
                                : "") === 1
                              ? "trip-done"
                              : ""
                            }`
                          }
                          onClick={() => {
                            closeTrackingModalOpen("shipment_no", shipment_no);
                          }}
                          data-tooltip-id={`close_${shipment_no}`}
                          data-tooltip-content="Close Tracking"
                        >
                          <img src={CompletedIcon}></img>
                        </div>
                        {last_event_data[0].status === "COMPLETED" ? (
                          ""
                        ) : (
                          <Tooltip id={`close_${shipment_no}`} />
                        )}

                        <div
                          className={
                            "cursor-class " +
                            `${last_event_data[0].status === "COMPLETED"
                              ? "trip-done"
                              : ""
                            }`
                          }
                          data-tooltip-id={`edit_${shipment_no}`}
                          data-tooltip-content="Edit Shipment Details"
                          onClick={() => {
                            editShipmentDetails("BK234232112");
                          }}
                        >
                          <img src={EditIcon}></img>
                        </div>
                        {last_event_data[0].status === "COMPLETED" ? (
                          ""
                        ) : (
                          <Tooltip id={`edit_${shipment_no}`} />
                        )}
                        <div
                          className="cursor-class"
                          onClick={() => {
                            openDeleteTrackingModal("Container", shipment_no);
                          }}
                          data-tooltip-id={`delete_${shipment_no}`}
                          data-tooltip-content="Delete Tracking"
                        >
                          <img src={DeleteIcon}></img>
                        </div>
                        <Tooltip id={`delete_${shipment_no}`} />
                        <div
                          className="cursor-class"
                          onClick={() => {
                            let local_url = `/tracking_details/container/${shipment_no}`;
                            shareTrackingDetails(
                              "Container",
                              container_no,
                              local_url
                            );
                          }}
                          data-tooltip-id={`share_${shipment_no}`}
                          data-tooltip-content="Sharing Tracking Details"
                        >
                          <img src={ShareIcon}></img>
                        </div>
                        <Tooltip id={`share_${shipment_no}`} />
                      </div>
                      <div style={{ width: "120px", textAlign: "center" }}>
                        <div
                          className={`${textDecoration} py-1 px-3`}
                          style={{
                            fontSize: "10px",
                            // color: "#FF0000",
                            fontWeight: 600,
                            // background: "#ffeded",
                            borderRadius: "14px",
                          }}
                        >
                          {delay_message}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      {/* <img
                        className={
                          "mb-3 cursor-class " +
                          `${last_event_data[0].status === "COMPLETED"
                            ? "trip-done"
                            : ""
                          }`
                        }
                        data-tooltip-id={`refresh_${shipment_no}`}
                        data-tooltip-content="Refresh Tracking"
                        src={RefreshIcon}
                      /> */}
                      {last_event_data[0].status === "COMPLETED" ? (
                        ""
                      ) : (
                        <Tooltip id={`refresh_${shipment_no}`} />
                      )}
                      <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                        {updated_at}
                      </div>
                      <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                        Next Update in 4 hours
                      </div>
                    </div>
                  </div>
                );
              })}
            </>}
      </div>
      {/* Container Tile */}
    </>
  );
};

export default TrackingTile2;


const CustomSVG = ({ text, height, width }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" rx="15" ry="15" fill="#f0f0f0" />
    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1vw" fill="#333">
      {text}
    </text>
  </svg>
);