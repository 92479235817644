import React, {
  useRef,
  useState,
  useEffect,
  useSyncExternalStore,
} from "react";
import { useAsyncError, useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "./editShipment.css";
import {
  FaRoad,
  FaTrain,
  FaPlane,
  FaShip,
  FaBox,
  FaTrash,
  FaUpload,
} from "react-icons/fa";
import Modal from "react-responsive-modal";
import SweetAlert from "react-bootstrap-sweetalert";
// import "./approverdashboard.css";
import { v4 as uuidv4 } from "uuid";
import DateTime from "react-datetime";
import $ from "jquery";
import redirectURL from "../redirectURL";
import moment from "moment";
import { BodyDropTarget, ColumnGroup } from "ag-grid-community";
import { reduceRight } from "country-flags-svg/dist/data/countries";
import { event } from "react-ga";
const ModalEditShipment = (props) => {
  // console.log("ModalEditShipment", props);
  const [contractNumber, setContractNumber] = useState("");
  const [PONumber, setPONumber] = useState("");
  const [recordforedit, setRecordforedit] = useState({});



  const onChangeContractNumber = (e) => {
    setContractNumber(e.target.value);
  };
  const onChangePONumber = (e) => {
    setPONumber(e.target.value);
    setEditStakeDetails((prev) => ({
      ...prev,
      po_no: e.target.value,
    }));
  };

  const onChangefreightForwarder = (e) => {
    setEditStakeDetails(prev => ({
      ...prev,
      freightForwarder: e.target.value,
    }))
  }
  const onChangeCustomHouseAgent = e => {
    setEditStakeDetails(prev => ({
      ...prev,
      customHouseAgent: e.target.value,
    }))
  }


  const [movementType, setMovementType] = useState([
    { value: "Import", label: "Import" },
    { value: "Export", label: "Export" },
  ]);
  const [selectMovementType, setSelectMovementType] = useState();

  const [cargoType, setCargoType] = useState([
    { value: "General Cargo", label: "General Cargo" },
    { value: "Hazardous Cargo", label: "Hazardous Cargo" },
    { value: "Refridgerated Cargo", label: "Refridgerated Cargo" },
    {
      value: "Refridgerated Hazardous Cargo",
      label: "Refridgerated Hazardous Cargo",
    },
  ]);
  const [lclCargoList, setLclCargoList] = useState([
    { value: "General Cargo", label: "General Cargo" },
    { value: "Hazardous Cargo", label: "Hazardous Cargo" },
  ]);
  const [selectCargoType, setSelectCargoType] = useState();
  const [selectLclCargoType, setSelectLclCargoType] = useState();
  const onSelectLclCargoType = (selectedOption) => {
    setSelectLclCargoType(selectedOption);
  };
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: "10px", // You can adjust this value to your liking
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "10px", // You can adjust this value to your liking
    }),
    control: (base, stat) => ({
      ...base,
      boxShadow: stat.isFocused ? "0px 0px 10px #4e73df" : "0px 0px 8px #d9d9d9",
      borderRadius: "6px"
    }),
  };
  const customStyles2 = {
    fontSize: "13px"
  }

  const [state, setState] = useState({
    portOptions: [],
    portName: { label: "", value: "" },
    showLoader: false,
    freightForwarder: "",
    customHouseAgent: "",
  });
  const [cargoList, setCargoList] = useState([
    { value: "PCargo1", label: "PCargo1" },
    { value: "PCargo2", label: "PCargo2" },
  ]);
  const [supplierCargoList, setSupplierCargoList] = useState([
    { value: "SPCargo1", label: "SPCargo1" },
    { value: "SPCargo2", label: "SPCargo2" },
  ]);

  const [selectedCargo, setCargo] = useState();
  const [selectedSupplierCargo, setSupplierCargo] = useState();

  const setProductCargoType = (selectedOption) => {
    setCargo(selectedOption);
  };
  const setSupplierProductCargoType = (selectedOption) => {
    setSupplierCargo(selectedOption);
  };


  const [productName, setProductName] = useState("");
  const [supplierProductName, setSupplierProductName] = useState("");
  const [disableCustomerProductSave, setDisableCustomerProductSave] =
    useState(true);
  const [disableSupplierProductSave, setDisableSupplierProductSave] =
    useState(true);
  const [errorTxtForCustomerProducts, setErrorTextForCustomerProducts] =
    useState(false);
  const [errorTxtForSupplierProducts, setErrorTextForSupplierProducts] =
    useState(false);
  const onChangeProductName = (e) => {
    setProductName(e.target.value);
  };

  const fetchPortCodes = () => {
    redirectURL
      .get("/exim/fetchPortCodes")
      .then((resp) => {
        if (resp.data.status === "success") {
          console.log("fetchPortCodes", resp.data);
          setState({
            ...state,
            portOptions: resp.data.portData,
          });
          //   const shippingLineArr = resp.data.shippingLineArr;
          //   console.log("shippingLineArr", shippingLineArr);
          //   // const fetchCustomers = customerDetails.map((e)=>{
          //   //     return e.customer
          //   // })
          //   const shippingLine = shippingLineArr.map((e) => ({
          //     value: e,
          //     label: e,
          //   }));
          //   console.log("shippingLine", shippingLine);
          //   setShippingLine(shippingLine);
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };

  const validateCustomerProductName = () => {
    if (productName.length === 0) {
      setDisableCustomerProductSave(true);
      setErrorTextForCustomerProducts(true);
    } else {
      setDisableCustomerProductSave(false);
      setErrorTextForCustomerProducts(false);
    }
  };
  const ValidateSupplierProductName = () => {
    if (supplierProductName.length === 0) {
      setDisableSupplierProductSave(true);
      setErrorTextForSupplierProducts(true);
    } else {
      setDisableSupplierProductSave(false);
      setErrorTextForSupplierProducts(false);
    }
  };
  const onChangeSupplierProductName = (e) => {
    setSupplierProductName(e.target.value);
  };

  const [hsCode, setHSCode] = useState("");
  const [supplierHSCode, setSupplierHSCode] = useState("");

  const onChangeHSCode = (e) => {
    setHSCode(e.target.value);
  };
  const onChangeSupplierHSCode = (e) => {
    setSupplierHSCode(e.target.value);
  };
  const closeAlert = () => {
    setShow(false);
    setOverly("show-n");
  };
  const [billOfLading, setBillOfLading] = useState("");
  const onChangeBillOfLading = (e) => {
    setBillOfLading(e.target.value);
    setEditdetails((prev) => ({
      ...prev,
      bill_of_lading_no: e.target.value,
    }));
  };

  const mblDate = $("#mbl_date").val();

  // for options
  const [shippingLine, setShippingLine] = useState([]);
  // for value
  const [selectShippingLine, setSelectShippingLine] = useState({
    label: "",
    value: "",
  });
  // onChange
  const onSelectShippingLine = (selectedOption) => {
    setSelectShippingLine(selectedOption);
    console.log(selectedOption, "selectedOption");
    setEditdetails((prev) => ({
      ...prev,
      shipping_line: selectedOption.value,
    }));
  };

  const [trackByValue, setTrackByValue] = useState({ label: "", value: "" });

  const [selectIncoterm, setSelectIncoterm] = useState();
  const onSelectIncoterm = (selectedOptions) => {
    setSelectIncoterm(selectedOptions);
    console.log("selectIncoterm", selectIncoterm);
  };

  const [billOfLadingDate, setBillOfLadingDate] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  //const [pickUpDate, setPickUpDate] = useState("");
  const onHandleDateChange = (e) => {
    setBillOfLadingDate(e);
    setEditdetails((prev) => ({
      ...prev,
      bill_of_lading_date: e,
    }));
  };
  const onHandleInvoiceDateChange = (e) => {
    setInvoiceDate(e);
    seteditAdditionalDetails((prev) => ({
      ...prev,
      invoice_date: e,
    }));
  };
  const onHandlePickUpDateChange = (e) => {
    setEditStakeDetails(prev => ({
      ...prev,
      pickUpDate: e,
    }))
  };

  let [containerCount, setContainerCount] = useState(0);
  const [boxes, setBoxes] = useState();
  const [containerNo, setContainerNo] = useState("");
  const [errorMssge, setErrorMssge] = useState("");
  const onChangeContainerNo = (e) => {
    setContainerNo(e.target.value);
  };
  const [submitDisable, setSubmitDisable] = useState(true);
  const validateContainerNo = () => {
    const pattern = /^[A-Z]{4}\d{7}$/;
    if (containerNo != "" && containerNo != undefined) {
      if (!pattern.test(containerNo)) {
        let errorMessage = "*Please Enter Valid Container No";
        setSubmitDisable(true);
        setErrorMssge(errorMessage);
      } else {
        setSubmitDisable(false);
        setErrorMssge("");
      }
    } else {
      setSubmitDisable(true);
    }
  };
  const [originPort, setOriginPort] = useState("");
  const [customerOriginPort, setCustomerOriginPort] = useState("");
  const [customerStuffingLocation, setCustomerStuffingLocation] = useState("");
  const [customerDestinationPort, setCustomerDestinationPort] = useState("");
  const [customerDestuffingLocation, setCustomerDestuffingLocation] =
    useState("");
  const onChangeCustomerOrigin = (e) => {
    setCustomerOriginPort(e.target.value);
    setEditStakeDetails((prev) => ({
      ...prev,
      origin_port: e.target.value,
    }));
  };
  const onChangeCustomerStuffingLocation = (e) => {
    setCustomerStuffingLocation(e.target.value);
    setEditStakeDetails((prev) => ({
      ...prev,
      stuffing_location: e.target.value,
    }));
  };
  const onChangeCustomerDestination = (e) => {
    setCustomerDestinationPort(e.target.value);
    setEditStakeDetails((prev) => ({
      ...prev,
      destination_location: e.target.value,
    }));
  };
  const onChangeCustomerDestuffingLocation = (e) => {
    setCustomerDestuffingLocation(e.target.value);
    setEditStakeDetails((prev) => ({
      ...prev,
      destuffing_location: e.target.value,
    }));
  };

  const [destinationPort, setDestinationPort] = useState("");
  const [transitDays, setTransitDays] = useState(0);
  const onChangeTransitDays = (e) => {
    setTransitDays(e.target.value);
    setEditdetails((prev) => ({
      ...prev,
      transit_days: e.target.value,
    }));
  };

  // for value
  const [customer, setCustomer] = useState();
  const [supplier, setSupplier] = useState();
  // for customerloading
  const [customerLoading, setCustomerLoading] = useState(true);
  const [supplierLoading, setSupplierLoading] = useState(true);
  // for options
  const [customerData, setCustomerData] = useState([
    { value: "+Add", label: "+ Edit Customer" },
  ]);
  // const [shippingLine, setPortData] = useState([])
  const [supplierData, setSupplierData] = useState([
    { value: "+Add", label: "+ Edit Supplier" },
  ]);
  const [productData, setProductData] = useState([
    { value: "+Add", label: "+ Add Product" },
  ]);
  const [supplierProductData, setSupplierProductData] = useState([
    { value: "+Add", label: "+ Add Product" },
  ]);
  const [attachmentTitle, setAttachmentTitle] = useState("");
  const [attachmentsDocArr, setAttachmentsDocArr] = useState([]);
  const [attachmentsDoc, setAttachmentsDoc] = useState("");
  const [selectedDocType, setSelectedDocType] = useState({
    value: "",
    label: "Type",
  });
  const [attachmentType, setAttachmentType] = useState({
    value: "",
    label: "Type",
  });
  const [isAttachmentsDocChanged, setIsAttachmentDocsChanged] = useState(false);
  const [documentExtension, setDocumentExtension] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState();
  const [supplierProduct, setSupplierProduct] = useState();

  const [productLoading, setProductLoading] = useState(true);
  const [supplierProductLoading, setSupplierProductLoading] = useState(true);

  const [openCustomerModel, setOpenCustomerModel] = useState(false);
  const [openProductModel, setOpenProductModel] = useState(false);

  const [openSupplierModel, setOpenSupplierModel] = useState(false);
  const [openSupplierProductModel, setSuppilerProductModel] = useState(false);
  const [customerReferenceNo, setCustomerReferenceNo] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [demurrageFreeDays, setDemurrageFreeDays] = useState("");
  const [detentionFreeDays, setDetetentionFreeDays] = useState("");
  const [CFSFreeDays, setCFSFreeDays] = useState("");
  const [supplierReferenceNo, setSupplierReferenceNo] = useState("");

  const [FTAOptions, setFTAOptions] = useState([
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]);
  const [incotermsList, setIncotermsList] = useState([
    { value: "EXW", label: "EXW - Ex Work" },
    { value: "FCA", label: "FCA - Free Carrier" },
    { value: "FAS", label: "FAS - Free Alongside Ship" },
    { value: "FOB", label: "FOB - Free on Board" },
    { value: "CFR", label: "CFR - Cost and Freight" },
    { value: "CIF", label: "CIF - Cost, Insurance, and Freight " },
    { value: "CPT", label: "CPT - Carriage Paid To" },
    { value: "CIP", label: "CIP - Carriage and Insurance Paid To" },
    { value: "DAP", label: "DAP - Delivered at Place " },
    { value: "DPU", label: "DPU - Delivered at Place Unloaded " },
    { value: "DDP", label: "DDP - Delivered Buty Paid " }
  ])

  const [RDBOptions, setRDBOptions] = useState([
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]);
  const [EPCGOptions, setEPCGOptions] = useState([
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]);
  const [LCOptions, setLCOptions] = useState([
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]);

  const [FTAValue, setFTAValue] = useState();
  //const [RDBValue, setRDBValue] = useState();
  const [EPCGValue, setEPCGValue] = useState();
  const [LCValue, setLCValue] = useState();

  const [IncotermsValue, setIncotermsValue] = useState({ value: "", label: "" });
  const [customerName, setCustomerName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);
  const [customerCode, setCustomerCode] = useState("");
  const [LCNumber, setLCNumber] = useState("");
  const [overly, setOverly] = useState("show-n");
  const [show, setShow] = useState(false);
  const [basicType, setBasicType] = useState("default");
  const [basicTitle, setBasicTitle] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [supplierAdminEmail, setSupplierAdminEmail] = useState("");
  const [adminName, setAdminName] = useState("");
  const [supplierAdminName, setSupplierAdminName] = useState("");
  const [enablePOC, setEnablePOC] = useState(true);
  const [supplierEnablePOC, setSupplierEnablePOC] = useState(true);

  const [teamList, setTeamList] = useState([
    { value: "team1", label: "team1" },
    { value: "team2", label: "team2" },
  ]);
  const [supplierTeamList, setSupplierTeamList] = useState([
    { value: "team1", label: "team1" },
    { value: "team2", label: "team2" },
  ]);
  const [selectedTeam, setSelectTeam] = useState();
  const [supplierSelectedTeam, setSupplierTeam] = useState();
  const [teamListForProduct, setTeamListForProduct] = useState([
    { value: "Default", label: "Default EGA Group" },
    { value: "PTeam1", label: "PTeam1" },
    { value: "PTeam2", label: "PTeam2" },
  ]);
  const [supplierTeamListForProduct, setSupplierTeamListForProduct] = useState([
    { value: "Default", label: "Default EGA Group" },
    { value: "SPTeam1", label: "SPTeam1" },
    { value: "SPTeam2", label: "SPTeam2" },
  ]);
  const [selectedTeamForProduct, setSelectTeamForProduct] = useState(
    teamListForProduct[0]
  );
  const [selectedSupplierTeamForProduct, setSelectSupplierTeamForProduct] =
    useState(supplierTeamListForProduct[0]);
  const [pointOfContacts, setPOCs] = useState([
    { value: "POC1", label: "POC1" },
    { value: "POC2", label: "POC2" },
  ]);
  const [supplierPointOfContacts, setSupplierPointOfContacts] = useState([
    { value: "POC1", label: "POC1" },
    { value: "POC2", label: "POC2" },
  ]);
  const [selectedPOC, setSelectedPOC] = useState();
  const [supplierPOC, setSupplierPOC] = useState();

  const [activeItem, setActiveItem] = useState("");
  const [shipmentActive, setShipmentActive] = useState("");
  const [showShipmentType, setShowShipmentType] = useState(false);
  const [lclCargoType, setLclCargoType] = useState(false);
  const [docFieldCount, setDocFieldCount] = useState(0);
  const [totalDocFields, setTotalDocFields] = useState([
    { id: 0, value: "", file: "" },
  ]);

  const [documentOptions, setDocumentOptions] = useState([
    { value: "MSDS", label: "MSDS" },
    { value: "Packing List", label: "Packing List" },
    { value: "Custom Invoice", label: "Custom Invoice" },
    { value: "Insurance Company", label: "Insurance Company" },
    { value: "Pickup Request Form", label: "Pickup Request Form" },
    { value: "Custom Invoice/PL", label: "Custom Invoice/PL" },
    { value: "Other", label: "Other" },
    { value: "DO", label: "DO" },
    { value: "Attachment", label: "Attachment" },
  ]);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [editDetails, setEditdetails] = useState({
    contract_number: "",
    movement_type: "",
    cargo_mode: "",
    shipment_type: "",
    selected_cargo_type: "",
    track_by: "",
    bill_of_lading_date: "",
    shipping_line: "",
    transit_days: "",
    bill_of_lading_no: "",
    container_no: ""
  });
  const [editStakeholderDetail, setEditStakeDetails] = useState({
    shipment_owner: "",
    po_no: "",
    shipper_type: "",
    origin_port: "",
    stuffing_location: "",
    destination_location: "",
    destuffing_location: "",
    freightForwarder: "NA",
    customHouseAgent: "NA",
    pickUpDate: "NA",

  });
  const [editAdditionalDetails, seteditAdditionalDetails] = useState({
    invoice_no: "",
    invoice_date: "",
    free_trade_agreement: "",
    rdb_value: "",
    epcg_value: "",
    letter_of_credit: "",
    incoterm: ""
  });
  const [editdemurrageDetails, seteditdemurrageDetails] = useState({
    demurrage_free_days: "",
    detention_free_days: "",
    portName: "",
    cfs_free_days: "",
  });
  const [editsupplier, seteditsupplier] = useState({
    supplier: "",
    admin_name: "",
    admin_email: "",
    team: "",
    poc: "",
    product_selected: "",
    supplierReferenceId: "",
  });
  const [editcustomer, seteditcustomer] = useState({
    customer: "",
    admin_name_cust: "",
    admin_email_cust: "",
    team_cust: "",
    poc_cust: "",
    product_selected_cust: "",
    customerReferenceId: "",
    customer_code: "",
  });
  useEffect(() => {
    let userEmail = localStorage.getItem("email");
    setEmail(userEmail);
    setActiveItem("SEA");
    setShipmentActive("FCL");
    // setTrackByValue()
    setShowShipmentType(true);
    fetchShippingLineDetails();
    setInputDisabled(true);
    getShipmentForEdit();
    fetchPortCodes();
  }, [props]);
  const getShipmentForEdit = () => {
    var params = { shipment_unique_id: props.shipment_unique_id };
    redirectURL.post("/exim/getshipmentforedit", params).then((resp) => {
      var record = resp.data.record;
      // console.log(record, "420");
      if (record != "" && record != undefined) {
        if (record.length > 0) {
          var e = record[0];
          if (e.hasOwnProperty("shipment_created_with") && e.shipment_created_with === "container_no") {
            setTrackByValue({ label: "Container Number", value: "Container Number" });
          } else if (e.hasOwnProperty("shipment_created_with") && e.shipment_created_with !== "container_no") {
            setTrackByValue({ label: "BL Number", value: "BL Number" });
          }
          if (e.hasOwnProperty("lc_no")) {
            setLCNumber(e.lc_no);
          } else {
            setLCNumber("");
          }
          setRecordforedit(record[0]);
          // console.log("DASDA",getHyphenDDMMYYYY(e.bill_of_lading_date))
          setEditdetails((prev) => ({
            ...prev,
            contract_number: e.contract_number,
            movement_type: e.movement_type,
            cargo_mode: e.cargo_mode,
            shipment_type: e.shipment_type,
            selected_cargo_type: e.selected_cargo_type,
            track_by: e.shipment_created_with,
            bill_of_lading_date: e.bill_of_lading_date,
            shipping_line: e.shipping_line,
            transit_days: e.transit_days,
            bill_of_lading_no: e.bill_of_lading_no,
            container_no: e.container_no,
            mbl_number: e.mbl_number
          }));
          setEditStakeDetails((prev) => ({
            ...prev,
            shipment_owner: e.shipment_owner,
            po_no: e.po_no,
            origin_port: e.origin_port,
            stuffing_location: e.stuffing_location,
            destination_location: e.destination_location,
            destuffing_location: e.destuffing_location,
            freightForwarder: e.freight_forwarder,
            customHouseAgent: e.custom_house_agent,
            pickUpDate: e.pick_up_date,
          }));
          seteditAdditionalDetails((prev) => ({
            ...prev,
            invoice_no: e.invoice_no,
            invoice_date: e.invoice_date,
            free_trade_agreement: e.free_trade_agreement,
            rdb_value: e.rd_benefit_value,
            epcg_value: e.epcg_value,
            letter_of_credit: e.letter_of_credit,
            incoterm: e.incoterm
          }));
          seteditdemurrageDetails((prev) => ({
            ...prev,
            demurrage_free_days: e.demurrage_free_days,
            detention_free_days: e.detention_free_days,
            cfs_free_days: e.cfs_free_days,
            portName: { value: e.demurrage_and_detention_port_code, label: e.demurrage_and_detention_port },
          }));
        }
      }
      if (
        resp.data.supplier_details != "" &&
        resp.data.supplier_details != undefined
      ) {
        if (resp.data.supplier_details.length > 0) {
          var supp_details = resp.data.supplier_details;
          seteditsupplier((prev) => ({
            ...prev,
            supplier: supp_details.supplier,
            admin_name: supp_details.admin_name,
            admin_email: supp_details.admin_email,
            team: supp_details.team,
            poc: supp_details.poc,
            product_selected: supp_details.product_selected,
            supplierReferenceId: supp_details.supplier_reference_id,
          }));
        }
      }
      if (
        resp.data.customer_details != "" &&
        resp.data.customer_details != undefined
      ) {
        if (resp.data.customer_details.length > 0) {
          var cust_details = resp.data.customer_details[0];
          console.log("custoimerr", cust_details);
          seteditcustomer((prev) => ({
            ...prev,
            customer: cust_details.customer,
            admin_name_cust: cust_details.admin_name,
            admin_email_cust: cust_details.admin_email,
            team_cust: cust_details.team,
            poc_cust: cust_details.poc,
            customer_code: cust_details.customer_code,
            // product_selected_cust: supp_details.product_selected,
            customerReferenceId: cust_details.customer_reference_id,
          }));
        }
      }
    });
  };
  const fetchShippingLineDetails = async () => {
    await redirectURL
      .post("/exim/fetchShippingLineDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const shippingLineArr = resp.data.shippingLineArr;
          // console.log("shippingLineArr", shippingLineArr);
          // const shippingLine = shippingLineArr.map((e) => ({
          //   value: e,
          //   label: e,
          // }));
          // console.log("shippingLine", shippingLine);
          setShippingLine(shippingLineArr);
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const fetchTableItemsForDocs = async () => {
    // console.log("entered fetch table");
    await redirectURL
      .post("/exim/getCustomerEximDocuments")
      .then((resp) => {
        // console.log("requiredDocs", resp.data);

        let trackingDocuments = resp.data.requiredDocs;
        trackingDocuments = trackingDocuments.map((doc) => ({
          documentName: doc.document_title,
          documentType: doc.document_type,
        }));
        // console.log("trackingDocuments", trackingDocuments);
        setAttachmentsDocArr(trackingDocuments);
        // console.log(attachmentsDocArr);
      })
      .catch((err) => {
        console.log("getEximTrackingDocuments err", err);
      });
  };
  const fetchCustomerDetails = async () => {
    console.log("customerData", customerData);
    await redirectURL
      .post("/exim/fetchCustomerDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const customerDetails = resp.data.customerDetails;
          console.log("customerDetails", customerDetails);
          const fetchCustomers = customerDetails.map((e) => {
            return e.customer;
          });

          console.log("fetchCustomers", fetchCustomers);
          const updateCustomerData = fetchCustomers.map((e) => ({
            value: e,
            label: e,
          }));
          console.log("updateCustomerData", updateCustomerData);
          let updatedDataNames = [];
          let updatedData = [];
          console.log(customerData);
          customerData.forEach((each) => {
            if (!updatedDataNames.includes(each.value)) {
              updatedData.push(each);
              updatedDataNames.push(each.value);
            } else {
            }
          });
          setCustomerData(updatedData);

          console.log(updateCustomerData);
          console.log(customerData);
          console.log(updatedData, "updatedDataupdatedData");
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const fetchSupplierDetails = async () => {
    await redirectURL
      .post("/exim/fetchSupplierDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const supplierDetails = resp.data.supplierDetails;
          console.log("supplierDetails", supplierDetails);
          const fetchSuppliers = supplierDetails.map((e) => {
            return e.supplier;
          });
          const updateSupplierData = fetchSuppliers.map((e) => ({
            value: e,
            label: e,
          }));
          console.log("updateSupplierData", updateSupplierData);
          let updatedDataNames = [];
          let updatedData = [];
          supplierData.forEach((each) => {
            if (!updatedDataNames.includes(each.value)) {
              updatedData.push(each);
              updatedDataNames.push(each.value);
            }
          });
          setSupplierData(updatedData);
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const fetchCustomerProductDetails = async () => {
    await redirectURL
      .post("/exim/fetchCustomerProductDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const customerProductDetails = resp.data.productDetails;
          console.log("customerProductDetails 565", customerProductDetails);
          const fetchProducts = customerProductDetails.map((e) => {
            return e.customer_product_name;
          });
          const updateProductData = fetchProducts.map((e) => ({
            value: e,
            label: e,
          }));
          console.log(updateProductData, "fetched DBdetails");
          let updatedDataNames = [];
          let updatedData = [];

          productData.forEach((each) => {
            if (!updatedDataNames.includes(each.value)) {
              updatedData.push(each);
              updatedDataNames.push(each.value);
            }
          });
          setProductData(updatedData);

          // console.log("404", productData)
        } else {
          console.log("unable to fetch records");
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const fetchSupplierProductDetails = async () => {
    await redirectURL
      .post("/exim/fetchSupplierProductDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const supplierProductDetails = resp.data.productDetails;
          console.log("supplierProductDetails", supplierProductDetails);
          const fetchProducts = supplierProductDetails.map((e) => {
            return e.product_name;
          });
          const updateProductData = fetchProducts.map((e) => ({
            value: e,
            label: e,
          }));

          let updatedDataNames = [];
          let updatedData = [];
          supplierProductData.forEach((each) => {
            if (!updatedDataNames.includes(each.value)) {
              updatedData.push(each);
              updatedDataNames.push(each.value);
            }
          });

          setSupplierProductData(updatedData);

          console.log("supplier product data", supplierProductData);
        } else {
          console.log("unable to fetch records");
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const onChangeCustomer = (selectedOption) => {
    if (selectedOption.value === "+Add") {
      setOverly("show-m");
      setCustomer("");
      setOpenCustomerModel(true);
    } else {
      setCustomer(selectedOption);
    }
  };
  const onChangeSupplier = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption.value === "+Add") {
      setOverly("show-m");
      setSupplier("");
      setOpenSupplierModel(true);
    } else {
      setSupplier(selectedOption);
    }
  };
  const onChangeInvoiceNo = (e) => {
    setInvoiceNo(e.target.value);
    seteditAdditionalDetails((prev) => ({
      ...prev,
      invoice_no: e.target.value,
    }));
  };
  const onChangeDemurrageDays = (e) => {
    setDemurrageFreeDays(e.target.value);
    seteditdemurrageDetails((prev) => ({
      ...prev,
      demurrage_free_days: e.target.value,
    }));
  };
  const onChangePrt = (e) => {
    // console.log("ERERE ", e)
    // setDetetentionFreeDays(e.target.value);
    seteditdemurrageDetails((prev) => ({
      ...prev,
      portName: e,
    }));
  };
  const onChangeDetentionDays = (e) => {
    setDetetentionFreeDays(e.target.value);
    seteditdemurrageDetails((prev) => ({
      ...prev,
      detention_free_days: e.target.value,
    }));
  };
  const onChangeCFSDays = (e) => {
    setCFSFreeDays(e.target.value);
    seteditdemurrageDetails((prev) => ({
      ...prev,
      cfs_free_days: e.target.value,
    }));
  };

  const customerList = [
    { value: "karthik", label: "Karthik" },
    { value: "dheeraj", label: "dheeraj" },
  ];
  const [Owners, setOwners] = useState(customerList);
  const [selectOwner, setSelectOwner] = useState();
  const onSelectOwner = (selectedOption) => {
    setSelectOwner(selectedOption);
    setEditStakeDetails((prev) => ({
      ...prev,
      shipment_owner: selectedOption.value,
    }));
  };
  const onSelectFTA = (selectedOption) => {
    setFTAValue(selectedOption);
    seteditAdditionalDetails((prev) => ({
      ...prev,
      free_trade_agreement: selectedOption.value,
    }));
  };
  const onSelectIncoterms = (selectedOption) => {
    setIncotermsValue(selectedOption)
    seteditAdditionalDetails((prev) => ({
      ...prev,
      incoterm: selectedOption.value
    }))

  }
  const onSelectRDB = (selectedOption) => {
    //setRDBValue(selectedOption);
    seteditAdditionalDetails((prev) => ({
      ...prev,
      rdb_value: selectedOption.value,
    }));
  };
  const onSelectEPCG = (selectedOption) => {
    setEPCGValue(selectedOption);
    seteditAdditionalDetails((prev) => ({
      ...prev,
      epcg_value: selectedOption.value,
    }));
  };
  const onSelectLC = (selectedOption) => {
    setLCValue(selectedOption);
    seteditAdditionalDetails((prev) => ({
      ...prev,
      letter_of_credit: selectedOption.value,
    }));
  };

  const onChangeLCNumber = (e) => {
    setLCNumber(e.target.value);
  };
  // Cross Click functionalities
  const onCloseCustomerModal = () => {
    setOpenCustomerModel(false);
    setOverly("show-n");
  };
  const onCloseProductModal = () => {
    setOpenProductModel(false);
    setOverly("show-n");
  };
  const onCloseSupplierModal = () => {
    setOpenSupplierModel(false);
    setOverly("show-n");
  };
  const onCloseSupplierProductModal = () => {
    setSuppilerProductModel(false);
    setOverly("show-n");
  };

  // Cancel click functionalities
  const onClickHideCustomerModal = () => {
    setOverly("show-n");
    setOpenCustomerModel(false);
  };
  const onClickHideProductModal = () => {
    setOverly("show-n");
    setOpenProductModel(false);
  };
  const onClickHideSupplierModal = () => {
    setOverly("show-n");
    setOpenSupplierModel(false);
  };
  const onClickHideSupplierProductModal = () => {
    setOverly("show-n");
    setSuppilerProductModel(false);
  };
  const [customerReferenceId, setCustomerReferenceId] = useState("");
  const [supplierReferenceId, setSupplierReferenceId] = useState("");

  const onSaveCustomerDetails = async (e) => {
    e.preventDefault();
    console.log("862");
    var customerDetails = {
      customer: editcustomer.customer,
      customer_code: editcustomer.customer_code,
      admin_name: editcustomer.admin_name_cust,
      admin_email: editcustomer.admin_email_cust,
      team: editcustomer.team_cust,
      poc: editcustomer.poc_cust,
      shipment_unique_id: props.shipment_unique_id,
    };
    await redirectURL
      .post("/exim/updateCustomerDetails", customerDetails)
      .then(async (resp) => {
        if (resp.data.status === "success") {
          console.log("yes");
          setShow(true);
          setBasicType("success");
          setBasicTitle(resp.data.message);

          setOpenCustomerModel(false);
          await fetchCustomerDetails();
          // console.log("606",openCustomerModel)
          await setTimeout(() => {
            const addCustomer = [{ value: customerName, label: customerName }];
            setCustomerData((prevState) => [...addCustomer, ...prevState]);
          }, 500);
          setOverly("show-n");
          setCustomerName("");
          setCustomerCode("");
          setAdminName("");
          setAdminEmail("");
          setSelectTeam({ value: "Default", label: "Default EGA Group" });
          setSelectedPOC("");
          setProduct("");
        } else {
          setShow(true);
          setBasicType("danger");
          setBasicTitle(resp.data.message);
        }
      })
      .catch((e) => {
        console.log("Error message is", e);
      });
  };
  const onSaveSupplierDetails = async (e) => {
    e.preventDefault();
    var supplierDetails = {
      supplier: editsupplier.supplier,
      admin_name: editsupplier.admin_name,
      admin_email: editsupplier.admin_email,
      team: editsupplier.team,
      poc: editsupplier.poc,
      shipment_unique_id: props.shipment_unique_id,
    };
    await redirectURL
      .post("/exim/updateSupplierDetails", supplierDetails)
      .then(async (resp) => {
        if (resp.data.status === "success") {
          setShow(true);
          setBasicType("success");
          setBasicTitle(resp.data.message);
          setOpenSupplierModel(false);
          await fetchSupplierDetails();
          await setTimeout(() => {
            const addSupplier = [{ value: supplierName, label: supplierName }];
            setSupplierData((prevState) => [...addSupplier, ...prevState]);
          }, 500);

          setOverly("show-n");
          setSupplierName("");
          setSupplierName("");
          setSupplierAdminName("");
          setSupplierAdminEmail("");
          setSupplierTeam({ value: "Default", label: "Default EGA Group" });
          setSupplierPOC("");
          setSupplierProduct("");
        } else {
          setShow(true);
          setBasicType("danger");
          setBasicTitle(resp.data.message);
        }
      })
      .catch((e) => {
        console.log("Error message is", e);
      });
  };
  const onSaveSupplierProductDetails = async (e) => {
    e.preventDefault();
    const ProductName = supplierProductName;
    const HSCode = supplierHSCode;
    const SelectedCargo = selectedSupplierCargo;
    const SelectedTeamForProduct = selectedSupplierTeamForProduct;
    const query = {
      supplierName,
      ProductName,
      HSCode,
      SelectedCargo,
      SelectedTeamForProduct,
      // supplier_product_reference_id,
      // supplier_reference_id: supplierReferenceId
    };
    if (ProductName.length === 0) {
      setErrorTextForSupplierProducts(true);
    } else {
      console.log("SupplierProductQuery", query);
      await redirectURL
        .post("/exim/saveSupplierProductDetails", query)
        .then(async (resp) => {
          if (resp.data.status === "success") {
            setShow(true);
            setBasicType("success");
            setBasicTitle(resp.data.message);
            setSuppilerProductModel(false);
            await fetchSupplierProductDetails();
            await setTimeout(() => {
              const addProduct = [
                { value: supplierProductName, label: supplierProductName },
              ];
              setSupplierProductData((prevState) => [
                ...addProduct,
                ...prevState,
              ]);
            }, 500);

            setOverly("show-n");
            setSupplierProductName("");
            setSupplierHSCode("");
            setSupplierCargo("");
            setSelectSupplierTeamForProduct("");
          } else {
            setShow(true);
            setBasicType("danger");
            setBasicTitle(resp.data.message);
          }
        })
        .catch((e) => {
          console.log("Error message is", e);
        });
    }
    console.log(query, "query");
  };
  const onSaveProductDetails = async (e) => {
    e.preventDefault();
    const customerProductName = productName;
    const customerHSCode = hsCode;
    const customerSelectedCargo = selectedCargo;
    const customerSelectedTeamForProduct = selectedTeamForProduct;

    // const now = new Date();
    // const customer_product_reference_id = `${now.getTime()}${now.getMilliseconds()}`;

    // console.log(uniqueId);

    const query = {
      customerName,
      customerProductName,
      customerHSCode,
      customerSelectedCargo,
      customerSelectedTeamForProduct,
      // customer_product_reference_id,
      // customer_reference_id: customerReferenceId
    };
    if (customerProductName.length === 0) {
      setErrorTextForCustomerProducts(true);
    } else {
      console.log("customerProductQuery", query);
      await redirectURL
        .post("/exim/saveCustomerProductDetails", query)
        .then(async (resp) => {
          if (resp.data.status === "success") {
            setShow(true);
            setBasicType("success");
            setBasicTitle(resp.data.message);
            setOpenProductModel(false);
            await fetchCustomerProductDetails();

            await setTimeout(() => {
              console.log(productData, "before");
              const addProduct = [{ value: productName, label: productName }];
              setProductData((prevState) => [...addProduct, ...prevState]);
              console.log(productData, "after save product Data is");
            }, 500);
            // console.log("538",openProductModel)

            setOverly("show-n");
            setProductName("");
            setHSCode("");
            setCargo("");
            setSelectTeamForProduct("");
            // add the product name as value label and update the productData here
          } else {
            setShow(true);
            setBasicType("danger");
            setBasicTitle(resp.data.message);
          }
        });
      // save product details to exim_products
    }
    console.log(query, "query");
  };

  const onChangeCustomerName = (e) => {
    seteditcustomer((prev) => ({
      ...prev,
      customer: e.target.value,
    }));
    setIsSelectDisabled(false);
  };
  const validateCustomerName = (e) => {
    if (customerName.length === 0) {
      setIsSelectDisabled(true);
    }
  };
  const [isSupplierSelectDisabled, setIsSupplierSelectDisabled] =
    useState(true);
  const onValidateSupplierName = (e) => {
    if (supplierName.length === 0) {
      setIsSupplierSelectDisabled(true);
    }
  };
  const onChangeSupplierName = (e) => {
    setSupplierName(e.target.value);
    setIsSupplierSelectDisabled(false);
    seteditsupplier((prev) => ({
      ...prev,
      supplier: e.target.value,
    }));
  };

  const onChangeCustomerCode = (e) => {
    seteditcustomer((prev) => ({
      ...prev,
      customer_code: e.target.value,
    }));
  };

  const onChangeAdminEmail = (e) => {
    seteditcustomer((prev) => ({
      ...prev,
      admin_email: e.target.value,
    }));
  };
  const onChangeSupplierAdminEmail = (e) => {
    seteditsupplier((prev) => ({
      ...prev,
      admin_email: e.target.value,
    }));
  };

  const onChangeAdminName = (e) => {
    seteditcustomer((prev) => ({
      ...prev,
      admin_name_cust: e.target.value,
    }));
  };
  const onChangeSupplierAdminName = (e) => {
    seteditsupplier((prev) => ({
      ...prev,
      admin_name: e.target.value,
    }));
  };

  const onSelectTeam = (selectedOption) => {
    seteditcustomer((prev) => ({
      ...prev,
      team: selectedOption.value,
    }));
    setEnablePOC(false);
  };
  const onSelectSupplierTeam = (selectedOption) => {
    seteditsupplier((prev) => ({
      ...prev,
      team: selectedOption.value,
    }));
    setSupplierEnablePOC(false);
  };

  const onSelectTeamForProduct = (selectedOption) => {
    setSelectTeamForProduct(selectedOption);
  };
  const onSelectSupplierTeamForProduct = (selectedOption) => {
    setSelectSupplierTeamForProduct(selectedOption);
  };

  const onSelectPOC = (selectedOption) => {
    seteditcustomer((prev) => ({
      ...prev,
      poc: selectedOption.value,
    }));
  };
  const onSelectSupplierPOC = (selectedOption) => {
    seteditsupplier((prev) => ({
      ...prev,
      poc: selectedOption.value,
    }));
  };

  const enableShipmentType = () => {
    setShowShipmentType(true);
    setActiveItem("SEA");
  };
  const disbaleShipmentType = () => {
    setShowShipmentType(false);
    setActiveItem("AIR");
  };
  const enableLclCargoTypeDropDown = () => {
    setLclCargoType(true);
    setShipmentActive("LCL");
  };
  const disableLclCargoTypeDropDown = () => {
    setLclCargoType(false);
    setShipmentActive("FCL");
  };

  const onSelectDocumentType = (selectedOption) => {
    setSelectedDocType(selectedOption);
  };
  const [openDocUploadSlider, setOpenDocUploadSlider] = useState("");

  const onAddDocuments = (e) => {
    e.preventDefault();
    if (trackByValue && trackByValue.value === "") {
      setOpenDocUploadSlider("");
    } else {
      console.log(customerReferenceId, "1186");
      console.log("here");
      setOverly("show-m");
      setBasicTitle("");
      setBasicType("");
      setShow("");
      setOpenDocUploadSlider("slider-translate");
    }
  };
  const onClickHideAll = () => {
    setOverly("show-n");
    setOpenDocUploadSlider("");
  };
  const onDeleteDocument = (id) => {
    const updatedTotalDOcFields = totalDocFields.filter(
      (field) => field.id != id
    );
    setTotalDocFields(updatedTotalDOcFields);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const onUploadDocument = (e, id) => {
    console.log(e, "e");
    console.log(id, "id");
    const file = e.target.files[0];
    const fieldId = id;

    const ogData = [...totalDocFields];

    const upOgData = ogData.map(
      (f) => (f.id = "shipmentInput" == fieldId ? { ...f, file: file.name } : f)
    );
    setTotalDocFields(upOgData);
    console.log("totalDocFields", totalDocFields);
  };
  const changeAttachmentsFileHandler = (event) => {
    const file = event.target.files[0];
    console.log("file", file);
    let validFileExtensions = ["png", "jpeg", "pdf", "xlsx"];
    const documentExtension = file.name.split(".").pop();
    console.log("documentExtension", documentExtension);

    if (validFileExtensions.includes(documentExtension)) {
      const previousFile = attachmentsDoc;
      if (file && file !== previousFile) {
        setIsAttachmentDocsChanged(true);
        setAttachmentsDoc(file);
        setDocumentExtension(documentExtension);
      } else {
        setIsAttachmentDocsChanged(false);
      }
    } else {
      document.getElementById("attachmentsDoc").value = null;
      setShow(true);
      setBasicType("danger");
      setBasicTitle("Invalid file format.");
    }
  };
  const getEximTrackingDocuments = async () => {
    let data = {
      tracking_user_mapping_id: this.state.tracking_user_mapping_id,
    };

    await redirectURL
      .post("/exim/getEximTrackingDocuments", data)
      .then((resp) => {
        // console.log("getEximTrackingDocuments resp", resp.data);

        let trackingDocuments = resp.data.eximDocuments;
        let allDocuments = [];

        trackingDocuments.forEach((eachDocx) => {
          let eachDOcxDetails = eachDocx.eximDocuments[0];
          allDocuments.push(eachDOcxDetails);
        });

        this.setState({
          attachmentsDocArr: allDocuments,
        });
      })
      .catch((err) => {
        console.log("getEximTrackingDocuments err", err);
      });
  };
  const handleAddAttachmentsBtn = (e) => {
    e.preventDefault();
    if (attachmentTitle && attachmentsDoc) {
      console.log("attachmentsDoc", attachmentsDoc);
      const formData = new FormData();
      // formData.append("exim_container_no", this.state.container_no);
      if (trackByValue && trackByValue.value === "Container Number") {
        formData.append("container_no", containerNo);
      } else if (trackByValue && trackByValue.value === "BL Number") {
        formData.append("bill_of_lading_no", billOfLading);
      }
      formData.append("created_by", email);
      formData.append("document_title", attachmentTitle);
      formData.append("document_type", selectedDocType.value);
      formData.append("exim_createshipment_doc", attachmentsDoc);
      formData.append("document_extension", documentExtension);
      //   formData.append("customer_reference_mapping_id", customerReferenceNo);
      if (customerReferenceId) {
        formData.append("customer_reference_id", customerReferenceId);
      } else if (customerReferenceNo) {
        formData.append("customer_reference_id", customerReferenceNo);
      } else if (supplierReferenceNo) {
        formData.append("supplier_reference_id", supplierReferenceNo);
      } else if (supplierReferenceId) {
        formData.append("supplier_reference_id", supplierReferenceId);
      }
      console.log(formData, "1290");
      if (isAttachmentsDocChanged) {
        redirectURL
          .post("/exim/saveCreateShipmentTrackingDocuments", formData)
          .then((response) => {
            if (response.data.status === "success") {
              console.log("response.data56", response.data);



              document.getElementById("attachmentsDoc").value = null;
              setSelectedDocType("");
              setAttachmentTitle("");
              setDocumentExtension("");
              onClickHideAll();
              setAttachmentsDocArr((prevState) => [
                ...prevState,
                {
                  documentName: response.data.document_title,
                  documentType: response.data.document_type,
                  createdBy: email,
                },
              ]);
            }
          });
      }
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  };
  const deleteEximDocument = async (id, documentDetails) => {
    console.log("documentDetails", documentDetails);
    console.log("id", id);

    let payload = {
      documentName: documentDetails.documentName,
    };
    console.log("payload", payload);
    await redirectURL
      .post("/exim/deleteCustomerEximDocument", payload)
      .then(async (resp) => {
        console.log("deleteEximDocument", resp.data);
        if (resp.data.success) {
          //   await this.getEximTrackingDocuments();
          fetchTableItemsForDocs();
          setShow(true);
          setBasicType("success");
          setBasicTitle(resp.data.message);
        }
      })
      .catch((err) => {
        console.log("deleteEximDocument err", err);
      });
  };
  // const onSubmitForm = async (e) => {
  //     e.preventDefault();

  //     if(trackByValue && selectShippingLine &&  selectMovementType && trackByValue.value !== "" && selectShippingLine.value !== "" && selectMovementType.value !==""){
  //         console.log("entered")
  //         const shipmentDetails = {
  //             contract_number: contractNumber,
  //             bill_of_lading_date: billOfLadingDate,
  //             transit_days: transitDays,
  //             po_no: PONumber,
  //             pick_up_date: pickUpDate,
  //             origin_port: customerOriginPort,
  //             stuffing_location: customerStuffingLocation,
  //             destination_location: customerDestinationPort,
  //             destuffing_location: customerDestuffingLocation,
  //             invoice_no: invoiceNo,
  //             invoice_date: invoiceDate,
  //             free_trade_agreement: FTAValue,
  //             rdb_value: RDBValue,
  //             epcg_value: EPCGValue,
  //             demurrage_free_days: demurrageFreeDays,
  //             detention_free_days: detentionFreeDays,
  //             cfs_free_days: CFSFreeDays,
  //         }
  //         if(customerReferenceId){
  //             shipmentDetails.customer_reference_id = customerReferenceId
  //         } else if(customerReferenceNo){
  //             shipmentDetails.customer_reference_id = customerReferenceNo
  //         } else if(supplierReferenceId){
  //             shipmentDetails.supplier_reference_id = supplierReferenceId;
  //         } else if(supplierReferenceNo){
  //             shipmentDetails.supplier_reference_id = supplierReferenceNo
  //         }
  //         if(selectMovementType && selectMovementType.value !==""){
  //             shipmentDetails.movement_type= selectMovementType.value;
  //         }
  //         // if(shippingLine && shippingLine.value !==""){
  //         //     shipmentDetails.shipping_line = selectShippingLine.value;
  //         // }
  //         if(selectOwner && selectOwner.value !==""){
  //             shipmentDetails.shipment_owner = selectOwner.value;
  //         }
  //         if(customer && customer.value !==""){
  //             shipmentDetails.customer = customer;
  //         }
  //         if(selectShippingLine && selectShippingLine.value !== ""){
  //             shipmentDetails.shipping_line = selectShippingLine.value;
  //         }
  //         if(supplier && supplier.value !== ""){
  //             shipmentDetails.supplier = supplier;
  //         }
  //         if(LCValue && LCValue.value!==""){
  //             shipmentDetails.letter_of_credit = LCValue;
  //         }
  //         if(shipperType && shipperType.value !==""){
  //             shipmentDetails.shipper_type = shipperType;
  //         }
  //         if(trackByValue && trackByValue.value === "Container Number"){
  //             shipmentDetails.container_no = containerNo
  //         }else{
  //             shipmentDetails.bill_of_lading_no = billOfLading
  //         }
  //         if(activeItem === "SEA"){
  //             shipmentDetails.cargo_mode = "SEA";
  //             if(shipmentActive === "FCL"){
  //                 shipmentDetails.shipment_type = "FCL";
  //                 if(selectCargoType && selectCargoType.value !==""){
  //                     shipmentDetails.selected_cargo_type = selectCargoType.value;
  //                 }
  //             }else {
  //                 shipmentDetails.shipment_type = "LCL";
  //                 if(selectCargoType && selectCargoType.value !==""){
  //                     shipmentDetails.selected_cargo_type = selectCargoType.value;
  //                 }
  //             }
  //         } else{
  //             shipmentDetails.cargo_mode = "AIR"
  //             if(selectCargoType && selectCargoType.value !==""){
  //                 shipmentDetails.selected_cargo_type = selectCargoType.value;
  //             }
  //         }
  //         console.log("LCValue",LCValue)
  //         if(LCValue && LCValue.value==="yes"){
  //             shipmentDetails.lc_no = LCNumber;
  //         }
  //         console.log("shipmentDetails", shipmentDetails)
  //         await redirectURL
  //         .post("/exim/storeCreatedShipmentDetails", shipmentDetails)
  //         .then((resp) => {
  //             if(resp.data.status ==="success"){
  //                 // window.location.reload()
  //                 setContractNumber("");
  //                 setSelectMovementType("");
  //                 setSelectCargoType({value:"",label:""})
  //                 setTrackByValue({value:"", label:""})
  //                 setContainerNo("");
  //                 setBillOfLadingDate("");
  //                 setShippingLine({value:"", label:""});
  //                 setTransitDays("");
  //                 setCustomer({value:"", label:""});
  //                 // setShippingLine({value:"", label:""});
  //                 setCustomerReferenceNo("");
  //                 setSelectOwner("");
  //                 setSupplier("");
  //                 setSupplierReferenceNo("");
  //                 setPONumber("");
  //                 setShipperType("");
  //                 setPickUpDate("");
  //                 setCustomerOriginPort("");
  //                 setCustomerStuffingLocation("");
  //                 setCustomerDestinationPort("");
  //                 setCustomerDestuffingLocation("");
  //                 setInvoiceNo("");
  //                 setInvoiceDate("");
  //                 setFTAValue({value:"", label:""});
  //                 setRDBValue({value:"", label:""})
  //                 setEPCGValue({value:"", label:""});
  //                 setLCValue({value:"", label:""});
  //                 setLCNumber("");
  //                 setDemurrageFreeDays("");
  //                 setDetetentionFreeDays("");
  //                 setCFSFreeDays("");
  //                 setAttachmentsDocArr("");
  //                 setCustomerReferenceId("");
  //                 setSupplierReferenceId("");

  //                 setInvoiceDate("")
  //                 setBillOfLadingDate("");
  //                 setPickUpDate("");
  //                 document.getElementById("invoiceDate").value = ""
  //                 document.getElementById("pickUpDate").value = ""
  //                 document.getElementById("billOfLadingDate").value = "";
  //             }
  //         })
  //         .catch((e)=>{
  //             console.log(e)
  //         })
  //     } else if(trackByValue && trackByValue.value===""){
  //         // console.log("enter trackBy Value")
  //         setShow(true);
  //         setBasicTitle("Select Track By Type")
  //         setBasicType("danger")

  //     } else if(selectShippingLine && selectShippingLine.value === ""){
  //         // console.log("enter trackBy Value")
  //         setShow(true);
  //         setBasicTitle("Select Shipping Line")
  //         setBasicType("danger")
  //     } else if(selectMovementType && selectMovementType.value === ""){
  //         setShow(true);
  //         setBasicTitle("Select Movement Type")
  //         setBasicType("danger")
  //     }

  // }
  const onSubmitForm = (e) => {
    e.preventDefault();
    var params = {};
    var formDetails = {
      contract_number: editDetails.contract_number,
      movement_type: editDetails.movement_type,
      cargo_mode: editDetails.cargo_mode,
      shipment_type: editDetails.shipment_type,
      selected_cargo_type: editDetails.selected_cargo_type,
      track_by: editDetails.track_by,
      bill_of_lading_date: editDetails.bill_of_lading_date,
      shipping_line: editDetails.shipping_line,
      transit_days: editDetails.transit_days,
      shipment_owner: editStakeholderDetail.shipment_owner,
      po_no: editStakeholderDetail.po_no,
      freight_forwarder: editStakeholderDetail.freightForwarder,
      custom_house_agent: editStakeholderDetail.customHouseAgent,
      pick_up_date: editStakeholderDetail.pickUpDate,
      shipper_type: editStakeholderDetail.shipper_type,
      origin_port: editStakeholderDetail.origin_port,
      stuffing_location: editStakeholderDetail.stuffing_location,
      destination_location: editStakeholderDetail.destination_location,
      destuffing_location: editStakeholderDetail.destuffing_location,
      invoice_no: editAdditionalDetails.invoice_no,
      invoice_date: editAdditionalDetails.invoice_date,
      free_trade_agreement: editAdditionalDetails.free_trade_agreement,
      rd_benefit_value: editAdditionalDetails.rdb_value,
      epcg_value: editAdditionalDetails.epcg_value,
      letter_of_credit: editAdditionalDetails.letter_of_credit,
      incoterm: editAdditionalDetails.incoterm,
      demurrage_free_days: editdemurrageDetails.demurrage_free_days,
      detention_free_days: editdemurrageDetails.detention_free_days,
      cfs_free_days: editdemurrageDetails.cfs_free_days,
      shipment_unique_id: props.shipment_unique_id,

      demurrage_and_detention_port: editdemurrageDetails.portName.label,
      demurrage_and_detention_port_code: editdemurrageDetails.portName.value,
    };
    if (LCNumber !== "" && LCNumber !== null && LCNumber !== undefined)
      formDetails.lc_no = LCNumber
    params.formDetails = formDetails;
    var supplierDetails = {
      supplier: editsupplier.supplier,
      admin_name: editsupplier.admin_name,
      admin_email: editsupplier.admin_email,
      team: editsupplier.team,
      poc: editsupplier.poc,
    };
    params.supplierDetails = supplierDetails;
    var customerDetails = {
      customer: editcustomer.supplier,
      admin_name: editcustomer.admin_name_cust,
      admin_email: editcustomer.admin_email_cust,
      team: editcustomer.team_cust,
      poc: editcustomer.poc_cust,
    };
    params.customerDetails = customerDetails;
    if (billOfLading != "" && billOfLading != undefined) {
      params.bill_of_lading_no = billOfLading;
    }
    // console.log(params,"paramssssssssssssss");
    redirectURL.post("/exim/updateshipment", params).then((resp) => {
      console.log(resp.data);
      if (resp.data.status === "success") {
        props.closeModal();
      }
    });
  };

  // console.log(editDetails, "recordforedit");
  var bill_lading_date = editDetails.bill_of_lading_date;
  // console.log(editcustomer, "1509");
  // console.log(submitDisable, "submitDisable");
  return (
    <>
      <div
        id="edit-shipment"
        className=""
        style={{ minHeight: "87vh", width: "95%", margin: "auto" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card custom-card" style={{ padding: "20px" }}>
              <div className="card-body" style={{ padding: "0px" }}>
                <form
                  method="POST"
                  className="form-theme"
                  onSubmit={onSubmitForm}
                >
                  <div className="row" style={{ marginBottom: "25px" }}>
                    <div className="col-xl-12 col-lg-12">
                      <div className="form-group row">
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Contract Number
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                            }}
                            type="text"
                            // placeholder="contract number"
                            id="shipmentInput"
                            value={editDetails.contract_number}
                            autoComplete="off"
                            // onChange={onChangeContractNumber}
                            className="inputEditShipment"
                            readOnly
                          //required
                          />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                          <label className="custom-label">
                            Select Movement Type
                          </label>
                          <Select
                            // placeholder="movement type"
                            closeMenuOnSelect={true}
                            value={{
                              label: editDetails.movement_type,
                              value: editDetails.movement_type,
                            }}
                            // className="form-control"
                            styles={customStyles}
                            id="shipmentInput"
                            isDisabled
                            // onChange={onSelectMovementType}
                            options={movementType}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{}}>
                    <div className="col-xl-12 col-lg-12">
                      <h6 className="custom-header">Shipment Details</h6>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "25px" }}>
                    {/* <div className={`${activeItem === "SEA"? 'col-xl-3 col-lg-6':"col-xl-5 col-lg-5"}`}> */}
                    <div
                      id="create-shipment"
                      className="col-xl-2 col-lg-2"
                      style={{ marginRight: "20px" }}
                    >
                      <div className="row">
                        <div
                          className="col-xl-12 col-lg-12"
                          style={{
                            paddingTop: "12px",
                            padding: "12px",
                            // background: "#f2f6ff",
                            marginRight: "10px",
                            borderRadius: "13px",
                          }}
                        >
                          <div className="shipmenttype-card" style={{ width: "177px", border: "1px" }} >
                            <ul className="icon-list">
                              <li
                                className={`icon-item ${editDetails.cargo_mode === "SEA" ? "active" : ""
                                  }`}
                              //onClick={enableShipmentType}
                              >
                                <FaShip
                                  className={`icon ${editDetails.cargo_mode === "SEA" ? "active-icon" : ""
                                    }`}
                                />
                                <span
                                  className={`icon-label ${editDetails.cargo_mode === "SEA" ? "active-label" : ""
                                    }`}
                                >
                                  SEA
                                </span>
                              </li>
                              <li
                                className={`icon-item ${editDetails.cargo_mode === "AIR" ? "active" : ""
                                  }`}
                              //onClick={disbaleShipmentType}
                              >
                                <FaPlane
                                  className={`icon ${editDetails.cargo_mode === "AIR" ? "active-icon" : ""
                                    }`}
                                />
                                <span
                                  className={`icon-label ${editDetails.cargo_mode === "AIR" ? "active-label" : ""
                                    }`}
                                >
                                  AIR
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showShipmentType ? (
                      <div
                        id="create-shipment"
                        className="col-xl-2 col-lg-2"
                        style={{
                          paddingTop: "12px",
                          padding: "12px",
                          // background: "#f2f6ff",
                          marginRight: "10px",
                          borderRadius: "13px",
                        }}
                      >
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <div className="shipmenttype-card" style={{ width: "177px", border: "1px" }}>
                              <ul className="icon-list">
                                <li
                                  className={`icon-item ${shipmentActive === "FCL" ? "active" : ""
                                    }`}
                                //onClick={disableLclCargoTypeDropDown}
                                >
                                  <FaBox
                                    className={`icon ${editDetails.shipment_type === "FCL"
                                      ? "active-icon"
                                      : ""
                                      }`}
                                  />
                                  <span
                                    className={`icon-label ${editDetails.shipment_type === "FCL"
                                      ? "active-label"
                                      : ""
                                      }`}
                                  >
                                    FCL
                                  </span>
                                </li>
                                <li
                                  className={`icon-item ${editDetails.shipment_type === "LCL" ? "active" : ""
                                    }`}
                                //onClick={enableLclCargoTypeDropDown}
                                >
                                  <FaBox
                                    className={`icon ${editDetails.shipment_type === "LCL"
                                      ? "active-label"
                                      : ""
                                      }`}
                                  />
                                  <span
                                    className={`icon-label ${editDetails.shipment_type === "LCL"
                                      ? "active-label"
                                      : ""
                                      }`}
                                  >
                                    LCL
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {!lclCargoType || activeItem === "AIR" ? (
                      <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Type of Shipment</label>
                        <Select
                          placeholder="Type of Cargo"
                          style={{ fontSize: "10px" }}
                          closeMenuOnSelect={true}
                          value={{
                            label: editDetails.selected_cargo_type,
                            value: editDetails.selected_cargo_type,
                          }}
                          // className="form-control"
                          styles={customStyles}
                          // onChange={onSelectCargoType}
                          options={cargoType}
                          isDisabled
                          id="shipmentInput"
                        />
                      </div>
                    ) : (
                      <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Type of Shipment</label>
                        <Select
                          placeholder="Type of Cargo"
                          closeMenuOnSelect={true}
                          value={selectLclCargoType}
                          // className="form-control"
                          styles={customStyles}
                          onChange={onSelectLclCargoType}
                          isDisabled
                          options={lclCargoList}
                          id="shipmentInput"
                        />
                      </div>
                    )}
                    <div className="col-xl-3 col-lg-3">
                      <label className="custom-label">
                        Shipment Created With
                      </label>
                      <Select
                        closeMenuOnSelect={true}
                        value={{
                          label: trackByValue.label,
                          value: editDetails.track_by,
                        }}
                        id="shipmentInput"
                        // className="form-control"
                        styles={customStyles}
                        //   onChange={onSelectTrackByType}
                        // options={trackByValue.value}
                        isDisabled
                      />
                    </div>
                    {editDetails.track_by === "mbl_number" ? (
                      <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">
                          Bill of Lading Number
                        </label>
                        <input
                          style={{
                            borderRadius: "6px",
                            backgroundColor: "#f2f2f2",
                            border: "none",
                          }}
                          type="text"
                          placeholder="MBL number"
                          id="shipmentInput"
                          value={editDetails.mbl_number}
                          // onChange={onChangeBillOfLading}
                          autoComplete="off"
                          className="inputEditShipment"
                          disabled={inputDisabled}
                        //required
                        />
                      </div>
                    ) : (
                      <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Container Number</label>
                        <input
                          style={{
                            borderRadius: "6px",
                            backgroundColor: "#f2f2f2",
                            border: "none",
                          }}
                          type="text"
                          placeholder="Container Number"
                          id="shipmentInput"
                          value={editDetails.container_no}
                          autoComplete="off"
                          // onChange={onChangeContainerNo}
                          onBlur={validateContainerNo}
                          className="inputEditShipment"
                          disabled={inputDisabled}
                        //required
                        />
                        {errorMssge ? (
                          <span className="error-mssge">{errorMssge}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                  <div className="row" style={{ marginBottom: "25px" }}>
                    <div className="col-xl-2 col-lg-2" style={{}}>
                      <label className="custom-label">
                        Bill of Lading Date
                      </label>
                      <div>
                        <DateTime
                          //className="date-time-container"
                          inputProps={{
                            id: "datetimeid",
                            name: "billOfLadingDate",
                          }}
                          initialValue={new Date(editDetails.bill_of_lading_date)}
                          value={new Date(editDetails.bill_of_lading_date)}
                          onChange={onHandleDateChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2">
                      <label className="custom-label">Shipping Line:</label>
                      <Select
                        // placeholder="Select Shipping Line"
                        closeMenuOnSelect={true}
                        value={{
                          label: editDetails.shipping_line,
                          value: editDetails.shipping_line,
                        }}
                        id="shipmentInput"
                        styles={customStyles}
                        onChange={onSelectShippingLine}
                        options={shippingLine}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2">
                      <label className="custom-label">
                        Promised Transit Days
                      </label>
                      <input
                        style={{
                          borderRadius: "6px",
                          backgroundColor: "#f2f4f6",
                          border: "none",
                          height: "2.4rem"
                        }}
                        type="number"
                        placeholder=""
                        id="shipmentInput"
                        value={editDetails.transit_days}
                        onChange={onChangeTransitDays}
                        autoComplete="off"
                        className="form-control"
                      //required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ paddingTop: "30px" }}
                    >
                      <h6 className="custom-header">Stakeholder Details</h6>
                    </div>
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        {editDetails.movement_type == "Import" ? (
                          <>
                            <div className="col-xl-2 col-lg-2">
                              <label className="custom-label">Customer:</label>
                              <Select
                                type="text"
                                placeholder=""
                                value={customer}
                                options={customerData}
                                autoComplete="off"
                                id="shipmentInput"
                                onChange={onChangeCustomer}
                                // className="form-control"
                                styles={customStyles}
                              // //required
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {editDetails.movement_type == "Export" ? (
                          <>
                            <div className="col-xl-2 col-lg-2">
                              <label className="custom-label">Supplier:</label>
                              <Select
                                type="text"
                                placeholder=""
                                value={supplier}
                                id="shipmentInput"
                                options={supplierData}
                                autoComplete="off"
                                onChange={onChangeSupplier}
                                // className="form-control"
                                styles={customStyles}
                              //required
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Shipment Owner:
                          </label>
                          <Select
                            placeholder="Shipment Owner"
                            closeMenuOnSelect={true}
                            value={{
                              label: editStakeholderDetail.shipment_owner,
                              value: editStakeholderDetail.shipment_owner,
                            }}
                            id="shipmentInput"
                            styles={customStyles}
                            // className="form-control"
                            onChange={onSelectOwner}
                            options={Owners}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3">
                          <label className="custom-label">
                            Purchase Order Number
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                            type="text"
                            placeholder="PO Number"
                            id="shipmentInput"
                            value={editStakeholderDetail.po_no}
                            autoComplete="off"
                            onChange={onChangePONumber}
                            className="form-control"
                          //required
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 col-xl-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        <div className="col-lg-2 col-xl-2">
                          <label className="custom-label" style={{ whiteSpace: "nowrap" }}>
                            Freight Forwarder
                          </label>
                          <input
                            type="text"
                            id="freightForwarder"
                            className="form-control"
                            value={editStakeholderDetail.freightForwarder}
                            onChange={onChangefreightForwarder}
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                          />
                          {/* <label
                                className="custom-label"
                                htmlFor="freightForwarder"
                              >
                                
                              </label> */}
                        </div>
                        <div className="col-xl-3 col-lg-3">
                          <label className="custom-label" style={{ whiteSpace: "nowrap" }}>
                            Custom House Agent
                          </label>
                          <input
                            type="text"
                            id="customHouseAgent"
                            className="form-control"
                            value={editStakeholderDetail.customHouseAgent}
                            onChange={onChangeCustomHouseAgent}
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                          />
                          {/* &nbsp;<label
                                className="custom-label"
                                htmlFor="customHouseAgent"
                              >
                                
                              </label> */}
                        </div>
                        <div className="col-xl-2 col-lg-2" style={{}}>
                          <label className="custom-label">Pickup Date</label>
                          <div>
                            {/* {console.log("pickUpDate ", pickUpDate)} */}
                            <DateTime
                              //className="date-time-container"
                              inputProps={{
                                id: "datetimeid",
                                //id: "pick_up_date",
                                name: "pickUpDate",
                              }}
                              initialValue={new Date(editStakeholderDetail.pickUpDate)}
                              value={new Date(editStakeholderDetail.pickUpDate)}
                              onChange={onHandlePickUpDateChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Origin Address:
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                            type="text"
                            placeholder="Origin Address"
                            value={editStakeholderDetail.origin_port}
                            autoComplete="off"
                            onChange={onChangeCustomerOrigin}
                            className="form-control"
                            //required
                            id="shipmentInput"
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Stuffing Location:
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                            type="text"
                            placeholder="Stuffing Location"
                            value={editStakeholderDetail.stuffing_location}
                            autoComplete="off"
                            onChange={onChangeCustomerStuffingLocation}
                            className="form-control"
                            //required
                            id="shipmentInput"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-12 col-lg-12" style={{marginBottom:"25px"}}>
                            <div className="row">
                                                                
                            </div>
                        </div> */}
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">

                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Destination Address:
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                            type="text"
                            placeholder="Destination Address"
                            value={editStakeholderDetail.destination_location}
                            autoComplete="off"
                            onChange={onChangeCustomerDestination}
                            className="form-control"
                            id="shipmentInput"
                          //required
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Destuffing Location:
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                            type="text"
                            placeholder="Destuffing Location"
                            value={editStakeholderDetail.destuffing_location}
                            autoComplete="off"
                            id="shipmentInput"
                            onChange={onChangeCustomerDestuffingLocation}
                            className="form-control"
                          //required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ paddingTop: "30px" }}
                    >
                      <h6 className="custom-header">Additional Details</h6>
                    </div>
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Invoice Number:
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                            type="text"
                            placeholder=""
                            value={editAdditionalDetails.invoice_no}
                            id="shipmentInput"
                            autoComplete="off"
                            onChange={onChangeInvoiceNo}
                            className="form-control"
                          //required
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2" style={{}}>
                          <label className="custom-label">Invoice Date</label>
                          <div>
                            <DateTime
                              //className="date-time-container"
                              inputProps={{
                                id: "datetimeid",
                                //id: "invoiceDate",
                                name: "invoiceDate",
                              }}
                              initialValue={new Date(editAdditionalDetails.invoice_date)}
                              value={new Date(editAdditionalDetails.invoice_date)}
                              onChange={onHandleInvoiceDateChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3">
                          <label className="custom-label">
                            Free Trade Agreement:
                          </label>
                          <Select
                            placeholder="Trade Agreement"
                            closeMenuOnSelect={true}
                            value={{
                              label: editAdditionalDetails.free_trade_agreement,
                              value: editAdditionalDetails.free_trade_agreement,
                            }}
                            styles={customStyles}
                            id="shipmentInput"
                            // className="form-control"
                            onChange={onSelectFTA}
                            options={FTAOptions}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4">
                          <label className="custom-label">
                            Research & Development Benefit Certificate:
                          </label>
                          <Select
                            placeholder="RDB Certificate"
                            closeMenuOnSelect={true}
                            value={{
                              label: editAdditionalDetails.rdb_value,
                              value: editAdditionalDetails.rdb_value,
                            }}
                            id="shipmentInput"
                            styles={customStyles}
                            // className="form-control"
                            onChange={onSelectRDB}
                            options={RDBOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">

                        <div className="col-xl-4 col-lg-4">
                          <label className="custom-label">
                            Export Promotion Capital Goods (EPCG):
                          </label>
                          <Select
                            placeholder="EPCG Certificate"
                            closeMenuOnSelect={true}
                            value={{
                              label: editAdditionalDetails.epcg_value,
                              value: editAdditionalDetails.epcg_value,
                            }}
                            styles={customStyles}
                            onChange={onSelectEPCG}
                            options={EPCGOptions}
                            id="shipmentInput"
                          />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                          <label className="custom-label" style={{ whiteSpace: "nowrap" }}>
                            LC Available (Letter of Credit):
                          </label>
                          <Select
                            placeholder="LC Certificate"
                            closeMenuOnSelect={true}
                            value={{
                              label: editAdditionalDetails.letter_of_credit,
                              value: editAdditionalDetails.letter_of_credit,
                            }}
                            id="shipmentInput"
                            styles={customStyles}
                            onChange={onSelectLC}
                            options={LCOptions}
                          />
                        </div>
                        {editAdditionalDetails.letter_of_credit && editAdditionalDetails.letter_of_credit === "yes" ? (
                          <div className="col-xl-3 col-lg-3">
                            <label className="custom-label">
                              Letter of Credit Number
                            </label>
                            <input
                              style={{
                                borderRadius: "6px",
                                backgroundColor: "#f2f4f6",
                                border: "none",
                                height: "2.4rem"
                              }}
                              type="text"
                              placeholder="LC Number"
                              value={LCNumber}
                              id="shipmentInput"
                              autoComplete="off"
                              onChange={onChangeLCNumber}
                              className="form-control"
                            //required
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Incoterms:
                          </label>
                          <Select
                            name=""
                            closeMenuOnSelect={true}
                            value={{
                              label: editAdditionalDetails.incoterm,
                              value: editAdditionalDetails.incoterm,
                            }}
                            id="shipmentInput"
                            styles={customStyles}
                            onChange={onSelectIncoterms}
                            options={incotermsList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ paddingTop: "30px" }}
                    >
                      <h6 className="custom-header added-style-demurrage">
                        Detention Details
                      </h6>
                    </div>
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        {/* <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Demurrage Free Days:
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                            }}
                            type="number"
                            placeholder=""
                            value={editdemurrageDetails.demurrage_free_days}
                            id="shipmentInput"
                            autoComplete="off"
                            onChange={onChangeDemurrageDays}
                            className="form-control"
                            //required
                          />
                        </div> */}
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">Port</label>
                          {/* <Select
                            closeMenuOnSelect={true}
                            name="portName"
                            className="border-radius-0"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                fontSize: "0.8rem",
                                color: "#6e707e",
                                width: "93%",
                              }),
                            }}
                            onChange={onSelectDocumentType}
                            options={state.portOptions}
                            value={state.portName}
                            //required
                          /> */}

                          <Select
                            closeMenuOnSelect={true}
                            name="portName"
                            className="border-radius-0"
                            onChange={onChangePrt}
                            options={state.portOptions}
                            value={editdemurrageDetails.portName}
                            required
                          />

                        </div>
                        <div className="col-xl-2 col-lg-2" style={{}}>
                          <label className="custom-label">
                            Detention Free Days:
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                              height: "2.4rem"
                            }}
                            type="number"
                            placeholder=""
                            value={editdemurrageDetails.detention_free_days}
                            id="shipmentInput"
                            autoComplete="off"
                            onChange={onChangeDetentionDays}
                            className="form-control"
                          //required
                          />
                        </div>
                        {/* <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">CFS Free Days:</label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                            }}
                            type="number"
                            placeholder=""
                            id="shipmentInput"
                            value={editdemurrageDetails.cfs_free_days}
                            autoComplete="off"
                            onChange={onChangeCFSDays}
                            className="form-control"
                            //required
                          />
                        </div> */}
                        {/* <div className="col-xl-2 col-lg-2">
                                    <label className="custom-label">Port:</label>
                                    <Select
                                        placeholder="Select Port"
                                        closeMenuOnSelect={true}
                                        value={selectShippingLine}
                                        id="shipmentInput"
                                        styles={customStyles} 
                                        onChange={onSelectShippingLine}
                                        options={shippingLine}
                                    />
                                </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col bg-white">
                      <button
                        className="btn btn-primary"
                        onClick={onAddDocuments}
                      >
                        Add Shipment Documents
                      </button>

                      <div className="row pl-3 mr-1">
                        <div className="col px-0">
                          {attachmentsDocArr.length === 0 ? (
                            <label className="custom-label" htmlFor="">
                              No Documents are attached
                            </label>
                          ) : (
                            <div
                              className="row my-2"
                              style={{ paddingLeft: "12px" }}
                            >
                              <table
                                className="table table-bordered tptinfo"
                                style={{ overflowY: "auto" }}
                              >
                                <thead>
                                  <th>
                                    <label className="custom-label" htmlFor="">
                                      Document Name
                                    </label>
                                  </th>
                                  <th>
                                    <label className="custom-label" htmlFor="">
                                      Document Type
                                    </label>
                                  </th>
                                  <th>
                                    <label className="custom-label" htmlFor="">
                                      Created By
                                    </label>
                                  </th>
                                  <th>
                                    <label className="custom-label" htmlFor="">
                                      Created At
                                    </label>
                                  </th>
                                  <th></th>
                                </thead>
                                <tbody>
                                  {(!attachmentsDocArr ||attachmentsDocArr.length===0 || attachmentsDocArr===undefined)?<></>:<>
                                  {attachmentsDocArr.map((eachDocx, index) => {
                                    let createdDate = eachDocx.created_at;
                                    createdDate = moment
                                      .parseZone(createdDate)
                                      .format("MM/DD/YY, HH:mm A");
                                    return (
                                      <tr key={index}>
                                        <td>{eachDocx.documentName}</td>
                                        <td>{eachDocx.documentType}</td>
                                        <td>{email}</td>
                                        <td>{createdDate}</td>
                                        <td style={{ width: "95px" }}>
                                          <div>
                                            <i
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                              }}
                                              class="fa fa-trash"
                                              aria-hidden="true"
                                              onClick={() =>
                                                deleteEximDocument(
                                                  index,
                                                  eachDocx
                                                )
                                              }
                                            ></i>
                                            <a
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                color: "black",
                                              }}
                                              href={`${eachDocx.document_url}`}
                                            >
                                              <i
                                                class="fa fa-download mx-2"
                                                aria-hidden="true"
                                              ></i>
                                            </a>
                                            {/* <i
                                                        style={{
                                                        fontSize: "20px",
                                                        cursor: "pointer",
                                                        }}
                                                        class="fa fa-eye"
                                                        aria-hidden="true"
                                                        onClick={this.previewDocument.bind(
                                                        this,
                                                        eachDocx
                                                        )}
                                                    ></i> 
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  </>
                                  }
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className={"slide-r " + openDocUploadSlider}
            style={{ overflow: "auto" }}
          >
            <div className="document-slider bg-primary text-white pt-2 px-3">
              <h6>
                {" "}
                Add Document
                <span
                  className="float-right closebtn"
                  style={{ marginRight: "25px" }}
                  onClick={onClickHideAll}
                >
                  X
                </span>
              </h6>
            </div>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="p-2 d-flex flex-column">
                <div className="pb-3">
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#ffffff",
                    }}
                    name="attachmentTitle"
                    className="form-control mr-3 mt-1"
                    id="shipmentInput"
                    placeholder="Document Name"
                    styles={{ width: "80%" }}
                    onChange={(e) => setAttachmentTitle(e.target.value)}
                    value={attachmentTitle}
                  //required
                  />
                </div>

                <div className="pb-3">
                  <Select
                    closeMenuOnSelect={true}
                    name="modeType"
                    className="border-radius-0"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        fontSize: "0.8rem",
                        color: "#6e707e",
                        width: "93%",
                      }),
                    }}
                    onChange={onSelectDocumentType}
                    options={documentOptions}
                    value={selectedDocType}
                  //required
                  />
                </div>
                <div className="">
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="file"
                    name="attachmentsDoc"
                    styles={{ fontSize: "0.8rem" }}
                    id="attachmentsDoc"
                    onChange={changeAttachmentsFileHandler}
                  // className="col-7 mt-3 border mr-3"
                  //required
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary mt-3"
                    onClick={handleAddAttachmentsBtn}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SweetAlert
            show={show}
            type={basicType}
            title={basicTitle}
            onConfirm={closeAlert}
          ></SweetAlert>
          <Modal
            open={openCustomerModel}
            onClose={onCloseCustomerModal}
            center
            classNames={{
              overlayAnimationIn: 'customEnterOverlayAnimation',
              overlayAnimationOut: 'customLeaveOverlayAnimation',
              modalAnimationIn: 'customEnterModalAnimation',
              modalAnimationOut: 'customLeaveModalAnimation',
            }}
            animationDuration={400}
          >
            <div
              className="row"
              style={{ width: "100%", zIndex: "9999" }}
            >
              <div className="col-xl-12 col-lg-12">
                <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Edit Customer</h5>
              </div>
              <div className="add-customer-container row">
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Customer Name:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={editcustomer.customer}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeCustomerName}
                    onBlur={validateCustomerName}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">
                    Customer Reference Number:
                  </label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={editcustomer.customerReferenceId}
                    autoComplete="off"
                    id="shipmentInput"
                    isDisabled
                    // onChange={onChangeCustomerReferenceNo}
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Customer Code:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={editcustomer.customer_code}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeCustomerCode}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Admin Name:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={editcustomer.admin_name_cust}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeAdminName}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Admin Email:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="email"
                    placeholder=""
                    value={editcustomer.admin_email_cust}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeAdminEmail}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Team:</label>
                  <Select
                    placeholder="Team"
                    closeMenuOnSelect={true}
                    value={editcustomer.team_cust}
                    id="shipmentInput"
                    styles={customStyles}
                    onChange={onSelectTeam}
                    options={teamList}
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">POC:</label>
                  <Select
                    placeholder="POC"
                    closeMenuOnSelect={true}
                    value={editcustomer.poc_cust}
                    styles={customStyles}
                    id="shipmentInput"
                    onChange={onSelectPOC}
                    options={pointOfContacts}
                    isDisabled={enablePOC}
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Product:</label>
                  <Select
                    type="text"
                    placeholder=""
                    value={product}
                    options={productData}
                    id="shipmentInput"
                    autoComplete="off"
                    isDisabled
                    // onChange={onChangeProduct}
                    // className="form-control"
                    styles={customStyles}
                  //required
                  // isDisabled={isSelectDisabled}
                  />
                </div>
              </div>
              <div
                className="col-xl-12 col-lg-12"
                style={{ borderTop: "1px solid" }}
              >
                <div className="modal-footer">
                  <div className="modal-action-buttons">
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onClickHideCustomerModal}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onSaveCustomerDetails}
                      type="button"
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={openProductModel}
            onClose={onCloseProductModal}
            style={{ width: "800px", height: "625px !important" }}
            classNames={{
              modal:
                selectedTeamForProduct.length > 1
                  ? "increase-product-modal"
                  : "custom-product-class",
              overlayAnimationIn: 'customEnterOverlayAnimation',
              overlayAnimationOut: 'customLeaveOverlayAnimation',
              modalAnimationIn: 'customEnterModalAnimation',
              modalAnimationOut: 'customLeaveModalAnimation',
            }}
            animationDuration={400}
            center>
            <div
              className="row"
              style={{ width: "100%", zIndex: "10000" }}
            >
              <div className="col-xl-12 col-lg-12">
                <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Edit Product</h5>
              </div>
              <div className="add-customer-container row">
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">Products Name:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={productName}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeProductName}
                    onBlur={validateCustomerProductName}
                    className="form-control"
                  //required
                  />
                  {errorTxtForCustomerProducts ? (
                    <span className="error-text">*Enter product name</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">HS Code:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={hsCode}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeHSCode}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">Cargo Type:</label>
                  <Select
                    placeholder="Team"
                    closeMenuOnSelect={true}
                    value={selectedCargo}
                    styles={customStyles}
                    id="shipmentInput"
                    onChange={setProductCargoType}
                    options={cargoList}
                  />
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ marginBottom: "65px" }}
                >
                  <label className="custom-label">Select Team:</label>
                  <Select
                    placeholder="Team"
                    closeMenuOnSelect={true}
                    id="shipmentInput"
                    value={selectedTeamForProduct}
                    className={
                      selectedTeamForProduct.length > 2
                        ? "tall-select"
                        : "short-select"
                    }
                    onChange={onSelectTeamForProduct}
                    options={teamListForProduct}
                    isMulti={true}
                  />
                </div>
              </div>
              <div
                className="col-xl-12 col-lg-12"
                style={{ borderTop: "1px solid" }}
              >
                <div className="modal-footer">
                  <div className="modal-action-buttons">
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onClickHideProductModal}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onSaveProductDetails}
                      type="button"
                      disabled={disableCustomerProductSave}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={openSupplierModel}
            onClose={onCloseSupplierModal}
            center
            classNames={{
              overlayAnimationIn: 'customEnterOverlayAnimation',
              overlayAnimationOut: 'customLeaveOverlayAnimation',
              modalAnimationIn: 'customEnterModalAnimation',
              modalAnimationOut: 'customLeaveModalAnimation',
            }}
            animationDuration={400}
          >
            <div
              className="row"
              style={{ width: "100%", zIndex: "100001" }}
            >
              <div className="col-xl-12 col-lg-12">
                <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Edit Supplier</h5>
              </div>
              <div className="add-customer-container row">
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Supplier Name:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={editsupplier.supplier}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeSupplierName}
                    onBlur={onValidateSupplierName}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">
                    Supplier Reference Number:
                  </label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    id="shipmentInput"
                    value={editsupplier.supplierReferenceId}
                    autoComplete="off"
                    isDisabled
                    // onChange={onChangeSupplierReferenceNo}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Admin Name:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={editsupplier.admin_name}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeSupplierAdminName}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Admin Email:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="email"
                    placeholder=""
                    value={editsupplier.admin_email}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeSupplierAdminEmail}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Team:</label>
                  <Select
                    placeholder="Team"
                    closeMenuOnSelect={true}
                    value={editsupplier.team}
                    styles={customStyles}
                    id="shipmentInput"
                    onChange={onSelectSupplierTeam}
                    options={supplierTeamList}
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">POC:</label>
                  <Select
                    placeholder="POC"
                    closeMenuOnSelect={true}
                    value={editsupplier.poc}
                    id="shipmentInput"
                    styles={customStyles}
                    onChange={onSelectSupplierPOC}
                    options={supplierPointOfContacts}
                    isDisabled={supplierEnablePOC}
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Product:</label>
                  <Select
                    type="text"
                    placeholder=""
                    value={supplierProduct}
                    id="shipmentInput"
                    options={supplierProductData}
                    autoComplete="off"
                    isDisabled
                    // onChange={onChangeSupplierProduct}
                    // className="form-control"
                    styles={customStyles}
                  // isDisabled={isSupplierSelectDisabled}
                  //required
                  />
                </div>
              </div>
              <div
                className="col-xl-12 col-lg-12"
                style={{ borderTop: "1px solid" }}
              >
                <div className="modal-footer">
                  <div className="modal-action-buttons">
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onClickHideSupplierModal}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onSaveSupplierDetails}
                      disabled={isSupplierSelectDisabled}
                      type="button"
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={openSupplierProductModel}
            onClose={onCloseSupplierProductModal}
            style={{ width: "800px", height: "620px !important" }}
            classNames={{
              modal:
                selectedSupplierTeamForProduct.length > 1
                  ? "increase-product-modal"
                  : "custom-product-class",
              overlayAnimationIn: 'customEnterOverlayAnimation',
              overlayAnimationOut: 'customLeaveOverlayAnimation',
              modalAnimationIn: 'customEnterModalAnimation',
              modalAnimationOut: 'customLeaveModalAnimation',
            }}
            animationDuration={400}
            center>
            <div
              className="row"
              style={{ padding: "20px", width: "100%", zIndex: "10002" }}
            >
              <div className="col-xl-12 col-lg-12">
                <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Add Product</h5>
              </div>
              <div className="add-customer-container row">
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">Products Name:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={supplierProductName}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeSupplierProductName}
                    onBlur={ValidateSupplierProductName}
                    className="form-control"
                  //required
                  />
                  {errorTxtForSupplierProducts ? (
                    <span className="error-text">*Enter Product Name</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">HS Code:</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={supplierHSCode}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeSupplierHSCode}
                    className="form-control"
                  //required
                  />
                </div>
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">Cargo Type:</label>
                  <Select
                    placeholder="Team"
                    closeMenuOnSelect={true}
                    value={selectedSupplierCargo}
                    id="shipmentInput"
                    styles={customStyles}
                    onChange={setSupplierProductCargoType}
                    options={supplierCargoList}
                  />
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ marginBottom: "65px" }}
                >
                  <label className="custom-label">Select Team:</label>
                  <Select
                    placeholder="Team"
                    closeMenuOnSelect={true}
                    id="shipmentInput"
                    value={selectedSupplierTeamForProduct}
                    className={
                      selectedSupplierTeamForProduct.length > 2
                        ? "tall-select"
                        : "short-select"
                    }
                    onChange={onSelectSupplierTeamForProduct}
                    options={supplierTeamListForProduct}
                    isMulti={true}
                  />
                </div>
              </div>
              <div
                className="col-xl-12 col-lg-12"
                style={{ borderTop: "1px solid" }}
              >
                <div className="modal-footer">
                  <div className="modal-action-buttons">
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onClickHideSupplierProductModal}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onSaveSupplierProductDetails}
                      type="button"
                      disabled={disableSupplierProductSave}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div
            className={"overlay-part " + overly}
            onClick={onClickHideAll}
          ></div>
        </div>
      </div>
    </>
  );
};
var getHyphenDDMMYYYY = function (date_string) {
  if (date_string === "" || date_string === undefined) {
    return "";
  } else {
    return moment.parseZone(date_string).format("YYYY-MM-DD");
  }
};

var getSlashDDMMYYYY = function (date_string) {
  if (date_string === "" || date_string === undefined) {
    return "";
  } else {
    return moment.parseZone(date_string).format("MM/DD/YYYY HH:mm A");
  }
};

export default ModalEditShipment;
