import React, { Component } from "react";
import ErrorHandler from "./common/errorHandler";
import Header from "./common/header/header.component";
import Sidebar from "./common/sidebar/sidebar.component";
// import Enmovil from '../../src/assets/icons/enmovil.svg'
import "../assets/css/encss.css";
import { Outlet } from "react-router-dom";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: true,
      navMenus: true,
      ssinner: "show-n",
      sideimg: require("../assets/icons/sidenav-blue.png"),
      imgbg: "whgb",
      l20n: "",
      showOutIcon: "show-m",
      sclose: "show-m",
      logginStatus: true,
      userroles: [],
      pageStyle: {},
      basicTitle1: "",
      basicType1: "",
      show1: false,
      showCT: false,
    };
  }

  showside() {
    //console.log("asdas");
    showSideBar();
  }

  // openCloseSidebar = () => {
  //     console.log("this.state.sidebar ", this.state.sidebar)
  //     if (this.state.sidebar) {
  //         this.setState({
  //             sidebar: true,
  //             ssinner: 'show-m',
  //             //sideimg:require('../assets/icons/SIDE-NAV.png'),
  //             imgbg: '',
  //             l20n: 'l20n',
  //             showOutIcon: 'show-m',
  //             sclose: 'show-n'
  //         })
  //         document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
  //         var sidebaricon = document.querySelector(".sidebaricon");
  //         if (sidebaricon.classList.contains('show-n')) {
  //             document.querySelector(".sidebaricon").classList.remove('show-n');
  //             document.querySelector(".sidebaricon").classList.add('show-m');
  //         }
  //         else {
  //             document.querySelector(".sidebaricon").classList.remove('show-m');
  //             document.querySelector(".sidebaricon").classList.add('show-n');
  //         }
  //     }
  // }

  openSidebar = () => {
    // console.log("Opening sidebar...");
    this.setState({
      sidebar: true, // Open the sidebar
      ssinner: "show-m",
      imgbg: "", // Remove any close state styles
      l20n: "l20n",
      showOutIcon: "show-m",
      sclose: "show-n",
    });

    document
      .querySelector(".page-body-wrapper")
      .classList.remove("sidebar-close");
    var sidebaricon = document.querySelector(".sidebaricon");
    sidebaricon.classList.remove("show-n");
    sidebaricon.classList.add("show-m");
  };

  closeSidebar = () => {
    // console.log("Closing sidebar...");
    this.setState({
      sidebar: false, // Close the sidebar
      ssinner: "show-n",
      imgbg: "whgb", // Add back any styles for the closed state
      l20n: "",
      showOutIcon: "show-m",
      sclose: "show-m",
    });

    document.querySelector(".page-body-wrapper").classList.add("sidebar-close");
    var sidebaricon = document.querySelector(".sidebaricon");
    sidebaricon.classList.remove("show-n");
    sidebaricon.classList.add("show-m");
  };

  toggle() {
    //  console.log("test", this.state.navMenus);
    this.setState((prevState) => ({
      navMenus: !prevState.navMenus,
    }));
  }
  onShowFullscreen() {
    fullscreen();
  }

  render() {
    return (
      <div className="page-wrapper">
        <div className="page-body-wrapper sidebar-close" id="pageBoby">
          <Header />
          <Sidebar
            context={this}
            closeSidebar={this.closeSidebar}
            openSidebar={this.openSidebar}
          />
          <div className="page-body" id="pbody" style={this.state.pageStyle}>
            <a
              href={"javascript:void(0)"}
              className={"sidebaricon " + this.state.showOutIcon}
              onMouseEnter={this.openSidebar}
            >
              <span className="whbg-bg" id="whbg-bg"></span>
              <span
                id="ssinner"
                className={"siconbg " + this.state.ssinner}
              ></span>
              <img
                src={require("../assets/icons/sidenav-blue.png")}
                id="sidebar-toggle"
                defaultChecked={this.state.sidebar}
                onClick={this.openCloseSidebar}
                className={
                  "sidemenu sidebrclose " +
                  this.state.imgbg +
                  " " +
                  this.state.l20n +
                  " " +
                  this.state.sclose
                }
                alt=""
              />
            </a>
            <ErrorHandler>
              <Outlet />
            </ErrorHandler>
          </div>
        </div>
        {/* <div className="row justify-content-end poweredByLogo" style={{ cursor: "pointer" }} onClick={(e) => window.open('https://www.enmovil.ai', '_blank')}>
                        <div className="">
                            <img src={Enmovil} alt="" />
                        </div>
                    </div> */}
      </div>
    );
  }
}

function fullscreen() {
  var ele = document.getElementById("plivebody");
  // $("#fscreen").hide();
  console.log("ele ", ele);
  if (ele.requestFullscreen) {
    console.log("S1");
    ele.requestFullscreen();
  } else if (ele.webkitRequestFullscreen) {
    console.log("S2");
    ele.webkitRequestFullscreen();
  } else if (ele.mozRequestFullScreen) {
    console.log("S3");
    ele.mozRequestFullScreen();
  } else if (ele.msRequestFullscreen) {
    console.log("S4");
    ele.msRequestFullscreen();
  } else {
    // $("#fscreen").show();
    console.log("Fullscreen API is not supported.");
  }
}

function showSideBar() {
  alert("test");
  var c = document.getElementById("pageBoby");
  c.removeClass = " sidebar-close";
}

export default App;
