// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.toggle-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #808080;
    transition: 0.3s;
    border-radius: 30px;
}


.toggle-span:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

input:checked + .toggle-span {
    background-color: #00c853;
}

input:checked + .toggle-span:before {
    transform: translateX(29px);
}`, "",{"version":3,"sources":["webpack://./src/components/container-tracking/ToggleSwitch.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".toggle-label {\n    position: relative;\n    display: inline-block;\n    width: 60px;\n    height: 30px;\n}\n\n.toggle-checkbox {\n    opacity: 0;\n    width: 0;\n    height: 0;\n}\n\n.toggle-span {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: #808080;\n    transition: 0.3s;\n    border-radius: 30px;\n}\n\n\n.toggle-span:before {\n    position: absolute;\n    content: \"\";\n    height: 25px;\n    width: 25px;\n    left: 3px;\n    bottom: 2.6px;\n    background-color: #fff;\n    border-radius: 50%;\n    transition: 0.3s;\n}\n\ninput:checked + .toggle-span {\n    background-color: #00c853;\n}\n\ninput:checked + .toggle-span:before {\n    transform: translateX(29px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
