import React, { useEffect, useState } from "react";
import ExportIcon from "../../assets/icons/export-arrow.svg";
import ImportIcon from "../../assets/icons/import-arrow.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CompletedIcon from "../../assets/icons/completed-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import RefreshIcon from "../../assets/icons/refresh-icon.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import FlagGrey from "../../assets/icons/flag-grey.svg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const UntrackableTiles = ({ eachNew, tabView }) => {
   //console.log("eachNew", eachNew,tabView);
  const [state, setState] = useState({
    movement_type: "",
    containerData: [],
    shipping_line: "ONE",
    total_containers: "",
    containerlist:[],
    containerNo: "",
    mbl_number: "NA",
    booking_no: "NA",
  });
  useEffect(() => {
    if (tabView === "bl") {
      setState({
        ...state,
        movement_type:
          eachNew.containers.length > 0
            ? eachNew.containers[0].hasOwnProperty("movement_type")
              ? eachNew.containers[0].movement_type
              : ""
            : "",
        containerData:
          eachNew.containers.length > 0 ? eachNew.containers[0] : [],

        shipping_line:
          eachNew.containers.length > 0
            ? eachNew.containers[0].hasOwnProperty("shipping_line")
              ? eachNew.containers[0].shipping_line
              : "ONE"
            : "ONE",
        containerlist: eachNew.containers,

        total_containers: `${eachNew.containers.length} Container(s)`,
        mbl_number: eachNew._id ? eachNew._id : "NA",
        booking_no:
          eachNew.containers.length > 0
            ? eachNew.containers[0].hasOwnProperty("booking_no")
              ? eachNew.containers[0].booking_no
                ? eachNew.containers[0].booking_no
                : "NA"
              : "NA"
            : "NA",
      });
    } else {
      setState({
        ...state,
        movement_type: eachNew.hasOwnProperty("movement_type")
          ? eachNew.movement_type
          : "",
        containerData: eachNew,
        shipping_line: eachNew.hasOwnProperty("shipping_line")
          ? eachNew.shipping_line
          : "",
        total_containers: "",
        mbl_number: eachNew.mbl_number,
        containerNo: eachNew.container_no,
      });
    }
  }, [eachNew, tabView]);
  return (
    <div
      className="card py-2 pr-3 d-flex flex-row justify-content-between align-items-center"
      style={{
        borderRadius: "18px",
      }}
    >
      {/* Content Card */}
      <div className="d-flex justify-content-start align-items-start">
        <div
          className="d-flex flex-column justify-content-center align-items-center p-2"
          style={{ background: "#407bff" }}
        >
          <img
            style={{
              width: "10px",
              marginBottom: "5px",
              transform: `${
                state.movement_type === "Import" ? "rotate(183deg)" : ""
              }`,
            }}
            src={ExportIcon}
          ></img>
          <div
            style={{
              color: "white",
              fontSize: "10px",
              fontWeight: 700,
            }}
          >
            {state.movement_type === "Import" ? "IM" : "EX"}{" "}
          </div>
        </div>
      </div>

      {/* Image Data */}
      <div className="" style={{ width: "7%" }}>
        <img
          src={require(`../../../public/static/images/Gocomet/${state.shipping_line}.png`)}
          alt={`${state.shipping_line}`}
          style={{ width: "100%" }}
        />
        <br />
        <label for="" data-tooltip-id={`tracking_line_${state.mbl_number}`}
                title={(state.containerlist.length > 0)?(state.containerlist.map((it) => { return it.container_no})):""}>{state.total_containers}</label>
      </div>
      {/* Image Data */}

      {/* BL/Container Data */}
      <div className="d-flex flex-column justify-content-start eachCard mr-5">
        <div className="d-flex flex-row justify-content-between">
          <div style={{ color: "#858796", fontSize: "10px" }}>BL No:</div>
          {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
          <div
            style={{
              fontSize: "10px",
              fontWeight: 600,
              marginLeft: "8px",
            }}
          >
            {state.mbl_number}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div style={{ color: "#858796", fontSize: "10px" }}>Booking No:</div>
          {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
          <div
            style={{
              fontSize: "10px",
              fontWeight: 600,
              marginLeft: "8px",
            }}
          >
            {state.booking_no}
          </div>
        </div>
        <div
          className="flex-row justify-content-between"
          style={{ display: tabView === "container" ? "flex" : "none" }}
        >
          <div style={{ color: "#858796", fontSize: "10px" }}>
            Container No:
          </div>
          <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>
            {state.containerNo}
          </div>
        </div>
      </div>
      {/* BL/Container Data */}

      {/* Progress Bar Line */}
      <div className="d-flex flex-row justify-content-center align-items-center cursor-class tilebl">
        <div className="align-items-center ml-1 portpos">
          <div className="row textAlignCenter">
            <div className="col-12">
              <div className="portxt"></div>
            </div>
          </div>
        </div>

        <div className="current-activity">
        <img className="portind newship_img" src={LocationBlue}></img>
          <span
          className="ontime-text"
            style={{
              fontSize: "10px",
              padding: "2px 5px",
              position: "relative",
              top: "-11px",
              borderRadius: "3px",
              fontWeight: "bolder",
              left: "-53px",
            }}
          >Untrackable</span>
           <img src={FlagGrey} className="desflg desship_img" />
        </div>
        <div className="d-flex flex-row tracking-background-progress">
          <div
          // style={{ width: barPos }}
          ></div>
          <div className="tracking-image-background">
            
          {/* <img className="portind" src={LocationBlue}></img> */}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center ml-1 portdes">
          <div className="row textAlignCenter location-flag">
            <div className="col-12">
              
            {/* <img src={FlagGrey} className="desflg"></img> */}
            </div>
            <div className="col-12">
              <div className="col-12 portxt"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Progress Bar Line */}

      {/* Time Statuses */}
      <div
        className="d-flex flex-column justify-content-start"
        style={
          {
            // width: "171.575px",
            // marginLeft:"96px"
          }
        }
      >
        <div className="d-flex flex-row justify-content-between mb-1" title="Estimated Time of Departure">
          <div style={{ color: "#858796", fontSize: "10px" }}>
             ETD:
          </div>
          {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.scheduledETD}</div> */}
          <div
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              fontWeight: 700,
            }}
          >
            NA
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between mb-1" title="Actual Time of Departure">
          <div style={{ color: "#858796", fontSize: "10px" }}>ATD:</div>
          {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETD}</div> */}
          <div
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              fontWeight: 700,
            }}
          >
            NA
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between mb-1" title="Estimated Time of Arrival">
          <div style={{ color: "#858796", fontSize: "10px" }}>ETA:</div>
          {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.plannedETA}</div> */}
          <div
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              fontWeight: 700,
            }}
          >
            NA
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between"  title="Actual Time of Arrival">
          <div style={{ color: "#858796", fontSize: "10px" }}>ATA:</div>
          {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETA}</div> */}
          <div
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              fontWeight: 700,
            }}
          >
            NA
          </div>
        </div>
      </div>
      {/* Time Statuses */}

      {/* Activities */}
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between mb-3">
          <div>
            <img src={CompletedIcon}></img>
          </div>
          <div>
            <img src={EditIcon}></img>
          </div>

          <div>
            <img src={DeleteIcon}></img>
          </div>
          <div>
            <img src={ShareIcon}></img>
          </div>
        </div>
        <div style={{ width: "120px", textAlign: "center" }}>
          <div
            className="py-1 px-3"
            style={{
              fontSize: "10px",
              fontWeight: 600,
              borderRadius: "14px",
            }}
          >
            NA
          </div>
        </div>
      </div>
      {/* Activities */}

      {/* Refresh Option */}
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        // onClick={refreshData}
      >
        <img
          className="mb-3 cursor-class"
          data-tooltip-content="Refresh Tracking"
          src={RefreshIcon}
        />
        {/* {last_event_data[0].status === "COMPLETED" ? (
          ""
        ) : (
          <Tooltip id={`refresh_${shipment_no}`} />
        )} */}
        <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
          NA
        </div>
        <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
          Next Update in 2 hours
        </div>
      </div>
      {/* Refresh Option */}

      {/* Content Card */}
    
    </div>
  );
};

export default UntrackableTiles;
