// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal-class-edit {
  width: 85vw !important;
  max-width: 100vw !important;
}
.custom-pagination {
  display: flex;
  padding: 10px;
  justify-content: center;
}

.custom-paginationItem{
  border: 0px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
}
.custom-paginationItem .active:hover{
  border: 0px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  background-color: aliceblue;
}
.custom-pagination li {
  margin: 5px;
  cursor: pointer;
}

.page-item{

}
.active{

}
.page-link{
  border: none;
  border-radius: 10px;
}
.visually-hidden {
display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/container-tracking/trackingTile.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;;AAEA;AACA;;AAEA;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;AACA,aAAa;AACb","sourcesContent":[".custom-modal-class-edit {\n  width: 85vw !important;\n  max-width: 100vw !important;\n}\n.custom-pagination {\n  display: flex;\n  padding: 10px;\n  justify-content: center;\n}\n\n.custom-paginationItem{\n  border: 0px;\n  text-align: center;\n  font-size: 20px;\n  border-radius: 10px;\n}\n.custom-paginationItem .active:hover{\n  border: 0px;\n  text-align: center;\n  font-size: 20px;\n  border-radius: 10px;\n  background-color: aliceblue;\n}\n.custom-pagination li {\n  margin: 5px;\n  cursor: pointer;\n}\n\n.page-item{\n\n}\n.active{\n\n}\n.page-link{\n  border: none;\n  border-radius: 10px;\n}\n.visually-hidden {\ndisplay: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
