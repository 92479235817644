// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* input{
    font-size: 10px !important;
} */

.product_details{
    font-weight: bold;
    color: black;
}

.product_details_map{
    font-size: 10px !important;
}


.accordion-button{
  border: none;
  background: #fff;
  width: 100%;
  text-align: left;
  color: #007bff;
  font-size: 16px;
  font-weight: bold;
}

button.accordion-button[aria-expanded="true"]::after {
  content: '\\2B9F'; 
  font-size: 25px;
  float: inline-end;
}

button.accordion-button[aria-expanded="false"]::after {
  content: '\\2B9D';
  font-size: 25px;
  float: inline-end;
}
.accordion-header{
  border-color: #007bff;
}
.accordion{
  padding: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/container-tracking/trackingDetailsCard.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B;;;AAGA;EACE,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;AACf","sourcesContent":["/* input{\n    font-size: 10px !important;\n} */\n\n.product_details{\n    font-weight: bold;\n    color: black;\n}\n\n.product_details_map{\n    font-size: 10px !important;\n}\n\n\n.accordion-button{\n  border: none;\n  background: #fff;\n  width: 100%;\n  text-align: left;\n  color: #007bff;\n  font-size: 16px;\n  font-weight: bold;\n}\n\nbutton.accordion-button[aria-expanded=\"true\"]::after {\n  content: '\\2B9F'; \n  font-size: 25px;\n  float: inline-end;\n}\n\nbutton.accordion-button[aria-expanded=\"false\"]::after {\n  content: '\\2B9D';\n  font-size: 25px;\n  float: inline-end;\n}\n.accordion-header{\n  border-color: #007bff;\n}\n.accordion{\n  padding: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
