import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { FaBars, FaClock, FaCheck } from "react-icons/fa";
import "./approverdashboard.css";
import redirectURL from "../redirectURL";
import moment from "moment";
import Favourites from "../common/favouritesComponent";
const Approverdashboard = () => {
  const navigate = useNavigate();
  const gridRef = useRef();
  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    filter: true,
    resizable: true,
    pivot: true,
    enableValue: true,
    enableRowGroup: false,
  });

  const [eventsData, setEventsData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [approvedData, setApprovedData] = useState([]);
  const [activeTab, setActiveTab] = useState("Events");
  const [rowData, setRowData] = useState([]);
  // const [userRole, setUserrole] = useState(localStorage.getItem("role"))


  useEffect(() => {
    let email = localStorage.getItem("email");

    let userRole = localStorage.getItem("role");
    // if(userRole && userRole != "admin"){
    //   navigate("/vendor-dashboard")
    // }//tmssssssssssssssssss
    let payload = {
      // email: email ? email : ""//tmssssssssssss
      email: ''
    }
    redirectURL.post("/procurement/getAdminDashboardDetails", payload).then((resp) => {
      console.log("getAdminDashboardDetails ", resp.data);
      if (resp.data.status === "success") {
        let { pendingEventsData, approvedEventsData, incompleteEvents } = resp.data;
        // console.log(incompleteEvents,"incompleteEvents")
        setEventsData([...incompleteEvents]);
        setPendingData(pendingEventsData)
        setApprovedData(approvedEventsData)
        setRowData([...incompleteEvents]);
        // console.log(rowData,"Load")
      }
    }).catch((err) => {
      console.log("getAdminDashboardDetails err", err);
    })
  }, [])


  const regNoRenderer = (params) => {
    return (
      <a
        href="#"
        onClick={() => handleCellClick(params.data.reference_number)}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {params.data.reference_number}
      </a>
    );
  };

  function handleCellClick(cellValue) {
    console.log("Clicked cell value:", cellValue);
  }

  const nameRenderer = (params) => {
    return (
      <div>
        <p>{params.data.name}</p>
      </div>
    );
  };

  const statusRenderer = (params) => {
    return (
      <div>
        <p>{params.data.status}</p>
      </div>
    );
  };
  const dateRenderer = (params) => {
    return (
      <div className="dateContainer">
        {/* <p className="mr-2">{moment.parseZone(params.data.from_date).format("DD-MM-YYYY")}</p> */}
        {/* <p>{moment.parseZone(params.data.to_date).format("DD-MM-YYYY")}</p> */}
      </div>
    );
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Ref No",
      field: "rfq_event_id",
      filter: true,
      width: 350
    },
    {
      headerName: "Subject",
      field: "rfq_subject",
      headerClass: "rfq_subject",
      filter: true,
      width: 200,
      valueGetter: function (params) {
        if (params.data.hasOwnProperty("rfq_subject") && params.data.rfq_subject) {
          return params.data.rfq_subject.slice(0, 1).toUpperCase() + params.data.rfq_subject.slice(1,)
        } else {
          return ""
        }
      }
    },
    {
      headerName: "Description",
      field: "rfq_description",
      filter: true,
      width: 300
    },
    // {
    //   headerName: "Created By",
    //   field: "user_name",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("user_name") && params.data.user_name){
    //       return params.data.user_name.slice(0,1).toUpperCase() + params.data.user_name.slice(1,)
    //     }else{
    //       return ""
    //     }
    //   }
    // },
    // {
    //   headerName: "Event Status",
    //   field: "user_name",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("is_form_submitted")){
    //       if(params.data.is_form_submitted === 0){
    //         return "Not Submitted"
    //       }
    //       if(params.data.is_form_submitted === 1){
    //         return "Submitted"
    //       }
    //     }else{
    //       return ""
    //     }
    //   }
    // },
    // {
    //   headerName: "Technicaal Approval",
    //   field: "technical_approval",
    //   filter: true,
    //   width: 100
    // },
    // {
    //   headerName: "Start Time (Buffer, in Hrs)",
    //   field: "rfq_start_date_buffer_hrs",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("rfq_start_date_is_buffer") && params.data.rfq_start_date_is_buffer){
    //       return params.data.rfq_start_date_buffer_hrs
    //     }else if(params.data.hasOwnProperty("rfq_start_date_is_buffer") && params.data.rfq_start_date_is_buffer === false){
    //       return ""
    //     }
    //   }
    // },
    {
      headerName: "Event Start Time",
      field: "rfq_start_time",
      filter: true,
      width: 180,
      valueGetter: function (params) {
        if (params.data.hasOwnProperty("rfq_start_time") && !params.data.rfq_end_date_is_buffer) {
          return moment.parseZone(params.data.rfq_start_time).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
        } else if (params.data.hasOwnProperty("rfq_start_time") && params.data.rfq_start_time && params.data.rfq_end_date_is_buffer) {
          return moment.parseZone(params.data.rfq_start_time).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
        } else {
          return ""
        }
      }
    },
    // {
    //   headerName: "End Time (Buffer, in Hrs)",
    //   field: "rfq_end_date_buffer_hrs",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("rfq_end_date_is_buffer") && params.data.rfq_end_date_is_buffer){
    //       return params.data.rfq_end_date_buffer_hrs
    //     }else if(params.data.hasOwnProperty("rfq_end_date_is_buffer") && params.data.rfq_end_date_is_buffer === false){
    //       return ""
    //     }
    //   }
    // },
    {
      headerName: "End Time",
      field: "rfq_end_time",
      filter: true,
      width: 180,
      valueGetter: function (params) {
        if (params.data.hasOwnProperty("rfq_end_time") && !params.data.rfq_end_date_is_buffer) {
          return moment.parseZone(params.data.rfq_end_time).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
        } else if (params.data.hasOwnProperty("rfq_end_time") && params.data.rfq_end_time && params.data.rfq_end_date_is_buffer) {
          return moment.parseZone(params.data.rfq_end_time).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
        }
        else {
          return ""
        }
      }
    },
    // {
    //   headerName: "Created on",
    //   field: "created_at",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("created_at")){
    //       return moment.parseZone(params.data.created_at).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
    //     }else{
    //       return ""
    //     }
    //   }
    // }
  ]);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [rowHeight, setRowHeight] = useState(50);
  const [gridStyles, setGridStyles] = useState({});

  // const rowClassRules = {
  //     'even-row': (params) => params.rowIndex % 2 === 0,
  // };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    let data = []
    switch (tab) {
      case "Events":
        data = eventsData
        setRowData(eventsData);
        break;
      case "Pending":
        data = pendingData
        setRowData(pendingData);
        break;
      case "Approved":
        data = approvedData
        setRowData(approvedData);
        break;
      default:
        setRowData([]);
    }
  };
  const onGridReady = (params) => {
    const gridApi = params.api;
    const gridColumnApi = params.columnApi;
    console.log("Grid is ready");
    setRowData(eventsData);
  };

  const renderCellClick = (rowData) => {
    // console.log(rowData,"rowData");
    if (rowData.is_form_submitted === 0) {
      navigate(`/request-quotation/${rowData.rfq_event_id}`)
    } else if (rowData.is_form_submitted === 1 && rowData.rfq_event_status === 5) {
      navigate(`/rfqPreviewAndApprove/${rowData.rfq_event_id}`)
    } else if (rowData.rfq_event_status === 6) { //later-on change it to 10(on publish) or 12 on (on response received)
      navigate(`/rfqVendorComparison/${rowData.rfq_event_id}`)
      // /admin-dashboardrfqVendorComparison
    }
  }
  return (
    <div className="" style={{ minHeight: "87vh", width: "95%", margin: "auto" }}>
       <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
          <i className="fa fa-dashboard cus-i"></i>&nbsp;<span>Dashboard</span>
          <Favourites />
      </h5>
      <div className="row">
        <div className="col-md-12">
          <div className="card custom-card">
            <div className="card-body" style={{ padding: "0px" }}>
              <ul
                className="nav nav-pills justify-content-between"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item tabClick">
                  <a
                    className={`nav-link ${activeTab === "Events"
                      ? "events-tab-active"
                      : "events-tab"
                      }`}
                    href="#"
                    style={{
                      fontSize: "25px",
                      padding: "20px",
                    }}
                    onClick={() => handleTabChange("Events")}
                  >
                    <FaBars
                      style={{ marginBottom: "6px", marginRight: "15px" }}
                    />
                    Events &#40; {eventsData.length} &#41;
                  </a>
                </li>
                <li className="nav-item tabClick">
                  <a
                    className={`nav-link ${activeTab === "Pending"
                      ? "pending-tab-active"
                      : "pending-tab"
                      }`}
                    href="#"
                    style={{
                      fontSize: "25px",
                      padding: "20px",
                    }}
                    onClick={() => handleTabChange("Pending")}
                  >
                    <FaClock
                      style={{ marginBottom: "6px", marginRight: "15px" }}
                    />
                    Pending &#40; {pendingData.length} &#41;
                  </a>
                </li>
                <li className="nav-item tabClick">
                  <a
                    className={`nav-link ${activeTab === "Approved"
                      ? "approved-tab-active"
                      : "approved-tab"
                      }`}
                    href="#"
                    style={{
                      fontSize: "25px",
                      padding: "20px",
                    }}
                    onClick={() => handleTabChange("Approved")}
                  >
                    <FaCheck
                      style={{ marginBottom: "6px", marginRight: "15px" }}
                    />
                    Approved &#40; {approvedData.length} &#41;
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-md-12 mb-1">
          <div
            className="dashboardContainer"
            style={{ width: "100%", height: "400px" }}
          >
            <div
              className={`ag-theme-alpine ${activeTab === "Events" ? "events-tab" : ""
                } ${activeTab === "Pending" ? "pending-tab" : ""} ${activeTab === "Approved" ? "approved-tab" : ""
                }`}
              style={{ width: "100%", height: "500px" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                defaultColDef={defaultColDef}
                pagination={true}
                // gridOptions={gridOptions}
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                animateRows={true}
                paginationPageSize={paginationPageSize}
                suppressCellSelection={true}
                suppressMovableColumns={true}
                allowScrolling={true}
                showNoRowsOverlay={false}
                suppressRowHoverHighlight={true}
                onGridReady={onGridReady}
                onCellClicked={(e) => { renderCellClick(e.data) }}
              />
              {/* <AgGridReact
                            modules={this.state.modules}
                            headerClass='curved-header'
                            columnDefs={columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.sliderRowData}
                            paginationPageSize={this.state.paginationPageSize}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            onCellClicked={this.getZoneLegData}
                            pagination={true}
                            masterDetail={true}
                            enableCharts={true}
                            enableRangeSelection={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            suppressRowClickSelection={true}
                            suppressRowHoverHighlight={true}
                        /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approverdashboard;
