import React, { useEffect } from "react";
import "./ProgressbarCard.css";
import moment from "moment";

const ProgressbarCard = (props) => {
  console.log("ProgressbarCard", props);
  let temp = props?.eventData?.filter(e=>e.event_name!="unknown")
  temp.map((e, i) => {
    if (!e.hasOwnProperty("actual_time")) {
      e["actual_time"] = ""
    }
    return e
  })

  if (Array.isArray(temp)) {
    temp.sort((a, b) => {
      if (a.actual_time !== "" && b.actual_time !== "") {
        return moment(a.actual_time, 'Do MMM, YYYY').diff(moment(b.actual_time, 'Do MMM, YYYY'))
      }
      else if (a.actual_time !== "") {
        return -1;
      }
      else if (b.actual_time !== "") {
        return 1;
      }
      else {
        return 0;
      }
    }
    );
  }
  useEffect(() => { }, [props]);
  return (
    <div className="card p-3" style={{ borderRadius: "10px", display: props.progressCardVisibility }}>
      {/* Heading Part */}
      <div class="pl-4">
        <label for="">
          {props.searchType === "BL" ? "BL Number" : "Container No:"}
        </label>
        <label for="" className="d-block fw-bolder">
          {props.searchNumber ? props.searchNumber : ""}
        </label>
      </div>

      {/* Progress Bar */}
      <div className="wrapper pl-3 pt-4">
        <ul className="StepProgress">
          {
            (!props.eventData || props.eventData.length === 0 || props.eventData === undefined) ? <></> :
              <>
                {temp.map((each, i) => {
                  let classname = "";
                  let idname = "";
                  if (each.actual_time == "") {
                    //for active events only current class is enough
                    classname = classname + " current";
                  } else {
                    // for finished events we set is-done which gives blue color
                    classname = classname + " is-done";
                    //for finished events again we have to determine delay / early and then assign green or red color
                    if (each.statusCode === "Delay") {
                      classname = classname + " late-event";
                    } else if (each.statusCode === "Early") {
                      classname = classname + " early-event";
                    }

                    if (each.is_last_event) {
                      idname = "last-event";
                    }
                  }
                  //If event has no actual_time, then that even is yet to get complete
                  // if (!each.hasOwnProperty("actual_time")) {
                  //   classname = "current ";
                  // } else {
                  //   // each.hasOwnProperty("is_last_event")
                  //   if (
                  //     each.hasOwnProperty("statusCode") &&
                  //     each.hasOwnProperty("is_last_event")
                  //   ) {
                  //     //have actual_time and it means event is done
                  //     // && each.is_last_event === false

                  //     //if status is on time and last time is false then add blue circle
                  //     if (each.statusCode === "On Time") {
                  //       classname = classname + " " + "is-done";
                  //     }

                  //     //if status is delay and last time is false then add red circle
                  //     if (each.statusCode === "Delay") {
                  //       classname = classname + " " + "late-event";
                  //     }

                  //     //if status is early and last time is false then add green circle
                  //     if (each.statusCode === "Early") {
                  //       classname = classname + " " + "early-event";
                  //     }

                  //     //for event is last event, again on status extra have to add id also
                  //     if (each.is_last_event) {
                  //       idname = "last-event";
                  //     }
                  //   }
                  // }
                  return (
                    <div
                      className={`StepProgress-item ${classname}`}
                      id={idname ? idname : ""}
                      key={`${each.event_name}-${i}`}
                    >
                      <strong>{`${each.event_name}, ${each.eventGroup}, ${each.location}`}</strong>
                      {each.actual_time}
                    </div>
                  );
                })}
              </>
          }
        </ul>
      </div>
    </div>
  );
};

export default ProgressbarCard;
