import moment from "moment";
import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import redirectURL from "../redirectURL";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class EventDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: 0,
      created_by: "",
      rfq_subject: "",
      rfq_start_time: "",
      rfq_end_time: "",
      rfq_description: "",
      currency: "",
      conversion_rate: "",
      rowData: [],
      loadshow: "show-n",
    };
  }

  componentDidMount() {
    let { id } = this.props.params;
    this.setState({
      pageId: id,
    });
    let eventId = {
      rfq_event_id: id,
    };
    this.setState({
      loadshow: "show-m",
    });

    redirectURL
      .post("/procurement/getRfqEventById", eventId)
      .then((event) => {
        var eventData = event.data.eventDetails[0];
        console.log("EVent", eventData);
        this.setState({
          created_by: eventData.created_by,
          rfq_subject: eventData.rfq_subject,
          rfq_description: eventData.rfq_description,
          rfq_start_time: moment
            .parseZone(new Date(eventData.rfq_start_time))
            .format("DD MMMM YYYY hh:mm"),
          rfq_end_time: moment
            .parseZone(new Date(eventData.rfq_end_time))
            .format("DD MMMM YYYY hh:mm"),
          currency: eventData.currency,
          conversion_rate: eventData.conversion_rate,
        });
        redirectURL
          .post("procurement/getAllVendorsAndTheirResponses", eventId)
          .then(async (resp) => {
            console.log("getAllVendorsForEvent", resp.data);
            var getAllInvitedVendorData = await resp.data
              .getAllInvitedVendorData;
            var allRfqResponses = await resp.data.allRfqResponses;

            var rowDataForGrid = await [];
            for await (let eachInvitedVendorData of getAllInvitedVendorData) {
              var eachRowData = {};
              var { vendor } = eachInvitedVendorData;
              var { contact_person, email, mobile_no } = vendor;
              var vendorResp = allRfqResponses.filter((each) => {
                return each.vendor_email === email;
              });
              // var {status} = vendorResp;
              console.log("vendorResp", vendorResp);
              var vendor_details = `${contact_person} (${email}, ${mobile_no})`;
              eachRowData.vendor_details = vendor_details;
              // eachRowData.status = status;
              if (vendorResp.length > 0) {
                if (vendorResp[0].hasOwnProperty("status")) {
                  console.log("status");
                  eachRowData.status = vendorResp[0].status;
                } else {
                  eachRowData.status = "";
                }

                if (vendorResp[0].hasOwnProperty("response_datetime")) {
                  var respDate = vendorResp[0].response_datetime;
                  respDate = moment.parseZone(respDate).format("Do MMM YYYY hh:mm")
                  eachRowData.response_datetime = respDate
                    
                } else {
                  eachRowData.response_datetime = "";
                }
              }
              rowDataForGrid.push(eachRowData);
            }
            console.log("rowDataForGrid",rowDataForGrid);
            this.setState({
              rowData: rowDataForGrid,
              loadshow: "show-n",
            });

            // var vendorsAllData = resp.data.getAllInvitedVendorData;

            // redirectURL.post("procurement/getResponsesForEvent")

            // var vendor_data = []
            // for(let each of vendors){
            //   var eachData = {
            //     rfq_event_id:id,
            //     vendor_email:each.vendor.email
            //   }
            //   vendor_data.push(eachData)
            // }

            // redirectURL.post("procurement/getVendorResponses",vendor_data).then((resp)=>{
            //   console.log("getVendorResponses",resp);
            //   var allVendorsResponses = resp.data.allVendorResponses
            //   this.setState({
            //     rowData:allVendorsResponses
            //   })

            // }).catch((err)=>{
            //   console.log("getVendorResponses",err);
            // })

            // console.log("vendor ids",vendor_ids);
          })
          .catch((err) => {
            console.log("getAllVendorsForEvent", err);
          });
      })
      .catch((err) => {
        console.log("Event details err", err);
      });
  }

  onClickHideAll = () => {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  };
  render() {
    const columnwithDefs = [
      {
        headerName: "Vendor Details",
        field: "vendor_details",
        width: 400,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Status",
        field: "status",
        width: 200,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Response Date & Time",
        width: 200,
        field: "response_datetime",
        filter: true,
        resizable: true,
      },
    ];
    return (
      <div className="container-fluid">
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div class="spinner-grow text-primary mr-2" role="status"></div>
          <div class="spinner-grow text-primary mr-2" role="status"></div>
          <div class="spinner-grow text-primary" role="status"></div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-md-12 mb-4">
            <div className="shadow h-100">
              <div className="column">
                <div className="col-12">
                  <div className="row justify-content-between bg-primary text-white">
                    <div className="col-6">
                      <label htmlFor="">RFQ Invited Vendors</label>
                    </div>
                    <div className="col-6 text-right">
                      <label htmlFor="">
                        RFQ Configured By: {this.state.created_by}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2 px-5">
                  <div className="row form-group no-gutters align-items-center">
                    <div className="col-2 text-right">
                      <label>Subject :</label>
                    </div>
                    <div className="col-3 pl-2">
                      <label htmlFor="">
                        <strong>{this.state.rfq_subject}</strong>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-5">
                  <div className="row form-group no-gutters align-items-center">
                    <div className="col-2 text-right">
                      <label>Start Date :</label>
                    </div>
                    <div className="col-3 pl-2">
                      <label htmlFor="">{this.state.rfq_start_time}</label>
                    </div>
                    <div className="col-2 text-right">
                      <label>End Date :</label>
                    </div>
                    <div className="col-3 pl-2">
                      <label htmlFor="">{this.state.rfq_end_time}</label>
                    </div>
                    <div className="col-2 p-0">
                      <button className="btn btn-success m-0 p-1">
                        <label htmlFor="">+ Modify Date</label>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-5">
                  <div className="row form-group no-gutters align-items-center">
                    <div className="col-2 text-right">
                      <label>Description :</label>
                    </div>
                    <div className="col-9 pl-2">
                      <label htmlFor="">{this.state.rfq_description}</label>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-5 pt-3">
                  {/* <div className="card shadow p-2"> */}
                  <div
                    id="myGrid"
                    style={{ height: "400px", width: "100%" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      columnDefs={columnwithDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={true}
                      enableRangeSelection={true}
                      autoGroupColumnDef={this.state.autoGroupColumnDef}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      statusBar={this.state.statusBar}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      sideBar={this.state.sideBar}
                      animateRows={true}
                      colResizeDefault="shift"
                    />
                  </div>
                  {/* </div> */}
                </div>
                <div className="col-12 justify-content-center px-5 pt-3 mb-3">
                  <div className="row justify-content-center">
                    <button className="btn btn-danger mr-2">
                      <span className="f13">
                        <i
                          className="icofont icofont-close mr-1"
                          style={{ color: "white" }}
                        ></i>
                        Cancel
                      </span>
                    </button>
                    <Link to={"/organiser-dashboard"}>
                      <button className="btn btn-primary mr-2">
                        <span className="f13">
                          <i
                            className="icofont icofont-reply mr-1"
                            style={{ color: "white" }}
                          ></i>
                          Exit
                        </span>
                      </button>
                    </Link>

                    <button className="btn btn-success mr-2">
                      <span className="f13">
                        <i
                          className="icofont icofont-arrow-right mr-1"
                          style={{ color: "white" }}
                        ></i>
                        Send Reminder
                      </span>
                    </button>

                    <Link to={`/rfqComparitiveAnalysis/${this.state.pageId}`}>
                      <button className="btn btn-warning mr-2">
                        <span className="f13">
                          <i
                            className="icofont icofont-arrow-right mr-1"
                            style={{ color: "black mr-1" }}
                          ></i>
                          View Report
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(EventDetails);
