import { useState } from 'react';
import CountUp from 'react-countup';

const MainCountersforDelivered = (props) => {
console.log(props,'5555')
    const [counterOptions, setCounterOptions] = useState([
        { label: "Consignments", value: "consignments" },
        { label: "On Time", value: "ontime" },
        { label: "Delayed", value: "delayed" },
        { label: "No GPS Available Lifetime", value: "no_gps_data_lifetime" },
        { label: "Overspeeding", value: "overspeeding" },
        { label: "Night Driving", value: "night_driving" },
        { label: "Stoppages", value: "stoppages" },
        { label: "Accidents", value: "accidents" },
        { label: "Trip Deviations", value: "trip_deviations" },
        { label: "Loading Delay", value: "loading_delay" },
        { label: "Unloading Delay", value: "unloading_delay" }
    ]);
    var [tCons, setTCons] = useState("show-m");
    var [transDelay, setTransDelay] = useState("show-n");
    var [nogps, setNogps] = useState("show-n");
    var [overspeed, setOverspeed] = useState("show-n");
    var [nightdrive, setNightDrive] = useState("show-n");
    var [loaddelay, setLoadDelay] = useState("show-n");
    var [unloaddelay, setUnloaddelay] = useState("show-n");
    var [stoppags, setStoppags] = useState("show-n");
    var [tripDev, setTripDev] = useState("show-n");
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer" onClick={(e) => { props?.setCounter(counterOptions[0].value); setTCons("show-m"); setTransDelay("show-n"); setNogps("show-n"); setOverspeed("show-n"); setNightDrive("show-n"); setStoppags("show-n"); setLoadDelay("show-n"); setUnloaddelay("show-n"); setTripDev("show-n"); }}>
                                    <span className={"f13 " + (tCons == 'show-m' ? 'fbold blackColor' : '')}><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                    <h4 className="greenfont f40"><span className="counter"><CountUp end={props.data.consignments ? props.data.consignments : 0} /></span></h4>
                                    <span className={"downpointer " + (tCons)}>
                                        <i className="fa fa-caret-down"></i>
                                    </span>
                                </div>
                                <div className="col cursorPointer" onClick={(e) => {
                                    props?.setCounter(counterOptions[1].value); setTCons("show-n"); setTransDelay("show-m"); setNogps("show-n"); setOverspeed("show-n"); setNightDrive("show-n"); setStoppags("show-n"); setLoadDelay("show-n"); setUnloaddelay("show-n"); setTripDev("show-n");
                                }}>
                                    <span className={"f13 " + (transDelay == 'show-m' ? 'fbold blackColor' : '')}><i style={{ color: '#60ef48d9' }} className="icofont icofont-clock-time f22"></i><br /> On Time</span>
                                    <h4 className="txt-danger f40"><span style={{ color: '#60ef48d9' }} className="counter"><CountUp end={props.data.ontime ? props.data.ontime : 0} /></span></h4>
                                    <span className={"downpointer " + (transDelay)}>                                        <i className="fa fa-caret-down"></i>
                                    </span>
                                </div>



                                <div className="col cursorPointer">
                                    <span className={"f13 " + (nogps == 'show-m' ? 'fbold blackColor' : '')}><i className="icofont icofont-clock-time f22 txt-danger"></i><br />Delayed</span>
                                    <h4 className="txt-secondary f40">
                                        <span className="counter" onClick={(e) => {
                                            props?.setCounter(counterOptions[2].value);setTCons("show-n");setTransDelay("show-n");setNogps("show-m");setOverspeed("show-n");setNightDrive("show-n");setStoppags("show-n");setLoadDelay("show-n");setUnloaddelay("show-n");setTripDev("show-n");}}>
                                            <CountUp className="txt-warning" end={props.data.delay ? props.data.delay : 0} />
                                        </span>
                                        {/* <span className="f24">&nbsp;/&nbsp;</span> */}
                                        {/* <span className="counter" onClick={e => props?.setCounter(counterOptions[3].value)}>
                                            <CountUp className="txt-secondary" end={props.data.no_gps_data_lifetime ? props.data.no_gps_data_lifetime : 0} />
                                        </span> */}
                                    </h4>
                                    <span className={"downpointer " + (nogps)}>
                                        <i className="fa fa-caret-down"></i>
                                    </span>
                                </div>
                                <div className="col cursorPointer" onClick={(e) => { props?.setCounter(counterOptions[4].value); setTCons("show-n"); setTransDelay("show-n"); setNogps("show-n"); setOverspeed("show-m"); setNightDrive("show-n"); setStoppags("show-n"); setLoadDelay("show-n"); setUnloaddelay("show-n"); setTripDev("show-n"); }}>
                                    <span className={"f13 " + (overspeed == 'show-m' ? 'fbold blackColor' : '')}><i className="fa fa-tachometer f22 darkblue"></i><br /> Overspeeding</span>
                                    <h4 className="darkblue f40"><span className="counter"><CountUp end={props.data.overspeeding ? props.data.overspeeding : 0} /></span></h4>
                                    <span className={"downpointer " + (overspeed)}>
                                        <i className="fa fa-caret-down"></i>
                                    </span>
                                </div>
                                <div className="col cursorPointer" onClick={(e) => { props?.setCounter(counterOptions[5].value); setTCons("show-n"); setTransDelay("show-n"); setNogps("show-n"); setOverspeed("show-n"); setNightDrive("show-m"); setStoppags("show-n"); setLoadDelay("show-n"); setUnloaddelay("show-n"); setTripDev("show-n"); }}>
                                    <span className={"f13 " + (nightdrive == 'show-m' ? 'fbold blackColor' : '')}><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                    <h4 className="txt-primary f40"><span className="counter"><CountUp end={props.data.night_driving ? props.data.night_driving : 0} /></span></h4>
                                    <span className={"downpointer " + (nightdrive)}>
                                        <i className="fa fa-caret-down"></i>
                                    </span>
                                </div>
                                <div className="col cursorPointer" onClick={(e) => { props?.setCounter(counterOptions[9].value); setTCons("show-n"); setTransDelay("show-n"); setNogps("show-n"); setOverspeed("show-n"); setNightDrive("show-n"); setStoppags("show-n"); setLoadDelay("show-m"); setUnloaddelay("show-n"); setTripDev("show-n"); }} >
                                    <span className={"f13 " + (loaddelay == 'show-m' ? 'fbold blackColor' : '')}><i className="icofont icofont-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                    <h4 className="darkorange f40"><span className="counter"><CountUp end={props.data.loading_delay ? props.data.loading_delay : 0} /></span></h4>
                                    <span className={"downpointer " + (loaddelay)}>
                                        <i className="fa fa-caret-down"></i>
                                    </span>
                                </div>
                                {
                                    props?.tabName?.value == "completed" || props?.tabName?.value == "all" ?
                                        <div className="col cursorPointer" onClick={(e) => { props?.setCounter(counterOptions[10].value); setTCons("show-n"); setTransDelay("show-n"); setNogps("show-n"); setOverspeed("show-n"); setNightDrive("show-n"); setStoppags("show-n"); setLoadDelay("show-n"); setUnloaddelay("show-m"); setTripDev("show-n"); }} >
                                            <span className={"f13 " + (unloaddelay == 'show-m' ? 'fbold blackColor' : '')}><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                            <h4 className="mildgreen f40"><span className="counter"><CountUp end={props.data.unloading_delay ? props.data.unloading_delay : 0} /></span></h4>
                                            <span className={"downpointer " + (unloaddelay)}>
                                                <i className="fa fa-caret-down"></i>
                                            </span>
                                        </div> :
                                        <></>
                                }
                                <div className="col cursorPointer" onClick={(e) => { props?.setCounter(counterOptions[6].value); setTCons("show-n"); setTransDelay("show-n"); setNogps("show-n"); setOverspeed("show-n"); setNightDrive("show-n"); setStoppags("show-m"); setLoadDelay("show-n"); setUnloaddelay("show-n"); setTripDev("show-n"); }}>
                                    <span className={"f13 " + (stoppags == 'show-m' ? 'fbold blackColor' : '')}><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                    <h4 className="maroon f40"><span className="counter"><CountUp end={props.data.stoppages ? props.data.stoppages : 0} /></span></h4>
                                    <span className={"downpointer " + (stoppags)}>
                                        <i className="fa fa-caret-down"></i>
                                    </span>
                                </div>
                                {/* <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[7].value)}>
                                    <span className="f13"><i className="icofont icofont-barricade f22 txt-danger"></i><br />Probable Accident</span>
                                    <h4 className="txt-secondary f40">
                                        <span className="counter">
                                            <CountUp className="txt-danger" end={props.data.accidents ? props.data.accidents : 0} />
                                        </span>
                                    </h4>
                                </div> */}
                                <div className="col cursorPointer" onClick={(e) => { props?.setCounter(counterOptions[8].value); setTCons("show-n"); setTransDelay("show-n"); setNogps("show-n"); setOverspeed("show-n"); setNightDrive("show-n"); setStoppags("show-n"); setLoadDelay("show-n"); setUnloaddelay("show-n"); setTripDev("show-m"); }}>
                                    <span className={"f13 " + (tripDev == 'show-m' ? 'fbold blackColor' : '')}><i className="icofont icofont-map-pins f22 txt-danger"></i><br />Deviated Routes</span>
                                    <h4 className="txt-secondary f40">
                                        <span className="counter">
                                            <CountUp className="txt-danger" end={props.data.trip_deviations ? props.data.trip_deviations : 0} />
                                        </span>
                                    </h4>
                                    <span className={"downpointer " + (tripDev)}>
                                        <i className="fa fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainCountersforDelivered