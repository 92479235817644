import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import StepOneIcon from "../../assets/icons/step1-inactive.svg";
import redirectURL from "../redirectURL";
import moment from "moment";
import "./vendorAcceptance.css";
import SweetAlert from "react-bootstrap-sweetalert";

const VendorAcceptance = () => {
  let navigate = useNavigate();
  let { rfq_event_id, vendor_id } = useParams();
  //   const location = useLocation();
  //   const queryParams = new URLSearchParams(location.search);
  //   const acceptParam = queryParams.get('accept');
  //   console.log("acceptParam",acceptParam);
  const [eventSubject, setEventSubject] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [isEventExpired, setIsEventExpired] = useState(false);
  const [responseMessage, setResponseMessage] = useState("")

  const [sweetalertConfig, setSweetalaertConfig] = useState({
    sweetAlertShow: false,
    basicType: "default",
    basicTitle: "",
  });

  useEffect(() => {
    if (rfq_event_id && vendor_id) {
      let payload = {
        rfq_event_id,
        vendor_id,
      };

      redirectURL
        .post("/procurement/getVendorMappingEventDetails", payload)
        .then((resp) => {
          console.log("getVendorMappingEventDetails data", resp.data);
          if (resp.data.message === "success") {
            let { is_invitation_accepted, is_invitation_declined } = resp.data.mappingDetails[0];
            if(is_invitation_accepted === 1 || is_invitation_declined === 1){
              navigate("/login")
            }
            let { rfq_subject, rfq_description, rfq_start_time, rfq_end_time } =
              resp.data.eventDetails[0];

            if (rfq_subject) {
              setEventSubject(rfq_subject);
            }

            if (rfq_description) {
              setEventDescription(rfq_description);
            }

            if (rfq_start_time) {
              let eventStartTime = moment
                .parseZone(rfq_start_time)
                .format("Do MMM YYYY hh:mm");
              if (eventStartTime) {
                setEventStartTime(eventStartTime);
              }
            }

            if (rfq_end_time) {
              let nowDate = moment();

              let eventEndTime = moment
                .parseZone(rfq_end_time)
                .format("Do MMM YYYY hh:mm");
              if (nowDate.isAfter(moment.parseZone(rfq_end_time))) {
                setIsEventExpired(true);
              }
              if (eventEndTime) {
                setEventEndTime(eventEndTime);
              }
            }
          }
        })
        .catch((err) => {
          console.log("getVendorMappingEventDetails err", err);
        });
    }
  }, []);

  const closeAlert = () => {
    setSweetalaertConfig({
      sweetAlertShow: false,
      basicType: "default",
      basicTitle: "",
    });    
    if(responseMessage === "success"){
      navigate("/login")
    }
  };

  const handleRfqInvitation = (acceptanceType) => {
    if (rfq_event_id && vendor_id) {
      let payload = {
        rfq_event_id,
        vendor_id,
        acceptance_type: acceptanceType,
      };

      redirectURL
        .post("/procurement/acceptVendorInvitationResponse", payload)
        .then((resp) => {
          console.log("acceptVendorInvitationResponse data", resp.data);
          if (resp.data.status === "success") {
            setSweetalaertConfig({
              sweetAlertShow: true,
              basicType: "success",
              basicTitle: resp.data.message,
            });
            setResponseMessage(resp.data.status)
          }
        })
        .catch((err) => {
          console.log("acceptVendorInvitationResponse err", err);
        });
    }
  };
  return (
    <>
      <SweetAlert
        show={sweetalertConfig.sweetAlertShow}
        type={sweetalertConfig.basicType}
        title={sweetalertConfig.basicTitle}
        onConfirm={closeAlert}
      />
      <div className="form__container" style={{ height: "387px" }}>
        <div className="form__header" id="step1">
          <div className="header__icon__container">
            <img src={StepOneIcon} alt="" />
          </div>
          <div className="header__title__container">
            <div className="header__title">Reference</div>
          </div>
        </div>

        {/* First Row */}
        <div className="row" style={{ padding: "0px 4vw" }}>
          <div className="col-6">
            <div>
              <label>
                RFQ Subject <span className="text-danger">*</span>
              </label>
            </div>
            <div>
              <div className="form-group">{eventSubject}</div>
            </div>
          </div>
          <div className="col-6">
            <div>
              <label>
                RFQ Description <span className="text-danger">*</span>
              </label>
            </div>
            <div>
              <div className="form-group">{eventDescription}</div>
            </div>
          </div>
        </div>
        {/* Second Row */}
        <div className="row" style={{ padding: "10px 4vw" }}>
          <div className="col-6">
            <label>
              Event Start Date<span className="text-danger">*</span>
            </label>
            <div className="event__schedule__datepicker">
              <div className="start__date__picker">{eventStartTime}</div>
            </div>
          </div>
          <div className="col-6">
            <label>
              Event End Date<span className="text-danger">*</span>
            </label>
            <div className="event__schedule__datepicker">
              <div className="end__date__picker">{eventEndTime}</div>
            </div>
          </div>
        </div>

        {isEventExpired ? (
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "18px",
            }}
          >
            <span className="txt-danger blinking-text">
              This Event is Expired, so can't be proceed further
            </span>
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "18px",
            }}
          >
            <span className="txt-danger blinking-text"></span>
          </div>
        )}

        <div
          className="next__btn__container"
          style={{ padding: "0px 4vw", height: "74px" }}
        >
          <button
            type="button"
            className="btn btn-primary next-button"
            disabled={isEventExpired ? "disabled" : null}
            style={{
              width: "16%",
              marginRight: "10px",
              cursor: isEventExpired ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              handleRfqInvitation("accept");
            }}
          >
            Accept Invitation
          </button>
          <button
            type="button"
            className="btn back__btn"
            style={{
              width: "16%",
              cursor: isEventExpired ? "not-allowed" : "pointer",
            }}
            disabled={isEventExpired ? "disabled" : null}
            onClick={() => {
              handleRfqInvitation("decline");
            }}
          >
            Decline Invitation
          </button>
        </div>
      </div>
    </>
  );
};

export default VendorAcceptance;
