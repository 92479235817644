import React, { Component } from "react";
import Select from "react-select";
import redirectURL from "../redirectURL";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Datetime from "react-datetime";
import Modal from "react-responsive-modal";
import "react-datetime/css/react-datetime.css";
import SweetAlert from "react-bootstrap-sweetalert";
import StepOneIcon from "../../assets/icons/step1-inactive.svg";
import StepTwoIcon from "../../assets/icons/step6-inactive.svg";
import StepThreeIcon from "../../assets/icons/step8-inactive.svg";
import StepFourIcon from "../../assets/icons/step10-inactive.svg";
import ClipIcon from "../../assets/icons/clip-icon.svg";
import PlaceholderIcon from "../../assets/icons/placeholder.svg";
import PlaceholderIcon2 from "../../assets/icons/placeholder2.svg";
import PlaceholderIcon3 from "../../assets/icons/yes-no-icon.svg";
import AddIcon from "../../assets/icons/add-icon.svg";
import SignatureIcon from "../../assets/icons/signature-icon-inactive.svg";
import SignatureIconActive from "../../assets/icons/signature-icon.svg";
import InvitedIcon from "../../assets/icons/invited.svg";
import SendInviteIcon from "../../assets/icons/WeightAndVolume/send-invite.svg";
import Favourites from "../common/favouritesComponent";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";
// import "./requestForQuotation.css";
import "./rfqRevamp.css";

import CSVFileValidator from "csv-file-validator";
import { getHyphenYYYYMMDDHHMMSS } from "../utils";
import withRouter from "../withRouter";

class RFQRevamp extends Component {
  constructor(props) {
    super(props);
    this.step2Ref = React.createRef();
    this.step3Ref = React.createRef();
    this.step4Ref = React.createRef();

    this.state = {
      openPreviewModal: false,
      vendorMailToRegister: "",
      isValidVendorMail: false,
      show: false,
      openModal: false,
      selectedFileName: "No File Selected",
      commercialFileName: "No File Selected",
      fileSelected: "",
      basicTitle: "",
      acceptedCurrency: 0,
      inputArr: [
        {
          type: "number",
          id: 1,
          value: "",
        },
      ],
      basicType: "default",
      defaultColDef: {
        // flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: true,
      },
      paginationPageSize: 200,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            { field: "callId" },
            { field: "direction" },
            { field: "number", minWidth: 150 },
            { field: "duration", valueFormatter: "x.toLocaleString() + 's'" },
            { field: "switchCode", minWidth: 150 },
          ],
          defaultColDef: {
            flex: 1,
          },
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.callRecords);
        },
      },
      rowPOData: [],
      rowVendorData: [],
      rowBidPreviewData: [],
      rowParticipantsData: [],
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      rfqEventId: "",
      rfqSubject: "",
      rfqDesc: "",
      rfqStartDate: "",
      rfqEndDate: "",
      baseCurrency: [],
      currenciesList: [{ value: "", label: "Select" }],
      // acceptedCurrencies : [],
      // acceptedConversionRates: [],
      acceptedCurrenciesList: [{}],
      tcDoc: "",
      tcDocFileUrl: "",
      // auctionType: {"value": "", "label": "Select"},
      // bidDuration: "",
      // maxBidExtCount: {"value": "", "label": "Select"},
      approvers: [],
      conversionRate: "",
      commercialTermsTitleArr: [],
      commercialTermsTitle: "",
      commercialTermsDocArr: [],
      commercialTermsDoc: "",
      attachmentsTitleArr: [],
      attachmentTitle: "",
      attachmentsDocArr: [],
      attachmentsDoc: "",
      questionsArr: [],
      questionTitle: "",
      // bidClosingType: {"value": "", "label": "Select"},
      // vendorRankDisplay: {"value": "", "label": "Select"},
      technicalApproval: { value: "", label: "" },
      viewRFQDetailsForm: "show-m",
      viewRFQParamForm: "show-n",
      viewAdditionalDetailsForm: "show-n",
      viewInviteVendorsForm: "show-n",
      viewPODetailsGrid: "show-m",
      viewVendorDetailsGrid: "show-m",
      viewBidPreviewGrid: "show-m",
      viewParticipantsGrid: "show-m",
      rfqItem: "",
      rfqItemDesc: "",
      rfqItemCapacity: { value: "", label: "" },
      rfqItemVolume: "",
      rfqItemQuantity: "",
      rfqItemTripsEstimate: 1,
      rfqItemWeightUOM: { value: "Kgs", label: "Kgs" },
      rfqItemVolumeUOM: { value: "CMT", label: "CMT" },
      rfqTargetPrice: "",
      // targetUnitPriceHideShow: {"value": "", "label": "Select"},
      // bidStartPrice: "",
      // bidStartPriceHideShow: {"value": "", "label": "Select"},
      rfqItemCode: "",
      deliveryLocation: "",
      rfqItemSourceLocation: "",
      rfqItemVehicleType: { value: "", label: "" },
      rfqItemUOM: { value: "", label: "" },
      rfqItemRemarks: "",
      // minimumDecrement: "",
      // minimumDecrementUnit: {"value": "Amount", "label": "Amount"},
      tatDays: "",
      rfqItemDistanceinkms: "",
      transitTimeinhrs: "",
      // showL1Price: {"value": "Yes", "label": "Yes"},
      poNumber: "",
      unitRate: "",
      searchVendorName: [],
      poDate: "",
      poValue: "",
      activeStep: 1,
      vendorGroup: { value: "", label: "" },
      vendorGroupOptions: [],
      inviteVendorName: [],
      selectedVendorListForInvitation: [],
      vendorNameOptions: [{ value: "", label: "Select" }],
      approverNameOptions: [{ value: "", label: "Select" }],
      finalRemarks: "",
      isComAccActive: false,
      isAttAccActive: false,
      isQuesAccActive: false,
      isFormSubmitted: 0,
      isIdGenerated: false,
      isTCDocChanged: false,
      isAttachmentsDocChanged: false,
      isCommercialTermsDocChanged: false,
      sliderRso: "",
      overly: "show-n",
      loadshow: "show-n",
      startDateFormatFxd: true,
      startDateFormatBuf: false,
      startDateFxdInp: "show-m",
      startDateBufInp: "show-n",
      startDateBuffer: 0,
      startDateBufferType: { value: "HRS", label: "HRS" },
      rfqStartTimeType: { value: "fixed_time", label: "Fixed Time" },
      rfqEndTimeType: { value: "fixed_time", label: "Fixed Time" },
      endDateFormatFxd: true,
      endDateFormatBuf: false,
      endDateFxdInp: "show-m",
      endDateBufInp: "show-n",
      endDateBuffer: 0,
      endDateBufferType: { value: "HRS", label: "HRS" },
      rowApprData: [],
      approverComments: [],
      disableSubmitButton: "disabled",
      form2: false,
      form3: false,
      form4: false,
    };
  }

  componentDidMount() {
    this.onPageLoad();
  }

  addCurrency(element) {
    if (this.state.acceptedCurrenciesList[element - 1]) {
      if (
        this.state.acceptedCurrenciesList[element - 1].currencyCode &&
        this.state.acceptedCurrenciesList[element - 1].conversionRate
      ) {
        this.setState({
          inputArr: [...this.state.inputArr, element + 1],
        });
      }
    }
  }

  removeCurrency(element) {
    if (element > 1) {
      let updatedArr = this.state.inputArr.filter(function (e) {
        return e !== element;
      });

      let updatedCurrencyList = this.state.acceptedCurrenciesList.filter(
        function (e, index) {
          return index !== element - 1;
        }
      );

      this.setState({
        inputArr: updatedArr,
        acceptedCurrenciesList: updatedCurrencyList,
      });
    }
  }

  closeModal = () => {
    document.getElementById("uploadfile").value = null;
    this.setState({
      openModal: false,
      loadshow: "show-n",
      overly: "show-n",
      slideuploadeway: "",
      sliderRouteTranslate: "",
      sliderSegmentTranslate: "",
      tabsliderTranslate: "",
      sliderTranslatesidebar: "",
      sliderForceCloseTranslate: "",
      sliderCommentTranslate: "",
      bulkPrioritySlide: "",
      sliderCreateConsignmentTranslate: "",
      sliderRso: "",
      selectedFileName: "No File Selected",
      commercialFileName: "No File Selected",
      fileSelected: "",
      commercialFileSelected: "",
      // uploadfilebulk:uploadfilebulk
    });
  };

  onPageLoad = async () => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    let user_params = {
      // email: localStorage.getItem("email"),
      email: "admin@enmovil.in",
    };
    if (this.props.match.params.hasOwnProperty("id")) {
      localStorage.setItem("rfq_event_id", this.props.match.params.id);
    } else {
      await redirectURL
        .post("/procurement/getrfqeventid", user_params)
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.rfq_event_id) {
              localStorage.setItem("rfq_event_id", response.data.rfq_event_id);
            }
          }
        });
    }

    // await redirectURL
    //   .post("/procurement/getcurrencieslist")
    //   .then((response) => {
    //     if (response.data.status === "success") {
    //       if (response.data.currenciesList) {
    //         let currenciesList = response.data.currenciesList.map(
    //           (currency) => {
    //             return {
    //               value: currency.currency_code,
    //               label: currency.currency_code,
    //             };
    //           }
    //         );
    //         this.setState({
    //           currenciesList: currenciesList,
    //         });
    //       }
    //     }
    //   });

    await redirectURL
      .post("/procurement/getallvendorsinfo")
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.allVendorDetails) {
            let vendorNameOptions = response.data.allVendorDetails.map(
              (vendor) => {
                return { value: vendor.vendor_id, label: vendor.company_name };
              }
            );
            this.setState({
              vendorNameOptions: vendorNameOptions,
            });
          }
        }
      });

    await redirectURL
      .post("/procurement/getallapproversinfo")
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.allApproversDetails) {
            let approverNameOptions = response.data.allApproversDetails.map(
              (approver) => {
                return { value: approver.approver_id, label: approver.name };
              }
            );
            this.setState({
              approverNameOptions: approverNameOptions,
            });
          }
        }
      });

    const rfq_event_id = localStorage.getItem("rfq_event_id");
    if (rfq_event_id) {
      let params = {
        rfq_event_id: rfq_event_id,
        is_form_submitted_status: 0,
      };

      await redirectURL
        .post("/procurement/getrfqdetailsinfo", params)
        .then((response) => {
          console.log("getrfqdetailsinfo data", response.data);
          if (response.data.status === "success") {
            if (response.data.eventData) {
              let eventData = response.data.eventData;
              let {
                rfq_end_date_buffer_hrs,
                rfq_end_date_is_buffer,
                rfq_end_time,

                rfq_start_date_buffer_hrs,
                rfq_start_date_is_buffer,
                rfq_start_time,
              } = eventData;

              if (
                rfq_start_date_is_buffer &&
                rfq_start_date_is_buffer === true
              ) {
                let startBuffer = parseInt(rfq_start_date_buffer_hrs);
                this.setState({
                  startDateBuffer: startBuffer,
                  startDateBufferType: { value: "HRS", label: "HRS" },
                });

                this.setState({
                  rfqStartTimeType: {
                    value: "variable_time",
                    label: "Variable Time",
                  },
                  startDateBufInp: "show-m",
                  startDateFxdInp: "show-n",
                });
              } else {
                this.setState({
                  rfqStartTimeType: {
                    value: "fixed_time",
                    label: "Fixed Time",
                  },
                  startDateBufInp: "show-n",
                  startDateFxdInp: "show-m",
                });
              }

              if (rfq_end_date_is_buffer && rfq_end_date_is_buffer === true) {
                let endBuffer = parseInt(rfq_end_date_buffer_hrs);
                this.setState({
                  endDateBuffer: endBuffer,
                  endDateBufferType: { value: "HRS", label: "HRS" },
                });

                this.setState({
                  rfqEndTimeType: {
                    value: "variable_time",
                    label: "Variable Time",
                  },
                  endDateBufInp: "show-m",
                  endDateFxdInp: "show-n",
                });
              } else {
                this.setState({
                  rfqEndTimeType: {
                    value: "fixed_time",
                    label: "Fixed Time",
                  },
                  endDateBufInp: "show-n",
                  endDateFxdInp: "show-m",
                });
              }

              if (response.data.eventData.hasOwnProperty("approver_comments")) {
                this.setState({
                  approverComments: response.data.eventData.approver_comments,
                });
              }

              let acceptedStatusesForApproval = [
                4, //RFQ_REGISTER_VENDORS_INVITED
                7, //RFQ_EVENT_CHANGE_REQUESTED
                9  //RFQ_EVENT_VENDORS_INVITE_ACCEPTED ---anyone
              ]


              if (acceptedStatusesForApproval.includes(response.data.eventData.rfq_event_status)) {
                this.setState({
                  disableSubmitButton: null,
                });
              }
              this.setState({
                rfqSubject: eventData.rfq_subject,
                // approverComments
                rfqDesc: eventData.rfq_description,
                baseCurrency: {
                  value: eventData.currency,
                  label: eventData.currency,
                },
                conversionRate: eventData.conversion_rate,
                tcDocFileUrl: eventData.tc_doc,
                rfqStartDate: moment(eventData.rfq_start_time)
                  .utcOffset(0)
                  .format("YYYY-MM-DD hh:mm:ss"),
                rfqEndDate: moment(eventData.rfq_end_time)
                  .utcOffset(0)
                  .format("YYYY-MM-DD hh:mm:ss"),
                technicalApproval: {
                  value: eventData.technical_approval,
                  label: eventData.technical_approval,
                },
              });

              if (response.data.eventItemData) {
                let eventItemData = response.data.eventItemData;
                let latestEventItemData =
                  eventItemData[eventItemData.length - 1];
                // let searchVendorName =
                //   latestEventItemData.rfq_item_search_vendor_name.map(
                //     (s_v_name) => {
                //       return { value: s_v_name, label: s_v_name };
                //     }
                //   );
                this.setState({
                  rfqItem: latestEventItemData.rfq_item,
                  rfqItemDesc: latestEventItemData.rfq_item_description,
                  rfqItemCapacity: {
                    value: latestEventItemData.rfq_item_capacity,
                    label: latestEventItemData.rfq_item_capacity
                  },
                  rfqItemTripsEstimate: latestEventItemData.rfq_item_trips_estimate,
                  // rfqItemVolume: latestEventItemData.rfq_item_volume,
                  rfqItemQuantity: latestEventItemData.rfq_item_quantity,
                  rfqItemRemarks: latestEventItemData.rfq_item_remarks,
                  rfqItemCode: latestEventItemData.rfq_item_code,
                  rfqItemWeightUOM: {
                    value: latestEventItemData.rfq_item_weight_uom,
                    label: latestEventItemData.rfq_item_weight_uom,
                  },
                  // rfqItemVolumeUOM: {
                  //   value: latestEventItemData.rfq_item_volume_uom,
                  //   label: latestEventItemData.rfq_item_volume_uom,
                  // },
                  rfqTargetPrice: latestEventItemData.rfq_item_target_price,
                  deliveryLocation:
                    latestEventItemData.rfq_item_delivery_location,
                  tatDays: latestEventItemData.rfq_item_tat_days,
                  rfqItemDistanceinkms: latestEventItemData.rfq_item_distance_inkms,
                  transitTimeinhrs: latestEventItemData.rfq_item_transit_time_inhrs,
                  rfqItemSourceLocation: latestEventItemData.rfq_item_source_location,
                  rfqItemVehicleType: {
                    value: latestEventItemData.rfq_item_vehicle_type,
                    label: latestEventItemData.rfq_item_vehicle_type
                  },
                  rfqItemUOM: {
                    value: latestEventItemData.rfq_item_UOM,
                    label: latestEventItemData.rfq_item_UOM
                  },
                  // poNumber: latestEventItemData.rfq_item_po_number,
                  // poDate: moment(latestEventItemData.rfq_item_po_date)
                  //   .utcOffset(0)
                  //   .format("YYYY-MM-DD hh:mm:ss"),
                  // searchVendorName: searchVendorName,
                  // unitRate: latestEventItemData.rfq_item_unit_rate,
                  // poValue: latestEventItemData.rfq_item_po_value,
                  rowPOData: [...this.state.rowPOData, ...eventItemData],
                });
              }

              if (response.data.eventCommercialTermsData) {
                let eventCommercialTermsData =
                  response.data.eventCommercialTermsData;
                let commercialTermsDocArr = eventCommercialTermsData.map(
                  ({
                    rfq_commercial_terms_title,
                    rfq_commercial_terms_doc,
                    _id,
                  }) => ({
                    rfq_commercial_terms_title,
                    rfq_commercial_terms_doc,
                    _id,
                  })
                );
                this.setState({
                  commercialTermsDocArr: commercialTermsDocArr,
                });
              }

              if (response.data.eventAttachmentsData) {
                let eventAttachmentsData = response.data.eventAttachmentsData;
                let attachmentsDocArr = eventAttachmentsData.map(
                  ({ rfq_attachments_title, rfq_attachments_doc }) => ({
                    rfq_attachments_title,
                    rfq_attachments_doc,
                  })
                );
                this.setState({
                  attachmentsDocArr: attachmentsDocArr,
                });
              }

              if (response.data.eventQuestionsData) {
                let eventQuestionsData = response.data.eventQuestionsData;
                let questionsArr = eventQuestionsData.map(
                  ({ rfq_questions_title, _id }) => ({
                    rfq_questions_title,
                    _id,
                  })
                );
                this.setState({
                  questionsArr: questionsArr,
                });
              }

              if (response.data.eventInvitedVendorData) {
                let row_data = [];
                let inviteVendorName = [];
                response.data.eventInvitedVendorData.map((vendor_list) => {
                  row_data.push({
                    invite_vendor_name: vendor_list.company_name,
                    invite_vendor_email: vendor_list.email,
                    invite_vendor_id: vendor_list.vendor_id,
                    is_invitation_sent: vendor_list.is_invitation_sent
                      ? "Sent"
                      : "Not Sent",
                  });

                  inviteVendorName = [
                    ...inviteVendorName,
                    {
                      value: vendor_list.vendor_id,
                      label: vendor_list.company_name,
                    },
                  ];
                });

                this.setState({
                  rowVendorData: row_data,
                  inviteVendorName: inviteVendorName,
                });
              }

              if (response.data.eventApproverData) {
                let approvers = [];
                response.data.eventApproverData.map((approver_list) => {
                  approvers = [
                    ...approvers,
                    {
                      value: approver_list.approver_id,
                      label: approver_list.name,
                    },
                  ];
                });
                this.setState({
                  approvers: approvers,
                  rowApprData: response.data.eventApproverData,
                });
              }
            }
          }
        });

      this.setState({
        rfqEventId: rfq_event_id,
        isIdGenerated: true,
        loadshow: "show-n",
        overly: "show-n",
      });
    } else {
      this.setState({
        rfqEventId: "",
        isIdGenerated: false,
        loadshow: "show-n",
        overly: "show-n",
      });
    }

    this.initialState = this.state;
  };

  customStyles = {
    option: (defaultStyles) => ({
      ...defaultStyles,
      padding: "2px 4px",
      fontSize: "1vw",
      zIndex: 9999,
      "@media only screen and (max-width: 600px)": {
        ...defaultStyles["@media only screen and (max-width: 600px)"],
        fontSize: "2.5vw",
      },
    }),

    container: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      width: "10vw",
      zIndex: 999,
      "@media only screen and (max-width: 600px)": {
        ...defaultStyles["@media only screen and (max-width: 600px)"],
        width: "45%",
        fontSize: "2vw",
      },
    }),

    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      padding: "4px",
      fontSize: "0.65vw",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      // width: "2vw"
    }),

    indicatorSeperator: (defaultStyles) => ({
      ...defaultStyles,
      display: "none",
    }),

    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      paddingLeft: "3px",
      color: "#000",
    }),

    valueContainer: (defaultStyles) => ({
      ...defaultStyles,
      height: "100%",
      zIndex: 999,
    }),

    menu: (defaultStyles) => ({
      ...defaultStyles,
      zIndex: 999,
    }),

    menuList: (defaultStyles) => ({
      ...defaultStyles,
      zIndex: 999,
    }),

    menuPortal: (defaultStyles) => ({
      ...defaultStyles,
      zIndex: 999,
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "#fff",
      fontSize: "1vw",
      borderRadius: "10px",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      height: 45,
      minHeight: 45,
      zIndex: 999,
      border: "1.08905px solid #B3B3B3",
      "@media only screen and (max-width: 600px)": {
        ...defaultStyles["@media only screen and (max-width: 600px)"],
        fontSize: "2.5vw",
      },
    }),
  };

  newFilter = (filterType, selOption) => {
    console.log("filterType ==>", filterType);
    console.log("selOption ==>", selOption);
    this.setState({ [filterType]: selOption }, () => {
      if (filterType === "rfqStartTimeType") {
        if (selOption.value === "variable_time") {
          this.setState({
            startDateBufInp: "show-m",
            startDateFxdInp: "show-n",
          });
        } else {
          this.setState({
            startDateBufInp: "show-n",
            startDateFxdInp: "show-m",
          });
        }
      }

      if (filterType === "rfqEndTimeType") {
        if (selOption.value === "variable_time") {
          this.setState({
            endDateBufInp: "show-m",
            endDateFxdInp: "show-n",
          });
        } else {
          this.setState({
            endDateBufInp: "show-n",
            endDateFxdInp: "show-m",
          });
        }
      }
    });
    if (filterType == "approvers") {
      let rowData = selOption.map((approver) => {
        return { approver_id: approver.value, name: approver.label };
      });
      this.setState({
        rowApprData: rowData,
      });
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onApprGridReady = (params) => {
    this.gridApprApi = params.api;
    this.gridApprColumnApi = params.columnApi;
  };

  changeTCFileHandler(event) {
    const file = event.target.files[0];
    const previousFile = this.state.tcDoc;
    if (file && file !== previousFile) {
      this.setState({
        isTCDocChanged: true,
        tcDoc: file,
      });
    } else {
      this.setState({
        isTCDocChanged: false,
      });
    }
    // this.setState({
    //     previousTCDoc: file,
    //     // tcDocFileUrl: fileUrl
    // })
  }

  changeCommercialTermsFileHandler(event) {
    const file = event.target.files[0];
    const previousFile = this.state.commercialTermsDoc;
    if (file && file !== previousFile) {
      const fileName = file.name;
      this.setState({
        isCommercialTermsDocChanged: true,
        commercialTermsDoc: file,
        commercialFileName: fileName,
        commercialFileSelected: fileName,
      });
    } else {
      this.setState({
        isCommercialTermsDocChanged: false,
      });
    }
  }

  changeRadioHandler(val) {
    if (val == "startDateFxd") {
      this.setState({
        startDateFormatFxd: true,
        startDateFormatBuf: false,
        startDateFxdInp: "show-m",
        startDateBufInp: "show-n",
      });
    } else if (val == "startDateBuf") {
      this.setState({
        startDateFormatFxd: false,
        startDateFormatBuf: true,
        startDateFxdInp: "show-n",
        startDateBufInp: "show-m",
      });
    } else if (val == "endDateFxd") {
      this.setState({
        endDateFormatFxd: true,
        endDateFormatBuf: false,
        endDateFxdInp: "show-m",
        endDateBufInp: "show-n",
      });
    } else {
      this.setState({
        endDateFormatFxd: false,
        endDateFormatBuf: true,
        endDateFxdInp: "show-n",
        endDateBufInp: "show-m",
      });
    }
  }

  changeAttachmentsFileHandler(event) {
    const file = event.target.files[0];
    const previousFile = this.state.attachmentsDoc;
    if (file && file !== previousFile) {
      this.setState({
        isAttachmentsDocChanged: true,
        attachmentsDoc: file,
      });
    } else {
      this.setState({
        isAttachmentsDocChanged: false,
      });
    }
  }

  handlerRFQStartDateTime = (event) => {
    var d = new Date(event._d);
    var rfqstartdate = new Date(getHyphenYYYYMMDDHHMMSS(d));
    var rfqenddate = new Date(this.state.rfqEndDate);
    var now = new Date();
    if (rfqstartdate) {
      if (rfqstartdate < now) {
        this.setState({ rfqStartDate: getHyphenYYYYMMDDHHMMSS(now) });
      } else if (rfqenddate < rfqstartdate) {
        this.setState({
          rfqEndDate: getHyphenYYYYMMDDHHMMSS(rfqstartdate),
          rfqStartDate: getHyphenYYYYMMDDHHMMSS(d),
        });
      } else {
        this.setState({ rfqStartDate: getHyphenYYYYMMDDHHMMSS(d) });
      }
    } else {
      this.setState({ rfqStartDate: "" });
    }
  };

  handlerRFQEndDateTime(event) {
    var d = new Date(event._d);
    var rfqenddate = new Date(getHyphenYYYYMMDDHHMMSS(d));
    var rfqstartdate = new Date(this.state.rfqStartDate);
    var now = new Date();
    if (rfqenddate) {
      if (rfqenddate < now) {
        this.setState({ rfqEndDate: getHyphenYYYYMMDDHHMMSS(now) });
      } else if (rfqenddate < rfqstartdate) {
        this.setState({ rfqEndDate: getHyphenYYYYMMDDHHMMSS(rfqstartdate) });
      } else {
        this.setState({ rfqEndDate: getHyphenYYYYMMDDHHMMSS(d) });
      }
    } else {
      this.setState({ rfqEndDate: "" });
    }
  }

  handlerPODateTime = (event) => {
    var d = new Date(event._d);
    this.setState({ poDate: getHyphenYYYYMMDDHHMMSS(d) });
  };

  handleRFQDetailsNextBtnClick() {
    var rfq_subject = this.state.rfqSubject;
    var rfq_desc = this.state.rfqDesc;

    if (this.state.rfqStartTimeType.value === "fixed_time") {
      var rfq_start_date_is_buffer = false;
      var rfq_start_date = this.state.rfqStartDate;
      var rfq_start_date_buffer_hrs = null;
    } else {
      var rfq_start_date_is_buffer = true;
      var rfq_start_date = null;
      if (this.state.startDateBufferType.value == "DAYS") {
        var rfq_start_date_buffer_hrs = 24 * this.state.startDateBuffer;
      } else {
        var rfq_start_date_buffer_hrs = this.state.startDateBuffer;
      }
    }

    if (this.state.rfqEndTimeType.value === "fixed_time") {
      var rfq_end_date_is_buffer = false;
      var rfq_end_date = this.state.rfqEndDate;
      var rfq_end_date_buffer_hrs = null;
    } else {
      var rfq_end_date_is_buffer = true;
      var rfq_end_date = null;
      if (this.state.endDateBufferType.value == "DAYS") {
        var rfq_end_date_buffer_hrs = 24 * this.state.endDateBuffer;
      } else {
        var rfq_end_date_buffer_hrs = this.state.endDateBuffer;
      }
    }

    // var rfq_base_currency = this.state.baseCurrency.value;
    // console.log("rfq_base_currency ==>", rfq_base_currency);
    // var blank_currency_field = this.state.acceptedCurrenciesList.filter(
    //   (element) => !element.currencyCode && !element.conversionRate
    // );
    // console.log("blank_currency_field ==>", blank_currency_field.length);

    // var rfq_accepted_currencies = this.state.acceptedCurrenciesList.filter(
    //   (element) => element.currencyCode && element.conversionRate
    // );
    var rfq_technical_approval = this.state.technicalApproval.value;
    var rfq_approvers = this.state.approvers.map((e) => e.value);

    if (
      rfq_subject &&
      rfq_desc &&
      (rfq_start_date || rfq_start_date_buffer_hrs) &&
      (rfq_end_date || rfq_end_date_buffer_hrs) &&
      // rfq_base_currency &&
      // !blank_currency_field.length &&
      // rfq_accepted_currencies
      // (this.state.tcDocFileUrl || this.state.isTCDocChanged) &&
      rfq_technical_approval &&
      rfq_approvers
    ) {
      // if(false){
      let rfq_event_id = "";
      console.log("this.state.isIdGenerated", this.state.isIdGenerated);
      if (!this.state.isIdGenerated) {
        rfq_event_id = uuidv4();
        localStorage.setItem("rfq_event_id", rfq_event_id);
        this.setState({
          isIdGenerated: true,
          rfqEventId: rfq_event_id,
        });
      } else {
        rfq_event_id = this.state.rfqEventId;
      }
      if (rfq_event_id) {
        const formData = new FormData();

        formData.append("rfq_event_id", rfq_event_id);
        formData.append("rfq_subject", rfq_subject);
        formData.append("rfq_desc", rfq_desc);
        formData.append("rfq_start_date_is_buffer", rfq_start_date_is_buffer);
        if (rfq_start_date_is_buffer)
          formData.append(
            "rfq_start_date_buffer_hrs",
            rfq_start_date_buffer_hrs
          );
        else formData.append("rfq_start_date", rfq_start_date);
        formData.append("rfq_end_date_is_buffer", rfq_end_date_is_buffer);
        if (rfq_end_date_is_buffer)
          formData.append("rfq_end_date_buffer_hrs", rfq_end_date_buffer_hrs);
        else formData.append("rfq_end_date", rfq_end_date);
        // formData.append("rfq_base_currency", rfq_base_currency);
        // formData.append(
        //   "rfq_accepted_currencies",
        //   JSON.stringify(rfq_accepted_currencies)
        // );

        if (this.state.tcDoc) formData.append("rfq_tcDoc", this.state.tcDoc);
        // formData.append("rfq_tcDocFileUrl", this.state.tcDocFileUrl);
        formData.append("rfq_technical_approval", rfq_technical_approval);
        formData.append("rfq_approvers", JSON.stringify(rfq_approvers));
        formData.append("is_form_submitted", this.state.isFormSubmitted);
        formData.append("rfq_event_status", 1);
        formData.append("is_tcDoc_changed", this.state.isTCDocChanged);
        // formData.append("user_email", localStorage.getItem("email"));
        // formData.append("user_name", localStorage.getItem("user_name"));//tmsssssss
        formData.append("user_email", "admin@enmovil.in");
        formData.append("user_name", "Enmovil Admin");

        redirectURL
          .post("/procurement/saverfqdetailsinfo", formData)
          .then((response) => {
            if (response.data.status === "success") {
              if (response.data.tcDocUrl) {
                this.setState({
                  tcDocFileUrl: response.data.tcDocUrl,
                });
              }
              this.setState({
                viewRFQDetailsForm: "show-n",
                viewRFQParamForm: "show-m",
                activeStep: 2,
              });
            }
          });
      }
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
    this.setState({
      form2: true,
    }, () => {
      setTimeout(() => {
        if (this.step2Ref.current) {
          this.step2Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100)
    })
  }

  handleRFQDetailsNextBtnClick2() {
    this.setState({
      form3: true,
    }, () => {
      setTimeout(() => {
        if (this.step3Ref.current) {
          this.step3Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100)
    })
  }
  handleRFQDetailsNextBtnClick3() {
    this.setState({
      form4: true,
    }, () => {
      setTimeout(() => {
        if (this.step4Ref.current) {
          this.step4Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100)
    })
  }
  handleRFQParamsBackBtnClick() {
    this.setState({
      viewRFQDetailsForm: "show-m",
      viewRFQParamForm: "show-n",
      activeStep: 1,
    });
  }

  handleRFQParamsNextBtnClick() {
    this.setState({
      activeStep: 3,
      viewRFQParamForm: "show-n",
      viewAdditionalDetailsForm: "show-m",
      viewInviteVendorsForm: "show-n",
    });
  }

  handleVendorAddBtnClick() {
    console.log("saverfqinvitevendorinfo", this.state.inviteVendorName);
    if (
      this.state.inviteVendorName &&
      Array.isArray(this.state.inviteVendorName) &&
      this.state.inviteVendorName.length > 0
    ) {
      var invite_vendor_list = this.state.inviteVendorName.map((e) => e.value);

      const formData = new FormData();
      formData.append("rfq_event_id", this.state.rfqEventId);
      formData.append("vendor_list", JSON.stringify(invite_vendor_list));

      redirectURL
        .post("/procurement/saverfqinvitevendorinfo", formData)
        .then((response) => {
          console.log("saverfqinvitevendorinfo data", response.data);
          if (response.data.status === "success") {
            var row_data = [];
            response.data.rfq_invite_vendor_list.map((vendor_list) => {
              row_data.push({
                invite_vendor_name: vendor_list.company_name,
                invite_vendor_email: vendor_list.email,
                invite_vendor_id: vendor_list.vendor_id,
                is_invitation_sent: vendor_list.is_invitation_sent
                  ? "Sent"
                  : "Not Sent",
              });
            });
            this.setState({
              rowVendorData: row_data,
            });
          }
        });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }

  inviteNewVendor = () => {
    if (this.state.isValidVendorMail) {
      // let vendorID = uuidv4();

      let payload = {
        rfq_event_id: this.state.rfqEventId,
        vendor_email: this.state.vendorMailToRegister,
      };

      redirectURL.post("/procurement/inviteNewVendor", payload).then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            vendorMailToRegister: "",
            show: true,
            basicTitle: "Mail has been sent for registration",
            basicType: "success",
          });
        } else {
          this.setState({
            // vendorMailToRegister: "",
            show: true,
            basicTitle: resp.data.message,
            basicType: "danger",
          });
        }
      });
    }
  };

  // handleApproverAddBtnClick() {
  //     if(this.state.approvers){
  //         let approver_list = this.state.approvers.map(e => e.value);

  //         const formData = new FormData();
  //         formData.append('rfq_event_id', this.state.rfqEventId);
  //         formData.append('approver_list', JSON.stringify(approver_list));

  //         redirectURL.post("/procurement/saverfqapproverlist",formData).then((response) => {
  //             if(response.data.status === "success")
  //             {
  //                 let row_data = [];
  //                 response.data.rfq_approver_list.map(approver_list =>{
  //                     row_data.push({
  //                         approver_id:approver_list.approver_id,
  //                         name:approver_list.name,
  //                         email:approver_list.email
  //                     })
  //                 })
  //                 console.log(row_data);
  //                 this.setState({
  //                     rowApprData: row_data
  //                 })
  //             }
  //         });
  //         return false;
  //     } else {
  //         this.setState({
  //             show: true,
  //             basicType: "danger",
  //             basicTitle: "No Approvers Found"
  //         })
  //         return false;
  //     }
  // }

  onVendorRowSelected(e) {
    if (e.node.selected) {
      this.setState((prevState, props) => ({
        selectedVendorListForInvitation: [
          ...prevState.selectedVendorListForInvitation,
          {
            vendor_id: e.node.data.invite_vendor_id,
            email: e.node.data.invite_vendor_email,
          },
        ],
      }));
    } else {
      this.setState((prevState, props) => ({
        selectedVendorListForInvitation:
          prevState.selectedVendorListForInvitation.filter(
            (arr) => arr.vendor_id != e.node.data.invite_vendor_id
          ),
      }));
    }
  }

  sendVendorInvite(e) {
    if (this.state.selectedVendorListForInvitation) {
      var selected_vendor_list = this.state.selectedVendorListForInvitation;
      console.log("selected_vendor_list", selected_vendor_list);
      console.log("selected_vendor_list", this.state.inviteVendorName);
      const formData = new FormData();
      formData.append("rfq_event_id", this.state.rfqEventId);
      formData.append(
        "selected_vendor_list",
        JSON.stringify(selected_vendor_list)
      );

      redirectURL
        .post("/procurement/sendrfqvendorinvite", formData)
        .then((response) => {
          console.log("sendrfqvendorinvite", response.data);
          if (response.data.status === "success") {
            if (
              response.data.hasOwnProperty("allVendorsAreInvited") &&
              response.data.allVendorsAreInvited
            ) {
              this.setState({
                disableSubmitButton: null,
              });
            }
            var row_data = [];
            response.data.rfq_invite_vendor_list.map((vendor_list) => {
              row_data.push({
                invite_vendor_name: vendor_list.company_name,
                invite_vendor_email: vendor_list.email,
                invite_vendor_id: vendor_list.vendor_id,
                is_invitation_sent: vendor_list.is_invitation_sent
                  ? "Sent"
                  : "Not Sent",
              });
            });
            this.setState({
              rowVendorData: row_data,
            });
          }
        });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }

  handleInviteVendorsBackBtnClick() {
    this.setState({
      activeStep: 3,
      viewRFQParamForm: "show-n",
      viewInviteVendorsForm: "show-n",
      viewAdditionalDetailsForm: "show-m",
    });
  }

  handleBidPreviewBtnClick() {
    let payload = {
      rfq_event_id: this.state.rfqEventId
    }
    redirectURL.post("/procurement/isFormSubmittable", payload).then((resp) => {
      if (resp.data.status === "success" && resp.data.eventSubmittable === true) {
        this.setState({
          disableSubmitButton: null,
          sliderTranslate: "slider-translate-70p",
        })
      } else {
        this.setState({
          disableSubmitButton: "disabled",
          sliderTranslate: "slider-translate-70p",
        })
      }
    }).catch((err) => {
      console.log("handleBidPreviewBtnClick err", err);
    })
  }

  handleAdditionalBackBtnClick() {
    this.setState({
      activeStep: 2,
      viewRFQParamForm: "show-m",
      viewAdditionalDetailsForm: "show-n",
    });
  }

  handleAdditionalNextBtnClick() {
    this.setState({
      activeStep: 4,
      viewRFQParamForm: "show-n",
      viewInviteVendorsForm: "show-m",
      viewAdditionalDetailsForm: "show-n",
    });
  }

  handleCancelBidBtnClick() { }

  handleAddApproversBtnClick() { }

  handleExportExcelBtnClick() { }

  handleImportExcelBtnClick() {
    this.setState({
      overly: "show-m",
      sliderRso: "slider-translate",
      openModal: true,
    });
  }

  handleAddRFQItemBtnClick() {
    var rfqItem = this.state.rfqItem;
    var rfqItemDesc = this.state.rfqItemDesc;
    var rfqItemCapacity = this.state.rfqItemCapacity.value;
    var rfqItemTripsEstimate = this.state.rfqItemTripsEstimate;
    // var rfqItemVolume = this.state.rfqItemVolume;
    var rfqItemQuantity = this.state.rfqItemQuantity;
    var rfqItemRemarks = this.state.rfqItemRemarks;
    var rfqItemCode = this.state.rfqItemCode;
    // var rfqItemWeightUOM = this.state.rfqItemWeightUOM.value;
    // var rfqItemVolumeUOM = this.state.rfqItemVolumeUOM.value;
    var rfqItemTargetPrice = this.state.rfqTargetPrice;
    var rfqItemDeliveryLocation = this.state.deliveryLocation;
    var rfqItemSourceLocation = this.state.rfqItemSourceLocation;
    var rfqItemVehicleType = this.state.rfqItemVehicleType.value;
    var rfqItemUOM = this.state.rfqItemUOM.value;
    var rfqItemTatDays = this.state.tatDays;
    var rfqItemDistanceinkms = this.state.rfqItemDistanceinkms;
    var transitTimeinhrs = this.state.transitTimeinhrs;
    // var rfqItemPoNumber = this.state.poNumber;
    // var rfqItemPoDate = this.state.poDate;
    // var rfqItemSearchVendorName = JSON.stringify(
    //   this.state.searchVendorName.map((e) => e.label)
    // );
    // var rfqItemUnitRate = this.state.unitRate;
    // var rfqItemPoValue = this.state.poValue;

    if (rfqItem && rfqItemCode && rfqItemDesc && rfqItemCapacity && rfqItemQuantity && rfqItemDeliveryLocation && rfqItemSourceLocation && rfqItemVehicleType && rfqItemUOM) {
      const formData = new FormData();

      formData.append("rfq_event_id", this.state.rfqEventId);
      formData.append("rfqItem", rfqItem);
      formData.append("rfqItemDesc", rfqItemDesc);
      formData.append("rfqItemCapacity", rfqItemCapacity);
      // formData.append("rfqItemVolume", rfqItemVolume);
      formData.append("rfqItemQuantity", rfqItemQuantity);
      formData.append("rfqItemRemarks", rfqItemRemarks);
      formData.append("rfqItemCode", rfqItemCode);
      // formData.append("rfqItemWeightUOM", rfqItemWeightUOM);
      // formData.append("rfqItemVolumeUOM", rfqItemVolumeUOM);
      formData.append("rfqItemTripsEstimate", rfqItemTripsEstimate);

      formData.append("rfqItemTargetPrice", rfqItemTargetPrice);
      formData.append("rfqItemDeliveryLocation", rfqItemDeliveryLocation);
      formData.append("rfqItemSourceLocation", rfqItemSourceLocation);
      formData.append("rfqItemVehicleType", rfqItemVehicleType);
      formData.append("rfqItemUOM", rfqItemUOM);
      formData.append("rfqItemTatDays", rfqItemTatDays);
      formData.append("rfqItemDistanceinkms", rfqItemDistanceinkms);
      formData.append("transitTimeinhrs", transitTimeinhrs);
      // formData.append("rfqItemPoNumber", rfqItemPoNumber);
      // formData.append("rfqItemPoDate", rfqItemPoDate);
      // formData.append("rfqItemSearchVendorName", rfqItemSearchVendorName);
      // formData.append("rfqItemUnitRate", rfqItemUnitRate);
      // formData.append("rfqItemPoValue", rfqItemPoValue);
      formData.append("is_form_submitted", this.state.isFormSubmitted);

      redirectURL
        .post("/procurement/saverfqitemdetailsinfo", formData)
        .then((response) => {
          if (response.data.status === "success") {
            this.state.rowPOData = this.state.rowPOData.filter(
              (obj) => obj.rfq_item_code != response.data.rfq_item_code
            );
            var rowData = [
              {
                rfq_item: rfqItem,
                rfq_item_description: rfqItemDesc,
                rfq_item_capacity: rfqItemCapacity,
                // rfq_item_volume: rfqItemVolume,
                rfq_item_quantity: rfqItemQuantity,
                // rfq_item_trips_estimate: rfqItemTripsEstimate,
                rfq_item_remarks: rfqItemRemarks,
                rfq_item_code: rfqItemCode,
                // rfq_item_weight_uom: rfqItemWeightUOM,
                // rfq_item_volume_uom: rfqItemVolumeUOM,
                rfq_item_target_price: rfqItemTargetPrice,
                rfq_item_delivery_location: rfqItemDeliveryLocation,
                rfq_item_source_location: rfqItemSourceLocation,
                rfq_item_vehicle_type: rfqItemVehicleType,
                rfq_item_UOM: rfqItemUOM,
                // rfq_item_tat_days: rfqItemTatDays,
                rfq_item_distance_inkms: rfqItemDistanceinkms,
                rfq_item_transit_time_inhrs: transitTimeinhrs,
                // rfq_item_po_number: rfqItemPoNumber,
                // rfq_item_po_date: rfqItemPoDate,
                // rfq_item_search_vendor_name: this.state.searchVendorName
                //   .map((e) => e.label)
                //   .toString(),
                // rfq_item_unit_rate: rfqItemUnitRate,
                // rfq_item_po_value: rfqItemPoValue,
              },
            ];
            this.setState(
              {
                rowPOData: [...this.state.rowPOData, ...rowData],
              },
              () => {
                this.setState({
                  rfqItem: "",
                  rfqItemCapacity: { value: "", label: "" },
                  // rfqItemVolume: "",
                  rfqItemQuantity: "",
                  rfqItemTripsEstimate: 1,
                  rfqItemWeightUOM: "",
                  // rfqItemVolumeUOM: "",
                  rfqItemCode: "",
                  rfqItemDesc: "",
                  rfqItemRemarks: "",
                  rfqTargetPrice: "",
                  deliveryLocation: "",
                  rfqItemSourceLocation: "",
                  rfqItemVehicleType: { value: "", label: "" },
                  rfqItemUOM: { value: "", label: "" },
                  tatDays: "",
                  rfqItemDistanceinkms: "",
                  transitTimeinhrs: "",
                });
              }
            );
          }
        });
    } else {
      this.setState({
        show: true,
        basicTitle: "Enter all fields",
        basicType: "danger",
      });
      return;
    }
  }

  handleAddCommercialTermsBtn(e) {
    e.preventDefault();
    if (this.state.commercialTermsTitle && this.state.commercialTermsDoc) {
      const formData = new FormData();
      formData.append("rfq_event_id", this.state.rfqEventId);
      formData.append("rfq_title", this.state.commercialTermsTitle);
      formData.append("rfq_doc", this.state.commercialTermsDoc);
      formData.append("is_doc_changed", this.state.isCommercialTermsDocChanged);
      formData.append("procure_rfq_table", "procure_rfq_commercial_terms");

      redirectURL
        .post("/procurement/saverfqadditionaldetailsinfo", formData)
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.rfq_title) {
              this.state.commercialTermsDocArr =
                this.state.commercialTermsDocArr.filter(
                  (obj) =>
                    obj.rfq_commercial_terms_title != response.data.rfq_title
                );
              let commercialTermsDocArr = [
                {
                  rfq_commercial_terms_title: response.data.rfq_title,
                  rfq_commercial_terms_doc: response.data.rfq_file_url,
                },
              ];
              this.setState({
                commercialTermsDocArr: [
                  ...this.state.commercialTermsDocArr,
                  ...commercialTermsDocArr,
                ],
              });
            }
          }
        });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }

  handleAddAttachmentsBtn(e) {
    e.preventDefault();
    if (this.state.attachmentTitle && this.state.attachmentsDoc) {
      const formData = new FormData();
      formData.append("rfq_event_id", this.state.rfqEventId);
      formData.append("rfq_title", this.state.attachmentTitle);
      formData.append("rfq_doc", this.state.attachmentsDoc);
      formData.append("is_doc_changed", this.state.isAttachmentsDocChanged);
      formData.append("procure_rfq_table", "procure_rfq_attachments");

      redirectURL
        .post("/procurement/saverfqadditionaldetailsinfo", formData)
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.rfq_title) {
              this.state.attachmentsDocArr =
                this.state.attachmentsDocArr.filter(
                  (obj) => obj.rfq_attachments_title != response.data.rfq_title
                );
              let attachmentsDocArr = [
                {
                  rfq_attachments_title: response.data.rfq_title,
                  rfq_attachments_doc: response.data.rfq_file_url,
                },
              ];
              this.setState({
                attachmentsDocArr: [
                  ...this.state.attachmentsDocArr,
                  ...attachmentsDocArr,
                ],
              });
            }
          }
        });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }

  handleAddQuestionsBtn(e) {
    e.preventDefault();
    if (this.state.questionTitle) {
      const formData = new FormData();
      formData.append("rfq_event_id", this.state.rfqEventId);
      formData.append("rfq_title", this.state.questionTitle);
      formData.append("is_doc_changed", 0);
      formData.append("procure_rfq_table", "procure_rfq_questions");

      redirectURL
        .post("/procurement/saverfqadditionaldetailsinfo", formData)
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.rfq_title) {
              this.state.questionsArr = this.state.questionsArr.filter(
                (obj) => obj.rfq_questions_title != response.data.rfq_title
              );
              let questionsArr = [
                {
                  rfq_questions_title: response.data.rfq_title,
                },
              ];
              this.setState({
                questionsArr: [...this.state.questionsArr, ...questionsArr],
              });
            }
          }
        });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }

  onSubmitConfigurations = (e) => {
    e.preventDefault();
    localStorage.removeItem("rfq_event_id");
    this.setState({ isIdGenerated: false });
  };

  onCloseBidPreviewSlider = () => {
    this.setState({
      sliderTranslate: "",
    });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onClickHideAll() {
    // var uploadfilebulk = $("#uploadfile").val(null)
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      slideuploadeway: "",
      sliderRouteTranslate: "",
      sliderSegmentTranslate: "",
      tabsliderTranslate: "",
      sliderTranslatesidebar: "",
      sliderForceCloseTranslate: "",
      sliderCommentTranslate: "",
      bulkPrioritySlide: "",
      sliderCreateConsignmentTranslate: "",
      sliderRso: "",
      // uploadfilebulk:uploadfilebulk
    });
  }

  previewDocument = (documentUrl) => {
    // console.log("document", document);
    const parts = documentUrl.split(".");
    const fileExtension = parts[parts.length - 1];
    this.setState({
      openPreviewModal: true,
      documentUrl: documentUrl,
      documentType: fileExtension,
    });
  };

  deleteCommercialTermsDoc = (objectID, collectionName) => {
    console.log(objectID);
    console.log(this.state.rfqEventId);
    let payload = {
      collection_name: collectionName,
      id: objectID,
      rfq_event_id: this.state.rfqEventId,
    };
    redirectURL
      .post("/procurement/deleteDocumentByID", payload)
      .then((resp) => {
        console.log("deleteDocumentByID data", resp.data);
        if (resp.data.message === "success") {
          if (collectionName === "procure_rfq_commercial_terms") {
            this.setState({
              commercialTermsDocArr: resp.data.updatedDocuments,
            });
          } else if (collectionName === "procure_rfq_questions") {
            this.setState({
              questionsArr: resp.data.updatedDocuments,
            });
          }
        }
      })
      .catch((err) => {
        console.log("deleteDocumentByID err", err);
      });
  };

  closePreviewModal = () => {
    this.setState({
      openPreviewModal: false,
    });
  };

  docUploadBtnHandler = (fileBoxId) => {
    document.getElementById(fileBoxId).click();
  };

  changeFileHandlerFc = async (e) => {
    var dt = "";
    // const config = {
    // 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
    // }

    const config = {
      headers: [
        {
          name: "ServiceCode",
          inputName: "rfq_item_code",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "ServiceName",
          inputName: "rfq_item",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "ServiceDescription",
          inputName: "rfq_item_description",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "TargetPrice",
          inputName: "rfq_item_target_price",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Capacity_mt",
          inputName: "rfq_item_capacity",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Quantity",
          inputName: "rfq_item_quantity",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "UOM",
          inputName: "rfq_item_UOM",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        // {
        //   name: "Volume",
        //   inputName: "rfq_item_volume",
        //   required: true,
        //   requiredError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        // },
        // {
        //   name: "VolumeUOM",
        //   inputName: "rfq_item_volume_uom",
        //   required: true,
        //   requiredError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        // },
        {
          name: "VehicleType_ft",
          inputName: "rfq_item_vehicle_type",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "SourceLocation",
          inputName: "rfq_item_source_location",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },

        {
          name: "DeliveryLocation",
          inputName: "rfq_item_delivery_location",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },

        {
          name: "Distance_kms",
          inputName: "rfq_item_distance_inkms",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Remarks",
          inputName: "rfq_item_remarks",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "PONo",
          inputName: "rfq_item_po_number",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "TranspoterName",
          inputName: "rfq_item_search_vendor_name",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "UnitRate",
          inputName: "rfq_item_unit_rate",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "PODate",
          inputName: "rfq_item_po_date",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "POValue",
          inputName: "rfq_item_po_value",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
      ],
    };
    var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
        this.setState({
          csvcontent: csvData.data,
        });
      })
      .catch((err) => { });
    var contentList = [];
    try {
      var out = new Promise(function (reject, resolve) {
        var reader = new FileReader();
        if (reader) {
          reader.onload = async function (e) {
            var contents = await e.target.result;
            resolve(contents);
          };
          if (e.target.files[0]) {
            var tt = reader.readAsText(e.target.files[0]);
          }
        }
      });
      this.setState({
        file: e.target.files[0],
      });
      if (e.target.files[0]) {
        if (
          e.target.files[0].type == "text/csv" ||
          e.target.files[0].type == "application/vnd.ms-excel"
        ) {
          const fileInput = e.target;
          const fileName = fileInput.files[0].name;
          this.setState({
            selectedFileName: fileName,
            fileSelected: fileName,
          });

          // console.log(fileData);
          // console.log(typeof(fileData))
        } else {
          e.target.value = null;
          this.setState({
            uploadFile: "",
            file: "",
            show: true,
            basicType: "danger",
            basicTitle: "Please upload file having extensions .csv only.",
          });
        }
      }
    } catch (e) {
      console.log("errrrrror ==>", e);
    }
  };

  onSubmitBulkUpload = async (event) => {
    event.preventDefault();

    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });

    if (this.state.csvcontent) {
      // console.log("csv-", this.state.csvcontent);
      if (this.state.csvcontent.length > 0) {
        var duplicateitemcode = [];
        var duplicatetruckno = [];
        var po_date_flag = 0;
        var customermobilenumber = [];
        var specialCharcters = 0;
        var specialCharcters1 = 0;
        var numberlength = true;
        this.state.csvcontent.map((em, i) => {
          if (em.rfq_item_code) {
            duplicateitemcode.push(em.rfq_item_code);
          }

          var podate = em.rfq_item_po_date;
          if (podate.indexOf("/") > 0) {
            podate = podate.split("/");
            if (
              podate[0].length !== 2 ||
              podate[1].length !== 2 ||
              podate[2].length !== 4
            ) {
              po_date_flag = 1;
            }

            if (po_date_flag == 0) {
              em.rfq_item_po_date =
                podate[2] + "-" + podate[1] + "-" + podate[0];
            }
          } else if (podate.indexOf("-") > 0) {
            podate = podate.split("-");
            if (
              podate[0].length !== 2 ||
              podate[1].length !== 2 ||
              podate[2].length !== 4
            ) {
              po_date_flag = 1;
            }
            if (po_date_flag == 0) {
              em.rfq_item_po_date =
                podate[2] + "-" + podate[1] + "-" + podate[0];
            }
          } else {
          }
        });

        duplicateitemcode.map((e) => {
          if (e.match(/[^a-zA-Z0-9]/g)) {
            specialCharcters1 = 1;
          }
        });

        var uniqueCheck = checkIfArrayIsUnique(duplicateitemcode);
        var rfq_event_id = localStorage.getItem("rfq_event_id");

        var params = {
          csvcontent: JSON.stringify(this.state.csvcontent),
        };

        params.rfq_event_id = rfq_event_id;
        await redirectURL
          .post("/procurement/savebulkrfqitemdetailsinfo", params)
          .then(async (response) => {
            if (response.data.eventItemData) {
              let eventItemData = response.data.eventItemData;
              let latestEventItemData = eventItemData[eventItemData.length - 1];
              this.setState({
                rfqItem: latestEventItemData.rfq_item,
                rfqItemDesc: latestEventItemData.rfq_item_description,
                rfqItemCapacity: {
                  value: latestEventItemData.rfq_item_capacity,
                  label: latestEventItemData.rfq_item_capacity
                },
                // rfqItemTripsEstimate: latestEventItemData.rfq_item_trips_estimate,
                // rfqItemVolume: latestEventItemData.rfq_item_volume,
                rfqItemQuantity: latestEventItemData.rfq_item_quantity,
                rfqItemRemarks: latestEventItemData.rfq_item_remarks,
                rfqItemCode: latestEventItemData.rfq_item_code,
                // rfqItemWeightUOM: {
                //   value: latestEventItemData.rfq_item_weight_uom,
                //   label: latestEventItemData.rfq_item_weight_uom,
                // },
                // rfqItemVolumeUOM: {
                //   value: latestEventItemData.rfq_item_volume_uom,
                //   label: latestEventItemData.rfq_item_volume_uom,
                // },
                rfqTargetPrice: latestEventItemData.rfq_item_target_price,
                deliveryLocation:
                  latestEventItemData.rfq_item_delivery_location,
                rfqItemSourceLocation: latestEventItemData.rfq_item_source_location,
                rfqItemVehicleType: {
                  value: latestEventItemData.rfq_item_vehicle_type,
                  label: latestEventItemData.rfq_item_vehicle_type,
                },
                rfqItemUOM: {
                  value: latestEventItemData.rfq_item_UOM,
                  label: latestEventItemData.rfq_item_UOM,
                },
                // tatDays: latestEventItemData.rfq_item_tat_days,
                rfqItemDistanceinkms: latestEventItemData.rfq_item_distance_inkms,
                transitTimeinhrs: latestEventItemData.rfq_item_transit_time_inhrs,
                poNumber: latestEventItemData.rfq_item_po_number,
                poDate: moment(latestEventItemData.rfq_item_po_date).format(
                  "DD/MM/YYYY hh:mm A"
                ),
                searchVendorName: {
                  value: latestEventItemData.rfq_item_search_vendor_name,
                  label: latestEventItemData.rfq_item_search_vendor_name,
                },
                unitRate: latestEventItemData.rfq_item_unit_rate,
                poValue: latestEventItemData.rfq_item_po_value,
                rowPOData: [...eventItemData],
              });
            }

            document.getElementById("uploadfile").value = null;
            this.setState({
              loadshow: "show-n",
              overly: "show-n",
              slideuploadeway: "",
              sliderRouteTranslate: "",
              sliderSegmentTranslate: "",
              tabsliderTranslate: "",
              sliderTranslatesidebar: "",
              sliderForceCloseTranslate: "",
              sliderCommentTranslate: "",
              bulkPrioritySlide: "",
              sliderCreateConsignmentTranslate: "",
              sliderRso: "",
              selectedFileName: "No File Selected",
              fileSelected: "",
            });
          });
      }
    } else {
      this.setState({
        show: true,
        basicTitle: "Choose file to continue",
        basicType: "danger",
        loadshow: "show-n",
        overly: "show-n",
      });
    }
  };

  handleEventSubmitBtnClick() {
    const formData = new FormData();
    formData.append("rfq_event_id", this.state.rfqEventId);
    formData.append("rfq_event_remarks", this.state.finalRemarks);

    redirectURL
      .post("/procurement/submitrfqevent", formData)
      .then((response) => {
        if (response.data.status === "success") {
          alert(response.data.message);
          let initialState = this.initialState;
          localStorage.removeItem("rfq_event_id");
          this.setState({
            ...initialState,
          });
          this.setState(
            {
              sliderTranslate: "",
            },
            () => {
              this.onPageLoad();
            }
          );
        }
      });
  }

  changecurrencyCode(element, index) {
    // let items = [...this.state.acceptedCurrencies];
    // items[index] = element.target.value
    // this.setState({
    //     acceptedCurrencies:items
    // });
    console.log(element);
    let currencies = [...this.state.acceptedCurrenciesList];
    let currency = { ...currencies[index] };
    currency.currencyCode = element.value;
    currencies[index] = currency;
    console.log(currencies);
    this.setState(
      {
        acceptedCurrenciesList: currencies,
      },
      () => {
        console.log(
          "acceptedCurrenciesList ==>" +
          JSON.stringify(this.state.acceptedCurrenciesList)
        );
      }
    );
  }

  changeConversionRate(element, index) {
    // let items = [...this.state.acceptedConversionRates];
    // items[index] = element.target.value
    // this.setState({
    //     acceptedConversionRates:items
    // });
    let currencies = [...this.state.acceptedCurrenciesList];
    let currency = { ...currencies[index] };
    currency.conversionRate = element.target.value;
    currencies[index] = currency;
    this.setState({
      acceptedCurrenciesList: currencies,
    });
  }

  addCurrency(element) {
    if (this.state.acceptedCurrenciesList[element - 1]) {
      if (
        this.state.acceptedCurrenciesList[element - 1].currencyCode &&
        this.state.acceptedCurrenciesList[element - 1].conversionRate
      ) {
        this.setState({
          inputArr: [...this.state.inputArr, element + 1],
        });
      }
    }
  }

  renderAcceptedCurrency = () => {
    const inputs = [];
    for (let i = 0; i < this.state.acceptedCurrency; i++) {
      inputs.push(
        <div className="currency__conversion__field">
          <div className="accepted__currency">
            <h5>Accepted Currency</h5>
            <div className="accepted__currency__dropdown">
              <Select
                id="accepted-currency"
                name="accepted-currency"
                isSearchable={true}
                options={this.state.currenciesList}
                defaultValue={"INR"}
                // styles={currencyStyles}
                onChange={""}
                placeholder={"INR"}
              />
            </div>
          </div>
          <div className="conversion__rate">
            <h5>Conversion Rate</h5>
            <input
              type="number"
              id="conversion-rate-field"
              min={1}
              className="form-control"
              name={"acceptedConversionRates" + i}
              value={
                this.state.acceptedCurrenciesList[i]
                  ? this.state.acceptedCurrenciesList[i].conversionRate
                  : ""
              }
              onChange={(e) => this.changeConversionRate.call(this, e, i)}
              required
            />
          </div>
        </div>
      );
    }
    return inputs;
  };

  changeSequence = (node, index) => {
    if (node.group) {
      console.log(index + " -> group: " + node.key);
    } else {
      this.setState((prevState) => ({
        approvers: [
          ...prevState.approvers,
          { value: node.data.approver_id, label: node.data.name },
        ],
      }));
    }
  };

  changeApproverSequence = (e) => {
    this.setState({
      approvers: [],
    });
    this.gridApprApi.forEachNode(this.changeSequence);
  };

  render() {
    const columnWithApprDefs = [
      {
        headerName: "Change Squence",
        // valueGetter: "node.rowIndex + 1",
        rowDrag: true,
      },
      {
        headerName: "Approver Id",
        field: "approver_id",
        hide: true,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Approver Name",
        width: 800,
        field: "name",
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
    ];

    const columnWithDefs = [
      {
        headerName: "Service Code",
        field: "rfq_item_code",
        width: 140,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Service Name",
        field: "rfq_item",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Service Description",
        field: "rfq_item_description",
        width: 200,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vehicle Type (ft)",
        field: "rfq_item_vehicle_type",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Target Price",
        field: "rfq_item_target_price",
        width: 110,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Capacity (MT)",
        field: "rfq_item_capacity",
        width: 120,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Quantity",
        field: "rfq_item_quantity",
        width: 120,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "UOM",
        field: "rfq_item_UOM",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //   headerName: "Trips Estimate",
      //   field: "rfq_item_trips_estimate",
      //   width: 130,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },

      // {
      //   headerName: "Volume UOM",
      //   field: "rfq_item_volume_uom",
      //   width: 130,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      {
        headerName: "Source Location",
        field: "rfq_item_source_location",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Delivery Location",
        field: "rfq_item_delivery_location",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //   headerName: "Transit Time (hrs)",
      //   field: "rfq_item_transit_time_inhrs",
      //   width: 130,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      {
        headerName: "Distance (Miles)",
        field: "rfq_item_distance_inkms",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //   headerName: "TAT",
      //   field: "rfq_item_tat_days",
      //   width: 50,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      {
        headerName: "Remarks",
        field: "rfq_item_remarks",
        width: 130,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //   headerName: "PO No.",
      //   field: "rfq_item_po_number",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Vendor Name",
      //   field: "rfq_item_search_vendor_name",
      //   width: 120,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Unit Rate",
      //   field: "rfq_item_unit_rate",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "PO Date",
      //   field: "rfq_item_po_date",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "PO Value",
      //   field: "rfq_item_po_value",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
    ];

    const cellClassRules = {
      "cell-green": (params) => params.value == "Sent",
      "cell-red": (params) => params.value == "Not Sent",
    };

    const columnWithVendorDetails = [
      {
        headerName: "Transporter Id",
        field: "invite_vendor_id",
        width: 200,
        hide: true,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Transporter Name",
        field: "invite_vendor_name",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        width: 400,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Transporter Email",
        field: "invite_vendor_email",
        width: 280,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Invitation Status",
        cellClassRules: cellClassRules,
        field: "is_invitation_sent",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: (params) => {
          if (params.data) {
            if (params.data.is_invitation_sent.toLowerCase() === "not sent") {
              return (
                <div class="invite__status__container">
                  <div class="invite__status__box">
                    <div className="invite__status__icon">
                      <img src={SendInviteIcon} alt="" />
                    </div>
                    <div class="invite__status__text">Send Invite</div>
                  </div>
                </div>
              );
            } else {
              return (
                <div class="invite__status__container">
                  <div class="invite__status__box invited">
                    <div className="invite__status__icon">
                      <img src={InvitedIcon} alt="" />
                    </div>
                    <div class="invite__status__text">Invited</div>
                  </div>
                </div>
              );
            }
          }
        },
        resizable: true,
      },
      // {
      //     headerName: "Target Price",
      // 	field: "rfq_item_target_price",
      // 	width: 110,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
      // {
      //     headerName: "Quantity",
      // 	field: "rfq_item_quantity",
      // 	width: 75,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
    ];

    const columnWithVendorPreviewDetails = [
      {
        headerName: "Transporter Id",
        field: "invite_vendor_id",
        width: 250,
        hide: true,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Transporter Name",
        field: "invite_vendor_name",
        width: 320,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Transporter Email",
        field: "invite_vendor_email",
        width: 250,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Invitation Status",
        cellClassRules: cellClassRules,
        field: "is_invitation_sent",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //     headerName: "Target Price",
      // 	field: "rfq_item_target_price",
      // 	width: 110,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
      // {
      //     headerName: "Quantity",
      // 	field: "rfq_item_quantity",
      // 	width: 75,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
    ];
    return (
      <div
        className="container-fluid"
        style={{ minHeight: "100vh", overflowY: "hidden" }}
      >
        <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
          <i className="icofont icofont-law-document cus-i"></i>&nbsp;<span>Request for Quotation</span>
          <Favourites />
      </h5>
        {/* <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="spinner-grow text-primary mr-2" role="status"></div>
          <div className="spinner-grow text-primary mr-2" role="status"></div>
          <div className="spinner-grow text-primary" role="status"></div>
        </div> */}
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>
        <div
          className={"overly-part " + this.state.overly}
          onClick={this.onClickHideAll.bind(this)}
        ></div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <Modal
          open={this.state.openPreviewModal}
          onClose={this.closePreviewModal}
        >
          <div aria-disabled={true}>
            <h6
              className="bg-primary text-white"
              style={{
                paddingTop: "10px",
                height: "38px",
                paddingLeft: "253px",
              }}
            >
              File Preview
            </h6>
            {this.state.documentType === "pdf" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "csv" ||
              this.state.documentType === "xlsx" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "png" ||
              this.state.documentType === "jpeg" ||
              this.state.documentType === "jpg" ? (
              <div className="p-3">
                <img
                  src={this.state.documentUrl}
                  // frameborder="1"
                  height="500px"
                  width="100%"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>

        <Modal open={this.state.openModal} onClose={this.closeModal}>
          <>
            <div className="modalTitle" style={{ backgroundColor: "#224abe" }}>
              <h4>
                Bulk Upload
                {/* <span
                  className="float-right closebtn"
                  style={{ marginRight: "25px" }}
                  onClick={this.onClickHideAll.bind(this)}
                >
                  X
                </span> */}
              </h4>
            </div>
            <div
              className="slide-rfq-body"
              style={{ position: "relative", overflowX: "hidden" }}
            >
              <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
              <div
                className={"row"}
                style={{
                  padding: "5px 0px",
                  width: "100%",
                  zIndex: "9999",
                  paddingLeft: "2vw",
                }}
              >
                <div className="row">
                  <div className="col-6">
                    <div>
                      <div className="form-group">
                        <label
                          className="upload__button upload"
                          style={{ zIndex: 1 }}
                          onClick={this.docUploadBtnHandler.bind(
                            this,
                            "uploadfile"
                          )}
                        >
                          <img src={ClipIcon} alt="" />
                          <h6>
                            Upload Doc <span className="text-danger">*</span>
                          </h6>
                        </label>
                        <input
                          type="file"
                          name="uploadfile"
                          id="uploadfile"
                          className=""
                          onChange={this.changeFileHandlerFc.bind(this)}
                          // style={{position:"relative", top:"42px", left:"-95px", zIndex: 0}}
                          style={{ display: "none" }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <label></label>
                    </div>
                    <div
                      style={{
                        width: "228%",
                        position: "relative",
                        top: "7px",
                      }}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          name="selectedFileName"
                          id="selectedFileName"
                          value={this.state.selectedFileName}
                          style={{ color: "black" }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column">
                  <div>
                    <button
                      type="button"
                      onClick={this.onSubmitBulkUpload.bind(this)}
                      className="btn btn-primary"
                      style={{
                        height: "51px",
                        borderRadius: "10px",
                        width: "110px",
                      }}
                      disabled={
                        this.state.fileSelected.length > 0 ? null : "disabled"
                      }
                    >
                      Submit
                    </button>

                    <label
                      className="blink"
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginLeft: "41px",
                        fontSize: "15px",
                      }}
                    >
                      Date format should be in dd-mm-yyyy hh:mm:ss
                    </label>
                  </div>

                  <div style={{ marginTop: "14px" }}>
                    <a
                      href={require("../../assets/json/sample_upload_items_data.csv")}
                      target="_blank"
                      className="btn btn-primary"
                      style={{
                        color: "white",
                        paddingTop: "10px",
                        borderRadius: "10px",
                        height: "51px",
                      }}
                    >
                      Download Template
                    </a>
                  </div>
                </div>

                {/* <div className="theme-form col-xl-12 col-lg-12">
                  
                  <div className="col-xl-12 col-lg-12 form-group">
                    <a
                      href={require("../../assets/json/sample_upload_items_data.csv")}
                      target="_blank"
                      className="btn btn-warning"
                    >
                      Download Template
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        </Modal>

        <form
          className="user"
          onSubmit={this.onSubmitConfigurations.bind(this)}
        >
          {/* Card-1 Starts Here */}
          <div className="new__reference__form__container">
            <div className="new__reference__form__header" id="step1">
              <div className="header__icon__container">
                <img src={StepOneIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 01/04</div>
                <div className="header__title">Reference</div>
              </div>
            </div>

            {/* First Row */}
            <div className="row" style={{ padding: "0px 4vw" }}>
              <div className="col-6">
                <div>
                  <label>
                    RFQ Subject <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      style={{ height: "45px" }}
                      name="rfqSubject"
                      value={this.state.rfqSubject}
                      onChange={(e) =>
                        this.setState({ rfqSubject: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label>
                    RFQ Description <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <textarea
                      type="text"
                      className="form-control pb-4"
                      style={{
                        resize: "none",
                        borderRadius: "10px",
                        overflowY: "hidden",
                        height: "45px",
                        paddingTop: "10px",
                      }}
                      name="rfqDesc"
                      value={this.state.rfqDesc}
                      onChange={(e) =>
                        this.setState({ rfqDesc: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Second Row */}
            <div class="row" style={{ padding: "0px 4vw" }}>
              <div class="col-6">
                <label>
                  Event Start Time <span className="text-danger">*</span>
                </label>
                <div class="row">
                  <div class="col-5">
                    <Select
                      closeMenuOnSelect={true}
                      name="startDateBufferType"
                      className="border-radius-0"
                      onChange={this.newFilter.bind(this, "rfqStartTimeType")}
                      options={[
                        { value: "fixed_time", label: "Fixed Time" },
                        {
                          value: "variable_time",
                          label: "Variable Time",
                        },
                      ]}
                      value={this.state.rfqStartTimeType}
                      required
                    />
                  </div>

                  <div
                    className={
                      "col-6 rfqStartDate " + this.state.startDateFxdInp
                    }
                  >
                    <Datetime
                      name="rfqStartDate"
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      isValidDate={(current) =>
                        current.isAfter(moment().subtract(1, "day"))
                      }
                      value={this.state.rfqStartDate}
                      // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      onChange={this.handlerRFQStartDateTime.bind(this)}
                      required
                    />
                  </div>

                  <div className={"col-4 " + this.state.startDateBufInp}>
                    <input
                      type="number"
                      className="form-control"
                      name="startDateBuffer"
                      min={0}
                      value={this.state.startDateBuffer}
                      onChange={(e) =>
                        this.setState({ startDateBuffer: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className={"col-3 " + this.state.startDateBufInp}>
                    <Select
                      closeMenuOnSelect={true}
                      name="startDateBufferType"
                      className="border-radius-0"
                      onChange={this.newFilter.bind(
                        this,
                        "startDateBufferType"
                      )}
                      options={[
                        { value: "HRS", label: "HRS" },
                        { value: "DAYS", label: "DAYS" },
                      ]}
                      value={this.state.startDateBufferType}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* RFQ End Time */}
              <div class="col-6">
                <label>
                  Event End Time <span className="text-danger">*</span>
                </label>
                <div class="row">
                  <div class="col-5">
                    <Select
                      closeMenuOnSelect={true}
                      name="rfqEndTimeType"
                      className="border-radius-0"
                      onChange={this.newFilter.bind(this, "rfqEndTimeType")}
                      options={[
                        { value: "fixed_time", label: "Fixed Time" },
                        {
                          value: "variable_time",
                          label: "Variable Time",
                        },
                      ]}
                      value={this.state.rfqEndTimeType}
                      required
                    />
                  </div>

                  <div
                    className={"col-6 rfqEndDate " + this.state.endDateFxdInp}
                  >
                    <Datetime
                      name="rfqEndDate"
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      value={this.state.rfqEndDate}
                      isValidDate={(current) =>
                        current.isAfter(moment().subtract(1, "day"))
                      }
                      // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      onChange={this.handlerRFQEndDateTime.bind(this)}
                      required
                    />
                  </div>

                  <div className={"col-4 " + this.state.endDateBufInp}>
                    <input
                      type="number"
                      className="form-control"
                      name="endDateBuffer"
                      min={0}
                      value={this.state.endDateBuffer}
                      onChange={(e) =>
                        this.setState({ endDateBuffer: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className={"col-3 " + this.state.endDateBufInp}>
                    <Select
                      closeMenuOnSelect={true}
                      name="endDateBufferType"
                      className="border-radius-0"
                      onChange={this.newFilter.bind(this, "endDateBufferType")}
                      options={[
                        { value: "HRS", label: "HRS" },
                        { value: "DAYS", label: "DAYS" },
                      ]}
                      value={this.state.endDateBufferType}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* RFQ End Time */}
            </div>

            {/* Third Row */}
            {/* <div className="currency__form__container">
              <div className="currency__container">
                <div className="container__tag">
                  <h5>Base Currency</h5>
                </div>
                <div className="base__currency__dropdown">
                  <Select
                    closeMenuOnSelect={true}
                    className="border-radius-0"
                    name="baseCurrency"
                    value={this.state.baseCurrency}
                    onChange={this.newFilter.bind(this, "baseCurrency")}
                    options={this.state.currenciesList}
                    required
                  />
                </div>
                <button
                  className="base__currency__addBtn"
                  onClick={this.addCurrency.bind(
                    this,
                    this.state.inputArr.length
                  )}
                >
                  {" "}
                  Add
                </button>
                <button
                  type="button"
                  class="base__currency__removeBtn"
                  onClick={this.removeCurrency.bind(
                    this,
                    this.state.inputArr.length
                  )}
                >
                  {" "}
                  Remove
                </button>
                <div>
                  {this.state.inputArr.map((item, i) => {
                    return (
                      <>
                        <div className="currency__conversion__field">
                          <div className="accepted__currency">
                            <h5>
                              Accepted Currency
                              <span className="text-danger">*</span>
                            </h5>
                            <div className="accepted__currency__dropdown">
                              <Select
                                closeMenuOnSelect={true}
                                className="border-radius-0"
                                name={"acceptedCurrencies" + i}
                                value={
                                  this.state.acceptedCurrenciesList[i]
                                    ? this.state.acceptedCurrenciesList[i]
                                        .currencyCode
                                      ? {
                                          value:
                                            this.state.acceptedCurrenciesList[i]
                                              .currencyCode,
                                          label:
                                            this.state.acceptedCurrenciesList[i]
                                              .currencyCode,
                                        }
                                      : ""
                                    : ""
                                }
                                onChange={(e) =>
                                  this.changecurrencyCode.call(this, e, i)
                                }
                                options={this.state.currenciesList}
                                required
                              />
                            </div>
                          </div>
                          <div className="conversion__rate">
                            <h5>
                              Conversion Rate
                              <span className="text-danger">*</span>
                            </h5>
                            <input
                              type="number"
                              min={1}
                              className="form-control"
                              name={"acceptedConversionRates" + i}
                              value={
                                this.state.acceptedCurrenciesList[i]
                                  ? this.state.acceptedCurrenciesList[i]
                                      .conversionRate
                                  : ""
                              }
                              onChange={(e) =>
                                this.changeConversionRate.call(this, e, i)
                              }
                              required
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div> */}
            {/* Third Row */}

            {/* Documents And Technical Aprrovers Section */}
            <div class="row" style={{ padding: "0px 4vw", marginTop: "14px" }}>
              <div class="col-6">
                <label>
                  Technical Approval <span className="text-danger">*</span>
                </label>
                <div class="row">
                  <div class="col-12">
                    <Select
                      closeMenuOnSelect={true}
                      name="technicalApproval"
                      className="border-radius-0"
                      onChange={this.newFilter.bind(this, "technicalApproval")}
                      options={[
                        { value: "Required", label: "Required" },
                        { value: "Not Required", label: "Not Required" },
                        {
                          value: "With Individual RFQ Response",
                          label: "With Individual RFQ Response",
                        },
                        {
                          value: "After All RFQ Responses",
                          label: "After All RFQ Responses",
                        },
                      ]}
                      value={this.state.technicalApproval}
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="col-6">
                <label>
                  Approvers <span className="text-danger">*</span>
                </label>
                <div class="row">
                  <div class="col-12">
                    <Select
                      closeMenuOnSelect={true}
                      name="approvers"
                      isMulti={true}
                      className="border-radius-0"
                      onChange={this.newFilter.bind(this, "approvers")}
                      options={this.state.approverNameOptions}
                      value={this.state.approvers}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card-body"
              style={{ padding: "0px 4vw", marginTop: "14px" }}
            >
              <div
                style={{ width: "100%", height: "40vh" }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  rowData={this.state.rowApprData}
                  columnDefs={columnWithApprDefs}
                  rowDragManaged={true}
                  animateRows={true}
                  defaultColDef={this.state.defaultColDef}
                  enableRangeSelection={true}
                  enableCharts={true}
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  masterDetail={true}
                  onGridReady={this.onApprGridReady}
                  onRowDragEnd={this.changeApproverSequence.bind(this)}
                  onRowDragLeave={this.changeApproverSequence.bind(this)}
                  sideBar={this.state.sideBar}
                  // frameworkComponents={this.state.frameworkComponents}
                  // statusBar={this.state.statusBar}
                  stopEditingWhenGridLosesFocus={true}
                  // paginationPageSize={this.state.paginationPageSize}
                  pagination={false}
                  gridOptionsApprover={{
                    context: { componentParent: this },
                  }}
                />
              </div>
            </div>

            {/* Documents And Technical Aprrovers Section */}
            <div className="next__btn__container">
              <div className="back__btn" style={{ cursor: "not-allowed" }}>
                Back
              </div>
              <button
                type="button"
                className="btn btn-primary next-button"
                onClick={this.handleRFQDetailsNextBtnClick.bind(this)}
              >
                {/* <a
                  href="#step2"
                  style={{ textDecoration: "none", color: "white" }}
                  role="button"
                > */}
                Save &amp; Next
                {/* </a> */}
              </button>
            </div>
            {/* Card-1 Ends Here */}
          </div>

          {/* Card-2 Starts Here */}
          <div
            className="new__product__details__form__container"
            style={{ minHeight: "70vh", display: this.state.form2 ? 'block' : 'none', }}
          >
            {/* Form Header */}
            <div className="new__product__details__form__header" ref={this.step2Ref} id="step2">
              <div className="header__icon__container">
                <img src={StepTwoIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 02/04</div>
                <div className="header__title">Transport Details</div>
              </div>
            </div>

            {/* first row */}

            <div className="row" style={{ padding: "0px 4vw" }}>
              <div className="col-xl-2 col-lg-4">
                <div>
                  <label>
                    Service Name{" "}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="rfqItem"
                      value={this.state.rfqItem}
                      onChange={(e) =>
                        this.setState({ rfqItem: e.target.value })
                      }
                      style={{ height: "45px" }}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4">
                <div>
                  <label>
                    Vehicle Type (ft){" "}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <Select
                      closeMenuOnSelect={true}
                      name="rfqItemVehicleType"
                      className="border-radius-0"
                      onChange={this.newFilter.bind(this, "rfqItemVehicleType")}
                      options={[
                        { value: 14, label: 14 },
                        { value: 17, label: 17 },
                        { value: 19, label: 19 },
                        { value: 22, label: 22 },
                        { value: 24, label: 24 },
                        { value: 32, label: 32 },
                        { value: 40, label: 40 },
                      ]}
                      value={this.state.rfqItemVehicleType}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-2 col-lg-4">
                <div>
                  <label title="metric ton">
                    Capacity (MT)
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="">
                  <input
                    type="number"
                    name="rfqItemCapacity"
                    className="form-control"
                    value={this.state.rfqItemCapacity}
                    onChange={(e) => {
                      this.setState({
                        rfqItemCapacity: e.target.value,
                      });
                    }}
                    required
                  />
                </div>
              </div> */}
              <div className="col-xl-2 col-lg-4">
                <div>
                  <label title="metric ton">
                    Capacity (MT)
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <Select
                      closeMenuOnSelect={true}
                      name="rfqItemCapacity"
                      className="border-radius-0"
                      onChange={this.newFilter.bind(this, "rfqItemCapacity")}
                      options={[
                        { value: 0.8, label: 0.8 },
                        { value: 3.5, label: 3.5 },
                        { value: 6, label: 6 },
                        { value: 7.2, label: 7.2 },
                        { value: 8.2, label: 8.2 },
                        { value: 9, label: 9 },
                        { value: 12.5, label: 12.5 },
                        { value: 16, label: 16 },
                      ]}
                      value={this.state.rfqItemCapacity}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-8">
                <div>
                  <label>
                    Quantity
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="cargo__invoice__input">
                  <input
                    type="text"
                    name="rfqItemQuantity"
                    className="form-control"
                    value={this.state.rfqItemQuantity}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, "");
                      const formattedValue = (+numericValue).toLocaleString(
                        "en-IN"
                      );
                      this.setState({
                        rfqItemQuantity: formattedValue,
                      });
                    }}
                    required
                  />
                  <div className="cargo__invoice__dropdown">
                    <Select
                      closeMenuOnSelect={true}
                      name="rfqItemUOM"
                      placeholder={"UOM"}
                      onChange={this.newFilter.bind(this, "rfqItemUOM")}
                      options={[
                        // { value: "Per Kg", label: "Per Kg" },
                        // { value: "Per CMT", label: "Per CMT" },
                        { value: "Per Trip (One-Way)", label: "Per Trip (One-Way)" },
                        { value: "Per Trip ( Round Trip)", label: "Per Trip ( Round Trip)" },
                      ]}
                      value={this.state.rfqItemUOM}
                      styles={this.customStyles}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-2 col-lg-4">
                <div>
                  <label>
                    UOM {" "}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                  <Select
                      closeMenuOnSelect={true}
                      name="rfqItemUOM"
                      className="border-radius-0"
                      onChange={this.newFilter.bind(this, "rfqItemUOM")}
                      options={[
                        { value: "Per Kg", label: "Per Kg" },
                        { value: "Per CMT", label: "Per CMT" },
                        { value: "Per Trip (One-Way)", label: "Per Trip (One-Way)" },
                        { value: "Per Trip ( Round Trip)", label: "Per Trip ( Round Trip)" },
                      ]}
                      value={this.state.rfqItemUOM}
                      required
                    />
                  </div>
                </div>
              </div>
              
              <div className="col-xl-2 col-lg-4">
                <div>
                  <label>
                    Trips Estimate
                  </label>
                </div>
                <div className="">
                  <input
                    type="number"
                    name="rfqItemTripsEstimate"
                    className="form-control"
                    min={1}
                    value={this.state.rfqItemTripsEstimate}
                    onChange={(e) => {
                      let val = (e.target.value >=1 || e.target.value == "") ? e.target.value : 1
                      this.setState({
                        rfqItemTripsEstimate: val,
                      });
                    }}
                  />
                </div>
              </div> */}
              {/* <div className="col-3">
                <div>
                  <label>
                    Volume
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="cargo__invoice__input">
                  <input
                    type="number"
                    name="rfqItemVolume"
                    className="form-control"
                    value={this.state.rfqItemVolume}
                    onChange={(e) => {
                      // const numericValue = e.target.value.replace(/\D/g, "");
                      // const formattedValue = (+numericValue).toLocaleString(
                      //   "en-IN"
                      // );
                      this.setState({
                        rfqItemVolume: e.target.value,
                      });
                    }}
                    required
                  />
                  <div className="cargo__invoice__dropdown">
                    <Select
                      closeMenuOnSelect={true}
                      name="rfqItemVolumeUOM"
                      placeholder={"UOM"}
                      onChange={this.newFilter.bind(this, "rfqItemVolumeUOM")}
                      options={[
                        { value: "CFT", label: "CFT" },
                        { value: "CMT", label: "CMT" },
                      ]}
                      value={this.state.rfqItemVolumeUOM}
                      styles={this.customStyles}
                      required
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-xl-2 col-lg-4">
                <div>
                  <label>
                    Service Code <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="rfqItemCode"
                      value={this.state.rfqItemCode}
                      onChange={(e) =>
                        this.setState({ rfqItemCode: e.target.value })
                      }
                      style={{ height: "45px" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* second Row */}
            <div className="row" style={{ padding: "0px 4vw" }}>
              <div className="col-12">
                <div>
                  <label>
                    Service Description <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <textarea
                      type="text"
                      className="form-control pb-4"
                      style={{
                        resize: "none",
                        borderRadius: "10px",
                        overflowY: "hidden",
                        height: "75px",
                        paddingTop: "10px",
                      }}
                      name="rfqItemDesc"
                      value={this.state.rfqItemDesc}
                      onChange={(e) =>
                        this.setState({ rfqItemDesc: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Third Row */}

            <div className="row" style={{ padding: "0px 4vw" }}>
              <div className="col-6">
                <div>
                  <label>Remarks</label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="rfqItemRemarks"
                      value={this.state.rfqItemRemarks}
                      onChange={(e) =>
                        this.setState({
                          rfqItemRemarks: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label>Target Price</label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="rfqTargetPrice"
                      value={this.state.rfqTargetPrice}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, "");
                        const formattedValue = (+numericValue).toLocaleString(
                          "en-IN"
                        );
                        this.setState({
                          rfqTargetPrice: formattedValue,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Fourth Row */}

            <div className="row" style={{ padding: "0px 4vw" }}>
              <div className="col-xl-4 col-lg-4">
                <div>
                  <label>
                    Source Location <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="rfqItemSourceLocation"
                      value={this.state.rfqItemSourceLocation}
                      onChange={(e) =>
                        this.setState({
                          rfqItemSourceLocation: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div>
                  <label>
                    Delivery Location <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="deliveryLocation"
                      value={this.state.deliveryLocation}
                      onChange={(e) =>
                        this.setState({
                          deliveryLocation: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div>
                  <label>Distance (Miles)</label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="rfqItemDistanceinkms"
                      value={this.state.rfqItemDistanceinkms}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, "");
                        const formattedValue = (+numericValue).toLocaleString(
                          "en-IN"
                        );
                        this.setState({ rfqItemDistanceinkms: formattedValue });
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-lg-6">
                <div>
                  <label>Transit Time (hrs)</label>
                </div>
                <div>
                  <div className="form-group">
                  <input
                      type="text"
                      className="form-control"
                      name="transitTimeinhrs"
                      value={this.state.transitTimeinhrs}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, "");
                        const formattedValue = (+numericValue).toLocaleString(
                          "en-IN"
                        );
                        this.setState({ transitTimeinhrs: formattedValue });
                      }}
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-3 col-lg-6">
                <div>
                  <label>Turn Around Time (days)</label>
                </div>
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="tatDays"
                      value={this.state.tatDays}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, "");
                        const formattedValue = (+numericValue).toLocaleString(
                          "en-IN"
                        );
                        this.setState({ tatDays: formattedValue });
                      }}
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div
              className="row align-items-center justify-content-center m-2 actionButtons"
              style={{ padding: "0px 4vw" }}
            >
              <button
                type="button"
                className="btn mr-3"
                style={{ backgroundColor: "#5d90d0", color: "white" }}
                onClick={this.handleImportExcelBtnClick.bind(this)}
              >
                Import Excel
              </button>
              <button
                type="button"
                onClick={this.handleAddRFQItemBtnClick.bind(this)}
                className="btn btn-primary mr-3"
                style={{ width: "100px" }}
              >
                Add
              </button>
              <button
                type="button"
                className="btn mr-1"
                style={{
                  backgroundColor: "#f05544",
                  color: "white",
                  width: "100px",
                }}
                onClick={() => {
                  this.setState({
                    rfqItem: "",
                    rfqItemCapacity: { value: "", label: "" },
                    rfqItemTripsEstimate: 1,
                    // rfqItemVolume: "",
                    rfqItemQuantity: "",
                    rfqItemWeightUOM: "",
                    // rfqItemVolumeUOM: "",
                    rfqItemCode: "",
                    rfqItemDesc: "",
                    rfqItemRemarks: "",
                    rfqTargetPrice: "",
                    deliveryLocation: "",
                    rfqItemSourceLocation: "",
                    rfqItemVehicleType: { value: "", label: "" },
                    rfqItemUOM: { value: "", label: "" },
                    tatDays: "",
                    transitTimeinhrs: "",
                    rfqItemDistanceinkms: "",
                  });
                }}
              >
                Clear
              </button>
            </div>

            <div
              style={{
                width: "100%",
                height: "475px",
                minHeight: "475px",
                padding: "0px 4vw",
                paddingTop: "25px",
              }}
              className="ag-theme-alpine rfqDetails"
            >
              <AgGridReact
                // domLayout="autoHeight"
                headerClass="curved-header"
                rowData={this.state.rowPOData}
                columnDefs={columnWithDefs}
                defaultColDef={this.state.defaultColDef}
                enableRangeSelection={true}
                enableCharts={true}
                detailCellRendererParams={this.state.detailCellRendererParams}
                masterDetail={true}
                onGridReady={this.onGridReady}
                sideBar={this.state.sideBar}
                // frameworkComponents={this.state.frameworkComponents}
                // statusBar={this.state.statusBar}
                stopEditingWhenGridLosesFocus={true}
                paginationPageSize={10}
                pagination={true}
                animateRows={true}
                // allowScrolling={true}
                showNoRowsOverlay={false}
                suppressRowHoverHighlight={true}
                gridOptions={{
                  context: { componentParent: this },
                }}
              />
            </div>

            <div
              className="next__btn__container"
              style={{ padding: "0px 4vw" }}
            >
              <button
                type="button"
                className="btn back__btn"
              // onClick={this.handleRFQParamsNextBtnClick.bind(this)}
              >
                {/* <a
                      href="#step1"
                      style={{ textDecoration: "none", color: "white" }}
                      role="button"
                    > */}
                Back
                {/* </a> */}
              </button>
              <button
                type="button"
                className="btn btn-primary next-button"
                onClick={this.handleRFQDetailsNextBtnClick2.bind(this)}
              >
                {/* <a
                      href="#step3"
                      style={{ textDecoration: "none", color: "white" }}
                      role="button"
                    > */}
                Save &amp; Next
                {/* </a> */}
              </button>
            </div>
          </div>

          {/* Card-2 Ends Here */}

          {/* Card-3 Starts Here */}
          <div className="additional__details__form__container" style={{ display: this.state.form3 ? 'block' : 'none' }} ref={this.step3Ref} id="step3">
            <div className="new__reference__form__header">
              <div className="header__icon__container">
                <img src={StepThreeIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 03/04</div>
                <div className="header__title">Additional Details</div>
              </div>
            </div>

            {/* Commercial Terms */}

            <div className="commercial__form__container">
              <div className="commercial__container">
                <div className="container__tag" style={{ top: "-21px" }}>
                  <h5 style={{ fontSize: "13px", fontWeight: "bold" }}>
                    Commercial Terms
                  </h5>
                </div>

                {/* Docx Input Row */}

                <div
                  className="row"
                  style={{ marginTop: "25px", marginLeft: "21px" }}
                >
                  <div className="commercial-terms-title col-4">
                    {/* <div class="commercial-terms-title"> */}
                    <input
                      name="commercialTermsTitle"
                      className="commercial-inputbox"
                      placeholder=" "
                      onChange={(e) =>
                        this.setState({
                          commercialTermsTitle: e.target.value,
                        })
                      }
                      value={this.state.commercialTermsTitle}
                      required
                    />
                    <label for="nameInput" className="commercialLabel">
                      File Name
                    </label>
                    {/* </div> */}
                  </div>

                  <div
                    className="col-2"
                    style={{ position: "relative", top: "-31px" }}
                  >
                    <div>
                      <div className="form-group">
                        <label
                          className="upload__button upload"
                          style={{ zIndex: 1 }}
                          onClick={this.docUploadBtnHandler.bind(
                            this,
                            "commercialTermsDoc"
                          )}
                        >
                          <img src={ClipIcon} alt="" />
                          <h6 style={{ position: "relative", top: "4px" }}>
                            Upload Doc <span className="text-danger">*</span>
                          </h6>
                        </label>

                        <input
                          type="file"
                          name="commercialTermsDoc"
                          id="commercialTermsDoc"
                          onChange={this.changeCommercialTermsFileHandler.bind(
                            this
                          )}
                          className=""
                          style={{ display: "none" }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4" style={{ top: "-31px" }}>
                    <div>
                      <label></label>
                    </div>
                    <div
                      style={{
                        width: "228%",
                        position: "relative",
                        top: "7px",
                      }}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          name="commercialFileName"
                          id="commercialFileName"
                          value={this.state.commercialFileName}
                          style={{
                            color: "black",
                            height: "51px",
                            width: "263px",
                            borderRadius: "10px",
                            paddingLeft: "12px",
                            fontSize: "10px",
                          }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-3">
                      
                    </div> */}

                  <div className="col-1">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleAddCommercialTermsBtn.bind(this)}
                      style={{
                        height: "51px",
                        borderRadius: "10px",
                        width: "90px",
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
                {/* Docx Input Row */}

                <span className="terms-heading">Commercial Terms</span>

                <div
                  className="termsContainer"
                  style={{
                    height: "325px",
                    overflowY: "auto",
                    padding: "0 2.1vw",
                  }}
                >
                  {this.state.commercialTermsDocArr.map((each, index) => {
                    let {
                      rfq_commercial_terms_title,
                      rfq_commercial_terms_doc,
                      _id,
                    } = each;
                    return (
                      <>
                        <div class="row" key={`term-${index}`}>
                          <div class="col-6" style={{ paddingTop: "33px" }}>
                            <input
                              type="text"
                              value={rfq_commercial_terms_title}
                              className="input-style"
                              style={{ width: "100%", paddingLeft: "16px" }}
                              disabled
                            />
                          </div>

                          <div class="col-3 txt-center">
                            <label for="">
                              <label
                                className="upload__button upload"
                                style={{ zIndex: 1, width: "170px" }}
                                // onClick={this.docUploadBtnHandler.bind(
                                //   this,
                                //   "commercialTermsDoc"
                                // )}
                                onClick={this.previewDocument.bind(
                                  this,
                                  rfq_commercial_terms_doc
                                )}
                              >
                                {/* <img src={ClipIcon} alt="" /> */}
                                <i
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  class="fa fa-2x fa-eye txt-black"
                                  aria-hidden="true"
                                ></i>
                                <h6
                                  style={{ position: "relative", top: "4px" }}
                                >
                                  Preview Doc
                                </h6>
                              </label>

                              <input
                                type="file"
                                name="commercialTermsDoc"
                                id="commercialTermsDoc"
                                // onChange={this.changeCommercialTermsFileHandler.bind(
                                //   this
                                // )}
                                className=""
                                style={{ display: "none" }}
                                required
                              />
                            </label>
                          </div>

                          <div class="col-3 txt-center">
                            <label
                              className="upload__button upload"
                              style={{ zIndex: 1, width: "170px" }}
                              onClick={this.deleteCommercialTermsDoc.bind(
                                this,
                                _id,
                                "procure_rfq_commercial_terms"
                              )}
                            >
                              <i
                                class="fa fa-2x fa-trash mx-2 txt-danger"
                                aria-hidden="true"
                              />
                              <h6 style={{ position: "relative", top: "4px" }}>
                                Delete Doc
                              </h6>
                            </label>
                          </div>

                          <div class="col-1"></div>
                        </div>
                      </>
                    );
                  })}
                </div>

                {/* Commercial Files Uploaded Info */}

                {/* <div
                    class="table-container"
                    style={{ padding: "1rem", margin: "1rem" }}
                  >
                    <table
                      class="table-scroll small-first-col"
                      style={{
                        display: "block",
                        emptyCells: "show",
                        borderSpacing: 0,
                        border: "1px solid",
                        overflow: "hidden",
                      }}
                    >
                      <thead
                        style={{
                          backgroundColor: "black",
                          position: "relative",
                          display: "block",
                          width: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <tr style={{ width: "100%", display: "flex" }}>
                          <th id="tableHeader">
                            <p>File Name</p>
                          </th>
                          <th
                            id="tableHeader"
                            style={{ position: "sticky", top: 0 }}
                          >
                            <p>File URL</p>
                          </th>
                        </tr>
                      </thead>
  
                      <tbody
                        style={{
                          display: "block",
                          position: "relative",
                          width: "100%",
                          overflowY: "scroll",
                          borderTop: "1px solid rgba(0,0,0,0.2)",
                          minHeight: "20vh",
                          maxHeight: "30vh",
                        }}
                      >
                        {this.state.commercialTermsDocArr.map(function (
                          feature,
                          i
                        ) {
                          return (
                            <tr key={`commercialTermsTitle-${i}`}>
                              <td key={`commercialTermsTitle-${i}`}>
                                {feature.rfq_commercial_terms_title}
                              </td>
                              <td key={`commercialTermsUrl-${i}`}>
                                {feature.rfq_commercial_terms_doc}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div> */}
              </div>
            </div>

            {/* Commercial Terms */}

            {/* Questions Uploaded Info */}
            <div
              className="commercial__form__container"
              style={{ margin: "20px 0px", marginTop: "42px" }}
            >
              <div
                className="commercial__container"
                style={{ marginBottom: "32px", border: "1px solid black" }}
              >
                <div className="container__tag" style={{ top: "-21px" }}>
                  <h5 style={{ fontSize: "13px", fontWeight: "bold" }}>
                    Questions
                  </h5>
                </div>

                {/* Docx Input Row */}

                <div className="row" style={{ margin: "25px 0px 32px 21px" }}>
                  <div className="commercial-terms-title col-10">
                    <input
                      name="questionTitle"
                      className="commercial-inputbox"
                      onChange={(e) =>
                        this.setState({
                          questionTitle: e.target.value,
                        })
                      }
                      value={this.state.questionTitle}
                      style={{ width: "100%" }}
                      placeholder=""
                      required
                    />

                    <label
                      for="nameInput"
                      className="commercialLabel"
                      style={{ paddingTop: "13px" }}
                    >
                      Write Question
                    </label>
                    {/* </div> */}
                  </div>

                  {/* <div className="col-3">
                      
                    </div> */}

                  <div className="col-1">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleAddQuestionsBtn.bind(this)}
                      style={{
                        height: "51px",
                        borderRadius: "10px",
                        width: "90px",
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
                {/* Docx Input Row */}

                <span className="question-heading">Questions</span>
                <div
                  className="termsContainer"
                  style={{
                    height: "325px",
                    overflowY: "auto",
                    padding: "0 2.1vw",
                  }}
                >
                  {this.state.questionsArr.map((each, index) => {
                    let { rfq_questions_title, _id } = each;
                    return (
                      <>
                        <div class="row" key={`questionterm-${index}`}>
                          <div class="col-9" style={{ paddingTop: "33px" }}>
                            <input
                              type="text"
                              value={rfq_questions_title}
                              className="input-style"
                              style={{ width: "100%", paddingLeft: "16px" }}
                              disabled
                            />
                          </div>

                          <div class="col-3 txt-center">
                            <label
                              className="upload__button upload"
                              style={{ zIndex: 1, width: "170px" }}
                              onClick={this.deleteCommercialTermsDoc.bind(
                                this,
                                _id,
                                "procure_rfq_questions"
                              )}
                            >
                              <i
                                class="fa fa-2x fa-trash mx-2 txt-danger"
                                aria-hidden="true"
                              />
                              <h6 style={{ position: "relative", top: "4px" }}>
                                Delete Doc
                              </h6>
                            </label>
                          </div>

                          <div class="col-1"></div>
                        </div>
                      </>
                    );
                  })}
                </div>

                {/* Questions Uploaded Info */}
                {/* <div
                    class="table-container"
                    style={{ padding: "1rem", margin: "1rem" }}
                  >
                    <table
                      class="table-scroll small-first-col"
                      style={{
                        display: "block",
                        emptyCells: "show",
                        borderSpacing: 0,
                        overflow: "hidden",
                      }}
                    >
                      <thead
                        style={{
                          backgroundColor: "black",
                          position: "relative",
                          display: "block",
                          width: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <tr style={{ width: "100%", display: "flex" }}>
                          <th id="tableHeader">
                            <p>Question</p>
                          </th>
                        </tr>
                      </thead>
  
                      <tbody
                        style={{
                          display: "block",
                          position: "relative",
                          width: "100%",
                          overflowY: "scroll",
                          borderTop: "1px solid rgba(0,0,0,0.2)",
                          minHeight: "20vh",
                          maxHeight: "30vh",
                        }}
                      >
                        {this.state.questionsArr.map(function (feature, i) {
                          return (
                            <tr key={`questionsTitle-${i}`}>
                              <td key={`questionsTitle--${i}`}>
                                {feature.rfq_questions_title}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div> */}
              </div>
            </div>
            {/* Questions Uploaded Info */}
            <div
              className="next__btn__container"
              style={{ padding: "0px 4vw", marginTop: "-37px" }}
            >
              <button
                type="button"
                className="btn back__btn"
              // onClick={this.handleRFQParamsNextBtnClick.bind(this)}
              >
                {/* <a
                      href="#step2"
                      style={{ textDecoration: "none", color: "white" }}
                      role="button"
                    > */}
                Back
                {/* </a> */}
              </button>
              <button
                type="button"
                className="btn btn-primary next-button"
                onClick={this.handleRFQDetailsNextBtnClick3.bind(this)}
              >
                {/* <a
                      href="#step4"
                      style={{ textDecoration: "none", color: "white" }}
                      role="button"
                    > */}
                Save &amp; Next
                {/* </a> */}
              </button>
            </div>
          </div>

          {/* Card-3 Ends Here */}

          {/* Card-4 Starts Here */}
          <div className="new__invite__vendors__container" ref={this.step4Ref} style={{ display: this.state.form4 ? 'block' : 'none' }} id="step4">
            <div className="new__reference__form__header">
              <div className="header__icon__container">
                <img src={StepFourIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 04/04</div>
                <div className="header__title">Invite Transporters</div>
              </div>
            </div>
            <div
              className="row align-items-center justify-content-center"
              style={{ padding: "0 0.6vw" }}
            >
              <div className="col-8">
                <div className="">
                  <label>
                    Transporter Name <span className="text-danger">*</span>
                  </label>
                </div>

                <div className="vendorNames">
                  <Select
                    closeMenuOnSelect={true}
                    name="inviteVendorName"
                    isMulti={true}
                    className="border-radius-0"
                    onChange={this.newFilter.bind(this, "inviteVendorName")}
                    options={this.state.vendorNameOptions}
                    value={this.state.inviteVendorName}
                    required
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="">
                  <label></label>
                </div>
                <button
                  type="button"
                  onClick={this.handleVendorAddBtnClick.bind(this)}
                  className="btn btn-primary mr-2"
                  style={{ height: "51px", borderRadius: "10px" }}
                  disabled={
                    this.state.inviteVendorName.length > 0 ? null : "disabled"
                  }
                >
                  Add
                </button>
              </div>
            </div>

            {/* //////////////// */}
            <div className="row my-3" style={{ padding: "0 4vw" }}>
              <div className="col-7">
                <label>
                  Invite Transporter
                  {/* <span className="text-danger">*</span> */}
                </label>
                <div>
                  <input
                    type="email"
                    name=""
                    id=""
                    value={this.state.vendorMailToRegister}
                    style={{
                      width: "100%",
                      height: "51px",
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      marginTop: "6px",
                    }}
                    onChange={(e) => {
                      this.setState(
                        {
                          vendorMailToRegister: e.target.value,
                        },
                        () => {
                          const emailRegex =
                            /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
                          if (
                            emailRegex.test(this.state.vendorMailToRegister)
                          ) {
                            this.setState({
                              isValidVendorMail: true,
                            });
                          } else {
                            this.setState({
                              isValidVendorMail: false,
                            });
                          }
                        }
                      );
                    }}
                    placeholder="Enter Transporter Email"
                    required
                  />
                </div>
              </div>

              <div className="col-2">
                <label></label>
                <button
                  type="button"
                  onClick={this.inviteNewVendor.bind(this)}
                  className="btn btn-primary mr-2"
                  style={{
                    height: "51px",
                    width: "200px",
                    borderRadius: "10px",
                    marginTop: "13px",
                  }}
                  disabled={this.state.isValidVendorMail ? null : "disabled"}
                >
                  Invite Transporter
                </button>
              </div>
            </div>
            {/* //////////////// */}

            <div className="row my-2 text-center">
              <button
                type="button"
                onClick={this.sendVendorInvite.bind(this)}
                disabled={this.state.selectedVendorListForInvitation.length > 0 ? false : true}
                className="btn btn-primary mr-2"
                style={{
                  borderRadius: "10px",
                  height: "51px",
                  marginLeft: "4.6vw",
                }}
              >
                Send Invite
              </button>
            </div>

            <div
              style={{
                width: "100%",
                height: "350px",
                padding: "0px 4vw",
              }}
              className="ag-theme-alpine rfqDetails"
            >
              <AgGridReact
                rowData={this.state.rowVendorData}
                columnDefs={columnWithVendorDetails}
                defaultColDef={this.state.defaultColDef}
                enableRangeSelection={true}
                enableCharts={true}
                detailCellRendererParams={this.state.detailCellRendererParams}
                masterDetail={true}
                onGridReady={this.onGridReady}
                sideBar={this.state.sideBar}
                // frameworkComponents={this.state.frameworkComponents}
                // statusBar={this.state.statusBar}
                rowSelection={"multiple"}
                onRowSelected={this.onVendorRowSelected.bind(this)}
                suppressRowClickSelection={true}
                stopEditingWhenGridLosesFocus={true}
                paginationPageSize={5}
                pagination={true}
                gridOptions={{
                  context: { componentParent: this },
                }}
              />
            </div>

            {/* <div
                className={" " + this.state.viewVendorDetailsGrid}
                style={{ padding: "0 4vw" }}
              >
                <div className={"row "}>
                  <div className="col-xl-12 col-md-12 mb-4">
                    <div className="card shadow h-100 py-2">
                      <div className="card-body">
                        <div
                          style={{ width: "100%", height: "60vh" }}
                          className="ag-theme-balham"
                        >
                          <div className="col">
                            
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

            {this.state.approverComments.length > 0 ? (
              <>
                <div class="" style={{ padding: "2vw 4vw" }}>
                  <label for="" style={{ fontWeight: "bolder" }}>
                    Approver Comments
                  </label>

                  <ul>
                    {this.state.approverComments.map((each, index) => {
                      let comment = each.split("by-");

                      return (
                        <li key={`${each}-${index}`}>
                          {comment[0]}{" "}
                          <span style={{ color: "blue", fontWeight: "bolder" }}>
                            (by {comment[1]})
                          </span>{" "}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              className="next__btn__container"
              style={{ padding: "0px 4vw" }}
            >
              <button
                type="button"
                className="btn back__btn"
              // onClick={this.handleInviteVendorsBackBtnClick.bind(this)}
              >
                {/* <a
                      href="#step3"
                      style={{ textDecoration: "none", color: "white" }}
                      role="button"
                    > */}
                Back
                {/* </a> */}
              </button>

              <button
                type="button"
                className="btn btn-primary next-button"
                onClick={this.handleBidPreviewBtnClick.bind(this)}
              >
                Preview RFQ
              </button>
            </div>
          </div>

          {/* <div className={" " + this.state.viewInviteVendorsForm}>
            <div className="col-lg-12 col-md-12">
              
            </div>
          </div> */}
          {/* Card-4 Ends Here */}

          {/* Preview Card Starts Here*/}

          <div
            className={"slide-rfq " + this.state.sliderTranslate}
            style={{ overflow: "auto", overflowX: "hidden", zIndex: "999" }}
          >
            {/* Title Card */}
            <div className="slide-rfq-title row">
              <div className="col-4"></div>
              <div className="col-4">
                <h4>RFQ Preview</h4>
              </div>
              <div className="col-4">
                <span
                  className="float-right closebtn mr-5 my-1"
                  style={{
                    marginRight: "25px",
                    width: "33px",
                    textAlign: "center",
                  }}
                  onClick={this.onCloseBidPreviewSlider.bind(this)}
                >
                  X
                </span>
              </div>
            </div>

            {/* Card-1 Starts Here */}
            <div
              className="new__reference__form__container"
              style={{ width: "61vw", minHeight: "48vh" }}
            >
              <div className="new__reference__form__header">
                <div className="header__icon__container">
                  <img src={StepOneIcon} alt="" />
                </div>
                <div className="header__title__container">
                  <div className="header__subtitle">Step 01/04</div>
                  <div className="header__title">Reference</div>
                </div>
              </div>

              {/* First Row */}
              <div className="row" style={{ padding: "0px 4vw" }}>
                <div className="col-6">
                  <div>
                    <label>
                      RFQ Subject <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div>
                    <div className="form-group">{this.state.rfqSubject}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <label>
                      RFQ Description <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div>
                    <div className="form-group">{this.state.rfqDesc}</div>
                  </div>
                </div>
              </div>
              {/* Second Row */}
              <div className="row" style={{ padding: "10px 4vw" }}>
                <div className="col-6">
                  <label>
                    Event Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="event__schedule__datepicker">
                    <div className="start__date__picker">
                      {this.state.rfqStartTimeType.value === "variable_time"
                        ? `${this.state.startDateBuffer} Hrs`
                        : this.state.rfqStartDate}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <label>
                    Event End Date<span className="text-danger">*</span>
                  </label>
                  <div className="event__schedule__datepicker">
                    <div className="end__date__picker">
                      {this.state.rfqEndTimeType.value === "variable_time"
                        ? `${this.state.endDateBuffer} Hrs`
                        : this.state.rfqEndDate}
                    </div>
                  </div>
                </div>
                {/* <div className="col-4">
                  <label>
                    Contract Starting Date <span className="redColor">*</span>
                  </label>
                  <div className="pickup__date__datepicker">
                    <div className="pickup__date__picker">
                      {this.state.rfqStartDate}
                    </div>
                  </div>
                </div> */}
              </div>
              {/* Third Row */}

              {/* <div className="row" style={{ padding: "0px 4vw" }}>
                <div className="col-4">
                  <label>Base Currency</label>
                  <div className="pickup__date__datepicker">
                    <div className="pickup__date__picker">
                      {this.state.baseCurrency.value
                        ? this.state.baseCurrency.value
                        : ""}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* Card-1 Ends Here */}

            {/* Card-2 Starts Here */}
            <div
              className="new__product__details__form__container"
              style={{
                width: "61vw",
                minHeight: "48vh",
                paddingBottom: "25px",
              }}
            >
              {/* Form Header */}
              <div className="new__product__details__form__header">
                <div className="header__icon__container">
                  <img src={StepTwoIcon} alt="" />
                </div>
                <div className="header__title__container">
                  <div className="header__subtitle">Step 02/04</div>
                  <div className="header__title">Transport Details</div>
                </div>
              </div>

              {/* Data */}
              <div
                style={{
                  width: "100%",
                  height: "475px",
                  minHeight: "475px",
                  padding: "0 3vw",
                }}
                className="ag-theme-alpine rfqDetails"
              >
                <AgGridReact
                  // domLayout="autoHeight"
                  headerClass="curved-header"
                  rowData={this.state.rowPOData}
                  columnDefs={columnWithDefs}
                  defaultColDef={this.state.defaultColDef}
                  enableRangeSelection={true}
                  enableCharts={true}
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  masterDetail={true}
                  onGridReady={this.onGridReady}
                  sideBar={this.state.sideBar}
                  // frameworkComponents={this.state.frameworkComponents}
                  // statusBar={this.state.statusBar}
                  stopEditingWhenGridLosesFocus={true}
                  paginationPageSize={10}
                  pagination={true}
                  animateRows={true}
                  // allowScrolling={true}
                  showNoRowsOverlay={false}
                  suppressRowHoverHighlight={true}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                />
              </div>
            </div>
            {/* Card-2 Ends Here */}

            {/* Card-3 Starts Here */}
            <div
              className="additional__details__form__container"
              style={{
                width: "61vw",
                minHeight: "48vh",
                paddingBottom: "20px",
              }}
            >
              <div className="new__reference__form__header">
                <div className="header__icon__container">
                  <img src={StepThreeIcon} alt="" />
                </div>
                <div className="header__title__container">
                  <div className="header__subtitle">Step 03/04</div>
                  <div className="header__title">Additional Details</div>
                </div>
              </div>

              {/* Commercial Files Uploaded Info */}
              <span
                className=""
                style={{ fontWeight: "bold", marginLeft: "3vw", color: "blue" }}
              >
                Commercial Terms
              </span>

              <div
                className="termsContainer"
                style={{
                  height: "325px",
                  overflowY: "auto",
                  margin: "20px 4vw 40px",
                  border: "1px solid black",
                }}
              >
                {this.state.commercialTermsDocArr.map((each, index) => {
                  let {
                    rfq_commercial_terms_title,
                    rfq_commercial_terms_doc,
                    _id,
                  } = each;
                  return (
                    <>
                      <div class="row" key={`term-${index}`}>
                        <div class="col-6" style={{ paddingTop: "33px" }}>
                          <input
                            type="text"
                            value={rfq_commercial_terms_title}
                            className="input-style"
                            style={{ width: "100%", paddingLeft: "16px" }}
                            disabled
                          />
                        </div>

                        <div class="col-4 txt-center">
                          <label for="">
                            <label
                              className="upload__button upload"
                              style={{ zIndex: 1, width: "170px" }}
                              // onClick={this.docUploadBtnHandler.bind(
                              //   this,
                              //   "commercialTermsDoc"
                              // )}
                              onClick={this.previewDocument.bind(
                                this,
                                rfq_commercial_terms_doc
                              )}
                            >
                              {/* <img src={ClipIcon} alt="" /> */}
                              <i
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                class="fa fa-2x fa-eye txt-black"
                                aria-hidden="true"
                              ></i>
                              <h6 style={{ position: "relative", top: "4px" }}>
                                Preview Doc
                              </h6>
                            </label>

                            <input
                              type="file"
                              name="commercialTermsDoc"
                              id="commercialTermsDoc"
                              // onChange={this.changeCommercialTermsFileHandler.bind(
                              //   this
                              // )}
                              className=""
                              style={{ display: "none" }}
                              required
                            />
                          </label>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {/* Commercial Files Uploaded Info */}

              {/* <span
                style={{
                  color: "blue",
                  fontWeight: "bolder",
                  padding: "0 3vw",
                }}
              >
                Questions
              </span>
              <div
                class="table-container"
                style={{ padding: "0 3vw", marginBottom: "20px" }}
              >
                <table
                  class="table-scroll small-first-col"
                  style={{
                    display: "block",
                    emptyCells: "show",
                    borderSpacing: 0,
                    overflow: "hidden",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "black",
                      position: "relative",
                      display: "block",
                      width: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <tr style={{ width: "100%", display: "flex" }}>
                      <th id="tableHeader">
                        <p>Question</p>
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    style={{
                      display: "block",
                      position: "relative",
                      width: "100%",
                      overflowY: "scroll",
                      borderTop: "1px solid rgba(0,0,0,0.2)",
                      minHeight: "20vh",
                      maxHeight: "30vh",
                    }}
                  >
                    {this.state.questionsArr.map(function (feature, i) {
                      return (
                        <tr key={`questionsTitle-${i}`}>
                          <td key={`questionsTitle--${i}`}>
                            {feature.rfq_questions_title}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div> */}
              <span
                className=""
                style={{ fontWeight: "bold", marginLeft: "3vw", color: "blue" }}
              >
                Questions
              </span>
              <div
                className="termsContainer"
                style={{
                  height: "325px",
                  overflowY: "auto",
                  margin: "20px 4vw 20px",
                  border: "1px solid black",
                }}
              >
                {this.state.questionsArr.map((each, index) => {
                  let { rfq_questions_title, _id } = each;
                  return (
                    <>
                      <div class="row" key={`questionterm-${index}`}>
                        <div class="col-9" style={{ paddingTop: "33px" }}>
                          <input
                            type="text"
                            value={rfq_questions_title}
                            className="input-style"
                            style={{ width: "100%", paddingLeft: "16px" }}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {/* Questions Uploaded Info */}
            </div>
            {/* Card-3 Ends Here */}

            {/* Card-4 Starts Here */}

            <div
              className="new__invite__vendors__container"
              style={{
                width: "61vw",
                minHeight: "48vh",
                paddingBottom: "30px",
              }}
            >
              <div className="new__reference__form__header">
                <div className="header__icon__container">
                  <img src={StepFourIcon} alt="" />
                </div>
                <div className="header__title__container">
                  <div className="header__subtitle">Step 04/04</div>
                  <div className="header__title">Participants Details</div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "400px",
                  padding: "0px 3vw",
                }}
                className="ag-theme-alpine rfqDetails"
              >
                <AgGridReact
                  rowData={this.state.rowVendorData}
                  columnDefs={columnWithVendorPreviewDetails}
                  defaultColDef={this.state.defaultColDef}
                  enableRangeSelection={true}
                  enableCharts={true}
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  masterDetail={true}
                  onGridReady={this.onGridReady}
                  sideBar={this.state.sideBar}
                  // frameworkComponents={this.state.frameworkComponents}
                  // statusBar={this.state.statusBar}
                  stopEditingWhenGridLosesFocus={true}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  gridOptions={{
                    context: {
                      componentParent: this,
                    },
                  }}
                />
              </div>
            </div>
            {/* Card-4 Ends Here */}

            <div
              className="new__invite__vendors__container"
              style={{
                width: "61vw",
                minHeight: "34vh",
                paddingBottom: "30px",
              }}
            >
              {/* Remarks Card */}
              <div className="row" style={{ padding: "3vh 3vw" }}>
                <div className="col-12">
                  <div>
                    <label>Remarks</label>
                  </div>
                  <div>
                    <div className="form-group">
                      <textarea
                        type="text"
                        className="form-control pb-4"
                        name="finalRemarks"
                        value={this.state.finalRemarks}
                        onChange={(e) =>
                          this.setState({
                            finalRemarks: e.target.value,
                          })
                        }
                        style={{
                          resize: "none",
                          borderRadius: "10px",
                          overflowY: "hidden",
                          height: "75px",
                          paddingTop: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Remarks Card */}

              {/* Action Buttons */}

              <div
                className="row justify-content-center"
                style={{ padding: "3vh 3vw" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary next-button"
                  onClick={this.handleEventSubmitBtnClick.bind(this)}
                  disabled={this.state.disableSubmitButton}
                  style={{
                    cursor: this.state.disableSubmitButton
                      ? "not-allowed"
                      : "pointer",
                  }}
                >
                  Submit
                </button>

                <button
                  type="button"
                  className="btn btn-info next-button"
                  onClick={this.handleExportExcelBtnClick.bind(this)}
                  style={{ width: "143px" }}
                >
                  Export To Excel
                </button>

                <button
                  type="button"
                  className="btn btn-dark next-button"
                  onClick={this.handleAddApproversBtnClick.bind(this)}
                  style={{ width: "143px" }}
                >
                  Add Approvers
                </button>

                <button
                  type="button"
                  className="btn btn-danger next-button"
                  onClick={this.handleCancelBidBtnClick.bind(this)}
                  style={{ width: "143px" }}
                >
                  Cancel RFQ
                </button>

                <button
                  type="button"
                  className="btn btn-secondary next-button"
                  onClick={this.onCloseBidPreviewSlider.bind(this)}
                >
                  Close
                </button>
              </div>

              {/* Action Buttons */}
            </div>
          </div>

          {/* Preview Card Ends Here */}
        </form>
      </div>
    );
  }
}

function checkIfArrayIsUnique(myArray) {
  return myArray.length === new Set(myArray).size;
}

export default withRouter(RFQRevamp);
