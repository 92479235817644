import { React, useEffect, useState } from 'react';
import AgGrid from "./AgGrid"
import { columnwithDefs } from "./columnDefs_vehicles";
import Constants from "./Masters_constants";
import redirectURL from "../redirectURL";
// import EditItem from './EditItem'
import SweetAlert from 'react-bootstrap-sweetalert';
import Favourites from "../common/favouritesComponent";
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';


const niprocols = [
    {
        headerName: "Destination Name",
        field: "consignee_name",
        width: 200,
        filter: true,
    },

    {
        headerName: "Destination Code",
        field: "consignee_code",
        width: 180,
        filter: true,

    },

    {
        headerName: "Destination Address",
        field: "consignee_address",
        width: 180,
        filter: true,

    },
    {
        headerName: "Destination City",
        field: "consignee_city",
        width: 180,
        filter: true,

    },
    {
        headerName: "Destination State",
        field: "consignee_state",
        width: 180,
        filter: true,

    },
    {
        headerName: "Latitude",
        field: "consignee_lat",
        width: 180,
        filter: true,

    },{
        headerName: "Longitude",
        field: "consignee_lng",
        width: 180,
        filter: true,

    },
    // {
    //     headerName: "Destination Zone",
    //     field: "consignee_zone",
    //     width: 180,
    //     filter: true,

    // },



];

function Masters() {
    var [rowData, setRowData] = useState([])
    var [columnDefs, setColumnDefs] = useState(niprocols)
    // var [showloaderbg, setshowloaderbg] = useState("show-m");
    var [showloader, setshowloader] = useState("show-m");
    var [showAddSlider, setshowAddSlider] = useState("");
    var [overlay, setoverlay] = useState("show-n");
    var [sliderText, setSliderText] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    var [showEditSlider, setshowEditSlider] = useState("");
    var [consignee_name, setdealername] = useState("");
    var [consignee_code, setdealercode] = useState("");
    var [consignee_address, setdealeraddress] = useState("");
    var [consignee_city, setdealercity] = useState("");
    var [consignee_state, setdealerstate] = useState("");
    var [consignee_zone, setdealerzone] = useState("");
    var [consignee_lat, setdealerlat] = useState("");
    var [consignee_lng, setdealerlng] = useState("");


    var [show, setShow] = useState(false);
    var [basicType, setBasicType] = useState("");
    var [basicTitle, setBasicTitle] = useState("");

    useEffect(() => {
        setTimeout(() => {
            // setshowloaderbg("show-n")
            setshowloader("show-n")
        }, 2000)
        loadData();

        // 
    }, [])

    var loadData = async () => {
        var parameters = {}
        await redirectURL.post('tracking/getDealerDetails', parameters)
            .then(response => {
                if (response.data.status == "success") {
                    setRowData(response.data.records);
                }
                // setshowloaderbg("show-n");
                setshowloader("show-n");

            })
            .catch(error => console.error('Error fetching data:', error));
    }

    var showAddBlock = async (rownode) => {
        setshowAddSlider("slider-translate-30p");
        setoverlay("show-m")
        setSliderText("Add Destination");
        setIsEditMode(false)
    }
    // var onClickShowEditItem = (rownode) => {
    //     console.log("Edit", rownode)
    //     setIsEditMode(true);
    //     setshowAddSlider("slider-translate-30p");
    //     setSliderText("Update Plant");
    //     setoverlay("show-m")

    // }
    var hideAll = () => {
        setoverlay("show-n");
        setshowEditSlider("");
        setshowAddSlider("");
    }
    var saveMasterForm = async (e) => {
        e.preventDefault();
        let user_email = localStorage.getItem('email');
        // setshowloaderbg("show-m");
        setshowloader("show-m")
        var params = {

            consignee_name: consignee_name,
            consignee_code: consignee_code,
            consignee_address: consignee_address,
            consignee_city: consignee_city,
            consignee_state: consignee_state,
            consignee_zone: consignee_zone,
            consignee_lat: consignee_lat,
            consignee_lng: consignee_lng

        }
        // console.log(params, "params");
        // console.log(isEditMode, "edit mode");
        if (isEditMode) {
            await redirectURL.post('/tracking/updateDealer', params).then((resp) => {
                // console.log(resp, "edit response");
                if (resp.data.status == "Success") {
                    setoverlay("show-n");
                    setshowAddSlider("");

                    setdealername("");
                    setdealercode("");
                    setdealeraddress("");
                    setdealercity("");
                    setdealerstate("");
                    setdealerzone("");
                    setdealerlat("");
                    setdealerlng("");


                    setIsEditMode(false);
                    setShow(true);
                    setBasicTitle("Updated data");
                    setBasicType("success");
                }
            })
        } else {
            await redirectURL.post('tracking/addDealer', params).then((resp) => {
                // console.log(resp.data, "634");
                if (resp.data.status == "success") {
                    setoverlay("show-n");
                    setshowAddSlider("");
                    setdealername("");
                    setdealercode("");
                    setdealeraddress("");
                    setdealercity("");
                    setdealerstate("");
                    setdealerzone("");
                    setdealerlat("");
                    setdealerlng("");

                    setIsEditMode(false);
                    setShow(true);
                    setBasicTitle("Added data");
                    setBasicType("success");
                    loadData();
                }
                else if (resp.data.status === "failure") {
                    setoverlay("show-n");

                    setshowloader("show-n");
                    setIsEditMode(false);
                    setshowAddSlider("");
                    setdealername("");
                    setdealercode("");
                    setdealeraddress("");
                    setdealercity("");
                    setdealerstate("");
                    setdealerzone("");
                    setdealerlat("");
                    setdealerlng("");

                    setShow(true);
                    setBasicTitle(resp.data.message);
                    setBasicType("danger")
                }
            }).catch(err => {
                console.log(err, "error");
            })
        }
    }

    var handleDealerName = (e) => {
        var inputValue = e.target.value;
        if (inputValue != "") {
            var isValid = /^[A-Za-z ]*$/;
            if (isValid.test(inputValue)) {
                if (inputValue.length <= 100) {
                    setdealername(inputValue)
                }
            }
        }
        else {
            setdealername("");
        }
    };

    var handleDealerCode = (e) => {
        if (isEditMode) return;

        var inputValue = e.target.value;
        var numericInput = inputValue.replace(/[^0-9A-Z]/g, '');

        if (numericInput !== "") {
            if (numericInput.length <= 10) {
                setdealercode(numericInput);
            } else {
                setdealercode(numericInput.substring(0, 10));
            }
        } else {
            setdealercode("");
        }
    }
    var handleDealerAddress = (e) => {
        var inputValue = e.target.value;
        if (inputValue != "") {
            var isValid = /^[A-Za-z ]*$/;
            if (isValid.test(inputValue)) {
                if (inputValue.length <= 100) {
                    setdealeraddress(inputValue)
                }
            }
        }
        else {
            setdealeraddress("");
        }
    };
    var handleDealerCity = (e) => {
        var inputValue = e.target.value;
        if (inputValue != "") {
            var isValid = /^[A-Za-z ]*$/;
            if (isValid.test(inputValue)) {
                if (inputValue.length <= 100) {
                    setdealercity(inputValue)
                }
            }
        }
        else {
            setdealercity("");
        }
    };
    var handleDealerState = (e) => {
        var inputValue = e.target.value;
        if (inputValue != "") {
            var isValid = /^[A-Za-z ]*$/;
            if (isValid.test(inputValue)) {
                if (inputValue.length <= 100) {
                    setdealerstate(inputValue)
                }
            }
        }
        else {
            setdealerstate("");
        }
    };
    var handleDealerZone = (e) => {
        var inputValue = e.target.value;
        if (inputValue != "") {
            var isValid = /^[A-Za-z ]*$/;
            if (isValid.test(inputValue)) {
                if (inputValue.length <= 100) {
                    setdealerzone(inputValue)
                }
            }
        }
        else {
            setdealerzone("");
        }
    };
    var handleDealerlat = (e) => {
        var inputValue = e.target.value;
        if (inputValue != "") {
            if (inputValue.length <= 20) {
                setdealerlat(inputValue)
            }
        }
        else {
            setdealerlat("");
        }
    };
    var handleDealerlng = (e) => {
        var inputValue = e.target.value;
        if (inputValue != "") {
            if (inputValue.length <= 20) {
                setdealerlng(inputValue)
            }
        }
        else {
            setdealerlng("");
        }
    };






    return (
        <div>
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={() => {
                        setShow(false)
                    }}>
                </SweetAlert>


            </div>
            <div className="container-fluid">
                <div className="row">
                    <h5 class="col-sm-12 mb-15p mt-15p fbold f20">
						<i class="fa fa-map-marker cus-i"></i>&nbsp;<span>Destinations</span>
                        <Favourites />
                        <button className="btn btn-warning pull-right f12" onClick={showAddBlock}>Add Destination</button>
                               
					</h5>

                    <div className="col-xl-12 col-md-12 xl-60">
                        <div className="card">
                            <div className="card-body">
                                <AgGrid
                                    rowData={rowData}
                                    //  columnDefs={columnwithDefs}
                                    columnDefs={columnDefs}
                                    currentPage={window.location.pathname}
                                    others={""}
                                    // onClickEditBlock={onClickShowEditItem}
                                    saveoptions={"N"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"overlay-part " + (overlay)} onClick={hideAll}></div>

            <div className={"slide-r " + showAddSlider}>
                <div className="slide-r-title">
                    <h4 className="">{sliderText}
                        {/* <span className="float-right closebtn" onClick={hideAll}> &times;   </span> */}
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={hideAll} >X</span>

                    </h4>
                </div>
                <div className="slide-r-body">

                    <form className="" style={{ padding: "20px" }} onSubmit={saveMasterForm}>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Destination Name<span style={{ color: 'red' }}>*</span></label>
                            <input type="text"
                                name="consignee_name"
                                id="consignee_name"
                                value={consignee_name}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }} onChange={handleDealerName} required />
                        </div>

                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Destination Code<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="consignee_code"
                                id="consignee_code"
                                value={consignee_code}
                                onChange={handleDealerCode}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Destination Address<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="consignee_address"
                                id="consignee_address"
                                value={consignee_address}
                                onChange={handleDealerAddress}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Destination City<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="consignee_city"
                                id="consignee_city"
                                value={consignee_city}
                                onChange={handleDealerCity}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Destination State<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="consignee_state"
                                id="consignee_state"
                                value={consignee_state}
                                onChange={handleDealerState}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div>
                        {/* <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Destination Zone<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="consignee_zone"
                                id="consignee_zone"
                                value={consignee_zone}
                                onChange={handleDealerZone}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div> */}
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Destination Latitude<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="consignee_lat"
                                id="consignee_lat"
                                value={consignee_lat}
                                onChange={handleDealerlat}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Destination Longitude<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="consignee_lng"
                                id="consignee_lng"
                                value={consignee_lng}
                                onChange={handleDealerlng}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div>

                        <div className="form-group" style={{ textAlign: "center" }}>
                            <button type="submit" className="btn btn-success"
                                style={{
                                    padding: "10px 20px",
                                    border: "none",
                                    borderRadius: "4px",
                                    backgroundColor: "#28a745",
                                    color: "white",
                                }}
                            >Add Destination</button>
                        </div>

                    </form>
                </div>

            </div>
            {/* <div className={"slide-r "+showEditSlider}>
             <div className="slider-header">
                    <h4 className="slide-r-title">Update Customer</h4>
                    <span className="close-button" onClick={hideAll}>
                    &times;
                    </span>
                </div>
                <div className="slide-r-body">
  
                    <form className="row" onSubmit={saveMasterForm}>
                        <div className="form-group">
                            <label>Plant Name</label>
                            <input type="text" name="plant_name" value={plant_name} onChange={(e) => setplantname(e.target.value)}  required />
                        </div>
                        <div className="form-group">
                            <label>Plant Code </label>
                            <input type="text" name="plant_code" id="plant_code" value={plant_code} onChange={(e) => setplantcode(e.target.value)}  required />
                        </div>
                        <div className="form-group">
                            <label>Company Name</label>
                            <input type="text" name="company_name" value={company_name} onChange={(e) => setcompanyname(e.target.value)}  required />
                        </div>
                        <div className="form-group">
                            <label>Contact Name</label>
                            <input type="text" name="contact_name" value={contact_name} onChange={(e) => setcontactname(e.target.value)}  required />
                        </div>
                        <div className="form-group">
                            <label>Contact Number</label>
                            <input type="text" name="contact_no" value={contact_no} onChange={(e) => setconsigneetelephoneno(e.target.value)}  required />
                        </div>
                        <div className="form-group">
                            <label>Email Address</label>
                            <input type="email" name="email_id" value={email_id} onChange={(e) => setemailid(e.target.value)}  required />
                        </div>
                        <div className="form-group">
                            <label>NIPRO Branch Office Address</label>
                            <input type="text" name="plant_address" value={plant_address} onChange={(e) => setniprobranchofficeaddress(e.target.value)}  required />
                        </div>
                        <div className="form-group">
                            <label>Consigner Pincode</label>
                            <input type="text" name="consigner_pincode" value={consigner_pincode} onChange={(e) => setpincode(e.target.value)} />
                        </div>
                    </form>
                </div>
            </div> */}

        </div>
    );
}

export default Masters;


