import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TrackingShipGreen from "../../assets/icons/tracking-ship-green.svg";
import TrackingShipRed from "../../assets/icons/tracking-ship-red.svg";
import TrackingShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import FlagGrey from "../../assets/icons/flag-grey.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import ExportIcon from "../../assets/icons/export-arrow.svg";
import ImportIcon from "../../assets/icons/import-arrow.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CompletedIcon from "../../assets/icons/completed-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import RefreshIcon from "../../assets/icons/refresh-icon.svg";
import { countries } from "country-flags-svg";
import Modal from "react-responsive-modal";
import redirectURL from "../redirectURL";
import moment from "moment";
import copyIcon from "../../assets/icons/copyIcon.png";
import $ from "jquery";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./trackingTile.css";
import ModalEditShipment from "./ModalEditShipment";
import NewShipment from "./NewShipment";
import UntrackableTiles from "./untrackableTiles";
import Pagination from 'react-bootstrap/Pagination';
import EmissionCloud from '../../assets/icons/co2-1.svg'
import InfoIcon from '../../assets/icons/info.png';
import SweetAlert from "react-bootstrap-sweetalert";

var shareURL = "https://demo.autometrics.in"

const TrackingTile = (props) => {
  console.log("tracktile propsprops", props);
  const customStyles = {
    modal: {
      width: '600px', // Set your desired width here
    },
  };

  let currentItems;
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [basicType, setBasicType] = useState("default");
  const [basicTitle, setBasicTitle] = useState("");
  const [show2, setShow2] = useState(false);
  const [basicType2, setBasicType2] = useState("default");
  const [basicTitle2, setBasicTitle2] = useState("");
  let ITEMS_PER_PAGE;
  if (props.tilesPerPage.label == "ALL" && props.tabActive == "BL/Booking")
    ITEMS_PER_PAGE = props?.bl_data?.length
  else if (props.tilesPerPage.label == "ALL" && props.tabActive == "Container")
    ITEMS_PER_PAGE = props?.container_data?.length
  else
    ITEMS_PER_PAGE = props.tilesPerPage.value
  let indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  let indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useState({
    openCloseTrackingModal: false,
    openEditShipmentDetailsModal: false,
    openDeleteModal: false,
    openShareModal: false,
    modalOpened: "",
    trackingDeletionReason: "",
    shipmentToBeDeleted: "",
    sharingType: "",
    tracking_url: "",
    tracking_value: "",
    emailID: "",
    shareEmailButton: "disabled",
    responseMessage: "",
    responseMessageClass: "",
    tabType: "",
    trackingToBeClosedType: "",
    trackingToBeClosedTypeValue: "",
    forceCloseTrackingReason: "",
  });
  if (props.tabActive === "BL/Booking") {
    currentItems = props.bl_data.slice(indexOfFirstItem, indexOfLastItem);
  }
  else {
    currentItems = props.container_data.slice(indexOfFirstItem, indexOfLastItem);
  }

  useEffect(() => {
    if (props.tilesPerPage.label === "ALL") {
      ITEMS_PER_PAGE = props.tilesPerPage.value
      indexOfFirstItem = 0
      indexOfLastItem = props.tilesPerPage.value - 1
      setCurrentPage(1)
    }
    if (props.tabActive === "BL/Booking") {
      currentItems = props.bl_data.slice(indexOfFirstItem, indexOfLastItem);
    }
    else {
      currentItems = props.container_data.slice(indexOfFirstItem, indexOfLastItem);
    }
  }, [props])

  const [shipmentUniqueId, setshipmentuniqueid] = useState("");

  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return {
        img: srcFlag[0]?.flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };

  function getValue(inputString) {
    if (inputString === "")
      return ""
    var words = inputString.slice(0, 10) + "...";
    return words;
  }

  const closeTrackingModalOpen = (serialType, serialNumber) => {
    setState({
      ...state,
      openCloseTrackingModal: true,
      modalOpened: "closeTracking",
      trackingToBeClosedType: serialType,
      trackingToBeClosedTypeValue: serialNumber,
    });
  };

  const editShipmentDetails = (shipment_unique_id) => {
    // console.log(shipment_unique_id);
    setState({
      ...state,
      openEditShipmentDetailsModal: true,
      modalOpened: "editTracking",
    });
    setshipmentuniqueid(shipment_unique_id);
  };

  const openDeleteTrackingModal = (tabType, trackingNum) => {
    setState({
      ...state,
      openDeleteModal: true,
      modalOpened: "deleteTracking",
      shipmentToBeDeleted: trackingNum,
      tabType: tabType,
    });
  };
  const closeAlert = () => {
    setShow(false);
    // window.location.reload();
    // window.location.href="/container-tracking"
  };
  const closeAlert2 = () => {
    setShow(false);
    window.location.href = "/container-tracking?tab=" + props.tabActive
  };

  const deleteTracking = () => {
    // console.log(state.shipmentToBeDeleted,"delete");
    let payload = {};
    if (state.tabType === "BL") {
      payload = {
        mbl_number: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
      };
    } else if (state.tabType == "UNTRACKABLE") {
      payload = {
        _id: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
      };
    }
    else {
      payload = {
        shipment_no: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
        user_type: localStorage.getItem('user_type'),
        email: localStorage.getItem('email')
      };
    }

    if (state.shipmentToBeDeleted && state.trackingDeletionReason) {
      redirectURL
        .post("/exim/deleteTracking", payload)
        .then((resp) => {
          if (resp.data.status === "success") {
            setShow2(true);
            setBasicType2("success");
            setBasicTitle2(resp.data.message);
            closeModal();
            props.trackingDataFun();
            setState({
              ...state,
              openDeleteModal: false,
              trackingDeletionReason: "",
              shipmentToBeDeleted: "",
            });
          }
          else {
            setShow(true);
            setBasicType("danger");
            setBasicTitle(resp.data.message);
          }
        })
        .catch((err) => {
          console.log("deleteTracking err", err);
        });
    } else {
    }
  };

  const refreshData = () => {
    props.trackingDataFun();
  };

  const forceCloseTracking = () => {
    // console.log(
    //   state.trackingToBeClosedTypeValue,
    //   state.forceCloseTrackingReason,
    //   state.trackingToBeClosedType
    // );

    let payload = {
      [state.trackingToBeClosedType]: state.trackingToBeClosedTypeValue, //shipment_no or mbl_number
      forceCloseTrackingReason: state.forceCloseTrackingReason,
      user_type: localStorage.getItem('user_type'),
      email: localStorage.getItem('email')
    };

    redirectURL
      .post("/exim/forceCloseTracking", payload)
      .then((resp) => {
        if (resp.data.status === "success") {
          closeModal();
          props.trackingDataFun();
        }
      })
      .catch((err) => {
        console.log("forceCloseTracking err", err);
      });
  };

  const handleCopyClick = () => {
    $("#clipboardMsg").removeClass("show-n").addClass("clipboard-msg");
    // let url = document.getElementById("trackingURL");
    const inputElement = document.getElementById('trackingURL');
    inputElement.select();

    const range = document.createRange();
    range.selectNode(inputElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    // url.select();
    try {
      if (document.execCommand) {
        document.execCommand('copy');
        // alert('Text copied to clipboard!');
      } else {
        // alert('Copying to clipboard not supported.');
      }
    } catch (error) {
      console.error('Failed to copy text to clipboard', error);
    }
    // navigator.clipboard.writeText(url);
    setInterval(() => {
      $("#clipboardMsg").addClass("show-n").removeClass("clipboard-msg");
    }, 2000);
  };

  const shareTrackingDetails = (sharingType, tracking_value, tracking_url) => {
    // "Container",url, container_no
    // tracking_type, tracking_value, tracking_url
    let live_url = `${shareURL}${tracking_url}`;
    setState({
      ...state,
      openShareModal: true,
      modalOpened: "shareTracking",
      sharingType,
      tracking_url: live_url,
      tracking_value,
    });
  };

  const closeModal = () => {
    if (state.modalOpened === "closeTracking") {
      setState({
        ...state,
        openCloseTrackingModal: false,
        trackingToBeClosedType: "",
        forceCloseTrackingReason: "",
        trackingToBeClosedTypeValue: "",
      });
    } else if (state.modalOpened === "editTracking") {
      setState({
        ...state,
        openEditShipmentDetailsModal: false,
      });
    } else if (state.modalOpened === "deleteTracking") {
      setState({
        ...state,
        openDeleteModal: false,
        trackingDeletionReason: "",
        shipmentToBeDeleted: "",
      });
    } else if (state.modalOpened === "shareTracking") {
      setState({
        ...state,
        openShareModal: false,
      });
    }
  };

  // const closeShareModal = () => {
  //     setState({
  //         ...state,
  //         openShareModal: false
  //     })
  // }

  const redirectToTrackingDetails = (shipment_no, mbl_number, each) => {
    if (props.tabActive === "BL/Booking") {
      navigate(`/tracking_details/BL/${mbl_number}`);
    } else {
      navigate(`/tracking_details/container/${shipment_no}`);
    }
  };

  const sendTrackingUrl = () => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (emailPattern.test(state.emailID)) {
      //   sharingType, tracking_value, tracking_url, mail

      let payload = {
        sharingType: state.sharingType,
        tracking_value: state.tracking_value,
        tracking_url: state.tracking_url,
        mail: state.emailID,
      };

      redirectURL
        .post("/exim/shareTrackingURL", payload)
        .then((resp) => {
          // console.log("shareTrackingURL data", resp.data);
          if (resp.data.status === "success") {
            setState({
              ...state,
              responseMessage: "Mail has been sent",
              responseMessageClass: "txt-info",
            });

            setTimeout(() => {
              setState({
                ...state,
                openShareModal: false,
                responseMessage: "",
                responseMessageClass: "",
                sharingType: "",
                tracking_url: "",
                tracking_value: "",
                emailID: "",
              });
            }, 2000);
          }
        })
        .catch((err) => {
          console.log("shareTrackingURL err", err);
        });

      //     sharingType,
      //   tracking_url,
      //   tracking_value,
    } else {
      setState({
        ...state,
        responseMessage: "Invalid Email ID",
        responseMessageClass: "txt-danger",
      });
      setTimeout(() => {
        setState({
          ...state,
          responseMessage: "",
          responseMessageClass: "",
        });
      }, 2000);
    }
  };

  return (
    <>
      <Modal open={state.openCloseTrackingModal} onClose={closeModal}
        center
        classNames={{
          overlayAnimationIn: 'customEnterOverlayAnimation',
          overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={400}
      >
        <div className="">
          <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
            Are you sure to want to close this tracking ?
          </h5>

          <div className="pt-3 ml-4">
            <div className="">
              <label>Reason</label>
            </div>
            <textarea
              name=""
              id=""
              cols="65"
              value={state.forceCloseTrackingReason}
              onChange={(e) => {
                setState({
                  ...state,
                  forceCloseTrackingReason: e.target.value,
                });
              }}
              rows="3"
              placeholder="Reason for Tracking Closing"
              style={{
                resize: "none",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingTop: "5px",
                width: "97%"
              }}
              required
            ></textarea>
          </div>

          <div className="button-box txt-center">
            <button className="btn btn-outline-dark mr-2" onClick={closeModal}>
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={forceCloseTracking}
              disabled={
                state.trackingToBeClosedTypeValue &&
                  state.forceCloseTrackingReason &&
                  state.trackingToBeClosedType
                  ? null
                  : "disabled"
              }
            >
              Complete
            </button>
          </div>
        </div>
      </Modal>

      <SweetAlert
        show={show}
        type={basicType}
        title={basicTitle}
        onConfirm={closeAlert}
      ></SweetAlert>

      <SweetAlert
        show={show2}
        type={basicType2}
        title={basicTitle2}
        onConfirm={closeAlert2}
      ></SweetAlert>
      <Modal
        open={state.openEditShipmentDetailsModal}
        onClose={closeModal}
        // center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "custom-modal-class-edit",
          //   overlayAnimationIn: 'customEnterOverlayAnimation',
          //   overlayAnimationOut: 'customLeaveOverlayAnimation',
          //   modalAnimationIn: 'customEnterModalAnimation',
          //   modalAnimationOut: 'customLeaveModalAnimation',
        }}
      //animationDuration={400}
      >
        <div className="">
          <h6
            className="modal-heading"
            style={{ position: "relative", width: "85vw", borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}
          >
            Edit Shipment Details
          </h6>
          <div className="mt-3">
            <ModalEditShipment shipment_unique_id={shipmentUniqueId} closeModal={closeModal} />
          </div>
        </div>
      </Modal>

      <Modal open={state.openDeleteModal} onClose={closeModal}
        center
        classNames={{
          overlayAnimationIn: 'customEnterOverlayAnimation',
          overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={400}
      >
        <div className="" style={{ height: "220px" }}>
          <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
            Are you sure to want to delete this tracking ?
          </h5>

          <div className="pt-2 ml-4">
            <div className="">
              <label>Reason</label>
            </div>
            <textarea
              name=""
              id=""
              cols="65"
              value={state.trackingDeletionReason}
              onChange={(e) => {
                setState({
                  ...state,
                  trackingDeletionReason: e.target.value,
                });
              }}
              rows="3"
              placeholder="Reason for Tracking Deletion"
              style={{
                resize: "none",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingTop: "5px",
                width: "97%"
              }}
              required
            ></textarea>
          </div>

          <div className="button-box txt-center">
            <button className="btn btn-outline-dark mr-2" onClick={closeModal}>
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={deleteTracking}
              disabled={
                state.shipmentToBeDeleted && state.trackingDeletionReason
                  ? null
                  : "disabled"
              }
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={state.openShareModal}
        onClose={closeModal}
        center
        classNames={{
          overlayAnimationIn: 'customEnterOverlayAnimation',
          overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={400}
        styles={customStyles}
      >
        <div className="" style={{ height: "0px" }}>
          <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Share tracking details</h5>
        </div>
        <div className="pl-3" style={{ marginTop: "63px" }}>
          <p>
            Tracking Url for {state.sharingType} No,{" "}
            <b>{state.tracking_value}</b> is{" "}
          </p>
          <span className="show-n" id="clipboardMsg">
            Link Copied to clipboard
          </span>
          <input
            ref={inputRef}
            type="text"
            id="trackingURL"
            placeholder="Text to be copied"
            value={state.tracking_url}
            style={{
              width: "95%",
              borderRadius: "10px",
              paddingLeft: "6px",
              userSelect: "none",
              cursor: "default",
              height: "39px",
              border: "1px solid #d9d9d9",
            }}
            readOnly
            disabled
          />

          <button
            onClick={handleCopyClick}
            style={{
              borderRadius: "0px 10px 10px 0px",
              border: "none",
              height: "37px",
              position: "relative",
              right: "29px",
              background: "none",
              outline: "none",
              top: "-2px",
            }}
          >
            <img
              src={copyIcon}
              alt=""
              width="16px"
              className="cursor-class"
              style={{ filter: "invert(1)" }}
            />
          </button>

          <p className={`${state.responseMessageClass} txt-center my-1`}>
            {state.responseMessage}
          </p>

          <div className="mt-3 mb-2">
            <input
              type="email"
              name=""
              id=""
              placeholder="Enter Email to send tracking Url"
              style={{
                borderRadius: "10px",
                height: "39px",
                width: "95%",
                paddingLeft: "10px",
                outline: "none",
              }}
              value={state.emailID}
              onChange={(e) => {
                setState({
                  ...state,
                  emailID: e.target.value,
                });
              }}
            />
            <span style={{ position: "absolute", right: "29px" }}>
              <button
                className="btn btn-primary"
                style={{
                  width: "93px",
                  height: "39px",
                  borderRadius: "0px 10px 10px 0px",
                  outline: "none",
                }}
                onClick={sendTrackingUrl}
              >
                Send
              </button>
            </span>
          </div>
        </div>
      </Modal>
      {
        (props?.tabActive === "BL/Booking" ?
          <div
            className={`${props.tabActive === "BL/Booking" ? "show-m" : "show-n"}`}
          >
            {/* {
           props.bl_new_shipments.map((eachNew, index)=>{
            // console.log("eachNew",eachNew)
            let {shipment_no} = eachNew;
            if(props.eventName === "all"){
              return <NewShipment shipment_no={shipment_no} eachNew={eachNew} tabView= "bl" eventName={ props.eventName }/>
            }
          })
        } */}

            {
              (!props.bl_data || props.bl_data.length === 0 || props.bl_data === undefined) ?
                <>

                  <div
                    className="card py-2 pr-3"
                    style={{
                      borderRadius: "18px",
                      textAlign: "center",
                      height: "50px",
                      marginTop: "40px",
                      marginBottom: "100px"
                      //   cursor: "pointer"
                    }}
                  >
                    No data found.
                  </div>
                </> :
                <>
                </>

            }

            {currentItems.map((each, index) => {
              // let {shipment_no, sub_status1, mbl_number } = each;
              var loadingport = []
              if (each.loading_port != "" && each.loading_port != undefined) {
                loadingport = each.loading_port;
              }
              each.loading_port = loadingport;
              let {
                _id,
                booking_no = "NA",
                container_no = "NA",
                discharge_port_code = "NA",
                last_event_data = "NA",
                last_port_ata = "NA",
                last_port_eta = "NA",
                loading_port_atd = "NA",
                loading_port_code = "NA",
                loading_port_etd = "NA",
                mbl_number = "NA",
                movement_type = "NA",
                shipment_no = "NA",
                shipping_line = "NA",
                tracking_updated_at = "NA",
                total_containers = "NA",
                url = "NA",
                delay_message = "NA",
                delay_status = "NA", // ["On Time", "Early", "Delay"]
                last_port = "NA",
                loading_port = "NA", // current_port(comes from last_event_location) === last_port, it does mean that it reached the port
                first_destination_event = "NA", //for arrival Dates
                last_origin_event = "NA", // for departure dates
              } = each;

              // console.log("first_destination_event bl", first_destination_event)
              // console.log("first_destination_event bl", last_origin_event)

              let image = 'NA';
              let imgStatus = true;
              try {
                let MyModule = require(`../../../public/static/images/Gocomet/${shipping_line}.png`)
              } catch (err) {
                imgStatus = false
              }

              let shipIcon;
              let info_msg = "Port to Port emission calculation based on GLEC framework using default factors"
              let progressLine;
              let textDecoration;
              let { event_group, status, location } = last_event_data[0];
              let s_etd = "NA";
              let a_etd = "NA";
              let p_eta = "NA";
              let a_eta = "NA";
              let delay_text = "";
              let delay_state = ""
              let updated_at = "NA";
              let dstnIcon = {};
              let srcIcon = {};
              let eventLabel = "NA"
              let imgPos = "0%";
              let barPos = "0%";
              let emissionIcon = EmissionCloud

              if (each.status !== "NEW" || each.status !== "PENDING" || each.status !== "UNTRACKABLE") {
                eventLabel = (each.hasOwnProperty("force_close")) ? each.force_close : "NA"

                if (eventLabel === 1) {
                  eventLabel = "Force Closed"
                }
                else if (each.status === "COMPLETED") {
                  eventLabel = each.all_events[each.all_events.length - 1].event_name
                }
                else {
                  eventLabel = last_event_data[0].event_name
                }
                if (delay_status === "On Time") {
                  shipIcon = TrackingShipBlue;
                  progressLine = "tracking-progress-blue-line";
                  textDecoration = "ontime-text";
                } else if (delay_status === "Early") {
                  shipIcon = TrackingShipGreen;
                  progressLine = "tracking-progress-green-line";
                  textDecoration = "early-text";
                } else {
                  //Delay
                  shipIcon = TrackingShipRed;
                  progressLine = "tracking-progress-red-line";
                  textDecoration = "delay-text";
                }


                let hours = moment().diff(
                  moment(tracking_updated_at).utcOffset("-05:30"),
                  "hours"
                );

                if (hours > 24) {
                  let dayTime = moment().diff(
                    moment(tracking_updated_at).utcOffset("-05:30"),
                    "days"
                  );
                  if (dayTime === 1) {
                    updated_at = `Updated ${dayTime} day ago`;
                  } else {
                    updated_at = `Updated ${dayTime} days ago`;
                  }
                } else {
                  if (hours === 1) {
                    updated_at = `Updated ${hours} hour ago`;
                  } else {
                    updated_at = `Updated ${hours} hours ago`;
                  }
                }
                if (each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE")
                  updated_at = ""





                if (status != "COMPLETED") {
                  if (event_group === "origin") {
                    barPos = "0%";
                    imgPos = "0%";
                  } else if (
                    event_group === "in_transit" ||
                    event_group === "transshipment"
                  ) {
                    barPos = "33%";
                    imgPos = "31%";
                  } else if (event_group === "destination") {
                    barPos = "66%";
                    imgPos = "62%";
                  } else {
                    barPos = "100%";
                    imgPos = "95%";
                  }
                }
                if (status === "COMPLETED" || last_port === location || each.status === "COMPLETED") {
                  barPos = "100%";
                  imgPos = "95%";
                }



                if (each !== undefined) {
                  if (each.loading_port_etd) {
                    s_etd = moment(each.loading_port_etd).utcOffset(0).format("Do MMM, YYYY")
                  }


                  if (each.loading_port_atd) {
                    a_etd = moment(each.loading_port_atd).utcOffset(0).format("Do MMM, YYYY")
                  }

                  if (each.last_port_eta) {
                    p_eta = moment(each.last_port_eta).utcOffset(0).format("Do MMM, YYYY")
                  }

                  if (each.last_port_ata) {
                    a_eta = moment(each.last_port_ata).utcOffset(0).format("Do MMM, YYYY")
                  }

                }


                try {
                  const delay = (moment(each.last_port_eta)).diff(moment(each.last_port_ata));
                  const duration = moment.duration(delay)
                  const days = duration.days();
                  const hours2 = duration.hours();
                  if (each.last_port_eta === "" || each.last_port_ata === "") {
                    delay_state = "NA"
                    delay_text = "NA"
                  }
                  if (days < 0) {
                    if (Math.abs(days) === 1) {
                      delay_text += "Delay by " + Math.abs(days) + " day"
                      delay_state = "Delay"
                    }
                    else {
                      delay_text += "Delay by " + Math.abs(days) + " days"
                      delay_state = "Delay"
                    }
                    if (hours2 !== 0)
                      delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                  }
                  else if (days > 0) {
                    if (Math.abs(days) === 1) {
                      delay_text += "Early by " + Math.abs(days) + " day"
                      delay_state = "Early"
                    }
                    else {
                      delay_text += "Early by " + Math.abs(days) + " days"
                      delay_state = "Early"
                    }
                    if (hours2 !== 0)
                      delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                  }
                  else {
                    if (days === 0) {
                      if (hours2 < 0) {
                        delay_text += "Delay by " + Math.abs(hours2) + " hour(s)"
                        delay_state = "Delay"
                      }
                      else if (hours2 > 0) {
                        delay_text += "Early by " + Math.abs(hours2) + " hour(s)"
                        delay_state = "Early"
                      }
                      else if (hours2 === 0)
                        delay_text += "On Time"
                    }
                  }
                } catch (err) {

                }
                if (each.hasOwnProperty("force_close") && each.force_close === 1) {
                  delay_text = "Force Closed"
                  delay_state = "Delay"
                }
                if (delay_state === "Early") {
                  shipIcon = TrackingShipGreen;
                  progressLine = "tracking-progress-green-line";
                  textDecoration = "early-text";
                } else if (delay_state === "Delay") {
                  //Delay
                  shipIcon = TrackingShipRed;
                  progressLine = "tracking-progress-red-line";
                  textDecoration = "delay-text";
                }
                else {
                  shipIcon = TrackingShipBlue;
                  progressLine = "tracking-progress-blue-line";
                  textDecoration = "ontime-text";
                }


                // if(last_origin_event.hasOwnProperty("actual_time") && last_origin_event.actual_time){
                //   a_etd = moment(last_origin_event.actual_time).format("Do MMM, YYYY");
                // }

                // if(last_origin_event.hasOwnProperty("estimated_time") && last_origin_event.estimated_time){
                //   s_etd = moment(last_origin_event.estimated_time).format("Do MMM, YYYY");
                // } else if(last_origin_event.hasOwnProperty("current_time") && last_origin_event.current_time){
                //   s_etd = moment(last_origin_event.current_time).format("Do MMM, YYYY");
                //}




                // if(first_destination_event.hasOwnProperty("actual_time") && first_destination_event.actual_time){
                //   a_eta = moment(first_destination_event.actual_time).format("Do MMM, YYYY");
                // }

                // if(first_destination_event.hasOwnProperty("estimated_time") && first_destination_event.estimated_time){
                //   p_eta = moment(first_destination_event.estimated_time).format("Do MMM, YYYY");
                // } else if(first_destination_event.hasOwnProperty("current_time") && first_destination_event.current_time){
                //   p_eta = moment(first_destination_event.current_time).format("Do MMM, YYYY");
                // }




                // if (loading_port_etd) {
                //   s_etd = moment(loading_port_etd).format("Do MMM, YYYY");
                // }
                // if (loading_port_atd) {
                //   a_etd = moment(loading_port_atd).format("Do MMM, YYYY");
                // }
                // if (last_port_eta) {
                //   p_eta = moment(last_port_eta).format("Do MMM, YYYY");
                // }
                // if (last_port_ata) {
                //   a_eta = moment(last_port_ata).format("Do MMM, YYYY");
                // }


                if (loading_port_code) {
                  srcIcon = getFlagIcon(loading_port_code.slice(0, 2));
                }


                if (discharge_port_code) {
                  dstnIcon = getFlagIcon(discharge_port_code.slice(0, 2));
                }
              }
              if (each.status === "NEW" || each.status === "PENDING") {
                eventLabel = "Newly Added"
                barPos = "0%";
                imgPos = "-1%";
              }
              else if (each.status === "UNTRACKABLE") {
                eventLabel = "Untrackable"
                barPos = "0%";
                imgPos = "-1%";
              }
              return (
                <div
                  key={each._id}
                  className="card py-2 pr-3 d-flex flex-row justify-content-between align-items-center"
                  style={{
                    borderRadius: "18px",
                    //   cursor: "pointer"
                  }}
                >
                  {/* {index} */}
                  <div className="d-flex justify-content-start align-items-start">
                    {movement_type === "Import" ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-center align-items-center p-2"
                          style={{ background: "#407bff2e" }}
                        >
                          <img
                            style={{
                              width: "10px",
                              marginBottom: "5px",
                            }}
                            src={ImportIcon}
                          />
                          <div
                            style={{
                              color: "#407bff",
                              fontSize: "10px",
                              fontWeight: 700,
                            }}
                          >
                            IM
                          </div>

                          {/* <div style={{ color: "white", fontSize: "10px", fontWeight: 700 }}>{props.trackingType}</div> */}
                        </div>
                      </>
                    ) : (movement_type === "Export" ? ((
                      <>
                        <div
                          className="d-flex flex-column justify-content-center align-items-center p-2"
                          style={{ background: "#407bff" }}
                        >
                          <img
                            style={{
                              width: "10px",
                              marginBottom: "5px",
                            }}
                            src={ExportIcon}
                          />
                          <div
                            style={{
                              color: "white",
                              fontSize: "10px",
                              fontWeight: 700,
                            }}
                          >
                            EX
                          </div>

                          {/* <div style={{ color: "white", fontSize: "10px", fontWeight: 700 }}>{props.trackingType}</div> */}
                        </div>
                      </>
                    )) : <></>)}
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: "7%" }}>
                    {(imgStatus) ?
                      <img
                        src={require(`../../../public/static/images/Gocomet/${shipping_line}.png`)}
                        alt={`${shipping_line}`}
                        style={{ width: "100%" }}
                      /> :
                      <CustomSVG text={shipping_line} height="5vw" width="7vw" />
                    }
                    <label title={container_no} style={{ fontSize: "0.69rem" }}>{total_containers} Container(s)</label>
                  </div>

                  <div className="d-flex flex-column justify-content-start eachCard mr-5">
                    <div className="d-flex flex-row justify-content-between">
                      <div style={{ color: "#858796", fontSize: "10px" }}>
                        BL No:
                      </div>
                      {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
                      <div
                        style={{
                          fontSize: "10px",
                          fontWeight: 600,
                          marginLeft: "8px",
                        }}
                      >
                        {mbl_number}
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <div style={{ color: "#858796", fontSize: "10px" }}>
                        Booking No:
                      </div>
                      {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
                      <div
                        style={{
                          fontSize: "10px",
                          fontWeight: 600,
                          marginLeft: "8px",
                        }}
                      >
                        {booking_no == "" ? "NA" : booking_no}
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      {/* <div style={{ color: "#858796", fontSize: "10px" }}>
                    Container No:
                  </div> */}
                      {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.containerNo}</div> */}
                      {/* <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      marginLeft: "8px",
                    }}
                  >
                    {container_no}
                  </div> */}
                    </div>
                  </div>

                  <Tooltip
                    id={`tracking_line_${shipment_no}`}
                  // style={{ top: "-27.875px" }}
                  />

                  <div
                    className="d-flex flex-row justify-content-center align-items-center cursor-class tilebl"
                    data-tooltip-id={`tracking_line_${shipment_no}`}
                    data-tooltip-content={(each.status === "UNTRACKABLE" || each.status === "PENDING" || each.status === "NEW") ? "" : "Click for Tracking details"}
                    onClick={() => {
                      if (each.status === "UNTRACKABLE" || each.status === "PENDING" || each.status === "NEW") { }
                      else
                        redirectToTrackingDetails(shipment_no, mbl_number, each);
                    }}
                  >
                    <div className="align-items-center ml-1 portpos">
                      <div className="row textAlignCenter">
                        <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                          <img className="portind" src={LocationBlue}></img>
                          <br />
                          {srcIcon.hasOwnProperty("img") &&
                            srcIcon.hasOwnProperty("name") ? (
                            <>
                              <img
                                src={srcIcon?.img}
                                alt="SRC"
                                className="flag_class"
                                style={{
                                  transform: `${srcIcon.name === "China" ? "rotateX(45deg)" : ""
                                    }`,
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}

                          {(((loading_port !== "" && loading_port !== undefined && loading_port !== null) ? loading_port.length : 0) + ((srcIcon.name != "" && srcIcon.name !== undefined && srcIcon.name !== null) ? srcIcon.name.length : 0) > 27) ? (
                            <>
                              <Tooltip id="info_name" />
                              <div className="col-12 portxt info_name"
                                data-tooltip-id="info_name"
                                data-tooltip-content={loading_port + ", " + (srcIcon.hasOwnProperty("name") ? srcIcon.name : "")}
                                style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                              >{(getValue(loading_port))}<br />{(getValue(srcIcon.hasOwnProperty("name") ? srcIcon.name : ""))}</div>
                            </>
                          ) : (
                            <>
                              <div className="col-12 portxt"
                                style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                              >{loading_port}<br />{srcIcon.hasOwnProperty("name") ? srcIcon.name : ""}</div>
                            </>
                          )}
                        </div>
                        {/* <div className="row">
                        <div className="col-12">
                        <img
                          src={srcIcon?.img}
                          alt="SRC"
                          className="flag_class portsimg"
                          style={{
                            transform: `${
                              srcIcon.name === "China" ? "rotateX(45deg)" : ""
                            }`,
                          }}
                        />
                        </div>
                        <div
                          className="portxt col-12"
                        >
                          <div className="portTxtpos">
                          {loading_port}, {srcIcon?.name}
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>

                    <div className="current-activity">
                      <span
                        className={`${textDecoration}`}
                        style={{
                          fontSize: "10px",
                          padding: "2px 5px",
                          position: "relative",
                          top: "-11px",
                          borderRadius: "3px",
                          fontWeight: "bolder",
                          left: "-53px",
                        }}
                      >
                        {eventLabel}
                      </span>
                    </div>
                    <div className="d-flex flex-row tracking-background-progress">
                      <div
                        // style={{ height: "2px", backgroundColor: "#407bff" }}
                        style={{ "--highlight": barPos }}
                        // className="tracking-progress-green-line"
                        className={progressLine}
                      ></div>
                      <div
                        className="tracking-image-background"
                        style={{ "--highlight2": imgPos }}
                      // style={{ background: "white", position: "absolute", left: "100px", bottom: "-5px", paddingLeft: "5px", paddingRight: "5px" }}
                      >
                        <img
                          className=""
                          // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                          // src={TrackingShipGreen}
                          src={shipIcon}
                          width="23px"
                        // ["On Time", "Early", "On Time"]
                        ></img>
                      </div>
                    </div>
                    {/* <div className="d-flex flex-row" style={{ position: "absolute", top: "20px", }}>
                      <img
                        className=""
                        // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                        // src={TrackingShipGreen}
                        src={emissionIcon}
                        width="40px" 
                      // ["On Time", "Early", "On Time"]
                      // ></img>
                      // <span style={{ marginLeft: "10px", lineHeight: "30px", fontSize: "12px" }}>
                      //   {(each.emissions.co2e.value) ? (parseFloat(each.emissions.co2e.value).toFixed(2) + " " + each.emissions.co2e.unit) : "NA"}
                      // </span>

                      // <Tooltip id="info_card_tracking" />
                      // <img src={InfoIcon} className="info_card_tracking" data-tooltip-id="info_card_tracking" alt="" width="15px" data-tooltip-content={info_msg} />
                      {/* <div class="info_card_tracking" data-tooltip-id="info_card_tracking" data-tooltip-content={info_msg}>
                      <img src={InfoIcon} alt="" width="15px" />
                    </div> */}

                    {/* </div> */}
                    <div className="d-flex flex-column justify-content-center align-items-center ml-1 portdes">
                      <div className="row textAlignCenter location-flag">
                        <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                          <img src={(each.status === "COMPLETED") ? FlagBlue : FlagGrey} className="desflg"></img>
                          <br />
                          {dstnIcon.hasOwnProperty("img") &&
                            dstnIcon.hasOwnProperty("name") ? (
                            <>
                              <img
                                src={dstnIcon?.img}
                                alt="DSTN"
                                className="flag_class"
                                style={{
                                  transform: `${dstnIcon.name === "China"
                                    ? "rotateX(45deg)"
                                    : ""
                                    }`,
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                          {/* <img src={props.destinationFlag}></img> */}
                          {/* <img
                          src={dstnIcon?.img}
                          alt="DSTN"
                          className="flag_class portsimg"
                          style={{
                            transform: `${
                              dstnIcon.name === "China" ? "rotateX(45deg)" : ""
                            }`,
                          }}
                        /> */}
                          {/* <div style={{ fontSize: "11px" }}>{props.destination}</div> */}
                          {(((last_port != "" && last_port != undefined) ? last_port.length : 0) + ((dstnIcon.name != "" && dstnIcon.name != undefined) ? dstnIcon.name.length : 0) > 27) ? (
                            <>
                              <Tooltip id="info_name" />
                              <div className="col-12 portxt info_name"
                                data-tooltip-id="info_name"
                                data-tooltip-content={last_port + ", " + (dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}
                                style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                              >{(getValue(last_port))}<br />{(getValue(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : ""))}</div>
                            </>
                          ) : (
                            <>
                              <div className="col-12 portxt"
                                style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                              >{last_port}<br />{(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}</div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column justify-content-start"
                    style={
                      {
                        // width: "171.575px",
                        // marginLeft:"96px"
                      }
                    }
                  >
                    <div className="d-flex flex-row justify-content-between mb-1" title="Estimated Time of Departure">
                      <div style={{ color: "#858796", fontSize: "10px" }}>
                        ETD:
                      </div>
                      {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.scheduledETD}</div> */}
                      <div
                        style={{
                          fontSize: "10px",
                          marginLeft: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {s_etd}
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1" title="Actual Time of Departure">
                      <div style={{ color: "#858796", fontSize: "10px" }}>
                        ATD:
                      </div>
                      {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETD}</div> */}
                      <div
                        style={{
                          fontSize: "10px",
                          marginLeft: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {a_etd}
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1" title="Estimated Time of Arrival">
                      <div style={{ color: "#858796", fontSize: "10px" }}>
                        ETA:
                      </div>
                      {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.plannedETA}</div> */}
                      <div
                        style={{
                          fontSize: "10px",
                          marginLeft: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {p_eta}
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between" title="Actual Time of Arrival">
                      <div style={{ color: "#858796", fontSize: "10px" }}>
                        ATA:
                      </div>
                      {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETA}</div> */}
                      <div
                        style={{
                          fontSize: "10px",
                          marginLeft: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {a_eta}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <div className="d-flex flex-row justify-content-between mb-3">
                      <div
                        className={
                          "cursor-class " +
                          `${(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE")
                            ? "trip-done"
                            : ""
                          }`
                        }
                        onClick={() => {
                          if (last_event_data[0].status !== "COMPLETED") {
                            if ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE") { }
                            else
                              closeTrackingModalOpen("mbl_number", mbl_number);
                          }
                        }}
                        data-tooltip-id={`close_${shipment_no}`}
                        data-tooltip-content="Mark as complete"
                      >
                        <img src={CompletedIcon}></img>
                      </div>
                      {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE") ? (
                        ""
                      ) : (
                        <Tooltip id={`close_${shipment_no}`} />
                      )}

                      <div
                        className={
                          "cursor-class " +
                          `${(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE")
                            ? "trip-done"
                            : ""
                          }`
                        }
                        data-tooltip-id={`edit_${shipment_no}`}
                        data-tooltip-content="Edit Shipment Details"
                        onClick={() => {
                          if (last_event_data[0].status !== "COMPLETED") {
                            if ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE") { }
                            else
                              editShipmentDetails(each.shipment_unique_id);
                          }
                        }}
                      >
                        <img src={EditIcon}></img>
                      </div>
                      {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE") ? (
                        ""
                      ) : (
                        <Tooltip id={`edit_${shipment_no}`} />
                      )}
                      <div
                        className={
                          "cursor-class " +
                          `${(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "" || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE") === 1)
                            ? "trip-done"
                            : ""
                          }`
                        }
                        onClick={() => {
                          if (last_event_data[0].status !== "COMPLETED") {
                            if ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW") { }
                            else if (each.status === "UNTRACKABLE")
                              openDeleteTrackingModal("UNTRACKABLE", each._id)
                            else
                              openDeleteTrackingModal("BL", mbl_number);
                          }
                        }}
                        data-tooltip-id={`delete_${shipment_no}`}
                        data-tooltip-content="Delete Tracking"
                      >
                        <img src={DeleteIcon}></img>
                      </div>
                      {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW") ? (
                        ""
                      ) : (
                        <Tooltip id={`delete_${shipment_no}`} />
                      )}
                      <div
                        className="cursor-class"
                        onClick={() => {
                          //tracking_details/BL/SZPDL7587900
                          let local_url = `/tracking_details/BL/${mbl_number}`;
                          shareTrackingDetails(
                            "Container",
                            container_no,
                            local_url
                          );
                        }}
                        data-tooltip-id={`share_${shipment_no}`}
                        data-tooltip-content="Sharing Tracking Details"
                      >
                        <img src={ShareIcon}></img>
                      </div>
                      <Tooltip id={`share_${shipment_no}`} />
                    </div>
                    <div style={{ width: "120px", textAlign: "center" }}>
                      <div
                        className={`${textDecoration} py-1 px-3`}
                        style={{
                          fontSize: "10px",
                          fontWeight: 600,
                          borderRadius: "14px",
                        }}
                      >
                        {delay_text}
                      </div>
                    </div>
                  </div>
                  {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE") ? (
                    <>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <label style={{ fontSize: "8px", fontWeight: "bolder", marginBottom: "0.1rem" }}>
                          {
                            ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1) ?
                              "Trip was Force Closed" :
                              ((each.status === "PENDING" || each.status === "NEW") ? "Newly Added" : (each.status === "UNTRACKABLE" ? "Untrackable" : ((each.last_event_data[0].event_group === "transshipment") ? "In Transshipment" : "Trip is Completed")))
                          }
                        </label>
                        <label style={{ fontSize: "8px", fontWeight: "bolder" }}>
                          {updated_at}
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        onClick={refreshData}
                      >
                        {/* <img
                          className={
                            "mb-3 cursor-class " +
                            `${(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE")
                              ? "trip-done"
                              : ""
                            }`
                          }
                          data-tooltip-id={`refresh_${shipment_no}`}
                          data-tooltip-content="Refresh Tracking"
                          src={RefreshIcon}
                        /> */}
                        {last_event_data[0].status === "COMPLETED" ? (
                          ""
                        ) : (
                          <Tooltip id={`refresh_${shipment_no}`} />
                        )}
                        <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                          {updated_at}
                        </div>
                        <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                          Next Update in 2 hours
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
            <Pagination className="custom-pagination">
              {Array.from({ length: Math.ceil(props.bl_data.length / ITEMS_PER_PAGE) }, (_, i) => (
                <Pagination.Item className="custom-paginationItem"
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => setCurrentPage(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination>

            {/* {
          props.bl_untrackable.map((each,index)=>{
            if(props.eventName==="untrackable" || props.eventName==="all"){
              return <UntrackableTiles eachNew={each} tabView= "bl" eventName={ props.eventName }/>
            }
          })
        } */}
          </div>
          :
          <div
            className={`${props.tabActive === "Container" ? "show-m" : "show-n"}`}
          >
            {/* {
          props.container_new_shipments.map((eachNew, index)=>{
            let {shipment_no} = eachNew;
            if(props.eventName === "all"){
              return <NewShipment shipment_no={shipment_no} eachNew={eachNew} tabView= "container" eventName={ props.eventName } />
            }
          })
        } */}
            {
              (!currentItems || currentItems.length === 0 || currentItems === undefined) ?
                <>
                  <div
                    className="card py-2 pr-3"
                    style={{
                      borderRadius: "18px",
                      textAlign: "center",
                      height: "50px",
                      marginTop: "40px",
                      marginBottom: "100px"
                    }}
                  >
                    No data found.
                  </div>
                </> :
                <>
                  {currentItems.map((each, index) => {
                    // let {shipment_no, sub_status1, mbl_number } = each;
                    let {
                      booking_no = "NA",
                      container_no = "NA",
                      discharge_port_code = "NA",
                      last_event_data = "NA",
                      last_port_ata = "NA",
                      last_port_eta = "NA",
                      loading_port_atd = "NA",
                      loading_port_code = "NA",
                      loading_port_etd = "NA",
                      mbl_number = "NA",
                      movement_type = "NA",
                      shipment_no = "NA",
                      shipping_line = "NA",
                      tracking_updated_at = "NA",
                      url = "NA",
                      delay_message = "NA",
                      delay_status = "NA", // ["On Time", "Early", "Delay"]
                      last_port = "NA",
                      loading_port = "NA",
                      first_destination_event = "NA",
                      last_origin_event = "NA"
                    } = each;

                    let image = 'NA';
                    let imgStatus = true;
                    try {
                      let MyModule = require(`../../../public/static/images/Gocomet/${shipping_line}.png`)
                    } catch (err) {
                      imgStatus = false
                    }

                    // console.log("first_destination_event cont",first_destination_event)
                    // console.log("last_origin_event cont",last_origin_event)
                    let editButtonDisable = null;
                    if (
                      each.hasOwnProperty("shipment_unique_id") &&
                      !each.shipment_unique_id
                    ) {
                      editButtonDisable = "disabled";
                    }

                    let shipIcon;
                    let info_msg = "Port to Port emission calculation based on GLEC framework using default factors"
                    let progressLine;
                    let textDecoration;
                    let updated_at = "";
                    let { event_group, status, location } = last_event_data[0];
                    let imgPos = "0%";
                    let barPos = "0%";
                    // from last_origin_event
                    let s_etd = "NA";
                    let a_etd = "NA";
                    // from first_destination_event
                    let p_eta = "NA";
                    let a_eta = "NA";
                    let delay_text = "";
                    let delay_state = ""
                    let srcIcon = {};
                    let dstnIcon = {};
                    let eventLabel = "NA"
                    let emissionIcon = EmissionCloud
                    if (each.status !== "PENDING" || each.status !== "NEW" || each.status !== "UNTRACKABLE") {
                      eventLabel = (each.hasOwnProperty("force_close")) ? each.force_close : "NA"
                      if (eventLabel === 1) {
                        eventLabel = "Force Closed"
                      }
                      else if (each.status === "COMPLETED") {
                        eventLabel = last_event_data[0].event_name || "NA"
                      }
                      else {
                        eventLabel = last_event_data[0].event_name || "NA"
                      }

                      if (delay_status === "On Time") {
                        shipIcon = TrackingShipBlue;
                        progressLine = "tracking-progress-blue-line";
                        textDecoration = "ontime-text";
                      } else if (delay_status === "Early") {
                        shipIcon = TrackingShipGreen;
                        progressLine = "tracking-progress-green-line";
                        textDecoration = "early-text";
                      } else {
                        //Delay
                        shipIcon = TrackingShipRed;
                        progressLine = "tracking-progress-red-line";
                        textDecoration = "delay-text";
                      }


                      let hours = moment().diff(tracking_updated_at, "hours");

                      if (hours > 24) {
                        let dayTime = moment().diff(tracking_updated_at, "days");
                        if (dayTime === 1) {
                          updated_at = `Updated ${dayTime} day ago`;
                        } else {
                          updated_at = `Updated ${dayTime} days ago`;
                        }
                      } else {
                        if (hours === 1) {
                          updated_at = `Updated ${hours} hour ago`;
                        } else {
                          updated_at = `Updated ${hours} hours ago`;
                        }
                      }
                      if (each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE")
                        updated_at = ""



                      if (status != "COMPLETED") {
                        if (event_group === "origin") {
                          barPos = "0%";
                          imgPos = "0%";
                        } else if (
                          event_group === "in_transit" ||
                          event_group === "transshipment"
                        ) {
                          barPos = "33%";
                          imgPos = "31%";
                        } else if (event_group === "destination") {
                          barPos = "66%";
                          imgPos = "62%";
                        } else {
                          barPos = "100%";
                          imgPos = "95%";
                        }
                      }
                      if (status === "COMPLETED" || last_port === location || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1) {
                        barPos = "100%";
                        imgPos = "95%";
                      }



                      // if(last_origin_event.hasOwnProperty("actual_time") && last_origin_event.actual_time){
                      //   a_etd = moment(last_origin_event.actual_time).format("Do MMM, YYYY");
                      // }

                      // if(last_origin_event.hasOwnProperty("estimated_time") && last_origin_event.estimated_time){
                      //   s_etd = moment(last_origin_event.estimated_time).format("Do MMM, YYYY");
                      // } else if(last_origin_event.hasOwnProperty("current_time") && last_origin_event.current_time){
                      //   s_etd = moment(last_origin_event.current_time).format("Do MMM, YYYY");
                      // }



                      // if(first_destination_event.hasOwnProperty("actual_time") && first_destination_event.actual_time){
                      //   a_eta = moment(first_destination_event.actual_time).format("Do MMM, YYYY");
                      // }

                      // if(first_destination_event.hasOwnProperty("estimated_time") && first_destination_event.estimated_time){
                      //   p_eta = moment(first_destination_event.estimated_time).format("Do MMM, YYYY");
                      // } else if(first_destination_event.hasOwnProperty("current_time") && first_destination_event.current_time){
                      //   p_eta = moment(first_destination_event.current_time).format("Do MMM, YYYY");
                      // }

                      // if (loading_port_etd) {
                      //   s_etd = moment(loading_port_etd).format("Do MMMM YYYY");
                      // }
                      // if (loading_port_atd) {
                      //   a_etd = moment(loading_port_atd).format("Do MMMM YYYY");
                      // }
                      // if (last_port_eta) {
                      //   p_eta = moment(last_port_eta).format("Do MMMM YYYY");
                      // }
                      // if (last_port_ata) {
                      //   a_eta = moment(last_port_ata).format("Do MMMM YYYY");
                      // }
                      //const aData=actualDuration.find(item=>item.container_no===container_no)
                      if (each !== undefined) {
                        if (each.loading_port_etd) {
                          s_etd = moment(each.loading_port_etd).utcOffset(0).format("Do MMM, YYYY")
                        }


                        if (each.loading_port_atd) {
                          a_etd = moment(each.loading_port_atd).utcOffset(0).format("Do MMM, YYYY")
                        }

                        if (each.last_port_eta) {
                          p_eta = moment(each.last_port_eta).utcOffset(0).format("Do MMM, YYYY")
                        }

                        if (each.last_port_ata) {
                          a_eta = moment(each.last_port_ata).utcOffset(0).format("Do MMM, YYYY")
                        }

                      }

                      try {
                        const delay = (moment(each.last_port_eta)).diff(moment(each.last_port_ata));
                        const duration = moment.duration(delay)
                        const days = duration.days();
                        const hours2 = duration.hours();
                        if (each.last_port_eta === "" || each.last_port_ata === "") {
                          delay_state = "NA"
                          delay_text = "NA"
                        }
                        if (days < 0) {
                          if (Math.abs(days) === 1) {
                            delay_text += "Delay by " + Math.abs(days) + " day"
                            delay_state = "Delay"
                          }
                          else {
                            delay_text += "Delay by " + Math.abs(days) + " days"
                            delay_state = "Delay"
                          }
                          if (hours2 !== 0)
                            delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                        }
                        else if (days > 0) {
                          if (Math.abs(days) === 1) {
                            delay_text += "Early by " + Math.abs(days) + " day"
                            delay_state = "Early"
                          }
                          else {
                            delay_text += "Early by " + Math.abs(days) + " days"
                            delay_state = "Early"
                          }
                          if (hours2 !== 0)
                            delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                        }
                        else {
                          if (days === 0) {
                            if (hours2 < 0) {
                              delay_text += "Delay by " + Math.abs(hours2) + " hour(s)"
                              delay_state = "Delay"
                            }
                            else if (hours2 > 0) {
                              delay_text += "Early by " + Math.abs(hours2) + " hour(s)"
                              delay_state = "Early"
                            }
                            else if (hours2 === 0)
                              delay_text += "On Time"
                          }
                        }
                      } catch (err) {

                      }
                      if (each.hasOwnProperty("force_close") && each.force_close === 1) {
                        delay_text = "Force Closed"
                        delay_state = "Delay"
                      }
                      if (delay_state === "Early") {
                        shipIcon = TrackingShipGreen;
                        progressLine = "tracking-progress-green-line";
                        textDecoration = "early-text";
                      } else if (delay_state === "Delay") {
                        //Delay
                        shipIcon = TrackingShipRed;
                        progressLine = "tracking-progress-red-line";
                        textDecoration = "delay-text";
                      }
                      else {
                        shipIcon = TrackingShipBlue;
                        progressLine = "tracking-progress-blue-line";
                        textDecoration = "ontime-text";
                      }


                      if (loading_port_code) {
                        srcIcon = getFlagIcon(loading_port_code.slice(0, 2));
                      }

                      if (discharge_port_code) {
                        dstnIcon = getFlagIcon(discharge_port_code.slice(0, 2));
                      }
                    }
                    if (each.status === "NEW" || each.status === "PENDING") {
                      eventLabel = "Newly Added"
                      barPos = "0%";
                      imgPos = "-1%";
                    }
                    else if (each.status === "UNTRACKABLE") {
                      eventLabel = "Untrackable"
                      barPos = "0%";
                      imgPos = "-1%";
                    }
                    return (
                      <div
                        key={each._id}
                        className="card py-2 pr-3 d-flex flex-row justify-content-between align-items-center"
                        style={{
                          borderRadius: "18px",
                          //   cursor: "pointer"
                        }}
                      >
                        <div className="d-flex justify-content-start align-items-start">
                          {movement_type === "Import" ? (
                            <>
                              <div
                                className="d-flex flex-column justify-content-center align-items-center p-2"
                                style={{ background: "#407bff2e" }}
                              >
                                <img
                                  style={{
                                    width: "10px",
                                    marginBottom: "5px",
                                  }}
                                  src={ImportIcon}
                                />
                                <div
                                  style={{
                                    color: "#407bff",
                                    fontSize: "10px",
                                    fontWeight: 700,
                                  }}
                                >
                                  IM
                                </div>
                              </div>
                            </>
                          ) : (movement_type === "Export" ? ((
                            <>
                              <div
                                className="d-flex flex-column justify-content-center align-items-center p-2"
                                style={{ background: "#407bff" }}
                              >
                                <img
                                  style={{
                                    width: "10px",
                                    marginBottom: "5px",
                                  }}
                                  src={ExportIcon}
                                />
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    fontWeight: 700,
                                  }}
                                >
                                  EX
                                </div>
                              </div>
                            </>
                          )) : <></>)}
                        </div>
                        <div className="" style={{ width: "7%" }}>
                          {(imgStatus) ?
                            <img
                              src={require(`../../../public/static/images/Gocomet/${shipping_line}.png`)}
                              alt={`${shipping_line}`}
                              style={{ width: "100%" }}
                            /> :
                            <CustomSVG text={shipping_line} height="5vw" width="7vw" />
                          }
                        </div>

                        <div className="d-flex flex-column justify-content-start eachCard mr-5">
                          <div className="d-flex flex-row justify-content-between">
                            <div style={{ color: "#858796", fontSize: "10px" }}>
                              BL No:
                            </div>
                            {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: 600,
                                marginLeft: "8px",
                              }}
                            >
                              {mbl_number == "" ? "NA" : mbl_number}
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-between">
                            <div style={{ color: "#858796", fontSize: "10px" }}>
                              Booking No:
                            </div>
                            {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: 600,
                                marginLeft: "8px",
                              }}
                            >
                              {booking_no == "" ? "NA" : booking_no}
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-between">
                            <div style={{ color: "#858796", fontSize: "10px" }}>
                              Container No:
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: 600,
                                marginLeft: "8px",
                              }}
                            >
                              {container_no == "" ? "NA" : container_no}
                            </div>
                          </div>
                        </div>

                        <Tooltip
                          id={`tracking_line_${shipment_no}`}
                        // style={{ top: "-27.875px" }}
                        />

                        <div
                          className="d-flex flex-row justify-content-center align-items-center cursor-class tilebl"
                          data-tooltip-id={`tracking_line_${shipment_no}`}
                          data-tooltip-content={(each.status === "UNTRACKABLE" || each.status === "PENDING" || each.status === "NEW") ? "" : "Click for Tracking details"}
                          onClick={() => {
                            if (each.status === "UNTRACKABLE" || each.status === "NEW" || each.status === "PENDING") { }
                            else
                              redirectToTrackingDetails(shipment_no, mbl_number, each);
                          }}
                        >
                          <div className="align-items-center ml-1 portpos">
                            <div className="row textAlignCenter">
                              <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                                <img className="portind" src={LocationBlue}></img>
                                <br />
                                {srcIcon.hasOwnProperty("img") &&
                                  srcIcon.hasOwnProperty("name") ? (
                                  <>
                                    <img
                                      src={srcIcon?.img}
                                      alt="SRC"
                                      className="flag_class"
                                      style={{
                                        transform: `${srcIcon.name === "China" ? "rotateX(45deg)" : ""
                                          }`,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {(((loading_port !== "" && loading_port !== undefined && loading_port !== null) ? loading_port.length : 0) + ((srcIcon.name != "" && srcIcon.name !== undefined && srcIcon.name !== null) ? srcIcon.name.length : 0) > 27) ? (
                                  <>
                                    <Tooltip id="info_name" />
                                    <div className="col-12 portxt info_name"
                                      data-tooltip-id="info_name"
                                      data-tooltip-content={loading_port + ", " + (srcIcon.hasOwnProperty("name") ? srcIcon.name : "")}
                                      style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                                    >{(getValue(loading_port))}<br />{(getValue(srcIcon.hasOwnProperty("name") ? srcIcon.name : ""))}</div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-12 portxt"
                                      style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                                    >{loading_port}<br />{srcIcon.hasOwnProperty("name") ? srcIcon.name : ""}</div>
                                  </>
                                )}
                              </div>
                              {/* <div className="row">
                        <div className="col-12">
                        <img
                          src={srcIcon?.img}
                          alt="SRC"
                          className="flag_class portsimg"
                          style={{
                            transform: `${
                              srcIcon.name === "China" ? "rotateX(45deg)" : ""
                            }`,
                          }}
                        />
                        </div>
                        <div
                          className="portxt col-12"
                        >
                          <div className="portTxtpos">
                          {loading_port}, {srcIcon?.name}
                          </div>
                        </div>
                      </div> */}
                            </div>
                          </div>

                          <div className="current-activity">
                            <span
                              className={`${textDecoration}`}
                              style={{
                                fontSize: "10px",
                                padding: "2px 5px",
                                position: "relative",
                                top: "-11px",
                                borderRadius: "3px",
                                fontWeight: "bolder",
                                left: "-53px",
                              }}
                            >
                              {eventLabel}
                            </span>
                          </div>
                          <div className="d-flex flex-row tracking-background-progress">
                            <div
                              // style={{ height: "2px", backgroundColor: "#407bff" }}
                              style={{ "--highlight": barPos }}
                              // className="tracking-progress-green-line"
                              className={progressLine}
                            ></div>

                            <div
                              className="tracking-image-background"
                              style={{ "--highlight2": imgPos }}
                            // style={{ background: "white", position: "absolute", left: "100px", bottom: "-5px", paddingLeft: "5px", paddingRight: "5px" }}
                            >
                              <img
                                className=""
                                // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                                // src={TrackingShipGreen}
                                src={shipIcon}
                                width="23px"
                              // ["On Time", "Early", "On Time"]
                              ></img>
                            </div>
                          </div>
                          {/* <div className="d-flex flex-row" style={{ position: "absolute", top: "20px", left: "95px" }}>
                            <img
                              className="" 
                               style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                               src={TrackingShipGreen}
                               src={emissionIcon}
                               width="40px"
                             ["On Time", "Early", "On Time"]
                             ></img>
                             <span style={{ marginLeft: "10px", lineHeight: "30px", fontSize: "12px" }}>
                               {each.emissions.co2e.value ? (parseFloat(each.emissions.co2e.value).toFixed(2) + " " + each.emissions.co2e.unit) : "NA"}
                             </span>

                             <Tooltip id="info_card_tracking" />
                             <img src={InfoIcon} className="info_card_tracking" data-tooltip-id="info_card_tracking" alt="" width="15px" data-tooltip-content={info_msg} />
                             <div class="info_card_tracking" data-tooltip-id="info_card_tracking" data-tooltip-content={info_msg}>
                      <img src={InfoIcon} alt="" width="15px" />
                    </div> 

                          </div>*/}
                          <div className="d-flex flex-column justify-content-center align-items-center ml-1 portdes">
                            <div className="row textAlignCenter location-flag">
                              <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                                <img src={(each.status === "COMPLETED") ? FlagBlue : FlagGrey} className="desflg"></img>
                                <br />

                                {dstnIcon.hasOwnProperty("img") &&
                                  dstnIcon.hasOwnProperty("name") ? (
                                  <>
                                    <img
                                      src={dstnIcon?.img}
                                      alt="DSTN"
                                      className="flag_class"
                                      style={{
                                        transform: `${dstnIcon.name === "China"
                                          ? "rotateX(45deg)"
                                          : ""
                                          }`,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                                {/* <img src={props.destinationFlag}></img> */}
                                {/* <img
                          src={dstnIcon?.img}
                          alt="DSTN"
                          className="flag_class portsimg"
                          style={{
                            transform: `${
                              dstnIcon.name === "China" ? "rotateX(45deg)" : ""
                            }`,
                          }}
                        /> */}
                                {/* <div style={{ fontSize: "11px" }}>{props.destination}</div> */}
                                {(((last_port != "" && last_port != undefined) ? last_port.length : 0) + ((dstnIcon.name != "" && dstnIcon.name != undefined) ? dstnIcon.name.length : 0) > 27) ? (
                                  <>
                                    <Tooltip id="info_name" />
                                    <div className="col-12 portxt info_name"
                                      data-tooltip-id="info_name"
                                      data-tooltip-content={last_port + ", " + (dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}
                                      style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                                    >{(getValue(last_port))}<br />{(getValue(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : ""))}</div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-12 portxt"
                                      style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                                    >{last_port}<br />{(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}</div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="d-flex flex-column justify-content-start"
                          style={
                            {
                              // width: "171.575px",
                              // marginLeft:"96px"
                            }
                          }
                        >
                          <div className="d-flex flex-row justify-content-between mb-1" title="Estimated Time of Departure">
                            <div style={{ color: "#858796", fontSize: "10px" }}>
                              ETD:
                            </div>
                            {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.scheduledETD}</div> */}
                            <div
                              style={{
                                fontSize: "10px",
                                marginLeft: "10px",
                                fontWeight: 700,
                              }}
                            >
                              {s_etd}
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-between mb-1" title="Actual Time of Departure">
                            <div style={{ color: "#858796", fontSize: "10px" }}>
                              ATD:
                            </div>
                            {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETD}</div> */}
                            <div
                              style={{
                                fontSize: "10px",
                                marginLeft: "10px",
                                fontWeight: 700,
                              }}
                            >
                              {a_etd}
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-between mb-1" title="Estimated Time of Arrival">
                            <div style={{ color: "#858796", fontSize: "10px" }}>
                              ETA:
                            </div>
                            {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.plannedETA}</div> */}
                            <div
                              style={{
                                fontSize: "10px",
                                marginLeft: "10px",
                                fontWeight: 700,
                              }}
                            >
                              {p_eta}
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-between" title="Actual Time of Arrival">
                            <div style={{ color: "#858796", fontSize: "10px" }}>
                              ATA:
                            </div>
                            {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETA}</div> */}
                            <div
                              style={{
                                fontSize: "10px",
                                marginLeft: "10px",
                                fontWeight: 700,
                              }}
                            >
                              {a_eta}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div className="d-flex flex-row justify-content-between mb-3">
                            <div
                              className={
                                "cursor-class " +
                                `${(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE")
                                  ? "trip-done"
                                  : ""
                                }`
                              }
                              onClick={() => {
                                if (last_event_data[0].status !== "COMPLETED") {
                                  if ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE") { }
                                  else
                                    closeTrackingModalOpen("shipment_no", shipment_no);
                                }
                              }}
                              data-tooltip-id={`close_${shipment_no}`}
                              data-tooltip-content="Mark as complete"
                            >
                              <img src={CompletedIcon}></img>
                            </div>
                            {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE") ? (
                              ""
                            ) : (
                              <Tooltip id={`close_${shipment_no}`} />
                            )}

                            <div
                              className={
                                "cursor-class " +
                                `${(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE")
                                  ? "trip-done"
                                  : ""
                                }`
                              }
                              data-tooltip-id={`edit_${shipment_no}`}
                              data-tooltip-content="Edit Shipment Details"
                              onClick={() => {
                                if (last_event_data[0].status !== "COMPLETED") {
                                  if ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE") { }
                                  else
                                    editShipmentDetails(each.shipment_unique_id);
                                }
                              }}
                            >
                              <img src={EditIcon}></img>
                            </div>
                            {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE") ? (
                              ""
                            ) : (
                              <Tooltip id={`edit_${shipment_no}`} />
                            )}
                            <div
                              className={
                                "cursor-class " +
                                `${(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW")
                                  ? "trip-done"
                                  : ""
                                }`
                              }
                              onClick={() => {
                                if (last_event_data[0].status !== "COMPLETED") {
                                  if ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW") { }
                                  else if (each.status === "UNTRACKABLE")
                                    openDeleteTrackingModal("UNTRACKABLE", each._id)
                                  else
                                    openDeleteTrackingModal("Container", each.shipment_no);
                                }
                              }}
                              data-tooltip-id={`delete_${shipment_no}`}
                              data-tooltip-content="Delete Tracking"
                            >
                              <img src={DeleteIcon}></img>
                            </div>
                            {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW") ? (
                              ""
                            ) : (
                              <Tooltip id={`delete_${shipment_no}`} />
                            )}
                            <div
                              className="cursor-class"
                              onClick={() => {
                                //tracking_details/BL/SZPDL7587900
                                let local_url = `/tracking_details/BL/${mbl_number}`;
                                shareTrackingDetails(
                                  "Container",
                                  container_no,
                                  local_url
                                );
                              }}
                              data-tooltip-id={`share_${shipment_no}`}
                              data-tooltip-content="Sharing Tracking Details"
                            >
                              <img src={ShareIcon}></img>
                            </div>
                            <Tooltip id={`share_${shipment_no}`} />
                          </div>
                          <div style={{ width: "120px", textAlign: "center" }}>
                            <div
                              className={`${textDecoration} py-1 px-3`}
                              style={{
                                fontSize: "10px",
                                fontWeight: 600,
                                borderRadius: "14px",
                              }}
                            >
                              {delay_text}
                            </div>
                          </div>
                        </div>
                        {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE") ? (
                          <>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <label style={{ fontSize: "8px", fontWeight: "bolder", marginBottom: "0.1rem" }}>
                                {
                                  ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1) ?
                                    "Trip was Force Closed" :
                                    ((each.status === "PENDING" || each.status === "NEW") ? "Newly Added" : (each.status === "UNTRACKABLE" ? "Untrackable" : ((each.last_event_data[0].event_group === "transshipment") ? "In Transshipment" : "Trip is Completed")))
                                }
                              </label>
                              <label style={{ fontSize: "8px", fontWeight: "bolder" }}>
                                {updated_at}
                              </label>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="d-flex flex-column justify-content-center align-items-center"
                              onClick={refreshData}
                            >
                              {/* <img
                                className={
                                  "mb-3 cursor-class " +
                                  `${(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE")
                                    ? "trip-done"
                                    : ""
                                  }`
                                }
                                data-tooltip-id={`refresh_${shipment_no}`}
                                data-tooltip-content="Refresh Tracking"
                                src={RefreshIcon}
                              /> */}
                              {last_event_data[0].status === "COMPLETED" ? (
                                ""
                              ) : (
                                <Tooltip id={`refresh_${shipment_no}`} />
                              )}
                              <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                                {updated_at}
                              </div>
                              <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                                Next Update in 2 hours
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
            }


            <Pagination className="custom-pagination">
              {Array.from({ length: Math.ceil(props.container_data.length / ITEMS_PER_PAGE) }, (_, i) => (
                <Pagination.Item className="custom-paginationItem"
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => setCurrentPage(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination>
            {/* {
          props.container_untrackable.map((each,index)=>{
            if(props.eventName==="untrackable" || props.eventName==="all"){
              return <UntrackableTiles eachNew={each} tabView= "container" eventName={ props.eventName }/>
            }
          })
        } */}
          </div>
        )}
      {/* Container Tile */}
      {/* Container Tile */}
    </>
  );
};

export default TrackingTile;


const CustomSVG = ({ text, height, width }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" rx="15" ry="15" fill="#f0f0f0" />
    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1vw" fill="#333">
      {text}
    </text>
  </svg>
);