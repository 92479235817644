import React from 'react';

const ResendConsentRenderer = (props) => {
    const handleClick = () => {
        // Implement the functionality for resending consent
    };

    const buttonHtml = `
        <button 
            style="padding:2% 10% 5% 10%" 
            class="btn btn-info f12"
            title="Resend Consent"
            onclick="(${handleClick.toString()})()"
        >
            Resend
        </button>
    `;

    return (
        
        <div dangerouslySetInnerHTML={{ __html: buttonHtml }} />
    );
};

export default ResendConsentRenderer;

