import React, {Component} from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import icn from "../../img/undraw_profile.svg";
import $, { param } from 'jquery';
import 'jquery-ui';
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import withRouter from './withRouter';
import CloseDocuments from './ClosedFreightDocuments';
import PendingDocuments from './BillingPendingApprovalsDocs';
import FreightLogs from "./BillingFreightLogs";


class FreightApprovalSubmit extends Component {
    constructor(props){
        super(props);
        // console.log(props)
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
			},
            frameworkComponents:{
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            conf:"",            
            showUploadCSVForm:"show-n",
            slider:"",
            overlay:"show-n",
            csvcontent:"",
            month:{value:"",label:"Select"},
            showdata:"show-m",
            todayDieselPrice:0,
            avgDieselPrice:0,
            ctab:"btn-default",
            tltab:"btn-default",
            ptab:"btn-info",
            dtab:"show-m",
            tolltab:"show-n",
            dieselbasis:"",
            showAfterSubmit:"show-m",
            diesel_price_changed:"",
            diesel_price_changed_date:"",
            capture_diesel_price_from_day:"",
            capture_diesel_price_to_day:"",
            changed_diesel_price:"",
            changed_diesel_price_reason:"",
            changed_diesel_price_upload:"",
            approval_freight:"",
            freightapproval:[],
            hideapprovalbtn:"show-m",
            showApprovalStatus:"show-n",
            showApprovalStatusText:"",
            showLevelApprovalStatusText:"",
            aprvColor:"#000000",
            approval_levls:[],
            showUserApproveBtn:"show-n",
            hideUserApproveTbl:"show-n",
            showApproveDocumentReason:"",
            showRejectDocumentReason:"",
            approve_remarks:"",
            reject_remarks:"",
            closedDocs:[],
            otherCosts:[],
            freightlogs:[],
            approval_levelemails:0,
            isUserCreateDoc:0,
            isSendForApprv:0,
            showptab:"show-n",
            useraccess:[],
            showlevelprocess:"show-m",
            istabclick:0,
            dotbg:"#cccccc",
            pendingCnt:0,
            closedCnt:0,
            dieselTooltip:"show-n"
        }
        this.loadFreightRates = this.loadFreightRates.bind(this);
        this.getDieselPrices = this.getDieselPrices.bind(this);
        this.getAvgDieselPrices = this.getAvgDieselPrices.bind(this);
        this.loadDieselPrices = this.loadDieselPrices.bind(this);
        this.onSubmitFormData = this.onSubmitFormData.bind(this);
        this.getDieselApprovalPrice = this.getDieselApprovalPrice.bind(this);
        this.onClickSendForApproavl = this.onClickSendForApproavl.bind(this);
        this.selectedHandleMonth = this.selectedHandleMonth.bind(this);
        this.getMonthStartAndENDDates = this.getMonthStartAndENDDates.bind(this);
        this.loadApprovalLevels = this.loadApprovalLevels.bind(this);
        this.loadApprovedDocuments = this.loadApprovedDocuments.bind(this);
        this.loadUserAccess = this.loadUserAccess.bind(this);
        this.loadUserLevelDocumentApproval = this.loadUserLevelDocumentApproval.bind(this);
        this.onLoadTabCount = this.onLoadTabCount.bind(this);
        this.onLoadTabClosedCount = this.onLoadTabClosedCount.bind(this);
    }
	componentDidMount = async () => {
        
        this.loadUserAccess();
        var currentURL = window.location.pathname;
        // console.log("currentURL ", currentURL)
        if(currentURL.includes("view-document-approval") === true)
        {
            if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
            {
                this.setState({
                    showUserApproveBtn:"show-m",
                    hideUserApproveTbl:"show-m"
                })
            }
        }
        // console.log("currentURL ", currentURL)
       
        redirectURL.post("/billing/freightothercosts")
		.then(async (response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                otherCosts:records
            })
        });
        await setTimeout(() => {
            redirectURL.post("/billing/configurations")
            .then((response) => {
                // console.log("response ", response.data)
                var records = response.data.records;
                if(records.length > 0)
                {
                    this.setState({
                        conf:records[0]
                    })
                   
                    if(records[0].diesel_price_capture_val === "1")
                    {
                        var diesel_percent = records[0].diesel_price_x;
                    }
                    
                    if(records[0].diesel_price_capture_val === "2")
                    {
                        var diesel_percent = "";
                    }
                    this.loadDieselPrices({diesel_percent:diesel_percent})
                    var params = {
                        document_no:this.props.params.document_no
                    }
                    this.getDieselApprovalPrice(params);
                    this.loadUserLevelDocumentApproval(params);
                }
                else{
                    redirectToConfigurations()
                }
            })
            
        },500)
        
        this.loadApprovedDocuments();
	}
    loadUserAccess(){        
        var currentURL = window.location.pathname;
        var params={
            email:localStorage.getItem("email")
        }
        redirectURL.post("/billing/usermoduleaccess",params)
        .then((response) => {
            var records = response.data.records;
            if(records.length > 0)
            {
                if(records.length > 0)
                {
                    if(records[0].document_creation !== "" && records[0].document_creation !== undefined)
                    {
                        if(records[0].document_creation === "Yes")
                        {
                            if(currentURL.includes("view-closed-document") === true)
                            {
                                if(this.state.month.value !== "")
                                {
                                    var shown = "show-m";
                                }
                                else{
                                    var shown = "show-n";
                                }
                                this.setState({
                                    ctab:"btn-default",
                                    tltab:"btn-success",
                                    dtab:"show-m",
                                    tolltab:"show-n",
                                    showAfterSubmit:"show-m",
                                    showdata:shown,
                                    ptab:"btn-default",
                                    showptab:"show-n",
                                    showlevelprocess:"show-m",
                                    istabclick:1,
                                    isUserCreateDoc:1,
                                })
                            
                            }
                            else if(currentURL.includes("view-document-approval") === true || currentURL.includes("view-document") === true || currentURL.includes("documents") === true)
                            {
                                if(this.state.month.value !== "")
                                {
                                    var shown = "show-m";
                                }
                                else{
                                    var shown = "show-n";
                                }
                                this.setState({
                                    ctab:"btn-default",
                                    tltab:"btn-default",
                                    dtab:"show-m",
                                    tolltab:"show-n",
                                    showAfterSubmit:"show-m",
                                    showdata:shown,
                                    ptab:"btn-info",
                                    showptab:"show-n",
                                    showlevelprocess:"show-m",
                                    istabclick:1,
                                    isUserCreateDoc:1,
                                })
                            
                            }
                            else{
                                this.setState({
                                    isUserCreateDoc:1,
                                    ctab:"btn-info",
                                    tltab:"btn-default",
                                    ptab:"btn-default",
                                })
                            }
                        }
                        else{
                            if(currentURL.includes("view-closed-document") === true)
                            {
                                if(this.state.month.value !== "")
                                {
                                    var shown = "show-m";
                                }
                                else{
                                    var shown = "show-n";
                                }
                                this.setState({
                                    ctab:"btn-default",
                                    tltab:"btn-info",
                                    dtab:"show-m",
                                    tolltab:"show-n",
                                    showAfterSubmit:"show-m",
                                    showdata:shown,
                                    ptab:"btn-default",
                                    showptab:"show-n",
                                    showlevelprocess:"show-m",
                                    istabclick:1,
                                    isUserCreateDoc:0,
                                })
                            
                            }
                            else if(currentURL.includes("view-document-approval") === true || currentURL.includes("view-document") === true || currentURL.includes("documents") === true)
                            {
                                if(this.state.month.value !== "")
                                {
                                    var shown = "show-m";
                                }
                                else{
                                    var shown = "show-n";
                                }
                                this.setState({
                                    ctab:"btn-default",
                                    tltab:"btn-default",
                                    dtab:"show-m",
                                    tolltab:"show-n",
                                    showAfterSubmit:"show-m",
                                    showdata:shown,
                                    ptab:"btn-info",
                                    showptab:"show-n",
                                    showlevelprocess:"show-m",
                                    istabclick:1,
                                    isUserCreateDoc:0,
                                })
                            
                            }
                            else{
                                this.setState({
                                    isUserCreateDoc:0,
                                    ctab:"btn-default",
                                    tltab:"btn-default",
                                    ptab:"btn-info",
                                })
                            }
                        }
                    }
                    else{
                        if(currentURL.includes("view-closed-document") === true)
                        {
                            if(this.state.month.value !== "")
                            {
                                var shown = "show-m";
                            }
                            else{
                                var shown = "show-n";
                            }
                            this.setState({
                                ctab:"btn-default",
                                tltab:"btn-info",
                                dtab:"show-m",
                                tolltab:"show-n",
                                showAfterSubmit:"show-m",
                                showdata:shown,
                                ptab:"btn-default",
                                showptab:"show-n",
                                showlevelprocess:"show-m",
                                istabclick:1
                            })
                        
                        }
                        else{
                            this.setState({
                                isUserCreateDoc:0,
                                ctab:"btn-default",
                                tltab:"btn-default",
                                ptab:"btn-info",
                            })
                        }
                    }
                }
                else{
                    if(currentURL.includes("view-closed-document") === true)
                    {
                        if(this.state.month.value !== "")
                        {
                            var shown = "show-m";
                        }
                        else{
                            var shown = "show-n";
                        }
                        this.setState({
                            ctab:"btn-default",
                            tltab:"btn-info",
                            dtab:"show-m",
                            tolltab:"show-n",
                            showAfterSubmit:"show-m",
                            showdata:shown,
                            ptab:"btn-default",
                            showptab:"show-n",
                            showlevelprocess:"show-m",
                            istabclick:1
                        })
                    
                    }
                    else{
                        this.setState({
                            isUserCreateDoc:0,
                            ctab:"btn-default",
                            tltab:"btn-default",
                            ptab:"btn-info",
                        })
                    }
                }
            }
            this.setState({
                useraccess:records
            })
            
        })
    }
    loadUserLevelDocumentApproval(params){
        params.email = localStorage.getItem("email");
        // console.log("params ", )
        redirectURL.post("/billing/levelwiseapprovals",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                var recordss = []
                records.map((itm) => {
                    if(itm.approved_on != "" && itm.approved_on != undefined)
                    {
                        itm.approved_on = moment.parseZone(itm.approved_on).format("DD-MM-YYYY HH:mm:ss");
                    }
                    if(itm.level === "1" || itm.level === 1)
                    {
                        if(itm.approval_status === 0)
                        {
                            itm.level_comment = "Pending to send for approval";
                        }
                        
                        if(itm.approval_status === 1)
                        {
                            itm.level_comment = "Send for approval";
                        }
                    }else{
                        if(itm.approval_status_txt === "Pending")
                        {
                            itm.level_comment = "Pending for approval";
                        }
                        
                        if(itm.approval_status_txt === "Send Back")
                        {
                            itm.level_comment = "Send Back";
                        }
                        
                        if(itm.approval_status === "Approved")
                        {
                            itm.level_comment = "Approved";
                        }
                        if(itm.approval_status === "Rejected")
                        {
                            itm.level_comment = "Rejected";
                        }
                    }
                    recordss.push(itm);
                })
                
                if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
                {
                    if(recordss[0].approval_status_txt === "Pending")
                    {
                        var dstat = "Pending for Approval"
                    }
                    else{
                        var dstat = recordss[0].approval_status_txt;
                    }
                    this.setState({
                        showLevelApprovalStatusText:dstat,
                        aprvColor:"#000000"
                    })
                }
            }
            else{
                this.setState({
                    showLevelApprovalStatusText:"",
                    aprvColor:"#000000"
                })
            }
		})
    }
    loadDieselPrices(params){
        redirectURL.post("/billing/dieselprices",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    diesel_price_changed:records[0].diesel_price,
                    diesel_price_changed_date:(records[0].diesel_price_date != "" && records[0].diesel_price_date != undefined)?moment.parseZone(records[0].diesel_price_date).format("DD-MM-YYYY"):""
                })
            }
		})
    }
   
    loadApprovedDocuments(){
        redirectURL.post("/billing/docapprovals")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    closedDocs:records
                })
            }
		})
    }
    loadFreightRates(){

        redirectURL.post("/billing/freightrates")
		.then((response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            var recordsarr = [];
            var formulacode = this.state.conf.diesel_price_formula;
            var diesel_impact_percent = (this.state.conf.diesel_impact_percent != "")?this.state.conf.diesel_impact_percent:0;
            
            if(records.length > 0)
            {
                records.map((item) => {
                    
                    // (Distance * (latest Diesel Price - Base Diesel Price)) / Mileage
                    // "Diesel impact as a percentage of Base Freight Rate * Base Freight Rate * ((Latest Diesel Price - Base Diesel Price) / Base Diesel Price)"
                    // console.log("item ", item)
                    var distance = item.total_distance;
                    var lastest_diesel_price = this.state.avgDieselPrice;
                    // console.log("this.state.avgDieselPrice ", this.state.avgDieselPrice)
                    var base_diesel_price = item.base_diesel_rate_per_approval_ltr;
                    var mileage = item.mileage;
                    var base_freight_rate = item.base_freight_rate_per_approval;
                    var diesel_price_consider_for_revision = this.state.approval_freight;
                    var changeDieselPrice = 0;
                    // console.log("formulacode ", formulacode)
                    if(formulacode === "1")
                    {   
                        // console.log('(parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)) ', (parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)))
                        changeDieselPrice = ((parseFloat(distance) * (parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)))/parseFloat(mileage)).toFixed(2);
                        // console.log("changeDieselPrice ", changeDieselPrice)
                        var diesel_impact = 0;
                    }
                    
                    if(formulacode === "2")
                    {   
                        changeDieselPrice = (((parseFloat(diesel_impact_percent)*parseFloat(base_freight_rate))/100) * base_freight_rate * ((lastest_diesel_price) - parseFloat(base_diesel_price)/parseFloat(base_diesel_price))).toFixed(2);
                        var diesel_impact = ((parseFloat(diesel_price_consider_for_revision)-parseFloat(base_diesel_price))/parseFloat(base_diesel_price)).toFixed(2);
                    }
                    item.change_in_diesel_cost = changeDieselPrice;
                    
                    item.diesel_price_impact =  diesel_impact*100;
                    if(this.state.diesel_price_changed_date !== "" && this.state.diesel_price_changed_date !== undefined)
                    {
                        item.revised_diesel_price_applicable_date = (this.state.diesel_price_changed_date != "")?this.state.diesel_price_changed_date:""
                    }
                    else{
                        item.revised_diesel_price_applicable_date = (this.state.capture_diesel_price_from_day != "")?this.state.capture_diesel_price_from_day:"";
                    }
                    
                    if(this.state.showApprovalStatusText === "Approved")
                    {
                        if(this.state.changed_diesel_price != "")
                        {
                            item.diesel_price_consider_for_revision = this.state.changed_diesel_price;
                        }
                        else{
                            item.diesel_price_consider_for_revision = this.state.approval_freight;
                        }
                    }
                    else{
                        item.diesel_price_consider_for_revision = this.state.approval_freight;
                    }
                    
                    recordsarr.push(item); 
                })
            }
            this.setState({
                rowData:recordsarr,
                showAfterSubmit:"show-m"
            })
            this.loadApprovalLevels(this.props.params.document_no)
		})
    }
    loadApprovalLevels(docno){
        var params = {
            document_no:docno
        }
        if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
        {
            // params.email  = localStorage.getItem("email");
        }
        redirectURL.post("/billing/levelwiseapprovals",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                var recordss = []
                records.map((itm) => {
                    if(itm.approved_on != "" && itm.approved_on != undefined)
                    {
                        itm.approved_on = moment.parseZone(itm.approved_on).format("DD-MM-YYYY HH:mm:ss");
                    }
                    if(itm.level === "1" || itm.level === 1)
                    {
                        if(itm.approval_status === 0)
                        {
                            itm.level_comment = "Pending to send for approval";
                            itm.dot_color = "#ff0000";
                        }
                        
                        if(itm.approval_status === 1)
                        {
                            itm.level_comment = "Send for approval";
                            itm.dot_color = "#1cc88a";
                        }
                    }else{
                        // console.log("itm.approval_status_txt , ", itm.approval_status_txt)
                        if(itm.approval_status_txt === "Pending")
                        {
                            itm.level_comment = "Pending for approval";
                            itm.dot_color = "#ff0000";
                        }
                        
                        else if(itm.approval_status_txt === "Send Back")
                        {
                            itm.level_comment = "Send Back";
                            itm.dot_color = "#cccccc";
                        }
                        
                        else if(itm.approval_status_txt === "Approved")
                        {
                            itm.level_comment = "Approved";
                            itm.dot_color = "#1cc88a";
                        }
                        else if(itm.approval_status_txt === "Rejected")
                        {
                            itm.level_comment = "Rejected";
                            itm.dot_color = "#ff0000";
                        }
                        else{
                            itm.level_comment = "";
                            itm.dot_color = "#ccc";
                        }
                    }
                    // console.log("itm ", itm)
                    recordss.push(itm);
                })
                // console.log("recordss ", recordss)
                
                recordss = recordss.sort(GetSortAscOrder("level"));
                this.setState({
                    approval_levls:recordss
                });

                if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
                {
                    if(recordss[0].approval_status_txt === "Pending")
                    {
                        var dstat = "Pending for Approval"
                    }
                    else{
                        var dstat = recordss[0].approval_status_txt;
                    }
                    // this.setState({
                    //     showLevelApprovalStatusText:dstat,
                    //     aprvColor:"#000000"
                    // })
                }
            }
		})
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onClickShowCSVForm(){
        this.setState({
            showUploadCSVForm:"show-m",
            slider:"slide30",
            overlay:"show-m"
        })
    }
    hideSlideBlock(){
        this.setState({
            showUploadCSVForm:"show-n",
            showApproveDocumentReason:"",
            showRejectDocumentReason:"",
            slider:"",
            overlay:"show-n"
        })
    }
    monthOptions(){
        var items = [{value:"",label:"Select"}];
        var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        var cmonth = moment.parseZone().format("MM");
        var cyear = moment.parseZone().format("YYYY");
        // console.log("cmonth ", parseInt(cmonth))
        for(var i=0;i<months.length;i++)
        {
            if(i<=(parseInt(cmonth)-1))
            {
                // console.log("Month ", months[i])
                if((i+1) < 10){
                    var dd = "0"+(i+1);
                }
                else{
                    var dd = i;
                }
                items.push({
                    value:cyear+"-"+dd,
                    label:months[i]+"-"+cyear
                })
            }
            
        }
        return items;
    }
    selectedHandleMonth = async (month) => {
        this.setState({
            month:month
        })
            // console.log("optin ", this.state.month)
            if(month.value != "")
            {
                var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        
                this.setState({
                    overlay:"show-m"
                })
                
                var mn =month.value;
                if(mn != "")
                {
                    var ml = mn.split("-");
                    var conf = this.state.conf;
                    // console.log("conf ", conf);
                    if(conf.diesel_price_capture_val === "2")
                    {
                        var fd_avg = conf.capture_diesel_price_from_avg;
                        var fb = conf.capture_diesel_price_from_day;
                        if(fd_avg != "")
                        {
                            var frd = fd_avg.split("-");
                            var fmon = moment.parseZone().subtract(frd[1],"months").format("YYYY-MM");
                            var capture_diesel_price_from_day = moment.parseZone(new Date(fmon+"-"+fb+" 00:00:00")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_from_day ", capture_diesel_price_from_day)
                        }
                        else{
                            var capture_diesel_price_from_day = "";
                        }
                        var td_avg = conf.capture_diesel_price_to_avg;
                        var td = conf.capture_diesel_price_to_day;
                        
                        if(td_avg != "")
                        {
                            var trd = td_avg.split("-");
                            var tmon = moment.parseZone().subtract(trd[1],"months").format("YYYY-MM");
                            // console.log("tmon+td ", tmon+"-"+td)
                            var capture_diesel_price_to_day = moment.parseZone(new Date(tmon+"-"+td+" 23:59:59")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_to_day ", capture_diesel_price_to_day)
                        }
                        else{
                            var capture_diesel_price_to_day = "";
                        }
                        // console.log("tmon ",tmon)
                        // console.log("fmon ",fmon)
                        this.setState({
                            capture_diesel_price_from_day:capture_diesel_price_from_day,
                            capture_diesel_price_to_day:capture_diesel_price_to_day,
                            diesel_price_changed_date:""
                        })
                    }
                }
                var dates = await this.getMonthStartAndENDDates(month);
                // console.log("dates ",dates)
                var params = {
                    from_date: dates.start.replace(/-/g,''),
                    to_date: dates.end.replace(/-/g,''),
                    start_date: dates.start,
                    end_date: dates.end
                }
                var avgDieselPrice = await this.getDieselPrices(params);
                await setTimeout(()=>{
                    
                // console.log("avgDieselPrice ", avgDieselPrice)
                    this.setState({
                        showdata:"show-m",
                        overlay:"show-n"
                        // avgDieselPrice:avgDieselPrice
                    })
                },2000)
                
            }
            else{
                this.setState({
                    showdata:"show-n",
                    overlay:"show-n"
                })
            }
    
    }
    handleMonth(month){
        this.setState({month},async ()=>{
            // console.log("optin ", this.state.month)
            if(this.state.month.value != "")
            {
                var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        
                this.setState({
                    overlay:"show-m"
                })
                
                var mn =this.state.month.value;
                if(mn != "")
                {
                    var ml = mn.split("-");
                    var conf = this.state.conf;
                    // console.log("conf ", conf);
                    if(conf.diesel_price_capture_val === "2")
                    {
                        var fd_avg = conf.capture_diesel_price_from_avg;
                        var fb = conf.capture_diesel_price_from_day;
                        if(fd_avg != "")
                        {
                            var frd = fd_avg.split("-");
                            var fmon = moment.parseZone().subtract(frd[1],"months").format("YYYY-MM");
                            var capture_diesel_price_from_day = moment.parseZone(new Date(fmon+"-"+fb+" 00:00:00")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_from_day ", capture_diesel_price_from_day)
                        }
                        else{
                            var capture_diesel_price_from_day = "";
                        }
                        var td_avg = conf.capture_diesel_price_to_avg;
                        var td = conf.capture_diesel_price_to_day;
                        
                        if(td_avg != "")
                        {
                            var trd = td_avg.split("-");
                            var tmon = moment.parseZone().subtract(trd[1],"months").format("YYYY-MM");
                            // console.log("tmon+td ", tmon+"-"+td)
                            var capture_diesel_price_to_day = moment.parseZone(new Date(tmon+"-"+td+" 23:59:59")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_to_day ", capture_diesel_price_to_day)
                        }
                        else{
                            var capture_diesel_price_to_day = "";
                        }
                        // console.log("tmon ",tmon)
                        // console.log("fmon ",fmon)
                        this.setState({
                            capture_diesel_price_from_day:capture_diesel_price_from_day,
                            capture_diesel_price_to_day:capture_diesel_price_to_day,
                            diesel_price_changed_date:""
                        })
                    }
                }
                var dates = await this.getStartAndENDDates();
                // console.log("dates ",dates)
                var params = {
                    from_date: dates.start.replace(/-/g,''),
                    to_date: dates.end.replace(/-/g,'')
                }
                var avgDieselPrice = await this.getDieselPrices(params);
                await setTimeout(()=>{
                    
                // console.log("avgDieselPrice ", avgDieselPrice)
                    this.setState({
                        showdata:"show-m",
                        overlay:"show-n"
                        // avgDieselPrice:avgDieselPrice
                    })
                },2000)
                
            }
            else{
                this.setState({
                    showdata:"show-n",
                    overlay:"show-n"
                })
            }
        })
    }
    getDieselPrices(params){
        redirectURL.post("/billing/getDieselPrices",params)
		.then((response) => {
			// console.log("response ", response.data)
            var prices = response.data.prices;
            var avgprice = this.getAvgDieselPrices(prices);
            // console.log("avgprice ", prices)

            var conf = this.state.conf;
            var dPrice = 0;
            var dieselbasis = "";
            if(conf.diesel_price_capture_val != "" && conf.diesel_price_capture_val != undefined)
            {
                if(conf.diesel_price_capture_val == "1")
                {
                    var diesel_price_x = parseFloat(conf.diesel_price_x);
                    // console.log("diesel_price_x ", diesel_price_x)
                    // console.log("avgprice ", avgprice)
                    dPrice = parseFloat(avgprice)+(parseFloat(avgprice)*(parseFloat(diesel_price_x)/100));
                    // console.log("dPrice ", dPrice);
                    dieselbasis = "Diesel price changed by "+diesel_price_x+" %";
                    this.setState({
                        // avgDieselPrice:dPrice.toFixed(2),
                        dieselbasis:dieselbasis
                    })
                }
                if(conf.diesel_price_capture_val == "2")
                {
                    // console.log("avgprice ", avgprice)
                    dieselbasis = " Daily diesel price averages ";
                    dPrice = avgprice;
                    this.setState({
                        // avgDieselPrice:dPrice,
                        dieselbasis:dieselbasis
                    })
                }

            }
            else{
                dPrice = 0;
                this.setState({
                    // avgDieselPrice:dPrice,
                    dieselbasis:""
                })
            }
            
            this.loadFreightRates();
            
		})
    }
    getAvgDieselPrices(prices){
        try{
            var allprices = []
            if(prices.length > 0)
            {
                prices.map((item) => {
                    if(item.diesel_price != "" && item.diesel_price != undefined)
                    {
                        allprices.push(parseFloat(item.diesel_price));
                    }
                    
                })
            }
            // console.log("allprices ", allprices.length)
            var avg =0;
            if(allprices.length > 0)
            {
                var sum = 0;
                for (let i = 0; i < allprices.length; i++) {
                    sum += allprices[i];
                }
                avg = (sum/allprices.length).toFixed(2);
            }
            return avg;
        }
        catch(e){}
        
    }
    getStartAndENDDates(){
        var freq = this.state.conf.diesel_frequence_option;
        var freqFrom = "";
        var freqTo = "";
        var month = this.state.month.value;
        // console.log("month ", month)
        // if(this.state.conf.diesel_frequence_option == "FortNightly")
        // {
        //     var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //     var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
        //     var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //     var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //     freqFrom = frm; 
        //     freqTo = trm; 
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Weekly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days.sort();

        //         var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Monthly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days[0];
                
        //         var fm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }

        try{
            if(this.state.freightapproval[0] !== undefined && this.state.freightapproval[0] !== "")
            {
                // console.log("this.state.freightapproval[0] ", this.state.freightapproval[0])
                var freq = this.state.freightapproval[0].diesel_frequence_option;
                var freqFrom = "";
                var freqTo = "";
                
                freqFrom = this.state.freightapproval[0].diesel_frequence_from_period; 
                freqTo = this.state.freightapproval[0].diesel_frequence_to_period; 
                // console.log("{start:freqFrom,end:freqTo} ",{start:freqFrom,end:freqTo})
                return {start:freqFrom,end:freqTo};
            }
        }
        catch(e){

        }
        
    }
    
    getMonthStartAndENDDates(monthOpt){
        var freq = this.state.conf.diesel_frequence_option;
        var freqFrom = "";
        var freqTo = "";
        var month = monthOpt.value;
        // console.log("this.state.freightapproval[0] ", this.state.freightapproval[0])
        // console.log("month ", month)
        // if(this.state.conf.diesel_frequence_option == "FortNightly")
        // {
        //     var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //     var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
        //     var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //     var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //     freqFrom = frm; 
        //     freqTo = trm; 
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Weekly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days.sort();

        //         var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Monthly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days[0];
                
        //         var fm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }
        // return {start:freqFrom,end:freqTo};


        try{
            if(this.state.freightapproval[0] !== undefined && this.state.freightapproval[0] !== "")
            {
                // console.log("this.state.freightapproval[0] ", this.state.freightapproval[0])
                var freq = this.state.freightapproval[0].diesel_frequence_option;
                var freqFrom = "";
                var freqTo = "";
                
                freqFrom = this.state.freightapproval[0].diesel_frequence_from_period; 
                freqTo = this.state.freightapproval[0].diesel_frequence_to_period; 
                // console.log("{start:freqFrom,end:freqTo} ",{start:freqFrom,end:freqTo})
                return {start:freqFrom,end:freqTo};
            }
        }
        catch(e){

        }
        

    }
    showTabView(oVal){
        if(oVal === 1)
        {
            if(this.state.month.value !== "")
            {
                var shown = "show-m";
            }
            else{
                var shown = "show-n";
            }
            this.setState({
                ctab:"btn-info",
                tltab:"btn-default",
                dtab:"show-m",
                tolltab:"show-n",
                showAfterSubmit:"show-m",
                showdata:shown,
                ptab:"btn-default",
                showptab:"show-n",
                showlevelprocess:"show-m",
                istabclick:1
            })
        }
        if(oVal === 2)
        {
            this.setState({
                ctab:"btn-default",
                tltab:"btn-info",
                dtab:"show-n",
                tolltab:"show-m",
                showAfterSubmit:"show-m",
                showdata:"show-n",
                ptab:"btn-default",
                showptab:"show-n",
                showlevelprocess:"show-n",
                istabclick:1
            })
        }
        
        if(oVal === 3)
        {
            if(this.state.isUserCreateDoc === 1)
            {
                var showlevelprocess = "show-n"
            }
            else {
                if(this.state.istabclick === 1)
                {
                    var showlevelprocess = "show-n"
                }
                else{
                    var showlevelprocess = "show-m"
                }
                
            }
            
            
            this.setState({
                ctab:"btn-default",
                tltab:"btn-default",
                dtab:"show-n",
                tolltab:"show-n",
                showAfterSubmit:"show-m",
                showdata:"show-n",
                ptab:"btn-info",
                showptab:"show-m",
                showlevelprocess:showlevelprocess,
                istabclick:1
            })
        }
    }
    changeHandler(event){
        // console.log("event ", event)
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    onSubmitFormData(event){
        event.preventDefault();
        var month = this.state.month.value;
        var diesel_frequence_option = this.state.conf.diesel_frequence_option;
        var avgDieselPrice = this.state.avgDieselPrice;
        var dieselbasis = this.state.dieselbasis;
        var diesel_price_changed_date = (this.state.diesel_price_changed_date != "")?this.state.diesel_price_changed_date:"";
        var capture_diesel_price_from_day = (this.state.capture_diesel_price_from_day != "")?this.state.capture_diesel_price_from_day:"";
        var capture_diesel_price_to_day = this.state.capture_diesel_price_to_day;
        var changed_diesel_price = this.state.changed_diesel_price;
        var changed_diesel_price_reason = this.state.changed_diesel_price_reason;
        var changed_diesel_price_upload = this.state.changed_diesel_price_upload;
        var params = {
            selected_month:month,
            diesel_frequence_option:diesel_frequence_option,
            avgDieselPrice:avgDieselPrice,
            dieselbasis:dieselbasis,
            diesel_price_changed_date:diesel_price_changed_date,
            capture_diesel_price_from_day:capture_diesel_price_from_day,
            capture_diesel_price_to_day:capture_diesel_price_to_day,
            changed_diesel_price:changed_diesel_price,
            changed_diesel_price_reason:changed_diesel_price_reason,
            changed_diesel_price_upload:changed_diesel_price_upload
        }
        // console.log("params",params)
        redirectURL.post("/billing/savedieselapprovalform",params)
		.then((response) => {
			//console.log("response ", response.data)
            // var records = response.data.records;
            // console.log("records ", records)
            if(response.data.status === "success")
            {
                var rownode = response.data.records[0];
                this.getDieselApprovalPrice(rownode);
            }
		});
    }
    getDieselApprovalPrice(params){
        redirectURL.post("/billing/dieselapprovaldata",params)
		.then(async (response) => {
			//console.log("response ", response.data)
                var records = response.data.records;
                // console.log("records ", records)
                if(records.length > 0)
                {
                    var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        
                    if(records[0].selected_month != "")
                    {
                        var s = records[0].selected_month.split("-");
                        var monthlbl = months[s[1]-1]+"-"+s[0];
                    }
                    else{
                        var monthlbl = "";
                    }
                    this.setState({
                        month:{value:records[0].selected_month,label:monthlbl}
                    })
                    
                    this.setState({
                        overlay:"show-m"
                    })
                    
                    if(records[0].approval_status > 0)
                    {
                        if(records[0].approval_status === 1)
                        {
                               var showApprovalStatus="show-m";
                                var showApprovalStatusText="Pending for approvals";
                                var aprvColor='#7e022a';
                            
                        }
                        else if(records[0].approval_status === 2)
                        {
                            var showApprovalStatus="show-m";
                            var showApprovalStatusText="Approved";
                            var aprvColor='#016e48';
                            // this.setState({
                            //     showApprovalStatus:"show-m",
                            //     showApprovalStatusText:"Approved",
                            //     aprvColor:'#016e48'
                            // })
                        }
                        else if(records[0].approval_status === 3)
                        {
                            var showApprovalStatus="show-m";
                            var showApprovalStatusText="Rejected";
                            var aprvColor='#ff0000';

                            // this.setState({
                            //     showApprovalStatus:"show-m",
                            //     showApprovalStatusText:"Rejected",
                            //     aprvColor:'#ff0000'
                            // })
                        }
                        else{
                            var showApprovalStatus="show-m";
                            var showApprovalStatusText="Document Created";
                            var aprvColor='#000';
                        }
                        
                        if(records[0].approval_sendback === 1)
                        {
                            var hideapprovalbtn  = "show-m";
                        }
                        else{
                            var hideapprovalbtn = "show-n"
                        }
                        
                        this.setState({
                            hideapprovalbtn:hideapprovalbtn,
                            showApprovalStatus:showApprovalStatus,
                            showApprovalStatusText:showApprovalStatusText,
                            aprvColor:aprvColor
                        })
                    }
                    else{
                        this.setState({
                            hideapprovalbtn:"show-m",
                            showApprovalStatus:"show-m",
                            showApprovalStatusText:"Document Created",
                            aprvColor:"#000"
                        })
                    }
                    this.setState({
                        avgDieselPrice:records[0].applicable_diesel_price
                        
                    })
                }
                else{
                    this.setState({
                        showdata:"show-n",
                        overlay:"show-n"
                    })
                }
                var approval_levelemails =0;
                if(records[0].approval_levels_email !== "" && records[0].approval_levels_email !== undefined)
                {
                    records[0].approval_levels_email.map((item) => {
                        if(item.email === localStorage.getItem("email") && item.level === 1)
                        {
                            approval_levelemails = 1
                        }     
                    })
                    
                }
                this.setState({
                    approval_freight:(records[0].changed_diesel_price !== "" && records[0].changed_diesel_price !== undefined)?records[0].changed_diesel_price:records[0].applicable_diesel_price,                    
                    changed_diesel_price:records[0].changed_diesel_price,
                    changed_diesel_price_reason:records[0].changed_diesel_price_reason,
                    changed_diesel_price_upload:records[0].changed_diesel_price_upload,
                    freightapproval:records,
                    approval_levelemails:approval_levelemails
                })
                setTimeout(() => {
                    this.selectedHandleMonth({value:records[0].selected_month,label:monthlbl})
                },500)
                
		});
    }
    onClickSendForApproavl(){
        // console.log("freightapproval ", this.state.freightapproval);
        var params = {
            document_no:this.state.freightapproval[0].document_no,
            email:localStorage.getItem("email")
        }
        redirectURL.post("/billing/sendForApproval",params)
		.then((response) => {
			//console.log("response ", response.data)
            // var records = response.data.records;
            // console.log("records ", records)
            if(response.data.status === "success")
            {
                this.setState({
                    // approval_freight:records[0].applicable_diesel_price,
                    hideapprovalbtn:"show-n"
                })
                window.location.reload();
            }
		});
    }
    onClickSendBackDocument(){
        var document_no = this.props.params.document_no;
        var email = localStorage.getItem("email");
        var params ={
            email:email,
            document_no:document_no
        }
        // console.log("SendBack ", params);
        redirectURL.post("/billing/sendBackDocument",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
        });

    }
    onClickApproveDocument(){
        this.setState({
            showApproveDocumentReason:"slide30",
            overlay:"show-m"
        })
        
    }
    onClickRejectDocument(){
        
        this.setState({
            showRejectDocumentReason:"slide30",
            overlay:"show-m"
        })
       
    }
    formApproveDocumentItem(event){
        event.preventDefault();
        var approve_remarks = this.state.approve_remarks;

        var document_no = this.props.params.document_no;
        var email = localStorage.getItem("email");
        var params ={
            email:email,
            document_no:document_no,
            approve_remarks:approve_remarks
        }
        // console.log("Approve ", params);
        redirectURL.post("/billing/approveDocument",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
        });
    }
    
    formRejectDocumentItem(event){
        event.preventDefault();
        var reject_remarks = this.state.reject_remarks;

        var document_no = this.props.params.document_no;
        var email = localStorage.getItem("email");
        var params ={
            email:email,
            document_no:document_no,
            reject_remarks:reject_remarks
        }
        // console.log("Approve ", params);
        redirectURL.post("/billing/rejectDocument",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
        });
    }
    onCellEditingStopped = (_params) => {
        // console.log("_params ",_params)
        if (_params.newValue != _params.oldValue) {
            if(this.state.hideapprovalbtn == 'show-m')
            {
                redirectURL.post("/billing/updateTollPrice",_params.data)
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        // this.gridApi.setRowData(_params);
                    }
                });
            }
        }
      }
    onLoadTabCount(count){
        this.setState({
            pendingCnt:count
        })
    }
    onLoadTabClosedCount(count){
        this.setState({
            closedCnt:count
        })
    }
    showToolTip(){
        this.setState({
            dieselTooltip:"show-m"
        });
    }
    onClickHideTooltip(){
        this.setState({
            dieselTooltip:"show-n"
        });
    }
    render(){
        var currentURL = window.location.pathname;
        // console.log("currentURL ", currentURL)
        if(currentURL.includes("view-document-approval") === true)
        {
            if(this.state.isUserCreateDoc === 1)
            {
                var iseditable = true;
            }
            else{
                if(this.state.hideapprovalbtn == 'show-m')
                {
                    var iseditable = true;
                }
                else{
                    var iseditable = false;
                }
                
            }
            var showpagetabs="show-m";
            var showpagelogs="show-m";
        }
        else{
            var iseditable = false;
            var showpagetabs="show-m";
            var showpagelogs="show-m";
        }
        var otherCosts = this.state.otherCosts;
		var columnDefs = [
            {
                headerName:Constants.FREIGHT_RATE_ROUTE,
                field:"route",
                width:120,
                // cellRendererSelector:function(params){
                //     return {
                //         component:"ViewPOD"
                //     }
                // },
            },
            {
                headerName:Constants.FREIGHT_RATE_SOURCE,
                field:"source",
                width:120,
            },
            {
                headerName:Constants.FREIGHT_RATE_DESTINATION,
                field:"destination",
                width:160,
            },
            {
                headerName:Constants.FREIGHT_RATE_LSP,
                field:"transporter_name",
                width:140
            },
            {
                headerName:Constants.FREIGHT_RATE_RATE_CLASSIFICATION,
                field:"rate_classification",
                width:140
            },
            {
                headerName:Constants.FREIGHT_RATE_CONTRACT,
                field:"contract_approval_no",
                width:100,
            },
            
            {
                headerName:Constants.FREIGHT_RATE_CONTRACT_VALID_FROM,
                field:"contract_valid_from",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.contract_valid_from != "" && params.data.contract_valid_from != undefined)
                        {
                            return moment.parseZone(params.data.contract_valid_from).format('DD-MM-YYYY HH:mm'); 
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_CONTRACT_VALID_TO,
                field:"contract_valid_to",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.contract_valid_to != "" && params.data.contract_valid_to != undefined)
                        {
                            return moment.parseZone(params.data.contract_valid_to).format('DD-MM-YYYY HH:mm'); 
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_TRUCK_TYPE,
                field:"truck_type",
                width:100,
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_DISTANCE,
            //     field:"total_distance",
            //     width:120,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_MILEAGE,
            //     field:"mileage",
            //     width:120,
            // },
            {
                headerName:Constants.FREIGHT_RATE_BASE_FREIGHT_RATE_PER_APPROVAL,
                field:"base_freight_rate_per_approval",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_BASE_DIESEL_RATE_PER_LTR,
                field:"base_diesel_rate_per_approval_ltr",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_TOLL_RATE_PER_APPROVAL,
                field:"base_toll_rate_per_approval",
                width:180,
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_BASE_DIESEL_CITY,
            //     field:"base_diesel_city",
            //     width:180,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_BASE_DIESEL_STATE,
            //     field:"base_diesel_state",
            //     width:180,
            // },
            
            // {
            //     headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_APPLICABLE_DATE,
            //     field:"revised_diesel_price_applicable_date",
            //     width:180,
            // },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_CONSIDERED_FOR_REVISION,
                field:"diesel_price_consider_for_revision",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_IMPACT_PERCENT,
                field:"diesel_price_impact",
                width:180,
                valueGetter:function(params){
                    try {
                        var revisedprice = 0;
                        var basepric = 0;
                        if(params.data.diesel_price_consider_for_revision !== "" && params.data.diesel_price_consider_for_revision !== undefined)
                        {
                            revisedprice = params.data.diesel_price_consider_for_revision;
                        }
                        if(params.data.base_diesel_rate_per_approval_ltr !== "" && params.data.base_diesel_rate_per_approval_ltr !== undefined)
                        {
                            basepric = params.data.base_diesel_rate_per_approval_ltr;
                        }
                        var c = (((parseFloat(revisedprice)-parseFloat(basepric))/parseFloat(basepric))*100).toFixed(2);
                        return c;
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_CHANGE_IN_DIESEL_COST,
                field:"change_in_diesel_cost",
                width:180,
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_TOLL_REVISION_APPLICABLE_DATE,
            //     field:"toll_revision_applicable_date",
            //     width:180,
            // },
            {
                headerName:Constants.FREIGHT_RATE_NEW_TOLL_PRICE,
                field:"new_toll_price",
                width:180,
                editable:iseditable
            },
            {
                headerName:Constants.FREIGHT_RATE_CHANGE_IN_TOLL_PRICE,
                field:"change_in_toll_price",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.new_toll_price != "" && params.data.new_toll_price != undefined)
                        {
                            if(params.data.new_toll_price !== "" && params.data.new_toll_price !== undefined)
                            {
                                var a = params.data.new_toll_price;
                            }
                            else{
                                var a = 0;
                            }
                            if(params.data.base_toll_rate_per_approval !== "" && params.data.base_toll_rate_per_approval !== undefined)
                            {
                                var b = params.data.base_toll_rate_per_approval; 
                            }
                            else{
                                var b = 0;
                            }
                            
                            return parseInt(a)-parseInt(b);
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_COST,
                field:"revised_freight_cost",
                width:180,
                valueGetter:function(params){
                    try {
                        var a = parseFloat(params.data.base_freight_rate_per_approval);
                        // console.log("A ", a);
                        var oCosts = 0;
                        // console.log("otherCosts ", otherCosts)
                        if(otherCosts.length > 0)
                        {
                            otherCosts.map((item) => {
                                oCosts += parseFloat(oCosts)+parseFloat(item.freight_other_cost_col_cost)
                            })
                        }
                        var b = parseFloat(oCosts);
                        // console.log("B ", b);
                        var c = 0;
                        if(params.data.new_toll_price != "" && params.data.new_toll_price != undefined)
                        {
                            if(params.data.new_toll_price !== "" && params.data.new_toll_price !== undefined)
                            {
                                var a1 = params.data.new_toll_price;
                            }
                            else{
                                var a1 = 0;
                            }
                            if(params.data.base_toll_rate_per_approval !== "" && params.data.base_toll_rate_per_approval !== undefined)
                            {
                                var b1 = params.data.base_toll_rate_per_approval; 
                            }
                            else{
                                var b1 = 0;
                            }
                            
                            var c = parseInt(a1)-parseInt(b1);
                        }

                        var revisedprice = 0;
                        var basepric = 0;
                        if(params.data.diesel_price_consider_for_revision !== "" && params.data.diesel_price_consider_for_revision !== undefined)
                        {
                            revisedprice = params.data.diesel_price_consider_for_revision;
                        }
                        if(params.data.base_diesel_rate_per_approval_ltr !== "" && params.data.base_diesel_rate_per_approval_ltr !== undefined)
                        {
                            basepric = params.data.base_diesel_rate_per_approval_ltr;
                        }
                        var d = (((parseFloat(revisedprice)-parseFloat(basepric))/parseFloat(basepric))*100).toFixed(2);
                        // console.log("D ", d);
                        return parseFloat(a)+parseFloat(b)+parseFloat(c)+parseFloat(d);
                    } catch (error) {
                        
                    }
                }
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE,
            //     field:"revised_freight_cost",
            //     width:180,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE_FROM,
            //     field:"revised_freight_cost",
            //     width:180,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE_TO,
            //     field:"revised_freight_cost",
            //     width:180,
            // },
        ]
        var closedcolumnDefs=[
            {
                headerName:Constants.FREIGHT_APPROVALS_DOCUMENT_NO,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_REVISION_MONTH,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_APPLICABLE_PERIOD,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_STATUS,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_VIEW_DOC,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_DOWNLOAD_DOC,
                field:"document_no"
            }
        ]
        var month = this.state.month.value;
        // console.log("month ",month)
        var freq = this.state.conf.diesel_frequence_option;
        var freqFrom = "";
        var freqTo = "";
        if(this.state.freightapproval[0] !== undefined && this.state.freightapproval[0] !== "")
        {
            var freq = this.state.freightapproval[0].diesel_frequence_option;
            var freqFrom = "";
            var freqTo = "";
            
            freqFrom = this.state.freightapproval[0].diesel_frequence_from_period; 
            freqTo = this.state.freightapproval[0].diesel_frequence_to_period; 
        }
        




        // if(this.state.conf.diesel_frequence_option == "FortNightly")
        // {
        //     var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //     var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
        //     var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //     var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //     freqFrom = frm; 
        //     freqTo = trm; 
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Weekly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days.sort();

        //         var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Monthly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days[0];
                
        //         var fm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }
        var approval_levls = this.state.approval_levls;
        
        return (
            <div className="container-fluid">
                 <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="fa fa-truck cus-i"></i>&nbsp;<span>{Constants.PAGE_TITLE_DIESEL_APPROVAL}</span>
                        <a href={"/dieselprices"} type="button" className={"btn btn-warning f13 float-right"} style={{marginRight:"10px"}}>Diesel Prices</a>
                    </h5>
                </div>
				
                <div className="row">
                    <div className="col-sm-12">
                        <ul className={"prg-l "+(this.state.showlevelprocess)}>
                            {(this.state.approval_levls.length > 0)?
                            (this.state.approval_levls.map((itm) =>
                                <li className={(itm.approval_status === 0)?"gryeBG":"grnlbg"}>
                                    <span className="startDot" style={{backgroundColor:(itm.dot_color)}}></span>
                                    <span className="startLine" ></span>
                                    {/* Level-{itm.level}
                                    {
                                        (itm.approval_status === 0)?
                                        <span style={{position:"absolute",left:"0px",top:"0px",width:"100%",height:"3px",borderTop:"3px dotted #ff0000"}}></span>
                                        :
                                        (itm.approval_status === 1)?
                                        <span style={{position:"absolute",left:"0px",top:"0px",width:"100%",height:"3px",borderTop:"3px solid #1cc88a"}}></span>
                                        :""
                                    } */}
                                    
                                    Level-{itm.level}
                                    {
                                        (itm.approval_status === 0)?
                                        // <span className="startLine" style={{border:"2px dotted #ff0000"}}></span>
                                        <span style={{position:"absolute",left:"0px",top:"0px",width:"100%",height:"3px",borderTop:"3px dotted #ff0000"}}></span>
                                        :
                                        (itm.approval_status === 1)?
                                        // <span className="startLine" style={{border:"2px dotted #1cc88a"}} ></span>
                                        <span style={{position:"absolute",left:"0px",top:"0px",width:"100%",height:"3px",borderTop:"3px solid #1cc88a"}}></span>
                                        :""
                                    }

                                    {/* <div>{itm.email}</div> */}
                                    <div>{(itm.approval_status === 0)?<span style={{color:"#000000",fontWeight:"700"}}>{itm.level_comment}</span>:
                                    (itm.approval_status === 1)?<span style={{color:"#1cc88a",fontWeight:"700"}}>{itm.level_comment}</span>:<span style={{color:"#ff0000",fontWeight:"700"}}>{itm.level_comment}</span>}</div>
                                    {(itm.approved_on !== "" && itm.approved_on !== undefined)?
                                    <div>Approved On: {itm.approved_on}</div>
                                    :<div>&nbsp;<br /></div>}
                                    
                                </li>
                            ))
                            :""}
                        </ul>
                        {/* <ul className={"navtabs "+showpagetabs}>
                            <li>
                                <button onClick={this.showTabView.bind(this,1)} type="button" className={"btn cbtn "+(this.state.ctab)}>Active</button>
                            </li>
                            <li>
                                <button onClick={this.showTabView.bind(this,2)} type="button" className={"btn cbtn "+(this.state.tltab)}>Close</button>
                            </li>
                            <li style={{float:"right"}}>
                                <div style={{fontWeight:"bold"}}  className={this.state.showApprovalStatus} >
                                   Current Approval Status:
                                   {(this.state.hideUserApproveTbl === "show-n")?
                                   <span style={{color:(this.state.aprvColor)}}>{this.state.showApprovalStatusText}</span>
                                   :
                                   <span style={{color:(this.state.aprvColor)}}>{this.state.showLevelApprovalStatusText}</span>
                                   }<br />
                                </div>
                            </li> 
                        </ul> */}

                        {(this.state.isUserCreateDoc === 1)?
                            
                            <div>
                                <a href={"/dieselpriceapprovals"} className={"btn cbtn "+(this.state.ctab)}>Initiate</a>
                                <button onClick={this.showTabView.bind(this,3)} type="button" className={"posrel btn cbtn "+(this.state.ptab)}>
                                    Pending
                                    <span className="tabcounter">&nbsp;{this.state.pendingCnt}</span>
                                </button>
                                <button onClick={this.showTabView.bind(this,2)} type="button" className={"posrel btn cbtn "+(this.state.tltab)}>Close
                                    <span className="tabcounter">&nbsp;{this.state.closedCnt}</span></button>
                            
                            </div>
                            :
                            <div>
                                <button onClick={this.showTabView.bind(this,3)} type="button" className={"posrel btn cbtn "+(this.state.ptab)}>Pending
                                    <span className="tabcounter">&nbsp;{this.state.pendingCnt}</span></button>
                                <button onClick={this.showTabView.bind(this,2)} type="button" className={"posrel btn cbtn "+(this.state.tltab)}>Close
                                    <span className="tabcounter">&nbsp;{this.state.closedCnt}</span></button>
                            </div>
                            }

                        <div className={this.state.dtab}>
                            <div className="card">
                                <div className="card-body">
                                        {/* <div style={{fontWeight:"bold"}}  className={showpagelogs} >
                                            Current Approval Status: 
                                            {(this.state.hideUserApproveTbl === "show-n")?
                                            <span style={{color:(this.state.aprvColor)}}>{this.state.showApprovalStatusText}</span>
                                            :
                                            <span style={{color:(this.state.aprvColor)}}>{this.state.showLevelApprovalStatusText}</span>
                                            }<br />
                                        </div> */}
                                        <div className="row" style={{marginTop:"20px"}}>
                                            <div className="col-xl-2 col-md-12 mb-4">
                                                Select Month:
                                            </div>
                                            <div className="col-xl-3 col-md-12 mb-4">
                                                <Select 
                                                    options={this.monthOptions()} 
                                                    value={this.state.month}
                                                    onChange={this.handleMonth.bind(this)}
                                                />
                                            </div>
                                            
                                            <div className="col-xl-6 col-md-12 mb-4">
                                            
                                                
                                            </div>
                                        </div>
                                        <div className={"col-12 "+(this.state.showdata)}>
                                            <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                                <div className="col-xl-2 col-md-12 mb-2">
                                                    Frequency: 
                                                </div>
                                                <div className="col-xl-2 col-md-12 mb-2">
                                                {freq}
                                                <input type="hidden" id="frequency" value={freq} />
                                                </div>
                                                <div className="col-xl-1 col-md-12 mb-2">&nbsp;</div>
                                                <div className="col-xl-2 col-md-12 mb-2">
                                                    Period: &nbsp;&nbsp;
                                                    From : {freqFrom}
                                                    <input type="hidden" id="from_period" value={freqFrom} />
                                                </div>
                                                <div className="col-xl-3 col-md-12 mb-2">
                                                    To : {freqTo}
                                                    <input type="hidden" id="to_period" value={freqTo} />
                                                </div>
                                            </div>
                                        {/* <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "}>
                                            <div className="col-xl-2 col-md-12 mb-1">
                                                Period: 
                                            </div>
                                            <div className="col-xl-3 col-md-12 mb-1">
                                                From : {freqFrom}
                                                <input type="hidden" id="from_period" value={freqFrom} />
                                            </div>
                                            <div className="col-xl-3 col-md-12 mb-1">
                                                To : {freqTo}
                                                <input type="hidden" id="to_period" value={freqTo} />
                                            </div>
                                        </div> */}
                                        <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                            <div className="col-xl-2 col-md-12 mb-2">
                                                Applicable Diesel price: 
                                            </div>
                                            <div className="col-xl-3 col-md-12 mb-2">
                                                {this.state.avgDieselPrice}
                                                <input type="hidden" id="applicableprice" value={this.state.avgDieselPrice} />
                                                <span className={"posrel "} style={{margin:"10px"}}>
                                                    <a href="javascript:;" onClick={this.showToolTip.bind(this)}><i className="fa fa-info-circle"></i></a>
                                                    <span className={"priceTooltip "+(this.state.dieselTooltip)}>
                                                        <a href="javascript:;" className="tooltipClose" onClick={this.onClickHideTooltip.bind(this)}>x</a>
                                                        <span className="priceTooltipBody">We will consider avg diesel price between start and end dates<br /> <div className="tooltip-highlite"><b>Applicable Diesel price = (Sum of diesel prices / count of diesel prices)</b></div></span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                            <div className="col-xl-2 col-md-12 mb-2">
                                                Diesel prices basis: 
                                            </div>
                                            <div className="col-xl-3 col-md-12 mb-2">
                                                {this.state.dieselbasis}
                                                <input type="hidden" id="dieselbasis" value={this.state.dieselbasis} />
                                            </div>
                                        
                                            <div className="col-xl-2 col-md-12 mb-2">
                                                Diesel Price Dates: 
                                            </div>
                                            <div className="col-xl-3 col-md-12 mb-2">
                                                {(this.state.diesel_price_changed_date != "")?this.state.diesel_price_changed_date:""}
                                                {(this.state.capture_diesel_price_from_day != "")?"From : "+this.state.capture_diesel_price_from_day:""}
                                                
                                                <input type="hidden" id="diesel_price_changed_date" value={this.state.diesel_price_changed_date} />
                                                <input type="hidden" id="capture_diesel_price_from_day" value={this.state.capture_diesel_price_from_day} />
                                            </div>
                                            <div className="col-xl-3 col-md-12 mb-1">
                                                {(this.state.capture_diesel_price_to_day != "")?"To : "+this.state.capture_diesel_price_to_day:""}
                                                <input type="hidden" id="capture_diesel_price_to_day" value={this.state.capture_diesel_price_to_day} />
                                            </div>
                                        </div>
                                        <form onSubmit={this.onSubmitFormData}>
                                            <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                                <div className="col-xl-2 col-md-12 mb-2">
                                                Do you want to change Diesel price? 
                                                </div>
                                                <div className="col-xl-2 col-md-12 mb-2">
                                                    <input type="text" className="form-control" name="changed_diesel_price" onChange={this.changeHandler.bind(this)} value={this.state.changed_diesel_price} placeholder="Enter Diesel Price" />
                                                </div>
                                                <div className="col-xl-1 col-md-12 mb-2">
                                                &nbsp;
                                                </div>
                                        
                                                <div className="col-xl-2 col-md-12 mb-2">
                                                Reson for changin price 
                                                </div>
                                                <div className="col-xl-3 col-md-12 mb-1">
                                                    <input type="text" className="form-control" name="changed_diesel_price_reason" onChange={this.changeHandler.bind(this)} value={this.state.changed_diesel_price_reason} placeholder="Enter Reason" />
                                                </div>
                                            </div>
                                            
                                            <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "}>
                                                <div className="col-xl-2 col-md-12 mb-1">
                                                Upload Document 
                                                </div>
                                                <div className="col-xl-6 col-md-12 mb-1">
                                                    <a href={this.state.changed_diesel_price_upload} target="_blank">{this.state.changed_diesel_price_upload}</a>
                                                </div>
                                            </div>
                                            {(this.state.showAfterSubmit != "show-m")?
                                                <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "}>
                                                    <div className="col-xl-4 col-md-12 mb-2" style={{textAlign:"right"}}>
                                                        {(this.state.approval_levelemails === 1)?
                                                        <button type="submit" className="btn btn-danger">Submit</button>
                                                        :""}
                                                    </div>
                                                </div> 
                                            :""}
                                    
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
				<div className={"row "+(this.state.showAfterSubmit)}>
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                             
                                <div className={"row "+this.state.dtab} >
                                   
                                    <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                    <AgGridReact 
                                        rowData={this.state.rowData} 
                                        columnDefs={columnDefs}
                                        defaultColDef ={this.state.defaultColDef }
                                        enableRangeSelection={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellEditingStopped={this.onCellEditingStopped.bind(this)}
                                        
                                    />
                                    </div>
                                    <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "+(this.state.hideapprovalbtn)}>
                                        <div className="col-xl-12 col-md-12 mb-4" style={{textAlign:"center"}}>
                                            {/* {console.log("this.state.approval_levelemails ", this.state.hideapprovalbtn)} */}
                                            {(this.state.approval_levelemails === 1)?
                                            <button type="button" className="btn btn-danger" onClick={this.onClickSendForApproavl}>Send For Approval</button>
                                            :""}
                                        </div>
                                    </div>
                                  

                                    {(this.state.hideUserApproveTbl === "show-m")?
                                    <div className="" style={{marginTop:"10px"}}>
                                        {(this.state.freightapproval.length > 0)?
                                        (this.state.freightapproval[0].approval_status == 1 && this.state.freightapproval[0].approval_sendback == 0)?
                                        (this.state.showLevelApprovalStatusText !== "Approved" && this.state.showLevelApprovalStatusText !== "")?
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-info" onClick={this.onClickSendBackDocument.bind(this)} style={{marginRight:"10px"}}>Send Back</button>
                                            <button type="button" className="btn btn-success" onClick={this.onClickApproveDocument.bind(this)} style={{marginRight:"10px"}}>Approve</button>
                                            <button type="button" className="btn btn-danger" onClick={this.onClickRejectDocument.bind(this)}>Reject</button>
                                        </div>
                                        :""
                                        :""
                                        :""}
                                    </div>
                                    :""}
                                    <div className={showpagelogs}>
                                        <FreightLogs 
                                            document_no={this.props.document_no}
                                        />
                                    </div>

                                </div>
                                
                                <div className={"col-md-12 "+(this.state.showptab)} >
                                    
                                    <PendingDocuments
                                        isDocumentCreator={this.state.isUserCreateDoc}
                                        context={this}
                                    />
                                </div>
                                <div className={" "+this.state.tolltab} >
                                    
                                    <CloseDocuments
                                        context={this}
                                    />
                                </div>
							</div>
						</div>
					</div>					
				</div>
                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
                
                
                <div className={"sliderBlock2 "+(this.state.showApproveDocumentReason)}>
                    <h5 className="crd-bg p-10p">Remarks</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.formApproveDocumentItem.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>                                        
                                        <textarea name="approve_remarks" id="approve_remarks" value={this.state.approve_remarks} className="form-control" onChange={this.changeHandler.bind(this)} rows="8" required ></textarea>
                                    </div>
                                    
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
                
                <div className={"sliderBlock2 "+(this.state.showRejectDocumentReason)}>
                    <h5 className="crd-bg p-10p">Remarks</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.formRejectDocumentItem.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>                                        
                                        <textarea name="reject_remarks" id="reject_remarks" value={this.state.reject_remarks} className="form-control" onChange={this.changeHandler.bind(this)} rows="8" required ></textarea>
                                    </div>
                                    
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
                    
                    

			</div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
// console.log("icn ", icn)
function pushNot(){
    // showNotification();
    
	// setInterval(function(){ showNotification(); }, 20000);
}

function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function showNotification() {	
	if (!Notification) {
		$('body').append('<h4 style="color:red">*Browser does not support Web Notification</h4>');
		return;
	}
	if (Notification.permission !== "granted") {		
		Notification.requestPermission();
	} else {		
		$.ajax({
            url:"http://localhost:5005/billing",
			type: "GET",
			success: function(data, textStatus, jqXHR) {
				
                var theurl = "http://localhost:3005/";
                var notifikasi = new Notification("Freight Rates", {
                    icon: icn,
                    body: "You are checking Freight Rates information.",
                });
                notifikasi.onclick = function () {
                    window.open(theurl); 
                    notifikasi.close();     
                };
                setTimeout(function(){
                    notifikasi.close();
                }, 5000);
        
			},
			error: function(jqXHR, textStatus, errorThrown)	{}
		}); 
	}
};
export default withRouter(FreightApprovalSubmit) ;