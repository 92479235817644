import React, {
    useRef,
    useState,
    useEffect,
    useSyncExternalStore,
  } from "react";
  import { useAsyncError, useNavigate, Link } from "react-router-dom";
  import Select from "react-select";
  import "ag-grid-community/styles/ag-grid.css";
  import "ag-grid-community/styles/ag-theme-alpine.css";
  import { AgGridReact } from "ag-grid-react";
  import "./createShipment.css";
  import {
    FaRoad,
    FaTrain,
    FaPlane,
    FaShip,
    FaBox,
    FaTrash,
    FaUpload,
  } from "react-icons/fa";
  import Modal from "react-responsive-modal";
  import SweetAlert from "react-bootstrap-sweetalert";
  // import "./approverdashboard.css";
  import { v4 as uuidv4 } from "uuid";
  import DateTime from "react-datetime";
  import $ from "jquery";
  import redirectURL from "../redirectURL";
  import moment from "moment";
  import { BodyDropTarget } from "ag-grid-community";
  import { includes, reduceRight } from "country-flags-svg/dist/data/countries";
  import { event } from "react-ga";
  import "./trackingDetailsCard.css";
  
  const TrackingDetailsCard = (props) => {
    console.log("TrackingDetailsCard", props);
    const [contractNumber, setContractNumber] = useState("");
    const [PONumber, setPONumber] = useState("");
    const [recordforedit, setRecordforedit] = useState({});
    const onChangeContractNumber = (e) => {
      setContractNumber(e.target.value);
    };
    const onChangePONumber = (e) => {
      setPONumber(e.target.value);
      setEditStakeDetails((prev) => ({
        ...prev,
        po_no: e.target.value,
      }));
    };
  
    const [movementType, setMovementType] = useState([
      { value: "Import", label: "Import" },
      { value: "Export", label: "Export" },
    ]);
    const [selectMovementType, setSelectMovementType] = useState();
  
    const [cargoType, setCargoType] = useState([
      { value: "General Cargo", label: "General Cargo" },
      { value: "Hazardous Cargo", label: "Hazardous Cargo" },
      { value: "Refridgerated Cargo", label: "Refridgerated Cargo" },
      {
        value: "Refridgerated Hazardous Cargo",
        label: "Refridgerated Hazardous Cargo",
      },
    ]);
    const [lclCargoList, setLclCargoList] = useState([
      { value: "General Cargo", label: "General Cargo" },
      { value: "Hazardous Cargo", label: "Hazardous Cargo" },
    ]);
    const [selectCargoType, setSelectCargoType] = useState();
    const [selectLclCargoType, setSelectLclCargoType] = useState();
    const onSelectLclCargoType = (selectedOption) => {
      setSelectLclCargoType(selectedOption);
    };
    const customStyles = {
      placeholder: (provided) => ({
        ...provided,
        fontSize: "10px", // You can adjust this value to your liking
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: "10px", // You can adjust this value to your liking
      }),
    };
    const [cargoList, setCargoList] = useState([
      { value: "PCargo1", label: "PCargo1" },
      { value: "PCargo2", label: "PCargo2" },
    ]);
    const [supplierCargoList, setSupplierCargoList] = useState([
      { value: "SPCargo1", label: "SPCargo1" },
      { value: "SPCargo2", label: "SPCargo2" },
    ]);
  
    const [selectedCargo, setCargo] = useState();
    const [selectedSupplierCargo, setSupplierCargo] = useState();
  
    const setProductCargoType = (selectedOption) => {
      setCargo(selectedOption);
    };
    const setSupplierProductCargoType = (selectedOption) => {
      setSupplierCargo(selectedOption);
    };
  
    const [shipperType, setShipperType] = useState(""); // Initially, no option is selected
    const handleShipperTypeChange = (e) => {
      setShipperType(e.target.value);
    };
  
    const [productName, setProductName] = useState("");
    const [supplierProductName, setSupplierProductName] = useState("");
    const [disableCustomerProductSave, setDisableCustomerProductSave] =
      useState(true);
    const [disableSupplierProductSave, setDisableSupplierProductSave] =
      useState(true);
    const [errorTxtForCustomerProducts, setErrorTextForCustomerProducts] =
      useState(false);
    const [errorTxtForSupplierProducts, setErrorTextForSupplierProducts] =
      useState(false);
    const onChangeProductName = (e) => {
      setProductName(e.target.value);
    };
    const validateCustomerProductName = () => {
      if (productName.length === 0) {
        setDisableCustomerProductSave(true);
        setErrorTextForCustomerProducts(true);
      } else {
        setDisableCustomerProductSave(false);
        setErrorTextForCustomerProducts(false);
      }
    };
    const ValidateSupplierProductName = () => {
      if (supplierProductName.length === 0) {
        setDisableSupplierProductSave(true);
        setErrorTextForSupplierProducts(true);
      } else {
        setDisableSupplierProductSave(false);
        setErrorTextForSupplierProducts(false);
      }
    };
    const onChangeSupplierProductName = (e) => {
      setSupplierProductName(e.target.value);
    };
  
    const [hsCode, setHSCode] = useState("");
    const [supplierHSCode, setSupplierHSCode] = useState("");
  
    const onChangeHSCode = (e) => {
      setHSCode(e.target.value);
    };
    const onChangeSupplierHSCode = (e) => {
      setSupplierHSCode(e.target.value);
    };
    const closeAlert = () => {
      setShow(false);
      setOverly("show-n");
    };
    const [billOfLading, setBillOfLading] = useState("");
    const onChangeBillOfLading = (e) => {
      setBillOfLading(e.target.value);
      setEditdetails((prev) => ({
        ...prev,
        bill_of_lading_no: e.target.value,
      }));
    };
  
    const mblDate = $("#mbl_date").val();
  
    // for options
    const [shippingLine, setShippingLine] = useState([]);
    // for value
    const [selectShippingLine, setSelectShippingLine] = useState({
      label: "",
      value: "",
    });
    // onChange
    const onSelectShippingLine = (selectedOption) => {
      setSelectShippingLine(selectedOption);
      // console.log(selectedOption, "selectedOption");
      setEditdetails((prev) => ({
        ...prev,
        shipping_line: selectedOption.value,
      }));
    };
  
    const [trackByList, setTrackByList] = useState([
      { label: "Container Number", value: "Container Number" },
      { label: "Bill of Lading Number", value: "BL Number" },
    ]);
    const [trackByValue, setTrackByValue] = useState({ label: "", value: "" });
    const onSelectTrackByType = (selectedOption) => {
      setTrackByValue(selectedOption);
      setInputDisabled(false);
    };
    const [selectIncoterm, setSelectIncoterm] = useState();
    const onSelectIncoterm = (selectedOptions) => {
      setSelectIncoterm(selectedOptions);
      // console.log("selectIncoterm", selectIncoterm);
    };
  
    const [billOfLadingDate, setBillOfLadingDate] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [pickUpDate, setPickUpDate] = useState("");
    const onHandleDateChange = (e) => {
      setBillOfLadingDate(e);
      setEditdetails((prev) => ({
        ...prev,
        bill_of_lading_date: e,
      }));
    };
    const onHandleInvoiceDateChange = (e) => {
      // console.log("onHandleInvoiceDateChange", e);
      setInvoiceDate(e);
      seteditAdditionalDetails((prev) => ({
        ...prev,
        invoice_date: e,
      }));
    };
    const onHandlePickUpDateChange = (e) => {
      setPickUpDate(e);
    };
  
    let [containerCount, setContainerCount] = useState(0);
    const [boxes, setBoxes] = useState();
    const [containerNo, setContainerNo] = useState("");
    const [errorMssge, setErrorMssge] = useState("");
    const onChangeContainerNo = (e) => {
      setContainerNo(e.target.value);
    };
    const [submitDisable, setSubmitDisable] = useState(true);
    const validateContainerNo = () => {
      const pattern = /^[A-Z]{4}\d{7}$/;
      if (containerNo != "" && containerNo != undefined) {
        if (!pattern.test(containerNo)) {
          let errorMessage = "*Please Enter Valid Container No";
          setSubmitDisable(true);
          setErrorMssge(errorMessage);
        } else {
          setSubmitDisable(false);
          setErrorMssge("");
        }
      } else {
        setSubmitDisable(true);
      }
    };
    const [originPort, setOriginPort] = useState("");
    const [customerOriginPort, setCustomerOriginPort] = useState("");
    const [customerStuffingLocation, setCustomerStuffingLocation] = useState("");
    const [customerDestinationPort, setCustomerDestinationPort] = useState("");
    const [customerDestuffingLocation, setCustomerDestuffingLocation] =
      useState("");
    const onChangeCustomerOrigin = (e) => {
      setCustomerOriginPort(e.target.value);
      setEditStakeDetails((prev) => ({
        ...prev,
        origin_port: e.target.value,
      }));
    };
    const onChangeCustomerStuffingLocation = (e) => {
      setCustomerStuffingLocation(e.target.value);
      setEditStakeDetails((prev) => ({
        ...prev,
        stuffing_location: e.target.value,
      }));
    };
    const onChangeCustomerDestination = (e) => {
      setCustomerDestinationPort(e.target.value);
      setEditStakeDetails((prev) => ({
        ...prev,
        destination_location: e.target.value,
      }));
    };
    const onChangeCustomerDestuffingLocation = (e) => {
      setCustomerDestuffingLocation(e.target.value);
      setEditStakeDetails((prev) => ({
        ...prev,
        destuffing_location: e.target.value,
      }));
    };
  
    const [destinationPort, setDestinationPort] = useState("");
    const [transitDays, setTransitDays] = useState(0);
    const onChangeTransitDays = (e) => {
      setTransitDays(e.target.value);
      setEditdetails((prev) => ({
        ...prev,
        transit_days: e.target.value,
      }));
    };
  
    // for value
    const [customer, setCustomer] = useState();
    const [supplier, setSupplier] = useState();
    // for customerloading
    const [customerLoading, setCustomerLoading] = useState(true);
    const [supplierLoading, setSupplierLoading] = useState(true);
    // for options
    const [customerData, setCustomerData] = useState([
      { value: "+Add", label: "+ Edit Customer" },
    ]);
    // const [shippingLine, setPortData] = useState([])
    const [supplierData, setSupplierData] = useState([
      { value: "+Add", label: "+ Edit Supplier" },
    ]);
    const [productData, setProductData] = useState([
      { value: "+Add", label: "+ Add Product" },
    ]);
    const [supplierProductData, setSupplierProductData] = useState([
      { value: "+Add", label: "+ Add Product" },
    ]);
    const [attachmentTitle, setAttachmentTitle] = useState("");
    const [attachmentsDocArr, setAttachmentsDocArr] = useState([]);
    const [attachmentsDoc, setAttachmentsDoc] = useState("");
    const [selectedDocType, setSelectedDocType] = useState({
      value: "",
      label: "Type",
    });
    const [attachmentType, setAttachmentType] = useState({
      value: "",
      label: "Type",
    });
    const [isAttachmentsDocChanged, setIsAttachmentDocsChanged] = useState(false);
    const [documentExtension, setDocumentExtension] = useState("");
    const [email, setEmail] = useState("");
    const [product, setProduct] = useState([]);
    const [supplierProduct, setSupplierProduct] = useState();
  
    const [productLoading, setProductLoading] = useState(true);
    const [supplierProductLoading, setSupplierProductLoading] = useState(true);
  
    const [openCustomerModel, setOpenCustomerModel] = useState(false);
    const [openProductModel, setOpenProductModel] = useState(false);
  
    const [openSupplierModel, setOpenSupplierModel] = useState(false);
    const [openSupplierProductModel, setSuppilerProductModel] = useState(false);
    const [customerReferenceNo, setCustomerReferenceNo] = useState("");
    const [invoiceNo, setInvoiceNo] = useState("");
    const [demurrageFreeDays, setDemurrageFreeDays] = useState("");
    const [detentionFreeDays, setDetetentionFreeDays] = useState("");
    const [CFSFreeDays, setCFSFreeDays] = useState("");
    const [supplierReferenceNo, setSupplierReferenceNo] = useState("");
  
    const [FTAOptions, setFTAOptions] = useState([
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ]);
    const [RDBOptions, setRDBOptions] = useState([
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ]);
    const [EPCGOptions, setEPCGOptions] = useState([
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ]);
    const [LCOptions, setLCOptions] = useState([
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ]);
  
    const [FTAValue, setFTAValue] = useState();
    const [RDBValue, setRDBValue] = useState();
    const [EPCGValue, setEPCGValue] = useState();
    const [LCValue, setLCValue] = useState();
  
    const [customerName, setCustomerName] = useState("");
    const [supplierName, setSupplierName] = useState("");
    const [isSelectDisabled, setIsSelectDisabled] = useState(true);
    const [customerCode, setCustomerCode] = useState("");
    const [LCNumber, setLCNumber] = useState("");
    const [overly, setOverly] = useState("show-n");
    const [show, setShow] = useState(false);
    const [basicType, setBasicType] = useState("default");
    const [basicTitle, setBasicTitle] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [supplierAdminEmail, setSupplierAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
    const [supplierAdminName, setSupplierAdminName] = useState("");
    const [enablePOC, setEnablePOC] = useState(true);
    const [supplierEnablePOC, setSupplierEnablePOC] = useState(true);
  
    const [teamList, setTeamList] = useState([
      { value: "team1", label: "team1" },
      { value: "team2", label: "team2" },
    ]);
    const [supplierTeamList, setSupplierTeamList] = useState([
      { value: "team1", label: "team1" },
      { value: "team2", label: "team2" },
    ]);
    const [selectedTeam, setSelectTeam] = useState();
    const [supplierSelectedTeam, setSupplierTeam] = useState();
    const [teamListForProduct, setTeamListForProduct] = useState([
      { value: "Default", label: "Default EGA Group" },
      { value: "PTeam1", label: "PTeam1" },
      { value: "PTeam2", label: "PTeam2" },
    ]);
    const [supplierTeamListForProduct, setSupplierTeamListForProduct] = useState([
      { value: "Default", label: "Default EGA Group" },
      { value: "SPTeam1", label: "SPTeam1" },
      { value: "SPTeam2", label: "SPTeam2" },
    ]);
    const [selectedTeamForProduct, setSelectTeamForProduct] = useState(
      teamListForProduct[0]
    );
    const [selectedSupplierTeamForProduct, setSelectSupplierTeamForProduct] =
      useState(supplierTeamListForProduct[0]);
    const [pointOfContacts, setPOCs] = useState([
      { value: "POC1", label: "POC1" },
      { value: "POC2", label: "POC2" },
    ]);
    const [supplierPointOfContacts, setSupplierPointOfContacts] = useState([
      { value: "POC1", label: "POC1" },
      { value: "POC2", label: "POC2" },
    ]);
    const [selectedPOC, setSelectedPOC] = useState();
    const [supplierPOC, setSupplierPOC] = useState();
  
    const [activeItem, setActiveItem] = useState("");
    const shipmentType=[
      {value: "FCL", label: "Full Container Load"},
      {value: "LCL", label: "Less Container Load"}]
    const incoterms=[
      { value: "EXW", label: "EXW - Ex Work" },
      { value: "FCA", label: "FCA - Free Carrier" },
      { value: "FAS", label: "FAS - Free Alongside Ship" },
      { value: "FOB", label: "FOB - Free on Board" },
      { value: "CFR", label: "CFR - Cost and Freight" },
      { value: "CIF", label: "CIF - Cost, Insurance, and Freight " },
      { value: "CPT", label: "CPT - Carriage Paid To" },
      { value: "CIP", label: "CIP - Carriage and Insurance Paid To" },
      { value: "DAP", label: "DAP - Delivered at Place " },
      { value: "DPU", label: "DPU - Delivered at Place Unloaded " },
      { value: "DDP", label: "DDP - Delivered Buty Paid " }
    ]
    const [shipmentActive, setShipmentActive] = useState({value:"",label:""});
    const [showShipmentType, setShowShipmentType] = useState(false);
    const [lclCargoType, setLclCargoType] = useState(false);
    const [docFieldCount, setDocFieldCount] = useState(0);
    const [totalDocFields, setTotalDocFields] = useState([
      { id: 0, value: "", file: "" },
    ]);
  
    const [documentOptions, setDocumentOptions] = useState([
      { value: "MSDS", label: "MSDS" },
      { value: "Packing List", label: "Packing List" },
      { value: "Custom Invoice", label: "Custom Invoice" },
      { value: "Insurance Company", label: "Insurance Company" },
      { value: "Pickup Request Form", label: "Pickup Request Form" },
      { value: "Custom Invoice/PL", label: "Custom Invoice/PL" },
      { value: "Other", label: "Other" },
      { value: "DO", label: "DO" },
      { value: "Attachment", label: "Attachment" },
    ]);
    const [inputDisabled, setInputDisabled] = useState(true);
    const [editDetails, setEditdetails] = useState({
      contract_number: "",
      movement_type: "",
      cargo_mode: "",
      shipment_type: "",
      selected_cargo_type: "",
      track_by: "",
      bill_of_lading_date: "",
      shipping_line: "",
      transit_days: "",
      bill_of_lading_no: "",
      container_no: "",
      shipment_created_with: "",
    });
  
    const [stateData, setStateData] = useState({
      customHouseAgent: "",
      freightForwarder: "",
      bl_containers: [],
    });
  
    const [editStakeholderDetail, setEditStakeDetails] = useState({
      shipment_owner: "",
      po_no: "",
      shipper_type: "",
      origin_port: "",
      stuffing_location: "",
      destination_location: "",
      destuffing_location: "",
    });
    const [editAdditionalDetails, seteditAdditionalDetails] = useState({
      invoice_no: "",
      invoice_date: "",
      free_trade_agreement: "",
      rdb_value: "",
      epcg_value: "",
      letter_of_credit: "",
      incoterm:{value:"",label:""},
    });
    const [editdemurrageDetails, seteditdemurrageDetails] = useState({
      demurrage_free_days: "",
      detention_free_days: "",
      cfs_free_days: "",
      demurrage_and_detention_port: ""
    });
  
    useEffect(() => {
      // console.log("editdemurrageDetails", editdemurrageDetails);
    }, [editdemurrageDetails]);
    const [editsupplier, seteditsupplier] = useState({
      supplier: "",
      admin_name: "",
      admin_email: "",
      team: "",
      poc: "",
      product_selected: "",
      supplierReferenceId: "",
    });
    const [editcustomer, seteditcustomer] = useState({
      customer: "",
      admin_name_cust: "",
      admin_email_cust: "",
      team_cust: "",
      poc_cust: "",
      product_selected_cust: "",
      customerReferenceId: "",
      customer_code: "",
    });
    useEffect(() => {
      let userEmail = localStorage.getItem("email");
      setEmail(userEmail);
  
      // setTrackByValue()
      setShowShipmentType(true);
      fetchShippingLineDetails();
      setInputDisabled(true);
      getShipmentForEdit();
    }, [props]);
    const getShipmentForEdit = () => {
      var params = { shipment_unique_id: props.shipment_unique_id };
      let bl_containers = [];
      redirectURL.post("/exim/getshipmentforedit", params).then((resp) => {
        // console.log("getshipmentforedit", resp.data);
        if (resp.data.customer_details.length > 0) {
          setCustomer(resp.data.customer_details[0].customer);
        } else {
          setCustomer("NA");
        }
  
        if (resp.data.supplier_details.length > 0) {
          setSupplier(resp.data.supplier_details[0].customer);
        } else {
          setSupplier("NA");
        }
  
        if (resp.data.product_details.length > 0) {
          setProduct(resp.data.product_details);
        }
        var record = resp.data.record;
        record.forEach((each) => {
          if (each.shipment_unique_id === props.shipment_unique_id) {
            bl_containers.push(each.container_no);
          }
        });
        // console.log(record, "420");
        if (record != "" && record != undefined) {
          if (record.length > 0) {
            var e = record[0];
  
            setContainerNo(e.container_no);
  
            setShipperType(e.shipper_type);
            setActiveItem(e.cargo_mode);
            setShipmentActive(shipmentType.find(item => item.value === e.shipping_mode));
            setShowShipmentType(e.shipment_type);
            setRecordforedit(record[0]);
            if (e.pick_up_date) {
              setPickUpDate(moment.parseZone(e.pick_up_date).utcOffset("+00:00"));
            } else {
              setPickUpDate("");
            }
  
            if (e.hasOwnProperty("lc_no")) {
              setLCNumber(e.lc_no);
            } else {
              setLCNumber("");
            }
            setEditdetails((prev) => ({
              ...prev,
              contract_number: e.contract_number,
              movement_type: e.movement_type,
              cargo_mode: e.cargo_mode,
              shipment_type: e.shipment_type,
              selected_cargo_type: e.selected_cargo_type,
              track_by: e.track_by,
              bill_of_lading_date: e.bill_of_lading_date
                ? moment.parseZone(e.bill_of_lading_date).utcOffset("+00:00")
                : "",
              shipping_line: e.shipping_line,
              transit_days: e.transit_days,
              bill_of_lading_no: e.mbl_number,
              container_no: e.container_no,
              shipment_created_with: e.shipment_created_with,
            }));
            setStateData({
              ...stateData,
              freightForwarder:e.freight_forwarder,
              customHouseAgent:e.custom_house_agent,
              bl_containers: bl_containers,
            });
            setEditStakeDetails((prev) => ({
              ...prev,
              shipment_owner: e.shipment_owner,
              po_no: e.po_no,
              shipper_type: e.shipper_type,
              origin_port: e.origin_port,
              stuffing_location: e.stuffing_location,
              destination_location: e.destination_location,
              destuffing_location: e.destuffing_location,
            }));
  
            let letterOfCredit = "No";
            if (
              e.hasOwnProperty("letter_of_credit") &&
              e.letter_of_credit.includes("yes")
            ) {
              letterOfCredit = "yes";
            } else {
              letterOfCredit = "No";
            }
            //let value=e.incoterm
            // for(var i=0;i<incotermsList.incoterm.length;i++){
            //   if(incotermsList[i].value===e.incoterm){
            //     seteditAdditionalDetails({
            //       ...editAdditionalDetails,
            //       incoterm:incotermsList[i]
            //     })
            //   }
            // }
            seteditAdditionalDetails((prev) => ({
              ...prev,
              invoice_no: e.invoice_no,
              invoice_date: e.invoice_date
                ? moment.parseZone(e.invoice_date).utcOffset("+00:00")
                : "",
              free_trade_agreement: e.free_trade_agreement,
              rdb_value: e.rdb_value,
              epcg_value: e.epcg_value,
              letter_of_credit: e.letter_of_credit,
              incoterm:incoterms.find(item=>item.value===e.incoterm)
            }));
  
            if (e.hasOwnProperty("lc_no") && e.lc_no != "") {
              setLCNumber(e.lc_no);
            } else {
              setLCNumber("NA");
            }
            seteditdemurrageDetails((prev) => ({
              ...prev,
              demurrage_free_days: e.hasOwnProperty("demurrage_free_days")
                ? e.demurrage_free_days
                : "NA",
              detention_free_days: e.hasOwnProperty("detention_free_days")
                ? e.detention_free_days
                : "NA",
              cfs_free_days: e.hasOwnProperty("cfs_free_days")
                ? e.cfs_free_days
                : "NA",
              demurrage_and_detention_port: e.hasOwnProperty("demurrage_and_detention_port") ? e.demurrage_and_detention_port : "NA"
            }));
          }
        }
        if (
          resp.data.supplier_details != "" &&
          resp.data.supplier_details != undefined
        ) {
          if (resp.data.supplier_details.length > 0) {
            var supp_details = resp.data.supplier_details;
            seteditsupplier((prev) => ({
              ...prev,
              supplier: supp_details.supplier,
              admin_name: supp_details.admin_name,
              admin_email: supp_details.admin_email,
              team: supp_details.team,
              poc: supp_details.poc,
              product_selected: supp_details.product_selected,
              supplierReferenceId: supp_details.supplier_reference_id,
            }));
          }
        }
        if (
          resp.data.customer_details != "" &&
          resp.data.customer_details != undefined
        ) {
          if (resp.data.customer_details.length > 0) {
            var cust_details = resp.data.customer_details[0];
            // console.log("custoimerr", cust_details);
            seteditcustomer((prev) => ({
              ...prev,
              customer: cust_details.customer,
              admin_name_cust: cust_details.admin_name,
              admin_email_cust: cust_details.admin_email,
              team_cust: cust_details.team,
              poc_cust: cust_details.poc,
              customer_code: cust_details.customer_code,
              // product_selected_cust: supp_details.product_selected,
              customerReferenceId: cust_details.customer_reference_id,
            }));
          }
        }
      });
    };
    
    const fetchShippingLineDetails = async () => {
      await redirectURL
        .post("/exim/fetchShippingLineDetails")
        .then((resp) => {
          if (resp.data.status === "success") {
            const shippingLineArr = resp.data.shippingLineArr;
            // console.log("shippingLineArr", shippingLineArr);
            const shippingLine = shippingLineArr.map((e) => ({
              value: e,
              label: e,
            }));
            // console.log("shippingLine", shippingLine);
            setShippingLine(shippingLine);
          }
        })
        .catch((e) => {
          console.log("error message is", e);
        });
    };
    const fetchTableItemsForDocs = async () => {
      // console.log("entered fetch table");
      await redirectURL
        .post("/exim/getCustomerEximDocuments")
        .then((resp) => {
          // console.log("requiredDocs", resp.data);
  
          let trackingDocuments = resp.data.requiredDocs;
          trackingDocuments = trackingDocuments.map((doc) => ({
            documentName: doc.document_title,
            documentType: doc.document_type,
          }));
          // console.log("trackingDocuments", trackingDocuments);
          setAttachmentsDocArr(trackingDocuments);
          // console.log(attachmentsDocArr);
        })
        .catch((err) => {
          console.log("getEximTrackingDocuments err", err);
        });
    };
    const fetchCustomerDetails = async () => {
      // console.log("customerData", customerData);
      await redirectURL
        .post("/exim/fetchCustomerDetails")
        .then((resp) => {
          if (resp.data.status === "success") {
            const customerDetails = resp.data.customerDetails;
            // console.log("customerDetails", customerDetails);
            const fetchCustomers = customerDetails.map((e) => {
              return e.customer;
            });
  
            // console.log("fetchCustomers", fetchCustomers);
            const updateCustomerData = fetchCustomers.map((e) => ({
              value: e,
              label: e,
            }));
            // console.log("updateCustomerData", updateCustomerData);
            let updatedDataNames = [];
            let updatedData = [];
            // console.log(customerData);
            customerData.forEach((each) => {
              if (!updatedDataNames.includes(each.value)) {
                updatedData.push(each);
                updatedDataNames.push(each.value);
              } else {
              }
            });
            setCustomerData(updatedData);
  
            // console.log(updateCustomerData);
            // console.log(customerData);
            // console.log(updatedData);
          }
        })
        .catch((e) => {
          console.log("error message is", e);
        });
    };
    const fetchSupplierDetails = async () => {
      await redirectURL
        .post("/exim/fetchSupplierDetails")
        .then((resp) => {
          if (resp.data.status === "success") {
            const supplierDetails = resp.data.supplierDetails;
            // console.log("supplierDetails", supplierDetails);
            const fetchSuppliers = supplierDetails.map((e) => {
              return e.supplier;
            });
            const updateSupplierData = fetchSuppliers.map((e) => ({
              value: e,
              label: e,
            }));
            // console.log("updateSupplierData", updateSupplierData);
            let updatedDataNames = [];
            let updatedData = [];
            supplierData.forEach((each) => {
              if (!updatedDataNames.includes(each.value)) {
                updatedData.push(each);
                updatedDataNames.push(each.value);
              }
            });
            setSupplierData(updatedData);
          }
        })
        .catch((e) => {
          console.log("error message is", e);
        });
    };
    const fetchCustomerProductDetails = async () => {
      await redirectURL
        .post("/exim/fetchCustomerProductDetails")
        .then((resp) => {
          if (resp.data.status === "success") {
            const customerProductDetails = resp.data.productDetails;
            // console.log("customerProductDetails 565", customerProductDetails);
            const fetchProducts = customerProductDetails.map((e) => {
              return e.customer_product_name;
            });
            const updateProductData = fetchProducts.map((e) => ({
              value: e,
              label: e,
            }));
            // console.log(updateProductData, "fetched DBdetails");
            let updatedDataNames = [];
            let updatedData = [];
  
            productData.forEach((each) => {
              if (!updatedDataNames.includes(each.value)) {
                updatedData.push(each);
                updatedDataNames.push(each.value);
              }
            });
            setProductData(updatedData);
  
            // console.log("404", productData)
          } else {
            console.log("unable to fetch records");
          }
        })
        .catch((e) => {
          console.log("error message is", e);
        });
    };
    const fetchSupplierProductDetails = async () => {
      await redirectURL
        .post("/exim/fetchSupplierProductDetails")
        .then((resp) => {
          if (resp.data.status === "success") {
            const supplierProductDetails = resp.data.productDetails;
            // console.log("supplierProductDetails", supplierProductDetails);
            const fetchProducts = supplierProductDetails.map((e) => {
              return e.product_name;
            });
            const updateProductData = fetchProducts.map((e) => ({
              value: e,
              label: e,
            }));
  
            let updatedDataNames = [];
            let updatedData = [];
            supplierProductData.forEach((each) => {
              if (!updatedDataNames.includes(each.value)) {
                updatedData.push(each);
                updatedDataNames.push(each.value);
              }
            });
  
            setSupplierProductData(updatedData);
  
            // console.log("supplier product data", supplierProductData);
          } else {
            console.log("unable to fetch records");
          }
        })
        .catch((e) => {
          console.log("error message is", e);
        });
    };
    const onChangeCustomer = (selectedOption) => {
      if (selectedOption.value === "+Add") {
        setOverly("show-m");
        setCustomer("");
        setOpenCustomerModel(true);
      } else {
        setCustomer(selectedOption);
      }
    };
    const onChangeSupplier = (selectedOption) => {
      // console.log(selectedOption);
      if (selectedOption.value === "+Add") {
        setOverly("show-m");
        setSupplier("");
        setOpenSupplierModel(true);
      } else {
        setSupplier(selectedOption);
      }
    };
    const onChangeInvoiceNo = (e) => {
      setInvoiceNo(e.target.value);
      seteditAdditionalDetails((prev) => ({
        ...prev,
        invoice_no: e.target.value,
      }));
    };
    const onChangeDemurrageDays = (e) => {
      setDemurrageFreeDays(e.target.value);
      seteditdemurrageDetails((prev) => ({
        ...prev,
        demurrage_free_days: e.target.value,
      }));
    };
    const onChangeDetentionDays = (e) => {
      setDetetentionFreeDays(e.target.value);
      seteditdemurrageDetails((prev) => ({
        ...prev,
        detention_free_days: e.target.value,
      }));
    };
    const onChangeCFSDays = (e) => {
      setCFSFreeDays(e.target.value);
      seteditdemurrageDetails((prev) => ({
        ...prev,
        cfs_free_days: e.target.value,
      }));
    };
  
    const customerList = [
      { value: "karthik", label: "Karthik" },
      { value: "dheeraj", label: "dheeraj" },
    ];
    const [Owners, setOwners] = useState(customerList);
    const [selectOwner, setSelectOwner] = useState();
    const onSelectOwner = (selectedOption) => {
      setSelectOwner(selectedOption);
      setEditStakeDetails((prev) => ({
        ...prev,
        shipment_owner: selectedOption.value,
      }));
    };
    const onSelectFTA = (selectedOption) => {
      setFTAValue(selectedOption);
      seteditAdditionalDetails((prev) => ({
        ...prev,
        free_trade_agreement: selectedOption.value,
      }));
    };
    const onSelectRDB = (selectedOption) => {
      setRDBValue(selectedOption);
      seteditAdditionalDetails((prev) => ({
        ...prev,
        rdb_value: selectedOption.value,
      }));
    };
    const onSelectEPCG = (selectedOption) => {
      setEPCGValue(selectedOption);
      seteditAdditionalDetails((prev) => ({
        ...prev,
        epcg_value: selectedOption.value,
      }));
    };
    const onSelectLC = (selectedOption) => {
      setLCValue(selectedOption);
      seteditAdditionalDetails((prev) => ({
        ...prev,
        letter_of_credit: selectedOption.value,
      }));
    };
  
    const onChangeLCNumber = (e) => {
      setLCNumber(e.target.value);
    };
    // Cross Click functionalities
    const onCloseCustomerModal = () => {
      setOpenCustomerModel(false);
      setOverly("show-n");
    };
    const onCloseProductModal = () => {
      setOpenProductModel(false);
      setOverly("show-n");
    };
    const onCloseSupplierModal = () => {
      setOpenSupplierModel(false);
      setOverly("show-n");
    };
    const onCloseSupplierProductModal = () => {
      setSuppilerProductModel(false);
      setOverly("show-n");
    };
  
    // Cancel click functionalities
    const onClickHideCustomerModal = () => {
      setOverly("show-n");
      setOpenCustomerModel(false);
    };
    const onClickHideProductModal = () => {
      setOverly("show-n");
      setOpenProductModel(false);
    };
    const onClickHideSupplierModal = () => {
      setOverly("show-n");
      setOpenSupplierModel(false);
    };
    const onClickHideSupplierProductModal = () => {
      setOverly("show-n");
      setSuppilerProductModel(false);
    };
    const [customerReferenceId, setCustomerReferenceId] = useState("");
    const [supplierReferenceId, setSupplierReferenceId] = useState("");
  
    const onSaveCustomerDetails = async (e) => {
      e.preventDefault();
      // console.log("862");
      var customerDetails = {
        customer: editcustomer.customer,
        customer_code: editcustomer.customer_code,
        admin_name: editcustomer.admin_name_cust,
        admin_email: editcustomer.admin_email_cust,
        team: editcustomer.team_cust,
        poc: editcustomer.poc_cust,
        shipment_unique_id: props.shipment_unique_id,
      };
      await redirectURL
        .post("/exim/updateCustomerDetails", customerDetails)
        .then(async (resp) => {
          if (resp.data.status === "success") {
            // console.log("yes");
            setShow(true);
            setBasicType("success");
            setBasicTitle(resp.data.message);
  
            setOpenCustomerModel(false);
            await fetchCustomerDetails();
            // console.log("606",openCustomerModel)
            await setTimeout(() => {
              const addCustomer = [{ value: customerName, label: customerName }];
              setCustomerData((prevState) => [...addCustomer, ...prevState]);
            }, 500);
            setOverly("show-n");
            setCustomerName("");
            setCustomerCode("");
            setAdminName("");
            setAdminEmail("");
            setSelectTeam({ value: "Default", label: "Default EGA Group" });
            setSelectedPOC("");
            setProduct([]);
          } else {
            setShow(true);
            setBasicType("danger");
            setBasicTitle(resp.data.message);
          }
        })
        .catch((e) => {
          console.log("Error message is", e);
        });
    };
    const onSaveSupplierDetails = async (e) => {
      e.preventDefault();
      var supplierDetails = {
        supplier: editsupplier.supplier,
        admin_name: editsupplier.admin_name,
        admin_email: editsupplier.admin_email,
        team: editsupplier.team,
        poc: editsupplier.poc,
        shipment_unique_id: props.shipment_unique_id,
      };
      await redirectURL
        .post("/exim/updateSupplierDetails", supplierDetails)
        .then(async (resp) => {
          if (resp.data.status === "success") {
            setShow(true);
            setBasicType("success");
            setBasicTitle(resp.data.message);
            setOpenSupplierModel(false);
            await fetchSupplierDetails();
            await setTimeout(() => {
              const addSupplier = [{ value: supplierName, label: supplierName }];
              setSupplierData((prevState) => [...addSupplier, ...prevState]);
            }, 500);
  
            setOverly("show-n");
            setSupplierName("");
            setSupplierName("");
            setSupplierAdminName("");
            setSupplierAdminEmail("");
            setSupplierTeam({ value: "Default", label: "Default EGA Group" });
            setSupplierPOC("");
            setSupplierProduct("");
          } else {
            setShow(true);
            setBasicType("danger");
            setBasicTitle(resp.data.message);
          }
        })
        .catch((e) => {
          console.log("Error message is", e);
        });
    };
    const onSaveSupplierProductDetails = async (e) => {
      e.preventDefault();
      const ProductName = supplierProductName;
      const HSCode = supplierHSCode;
      const SelectedCargo = selectedSupplierCargo;
      const SelectedTeamForProduct = selectedSupplierTeamForProduct;
      const query = {
        supplierName,
        ProductName,
        HSCode,
        SelectedCargo,
        SelectedTeamForProduct,
        // supplier_product_reference_id,
        // supplier_reference_id: supplierReferenceId
      };
      if (ProductName.length === 0) {
        setErrorTextForSupplierProducts(true);
      } else {
        // console.log("SupplierProductQuery", query);
        await redirectURL
          .post("/exim/saveSupplierProductDetails", query)
          .then(async (resp) => {
            if (resp.data.status === "success") {
              setShow(true);
              setBasicType("success");
              setBasicTitle(resp.data.message);
              setSuppilerProductModel(false);
              await fetchSupplierProductDetails();
              await setTimeout(() => {
                const addProduct = [
                  { value: supplierProductName, label: supplierProductName },
                ];
                setSupplierProductData((prevState) => [
                  ...addProduct,
                  ...prevState,
                ]);
              }, 500);
  
              setOverly("show-n");
              setSupplierProductName("");
              setSupplierHSCode("");
              setSupplierCargo("");
              setSelectSupplierTeamForProduct("");
            } else {
              setShow(true);
              setBasicType("danger");
              setBasicTitle(resp.data.message);
            }
          })
          .catch((e) => {
            console.log("Error message is", e);
          });
      }
      // console.log(query, "query");
    };
    const onSaveProductDetails = async (e) => {
      e.preventDefault();
      const customerProductName = productName;
      const customerHSCode = hsCode;
      const customerSelectedCargo = selectedCargo;
      const customerSelectedTeamForProduct = selectedTeamForProduct;
  
      // const now = new Date();
      // const customer_product_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
  
      // console.log(uniqueId);
  
      const query = {
        customerName,
        customerProductName,
        customerHSCode,
        customerSelectedCargo,
        customerSelectedTeamForProduct,
        // customer_product_reference_id,
        // customer_reference_id: customerReferenceId
      };
      if (customerProductName.length === 0) {
        setErrorTextForCustomerProducts(true);
      } else {
        // console.log("customerProductQuery", query);
        await redirectURL
          .post("/exim/saveCustomerProductDetails", query)
          .then(async (resp) => {
            if (resp.data.status === "success") {
              setShow(true);
              setBasicType("success");
              setBasicTitle(resp.data.message);
              setOpenProductModel(false);
              await fetchCustomerProductDetails();
  
              await setTimeout(() => {
                // console.log(productData, "before");
                const addProduct = [{ value: productName, label: productName }];
                setProductData((prevState) => [...addProduct, ...prevState]);
                // console.log(productData, "after save product Data is");
              }, 500);
              // console.log("538",openProductModel)
  
              setOverly("show-n");
              setProductName("");
              setHSCode("");
              setCargo("");
              setSelectTeamForProduct("");
              // add the product name as value label and update the productData here
            } else {
              setShow(true);
              setBasicType("danger");
              setBasicTitle(resp.data.message);
            }
          });
        // save product details to exim_products
      }
      // console.log(query, "query");
    };
  
    const onChangeCustomerName = (e) => {
      seteditcustomer((prev) => ({
        ...prev,
        customer: e.target.value,
      }));
      setIsSelectDisabled(false);
    };
    const validateCustomerName = (e) => {
      if (customerName.length === 0) {
        setIsSelectDisabled(true);
      }
    };
    const [isSupplierSelectDisabled, setIsSupplierSelectDisabled] =
      useState(true);
    const onValidateSupplierName = (e) => {
      if (supplierName.length === 0) {
        setIsSupplierSelectDisabled(true);
      }
    };
    const onChangeSupplierName = (e) => {
      setSupplierName(e.target.value);
      setIsSupplierSelectDisabled(false);
      seteditsupplier((prev) => ({
        ...prev,
        supplier: e.target.value,
      }));
    };
  
    const onChangeCustomerCode = (e) => {
      seteditcustomer((prev) => ({
        ...prev,
        customer_code: e.target.value,
      }));
    };
  
    const onChangeAdminEmail = (e) => {
      seteditcustomer((prev) => ({
        ...prev,
        admin_email: e.target.value,
      }));
    };
    const onChangeSupplierAdminEmail = (e) => {
      seteditsupplier((prev) => ({
        ...prev,
        admin_email: e.target.value,
      }));
    };
  
    const onChangeAdminName = (e) => {
      seteditcustomer((prev) => ({
        ...prev,
        admin_name_cust: e.target.value,
      }));
    };
    const onChangeSupplierAdminName = (e) => {
      seteditsupplier((prev) => ({
        ...prev,
        admin_name: e.target.value,
      }));
    };
  
    const onSelectTeam = (selectedOption) => {
      seteditcustomer((prev) => ({
        ...prev,
        team: selectedOption.value,
      }));
      setEnablePOC(false);
    };
    const onSelectSupplierTeam = (selectedOption) => {
      seteditsupplier((prev) => ({
        ...prev,
        team: selectedOption.value,
      }));
      setSupplierEnablePOC(false);
    };
  
    const onSelectTeamForProduct = (selectedOption) => {
      setSelectTeamForProduct(selectedOption);
    };
    const onSelectSupplierTeamForProduct = (selectedOption) => {
      setSelectSupplierTeamForProduct(selectedOption);
    };
  
    const onSelectPOC = (selectedOption) => {
      seteditcustomer((prev) => ({
        ...prev,
        poc: selectedOption.value,
      }));
    };
    const onSelectSupplierPOC = (selectedOption) => {
      seteditsupplier((prev) => ({
        ...prev,
        poc: selectedOption.value,
      }));
    };
  
    const enableShipmentType = () => {
      setShowShipmentType(true);
      setActiveItem("SEA");
    };
    const disbaleShipmentType = () => {
      setShowShipmentType(false);
      setActiveItem("AIR");
    };
    const enableLclCargoTypeDropDown = () => {
      setLclCargoType(true);
      setShipmentActive("LCL");
    };
    const disableLclCargoTypeDropDown = () => {
      setLclCargoType(false);
      setShipmentActive("FCL");
    };
  
    const onSelectDocumentType = (selectedOption) => {
      setSelectedDocType(selectedOption);
    };
    const [openDocUploadSlider, setOpenDocUploadSlider] = useState("");
  
    const onAddDocuments = (e) => {
      e.preventDefault();
      if (trackByValue && trackByValue.value === "") {
        setOpenDocUploadSlider("");
      } else {
        // console.log(customerReferenceId, "1186");
        // console.log("here");
        setOverly("show-m");
        setBasicTitle("");
        setBasicType("");
        setShow("");
        setOpenDocUploadSlider("slider-translate");
      }
    };
    const onClickHideAll = () => {
      setOverly("show-n");
      setOpenDocUploadSlider("");
    };
    const onDeleteDocument = (id) => {
      const updatedTotalDOcFields = totalDocFields.filter(
        (field) => field.id != id
      );
      setTotalDocFields(updatedTotalDOcFields);
    };
    const [selectedFile, setSelectedFile] = useState(null);
    const onUploadDocument = (e, id) => {
      // console.log(e, "e");
      // console.log(id, "id");
      const file = e.target.files[0];
      const fieldId = id;
  
      const ogData = [...totalDocFields];
  
      const upOgData = ogData.map(
        (f) => (f.id = "shipmentInput" == fieldId ? { ...f, file: file.name } : f)
      );
      setTotalDocFields(upOgData);
      // console.log("totalDocFields", totalDocFields);
    };
    const changeAttachmentsFileHandler = (event) => {
      const file = event.target.files[0];
      // console.log("file", file);
      let validFileExtensions = ["png", "jpeg", "pdf", "xlsx"];
      const documentExtension = file.name.split(".").pop();
      // console.log("documentExtension", documentExtension);
  
      if (validFileExtensions.includes(documentExtension)) {
        const previousFile = attachmentsDoc;
        if (file && file !== previousFile) {
          setIsAttachmentDocsChanged(true);
          setAttachmentsDoc(file);
          setDocumentExtension(documentExtension);
        } else {
          setIsAttachmentDocsChanged(false);
        }
      } else {
        document.getElementById("attachmentsDoc").value = null;
        setShow(true);
        setBasicType("danger");
        setBasicTitle("Invalid file format.");
      }
    };
    const getEximTrackingDocuments = async () => {
      let data = {
        tracking_user_mapping_id: this.state.tracking_user_mapping_id,
      };
  
      await redirectURL
        .post("/exim/getEximTrackingDocuments", data)
        .then((resp) => {
          // console.log("getEximTrackingDocuments resp", resp.data);
  
          let trackingDocuments = resp.data.eximDocuments;
          let allDocuments = [];
  
          trackingDocuments.forEach((eachDocx) => {
            let eachDOcxDetails = eachDocx.eximDocuments[0];
            allDocuments.push(eachDOcxDetails);
          });
  
          this.setState({
            attachmentsDocArr: allDocuments,
          });
        })
        .catch((err) => {
          console.log("getEximTrackingDocuments err", err);
        });
    };
    const handleAddAttachmentsBtn = (e) => {
      e.preventDefault();
      if (attachmentTitle && attachmentsDoc) {
        // console.log("attachmentsDoc", attachmentsDoc);
        const formData = new FormData();
        // formData.append("exim_container_no", this.state.container_no);
        if (trackByValue && trackByValue.value === "Container Number") {
          formData.append("container_no", containerNo);
        } else if (trackByValue && trackByValue.value === "BL Number") {
          formData.append("bill_of_lading_no", billOfLading);
        }
        formData.append("created_by", email);
        formData.append("document_title", attachmentTitle);
        formData.append("document_type", selectedDocType.value);
        formData.append("exim_createshipment_doc", attachmentsDoc);
        formData.append("document_extension", documentExtension);
        //   formData.append("customer_reference_mapping_id", customerReferenceNo);
        if (customerReferenceId) {
          formData.append("customer_reference_id", customerReferenceId);
        } else if (customerReferenceNo) {
          formData.append("customer_reference_id", customerReferenceNo);
        } else if (supplierReferenceNo) {
          formData.append("supplier_reference_id", supplierReferenceNo);
        } else if (supplierReferenceId) {
          formData.append("supplier_reference_id", supplierReferenceId);
        }
        // console.log(formData, "1290");
        if (isAttachmentsDocChanged) {
          redirectURL
            .post("/exim/saveCreateShipmentTrackingDocuments", formData)
            .then((response) => {
              if (response.data.status === "success") {
                // console.log("response.data56", response.data);
  
                document.getElementById("attachmentsDoc").value = null;
                setSelectedDocType("");
                setAttachmentTitle("");
                setDocumentExtension("");
                onClickHideAll();
                setAttachmentsDocArr((prevState) => [
                  ...prevState,
                  {
                    documentName: response.data.document_title,
                    documentType: response.data.document_type,
                    createdBy: email,
                  },
                ]);
              }
            });
        }
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Fields cannot be empty",
        });
      }
    };
    const deleteEximDocument = async (id, documentDetails) => {
      // console.log("documentDetails", documentDetails);
      // console.log("id", id);
  
      let payload = {
        documentName: documentDetails.documentName,
      };
      // console.log("payload", payload);
      await redirectURL
        .post("/exim/deleteCustomerEximDocument", payload)
        .then(async (resp) => {
          // console.log("deleteEximDocument", resp.data);
          if (resp.data.success) {
            //   await this.getEximTrackingDocuments();
            fetchTableItemsForDocs();
            setShow(true);
            setBasicType("success");
            setBasicTitle(resp.data.message);
          }
        })
        .catch((err) => {
          console.log("deleteEximDocument err", err);
        });
    };
    
    var bill_lading_date = editDetails.bill_of_lading_date;
    // console.log(editcustomer, "1509");
    // console.log(submitDisable, "submitDisable");
    const [activeIndex1, setActiveIndex1] = useState(0);
    const [activeIndex2, setActiveIndex2] = useState(1);
    const [activeIndex3, setActiveIndex3] = useState(2);
    const [activeIndex4, setActiveIndex4] = useState(3);
    const [activeIndex5, setActiveIndex5] = useState(4);
    
    const [icn1, seticn1] = useState('up');
    const [icn2, seticn2] = useState('up');
    const [icn3, seticn3] = useState('up');
    const [icn4, seticn4] = useState('up');
    const [icn5, seticn5] = useState('up');

    
  const handleItemClick1 = (index) => {
    setActiveIndex1(index === activeIndex1 ? null : index);
    if(index === activeIndex1)
    {
      var icnef1 = "down"
    }
    else{
      var icnef1 = "up"
    }
    seticn1(icnef1)
  };
  const handleItemClick2 = (index) => {
    setActiveIndex2(index === activeIndex2 ? null : index);
    if(index === activeIndex2)
    {
      var icnef1 = "down"
    }
    else{
      var icnef1 = "up"
    }
    seticn2(icnef1)
  };
  const handleItemClick3 = (index) => {
    setActiveIndex3(index === activeIndex3 ? null : index);
    if(index === activeIndex3)
    {
      var icnef3 = "down"
    }
    else{
      var icnef3 = "up"
    }
    seticn3(icnef3)

  };
  const handleItemClick4 = (index) => {
    setActiveIndex4(index === activeIndex4 ? null : index);
    if(index === activeIndex4)
    {
      var icnef4 = "down"
    }
    else{
      var icnef4 = "up"
    }
    seticn4(icnef4)
  };
  const handleItemClick5 = (index) => {
    setActiveIndex5(index === activeIndex5 ? null : index);
    if(index === activeIndex5)
    {
      var icnef5 = "down"
    }
    else{
      var icnef5 = "up"
    }
    seticn5(icnef5)
  };

  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  var cwid = screenWidth-240;
  // console.log("cwid ", cwid)
    return (
      <>
      
        <div
          className=""
          style={{ minHeight: "87vh", width: "95%", margin: "auto" }}
        >
          <div className="row">
            <div className="card custom-card" style={{ padding: "20px", borderRadius: "18px",marginLeft: "-14px",paddingLeft:"36px",paddingTop: "30px" , whiteSpace:"normal"  }}>
              <div className="card-body" style={{ padding: "0px" }}>


            
            <div key={0} className="accordion-item">
                <div
                    className={`accordion-header ${0 === activeIndex1 ? 'active' : ''}`}
                    onClick={() => handleItemClick1(0)}
                >
                    Summary
                    <span className="float-right" style={{cursor:"pointer"}} onClick={() => handleItemClick1(0)}>
                        <i className={"fa fa-chevron-"+icn1}></i>
                    </span>
                </div>
                {0 === activeIndex1 && (
                    <div className="accordion-content">
                        <div className="form-group row">
                            <div className="col-xl-2 col-lg-2">
                              <label className="custom-label">Contract Number : </label>
                            </div>
                            <div className="col-xl-10 col-lg-10">
                            {
                                (editDetails.contract_number !== "" && editDetails.contract_number !== undefined && editDetails.contract_number !== null)
                                    ? editDetails.contract_number
                                    : "NA"
                                }
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-xl-2 col-lg-2">
                              <label className="custom-label">Movement Type : </label>
                            </div>
                            <div className="col-xl-10 col-lg-10">
                            {
                                (editDetails.movement_type !== "" && editDetails.movement_type !== undefined && editDetails.movement_type !== null) != ""
                                ? editDetails.movement_type
                                : "NA"
                                }
                            </div>
                        </div>
                                
                    </div>
                )}
            
            </div>

        <div key={1} className="accordion-item">
        <div
            className={`accordion-header ${1 === activeIndex2 ? 'active' : ''}`}
            onClick={() => handleItemClick2(1)}
        >
            Shipment Details
            
            <span className="float-right" style={{cursor:"pointer"}} onClick={() => handleItemClick2(1)}>
                  <i className={"fa fa-chevron-"+icn2}></i>
              </span>
        </div>
        {1 === activeIndex2 && (
            <div className="accordion-content">

                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Cargo Mode : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       (activeItem !== "" && activeItem !== undefined && activeItem !== null)?activeItem:"NA"
                    }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Shipment Mode : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                    (shipmentActive !== "" && shipmentActive !== undefined && shipmentActive !== null)
                    ? shipmentActive.label
                    : "NA"
                    }
                    </div>
                </div>
                
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Type of Shipment : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editDetails.selected_cargo_type !== "" && editDetails.selected_cargo_type !== undefined && editDetails.selected_cargo_type !== null)
                            ? editDetails.selected_cargo_type
                            : "NA"
                        }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Shipment Created With : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editDetails.shipment_created_with !== "" && editDetails.shipment_created_with !== undefined && editDetails.shipment_created_with !== null)?
                        (editDetails.shipment_created_with === "container_no" ? "Container Number" : "BL Number"):("NA")
                    }
                    </div>
                </div>

                
                {/* {editDetails.shipment_created_with != "container_no" ? (
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Bill of Lading Number : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editDetails.bill_of_lading_no !== "" && editDetails.bill_of_lading_no !== undefined && editDetails.bill_of_lading_no !== null)
                            ? editDetails.bill_of_lading_no
                            : "NA"
                        }
                    </div>
                </div>
                 ) : (
                 <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Container Number : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                            (editDetails.container_no !== "" && editDetails.container_no !== undefined && editDetails.container_no !== null)
                                ? editDetails.container_no
                                : "NA"
                            }
                    </div>
                </div>)} */}

                  <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Bill of Lading Number : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editDetails.bill_of_lading_no !== "" && editDetails.bill_of_lading_no !== undefined && editDetails.bill_of_lading_no !== null)
                            ? editDetails.bill_of_lading_no
                            : "NA"
                        }
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">{(props.searchType=="container")?"Container Number" :"Container Number (s)"} : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {(props.containers &&props.containers.length===0) ?"NA":[...new Set(props.containers)].join(", ")}
                    </div>
                </div>


                 {/* {editDetails.shipment_created_with != "container_no" ? (
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Container(s) : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {(props.containers &&props.containers.length===0) ?"NA":[...new Set(props.containers)].join(", ")}
                    </div>
                </div>
                ):<></>} */}

                    {/* {console.log("editDetails.bill_of_lading_date ", editDetails.bill_of_lading_date)} */}
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Bill of Lading Date : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editDetails.bill_of_lading_date !== "" && editDetails.bill_of_lading_date !== undefined && editDetails.bill_of_lading_date !== null)
                            ? getHyphenDDMMYYYYHHmm(editDetails.bill_of_lading_date)
                            : "NA"
                        }
                    </div>
                </div>
                        
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Shipping Line : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editDetails.shipping_line !== "" && editDetails.shipping_line !== undefined && editDetails.shipping_line !== null)
                        ? editDetails.shipping_line
                        : "NA"
                    }
                    </div>
                </div>
                        
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Promised Transit Days : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editDetails.transit_days !== "" && editDetails.transit_days !== undefined && editDetails.transit_days !== null)
                        ? editDetails.transit_days
                        : "NA"
                    }
                    </div>
                </div>
        

            </div>
        )}
        
        </div>
        
        <div key={2} className="accordion-item">
        <div
            className={`accordion-header ${2 === activeIndex3 ? 'active' : ''}`}
            onClick={() => handleItemClick3(2)}
        >
            Stakeholder Details
            
            <span className="float-right" style={{cursor:"pointer"}} onClick={() => handleItemClick3(2)}>
                <i className={"fa fa-chevron-"+icn3}></i>
            </span>
        </div>
        {2 === activeIndex3 && (
            <div className="accordion-content">
                
                {editDetails.movement_type == "Import" ? (
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Customer : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       customer
                    }
                    </div>
                </div>
                ):("")}
                 {editDetails.movement_type == "Export" ? (
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Supplier : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       supplier
                    }
                    </div>
                </div>
                ):("")}

                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Shipment Owner : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       (editStakeholderDetail.shipment_owner !== "" && editStakeholderDetail.shipment_owner !== undefined && editStakeholderDetail.shipment_owner !== null)?
                       editStakeholderDetail.shipment_owner:
                       "NA"
                    }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Purchase Order Number : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       (editStakeholderDetail.po_no !== "" && editStakeholderDetail.po_no !== undefined && editStakeholderDetail.po_no !== null)
                       ? editStakeholderDetail.po_no
                       : "NA"
                    }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Freight Forwarder : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       (stateData.freightForwarder !== "" && stateData.freightForwarder !== undefined && stateData.freightForwarder !== null)
                       ? stateData.freightForwarder
                       : "NA"
                    }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Custom House Agent : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       (stateData.customHouseAgent !== "" && stateData.customHouseAgent !== undefined && stateData.customHouseAgent !== null)
                       ? stateData.customHouseAgent
                       : "NA"
                    }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Pickup Date : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       (pickUpDate !== "" && pickUpDate !== undefined && pickUpDate !== null) ? getHyphenDDMMYYYYHHmm(pickUpDate) : "NA"
                    }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Origin Address : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       (editStakeholderDetail.origin_port !== "" && editStakeholderDetail.origin_port !== undefined && editStakeholderDetail.origin_port !== null)
                       ? editStakeholderDetail.origin_port
                       : "NA"
                    }
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Stuffing Location : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                       (editStakeholderDetail.stuffing_location !== "" && editStakeholderDetail.stuffing_location !== undefined && editStakeholderDetail.stuffing_location !== null)
                       ? editStakeholderDetail.stuffing_location
                       : "NA"
                    }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Destination Address : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editStakeholderDetail.destination_location !== "" && editStakeholderDetail.destination_location !== undefined && editStakeholderDetail.destination_location !== null)
                        ? editStakeholderDetail.destination_location
                        : "NA"
                    }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label">Destuffing Location : </label>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                    {
                        (editStakeholderDetail.destuffing_location !== "" && editStakeholderDetail.destuffing_location !== undefined && editStakeholderDetail.destuffing_location !== null)
                        ? editStakeholderDetail.destuffing_location
                        : "NA"
                    }
                    </div>
                </div>
                {product.length > 0 ? (
                <div className="form-group row">
                    <div className="col-xl-2 col-lg-2">
                        <label className="custom-label" style={{fontWeight:"bold !important"}}>Product Details : </label>
                    </div>
                    <div className="col-xl-12 col-lg-12" style={{minHeight:"300px"}}>
                        <table width="100%" border="0" className="table envtable" cellSpacing={5}  style={{minHeight:"300px"}}>
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Selected Cargo</th>
                                    <th>Selected Team</th>
                                </tr>
                            </thead>
                            <tbody>
                            { product.map((each, inx) => {
                            let { customer_product_name, _id } = each;
                            if (customer_product_name) {
                                let customer_selected_cargo = { label: "", value: "" };

                                if (each.hasOwnProperty("customer_selected_cargo")) {
                                customer_selected_cargo = each.customer_selected_cargo;
                                }
            
                                let teamsNames = "";
                                each?.customer_selected_team?.forEach((eachTeam) => {
                                teamsNames = teamsNames + eachTeam.label + ", ";
                                });
                                return (
                                <tr key={_id}>
                                    <td>
                                        {(customer_product_name !== "" && customer_product_name !== undefined && customer_product_name !== null)?
                                        customer_product_name:"NA"}
                                    </td>
                                    <td>
                                      {(customer_selected_cargo.label !== "" && customer_selected_cargo.label !== undefined && customer_selected_cargo.label !== null)?
                                        customer_selected_cargo.label:"NA"}
                                    </td>
                                    <td>
                                        {(teamsNames !== "" && teamsNames !== undefined && teamsNames !== null)?
                                        teamsNames:"NA"}
                                    </td>
                                </tr>
                                );
                            }
                            })}
                            </tbody>
                        </table>
                    
                    </div>
                </div>
                ):""}



            </div>
        )}
        
        </div>
        
        <div key={3} className="accordion-item">
        <div
            className={`accordion-header ${3 === activeIndex4 ? 'active' : ''}`}
            onClick={() => handleItemClick4(3)}
        >
            Additional Details
            <span className="float-right" style={{cursor:"pointer"}} onClick={() => handleItemClick4(3)}>
                <i className={"fa fa-chevron-"+icn4}></i>
            </span>
        </div>
        {3 === activeIndex4 && (
            <div className="accordion-content">
                
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-3">
                        <label className="custom-label">Invoice Number : </label>
                    </div>
                    <div className="col-xl-9 col-lg-9">
                    {
                        (editAdditionalDetails.invoice_no !== "" && editAdditionalDetails.invoice_no !== undefined && editAdditionalDetails.invoice_no !== null)
                        ? editAdditionalDetails.invoice_no
                        : "NA"
                    }
                    </div>
                </div>


                <div className="form-group row">
                    <div className="col-xl-3 col-lg-3">
                        <label className="custom-label">Invoice Date : </label>
                    </div>
                    <div className="col-xl-9 col-lg-9">
                    {
                        (editAdditionalDetails.invoice_date !== "" && editAdditionalDetails.invoice_date !== undefined && editAdditionalDetails.invoice_date !== null)
                        ? getHyphenDDMMYYYYHHmm(editAdditionalDetails.invoice_date)
                        : "NA"
                    }
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-xl-3 col-lg-3">
                        <label className="custom-label">Free Trade Agreement : </label>
                    </div>
                    <div className="col-xl-9 col-lg-9">
                    {
                          (editAdditionalDetails.free_trade_agreement !== "" && editAdditionalDetails.free_trade_agreement !== undefined && editAdditionalDetails.free_trade_agreement !== null)
                          ? editAdditionalDetails.free_trade_agreement.toLocaleUpperCase()
                          : "NA"
                    }
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-xl-3 col-lg-3">
                        <label className="custom-label">Research & Development Benefit Certificate : </label>
                    </div>
                    <div className="col-xl-9 col-lg-9">
                    {
                        (editAdditionalDetails.rdb_value !== "" && editAdditionalDetails.rdb_value !== undefined && editAdditionalDetails.rdb_value !== null)
                        ? editAdditionalDetails.rdb_value.toLocaleUpperCase()
                        : "NA"
                    }
                    </div>
                </div>


                <div className="form-group row">
                    <div className="col-xl-3 col-lg-3">
                        <label className="custom-label">Export Promotion Capital Goods (EPCG) : </label>
                    </div>
                    <div className="col-xl-9 col-lg-9">
                    {
                        (editAdditionalDetails.epcg_value !== "" && editAdditionalDetails.epcg_value !== undefined && editAdditionalDetails.epcg_value !== null)
                        ? editAdditionalDetails.epcg_value.toLocaleUpperCase()
                        : "NA"
                    }
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-xl-3 col-lg-3">
                        <label className="custom-label">LC Available (Letter of Credit) : </label>
                    </div>
                    <div className="col-xl-9 col-lg-9">
                    {
                        (editAdditionalDetails.letter_of_credit !== "" && editAdditionalDetails.letter_of_credit !== undefined && editAdditionalDetails.letter_of_credit !== null)
                        ? editAdditionalDetails.letter_of_credit.toLocaleUpperCase()
                        : "NA"
                    }
                    </div>
                </div>
                {editAdditionalDetails &&
                    editAdditionalDetails.letter_of_credit === "yes" ? (
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-3">
                        <label className="custom-label"> Letter of Credit Number : </label>
                    </div>
                    <div className="col-xl-9 col-lg-9">
                    {
                        (LCNumber !== "" && LCNumber !== undefined && LCNumber !== null)?LCNumber:"NA"
                    }
                    </div>
                </div>
                    ):""}
                                    <div className="form-group row">
                    <div className="col-xl-3 col-lg-3">
                        <label className="custom-label">Incoterms : </label>
                    </div>
                    <div className="col-xl-9 col-lg-9">
                    {
                        (editAdditionalDetails.incoterm !== "" && editAdditionalDetails.incoterm !== undefined && editAdditionalDetails.incoterm !== null)
                        ? editAdditionalDetails.incoterm.label
                        : "NA"
                    }
                    </div>
                </div>

            </div>
        )}
        
        </div>


        <div key={4} className="accordion-item mwidth" style={{width:(cwid)+"px"}}>
            <div
                className={`accordion-header ${4 === activeIndex5 ? 'active' : ''}`}
                onClick={() => handleItemClick5(4)}
            >
                Detention Details
                
            <span className="float-right" style={{cursor:"pointer"}} onClick={() => handleItemClick5(4)}>
                <i className={"fa fa-chevron-"+icn5}></i>
            </span>
            </div>
                {4 === activeIndex5 && (
                    <div className="accordion-content">
                        <div className="form-group row">
                            <div className="col-xl-2 col-lg-2">
                                <label className="custom-label"> Detention Port : </label>
                            </div>
                            <div className="col-xl-10 col-lg-10">
                            {
                                 (editdemurrageDetails.demurrage_and_detention_port !== "" && editdemurrageDetails.demurrage_and_detention_port !== undefined && editdemurrageDetails.demurrage_and_detention_port !== null)
                                 ? editdemurrageDetails.demurrage_and_detention_port
                                 : "NA"
                            }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-xl-2 col-lg-2">
                                <label className="custom-label"> Detention Free Days : </label>
                            </div>
                            <div className="col-xl-10 col-lg-10">
                            {
                                (editdemurrageDetails.detention_free_days !== "" && editdemurrageDetails.detention_free_days !== undefined && editdemurrageDetails.detention_free_days !== null)
                                ? editdemurrageDetails.detention_free_days
                                : "NA"
                            }
                            </div>
                        </div>
                    </div>
                )}
        
                </div>





              </div>
            </div>
  
            <SweetAlert
              show={show}
              type={basicType}
              title={basicTitle}
              onConfirm={closeAlert}
            ></SweetAlert>
            <Modal
              open={openCustomerModel}
              onClose={onCloseCustomerModal}
              center
              classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
              }}
              animationDuration={400}
            >
              <div
                className="row"
                style={{ padding: "20px", width: "100%", zIndex: "9999" }}
              >
                <div className="col-xl-12 col-lg-12">
                <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>Edit Customer</h5>
                </div>
                <div className="add-customer-container row">
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Customer Name:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={editcustomer.customer}
                      autoComplete="off"
                      id="shipmentInput"
                      onChange={onChangeCustomerName}
                      onBlur={validateCustomerName}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">
                      Customer Reference Number:
                    </label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={editcustomer.customerReferenceId}
                      autoComplete="off"
                      id="shipmentInput"
                      isDisabled
                      // onChange={onChangeCustomerReferenceNo}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Customer Code:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={editcustomer.customer_code}
                      autoComplete="off"
                      id="shipmentInput"
                      onChange={onChangeCustomerCode}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Admin Name:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={editcustomer.admin_name_cust}
                      id="shipmentInput"
                      autoComplete="off"
                      onChange={onChangeAdminName}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Admin Email:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="email"
                      placeholder=""
                      value={editcustomer.admin_email_cust}
                      id="shipmentInput"
                      autoComplete="off"
                      onChange={onChangeAdminEmail}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Team:</label>
                    <Select
                      isDisabled={true}
                      placeholder="Team"
                      closeMenuOnSelect={true}
                      value={editcustomer.team_cust}
                      id="shipmentInput"
                      styles={customStyles}
                      onChange={onSelectTeam}
                      options={teamList}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">POC:</label>
                    <Select
                      isDisabled={true}
                      placeholder="POC"
                      closeMenuOnSelect={true}
                      value={editcustomer.poc_cust}
                      styles={customStyles}
                      id="shipmentInput"
                      onChange={onSelectPOC}
                      options={pointOfContacts}
                      // isDisabled={enablePOC}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Product:</label>
                    <Select
                      isDisabled={true}
                      type="text"
                      placeholder=""
                      value={product}
                      options={productData}
                      id="shipmentInput"
                      autoComplete="off"
                      // onChange={onChangeProduct}
                      // className="form-control"
                      styles={customStyles}
                      //required
                      // isDisabled={isSelectDisabled}
                    />
                  </div>
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ borderTop: "1px solid" }}
                >
                  <div className="modal-footer">
                    <div className="modal-action-buttons">
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onClickHideCustomerModal}
                        type="button"
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onSaveCustomerDetails}
                        type="button"
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              open={openProductModel}
              onClose={onCloseProductModal}
              style={{ width: "800px", height: "625px !important" }}
              classNames={{
                modal:
                  selectedTeamForProduct.length > 1
                    ? "increase-product-modal"
                    : "custom-product-class",
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
                }}
                animationDuration={400}
                center>
              <div
                className="row"
                style={{ padding: "20px", width: "100%", zIndex: "10000" }}
              >
                <div className="col-xl-12 col-lg-12">
                <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>Add Product</h5>
                </div>
                <div className="add-customer-container row">
                  <div className="col-xl-12 col-lg-12">
                    <label className="custom-label">Products Name:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={productName}
                      id="shipmentInput"
                      autoComplete="off"
                      onChange={onChangeProductName}
                      onBlur={validateCustomerProductName}
                      className="form-control"
                      //required
                    />
                    {errorTxtForCustomerProducts ? (
                      <span className="error-text">*Enter product name</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <label className="custom-label">HS Code:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={hsCode}
                      id="shipmentInput"
                      autoComplete="off"
                      onChange={onChangeHSCode}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <label className="custom-label">Cargo Type:</label>
                    <Select
                      isDisabled={true}
                      placeholder="Team"
                      closeMenuOnSelect={true}
                      value={selectedCargo}
                      styles={customStyles}
                      id="shipmentInput"
                      onChange={setProductCargoType}
                      options={cargoList}
                    />
                  </div>
                  <div
                    className="col-xl-12 col-lg-12"
                    style={{ marginBottom: "65px" }}
                  >
                    <label className="custom-label">Select Team:</label>
                    <Select
                      isDisabled={true}
                      placeholder="Team"
                      closeMenuOnSelect={true}
                      id="shipmentInput"
                      value={selectedTeamForProduct}
                      className={
                        selectedTeamForProduct.length > 2
                          ? "tall-select"
                          : "short-select"
                      }
                      onChange={onSelectTeamForProduct}
                      options={teamListForProduct}
                      isMulti={true}
                    />
                  </div>
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ borderTop: "1px solid" }}
                >
                  <div className="modal-footer">
                    <div className="modal-action-buttons">
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onClickHideProductModal}
                        type="button"
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onSaveProductDetails}
                        type="button"
                        disabled={disableCustomerProductSave}
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              open={openSupplierModel}
              onClose={onCloseSupplierModal}
              center
              classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
              }}
              animationDuration={400}
              >
              <div
                className="row"
                style={{ padding: "20px", width: "100%", zIndex: "100001" }}
              >
                <div className="col-xl-12 col-lg-12">
                <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>Edit Supplier</h5>
                </div>
                <div className="add-customer-container row">
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Supplier Name:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={editsupplier.supplier}
                      autoComplete="off"
                      id="shipmentInput"
                      onChange={onChangeSupplierName}
                      onBlur={onValidateSupplierName}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">
                      Supplier Reference Number:
                    </label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      id="shipmentInput"
                      value={editsupplier.supplierReferenceId}
                      autoComplete="off"
                      // isDisabled
                      // onChange={onChangeSupplierReferenceNo}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Admin Name:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={editsupplier.admin_name}
                      autoComplete="off"
                      id="shipmentInput"
                      onChange={onChangeSupplierAdminName}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Admin Email:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="email"
                      placeholder=""
                      value={editsupplier.admin_email}
                      autoComplete="off"
                      id="shipmentInput"
                      onChange={onChangeSupplierAdminEmail}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Team:</label>
                    <Select
                      isDisabled={true}
                      placeholder="Team"
                      closeMenuOnSelect={true}
                      value={editsupplier.team}
                      styles={customStyles}
                      id="shipmentInput"
                      onChange={onSelectSupplierTeam}
                      options={supplierTeamList}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">POC:</label>
                    <Select
                      isDisabled={true}
                      placeholder="POC"
                      closeMenuOnSelect={true}
                      value={editsupplier.poc}
                      id="shipmentInput"
                      styles={customStyles}
                      onChange={onSelectSupplierPOC}
                      options={supplierPointOfContacts}
                      // isDisabled={supplierEnablePOC}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label className="custom-label">Product:</label>
                    <Select
                      isDisabled={true}
                      type="text"
                      placeholder=""
                      value={supplierProduct}
                      id="shipmentInput"
                      options={supplierProductData}
                      autoComplete="off"
                      // isDisabled
                      // onChange={onChangeSupplierProduct}
                      // className="form-control"
                      styles={customStyles}
                      // isDisabled={isSupplierSelectDisabled}
                      //required
                    />
                  </div>
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ borderTop: "1px solid" }}
                >
                  <div className="modal-footer">
                    <div className="modal-action-buttons">
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onClickHideSupplierModal}
                        type="button"
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onSaveSupplierDetails}
                        disabled={isSupplierSelectDisabled}
                        type="button"
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              open={openSupplierProductModel}
              onClose={onCloseSupplierProductModal}
              style={{ width: "800px", height: "620px !important" }}
              classNames={{
                modal:
                  selectedSupplierTeamForProduct.length > 1
                    ? "increase-product-modal"
                    : "custom-product-class",
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
              }}
                animationDuration={400}
                center
            >
              <div
                className="row"
                style={{ padding: "20px", width: "100%", zIndex: "10002" }}
              >
                <div className="col-xl-12 col-lg-12">
                <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>Add Product</h5>
                </div>
                <div className="add-customer-container row">
                  <div className="col-xl-12 col-lg-12">
                    <label className="custom-label">Products Name:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={supplierProductName}
                      id="shipmentInput"
                      autoComplete="off"
                      onChange={onChangeSupplierProductName}
                      onBlur={ValidateSupplierProductName}
                      className="form-control"
                      //required
                    />
                    {errorTxtForSupplierProducts ? (
                      <span className="error-text">*Enter Product Name</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <label className="custom-label">HS Code:</label>
                    <input
                      disabled
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={supplierHSCode}
                      autoComplete="off"
                      id="shipmentInput"
                      onChange={onChangeSupplierHSCode}
                      className="form-control"
                      //required
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <label className="custom-label">Cargo Type:</label>
                    <Select
                      isDisabled={true}
                      placeholder="Team"
                      closeMenuOnSelect={true}
                      value={selectedSupplierCargo}
                      id="shipmentInput"
                      styles={customStyles}
                      onChange={setSupplierProductCargoType}
                      options={supplierCargoList}
                    />
                  </div>
                  <div
                    className="col-xl-12 col-lg-12"
                    style={{ marginBottom: "65px" }}
                  >
                    <label className="custom-label">Select Team:</label>
                    <Select
                      isDisabled={true}
                      placeholder="Team"
                      closeMenuOnSelect={true}
                      id="shipmentInput"
                      value={selectedSupplierTeamForProduct}
                      className={
                        selectedSupplierTeamForProduct.length > 2
                          ? "tall-select"
                          : "short-select"
                      }
                      onChange={onSelectSupplierTeamForProduct}
                      options={supplierTeamListForProduct}
                      isMulti={true}
                    />
                  </div>
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ borderTop: "1px solid" }}
                >
                  <div className="modal-footer">
                    <div className="modal-action-buttons">
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onClickHideSupplierProductModal}
                        type="button"
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onSaveSupplierProductDetails}
                        type="button"
                        disabled={disableSupplierProductSave}
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <div
              className={"overlay-part " + overly}
              onClick={onClickHideAll}
            ></div>
          </div>
        </div>
      </>
    );
  };
  var getHyphenDDMMYYYY = function (date_string) {
    if (date_string === "" || date_string === undefined) {
      return "";
    } else {
      return moment.parseZone(date_string).format("YYYY-MM-DD");
    }
  };
  var getHyphenDDMMYYYYHHmm = function (date_string) {
    if (date_string === "" || date_string === undefined) {
      return "";
    } else {
      return moment.parseZone(date_string).format("DD-MM-YYYY HH:mm");
    }
  };
  
  export default TrackingDetailsCard;
  