/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
// import ShowTruckConsignments from "./showtruckconsignments.js"
import RevokSaveBilling from "./checkboxtruckconsignments.js"
import Showuptimelegs from "./showgpsbillinguptimelegs.js";
import CSVFileValidator from 'csv-file-validator';
import redirectURL from "../redirectURL";
import Favourites from "../common/favouritesComponent";
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class TPTTrucksDistanceMatrix extends Component {
    state = {
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
        },
        eventAction: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        loadshow: 'show-n',
        overly: 'show-n',
        columnDefs: [],
        rowData: [],
        rowSelection: 'multiple',
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        frameworkComponents: {
            // showtruckconsignments:ShowTruckConsignments,
            RevokSaveBilling: RevokSaveBilling,
            Showuptimelegs: Showuptimelegs
        },

        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        paginationPageSize: 1000,
        geofencelist: [],
        geoFenceData: "",
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        animateRows: true,
        dept_code: "",
        pageTitle: "",
        month: "",
        year: "",
        open: false,
        showdata: "show-n",
        tpttrucks: []
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        var tptCode = localStorage.getItem('transportercode');
        // var vehicle_group = localStorage.getItem("vehicle_group");
        // if(vehicle_group != "")
        // {
        // 	var tparms = {
        // 		transporter_code:tptCode,
        // 		region:vehicle_group
        // 	}
        // 	redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
        // 	.then((response) => {
        // 	   var records = response.data.records;
        // 		// console.log("records ", records)
        // 		this.setState({
        // 			tpttrucks:records
        // 		})
        // 	})
        // }
        var month = moment.parseZone().format("MM");
        var year = moment.parseZone().format("YYYY");
        var mopts = ['January', 'February', 'March', "April", 'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December']
        var montlabel = mopts[parseInt(month) - 1]
        this.setState({
            month: { "value": month, "label": montlabel },
            year: { "value": year, "label": year }
        })

    }

    // onLoadBillingData(parameters){
    //     this.setState({
    //         loadshow:"show-m"
    //     })
    //     redirectURL.post("/consignments/tptgpsbillingtrucks",parameters)
    //     .then((response) => {
    //         // console.log("response ", response.data.devices)


    //     })
    // }


    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        this.gridApi.addEventListener('paginationChanged', (e) => {
            //Reset rows selection based on current page
            // this.resetPaginationSelection(this);
        });
        this.gridApi.forEachLeafNode((node) => {
            console.log("node here ", node)
            node.setSelected(true);

        });

    };


    onGridState = () => {
        //  console.log(this.gridApi);		
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
        //
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderUptime: ""
        });

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({
            open: false,
            revertopen: false,
            approveenopen: false,
            apprvopen: false,
            disapproveopen: false,
        });
    };


    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }



    changeFileHandler = async (e) => {
        var dt = '';

        const config = {
            headers: [
                {
                    name: 'truck_no',
                    inputName: 'truck_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }


            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                this.setState({
                    csvcontent: csvData.data
                });
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                console.log("contents ", contents)
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
            console.log("tt ", tt)
        });
        //console.log("Out ", out);

        this.setState({
            file: e.target.files[0]
        });

    }
    changeMonthHandler = month => {
        this.setState(
            { month },
            () => console.log(`Month Option selected:`, this.state.month)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    selectMonths() {
        var items = []
        var month = moment.parseZone().format("MM");
        var year = moment.parseZone().format("YYYY");
        var mopts = ['January', 'February', 'March', "April", 'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December']
        var montlabel = mopts[parseInt(month) - 1]

        // items.push({value:'',label:'Month'});
        // for(var m=0;m<month;m++)
        // {
        //     items.push({value:month,label:mopts[parseInt(m)]});
        // }

        items.push({ value: '01', label: 'January' });
        items.push({ value: '02', label: 'February' });
        items.push({ value: '03', label: 'March' });
        items.push({ value: '04', label: 'April' });
        items.push({ value: '05', label: 'May' });
        items.push({ value: '06', label: 'June' });
        items.push({ value: '07', label: 'July' });
        items.push({ value: '08', label: 'August' });
        items.push({ value: '09', label: 'September' });
        items.push({ value: '10', label: 'October' });
        items.push({ value: '11', label: 'November' });
        items.push({ value: '12', label: 'December' });
        return items
    }
    changeYearHandler = year => {
        this.setState(
            { year },
            () => console.log(`Month Option selected:`, this.state.year)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    selectYears() {
        var items = []
        items.push({ value: '', label: 'Year' });
        var cyear = moment.parseZone().format("YYYY");
        for (var y = 2021; y <= cyear; y++) {
            items.push({ value: y, label: y });
        }

        return items
    }
    onClickFilterData() {
        this.setState({
            loadshow: "show-m"
        })
        var year = this.state.year
        if (this.state.month != "") {
            var month = this.state.month.value
        }
        else {
            var month = ""
        }
        if (this.state.year != "") {
            var year = this.state.year.value
        }
        else {
            var year = ""
        }

        if (month == "" && year == "") {
            this.setState({
                show: true,
                basicTitle: "All * fields are mandatory",
                basicType: "danger"
            })
        }
        else {
            // let dpt = this.props.match.path == "/distancetravelled" ? "SNDG" : "LOG-PRT"
            let dpt = "SNDG";

            var transporter_code = localStorage.getItem("transportercode");
            console.log(transporter_code,"checl")
            var params = {
                month: month,
                year: year,
                dept_code: dpt
            }
            if (transporter_code){
                params.transporter_code = transporter_code
            } 

            const dealer = localStorage.getItem('consignee_code')
            if (dealer) {
                let dealerList = JSON.stringify(dealer.split(","))
                // console.log("dealerList",dealerList)
                params.dealerList = dealerList
            }

            redirectURL.post("/consignments/tpttruckdistancematrix", params)
                .then((response) => {
                    console.log("response ", response.data)
                    var records = Array.isArray(response.data) ? response.data : []
                    var columns = [
                        {
                            headerName: "Truck No",
                            field: "truck_no",
                            width: 140
                        },
                        {
                            headerName: "Transporter Code",
                            field: "transporter_code",
                            width: 140
                        },
                        {
                            headerName: "Transporter Name",
                            field: "transporter_name",
                            width: 160
                        }
                    ]

                    if (records.length > 0) {
                        var data = records[0];
                        console.log("Data ", data)
                        // data.map((item) => {
                        var cols = {}
                        // console.log("item ", item)
                        Object.keys(data).forEach(function (key) {
                            // console.log("Keys ", key)
                            if (key != "truck_no" && key != "transporter_name" && key != "transporter_code") {
                                //     cols.headerName = key;
                                //     cols.field = key;
                                //     cols.width = 140;

                                columns.push({
                                    headerName: key,
                                    field: key,
                                    width: 140
                                })
                            }

                        })
                        // console.log("cols ", cols)  

                        // })

                    }
                    // console.log("columns ", columns)
                    var tpttrucks = this.state.tpttrucks;
                    var tptunqrecords = []
                    if (records.length > 0) {
                        if (tpttrucks.length > 0) {
                            records.filter(f =>
                                tpttrucks.filter((d) => {
                                    if (d.truck_no == f.truck_no) {
                                        tptunqrecords.push(f)
                                    }
                                })
                            );
                        }
                        else {
                            tptunqrecords = records
                        }
                    }
                    this.setState({
                        columnDefs: columns,
                        rowData: tptunqrecords,
                        showdata: "show-m",
                        loadshow: "show-n"
                    })
                })
        }
    }
    render() {
        const { open } = this.state;

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Day Wise Distance (Monthly)</span>
                        <Favourites />
                    </h5>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-body pt-15px">
                                <form method="POST" className="row col-xl-12 col-lg-12">

                                    <div className="form-group col-xl-4 col-lg-4">

                                        <lable>Select Month *</lable>
                                        <Select placeholder={"Month"}
                                            // closeMenuOnSelect={false} 
                                            value={this.state.month}
                                            className="border-radius-0"
                                            onChange={this.changeMonthHandler.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.selectMonths()}
                                            required />

                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">

                                        <lable>Select Year *</lable>
                                        <Select placeholder={"All"}
                                            // closeMenuOnSelect={false} 
                                            value={this.state.year}
                                            className="border-radius-0"
                                            onChange={this.changeYearHandler.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.selectYears()}
                                            required />

                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">

                                        <button
                                            type="button"
                                            className="btn btn-warning mt-22p"
                                            onClick={this.onClickFilterData.bind(this)}
                                        >Submit</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className={"card " + (this.state.showdata)}>
                            <div className={"card-body pt-15px "}>
                                <div className="row">
                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            pinnedBottomRowData={this.state.tottalDistance}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>
        )
    }
} 