import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
// import UpdateButton from "./updatelmstates";
// import UpdateTransitButton from "./updatetransitbutton";
// import * as Datetime from 'react-datetime';
// import 'react-datetime/css/react-datetime.css';
// import ShowTPTGpsMap from "./showtptgpsmap";
// import DrawMap from '../common/drawmap';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import redirectURL from "../redirectURL";

import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils';
// const $ = window.$;
import Favourites from "../common/favouritesComponent";
window.jQuery = $;
window.$ = $;
var alertIcon_50 = require("../../assets/icons/accident alert_16.jpeg");
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class SafetyIncidents extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            pageTitle: "Transporter GPS Overspeed",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            radius: '15',
            rlatitude: '',
            rlongitude: '',
            latitude: '',
            longitude: '',
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            rowData: [],
            truckList: [],
            originalrowData: [],
            selectedTabLat: '',
            selectedTabLng: '',
            cluster_centroids: [],
            deftab: 'all',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            frameworkComponents: {

            },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,

            showPlant: 'show-n',
            showAddr: 'show-n',
            request_type: '',
            view_type: '',
            tabplants: [],
            clickabletab: '',
            plantlist: [],
            stateFormData: "",
            sliderStateEmailsTranslate: "",
            overly: "show-n",
            rakeslist: [],
            loadingpoints: [],
            destinationpoints: [],
            userType: "",
            pinnedRegionBottomRowData: [],
            pinnedTransitBottomRowData: [],
            stockData: [],
            totalStock: 0,
            totalSales: 0,
            totalDispatches: 0,
            railShare: 0,
            totalFySales: 0,
            totalFyDispatches: 0,
            railFyShare: 0,
            pendingForDispatchStock: 0,
            transitStock: 0,
            unloadingUpdatedDate: "",
            intransitUpdatedDate: "",
            screenView: "",
            todayInstalled: [],
            totalInstalled: [],
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            filteredData: [],
            truckNoList: [],
            showTrucksList: "show-n",
            startDate: moment.parseZone().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss'),
            endDate: moment.parseZone().format('YYYY-MM-DDTHH:mm:ss'),
            truckNos: [],
            tptcodes: [],
            mapinfo: [],
            sliderRouteTranslate: '',
            tpttrucks: [],
            truck_no: [],
            tpt_code: [],
            truck_no_option: [],
            tpt_code_option: [],
            lastElement: "",
            lastNumbers: 0,
            allData: [],
            page_no: 0,
            alrData: 0,
            total_count: [],
            // from_date: moment.parseZone().subtract(5,'days').format("YYYY-MM-DD"),
            // to_date: moment.parseZone().format("YYYY-MM-DD"),
        };
        // this.onShowTPTGPSMapRoute = this.onShowTPTGPSMapRoute.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });


    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.getApiData();

    }

    getApiData = async () => {

        var rowData = []

        // loadDateTimeScript();
        // var from_date = moment.parseZone().subtract(30,'days').format("YYYY-MM-DD")
        // var to_date = moment.parseZone().format("YYYY-MM-DD")
        // $("#fromDate").val(from_date);
        // $("#toDate").val(to_date);
        var params = {
            startdate: this.state.startDate,
            enddate: this.state.endDate
        }
        console.log("params", params)
        await redirectURL.post("/tracking/overspeedingreports", params)
            .then((response) => {
                // console.log(response,"HHHHHHHkhhhHH")
                rowData = response.data.data;
                var truckList = response.data.trucksData;
                var transportersList = response.data.transporterName;

                console.log(truckList, "truckList")
                console.log(transportersList, "transportersList")
                if (truckList != null && truckList != undefined) {
                    var trucksData = [];
                    truckList.map((e) => {
                        trucksData.push({ "label": e, "value": e })
                    })
                }

                var tptcodes = [];
                if (transportersList != null && transportersList != undefined) {
                    transportersList.map((e) => {
                        // if(tptcodes.filter(k => k.value == e.transporter_code).length == 0 )
                        // {
                        tptcodes.push({ "label": e.transporter_name, "value": e.transporter_code })
                        // }

                    })
                }


                console.log(trucksData, "trucksData")
                console.log(tptcodes, "tptcodes")
                console.log(rowData, "rowData")

                this.setState({
                    rowData,
                    truck_no_option: trucksData,
                    tpt_code_option: tptcodes

                })
            })
        console.log(rowData, "rowData")
    }

    onClickHideAll() {
        this.setState({
            overly: "show-n",
            showTrucksList: "show-n",
            sliderRouteTranslate: '',
        })
    }
    hideList() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            showTrucksList: "show-n",
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };


    onClickFilterSafetyReport = () => {

        var truckNo = this.state.truck_no.value

        var tptcode = this.state.tpt_code;
        var tpt_code = [];
        if (tptcode != null && tptcode != "null") {
            if (tptcode.length > 0) {
                tptcode.map(function (e) {
                    tpt_code.push(e.value)
                });
            }
        }


        var from_date = this.state.startDate
        var to_date = this.state.endDate

        if (from_date != '' || to_date != '' || truckNo != '' || tpt_code != '') {

            if (from_date != undefined && from_date != "" && to_date != undefined && to_date != "") {
                from_date = moment.parseZone(new Date(from_date)).utcOffset("+05:30").format("YYYY-MM-DD")
                to_date = moment.parseZone(new Date(to_date)).utcOffset("+05:30").format("YYYY-MM-DD")
            }
            var check = 0;
            if (from_date != undefined && from_date != "") {
                if (to_date == undefined || to_date == "") {
                    check = 1;
                }
            }
            if (to_date != undefined && to_date != "") {
                if (from_date == undefined || from_date == "") {
                    check = 2;
                }
            }
            if (check == 0) {
                var reqparams = {
                    // transporter_code:transporter_code,	
                    truck_no: truckNo,
                    tpt_code: tpt_code,
                    startdate: from_date,
                    enddate: to_date,
                }

                this.setState({
                    loadshow: 'show-m',
                    overly: 'show-m',
                })
                redirectURL.post("/tracking/overspeedingfilterreports", reqparams)
                    .then((response) => {
                        var records = response.data;
                        console.log(records, "records")
                        this.setState({
                            loadshow: 'show-n',
                            overly: 'show-n',
                            rowData: records
                        })

                    })
                    .catch(function (e) {
                        console.log("Error ", e)
                    })
            }

            else {
                this.setState({
                    show: true,
                    basicTitle: 'From Date and To Date are required',
                    basicType: "danger",
                    loadshow: 'show-n',
                })
            }
        }

        else {
            this.setState({
                show: true,
                basicTitle: 'Please select atleast one input',
                basicType: "danger",
                loadshow: 'show-n',
            })
        }

    }

    handleStartDateChange = (date) => {
        if (this.state.endDate && date > this.state.endDate) {
            alert("From Date cannot be greater than To Date.");
        } else {
            this.setState({ startDate: date });
        }
    }

    handleEndDateChange = (date) => {
        if (this.state.startDate && date < this.state.startDate) {
            alert("To Date cannot be less than From Date.");
        } else {
            this.setState({ endDate: date });
        }
    }

    onChangeDepartment(truckNos) {
        this.setState(
            { truckNos },
            () => console.log(`truckNos Option selected:`, this.state.truckNos)
        );
    }

    handlerFromDateTime = (event, currentDate, selectedDate) => {

        var from_date = moment(new Date(event._d)).format('YYYY-MM-DD');
        this.setState({
            from_date
        });
    }
    handlerToDateTime = (event, currentDate, selectedDate) => {
        var to_date = moment(new Date(event._d)).format('YYYY-MM-DD');

        this.setState({
            to_date
        });
    }

    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truck_no_option;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if (tpttrucks.length > 0) {
            dataset.filter(f =>
                tptunqrecords.push(f)
            );
        }
        else {
            tptunqrecords = dataset
        }
        tptunqrecords = tptunqrecords.slice(0, 100);
        this.setState({
            filteredData: tptunqrecords,
            showTrucksList: "show-m",
        })
    }

    handlerForm = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truck_no_option;
        var tptunqrecords = []
        console.log("inputval", $("#inputTruck").val())
        if (dataset.length > 0) {
            dataset.filter(f =>
                tptunqrecords.push(f)
            );
        }
        else {
            tptunqrecords = dataset
        }
        // eslint-disable-next-line array-callback-return
        var filteredResult = tptunqrecords.filter((e) => {
            if (e != "" && e != null) {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }

        });
        console.log("filteredResult ", filteredResult)
        filteredResult = filteredResult.slice(0, 100);
        this.setState({
            filteredData: filteredResult,
            showTrucksList: "show-m",
        })
    }



    render() {

        var columnWithDefs = [

            {
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.transporter_name != undefined && params.data.transporter_name != '') {
                        return params.data.transporter_name
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Start Time",
                field: "first_instance",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.first_instance != undefined && params.data.first_instance != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.first_instance)
                    }
                    else {
                        return "N/A";
                    }
                }
            },

            {
                headerName: "End Time",
                field: "recent_instance",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.recent_instance != undefined && params.data.recent_instance != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.recent_instance)
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            // {
            // 	headerName:"Duration (HH:MM)",
            // 	field:"duration",
            // 	width:140,
            // 	filter: true,
            //     resizable: true,
            // 	sortable:true,
            //     valueGetter:function(params){
            // 		try{
            // 			if(params.data.first_instance != "" && params.data.first_instance != undefined && params.data.recent_instance != "" && params.data.recent_instance != undefined)
            // 			{
            //                 var firstinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(params.data.first_instance))).utcOffset("+05:30")._d
            //                 var recentinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(params.data.recent_instance))).utcOffset("+05:30")._d
            //                 var data = (recentinstance - firstinstance)
            // 				return secondsToHM(data/1000);
            // 			}
            // 			else{
            // 				return "";
            // 			}
            // 		}
            // 		catch(e){
            // 			return "";
            // 		}
            // 	}
            // },
            {
                headerName: "Speed (mile/h)",
                field: "speed",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.speed != undefined && params.data.speed != '') {
                        return params.data.speed
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Start Address",
                field: "first_instance_address",
                width: 240,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.first_instance_address != undefined && params.data.first_instance_address != '') {
                        return params.data.first_instance_address
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "End Address",
                field: "recent_instance_address",
                width: 240,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.recent_instance_address != undefined && params.data.recent_instance_address != '') {
                        return params.data.first_instance_address
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Start Address Lat",
                field: "first_instance_lat",
                width: 140,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    console.log(params.data.first_lat, "params.data.first_lat")
                    if (params.data.first_instance_lat != undefined && params.data.first_instance_lat != '') {
                        return (params.data.first_instance_lat.toFixed(5))
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Start Address Lng",
                field: "first_instance_lng",
                width: 140,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.first_instance_lng != undefined && params.data.first_instance_lng != '') {
                        return (params.data.first_instance_lng.toFixed(5))
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "End Address Lat",
                field: "recent_instance_lat",
                width: 140,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.recent_instance_lat != undefined && params.data.recent_instance_lat != '') {
                        return (params.data.recent_instance_lat.toFixed(5))
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "End Address Lng",
                field: "recent_instance_lng",
                width: 140,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.recent_instance_lng != undefined && params.data.recent_instance_lng != '') {
                        return (params.data.recent_instance_lng.toFixed(5))
                    }
                    else {
                        return "N/A";
                    }
                }
            },
        ];
        var listData = []
        // eslint-disable-next-line array-callback-return
        this.state.filteredData.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        return (

            <div className={(this.state.screenView == "Mobile") ? "" : "container-fluid"} style={(this.state.screenView == "Mobile") ? { marginTop: "2em" } : {}}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i class="icofont icofont-vehicle-delivery-van cus-i"></i>&nbsp;
                        Overspeed Report
                        <Favourites />
                    </h5>
                </div>
                <div className="card">
                    <div className={(this.state.screenView == "Mobile") ? "card-body" : "card-body col-xl-12 col-lg-12"}>
                        <div className={"trucks-dropdown " + this.state.showTrucksList} style={{ top: "94px" }}>
                            <ul>
                                {listData}
                            </ul>
                        </div>
                        <div class="row">
                            <div class={" col-xl-3 col-lg-3 form-group"} style={{ zIndex: "8" }}>
                                <label>Truck</label>
                                <Select
                                    placeholder={"Truck No"}
                                    closeMenuOnSelect={true}
                                    onChange={(e) => { this.setState({ truck_no: e }) }}
                                    className={"border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    value={this.state.truck_no}
                                    options={this.state.truck_no_option} />
                            </div>
                            <div class={" col-xl-3 col-lg-3 form-group"} style={{ zIndex: "8" }}>
                                <label>Transporter </label>
                                <Select
                                    placeholder={"Transporter"}
                                    closeMenuOnSelect={false}
                                    isMulti={true}
                                    onChange={(e) => { this.setState({ tpt_code: e }) }}
                                    className={"border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.tpt_code_option} />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                <label>From Date</label>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    className="form-control"
                                    // id="ndfromDate"
                                    autoComplete="off"
                                    placeholderText="From date"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                <label>To Date</label>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    className="form-control"
                                    // id="ndtoDate"
                                    autoComplete="off"
                                    placeholderText="To date"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group" style={{ display: "flex", alignItems: "center" }}>
                                <button type="submit" onClick={this.onClickFilterSafetyReport.bind(this)} class="btn btn-success mt-25p">Submit</button>
                            </div>
                        </div>
                        {/* <p>Note* : Data can be fetched only for 5 days window</p> */}
                        <div id="myGrid" style={{ width: "100%", height: "460px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                columnDefs={columnWithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={1000}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this },
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                        {/* <p className="float-right" style = {{fontWeight:"bold"}}> Showing Rows {this.state.alrData+" of "+this.state.total_data} </p>
                        <div className="row col-xl-12"  style ={{marginTop:"2%"}}>
                            <div className="col-lg-6">
                                {this.state.page_no > 0 ?
                                <button type="submit" onClick={this.onClickPreviousData.bind(this)} className="btn btn-success">Previous</button>
                                :""}
                            </div>
                            <div className="col-lg-6">
                                {this.state.rowData.length >= 1000?
                                <button type="submit" onClick={this.onClickNextData.bind(this)} className="btn btn-success float-right">Next</button>
                                :""}
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                {this.state.sliderRouteTranslate != '' ?
                    <div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
                        <div className="slide-r-title">
                            <h4>
                                View Route
                                <span className="float-right closebtn" style={{ marginRight: "25px" }}
                                    onClick={this.onClickHideAll.bind(this)} >X</span>

                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>

                            {/* <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap 
                                    context={this} 
                                    mapFor={"truck"} 
                                    mapinfo={this.state.mapinfo}  />
							</div> */}
                        </div>
                    </div>
                    : ""}

            </div>

        );
    }
}


// eslint-disable-next-line no-unused-vars
function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {

    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'Y-m-d',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

$(document).on("change", "#ndtoDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#ndfromDate").val();
    var cargoDate = $("#ndtoDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime, endTime);
    if (startTime > endTime) {
        $("#ndtoDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#ndfromDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#ndfromDate").val();
    var cargoDate = $("#ndtoDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    if (startTime > endTime) {
        $("#ndfromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function DateEditor() { }

DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

DateEditor.prototype.getGui = function () {
    return this.eInput;
};

DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};

DateEditor.prototype.destroy = function () {

};

DateEditor.prototype.isPopup = function () {
    return false;
};

function NumericCellEditor() {
}

NumericCellEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};


NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

NumericCellEditor.prototype.destroy = function () {
};

NumericCellEditor.prototype.isPopup = function () {
    return false;
};


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
function secondsToHM(seconds) {
    seconds = Number(seconds);


    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh * 3600));
    var mm = Math.floor(hrr / 60)
    if (mm < 10) {
        var mins = "0" + mm;
    }
    else {
        var mins = mm;
    }

    if (hh < 10) {
        var hrs = "0" + hh;
    }
    else {
        var hrs = hh;
    }
    return hrs + ":" + mins;
}