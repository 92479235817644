const TripDeviation=(props)=>{
    const handleClick = (params) => {
        props.onShowTripDeviationData(props);
    };
    let color = "#cccc"
    if (props.getValue() != undefined && props.getValue() == 1) {
        color = "#ab8ce4"
    }
    return(
        <a onClick={handleClick}  title="Trip Deviation"><i className="fa fa-random f20" style={{ color: color }}></i> </a>
    )
}
export default TripDeviation