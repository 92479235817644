import React, { useEffect, useState } from "react";
import UploadIcon from "../../assets/icons/upload.png";
import UploadWhiteIcon from "../../assets/icons/uploadWhite.png";
import DocumentTile from "./DocumentTile";
import Modal from "react-responsive-modal";
import Select from "react-select";
import documentIcon from "../../assets/icons/downloadIcon.png";
import $ from "jquery";
import redirectURL from "../redirectURL";

const DocumentsCard = (props) => {
  console.log("documentCard", props);
  const [state, setState] = useState({
    uploadIconStat: false,
    openDocumentsModal: false,
    uploadedFile: "",
    uploadedFileName: "",
    documentExtension: "",
    email: localStorage.getItem("email"),
    uploadDocumentType: "",
  });

  const closeModal = () => {
    setState({
      ...state,
      openDocumentsModal: false,
    });
  };

  // useEffect(() => {}, [documentDetails]);

  const changeUploadImage = (flag) => {
    setState({
      ...state,
      uploadIconStat: flag,
    });
  };

  const [fileName, setFileName] = useState("");

  const previewCard = (document_type, document_url) => {
    props.previewCard(document_type, document_url);
  };

  const filterDataOnDelete = (residueData) => {
    // console.log("documentDetails res", residueData);
    // props.uploadedDocx = residueData;
    props.updateDocxCount(residueData.length);
    props.updateDocumentsDataFn(residueData)
  };

  useEffect(() => {
    props.updateDocxCount(props.uploadedDocx.length);
  }, [props]);

  const uploadDocuments = () => {
    setState({
      ...state,
      openDocumentsModal: true,
    });
  };

  const docUploadBtnHandler = () => {
    document.getElementById("uploadFile").click();
  };

  const onChangeDocxUpload = (e) => {
    const file = e.target.files[0];
    let validFileExtensions = [
      "png",
      "jpeg",
      "gif",
      "bmp",
      "pdf",
      "csv",
      "xlsx",
      "mp4",
      "webm",
      "mp3",
    ];
    let { name, type } = file;
    const fileExtension = name.split(".").pop();
    if (validFileExtensions.includes(fileExtension)) {
      setState({
        ...state,
        uploadedFile: file,
        uploadedFileName: name,
        documentExtension: fileExtension,
      });
    } else {
      clearDocxDetails();
    }
    setFileName(name);
  };

  const handleAddAttachmentsBtn = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("created_by", state.email);
    formData.append("document_title", state.uploadedFileName);
    formData.append("document_type", state.uploadDocumentType.value); //have to handle that dropdown
    formData.append("exim_createshipment_doc", state.uploadedFile);
    formData.append("document_extension", state.documentExtension);
    formData.append("shipment_unique_id", props.shipment_unique_id);
    formData.append("customer_reference_id", "");
    formData.append("supplier_reference_id", "");
    redirectURL
      .post("/exim/saveCreateShipmentTrackingDocuments", formData)
      .then((response) => {
        if (response.data.status === "success") {
          let {
            // document_title,
            // exim_file_url,
            // document_type,
            // created_at,
            // document_extension,
            uploadedData,
          } = response.data;

          // setdocumentDetails([addedDocx,...documentDetails])
          props.uploadedDocx.unshift(...uploadedData);
          props.updateDocxCount(props.uploadedDocx.length + 1);
          clearDocxDetails();
          closeModal();
        }
      });
  };

  const clearDocxDetails = () => {
    setFileName("");
    document.getElementById("uploadFile");
    $("#uploadFile").val(null);
    setState({
      ...state,
      uploadedFile: "",
      uploadedFileName: "",
      documentExtension: "",
      uploadDocumentType: "",
    });
  };

  const handleFilterOptions = (selOption, dropdownName) => {
    // console.log("selOption", selOption);
    // console.log("dropdownName", dropdownName.name);
    setState({
      ...state,
      [dropdownName.name]: selOption,
    });
  };

  return (
    <div>
      <Modal open={state.openDocumentsModal} onClose={closeModal}
      center
      classNames={{
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationIn: 'customEnterModalAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      animationDuration={400}
      >
        <div className="" style={{ height: "427px" }}>
          {/* <h6 className="modal-heading">
            Are you sure to want to close this tracking ?
          </h6> */}
          <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>
            Document Type
            </h5>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-6 fileType" style={{ paddingLeft: "31px" }}>
              <Select
                closeMenuOnSelect={true}
                name="uploadDocumentType"
                className="border-radius-0"
                options={[
                  { value: "MSDS", label: "MSDS" },
                  { value: "Packing List", label: "Packing List" },
                  { value: "Custom Invoice", label: "Custom Invoice" },
                  { value: "Insurance Company", label: "Insurance Company" },
                  {
                    value: "Pickup Request Form",
                    label: "Pickup Request Form",
                  },
                  { value: "Custom Invoice/PL", label: "Custom Invoice/PL" },
                  { value: "Others", label: "Others" },
                  { value: "DO", label: "DO" },
                  { value: "Attachment", label: "Attachment" },
                ]}
                value={state.uploadDocumentType}
                onChange={handleFilterOptions}
                required
              />
            </div>

            <div
              className="col-4"
              style={{ position: "relative", top: "-32px" }}
            >
              <input
                type="file"
                name="uploadFile"
                id="uploadFile"
                style={{ visibility: "hidden" }}
                onChange={onChangeDocxUpload}
              />
              {!fileName ? (
                <button
                  className="btn"
                  style={{
                    border: "1px solid #3e64ff",
                    padding: "9.5px 30px",
                    outline: "none",
                  }}
                  onClick={docUploadBtnHandler}
                >
                  <img src={documentIcon} alt="" width="22px" />
                  <span>Browse</span>
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{
                    border: "1px solid #3e64ff",
                    padding: "9.5px 30px",
                    outline: "none",
                  }}
                  onClick={handleAddAttachmentsBtn}
                  disabled={
                    state.uploadDocumentType && fileName ? null : "disabled"
                  }
                >
                  <img
                    src={UploadWhiteIcon}
                    alt=""
                    width="15px"
                    height={"15px"}
                  />{" "}
                  <span>Upload</span>
                </button>
              )}
              {/* <div className="uploadArea">
                <div className="document__name__container">
                  <h4 className="documentHeading">Document Details</h4>
                  <input
                    type="text"
                    name="filename"
                    id="filename"
                    placeholder="Enter Document Name"
                  />
                </div>
                <div
                  className="upload__button upload"
                  onClick={docUploadBtnHandler}
                >
                  <img src={documentIcon} alt="" />
                  <h6>Upload Doc</h6>
                </div>
                <input
                  type="file"
                  name="file"
                  id="document-upload"
                  onChange={onChangeDocxUpload}
                />
              </div> */}
            </div>
          </div>
          {fileName ? (
            <span className="p-2 ml-3 file_name">
              {fileName}{" "}
              <span className="cursor-class pl-3" onClick={clearDocxDetails}>
                &#10006;
              </span>
            </span>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <div className="justify-content-end mx-3 row">
        <button
          className="btn btn-outline-primary"
          onMouseEnter={() => {
            changeUploadImage(true);
          }}
          onMouseLeave={() => {
            changeUploadImage(false);
          }}
          onClick={uploadDocuments}
        >
          <img
            src={state.uploadIconStat ? UploadWhiteIcon : UploadIcon}
            alt=""
            width="15px"
            height={"15px"}
          />{" "}
          Upload
        </button>
      </div>
      <div
        className="align-content-center justify-content-around mt-3 mx-3 row"
        style={{ height: "50px", background: "#e4edfc", borderRadius: "10px" }}
      >
        <div className="col-3">
          <label for="">Document Type</label>
        </div>
        <div className="col-3">
          <label for="">Attachment</label>
        </div>
        <div className="col-4">
          <label for="">Uploaded On</label>
        </div>
        <div className="col-2">
          <label for="">Actions</label>
        </div>
      </div>
      {(!props.uploadedDocx || props.uploadedDocx.length === 0 || props.uploadedDocx===undefined)?<></>:<>
      {props.uploadedDocx.map((each) => {
        return (
          <React.Fragment key={each._id}>
            <DocumentTile
              documentDetails={each}
              previewCard={previewCard}
              // deleteDocument={deleteDocument}
              uploadedDocx={props.uploadedDocx}
              filterDataOnDelete={filterDataOnDelete}
            />
          </React.Fragment>
        );
      })}
      </>}
    </div>
  );
};

export default DocumentsCard;
