import React, { Component } from 'react';

import redirectURL from '../../redirectURL';
// Import authService

class Notification extends Component {
    constructor(props) {
        super(props);
      
    }
   onClickRefrsh(){
    this.props.context.loadFavourites();
   }
   removeFavItem(oItem){
    // console.log("oItem", oItem)
        var params ={
            user_email:localStorage.getItem("email"),
            page_path:oItem
        }
        // console.log("params ", params)
        redirectURL.post("app/deleteFavouriteItem", params)
        .then((response) => {

            this.props.context.loadFavourites();
        })
   }
    render() {

        return (
            <li className="onhover-dropdown">
                <a className="txt-dark">
                    {/* <img className="align-self-center pull-right mr-2" src={require("../../../assets/images/dashboard/notification.png")} alt="header-notification" /> */}
                    <i className="fa fa-star f20"></i>
                    {/* <span className="badge badge-pill badge-primary notification">3</span> */}
                </a>
                <ul className="notification-dropdown onhover-show-div">
                    <li className="myfavtitle">Favourites
                        <a onClick={this.onClickRefrsh.bind(this)} className="badge badge-pill badge-success text-white text-uppercase pull-right" style={{background:"#000"}}>
                            <i className="fa fa-refresh f12"></i></a>
                    </li>
                    {(this.props.pages.length > 0)?
                    this.props.pages.map((itm) => 
                    <li>
                        <div className="media favic">
                            <i className="align-self-center notification-icon icofont icofont-file"></i>
                            <div className="media-body">
                                <h6 className="mt-0">
                                    <a href={itm.page_path}>{itm.screen_title}</a>
                                    <button className="btn btn-default float-right f10" style={{background:"transparent"}} type="button" onClick={this.removeFavItem.bind(this,itm.page_path)}><i className="f12 fa fa-trash"></i> </button>
                                </h6>
                                
                            </div>
                        </div>
                    </li>
                    )
                    :
                    <li style={{height:"100px"}}>
                        No favourites found.
                    </li>
                    
                    }
                    {/* <li>
                        <div className="media">
                            <i className="align-self-center notification-icon icofont icofont-shopping-cart bg-primary"></i>
                            <div className="media-body">
                                <h6 className="mt-0">Your order ready for Ship..!</h6>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                <span><i className="icofont icofont-clock-time p-r-5"></i>Just Now</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <i className="align-self-center notification-icon icofont icofont-download-alt bg-success"></i>
                            <div className="media-body">
                                <h6 className="mt-0 txt-success">Download Complete</h6>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                <span><i className="icofont icofont-clock-time p-r-5"></i>5 minutes ago</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <i className="align-self-center notification-icon icofont icofont-recycle bg-danger"></i>
                            <div className="media-body">
                                <h6 className="mt-0 txt-danger">250 MB trush files</h6>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                <span><i className="icofont icofont-clock-time p-r-5"></i>25 minutes ago</span>
                            </div>
                        </div>
                    </li>
                    <li className="text-center">You have Check <a href="#">all</a> notification  </li> */}
                </ul>
            </li>
        );
    }
}

export default Notification