import { React, useState, useEffect, useRef } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import MainTrackingCounters from "./mainCounters";
import CreateShipmentIcon from "../../assets/icons/create-shipment-icon.svg";
import moment from "moment";
import BulkUploadIcon from "../../assets/icons/bulk-upload-icon.svg";
import SearchIcon from "../../assets/icons/search-icon.svg";
import FilterIcon from "../../assets/icons/filter-icon.svg";
import SortIcon from "../../assets/icons/sort-icon.svg";
import GridViewActive from "../../assets/icons/grid-view-white.svg";
import GridViewInactive from "../../assets/icons/grid-view-blue.svg";
import TileViewActive from "../../assets/icons/tile-view-white.svg";
import TileViewInactive from "../../assets/icons/tile-view-blue.svg";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import TrackingTile from "./trackingTile";
import redirectURL from "../redirectURL";
import EximGrid from "./EximGrid";
import Modal from "react-responsive-modal";
import Select from "react-select";
import $ from "jquery";
import { countries } from "country-flags-svg";
// import { mainEvents } from "./constants";
// import Datetime from "react-datetime";
// import "react-datetime/css/react-datetime.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapLoader from "./BootstrapLoader";
import "./tracking.css";
import { Tooltip } from "react-tooltip";
import CargoShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import CargoShipWhite from "../../assets/icons/cargo-ship-white.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import LocationWhite from "../../assets/icons/location-white.svg";
import ShipmentBlue from "../../assets/icons/shipment-blue.svg";
import ShipmentWhite from "../../assets/icons/shipment-white.svg";
import EmissionCloud from "../../assets/icons/co2-1.svg";
import InfoIcon from "../../assets/icons/info.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import { IoSettingsOutline } from "react-icons/io5";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import FlagWhite from "../../assets/icons/flag-white.svg";
import { FaGlobe } from "react-icons/fa";
import { AiFillApi } from "react-icons/ai";
import Favourites from "../common/favouritesComponent";

const ContainerTracking = () => {
  const customStyles = {
    modal: {
      width: "600px", // Set your desired width here
      overflow: "visible",
    },
  };
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openSubCounterChoise, setOpenSubCounterChoise] = useState(false);
  const [tabActiveSCnt, settabActiveSCnt] = useState("0");
  const [tabActive, setTabActive] = useState("BL/Booking");
  const [bl_data_Temission, setBlTemission] = useState("");
  const [container_data_Temission, setContainerTemission] = useState("");
  let emissionIcon = EmissionCloud;
  const [ULIPData, setULIPData] = useState([]);
  const [apiProvider, setAiProvider] = useState([]);
  const [gridLoader, setGridLoader] = useState(false);

  const counters = useRef([
    {
      id: 1,
      title: "Origin",
      imgSrc: LocationBlue,
      activeImgSrc: LocationWhite,
      subCounters: [
        { label: "In-Transit to Consignor", value: "in_transit_to_consignee" },
        { label: "At Consignor", value: "at_consignee" },
        {
          label: "In-transit to first port",
          value: "in_transit_to_first_port",
        },
        { label: "At Port(other than POL)", value: "at_port" },
        { label: "In-transit at POL", value: "in_transit_to_pol" },
        { label: "At POL", value: "at_pol" },
      ],
    },
    {
      id: 2,
      title: "In Transit",
      imgSrc: ShipmentBlue,
      activeImgSrc: ShipmentWhite,
      subCounters: [
        { label: "High Seas", value: "high_seas" },
        { label: "At Transhipment Port", value: "at_transshipment" },
        // {label:"At POD",value:""},
        // {label:"In-Transit to Final Destination",value:""}
      ],
    },
    {
      id: 3,
      title: "Destination",
      imgSrc: FlagBlue,
      activeImgSrc: FlagWhite,
      subCounters: [
        { label: "At POD", value: "at_pod" },
        {
          label: "In-Transit to Final Port",
          value: "in_transit_to_final_port",
        },
        { label: "At Final Port", value: "at_final_port" },
        { label: "Out For Delivery", value: "out_for_final_delivery" },
        { label: "At Customer", value: "at_customer" },
      ],
    },
    {
      id: 4,
      title: "ULIP",
      imgSrc: FlagBlue,
      activeImgSrc: FlagWhite,
      subCounters: [
        { label: "LDB", value: "LDB" },
        { label: "FOIS", value: "FOIS" },
        { label: "ICEGATE", value: "ICEGATE" },
        { label: "PCS", value: "PCS" },
      ],
    },
    {
      id: 5,
      title: "API",
      imgSrc: FlagBlue,
      activeImgSrc: FlagWhite,
      subCounters: [
        { label: "Searates", value: "searates" },
        { label: "Cargoes", value: "cargoes" },
      ],
    },
  ]);
  const sub_counter = [
    "in_transit_to_consignee",
    "at_consignee",
    "in_transit_to_first_port",
    "at_port",
    "in_transit_to_pol",
    "at_pol",
    "high_seas",
    "at_transshipment",
    "at_pod",
    "in_transit_to_final_port",
    "at_final_port",
    "out_for_final_delivery",
    "at_customer",
  ];
  const subcountersCheckbox = useRef([
    "in_transit_to_consignee",
    "at_consignee",
    "in_transit_to_first_port",
    "at_port",
    "in_transit_to_pol",
    "at_pol",
    "high_seas",
    "at_transshipment",
    "at_pod",
    "in_transit_to_final_port",
    "at_final_port",
    "out_for_final_delivery",
    "at_customer",
    ...ULIPData,
    ...apiProvider,
  ]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([
    "in_transit_to_consignee",
    "at_consignee",
    "in_transit_to_first_port",
    "at_port",
    "in_transit_to_pol",
    "at_pol",
    "high_seas",
    "at_transshipment",
    "at_pod",
    "in_transit_to_final_port",
    "at_final_port",
    "out_for_final_delivery",
    "at_customer",
    ...ULIPData,
    ...apiProvider,
  ]);
  const [subCounters, setSubCounters] = useState(counters.current[0]);
  const [state, setState] = useState({
    showLoader: false,
    bl_new_shipments: [],
    bl_untrackable: [],
    container_new_shipments: [],
    container_untrackable: [],
    containerShippingLines: [],
    blShippingLines: [],
    shippingLines: [],
    shippingLine: "",
    tempContainerData: [],
    filterText: "",
    trackingData: [],
    container_data: [],
    all_container_data: [],
    all_bl_data: [],
    bl_data: [],
    cummulativeBalEmf: "",
    balContainerEmf: "",
    cummulativeContainerEmf: "",
    containerEmf: "",
    isTileView: true,
    filterModeType: { value: "bl_number", label: "BL/Booking" },
    modeOptions: [
      { value: "bl_number", label: "BL/Booking" },
      { value: "container_number", label: "Container" },
      { value: "invoice_no", label: "Invoice" },
      { value: "po_no", label: "PO" },
    ],
    searchInput: "",
    tilesPerPage: { value: "100", label: "ALL" },
    tilesPerPageOptions: [
      { value: "100", label: "ALL" },
      { value: "10", label: "10" },
      { value: "20", label: "20" },
      { value: "30", label: "30" },
      { value: "40", label: "40" },
      { value: "50", label: "50" },
      { value: "60", label: "60" },
    ],
    reportType: "",
    reportTypeOptions: [
      { value: "PDF", label: "PDF" },
      { value: "XLSX", label: "XLSX" },
    ],
    showSortCard: "show-n",
    showFilterCard: "show-n",
    rowData: [],
    originRegionOptions: [],
    originRegion: "",
    destinationRegionOptions: [],
    destinationRegion: "",
    container_org_options: [],
    container_dest_options: [],
    bl_org_options: [],
    bl_dest_options: [],
    overly: "show-n",
    documentsTranslate: "",
    eventName: "all",
    delayed_bl_data: {
      count: 0,
      data: [],
    },
    delayed_containers_data: {
      count: 0,
      data: [],
    },
    bl_detention_data: {
      count: 0,
      containers: [],
    },
    container_detention_data: {
      count: 0,
      containers: [],
    },
  });

  const [sortCardClass, setSortCardClass] = useState("sortCardHeight_all");

  const openFilterModal2 = () => {
    setOpenFilterModal(true);
  };
  const handleCheckboxChange = (option) => {
    if (checkedCheckboxes.includes(option)) {
      setCheckedCheckboxes(checkedCheckboxes.filter((item) => item !== option));
    } else {
      setCheckedCheckboxes([...checkedCheckboxes, option]);
    }
  };
  const applySubCounters = async (e) => {
    subcountersCheckbox.current = [...checkedCheckboxes];
    const counters = new Array();
    sub_counter.forEach((each) => {
      if (subcountersCheckbox.current.includes(each)) {
        counters.push(each);
      }
    });
    await redirectURL
      .post("/exim/saveSubCounterOptions", {
        email: localStorage.getItem("email"),
        counters: counters,
      })
      .then((resp) => {
        if (resp.data.status === "success") {
          console.log(resp.data.message);
        }
      })
      .catch((e) => {
        console.log(
          "error message while updating customer reference for product",
          e
        );
      });
    setOpenSubCounterChoise(false);
  };
  const closeModal2 = () => {
    setOpenSubCounterChoise(false);
  };

  const closeModal = () => {
    clearAllFilters();
    if (tabActive === "BL/Booking") {
      // let blData = state.all_bl_data;
      setState({
        ...state,
        // bl_data: blData,
        destinationRegion: "",
        originRegion: "",
      });
      setOpenFilterModal(false);
      setFromDate("");
      setToDate("");
      setMovementType("");
    } else {
      // let containerData = state.all_container_data;
      setState({
        ...state,
        // container_data: containerData,
        destinationRegion: "",
        originRegion: "",
      });
      setOpenFilterModal(false);
      setFromDate("");
      setToDate("");
      setMovementType("");
    }
  };

  const getCountryName = (isoCode) => {
    let countryName = "";
    countries.forEach((each) => {
      if (each.iso2 === isoCode) {
        countryName = each.name;
      }
    });
    return countryName;
  };

  const [mainCounter, setMainCounters] = useState({
    all: 0,
    origin: 0,
    in_transit: 0,
    destination: 0,
    completed: 0,
    delayed: 0,
    detained: 0,
  });

  const [containerCounters, setContainerCounters] = useState({
    all: 0,
    origin: 0,
    in_transit: 0,
    destination: 0,
    completed: 0,
    delayed: 0,
    detained: 0,
  });

  const [movementType, setMovementType] = useState("");

  const handleOptionChange = (event) => {
    setMovementType(event.target.value);
  };

  const [filterTypes, setFilterTypes] = useState({
    shippingdate: "show-m",
    originregion: "show-m",
    destinationregion: "show-m",
    shippingline: "show-m",
    movementtype: "show-m",
  });
  const selectRef = useRef(null);
  const selectRef1 = useRef(null);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMenuOpen1, setMenuOpen1] = useState(false);
  const [createShipment, setCreateShipment] = useState(true);

  useEffect(() => {
    // console.log(movementType);
    // if (state.filterText) {
    // }
    let email = localStorage.getItem("email");
    redirectURL
      .post("exim/getCurrentContainers", { email: email })
      .then((resp) => {
        if (resp.data.container_data?.length >= 15) {
          setCreateShipment(false);
        }
      })
      .catch((err) => console.log(err));
    let filters = [
      "shippingdate",
      "originregion",
      "destinationregion",
      "shippingline",
      "movementtype",
    ];
    let updateFilters = {};
    filters.forEach((each) => {
      if (each.includes(state.filterText.toLowerCase().split(" ").join(""))) {
        updateFilters[each] = "show-m";
      } else {
        updateFilters[each] = "show-n";
      }
    });
    setFilterTypes(updateFilters);
    document.addEventListener("mousedown", handleClickOutside);
  }, [state.filterText]);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      // Clicked outside the select component
      setMenuOpen(false);
    }

    if (selectRef1.current && !selectRef1.current.contains(event.target)) {
      // Clicked outside the select component
      setMenuOpen1(false);
    }
  };

  const handleMenuOpen = () => {
    // console.log("SA")
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen1 = () => {
    // console.log("DD")
    setMenuOpen1(true);
  };

  const handleMenuClose1 = () => {
    setMenuOpen1(false);
  };

  const filterDataFromFilters = () => {
    let mainCounter = localStorage.getItem("current_counter");
    let subCounter = localStorage.getItem("sub_counter");

    setOpenFilterModal(false);
    if (tabActive === "BL/Booking") {
      let blData;
      if (subCounter === "")
        blData = getfilterDataByCounter("main", mainCounter);
      else blData = getfilterDataByCounter("sub", subCounter);

      let filteredData = [];

      //Movement Type
      if (movementType) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (each.movement_type && each.movement_type === movementType) {
              return each;
            }
          });
        } else {
          filteredData = blData.filter((each) => {
            if (each.movement_type && each.movement_type === movementType) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            bl_data: filteredData,
          });
          return;
        }
      }

      //if only fromDate and no toDate then filter all from or after created_at:"2023-11-15T05:12:56.460Z"
      if (fromDate && !toDate) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        } else {
          filteredData = blData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            bl_data: filteredData,
          });
          return;
        }
      }

      //if only toDate and no fromDate then filter all before or equal to created_at:"2023-11-15T05:12:56.460Z"
      if (!fromDate && toDate) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        } else {
          filteredData = blData.filter((each) => {
            if (
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            bl_data: filteredData,
          });
          return;
        }
      }

      // fromDate <= created_at <= toDate
      if (fromDate && toDate) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.created_at)) &&
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        } else {
          filteredData = blData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.created_at)) &&
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            bl_data: filteredData,
          });
          return;
        }
      }

      //Origin Region
      if (state.originRegion && state.originRegion.hasOwnProperty("value")) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              each.loading_port_code.slice(0, 2) === state.originRegion.value
            ) {
              return each;
            }
          });
        } else {
          filteredData = blData.filter((each) => {
            if (
              each.loading_port_code.slice(0, 2) === state.originRegion.value
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            bl_data: filteredData,
          });
          return;
        }
      }

      //Destination Region
      if (
        state.destinationRegion &&
        state.destinationRegion.hasOwnProperty("value")
      ) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              each.discharge_port_code.slice(0, 2) ===
              state.destinationRegion.value
            ) {
              return each;
            }
          });
        } else {
          filteredData = blData.filter((each) => {
            if (
              each.discharge_port_code.slice(0, 2) ===
              state.destinationRegion.value
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            bl_data: filteredData,
          });
          return;
        }
      }

      //Shipping Line
      if (state.shippingLine && state.shippingLine.hasOwnProperty("value")) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (each.shipping_line === state.shippingLine.value) {
              return each;
            }
          });
        } else {
          filteredData = blData.filter((each) => {
            if (each.shipping_line === state.shippingLine.value) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            bl_data: filteredData,
          });
          return;
        }
      }
      setState({
        ...state,
        //bl_data: filteredData.slice(0, tileCount),
        bl_data: filteredData,
      });
      // await showFilterCard();
    } else {
      let containerData;

      if (subCounter === "")
        containerData = getfilterDataByCounter("main", mainCounter);
      else containerData = getfilterDataByCounter("sub", subCounter);
      let filteredData = [];

      // if((fromDate != "" && state.originRegion == "" && state.destinationRegion == "" && state.shippingLine == "" && toDate == ""&& movementType == "") ||
      // (fromDate == "" && state.originRegion != "" && state.destinationRegion == "" && state.shippingLine == "" && toDate == ""&& movementType == "") ||
      // (fromDate == "" && state.originRegion == "" && state.destinationRegion != "" && state.shippingLine == "" && toDate == ""&& movementType == "") ||
      // (fromDate == "" && state.originRegion == "" && state.destinationRegion == "" && state.shippingLine != "" && toDate == ""&& movementType == "") ||
      // (fromDate == "" && state.originRegion == "" && state.destinationRegion == "" && state.shippingLine == "" && toDate != ""&& movementType == "") ||
      // (fromDate == "" && state.originRegion == "" && state.destinationRegion == "" && state.shippingLine == "" && toDate == ""&& movementType != "")
      // )
      // {
      //   containerData=state.all_container_data
      // }
      // else
      //containerData=state.container_data

      //Movement Type
      if (movementType) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (each.movement_type && each.movement_type === movementType) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (each.movement_type && each.movement_type === movementType) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            container_data: filteredData,
          });
          return;
        }
      }

      //if only fromDate and no toDate then filter all from or after created_at:"2023-11-15T05:12:56.460Z"
      if (fromDate && !toDate) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            container_data: filteredData,
          });
          return;
        }
      }

      //if only toDate and no fromDate then filter all before or equal to created_at:"2023-11-15T05:12:56.460Z"
      if (!fromDate && toDate) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            container_data: filteredData,
          });
          return;
        }
      }

      // fromDate <= created_at <= toDate
      if (fromDate && toDate) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.created_at)) &&
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.created_at)) &&
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.created_at))
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            container_data: filteredData,
          });
          return;
        }
      }

      //Origin Region
      if (state.originRegion && state.originRegion.hasOwnProperty("value")) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              each.loading_port_code.slice(0, 2) === state.originRegion.value
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              each.loading_port_code.slice(0, 2) === state.originRegion.value
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            container_data: filteredData,
          });
          return;
        }
      }

      //Destination Region
      if (
        state.destinationRegion &&
        state.destinationRegion.hasOwnProperty("value")
      ) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              each.discharge_port_code.slice(0, 2) ===
              state.destinationRegion.value
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              each.discharge_port_code.slice(0, 2) ===
              state.destinationRegion.value
            ) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            container_data: filteredData,
          });
          return;
        }
      }

      //Shipping Line
      if (state.shippingLine && state.shippingLine.hasOwnProperty("value")) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (each.shipping_line === state.shippingLine.value) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (each.shipping_line === state.shippingLine.value) {
              return each;
            }
          });
        }
        if (filteredData.length == 0) {
          setState({
            ...state,
            container_data: filteredData,
          });
          return;
        }
      }
      setState({
        ...state,
        //container_data: filteredData.slice(0, tileCount),
        container_data: filteredData,
      });
      // await showFilterCard();
    }
    // $("#sort-filter")
    //   .addClass("show-n")
    //   .removeClass("show-m")
    //   .removeClass("card");
    // setState({
    //   ...state,
    //   showFilterCard: "show-n",
    // });
  };

  const getfilterDataByCounter = (counterType, eventName) => {
    if (tabActive === "BL/Booking") {
      //if all is clicked simply assign all_bl_data irrespective of any counter

      let blDetentionData = [];

      if (eventName === "0-5") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "0-5") {
            blDetentionData.push(each);
          }
        });
        return blDetentionData;
      }

      if (eventName === "6-10") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "6-10") {
            blDetentionData.push(each);
          }
        });
        return blDetentionData;
      }

      if (eventName === "11-15") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "11-15") {
            blDetentionData.push(each);
          }
        });
        return blDetentionData;
      }

      if (eventName === "16-21") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "16-21") {
            blDetentionData.push(each);
          }
        });
        return blDetentionData;
      }

      if (eventName === ">21") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === ">21") {
            blDetentionData.push(each);
          }
        });
        return blDetentionData;
      }

      if (eventName === "detained") {
        return state.bl_detention_data.containers;
      }

      if (eventName === "delayed") {
        return state.delayed_bl_data.data;
      }

      if (eventName === "all") {
        return state.all_bl_data;
      }

      // if main counter is clicked then sort by event_group level
      let filteredData = [];
      let bl_data = state.all_bl_data;
      //main card level sort
      if (counterType === "main") {
        filteredData = bl_data.filter((each) => {
          let { event_group } = each.last_event_data[0];
          if (eventName === "in_transit") {
            if (
              event_group === "in_transit" ||
              event_group === "transshipment"
            ) {
              return each;
            }
          } else if (event_group === eventName) {
            return each;
          }
          if (
            eventName.toUpperCase() === "UNTRACKABLE" &&
            (each.status === "UNTRACKABLE" || each.status === "NEW")
          ) {
            return each;
          }
        });

        //sub card level filter
      } else {
        filteredData = bl_data.filter((each) => {
          let { event_code, sub_event_group } = each.last_event_data[0];
          if (sub_event_group === eventName) {
            return each;
          }
        });
      }
      return filteredData;
    } else {
      let containerDetentionData = [];

      if (eventName === "0-5") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "0-5") {
            containerDetentionData.push(each);
          }
        });
        return containerDetentionData;
      }
      if (eventName === "6-10") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "6-10") {
            containerDetentionData.push(each);
          }
        });
        return containerDetentionData;
      }

      if (eventName === "11-15") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "11-15") {
            containerDetentionData.push(each);
          }
        });
        return containerDetentionData;
      }
      if (eventName === "16-21") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "16-21") {
            containerDetentionData.push(each);
          }
        });
        return containerDetentionData;
      }
      if (eventName === ">21") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === ">21") {
            containerDetentionData.push(each);
          }
        });
        return containerDetentionData;
      }

      if (eventName === "detained") {
        return state.container_detention_data.containers;
      }

      if (eventName === "delayed") {
        return state.delayed_containers_data.data;
      }
      //container level filter
      if (eventName === "all") {
        return state.all_container_data;
      }
      let filteredData = [];
      let containerData = state.all_container_data;
      //main card level sort
      if (counterType === "main") {
        filteredData = containerData.filter((each) => {
          let { event_group } = each.last_event_data[0];
          if (eventName === "in_transit") {
            if (
              event_group === "in_transit" ||
              event_group === "transshipment"
            ) {
              return each;
            }
          }
          if (eventName === "destination" && event_group === "destination") {
            return each;
          }
          if (eventName === "origin" && event_group === "origin") {
            return each;
          }
          if (eventName === "completed" && event_group === "completed") {
            return each;
          }
          if (
            eventName.toUpperCase() === "UNTRACKABLE" &&
            (each.status === "UNTRACKABLE" || each.status === "NEW")
          ) {
            return each;
          }
        });
      } else {
        filteredData = containerData.filter((each) => {
          let { event_code, sub_event_group } = each.last_event_data[0];
          if (sub_event_group === eventName) {
            return each;
          }
        });
      }
      return filteredData;
    }
  };

  const clearAllFilters = () => {
    let tileCount = 10;

    if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
      tileCount = parseInt(state.tilesPerPage.value);
    }
    //setOpenFilterModal(false)
    let mainCounter = localStorage.getItem("current_counter");
    let subCounter = localStorage.getItem("sub_counter");
    if (subCounter === "") filterDataByCounter("main", mainCounter);
    else filterDataByCounter("sub", subCounter);
    setFromDate("");
    setToDate("");
    setMovementType("");
    if (tabActive === "BL/Booking") {
      // let blData = state.all_bl_data;
      setState({
        ...state,
        bl_data: state.all_bl_data,
        destinationRegion: "",
        originRegion: "",
      });

      // setFromDate("");
      // setToDate("");
      // setMovementType("");
    } else {
      // let containerData = state.all_container_data;
      setState({
        ...state,
        container_data: state.all_container_data,
        destinationRegion: "",
        originRegion: "",
      });
      // setFromDate("");
      // setToDate("");
      // setMovementType("");
    }
  };

  const [eventName, setEventName] = useState("all");

  const [blCounters, setBLCounters] = useState({
    all: 0,
    origin: 0,
    in_transit: 0,
    destination: 0,
    completed: 0,
    delayed: 0,
    detained: 0,
    // sub_empty_pickup: 0,
    // sub_dispatch: 0,
    // sub_gate_in: 0,
    // sub_transhipment: 0,
    // sub_destination: 0,
    // sub_gate_out: 0,
    // sub_empty_return: 0,
  });

  // useEffect(() => {
  //   console.log("useEffect", state.eventName)
  //   if (state.eventName === "all") {
  //     setSortCardHeight("301px");
  //   } else {
  //     setSortCardHeight("285px");
  //   }
  // }, [state.eventName]);
  const sortTabHeight = useRef(0);

  const filterDataByCounter = (counterType, eventName) => {
    let tileCount = 10;

    if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
      tileCount = parseInt(state.tilesPerPage.value);
    }
    // console.log(
    //   "fdghjfilterDataByCounterwertyuiopufdfghjgfdgg",
    //   counterType,
    //   eventName,
    //   state.tabActive
    // );
    settabActiveSCnt(1);
    if (eventName !== "all") sortTabHeight.current = "313px";
    else sortTabHeight.current = "191px";

    setEventName(eventName);

    setState({
      ...state,
      counterType: counterType,
      eventName: eventName,
      showLoader: true,
    });
    if (eventName != "all") {
      setSortCardClass("sortCardHeight_others");
    }
    let all_slabs = ["0-5", "6-10", "11-15", "16-21", ">21"];

    if (tabActive === "BL/Booking") {
      //if all is clicked simply assign all_bl_data irrespective of any counter

      let blDetentionData = [];

      if (eventName === "0-5") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "0-5") {
            blDetentionData.push(each);
          }
        });
        setState({
          ...state,
          //bl_data: blDetentionData.slice(0, tileCount),
          bl_data: blDetentionData,
          eventName: "0-5",
        });
        return;
      }

      if (eventName === "6-10") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "6-10") {
            blDetentionData.push(each);
          }
        });
        setState({
          ...state,
          //bl_data: blDetentionData.slice(0, tileCount),
          bl_data: blDetentionData,
          eventName: "6-10",
        });
        return;
      }

      if (eventName === "11-15") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "11-15") {
            blDetentionData.push(each);
          }
        });
        setState({
          ...state,
          //bl_data: blDetentionData.slice(0, tileCount),
          bl_data: blDetentionData,
          eventName: "11-15",
        });
        return;
      }

      if (eventName === "16-21") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "16-21") {
            blDetentionData.push(each);
          }
        });
        setState({
          ...state,
          //bl_data: blDetentionData.slice(0, tileCount),
          bl_data: blDetentionData,
          eventName: "16-21",
        });
        return;
      }

      if (eventName === ">21") {
        let bl_detention_data = state.bl_detention_data.containers;
        bl_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === ">21") {
            blDetentionData.push(each);
          }
        });

        setState({
          ...state,
          //bl_data: blDetentionData.slice(0, tileCount),
          bl_data: blDetentionData,
          eventName: ">21",
        });
        return;
      }

      if (eventName === "detained") {
        // console.log("detainedData", state.bl_detention_data.containers);
        setState({
          ...state,
          //bl_data: state.bl_detention_data.containers.slice(0, tileCount),
          bl_data:
            state.bl_detention_data.containers == undefined
              ? []
              : state.bl_detention_data.containers,
          eventName: "detained",
        });
        return;
      }

      if (eventName === "delayed") {
        setState({
          ...state,
          //bl_data: state.delayed_bl_data.data.slice(0, tileCount),
          bl_data: state.delayed_bl_data.data,
          eventName: "delay",
          // showLoader: false,
        });
        // setSortCardClass("sortCardHeight_all");
        return;
      }

      if (eventName === "all") {
        // console.log(state.bl_new_shipments.length,"state.bl_new_shipments.length")
        // if((tileCount-state.bl_new_shipments.length-state.bl_untrackable.length)>0)
        // tileCount=tileCount-state.bl_new_shipments.length-state.bl_untrackable.length
        setState({
          ...state,
          //bl_data: state.all_bl_data.slice(0, tileCount),
          bl_data: state.all_bl_data,
          eventName: "all",
          // showLoader: false,
        });
        setSortCardClass("sortCardHeight_all");
        // console.log(state.all_bl_data);
        return;
      }

      // if main counter is clicked then sort by event_group level
      let filteredData = [];
      let bl_data = state.all_bl_data;
      //main card level sort
      if (counterType === "main") {
        filteredData = bl_data.filter((each) => {
          let { event_group } = each.last_event_data[0];
          if (eventName === "in_transit") {
            if (
              event_group === "in_transit" ||
              event_group === "transshipment"
            ) {
              return each;
            }
          } else if (event_group === eventName) {
            return each;
          }
          if (
            eventName.toUpperCase() === "UNTRACKABLE" &&
            (each.status === "UNTRACKABLE" || each.status === "NEW")
          ) {
            return each;
          }
        });

        //sub card level filter
      } else {
        filteredData = bl_data.filter((each) => {
          let { event_code, sub_event_group } = each.last_event_data[0];
          if (sub_event_group === eventName) {
            return each;
          }
        });
      }
      // console.log("filterDataByCounterType", counterType);
      // console.log("filterDataByCounter", eventName);
      // console.log("filteredData", filteredData);
      setState({
        ...state,
        //bl_data: filteredData.slice(0, tileCount),
        bl_data: filteredData,
        // showLoader: false,
      });

      ///////////////////////////////////////////////////////////////////////////////////////////////////
    } else {
      let containerDetentionData = [];

      if (eventName === "0-5") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "0-5") {
            containerDetentionData.push(each);
          }
        });
        setState({
          ...state,
          //container_data: containerDetentionData.slice(0, tileCount),
          container_data: containerDetentionData,
          eventName: "0-5",
        });
        return;
      }
      if (eventName === "6-10") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "6-10") {
            containerDetentionData.push(each);
          }
        });
        setState({
          ...state,
          //container_data: containerDetentionData.slice(0, tileCount),
          container_data: containerDetentionData,
          eventName: "6-10",
        });
        return;
      }

      if (eventName === "11-15") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "11-15") {
            containerDetentionData.push(each);
          }
        });
        setState({
          ...state,
          //container_data: containerDetentionData.slice(0, tileCount),
          container_data: containerDetentionData,
          eventName: "11-15",
        });
        return;
      }
      if (eventName === "16-21") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === "16-21") {
            containerDetentionData.push(each);
          }
        });
        setState({
          ...state,
          //container_data: containerDetentionData.slice(0, tileCount),
          container_data: containerDetentionData,
          eventName: "16-21",
        });
        return;
      }
      if (eventName === ">21") {
        let container_detention_data =
          state.container_detention_data.containers;
        container_detention_data.forEach((each) => {
          let { slab_name } = each;
          if (slab_name === ">21") {
            containerDetentionData.push(each);
          }
        });

        setState({
          ...state,
          //container_data: containerDetentionData.slice(0, tileCount),
          container_data: containerDetentionData,
          eventName: ">21",
        });
        return;
      }

      if (eventName === "detained") {
        // console.log("detainedData", state.container_detention_data.containers);
        setState({
          ...state,
          //container_data: state.container_detention_data.containers.slice(0, tileCount),
          container_data: state.container_detention_data.containers,
          eventName: "detained",
        });
        return;
      }

      if (eventName === "delayed") {
        setState({
          ...state,
          //container_data: state.delayed_containers_data.data.slice(0, tileCount),
          container_data: state.delayed_containers_data.data,
          eventName: "delay",
          // showLoader: false,
        });
        // setSortCardClass("sortCardHeight_all");
        return;
      }
      //container level filter
      if (eventName === "all") {
        // console.log(state.all_container_data);
        setState({
          ...state,
          //container_data: state.all_container_data.slice(0, tileCount),
          container_data: state.all_container_data,
          eventName: "all",
          // showLoader: false,
        });
        return;
      }
      let filteredData = [];
      let containerData = state.all_container_data;
      //main card level sort
      if (counterType === "main") {
        filteredData = containerData.filter((each) => {
          let { event_group } = each.last_event_data[0];
          if (eventName === "in_transit") {
            if (
              event_group === "in_transit" ||
              event_group === "transshipment"
            ) {
              return each;
            }
          }
          if (eventName === "destination" && event_group === "destination") {
            return each;
          }
          if (eventName === "origin" && event_group === "origin") {
            return each;
          }
          if (eventName === "completed" && event_group === "completed") {
            return each;
          }
          if (
            eventName.toUpperCase() === "UNTRACKABLE" &&
            (each.status === "UNTRACKABLE" || each.status === "NEW")
          ) {
            return each;
          }
        });

        //sub card level filter
      } else {
        filteredData = containerData.filter((each) => {
          let { event_code, sub_event_group } = each.last_event_data[0];
          if (sub_event_group === eventName) {
            return each;
          }
        });
      }
      // console.log("filterDataByCounter", eventName);
      // console.log("filteredData", filteredData);
      setState({
        ...state,
        //container_data: filteredData.slice(0, tileCount),
        container_data: filteredData,
        // showLoader: false,
      });
    }
  };

  const handleShippingDate = () => {};

  const showTabView = (tab) => {
    setTabActive(tab);
    if (tab === "BL/Booking") {
      setState({
        ...state,
        filterModeType: { value: "bl_number", label: "BL/Booking" },
        originRegionOptions: state.bl_org_options,
        destinationRegionOptions: state.bl_dest_options,
        shippingLines: state.blShippingLines,
        bl_data: state.all_bl_data,
        tilesPerPage: { value: state.all_bl_data.length, label: "ALL" },
        tilesPerPageOptions: [
          { value: state.all_bl_data.length, label: "ALL" },
          { value: "10", label: "10" },
          { value: "20", label: "20" },
          { value: "30", label: "30" },
          { value: "40", label: "40" },
          { value: "50", label: "50" },
          { value: "60", label: "60" },
        ],
      });
      localStorage.setItem("sub_counter", "");
      setMainCounters(blCounters);
      clearAllFilters();
      settabActiveSCnt(0);
    } else {
      setState({
        ...state,
        filterModeType: {
          value: "container_number",
          label: "Container",
        },
        originRegionOptions: state.container_org_options,
        destinationRegionOptions: state.container_dest_options,
        shippingLines: state.containerShippingLines,
        container_data: state.all_container_data,
        tilesPerPage: { value: state.all_container_data.length, label: "ALL" },
        tilesPerPageOptions: [
          { value: state.all_container_data.length, label: "ALL" },
          { value: "10", label: "10" },
          { value: "20", label: "20" },
          { value: "30", label: "30" },
          { value: "40", label: "40" },
          { value: "50", label: "50" },
          { value: "60", label: "60" },
        ],
      });
      localStorage.setItem("sub_counter", "");
      setMainCounters(containerCounters);
      clearAllFilters();

      settabActiveSCnt(0);
    }

    // let inTransitTrackingData = []
    // if (tab == "transit") {
    //   let allTrackingData = [...this.state.allTrackingData]
    //   inTransitTrackingData = allTrackingData.filter(container => container.trip_completed == 0)
    // } else {
    //   let allTrackingData = [...this.state.allTrackingData]
    //   inTransitTrackingData = allTrackingData.filter(container => container.trip_completed == 1)
    // }
    // let trackingDataLength = inTransitTrackingData.length
    // console.log("trackingDataLength", trackingDataLength)
    // console.log(inTransitTrackingData, "inTransitTrackingData")
    // this.setState({
    //   loadshow: 'show-m',
    //   showFilterBox: false,
    //   tabActive: tab,
    //   showFilterBox: false,
    //   searchFilterValue: '',
    //   filterStartDate: '',
    //   filterOriginRegion: '',
    //   filterDestinationRegion: '',
    //   completedCounter: 0,
    //   onTimeCounter: 0,
    //   delayedCounter: 0,
    //   emptyPickupCounter: 0,
    //   dipatchCounter: 0,
    //   gateInCounter: 0,
    //   originCounter: 0,
    //   transhipmentCounter: 0,
    //   destinationCounter: 0,
    //   gateOutCounter: 0,
    //   emptyReturnCounter: 0,
    //   trackingDataLength: trackingDataLength
    // }, () => {
    //   // isTransitTrackingData has 12 records
    //   this.loadTrackingPageData(inTransitTrackingData, true)
    // })
  };

  const trackingData = () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const tab = queryParams.get("tab");
      if (tab == "Container") {
        setTabActive(tab);
      }
    } catch (err) {}
    setState({
      ...state,
      showLoader: true,
    });
    let payload = {
      user_type: localStorage.getItem("user_type"),
      email: localStorage.getItem("email"),
    };
    redirectURL
      .post("/exim/getTrackingData", payload)
      .then((resp) => {
        console.log("getTrackingData data", resp.data);

        if (resp.data.status === "success") {
          let {
            container_data,
            bl_data,
            delayed_containers_data,
            delayed_bl_data,
            container_detention_data,
            bl_detention_data,
            bl_new_shipments,
            bl_untrackable,
            container_new_shipments,
            container_untrackable,
          } = resp.data;

          let subContainerCounters = {};
          // let subBLCounters = {};
          // let mainEventNames = Object.keys(mainEvents);

          let container_org_options = [];
          let container_dest_options = [];
          let bl_org_options = [];
          let bl_dest_options = [];

          let blAllCounters = {
            all: 0,
            origin: 0,
            in_transit: 0,
            destination: 0,
            completed: 0,
            delayed: 0,
            detained: 0,
          };

          blAllCounters.detained = bl_detention_data.count;

          let containerAllCounters = {
            all: 0,
            origin: 0,
            in_transit: 0,
            destination: 0,
            completed: 0,
            delayed: 0,
            detained: 0,
          };

          containerAllCounters.detained = container_detention_data.count;

          let containerAllCount = 0; //count for all trackable containers i.e which have mbl_number
          let containerUnTrackable = 0; //count for all untrackable containers i.e which have no mbl_number
          let untrackableContainers = []; //data of untrackable containers with no mbl_number
          let trackableContainers = []; //data of untrackable containers with no mbl_number

          let blAllCount = 0; //count for all trackable mbls i.e which have no mbl_number
          let blUnTrackable = 0; //count for all untrackable mbls i.e which have no mbl_number
          let untrackableMbl = []; //data of untrackable mbl with no mbl_number
          let trackableMbl = []; //data of untrackable mbl with no mbl_number

          let containerOrgCountries = [];
          let containerDestCountries = [];

          let containerShippingLines = [];
          let containerShippingDuplicates = [];

          //container_data filtering
          let totalEmission = 0;
          let totalContainerEmission = 0;
          container_data.forEach((each) => {
            let {
              loading_port_code,
              discharge_port_code,
              mbl_number,
              shipping_line,
              slab_name,
              last_event_data,
            } = each;
            let originCode = loading_port_code.slice(0, 2);
            let originCountry = getCountryName(originCode);

            if (!containerShippingDuplicates.includes(shipping_line)) {
              containerShippingLines.push({
                label: shipping_line,
                value: shipping_line,
              });
              containerShippingDuplicates.push(shipping_line);
            }
            if (each.hasOwnProperty("force_close") && each.force_close === 1) {
              last_event_data[0].status = "COMPLETED";
              last_event_data[0].event_group = "completed";
              last_event_data[0].event_name = "Force Closed";
              last_event_data[0].sub_event_group = "empty_return";
              each.delay_status = "Delay";
            }
            // event_name: 'Discharge from Vessel'   ||   event_code: "dischargeFromVessel" ||   event_group: "in_transit"
            let { event_name, event_code, event_group, sub_event_group } =
              each.last_event_data[0];

            // if (mbl_number !== "") {
            //
            //to remove duplicate insertion
            if (!containerOrgCountries.includes(originCountry)) {
              container_org_options.push({
                label: originCountry,
                value: originCode,
              });
              containerOrgCountries.push(originCountry);
            }

            let destinationCode = discharge_port_code.slice(0, 2);
            let destinationCountry = getCountryName(destinationCode);

            //to remove duplicate insertion
            if (!containerDestCountries.includes(originCountry)) {
              container_dest_options.push({
                label: destinationCountry,
                value: destinationCode,
              });
              containerDestCountries.push(destinationCountry);
            }
            //

            containerAllCount++;
            trackableContainers.push(each);
            // at main level
            if (
              event_group === "transshipment" ||
              event_group === "in_transit"
            ) {
              if (containerAllCounters.hasOwnProperty("in_transit")) {
                containerAllCounters["in_transit"] =
                  containerAllCounters["in_transit"] + 1; //increase count of main counter by 1 if already exists
              } else {
                containerAllCounters["in_transit"] = 1;
              }
            } else {
              if (containerAllCounters.hasOwnProperty(event_group)) {
                containerAllCounters[event_group] =
                  containerAllCounters[event_group] + 1; //increase count of main counter by 1 if already exists
              } else {
                containerAllCounters[event_group] = 1;
              }
            }

            //at sub_level
            if (sub_event_group) {
              let sublevel_key = "";
              if (
                event_group === "transshipment" ||
                event_group === "in_transit"
              ) {
                sublevel_key = "in_transit" + "_" + sub_event_group;
              } else {
                sublevel_key = event_group + "_" + sub_event_group;
              }
              if (containerAllCounters.hasOwnProperty(sublevel_key)) {
                containerAllCounters[sublevel_key] =
                  containerAllCounters[sublevel_key] + 1; //increase count of main counter by 1 if already exists
              } else {
                containerAllCounters[sublevel_key] = 1;
              }
            }

            if (
              containerAllCounters.hasOwnProperty("detained" + "_" + slab_name)
            ) {
              containerAllCounters["detained" + "_" + slab_name] =
                containerAllCounters["detained" + "_" + slab_name] + 1;
            } else {
              containerAllCounters["detained" + "_" + slab_name] = 1;
            }
          });
          for (let item of container_data) {
            totalContainerEmission += item.emissions.co2e.value
              ? parseFloat(item.emissions.co2e.value)
              : 0;
          }
          let containter_level_cummulative_emission =
            totalContainerEmission.toFixed(2) + " " + "Kg";

          containerAllCounters["containersTrackable"] = containerAllCount;
          containerAllCounters["containersUnTrackable"] =
            containerUnTrackable + container_new_shipments.length;
          containerAllCounters["all"] =
            containerAllCount +
            container_untrackable.length +
            container_new_shipments.length;
          containerAllCounters["untrackable"] =
            container_untrackable.length + container_new_shipments.length;
          containerAllCounters["delayed"] = delayed_containers_data.count;

          let blOrgCountries = [];
          let blDestCountries = [];
          let blShippingLines = [];
          let blShippingDuplicates = [];

          let trans = 0;
          let inT = 0;

          //bl_data filtering
          bl_data.forEach((each) => {
            let {
              loading_port_code,
              discharge_port_code,
              mbl_number,
              shipping_line,
              slab_name,
              last_event_data,
            } = each;

            if (each.hasOwnProperty("force_close") && each.force_close === 1) {
              last_event_data[0].status = "COMPLETED";
              last_event_data[0].event_group = "completed";
              last_event_data[0].event_name = "Force Closed";
              last_event_data[0].sub_event_group = "empty_return";
            }

            if (!blShippingDuplicates.includes(shipping_line)) {
              blShippingLines.push({
                label: shipping_line,
                value: shipping_line,
              });
              blShippingDuplicates.push(shipping_line);
            }
            let { event_name, event_code, event_group, sub_event_group } =
              each.last_event_data[0];

            blAllCount++;

            if (
              event_group === "transshipment" ||
              event_group === "in_transit"
            ) {
              if (blAllCounters.hasOwnProperty("in_transit")) {
                blAllCounters["in_transit"] = blAllCounters["in_transit"] + 1; //increase count of main counter by 1 if already exists
              } else {
                blAllCounters["in_transit"] = 1;
              }
            } else {
              if (blAllCounters.hasOwnProperty(event_group)) {
                blAllCounters[event_group] = blAllCounters[event_group] + 1; //increase count of main counter by 1 if already exists
              } else {
                blAllCounters[event_group] = 1;
              }
            }

            // if (blAllCounters.hasOwnProperty(event_group)) {
            //   blAllCounters[event_group] = blAllCounters[event_group] + 1; //increase count of main counter by 1 if already exists
            // } else {
            //   blAllCounters[event_group] = 1;
            // }

            //at sub_level
            if (sub_event_group) {
              let sublevel_key = "";
              if (
                event_group === "transshipment" ||
                event_group === "in_transit"
              ) {
                sublevel_key = "in_transit" + "_" + sub_event_group;
              } else {
                sublevel_key = event_group + "_" + sub_event_group;
              }
              if (blAllCounters.hasOwnProperty(sublevel_key)) {
                blAllCounters[sublevel_key] = blAllCounters[sublevel_key] + 1; //increase count of main counter by 1 if already exists
              } else {
                blAllCounters[sublevel_key] = 1;
              }
            }

            if (blAllCounters.hasOwnProperty("detained" + "_" + slab_name)) {
              blAllCounters["detained" + "_" + slab_name] =
                blAllCounters["detained" + "_" + slab_name] + 1;
            } else {
              blAllCounters["detained" + "_" + slab_name] = 1;
            }
            // let sublevel_key = event_group + "_" + event_code; //in_transit_dischargeFromVessel

            let originCode = loading_port_code.slice(0, 2);
            let originCountry = getCountryName(originCode);
            let destinationCode = discharge_port_code.slice(0, 2);
            let destinationCountry = getCountryName(destinationCode);

            if (!blOrgCountries.includes(originCountry)) {
              bl_org_options.push({
                label: originCountry,
                value: originCode,
              });
              blOrgCountries.push(originCountry);
            }

            if (!blDestCountries.includes(destinationCountry)) {
              bl_dest_options.push({
                label: destinationCountry,
                value: destinationCode,
              });
              blDestCountries.push(destinationCountry);
            }
            // mainEventNames.forEach((key) => {
            //   if (mainEvents[key].includes(event_name)) {
            //     if (subBLCounters.hasOwnProperty(key)) {
            //       subBLCounters[key] = subBLCounters[key] + 1;
            //     } else {
            //       subBLCounters[key] = 1;
            //     }

            //     if (subBLCounters.hasOwnProperty(event_code)) {
            //       subBLCounters[event_code] = subBLCounters[event_code] + 1;
            //     } else {
            //       subBLCounters[event_code] = 1;
            //     }
            //   }
            // });
          });
          for (let item of bl_data) {
            totalEmission += item.emissions.co2e.value
              ? parseFloat(item.emissions.co2e.value)
              : 0;
          }
          var bl_level_cummulative_emission =
            totalEmission.toFixed(2) + " " + "Kg";
          setBlTemission(bl_level_cummulative_emission);
          setContainerTemission(containter_level_cummulative_emission);

          blAllCounters["blAllCount"] = blAllCount;
          blAllCounters["all"] =
            blAllCount + bl_untrackable.length + bl_new_shipments.length;
          blAllCounters["untrackable"] =
            bl_untrackable.length + bl_new_shipments.length;
          blAllCounters["delayed"] = delayed_bl_data.count;

          //console.log(blAllCounters,"setMainCounter");
          //console.log(containerAllCounters,"setMainCountersss");
          setContainerCounters(containerAllCounters);
          setBLCounters(blAllCounters);
          setMainCounters(blAllCounters);
          const last_event_data = [
            {
              status: "",
              event_name: "",
              actual_time: "",
              event_code: "",
              shipment_no: "",
              event_group: "",
              location: "",
              sub_event_group: "",
            },
          ];
          bl_data = bl_new_shipments
            .map((item) => ({
              ...item,
              mbl_number: item.bill_of_lading_no,
              last_event_data,
            }))
            .concat(
              bl_data,
              bl_untrackable.map((item) => ({
                ...item,
                mbl_number: item.bill_of_lading_no,
                last_event_data,
              }))
            );
          let allContainerData = container_new_shipments
            .map((item) => ({
              ...item,
              mbl_number: item.bill_of_lading_no,
              last_event_data,
            }))
            .concat(
              trackableContainers,
              container_untrackable.map((item) => ({
                ...item,
                mbl_number:
                  item.bill_of_lading_no == undefined
                    ? ""
                    : item.bill_of_lading_no,
                last_event_data,
              }))
            );

          setState({
            ...state,
            rowData: bl_data,
            //Set All MBL DATA
            all_bl_data: bl_data,
            trackableMbl: bl_data,
            untrackableMbl: untrackableMbl,
            bl_data: bl_data,
            tilesPerPage: {
              value:
                tabActive === "BL/Booking"
                  ? blAllCount + bl_untrackable.length + bl_new_shipments.length
                  : containerAllCount +
                    container_untrackable.length +
                    container_new_shipments.length,
              label: "ALL",
            },
            tilesPerPageOptions: [
              {
                value:
                  tabActive === "BL/Booking"
                    ? blAllCount +
                      bl_untrackable.length +
                      bl_new_shipments.length
                    : containerAllCount +
                      container_untrackable.length +
                      container_new_shipments.length,
                label: "ALL",
              },
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "30", label: "30" },
              { value: "40", label: "40" },
              { value: "50", label: "50" },
              { value: "60", label: "60" },
            ],

            //Set All Container Data
            all_container_data: allContainerData,
            trackableContainers: allContainerData,
            untrackableContainers,
            container_data: allContainerData,
            showLoader: false,
            bl_org_options,
            bl_dest_options,
            container_org_options,
            container_dest_options,
            originRegionOptions: bl_org_options,
            destinationRegionOptions: bl_dest_options,
            containerShippingLines: containerShippingLines,
            blShippingLines: blShippingLines,
            shippingLines: blShippingLines,
            delayed_containers_data,
            delayed_bl_data,
            container_detention_data,
            bl_detention_data,

            bl_new_shipments,
            bl_untrackable,
            container_new_shipments,
            container_untrackable,
            // containerAllCounters,
          });
        }
      })
      .catch((err) => {
        console.log("getTrackingData err", err);
      });
  };

  const handleView = (viewType) => {
    setState({
      ...state,
      showSortCard: "show-n",
    });

    $("#sort-filter")
      .removeClass("show-m")
      .addClass("show-n")
      .removeClass("sort-filter-slider-close")
      .removeClass("sort-filter-slider-open")
      .removeClass("card");

    if (viewType === "tileView") {
      setState({
        ...state,
        isTileView: true,
      });
      // $("#sort-filter").css({"top":"276px"})
    } else {
      setState({
        ...state,
        isTileView: false,
      });
      // $("#sort-filter").css({"top":"495px"})
    }
  };

  const filterDataFromSearchBox = () => {
    let tileCount = 10;

    if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
      tileCount = parseInt(state.tilesPerPage.value);
    }
    // console.log("state.filterModeType.value", state.filterModeType.value);
    let mainCounter = localStorage.getItem("current_counter");
    let subCounter = localStorage.getItem("sub_counter");
    if (tabActive === "BL/Booking") {
      let bl_data;

      if (subCounter === "")
        bl_data = getfilterDataByCounter("main", mainCounter);
      else bl_data = getfilterDataByCounter("sub", subCounter);

      if (state.searchInput === "") {
        // setState({
        //   ...state,
        //   //bl_data: state.all_bl_data.slice(0, tileCount),
        //   bl_data: state.all_bl_data,
        // });
        let mainCounter = localStorage.getItem("current_counter");
        let subCounter = localStorage.getItem("sub_counter");
        if (subCounter === "") filterDataByCounter("main", mainCounter);
        else filterDataByCounter("sub", subCounter);
        //localStorage.setItem("current_counter","all")
        //settabActiveSCnt(0)
      }
      //for bl_number in bl_tab
      if (state.filterModeType.value === "bl_number") {
        if (state.searchInput) {
          let bl_filteredData = bl_data.filter((each) => {
            if (
              each.mbl_number.includes(state.searchInput.toUpperCase().trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //bl_data: bl_filteredData.slice(0, tileCount),
            bl_data: bl_filteredData,
          });
        }
      } else if (state.filterModeType.value === "container_number") {
        if (state.searchInput) {
          let bl_filteredData = bl_data.filter((each) => {
            if (
              each.container_no.includes(state.searchInput.toUpperCase().trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //bl_data: bl_filteredData.slice(0, tileCount),
            bl_data: bl_filteredData,
          });
        }
      } else if (state.filterModeType.value === "invoice_no") {
        if (state.searchInput) {
          let bl_filteredData = bl_data.filter((each) => {
            if (
              each.invoice_no &&
              each.invoice_no
                .toLowerCase()
                .includes(state.searchInput.toLowerCase().trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //bl_data: bl_filteredData.slice(0, tileCount),
            bl_data: bl_filteredData,
          });
        }
      } else if (state.filterModeType.value === "po_no") {
        if (state.searchInput) {
          let bl_filteredData = bl_data.filter((each) => {
            if (
              each.po_no &&
              each.po_no
                .toLowerCase()
                .includes(state.searchInput.toLowerCase().trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //bl_data: bl_filteredData.slice(0, tileCount),
            bl_data: bl_filteredData,
          });
        }
      }
    }
    //for container tab
    else {
      let containerData;
      if (subCounter === "")
        containerData = getfilterDataByCounter("main", mainCounter);
      else containerData = getfilterDataByCounter("sub", subCounter);

      if (state.searchInput === "") {
        let mainCounter = localStorage.getItem("current_counter");
        let subCounter = localStorage.getItem("sub_counter");
        if (subCounter === "") filterDataByCounter("main", mainCounter);
        else filterDataByCounter("sub", subCounter);
        // setState({
        //   ...state,
        //   //containerData: state.all_container_data.slice(0, tileCount),
        //   containerData: state.all_container_data
        // });
        // localStorage.setItem("current_counter","all")
        // settabActiveSCnt(0)
        return;
      }
      if (state.filterModeType.value === "bl_number") {
        if (state.searchInput) {
          let container_filteredData = containerData.filter((each) => {
            if (
              each.mbl_number.includes(state.searchInput.toUpperCase().trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //container_data: container_filteredData.slice(0, tileCount),
            container_data: container_filteredData,
          });
        } else {
          setState({
            ...state,
            //container_data: containerData.slice(0, tileCount),
            container_data: containerData,
          });
        }
      } else if (state.filterModeType.value === "container_number") {
        if (state.searchInput) {
          let container_filteredData = containerData.filter((each) => {
            if (
              each.container_no.includes(state.searchInput.toUpperCase().trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //container_data: container_filteredData.slice(0, tileCount),
            container_data: container_filteredData,
          });
        } else {
          setState({
            ...state,
            //container_data: containerData.slice(0, tileCount),
            container_data: containerData,
          });
        }
      } else if (state.filterModeType.value === "invoice_no") {
        if (state.searchInput) {
          let container_filteredData = containerData.filter((each) => {
            if (
              each.invoice_no &&
              each.invoice_no
                .toLowerCase()
                .includes(state.searchInput.toLowerCase().trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //container_data: container_filteredData.slice(0, tileCount),
            container_data: container_filteredData,
          });
        }
      } else if (state.filterModeType.value === "po_no") {
        if (state.searchInput) {
          let container_filteredData = containerData.filter((each) => {
            if (
              each.po_no &&
              each.po_no
                .toLowerCase()
                .includes(state.searchInput.toLowerCase().trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //container_data: container_filteredData.slice(0, tileCount),
            container_data: container_filteredData,
          });
        }
      }
    }
  };

  const trackingDetails = () => {
    // console.log(state.filterModeType);
    // console.log(state.searchInput);

    filterDataFromSearchBox();

    // let { label } = state.filterModeType;
    // let acceptedFormat = "";
    // let payload = {};
    // let urlType = "";
    // if (label === "Container number") {
    //   acceptedFormat = "ABCD1234567";
    //   var regexPattern = /^[A-Z]{4}\d{7}$/;
    //   payload["container_no"] = state.searchInput;
    //   urlType = "/exim/containerTrackingDetails";
    // } else {
    //   acceptedFormat = "BL123456789";
    //   var regexPattern = /^BL\d{9}$/;
    //   payload["container_no"] = state.searchInput;
    //   urlType = "/exim/mblTrackingDetails";
    // }

    // if (!regexPattern.test(state.searchInput)) {
    //   alert(`Invalid ${label} and valid format is ${acceptedFormat}`);
    //   return;
    // }

    // redirectURL
    //   .post(urlType, payload)
    //   .then((resp) => {
    //     console.log("trackingDetails", resp.data);
    //   })
    //   .catch((err) => {
    //     console.log("trackingDetails err", err);
    //   });
  };

  useEffect(() => {
    if (state.searchInput == "") filterDataFromSearchBox();
  }, [state.searchInput]);

  // useEffect(() => {
  //   let tileCount = 10;

  //   if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
  //     tileCount = parseInt(state.tilesPerPage.value);
  //   }
  //   if (tabActive === "BL/Booking") {
  //     // console.log(object)

  //     // let blData = state.bl_data.slice(0, tileCount);
  //     // setState({
  //     //   ...state,
  //     //   bl_data: blData,
  //     // });
  //   } else {
  //     let containerData
  //     //if((tileCount-state.container_new_shipments.length-state.container_untrackable.length)>0)
  //     //tileCount=tileCount-state.container_new_shipments.length-state.container_untrackable.length
  //     //else{
  //       // console.log(state.container_untrackable.length,"state.container_untrackable.lengthhh");
  //       // containerData = state.all_container_data.slice(0, tileCount);
  //       // setState({
  //       //   ...state,
  //       //   container_data: containerData,
  //       // })
  //     //}
  //     // setState({
  //     //   ...state,
  //     //   container_data: containerData,
  //     // });
  //   }

  //   // var mm = 'all'
  //   // if(localStorage.getItem("current_counter") == null)
  //   // {
  //   //   mm = 'all';
  //   // }
  //   // else{
  //   //   mm = localStorage.getItem("current_counter");
  //   // }

  //   // // console.log(state.counterType+" , "+state.eventName+",,"+mm);
  //   // if(localStorage.getItem("sub_counter") != "" && localStorage.getItem("sub_counter") != undefined)
  //   // {

  //   //   var smm = 'all'
  //   //   if(localStorage.getItem("sub_counter") == null || localStorage.getItem("sub_counter") == "")
  //   //   {
  //   //     smm = 'all';
  //   //   }
  //   //   else{
  //   //     smm = localStorage.getItem("sub_counter");
  //   //   }
  //   //   filterDataByCounter("sub",smm)
  //   // }
  //   // else{
  //   //   filterDataByCounter("main",mm)
  //   // }

  // }, [state.tilesPerPage]);

  const handleFilterOptions = (selOption, dropdownName) => {
    //console.log("selOption", selOption);
    let mainCounter = localStorage.getItem("current_counter");
    let subCounter = localStorage.getItem("sub_counter");
    if (dropdownName.name === "tilesPerPage" && selOption.label === "ALL") {
      setOpenFilterModal(false);
      if (tabActive === "BL/Booking") {
        let blData;
        if (subCounter === "")
          blData = getfilterDataByCounter("main", mainCounter);
        else blData = getfilterDataByCounter("sub", subCounter);

        setState({
          ...state,
          tilesPerPage: {
            value: state.all_bl_data.length,
            label: "ALL",
          },
          bl_data: blData,
        });
      } else {
        let contData;
        if (subCounter === "")
          contData = getfilterDataByCounter("main", mainCounter);
        else contData = getfilterDataByCounter("sub", subCounter);
        setState({
          ...state,
          tilesPerPage: {
            value: state.all_container_data.length,
            label: "ALL",
          },
          bl_data: contData,
        });
      }
    } else {
      setState({
        ...state,
        [dropdownName.name]: selOption,
      });
    }
  };

  const openDocumentsSlider = () => {
    setState({
      ...state,
      overly: "show-m",
      documentsTranslate: "slider-translate",
    });
  };

  const showSortCard = () => {
    $("#filter-card")
      .addClass("show-n")
      .removeClass("show-m")
      .removeClass("card");

    // sort-filter-slider-close
    //left - open
    //right - close
    let finalClass = "";
    if ($("#sidebarToggleIcon").hasClass("fa-angle-left")) {
      finalClass = "sort-filter-slider-open card";
    } else {
      finalClass = "sort-filter-slider-close card";
    }

    if (state.showSortCard === "show-n") {
      setState({
        ...state,
        showSortCard: "show-m " + finalClass,
      });
    } else {
      setState({
        ...state,
        showSortCard: "show-n",
      });
    }
  };

  const showFilterCard = () => {
    $("#sort-filter")
      .addClass("show-n")
      .removeClass("show-m")
      .removeClass("card");
    if (state.showFilterCard === "show-n") {
      setState({
        ...state,
        showFilterCard: "show-m card",
      });
    } else {
      setState({
        ...state,
        showFilterCard: "show-n",
        destinationRegion: "",
        originRegion: "",
        filterText: "",
      });
      setFromDate("");
    }
  };
  function timeoutLoader() {
    setGridLoader(false);
  }
  const sortData = (dataType) => {
    var defnew = 0;
    setGridLoader(true);
    if (localStorage.getItem("current_counter") == "all") {
      if (tabActive === "BL/Booking") {
        defnew = state.bl_new_shipments.length;
      } else {
        defnew = state.container_new_shipments.length;
      }
    }
    // console.log(dataType);
    // console.log(state.container_data);
    if (dataType === "last_tracking_time") {
      if (tabActive === "BL/Booking") {
        let blData = state.bl_data;
        blData.sort((a, b) =>
          moment(b.tracking_updated_at).diff(moment(a.tracking_updated_at))
        ); //descending order
        // state.container_data
        setState({
          ...state,
          //bl_data: blData.slice(0, tileCount-defnew),
          //bl_data:blData,
          showSortCard: "show-n",
        });
        setTimeout(timeoutLoader, 500);
      } else {
        let containerData = state.container_data;
        containerData.sort((a, b) =>
          moment(b.tracking_updated_at).diff(moment(a.tracking_updated_at))
        ); //descending order
        // state.container_data
        setState({
          ...state,
          //container_data: containerData.slice(0, tileCount-defnew),
          container_data: containerData,
          showSortCard: "show-n",
        });
        setTimeout(timeoutLoader, 500);
        // $("#sort-filter")
        //   .addClass("show-n")
        //   .removeClass("show-m")
        //   .removeClass("card");
      }
    } else if (dataType === "last_activity_update") {
      if (tabActive === "BL/Booking") {
        let blData = state.bl_data;
        blData.sort((a, b) =>
          moment(b.last_event_data[0].actual_time).diff(
            moment(a.last_event_data[0].actual_time)
          )
        ); //descending order
        // state.container_data
        setState({
          ...state,
          //bl_data: blData.slice(0, tileCount-defnew),
          bl_data: blData,
          showSortCard: "show-n",
        });
        setTimeout(timeoutLoader, 500);
      } else {
        let containerData = state.container_data;
        // let last_event_time = containerData[0].last_event_data[0].actual_time
        containerData.sort((a, b) =>
          moment(a.last_event_data[0].actual_time).diff(
            moment(b.last_event_data[0].actual_time)
          )
        ); //descending order
        setTimeout(timeoutLoader, 500);
        setState({
          ...state,
          //container_data: containerData.slice(0, tileCount-defnew),
          container_data: containerData,
          showSortCard: "show-n",
        });
      }
      // $("#sort-filter")
      //   .addClass("show-n")
      //   .removeClass("show-m")
      //   .removeClass("card");
    } else {
      //for final_eta
      if (tabActive === "BL/Booking") {
        let blData = state.bl_data;
        blData.sort(
          (
            a,
            b //if ata exits then ata else take eta
          ) =>
            moment(b.last_port_ata ? b.last_port_ata : b.last_port_eta).diff(
              moment(a.last_port_ata ? a.last_port_ata : a.last_port_eta)
            )
        ); //descending order
        // state.container_data
        setState({
          ...state,
          //bl_data: blData.slice(0, tileCount-defnew),
          bl_data: blData,
          showSortCard: "show-n",
        });
        setTimeout(timeoutLoader, 500);
      } else {
        let containerData = state.container_data;
        containerData.sort(
          (
            a,
            b //if ata exits then ata else take eta
          ) =>
            moment(b.last_port_ata ? b.last_port_ata : b.last_port_eta).diff(
              moment(a.last_port_ata ? a.last_port_ata : a.last_port_eta)
            )
        ); //descending order
        // state.container_data
        setTimeout(timeoutLoader, 500);
        setState({
          ...state,
          //container_data: containerData.slice(0, tileCount-defnew),
          container_data: containerData,
          showSortCard: "show-n",
        });
      }

      // $("#sort-filter")
      //   .addClass("show-n")
      //   .removeClass("show-m")
      //   .removeClass("card");
    }
  };

  useEffect(() => {
    localStorage.setItem("email", "admin@enmovil.in");
    localStorage.setItem("user_type", "admin");
    localStorage.setItem("current_counter", "all");
    localStorage.setItem("sub_counter", "");
    trackingData();
    const temp = [];
    let promise = new Promise(function (myResolve, myReject) {
      redirectURL
        .post("/exim/getULIPAPIData", {
          user_type: localStorage.getItem("user_type"),
          email: localStorage.getItem("email"),
        })
        .then((response) => {
          myResolve(response.data);
        })
        .catch((error) => myReject(error));
    });
    promise.then(
      function (data) {
        if (data.data[0].ldb_opted == 1) temp.push("LDB");
        if (data.data[0].fois_opted == 1) temp.push("FOIS");
        if (data.data[0].icegate_opted == 1) temp.push("ICEGATE");
        if (data.data[0].pcs_opted == 1) temp.push("PCS");
        setCheckedCheckboxes((prev) => [
          ...prev,
          ...temp,
          ...[data.api_data[0].api_provider],
        ]);
        setULIPData(temp);
        if (data.hasOwnProperty("api_data")) {
          setAiProvider([data.api_data[0].api_provider]);
        }
      },
      function (error) {
        console.log(error);
      }
    );
    let promise2 = new Promise(function (myResolve, myReject) {
      redirectURL
        .post("/exim/getSubCounterOptions", {
          email: localStorage.getItem("email"),
        })
        .then((response) => {
          myResolve(response.data);
        })
        .catch((error) => myReject(error));
    });
    promise2.then(
      function (data) {
        setCheckedCheckboxes([...data.data, ...temp]);
        subcountersCheckbox.current = [...data.data, ...temp];
      },
      function (error) {
        console.log(error);
      }
    );
    setState({
      ...state,
      showLoader: false,
    });
  }, []);

  const onClickHideAll = () => {
    setState({
      ...state,
      overly: "show-n",
      documentsTranslate: "",
    });
  };

  const handleFromDate = (date) => {
    setFromDate(date);
    // setToDate((prevToDate) => (prevToDate < date ? date : prevToDate));
  };

  const handleToDate = (date) => {
    let cdate = new Date(date);
    cdate.setHours(23);
    cdate.setMinutes(59);
    cdate.setSeconds(59);
    setToDate(cdate);
    setFromDate((prevFromDate) =>
      prevFromDate > cdate ? cdate : prevFromDate
    );
  };

  return (
    <div id="exim-tracking">
      <BootstrapLoader showLoader={state.showLoader} />
      <Modal
        open={openFilterModal}
        onClose={closeModal}
        center
        classNames={{
          overlayAnimationIn: "customEnterOverlayAnimation",
          overlayAnimationOut: "customLeaveOverlayAnimation",
          // modalAnimationIn: "customEnterModalAnimation",
          // modalAnimationOut: "customLeaveModalAnimation",
        }}
        animationDuration={400}
        styles={customStyles}
      >
        <div className="" style={{ height: "57px" }}>
          <h5
            className="modal-heading"
            style={{
              borderTopRightRadius: "15px",
              borderTopLeftRadius: "15px",
              padding: "20px",
            }}
          >
            Filters
          </h5>
        </div>

        <div className="px-2 mb-4">
          {/* Search And Buttons Come Here */}
          <div className="p-2">
            <input
              type="text"
              name=""
              id=""
              className="search_input"
              style={{ width: "44%" }}
              value={state.filterText}
              onChange={(e) => {
                setState({ ...state, filterText: e.target.value });
              }}
            />
            <img
              src={SearchIcon}
              className="filter_search_icon"
              // onClick={trackingDetails}
            />
            <button
              className="btn filter_button mr-2"
              // style={{ width: "85px", fontSize: "11px" }}
              onClick={clearAllFilters}
              style={{ backgroundColor: "#407bff", color: "white" }}
            >
              Clear Filters
            </button>
            <button
              className="btn filter_button mr-2"
              // style={{ width: "85px", fontSize: "11px" }}
              disabled={
                fromDate ||
                state.originRegion ||
                state.destinationRegion ||
                state.shippingLine ||
                toDate ||
                movementType
                  ? null
                  : "disabled"
              }
              onClick={filterDataFromFilters}
              style={{ backgroundColor: "#407bff", color: "white" }}
            >
              Apply Filters
            </button>
            {/* <button
              class="btn btn-primary filter_button"
              // style={{ width: "85px", fontSize: "11px" }}
              onClick={closeModal}
            >
              Close
            </button> */}
          </div>
          {/* Movement Type */}
          <div
            className={`region_card mt-2 ${filterTypes.movementtype} px-2`}
            id="originregion"
          >
            <label for="" className="mr-3">
              Movement Type
            </label>
            <label className="mr-3">
              <input
                type="radio"
                value="Import"
                checked={movementType === "Import"}
                onChange={handleOptionChange}
                className="radio_button"
              />
              Import
            </label>

            <label>
              <input
                type="radio"
                value="Export"
                checked={movementType === "Export"}
                onChange={handleOptionChange}
                className="radio_button"
              />
              Export
            </label>
          </div>
          {/* Shipping Date */}
          <div
            className={`${filterTypes.shippingdate} mt-2 col-3 px-2`}
            id="shippingdate"
          >
            <div className="row">
              <div className="col-5">
                <label for="" style={{ width: "375%" }}>
                  Shipment From Date
                </label>
                {/* <label for="">From</label> */}
                <DatePicker
                  selected={fromDate}
                  onChange={handleFromDate}
                  dateFormat={"yyyy/MM/dd"}
                  selectsStart
                  startDate={fromDate}
                  endDate={toDate}
                  maxDate={toDate ? toDate : null}
                  wrapperClassName="datepick"
                />
              </div>
              <div
                className="col-5"
                style={{ position: "absolute", left: "307px" }}
              >
                <label for="" style={{ width: "375%" }}>
                  Shipment To Date
                </label>

                {/* <label for="">To</label> */}
                <DatePicker
                  selected={toDate}
                  onChange={handleToDate}
                  dateFormat={"yyyy/MM/dd"}
                  selectsEnd
                  startDate={fromDate}
                  endDate={toDate}
                  minDate={fromDate ? fromDate : null}
                  wrapperClassName="datepick"
                />
              </div>
            </div>
          </div>
          {/* Origin Region */}
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, padding: "10px", border: "1px" }}>
              <div id="originregion">
                <label for="">Origin Region</label>
                <Select
                  closeMenuOnSelect={true}
                  name="originRegion"
                  className="border-radius-0"
                  onChange={handleFilterOptions}
                  options={state.originRegionOptions}
                  value={state.originRegion}
                  maxMenuHeight={window.innerHeight * 0.26}
                />
              </div>
            </div>
            <div style={{ flex: 1, padding: "10px", border: "1px" }}>
              <div
                // class="region_card destinationregion"
                id="destinationregion"
              >
                <label for="">Destination Region</label>
                <Select
                  closeMenuOnSelect={true}
                  name="destinationRegion"
                  className="border-radius-0"
                  onChange={handleFilterOptions}
                  options={state.destinationRegionOptions}
                  value={state.destinationRegion}
                  required
                  maxMenuHeight={window.innerHeight * 0.26}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openSubCounterChoise}
        center
        closeIcon={"  "}
        classNames={{
          overlayAnimationIn: "customEnterOverlayAnimation",
          overlayAnimationOut: "customLeaveOverlayAnimation",
          // modalAnimationIn: "customEnterModalAnimation",
          // modalAnimationOut: "customLeaveModalAnimation",
        }}
        modalId="subcounters"
        animationDuration={400}
      >
        <div
          className="mx-4 mt-5 row align-content-center justify-content-center"
          style={{
            cursor: "pointer",
            height: "54px",
            background: "#e4edfc",
            borderRadius: "12px",
            whiteSpace: "nowrap",
          }}
        >
          <div
            className="col"
            style={{
              color: subCounters.id == 1 ? "#fff" : "#407bff",
              background: subCounters.id == 1 ? "#407bff" : "#00000000",
              borderRadius: "12px",
            }}
          >
            <button
              className="col"
              style={{
                color: subCounters.id == 1 ? "#fff" : "black",
                background: "#00000000",
                height: "54px",
                border: "none",
                borderRadius: "12px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setSubCounters(counters.current[0])}
            >
              <img
                src={
                  subCounters.id == 1
                    ? subCounters.activeImgSrc
                    : counters.current[0].imgSrc
                }
              />
              &nbsp; Origin
            </button>
          </div>
          <div
            className="col"
            style={{
              color: subCounters.id == 2 ? "#fff" : "#407bff",
              background: subCounters.id == 2 ? "#407bff" : "#00000000",
              borderRadius: "12px",
              paddingLeft: "3px",
            }}
          >
            <button
              className="col"
              style={{
                color: subCounters.id == 2 ? "#fff" : "black",
                background: "#00000000",
                height: "54px",
                border: "none",
                borderRadius: "12px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setSubCounters(counters.current[1])}
            >
              <img
                src={
                  subCounters.id == 2
                    ? subCounters.activeImgSrc
                    : counters.current[1].imgSrc
                }
                style={{ maxHeight: "35px", maxWidth: "30px" }}
              />
              &nbsp; In-transit
            </button>
          </div>
          <div
            className="col"
            style={{
              color: subCounters.id == 3 ? "#fff" : "#407bff",
              background: subCounters.id == 3 ? "#407bff" : "#00000000",
              borderRadius: "12px",
              paddingLeft: "2px",
            }}
          >
            <button
              className="col"
              style={{
                color: subCounters.id == 3 ? "#fff" : "black",
                background: "#00000000",
                height: "54px",
                border: "none",
                borderRadius: "12px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setSubCounters(counters.current[2])}
            >
              <img
                src={
                  subCounters.id == 3
                    ? subCounters.activeImgSrc
                    : counters.current[2].imgSrc
                }
              />
              &nbsp; Destination
            </button>
          </div>
          <div
            className="col"
            style={{
              color: subCounters.id == 4 ? "#fff" : "#407bff",
              background: subCounters.id == 4 ? "#407bff" : "#00000000",
              borderRadius: "12px",
            }}
          >
            <button
              className="col"
              style={{
                color: subCounters.id == 4 ? "#fff" : "black",
                background: "#00000000",
                height: "54px",
                border: "none",
                borderRadius: "12px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setSubCounters(counters.current[3])}
            >
              {subCounters.id == 4 ? (
                <FaGlobe size={38} style={{ color: "#ffff" }} />
              ) : (
                <FaGlobe size={38} style={{ color: "#407bff" }} />
              )}
              &nbsp; ULIP
            </button>
          </div>

          {/* <div className="col" style={{
            color: (subCounters.id == 5) ? "#fff" : "#407bff",
            background: (subCounters.id == 5) ? "#407bff" : "#00000000",
            borderRadius: "12px",
          }}>
            <button className="col"
              style={{
                color: (subCounters.id == 5) ? "#fff" : "black",
                background: "#00000000",
                height: "54px",
                border: "none",
                borderRadius: "12px",
                fontWeight: "bold"
              }}
              onClick={() => setSubCounters(counters.current[4])}>
              {(subCounters.id == 5) ? <AiFillApi size={38} style={{ color: "#ffff" }} /> : <AiFillApi size={38} style={{ color: "#407bff" }} />}&nbsp; API
            </button>
          </div> */}
        </div>
        <div
          className="row mx-5 my-4 row align-content-left justify-content-left"
          style={{ height: "12vw" }}
        >
          {subCounters.subCounters.map((item, index) => (
            <div
              key={item.value}
              className="col-6 my-2"
              style={{ fontSize: "medium" }}
            >
              <input
                type="checkbox"
                disabled={
                  subCounters.id === 4 || subCounters.id === 5 ? true : false
                }
                style={{ transform: "scale(1.3)" }}
                value={item.value}
                checked={checkedCheckboxes.includes(item.value)}
                onChange={() => handleCheckboxChange(item.value)}
              />
              &nbsp;&nbsp;&nbsp;{item.label}
            </div>
          ))}
        </div>
        <div className="row mx-4 mb-5 row align-content-center justify-content-center">
          <div className="col-6">
            <button
              className="col"
              style={{
                color: "#407bff",
                background: "#fff",
                border: "2px solid #407bff",
                borderRadius: "12px",
                height: "35px",
              }}
              onClick={() => closeModal2()}
            >
              {subCounters.id == 4 || subCounters.id == 5 ? "Close" : "Cancel"}
            </button>
          </div>
          <div
            className={
              subCounters.id == 4 || subCounters.id == 5 ? "d-none" : "col-6"
            }
          >
            <button
              className="col"
              style={{
                color: "#fff",
                background: "#407bff",
                border: "none",
                borderRadius: "12px",
                height: "35px",
              }}
              onClick={applySubCounters}
            >
              Apply
            </button>
          </div>
        </div>
      </Modal>
      <div
        className={"slide-r " + state.documentsTranslate}
        style={{ overflow: "auto" }}
      >
        <div className="document-slider bg-primary text-white pt-2 px-3">
          <h6>
            Add Document
            <span
              className="float-right closebtn"
              style={{ marginRight: "25px" }}
              onClick={onClickHideAll}
            >
              X
            </span>
          </h6>
        </div>
        <div className="slide-r-body" style={{ position: "relative" }}>
          <div className="p-2 d-flex flex-column">
            <div className="pb-3">
              <input
                name="attachmentTitle"
                className="form-control mr-3 mt-1"
                id="exim-document"
                placeholder="Name"
                style={{ width: "93%" }}
                onChange={(e) =>
                  this.setState({
                    attachmentTitle: e.target.value,
                  })
                }
                value={state.attachmentTitle}
                required
              />
            </div>

            <div className="pb-3">
              <Select
                closeMenuOnSelect={true}
                name="modeType"
                className="border-radius-0"
                // styles={{
                //   control: (provided, state) => ({
                //     ...provided,
                //     fontSize: "0.8rem",
                //     color: "#6e707e",
                //     width: "93%",
                //   }),
                // }}
                // onChange={this.newFilter.bind(this, "attachmentType")}
                options={[
                  { value: "Bill of Lading", label: "Bill of Lading" },
                  { value: "Booking Note", label: "Booking Note" },
                  {
                    value: "Certificate of Origin",
                    label: "Certificate of Origin",
                  },
                  {
                    value: "Commercial Invoice",
                    label: "Commercial Invoice",
                  },
                  {
                    value: "Forwarders Cargo Receipt",
                    label: "Forwarders Cargo Receipt",
                  },
                  {
                    value: "Freight Invoice",
                    label: "Freight Invoice",
                  },
                  {
                    value: "Hazardous Declaration",
                    label: "Hazardous Declaration",
                  },
                  { value: "Manifest", label: "Manifest" },
                  { value: "Packing List", label: "Packing List" },
                  { value: "Purchase Order", label: "Purchase Order" },
                  { value: "Sea Waybill", label: "Sea Waybill" },
                ]}
                // value={this.state.attachmentType}
                required
              />
            </div>
            <div className="">
              <input
                type="file"
                name="attachmentsDoc"
                style={{ fontSize: "0.8rem" }}
                id="attachmentsDoc"
                // onChange={this.changeAttachmentsFileHandler.bind(this)}
                // className="col-7 mt-3 border mr-3"
                required
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary mt-3"
                // onClick={this.handleAddAttachmentsBtn.bind(this)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div>
          {/* <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{background: "none"}}>
                    <li className="breadcrumb-item">Container Tracking</li>
                </ol>
            </nav> */}
          {/* <div
            style={{
              color: "#263238",
              //fontSize: "22px",
              fontWeight: 700,
              marginBottom: "20px",
              paddingLeft: "10px",
            }}
          >
           Container Tracking
          </div> */}
          <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
            <i className="fa fa-truck"></i> Container Tracking
            <Favourites />
          </h5>
          {/* <img
            className=""
            // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
            // src={TrackingShipGreen}
            src={emissionIcon}
            width="40px"
            style={{ marginLeft: "20px" }}
          // ["On Time", "Early", "On Time"]
          ></img> */}
          {/* margin-top: 20px;
                  position: absolute;
                  top: 20px;
                  font-size: 12px;
                  left: 220px; */}
          {/* <span style={{ fontSize: "14px", color: "#A1A2A6", marginLeft: "4px" }}>
            {
              state.tabActive === "BL/Booking" ? bl_data_Temission : container_data_Temission
              // state.tabActive === "BL/Booking" ? bl_level_cummulative_emission: containter_level_cummulative_emission
            }
          </span> */}

          {/* <Tooltip id="info_card_tracking_header" />
          <img
            src={InfoIcon}
            className="info_card_tracking_header"
            data-tooltip-id="info_card_tracking_header"
            alt="" width="15px"
            data-tooltip-content="Port to Port emission calculation based on GLEC framework using default factors."
          /> */}
        </div>
        <div className="d-flex flex-row justify-content-between ml-4">
          <div className="">
            <ul className={"nav nav-tabs"}>
              <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    tabActive == "BL/Booking" ? "tab-active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => showTabView("BL/Booking")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  BL/Booking
                </a>
              </li>
              <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    tabActive == "Container" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Container")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Container
                </a>
              </li>
            </ul>
          </div>
          <div className="d-flex mr-4">
            {createShipment ? (
              <button
                className="d-flex justify-content-center align-items-center mr-3 px-4 mb-2"
                style={{
                  borderRadius: "10px",
                  color: "white",
                  background: "#407bff",
                  border: "none",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
                onClick={() => {
                  navigate("/create-shipment");
                }}
              >
                <img src={CreateShipmentIcon}></img>
                <div className="ml-2">Create Shipment</div>
              </button>
            ) : (
              <></>
            )}
            <button
              className="d-none"
              style={{
                borderRadius: "5px",
                color: "#407bff",
                background: "white",
                border: "2px solid #407bff",
                fontSize: "12px",
                fontWeight: 600,
              }}
              // d-flex justify-content-center align-items-center px-4 mb-2
              onClick={openDocumentsSlider}
            >
              <img src={BulkUploadIcon}></img>
              <div className="ml-2">Bulk Upload</div>
            </button>
            <button
              className="d-flex justify-content-center align-items-center mr-3 px-4 mb-2"
              style={{
                border: "none",
                background: "transparent",
                color: "#407bff",
              }}
              onClick={() => {
                setOpenSubCounterChoise(true);
              }}
            >
              <IoSettingsOutline size={32} />
            </button>
          </div>
        </div>
        <div
          className="card col-lg-12 col-md-12 pt-4"
          style={{ borderRadius: "18px" }}
        >
          <div>
            <MainTrackingCounters
              counters={mainCounter}
              filterDataByCounter={filterDataByCounter}
              tabActiveSCnt={tabActiveSCnt}
              subCountersTab={subcountersCheckbox.current}
              clearAllFilters={clearAllFilters}
              // tabActive={state.tabActive}
              // bl_counters={blCounters}
              // containerCounters={containerCounters}
            />
          </div>

          {/* Functionality Card */}
          <div className="d-flex flex-row mb-5 pl-3 mt-4 flex-wrap">
            <div className="d-flex flex-row">
              {/* <select style={{ width: "115px", height: "31px", borderRadius: "6px", border: "1px solid #cccccc", marginRight: "20px" }} >
  

                        </select> */}

              <div className="region_card mr-3" ref={selectRef}>
                {/* mode-type */}
                <Select
                  menuIsOpen={isMenuOpen}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  closeMenuOnSelect={true}
                  name="filterModeType"
                  className="border-radius-0"
                  // styles={{
                  //     control: (provided, state) => ({
                  //     ...provided,
                  //     fontSize: "0.8rem",
                  //     color: "#6e707e",
                  //     }),
                  // }}
                  onChange={handleFilterOptions}
                  options={state.modeOptions}
                  value={state.filterModeType}
                  // isDisabled={true}
                  required
                />
              </div>
              <div
                className="d-flex"
                style={{ position: "relative", marginRight: "20px" }}
              >
                <input
                  style={{
                    border: "1px solid #cccccc",
                    borderRadius: "11px",
                    height: "38px",
                    outline: "none",
                    paddingLeft: "6px",
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setState({
                      ...state,
                      searchInput: newValue,
                    });
                  }}
                  value={state.searchInput}
                />
                <img
                  src={SearchIcon}
                  className="search_icon"
                  onClick={trackingDetails}
                />
              </div>

              {/* <button
                className="d-flex px-3 py-2 mr-3 justify-content-center align-items-center"
                style={{
                  background: "white",
                  borderRadius: "11px",
                  border: "1px solid #407bff",
                  height: "38px",
                }}
              > */}
              <div
                className="d-flex py-2 mr-3 justify-content-center align-items-center"
                style={{
                  background: "white",
                  borderRadius: "11px",
                  border: "1px solid #407bff",
                  height: "38px",
                  paddingRight: "10px",
                }}
              >
                {/* <img src={SortIcon}></img>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 700,
                      color: "#407bff",
                      marginLeft: "10px",
                    }}
                  >
                    Sort
                  </div>
                </div> */}
                <NavDropdown
                  className="custom-nav-dropdown"
                  title={<CustomDropdownTitle />}
                  style={{
                    fontSize: "13px",
                    fontWeight: 700,
                    color: "#407bff",
                    marginLeft: "10px",
                  }}
                >
                  <NavDropdown.Item
                    onClick={() => {
                      sortData("last_tracking_time");
                    }}
                  >
                    Last Tracking Time
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      sortData("last_activity_update");
                    }}
                  >
                    Last Activity Update
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      sortData("final_eta");
                    }}
                  >
                    Final ETA
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
              {/* </button> */}
              <button
                className="d-flex px-3 py-2 justify-content-center align-items-center"
                style={{
                  background: "white",
                  borderRadius: "11px",
                  border: "1px solid #407bff",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <div
                  className="d-flex flex-row"
                  onClick={openFilterModal2}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img src={FilterIcon}></img>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 700,
                      color: "#407bff",
                      marginLeft: "10px",
                    }}
                  >
                    Filters
                  </div>
                </div>
              </button>

              {/* <div
                className={`sort-card ${state.showSortCard} ${sortCardClass}`}
                id="sort-filter"
                style={{
                  left:
                    state.filterModeType.value === "bl_number" ||
                    state.filterModeType.value === "po_no"
                      ? "328px"
                      : "328px",
                  top: sortTabHeight.current
                }}
              >
                <div
                  className="sorting-line cursor-class"
                  onClick={() => {
                    sortData("last_tracking_time");
                  }}
                >
                  <label className="cursor-class" for="">
                    Last Tracking Time
                  </label>
                </div>
                <div
                  className="sorting-line cursor-class"
                  onClick={() => {
                    sortData("last_activity_update");
                  }}
                >
                  <label className="cursor-class" for="">
                    Last Activity Update
                  </label>
                </div>
                <div
                  className="cursor-class"
                  onClick={() => {
                    sortData("final_eta");
                  }}
                >
                  <label className="cursor-class" for="">
                    Final ETA
                  </label>
                </div>
              </div> */}
            </div>
            <div className="d-flex flex-row ml-4">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row">
                  <button
                    className="d-flex flex-row justify-content-center align-items-center px-3"
                    style={{
                      background: state.isTileView ? "#407bff" : "white",
                      // color: state.isTileView ? "": "#407bff",
                      borderTopLeftRadius: "11px",
                      borderBottomLeftRadius: "11px",
                      border: "1px solid #407bff",
                      height: "38px",
                    }}
                    onClick={() => {
                      handleView("tileView");
                    }}
                  >
                    {/* <img src={TileViewActive}/> */}
                    <img
                      src={state.isTileView ? TileViewActive : TileViewInactive}
                    />
                    <div
                      style={{
                        color: state.isTileView ? "white" : "#407bff",
                        fontSize: "10px",
                        fontWeight: 700,
                        marginLeft: "10px",
                        lineHeight: "12px",
                      }}
                    >
                      Tile
                      <br />
                      View
                    </div>
                  </button>

                  <button
                    className="d-flex flex-row justify-content-center align-items-center px-3"
                    style={{
                      background: !state.isTileView ? "#407bff" : "white",
                      borderTopRightRadius: "11px",
                      borderBottomRightRadius: "11px",
                      border: "1px solid #407bff",
                      height: "38px",
                    }}
                    onClick={() => {
                      handleView("gridView");
                    }}
                  >
                    {/* <img src={GridViewInactive}/> */}
                    <img
                      src={
                        !state.isTileView ? GridViewActive : GridViewInactive
                      }
                    />
                    <div
                      style={{
                        color: !state.isTileView ? "white" : "#407bff",
                        fontSize: "10px",
                        fontWeight: 700,
                        marginLeft: "10px",
                        lineHeight: "12px",
                      }}
                    >
                      Grid
                      <br />
                      View
                    </div>
                  </button>
                </div>
                <div
                  className="d-flex flex-column ml-3"
                  style={{ position: "relative" }}
                >
                  <label
                    style={{
                      fontSize: "10px",
                      color: "#407bff",
                      position: "absolute",
                      top: "-25px",
                    }}
                  >
                    Tiles per page
                  </label>
                  {/* <select style={{ width: "115px", height: "31px", borderRadius: "6px", border: "1px solid #cccccc", marginRight: "20px", height: "31px" }}></select> */}
                  <div className="tilesPageSelector" ref={selectRef1}>
                    {/* mode-type */}
                    <Select
                      menuIsOpen={isMenuOpen1}
                      onMenuOpen={handleMenuOpen1}
                      onMenuClose={handleMenuClose1}
                      closeMenuOnSelect={true}
                      className="border-radius-0"
                      name="tilesPerPage"
                      // styles={{
                      //     control: (provided, state) => ({
                      //     ...provided,
                      //     fontSize: "0.8rem",
                      //     color: "#6e707e",
                      //     }),
                      // }}
                      onChange={handleFilterOptions}
                      options={state.tilesPerPageOptions}
                      value={state.tilesPerPage}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none">
              {/* d-flex flex-row  */}
              <div
                className="d-flex flex-column ml-2"
                style={{ position: "relative" }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    color: "#407bff",
                    position: "absolute",
                    top: "-25px",
                  }}
                >
                  Reports
                </label>
                <div className="region_card mr-3">
                  {/* mode-type */}
                  <Select
                    closeMenuOnSelect={true}
                    name="reportType"
                    className="border-radius-0"
                    // styles={{
                    //     control: (provided, state) => ({
                    //     ...provided,
                    //     fontSize: "0.8rem",
                    //     color: "#6e707e",
                    //     }),
                    // }}
                    onChange={handleFilterOptions}
                    options={state.reportTypeOptions}
                    value={state.reportType}
                    required
                  />
                </div>
              </div>
              <div>
                <button
                  className="d-flex flex-row justify-content-center align-items-center px-3"
                  style={{
                    background: "white",
                    borderRadius: "11px",
                    border: "1px solid #407bff",
                    height: "38px",
                  }}
                >
                  <img src={DownloadIcon}></img>
                  <div
                    style={{
                      color: "#407bff",
                      fontSize: "10px",
                      fontWeight: 700,
                      marginLeft: "10px",
                      lineHeight: "12px",
                    }}
                  >
                    Download
                  </div>
                </button>
              </div>
            </div>
          </div>
          {/* Functionality Card */}

          {state.isTileView ? (
            <>
              <div>
                <TrackingTile
                  bl_data={state.bl_data}
                  bl_untrackable={state.bl_untrackable}
                  container_untrackable={state.container_untrackable}
                  tabActive={tabActive}
                  container_data={state.container_data}
                  trackingDataFun={trackingData}
                  bl_new_shipments={state.bl_new_shipments}
                  container_new_shipments={state.container_new_shipments}
                  eventName={eventName}
                  tilesPerPage={state.tilesPerPage}
                />
              </div>
            </>
          ) : (
            <>
              {gridLoader ? (
                <div
                  className="d-flex flex-row justify-content-center align-items-center align-center"
                  style={{ width: "100%", height: "400px" }}
                >
                  <div className="contener_general" align="center">
                    <div className="contener_mixte">
                      <div className="ballcolor ball_1">&nbsp;</div>
                    </div>
                    <div className="contener_mixte">
                      <div className="ballcolor ball_2">&nbsp;</div>
                    </div>
                    <div className="contener_mixte">
                      <div className="ballcolor ball_3">&nbsp;</div>
                    </div>
                    <div className="contener_mixte">
                      <div className="ballcolor ball_4">&nbsp;</div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <EximGrid
                    bl_data={state.bl_data}
                    tabActive={tabActive}
                    bl_untrackable={state.bl_untrackable}
                    bl_new_shipments={state.bl_new_shipments}
                    container_untrackable={state.container_untrackable}
                    container_new_shipments={state.container_new_shipments}
                    container_data={state.container_data}
                    paginationSize={state.tilesPerPage}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      {/* <div className={`sort-card ${state.showSortCard}`} id="sort-filter">
            <div className="sorting-line" onClick={()=>{sortData("last_tracking_time")}}>
                <label for="">Last Tracking Time</label>                                    
            </div>            
            <div className="sorting-line" onClick={()=>{sortData("last_activity_update")}}>
                <label for="">Last Activity Update</label>                                    
            </div>
            <div className="" onClick={()=>{sortData("final_eta")}}>
                <label for="">Final ETA</label>
            </div>
        </div> */}
    </div>
  );
};
const CustomDropdownTitle = () => {
  return (
    <span>
      <img src={SortIcon}></img> &nbsp; Sort
    </span>
  );
};

export default ContainerTracking;
