import React, {
  useRef,
  useState,
  useEffect,
  useSyncExternalStore,
} from "react";
import { useAsyncError, useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "./createShipment.css";
import {
  FaRoad,
  FaTrain,
  FaPlane,
  FaShip,
  FaBox,
  FaTrash,
  FaUpload,
} from "react-icons/fa";
import Modal from "react-responsive-modal";
import SweetAlert from "react-bootstrap-sweetalert";
// import "./approverdashboard.css";
import { v4 as uuidv4 } from "uuid";
import DateTime from "react-datetime";
import $, { each } from "jquery";
import redirectURL from "../redirectURL";
import moment from "moment";
import { BodyDropTarget } from "ag-grid-community";
import { reduceRight } from "country-flags-svg/dist/data/countries";
import BootstrapLoader from "./BootstrapLoader";
import Alert from "react-bootstrap/Alert";
import { FiAlertTriangle } from "react-icons/fi";
import Favourites from "../common/favouritesComponent";

const CreateShipment = () => {
  let [shipment_unique_id, set_shipment_unique_id] = useState(uuidv4());
  const navigate = useNavigate();
  //   const gridRef = useRef();
  //   const [defaultColDef, setDefaultColDef] = useState({
  //     sortable: true,
  //     filter: true,
  //     resizable: true,
  //     pivot: true,
  //     enableValue: true,
  //     enableRowGroup: false,
  //   });
  //   const [rowData, setRowData] = useState([]);
  //   const [eventsData, setEventsData] = useState([]);
  //   const [pendingData, setPendingData] = useState([]);
  //   const [approvedData, setApprovedData] = useState([]);
  //   const [activeTab, setActiveTab] = useState("")
  // const [userRole, setUserrole] = useState(localStorage.getItem("role"))
  const [contractNumber, setContractNumber] = useState("");
  const [PONumber, setPONumber] = useState("");
  const onChangeContractNumber = (e) => {
    setContractNumber(e.target.value);
  };
  const onChangePONumber = (e) => {
    setPONumber(e.target.value);
  };

  const [movementType, setMovementType] = useState([
    { value: "Import", label: "Import" },
    { value: "Export", label: "Export" },
  ]);
  const [selectMovementType, setSelectMovementType] = useState();
  const onSelectMovementType = (selectedOption) => {
    setSelectMovementType(selectedOption);
  };

  const [cargoType, setCargoType] = useState([
    { value: "General Cargo", label: "General Cargo" },
    { value: "Hazardous Cargo", label: "Hazardous Cargo" },
    { value: "Refridgerated Cargo", label: "Refridgerated Cargo" },
    {
      value: "Refridgerated Hazardous Cargo",
      label: "Refridgerated Hazardous Cargo",
    },
  ]);
  const [lclCargoList, setLclCargoList] = useState([
    { value: "General Cargo", label: "General Cargo" },
    { value: "Hazardous Cargo", label: "Hazardous Cargo" },
  ]);
  const [selectCargoType, setSelectCargoType] = useState();
  const [selectLclCargoType, setSelectLclCargoType] = useState();
  const onSelectCargoType = (selectedOption) => {
    setSelectCargoType(selectedOption);
  };
  const onSelectLclCargoType = (selectedOption) => {
    setSelectLclCargoType(selectedOption);
  };
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: "10px", // You can adjust this value to your liking
      height: "1rem",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "10px", // You can adjust this value to your liking
    }),
    control: (base, stat) => ({
      ...base,
      boxShadow: stat.isFocused
        ? "0px 0px 10px #4e73df"
        : "0px 0px 8px #d9d9d9",
      borderRadius: "6px",
    }),
  };
  const customStyles2 = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: "10px", // You can adjust this value to your liking
      height: "1rem",
      width: "8rem",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "10px", // You can adjust this value to your liking
    }),
    control: (base, stat) => ({
      ...base,
      boxShadow: stat.isFocused
        ? "0px 0px 10px #4e73df"
        : "0px 0px 8px #d9d9d9",
      borderRadius: "6px",
    }),
  };
  const [cargoList, setCargoList] = useState([
    { value: "PCargo1", label: "PCargo1" },
    { value: "PCargo2", label: "PCargo2" },
  ]);
  const [supplierCargoList, setSupplierCargoList] = useState([
    { value: "SPCargo1", label: "SPCargo1" },
    { value: "SPCargo2", label: "SPCargo2" },
  ]);

  const [selectedCargo, setCargo] = useState();
  const [selectedSupplierCargo, setSupplierCargo] = useState();

  const setProductCargoType = (selectedOption) => {
    setCargo(selectedOption);
  };
  const setSupplierProductCargoType = (selectedOption) => {
    setSupplierCargo(selectedOption);
  };

  const [shipperType, setShipperType] = useState(""); // Initially, no option is selected
  const handleShipperTypeChange = (e) => {
    setShipperType(e.target.value);
  };

  const [productName, setProductName] = useState("");
  const [supplierProductName, setSupplierProductName] = useState("");
  //const [disableCustomerProductSave, setDisableCustomerProductSave] =
  // useState(true);
  const [disableSupplierProductSave, setDisableSupplierProductSave] =
    useState(true);
  //const [errorTxtForCustomerProducts, setErrorTextForCustomerProducts] =
  // useState(false);
  const [errorTxtForSupplierProducts, setErrorTextForSupplierProducts] =
    useState(false);
  const onChangeProductName = (e) => {
    setProductName(e.target.value);
  };
  // const validateCustomerProductName = () => {
  //   if (productName.length === 0) {
  //     setDisableCustomerProductSave(true);
  //     setErrorTextForCustomerProducts(true);
  //   } else {
  //     setDisableCustomerProductSave(false);
  //     setErrorTextForCustomerProducts(false);
  //   }
  // };
  const ValidateSupplierProductName = () => {
    if (supplierProductName.length === 0) {
      setDisableSupplierProductSave(true);
      setErrorTextForSupplierProducts(true);
    } else {
      setDisableSupplierProductSave(false);
      setErrorTextForSupplierProducts(false);
    }
  };
  const onChangeSupplierProductName = (e) => {
    setSupplierProductName(e.target.value);
  };

  const [hsCode, setHSCode] = useState("");
  const [supplierHSCode, setSupplierHSCode] = useState("");

  const onChangeHSCode = (e) => {
    setHSCode(e.target.value);
  };
  const onChangeSupplierHSCode = (e) => {
    setSupplierHSCode(e.target.value);
  };

  const [redirect, setRedirect] = useState(false);
  const [vendorID, setVendorID] = useState();
  const [clientID, setClientID] = useState();
  const closeAlert = () => {
    setShow(false);
    setOverly("show-n");
    setState({
      ...state,
      showLoader: false,
    });
    if (redirect) {
      navigate("/container-tracking");
    }
  };
  const [billOfLading, setBillOfLading] = useState("");
  const onChangeBillOfLading = (e) => {
    setBillOfLading(e.target.value);
  };

  const mblDate = $("#mbl_date").val();

  // for options
  const [shippingLine, setShippingLine] = useState([]);
  // for value
  const [selectShippingLine, setSelectShippingLine] = useState({
    label: "",
    value: "",
  });
  // onChange
  const onSelectShippingLine = (selectedOption) => {
    setSelectShippingLine(selectedOption);
    let mbl_lines = [
      "Evergreen",
      "WEK Liner",
      "COSCO",
      "Seatrade",
      "SITC",
      "Crowley",
      "JAS",
      "DSV",
      "Blue World Lines",
    ];
    if (mbl_lines.includes(selectedOption.label)) {
      setTrackByList([{ label: "Bill of Lading Number", value: "BL Number" }]);
      setTrackByValue({ label: "Bill of Lading Number", value: "BL Number" });
    } else {
      setTrackByList([
        { label: "Container Number", value: "Container Number" },
        { label: "Bill of Lading Number", value: "BL Number" },
      ]);
      setTrackByValue({ label: "", value: "" });
    }
  };

  const [trackByList, setTrackByList] = useState([
    { label: "Container Number", value: "Container Number" },
    { label: "Bill of Lading Number", value: "BL Number" },
  ]);
  const [trackByValue, setTrackByValue] = useState({ label: "", value: "" });
  const onSelectTrackByType = (selectedOption) => {
    setTrackByValue(selectedOption);
    setInputDisabled(false);
    setContainerNo("");
    setBillOfLading("");
  };
  const [Incoterms, setIncoterms] = useState([
    { value: "EXW", label: "EXW" },
    { value: "FCA", label: "FCA" },
    { value: "FAS", label: "FAS" },
    { value: "FOB", label: "FOB" },
    { value: "CFR", label: "CFR" },
    { value: "CIF", lable: "CIF" },
    { value: "CPT", label: "CPT" },
    { value: "CIP", label: "CIP" },
    { value: "DAP", lable: "DAP" },
    { value: "DPU", lable: "DPU" },
    { value: "DAP", lable: "DAP" },
  ]);
  const [selectIncoterm, setSelectIncoterm] = useState();
  const onSelectIncoterm = (selectedOptions) => {
    setSelectIncoterm(selectedOptions);
    //console.log("selectIncoterm", selectIncoterm);
  };

  const [billOfLadingDate, setBillOfLadingDate] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const onHandleDateChange = (e) => {
    setBillOfLadingDate(e);
  };
  const onHandleInvoiceDateChange = (e) => {
    setInvoiceDate(e);
  };
  const onHandlePickUpDateChange = (e) => {
    setPickUpDate(e);
  };

  let [containerCount, setContainerCount] = useState(0);
  const onChangeContainerCount = (e) => {
    setContainerCount(e.target.value);
  };

  // const [boxes, setBoxes] = useState([])
  const [boxes, setBoxes] = useState();
  const [containerNo, setContainerNo] = useState("");
  const [errorMssge, setErrorMssge] = useState("");
  // const onChangeContainerNo = (e,index) => {
  //     let newValue = e.target.value;
  //     let tempBox = [...boxes];
  //     tempBox[index].value = newValue;
  //     setBoxes(tempBox);
  // }
  const onChangeContainerNo = (e) => {
    setContainerNo(e.target.value);
  };
  // const validateContainerNo = (e, index) => {
  //     console.log("entered")
  //     const pattern = /^[A-Z]{4}\d{7}$/;
  //     let boxArr = [...boxes];
  //     let valueToValidate = boxArr[index].value
  //     console.log("value to validate",valueToValidate)
  //     if(!pattern.test(valueToValidate)){
  //         let errorMessage = "*Please Enter Valid Container No";
  //         let tempBox = [...boxes];
  //         tempBox[index].isValid = errorMessage;
  //         setBoxes(tempBox);
  //         return;
  //     } else {
  //         let tempBoxes = [...boxes];
  //         tempBoxes[index].isValid = "";
  //         setBoxes(tempBoxes);
  //         return;
  //     }
  // };
  const [submitDisable, setSubmitDisable] = useState(true);
  const validateContainerNo = () => {
    const pattern = /^[A-Z]{4}\d{7}$/;
    if (!pattern.test(containerNo)) {
      let errorMessage = "Enter Valid Container No.(4 alpha + 7 Digits)";
      setErrorMssge(errorMessage);
    } else {
      setErrorMssge("");
    }
  };
  const generateContainerBoxes = () => {
    if (containerCount < 1) {
      window.alert("No of containers should be greater than 0");
    } else {
      let newBoxes = [];

      for (let i = 1; i <= containerCount; i += 1) {
        newBoxes.push({ value: "", isValid: "" });
      }
      setBoxes(newBoxes);
    }
  };

  const [originPort, setOriginPort] = useState("");
  const [customerOriginPort, setCustomerOriginPort] = useState("");
  const [customerStuffingLocation, setCustomerStuffingLocation] = useState("");
  const [customerDestinationPort, setCustomerDestinationPort] = useState("");
  const [customerDestuffingLocation, setCustomerDestuffingLocation] =
    useState("");
  const onChangePortOfOrigin = (e) => {
    setOriginPort(e.target.value);
  };
  const onChangeCustomerOrigin = (e) => {
    setCustomerOriginPort(e.target.value);
  };
  const onChangeCustomerStuffingLocation = (e) => {
    setCustomerStuffingLocation(e.target.value);
  };
  const onChangeCustomerDestination = (e) => {
    setCustomerDestinationPort(e.target.value);
  };
  const onChangeCustomerDestuffingLocation = (e) => {
    setCustomerDestuffingLocation(e.target.value);
  };

  const [destinationPort, setDestinationPort] = useState("");
  const onChangePortOfDestination = (e) => {
    setDestinationPort(e.target.value);
  };

  const [transitDays, setTransitDays] = useState("");
  const onChangeTransitDays = (e) => {
    setTransitDays(e.target.value);
  };

  // for value
  const [customer, setCustomer] = useState();
  const [supplier, setSupplier] = useState();
  // for customerloading
  const [customerLoading, setCustomerLoading] = useState(true);
  const [supplierLoading, setSupplierLoading] = useState(true);
  // for options
  const [customerData, setCustomerData] = useState([
    { value: "+Add", label: "+ Add Customer" },
  ]);
  // const [shippingLine, setPortData] = useState([])
  const [supplierData, setSupplierData] = useState([
    { value: "+Add", label: "+ Add Supplier" },
  ]);
  const [productData, setProductData] = useState([
    { value: "+Add", label: "+ Add Product" },
  ]);
  const [supplierProductData, setSupplierProductData] = useState([
    { value: "+Add", label: "+ Add Product" },
  ]);
  const [attachmentTitle, setAttachmentTitle] = useState("");
  const [attachmentsDocArr, setAttachmentsDocArr] = useState([]);
  const [attachmentsDoc, setAttachmentsDoc] = useState("");
  const [selectedDocType, setSelectedDocType] = useState({
    value: "",
    label: "Type",
  });
  const [attachmentType, setAttachmentType] = useState({
    value: "",
    label: "Type",
  });
  const [isAttachmentsDocChanged, setIsAttachmentDocsChanged] = useState(false);
  const [documentExtension, setDocumentExtension] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState();
  const [supplierProduct, setSupplierProduct] = useState();

  const [productLoading, setProductLoading] = useState(true);
  const [supplierProductLoading, setSupplierProductLoading] = useState(true);

  const [openCustomerModel, setOpenCustomerModel] = useState(false);
  const [openProductModel, setOpenProductModel] = useState(false);

  const [openSupplierModel, setOpenSupplierModel] = useState(false);
  const [openSupplierProductModel, setSuppilerProductModel] = useState(false);
  const [customerReferenceNo, setCustomerReferenceNo] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [demurrageFreeDays, setDemurrageFreeDays] = useState("");
  const [detentionFreeDays, setDetetentionFreeDays] = useState("");
  const [CFSFreeDays, setCFSFreeDays] = useState("");
  const [supplierReferenceNo, setSupplierReferenceNo] = useState("");

  const [FTAOptions, setFTAOptions] = useState([
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]);
  const [RDBOptions, setRDBOptions] = useState([
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]);
  const [EPCGOptions, setEPCGOptions] = useState([
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]);
  const [LCOptions, setLCOptions] = useState([
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]);
  const [incotermsList, setIncotermsList] = useState([
    { value: "EXW", label: "EXW - Ex Work" },
    { value: "FCA", label: "FCA - Free Carrier" },
    { value: "FAS", label: "FAS - Free Alongside Ship" },
    { value: "FOB", label: "FOB - Free on Board" },
    { value: "CFR", label: "CFR - Cost and Freight" },
    { value: "CIF", label: "CIF - Cost, Insurance, and Freight " },
    { value: "CPT", label: "CPT - Carriage Paid To" },
    { value: "CIP", label: "CIP - Carriage and Insurance Paid To" },
    { value: "DAP", label: "DAP - Delivered at Place " },
    { value: "DPU", label: "DPU - Delivered at Place Unloaded " },
    { value: "DDP", label: "DDP - Delivered Buty Paid " },
  ]);

  const [FTAValue, setFTAValue] = useState({ value: "", label: "" });
  const [RDBValue, setRDBValue] = useState({ value: "", label: "" });
  const [EPCGValue, setEPCGValue] = useState({ value: "", label: "" });
  const [IncotermsValue, setIncotermsValue] = useState({
    value: "",
    label: "",
  });
  const [LCValue, setLCValue] = useState();

  const [customerName, setCustomerName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);
  const [customerCode, setCustomerCode] = useState("");
  const [LCNumber, setLCNumber] = useState("");
  const [overly, setOverly] = useState("show-n");
  const [show, setShow] = useState(false);
  const [basicType, setBasicType] = useState("default");
  const [basicTitle, setBasicTitle] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [supplierAdminEmail, setSupplierAdminEmail] = useState("");
  const [adminName, setAdminName] = useState("");
  const [supplierAdminName, setSupplierAdminName] = useState("");
  const [enablePOC, setEnablePOC] = useState(true);
  const [supplierEnablePOC, setSupplierEnablePOC] = useState(true);

  const [teamList, setTeamList] = useState([
    { value: "team1", label: "team1" },
    { value: "team2", label: "team2" },
  ]);
  const [supplierTeamList, setSupplierTeamList] = useState([
    { value: "team1", label: "team1" },
    { value: "team2", label: "team2" },
  ]);
  const [selectedTeam, setSelectTeam] = useState();
  const [supplierSelectedTeam, setSupplierTeam] = useState();
  const [teamListForProduct, setTeamListForProduct] = useState([
    { value: "Default", label: "Default EGA Group" },
    { value: "PTeam1", label: "PTeam1" },
    { value: "PTeam2", label: "PTeam2" },
  ]);
  const [supplierTeamListForProduct, setSupplierTeamListForProduct] = useState([
    { value: "Default", label: "Default EGA Group" },
    { value: "SPTeam1", label: "SPTeam1" },
    { value: "SPTeam2", label: "SPTeam2" },
  ]);
  const [selectedTeamForProduct, setSelectTeamForProduct] = useState();
  const [selectedSupplierTeamForProduct, setSelectSupplierTeamForProduct] =
    useState(supplierTeamListForProduct[0]);
  const [pointOfContacts, setPOCs] = useState([
    { value: "POC1", label: "POC1" },
    { value: "POC2", label: "POC2" },
  ]);
  const [supplierPointOfContacts, setSupplierPointOfContacts] = useState([
    { value: "POC1", label: "POC1" },
    { value: "POC2", label: "POC2" },
  ]);
  const apiOptions = [
    { label: "Flow Cargoes", value: "cargoes" },
    { label: "Searates", value: "searates" },
  ];
  const [selectedPOC, setSelectedPOC] = useState();
  const [selectedAPI, setSelectedAPI] = useState();
  const [supplierPOC, setSupplierPOC] = useState();

  const [clientList, setClientList] = useState([]);
  const [selectedClientList, setSelectedClientList] = useState([]);

  const [activeItem, setActiveItem] = useState("");
  const [shipmentActive, setShipmentActive] = useState("");
  const [showShipmentType, setShowShipmentType] = useState(false);
  const [lclCargoType, setLclCargoType] = useState(false);
  const [docFieldCount, setDocFieldCount] = useState(0);
  const [totalDocFields, setTotalDocFields] = useState([
    { id: 0, value: "", file: "" },
  ]);

  const [documentOptions, setDocumentOptions] = useState([
    { value: "MSDS", label: "MSDS" },
    { value: "Packing List", label: "Packing List" },
    { value: "Custom Invoice", label: "Custom Invoice" },
    { value: "Insurance Company", label: "Insurance Company" },
    { value: "Pickup Request Form", label: "Pickup Request Form" },
    { value: "Custom Invoice/PL", label: "Custom Invoice/PL" },
    { value: "Other", label: "Other" },
    { value: "DO", label: "DO" },
    { value: "Attachment", label: "Attachment" },
  ]);
  const [inputDisabled, setInputDisabled] = useState(true);
  useEffect(() => {
    // let email = localStorage.getItem("email")//tmsssssssssssssss
    localStorage.setItem("email", "admin@enmovil.in");
    localStorage.setItem("user_type", "admin");
    let email = "admin@enmovil.in";
    redirectURL
      .post("exim/getCurrentContainers", { email: email })
      .then((resp) => {
        if (resp.data.container_data?.length >= 15) {
          navigate("/limit-reached");
        } else if (resp.data.container_data?.length <= 15) {
          // let userEmail = localStorage.getItem("email");
          let userEmail = "admin@enmovil.in";
          setEmail(userEmail);
          setActiveItem("SEA");
          setShipmentActive("FCL");
          // setTrackByValue()
          setShowShipmentType(true);
          // fetchTableItemsForDocs();
          // fetchCustomerProductDetails();
          // fetchSupplierProductDetails();
          // fetchCustomerDetails();
          // fetchSupplierDetails();
          fetchShippingLineDetails();
          setInputDisabled(true);
          fetchPortCodes();
          fetchClients();
        } else {
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [state, setState] = useState({
    portOptions: [],
    portName: { label: "", value: "" },
    showLoader: false,
    freightForwarder: "",
    customHouseAgent: "",
  });

  const handleFilterOptions = (selOption, dropdownName) => {
    console.log("selOption", selOption);
    console.log("dropdownName", dropdownName.name);
    setState({
      ...state,
      [dropdownName.name]: selOption,
    });
  };

  const fetchPortCodes = () => {
    redirectURL
      .get("/exim/fetchPortCodes")
      .then((resp) => {
        if (resp.data.status === "success") {
          //console.log("fetchPortCodes", resp.data);
          setState({
            ...state,
            portOptions: resp.data.portData,
          });
          //   const shippingLineArr = resp.data.shippingLineArr;
          //   console.log("shippingLineArr", shippingLineArr);
          //   // const fetchCustomers = customerDetails.map((e)=>{
          //   //     return e.customer
          //   // })
          //   const shippingLine = shippingLineArr.map((e) => ({
          //     value: e,
          //     label: e,
          //   }));
          //   console.log("shippingLine", shippingLine);
          //   setShippingLine(shippingLine);
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };

  const fetchClients = async () => {
    if (
      localStorage.getItem("user_type") == "vendor" ||
      localStorage.getItem("user_type") == "admin"
    ) {
      await redirectURL
        .post("exim/getVendor", { email: localStorage.getItem("email") })
        .then((response) => {
          if (response.data.status == "success") {
            setVendorID(response.data.vendor_id);
          }
        });
    } else if (
      localStorage.getItem("user_type") == "client" ||
      localStorage.getItem("user_type") == "user"
    ) {
      await redirectURL
        .post("exim/getClient", { email: localStorage.getItem("email") })
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.clientsData?.length > 0) {
              setVendorID(response.data.clientsData?.[0].vendor_id);
              setClientID(response.data.clientsData?.[0].client_id);
            }
          }
        });
    }
  };

  const fetchShippingLineDetails = async () => {
    await redirectURL
      .post("/exim/fetchShippingLineDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const shippingLineArr = resp.data.shippingLineArr.sort();
          // console.log("shippingLineArr", shippingLineArr);
          // const fetchCustomers = customerDetails.map((e)=>{
          //     return e.customer
          // })
          // const shippingLine = shippingLineArr.map((e) => ({
          //   value: e.carrierScac,
          //   label: e.carrierName,
          // }));
          // console.log("shippingLine", shippingLine);
          setShippingLine(shippingLineArr);
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const fetchTableItemsForDocs = async () => {
    // console.log("entered fetch table");
    await redirectURL
      .post("/exim/getCustomerEximDocuments")
      .then((resp) => {
        // console.log("requiredDocs", resp.data);

        let trackingDocuments = resp.data.requiredDocs;
        trackingDocuments = trackingDocuments.map((doc) => ({
          documentName: doc.document_title,
          documentType: doc.document_type,
        }));
        // console.log("trackingDocuments", trackingDocuments);
        setAttachmentsDocArr(trackingDocuments);
        // console.log(attachmentsDocArr);
      })
      .catch((err) => {
        console.log("getEximTrackingDocuments err", err);
      });
  };
  const fetchCustomerDetails = async () => {
    // console.log("customerData", customerData);
    await redirectURL
      .post("/exim/fetchCustomerDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const customerDetails = resp.data.customerDetails;
          // console.log("customerDetails", customerDetails);
          const fetchCustomers = customerDetails.map((e) => {
            return e.customer;
          });

          // console.log("fetchCustomers", fetchCustomers);
          const updateCustomerData = fetchCustomers.map((e) => ({
            value: e,
            label: e,
          }));
          // console.log("updateCustomerData", updateCustomerData);
          let updatedDataNames = [];
          let updatedData = [];
          // console.log(customerData);
          customerData.forEach((each) => {
            if (!updatedDataNames.includes(each.value)) {
              updatedData.push(each);
              updatedDataNames.push(each.value);
            } else {
            }
          });
          setCustomerData(updatedData);

          // console.log(updateCustomerData);
          // console.log(customerData);
          // console.log(updatedData);
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const fetchSupplierDetails = async () => {
    await redirectURL
      .post("/exim/fetchSupplierDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const supplierDetails = resp.data.supplierDetails;
          // console.log("supplierDetails", supplierDetails);
          const fetchSuppliers = supplierDetails.map((e) => {
            return e.supplier;
          });
          const updateSupplierData = fetchSuppliers.map((e) => ({
            value: e,
            label: e,
          }));
          // console.log("updateSupplierData", updateSupplierData);
          let updatedDataNames = [];
          let updatedData = [];
          supplierData.forEach((each) => {
            if (!updatedDataNames.includes(each.value)) {
              updatedData.push(each);
              updatedDataNames.push(each.value);
            }
          });
          setSupplierData(updatedData);
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const fetchCustomerProductDetails = async () => {
    await redirectURL
      .post("/exim/fetchCustomerProductDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const customerProductDetails = resp.data.productDetails;
          // console.log("customerProductDetails 565", customerProductDetails);
          const fetchProducts = customerProductDetails.map((e) => {
            return e.customer_product_name;
          });
          const updateProductData = fetchProducts.map((e) => ({
            value: e,
            label: e,
          }));
          // console.log(updateProductData, "fetched DBdetails");
          let updatedDataNames = [];
          let updatedData = [];

          productData.forEach((each) => {
            if (!updatedDataNames.includes(each.value)) {
              updatedData.push(each);
              updatedDataNames.push(each.value);
            }
          });
          setProductData(updatedData);

          // console.log("404", productData)
        } else {
          console.log("unable to fetch records");
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  const fetchSupplierProductDetails = async () => {
    await redirectURL
      .post("/exim/fetchSupplierProductDetails")
      .then((resp) => {
        if (resp.data.status === "success") {
          const supplierProductDetails = resp.data.productDetails;
          // console.log("supplierProductDetails", supplierProductDetails);
          const fetchProducts = supplierProductDetails.map((e) => {
            return e.product_name;
          });
          const updateProductData = fetchProducts.map((e) => ({
            value: e,
            label: e,
          }));

          let updatedDataNames = [];
          let updatedData = [];
          supplierProductData.forEach((each) => {
            if (!updatedDataNames.includes(each.value)) {
              updatedData.push(each);
              updatedDataNames.push(each.value);
            }
          });

          setSupplierProductData(updatedData);

          // console.log("supplier product data", supplierProductData);
        } else {
          console.log("unable to fetch records");
        }
      })
      .catch((e) => {
        console.log("error message is", e);
      });
  };
  // const [selectShippingLine, setPort] = useState()
  // const onSelectShippingLine = (selectedOption) => {
  //     setPort(selectedOption)
  // }
  const onChangeCustomer = (selectedOption) => {
    if (selectedOption.value === "+Add") {
      setOverly("show-m");
      setCustomer("");
      setOpenCustomerModel(true);
    } else {
      setCustomer(selectedOption);
    }
  };
  const onChangeSupplier = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption.value === "+Add") {
      setOverly("show-m");
      setSupplier("");
      setOpenSupplierModel(true);
    } else {
      setSupplier(selectedOption);
    }
  };

  const onChangeProduct = (selectedOption) => {
    if (selectedOption.value === "+Add") {
      setOverly("show-m");
      setProduct("");
      setOpenProductModel(true);
    } else {
      setProduct(selectedOption);
    }
  };
  const onChangeSupplierProduct = (selectedOption) => {
    if (selectedOption.value === "+Add") {
      setOverly("show-m");
      setSupplierProduct("");
      setSuppilerProductModel(true);
    } else {
      setSupplierProduct(selectedOption);
    }
  };

  const onChangeCustomerReferenceNo = (e) => {
    setCustomerReferenceNo(e.target.value);
  };
  const onChangeInvoiceNo = (e) => {
    setInvoiceNo(e.target.value);
  };
  const onChangeSupplierReferenceNo = (e) => {
    setSupplierReferenceNo(e.target.value);
  };
  const onChangeDemurrageDays = (e) => {
    setDemurrageFreeDays(e.target.value);
  };
  const onChangeDetentionDays = (e) => {
    setDetetentionFreeDays(e.target.value);
  };
  const onChangeCFSDays = (e) => {
    setCFSFreeDays(e.target.value);
  };

  const customerList = [
    { value: "karthik", label: "Karthik" },
    { value: "dheeraj", label: "dheeraj" },
  ];
  const [Owners, setOwners] = useState(customerList);
  const [selectOwner, setSelectOwner] = useState();
  const onSelectOwner = (selectedOption) => {
    setSelectOwner(selectedOption);
  };
  const onSelectFTA = (selectedOption) => {
    setFTAValue(selectedOption);
  };
  const onSelectRDB = (selectedOption) => {
    setRDBValue(selectedOption);
  };
  const onSelectEPCG = (selectedOption) => {
    setEPCGValue(selectedOption);
  };
  const onSelectIncoterms = (selectedOption) => {
    setIncotermsValue(selectedOption);
  };
  const onSelectLC = (selectedOption) => {
    setLCValue(selectedOption);
  };

  const onChangeLCNumber = (e) => {
    setLCNumber(e.target.value);
  };
  // Cross Click functionalities
  const onCloseCustomerModal = () => {
    setOpenCustomerModel(false);
    setOverly("show-n");
  };
  const onCloseProductModal = () => {
    setOpenProductModel(false);
    setOverly("show-n");
  };
  const onCloseSupplierModal = () => {
    setOpenSupplierModel(false);
    setOverly("show-n");
  };
  const onCloseSupplierProductModal = () => {
    setSuppilerProductModel(false);
    setOverly("show-n");
  };

  // Cancel click functionalities
  const onClickHideCustomerModal = () => {
    setOverly("show-n");
    setOpenCustomerModel(false);
  };
  const onClickHideProductModal = () => {
    setOverly("show-n");
    setOpenProductModel(false);
  };
  const onClickHideSupplierModal = () => {
    setOverly("show-n");
    setOpenSupplierModel(false);
  };
  const onClickHideSupplierProductModal = () => {
    setOverly("show-n");
    setSuppilerProductModel(false);
  };
  const [customerReferenceId, setCustomerReferenceId] = useState("");
  const [supplierReferenceId, setSupplierReferenceId] = useState("");

  const onSaveCustomerDetails = async (e) => {
    e.preventDefault();
    const customer = customerName;
    const customer_code = customerCode;
    const admin_name = adminName;
    const admin_email = adminEmail;
    const team = selectedTeam;
    const poc = selectedPOC;
    const product_selected = product;
    const customerManualReferenceId = customerReferenceNo;
    let customerDetails = {};
    if (!customerManualReferenceId) {
      const now = new Date();
      const customer_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
      // console.log(customer_reference_id);
      setCustomerReferenceId(customer_reference_id);
      // await setTimeout(()=>{

      // }, 3000)
      customerDetails = {
        customer,
        customer_code,
        admin_name,
        admin_email,
        team,
        poc,
        product_selected,
        customerReferenceId: customer_reference_id,
      };
      // console.log("internal customer ref id", customerDetails);
    } else {
      customerDetails = {
        customer,
        customer_code,
        admin_name,
        admin_email,
        team,
        poc,
        product_selected,
        customerManualReferenceId,
        shipment_unique_id,
      };
      // console.log("manual customer ref id", customerDetails);
    }

    await redirectURL
      .post("/exim/saveCustomerDetails", customerDetails)
      .then(async (resp) => {
        if (resp.data.status === "success") {
          setShow(true);
          setBasicType("success");
          setBasicTitle(resp.data.message);

          setOpenCustomerModel(false);
          await fetchCustomerDetails();
          // console.log("606",openCustomerModel)
          await setTimeout(() => {
            const addCustomer = [{ value: customerName, label: customerName }];
            setCustomerData((prevState) => [...addCustomer, ...prevState]);
          }, 500);

          // console.log(customerData,"customerData")
          // setCustomerData((prevState)=>{
          //     var updateData = [...prevState];
          //     updateData.splice(0,0,...addCustomer)
          //     return updateData
          // })
          setOverly("show-n");
          setCustomerName("");
          setCustomerCode("");
          setAdminName("");
          setAdminEmail("");
          setSelectTeam({ value: "Default", label: "Default EGA Group" });
          setSelectedPOC("");
          setProduct("");
        } else {
          setShow(true);
          setBasicType("danger");
          setBasicTitle(resp.data.message);
        }
      })
      .catch((e) => {
        console.log("Error message is", e);
      });

    if (customerManualReferenceId) {
      const customer_reference_id = {
        customer_reference_id: customerManualReferenceId,
      };
      await redirectURL
        .post("/exim/updateCustomerReferenceForProduct", customer_reference_id)
        .then((resp) => {
          if (resp.data.status === "success") {
            console.log(resp.data.message);
          }
        })
        .catch((e) => {
          console.log(
            "error message while updating customer reference for product",
            e
          );
        });
    } else {
      const customer_reference_id = {
        customer_reference_id: customerReferenceId,
      };
      await redirectURL
        .post("/exim/updateCustomerReferenceForProduct", customer_reference_id)
        .then((resp) => {
          if (resp.data.status === "success") {
            console.log(resp.data.message);
          }
        })
        .catch((e) => {
          console.log(
            "error message while updating customer reference for product",
            e
          );
        });
    }
  };
  const onSaveSupplierDetails = async (e) => {
    e.preventDefault();
    const supplier = supplierName;
    const admin_name = supplierAdminName;
    const admin_email = supplierAdminEmail;
    const team = supplierSelectedTeam;
    const poc = supplierPOC;
    const product_selected = supplierProduct;
    const supplierManualReferenceId = supplierReferenceNo;
    if (!supplierManualReferenceId) {
      const now = new Date();
      const supplier_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
      setSupplierReferenceId(supplier_reference_id);
      var supplierDetails = {
        supplier,
        admin_name,
        admin_email,
        team,
        poc,
        product_selected,
        supplierReferenceId: supplier_reference_id,
      };
      // console.log("internal supplier reference id", supplierDetails);
    } else {
      var supplierDetails = {
        supplier,
        admin_name,
        admin_email,
        team,
        poc,
        product_selected,
        supplierManualReferenceId,
        shipment_unique_id,
      };
      // console.log("supplier manual reference id", supplierDetails);
    }

    await redirectURL
      .post("/exim/saveSupplierDetails", supplierDetails)
      .then(async (resp) => {
        if (resp.data.status === "success") {
          setShow(true);
          setBasicType("success");
          setBasicTitle(resp.data.message);
          setOpenSupplierModel(false);
          await fetchSupplierDetails();
          await setTimeout(() => {
            const addSupplier = [{ value: supplierName, label: supplierName }];
            setSupplierData((prevState) => [...addSupplier, ...prevState]);
          }, 500);

          setOverly("show-n");
          setSupplierName("");
          setSupplierName("");
          setSupplierAdminName("");
          setSupplierAdminEmail("");
          setSupplierTeam({ value: "Default", label: "Default EGA Group" });
          setSupplierPOC("");
          setSupplierProduct("");
        } else {
          setShow(true);
          setBasicType("danger");
          setBasicTitle(resp.data.message);
        }
      })
      .catch((e) => {
        console.log("Error message is", e);
      });
    if (supplierManualReferenceId) {
      const supplier_reference_id = {
        supplier_reference_id: supplierManualReferenceId,
      };
      await redirectURL
        .post("/exim/updateSupplierReferenceForProduct", supplier_reference_id)
        .then((resp) => {
          if (resp.data.status === "success") {
            console.log(resp.data.message);
          }
        })
        .catch((e) => {
          console.log(
            "error message while updating customer reference for product",
            e
          );
        });
    } else {
      const supplier_reference_id = {
        supplier_reference_id: supplierReferenceId,
      };
      await redirectURL
        .post("/exim/updateSupplierReferenceForProduct", supplier_reference_id)
        .then((resp) => {
          if (resp.data.status === "success") {
            console.log(resp.data.message);
          }
        })
        .catch((e) => {
          console.log(
            "error message while updating customer reference for product",
            e
          );
        });
    }
  };
  const onSaveSupplierProductDetails = async (e) => {
    e.preventDefault();
    const ProductName = supplierProductName;
    const HSCode = supplierHSCode;
    const SelectedCargo = selectedSupplierCargo;
    const SelectedTeamForProduct = selectedSupplierTeamForProduct;

    // const now = new Date();
    // const supplier_product_reference_id = `${now.getTime()}${now.getMilliseconds()}`;

    // console.log(uniqueId);

    const query = {
      supplierName,
      ProductName,
      HSCode,
      SelectedCargo,
      SelectedTeamForProduct,
      shipment_unique_id,
      // supplier_product_reference_id,
      // supplier_reference_id: supplierReferenceId
    };
    if (ProductName.length === 0) {
      setErrorTextForSupplierProducts(true);
    } else {
      // console.log("SupplierProductQuery", query);
      await redirectURL
        .post("/exim/saveSupplierProductDetails", query)
        .then(async (resp) => {
          if (resp.data.status === "success") {
            setShow(true);
            setBasicType("success");
            setBasicTitle(resp.data.message);
            setSuppilerProductModel(false);
            await fetchSupplierProductDetails();
            await setTimeout(() => {
              const addProduct = [
                { value: supplierProductName, label: supplierProductName },
              ];
              setSupplierProductData((prevState) => [
                ...addProduct,
                ...prevState,
              ]);
            }, 500);

            setOverly("show-n");
            setSupplierProductName("");
            setSupplierHSCode("");
            setSupplierCargo("");
            setSelectSupplierTeamForProduct("");
          } else {
            setShow(true);
            setBasicType("danger");
            setBasicTitle(resp.data.message);
          }
        })
        .catch((e) => {
          console.log("Error message is", e);
        });
    }
    // console.log(query, "query");
  };
  const onSaveProductDetails = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const customerSelectedTeamForProduct = selectedTeamForProduct.map(
      (item) => {
        return item.value;
      }
    );
    formData.append("customer_name", customerName);
    formData.append("customer_product_name", productName);
    formData.append("customer_hs_code", hsCode);
    formData.append("customer_selected_cargo", selectedCargo.label);
    formData.append("customer_selected_team", customerSelectedTeamForProduct);
    formData.append("customer_trade_type", tradeType);
    formData.append("shipment_unique_id", shipment_unique_id);
    formData.append("product_files", tradeFiles);

    // const customerProductName = productName;
    // const customerHSCode = hsCode;
    // const customerSelectedCargo = selectedCargo.label;
    // const customerSelectedTeamForProduct = selectedTeamForProduct.map(item=>{
    //   return item.value
    // });
    // const customerFiles=tradeFiles
    // const customerTradeType=tradeType

    // const now = new Date();
    // const customer_product_reference_id = `${now.getTime()}${now.getMilliseconds()}`;

    // console.log(uniqueId);

    // const query = {
    //   customerName,
    //   customerProductName,
    //   customerHSCode,
    //   customerSelectedCargo,
    //   customerSelectedTeamForProduct,
    //   customerFiles,
    //   customerTradeType,
    //   shipment_unique_id,
    //   // customer_product_reference_id,
    //   // customer_reference_id: customerReferenceId
    // };
    //console.log(customerProductName.length,customerHSCode.length,customerSelectedCargo,customerSelectedTeamForProduct,customerFiles,"fliesdetails");
    // if (productName.length === 0 || hsCode.length===0||selectedCargo.label.length===0 ||customerSelectedTeamForProduct.length===0||(tradeFiles=== undefined && tradeFiles=== "" )) {
    //   setErrorTextForCustomerProducts(true);
    // } else {
    //console.log("customerProductQuery", query);
    await redirectURL
      .post("/exim/saveCustomerProductDetails", formData)
      .then(async (resp) => {
        if (resp.data.status === "success") {
          setShow(true);
          setBasicType("success");
          setBasicTitle(resp.data.message);
          setOpenProductModel(false);
          await fetchCustomerProductDetails();

          await setTimeout(() => {
            // console.log(productData, "before");
            const addProduct = [{ value: productName, label: productName }];
            setProductData((prevState) => [...addProduct, ...prevState]);
            // console.log(productData, "after save product Data is");
          }, 500);
          // console.log("538",openProductModel)

          setOverly("show-n");
          setProductName("");
          setHSCode("");
          setCargo("");
          setSelectTeamForProduct("");
          // add the product name as value label and update the productData here
        } else {
          setShow(true);
          setBasicType("danger");
          setBasicTitle(resp.data.message);
        }
      });
    // save product details to exim_products
    //}
    // console.log(query, "query");
  };

  const onChangeCustomerName = (e) => {
    setCustomerName(e.target.value);
    setIsSelectDisabled(false);
  };
  const validateCustomerName = (e) => {
    if (customerName.length === 0) {
      setIsSelectDisabled(true);
    }
  };
  const [isSupplierSelectDisabled, setIsSupplierSelectDisabled] =
    useState(true);
  const onValidateSupplierName = (e) => {
    if (supplierName.length === 0) {
      setIsSupplierSelectDisabled(true);
    }
  };
  const onChangeSupplierName = (e) => {
    setSupplierName(e.target.value);
    setIsSupplierSelectDisabled(false);
  };

  const onChangeCustomerCode = (e) => {
    setCustomerCode(e.target.value);
  };

  const onChangeAdminEmail = (e) => {
    setAdminEmail(e.target.value);
  };
  const onChangeSupplierAdminEmail = (e) => {
    setSupplierAdminEmail(e.target.value);
  };

  const onChangeAdminName = (e) => {
    setAdminName(e.target.value);
  };
  const onChangeSupplierAdminName = (e) => {
    setSupplierAdminName(e.target.value);
  };

  const onSelectTeam = (selectedOption) => {
    setSelectTeam(selectedOption);
    setEnablePOC(false);
  };
  const onSelectSupplierTeam = (selectedOption) => {
    setSupplierTeam(selectedOption);
    setSupplierEnablePOC(false);
  };

  const onSelectTeamForProduct = (selectedOption) => {
    setSelectTeamForProduct(selectedOption);
  };

  const onSelectClientList = (selectedOption) => {
    const temp = selectedOption.find((e) => e.value == "none");
    if (temp != undefined)
      setSelectedClientList([{ label: "None", value: "none" }]);
    else setSelectedClientList(selectedOption);
  };

  const onChangeAPI = (selectedOption) => {
    setSelectedAPI(selectedOption);
  };

  const onSelectSupplierTeamForProduct = (selectedOption) => {
    setSelectSupplierTeamForProduct(selectedOption);
  };

  const onSelectPOC = (selectedOption) => {
    setSelectedPOC(selectedOption);
  };
  const onSelectSupplierPOC = (selectedOption) => {
    setSupplierPOC(selectedOption);
  };

  const enableShipmentType = () => {
    setShowShipmentType(true);
    setActiveItem("SEA");
  };
  const disbaleShipmentType = () => {
    setShowShipmentType(false);
    setActiveItem("AIR");
  };
  const enableLclCargoTypeDropDown = () => {
    setLclCargoType(true);
    setShipmentActive("LCL");
  };
  const disableLclCargoTypeDropDown = () => {
    setLclCargoType(false);
    setShipmentActive("FCL");
  };

  const onSelectDocumentType = (selectedOption) => {
    setSelectedDocType(selectedOption);
  };
  const [openDocUploadSlider, setOpenDocUploadSlider] = useState("");

  const onAddDocuments = (e) => {
    e.preventDefault();
    if (trackByValue && trackByValue.value === "") {
      setOpenDocUploadSlider("");
    } else {
      // console.log(customerReferenceId, "1186");
      // console.log("here");
      setOverly("show-m");
      setBasicTitle("");
      setBasicType("");
      setShow("");
      setOpenDocUploadSlider("slider-translate");
    }
  };
  const onClickHideAll = () => {
    setOverly("show-n");
    setOpenDocUploadSlider("");
  };
  const onDeleteDocument = (id) => {
    const updatedTotalDOcFields = totalDocFields.filter(
      (field) => field.id != id
    );
    setTotalDocFields(updatedTotalDOcFields);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const onUploadDocument = (e, id) => {
    // console.log(e, "e");
    // console.log(id, "id");
    const file = e.target.files[0];
    const fieldId = id;

    const ogData = [...totalDocFields];

    const upOgData = ogData.map(
      (f) => (f.id = "shipmentInput" == fieldId ? { ...f, file: file.name } : f)
    );
    setTotalDocFields(upOgData);
    // console.log("totalDocFields", totalDocFields);
  };
  const [tradeFiles, setTradeFiles] = useState();
  const [tradeType, setTradeType] = useState();
  const changeTradeFilesHandler = (event) => {
    const file = event.target.files[0];
    console.log("file", file);
    setTradeType(event.target.getAttribute("id"));
    let validFileExtensions = [
      "png",
      "PNG",
      "jpeg",
      "jpg",
      "JPEG",
      "pdf",
      "xlsx",
      "xls",
    ];
    const documentExtension = file.name.split(".").pop();
    console.log("documentExtension", documentExtension);
    if (validFileExtensions.includes(documentExtension)) {
      setTradeFiles(file);
    } else {
      document.getElementById(event.target.getAttribute("id")).value = null;
      setShow(true);
      setBasicType("danger");
      setBasicTitle("Invalid file format.");
    }
  };

  const changeAttachmentsFileHandler = (event) => {
    const file = event.target.files[0];
    // console.log("file", file);
    let validFileExtensions = ["png", "jpeg", "pdf", "xlsx"];
    const documentExtension = file.name.split(".").pop();
    // console.log("documentExtension", documentExtension);

    if (validFileExtensions.includes(documentExtension)) {
      const previousFile = attachmentsDoc;
      if (file && file !== previousFile) {
        setIsAttachmentDocsChanged(true);
        setAttachmentsDoc(file);
        setDocumentExtension(documentExtension);
      } else {
        setIsAttachmentDocsChanged(false);
      }
    } else {
      document.getElementById("attachmentsDoc").value = null;
      setShow(true);
      setBasicType("danger");
      setBasicTitle("Invalid file format.");
    }
  };

  const handleAddAttachmentsBtn = (e) => {
    e.preventDefault();
    if (attachmentTitle && attachmentsDoc) {
      // console.log("attachmentsDoc", attachmentsDoc);
      const formData = new FormData();
      // formData.append("exim_container_no", this.state.container_no);
      if (trackByValue && trackByValue.value === "Container Number") {
        formData.append("container_no", containerNo);
      } else if (trackByValue && trackByValue.value === "BL Number") {
        formData.append("bill_of_lading_no", billOfLading);
      }
      formData.append("created_by", email);
      formData.append("document_title", attachmentTitle);
      formData.append("document_type", selectedDocType.value);
      formData.append("exim_createshipment_doc", attachmentsDoc);
      formData.append("document_extension", documentExtension);
      //   formData.append("customer_reference_mapping_id", customerReferenceNo);
      if (customerReferenceId) {
        formData.append("customer_reference_id", customerReferenceId);
      } else if (customerReferenceNo) {
        formData.append("customer_reference_id", customerReferenceNo);
      } else if (supplierReferenceNo) {
        formData.append("supplier_reference_id", supplierReferenceNo);
      } else if (supplierReferenceId) {
        formData.append("supplier_reference_id", supplierReferenceId);
      }
      formData.append("shipment_unique_id", shipment_unique_id);
      // console.log(formData, "1290");
      if (isAttachmentsDocChanged) {
        redirectURL
          .post("/exim/saveCreateShipmentTrackingDocuments", formData)
          .then((response) => {
            if (response.data.status === "success") {
              console.log("response.data56", response.data);

              document.getElementById("attachmentsDoc").value = null;
              setSelectedDocType("");
              setAttachmentTitle("");
              setDocumentExtension("");
              onClickHideAll();
              setAttachmentsDocArr((prevState) => [
                ...prevState,
                {
                  documentName: response.data.document_title,
                  documentType: response.data.document_type,
                  createdBy: email,
                },
              ]);
              setShow(true);
              setBasicTitle("Document added successfully");
              setBasicType("success");
            }
          });
      }
    } else {
      setShow(true);
      setBasicTitle("Error uploading Document");
      setBasicType("danger");
    }
  };
  const deleteEximDocument = async (id, documentDetails) => {
    // console.log("documentDetails", documentDetails);
    // console.log("id", id);

    let payload = {
      documentName: documentDetails.documentName,
    };
    // console.log("payload", payload);
    await redirectURL
      .post("/exim/deleteCustomerEximDocument", payload)
      .then(async (resp) => {
        // console.log("deleteEximDocument", resp.data);
        if (resp.data.success) {
          //   await this.getEximTrackingDocuments();
          fetchTableItemsForDocs();
          setShow(true);
          setBasicType("success");
          setBasicTitle(resp.data.message);
        }
      })
      .catch((err) => {
        console.log("deleteEximDocument err", err);
      });
  };

  useEffect(() => {
    // console.log("trackByValue selectShippingLine", trackByValue, selectShippingLine)
    console.log(trackByValue, selectShippingLine, "tttttttttttttttttttt");
    if (trackByValue.value !== "" && selectShippingLine.value !== "") {
      if (trackByValue.value === "Container Number" && containerNo != "") {
        setSubmitDisable(null);
      } else if (trackByValue.value === "BL Number" && billOfLading != "") {
        setSubmitDisable(null);
      } else {
        setSubmitDisable(true);
      }
    } else {
      setSubmitDisable(true);
    }
  }, [trackByValue, selectShippingLine, containerNo, billOfLading]);

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      showLoader: true,
    });
    window.scrollTo(0, 0);
    if (
      trackByValue &&
      trackByValue.value !== "" &&
      selectShippingLine &&
      // selectMovementType &&
      selectShippingLine.value !== ""
      // selectMovementType.value !== "" &&
      // state.portName.value !== ""
    ) {
      console.log("entered");
      const shipmentDetails = {
        contract_number: contractNumber,
        bill_of_lading_date: billOfLadingDate,
        transit_days: transitDays,
        po_no: PONumber,
        pick_up_date: pickUpDate,
        origin_port: customerOriginPort,
        stuffing_location: customerStuffingLocation,
        destination_location: customerDestinationPort,
        destuffing_location: customerDestuffingLocation,
        invoice_no: invoiceNo,
        invoice_date: invoiceDate,
        free_trade_agreement: FTAValue.value,
        rdb_value: RDBValue.value,
        epcg_value: EPCGValue.value,
        inco_terms: IncotermsValue.value,
        // demurrage_free_days: demurrageFreeDays,
        detention_free_days: detentionFreeDays,
        // cfs_free_days: CFSFreeDays,
        demurrage_and_detention_port: state.portName.label,
        demurrage_and_detention_port_code: state.portName.value,
      };
      if (customerReferenceId) {
        shipmentDetails.customer_reference_id = customerReferenceId;
      } else if (customerReferenceNo) {
        shipmentDetails.customer_reference_id = customerReferenceNo;
      } else if (supplierReferenceId) {
        shipmentDetails.supplier_reference_id = supplierReferenceId;
      } else if (supplierReferenceNo) {
        shipmentDetails.supplier_reference_id = supplierReferenceNo;
      }

      if (selectMovementType && selectMovementType.value !== "") {
        shipmentDetails.movement_type = selectMovementType.value;
      } else {
        shipmentDetails.movement_type = "";
      }
      // if(shippingLine && shippingLine.value !==""){
      //     shipmentDetails.shipping_line = selectShippingLine.value;
      // }
      if (selectOwner && selectOwner.value !== "") {
        shipmentDetails.shipment_owner = selectOwner.value;
      } else {
        shipmentDetails.shipment_owner = "";
      }
      if (customer && customer.value !== "") {
        shipmentDetails.customer = customer.value;
      } else {
        shipmentDetails.customer = "";
      }
      if (selectShippingLine && selectShippingLine.value !== "") {
        shipmentDetails.shipping_line = selectShippingLine;
      } else {
        shipmentDetails.shipping_line = "";
      }

      if (supplier && supplier.value !== "") {
        shipmentDetails.supplier = supplier.value;
      } else {
        shipmentDetails.supplier = "";
      }

      if (LCValue && LCValue.value !== "") {
        shipmentDetails.letter_of_credit = LCValue.value;
      } else {
        shipmentDetails.letter_of_credit = "";
      }

      // if (shipperType) {
      //   shipmentDetails.shipper_type = shipperType;
      // } else {
      //   shipmentDetails.shipper_type = "";
      // }

      shipmentDetails.freightForwarder = state.freightForwarder;
      shipmentDetails.customHouseAgent = state.customHouseAgent;

      if (trackByValue && trackByValue.value === "Container Number") {
        shipmentDetails.container_no = containerNo;
        shipmentDetails.shipment_created_with = "container_no";
      } else {
        shipmentDetails.bill_of_lading_no = billOfLading;
        shipmentDetails.shipment_created_with = "mbl_number";
      }

      if (activeItem === "SEA") {
        shipmentDetails.cargo_mode = "SEA";
        if (shipmentActive === "FCL") {
          shipmentDetails.shipment_type = "FCL";
          if (selectCargoType && selectCargoType.value !== "") {
            shipmentDetails.selected_cargo_type = selectCargoType.value;
          }
        } else {
          shipmentDetails.shipment_type = "LCL";
          if (selectLclCargoType && selectLclCargoType.value !== "") {
            shipmentDetails.selected_cargo_type = selectLclCargoType.value;
          }
        }
      } else {
        shipmentDetails.cargo_mode = "AIR";
        if (selectCargoType && selectCargoType.value !== "") {
          shipmentDetails.selected_cargo_type = selectCargoType.value;
        }
      }
      console.log("LCValue", LCValue);
      if (LCValue && LCValue.value === "yes") {
        shipmentDetails.lc_no = LCNumber;
      }

      shipmentDetails["shipment_unique_id"] = shipment_unique_id;
      shipmentDetails["created_by"] = localStorage.getItem("email");
      //console.log(shipmentDetails,"Shipment Details");

      if (
        localStorage.getItem("user_type") == "admin" ||
        localStorage.getItem("user_type") == "vendor"
      ) {
        shipmentDetails["vendor_id"] = vendorID;
        shipmentDetails["client_id"] = "";
      } else if (
        localStorage.getItem("user_type") == "client" ||
        localStorage.getItem("user_type") == "user"
      ) {
        shipmentDetails["vendor_id"] = vendorID;
        shipmentDetails["client_id"] = clientID;
      }
      // if (selectedAPI.value != "" && selectedAPI.value != undefined)
      //   shipmentDetails["api"] = selectedAPI.value

      await redirectURL
        .post("/exim/storeCreatedShipmentDetails", shipmentDetails)
        .then((resp) => {
          console.log("storeCreatedShipmentDetails", resp.data);
          let message = resp.data.hasOwnProperty("output")
            ? resp.data.output.message
            : resp.data.message;
          if (resp.data.status == 200 || resp.data.status === "success") {
            //console.log("shipment create",resp.data.status);
            setShow(true);
            setBasicTitle(message);
            setBasicType("success");

            setContainerNo("");
            setState({
              ...state,
              showLoader: false,
            });
            const clickedButton = e.nativeEvent.submitter;
            if (
              // clickedButton.name === "save_and_submit_button" &&
              resp.data.status == 200 ||
              resp.data.status === "success"
            ) {
              setRedirect(true);
            }

            // if (
            //   clickedButton.name === "save_button" &&
            //   (resp.data.status == 200 || resp.data.status==="success")
            // ) {
            //   setRedirect(true);
            // }
            // window.location.reload()
            // setContractNumber("");
            // setSelectMovementType("");
            // setSelectCargoType({ value: "", label: "" });
            // setTrackByValue({ value: "", label: "" });

            // setBillOfLadingDate("");
            // setShippingLine({ value: "", label: "" });
            // setTransitDays("");
            // setCustomer({ value: "", label: "" });
            // setShippingLine({value:"", label:""});
            // setCustomerReferenceNo("");
            // setSelectOwner("");
            // setSupplier("");
            // setSupplierReferenceNo("");
            // setPONumber("");
            // setShipperType("");
            // setPickUpDate("");
            // setCustomerOriginPort("");
            // setCustomerStuffingLocation("");
            // setCustomerDestinationPort("");
            // setCustomerDestuffingLocation("");
            // setInvoiceNo("");
            // setInvoiceDate("");
            // setFTAValue({ value: "", label: "" });
            // setRDBValue({ value: "", label: "" });
            // setEPCGValue({ value: "", label: "" });
            // setLCValue({ value: "", label: "" });
            // setLCNumber("");
            // setDemurrageFreeDays("");
            // setDetetentionFreeDays("");
            // setCFSFreeDays("");
            // setAttachmentsDocArr("");
            // setCustomerReferenceId("");
            // setSupplierReferenceId("");

            // setInvoiceDate("");
            // setBillOfLadingDate("");
            // setPickUpDate("");
            // document.getElementById("invoiceDate").value = "";
            // document.getElementById("pickUpDate").value = "";
            // document.getElementById("billOfLadingDate").value = "";
          } else {
            setShow(true);
            setBasicTitle(message);
            setBasicType("danger");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (trackByValue && trackByValue.value === "") {
      // console.log("enter trackBy Value")
      setShow(true);
      setBasicTitle("Select Track By Type");
      setBasicType("danger");
    } else if (selectShippingLine && selectShippingLine.value === "") {
      // console.log("enter trackBy Value")
      setShow(true);
      setBasicTitle("Select Shipping Line");
      setBasicType("danger");
    } else if (selectMovementType && selectMovementType.value === "") {
      setShow(true);
      setBasicTitle("Select Movement Type");
      setBasicType("danger");
    }
  };
  return (
    <>
      <BootstrapLoader showLoader={state.showLoader} />
      <ol
        className="breadcrumb"
        style={{ background: "none", height: "52px", marginLeft: "21px" }}
      >
        <li className="breadcrumb-item" style={{ height: "40px" }}>
          <Link
            to={"/container-tracking"}
            style={{
              textDecoration: "none",
              position: "relative",
              top: "2px",
            }}
          >
            Container Tracking
          </Link>
        </li>
      </ol>
      <h5 className="col-sm-12 mb-15p fbold f20">
        <i className="icofont icofont-law-document cus-i"></i>&nbsp;
        <span>Create Shipment</span>
        <Favourites />
      </h5>

      <div
        className=""
        id="create-shipment"
        style={{ minHeight: "87vh", width: "95%", margin: "auto" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div
              className="card custom-card"
              style={{
                padding: "20px",
                borderRadius: "18px",
                marginLeft: "-14px",
                paddingLeft: "36px",
                paddingTop: "30px",
              }}
            >
              <div className="card-body" style={{ padding: "0px" }}>
                <Alert
                  variant="warning"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <FiAlertTriangle size={40} />
                  &nbsp; &nbsp; Note: Please review the Container/BL Number
                  carefully as it cannot be edited after submission
                </Alert>
                {/* <label style={{color:"#EB1D54" , fontSize:"13px", fontFamily:"inherit"}}>*
                <label style={{color:"black" , fontSize:"11px", fontFamily:"inherit"}}>Required Fields</label> </label> */}
                <form
                  method="POST"
                  className="form-theme"
                  onSubmit={onSubmitForm}
                >
                  <div className="row" style={{ marginBottom: "25px" }}>
                    <div className="col-xl-12 col-lg-12">
                      <div className="form-group row">
                        {/* <div className="col-xl-2 col-lg-2">
                          <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*</label>
                          <label className="custom-label">
                            Select API
                          </label>
                          <Select
                            placeholder=""
                            closeMenuOnSelect={true}
                            value={selectedAPI}
                            styles={customStyles2}
                            onChange={onChangeAPI}
                            options={apiOptions}
                            required />
                        </div> */}
                        {/* {
                          (localStorage.getItem('user_type') == 'admin' || localStorage.getItem('user_type') == 'vendor') ?
                            <>
                              <div className="pl-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*</label>
                                <label className="custom-label">
                                  Select Clients
                                </label>
                                <Select
                                  placeholder=""
                                  closeMenuOnSelect={true}
                                  value={selectedClientList}
                                  styles={customStyles2}
                                  onChange={onSelectClientList}
                                  options={clientList}
                                  isMulti={true}
                                  required
                                />
                              </div>
                            </> : <></>
                        } */}

                        <div className="col-xl-2 col-lg-2">
                          <label
                            style={{
                              color: "#EB1D54",
                              fontSize: "13px",
                              fontFamily: "inherit",
                            }}
                          >
                            *
                          </label>
                          <label className="custom-label">
                            Contract Number
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              // backgroundColor: "#f2f4f6",
                              border: "1px solid #ced4da",
                              height: "2.5rem",
                            }}
                            type="text"
                            placeholder=""
                            id="shipmentInput"
                            value={contractNumber}
                            autoComplete="off"
                            onChange={onChangeContractNumber}
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                          <label
                            style={{
                              color: "#EB1D54",
                              fontSize: "13px",
                              fontFamily: "inherit",
                            }}
                          >
                            *
                          </label>
                          <label className="custom-label">
                            Select Movement Type
                          </label>
                          <Select
                            placeholder=""
                            closeMenuOnSelect={true}
                            value={selectMovementType}
                            // className="form-control"
                            styles={customStyles}
                            id="shipmentInput"
                            onChange={onSelectMovementType}
                            options={movementType}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{}}>
                    <div className="col-xl-12 col-lg-12">
                      <h6 className="custom-header">Shipment Details</h6>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "25px" }}>
                    {/* <div className={`${activeItem === "SEA"? 'col-xl-3 col-lg-6':"col-xl-5 col-lg-5"}`}> */}
                    <div
                      className="col-xl-2 col-lg-2"
                      style={{ marginRight: "10px" }}
                    >
                      <div className="row">
                        <div
                          className="col-xl-12 col-lg-12"
                          style={{
                            paddingTop: "12px",
                            padding: "12px",
                            marginRight: "10px",
                            borderRadius: "13px",
                          }}
                        >
                          <div
                            className="shipmenttype-card"
                            style={{ border: "1px" }}
                          >
                            <ul className="icon-list">
                              <li
                                className={`icon-item ${
                                  activeItem === "SEA" ? "active" : ""
                                }`}
                                onClick={enableShipmentType}
                              >
                                <FaShip
                                  className={`icon ${
                                    activeItem === "SEA" ? "active-icon" : ""
                                  }`}
                                />
                                <span
                                  className={`icon-label ${
                                    activeItem === "SEA" ? "active-label" : ""
                                  }`}
                                >
                                  SEA
                                </span>
                              </li>
                              <li
                                className={`icon-item ${
                                  activeItem === "AIR" ? "active" : ""
                                }`}
                                onClick={disbaleShipmentType}
                              >
                                <FaPlane
                                  className={`icon ${
                                    activeItem === "AIR" ? "active-icon" : ""
                                  }`}
                                />
                                <span
                                  className={`icon-label ${
                                    activeItem === "AIR" ? "active-label" : ""
                                  }`}
                                >
                                  AIR
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showShipmentType ? (
                      <div
                        className="col-xl-2 col-lg-2"
                        style={{
                          paddingTop: "12px",
                          padding: "12px",
                          marginRight: "10px",
                          borderRadius: "13px",
                        }}
                      >
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <div
                              className="shipmenttype-card"
                              style={{ border: "1px" }}
                            >
                              <ul className="icon-list">
                                <li
                                  className={`icon-item ${
                                    shipmentActive === "FCL" ? "active" : ""
                                  }`}
                                  onClick={disableLclCargoTypeDropDown}
                                >
                                  <FaBox
                                    className={`icon ${
                                      shipmentActive === "FCL"
                                        ? "active-icon"
                                        : ""
                                    }`}
                                  />
                                  <span
                                    className={`icon-label ${
                                      shipmentActive === "FCL"
                                        ? "active-label"
                                        : ""
                                    }`}
                                  >
                                    FCL
                                  </span>
                                </li>
                                <li
                                  className={`icon-item ${
                                    shipmentActive === "LCL" ? "active" : ""
                                  }`}
                                  onClick={enableLclCargoTypeDropDown}
                                >
                                  <FaBox
                                    className={`icon ${
                                      shipmentActive === "LCL"
                                        ? "active-label"
                                        : ""
                                    }`}
                                  />
                                  <span
                                    className={`icon-label ${
                                      shipmentActive === "LCL"
                                        ? "active-label"
                                        : ""
                                    }`}
                                  >
                                    LCL
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="col-xl-3 col-lg-">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6"  style={{paddingTop:"12px",padding:"12px", background:"#f2f6ff", marginRight:"10px", borderRadius:"13px"}}>
                                    <div className="shipmenttype-card" style={{}}>
                                        <ul className="icon-list">
                                            <li className={`icon-item ${activeItem === 'SEA'? 'active': ""}`} onClick={enableShipmentType}>
                                                <FaShip className={`icon ${activeItem === 'SEA' ? 'active-icon' : ''}`} />
                                                <span className={`icon-label ${activeItem === 'SEA' ? 'active-label': ""}`}>SEA</span>
                                            </li>
                                            <li className={`icon-item ${activeItem === "AIR" ? 'active': ""}`} onClick={disbaleShipmentType}>
                                                <FaPlane className={`icon ${activeItem === 'AIR' ? 'active-icon' : ''}`} />
                                                <span className={`icon-label ${activeItem === 'AIR' ? 'active-label': ""}`}>AIR</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {showShipmentType ?<div className="col-xl-6 col-lg-6" style={{paddingTop:"12px",padding:"12px", background:"#f2f6ff", marginRight:"10px", borderRadius:"13px"}}>
                                    <div className="shipmenttype-card">
                                        <ul className="icon-list">
                                            <li className={`icon-item ${shipmentActive === "FCL"? 'active': ""}`} onClick={disableLclCargoTypeDropDown}>
                                                <FaBox className={`icon ${shipmentActive === 'FCL' ? 'active-icon' : ''}`} />
                                                <span className={`icon-label ${shipmentActive === "FCL" ? 'active-label':""}`}>FCL</span>
                                            </li>
                                            <li className={`icon-item ${shipmentActive === "LCL"? 'active': ""}`} onClick={enableLclCargoTypeDropDown}>
                                                <FaBox className={`icon ${shipmentActive === "LCL" ? 'active-label':""}`} />
                                                <span className={`icon-label ${shipmentActive === "LCL" ? 'active-label':""}`}>LCL</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>:""}
                            </div>
                        </div> */}
                    {!lclCargoType || activeItem === "AIR" ? (
                      <div className="col-xl-2 col-lg-2">
                        <label
                          style={{
                            color: "#EB1D54",
                            fontSize: "13px",
                            fontFamily: "inherit",
                          }}
                        >
                          *
                        </label>
                        <label className="custom-label">Type of Shipment</label>
                        <Select
                          placeholder=""
                          style={{ fontSize: "10px" }}
                          closeMenuOnSelect={true}
                          value={selectCargoType}
                          // className="form-control"
                          styles={customStyles}
                          onChange={onSelectCargoType}
                          options={cargoType}
                          id="shipmentInput"
                          required
                        />
                      </div>
                    ) : (
                      <div className="col-xl-2 col-lg-2">
                        <label
                          style={{
                            color: "#EB1D54",
                            fontSize: "13px",
                            fontFamily: "inherit",
                          }}
                        >
                          *
                        </label>
                        <label className="custom-label">Type of Shipment</label>
                        <Select
                          placeholder=""
                          closeMenuOnSelect={true}
                          value={selectLclCargoType}
                          // className="form-control"
                          styles={customStyles}
                          onChange={onSelectLclCargoType}
                          options={lclCargoList}
                          id="shipmentInput"
                          required
                        />
                      </div>
                    )}
                    <div className="col-xl-2 col-lg-2">
                      <label
                        style={{
                          color: "#EB1D54",
                          fontSize: "13px",
                          fontFamily: "inherit",
                        }}
                      >
                        *
                      </label>
                      <label className="custom-label">Shipping Line</label>
                      <Select
                        placeholder=""
                        closeMenuOnSelect={true}
                        value={selectShippingLine}
                        id="shipmentInput"
                        styles={customStyles}
                        onChange={onSelectShippingLine}
                        options={shippingLine}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "25px" }}>
                    <div className="col-xl-2 col-lg-2">
                      <label
                        style={{
                          color: "#EB1D54",
                          fontSize: "13px",
                          fontFamily: "inherit",
                        }}
                      >
                        *
                      </label>
                      <label className="custom-label">
                        Shipment Created With
                      </label>
                      <Select
                        placeholder=""
                        closeMenuOnSelect={true}
                        value={trackByValue}
                        id="shipmentInput"
                        // className="form-control"
                        styles={customStyles}
                        onChange={onSelectTrackByType}
                        options={trackByList}
                      />
                    </div>
                    {trackByValue && trackByValue.value === "BL Number" ? (
                      <div className="col-xl-3 col-lg-3">
                        <label
                          style={{
                            color: "#EB1D54",
                            fontSize: "13px",
                            fontFamily: "inherit",
                          }}
                        >
                          *
                        </label>
                        <label className="custom-label">MBL Number</label>
                        <input
                          style={{
                            borderRadius: "6px",
                            // backgroundColor: "#f2f4f6",
                            border: "1px solid #ced4da",
                            height: "2.5rem",
                          }}
                          type="text"
                          placeholder=""
                          id="shipmentInput"
                          value={billOfLading}
                          onChange={onChangeBillOfLading}
                          autoComplete="off"
                          className="form-control"
                          required
                        />
                      </div>
                    ) : (
                      <div className="col-xl-2 col-lg-2">
                        <label
                          style={{
                            color: "#EB1D54",
                            fontSize: "13px",
                            fontFamily: "inherit",
                          }}
                        >
                          *
                        </label>
                        <label className="custom-label">Container Number</label>
                        <input
                          style={{
                            borderRadius: "6px",
                            // backgroundColor: "#f2f4f6",
                            border: "1px solid #ced4da",
                            height: "2.5rem",
                          }}
                          type="text"
                          placeholder=""
                          id="shipmentInput"
                          value={containerNo}
                          autoComplete="off"
                          onChange={onChangeContainerNo}
                          onBlur={validateContainerNo}
                          className="form-control"
                          disabled={inputDisabled}
                          required
                        />
                        {errorMssge ? (
                          <div className="error-mssge">{errorMssge}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div className="col-xl-2 col-lg-2">
                      <label className="custom-label">
                        Bill of Landing Date
                      </label>
                      <div>
                        <DateTime
                          //className="date-time-container"
                          inputProps={{
                            id: "datetimeid",
                            name: "billOfLadingDate",
                            style: {
                              height: "2.5rem",
                              borderRadius: "6px",
                            },
                          }}
                          value={billOfLadingDate}
                          onChange={onHandleDateChange}
                          wrapperClassName="datepick"
                          //closeOnSelect
                        />
                      </div>
                    </div>
                    {/* <div className="col-xl-2 col-lg-2">
                            <label className="custom-label">Shipping Line</label>
                            <Select
                                placeholder="Shipping Line"
                                closeMenuOnSelect={true}
                                value={selectShippingLine}
                                // className="form-control" 
                                id="shipmentInput" 
                                styles={customStyles}  
                                onChange={onSelectShippingLine}
                                options={shippingLine}
                            />
                        </div> */}
                    {/* tjis */}
                    <div className="col-xl-2 col-lg-2">
                      <label className="custom-label">
                        Promised Transit Days
                      </label>
                      <input
                        style={{
                          borderRadius: "6px",
                          // backgroundColor: "#f2f4f6",
                          border: "1px solid #ced4da",
                          height: "2.5rem",
                        }}
                        type="number"
                        placeholder=""
                        id="shipmentInput"
                        value={transitDays}
                        onChange={onChangeTransitDays}
                        autoComplete="off"
                        className="form-control"
                        min={0}
                        //required
                      />
                    </div>
                    {/* <div className="col-xl-2 col-lg-2">
                            <label className="custom-label">Incoterms</label>
                            <Select
                                placeholder="Incoterms"
                                closeMenuOnSelect={true}
                                value={selectIncoterm}
                                styles={customStyles} 
                                // className="form-control" 
                                onChange={onSelectIncoterm}
                                options={Incoterms}
                                isMulti={true}
                            />
                        </div> */}
                  </div>
                  {/* <div className="row">
                        <div className="col-xl-2 col-lg-2">
                            <label className="custom-label">No of Containers</label>
                            <input style={{borderRadius:"6px", backgroundColor:"#f2f4f6", border:"none"}}
                                type="number"
                                placeholder="contract number"
                                id="shipmentInput""contract_number"
                                value={containerCount}
                                autoComplete="off"
                                onChange={onChangeContainerCount}
                                onBlur={generateContainerBoxes}
                                className="form-control"
                                style={{marginBottom:"15px"}}
                                min={0}
                                //required
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <label className="custom-label">Port of loading</label>
                            <input style={{borderRadius:"6px", backgroundColor:"#f2f4f6", border:"none"}}
                                type="text"
                                placeholder="Origin"
                                value={originPort}
                                autoComplete="off"
                                onChange={onChangePortOfOrigin}
                                className="form-control"
                                //required
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <label className="custom-label">Port of Discharge</label>
                            <input style={{borderRadius:"6px", backgroundColor:"#f2f4f6", border:"none"}}
                                type="text"
                                placeholder="Discharge"
                                value={destinationPort}
                                autoComplete="off"
                                onChange={onChangePortOfDestination}
                                className="form-control"
                                //required
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <label className="custom-label">Incoterms</label>
                            <Select
                                placeholder="Incoterms"
                                closeMenuOnSelect={true}
                                value={selectIncoterm}
                                styles={customStyles} 
                                // className="form-control" 
                                onChange={onSelectIncoterm}
                                options={Incoterms}
                                isMulti={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {
                            boxes.map((e, index)=>{
                                return (
                                    <div className="col-xl-2 col-lg-2" key={`data-${index}`}>
                                        <label className="custom-label">Container {index}</label>                        
                                        <input style={{borderRadius:"6px", backgroundColor:"#f2f4f6", border:"none"}}
                                            type="text"
                                            placeholder="Container Number"
                                            id="shipmentInput""container_number"
                                            value={e.value}
                                            autoComplete="off"
                                            onChange={(e)=> onChangeContainerNo(e, index)}
                                            onBlur={(e)=> validateContainerNo(e, index)}
                                            className="form-control"
                                            //required
                                        />
                                        <span className="error-mssge">{boxes[index].isValid}</span>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ paddingTop: "30px" }}
                    >
                      <h6 className="custom-header">Stakeholder Details</h6>
                    </div>
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        {selectMovementType &&
                        selectMovementType.value === "Import" ? (
                          <>
                            <div className="col-xl-2 col-lg-2">
                              <label className="custom-label">Customer</label>
                              <Select
                                type="text"
                                placeholder=""
                                value={customer}
                                options={customerData}
                                autoComplete="off"
                                id="shipmentInput"
                                onChange={onChangeCustomer}
                                // className="form-control"
                                styles={customStyles}
                                // //required
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {selectMovementType &&
                        selectMovementType.value === "Export" ? (
                          <>
                            <div className="col-xl-2 col-lg-2">
                              <label className="custom-label">Supplier</label>
                              <Select
                                type="text"
                                placeholder=""
                                value={supplier}
                                id="shipmentInput"
                                options={supplierData}
                                autoComplete="off"
                                onChange={onChangeSupplier}
                                // className="form-control"
                                styles={customStyles}
                                //required
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">Shipment Owner</label>
                          <Select
                            placeholder=""
                            closeMenuOnSelect={true}
                            value={selectOwner}
                            id="shipmentInput"
                            styles={customStyles}
                            // className="form-control"
                            onChange={onSelectOwner}
                            options={Owners}
                          />
                        </div>

                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Purchase Order Number
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              // backgroundColor: "#f2f4f6",
                              border: "1px solid #ced4da",
                              height: "2.5rem",
                            }}
                            type="text"
                            placeholder=""
                            id="shipmentInput"
                            value={PONumber}
                            autoComplete="off"
                            onChange={onChangePONumber}
                            className="form-control"
                            //required
                          />
                        </div>

                        {/* <label className="custom-label">Shipper</label> */}
                        <div className="col-lg-2 col-xl-2">
                          <label className="custom-label">
                            Freight Forwarder
                          </label>
                          <input
                            type="text"
                            id="freightForwarder"
                            className="form-control"
                            style={{
                              borderRadius: "6px",
                              border: "1px solid #ced4da",
                              // backgroundColor: "#f2f4f6",
                              height: "2.5rem",
                            }}
                            value={state.freightForwarder}
                            onChange={(e) => {
                              setState({
                                ...state,
                                freightForwarder: e.target.value,
                              });
                            }}
                          />
                          {/* <label
                            className="custom-label"
                            htmlFor="freightForwarder"
                          >
                            
                          </label> */}
                        </div>
                        <div className="col-xl-3 col-lg-3">
                          <label className="custom-label">
                            Custom House Agent
                          </label>
                          <input
                            type="text"
                            id="shipmentInput"
                            className="form-control"
                            style={{
                              borderRadius: "6px",
                              border: "1px solid #ced4da",
                              // backgroundColor: "#f2f4f6",
                              height: "2.5rem",
                            }}
                            value={state.customHouseAgent}
                            onChange={(e) => {
                              setState({
                                ...state,
                                customHouseAgent: e.target.value,
                              });
                            }}
                          />
                          {/* &nbsp;<label
                            className="custom-label"
                            htmlFor="customHouseAgent"
                          >
                            
                          </label> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 col-xl-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        <div className="col-xl-2 col-lg-2" style={{}}>
                          <label className="custom-label">Pickup Date</label>
                          <div>
                            <DateTime
                              //className="date-time-container"
                              inputProps={{
                                //id: "pick_up_date",
                                id: "datetimeid",
                                name: "pickUpDate",
                                style: {
                                  height: "2.5rem",
                                  borderRadius: "6px",
                                },
                              }}
                              value={pickUpDate}
                              onChange={onHandlePickUpDateChange}
                              wrapperClassName="datepick"
                              //closeOnSelect
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">Origin Address</label>
                          <input
                            style={{
                              borderRadius: "6px",
                              // backgroundColor: "#f2f4f6",
                              border: "1px solid #ced4da",
                              height: "2.5rem",
                            }}
                            type="text"
                            placeholder=""
                            value={customerOriginPort}
                            autoComplete="off"
                            onChange={onChangeCustomerOrigin}
                            className="form-control"
                            //required
                            id="shipmentInput"
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Destination Address
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              // backgroundColor: "#f2f4f6",
                              border: "1px solid #ced4da",
                              height: "2.5rem",
                            }}
                            type="text"
                            placeholder=""
                            value={customerDestinationPort}
                            autoComplete="off"
                            onChange={onChangeCustomerDestination}
                            className="form-control"
                            id="shipmentInput"
                            //required
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Stuffing Location
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              // backgroundColor: "#f2f4f6",
                              border: "1px solid #ced4da",
                              height: "2.5rem",
                            }}
                            type="text"
                            placeholder=""
                            value={customerStuffingLocation}
                            autoComplete="off"
                            onChange={onChangeCustomerStuffingLocation}
                            className="form-control"
                            //required
                            id="shipmentInput"
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Destuffing Location
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              // backgroundColor: "#f2f4f6",
                              border: "1px solid #ced4da",
                              height: "2.5rem",
                            }}
                            type="text"
                            placeholder=""
                            value={customerDestuffingLocation}
                            autoComplete="off"
                            id="shipmentInput"
                            onChange={onChangeCustomerDestuffingLocation}
                            className="form-control"
                            //required
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-12 col-lg-12" style={{marginBottom:"25px"}}>
                            <div className="row">
                                                                
                            </div>
                        </div> */}
                    {/* <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ paddingTop: "30px" }}
                    >
                      <h6 className="custom-header">Additional Details</h6>
                    </div>
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">Invoice Number</label>
                          <input
                            style={{
                              borderRadius: "6px",
                              // backgroundColor: "#f2f4f6",
                              border: "1px solid #ced4da",
                              height: "2.5rem",
                            }}
                            type="text"
                            placeholder=""
                            value={invoiceNo}
                            id="shipmentInput"
                            autoComplete="off"
                            onChange={onChangeInvoiceNo}
                            className="form-control"
                            //required
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2" style={{}}>
                          <label className="custom-label">Invoice Date</label>
                          <div>
                            <DateTime
                              //className="date-time-container"
                              inputProps={{
                                //id: "invoiceDate",
                                id: "datetimeid",
                                name: "invoiceDate",
                                style: {
                                  height: "2.5rem",
                                  borderRadius: "6px",
                                },
                              }}
                              value={invoiceDate}
                              onChange={onHandleInvoiceDateChange}
                              wrapperClassName="datepick"
                              //closeOnSelect
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Free Trade Agreement
                          </label>
                          <Select
                            placeholder=""
                            closeMenuOnSelect={true}
                            value={FTAValue}
                            styles={customStyles}
                            id="shipmentInput"
                            // className="form-control"
                            onChange={onSelectFTA}
                            options={FTAOptions}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4">
                          <label className="custom-label">
                            Research & Development Benefit Certificate
                          </label>
                          <Select
                            placeholder=""
                            closeMenuOnSelect={true}
                            value={RDBValue}
                            id="shipmentInput"
                            styles={customStyles}
                            // className="form-control"
                            onChange={onSelectRDB}
                            options={RDBOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        <div className="col-xl-3 col-lg-3">
                          <label className="custom-label">
                            Export Promotion Capital Goods (EPCG)
                          </label>
                          <Select
                            placeholder=""
                            closeMenuOnSelect={true}
                            value={EPCGValue}
                            styles={customStyles}
                            onChange={onSelectEPCG}
                            options={EPCGOptions}
                            id="shipmentInput"
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            LC Available (Letter of Credit)
                          </label>
                          <Select
                            placeholder=""
                            closeMenuOnSelect={true}
                            value={LCValue}
                            id="shipmentInput"
                            styles={customStyles}
                            onChange={onSelectLC}
                            options={LCOptions}
                          />
                        </div>
                        {LCValue && LCValue.value === "yes" ? (
                          <div className="col-xl-2 col-lg-2" style={{}}>
                            <label className="custom-label">LC Number</label>
                            <input
                              style={{
                                borderRadius: "6px",
                                // backgroundColor: "#f2f4f6",
                                border: "1px solid #ced4da",
                                height: "2.5rem",
                              }}
                              type="text"
                              placeholder=""
                              value={LCNumber}
                              id="shipmentInput"
                              autoComplete="off"
                              onChange={onChangeLCNumber}
                              className="form-control"
                              //required
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">Incoterms</label>
                          <Select
                            placeholder=""
                            closeMenuOnSelect={true}
                            value={IncotermsValue}
                            id="shipmentInput"
                            styles={customStyles}
                            onChange={onSelectIncoterms}
                            options={incotermsList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ paddingTop: "30px" }}
                    >
                      <h6 className="custom-header added-style-demurrage">
                        Detention Details
                      </h6>
                    </div>
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="row">
                        {/* <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">
                            Demurrage Free Days:
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                            }}
                            type="number"
                            placeholder=""
                            value={demurrageFreeDays}
                            id="shipmentInput"
                            autoComplete="off"
                            onChange={onChangeDemurrageDays}
                            className="form-control"
                            min={0}
                          />
                        </div> */}

                        <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">Port</label>
                          {/* <Select
                            closeMenuOnSelect={true}
                            name="portName"
                            className="border-radius-0"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                fontSize: "0.8rem",
                                color: "#6e707e",
                                width: "93%",
                              }),
                            }}
                            onChange={onSelectDocumentType}
                            options={state.portOptions}
                            value={state.portName}
                            //required
                          /> */}

                          <Select
                            closeMenuOnSelect={true}
                            name="portName"
                            className="border-radius-0"
                            onChange={handleFilterOptions}
                            options={state.portOptions}
                            styles={customStyles}
                            value={state.portName}
                            required
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2" style={{}}>
                          <label className="custom-label">
                            Detention Free Days
                          </label>
                          <input
                            style={{
                              borderRadius: "6px",
                              // backgroundColor: "#f2f4f6",
                              border: "1px solid #ced4da",
                              height: "2.5rem",
                            }}
                            type="number"
                            placeholder=""
                            value={detentionFreeDays}
                            id="shipmentInput"
                            autoComplete="off"
                            onChange={onChangeDetentionDays}
                            className="form-control"
                            min={0}
                            //required
                          />
                        </div>

                        {/* <div className="col-xl-2 col-lg-2">
                          <label className="custom-label">CFS Free Days</label>
                          <input
                            style={{
                              borderRadius: "6px",
                              backgroundColor: "#f2f4f6",
                              border: "none",
                            }}
                            type="number"
                            placeholder=""
                            id="shipmentInput"
                            value={CFSFreeDays}
                            autoComplete="off"
                            onChange={onChangeCFSDays}
                            className="form-control"
                          />
                        </div> */}

                        {/* <div className="col-xl-2 col-lg-2">
                                    <label className="custom-label">Port</label>
                                    <Select
                                        placeholder="Select Port"
                                        closeMenuOnSelect={true}
                                        value={selectShippingLine}
                                        id="shipmentInput"
                                        styles={customStyles} 
                                        onChange={onSelectShippingLine}
                                        options={shippingLine}
                                    />
                                </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div
                      className="col-xl-12 col-lg-12"
                      style={{ paddingTop: "30px" }}
                    >
                      <h6 className="custom-header">Documents</h6>
                    </div>
                    <div className="col bg-white">
                      <button
                        className="btn btn-primary"
                        onClick={onAddDocuments}
                        disabled={submitDisable}
                      >
                        Add Shipment Documents
                      </button>

                      <div className="row pl-3 mr-1">
                        <div className="col px-0">
                          {attachmentsDocArr.length === 0 ? (
                            <label className="custom-label" htmlFor="">
                              No Documents are attached
                            </label>
                          ) : (
                            <div
                              className="row my-2"
                              style={{ paddingLeft: "12px" }}
                            >
                              <table
                                className="table table-bordered tptinfo"
                                style={{ overflowY: "auto" }}
                              >
                                <thead>
                                  <th>
                                    <label className="custom-label" htmlFor="">
                                      Document Name
                                    </label>
                                  </th>
                                  <th>
                                    <label className="custom-label" htmlFor="">
                                      Document Type
                                    </label>
                                  </th>
                                  <th>
                                    <label className="custom-label" htmlFor="">
                                      Created By
                                    </label>
                                  </th>
                                  <th>
                                    <label className="custom-label" htmlFor="">
                                      Created At
                                    </label>
                                  </th>
                                  <th></th>
                                </thead>
                                <tbody>
                                  {(!attachmentsDocArr || attachmentsDocArr.length === 0 || attachmentsDocArr === undefined) ? <></> : <>
                                    {attachmentsDocArr.map((eachDocx, index) => {
                                      let createdDate = eachDocx.created_at;
                                      createdDate = moment
                                        .parseZone(createdDate)
                                        .format("MM/DD/YY, HH:mm A");
                                      return (
                                        <tr key={index}>
                                          <td>{eachDocx.documentName}</td>
                                          <td>{eachDocx.documentType}</td>
                                          <td>{email}</td>
                                          <td>{createdDate}</td>
                                          <td style={{ width: "95px" }}>
                                            <div>
                                              <i
                                                style={{
                                                  fontSize: "20px",
                                                  cursor: "pointer",
                                                }}
                                                class="fa fa-trash"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  deleteEximDocument(
                                                    index,
                                                    eachDocx
                                                  )
                                                }
                                              ></i>
                                              {/* <a
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                color: "black",
                                              }}
                                              href={`${eachDocx.document_url}`}
                                            >
                                              <i
                                                class="fa fa-download mx-2"
                                                aria-hidden="true"
                                              ></i>
                                            </a> */}
                  {/* <i
                                                        style={{
                                                        fontSize: "20px",
                                                        cursor: "pointer",
                                                        }}
                                                        class="fa fa-eye"
                                                        aria-hidden="true"
                                                        onClick={this.previewDocument.bind(
                                                        this,
                                                        eachDocx
                                                        )}
                                                    ></i> 
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {trackByValue.value === "Container Number" ? (
                    <>
                      <div className="row justify-content-end ">
                        <div
                          className="mr-3"
                          //   style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <button
                            className="px-4 secondaryBtn"
                            disabled={submitDisable}
                            name="save_button"
                            type="submit"
                          >
                            Save
                          </button>
                        </div>
                        {/* <div
                      className="col-xl-1 col-lg-1"
                    //   style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Link
                        className="btn btn-primary"
                        to={"/container-tracking"}
                        // disabled={submitDisable}
                        // type="submit"
                      >
                        Finish
                      </Link>
                    </div> */}
                        <div className="mr-5">
                          <button
                            className="btn btn-primary px-4"
                            disabled={submitDisable}
                            name="save_and_submit_button"
                            type="submit"
                            onClick={() => {}}
                          >
                            Save & Submit
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row justify-content-end ">
                        <div
                          className="mr-5"
                          //   style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <button
                            className="btn btn-primary px-4"
                            disabled={submitDisable}
                            name="save_button"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                        {/* <div
                      className="col-xl-1 col-lg-1"
                    //   style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Link
                        className="btn btn-primary"
                        to={"/container-tracking"}
                        // disabled={submitDisable}
                        // type="submit"
                      >
                        Finish
                      </Link>
                    </div> */}
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>

          <div
            className={"slide-r " + openDocUploadSlider}
            style={{ overflow: "auto" }}
          >
            <div className="document-slider bg-primary text-white pt-2 px-3">
              <h6>
                {" "}
                Add Document
                <span
                  className="float-right closebtn"
                  style={{ marginRight: "25px" }}
                  onClick={onClickHideAll}
                >
                  X
                </span>
              </h6>
            </div>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="p-2 d-flex flex-column">
                <form onSubmit={handleAddAttachmentsBtn}>
                  <div className="pb-3">
                    <label
                      style={{
                        color: "#EB1D54",
                        fontSize: "13px",
                        fontFamily: "inherit",
                      }}
                    >
                      *
                    </label>
                    <label className="custom-label">Document Name </label>
                    <input
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#ffffff",
                      }}
                      name="attachmentTitle"
                      className="form-control mr-3 mt-1"
                      id="shipmentInput"
                      placeholder="Document Name"
                      styles={{ width: "80%" }}
                      onChange={(e) => setAttachmentTitle(e.target.value)}
                      value={attachmentTitle}
                      required
                    />
                  </div>

                  <div className="pb-3">
                    <label
                      style={{
                        color: "#EB1D54",
                        fontSize: "13px",
                        fontFamily: "inherit",
                      }}
                    >
                      *
                    </label>
                    <label className="custom-label">Document Type </label>
                    <Select
                      closeMenuOnSelect={true}
                      name="modeType"
                      className="border-radius-0"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          fontSize: "0.8rem",
                          color: "#6e707e",
                          width: "93%",
                        }),
                      }}
                      onChange={onSelectDocumentType}
                      options={documentOptions}
                      value={selectedDocType}
                      required
                    />
                  </div>
                  <div className="">
                    <label
                      style={{
                        color: "#EB1D54",
                        fontSize: "13px",
                        fontFamily: "inherit",
                      }}
                    >
                      *
                    </label>
                    <label className="custom-label">Browse </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="file"
                      name="attachmentsDoc"
                      styles={{ fontSize: "0.8rem" }}
                      id="attachmentsDoc"
                      onChange={changeAttachmentsFileHandler}
                      // className="col-7 mt-3 border mr-3"
                      required
                    />
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary mt-3">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <SweetAlert
            show={show}
            type={basicType}
            title={basicTitle}
            onConfirm={closeAlert}
          ></SweetAlert>
          <Modal
            open={openCustomerModel}
            onClose={onCloseCustomerModal}
            center
            classNames={{
              overlayAnimationIn: "customEnterOverlayAnimation",
              overlayAnimationOut: "customLeaveOverlayAnimation",
              modalAnimationIn: "customEnterModalAnimation",
              modalAnimationOut: "customLeaveModalAnimation",
            }}
            animationDuration={400}
          >
            <div
              className="row"
              //style={{ width: "100%", zIndex: "9999" }}
            >
              <div className="col-xl-12 col-lg-12">
                <h5
                  className="modal-heading"
                  style={{
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                  }}
                >
                  Add Customer
                </h5>
              </div>
              <div className="add-customer-container row">
                <div className="col-xl-6 col-lg-6">
                  <label
                    style={{
                      color: "#EB1D54",
                      fontSize: "13px",
                      fontFamily: "inherit",
                    }}
                  >
                    *
                  </label>
                  <label className="custom-label">Customer Name</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={customerName}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeCustomerName}
                    onBlur={validateCustomerName}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">
                    Customer Reference Number
                  </label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={customerReferenceNo}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeCustomerReferenceNo}
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Customer Code</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={customerCode}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeCustomerCode}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Admin Name</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={adminName}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeAdminName}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Admin Email</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="email"
                    placeholder=""
                    value={adminEmail}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeAdminEmail}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Team</label>
                  <Select
                    placeholder=""
                    closeMenuOnSelect={true}
                    value={selectedTeam}
                    id="shipmentInput"
                    styles={customStyles}
                    onChange={onSelectTeam}
                    options={teamList}
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">POC</label>
                  <Select
                    placeholder=""
                    closeMenuOnSelect={true}
                    value={selectedPOC}
                    styles={customStyles}
                    id="shipmentInput"
                    onChange={onSelectPOC}
                    options={pointOfContacts}
                    isDisabled={enablePOC}
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Product</label>
                  <Select
                    type="text"
                    placeholder=""
                    value={product}
                    options={productData}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeProduct}
                    // className="form-control"
                    styles={customStyles}
                    //required
                    isDisabled={isSelectDisabled}
                  />
                </div>
              </div>
              <div
                className="col-xl-12 col-lg-12"
                style={{ borderTop: "1px solid" }}
              >
                <div className="modal-footer">
                  <div className="modal-action-buttons">
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onClickHideCustomerModal}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onSaveCustomerDetails}
                      type="button"
                      disabled={isSelectDisabled}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={openProductModel}
            onClose={onCloseProductModal}
            style={{ width: "800px", height: "625px !important" }}
            classNames={{
              // modal:
              //   selectedTeamForProduct.length > 1
              //     ? "increase-product-modal"
              //     : "custom-product-class",
              overlayAnimationIn: "customEnterOverlayAnimation",
              overlayAnimationOut: "customLeaveOverlayAnimation",
              modalAnimationIn: "customEnterModalAnimation",
              modalAnimationOut: "customLeaveModalAnimation",
            }}
            animationDuration={400}
            center
          >
            <div className="row" style={{ width: "100%", zIndex: "10000" }}>
              <div className="col-xl-12 col-lg-12">
                <h5
                  className="modal-heading"
                  style={{
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                  }}
                >
                  Add Product
                </h5>
              </div>
              <form onSubmit={onSaveProductDetails}>
                <div
                  className="add-customer-container row"
                  style={{ padding: "44px" }}
                >
                  <div className="col-xl-12 col-lg-12">
                    <label
                      style={{
                        color: "#EB1D54",
                        fontSize: "13px",
                        fontFamily: "inherit",
                      }}
                    >
                      *
                    </label>
                    <label className="custom-label">Products Name</label>
                    <input
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={productName}
                      id="shipmentInput"
                      autoComplete="off"
                      onChange={onChangeProductName}
                      //onBlur={validateCustomerProductName}
                      className="form-control"
                      required
                    />
                    {/* {errorTxtForCustomerProducts ? (
                    <span className="error-text">*Enter Product Name</span>
                  ) : (
                    ""
                  )} */}
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <label
                      style={{
                        color: "#EB1D54",
                        fontSize: "13px",
                        fontFamily: "inherit",
                      }}
                    >
                      *
                    </label>
                    <label className="custom-label">HS Code</label>
                    <input
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="text"
                      placeholder=""
                      value={hsCode}
                      id="shipmentInput"
                      autoComplete="off"
                      onChange={onChangeHSCode}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <label
                      style={{
                        color: "#EB1D54",
                        fontSize: "13px",
                        fontFamily: "inherit",
                      }}
                    >
                      *
                    </label>
                    <label className="custom-label">Cargo Type</label>
                    <Select
                      placeholder=""
                      closeMenuOnSelect={true}
                      value={selectedCargo}
                      styles={customStyles}
                      id="shipmentInput"
                      onChange={setProductCargoType}
                      options={cargoList}
                    />
                  </div>
                  <div
                    className="col-xl-12 col-lg-12"
                    style={{ marginBottom: "65px" }}
                  >
                    <label
                      style={{
                        color: "#EB1D54",
                        fontSize: "13px",
                        fontFamily: "inherit",
                      }}
                    >
                      *
                    </label>
                    <label className="custom-label">Select Team</label>
                    <Select
                      placeholder=""
                      closeMenuOnSelect={true}
                      id="shipmentInput"
                      value={selectedTeamForProduct}
                      // className={
                      //   selectedTeamForProduct.length > 2
                      //     ? "tall-select"
                      //     : "short-select"
                      // }
                      onChange={onSelectTeamForProduct}
                      options={teamListForProduct}
                      isMulti={true}
                      required
                    />
                  </div>
                  <div
                    className="col-xl-12 col-lg-12"
                    style={{ marginBottom: "15px" }}
                  >
                    <label
                      style={{
                        color: "#EB1D54",
                        fontSize: "13px",
                        fontFamily: "inherit",
                      }}
                    >
                      *
                    </label>
                    <label className="custom-label">Upload Documents</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f6",
                        border: "none",
                      }}
                      type="file"
                      name="import_customer"
                      styles={{ fontSize: "0.8rem" }}
                      id="import_customer"
                      onChange={changeTradeFilesHandler}
                      // className="col-7 mt-3 border mr-3"
                      required
                    />
                  </div>
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ borderTop: "1px solid" }}
                >
                  <div className="modal-footer">
                    <div className="modal-action-buttons">
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        onClick={onClickHideProductModal}
                        type="button"
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginRight: "20px", padding: "12px 10px" }}
                        //onClick={onSaveProductDetails}
                        type="submit"
                        //disabled={disableCustomerProductSave}
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            open={openSupplierModel}
            onClose={onCloseSupplierModal}
            center
            classNames={{
              overlayAnimationIn: "customEnterOverlayAnimation",
              overlayAnimationOut: "customLeaveOverlayAnimation",
              modalAnimationIn: "customEnterModalAnimation",
              modalAnimationOut: "customLeaveModalAnimation",
            }}
            animationDuration={400}
          >
            <div className="row" style={{ width: "100%", zIndex: "100001" }}>
              <div className="col-xl-12 col-lg-12">
                <h5
                  className="modal-heading"
                  style={{
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                  }}
                >
                  Add Supplier
                </h5>
              </div>
              <div className="add-customer-container row">
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Supplier Name</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={supplierName}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeSupplierName}
                    onBlur={onValidateSupplierName}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">
                    Supplier Reference Number
                  </label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    id="shipmentInput"
                    value={supplierReferenceNo}
                    autoComplete="off"
                    onChange={onChangeSupplierReferenceNo}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Admin Name</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={supplierAdminName}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeSupplierAdminName}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Admin Email</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="email"
                    placeholder=""
                    value={supplierAdminEmail}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeSupplierAdminEmail}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Team</label>
                  <Select
                    placeholder=""
                    closeMenuOnSelect={true}
                    value={supplierSelectedTeam}
                    styles={customStyles}
                    id="shipmentInput"
                    onChange={onSelectSupplierTeam}
                    options={supplierTeamList}
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">POC</label>
                  <Select
                    placeholder=""
                    closeMenuOnSelect={true}
                    value={supplierPOC}
                    id="shipmentInput"
                    styles={customStyles}
                    onChange={onSelectSupplierPOC}
                    options={supplierPointOfContacts}
                    isDisabled={supplierEnablePOC}
                  />
                </div>
                <div className="col-xl-6 col-lg-6">
                  <label className="custom-label">Product</label>
                  <Select
                    type="text"
                    placeholder=""
                    value={supplierProduct}
                    id="shipmentInput"
                    options={supplierProductData}
                    autoComplete="off"
                    onChange={onChangeSupplierProduct}
                    // className="form-control"
                    styles={customStyles}
                    isDisabled={isSupplierSelectDisabled}
                    //required
                  />
                </div>
              </div>
              <div
                className="col-xl-12 col-lg-12"
                style={{ borderTop: "1px solid" }}
              >
                <div className="modal-footer">
                  <div className="modal-action-buttons">
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onClickHideSupplierModal}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onSaveSupplierDetails}
                      disabled={isSupplierSelectDisabled}
                      type="button"
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={openSupplierProductModel}
            onClose={onCloseSupplierProductModal}
            style={{ width: "800px", height: "620px !important" }}
            classNames={{
              modal:
                selectedSupplierTeamForProduct.length > 1
                  ? "increase-product-modal"
                  : "custom-product-class",
              overlayAnimationIn: "customEnterOverlayAnimation",
              overlayAnimationOut: "customLeaveOverlayAnimation",
              modalAnimationIn: "customEnterModalAnimation",
              modalAnimationOut: "customLeaveModalAnimation",
            }}
            animationDuration={400}
            center
          >
            <div className="row" style={{ width: "100%", zIndex: "10002" }}>
              <div className="col-xl-12 col-lg-12">
                <h5
                  className="modal-heading"
                  style={{
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                  }}
                >
                  Add Product
                </h5>
              </div>
              <div className="add-customer-container row">
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">Products Name</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={supplierProductName}
                    id="shipmentInput"
                    autoComplete="off"
                    onChange={onChangeSupplierProductName}
                    onBlur={ValidateSupplierProductName}
                    className="form-control"
                    //required
                  />
                  {errorTxtForSupplierProducts ? (
                    <span className="error-text">*Enter Product Name</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">HS Code</label>
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="text"
                    placeholder=""
                    value={supplierHSCode}
                    autoComplete="off"
                    id="shipmentInput"
                    onChange={onChangeSupplierHSCode}
                    className="form-control"
                    //required
                  />
                </div>
                <div className="col-xl-12 col-lg-12">
                  <label className="custom-label">Cargo Type</label>
                  <Select
                    placeholder=""
                    closeMenuOnSelect={true}
                    value={selectedSupplierCargo}
                    id="shipmentInput"
                    styles={customStyles}
                    onChange={setSupplierProductCargoType}
                    options={supplierCargoList}
                  />
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ marginBottom: "65px" }}
                >
                  <label className="custom-label">Select Team</label>
                  <Select
                    placeholder=""
                    closeMenuOnSelect={true}
                    id="shipmentInput"
                    value={selectedSupplierTeamForProduct}
                    className={
                      selectedSupplierTeamForProduct.length > 2
                        ? "tall-select"
                        : "short-select"
                    }
                    onChange={onSelectSupplierTeamForProduct}
                    options={supplierTeamListForProduct}
                    isMulti={true}
                  />
                </div>
                <div
                  className="col-xl-12 col-lg-12"
                  style={{ marginBottom: "15px" }}
                >
                  <label className="custom-label">Upload Documents:</label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f2f4f6",
                      border: "none",
                    }}
                    type="file"
                    name="export_supplier"
                    styles={{ fontSize: "0.8rem" }}
                    id="export_supplier"
                    onChange={changeTradeFilesHandler}
                    // className="col-7 mt-3 border mr-3"
                    //required
                  />
                </div>
              </div>
              <div
                className="col-xl-12 col-lg-12"
                style={{ borderTop: "1px solid" }}
              >
                <div className="modal-footer">
                  <div className="modal-action-buttons">
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onClickHideSupplierProductModal}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginRight: "20px", padding: "12px 10px" }}
                      onClick={onSaveSupplierProductDetails}
                      type="button"
                      disabled={disableSupplierProductSave}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div
            className={"overlay-part " + overly}
            onClick={onClickHideAll}
          ></div>
        </div>
      </div>
    </>
  );
};

export default CreateShipment;
