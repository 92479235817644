// import React, { Component } from "react";

// import { AgGridReact } from "ag-grid-react";
// import "ag-grid-enterprise";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
// // import { AllModules } from "@ag-grid-enterprise/all-modules";

// import "./vendorProcureDashboard.css";
// import { Modal } from "react-responsive-modal";
// import "react-responsive-modal/styles.css";
// import { Link } from "react-router-dom";
// import redirectURL from "../redirectURL";
// import moment from "moment";
// import { v4 as uuidv4 } from "uuid";

// export default class ProcureDashboard extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       user_type: "",
//       vendor_email: "",
//       openEAuctionsCount: 0,
//       closedEAuctionsCount: 0,
//       openRFxCount: 0,
//       submittedRFxCount: 0,
//       pendingPOsCount: 0,
//       acceptedOrRevertedPOsCount: 0,
//       billTrackerCount: 0,
//       openQueryCount: 0,
//       boxHeading: "Open",
//       termsAndCondtionsCheckboxStatus: false,
//       openModal: false,
//       sentVendorConsent: false,
//       pastVendorEvents: [],
//       upcomingVendorEvents: [],
//       ongoingVendorEvents: [],
//       vendorEvents: [],
//       allVendorEvents: [],
//       modalEvent: "",
//       modalEventId: "",
//       isRfqSubmitted: 0,
//       loadshow: "show-n",
//       // modules: AllModules,
//       rowSelection: "multiple",
//       defaultColDef: {
//         sortable: true,
//         filter: true,
//         resizable: true,
//       },
//       sideBar: {
//         toolPanels: [
//           {
//             id: "columns",
//             labelDefault: "Columns",
//             labelKey: "columns",
//             iconKey: "columns",
//             toolPanel: "agColumnsToolPanel",
//           },
//           {
//             id: "filters",
//             labelDefault: "Filters",
//             labelKey: "filters",
//             iconKey: "filter",
//             toolPanel: "agFiltersToolPanel",
//           },
//         ],
//       },
//       statusBar: {
//         statusPanels: [
//           {
//             statusPanel: "agTotalAndFilteredRowCountComponent",
//             align: "left",
//           },
//           {
//             statusPanel: "agTotalRowCountComponent",
//             align: "center",
//           },
//           { statusPanel: "agFilteredRowCountComponent" },
//           { statusPanel: "agSelectedRowCountComponent" },
//           { statusPanel: "agAggregationComponent" },
//         ],
//       },
//       rowData: [],
//       paginationPageSize: 100,
//     };
//   }

//   componentDidMount = () => {
//     this.setState({
//       loadshow: "show-m",
//     });
//     var userType = localStorage.getItem("user_type");
//     var email = localStorage.getItem("email");
//     var payload = {
//       email,
//     };

//     redirectURL
//       .post("/procurement/getAllEventsForVendor", payload)
//       .then((resp) => {
//         console.log("getAllEventsForVendor", resp.data);

//         var onGoingEVents = resp.data.ongoingEvents;
//         var upcomingEvents = resp.data.upcomingEvents;
//         var vendorUpcomingAndOngoingEvents = [
//           ...onGoingEVents,
//           ...upcomingEvents,
//         ];

//         this.setState({
//           pastVendorEvents: resp.data.pastEvents,
//           ongoingEvents: resp.data.ongoingEvents,
//           upcomingEvents: resp.data.upcomingEvents,
//           vendorEvents: vendorUpcomingAndOngoingEvents,
//           // vendorEvents: resp.data.pastEvents,
//           // vendorEvents: resp.data.ongoingEvents,
//           // vendorEvents: resp.data.upcomingEvents,
//           allVendorEvents: [
//             ...resp.data.pastEvents,
//             ...resp.data.ongoingEvents,
//             ...resp.data.upcomingEvents,
//           ],
//           openRFxCount: resp.data.ongoingEvents.length,
//           loadshow: "show-n",
//           rowData: onGoingEVents,
//         });
//       })
//       .catch((err) => {
//         console.log("getAllEventsForVendor", err);
//       });

//     if (email !== "" && email !== undefined && email !== null) {
//       this.setState({
//         vendor_email: email,
//         user_type: userType,
//       });
//     }
//   };

//   openModal = (rfq_event_id, startTime, nowTime) => {
//     var filteredEvent = this.state.allVendorEvents.filter((each) => {
//       return each.rfq_event_id === rfq_event_id;
//     });
//     console.log("startTime", startTime);
//     console.log("nowTime", nowTime);

//     // for start time > now time, such shouldn't be opened
//     var disableModel = startTime > nowTime;
//     // console.log("disableModel", disableModel);
//     this.setState({
//       openModal: !disableModel,
//       modalEvent: filteredEvent[0],
//       modalEventId: filteredEvent[0].rfq_event_id,
//     });
//   };

//   closeModal = () => {
//     this.setState({
//       openModal: false,
//     });
//   };

//   changeTermsAndCondtionsCheckboxStatus = () => {
//     this.setState({
//       termsAndCondtionsCheckboxStatus:
//         !this.state.termsAndCondtionsCheckboxStatus,
//     });
//   };

//   registerVendorConsent = () => {
//     var nowDate = moment.parseZone(new Date()).utcOffset("+00:00")._d;
//     console.log("nowdate", nowDate);
//     var vendorResponse = {
//       user_type: this.state.user_type,
//       vendor_email: this.state.vendor_email,
//       status: "Intend to Participate",
//       rfq_event_id: this.state.modalEventId,
//       rfq_response_submitted: 0,
//       response_datetime: nowDate,
//     };
//     redirectURL
//       .post("/procurement/registerVendorResponse", vendorResponse)
//       .then((resp) => {
//         console.log("registerVendorResponse", resp);
//       })
//       .catch((err) => {
//         console.log("registerVendorResponse", err);
//       });
//   };

//   onGridReady = (params) => {
//     this.gridApi = params.api;
//     this.gridColumnApi = params.columnApi;
//     this.gridApi.sizeColumnsToFit();
//   };

//   onGridState = () => {
//     //console.log(this.gridApi);

//     /*Get  Current Columns State and Store in this.colState */
//     this.colState = this.gridColumnApi.getColumnState();

//     /*Get Current RowGroup Columns State and Store in this.rowGroupState */
//     this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

//     /*Get Current Pivot Columns State and Store in this.pivotColumns,
//      * User should enable the Pivot mode.*/
//     this.pivotColumns = this.gridColumnApi.getPivotColumns();

//     /*Get Current Filter State and Store in window.filterState */
//     window.filterState = this.gridApi.getFilterModel();
//     this.gridApi.setFilterModel(window.filterState);
//   };

//   render() {
//     let columnwithDefs = [
//       {
//         headerName: "Reference Number",
//         headerClass: "custom-header",
//         field: "rfq_event_id",
//         filter: true,
//         width: 200,
//       },
//       {
//         headerName: "Event Subject",
//         headerClass: "custom-header",
//         field: "rfq_subject",
//         filter: true,
//         width: 200,
//       },
//       {
//         headerName: "Event Description",
//         headerClass: "custom-header",
//         field: "rfq_description",
//         filter: true,
//         width: 200,
//       },
//       {
//         headerName: "Event Start Time",
//         headerClass: "custom-header",
//         field: "rfq_start_time",
//         filter: true,
//         width: 200,
//         valueGetter: function (params) {
//           if (params.data.rfq_start_time) {
//             return moment(params.data.rfq_start_time)
//               .utcOffset("+00:00")
//               .format("YYYY-MM-DD HH:mm");
//           } else {
//             return "";
//           }
//         },
//       },
//       {
//         headerName: "Event End Time",
//         headerClass: "custom-header",
//         field: "rfq_end_time",
//         filter: true,
//         width: 200,
//         valueGetter: function (params) {
//           if (params.data.rfq_end_time) {
//             return moment(params.data.rfq_end_time)
//               .utcOffset("+00:00")
//               .format("YYYY-MM-DD HH:mm");
//           } else {
//             return "";
//           }
//         },
//       },
//       {
//         headerName: "Event Status",
//         headerClass: "custom-header",
//         field: "rfq_event_status",
//         filter: true,
//         width: 200,
//         valueGetter: function (params) {
//           if (
//             params.data.rfq_event_status &&
//             params.data.rfq_event_status === 1
//           ) {
//             return "Open";
//           } else {
//             return "Closed";
//           }
//         },
//         cellStyle: (params) => {
//           if (params.data.rfq_event_status) {
//             const status = params.data.rfq_event_status;
//             let textColor;
//             // let fontWeight
//             if (status === 1) {
//               textColor = "#407BFF";
//               // fontWeight = 'bold';
//             }
//             // else if (status === "Scheduled") {
//             //   textColor = "#407BFF";
//             // } else if (status === "Bidding") {
//             //   textColor = "#EBCD64";
//             //   // fontWeight = 'bold';
//             // } else if (status === "Submitted") {
//             //   textColor = "#6D6D6D";
//             //   // fontWeight = 'bold';
//             // } else if (status === "Negotiation") {
//             //   textColor = "#2CCE28";
//             //   // fontWeight = 'bold';
//             // } else if (status === "Auction") {
//             //   textColor = "#2CCE28";
//             //   // fontWeight = 'bold';
//             // } else {
//             //   textColor = "#6D6D6D";
//             //   // fontWeight = 'bold';
//             // }

//             return { color: textColor };
//           } else {
//           }
//         },
//       },
//     ];
//     return (
//       <>
//         <div className="container-fluid">
//           {/*
//         Modal
//         */}

//           <div className={"dataLoadpage " + this.state.loadshow}></div>
//           <div className={"dataLoadpageimg " + this.state.loadshow}>
//             <div class="spinner-grow text-primary mr-2" role="status"></div>
//             <div class="spinner-grow text-primary mr-2" role="status"></div>
//             <div class="spinner-grow text-primary" role="status"></div>
//           </div>

//           <Modal open={this.state.openModal} onClose={this.closeModal}>
//             <div
//               className="row justify-content-between text-white bg-primary p-2"
//               id="modalHeading"
//             >
//               <span>
//                 <i className="fa fa-bars">&nbsp; eRFQ Terms and Conditions</i>
//               </span>
//             </div>
//             <div className="column pt-3 px-5">
//               <div className="row justify-content-between">
//                 <div className="col-12">
//                   <div className="row">
//                     <div className="col-3">RFQ Subject :</div>
//                     <div className="col-3">
//                       <strong>{this.state.modalEvent.rfq_subject}</strong>
//                     </div>
//                     <div className="col-3">Currency :</div>
//                     <div className="col-3">
//                       <strong>{this.state.modalEvent.currency}</strong>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-12">
//                   <div className="row">
//                     <div className="col-3">RFQ Description :</div>
//                     <div className="col-3">
//                       {this.state.modalEvent.rfq_description}
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-12">
//                   <div className="row">
//                     <div className="col-3">RFQ Start Date :</div>
//                     <div className="col-3">
//                       {moment
//                         .parseZone(this.state.modalEvent.rfq_start_time)
//                         .format("Do MMM YYYY hh:mm")}
//                     </div>
//                     <div className="col-3">RFQ End Date :</div>
//                     <div className="col-3">
//                       {moment
//                         .parseZone(this.state.modalEvent.rfq_end_time)
//                         .format("Do MMM YYYY hh:mm")}
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="row justify-content-left pt-2">
//                 <input
//                   type="checkbox"
//                   name="accept"
//                   id="termsAndCondtionsAccept"
//                   onClick={this.changeTermsAndCondtionsCheckboxStatus.bind(
//                     this
//                   )}
//                 />
//                 <label className="px-1" htmlFor="termsAndCondtionsAccept">
//                   I hereby accept the
//                   <a
//                     href={
//                       this.state.modalEvent.hasOwnProperty("tc_doc")
//                         ? `${this.state.modalEvent.tc_doc}`
//                         : "#"
//                     }
//                   >
//                     Terms and Conditions{" "}
//                   </a>
//                   of this RFQ and agree to participate as per above RFQ details
//                   &nbsp;
//                 </label>
//               </div>
//             </div>
//             <Link
//               type="button"
//               className={
//                 this.state.termsAndCondtionsCheckboxStatus
//                   ? "btn btn-success mx-auto"
//                   : "btn btn-success mx-auto disabled"
//               }
//               id="continueBtn"
//               data-dismiss="modal"
//               to={`/rfqResponse/${this.state.modalEvent.rfq_event_id}`}
//               onClick={this.registerVendorConsent}
//             >
//               Continue
//             </Link>
//             {/* <div className="modal-content mb-0 pb-2">
              
//             </div> */}
//             {/* <div className="modal-dialog modal-lg">
              
//             </div> */}
//           </Modal>

//           <div class="card p-3">
//             <div class="row justify-content-center txt-center">
//               <div class="col-3" style={{borderRight: "1px solid blue", cursor: "pointer"}}>
//                 <label for="">Completed</label>
//                 <div>0</div>
//               </div>
//               <div class="col-3" style={{borderRight: "1px solid blue", cursor: "pointer"}}>
//                 <label for="">Open</label>
//                 <div>0</div>
//               </div>
//               <div class="col-3" style={{borderRight: "1px solid blue", cursor: "pointer"}}>
//                 <label for="">Pending</label>
//                 <div>0</div>
//               </div>
//               <div class="col-3" style={{cursor: "pointer"}}>
//                 <label for="">Preview</label>
//                 <div>0</div>
//               </div>
//             </div>
//           </div>

//           <div
//             className="dashboardContainer"
//             style={{ width: "100%", height: "500px" }}
//           >
//             <div
//               className="ag-theme-alpine"
//               style={{ width: "100%", height: "500px" }}
//             >
//               <AgGridReact
//                 // rowHeight={50}
//                 // pagination={true}
//                 // // ref={gridRef}
//                 // rowData={this.state.rowData}
//                 // columnDefs={columnDefs}
//                 // animateRows={true}
//                 // paginationPageSize={10}
//                 // // suppressCellSelection={true}
//                 // suppressMovableColumns={true}
//                 // allowScrolling={true}
//                 // showNoRowsOverlay={false}
//                 // rowClassRules={rowClassRules}

//                 // modules={this.state.modules}
//                 columnDefs={columnwithDefs}
//                 defaultColDef={this.state.defaultColDef}
//                 rowData={this.state.rowData}
//                 enableCharts={true}
//                 enableRangeSelection={true}
//                 autoGroupColumnDef={this.state.autoGroupColumnDef}
//                 onGridReady={this.onGridReady}
//                 onGridState={this.onGridState}
//                 statusBar={this.state.statusBar}
//                 sideBar={this.state.sideBar}
//                 paginationPageSize={this.state.paginationPageSize}
//                 pagination={true}
//                 // detailCellRendererParams={this.state.detailCellRendererParams}
//                 //editType={this.state.editType}
//                 // stopEditingWhenGridLosesFocus={true}
//                 //floatingFilter={true}
//                 enableCellChangeFlash={true}
//                 suppressCellFlash={true}
//                 // rowClassRules={this.state.rowClassRules}
//                 //onCellClicked={this.onRowClicked.bind(this)}
//                 gridOptions={{
//                   context: { componentParent: this },
//                 }}
//                 masterDetail={true}
//                 // rowSelection={this.state.rowSelection}
//                 //onRowSelected={this.onRowSelected.bind(this)}
//                 // suppressRowClickSelection={true}
//               />
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { FaBars, FaClock, FaCheck } from "react-icons/fa";
import "./approverdashboard.css";
import redirectURL from "../redirectURL";
import moment from "moment";
const VendorProcureDashboard = () => {
  const navigate = useNavigate();
  const gridRef = useRef();
  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    filter: true,
    resizable: true,
    pivot: true,
    enableValue: true,
    enableRowGroup: false,
  });

  const [eventsData, setEventsData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [approvedData, setApprovedData] = useState([]);


  useEffect(()=>{
    let email = localStorage.getItem("email");

    let userRole = localStorage.getItem("role");
    let acceptedRoles = ["vendor", "vender_login"]
    if(userRole && !acceptedRoles.includes(userRole) && userRole === "admin"){
      navigate("/admin-dashboard")
    }else if(userRole && !acceptedRoles.includes(userRole) && userRole != "admin"){
      localStorage.clear();
      navigate("/login")
    }
    let payload = {
      email: email ? email: ""
    }
    redirectURL.post("/procurement/getAllEventsForVendor", payload).then((resp)=>{
      console.log("getAllEventsForVendor ", resp.data);
      if(resp.data.status === "success"){
        let { ongoingEvents, upcomingEvents, pastEvents } = resp.data;
        if(pastEvents.length > 0){
          setEventsData([pastEvents[0].event]);
          setRowData([pastEvents[0].event])
        }
        if(ongoingEvents.length > 0){
          setPendingData([ongoingEvents[0].event])
        }
        if(upcomingEvents.length > 0){
          setApprovedData([upcomingEvents[0].event])
        }
      }
    }).catch((err)=>{
      console.log("getAllEventsForVendor err", err);
    })
  },[])


  const regNoRenderer = (params) => {
    return (
      <a
        href="#"
        onClick={() => handleCellClick(params.data.reference_number)}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {params.data.reference_number}
      </a>
    );
  };

  function handleCellClick(cellValue) {
    console.log("Clicked cell value:", cellValue);
  }

  const nameRenderer = (params) => {
    return (
      <div>
        <p>{params.data.name}</p>
      </div>
    );
  };

  const statusRenderer = (params) => {
    return (
      <div>
        <p>{params.data.status}</p>
      </div>
    );
  };
  const dateRenderer = (params) => {
    return (
      <div className="dateContainer">
        {/* <p className="mr-2">{moment.parseZone(params.data.from_date).format("DD-MM-YYYY")}</p> */}
        {/* <p>{moment.parseZone(params.data.to_date).format("DD-MM-YYYY")}</p> */}
      </div>
    );
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Ref No",
      field: "rfq_event_id",
      filter: true,
      width: 330
    },
    {
      headerName: "Subject",
      field: "rfq_subject",
      headerClass: "rfq_subject",
      filter: true,
      width: 200,
      valueGetter:function(params){
        if(params.data.hasOwnProperty("rfq_subject")){
          return params.data.rfq_subject.slice(0,1).toUpperCase() + params.data.rfq_subject.slice(1,)
        }else{
          return ""
        }
      }
    },
    {
      headerName: "Description",
      field: "rfq_description",
      filter: true,
      width: 500
    },
    // {
    //   headerName: "Created By",
    //   field: "user_name",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("user_name")){
    //       return params.data.user_name.slice(0,1).toUpperCase() + params.data.user_name.slice(1,)
    //     }else{
    //       return ""
    //     }
    //   }
    // },
    // {
    //   headerName: "Event Status",
    //   field: "user_name",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("is_form_submitted")){
    //       if(params.data.is_form_submitted === 0){
    //         return "Not Submitted"
    //       }
    //       if(params.data.is_form_submitted === 1){
    //         return "Submitted"
    //       }
    //     }else{
    //       return ""
    //     }
    //   }
    // },
    // {
    //   headerName: "Technicaal Approval",
    //   field: "technical_approval",
    //   filter: true,
    //   width: 100
    // },
    // {
    //   headerName: "Start Time (Buffer, in Hrs)",
    //   field: "rfq_start_date_buffer_hrs",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("rfq_start_date_is_buffer") && params.data.rfq_start_date_is_buffer){
    //       return params.data.rfq_start_date_buffer_hrs
    //     }else if(params.data.hasOwnProperty("rfq_start_date_is_buffer") && params.data.rfq_start_date_is_buffer === false){
    //       return ""
    //     }
    //   }
    // },
    {
      headerName: "Start Time",
      field: "rfq_start_time",
      filter: true,
      width: 200,
      valueGetter:function(params){
        if(params.data.hasOwnProperty("rfq_start_time") && !params.data.rfq_end_date_is_buffer){
          return moment.parseZone(params.data.rfq_start_time).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
        }else if(params.data.hasOwnProperty("rfq_start_time") && params.data.rfq_start_time && params.data.rfq_end_date_is_buffer){
          return moment.parseZone(params.data.rfq_start_time).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
        }else{
          return ""
        }
      }
    },
    // {
    //   headerName: "End Time (Buffer, in Hrs)",
    //   field: "rfq_end_date_buffer_hrs",
    //   filter: true,
    //   width: 100,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("rfq_end_date_is_buffer") && params.data.rfq_end_date_is_buffer){
    //       return params.data.rfq_end_date_buffer_hrs
    //     }else if(params.data.hasOwnProperty("rfq_end_date_is_buffer") && params.data.rfq_end_date_is_buffer === false){
    //       return ""
    //     }
    //   }
    // },
    {
      headerName: "End Time",
      field: "rfq_end_time",
      filter: true,
      width: 200,
      valueGetter:function(params){
        if(params.data.hasOwnProperty("rfq_end_time") && !params.data.rfq_end_date_is_buffer){
          return moment.parseZone(params.data.rfq_end_time).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
        } else if(params.data.hasOwnProperty("rfq_end_time") && params.data.rfq_end_time && params.data.rfq_end_date_is_buffer){
          return moment.parseZone(params.data.rfq_end_time).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
        } 
        else{
          return ""
        }
      }
    },
    // {
    //   headerName: "Created on",
    //   field: "created_at",
    //   filter: true,
    //   width: 200,
    //   valueGetter:function(params){
    //     if(params.data.hasOwnProperty("created_at")){
    //       return moment.parseZone(params.data.created_at).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
    //     }else{
    //       return ""
    //     }
    //   }
    // }

    
  
  ]);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [rowHeight, setRowHeight] = useState(50);
  const [gridStyles, setGridStyles] = useState({});

  // const rowClassRules = {
  //     'even-row': (params) => params.rowIndex % 2 === 0,
  // };
  const [activeTab, setActiveTab] = useState("Events");
  const [rowData, setRowData] = useState([]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    let data = []
    switch (tab) {
      case "Events":
        data = eventsData
        setRowData(eventsData);
        break;
      case "Open":
        data = pendingData
        setRowData(pendingData);
        break;
      case "Upcoming":
        data = approvedData
        setRowData(approvedData);
        break;
      default:
        setRowData([]);
    }
  };
  const onGridReady = (params) => {
    const gridApi = params.api;
    const gridColumnApi = params.columnApi;
    console.log("Grid is ready");
    setRowData(eventsData);
  };

  const renderCellClick = (rowData) => {
    console.log(rowData);
    let {rfq_start_time, rfq_end_time, rfq_event_id}  = rowData;
    var nowTime = moment().utcOffset("+05:30")._d;
    var startTime = moment.parseZone(new Date(rfq_start_time)).utcOffset(0)._d;
    var endTime = moment.parseZone(new Date(rfq_end_time)).utcOffset(0)._d;

    if (startTime <= nowTime && nowTime <= endTime) {
       //OPen
       navigate(`/rfqResponse/${rfq_event_id}`)
    } 
    // else if (startTime > nowTime) {
    //   upcomingEvents.push(eachEvent); //future
    // } else if (nowTime > endTime) {
    //   pastEvents.push(eachEvent); //Past
    // }
    
    // if(rowData.is_form_submitted === 0){
    //   navigate(`/request-quotation/${rowData.rfq_event_id}`)
    // }else if(rowData.is_form_submitted === 1 && rowData.rfq_event_status === 5) {
    //   navigate(`/rfqPreviewAndApprove/${rowData.rfq_event_id}`)
    // }
    
  }
  return (
    <div className="" style={{minHeight: "87vh", width: "95%", margin: "auto"}}>
      <div className="row">
        <div className="col-md-12">
          <div className="card custom-card">
            <div className="card-body" style={{ padding: "0px" }}>
              <ul
                className="nav nav-pills justify-content-between"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item tabClick">
                  <a
                    className={`nav-link ${
                      activeTab === "Events"
                        ? "events-tab-active"
                        : "events-tab"
                    }`}
                    href="#"
                    style={{
                      fontSize: "25px",
                      padding: "20px",
                    }}
                    onClick={() => handleTabChange("Events")}
                  >
                    <FaBars
                      style={{ marginBottom: "6px", marginRight: "15px" }}
                    />
                    Events &#40; {eventsData.length} &#41;
                  </a>
                </li>
                <li className="nav-item tabClick">
                  <a
                    className={`nav-link ${
                      activeTab === "Open"
                        ? "pending-tab-active"
                        : "pending-tab"
                    }`}
                    href="#"
                    style={{
                      fontSize: "25px",
                      padding: "20px",
                    }}
                    onClick={() => handleTabChange("Open")}
                  >
                    <FaClock
                      style={{ marginBottom: "6px", marginRight: "15px" }}
                    />
                    Open &#40; {pendingData.length} &#41;
                  </a>
                </li>
                <li className="nav-item tabClick">
                  <a
                    className={`nav-link ${
                      activeTab === "Upcoming"
                        ? "approved-tab-active"
                        : "approved-tab"
                    }`}
                    href="#"
                    style={{
                      fontSize: "25px",
                      padding: "20px",
                    }}
                    onClick={() => handleTabChange("Upcoming")}
                  >
                    <FaCheck
                      style={{ marginBottom: "6px", marginRight: "15px" }}
                    />
                    Upcoming &#40; {approvedData.length} &#41;
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-md-12 mb-1">
          <div
            className="dashboardContainer"
            style={{ width: "100%", height: "400px" }}
          >
            <div
              className={`ag-theme-alpine ${
                activeTab === "Events" ? "events-tab" : ""
              } ${activeTab === "Open" ? "pending-tab" : ""} ${
                activeTab === "Upcoming" ? "approved-tab" : ""
              }`}
              style={{ width: "100%", height: "400px" }}
            >
              <AgGridReact
                // domLayout="autoHeight"
                // headerClass="curved-header"
                rowHeight={rowHeight}
                defaultColDef={defaultColDef}
                pagination={true}
                // gridOptions={gridOptions}
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                animateRows={true}
                paginationPageSize={paginationPageSize}
                suppressCellSelection={true}
                suppressMovableColumns={true}
                allowScrolling={true}
                showNoRowsOverlay={false}
                suppressRowHoverHighlight={true}
                onGridReady={onGridReady}
                onCellClicked={(e) => {renderCellClick(e.data)}}
              />
              {/* <AgGridReact
                            modules={this.state.modules}
                            headerClass='curved-header'
                            columnDefs={columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.sliderRowData}
                            paginationPageSize={this.state.paginationPageSize}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            onCellClicked={this.getZoneLegData}
                            pagination={true}
                            masterDetail={true}
                            enableCharts={true}
                            enableRangeSelection={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            suppressRowClickSelection={true}
                            suppressRowHoverHighlight={true}
                        /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorProcureDashboard;

