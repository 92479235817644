import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import "./vendorProcureDashboard.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";
import redirectURL from "../redirectURL";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export default class VendorProcureDashboardRevamp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_type: "",
      vendor_email: "",
      openEAuctionsCount: 0,
      closedEAuctionsCount: 0,
      openRFxCount: 0,
      submittedRFxCount: 0,
      pendingPOsCount: 0,
      acceptedOrRevertedPOsCount: 0,
      billTrackerCount: 0,
      openQueryCount: 0,
      boxHeading: "Open Events",
      termsAndCondtionsCheckboxStatus: false,
      openModal: false,
      sentVendorConsent: false,
      pastVendorEvents: [],
      upcomingVendorEvents: [],
      ongoingVendorEvents: [],
      vendorEvents: [],
      allVendorEvents: [],
      modalEvent: "",
      modalEventId: "",
      isRfqSubmitted: 0,
      loadshow: "show-n",
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      rowData: [],
      paginationPageSize: 100,
    };
  }

  componentDidMount = () => {
    this.setState({
      loadshow: "show-m",
    });
    var userType = localStorage.getItem("user_type");
    var email = localStorage.getItem("email");
    var payload = {
      email,
    };

    redirectURL
      .post("/procurement/getAllEventsForVendor", payload)
      .then((resp) => {
        console.log("getAllEventsForVendor", resp.data);
        if(resp.data.status === 400 && resp.data.message === "No Events Found"){
          this.setState({
            loadshow: "show-n",
          });
        }

        var onGoingEVents = resp.data.ongoingEvents;
        var upcomingEvents = resp.data.upcomingEvents;
        var vendorUpcomingAndOngoingEvents = [
          ...onGoingEVents,
          ...upcomingEvents,
        ];

        this.setState({
          pastVendorEvents: resp.data.pastEvents,
          ongoingEvents: resp.data.ongoingEvents,
          upcomingEvents: resp.data.upcomingEvents,
          vendorEvents: vendorUpcomingAndOngoingEvents,
          // vendorEvents: resp.data.pastEvents,
          // vendorEvents: resp.data.ongoingEvents,
          // vendorEvents: resp.data.upcomingEvents,
          allVendorEvents: [
            ...resp.data.pastEvents,
            ...resp.data.ongoingEvents,
            ...resp.data.upcomingEvents,
          ],
          openRFxCount: resp.data.ongoingEvents.length,
          loadshow: "show-n",
        });
      })
      .catch((err) => {
        console.log("getAllEventsForVendor", err);
      });

    if (email !== "" && email !== undefined && email !== null) {
      this.setState({
        vendor_email: email,
        user_type: userType,
      });
    }
  };

  openModal = (rfq_event_id, startTime, nowTime) => {
    var filteredEvent = this.state.allVendorEvents.filter((each) => {
      return each.rfq_event_id === rfq_event_id;
    });
    console.log("startTime",startTime);
    console.log("nowTime",nowTime);

    // for start time > now time, such shouldn't be opened
    var disableModel = startTime > nowTime;
    // console.log("disableModel", disableModel);
    this.setState({
      openModal: !disableModel,
      modalEvent: filteredEvent[0],
      modalEventId: filteredEvent[0].rfq_event_id,
    });
  };

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  changeTermsAndCondtionsCheckboxStatus = () => {
    this.setState({
      termsAndCondtionsCheckboxStatus:
        !this.state.termsAndCondtionsCheckboxStatus,
    });
  };

  registerVendorConsent = () => {
    var nowDate = moment.parseZone(new Date()).utcOffset("+00:00")._d
    console.log("nowdate",nowDate);
    var vendorResponse = {
      user_type: this.state.user_type,
      vendor_email: this.state.vendor_email,
      status: "Intend to Participate",
      rfq_event_id: this.state.modalEventId,
      rfq_response_submitted: 0,
      response_datetime : nowDate
    };
    redirectURL
      .post("/procurement/registerVendorResponse", vendorResponse)
      .then((resp) => {
        console.log("registerVendorResponse", resp);
      })
      .catch((err) => {
        console.log("registerVendorResponse", err);
      });
  };

  render() {

    let columnDefs = [
      {
        headerName: "Reference Number",
        headerClass: "custom-header",
        field: "rfq_event_id",
        filter: true,
        width: 200
      },
      {
        headerName: "Event Subject",
        field: "rfq_subject",
        filter: true,
        width: 120
      },
      {
        headerName: "Event Description",
        field: "rfq_description",
        filter: true,
        width: 120
      },
      {
        headerName: "Event Start Time",
        field: "rfq_start_time",
        filter: true,
        width: 200,
        valueGetter: function(params) {
          if (params.data.rfq_start_time) {
            return moment(params.data.rfq_start_time).format("YYYY-MM-DD HH:mm");
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Event End Time",
        field: "rfq_end_time",
        filter: true,
        width: 200,
        valueGetter: function(params) {
          if (params.data.rfq_end_time) {
            return moment(params.data.rfq_end_time).format("YYYY-MM-DD HH:mm");
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Event Status",
        field: "rfq_event_status",
        filter: true,
        width: 200,
        valueGetter: function(params) {
          if (params.data.rfq_event_status && params.data.rfq_event_status === 1) {
            return "Open";
          } else {
            return "Closed";
          }
        },
      },

    ]
    return (
      <>
        <div className="container-fluid">
          {/*
        Modal
        */}

          <div className={"dataLoadpage " + this.state.loadshow}></div>
          <div className={"dataLoadpageimg " + this.state.loadshow}>
            <div class="spinner-grow text-primary mr-2" role="status"></div>
            <div class="spinner-grow text-primary mr-2" role="status"></div>
            <div class="spinner-grow text-primary" role="status"></div>
          </div>

          <Modal open={this.state.openModal} onClose={this.closeModal}>
            <div
              className="row justify-content-between text-white bg-primary p-2"
              id="modalHeading"
            >
              <span>
                <i className="fa fa-bars">&nbsp; eRFQ Terms and Conditions</i>
              </span>
            </div>
            <div className="column pt-3 px-5">
              <div className="row justify-content-between">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">RFQ Subject :</div>
                    <div className="col-3">
                      <strong>{this.state.modalEvent.rfq_subject}</strong>
                    </div>
                    <div className="col-3">Currency :</div>
                    <div className="col-3">
                      <strong>{this.state.modalEvent.currency}</strong>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-3">RFQ Description :</div>
                    <div className="col-3">
                      {this.state.modalEvent.rfq_description}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-3">RFQ Start Date :</div>
                    <div className="col-3">
                      {moment
                        .parseZone(this.state.modalEvent.rfq_start_time)
                        .format("Do MMM YYYY hh:mm")}
                    </div>
                    <div className="col-3">RFQ End Date :</div>
                    <div className="col-3">
                      {moment
                        .parseZone(this.state.modalEvent.rfq_end_time)
                        .format("Do MMM YYYY hh:mm")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-left pt-2">
                <input
                  type="checkbox"
                  name="accept"
                  id="termsAndCondtionsAccept"
                  onClick={this.changeTermsAndCondtionsCheckboxStatus.bind(
                    this
                  )}
                />
                <label className="px-1" htmlFor="termsAndCondtionsAccept">
                  I hereby accept the
                  <a
                    href={
                      this.state.modalEvent.hasOwnProperty("tc_doc")
                        ? `${this.state.modalEvent.tc_doc}`
                        : "#"
                    }
                  >
                    Terms and Conditions{" "}
                  </a>
                  of this RFQ and agree to participate as per above RFQ details
                  &nbsp;
                </label>
              </div>
            </div>
            <Link
              type="button"
              className={
                this.state.termsAndCondtionsCheckboxStatus
                  ? "btn btn-success mx-auto"
                  : "btn btn-success mx-auto disabled"
              }
              id="continueBtn"
              data-dismiss="modal"
              to={`/rfqResponse/${this.state.modalEvent.rfq_event_id}`}
              onClick={this.registerVendorConsent}
            >
              Continue
            </Link>
            {/* <div className="modal-content mb-0 pb-2">

            </div> */}
            {/* <div className="modal-dialog modal-lg">

            </div> */}
          </Modal>

          {/* <div
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >

        </div> */}

          {/*
        Modal
        */}

          <div className="row">
            <div className="col-xl-12 col-md-12 mb-4">
              <div className="card shadow h-100 py-2">
                <div className="row justify-content-between topCardBody">
                  <div
                    className="card text-white h-75 eventsCard"
                    style={{ background: "#8a7cab" }}
                  >
                    <div
                      className="row justify-content-between"
                      onClick={() => {
                        this.setState({ boxHeading: "Open eAuctions" });
                      }}
                    >
                      <label>Open eAuctions</label>
                      <label>{this.state.openEAuctionsCount}</label>
                    </div>
                    <div
                      className="row justify-content-between"
                      onClick={() => {
                        this.setState({ boxHeading: "Closed eAuctions" });
                      }}
                    >
                      <label>Closed eAuctions</label>
                      <label>{this.state.closedEAuctionsCount}</label>
                    </div>
                  </div>
                  <div
                    className="card text-white h-75 eventsCard"
                    style={{ background: "#2d9845" }}
                  >
                    <div
                      className="row justify-content-between"
                      onClick={() => {
                        this.setState({ boxHeading: "Open RFx" });
                      }}
                    >
                      <label>Open RFx</label>
                      <label>{this.state.openRFxCount}</label>
                    </div>
                    <div
                      className="row justify-content-between"
                      onClick={() => {
                        this.setState({ boxHeading: "Submitted RFx" });
                      }}
                    >
                      <label>Submitted RFx</label>
                      <label> {this.state.submittedRFxCount} </label>
                    </div>
                  </div>
                  <div
                    className="card text-white h-75 eventsCard"
                    style={{ background: "#fe8147" }}
                  >
                    <div
                      className="row justify-content-between"
                      onClick={() => {
                        this.setState({ boxHeading: "Pending PO's" });
                      }}
                    >
                      <label>Pending PO's</label>
                      <label> {this.state.pendingPOsCount} </label>
                    </div>
                    <div
                      className="row justify-content-between"
                      onClick={() => {
                        this.setState({ boxHeading: "Accepted/Reverted PO's" });
                      }}
                    >
                      <label>Accepted/Reverted PO's</label>
                      <label> {this.state.acceptedOrRevertedPOsCount} </label>
                    </div>
                  </div>
                  <div
                    className="card text-white h-75 eventsCard"
                    style={{ background: "#e88b81" }}
                  >
                    <div
                      className="row justify-content-between"
                      onClick={() => {
                        this.setState({ boxHeading: "Bill Tracker" });
                      }}
                    >
                      <label>Bill Tracker</label>
                      <label> {this.state.billTrackerCount} </label>
                    </div>
                    <div
                      className="row justify-content-between"
                      onClick={() => {
                        this.setState({ boxHeading: "Open Query" });
                      }}
                    >
                      <label>Open Query</label>
                      <label> {this.state.openQueryCount} </label>
                    </div>
                  </div>
                </div>
                <div className="bottomCardBody shadow">
                  <div className="bottomCardHeading bg-primary d-flex flex-row justify-content-between p-2">
                    <div>
                      <h6 className="text-white" id="bottomCardHeadingTitle">
                        {this.state.boxHeading}
                      </h6>
                    </div>
                    <div>
                      <input
                        type="search"
                        className="form-control rounded"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </div>
                  </div>
                  <div className="column justify-content-between">
                    {this.state.vendorEvents.map((eachEvent) => {
                      var uniqueKey = uuidv4();
                      var startTime = moment.parseZone(
                        eachEvent.rfq_start_time
                      ).utcOffset("-05:30")._d;
                      var nowTime = moment.parseZone(new Date())._d;
                      console.log("times", nowTime < startTime);
                      return (
                        <div
                          key={uniqueKey}
                          className="eachEvent row justify-content-between align-items-center"
                          onClick={this.openModal.bind(
                            this,
                            eachEvent.rfq_event_id,
                            startTime,
                            nowTime
                          )}
                          isDisabled={true}
                        >
                          <label htmlFor="">
                            {eachEvent.rfq_subject} | {eachEvent.created_by}
                          </label>
                          <label className="text-red">
                            <i>
                              {moment
                                .parseZone(eachEvent.rfq_start_time)
                                .format("Do MMM YYYY hh:mm")}
                            </i>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}