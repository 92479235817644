import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { ScrollContext } from 'react-router-scroll-4';
// import './index.scss';
import "./assets/css/style.css";
import "./assets/css/flag-icon.css";
import "./assets/css/fontawesome.css";
import "./assets/css/icofont.css";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.css";
import "./assets/css/page-builder.css";
import "./assets/css/print.css";
import "./assets/css/prism.css";
import "./assets/css/svg-icon.css";
import "./assets/css/sweetalert2.css";
import "./assets/css/themify.css";
import "./assets/css/timepicker.css";
import "../src/assets/css/encss.css"
// import "../src/assets/css/encss2.css"


// ** Import custom components **
import store from './store';
import App from './components/app';

// Dashboards
// import LandingPage from './components/dashboard/default/index.component'
import Error404 from './components/pages/error-404'
import Login from './components/pages/login'
import Register from './components/pages/register'
import ForgetPassword from './components/pages/forget-password'
import ResetPassword from './components/pages/reset-password'
import Comingsoon from './components/pages/comingsoon'
import Maintenance from './components/pages/maintenance'
import Consignments from './components/tracktrace/Consignments';
import Consignments_New from './components/tracktrace/Consignments_New'
import Epod from './components/tracktrace/Epod';

import Dealers from './components/tracktrace/Dealers';
import Vehicles from './components/tracktrace/Vehicles';
import SimTracking from './components/tracktrace/SimTracking.jsx';
import Transporters from './components/tracktrace/Transporters';
import Geofence from './components/tracktrace/Geofence.jsx'
import AddGeofence from './components/tracktrace/AddGeofence.jsx'
import EditGeofence from './components/tracktrace/EditGeofence.jsx'
import Configuration from './components/tracktrace/configuration.jsx';
// import Default from './components/tracktrace/truckwithingeofence.js'
import TrucksWithInGeofence from './components/tracktrace/truckwithingeofence.js';
import ManageNearByConsignmentTrucks from './components/manage/consignment-nearby-trucks'
import SafetyReport from './components/tracktrace/safetyreport.js';
import ConsignmentsGeofence from './components/layouts/consignmentgeofence.js';
import OptimizeDeviationRoutes from './components/tracktrace/PRTOptimizeDeviationRoutes.js';
import ChangePassword from './components/manage/changePassword.js';
import DailyRunningDashboardComponent from './components/dashboard/dailyrunningdashboardComponent.js';
import LoadingDashboard from './components/dashboard/loadingDashboardComponent.js';
import TransitAnalytics from './components/dashboard/trasitAnalytics';
import GpsUptimeReport from "./components/dashboard/gpsuptimereport.js";
import ConsigneeVicinity from './components/dashboard/consigneevicinityComponent.js'
import TranshipmentComponent from './components/dashboard/transhipmentComponent.js';
// import DailyRunningDashboard from './components/dashboard/dailyrunningdashboard.js';
import DailyRunningTransporter from './components/dashboard/dailyrunningtransporter.js';
import GPSDashboardComponent from './components/dashboard/gpsdashboardComponent.js';
import OnTimeDashboardComponent from './components/dashboard/ontimedashboardComponent.js';
import DeviationRoutes from './components/tracktrace/routeDeviationreportComponent.js';
import NightDriving from './components/dashboard/NightDriving';
import StoppageReport from './components/dashboard/StoppageReport';
import OverspeedReport from './components/dashboard/OverspeedReport';
import GenerateEwayBill from './components/ewaybill/generatenewewaybill.js';
import EwayBillData from './components/ewaybill/ewaybillsdata.js';
import EwayBillRequests from './components/ewaybill/ewaybillrequests.js';
import ConsolidateEwayBill from './components/ewaybill/consolidateewaybill.js';
import ConsolidateEwayBillRequests from './components/ewaybill/consolidateewaybillrequests.js';
import MenuSideBar from './components/common/sidebar/menuSidebar.jsx';
import DealerZoneWiseRoutes from './components/tracktrace/dealerZoneWiseRoutes.js';
import SavedBaseRoutes from './components/tracktrace/savedBaseRoutes.js';
import TopBaseRouteSummary from './components/tracktrace/topBaseRoutesSummary.js';
import DailyRunningTransporterDashboardComponent from './components/dashboard/dailrunningtransporterdashboardComponent.js';
import TicketManagementSystem from './components/tracktrace/TicketManagementSystem'
import TPTTrucksGPSStatus from './components/tracktrace/tpttrucksgpsstatus.js';
import UnLoadingDashboard from './components/dashboard/unLoadingDashboardComponent.js'
import TransitTimes from './components/tracktrace/TransitTimes.js'
import DealerDetentionReport from './components/tracktrace/DealerDetentionReport.js';
import DayWiseDistanceReport from './components/tracktrace/tpttrucksdistancematrixcomponent.js';
import PickupDropLocations from "./components/optirun-admin/pickupdroplocationsComponent";
import VehiclesData from "./components/optirun-admin/vehiclesMasterData";
import VehicleAvailabilityDetails from "./components/optirun-admin/vehicleAvailabilityDetails";
import SKUMaster from "./components/optirun-admin/skumaster";
import TransporterConfiguration from "./components/optirun-admin/transporterConfigurations";
import VehicleCostConfigurations from "./components/optirun-admin/vehicleCostComponent";
import ConsolidatedRunPlan from "./components/optirun-admin/consolidatedRunPlan";
import ViewDispatchPlanDetails from "./components/optirun-admin/viewdispatchplandetails";
import Ordermanagement from "./components/optirun-admin/ordermanagement";
import EnmUserLogin from "./components/pages/enmuserlogin";
import EPODPrint from "./components/tracktrace/printepoddataComponent";
// Freight Management Imports Start
import FrieghtConfigurations from "./components/billing/Configurations";
import BillingDieselPrices from "./components/billing/BillingDieselPricescreen";
import APIConfigurations from "./components/billing/ApiConfigurations";
import BillingTrips from "./components/billing/BillingTripsComponent";
import UploadBillingTrips from "./components/billing/BillingTripsUploadComponent";
import FreightRates from "./components/billing/FreightRateComponent";
import DieselPriceApproval from "./components/billing/DieselPricesApproval";
import FreightApprovalSubmit from "./components/billing/freightapprovalaftersubmit";
import FreighLevelApprovals from "./components/billing/BillingFreightLevelApprovals";
import ViewFreightDocument from "./components/billing/freightapprovalaftersubmit";
import OnlyViewFreightDocument from "./components/billing/freightapprovalaftersubmit";
import DownloadFreightDocument from "./components/billing/DownloadFreightDocument";
import BillingModuleUserAccess from "./components/billing/BillingModuleUserAccess";
import BillingTransporterGST from "./components/billing/BillingTransporterGST";
import BillingDeviationFlow from "./components/billing/BillingDeviationflow";
// END

// RFQ Start
import Approverdashboard from './components/procurement/Approverdashboard';
import ReverseAuction from './components/procurement/reverseAuction';
import RfqRevamp from './components/procurement/rfqRevamp';
import VendorRegistration from './components/procurement/VendorRegistration';
import VendorProcureDashboard from './components/procurement/vendorProcureDashboard';
import EventDetails from './components/procurement/eventDetails';
import OrganiserProcureDashboard from './components/procurement/organiserProcureDashboard';
import RfqComparitiveAnalysis from './components/procurement/rfqComparitiveAnalysis';
import VendorProcureDashboardRevamp from './components/procurement/vendorProcureDashboardRevamp';
import RfqResponseRevamp from './components/procurement/rfqResponseRevamp';
import RfqVendorComparison from './components/procurement/rfqVendorComparison';
import VendorAcceptance from './components/procurement/VendorAcceptance';
import RfqPreviewAndApprovePage from './components/procurement/rfqPreviewAndApprovePage';
import ContainerTracking from './components/container-tracking/eximTracking.js';
import ContainerTrackingDetails from './components/container-tracking/ContainerTrackingDetails.js';
import ClientUsers from './components/container-tracking/clientUser.js';
import Clients from './components/container-tracking/Clients.js';
import CreateShipment from './components/container-tracking/createshipment.js';



// END

class Root extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <Router basename={'/'}>
                    {/* <ScrollRestoration> */}
                    <Routes>
                        <Route path={"/"} element={<Login />} />
                        <Route path="/enmuserlogin/:token" element={<EnmUserLogin />} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/error-404`} element={<Error404 />} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/login`} element={<Login />} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/register`} element={<Register />} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} element={<ForgetPassword />} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/reset-password`} element={<ResetPassword />} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} element={<Comingsoon />} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} element={<Maintenance />} />
                        <Route path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgetPassword />} />


                        <Route path="/vendorAcceptance/:rfq_event_id/:vendor_id" element={<VendorAcceptance />} />
                        <Route path="/vendorRegister/:rfq_event_id" element={<VendorRegistration />} />

                        <Route element={<App />}>
                            {/* <Route path={`${process.env.PUBLIC_URL}/`} element={<LandingPage />    } /> */}
                            {/* <Route path={`${process.env.PUBLIC_URL}/dashboard/default`} element={<LandingPage />    } /> */}
                            <Route path={`${process.env.PUBLIC_URL}/change-password`} element={<ChangePassword />} />
                            <Route path={`${process.env.PUBLIC_URL}/activeconsignments`} element={<Consignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/completedconsignments`} element={<Consignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/allconsignments`} element={<Consignments />} />

                            <Route path={`${process.env.PUBLIC_URL}/activenewconsignments`} element={<Consignments_New />} />
                            <Route path={`${process.env.PUBLIC_URL}/completednewconsignments`} element={<Consignments_New />} />
                            <Route path={`${process.env.PUBLIC_URL}/allnewconsignments`} element={<Consignments_New />} />

                            <Route path={`${process.env.PUBLIC_URL}/epod`} element={<Epod />} />
                            <Route path={`${process.env.PUBLIC_URL}/printepoditems/:invoice_no`} element={<EPODPrint />} />

                            <Route path={`${process.env.PUBLIC_URL}/dealers`} element={<Dealers />} />
                            <Route path={`${process.env.PUBLIC_URL}/vehicles`} element={<Vehicles />} />
                            <Route path={`${process.env.PUBLIC_URL}/transporters`} element={<Transporters />} />

                            <Route path={`${process.env.PUBLIC_URL}/simtrackingdrivers`} element={<SimTracking />} />
                            <Route path={`${process.env.PUBLIC_URL}/confignd`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/configospd`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/confignogps`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/configstoppages`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/configldelay`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/configudelay`} element={<Configuration />} />

                            <Route path={`${process.env.PUBLIC_URL}/ticketmanagementsystem`} element={<TicketManagementSystem />} />
                            <Route path={`${process.env.PUBLIC_URL}/tpttruckgpsstatus`} element={<TPTTrucksGPSStatus />} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/gpsintegration`} element={<TrucksWithInGeofence />} /> */}
                            <Route path={`${process.env.PUBLIC_URL}/snddepartmentvicinity/sndg`} element={<TrucksWithInGeofence />} />
                            <Route path={`${process.env.PUBLIC_URL}/nearbysndtrucks`} element={<ManageNearByConsignmentTrucks />} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/sndsafetyreport`} element={<SafetyReport />} /> */}
                            <Route path={`${process.env.PUBLIC_URL}/sndgeofence`} element={<ConsignmentsGeofence />} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/optimizeroutes`} element={<OptimizeDeviationRoutes />} /> */}
                            <Route path={`${process.env.PUBLIC_URL}/deviationroutes`} element={<DeviationRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/dailyrunningdashboard`} element={<DailyRunningDashboardComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndloadingdashboard`} element={<LoadingDashboard />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndtransitanalytics`} element={<TransitAnalytics />} />
                            <Route path={`${process.env.PUBLIC_URL}/gpsuptimereport`} element={<GpsUptimeReport />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndvicinity`} element={<ConsigneeVicinity />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndtranshipments`} element={<TranshipmentComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndtransportdashboard`} element={<DailyRunningTransporterDashboardComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndgpsdashboard`} element={<GPSDashboardComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/ontimedashboard`} element={<OnTimeDashboardComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/nightdriving`} element={<NightDriving />} />
                            <Route path={`${process.env.PUBLIC_URL}/overspeedreport`} element={<OverspeedReport />} />
                            <Route path={`${process.env.PUBLIC_URL}/stoppagereport`} element={<StoppageReport />} />
                            <Route path={`${process.env.PUBLIC_URL}/trucksdistancematrix`} element={<DayWiseDistanceReport />} />


                            <Route path={`${process.env.PUBLIC_URL}/generatenewewaybill`} element={<GenerateEwayBill />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybilldata/1`} element={<EwayBillData />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybilldata/2`} element={<EwayBillData />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybilldata/3`} element={<EwayBillData />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybillrequests`} element={<EwayBillRequests />} />
                            <Route path={`${process.env.PUBLIC_URL}/consolidateewaybill`} element={<ConsolidateEwayBill />} />
                            <Route path={`${process.env.PUBLIC_URL}/consolidateewaybillrequests`} element={<ConsolidateEwayBillRequests />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybillreport`} element={<EwayBillData />} />
                            <Route path={`${process.env.PUBLIC_URL}/sidebar`} element={<MenuSideBar />} />

                            <Route path={`${process.env.PUBLIC_URL}/optimizeroutes`} element={<OptimizeDeviationRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/dealerzonewiseroutes`} element={<DealerZoneWiseRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/deviationanalysisdata`} element={<DeviationRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/saproutes`} element={<SavedBaseRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/topbaseroutessummary`} element={<TopBaseRouteSummary />} />



                            <Route path={`${process.env.PUBLIC_URL}/geofence`} element={<Geofence />} />
                            <Route path={`${process.env.PUBLIC_URL}/addgeofence`} element={<AddGeofence />} />
                            <Route path={`${process.env.PUBLIC_URL}/editgeofence`} element={<EditGeofence />} />
                            <Route path={`${process.env.PUBLIC_URL}/editGeofence/:geofencename`} element={<EditGeofence />} />


                            <Route path={`${process.env.PUBLIC_URL}/consignments-active`} element={<Consignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/consignments-delivered`} element={<Consignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/consignments-all`} element={<Consignments />} />

                            <Route path={`${process.env.PUBLIC_URL}/sndunloadingdashboard`} element={<UnLoadingDashboard />} />
                            <Route path={`${process.env.PUBLIC_URL}/transittimes`} element={<TransitTimes />} />
                            <Route path={`${process.env.PUBLIC_URL}/dealerDetentionReport`} element={<DealerDetentionReport />} />
                            {/* Dispatch Planing Routes  Start*/}

                            <Route path={"/pickup_and_drop"} element={<PickupDropLocations />} />
                            <Route path={"/vehicles_data"} element={<VehiclesData />} />
                            <Route path={"/vehicle_availability"} element={<VehicleAvailabilityDetails />} />
                            <Route path={"/sku_master"} element={<SKUMaster />} />
                            <Route path={"/transporter_details"} element={<TransporterConfiguration />} />
                            <Route path={"/freightconfigurations"} element={<VehicleCostConfigurations />} />
                            <Route path={"/consolidatedRun"} element={<ConsolidatedRunPlan />} />
                            <Route path={"/viewdispatchplandetails"} element={<ViewDispatchPlanDetails />} />
                            <Route path={"/ordermanagement"} element={<Ordermanagement />} />
                            {/* END */}
                            {/* Freight Management Start*/}
                            <Route path="/freight-configurations" element={<FrieghtConfigurations />} />
                            <Route path="/dieselprices" element={<BillingDieselPrices />} />
                            <Route path="/apisetup" element={<APIConfigurations />} />
                            <Route path="/trips" element={<BillingTrips />} />
                            <Route path="/upload-trips" element={<UploadBillingTrips />} />
                            <Route path="/freightrates" element={<FreightRates />} />
                            <Route path="/dieselpriceapprovals" element={<DieselPriceApproval />} />
                            <Route path="/documents/:document_no" element={<FreightApprovalSubmit />} />
                            <Route path="/approvals" element={<FreighLevelApprovals />} />
                            <Route path="/view-document-approval/:document_no" element={<ViewFreightDocument />} />
                            <Route path="/view-document/:document_no" element={<OnlyViewFreightDocument />} />
                            <Route path="/view-closed-document/:document_no" element={<OnlyViewFreightDocument />} />
                            <Route path="/download-document/:document_no" element={<DownloadFreightDocument />} />
                            <Route path="/user-access" element={<BillingModuleUserAccess />} />
                            <Route path="/transporter-gst" element={<BillingTransporterGST />} />
                            <Route path="/deviations" element={<BillingDeviationFlow />} />

                            {/* END */}
                            {/* RFQ Start */}
                            <Route path="/admin-dashboard" element={<Approverdashboard />} />
                            <Route path="/reverse-auction" element={<ReverseAuction />} />
                            <Route path="/request-quotation" element={<RfqRevamp />} />
                            <Route path="/request-quotation/:id" element={<RfqRevamp />} />
                            <Route path="/vendorRegistration" element={<VendorRegistration />} />
                            <Route path="/vendor-dashboard" element={<VendorProcureDashboard />} />
                            <Route path="/vendor-dashboard-revamp" element={<VendorProcureDashboardRevamp />} />
                            <Route path="/event-details/:id" element={<EventDetails />} />
                            <Route path="/organiser-dashboard" element={<OrganiserProcureDashboard />} />
                            <Route path="/rfqResponse/:event_id" element={<RfqResponseRevamp />} />
                            <Route path="/rfqVendorComparison/:event_id" element={<RfqVendorComparison />} />

                            <Route path="/rfqComparitiveAnalysis/:id" element={<RfqComparitiveAnalysis />} />
                            <Route path="/rfqPreviewAndApprove/:id" element={<RfqPreviewAndApprovePage />} />

                            {/* Container Tracking Routes  Start*/}
                            <Route path="/container-tracking" element={<ContainerTracking />} />
                            <Route path="/tracking_details/:searchType/:searchNumber" element={<ContainerTrackingDetails />} />
                            <Route path='/users' element={<ClientUsers />} />
                            <Route path={"/clients"} element={<Clients />} />
                            <Route path={"/create-shipment"} element={<CreateShipment />} />
                            {/* Container Tracking Routes  End*/}
                            
                            {/* END */}
                        </Route>

                    </Routes>
                    {/* </ScrollRestoration> */}
                </Router>
            </Provider>
        )
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);


