import React, { useEffect } from "react";
import "./rfqVendorComparison.css";
import { useParams } from "react-router-dom";
import redirectURL from '../redirectURL'
const RfqVendorComparison = () => {
  const handleShowFilter = () => {

  }

  const rfq_event_id = useParams().event_id;

  useEffect(()=>{
    redirectURL.post("/procurement/getEventResponses", {rfq_event_id}).then((resp)=>{
        console.log("getEventResponses data", resp.data);
    }).catch((err)=>{
        console.log("getEventResponses err", err);
    })
  },[])


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12 col-md-12 mb-4">
          <div
            className="card shadow h-100 py-2"
            style={{ borderRadius: "15px" }}
          >
            <div className="card-body" style={{ padding: "10px 35px" }}>
              <div className="row">
                {/* First Column */}
                <div
                  className="col-xl-4 col-lg-4 col-md-4 border-end grey-border"
                  style={{ position: "sticky", left: 0, zIndex: 1 }}
                >
                  <div
                    className="row gray-border-bottom"
                    style={{ padding: "20px", height: "140px" }}
                  >
                    <div className="col">
                      <h6 style={{ fontWeight: "500" }}>Vendor</h6>
                      <h6 style={{ fontWeight: "500" }}>Price</h6>
                      <button
                        onClick={handleShowFilter}
                        className="btn btn-primary mr-1 custom-filter-btn"
                        style={{ background: "transparent" }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Filter"
                      >
                        <i className="fas fa-filter"></i>&nbsp; Filter
                      </button>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ padding: "20px", height: "480px" }}
                  >
                    <div className="col">
                      <p>
                        Carrier&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transit Time&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transhipment Via&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Detention Free time at Destination&nbsp;
                        <span className="">|</span>
                      </p>
                      <p>
                        Schedule&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Valid Till&nbsp;<span className="">|</span>
                      </p>
                    </div>
                  </div>
                  <div className="row header-gray-border">
                    <div className="col">
                      <p
                        style={{
                          margin: "0",
                          padding: "10px 20px",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Freight Charges
                      </p>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ padding: "20px", height: "200px" }}
                  >
                    <div className="col">
                      <p>
                        Carrier&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transit Time&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transhipment Via&nbsp;<span className="">|</span>
                      </p>
                      <h6 style={{ fontWeight: "500" }}>Subtotal</h6>
                    </div>
                  </div>
                  <div className="row header-gray-border">
                    <div className="col">
                      <p
                        style={{
                          fontSize: "14px",
                          padding: "10px 20px",
                          fontWeight: "500",
                        }}
                      >
                        Origin Charges
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ padding: "20px" }}>
                    <div className="col">
                      <p>
                        Carrier&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transit Time&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transhipment Via&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transhipment Via&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transhipment Via&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transhipment Via&nbsp;<span className="">|</span>
                      </p>
                      <p>
                        Transhipment Via&nbsp;<span className="">|</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8">
                  <div className="row vendor-group">
                    <div className="col border-end grey-border">
                      <div
                        className="row gray-border-bottom"
                        style={{ padding: "20px", height: "140px" }}
                      >
                        <div className="col">
                          <h6 style={{ fontWeight: "500" }}>
                            Vendor Company 1
                          </h6>
                          <h6 style={{ fontWeight: "500" }}>Cost</h6>
                          <button
                            className="custom-type-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Filter"
                          >
                            RANK&nbsp;#1
                          </button>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "480px" }}
                      >
                        <div className="col">
                          <p>Details for Carrier</p>
                          <p>Details for Transit Time</p>
                          <p>Details for Transhipment Via</p>
                          <p>Details for Detention Free Time</p>
                          <p>Details for Schedule</p>
                          <p>Details for Valid Till</p>
                          <button className="btn btn-primary inverse-btn quote-button-styling">
                            View Complete Quote
                          </button>
                          <div className="quote-block">
                            <button className="btn btn-primary me-2 inverse-btn">
                              Confirm Quote
                            </button>
                            <button className="btn btn-primary me-2 inverse-btn">
                              Negotiate
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{
                                fontSize: "12px",
                                width: "112px",
                                marginRight: "25px",
                              }}
                            >
                              Messages
                            </button>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{ fontSize: "12px", width: "112px" }}
                            >
                              More Options
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              margin: "0",
                              padding: "10px 20px",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "200px" }}
                      >
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              fontSize: "14px",
                              padding: "10px 20px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="row" style={{ padding: "20px" }}>
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col border-end grey-border">
                      <div
                        className="row gray-border-bottom"
                        style={{ padding: "20px", height: "140px" }}
                      >
                        <div className="col">
                          <h6 style={{ fontWeight: "500" }}>
                            Vendor Company 1
                          </h6>
                          <h6 style={{ fontWeight: "500" }}>Cost</h6>
                          <button
                            className="custom-type-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Filter"
                          >
                            RANK&nbsp;#1
                          </button>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "480px" }}
                      >
                        <div className="col">
                          <p>Details for Carrier</p>
                          <p>Details for Transit Time</p>
                          <p>Details for Transhipment Via</p>
                          <p>Details for Detention Free Time</p>
                          <p>Details for Schedule</p>
                          <p>Details for Valid Till</p>
                          <button className="btn btn-primary inverse-btn quote-button-styling">
                            View Complete Quote
                          </button>
                          <div className="quote-block">
                            <button className="btn btn-primary me-2 inverse-btn">
                              Confirm Quote
                            </button>
                            <button className="btn btn-primary me-2 inverse-btn">
                              Negotiate
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{
                                fontSize: "12px",
                                width: "112px",
                                marginRight: "25px",
                              }}
                            >
                              Messages
                            </button>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{ fontSize: "12px", width: "112px" }}
                            >
                              More Options
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              margin: "0",
                              padding: "10px 20px",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "200px" }}
                      >
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              fontSize: "14px",
                              padding: "10px 20px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="row" style={{ padding: "20px" }}>
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col border-end grey-border">
                      <div
                        className="row gray-border-bottom"
                        style={{ padding: "20px", height: "140px" }}
                      >
                        <div className="col">
                          <h6 style={{ fontWeight: "500" }}>
                            Vendor Company 1
                          </h6>
                          <h6 style={{ fontWeight: "500" }}>Cost</h6>
                          <button
                            className="custom-type-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Filter"
                          >
                            RANK&nbsp;#1
                          </button>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "480px" }}
                      >
                        <div className="col">
                          <p>Details for Carrier</p>
                          <p>Details for Transit Time</p>
                          <p>Details for Transhipment Via</p>
                          <p>Details for Detention Free Time</p>
                          <p>Details for Schedule</p>
                          <p>Details for Valid Till</p>
                          <button className="btn btn-primary inverse-btn quote-button-styling">
                            View Complete Quote
                          </button>
                          <div className="quote-block">
                            <button className="btn btn-primary me-2 inverse-btn">
                              Confirm Quote
                            </button>
                            <button className="btn btn-primary me-2 inverse-btn">
                              Negotiate
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{
                                fontSize: "12px",
                                width: "112px",
                                marginRight: "25px",
                              }}
                            >
                              Messages
                            </button>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{ fontSize: "12px", width: "112px" }}
                            >
                              More Options
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              margin: "0",
                              padding: "10px 20px",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "200px" }}
                      >
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              fontSize: "14px",
                              padding: "10px 20px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="row" style={{ padding: "20px" }}>
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col border-end grey-border">
                      <div
                        className="row gray-border-bottom"
                        style={{ padding: "20px", height: "140px" }}
                      >
                        <div className="col">
                          <h6 style={{ fontWeight: "500" }}>
                            Vendor Company 1
                          </h6>
                          <h6 style={{ fontWeight: "500" }}>Cost</h6>
                          <button
                            className="custom-type-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Filter"
                          >
                            RANK&nbsp;#1
                          </button>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "480px" }}
                      >
                        <div className="col">
                          <p>Details for Carrier</p>
                          <p>Details for Transit Time</p>
                          <p>Details for Transhipment Via</p>
                          <p>Details for Detention Free Time</p>
                          <p>Details for Schedule</p>
                          <p>Details for Valid Till</p>
                          <button className="btn btn-primary inverse-btn quote-button-styling">
                            View Complete Quote
                          </button>
                          <div className="quote-block">
                            <button className="btn btn-primary me-2 inverse-btn">
                              Confirm Quote
                            </button>
                            <button className="btn btn-primary me-2 inverse-btn">
                              Negotiate
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{
                                fontSize: "12px",
                                width: "112px",
                                marginRight: "25px",
                              }}
                            >
                              Messages
                            </button>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{ fontSize: "12px", width: "112px" }}
                            >
                              More Options
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              margin: "0",
                              padding: "10px 20px",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "200px" }}
                      >
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              fontSize: "14px",
                              padding: "10px 20px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="row" style={{ padding: "20px" }}>
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col border-end grey-border">
                      <div
                        className="row gray-border-bottom"
                        style={{ padding: "20px", height: "140px" }}
                      >
                        <div className="col">
                          <h6 style={{ fontWeight: "500" }}>
                            Vendor Company 1
                          </h6>
                          <h6 style={{ fontWeight: "500" }}>Cost</h6>
                          <button
                            className="custom-type-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Filter"
                          >
                            RANK&nbsp;#1
                          </button>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "480px" }}
                      >
                        <div className="col">
                          <p>Details for Carrier</p>
                          <p>Details for Transit Time</p>
                          <p>Details for Transhipment Via</p>
                          <p>Details for Detention Free Time</p>
                          <p>Details for Schedule</p>
                          <p>Details for Valid Till</p>
                          <button className="btn btn-primary inverse-btn quote-button-styling">
                            View Complete Quote
                          </button>
                          <div className="quote-block">
                            <button className="btn btn-primary me-2 inverse-btn">
                              Confirm Quote
                            </button>
                            <button className="btn btn-primary me-2 inverse-btn">
                              Negotiate
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{
                                fontSize: "12px",
                                width: "112px",
                                marginRight: "25px",
                              }}
                            >
                              Messages
                            </button>
                            <button
                              className="btn btn-outline-primary custom-type-2"
                              style={{ fontSize: "12px", width: "112px" }}
                            >
                              More Options
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              margin: "0",
                              padding: "10px 20px",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ padding: "20px", height: "200px" }}
                      >
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                      <div className="row header-gray-border">
                        <div className="col">
                          <p
                            style={{
                              fontSize: "14px",
                              padding: "10px 20px",
                              fontWeight: "500",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="row" style={{ padding: "20px" }}>
                        <div className="col">
                          <p>
                            Carrier&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transit Time&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                          <p>
                            Transhipment Via&nbsp;<span className="">|</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RfqVendorComparison;
