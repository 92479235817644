// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-popup12{
    /* background-color: white; */
    padding: 0px !important;  
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/optirun-admin/viewdispatchplandetails.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".delete-popup12{\n    /* background-color: white; */\n    padding: 0px !important;  \n    margin-bottom: 0px;\n    margin-left: 0px;\n    margin-right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
