import React from "react";
 
const GridButton = (props) => {
  const handleOnClick = () => {
    props.colDef.params.onClickFunction(props, true);
  };
 
  let iconName = "fa fa-edit";
  if (props.colDef.params.iconName) {
    iconName = props.colDef.params.iconName;
  }
 
  let gButton = "btn btn-warning";
  if (props.colDef.params.buttonCls) {
    gButton = props.colDef.params.buttonCls;
  }
 
  let isDisable = false;
  if (props.colDef.params.buttonName === "Update" && props.data.fo_number) {
    isDisable = true;
  }
 
  if (props.colDef.params.buttonName === "Download" && props.data.execution_status === 0) {
    isDisable = true;
  }
 
  if (props.colDef.params.buttonName === "Edit" &&
    props.data.vehicle_no !== "Not Assigned" &&
    props.data.route_finalized === 1 &&
    props.data.is_available === 0) {
    isDisable = true;
  }
 
  if (props.colDef.params.buttonName === "Assign Truck" &&
    props.data.vehicle_no !== "Not Assigned" &&
    props.data.route_finalized === 1 &&
    props.data.is_available === 0) {
    isDisable = true;
  }
 
  if (props.colDef.params.check_for_epod === 1) {
    gButton = '';
    if (props.data.process_epod === 1) {
      iconName = "fa fa-download f20";
    } else {
      iconName = "fa fa-edit";
    }
  }
 
 
 
  return (
    <div>
      {(props.colDef.params.check_for_epod === 1 || props.colDef.params.check_for_invoice === 1)?(<button
        className={gButton + " grid-btn"}
        disabled={isDisable}
        onClick={handleOnClick}
        style={{ border: "0px", position: "relative" }}
      >
        {props.colDef.params.buttonName === "Assign" ? "Assign" : (
          props.colDef.params.buttonName === "approve" ||
          props.colDef.params.buttonName === "Update" ? (
            <i className={iconName}></i>
          ) : (
            props.colDef.params.buttonName === "View" ? (
              <i className={iconName}></i>
            ) : (
              <><i className={iconName}></i></>
            )
          )
        )}
      </button>):(<button
        className={gButton + " grid-btn"}
        disabled={isDisable}
        onClick={handleOnClick}
        style={{ border: "0px", position: "relative" }}
      >
        {props.colDef.params.buttonName === "Assign" ? "Assign" : (
          props.colDef.params.buttonName === "approve" ||
          props.colDef.params.buttonName === "Update" ? (
            <i className={iconName}></i>
          ) : (
            props.colDef.params.buttonName === "View" ? (
              <i className={iconName}></i>
            ) : (
              <><i className={iconName}></i> {props.colDef.params.buttonName}</>
            )
          )
        )}
      </button>)}
    </div>
  );
};
 
export default GridButton;