import React, { useState, useEffect, useRef } from "react";
import redirectURL from "../redirectURL";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapLoader from "./BootstrapLoader";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { countries } from "country-flags-svg";
import { FcHighPriority } from "react-icons/fc";
import { FaPowerOff } from "react-icons/fa6";
import { ToggleSwitch } from "./ToggleSwitch";
import { GoDotFill } from "react-icons/go";
import Pagination from 'react-bootstrap/Pagination';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { FaEdit } from "react-icons/fa";
import { RxDotsVertical } from "react-icons/rx";
import { AiFillApi } from "react-icons/ai";
import { FaSearchLocation } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa6";
import { FaLessThan } from "react-icons/fa6";
import Favourites from "../common/favouritesComponent";

const Clients = () => {

    const [clientID, setClientID] = useState('')
    const [clientList, setClientList] = useState([])
    const [clientName, setClientName] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [emailID, setEmailID] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [show, setShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [basicType, setBasicType] = useState("default");
    const [basicTitle, setBasicTitle] = useState("");
    const [overly, setOverly] = useState("show-n");
    const [openAddClient, setOpenAddClient] = useState(false);
    const [countriesList, setCountriesList] = useState([]);
    const [countriesList2, setCountriesList2] = useState([]);
    const [flag, setFlag] = useState();
    const [openChangeStatus, setOpenChangeStatus] = useState(false);
    const [header, setHeader] = useState('');
    const [currentClient, setCurrentClient] = useState('');
    const [route, setRoute] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [show2, setShow2] = useState(false);
    const [basicType2, setBasicType2] = useState("default");
    const [basicTitle2, setBasicTitle2] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [zipcode, setZipcode] = useState("")
    const [openEditClient, setOpenEditClient] = useState(false)
    const editClientID = useRef(null)
    const [editClientName, setEditClientName] = useState('')
    const [eidtFirstName, setEditFirstName] = useState('')
    const [editLastName, setEditLastName] = useState('')
    const editEmailID = useRef(null)
    const [editFlag, setEditFlag] = useState()
    const [editPhoneNumber, setEditPhoneNumber] = useState('')
    const [editAddress, setEditAddress] = useState('')
    const [editCountry, setEditCountry] = useState('')
    const [editZipcode, setEditZipcode] = useState('')
    const [apiSelected, setApiSelected] = useState('')


    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            height: "1rem",
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "14px",
        }),
        control: (base, stat) => ({
            ...base,
            lineHeight: '1rem',
            height: "3rem",
            boxShadow: stat.isFocused ? "0px 0px 10px #4e73df" : "0px 0px 8px #d9d9d9",
            borderRadius: "6px",
            fontSize: "0.8rem"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: '0.8rem', // Change the font size as per your requirement
        }),
        menuList: (base, state) => ({
            ...base,
            maxHeight: '10rem'
        })
    };

    const customStyles2 = {
        option: (provided, state) => ({
            ...provided,
        }),
        control: (base, stat) => ({
            ...base,
            borderRadius: "6px",
            height: "3rem",
            width: "2.5rem",
            padding: "0px",
        }),
        menuList: (base, state) => ({
            ...base,
            maxHeight: '10rem',
            padding: "0px",
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
        }),
        dropdownIndicator: base => ({
            ...base,
            display: 'none',
            padding: "0px"
        }),
        indicatorsContainer: base => ({
            ...base,
            display: 'none !important'
        }),
        menu: base => ({
            ...base,
            padding: "0px",
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
        }),
    };
    const generateClientID = () => {
        const characters = '0123456789';
        let uniqueString = 'CL-';
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            uniqueString += characters.charAt(randomIndex);
        }
        return uniqueString;
    }


    useEffect(() => {
        onLoadData()
    }, [])


    function changeData(i) {
        console.log(i, "iiiiiiiiiiiiiiii");
        setCurrentPage(i + 1)
        setCurrentItems(clientList.slice(((i) * 5), ((i) * 5) + 5))
    }

    const onLoadData = async () => {

        let countriesArray = []
        let countriesArray2 = []
        countries.filter(each => {
            let option =
                <div>
                    <img
                        src={each.flag}
                        alt={each.name}
                        style={{ width: "20px", height: "16px" }}
                    />
                </div>
            if (each.code != "UM") {
                countriesArray2.push({ label: option, value: each.name, code: each.iso2 })
                countriesArray.push({ label: each.name, value: each.name })
            }
        })
        setCountriesList(countriesArray)
        setCountriesList2(countriesArray2)

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
                const data = await response.json();
                setFlag(countriesArray2.find(e => e.code == data.countryCode))
                setCountry(countriesArray.find(e => e.value == data.countryName))
            },
                (error) => {
                    setFlag({
                        "label":
                            <div>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                                    alt="India"
                                    style={{ width: "20px", height: "16px" }}
                                />
                            </div>,
                        "value": "India",
                        "code": "IN"
                    })
                    setCountry({ label: "India", value: "India" })
                });
        } else {
            setFlag({
                "label":
                    <div>
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                            alt="India"
                            style={{ width: "20px", height: "16px" }}
                        />
                    </div>,
                "value": "India",
                "code": "IN"
            })
            setCountry({ label: "India", value: "India" })
        }
        await redirectURL.post("exim/getClients", { email: localStorage.getItem('email') })
            .then((response) => {
                if (response.data.status == "success") {
                    setClientList(response.data.clientsData)
                    setCurrentItems(response.data.clientsData.slice(0, 5))
                }
                else {
                    // setShow2(true);
                    // setBasicType2("danger");
                    // setBasicTitle2(response.data.message);
                }
            })
    }

    const changeHandler2 = (selectedOption) => {
        setCountry(selectedOption);
        setFlag(countriesList2.find(e => e.value == selectedOption.label))
        setPhoneNumber("")
    };

    const changeHandler3 = (selectedOption) => {
        setCountry(countriesList.find(e => e.value == selectedOption.value))
        setFlag(selectedOption);
        setPhoneNumber("")
    };


    const changeHandler6 = (selectedOption) => {
        setEditCountry(selectedOption);
        setEditFlag(countriesList2.find(e => e.value == selectedOption.label))
        setEditPhoneNumber("")
    };

    const changeHandler4 = (selectedOption) => {
        setEditCountry(countriesList.find(e => e.value == selectedOption.value))
        setEditFlag(selectedOption);
        setEditPhoneNumber("")
    };


    const onEditSubmitHandler = async (e) => {
        e.preventDefault();
        if (editPhoneNumber != "") {
            let numberObj = parsePhoneNumber(editPhoneNumber, editFlag.code)
            if (!numberObj.isPossible()) {
                setShow2(true)
                setBasicType2("danger")
                setBasicTitle2("Please enter a valid phone number")
            }
            else {
                setShowLoader(true)
                let body = {
                    client_id: editClientID.current,
                    org_name: editClientName,
                    firstname: eidtFirstName,
                    lastname: editLastName,
                    mobile_no: editPhoneNumber,
                    address: editAddress,
                    zipcode: editZipcode,
                    country: editCountry.label,
                    vendor_email: localStorage.getItem("email")
                }
                await redirectURL.post("exim/editClientData", body)
                    .then((response) => {
                        if (response.data.status == "success") {
                            setOpenEditClient(false)
                            setRoute('saveClientData')
                            setShow(true);
                            setBasicType("success");
                            setBasicTitle(response.data.message);
                        }
                        else {
                            setShow(true);
                            setBasicType("danger");
                            setBasicTitle(response.data.message);
                        }
                    })

                setShowLoader(false)
            }
        }
        else {
            setShowLoader(true)
            let body = {
                client_id: editClientID.current,
                org_name: editClientName,
                firstname: eidtFirstName,
                lastname: editLastName,
                mobile_no: editPhoneNumber,
                address: editAddress,
                zipcode: editZipcode,
                country: editCountry.label,
                vendor_email: localStorage.getItem("email")
            }
            await redirectURL.post("exim/editClientData", body)
                .then((response) => {
                    if (response.data.status == "success") {
                        setOpenEditClient(false)
                        setRoute('saveClientData')
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle(response.data.message);
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle(response.data.message);
                    }
                })
            setShowLoader(false)
        }
    }


    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (phoneNumber != "") {
            let numberObj = parsePhoneNumber(phoneNumber, flag.code)
            if (!numberObj.isPossible()) {
                setShow2(true)
                setBasicType2("danger")
                setBasicTitle2("Please enter a valid phone number")
            }
            else {
                setShowLoader(true)
                let body = {
                    client_id: clientID,
                    org_name: clientName,
                    firstname: firstName,
                    lastname: lastName,
                    email: emailID,
                    mobile_no: phoneNumber,
                    address: address,
                    zipcode: zipcode,
                    country: country.label,
                    api_provider: apiSelected.value,
                    vendor_email: localStorage.getItem("email")
                }
                await redirectURL.post("exim/saveClientData", body)
                    .then((response) => {
                        if (response.data.status == "success") {
                            setOpenAddClient(false)
                            setRoute('saveClientData')
                            setShow(true);
                            setBasicType("success");
                            setBasicTitle(response.data.message);
                        }
                        else {
                            setShow(true);
                            setBasicType("danger");
                            setBasicTitle(response.data.message);
                        }
                    })

                setShowLoader(false)
            }
        }
        else {
            setShowLoader(true)
            let body = {
                client_id: clientID,
                org_name: clientName,
                firstname: firstName,
                lastname: lastName,
                email: emailID,
                mobile_no: phoneNumber,
                address: address,
                zipcode: zipcode,
                country: country.label,
                api_provider: apiSelected.value,
                vendor_email: localStorage.getItem("email")
            }
            await redirectURL.post("exim/saveClientData", body)
                .then((response) => {
                    if (response.data.status == "success") {
                        setOpenAddClient(false)
                        setRoute('saveClientData')
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle(response.data.message);
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle(response.data.message);
                    }
                })

            setShowLoader(false)
        }
    }

    const getFlagIcon = (countryName) => {
        var srcFlag = countries.filter((eachCountry) => {
            if (eachCountry.name == countryName) {
                return eachCountry
            }
        });
        if (srcFlag && srcFlag[0]?.flag) {
            return {
                img: srcFlag[0]?.flag
            };
        } else {
            return "";
        }
    };

    const closeModal = () => {
        setOpenAddClient(false)
        setOpenChangeStatus(false)
        setShowLoader(false)
        setOpenEditClient(false)
    };

    function openEditModal(client) {
        editClientID.current = client.client_id
        setEditClientName(client.org_name)
        setEditFirstName(client.firstname)
        setEditLastName(client.lastname)
        editEmailID.current = client.email
        let currentflag = countriesList2.find(e => e.value == client.country)
        setEditFlag(currentflag)

        try {
            let numberObj = parsePhoneNumber(client.mobile_no)
            setEditPhoneNumber(numberObj.nationalNumber)
        } catch (e) {
            setEditPhoneNumber(client.mobile_no)
        }

        setEditAddress(client.address)
        setEditCountry({ label: client.country, value: client.country })
        setEditZipcode(client.zipcode)
        setOpenEditClient(true)
    }

    const openModal = async () => {

        let uniqueString = generateClientID()
        let status = true
        while (status) {
            await redirectURL.post("exim/checkClientID", { client_id: uniqueString })
                .then((response) => {
                    if (response.data.status == "notExist") {
                        status = false
                        setClientID(uniqueString)
                    }
                    else {
                        uniqueString = generateClientID()
                    }
                })
        }
        setClientName('')
        setEmailID('')
        setPhoneNumber('')
        setAddress('')
        setFirstName('')
        setLastName('')
        setZipcode('')
        setOpenAddClient(true)
    };

    function changeStatus(client) {
        setOpenChangeStatus(true)
        setShowLoader(true)
        if (client.active == 1) {
            setHeader(`Are you sure you want to de-activate all ${client.org_name} users?`)
        }
        else {
            setHeader(`Are you sure you want to activate ${client.org_name}?`)
        }
        setCurrentClient(client)
    }

    const changeStatusSubmit = async () => {
        if (currentClient.active == 1) {
            await redirectURL.post("exim/deactivateClient", { client_id: currentClient.client_id, org_name: currentClient.org_name, email: currentClient.email })
                .then((response) => {
                    setShowLoader(false)
                    if (response.data.status == "success") {
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle("Successfully de-activated client and its users");
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle("Failed to de-activate");
                    }
                })
        }
        else {
            await redirectURL.post("exim/activateClient", { client_id: currentClient.client_id, org_name: currentClient.org_name, email: currentClient.email })
                .then((response) => {
                    setShowLoader(false)
                    if (response.data.status == "success") {
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle("Successfully activated client and its users");
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle("Failed to activate");
                    }
                })
        }
        setOpenChangeStatus(false)
        onLoadData()
        setCurrentPage(1)
    }

    const closeAlert = () => {
        setShow(false);
        setOverly("show-n");
        setShowLoader(false);
        onLoadData()
        setCurrentPage(1)
        if (route == 'saveClientData') {
            setRoute('')
        }
    };



    const changeHandler = (e) => {
        let number = ""
        if (e.target.value.match(/\d+/g) !== null)
            number = e.target.value.match(/\d+/g).join()
        setPhoneNumber(number)
    }

    const formatPhoneNumber = () => {
        if (phoneNumber != "") {
            let numberObj = parsePhoneNumber(phoneNumber, flag.code)
            if (!numberObj.isPossible()) {
                setShow2(true)
                setBasicType2("danger")
                setBasicTitle2("Please provide a valid phone number")
            }
            else
                setPhoneNumber(numberObj.number)
        }
    }

    const changeHandler5 = (e) => {
        let number = ""
        if (e.target.value.match(/\d+/g) !== null)
            number = e.target.value.match(/\d+/g).join()
        setEditPhoneNumber(number)
    }

    const editFormatPhoneNumber = () => {
        if (editPhoneNumber != "") {
            let numberObj = parsePhoneNumber(editPhoneNumber, editFlag.code)
            if (!numberObj.isPossible()) {
                setShow2(true)
                setBasicType2("danger")
                setBasicTitle2("Please provide a valid phone number")
            }
            else
                setEditPhoneNumber(numberObj.number)
        }
    }

    const closeAlert2 = () => {
        setShow2(false);
        setShowLoader(false);
    };
    return (
        <div className="container-fluid">
            <BootstrapLoader showLoader={showLoader} />
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            ></SweetAlert>
            <SweetAlert
                show={show2}
                type={basicType2}
                title={basicTitle2}
                onConfirm={closeAlert2}
            ></SweetAlert>
            <Modal open={openChangeStatus} onClose={closeModal}
                center
                classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                }}
                animationDuration={400}
            >
                <div className="">
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
                        Change Status
                    </h5>

                    <div className="m-4 text-center">
                        {
                            currentClient.active == 1 ?
                                <><FcHighPriority size={120} /></> :
                                <><FaPowerOff size={120} color="#58ca58" /></>
                        }
                        <h4 style={{ paddingTop: '30px' }}>{header}</h4>
                    </div>

                    <div className="button-box txt-center mb-3">
                        <button className="btn btn-warning mr-2" onClick={closeModal}>
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={changeStatusSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal open={openAddClient} onClose={closeModal}
                center
                classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                }}
                animationDuration={400}>
                <div className="">
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Create Client</h5>
                </div>
                <div className="px-2 mb-4">
                    <form className="" onSubmit={onSubmitHandler}>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Client ID
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="ClientID"
                                        value={clientID}
                                        disabled
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Organization
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Organization"
                                        maxLength={100}
                                        value={clientName}
                                        onChange={(e) => setClientName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        First Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        pattern="[A-Za-z\s]+"
                                        title="Only alphabetic characters are allowed"
                                        maxLength={30}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Last Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        pattern="[A-Za-z\s]+"
                                        title="Only alphabetic characters are allowed"
                                        maxLength={30}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Email ID
                                    </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email ID"
                                        value={emailID}
                                        onChange={(e) => setEmailID(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label className="custom-label">
                                        Phone Number
                                    </label>
                                    <div className="col-xl-12 col-lg-12" style={{ display: "flex", paddingInline: "0px" }}>

                                        <Select
                                            placeholder=""
                                            closeMenuOnSelect={true}
                                            styles={customStyles2}
                                            onChange={changeHandler3}
                                            options={countriesList2}
                                            value={flag}
                                        />
                                        <input
                                            className="form-control"
                                            type="text"
                                            minLength={8}
                                            maxLength={15}
                                            placeholder="Phone Number"
                                            value={phoneNumber}
                                            onBlur={formatPhoneNumber}
                                            onChange={changeHandler}
                                            style={{ height: "3rem" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <label className="custom-label">
                                Address
                            </label>
                            <textarea
                                className="form-control"
                                type="text"
                                placeholder="Address"
                                value={address}
                                minLength={30}
                                maxLength={120}
                                onChange={(e) => setAddress(e.target.value)}
                                rows={4}
                            />
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <label className="custom-label">
                                Select API
                            </label>
                            <Select
                                placeholder="Select API"
                                closeMenuOnSelect={true}
                                styles={customStyles}
                                onChange={(e) => setApiSelected(e)}
                                options={[{ label: 'Searates', value: 'searates' }, { label: 'Cargoes', value: 'cargoes' }]}
                                value={apiSelected}
                                required
                            />
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Country
                                    </label>
                                    <Select
                                        placeholder="Select Country"
                                        closeMenuOnSelect={true}
                                        styles={customStyles}
                                        onChange={changeHandler2}
                                        options={countriesList}
                                        value={country}
                                        required
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Zipcode
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        maxLength={10}
                                        placeholder="Zipcode"
                                        pattern="[A-Za-z0-9\-]+"
                                        title="Only alphanumeric characters and '-' are allowed"
                                        value={zipcode}
                                        onChange={e => setZipcode(e.target.value)}
                                        style={{ height: "3rem" }}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="col-xl-12 col-lg-12">
                                <button type="submit" className="btn btn-primary btn-user mt-4 pt-2">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>


            <Modal open={openEditClient} onClose={closeModal}
                center
                classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                }}
                animationDuration={400}>
                <div className="">
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Edit Client</h5>
                </div>
                <div className="px-2 mb-4">
                    <form className="" onSubmit={onEditSubmitHandler}>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Client ID
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="ClientID"
                                        value={editClientID.current}
                                        disabled
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Organization
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Organization"
                                        maxLength={100}
                                        value={editClientName}
                                        onChange={(e) => setEditClientName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        First Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="First Name"
                                        value={eidtFirstName}
                                        pattern="[A-Za-z\s]+"
                                        title="Only alphabetic characters are allowed"
                                        maxLength={30}
                                        onChange={(e) => setEditFirstName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Last Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Last Name"
                                        value={editLastName}
                                        pattern="[A-Za-z\s]+"
                                        title="Only alphabetic characters are allowed"
                                        maxLength={30}
                                        onChange={(e) => setEditLastName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Email ID
                                    </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email ID"
                                        value={editEmailID.current}
                                        disabled
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label className="custom-label">
                                        Phone Number
                                    </label>
                                    <div className="col-xl-12 col-lg-12" style={{ display: "flex", paddingInline: "0px" }}>

                                        <Select
                                            placeholder=""
                                            closeMenuOnSelect={true}
                                            styles={customStyles2}
                                            onChange={changeHandler4}
                                            options={countriesList2}
                                            value={editFlag}
                                        />
                                        <input
                                            className="form-control"
                                            type="text"
                                            minLength={8}
                                            maxLength={15}
                                            placeholder="Phone Number"
                                            value={editPhoneNumber}
                                            onBlur={editFormatPhoneNumber}
                                            onChange={changeHandler5}
                                            style={{ height: "3rem" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <label className="custom-label">
                                Address
                            </label>
                            <textarea
                                className="form-control"
                                type="text"
                                placeholder="Address"
                                value={editAddress}
                                minLength={30}
                                maxLength={120}
                                onChange={(e) => setEditAddress(e.target.value)}
                                rows={4}
                            />
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Country
                                    </label>
                                    <Select
                                        placeholder="Select Country"
                                        closeMenuOnSelect={true}
                                        styles={customStyles}
                                        onChange={changeHandler6}
                                        options={countriesList}
                                        value={editCountry}
                                        required
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Zipcode
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        maxLength={10}
                                        placeholder="Zipcode"
                                        pattern="[A-Za-z0-9\-]+"
                                        title="Only alphanumeric characters and '-' are allowed"
                                        value={editZipcode}
                                        onChange={e => setEditZipcode(e.target.value)}
                                        style={{ height: "3rem" }}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="col-xl-12 col-lg-12">
                                <button type="submit" className="btn btn-primary btn-user mt-4 pt-2">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>


            <div className="row">
                <h5 className="col-sm-12 mb-15p fbold f20">
                    <i className="fa fa-users cus-i"></i>&nbsp;<span>Clients</span>
                    <Favourites />
                    <button className="f14 add-user-button float-right" type="button" onClick={openModal}><CreateClientIcon color={"var(--colorOnM)"} />&nbsp; Create Client</button>
                    
                </h5>
            </div>
            <br />
            <div
                className=""
                style={{ minHeight: "87vh", margin: "auto" }}>
                <div className="row">
                    <div className="col-md-12">
                        <div
                            className="card custom-card"
                            style={{
                                padding: "40px",
                                borderRadius: "18px",
                                paddingTop: "30px",
                            }}>
                            <div className="card-body" style={{ padding: "0px", height: "30rem", overflowY: "auto" }}>
                                <table id="clientTable" className="mt-4" style={{ width: '100%' }}>
                                    <thead>
                                        <tr style={{ borderSpacing: '1rem', background: "#eef3ff" }}>
                                            <th style={{ padding: "1.5rem", textAlign: "center" }}>Client ID</th>
                                            <th style={{ textAlign: "left" }}>Organization</th>
                                            <th style={{ textAlign: "left" }}>First Name</th>
                                            <th style={{ textAlign: "left" }}>Last Name</th>
                                            <th style={{ textAlign: "left" }}>Email</th>
                                            {/* <th style={{ textAlign: "left" }}>Phone Number</th> */}
                                            <th style={{ textAlign: "left" }}>Status</th>
                                            <th style={{ textAlign: "left" }}>Change Status</th>
                                            {/* <th style={{ textAlign: "left" }}>API</th> */}
                                            <th style={{ textAlign: "left" }}>Country</th>
                                            {/* <th style={{ textAlign: "left" }}>Address</th> */}
                                            {/* <th style={{ textAlign: "left" }}>Zipcode</th> */}
                                            <th style={{ textAlign: "left" }}></th>
                                            <th style={{ textAlign: "left" }}></th>
                                        </tr>
                                    </thead>
                                    {
                                        (clientList != null && clientList != undefined && clientList.length != 0) ?
                                            <>
                                                {
                                                    currentItems.map((each, index) => {
                                                        return (
                                                            <tbody key={each.client_id} style={{ borderBottom: "1px solid #808080" }}>
                                                                <tr>
                                                                    <td className="px-1" style={{ padding: "1.5rem", textAlign: 'center', whiteSpace: "nowrap" }}>{each.client_id == "" ? "NA" : each.client_id}</td>
                                                                    <td className="px-1" style={{ textAlign: 'left' }}>{each.org_name == "" ? "NA" : each.org_name}</td>
                                                                    <td className="px-1" style={{ textAlign: 'left' }}>{each.firstname == "" ? "NA" : each.firstname}</td>
                                                                    <td className="px-1" style={{ textAlign: 'left' }}>{each.lastname == "" ? "NA" : each.lastname}</td>
                                                                    <td className="px-1" style={{ textAlign: 'left' }}>{each.email == "" ? "NA" : each.email}</td>
                                                                    {/* <td className="px-1" style={{ textAlign: 'left' }}>{each.mobile_no == "" ? "NA" : each.mobile_no}</td> */}
                                                                    <td className="px-1" style={{ textAlign: 'left', whiteSpace: "nowrap" }}>{(each.active == 1) ? <><GoDotFill color="#00c853" size={20} />&nbsp;Active</> : <><GoDotFill size={20} />&nbsp;In-Active</>}</td>
                                                                    <td className="px-1" style={{ textAlign: 'left' }}>
                                                                        {
                                                                            (each.active == 1) ?
                                                                                <ToggleSwitch toggled={true} onClick={e => changeStatus(each)} />
                                                                                :
                                                                                <ToggleSwitch toggled={false} onClick={e => changeStatus(each)} />
                                                                        }
                                                                    </td>

                                                                    {/* <td className="py-2 px-1" style={{ textAlign: 'left', maxWidth: "20rem" }}>{(each.api_provider == "" || each.api_provider == null) ? "NA" : each.api_provider}</td> */}
                                                                    <td style={{ textAlign: 'left' }}>
                                                                        {getFlagIcon(each.country).hasOwnProperty("img") ? (
                                                                            <>
                                                                                &nbsp;&nbsp;<img
                                                                                    src={getFlagIcon(each.country)?.img}
                                                                                    alt="SRC"
                                                                                    style={{ height: '16px', width: '20px', position: 'relative' }}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )} {each.country}
                                                                    </td>
                                                                    {/* <td className="py-2 px-1" style={{ textAlign: 'left', maxWidth: "20rem" }}>{each.address == "" ? "NA" : each.address}</td> */}
                                                                    {/* <td className="px-1" style={{ textAlign: 'left' }}>{each.zipcode == "" ? "NA" : each.zipcode}</td> */}
                                                                    <td className="px-3" style={{ textAlign: 'left', cursor: "pointer" }} onClick={e => openEditModal(each)} ><FaEdit size={20} color="#407bff" /></td>

                                                                    <td className="px-3" style={{ textAlign: 'left', cursor: "pointer" }}>
                                                                        <a data-bs-toggle="collapse" href={`#clientDetails${index}`} role="button" aria-expanded={`clientDetails${index}`} aria-controls={`clientDetails${index}`}>
                                                                            <RxDotsVertical size={28} color="#407bff" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={10} style={{ textAlign: 'left' }}>
                                                                        <div className={`collapse  mr-4 clientDetails${index}`} id={`clientDetails${index}`} style={{ paddingInline: "10rem" }}>
                                                                            <div className="card card-body" style={{ borderRadius: "10px", padding: "3rem" }}>
                                                                                <table style={{ borderStyle: "solid", borderColor: "#cccc", borderCollapse: "collapse" }}>
                                                                                    <tbody>
                                                                                        <tr style={{ borderStyle: "solid", borderColor: "#cccc", borderCollapse: "collapse" }}>
                                                                                            <td style={{ borderStyle: "solid", borderColor: "#cccc", borderCollapse: "collapse", padding: "1rem" }}>
                                                                                                <span style={{ fontSize: "17px" }}>Phone Number</span>&nbsp;&nbsp;<i className="fa fa-phone" style={{ fontSize: "22px", color: "#3772f5" }}></i>
                                                                                                <br />
                                                                                                <p style={{ fontSize: "17px", fontWeight: "600" }}>{each.mobile_no}</p>
                                                                                            </td>
                                                                                            <td style={{ borderStyle: "solid", borderColor: "#cccc", borderCollapse: "collapse", padding: "1rem" }}>
                                                                                                <span style={{ fontSize: "17px" }}>API</span>&nbsp;&nbsp;<AiFillApi size={25} style={{ color: "#3772f5" }} />
                                                                                                <br />
                                                                                                <p style={{ fontSize: "17px", fontWeight: "600" }}>{each.api_provider}</p>
                                                                                            </td>
                                                                                            <td style={{ borderStyle: "solid", borderColor: "#cccc", borderCollapse: "collapse", padding: "1rem" }}>
                                                                                                <span style={{ fontSize: "17px" }}>Zip Code</span>&nbsp;&nbsp;<FaSearchLocation size={23} style={{ color: "#3772f5" }} />
                                                                                                <br />
                                                                                                <p style={{ fontSize: "17px", fontWeight: "600" }}>{each.zipcode}</p>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style={{ borderStyle: "solid", borderColor: "#cccc", borderCollapse: "collapse" }}>
                                                                                            <td colSpan={3} style={{ borderStyle: "solid", borderColor: "#cccc", borderCollapse: "collapse", padding: "1rem" }}>
                                                                                                <span style={{ fontSize: "17px" }}>Address</span>&nbsp;&nbsp;<i className="fa fa-address-card" style={{ fontSize: "22px", color: "#3772f5" }}></i>
                                                                                                <br />
                                                                                                <p style={{ fontSize: "17px", fontWeight: "600" }}>{each.address}</p>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={12}>
                                                            <div
                                                                className="card py-2 pr-3"
                                                                style={{
                                                                    borderRadius: "18px",
                                                                    textAlign: "center",
                                                                    width: '100%',
                                                                    marginTop: "40px",
                                                                }}
                                                            >
                                                                No data found.
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                    }
                                </table>
                            </div>
                            <br />
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FaLessThan color="#407bff" className="pagenation-custom2" size={20}
                                    onClick={
                                        (currentPage > 1) ?
                                            () => changeData(currentPage - 2) :
                                            () => { }
                                    } />&nbsp;&nbsp;&nbsp;
                                {
                                    (Math.ceil(clientList.length / 5) > 5 && currentPage < (Math.ceil(clientList.length / 5) - 5)) ?
                                        <>
                                            {
                                                (currentPage >= 5) ?
                                                    <>
                                                        <span className={`pagenation-custom`}>
                                                            .....
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage - 4) }}>
                                                            {currentPage - 3}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage - 3) }}>
                                                            {currentPage - 2}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage - 2) }}>
                                                            {currentPage - 1}
                                                        </span>
                                                        <span className={`pagenation-custom activepg`}
                                                            onClick={() => { changeData(currentPage - 1) }}>
                                                            {currentPage}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage) }}>
                                                            {currentPage + 1}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage + 1) }}>
                                                            {currentPage + 2}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage + 2) }}>
                                                            {currentPage + 3}
                                                        </span>
                                                        <span className={`pagenation-custom`}>
                                                            .....
                                                        </span>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (currentPage < 5) ?
                                                    <>
                                                        <span className={`pagenation-custom ${(1 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(0) }}>
                                                            {1}
                                                        </span>

                                                        <span className={`pagenation-custom ${(2 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(1) }}>
                                                            {2}
                                                        </span>

                                                        <span className={`pagenation-custom ${(3 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(2) }}>
                                                            {3}
                                                        </span>

                                                        <span className={`pagenation-custom ${(4 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(3) }}>
                                                            {4}
                                                        </span>

                                                        <span className={`pagenation-custom ${(5 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(4) }}>
                                                            {5}
                                                        </span>
                                                        <span className={`pagenation-custom`}>
                                                            .....
                                                        </span>
                                                    </>
                                                    : <></>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                (Math.ceil(clientList.length / 5) > 5 && currentPage >= (Math.ceil(clientList.length / 5) - 5)) ?
                                                    <>
                                                        <span className={`pagenation-custom ${(1 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 5)) }}>
                                                            {1}
                                                        </span>
                                                        <span className={`pagenation-custom ${((Math.ceil(clientList.length / 5) - 1) === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 4)) }}>
                                                            {Math.ceil(clientList.length / 5) - 1}
                                                        </span>
                                                        <span className={`pagenation-custom ${((Math.ceil(clientList.length / 5) - 2) === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 3)) }}>
                                                            {Math.ceil(clientList.length / 5) - 2}
                                                        </span>
                                                        <span className={`pagenation-custom ${((Math.ceil(clientList.length / 5) - 1) === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 2)) }}>
                                                            {Math.ceil(clientList.length / 5) - 1}
                                                        </span>
                                                        <span className={`pagenation-custom ${(Math.ceil(clientList.length / 5) === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 1)) }}>
                                                            {Math.ceil(clientList.length / 5)}
                                                        </span>
                                                    </> :
                                                    <>
                                                        {
                                                            (Math.ceil(clientList.length / 5) < 5) ?
                                                                Array.from({ length: Math.ceil(clientList.length / 5) }, (_, i) => (
                                                                    <span className={`pagenation-custom ${(i + 1 === currentPage) ? "activepg" : ""}`}
                                                                        key={i + 1}
                                                                        onClick={() => { changeData(i) }}>
                                                                        {i + 1}
                                                                    </span>
                                                                ))
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                                &nbsp;&nbsp;&nbsp;<FaGreaterThan className="pagenation-custom2" color="#407bff" size={20}
                                    onClick={
                                        (currentPage < Math.ceil(clientList.length / 5)) ?
                                            () => changeData(currentPage) :
                                            () => { }
                                    } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clients;


const CreateClientIcon = ({ color }) => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.58309 7.90638C5.58309 7.44991 5.21305 7.07987 4.75658 7.07987H1.45053C0.994064 7.07987 0.624023 6.70983 0.624023 6.25336C0.624023 5.79689 0.994065 5.42684 1.45053 5.42684H4.75658C5.21305 5.42684 5.58309 5.0568 5.58309 4.60033V1.29428C5.58309 0.837814 5.95314 0.467773 6.40961 0.467773C6.86608 0.467773 7.23612 0.837815 7.23612 1.29428V4.60033C7.23612 5.0568 7.60616 5.42684 8.06263 5.42684H11.3687C11.8251 5.42684 12.1952 5.79689 12.1952 6.25336C12.1952 6.70983 11.8251 7.07987 11.3687 7.07987H8.06263C7.60616 7.07987 7.23612 7.44991 7.23612 7.90638V11.2124C7.23612 11.6689 6.86608 12.0389 6.40961 12.0389C5.95314 12.0389 5.58309 11.6689 5.58309 11.2124V7.90638Z" fill={color} />
    </svg>

);