import React, { Component } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import StepOneIcon from "../../assets/icons/step1-inactive.svg";
import StepTwoIcon from "../../assets/icons/step6-inactive.svg";
import StepThreeIcon from "../../assets/icons/step8-inactive.svg";
import ClipIcon from "../../assets/icons/clip-icon.svg";
import $ from "jquery";
import "./rfqResponseRevamp.css";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Modal from "react-responsive-modal";
import redirectURL from "../redirectURL";
import RfqDateCellEditor from "./RfqDateCellEditor";

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class RfqResponseRevamp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachment_title: "",
      vendorAttachments: [],
      selected_file: "",
      rfq_vendor_attachments: "",
      isFormSubmitted: 0,
      totalAmountWithGST: 0,
      totalAmountWithoutGST: 0,
      loadshow: "show-n",
      overly: "show-n",
      sliderTranslate: "",
      rfq_subject: "",
      rfq_description: "",
      rfq_start_time: "",
      rfq_end_time: "",
      rfq_valid_till: "",
      tc_doc: "",
      itemsData: [],
      defaultColDef: {
        // flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: true,
        textAlign: "center",
      },
      paginationPageSize: 200,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            { field: "callId" },
            { field: "direction" },
            { field: "number", minWidth: 150 },
            { field: "duration", valueFormatter: "x.toLocaleString() + 's'" },
            { field: "switchCode", minWidth: 150 },
          ],
          defaultColDef: {
            flex: 1,
          },
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.callRecords);
        },
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      openModal: false,
      openPreviewModal: false,
      documentType: "",
      documentUrl: "",
      selectedItemRow: {},
      itemPrice: "",
      itemGST: "",
      itemFreightInsurance: "",
      itemPackingForwarding: "",
      itemDiscount: "",
      isCommercialTermsSameForAllItems: false,
      rfq_event_id: "",
      show: false,
      basicTitle: "",
      basicType: "default",
      commercialTermsDocArr: [],
      rfqQuestions: [],
      previewRfqQuestions: [],
      allProductDetailsFilled: false,
      allQuestionsFilled: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var userType = localStorage.getItem("user_type");
    var email = localStorage.getItem("email");

    let { event_id } = this.props.params;
    this.setState({
      rfq_event_id: event_id,
      vendor_email: email,
    });

    let payload = {
      rfq_event_id: event_id,
    };

    let vendorResppayload = {
      rfq_event_id: event_id,
      email: email,
    };

    var productDetails = [];
    var questionResponses = [];

    redirectURL
      .post("/procurement/getRfqResponsesByEmail", vendorResppayload)
      .then((resp) => {
        console.log("getRfqResponsesByEmail data", resp.data);
        var vendorResponse = resp.data.vendorResponse;
        let totalAmountWithGST = 0;
        let totalAmountWithoutGST = 0;
        if (vendorResponse.length > 0) {
          if (vendorResponse[0].hasOwnProperty("product_details")) {
            productDetails = vendorResponse[0].product_details;

            productDetails.forEach((each) => {
              if (
                each.hasOwnProperty("amount_with_gst") &&
                each.amount_with_gst
              ) {
                totalAmountWithGST += parseFloat(each.amount_with_gst);
              }

              if (
                each.hasOwnProperty("amount_without_gst") &&
                each.amount_without_gst
              ) {
                totalAmountWithoutGST += parseFloat(each.amount_without_gst);
              }
            });
          }

          if (vendorResponse[0].hasOwnProperty("attachments")) {
            this.setState({
              vendorAttachments: vendorResponse[0].attachments,
            });
          }

          if (vendorResponse[0].hasOwnProperty("rfq_response_submitted")) {
            this.setState({
              isFormSubmitted: parseInt(
                vendorResponse[0].rfq_response_submitted
              ),
            });
          }

          if (vendorResponse[0].hasOwnProperty("question_responses")) {
            questionResponses = vendorResponse[0].question_responses;
          }
        }

        this.setState(
          {
            productDetails: productDetails,
            totalAmountWithGST: Number(totalAmountWithGST).toFixed(2),
            totalAmountWithoutGST: Number(totalAmountWithoutGST).toFixed(2),
          },
          () => {
            //for event details
            redirectURL
              .post("/procurement/getRfqEventById", payload)
              .then((resp) => {
                console.log("getRfqEventById", resp.data);
                var eventDetails = resp.data.eventDetails[0];
                this.setState({
                  rfq_subject: eventDetails.rfq_subject,
                  rfq_description: eventDetails.rfq_description,
                  rfq_start_time: eventDetails.rfq_start_time
                    ? moment
                        .parseZone(eventDetails.rfq_start_time)
                        .format("Do MMM YYYY hh:mm")
                    : "",
                  rfq_end_time: eventDetails.rfq_end_time
                    ? moment
                        .parseZone(eventDetails.rfq_end_time)
                        .format("Do MMM YYYY hh:mm")
                    : "",
                });
              })
              .catch((err) => {
                console.log("getRfqEventById err", err);
              });

            //for event items
            redirectURL
              .post("/procurement/getItemsForEvent", payload)
              .then((resp) => {
                console.log("getItemsForEvent", resp.data.eventItemDetails);
                var eventItemDetails = resp.data.eventItemDetails;
                let extraProductDetails = {
                  basic_price: "",
                  price_without_gst: "",
                  price_with_gst: "",
                  amount_without_gst: "",
                  amount_with_gst: "",
                  gst_charge: "",
                  freight_insurance_charge: "",
                  packing_forwarding_charge: "",
                  discount_charge: "",
                  rfq_item_distance_inkms: "",
                  rfq_item_transit_time_inhrs: "",
                  rfq_valid_till: "",
                };
                let allExtraFields = Object.keys(extraProductDetails);

                eventItemDetails = eventItemDetails.map((each) => {
                  return { ...each, ...extraProductDetails };
                });

                eventItemDetails.map((each) => {
                  productDetails.forEach((eachResp) => {
                    if (eachResp._id === each._id) {
                      allExtraFields.forEach((eachKey) => {
                        if (
                          eachResp.hasOwnProperty(eachKey) &&
                          parseFloat(eachResp[eachKey])
                        ) {
                          each[eachKey] = eachResp[eachKey];
                        }
                      });
                    }
                  });
                });

                this.setState({
                  itemsData: eventItemDetails,
                });
              })
              .catch((err) => {
                console.log("getItemsForEvent err", err);
              });

            //commercial terms
            redirectURL
              .post("/procurement/getRfqCommercialTermsByEventId", payload)
              .then((resp) => {
                console.log(
                  "getRfqCommercialTermsByEventId",
                  resp.data.commercialTerms
                );
                this.setState({
                  commercialTermsDocArr: resp.data.commercialTerms,
                });
              })
              .catch((err) => {
                console.log("getRfqCommercialTermsByEventId", err);
              });

            //Questions
            redirectURL
              .post("/procurement/getRfqQuestionsByEventId", payload)
              .then((resp) => {
                console.log("rfqQuestions", resp.data.rfqQuestions);
                let questionDetails = resp.data.rfqQuestions;

                ////////////////////////////////////////////////////////////////////////
                let extraQuestionFields = {
                  response: "",
                };
                let allExtraFields = Object.keys(extraQuestionFields);

                questionDetails = questionDetails.map((each) => {
                  return { ...each, ...extraQuestionFields };
                });

                questionDetails.map((each) => {
                  questionResponses.forEach((eachResp) => {
                    if (eachResp._id === each._id) {
                      allExtraFields.forEach((eachKey) => {
                        if (
                          eachResp.hasOwnProperty(eachKey) &&
                          eachResp[eachKey]
                        ) {
                          each[eachKey] = eachResp[eachKey];
                        }
                      });
                    }
                  });
                });

                ////////////////////////////////////////////////////////////////////////

                // questionResponses
                this.setState(
                  {
                    rfqQuestions: questionDetails,
                    loadshow: "show-n",
                    overly: "show-n",
                  },
                  () => {
                    console.log(
                      "this.state.rfqQuestions",
                      this.state.rfqQuestions
                    );
                  }
                );
              })
              .catch((err) => {
                console.log("rfqQuestions", err);
              });
          }
        );
      })
      .catch((err) => {
        console.log("getRfqResponsesByEmail err", err);
      });
  };

  onClickHideAll = () => {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  };

  //   renderCellClick = (rowData) => {
  //     // console.log(rowData);
  //   };

  updateItemPrice = (rowData) => {
    console.log("rowData", rowData);
    this.setState({
      openModal: true,
      selectedItemRow: rowData,
      itemPrice: rowData.basic_price ? parseFloat(rowData.basic_price) : "",
      itemGST: rowData.gst_charge ? parseFloat(rowData.gst_charge) : "",
      itemFreightInsurance: rowData.freight_insurance_charge
        ? parseFloat(rowData.freight_insurance_charge)
        : "",
      itemPackingForwarding: rowData.packing_forwarding_charge
        ? parseFloat(rowData.packing_forwarding_charge)
        : "",
      itemDiscount: rowData.discount_charge
        ? parseFloat(rowData.discount_charge)
        : "",
    });
  };

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  closePreviewModal = () => {
    this.setState({
      openPreviewModal: false,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReadyA = (params) => {
    this.gridApiA = params.api;
    this.gridColumnApiA = params.columnApi;
  };

  onGridReady1 = (params) => {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  };

  onGridReady2 = (params) => {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
  };

  updateChargesAndRowData = (
    itemPrice,
    itemDiscount,
    itemGST,
    itemFreightInsurance,
    itemPackingForwarding,
    selectedItemRow,
    itemQuantity
  ) => {
    let discountPrice = itemPrice * (1 - itemDiscount / 100);
    let freightAndInsurance = discountPrice * (itemFreightInsurance / 100);
    let packingAndForwarding = discountPrice * (itemPackingForwarding / 100);
    let gstAmount = discountPrice * (itemGST / 100);

    let priceWithoutGst = parseFloat(
      Number(
        discountPrice + freightAndInsurance + packingAndForwarding
      ).toFixed(2)
    );
    let priceWithGst = parseFloat(
      Number(
        discountPrice + freightAndInsurance + packingAndForwarding + gstAmount
      ).toFixed(2)
    );

    let amountWithoutGST = priceWithoutGst * itemQuantity;
    let amountWithGST = priceWithGst * itemQuantity;

    selectedItemRow["basic_price"] = itemPrice;
    selectedItemRow["price_without_gst"] = priceWithoutGst;
    selectedItemRow["price_with_gst"] = priceWithGst;
    selectedItemRow["amount_without_gst"] = amountWithoutGST;
    selectedItemRow["amount_with_gst"] = amountWithGST;
    selectedItemRow["gst_charge"] = this.state.itemGST;
    selectedItemRow["freight_insurance_charge"] =
      this.state.itemFreightInsurance;
    selectedItemRow["packing_forwarding_charge"] =
      this.state.itemPackingForwarding;
    selectedItemRow["discount_charge"] = this.state.itemDiscount;

    this.gridApi.updateRowData({ update: [selectedItemRow] });
    this.gridApiA.updateRowData({ update: [selectedItemRow] });
    // console.log("test ",this.gridApi);
    console.log("stater", this.state.itemsData);

    let totalAmountWithGST = 0;
    let totalAmountWithoutGST = 0;

    this.state.itemsData.forEach((each) => {});

    this.state.itemsData.forEach((each) => {
      if (each.hasOwnProperty("amount_with_gst") && each.amount_with_gst) {
        totalAmountWithGST += parseFloat(each.amount_with_gst);
      }

      if (
        each.hasOwnProperty("amount_without_gst") &&
        each.amount_without_gst
      ) {
        totalAmountWithoutGST += parseFloat(each.amount_without_gst);
      }
    });

    this.setState({
      totalAmountWithGST: totalAmountWithGST,
      totalAmountWithoutGST: totalAmountWithoutGST,
    });

    return true;
  };

  updateItemDetails = (e) => {
    e.preventDefault();
    // console.log(this.state.selectedItemRow);

    let itemPrice = 0;
    let itemGST = 0;
    let itemFreightInsurance = 0;
    let itemPackingForwarding = 0;
    let itemDiscount = 0;
    let itemQuantity = 0;

    if (this.state.itemPrice) {
      itemPrice = parseFloat(this.state.itemPrice);
    } else {
      return;
    }

    if (this.state.itemGST) {
      itemGST = parseFloat(this.state.itemGST);
    } else {
      return;
    }

    if (this.state.itemFreightInsurance) {
      itemFreightInsurance = parseFloat(this.state.itemFreightInsurance);
    } else {
      return;
    }

    if (this.state.itemPackingForwarding) {
      itemPackingForwarding = parseFloat(this.state.itemPackingForwarding);
    } else {
      return;
    }

    if (this.state.itemDiscount) {
      itemDiscount = parseFloat(this.state.itemDiscount);
    } else {
      return;
    }

    if (
      this.state.selectedItemRow.hasOwnProperty("rfq_item_quantity") &&
      this.state.selectedItemRow.rfq_item_quantity
    ) {
      itemQuantity = parseInt(this.state.selectedItemRow.rfq_item_quantity);
    }

    if (this.state.isCommercialTermsSameForAllItems) {
      //if same commercial terms are to be applied for all items
      this.state.itemsData.forEach((each) => {
        // for the selected row data
        if (each._id === this.state.selectedItemRow._id) {
          let isUpdated = this.updateChargesAndRowData(
            itemPrice,
            itemDiscount,
            itemGST,
            itemFreightInsurance,
            itemPackingForwarding,
            this.state.selectedItemRow,
            itemQuantity
          );
          if (isUpdated) {
            this.setState({
              itemPrice: "",
              itemGST: "",
              itemFreightInsurance: "",
              itemPackingForwarding: "",
              itemDiscount: "",
              openModal: false,
            });
          }
        } else {
          if (
            each.hasOwnProperty("basic_price") &&
            each.basic_price &&
            parseFloat(each.basic_price)
          ) {
            let itemPrice = 0;
            itemPrice = parseFloat(each.basic_price);

            let isUpdated = this.updateChargesAndRowData(
              itemPrice,
              itemDiscount,
              itemGST,
              itemFreightInsurance,
              itemPackingForwarding,
              each,
              each.rfq_item_quantity
            );

            if (isUpdated) {
              this.setState({
                itemPrice: "",
                itemGST: "",
                itemFreightInsurance: "",
                itemPackingForwarding: "",
                itemDiscount: "",
                openModal: false,
              });
            }
          }
        }
      });
    } else {
      let isUpdated = this.updateChargesAndRowData(
        itemPrice,
        itemDiscount,
        itemGST,
        itemFreightInsurance,
        itemPackingForwarding,
        this.state.selectedItemRow,
        itemQuantity
      );
      if (isUpdated) {
        this.setState({
          itemPrice: "",
          itemGST: "",
          itemFreightInsurance: "",
          itemPackingForwarding: "",
          itemDiscount: "",
          openModal: false,
        });
      }
    }
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  handleSubmitQuote = () => {
    this.setState({
      sliderTranslate: "",
    });
    // alert("RFQ response submitted and forwared to company");
    let nowDate = moment.parseZone(new Date()).utcOffset("+00:00")._d;
    var query = {
      vendor_email: this.state.vendor_email,
      rfq_event_id: this.state.rfq_event_id,
      rfq_response_submitted: 1,
      status: "Submitted",
      response_datetime: nowDate,
    };
    redirectURL
      .post("/procurement/submitRfqResponse", query)
      .then((resp) => {
        console.log("submitRfqResponse", resp.data);
        let isFormSubmitted = 0;

        if (resp.data.status === "success") {
          if (
            resp.data.vendorResponse.length > 0 &&
            resp.data.vendorResponse[0].hasOwnProperty("rfq_response_submitted")
          ) {
            isFormSubmitted = parseInt(
              resp.data.vendorResponse[0].rfq_response_submitted
            );
          }
          this.setState({
            sliderTranslate: "",
            isFormSubmitted: isFormSubmitted,
          });
        }
      })
      .catch((err) => {
        console.log("submitRfqResponse", err);
      });
  };

  saveProductDetails = () => {
    // console.log("test save data" ,this.state.itemsData);
    let actualItemsLength = this.state.itemsData.length;
    let updatedItems = 0;
    this.state.itemsData.forEach((each) => {
      if (each.hasOwnProperty("basic_price") && each.basic_price != "" &&
        each.hasOwnProperty("rfq_item_transit_time_inhrs") && each.rfq_item_transit_time_inhrs != "" &&
        each.hasOwnProperty("rfq_valid_till") && each.rfq_valid_till != "" &&
        each.hasOwnProperty("rfq_item_distance_inkms") && each.rfq_item_distance_inkms != ""
      ) {
        updatedItems++;
      }
    });

    if (actualItemsLength === updatedItems) {
      let payload = {
        vendor_email: localStorage.getItem("email"),
        rfq_event_id: this.state.rfq_event_id,
        rfq_response_submitted: 0,
        product_details: this.state.itemsData,
        //   product_id: this.state.selectedItemRow._id,
        //   basic_price: itemPrice,
        //   price_without_gst: priceWithoutGst,
        //   price_with_gst: priceWithGst,
        //   amount_without_gst: amountWithoutGST,
        //   amount_with_gst: amountWithGST,
        //   gst_charge: this.state.itemGST,
        //   freight_insurance_charge: this.state.itemFreightInsurance,
        //   packing_forwarding_charge: this.state.itemPackingForwarding,
        //   discount_charge: this.state.itemDiscount,
      };

      redirectURL
        .post("/procurement/saveProductDetails", payload)
        .then((resp) => {
          console.log("saveProductDetails resp", resp.data);
          this.setState({
            allProductDetailsFilled: true,
          });
        })
        .catch((err) => {
          console.log("saveProductDetails err", err);
          this.setState({
            allProductDetailsFilled: false,
          });
        });
    } else {
      this.setState({
        show: true,
        basicTitle: "All Fields are mandatory",
        basicType: "danger",
        allProductDetailsFilled: false,
      });
    }
  };

  onCloseBidPreviewSlider = () => {
    this.setState({
      sliderTranslate: "",
    });
  };

  saveQuestionReplies = () => {
    let totalQuestions = this.state.rfqQuestions.length;
    let questionResponse = 0;
    this.state.rfqQuestions.forEach((each) => {
      if (each.hasOwnProperty("response") && each.response) {
        questionResponse++;
      }
    });
    if (totalQuestions != questionResponse) {
      this.setState({
        show: true,
        basicTitle: "All Fields are mandatory",
        basicType: "danger",
        allQuestionsFilled: false,
      });
    } else {
      let payload = {
        vendor_email: localStorage.getItem("email"),
        rfq_event_id: this.state.rfq_event_id,
        rfq_response_submitted: 0,
        question_responses: this.state.rfqQuestions,
      };
      redirectURL
        .post("/procurement/saveQuestionResponses", payload)
        .then((resp) => {
          console.log("saveQuestionResponses data", resp.data);
          if (resp.data.status === "success") {
            this.setState(
              {
                allQuestionsFilled: true,
                sliderTranslate: "slider-translate-70p",
                previewRfqQuestions: this.state.rfqQuestions,
              }
              //   () => {
              //     if (
              //       this.state.allProductDetailsFilled &&
              //       this.state.allQuestionsFilled
              //     ) {
              //     }
              //   }
            );
          }
        })
        .catch((err) => {
          console.log("saveQuestionResponses err", err);
          this.setState({
            allQuestionsFilled: false,
          });
        });
    }
  };

  handleAddCommercialTermsBtn(e) {
    e.preventDefault();
    console.log("commercialTerms", this.state.attachment_title);
    console.log("commercialTerms", this.state.rfq_vendor_attachments);
    if (this.state.attachment_title && this.state.rfq_vendor_attachments) {
      const formData = new FormData();
      formData.append("vendor_email", this.state.vendor_email);
      formData.append("rfq_event_id", this.state.rfq_event_id);
      formData.append("rfq_attachment_title", this.state.attachment_title);
      formData.append(
        "rfq_vendor_attachments",
        this.state.rfq_vendor_attachments
      );
      formData.append(
        "is_attachments_docs_changed",
        this.state.isAttachmentsDocsChanged
      );

      redirectURL
        .post("/procurement/saveRfqVendorQuestionsAndAttachments", formData)
        .then((response) => {
          console.log("response ", response.data);
          if (
            response.data.status === "success" &&
            response.data.hasOwnProperty("updatedData")
          ) {
            document.getElementById("rfq_vendor_attachments").value = null;
            this.setState({
              vendorAttachments: response.data.updatedData[0].attachments,
              attachment_title: "",
              selected_file: "",
              rfq_vendor_attachments: "",
            });
          }
          // if (response.data.status === "success") {
          //   if (response.data.rfq_title) {
          //     this.state.commercialTermsDocArr =
          //       this.state.commercialTermsDocArr.filter(
          //         (obj) =>
          //           obj.rfq_commercial_terms_title != response.data.rfq_title
          //       );
          //     let commercialTermsDocArr = [
          //       {
          //         rfq_commercial_terms_title: response.data.rfq_title,
          //         rfq_commercial_terms_doc: response.data.rfq_file_url,
          //       },
          //     ];
          //     this.setState({
          //       commercialTermsDocArr: [
          //         ...this.state.commercialTermsDocArr,
          //         ...commercialTermsDocArr,
          //       ],
          //     });
          //   }
          // }
        });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }

  handleCheckBox = () => {
    this.setState({
      isCommercialTermsSameForAllItems:
        !this.state.isCommercialTermsSameForAllItems,
    });
  };

  docUploadBtnHandler = (fileBoxId) => {
    document.getElementById(fileBoxId).click();
  };

  deleteVendorAttachment = (id) => {
    let payload = {
      rfq_event_id: this.state.rfq_event_id,
      id: id,
      vendor_email: localStorage.getItem("email"),
    };

    redirectURL
      .post("/procurement/deleteVendorAttachment", payload)
      .then((response) => {
        console.log("deleteVendorAttachment", response.data);
        if (
          response.data.status === "success" &&
          response.data.hasOwnProperty("updatedData")
        ) {
          this.setState({
            vendorAttachments: response.data.updatedData[0].attachments,
          });
        }
      })
      .catch((err) => {
        console.log("deleteVendorAttachment err", err);
      });
  };

  // changeTCFileHandler(event) {
  //   const file = event.target.files[0];
  //   const previousFile = this.state.tcDoc;
  //   if (file && file !== previousFile) {
  //     this.setState({
  //       isTCDocChanged: true,
  //       tcDoc: file,
  //     });
  //   } else {
  //     this.setState({
  //       isTCDocChanged: false,
  //     });
  //   }
  //   // this.setState({
  //   //     previousTCDoc: file,
  //   //     // tcDocFileUrl: fileUrl
  //   // })
  // }

  changeTCFileHandler(event) {
    const file = event.target.files[0];
    console.log("file", file);
    const previousFile = this.state.rfq_vendor_attachments;
    if (file && file !== previousFile) {
      this.setState({
        isAttachmentsDocsChanged: true,
        rfq_vendor_attachments: file,
        selected_file: file.name,
      });
    } else {
      this.setState({
        isAttachmentsDocsChanged: false,
      });
    }
  }

  handleCharges = (value, inputType) => {
    let inputValue = value;
    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      this.setState({
        [inputType]: value,
      });
    }
  };

  previewDocument = (documentUrl) => {
    // console.log("document", document);
    const parts = documentUrl.split(".");
    const fileExtension = parts[parts.length - 1];
    this.setState({
      openPreviewModal: true,
      documentUrl: documentUrl,
      documentType: fileExtension,
    });
  };

  updateQuestionData = (value, selectedQuestionRow) => {
    // console.log();
    selectedQuestionRow["response"] = value;
    this.gridApi1.updateRowData({ update: [selectedQuestionRow] });
    this.gridApi2.updateRowData({ update: [selectedQuestionRow] });
  };
  handleRFQValidTill= (e,rowdata) => {
    // console.log("test 1-",e);
    let val= moment(e._d).format("YYYY-MM-DD");
    rowdata["rfq_valid_till"]=val;
    
    this.gridApi.updateRowData({ update: [rowdata] });
    this.gridApiA.updateRowData({ update: [rowdata] });
    this.setState({rfq_valid_till: val})
  }

  render() {
    // if (this.state.isFormSubmitted === 1) {
    //   return <Navigate to={"/vendor-dashboard"} push={true} />;
    // }
    // console.log("test -", this.state.itemsData);
    const columnWithDefs = [
      //   {
      //     headerName: "Item Code",
      //     field: "rfq_item_code",
      //     width: 100,
      //     hide: false,
      //     filter: "agSetColumnFilter",
      //     cellRenderer: "",
      //     resizable: true,
      //   },
      {
        headerName: "Service Name",
        field: "rfq_item",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Service Description",
        field: "rfq_item_description",
        width: 200,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vehicle Type (ft)",
        field: "rfq_item_vehicle_type",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Capacity (MT)",
        field: "rfq_item_capacity",
        width: 120,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Quantity",
        field: "rfq_item_quantity",
        width: 130,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "UOM",
        field: "rfq_item_UOM",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      
      // {
      //   headerName: "Trips Estimate",
      //   field: "rfq_item_trips_estimate",
      //   width: 130,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Volume",
      //   field: "rfq_item_volume",
      //   width: 120,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Volume UOM",
      //   field: "rfq_item_volume_uom",
      //   width: 130,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      {
        headerName: "Source Location",
        field: "rfq_item_source_location",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Delivery Location",
        field: "rfq_item_delivery_location",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Transit Time (hrs)",
        field: "rfq_item_transit_time_inhrs",
        width: 130,
        hide: false,
        headerClass: "cust-header",
        cellStyle: {color: "blue"},
        filter: "agSetColumnFilter",
        cellRenderer: "",
        editable: true,
        resizable: true,
      },
      {
        headerName: "Distance (kms)",
        field: "rfq_item_distance_inkms",
        width: 150,
        hide: false,
        headerClass: "cust-header",
        cellStyle: {color:"blue"},
        filter: "agSetColumnFilter",
        cellRenderer: "",
        editable: true,
        resizable: true,
      },
      {
        headerName: "Rate Valid till",
        field: "rfq_valid_till",
        width: 200,
        headerClass: "cust-header",
        cellStyle: {color:"blue"},
        filter: "agSetColumnFilter",
        // cellRenderer: "",
        // valueFormatter: (params) => {
        //   if (!params.value) {
        //     return "";
        //   }
        //   const month = params.value.getMonth() + 1;
        //   const day = params.value.getDate();
        //   return `${params.value.getFullYear()}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
        // },
        editable: true,
        // cellDataType: 'date',
        cellEditor: "RfqDateCellEditor",
        // cellEditor: "agDateCellEditor",
      },
      // {
      //   headerName: "TAT",
      //   field: "rfq_item_tat_days",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      {
        headerName: "Change Price",
        field: "",
        width: 200,
        hide: false,
        filter: "agSetColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <button
              className="btn btn-primary"
              style={{
                border: "1px solid white",
                color: "white",
                cursor: "pointer",
                width: "120px",
                borderRadius: "10px",
              }}
              onClick={this.updateItemPrice.bind(this, params.data)}
            >
              Update Price
            </button>
          );
        },
        resizable: true,
      },
      {
        headerName: "Basic Price",
        field: "basic_price",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("basic_price") &&
            parseFloat(params.data.basic_price)
          ) {
            return Number(params.data.basic_price).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Price (without GST)",
        field: "price_without_gst",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("price_without_gst") &&
            parseFloat(params.data.price_without_gst)
          ) {
            return Number(params.data.price_without_gst).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Price (with GST)",
        field: "price_with_gst",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("price_with_gst") &&
            parseFloat(params.data.price_with_gst)
          ) {
            return Number(params.data.price_with_gst).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Amount (without GST)",
        field: "amount_without_gst",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("amount_without_gst") &&
            parseFloat(params.data.amount_without_gst)
          ) {
            return Number(params.data.amount_without_gst).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Amount (with GST)",
        field: "amount_with_gst",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("amount_with_gst") &&
            parseFloat(params.data.amount_with_gst)
          ) {
            return Number(params.data.amount_with_gst).toFixed(2);
          } else {
            return "";
          }
        },
      },
      //   {
      //     headerName: "Remarks",
      //     field: "rfq_item_remarks",
      //     width: 100,
      //     hide: false,
      //     filter: "agSetColumnFilter",
      //     cellRenderer: "",
      //     resizable: true,
      //   },
      // {
      //   headerName: "PO No.",
      //   field: "rfq_item_po_number",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Vendor Name",
      //   field: "rfq_item_search_vendor_name",
      //   width: 120,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Unit Rate",
      //   field: "rfq_item_unit_rate",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "PO Date",
      //   field: "rfq_item_po_date",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "PO Value",
      //   field: "rfq_item_po_value",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
    ];

    const productDetailsPreviewColumnDefs = [
      //   {
      //     headerName: "Item Code",
      //     field: "rfq_item_code",
      //     width: 100,
      //     hide: false,
      //     filter: "agSetColumnFilter",
      //     cellRenderer: "",
      //     resizable: true,
      //   },
      {
        headerName: "Service Name",
        field: "rfq_item",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //   headerName: "Description",
      //   field: "rfq_item_description",
      //   width: 200,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Quantity",
      //   field: "rfq_item_quantity",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "UOM",
      //   field: "rfq_item_uom",
      //   width: 75,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Delivery Location",
      //   field: "rfq_item_delivery_location",
      //   width: 150,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "TAT",
      //   field: "rfq_item_tat_days",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      {
        headerName: "Transit Time (hrs)",
        field: "rfq_item_transit_time_inhrs",
        width: 130,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Distance (kms)",
        field: "rfq_item_distance_inkms",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Rate Valid till",
        field: "rfq_valid_till",
        width: 200,
      },
      {
        headerName: "Basic Price",
        field: "basic_price",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("basic_price") &&
            parseFloat(params.data.basic_price)
          ) {
            return Number(params.data.basic_price).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Price (without GST)",
        field: "price_without_gst",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("price_without_gst") &&
            parseFloat(params.data.price_without_gst)
          ) {
            return Number(params.data.price_without_gst).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Price (with GST)",
        field: "price_with_gst",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("price_with_gst") &&
            parseFloat(params.data.price_with_gst)
          ) {
            return Number(params.data.price_with_gst).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Amount (without GST)",
        field: "amount_without_gst",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("amount_without_gst") &&
            parseFloat(params.data.amount_without_gst)
          ) {
            return Number(params.data.amount_without_gst).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Amount (with GST)",
        field: "amount_with_gst",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.hasOwnProperty("amount_with_gst") &&
            parseFloat(params.data.amount_with_gst)
          ) {
            return Number(params.data.amount_with_gst).toFixed(2);
          } else {
            return "";
          }
        },
      },
      //   {
      //     headerName: "Remarks",
      //     field: "rfq_item_remarks",
      //     width: 100,
      //     hide: false,
      //     filter: "agSetColumnFilter",
      //     cellRenderer: "",
      //     resizable: true,
      //   },
      // {
      //   headerName: "PO No.",
      //   field: "rfq_item_po_number",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Vendor Name",
      //   field: "rfq_item_search_vendor_name",
      //   width: 120,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Unit Rate",
      //   field: "rfq_item_unit_rate",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "PO Date",
      //   field: "rfq_item_po_date",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "PO Value",
      //   field: "rfq_item_po_value",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
    ];

    const commercialTermsDefs = [
      {
        headerName: "File Name",
        field: "rfq_commercial_terms_title",
        width: 350,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Preview",
        field: "",
        width: 250,
        hide: false,
        filter: "agSetColumnFilter",
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <i
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
              class="fa fa-eye"
              aria-hidden="true"
              onClick={this.previewDocument.bind(
                this,
                params.data.rfq_commercial_terms_doc
              )}
            ></i>
          );
        },
      },
      {
        headerName: "Download",
        field: "",
        width: 250,
        hide: false,
        filter: "agSetColumnFilter",
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            // <i
            //   style={{
            //     fontSize: "20px",
            //     cursor: "pointer",
            //   }}
            //   class="fa fa-eye"
            //   aria-hidden="true"
            //   onClick={this.previewDocument.bind(
            //     this,
            //     params.data.rfq_commercial_terms_doc
            //   )}
            // ></i>
            <a
              style={{
                fontSize: "20px",
                cursor: "pointer",
                color: "black",
              }}
              href={`${params.data.rfq_commercial_terms_doc}`}
            >
              <i class="fa fa-download mx-2" aria-hidden="true"></i>
            </a>
          );
        },
      },
    ];

    const questionTermsDefs = [
      {
        headerName: "File Name",
        field: "rfq_questions_title",
        width: 350,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Response",
        field: "response",
        width: 300,
        hide: false,
        resizable: true,
        cellRendererFramework: (params) => {
          let response = "";
          if (params.data.hasOwnProperty("response") && params.data.response) {
            response = params.data.response;
          }
          return (
            <input
              type="text"
              placeholder="Enter Response"
              style={{
                height: "40px",
                width: "112%",
                position: "relative",
                left: "-16px",
              }}
              value={response}
              onChange={(e) => {
                this.updateQuestionData(e.target.value, params.data);
              }}
            />
          );
        },
      },
      //   {
      //     headerName: "Update",
      //     field: "",
      //     width: 250,
      //     hide: false,
      //     filter: "agSetColumnFilter",
      //     resizable: true,
      //     cellRendererFramework: (params) => {
      //       return <button class="btn btn-primary">Update</button>;
      //     },
      //   },
    ];

    const previewQuestionDefs = [
      {
        headerName: "File Name",
        field: "rfq_questions_title",
        width: 350,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Response",
        field: "response",
        width: 300,
        hide: false,
        resizable: true,
      },
      //   {
      //     headerName: "Update",
      //     field: "",
      //     width: 250,
      //     hide: false,
      //     filter: "agSetColumnFilter",
      //     resizable: true,
      //     cellRendererFramework: (params) => {
      //       return <button class="btn btn-primary">Update</button>;
      //     },
      //   },
    ];

    return (
      <div
        className="container-fluid"
        style={{ minHeight: "100vh", overflowY: "hidden" }}
      >
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>

        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        />

        <Modal
          open={this.state.openPreviewModal}
          onClose={this.closePreviewModal}
        >
          <div aria-disabled={true}>
            <h6
              className="bg-primary text-white"
              style={{
                paddingTop: "10px",
                height: "38px",
                paddingLeft: "253px",
              }}
            >
              File Preview
            </h6>
            {this.state.documentType === "pdf" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "csv" ||
            this.state.documentType === "xlsx" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "png" ||
            this.state.documentType === "jpeg" ||
            this.state.documentType === "jpg" ? (
              <div className="p-3">
                <img
                  src={this.state.documentUrl}
                  // frameborder="1"
                  height="500px"
                  width="100%"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>

        <Modal
          open={this.state.openModal}
          onClose={this.closeModal}
          styles={{ overflowX: "hidden" }}
        >
          <div class="modal__container" style={{ overflowX: "hidden" }}>
            <div class="modalbox_heading bg-primary">Update Item Price</div>
            <form action="" onSubmit={this.updateItemDetails}>
              {/* base price */}
              <div
                className="row base_price_card"
                style={{ padding: "20px 2vw" }}
              >
                <div className="col-12">
                  <div>
                    <label>
                      Basic Price{" "}
                      <span className="text-danger">
                        ( Unit Rate-Ex.Taxes & Duties*)
                      </span>
                    </label>
                  </div>
                  <div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        style={{ height: "45px" }}
                        name="rfqSubject"
                        value={this.state.itemPrice}
                        onChange={(e) => {
                          this.handleCharges(e.target.value, "itemPrice");
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* base price */}

              {/* GST and Freight & Insurance*/}
              <div
                className="row base_price_card"
                style={{ padding: "0px 2vw" }}
              >
                <div className="col-6">
                  <div>
                    <label>GST (%)</label>
                  </div>
                  <div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        style={{ height: "45px" }}
                        name="rfqSubject"
                        value={this.state.itemGST}
                        onChange={(e) => {
                          this.handleCharges(e.target.value, "itemGST");
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div>
                    <label>Freight & Insurance (%)</label>
                  </div>
                  <div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        style={{ height: "45px" }}
                        name="rfqSubject"
                        value={this.state.itemFreightInsurance}
                        onChange={(e) => {
                          this.handleCharges(
                            e.target.value,
                            "itemFreightInsurance"
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* GST and Freight & Insurance*/}

              {/* Packing & Forwarding and Discount*/}
              <div
                className="row base_price_card"
                style={{ padding: "0px 2vw" }}
              >
                <div className="col-6">
                  <div>
                    <label>Packing & Forwarding (%)</label>
                  </div>
                  <div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        style={{ height: "45px" }}
                        name="rfqSubject"
                        value={this.state.itemPackingForwarding}
                        onChange={(e) => {
                          this.handleCharges(
                            e.target.value,
                            "itemPackingForwarding"
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div>
                    <label>Discount (%)</label>
                  </div>
                  <div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        style={{ height: "45px" }}
                        name="rfqSubject"
                        value={this.state.itemDiscount}
                        onChange={(e) => {
                          this.handleCharges(e.target.value, "itemDiscount");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Packing & Forwarding and Discount*/}

              <div
                className="row base_price_card"
                style={{ padding: "0px 2vw" }}
              >
                <div class="col-12">
                  <input
                    type="checkbox"
                    name="accept"
                    id="termsAndCondtionsAccept"
                    checked={this.state.isCommercialTermsSameForAllItems}
                    onClick={this.handleCheckBox.bind(this)}
                  />
                  <label
                    className="px-1"
                    htmlFor="termsAndCondtionsAccept"
                    style={{
                      position: "relative",
                      top: "-6px",
                      left: "5px",
                      outline: "none",
                    }}
                  >
                    Replicate the above commercial terms for all the items of
                    this RFQ
                  </label>
                </div>
              </div>

              <div
                className=""
                style={{
                  padding: "0px 2vw",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <div
          className={"overly-part " + this.state.overly}
          onClick={this.onClickHideAll.bind(this)}
        ></div>

        {/* Card-1 Starts Here */}

        <div className="form__container" style={{ height: "290px" }}>
          <div className="form__header" id="step1">
            <div className="header__icon__container">
              <img src={StepOneIcon} alt="" />
            </div>
            <div className="header__title__container">
              <div className="header__subtitle">Step 01/03</div>
              <div className="header__title">Reference</div>
            </div>
          </div>

          {/* First Row */}
          <div className="row" style={{ padding: "0px 4vw" }}>
            <div className="col-6">
              <div>
                <label>
                  RFQ Subject <span className="text-danger">*</span>
                </label>
              </div>
              <div>
                <div className="form-group">{this.state.rfq_subject}</div>
              </div>
            </div>
            <div className="col-6">
              <div>
                <label>
                  RFQ Description <span className="text-danger">*</span>
                </label>
              </div>
              <div>
                <div className="form-group">{this.state.rfq_description}</div>
              </div>
            </div>
          </div>
          {/* Second Row */}
          <div className="row" style={{ padding: "10px 4vw" }}>
            <div className="col-6">
              <label>
                Event Start Date<span className="text-danger">*</span>
              </label>
              <div className="event__schedule__datepicker">
                <div className="start__date__picker">
                  {this.state.rfq_start_time}
                </div>
              </div>
            </div>
            <div className="col-6">
              <label>
                Event End Date<span className="text-danger">*</span>
              </label>
              <div className="event__schedule__datepicker">
                <div className="end__date__picker">
                  {this.state.rfq_end_time}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Card-1 Ends Here */}

        {/* Card-2 Starts Here */}
        <div
          className="form__container"
          style={{ height: "810px", marginBottom: "29px" }}
        >
          <div className="form__header" id="">
            <div className="header__icon__container">
              <img src={StepTwoIcon} alt="" />
            </div>
            <div className="header__title__container">
              <div className="header__subtitle">Step 02/03</div>
              <div className="header__title">Transport Details</div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "475px",
              minHeight: "475px",
              padding: "0 3vw",
            }}
            className="ag-theme-alpine rfqDetails"
          >
            <AgGridReact
              headerClass="curved-header"
              rowData={this.state.itemsData}
              columnDefs={columnWithDefs}
              components={{RfqDateCellEditor: RfqDateCellEditor}}
              reactiveCustomComponents={true}
              defaultColDef={this.state.defaultColDef}
              enableRangeSelection={true}
              enableCharts={true}
              detailCellRendererParams={this.state.detailCellRendererParams}
              masterDetail={true}
              onGridReady={this.onGridReady}
              sideBar={this.state.sideBar}
              paginationPageSize={10}
              pagination={true}
              animateRows={true}
              showNoRowsOverlay={false}
              // suppressRowHoverHighlight={true}
              gridOptions={{
                context: { componentParent: this },
              }}
            />
          </div>
          <div class="row justify-content-around mt-3">
            <div class="col-5">
              <div class="bg-primary mt-3 mx-auto py-2 totalGross">
                <div class="row justify-content-around">
                  <div class="col-5">
                    <label className="costCardTitle">
                      Total Gross (Without GST)
                    </label>
                  </div>
                  <div class="col-5">
                    <label className="costCardCost">
                      {this.state.totalAmountWithoutGST}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="bg-primary mt-3 mx-auto py-2 totalGross">
                <div class="row justify-content-around">
                  <div class="col-5">
                    <label className="costCardTitle">
                      Total Gross (With GST)
                    </label>
                  </div>
                  <div class="col-5">
                    <label className="costCardCost">
                      {this.state.totalAmountWithGST}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="next__btn__container">
            <div className="back__btn" style={{ cursor: "not-allowed" }}>
              Back
            </div>
            <button
              type="button"
              className="btn btn-primary next-button"
              onClick={this.saveProductDetails.bind(this)}
            >
              <a
                href="#step3"
                style={{ textDecoration: "none", color: "white" }}
                role="button"
              >
                Save &amp; Next
              </a>
            </button>
          </div>
        </div>
        {/* Card-2 Ends Here */}

        {/* Card-3 Starts Here */}
        <div
          className="form__container"
          id="step3"
          style={{ minHeight: "230px", marginBottom: "100px" }}
        >
          <div className="form__header" id="">
            <div className="header__icon__container">
              <img src={StepThreeIcon} alt="" />
            </div>
            <div className="header__title__container">
              <div className="header__subtitle">Step 03/03</div>
              <div className="header__title">Additional Details</div>
            </div>
          </div>

          <span
            className="terms-heading"
            style={{ position: "relative", top: "0px", marginLeft: "47px" }}
          >
            Commercial Terms
          </span>
          <div
            className="termsContainer"
            style={{
              height: "325px",
              overflowY: "auto",
              marginRight: "2.5vw",
            }}
          >
            {this.state.commercialTermsDocArr.map((each, index) => {
              let {
                rfq_commercial_terms_title,
                rfq_commercial_terms_doc,
                _id,
              } = each;
              return (
                <>
                  <div
                    class="row"
                    key={`term-${index}`}
                    style={{ height: "82px" }}
                  >
                    <div class="col-6" style={{ paddingTop: "33px" }}>
                      <input
                        type="text"
                        value={rfq_commercial_terms_title}
                        className="input-style"
                        style={{ width: "100%", paddingLeft: "16px" }}
                        disabled
                      />
                    </div>

                    <div class="col-4 txt-center">
                      <label for="">
                        <label
                          className="upload__button upload"
                          style={{ zIndex: 1, width: "170px" }}
                          // onClick={this.docUploadBtnHandler.bind(
                          //   this,
                          //   "commercialTermsDoc"
                          // )}
                          onClick={this.previewDocument.bind(
                            this,
                            rfq_commercial_terms_doc
                          )}
                        >
                          {/* <img src={ClipIcon} alt="" /> */}
                          <i
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            class="fa fa-2x fa-eye txt-black"
                            aria-hidden="true"
                          ></i>
                          <h6 style={{ position: "relative", top: "4px" }}>
                            Preview Doc
                          </h6>
                        </label>

                        <input
                          type="file"
                          name="commercialTermsDoc"
                          id="commercialTermsDoc"
                          // onChange={this.changeCommercialTermsFileHandler.bind(
                          //   this
                          // )}
                          className=""
                          style={{ display: "none" }}
                          required
                        />
                      </label>
                    </div>

                    <div class="col-1"></div>
                  </div>
                </>
              );
            })}
          </div>

          <div class="row mt-3" style={{ margin: "0 2.1vw" }}>
            <div class="col-10">
              <div
                style={{
                  height: "1px",
                  border: "1px solid black",
                  position: "relative",
                  top: "25.5px",
                  width: "109%",
                }}
              ></div>
            </div>
            <div
              class="col-2 txt-center bg-primary colorWhite"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "51px",
                borderRadius: "10px",
              }}
            >
              <label for="">Additional Doc</label>
            </div>
          </div>

          <div
            className="row"
            style={{ marginTop: "25px", marginLeft: "3vw", height: "88px" }}
          >
            <div className="commercial-terms-title col-4">
              <input
                name="attachment_title"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  height: "51px",
                  paddingLeft: "10px",
                }}
                placeholder="Enter File Name"
                onChange={(e) => {
                  this.setState({
                    attachment_title: e.target.value,
                  });
                }}
                value={this.state.attachment_title}
                required
              />
            </div>

            <div
              className="col-2"
              style={{ position: "relative", top: "-31px" }}
            >
              <label
                className="upload__button upload"
                style={{ zIndex: 1, width: "160px" }}
                onClick={this.docUploadBtnHandler.bind(
                  this,
                  "rfq_vendor_attachments"
                )}
              >
                <img src={ClipIcon} alt="" />
                <h6 style={{ position: "relative", top: "4px" }}>
                  Upload Doc <span className="text-danger">*</span>
                </h6>
              </label>

              <input
                type="file"
                name="rfq_vendor_attachments"
                id="rfq_vendor_attachments"
                onChange={this.changeTCFileHandler.bind(this)}
                className="form-control"
                style={{ visibility: "hidden" }}
                required
              />
            </div>

            <div class="col-3">
              <input
                type="text"
                value={this.state.selected_file}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  height: "51px",
                  paddingLeft: "10px",
                }}
                disabled
              />
            </div>

            <div class="col-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleAddCommercialTermsBtn.bind(this)}
                style={{ width: "115px", borderRadius: "10px", height: "51px" }}
              >
                Add
              </button>
            </div>
          </div>

          <span
            style={{ fontWeight: "bold", margin: "0 3.6vw", color: "blue" }}
          >
            Transporter Attachments
          </span>

          <div
            style={{
              height:
                this.state.vendorAttachments.length > 0
                  ? `${this.state.vendorAttachments.length * 99}px`
                  : "0px",
              maxHeight:
                this.state.vendorAttachments.length === 4
                  ? "300px"
                  : `${this.state.vendorAttachments.length * 99}px`,
              overflowY: "auto",
            }}
          >
            {this.state.vendorAttachments.map((each, index) => {
              let { file_name, rfq_file_url, id } = each;
              return (
                <>
                  <div
                    class="row"
                    key={`term-${id}`}
                    style={{ margin: "0 3vw", height: "70px" }}
                  >
                    <div class="col-6" style={{ paddingTop: "33px" }}>
                      <input
                        type="text"
                        value={file_name}
                        className="input-style"
                        style={{ width: "100%", paddingLeft: "16px" }}
                        disabled
                      />
                    </div>

                    <div class="col-3 txt-center">
                      <label for="">
                        <label
                          className="upload__button upload"
                          style={{ zIndex: 1, width: "170px" }}
                          onClick={this.previewDocument.bind(
                            this,
                            rfq_file_url
                          )}
                        >
                          {/* <img src={ClipIcon} alt="" /> */}
                          <i
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            class="fa fa-2x fa-eye txt-black"
                            aria-hidden="true"
                          ></i>
                          <h6 style={{ position: "relative", top: "4px" }}>
                            Preview Doc
                          </h6>
                        </label>

                        <input
                          type="file"
                          name="commercialTermsDoc"
                          id="commercialTermsDoc"
                          // onChange={this.changeCommercialTermsFileHandler.bind(
                          //   this
                          // )}
                          className=""
                          style={{ display: "none" }}
                          required
                        />
                      </label>
                    </div>

                    <div class="col-3 txt-center">
                      <label
                        className="upload__button upload"
                        style={{ zIndex: 1, width: "170px" }}
                        onClick={this.deleteVendorAttachment.bind(this, id)}
                      >
                        <i
                          class="fa fa-2x fa-trash mx-2 txt-danger"
                          aria-hidden="true"
                        />
                        <h6 style={{ position: "relative", top: "4px" }}>
                          Delete Doc
                        </h6>
                      </label>
                    </div>

                    <div class="col-1"></div>
                  </div>
                </>
              );
            })}
          </div>

          <span
            style={{ fontWeight: "bold", margin: "0 3.6vw", color: "blue" }}
          >
            Questions
          </span>

          <div
            style={{
              height:
                this.state.rfqQuestions.length > 0
                  ? `${this.state.rfqQuestions.length * 99}px`
                  : "0px",
              maxHeight:
                this.state.rfqQuestions.length === 4
                  ? "300px"
                  : `${this.state.rfqQuestions.length * 99}px`,
              overflowY: "auto",
            }}
          >
            {this.state.rfqQuestions.map((eachQuestion, index) => {
              let { _id, response, rfq_questions_title } = eachQuestion;
              return (
                <React.Fragment key={_id}>
                  <div class="row" style={{ margin: "0 3vw", height: "70px" }}>
                    <div class="col-6" style={{ paddingTop: "33px" }}>
                      <input
                        type="text"
                        value={rfq_questions_title}
                        className="input-style"
                        style={{ width: "100%", paddingLeft: "16px" }}
                        disabled
                      />
                    </div>
                    <div class="col-4" style={{ paddingTop: "33px" }}>
                      <input
                        className="input-style"
                        style={{ width: "100%", paddingLeft: "16px" }}
                        value={response}
                        onChange={(e) => {
                          let updatedResponse = e.target.value;
                          let updatedData = {
                            ...eachQuestion,
                            response: updatedResponse,
                          };

                          this.state.rfqQuestions[index] = updatedData;

                          this.setState({
                            rfqQuestions: this.state.rfqQuestions,
                          });
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          {/* <div className="terms__container" style={{ top: "-105px" }}>
              <div className="terms__tag" style={{ top: "-21px" }}>
                <h5 style={{ fontSize: "13px", fontWeight: "bold" }}>
                  Questions
                </h5>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "282px",
                  padding: "3vh 2vw 0vh",
                }}
                className="ag-theme-alpine rfqDetails"
              >
                <AgGridReact
                  headerClass="curved-header"
                  rowData={this.state.rfqQuestions}
                  columnDefs={questionTermsDefs}
                  defaultColDef={this.state.defaultColDef}
                  enableRangeSelection={true}
                  enableCharts={true}
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  masterDetail={true}
                  onGridReady={this.onGridReady1}
                  sideBar={this.state.sideBar}
                  paginationPageSize={3}
                  pagination={true}
                  animateRows={true}
                  showNoRowsOverlay={false}
                  suppressRowHoverHighlight={true}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                />
              </div>
            </div> */}

          <div className="next__btn__container">
            <Link
              className="back__btn"
              to={"/vendor-dashboard"}
              style={{ textDecoration: "none", color: "white" }}
            >
              Close
            </Link>
            <button
              type="button"
              className="btn btn-primary next-button"
              onClick={this.saveQuestionReplies.bind(this)}
            >
              Preview
            </button>
          </div>
        </div>

        {/* Card-3 Ends Here */}

        {/* Preview Card Starts Here*/}

        <div
          className={"slide-rfq " + this.state.sliderTranslate}
          style={{ overflow: "auto", overflowX: "hidden" }}
        >
          <div className="slide-rfq-title row">
            <div className="col-4"></div>
            <div className="col-4">
              <h4>RFQ Preview</h4>
            </div>
            <div className="col-4">
              <span
                className="float-right closebtn mr-5 my-1"
                style={{
                  marginRight: "25px",
                  width: "33px",
                  textAlign: "center",
                }}
                onClick={this.onCloseBidPreviewSlider.bind(this)}
              >
                X
              </span>
            </div>
          </div>

          {/* Card-1 Starts Here */}

          <div
            className="form__container"
            style={{ height: "290px", width: "62vw" }}
          >
            <div className="form__header" id="step1">
              <div className="header__icon__container">
                <img src={StepOneIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 01/03</div>
                <div className="header__title">Reference</div>
              </div>
            </div>

            {/* First Row */}
            <div className="row" style={{ padding: "0px 4vw" }}>
              <div className="col-6">
                <div>
                  <label>
                    RFQ Subject <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">{this.state.rfq_subject}</div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label>
                    RFQ Description <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">{this.state.rfq_description}</div>
                </div>
              </div>
            </div>
            {/* Second Row */}
            <div className="row" style={{ padding: "10px 4vw" }}>
              <div className="col-6">
                <label>
                  Event Start Date<span className="text-danger">*</span>
                </label>
                <div className="event__schedule__datepicker">
                  <div className="start__date__picker">
                    {this.state.rfq_start_time}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <label>
                  Event End Date<span className="text-danger">*</span>
                </label>
                <div className="event__schedule__datepicker">
                  <div className="end__date__picker">
                    {this.state.rfq_end_time}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card-1 Ends Here */}

          {/* Card-2 Starts Here */}
          <div
            className="form__container"
            style={{ height: "687px", marginBottom: "29px", width: "62vw" }}
          >
            <div className="form__header" id="">
              <div className="header__icon__container">
                <img src={StepTwoIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 02/03</div>
                <div className="header__title">Transport Details</div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "475px",
                minHeight: "475px",
                padding: "0 3vw",
              }}
              className="ag-theme-alpine rfqDetails"
            >
              <AgGridReact
                headerClass="curved-header"
                rowData={this.state.itemsData}
                columnDefs={productDetailsPreviewColumnDefs}
                defaultColDef={this.state.defaultColDef}
                // enableRangeSelection={true}
                // enableCharts={true}
                // detailCellRendererParams={this.state.detailCellRendererParams}
                // masterDetail={true}
                onGridReady={this.onGridReadyA}
                sideBar={this.state.sideBar}
                paginationPageSize={10}
                pagination={true}
                // animateRows={true}
                // showNoRowsOverlay={false}
                // suppressRowHoverHighlight={true}
                // gridOptions={{
                //   context: { componentParent: this },
                // }}
              />
            </div>
          </div>
          {/* Card-2 Ends Here */}

          {/* Card-3 Starts Here */}
          <div
            className="form__container"
            style={{ height: "561px ", marginBottom: "50px", width: "62vw" }}
          >
            <div className="form__header" id="">
              <div className="header__icon__container">
                <img src={StepThreeIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 03/03</div>
                <div className="header__title">Additional Details</div>
              </div>
            </div>

            <span
            style={{ fontWeight: "bold", margin: "0 3.6vw", color: "blue" }}
          >
            Transporter Attachments
          </span>

          <div
            style={{
              height:
                this.state.vendorAttachments.length > 0
                  ? `${this.state.vendorAttachments.length * 99}px`
                  : "0px",
              maxHeight:
                this.state.vendorAttachments.length === 4
                  ? "300px"
                  : `${this.state.vendorAttachments.length * 99}px`,
              overflowY: "auto",
            }}
          >
            {this.state.vendorAttachments.map((each, index) => {
              let { file_name, rfq_file_url, id } = each;
              return (
                <>
                  <div
                    class="row"
                    key={`term-${id}`}
                    style={{ margin: "0 3vw", height: "70px" }}
                  >
                    <div class="col-6" style={{ paddingTop: "33px" }}>
                      <input
                        type="text"
                        value={file_name}
                        className="input-style"
                        style={{ width: "100%", paddingLeft: "16px" }}
                        disabled
                      />
                    </div>

                    <div class="col-3 txt-center">
                      <label for="">
                        <label
                          className="upload__button upload"
                          style={{ zIndex: 1, width: "170px" }}
                          onClick={this.previewDocument.bind(
                            this,
                            rfq_file_url
                          )}
                        >
                          {/* <img src={ClipIcon} alt="" /> */}
                          <i
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            class="fa fa-2x fa-eye txt-black"
                            aria-hidden="true"
                          ></i>
                          <h6 style={{ position: "relative", top: "4px" }}>
                            Preview Doc
                          </h6>
                        </label>

                        <input
                          type="file"
                          name="commercialTermsDoc"
                          id="commercialTermsDoc"
                          // onChange={this.changeCommercialTermsFileHandler.bind(
                          //   this
                          // )}
                          className=""
                          style={{ display: "none" }}
                          required
                        />
                      </label>
                    </div>

                    

                    <div class="col-1"></div>
                  </div>
                </>
              );
            })}
          </div>

            <span
              style={{ fontWeight: "bold", margin: "0 3.6vw", color: "blue" }}
            >
              Questions
            </span>

            <div
              style={{
                height:
                  this.state.rfqQuestions.length > 0
                    ? `${this.state.rfqQuestions.length * 99}px`
                    : "0px",
                maxHeight:
                  this.state.rfqQuestions.length === 4
                    ? "300px"
                    : `${this.state.rfqQuestions.length * 99}px`,
                overflowY: "auto",
              }}
            >
              {this.state.rfqQuestions.map((eachQuestion, index) => {
                let { _id, response, rfq_questions_title } = eachQuestion;
                return (
                  <React.Fragment key={_id}>
                    <div
                      class="row"
                      style={{ margin: "0 3vw", height: "70px" }}
                    >
                      <div class="col-6" style={{ paddingTop: "33px" }}>
                        <input
                          type="text"
                          value={rfq_questions_title}
                          className="input-style"
                          style={{ width: "100%", paddingLeft: "16px" }}
                          disabled
                        />
                      </div>
                      <div class="col-4" style={{ paddingTop: "33px" }}>
                        <input
                          className="input-style"
                          style={{ width: "100%", paddingLeft: "16px" }}
                          value={response}
                          disabled
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            {/* <div
              className="terms__container"
              style={{ margin: "0 3vw", width: "89%" }}
            >
              <div className="terms__tag" style={{ top: "-21px" }}>
                <h5 style={{ fontSize: "13px", fontWeight: "bold" }}>
                  Questions
                </h5>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "282px",
                  padding: "3vh 2vw 0vh",
                }}
                className="ag-theme-alpine rfqDetails"
              >
                <AgGridReact
                  headerClass="curved-header"
                  rowData={this.state.rfqQuestions}
                  columnDefs={previewQuestionDefs}
                  defaultColDef={this.state.defaultColDef}
                  //   enableRangeSelection={true}
                  //   enableCharts={true}
                  //   detailCellRendererParams={this.state.detailCellRendererParams}
                  //   masterDetail={true}
                  onGridReady={this.onGridReady2}
                  sideBar={this.state.sideBar}
                  paginationPageSize={3}
                  pagination={true}
                  //   animateRows={true}
                  //   showNoRowsOverlay={false}
                  //   suppressRowHoverHighlight={true}
                  //   gridOptions={{
                  //     context: { componentParent: this },
                  //   }}
                />
              </div>
            </div> */}
            <div className="next__btn__container">
              <div
                className="back__btn"
                onClick={this.onCloseBidPreviewSlider.bind(this)}
              >
                Close
              </div>
              <button
                type="button"
                className="btn btn-primary next-button"
                //   onClick={this.saveQuestionReplies.bind(this)}
                onClick={this.handleSubmitQuote.bind(this)}
              >
                Submit
              </button>
            </div>
          </div>

          {/* Card-3 Ends Here */}
        </div>
      </div>
    );
  }
}

export default withParams(RfqResponseRevamp);
