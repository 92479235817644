import React, {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from "../redirectURL";

class Favourites extends Component {
    constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
            favouriteModel:"show-n",
			screen_title:"",
            alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			overly:"show-n",
            defIco:"fa-star-o"
	    }
    }
    componentDidMount(){
        if(localStorage.getItem("favourites") !== "" && localStorage.getItem("favourites") !== undefined)
        {
            if(localStorage.getItem("favourites") !== "" && localStorage.getItem("favourites") !== null && localStorage.getItem("favourites") != undefined)
            {
                var favs = localStorage.getItem("favourites");
                console.log("aasd",favs)
                var f = favs.split(",");
                var cpath = window.location.pathname;
                // console.log(f.indexOf(cpath),"paht");
                if(f.length > 0)
                {
                    if(f.indexOf(cpath) >= 0)
                    {
                        this.setState({
                            defIco:"fa-star"
                        })
                    }
                    else{
                        this.setState({
                            defIco:"fa-star-o"
                        })
                    }
                }
            }
        }
    }
    setAsFavourite = () =>{
        this.setState({
			favouriteModel:"show-m",
			overly:"show-m",
			screen_title:""
		})
    }
    onClickHideAll() {
		this.setState({
			favouriteModel:"show-n",
			overly:"show-n",
			screen_title:""
		})
	}
    closeAlert = () => {
		this.setState({
			show: false
		});
	}
	formFavourite = (e) =>{
        e.preventDefault();
        var screenTitle = document.getElementById("screen_title").value;
        var pagePath = window.location.pathname;
        var params = {
            screen_title:screenTitle,
            page_path:pagePath,
            user_email:localStorage.getItem("email")
        }
        // console.log("parmas", params)
        redirectURL.post("/app/setScreenAsFavaourite",params)
        .then((response)=>{
            if(response.data.status == "success")
            {
				this.setState({
					favouriteModel:"show-n",
					overly:"show-n",
					screen_title:"",
					show:true,
					basicTitle:"Successfully added your favorites",
					basicType:"success",
                    defIco:"fa-star"
				})
            }
            else{
				this.setState({
					favouriteModel:"show-n",
					overly:"show-n",
					screen_title:"",
					show:true,
					basicTitle:"Something went wrong",
					basicType:"dnager",
                    defIco:"fa-star-o"
				})
            }
            document.getElementById("screen_title").value="";
        })
    }
    render() {
        return (
            <span style={{position:"relative"}}>
                <SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
                {/* <a href="javascript:;" onClick={this.setAsFavourite.bind(this)}>
                    <i className={"fa "+this.state.defIco+" f20"}></i>
                </a> */}
                <span className={"favBlock "+this.state.favouriteModel}>
                    <form onSubmit={this.formFavourite.bind(this)} className="row">
                        <div className="col-xl-9 col-lg-9">
                            <input type="text" name="screen_title" className="form-control" placeholder="Screen Title" id="screen_title" autoComplete='off'  />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                            <button type='submit' className='btn btn-success f15'>Submit</button>
                        </div>
                    </form>
                </span>
                
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </span>
        );
    }
}

export default Favourites;