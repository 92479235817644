// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#edit-shipment input{
    font-size: 10px;
}
/* label{
    white-space: nowrap;
} */
#edit-shipment .form-control {
    font-size: 0.8rem !important;
  }

  #billOfLadingDate{
    width: 157px !important;
    border-radius: 6px;
    background-color: #f2f4f2;
    height: 40px;
    /* border:none !important; */
  }
  #pick_up_date{
    width: 157px !important;
    height: 38px;
    border-radius: 6px;
    background-color: #f2f4f2;
    /* border:none !important; */
  }
  #invoiceDate{
    width: 157px !important;
    border-radius: 6px;
    background-color: #f2f4f2;
    height: 38px;
    /* border:none !important; */
  }`, "",{"version":3,"sources":["webpack://./src/components/container-tracking/editShipment.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;;GAEG;AACH;IACI,4BAA4B;EAC9B;;EAEA;IACE,uBAAuB;IACvB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,4BAA4B;EAC9B;EACA;IACE,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,4BAA4B;EAC9B;EACA;IACE,uBAAuB;IACvB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,4BAA4B;EAC9B","sourcesContent":["#edit-shipment input{\n    font-size: 10px;\n}\n/* label{\n    white-space: nowrap;\n} */\n#edit-shipment .form-control {\n    font-size: 0.8rem !important;\n  }\n\n  #billOfLadingDate{\n    width: 157px !important;\n    border-radius: 6px;\n    background-color: #f2f4f2;\n    height: 40px;\n    /* border:none !important; */\n  }\n  #pick_up_date{\n    width: 157px !important;\n    height: 38px;\n    border-radius: 6px;\n    background-color: #f2f4f2;\n    /* border:none !important; */\n  }\n  #invoiceDate{\n    width: 157px !important;\n    border-radius: 6px;\n    background-color: #f2f4f2;\n    height: 38px;\n    /* border:none !important; */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
