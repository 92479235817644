import React, {
    useRef,
    useState,
    useEffect,
    useSyncExternalStore,
} from "react";
import redirectURL from "../redirectURL";
import moment from "moment";
import "./trackingDetailsCard.css";

const DetailsTab = (props) => {
    console.log("TrackingDetailsCard", props);
    const [recordforedit, setRecordforedit] = useState({});
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            fontSize: "10px", // You can adjust this value to your liking
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "10px", // You can adjust this value to your liking
        }),
    };
    const [cargoList, setCargoList] = useState([
        { value: "PCargo1", label: "PCargo1" },
        { value: "PCargo2", label: "PCargo2" },
    ]);
    const [supplierCargoList, setSupplierCargoList] = useState([
        { value: "SPCargo1", label: "SPCargo1" },
        { value: "SPCargo2", label: "SPCargo2" },
    ]);

    const [shipperType, setShipperType] = useState("");


    const [pickUpDate, setPickUpDate] = useState("");

    const [containerNo, setContainerNo] = useState("");
    // for value
    const [customer, setCustomer] = useState();
    const [supplier, setSupplier] = useState([]);
    const [email, setEmail] = useState("");
    const [product, setProduct] = useState([]);

    const [LCNumber, setLCNumber] = useState("");


    const [activeItem, setActiveItem] = useState("");
    const shipmentType = [
        { value: "FCL", label: "Full Container Load" },
        { value: "LCL", label: "Less Container Load" }]
    const incoterms = [
        { value: "EXW", label: "EXW - Ex Work" },
        { value: "FCA", label: "FCA - Free Carrier" },
        { value: "FAS", label: "FAS - Free Alongside Ship" },
        { value: "FOB", label: "FOB - Free on Board" },
        { value: "CFR", label: "CFR - Cost and Freight" },
        { value: "CIF", label: "CIF - Cost, Insurance, and Freight " },
        { value: "CPT", label: "CPT - Carriage Paid To" },
        { value: "CIP", label: "CIP - Carriage and Insurance Paid To" },
        { value: "DAP", label: "DAP - Delivered at Place " },
        { value: "DPU", label: "DPU - Delivered at Place Unloaded " },
        { value: "DDP", label: "DDP - Delivered Buty Paid " }
    ]
    const [shipmentActive, setShipmentActive] = useState({ value: "", label: "" });
    const [showShipmentType, setShowShipmentType] = useState(false);

    const [inputDisabled, setInputDisabled] = useState(true);
    const [editDetails, setEditdetails] = useState({
        contract_number: "",
        movement_type: "",
        cargo_mode: "",
        shipment_type: "",
        selected_cargo_type: "",
        track_by: "",
        bill_of_lading_date: "",
        shipping_line: "",
        transit_days: "",
        bill_of_lading_no: "",
        container_no: "",
        shipment_created_with: "",
    });

    const [stateData, setStateData] = useState({
        customHouseAgent: "",
        freightForwarder: "",
        bl_containers: [],
    });

    const [editStakeholderDetail, setEditStakeDetails] = useState({
        shipment_owner: "",
        po_no: "",
        shipper_type: "",
        origin_port: "",
        stuffing_location: "",
        destination_location: "",
        destuffing_location: "",
    });
    const [editAdditionalDetails, seteditAdditionalDetails] = useState({
        invoice_no: "",
        invoice_date: "",
        free_trade_agreement: "",
        rdb_value: "",
        epcg_value: "",
        letter_of_credit: "",
        incoterm: { value: "", label: "" },
    });
    const [editdemurrageDetails, seteditdemurrageDetails] = useState({
        demurrage_free_days: "",
        detention_free_days: "",
        cfs_free_days: "",
        demurrage_and_detention_port: ""
    });

    useEffect(() => {

    }, [editdemurrageDetails]);
    const [editsupplier, seteditsupplier] = useState({
        supplier: "",
        admin_name: "",
        admin_email: "",
        team: "",
        poc: "",
        product_selected: "",
        supplierReferenceId: "",
    });
    const [editcustomer, seteditcustomer] = useState({
        customer: "",
        admin_name_cust: "",
        admin_email_cust: "",
        team_cust: "",
        poc_cust: "",
        product_selected_cust: "",
        customerReferenceId: "",
        customer_code: "",
    });
    useEffect(() => {
        let userEmail = localStorage.getItem("email");
        setEmail(userEmail);

        // setTrackByValue()
        setShowShipmentType(true);
        setInputDisabled(true);
        getShipmentForEdit();
    }, [props]);
    const getShipmentForEdit = () => {
        var params = { shipment_unique_id: props.shipment_unique_id };
        let bl_containers = [];
        redirectURL.post("/exim/getshipmentforedit", params).then((resp) => {
            // console.log(e,"eeeeeeeeeeeeeeeeeeeee",props.shipment_unique_id);
            console.log("getshipmentforedit", resp.data);
            if (resp.data.customer_details.length > 0) {
                setCustomer(resp.data.customer_details[0].customer);
            } else {
                setCustomer("NA");
            }

            if (resp.data.supplier_details.length > 0) {
                setSupplier(resp.data.supplier_details);
            } else {
                setSupplier("NA");
            }

            if (resp.data.product_details.length > 0) {
                setProduct(resp.data.product_details);
            }
            var record = resp.data.record;
            record.forEach((each) => {
                if (each.shipment_unique_id === props.shipment_unique_id) {
                    bl_containers.push(each.container_no);
                }
            });
            // console.log(record, "420");
            if (record != "" && record != undefined) {
                if (record.length > 0) {
                    var e = record[0];

                    setContainerNo(e.container_no);

                    setShipperType(e.shipper_type);
                    setActiveItem(e.cargo_mode);
                    setShipmentActive(shipmentType.find(item => item.value === e.shipping_mode));
                    setShowShipmentType(e.shipment_type);
                    setRecordforedit(record[0]);
                    if (e.pick_up_date) {
                        setPickUpDate(moment.parseZone(e.pick_up_date).utcOffset("+00:00"));
                    } else {
                        setPickUpDate("");
                    }

                    if (e.hasOwnProperty("lc_no")) {
                        setLCNumber(e.lc_no);
                    } else {
                        setLCNumber("");
                    }
                    setEditdetails((prev) => ({
                        ...prev,
                        contract_number: e.contract_number,
                        movement_type: e.movement_type,
                        cargo_mode: e.cargo_mode,
                        shipment_type: e.shipment_type,
                        selected_cargo_type: e.selected_cargo_type,
                        track_by: e.track_by,
                        bill_of_lading_date: e.bill_of_lading_date
                            ? moment.parseZone(e.bill_of_lading_date).utcOffset("+00:00")
                            : "",
                        shipping_line: e.shipping_line,
                        transit_days: e.transit_days,
                        bill_of_lading_no: e.mbl_number,
                        container_no: e.container_no,
                        shipment_created_with: e.shipment_created_with,
                    }));
                    setStateData({
                        ...stateData,
                        freightForwarder: e.freight_forwarder,
                        customHouseAgent: e.custom_house_agent,
                        bl_containers: bl_containers,
                    });
                    setEditStakeDetails((prev) => ({
                        ...prev,
                        shipment_owner: e.shipment_owner,
                        po_no: e.po_no,
                        shipper_type: e.shipper_type,
                        origin_port: e.origin_port,
                        stuffing_location: e.stuffing_location,
                        destination_location: e.destination_location,
                        destuffing_location: e.destuffing_location,
                    }));

                    let letterOfCredit = "No";
                    if (
                        e.hasOwnProperty("letter_of_credit") &&
                        e.letter_of_credit.includes("yes")
                    ) {
                        letterOfCredit = "yes";
                    } else {
                        letterOfCredit = "No";
                    }
                    seteditAdditionalDetails((prev) => ({
                        ...prev,
                        invoice_no: e.invoice_no,
                        invoice_date: e.invoice_date
                            ? moment.parseZone(e.invoice_date).utcOffset("+00:00")
                            : "",
                        free_trade_agreement: e.free_trade_agreement,
                        rdb_value: e.rd_benefit_value,
                        epcg_value: e.epcg_value,
                        letter_of_credit: e.letter_of_credit,
                        incoterm: incoterms.find(item => item.value === e.inco_terms)
                    }));

                    if (e.hasOwnProperty("lc_no") && e.lc_no != "") {
                        setLCNumber(e.lc_no);
                    } else {
                        setLCNumber("NA");
                    }
                    seteditdemurrageDetails((prev) => ({
                        ...prev,
                        demurrage_free_days: e.hasOwnProperty("demurrage_free_days")
                            ? e.demurrage_free_days
                            : "NA",
                        detention_free_days: e.hasOwnProperty("detention_free_days")
                            ? e.detention_free_days
                            : "NA",
                        cfs_free_days: e.hasOwnProperty("cfs_free_days")
                            ? e.cfs_free_days
                            : "NA",
                        demurrage_and_detention_port: e.hasOwnProperty("demurrage_and_detention_port") ? e.demurrage_and_detention_port : "NA"
                    }));
                }
            }
            if (
                resp.data.supplier_details != "" &&
                resp.data.supplier_details != undefined
            ) {
                if (resp.data.supplier_details.length > 0) {
                    var supp_details = resp.data.supplier_details;
                    seteditsupplier((prev) => ({
                        ...prev,
                        supplier: supp_details.supplier,
                        admin_name: supp_details.admin_name,
                        admin_email: supp_details.admin_email,
                        team: supp_details.team,
                        poc: supp_details.poc,
                        product_selected: supp_details.product_selected,
                        supplierReferenceId: supp_details.supplier_reference_id,
                    }));
                }
            }
            if (
                resp.data.customer_details != "" &&
                resp.data.customer_details != undefined
            ) {
                if (resp.data.customer_details.length > 0) {
                    var cust_details = resp.data.customer_details[0];
                    // console.log("custoimerr", cust_details);
                    seteditcustomer((prev) => ({
                        ...prev,
                        customer: cust_details.customer,
                        admin_name_cust: cust_details.admin_name,
                        admin_email_cust: cust_details.admin_email,
                        team_cust: cust_details.team,
                        poc_cust: cust_details.poc,
                        customer_code: cust_details.customer_code,
                        // product_selected_cust: supp_details.product_selected,
                        customerReferenceId: cust_details.customer_reference_id,
                    }));
                }
            }
        });
    };

    return (
        <div style={{ minHeight: "87vh", width: "95%", margin: "auto" }}>
            <div className="card custom-card" style={{ padding: "20px", borderRadius: "18px", marginLeft: "-14px", paddingLeft: "36px", paddingTop: "30px", whiteSpace: "normal" }}>
                <div className="card-body" style={{ padding: "0px" }}><div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                Summary
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                            <div className="accordion-body">

                                <div className="accordion-content">
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Contract Number : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editDetails.contract_number !== "" && editDetails.contract_number !== undefined && editDetails.contract_number !== null)
                                                    ? editDetails.contract_number
                                                    : "NA"
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Movement Type : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editDetails.movement_type !== "" && editDetails.movement_type !== undefined && editDetails.movement_type !== null) != ""
                                                    ? editDetails.movement_type
                                                    : "NA"
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                                Shipment Details
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show">
                            <div className="accordion-body">
                                <div className="accordion-content">
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Cargo Mode : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (activeItem !== "" && activeItem !== undefined && activeItem !== null) ? activeItem : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Shipment Mode : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (shipmentActive !== "" && shipmentActive !== undefined && shipmentActive !== null)
                                                    ? shipmentActive.label
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Type of Shipment : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editDetails.selected_cargo_type !== "" && editDetails.selected_cargo_type !== undefined && editDetails.selected_cargo_type !== null)
                                                    ? editDetails.selected_cargo_type
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Shipment Created With : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editDetails.shipment_created_with !== "" && editDetails.shipment_created_with !== undefined && editDetails.shipment_created_with !== null) ?
                                                    (editDetails.shipment_created_with === "container_no" ? "Container Number" : "BL Number") : ("NA")
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Bill of Lading Number : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editDetails.bill_of_lading_no !== "" && editDetails.bill_of_lading_no !== undefined && editDetails.bill_of_lading_no !== null)
                                                    ? editDetails.bill_of_lading_no
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">{(props.searchType == "container") ? "Container Number" : "Container Number (s)"} : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {(props.containers && props.containers.length === 0) ? "NA" : [...new Set(props.containers)].join(", ")}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Bill of Lading Date : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editDetails.bill_of_lading_date !== "" && editDetails.bill_of_lading_date !== undefined && editDetails.bill_of_lading_date !== null)
                                                    ? getHyphenDDMMYYYYHHmm(editDetails.bill_of_lading_date)
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Shipping Line : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editDetails.shipping_line !== "" && editDetails.shipping_line !== undefined && editDetails.shipping_line !== null)
                                                    ? editDetails.shipping_line
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Promised Transit Days : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editDetails.transit_days !== "" && editDetails.transit_days !== undefined && editDetails.transit_days !== null)
                                                    ? editDetails.transit_days
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true" aria-controls="panelsStayOpen-collapseThree">
                                Stakeholder Details
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show">
                            <div className="accordion-body">

                                <div className="accordion-content">

                                    {editDetails.movement_type == "Import" ? (
                                        <div className="form-group row">
                                            <div className="col-xl-3 col-lg-3">
                                                <label className="custom-label">Customer : </label>
                                            </div>
                                            <div className="col-xl-9 col-lg-9">
                                                {
                                                    customer
                                                }
                                            </div>
                                        </div>
                                    ) : ("")}
                                    {editDetails.movement_type == "Export" ? (
                                        <div className="form-group row">
                                            <div className="col-xl-3 col-lg-3">
                                                <label className="custom-label">Supplier : </label>
                                            </div>
                                            {
                                                (Array.isArray(supplier) && supplier.length>0)?
                                                
                                                <div className="col-xl-12 col-lg-12" style={{ minHeight: "300px" }}>
                                                    <table width="100%" border="0" className="table envtable" cellSpacing={5} style={{ minHeight: "100px" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Supplier Name</th>
                                                                <th>Supplier Reference Number</th>
                                                                <th>Admin Name</th>
                                                                <th>Admin Email</th>
                                                                <th>Team</th>
                                                                <th>POC</th>
                                                                <th>Product</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {supplier.map((each, inx) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {(each.supplier !== "" && each.supplier !== undefined && each.supplier !== null) ?
                                                                                each.supplier : "NA"}
                                                                        </td>
                                                                        <td>
                                                                            {(each.supplier_reference_id !== "" && each.supplier_reference_id !== undefined && each.supplier_reference_id !== null) ?
                                                                                each.supplier_reference_id : "NA"}
                                                                        </td>
                                                                        <td>
                                                                            {(each.admin_name !== "" && each.admin_name !== undefined && each.admin_name !== null) ?
                                                                                each.admin_name : "NA"}
                                                                        </td>
                                                                        <td>
                                                                            {(each.admin_email !== "" && each.admin_email !== undefined && each.admin_email !== null) ?
                                                                                each.admin_email : "NA"}
                                                                        </td>
                                                                        <td>
                                                                            {(each.team.label !== "" && each.team.label !== undefined && each.team.label !== null) ?
                                                                                each.team.label : "NA"}
                                                                        </td>
                                                                        <td>
                                                                            {(each.poc.label !== "" && each.poc.label !== undefined && each.poc.label !== null) ?
                                                                                each.poc.label : "NA"}
                                                                        </td>
                                                                        <td>
                                                                            {(each.product_selected.label !== "" && each.product_selected.label !== undefined && each.product_selected.label !== null) ?
                                                                                each.product_selected.label : "NA"}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>:
                                                <></>
                                            }
                                            </div>
                                    ) : ("")}

                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Shipment Owner : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editStakeholderDetail.shipment_owner !== "" && editStakeholderDetail.shipment_owner !== undefined && editStakeholderDetail.shipment_owner !== null) ?
                                                    editStakeholderDetail.shipment_owner :
                                                    "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Purchase Order Number : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editStakeholderDetail.po_no !== "" && editStakeholderDetail.po_no !== undefined && editStakeholderDetail.po_no !== null)
                                                    ? editStakeholderDetail.po_no
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Freight Forwarder : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (stateData.freightForwarder !== "" && stateData.freightForwarder !== undefined && stateData.freightForwarder !== null)
                                                    ? stateData.freightForwarder
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Custom House Agent : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (stateData.customHouseAgent !== "" && stateData.customHouseAgent !== undefined && stateData.customHouseAgent !== null)
                                                    ? stateData.customHouseAgent
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Pickup Date : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (pickUpDate !== "" && pickUpDate !== undefined && pickUpDate !== null) ? getHyphenDDMMYYYYHHmm(pickUpDate) : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Origin Address : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editStakeholderDetail.origin_port !== "" && editStakeholderDetail.origin_port !== undefined && editStakeholderDetail.origin_port !== null)
                                                    ? editStakeholderDetail.origin_port
                                                    : "NA"
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Stuffing Location : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editStakeholderDetail.stuffing_location !== "" && editStakeholderDetail.stuffing_location !== undefined && editStakeholderDetail.stuffing_location !== null)
                                                    ? editStakeholderDetail.stuffing_location
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Destination Address : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editStakeholderDetail.destination_location !== "" && editStakeholderDetail.destination_location !== undefined && editStakeholderDetail.destination_location !== null)
                                                    ? editStakeholderDetail.destination_location
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label">Destuffing Location : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editStakeholderDetail.destuffing_location !== "" && editStakeholderDetail.destuffing_location !== undefined && editStakeholderDetail.destuffing_location !== null)
                                                    ? editStakeholderDetail.destuffing_location
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    {product.length > 0 ? (
                                        <div className="form-group row">
                                            <div className="col-xl-3 col-lg-3">
                                                <label className="custom-label" style={{ fontWeight: "bold !important" }}>Product Details : </label>
                                            </div>
                                            <div className="col-xl-12 col-lg-12" style={{ minHeight: "300px" }}>
                                                <table width="100%" border="0" className="table envtable" cellSpacing={5} style={{ minHeight: "100px" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>HS Code</th>
                                                            <th>Selected Cargo</th>
                                                            <th>Selected Team</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {product.map((each, inx) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {(each.product_name !== "" && each.product_name !== undefined && each.product_name !== null) ?
                                                                            each.product_name : "NA"}
                                                                    </td>
                                                                    <td>
                                                                        {(each.hs_code !== "" && each.hs_code !== undefined && each.hs_code !== null) ?
                                                                            each.hs_code : "NA"}
                                                                    </td>
                                                                    <td>
                                                                        {(each.selected_cargo.label !== "" && each.selected_cargo.label !== undefined && each.selected_cargo.label !== null) ?
                                                                            each.selected_cargo.label : "NA"}
                                                                    </td>
                                                                    <td>
                                                                        {(each.selected_team.label !== "" && each.selected_team.label !== undefined && each.selected_team.label !== null) ?
                                                                            each.selected_team.label : "NA"}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="true" aria-controls="panelsStayOpen-collapseFour">
                                Additional Details
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show">
                            <div className="accordion-body">

                                <div className="accordion-content">

                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-4">
                                            <label className="custom-label">Invoice Number : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            {
                                                (editAdditionalDetails.invoice_no !== "" && editAdditionalDetails.invoice_no !== undefined && editAdditionalDetails.invoice_no !== null)
                                                    ? editAdditionalDetails.invoice_no
                                                    : "NA"
                                            }
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-4">
                                            <label className="custom-label">Invoice Date : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            {
                                                (editAdditionalDetails.invoice_date !== "" && editAdditionalDetails.invoice_date !== undefined && editAdditionalDetails.invoice_date !== null)
                                                    ? getHyphenDDMMYYYYHHmm(editAdditionalDetails.invoice_date)
                                                    : "NA"
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-4">
                                            <label className="custom-label">Free Trade Agreement : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            {
                                                (editAdditionalDetails.free_trade_agreement !== "" && editAdditionalDetails.free_trade_agreement !== undefined && editAdditionalDetails.free_trade_agreement !== null)
                                                    ? editAdditionalDetails.free_trade_agreement.toLocaleUpperCase()
                                                    : "NA"
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-4">
                                            <label className="custom-label">Research & Development Benefit Certificate : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            {
                                                (editAdditionalDetails.rdb_value !== "" && editAdditionalDetails.rdb_value !== undefined && editAdditionalDetails.rdb_value !== null)
                                                    ? editAdditionalDetails.rdb_value.toLocaleUpperCase()
                                                    : "NA"
                                            }
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-4">
                                            <label className="custom-label">Export Promotion Capital Goods (EPCG) : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            {
                                                (editAdditionalDetails.epcg_value !== "" && editAdditionalDetails.epcg_value !== undefined && editAdditionalDetails.epcg_value !== null)
                                                    ? editAdditionalDetails.epcg_value.toLocaleUpperCase()
                                                    : "NA"
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-4">
                                            <label className="custom-label">LC Available (Letter of Credit) : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            {
                                                (editAdditionalDetails.letter_of_credit !== "" && editAdditionalDetails.letter_of_credit !== undefined && editAdditionalDetails.letter_of_credit !== null)
                                                    ? editAdditionalDetails.letter_of_credit.toLocaleUpperCase()
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    {editAdditionalDetails &&
                                        editAdditionalDetails.letter_of_credit === "yes" ? (
                                        <div className="form-group row">
                                            <div className="col-xl-3 col-lg-4">
                                                <label className="custom-label"> Letter of Credit Number : </label>
                                            </div>
                                            <div className="col-xl-9 col-lg-8">
                                                {
                                                    (LCNumber !== "" && LCNumber !== undefined && LCNumber !== null) ? LCNumber : "NA"
                                                }
                                            </div>
                                        </div>
                                    ) : ""}
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-4">
                                            <label className="custom-label">Incoterms : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            {
                                                (editAdditionalDetails.incoterm !== "" && editAdditionalDetails.incoterm !== undefined && editAdditionalDetails.incoterm !== null)
                                                    ? editAdditionalDetails.incoterm.label
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="true" aria-controls="panelsStayOpen-collapseFive">
                                Detention Details
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse show">
                            <div className="accordion-body">

                                <div className="accordion-content">
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label"> Detention Port : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editdemurrageDetails.demurrage_and_detention_port !== "" && editdemurrageDetails.demurrage_and_detention_port !== undefined && editdemurrageDetails.demurrage_and_detention_port !== null)
                                                    ? editdemurrageDetails.demurrage_and_detention_port
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-xl-3 col-lg-3">
                                            <label className="custom-label"> Detention Free Days : </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-9">
                                            {
                                                (editdemurrageDetails.detention_free_days !== "" && editdemurrageDetails.detention_free_days !== undefined && editdemurrageDetails.detention_free_days !== null)
                                                    ? editdemurrageDetails.detention_free_days
                                                    : "NA"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};
var getHyphenDDMMYYYY = function (date_string) {
    if (date_string === "" || date_string === undefined) {
        return "";
    } else {
        return moment.parseZone(date_string).format("YYYY-MM-DD");
    }
};
var getHyphenDDMMYYYYHHmm = function (date_string) {
    if (date_string === "" || date_string === undefined) {
        return "";
    } else {
        return moment.parseZone(date_string).format("DD-MM-YYYY HH:mm");
    }
};

export default DetailsTab;
