import React, { Component } from 'react';
import AgGrid from "./AgGrid";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DashboardCountersComponent from './dashboardCounters.component';
import ComponentIndex from '../../common/appendComponents';
import DateFormater from '../layouts/dateComponent';
import DatePicker from 'react-datepicker';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import 'react-datepicker/dist/react-datepicker.css';
import redirectURL from "../redirectURL";

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


class Default extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            totalTrucks: [],
            uniqueActiveConsignments: null,
            totalActiveConsignments: null,
            gpsNotTodayActiveConsignments: null,
            totalActiveConsignmentsCount: 0,
            gpsNotTodayActiveConsignmentsCount: 0,
            currentDepartmentTitle: null,
            showdata: this.displayData,
            truckInfo: '',
            consignments: '',
            sndshow: 'show-n',
            gpsnotreported: [],
            departmentchrtshow: 'show-m',
            currenttrucklocation: '',
            truckoperates: 0,
            truckgpslifetime: 0,
            lspInfo: 0,
            deptServes: 0,
            transrecordserves: 0,
            gpssendtoday: 0,
            nopetransporters: 0,
            gpstoday: 0,
            alldepts: [],
            //deptvalue:'all',
            deptvalue: null,
            sliderTranslate: '',
            truckscnt: 0,
            trucklocations: 0,
            notrans: 0,
            trucktoday: 0,
            trucknogps: 0,
            data: "grouped",
            legendPosition: "top-left",
            interpolate: "linear",
            xLabel: "M",
            yLabel: "GGGGGGGGGG",
            hidePoints: false,
            hideLines: false,
            yMin: null,
            yMax: null,
            xMax: null,
            xMin: null,
            loadsData: [],
            transportschart: [],
            transaxis: [],
            transyaxis: [],
            trans_no_gps_axis: [],
            trans_no_gps_yaxis: [],
            deptaxis: [],
            deptyaxis: [],
            lspaxis: [],
            lspyaxis: [],
            lsp_no_gps_axis: [],
            lsp_no_gps_yaxis: [],
            gpsintervalaxis: [],
            gpsintervalyaxis: [],
            // modules: AllModules,
            open: false,
            filterstatess: {},
            columnsdatas: {},
            rowgroupdcols: [],
            valuecolumns: [],
            pivotmodeis: false,
            pivotcols: [],

            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true

            },
            frameworkComponents: {
                dateFormater: DateFormater
            },

            rowData: [],
            rowwithoutData: '',
            rowgpspacketData: null,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: true,
            suppressAggFuncInHeader: true,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            pagetitle: "",
            eventGridAction: "gridAction",
            defaulttab: "all",
            loadshow: "show-n",
            ontripwithgps: 0,
            ontripwithgpsdata: [],
            ontripgpsna: 0,
            trucksWithGpsLocation: [],
            atdealertruckcnt: 0,
            atdealernogps: [],
            alldealertrucks: [],
            rowdataatdealer: [],
            primaryontrips: [],
            secondaryontrips: [],
            usermanualmodal: false,
            inactivewheels: [],
            inactiveeicher: [],
            activeeicher: [],
            activewheels: [],
            totaltruckgps: [],
            totalactive: [],
            totalinactive: [],
            totaltruckgpsontrips: [],
            totaltruckgpsofftrips: [],
            totalactiveontrips: [],
            totalactiveofftrips: [],
            totalinactiveontrips: [],
            totalinactiveofftrips: [],
            truckrowData: null,
            sliderTrucksTranslate: "",
            sidebartitle: "",
            overly: "show-n",
            inactiveaxesontrips: [],
            inactiveenmovilontrips: [],
            inactiveaxesofftrips: [],
            inactiveenmovilofftrips: [],
            dealersdata: [],
            allontriptrucks: [],
            truck_org_names_list: []
        };
        this.onShowGridData = this.onShowGridData.bind(this);
        // this.activeOntripsData = this.activeOntripsData.bind(this);
        this.cipher = this.cipher.bind(this);
    }
    refreshForEveryMinute = () => {
        console.log('Refreshing data...');
        // Fetch new data or perform some action here
        setTimeout(() => this.refreshForEveryMinute(), 60000); // Continue refreshing every minute
    }
    async componentDidMount() {
        //googleAnalytics.initGA();
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });
        await this.filterByDepartment();
    }

    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        catch (e) {

        }

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onChartOptionsChanged(event) {
        var chartOptions = event.chartOptions;
    }
    onFirstDataRendered(params) {
        var createRangeChartParams = {
            cellRange: {
                columns: ["country", "sugar", "fat", "weight"]
            },
            chartContainer: document.querySelector("#myChart"),
            chartType: "bubble",
            suppressChartRanges: true
        };
    }

    onGridState = () => {
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onScatterHover(point) {
        return `<b>Date: </b>${point.x}<br /><b>Value: </b>${point.y}`;
    }
    createSelectItems() {
        let items = [];
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All</option>);
        this.state.alldepts.map((item, key) => {
            //console.log("Here "+item,key);
            items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);
        });
        return items;
    }
    handleChange(e) {
        //console.log("V",e.target.value);
        this.setState({ deptvalue: e.target.value });
    }

    getCurrentDepartmentCode(pathToMatch = null) {
        let departmentCode = null;
        let departmentName = null;
        switch (pathToMatch) {
            case "/dashboard/snddefault":
                //departmentCode='SNDG';
                var dcode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                // departmentCode = dcode('SNDG');
                departmentCode = ('SNDG');
                departmentName = " (Sales and Dispatch) ";
                break;
            case "/dashboard/prtdefault":
                //departmentCode='LOG-PRT';
                var dcode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                // departmentCode = dcode('LOG-PRT');
                departmentCode = ('LOG-PRT');
                departmentName = " (Spare Parts) ";
                break;
            case "/dashboard/tnpdefault":
                //departmentCode='LOG-TNP';
                var dcode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                // departmentCode = dcode('LOG-TNP');
                departmentCode = ('LOG-TNP');
                departmentName = " (Train and Production) ";
                break;
            case "/dashboard/scdefault":
                //departmentCode='LOG-SC';
                var dcode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                // departmentCode = dcode('LOG-SC');
                departmentCode = ('LOG-SC');
                departmentName = " (Supply Chain) ";
                break;
            default:
                departmentName = " default ";
            // console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
        }
        this.setState({
            deptvalue: departmentCode,
            currentDepartmentTitle: departmentName,
        });
        return departmentCode;
    }
    filterByDepartment = async () => {
        this.setState({
            // loadshow: 'show-m'
        })
        // Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
        redirectURL.post('/consignments/gettrucks', {}, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        })
            .then(async(response) => {
                // console.log(response, "check_response")
                let result = JSON.parse(JSON.stringify(response)).data;
                // console.log("resultt", result)
                let uniqueActiveConsignments = (result.onTriprecords) ? result.onTriprecords : [];
                let uniqueActiveConsignmentsCount = (result.onTriprecords) ? result.onTriprecords.length : 0;
                let totalTrucks = []
                var non_enmovil_trucks = []
                if (result.onTriprecords.length > 0) {
                    console.log("check_here")
                    result.onTriprecords.map((item) => {
                        totalTrucks.push(item)
                    })
                }
                console.log(non_enmovil_trucks, "non_enmovil_trucks_all")
                let totalTrucksCount = totalTrucks.length;
                console.log("total", totalTrucks, totalTrucksCount);

                //totalActiveConsignmentsCount
                // console.log("uniqueActiveConsignmentsCount ", uniqueActiveConsignmentsCount)
                var allontrips = []
                if (uniqueActiveConsignments.length > 0) {
                    uniqueActiveConsignments.map((item) => {
                        allontrips.push(item.truck_no)
                    })
                }
                console.log(totalTrucks,"totalTrucks-377")
                await this.setState({
                    totalTrucks: totalTrucks,
                    truckscnt: totalTrucksCount,
                    gpsNotTodayActiveConsignments: uniqueActiveConsignments,
                    uniqueActiveConsignments: uniqueActiveConsignments,
                    dealerRecords: result.dealerRecords,
                    totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
                    allontriptrucks: allontrips,
                    // loadshow: 'show-n'
                },()=>{
                    this.onShowGridData()
                });
                // await this.onShowGridData();
            })
            .catch(function (error) {
                console.log(error);
            });


        /*Trucks with GPS Data*/
        // Counter for "Trucks with GPS Data"
        redirectURL.post('/consignments/gettruckswithgpsdata', {}, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        })
            .then((response) => {
                let result = JSON.parse(JSON.stringify(response)).data;
                let trucksWithGpsLocation = []
                if (window.location.href.includes("/tpttruckgpsstatus")) {
                    if (result.trucksWithGpsLocation.length > 0) {
                        result.trucksWithGpsLocation.map((item) => {
                            if (item.actual_lspuser != "WHEELSEYE") {
                                trucksWithGpsLocation.push(item)
                            }
                        })
                    }
                } else {
                    if (result.trucksWithGpsLocation.length > 0) {
                        result.trucksWithGpsLocation.map((item) => {
                            if (!["WHEELSEYE", "VOLVOEICHER"].includes(item.actual_lspuser)) {
                                trucksWithGpsLocation.push(item)
                            }
                        })
                    }
                }
                console.log(trucksWithGpsLocation, "1729")
                let trucksWithGpsLocationCount = trucksWithGpsLocation.length;
                var ontripwithgps = (result.ontripwithgps) ? result.ontripwithgps : [];

                let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
                    if (truck.timestamp) {
                        let result = false;
                        let consignmentDate = truck.timestamp;
                        let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
                        let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);

                        // console.log("consignmentDateOnly ", consignmentDateOnly)
                        // console.log("todayDateOnly ", todayDateOnly)
                        var s1 = moment.parseZone(consignmentDateOnly).format("x")
                        var s2 = moment.parseZone(todayDateOnly).format("x")
                        // if (consignmentDateOnly !== todayDateOnly) {
                        // 	result = true;
                        // }

                        // console.log("consignmentDateOnly s1", s1)
                        // 	console.log("todayDateOnly s1", s2)
                        // if(s1<s2)
                        // {
                        result = true;
                        //}
                        return result;

                    } else {
                        //console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
                    }
                });

                //console.log("gpsDataNotReportedToday")
                let gpsDataNotReportedTodayCount = (trucksWithGpsLocation) ? trucksWithGpsLocation.length : 0;
                let trucksWithGpsDataCount = (trucksWithGpsLocationCount && gpsDataNotReportedTodayCount) ? trucksWithGpsLocationCount - gpsDataNotReportedTodayCount : 0;


                // let gpsDataNotReportedTodayOnTripTrucks = [];
                // if (this.state.uniqueActiveConsignments && gpsDataNotReportedToday) {
                // 	gpsDataNotReportedTodayOnTripTrucks = this.state.uniqueActiveConsignments.filter(consignment => {
                // 		gpsDataNotReportedToday.forEach(item => {
                // 			return consignment['truck_no'] == item.truck_no;
                // 		})
                // 	})
                // }
                // console.log("dealersdata ", result.dealersdata)

                this.setState({
                    trucklocations: trucksWithGpsLocation.length,
                    ontripwithgpsdata: ontripwithgps,
                    ontripwithgps: ontripwithgps.length,
                    trucksWithGpsLocation: trucksWithGpsLocation,
                    loadshow: 'show-n',
                    atdealertruckcnt: result.totaldealertrucks,
                    dealersdata: result.dealersdata,
                    atdealernogps: result.atdealernogps,
                    alldealertrucks: result.alldealertrucks
                });
                console.log("check1")
                this.onShowGridData();
                // let gpsDataNotReportedToday = []; // for a while
                // this.setState({
                // 	totalActiveConsignments: records,
                // 	gpsNotTodayActiveConsignments: gpsDataNotReportedToday,
                // 	totalActiveConsignmentsCount: records.uniqueTrucks.length,
                // 	gpsNotTodayActiveConsignmentsCount: gpsDataNotReportedToday.length,
                // });
            })
            .catch(function (error) {
                console.log(error);
            });

        redirectURL.post('/consignments/trucksWithNoTransportersDataCount', {}, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        })
            .then((response) => {

                var trucks = JSON.parse(JSON.stringify(response)).data.count;
                // console.log("Truck GPS",trucks);
                this.setState({
                    notrans: trucks
                });


            })
            .catch(function (error) {
                console.log(error);
            });


        /*Truck with gpsdata*/
        redirectURL.post('/consignments/truckWithGPSData', {}, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        })
            .then((response) => {

                var records = JSON.parse(JSON.stringify(response)).data;
                console.log("indexcomponent records = ", records);
                var activewheels = []
                var activeeicher = []
                var recordsarr = []
                var gpsnotreported = []
                var totaltruckgps = []
                var totalactive = []
                var totalinactive = []
                var inactivewheels = []
                var inactiveeicher = []
                console.log("wheels-gpsreported", records.gpsreported)
                console.log("wheels-gpsNotReported", records.gpsNotReported)

                var non_enmovil_trucks = []
                if (records) {
                    var recordsarrr = records.gpsreported
                    var gpsnotreportedd = records.gpsNotReported
                    // uncomment below code after lsp_apiuser has data
                    recordsarrr.map((item) => {

                        if (item.actual_lspuser == "WHEELSEYE") {
                            activewheels.push(item);
                            totalactive.push(item);
                            recordsarr.push(item)
                        }

                        if (item.actual_lspuser == "VOLVOEICHER") {
                            activeeicher.push(item);
                            totalactive.push(item);
                            recordsarr.push(item)
                        }
                    })

                    gpsnotreportedd.map((item) => {

                        if (item.actual_lspuser == "WHEELSEYE") {
                            // console.log(item.truck_no,"line-561")
                            inactivewheels.push(item);
                            totalinactive.push(item);
                            gpsnotreported.push(item)
                        }

                        if (item.actual_lspuser == "VOLVOEICHER") {
                            inactiveeicher.push(item);
                            totalinactive.push(item);
                            gpsnotreported.push(item)
                        }
                    })
                }

                // console.log("totalactive", recordsarr.length, totalactive.length)
                // console.log("totalinactive-567", totalinactive.length)
                this.setState({
                    rowData: recordsarr,
                    gpsnotreported: gpsnotreported,
                    activeeicher: activeeicher,
                    activewheels: activewheels,
                    totalactive: totalactive,
                    inactivewheels: inactivewheels,
                    inactiveeicher: inactiveeicher,
                    totalinactive: totalinactive,
                });

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    onShowGridData = async () => {
        console.log("onShowGridData function call starts")
        this.setState({
            rowwithoutData: ''
        });
        try {
            var totalTrucks = this.state.totalTrucks;
                console.log(totalTrucks, "totalTrucks")
                var trucksWithGpsLocation = this.state.trucksWithGpsLocation;
                var result3 = []
                
            if (totalTrucks.length > 0) {
                if (trucksWithGpsLocation.length > 0) {
                    totalTrucks.map((e) => {
                        if (trucksWithGpsLocation.filter(k => k.truck_no == e.truck_no).length == 0) {
                            result3.push(e)
                        }
                    })
                }
            }

                var inactivewheels = [];
                var inactiveeicher = []
                var totalinactive = []

                if (result3.length > 0) {
                    result3.map((item) => {
                        if (item.actual_lspuser == "WHEELSEYE") {
                            inactivewheels.push(item)
                            // totalinactive.push(item)
                        }
                        else if (item.actual_lspuser == "VOLVOEICHER") {
                            inactiveeicher.push(item)
                            // totalinactive.push(item)
                        }
                    })
                }
                console.log(totalinactive, "totalinactive")
                console.log(result3, "992")
                var ontriprucks = this.state.uniqueActiveConsignments;
                var totalactive = this.state.totalactive;
                // console.log("totalactive981", totalactive)
                console.log("ontriprucks633", ontriprucks)
                var totalinactiveofftrips = [];
                var totalinactiveontrips = [];
                this.state.totalinactive.map((e) => {
                    // console.log("HHERE ", parkdata.filter(k => k.truck_no == e.truck_no))
                    if (ontriprucks.filter(k => k.truck_no == e.truck_no).length == 0) {
                        totalinactiveofftrips.push(e)
                    }
                    if (ontriprucks.filter(k => k.truck_no == e.truck_no).length > 0) {
                        totalinactiveontrips.push(e)
                    }
                })

                var totalactiveofftrips = [];
                var totalactiveontrips = [];
                totalactive.map((e) => {
                    // console.log("HHERE ", parkdata.filter(k => k.truck_no == e.truck_no))
                    if (ontriprucks.filter(k => k.truck_no == e.truck_no).length == 0) {
                        totalactiveofftrips.push(e)
                    }
                    if (ontriprucks.filter(k => k.truck_no == e.truck_no).length > 0) {
                        totalactiveontrips.push(e)
                    }
                })

                console.log(totalactiveontrips,"totalactiveontrips")

                var inactiveaxesontrips = [];
                var inactiveaxesofftrips = [];

                this.state.inactivewheels.map((e) => {
                    // console.log("HHERE ", parkdata.filter(k => k.truck_no == e.truck_no))
                    if (ontriprucks.filter(k => k.truck_no == e.truck_no).length == 0) {
                        inactiveaxesofftrips.push(e)
                    }
                    if (ontriprucks.filter(k => k.truck_no == e.truck_no).length > 0) {
                        inactiveaxesontrips.push(e)
                    }
                })
                var inactiveenmovilontrips = [];
                var inactiveenmovilofftrips = [];

                this.state.inactiveeicher.map((e) => {
                    // console.log("HHERE ", parkdata.filter(k => k.truck_no == e.truck_no))
                    if (ontriprucks.filter(k => k.truck_no == e.truck_no).length == 0) {
                        inactiveenmovilofftrips.push(e)
                    }
                    if (ontriprucks.filter(k => k.truck_no == e.truck_no).length > 0) {
                        inactiveenmovilontrips.push(e)
                    }
                })

                // console.log("notontriptrucks ", notontriptrucks)
                // console.log("inactivewheels",inactivewheels)
                this.setState({
                    rowwithoutData: result3,
                    inactiveeicher: this.state.inactiveeicher,
                    inactivewheels: this.state.inactivewheels,
                    // totalinactive: totalinactive,
                    totalinactiveontrips: totalinactiveontrips,
                    totalinactiveofftrips: totalinactiveofftrips,
                    totalactiveontrips: totalactiveontrips,
                    totalactiveofftrips: totalactiveofftrips,
                    inactiveaxesontrips: inactiveaxesontrips,
                    inactiveenmovilontrips: inactiveenmovilontrips,
                    inactiveaxesofftrips: inactiveaxesofftrips,
                    inactiveenmovilofftrips: inactiveenmovilofftrips
                });
            

            if (this.props.path != "/raildefault") {
                var uniqueActiveConsignments = this.state.uniqueActiveConsignments
                var ontripwithgpsdata = this.state.ontripwithgpsdata

                var ontripnoresult = await uniqueActiveConsignments.filter(f =>
                    !ontripwithgpsdata.some(d => d.truck_no == f.truck_no)
                );

                // var primaryontrips = []
                // var secondaryontrips = []
                // if (ontripnoresult.length > 0) {
                //     await ontripnoresult.map((rd) => {
                //         if (rd.consigner_code == "F" || rd.consigner_code == "M"
                //             || rd.consigner_code == "G") {
                //             primaryontrips.push(rd)
                //         }
                //         else {
                //             secondaryontrips.push(rd)
                //         }
                //     })

                //     await this.setState({
                //         gpsNotTodayActiveConsignments: ontripnoresult,
                //         primaryontrips: primaryontrips.length,
                //         secondaryontrips: secondaryontrips.length
                //     })


                // }
                // else {
                //     await this.setState({
                //         gpsNotTodayActiveConsignments: [],
                //         primaryontrips: 0,
                //         secondaryontrips: 0
                //     })
                // }
                //console.log("onshow ontripnoresult ", ontripnoresult.length)
            }

            var alldealertrucks = this.state.alldealertrucks
            var atdealernogps = this.state.atdealernogps

            var atdealernogpsNA = await alldealertrucks.filter(f =>
                atdealernogps.some(d => d.truck_no == f.truck_no)
            );
            await this.setState({
                rowdataatdealer: atdealernogpsNA
            })
            //console.log("atdealernogpsNA ", atdealernogpsNA)
        }
        catch (e) {

        }
        //console.log("trucksWithGpsLocation ",this.state.trucksWithGpsLocation)
        //console.log("uniqueActiveConsignments ",this.state.totalTrucks)
        // var difftrucks = []
        // var result =await totalTrucks.filter(f =>
        // 	!trucksWithGpsLocation.some(d => d.truck_no == f.truck_no)
        //   );
        // //console.log("difftrucks ", result)
        // if(result.length > 0)
        // {

        // 	await this.setState({
        // 		rowwithoutData:result
        // 	});
        // }
        // else
        // {
        // 	await this.setState({
        // 		rowwithoutData:[]
        // 	});
        // }


    }

    /*Truck Sidebar Component Starts*/
    async onShowSidebarAquireComponent(e) {
        this.setState({
            sliderTranslate: ""
        });
        //console.log(e);
        if (e.colDef.field == 'truck_no') {
            let ComponentInfo = ComponentIndex['truck'];
            //console.log("ComponentInfo", ComponentInfo);
            var layoutdata1 = {
                truck_no: e.data[e.colDef.field]
            };
            //console.log("layoutdata",layoutdata);
            await redirectURL.post('/masters/truckByNo', layoutdata1)
                .then((response) => {
                    //console.log("trucks response", response);
                    var truckrecords = JSON.parse(JSON.stringify(response)).data;

                    this.setState({
                        truckInfo: truckrecords
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*Trucks details by Truck No*/
            await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
                .then((response) => {
                    //console.log(response);
                    var consignrecords = JSON.parse(JSON.stringify(response)).data;

                    this.setState({
                        consignments: consignrecords
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*Trucks details by Truck No*/
            await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
                .then((response) => {
                    //console.log("HR55W4880",response);
                    var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;

                    this.setState({
                        currenttrucklocation: currenttrucklocation
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
            //console.log("currenttrucklocation",this.state.currenttrucklocation);
            //setTimeout(function(){})
            this.displayData = await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo} consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);

            this.setState({
                showdata: this.displayData,
                sliderTranslate: "slider-translate"
            });
        }

        /*Transporters Sidebar*/

        if (e.colDef.field == 'transporter_name') {
            let ComponentInfo = ComponentIndex['transporter'];
            //console.log("ComponentInfo", ComponentInfo);
            var layoutdata1 = {
                transporter_name: e.data[e.colDef.field]
            };
            //console.log("layoutdata",layoutdata);
            await redirectURL.post('/masters/transporterByName', layoutdata1)
                .then((response) => {
                    //console.log("transporterByName response", response);
                    var transporterrecords = JSON.parse(JSON.stringify(response)).data;
                    var deptcodes = [];
                    var transportName = '';
                    var transportCode = '';
                    transporterrecords.map(item => {
                        transportName = item.transporter_name;
                        transportCode = item.transporter_code;
                        deptcodes.push(item.dept_code + ", ");
                    });
                    //console.log(transportName+","+transportCode+","+deptcodes)
                    var transportData = [{
                        transporter_name: transportName,
                        transporter_code: transportCode,
                        dept_code: deptcodes
                    }]
                    this.setState({
                        transporterInfo: transportData
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*Total Trucks Operating*/
            await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
                .then((response) => {
                    //console.log("Total Trucks Operating response", response);
                    this.setState({
                        truckoperates: response
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*Total Trucks With GPS Integration Lifetime*/
            await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
                .then((response) => {
                    //console.log("Total Trucks Operating response", response);
                    this.setState({
                        truckgpslifetime: response
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*Total Trucks With GPS Integration Today*/
            await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
                .then((response) => {
                    //console.log("Total Trucks Operating response", response);
                    this.setState({
                        gpstoday: response
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*Transporters Consignment details*/
            await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
                .then((response) => {
                    //console.log(response);
                    var consignrecords = JSON.parse(JSON.stringify(response)).data;
                    var consignrecordslist = [];
                    consignrecords.map(item => {
                        if (item.consigns.length > 0) {
                            item.consigns.map(cons => {
                                consignrecordslist.push(cons)
                            })

                        }
                    });

                    //console.log("consignrecords list", consignrecordslist);
                    this.setState({
                        consignments: consignrecordslist
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });


            this.displayData = await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);

            this.setState({
                showdata: this.displayData,
                sliderTranslate: "slider-translate"
            });
        }
        /*End*/

        /*LSP Sidebar*/

        if (e.colDef.field == 'actual_lspuser') {
            let ComponentInfo = ComponentIndex['lsp'];
            //console.log("ComponentInfo", ComponentInfo);
            var layoutdata2 = {
                actual_lspuser: e.data[e.colDef.field]
            };
            // console.log("layoutdata2", layoutdata2);
            //Total Trucks Integrate;
            await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
                .then((response) => {
                    var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
                    //console.log("LSP response serveeeee", lsprecords);

                    var result = lsprecords.reduce(function (sum, item) {
                        return sum = sum + item.count;
                    }, 0);
                    //console.log("result", result);
                    this.setState({
                        lspInfo: result
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*Department Serving*/
            await redirectURL.post('/masters/totalDeptServe', layoutdata2)
                .then((response) => {
                    var deprecords = JSON.parse(JSON.stringify(response)).data;
                    //console.log("LSP DeptServe response", deprecords);

                    var deptcnt = [];
                    deprecords.map(item => {
                        deptcnt.push(item._id)
                    });
                    this.setState({
                        deptServes: deptcnt.length
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*No Of Transporters Serving*/
            await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
                .then((response) => {
                    var transrecords = JSON.parse(JSON.stringify(response)).data;
                    //console.log("Transporter serve response", transrecords);
                    var trasportcnt = [];
                    transrecords.map(item => {
                        trasportcnt.push(item._id)
                    });
                    this.setState({
                        transrecordserves: trasportcnt.length
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*No Of GPS DATA Send Today*/
            await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
                .then((response) => {
                    var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
                    //console.log("GPS Send Data response", gpssendrecords);

                    this.setState({
                        gpssendtoday: gpssendrecords.length
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            /*Total Trucks with no transporter data*/
            // await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
            // .then((response) => {
            // 	var notransrecords = JSON.parse(JSON.stringify(response)).data;
            // 	//console.log("No Trasnporter Data response", notransrecords);

            // 	this.setState({
            // 		nopetransporters:notransrecords.length
            // 	});
            // })
            // .catch(function (error) {
            //	console.log("trucks with no transporter","trucks");
            // });



            this.displayData = await (<ComponentInfo context={this}
                // nopetransporters={this.state.nopetransporters}
                gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);

            this.setState({
                showdata: this.displayData,
                sliderTranslate: "slider-translate"
            });
        }
        /*End*/
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        //this.gridApi.sizeColumnsToFit();
    }
    /*Truck Sidebar Component End*/
    onCloseUploadDiv = () => {
        this.setState({
            sliderTranslate: "",
            sliderTrucksTranslate: "",
            truckrowData: null,
            sidebartitle: "",
            overly: "show-n"
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            sliderTranslate: "",
            sliderTrucksTranslate: "",
            truckrowData: null,
            sidebartitle: "",
            overly: "show-n"
        });

    }

    onClickShowTab = async (tab) => {
        await this.setState({
            defaulttab: tab
        });
        await this.filterByDepartment()
    }
    onClickShowRailTab = async (tab) => {
        await this.setState({
            defaulttab: tab
        });
        await this.filterByDepartment()
    }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onClickCounterShowData(params) {
        //console.log("params ", params)
        var ontrips = this.state.allontriptrucks;
        if (params == "axesinactivetrucks") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.inactivewheels.length > 0) {
                this.state.inactivewheels.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "WHEELSEYE Inactive Trucks"
            })
        }
        if (params == "enmoilinactivetrucks") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.inactiveeicher.length > 0) {
                this.state.inactiveeicher.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "VOLVOEICHER Inactive Trucks"
            })

        }
        if (params == "totaxes") {
            var j1 = this.state.activewheels;
            var j2 = this.state.inactivewheels;
            var jsondata = j1.concat(j2);
            var rows = []
            // if (ontrips.length > 0) {
            if (jsondata.length > 0) {
                jsondata.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            // console.log("jsondata " ,jsondata)
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "WHEELSEYE - Total Trucks"
            })

        }
        if (params == "actaxes") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.activewheels.length > 0) {
                this.state.activewheels.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "WHEELSEYE - Total Active Trucks"
            })

        }

        if (params == "totenv") {
            var j1 = this.state.activeeicher;
            var j2 = this.state.inactiveeicher;
            var jsondata = j1.concat(j2);
            // console.log("jsondata " ,jsondata)
            var rows = []
            // if (ontrips.length > 0) {
            if (jsondata.length > 0) {
                jsondata.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Enomovil - Total Trucks"
            })

        }
        if (params == "actenv") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.activeeicher.length > 0) {
                this.state.activeeicher.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "VOLVOEICHER - Total Active Trucks"
            })

        }

        if (params == "0") {
            var j1 = this.state.totalactive;
            var j2 = this.state.totalinactive;
            var jsondata = j1.concat(j2);
            console.log("jsondata_1618 ", jsondata)
            var rows = []
            // if (ontrips.length > 0) {
            if (jsondata.length > 0) {
                jsondata.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            console.log(rows, "rows_1729")
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Trucks With GPS Installed"
            })

        }

        if (params == "1") {
            var j1 = this.state.totalactiveontrips;
            var j2 = this.state.totalinactiveontrips;
            var jsondata = j1.concat(j2);
            // console.log("jsondata Ontrips " ,jsondata)
            var rows = []
            // if (ontrips.length > 0) {
            if (jsondata.length > 0) {
                jsondata.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Trucks With GPS Installed (Ontrips)"
            })

        }
        if (params == "2") {
            var j1 = this.state.totalactiveofftrips;
            var j2 = this.state.totalinactiveofftrips;
            var jsondata = j1.concat(j2);
            var rows = []
            // if (ontrips.length > 0) {
            if (jsondata.length > 0) {
                jsondata.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Trucks With GPS Installed (Off Trips)"
            })

        }

        if (params == "3") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.totalactive.length > 0) {
                this.state.totalactive.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Active Trucks"
            })

        }

        if (params == "4") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.totalactiveontrips.length > 0) {
                this.state.totalactiveontrips.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Active Trucks (Ontrips)"
            })

        }

        if (params == "5") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.totalactiveofftrips.length > 0) {
                this.state.totalactiveofftrips.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Active Trucks (Off Trips)"
            })
        }

        if (params == "6") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.totalinactive.length > 0) {
                this.state.totalinactive.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Inactive Trucks"
            })

        }

        if (params == "7") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.totalinactiveontrips.length > 0) {
                this.state.totalinactiveontrips.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Inactive Trucks (Ontrips)"
            })

        }

        if (params == "8") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.totalinactiveofftrips.length > 0) {
                this.state.totalinactiveofftrips.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "Total Inactive Trucks (Off Trips)"
            })
        }

        if (params == "9") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.dealersdata.length > 0) {
                this.state.dealersdata.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "At Dealers Trucks"
            })
        }
        if (params == "10") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.inactiveaxesontrips.length > 0) {
                this.state.inactiveaxesontrips.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "WHEELSEYE - Total Inactive Trucks (On Trips)"
            })
        }
        if (params == "11") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.inactiveaxesofftrips.length > 0) {
                this.state.inactiveaxesofftrips.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "WHEELSEYE - Total Inactive Trucks (Off Trips)"
            })
        }

        if (params == "12") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.inactiveenmovilontrips.length > 0) {
                this.state.inactiveenmovilontrips.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "VOLVOEICHER - Total Inactive Trucks (On Trips)"
            })
        }
        if (params == "13") {
            var rows = []
            // if (ontrips.length > 0) {
            if (this.state.inactiveenmovilofftrips.length > 0) {
                this.state.inactiveenmovilofftrips.map((item) => {
                    if (ontrips.includes(item.truck_no)) {
                        item.trip_type = "On Trip";
                    }
                    else {
                        item.trip_type = "Off Trip";
                    }
                    rows.push(item);
                })
                // }
            }
            this.setState({
                truckrowData: rows,
                overly: "show-m",
                sliderTrucksTranslate: "slider-translate-60p",
                sidebartitle: "VOLVOEICHER - Total Inactive Trucks (Off Trips)"
            })
        }

    }
    render() {
        const statedeptcode = this.state.deptvalue;
        //console.log("statedeptcode ",statedeptcode)
        var columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 200,
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 200,

            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,

            },
            // 	{
            // 	headerName: "Department Code",
            // 	field: "dept_code",
            // 	width: 200,

            //   },
            {
                headerName: "GPS Provider",
                field: "actual_lspuser",
                width: 200,

            },
            {
                headerName: "Last Known City/Town",
                field: "area",
                width: 200,
            },
            {
                headerName: "Last Known State",
                field: "state",
                width: 200,
            },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 200,
                type: "datetime",

            },
            {
                headerName: "Data Received On",
                field: "modified_date",
                width: 200,
                type: "datetime",
            }


        ];
        var columnwithoutDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 200,

            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 200,

            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,

            },
            // 	{
            // 	headerName: "Department Code",
            // 	field: "dept_code",
            // 	width: 200,

            //   },
            {
                headerName: "GPS Provider",
                field: "actual_lspuser",
                width: 200,

            },
            {
                headerName: "Last Known City/Town",
                field: "area",
                width: 200,
            },
            {
                headerName: "Last Known State",
                field: "state",
                width: 200,
            },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 200,
                type:"datetime",

            },
            {
                headerName: "Data Received On",
                field: "modified_date",
                width: 200,
                type:"datetime",
            }

        ];
        var columnGpsNotTodayDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 200,

            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 140,

            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,
            },

            {
                headerName: "Plant Code",
                field: "consigner_code",
                width: 200,

            },

            {
                headerName: "Consignee City",
                field: "consignee_city",
                width: 140
            },
            {
                headerName: "Consignee State",
                field: "consignee_state",
                width: 140
            },

            //   {
            // 	headerName: "Cluster",
            // 	field: "cluster",
            // 	width: 140
            //   },
            // {
            // 	headerName: "Department Code",
            // 	field: "dept_code",
            // 	width: 200,

            //   },
            {
                headerName: "GPS Provider",
                field: "actual_lspuser",
                width: 200,
            },
            {
                headerName: "Last Known City/Town",
                field: "area",
                width: 200,
            },
            {
                headerName: "Last Known State",
                field: "state",
                width: 200,
            },
            // {
            //   headerName: "Last Packet Time",
            //   field: "timestamp",
            //   width: 200,
            //   //cellRenderer:'dateFormater'
            //   valueGetter:function(params){
            // 	return getDDMMYYYYHHMMSS(params.data.timestamp)
            //   }
            // },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 200,
                type:"datetime",

            },
            {
                headerName: "Data Received On",
                field: "modified_date",
                width: 200,
                type:"datetime",
            },
            // {
            // 	headerName: "Data Received On",
            // 	field: "modified_date",
            // 	width: 200,
            // 	//cellRenderer:'dateFormater'
            // 	valueGetter:function(params){
            // 		return getDDMMYYYYHHMMSS(params.data.modified_date)
            // 	  }
            //   }

        ];
        var columngpspacketDefs = [
            {
                headerName: "GPS Provider",
                field: "_id",
                width: 200,


            },
            {
                headerName: "< 3 Minutes",
                field: "less_180",
                width: 200,

            },
            {
                headerName: "Between 3 to 5 Minutes",
                field: "180_300",
                width: 200,

            },
            {
                headerName: "Between 5 to 10 Minutes",
                field: "300_600",
                width: 200,

            },
            {
                headerName: "Between 10 to 15 Minutes",
                field: "600_900",
                width: 200,

            },
            {
                headerName: "More than 15 Minutes",
                field: "greater_900",
                width: 120,

            }

        ];

        var dealerGpsNotTodayDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 200,

            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 200,

            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,

            },

            {
                headerName: "Plant Code",
                field: "consigner_code",
                width: 200
            },

            {
                headerName: "Consignee City",
                field: "consignee_city",
                width: 140
            },
            {
                headerName: "Consignee State",
                field: "consignee_state",
                width: 140
            },

            //   {
            // 	headerName: "Cluster",
            // 	field: "cluster",
            // 	width: 140
            //   },
            // {
            // 	headerName: "Department Code",
            // 	field: "dept_code",
            // 	width: 200,

            //   },
            {
                headerName: "GPS Provider",
                field: "actual_lspuser",
                width: 200,

            },
            {
                headerName: "Last Known City/Town",
                field: "area",
                width: 200,

            },
            {
                headerName: "Last Known State",
                field: "state",
                width: 200,

            },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 200,
                type:"datetime",

            },
            {
                headerName: "Data Received On",
                field: "modified_date",
                width: 200,
                type:"datetime",

            },

        ];

        let order_cnt = [];

        rendJsonData(this.state.loadsData);

        //console.log("Default",localStorage.getItem('token'));
        const dStyles = {
            width: '100%',
            height: '100%'
        }
        const highchartoptions = {
            chart: {
                type: 'column'
            },
            plotOptions: {
                column: {
                    colorByPoint: true
                },
                series: {
                    maxPointWidth: 20
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: this.state.transaxis
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    },
                    colorByPoint: true

                },
            },
            series: [
                {
                    name: "With GPS",
                    data: this.state.transyaxis
                }
            ],
            colors: ['#f15c80', '#92A8CD', '#A47D7C', '#B5CA92', '#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            credits: false,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: '100%'
                    },
                    chartOptions: {
                        chart: {
                            height: '100%'
                        },
                        subtitle: {
                            text: null
                        },
                        navigator: {
                            enabled: false
                        }
                    }
                }]
            }
        };

        const transporterNoGPSChart = {
            chart: {
                type: 'column'
            },
            plotOptions: {
                column: {
                    colorByPoint: true
                },
                series: {
                    maxPointWidth: 20
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: this.state.trans_no_gps_axis
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    },
                    colorByPoint: true

                },
            },
            series: [
                {
                    name: "Without GPS",
                    data: this.state.trans_no_gps_yaxis
                }
            ],
            colors: ['#f15c80', '#92A8CD', '#A47D7C', '#B5CA92', '#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            credits: false,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: '100%'
                    },
                    chartOptions: {
                        chart: {
                            height: '100%'
                        },
                        subtitle: {
                            text: null
                        },
                        navigator: {
                            enabled: false
                        }
                    }
                }]
            }
        };

        const lsphighchartoptions = {
            chart: {
                type: 'column'
            },
            plotOptions: {
                column: {
                    colorByPoint: true
                },
                series: {
                    maxPointWidth: 20
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: this.state.lspaxis
            },
            series: [
                {
                    data: this.state.lspyaxis
                    //data: [64,21,33,42,52]
                }
            ],
            colors: ['#f15c80', '#92A8CD', '#A47D7C', '#B5CA92', '#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            credits: false,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: '100%'
                    },
                    chartOptions: {
                        chart: {
                            height: '100%'
                        },
                        subtitle: {
                            text: null
                        },
                        navigator: {
                            enabled: false
                        }
                    }
                }]
            }
        };

        const lspNoGPShighchartoptions = {
            chart: {
                type: 'column'
            },
            plotOptions: {
                column: {
                    colorByPoint: true
                },
                series: {
                    maxPointWidth: 20
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: this.state.lsp_no_gps_axis
            },
            series: [
                {
                    data: this.state.lsp_no_gps_yaxis
                    //data: [64,21,33,42,52]
                }
            ],
            colors: ['#f15c80', '#92A8CD', '#A47D7C', '#B5CA92', '#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            credits: false,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: '100%'
                    },
                    chartOptions: {
                        chart: {
                            height: '100%'
                        },
                        subtitle: {
                            text: null
                        },
                        navigator: {
                            enabled: false
                        }
                    }
                }]
            }
        };

        const highchartoptions1 = {
            chart: {
                type: 'pie'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true,
                    dataLabels: {
                        enabled: true,
                        format: '{point.x:,.0f}'
                    }
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: this.state.deptaxis
            },
            legend: {
                enabled: true,
                labelFormatter: function () {

                    //console.log("test",this.series)

                    var legendIndex = this.index;
                    var legendName = this.series.chart.axes[0].categories[legendIndex];

                    return legendName;
                }
            },
            series: [
                {
                    data: this.state.deptyaxis
                    //data: [64,21,33,42,52]
                }
            ],
            colors: ['#f15c80', '#92A8CD', '#A47D7C', '#B5CA92', '#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            credits: false,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: '100%'
                    },
                    chartOptions: {
                        chart: {
                            height: '100%'
                        },
                        subtitle: {
                            text: null
                        },
                        navigator: {
                            enabled: true
                        }
                    }
                }]
            }
        };

        const highchartgpsinterval = {
            chart: {
                type: 'column'
            },
            title: {
                text: ' '
            },
            xAxis: {
                categories: this.state.gpsintervalaxis
            },
            yAxis: {
                min: 0,

                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: this.state.gpsintervalyaxis
        };

        var israil = (this.props.path == "/raildefault") ? 1 : 0

        const { usermanualmodal } = this.state;
        const modalStyles = {
            width: '1300px !important',
        }

        var truckcolumnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 200,


            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 200,

            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,

            },
            {
                headerName: "GPS Provider",
                field: "actual_lspuser",
                width: 200,

            },
            {
                headerName: "Last Known City/Town",
                field: "area",
                width: 200,
            },
            {
                headerName: "Last Known State",
                field: "state",
                width: 200,
            },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 200,
                type:"datetime",

            },
            {
                headerName: "Data Received On",
                field: "modified_date",
                width: 200,
                type:"datetime",
            },
            {
                headerName: "Trip Type",
                field: "trip_type",
                width: 100
            }


        ];
        return (
            <div >
                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    {/* {(this.state.deptvalue == 'SNDG') ?
						<div className="row col-xl-12 col-lg-12">
							<div className="row col-xl-12 col-lg-12">
								<div className="col-xl-6 col-lg-6"></div>
								<div className="row col-xl-6 col-lg-6">
									<div className="col-xl-7 col-lg-7 f12" style={{ textAlign: "right" }}>Master Data Updated: </div>
									<div className="col-xl-5 col-lg-5 f12">Primary - 16-Aug-2021<br /> Secondary - 26-Apr-2021</div>
								</div>
								<div className="col-xl-8 col-lg-8"></div>
								<div className="row col-xl-4 col-lg-4 f12 n-p-0">
									<span style={{ fontWeight: "bold" }}>Note:&nbsp;</span> Any new trucks taking loads are automatically <br />added to the master data
								</div>
							</div>
						</div>
						: ""} */}

                    <div style={{ marginBottom: '10px' }} className="row">
                        {(this.state.currentDepartmentTitle == 'default') ?

                            <div className="col-xl-12 col-lg-12">
                                <div className="card">

                                    <div className="card-header">
                                        <h5>
                                            <span>Dashboard  </span>
                                        </h5>
                                    </div>

                                </div>
                            </div>
                            : ""}
                        {(this.state.deptvalue == 'SNDG') ?

                            <div className="col-xl-12 col-lg-12">

                                {/* <ul className="d-tabs">
									<li>
										<button className={"btn btn-default " + (this.state.defaulttab == "all" ? "activet" : "")} onClick={this.onClickShowTab.bind(this, 'all')}>All</button>
									</li>
									<li>
										<button className={"btn btn-default " + (this.state.defaulttab == "primary" ? "activet" : "")} onClick={this.onClickShowTab.bind(this, 'primary')}>Primary</button>
									</li>
									<li>
										<button className={"btn btn-default " + (this.state.defaulttab == "secondary" ? "activet" : "")} onClick={this.onClickShowTab.bind(this, 'secondary')}>Secondary</button>
									</li>
								</ul> */}

                            </div>
                            : ""}
                        {(this.props.path == "/raildefault") ?
                            <div className="col-xl-12 col-lg-12">

                                <ul className="d-tabs">
                                    <li>
                                        <button className={"btn btn-default " + (this.state.defaulttab == "all" ? "activet" : "")} onClick={this.onClickShowRailTab.bind(this, 'all')}>All</button>
                                    </li>
                                    <li>
                                        <button className={"btn btn-default " + (this.state.defaulttab == "firstmile" ? "activet" : "")} onClick={this.onClickShowRailTab.bind(this, 'firstmile')}>First Mile</button>
                                    </li>
                                    <li>
                                        <button className={"btn btn-default " + (this.state.defaulttab == "rakes" ? "activet" : "")} onClick={this.onClickShowRailTab.bind(this, 'rakes')}>Rakes</button>
                                    </li>
                                    <li>
                                        <button className={"btn btn-default " + (this.state.defaulttab == "lastmile" ? "activet" : "")} onClick={this.onClickShowRailTab.bind(this, 'lastmile')}>Last Mile</button>
                                    </li>
                                </ul>

                            </div>
                            : ""}
                        {/* <a style={{color:'#333'}} href="javascript:void(0)" className="filernow_">
                			<img style={{marginTop:'10px',float:"left",marginLeft:"5px"}} src={require("../../../assets/icons/funnel.png")} className="filernow_" />
                		    <span style={{marginTop:'8px',marginLeft:"5px",float:"left"}}>Filtering</span>
                	    </a>
                		<div className="col-xl-5 col-lg-5 col-sm-5 row">
                			<div className="col-xl-8 col-lg-8 col-sm-8" style={{paddingRight:'0px'}}>
                				<select refs="deptwise" className="form-control" onChange={this.handleChange.bind(this)}>
                					{this.createSelectItems()}
                				</select>
                			</div>
                			<div className="col-xl-2 col-lg-2 col-sm-2" style={{padding:"0"}}>
                				<button name="go" id="go"style={{height:"38px"}} className="btn btn-primary" onClick={this.filterByDepartment.bind(this)}>Go</button>

                			</div>
                		</div> */}
                    </div>


                    {/*
                	<div className="flitrblock shwfilt" id="filterblock">
                	</div>
                */}

                    {/*
					<BoxRowOne tottrucks={this.state.truckscnt} truckgps={this.state.trucklocations} notruckgps={this.state.notrans} truckno={this.state.trucknogps} />
					 */}

                    <DashboardCountersComponent
                        tottrucks={this.state.truckscnt}
                        truckgps={this.state.trucklocations}
                        notruckgps={this.state.notrans}
                        totalActiveConsignmentsCount={this.state.totalActiveConsignmentsCount}
                        gpsNotTodayActiveConsignmentsCount={this.state.gpsNotTodayActiveConsignmentsCount}
                        truckno={this.state.trucknogps}
                        gpsnotreport={this.state.rowwithoutData.length}
                        ontripwithgps={this.state.ontripwithgps}
                        israil={israil}
                        context={this}
                        ontripgpsna={this.state.ontripgpsna}
                        atdealertruckcnt={this.state.atdealertruckcnt}
                        atdealernogps={this.state.atdealernogps.length}
                        primaryontrips={this.state.primaryontrips}
                        secondaryontrips={this.state.secondaryontrips}
                        deptcode={this.state.deptvalue}
                        activewheels={this.state.activewheels.length}
                        activeeicher={this.state.activeeicher.length}
                        inactivewheels={this.state.inactivewheels.length}
                        inactiveeicher={this.state.inactiveeicher.length}

                        totalactive={this.state.totalactive.length}
                        totalinactive={this.state.totalinactive.length}
                        totaltruckgpsontrips={this.state.inactivewheels.length}
                        totaltruckgpsofftrips={this.state.inactivewheels.length}
                        totalactiveontrips={this.state.totalactiveontrips.length}
                        totalactiveofftrips={this.state.totalactiveofftrips.length}
                        totalinactiveontrips={this.state.totalinactiveontrips.length}
                        totalinactiveofftrips={this.state.totalinactiveofftrips.length}

                        inactiveaxesontrips={this.state.inactiveaxesontrips.length}
                        inactiveenmovilontrips={this.state.inactiveenmovilontrips.length}
                        inactiveaxesofftrips={this.state.inactiveaxesofftrips.length}
                        inactiveenmovilofftrips={this.state.inactiveenmovilofftrips.length}

                    />
                    <div className="row">
                        <div className="col-xl-6 col-lg-12">
                            <div className="card height-equal equal-height-lg">
                                <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>Trucks with GPS Data</h5>
                                </div>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div id="myGrid" style={{ height: "550px", width: "100%" }} className="ag-theme-balham">
                                        <AgGrid
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={false}
                                            enableRangeSelection={true}
                                            statusBar={this.state.statusBar}
                                            pagination={false}
                                            groupHeaderHeight={40}
                                            headerHeight={40}
                                            floatingFiltersHeight={50}
                                            pivotGroupHeaderHeight={30}
                                            pivotHeaderHeight={50}
                                            rowHeight={40}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                        //sideBar={this.state.sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>




                        <div className="col-xl-6 col-lg-12">
                            <div className="card height-equal equal-height-lg">
                                <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>GPS Data Not Reported Last 24 Hours</h5>
                                </div>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div id="myGrid" style={{ height: "550px", width: "100%" }} className="ag-theme-balham">
                                        <AgGrid
                                            columnDefs={columnwithoutDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.gpsnotreported}
                                            enableCharts={false}
                                            enableRangeSelection={true}
                                            statusBar={this.state.statusBar}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            frameworkComponents={this.state.frameworkComponents}
                                            pagination={false}
                                            groupHeaderHeight={40}
                                            headerHeight={40}
                                            floatingFiltersHeight={50}
                                            pivotGroupHeaderHeight={30}
                                            pivotHeaderHeight={50}
                                            rowHeight={40}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                        //sideBar={this.state.sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>



                        <div className="col-xl-6 col-lg-12">
                            <div className="card height-equal equal-height-lg">
                                <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>GPS Data Not Reported Last 24 Hours (On Trip Trucks)</h5>
                                </div>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div id="myGrid" style={{ height: "550px", width: "100%" }} className="ag-theme-balham">
                                        <AgGrid
                                            columnDefs={columnGpsNotTodayDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.gpsNotTodayActiveConsignments}
                                            enableCharts={false}
                                            enableRangeSelection={true}
                                            statusBar={this.state.statusBar}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            frameworkComponents={this.state.frameworkComponents}
                                            pagination={false}
                                            groupHeaderHeight={40}
                                            headerHeight={40}
                                            floatingFiltersHeight={50}
                                            pivotGroupHeaderHeight={30}
                                            pivotHeaderHeight={50}
                                            rowHeight={40}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                        //sideBar={this.state.sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="col-xl-6 col-lg-12">
                            <div className="card height-equal equal-height-lg">
                                <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>GPS Data Not Reported Last 24 Hours (At Dealer)</h5>
                                </div>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div id="myGrid" style={{ height: "550px", width: "100%" }} className="ag-theme-balham">
                                        <AgGrid
                                            columnDefs={dealerGpsNotTodayDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            // rowData={this.state.rowdataatdealer}
                                            rowData={this.state.dealerRecords}
                                            enableCharts={false}
                                            enableRangeSelection={true}
                                            statusBar={this.state.statusBar}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            frameworkComponents={this.state.frameworkComponents}
                                            pagination={false}
                                            groupHeaderHeight={40}
                                            headerHeight={40}
                                            floatingFiltersHeight={50}
                                            pivotGroupHeaderHeight={30}
                                            pivotHeaderHeight={50}
                                            rowHeight={40}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                        //sideBar={this.state.sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>




                        <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                        </div>
                    </div>

                    <div className={"slide-r " + (this.state.sliderTranslate)} >

                        {this.displayData}
                    </div>


                </div>
                {/*Container-fluid Ends*/}

                <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                        <h5 className="model-title">GPS Integratin</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                GPS integration report comprises of below mentioned reports
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        a. GPS integration status:
                                        <ul>
                                            <li>
                                                <div className="col-xl-12 col-lg-12">
                                                    This screen provides data of overall trucks that are present in system for that particular department and its current integration status regarding GPS data
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    Image here
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    This screen provides analytics related to GPS data vehicle wise. This screen shows data in four grids.  The details are present in all four analytics in below mentioned columns
                                                    <table className="table table-border">
                                                        <tr>
                                                            <th>
                                                                Column
                                                            </th>
                                                            <th>
                                                                Remarks
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Truck No
                                                            </td>
                                                            <td>
                                                                Truck number for which details are provided
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Transporter Code
                                                            </td>
                                                            <td>
                                                                Transporter code provided by MSIL
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Transporter Name
                                                            </td>
                                                            <td>
                                                                Transporter Name
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Department Code
                                                            </td>
                                                            <td>
                                                                Department details
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Actual GPS Provider
                                                            </td>
                                                            <td>
                                                                GPS service provider
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Last Known City/Town
                                                            </td>
                                                            <td>
                                                                Last known Location as per GPS data
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Last Known State
                                                            </td>
                                                            <td>
                                                                Last known State as per GPS data
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Last Packet Time
                                                            </td>
                                                            <td>
                                                                packet date and time sent by GPS provider
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Data Received On
                                                            </td>
                                                            <td>
                                                                Data packet received from service provider
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    <ul>
                                                        <li>
                                                            Trucks with GPS Data
                                                        </li>
                                                        <li>
                                                            GPS data not reported last 24 hours
                                                        </li>
                                                        <li>
                                                            GPS data not reported in last 24 hours (On trip trucks)
                                                        </li>
                                                        <li>
                                                            GPS data not reported in last 24 hours (At dealer)
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    For SND department data is available in two sections primary trips and Secondary trips. The section is divided based on movements which are done from plants for primary and from TVP for Secondary
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        b. Truck GPS data:
                                        <ul>
                                            <li>
                                                <div className="col-xl-12 col-lg-12">
                                                    This screen helps in tracking individual truck (departmental wise) in a particular time frame. The user just needs to enter the truck number and the time frame for which user wants to know the trail.
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    Image Here
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    Once the data is filled as per required fields, Click on generate button, required details will be displayed
                                                </div>
                                            </li>
                                        </ul>

                                    </li>
                                    <li>
                                        c. Manual GPS integration:
                                        <ul>
                                            <li>
                                                <div className="col-xl-12 col-lg-12">
                                                    This screen helps in adding particular location details for the vehicle which GPS data has not been provided by its respective service provider. If a user is well aware of the location it can be added in system manually by user by using this option. For adding the GPS location the user needs to add details like the vehicle number the location co-ordinates which needs to be added (can be searched from map provided) and the time at which the vehicle was present at that location. Click on save button then the details will be added for the vehicle at that particular time
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    Image Here
                                                </div>

                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </Modal>

                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


                <div className={"slide-r " + (this.state.sliderTrucksTranslate)} >
                    <div className="slide-r-title">
                        <h4>
                            {this.state.sidebartitle}
                            <span
                                className="float-right closebtn"
                                style={{ marginRight: "25px" }}
                                onClick={this.onCloseUploadDiv.bind(this)}
                            >
                                X
                            </span>
                        </h4>
                    </div>

                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div id="myGrid6" style={{ height: "600px", width: "100%" }} className="ag-theme-balham">
                            <AgGrid
                                columnDefs={truckcolumnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.truckrowData}
                                enableCharts={false}
                                enableRangeSelection={true}
                                statusBar={this.state.statusBar}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                frameworkComponents={this.state.frameworkComponents}
                                pagination={false}
                                groupHeaderHeight={40}
                                headerHeight={40}
                                floatingFiltersHeight={50}
                                pivotGroupHeaderHeight={30}
                                pivotHeaderHeight={50}
                                rowHeight={40}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                            //sideBar={this.state.sideBar}
                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                            />
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

function rendJsonData(data) {
    //console.log(data);
    var order_cnt = [];
    order_cnt = data.map((seris) =>
        seris.key
    )
    //console.log(order_cnt)
}

function dateComparator(date1, date2) {
    // console.log("dateComparator1");
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    return date1Number - date2Number;
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 19) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}



export default Default;