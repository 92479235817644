const LoadingAreaLegs = (props) => {
    const handleRouteClick = (e) => {
        console.log("check_here")
        props.onClickShowLoadingAreaLegs(props);
    };

    return (
        <div className="map-icon-component">
            <button onClick={handleRouteClick} className="custom-btn label label-info" title="Consignments">
                <i style={{ color: "#1E73D5" }} className="icofont icofont-site-map f25"></i>
            </button>
        </div>
    );
};

export default LoadingAreaLegs;
