import React, {Component} from 'react';
import Select from 'react-select';
import redirectURL from '../redirectURL';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import Favourites from "../common/favouritesComponent";
import "./reverseAuction.css";

export default class ReverseAuction extends Component {
    constructor(props){
        super(props);
        this.state={
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
				sortable:true
			},
            paginationPageSize:200,
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowPOData:[],
            rowVendorData: [],
            rowBidPreviewData: [],
            rowParticipantsData: [],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            bidSubject: "",
            bidDesc: "",
            bidDate: "",
            currency: {"value": "", "label": "Select"},
            tcDoc: "",
            auctionType: {"value": "", "label": "Select"},
            bidDuration: "",
            maxBidExtCount: {"value": "", "label": "Select"},
            approvers: {"value": "", "label": "Select"},
            conversionRate: "",
            attachmentsDoc: "",
            bidClosingType: {"value": "", "label": "Select"},
            vendorRankDisplay: {"value": "", "label": "Select"},
            maskParticipants: {"value": "", "label": "Select"},
            viewBidDetailsForm: 'show-m',
            viewSetupBidParamForm: 'show-n',
            viewInviteVendorsForm: 'show-n',
            viewPODetailsGrid: 'show-m',
            viewVendorDetailsGrid: 'show-m',
            viewBidPreviewGrid: 'show-m',
            viewParticipantsGrid: 'show-m',
            biddingItem: "",
            bidItemQuantity: "",
            bidItemUOM: {"value": "", "label": ""},
            targetUnitPrice: "",
            targetUnitPriceHideShow: {"value": "", "label": "Select"},
            bidStartPriceHideShow: {"value": "", "label": "Select"},
            bidItemCode: "",
            bidItemRemarks: "",
            minimumDecrement: "",
            minimumDecrementUnit: {"value": "Amount", "label": "Amount"},
            showL1Price: {"value": "Yes", "label": "Yes"},
            poNumber: "",
            unitRate: "",
            searchVendorName: {"value": "", "label": ""},
            poDate: "",
            poValue: "",
            activeStep: 1,
            vendorGroup: {"value": "", "label": ""},
            vendorGroupOptions: [],
            inviteVendorName: {"value": "", "label": ""},
            vendorNameOptions: [],
            finalRemarks: "",
        }
    }

    newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };

    changeTCFileHandler(event){
        this.setState({
            tcDoc: event.target.files[0]
        })
    }

    changeAttachmentsFileHandler(event){
        this.setState({
            attachmentsDoc: event.target.files[0]
        })
    }

    handlerBidDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var biddate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		if (this.state.bidDate != undefined && this.state.bidDate != "") {
			var bidDate = new Date(biddate);
			var bidTime = bidDate.getTime();
            var now = d.getTime()

			if (bidTime < now) {
				this.setState({
					bidDate: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: "Bid Date cannot be before current time"
				});
			}
			else {
				this.setState({
					bidDate: biddate
				});
			}
		}
		else {
			this.setState({
				bidDate: biddate
			});
		}
	}

    handlerPODateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var podate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		// if (this.state.poDate != undefined && this.state.poDate != "") {
			// var poDate = new Date(podate);
			// var poTime = poDate.getTime();
            // var now = d.getTime()

			// if (poTime < now) {
			// 	this.setState({
			// 		poDate: ""
			// 	});
			// 	alert("PO Date cannot be before current time");
			// }
			// else {
				this.setState({
					poDate: podate
				});
			// }
		// }
		// else {
		// 	this.setState({
		// 		poDate: podate
		// 	});
		// }
	}

    handleBidDetailsNextBtnClick(){
        // e.preventDefault();
        this.setState({
            viewBidDetailsForm: "show-n",
            viewSetupBidParamForm: "show-m",
            step1completed: true,
            activeStep: 2,
        })
    }

    handleSetupBidParamsBackBtnClick(){
        this.setState({
            viewBidDetailsForm: "show-m",
            viewSetupBidParamForm: "show-n",
            step2completed: false,
            activeStep: 1,
        })
    }

    handleSetupBidParamsNextBtnClick() {
        this.setState({
            activeStep: 3,
            viewSetupBidParamForm: 'show-n',
            viewInviteVendorsForm: 'show-m'
        })
    }

    handleVendorSearchBtnClick() {

    }

    handleInviteVendorsBackBtnClick() {
        this.setState({
            activeStep: 2,
            viewSetupBidParamForm: 'show-m',
            viewInviteVendorsForm: 'show-n'
        })
    }

    handleBidPreviewBtnClick() {
        this.setState({
            sliderTranslate: "slider-translate-70p",
        })
    }

    handleCancelBidBtnClick() {

    }

    handleAddApproversBtnClick() {

    }

    handleExportExcelBtnClick() {

    }

    handleImportExcelBtnClick() {

    }

    onSubmitConfigurations = (e) => {
        e.preventDefault();
    }

    onCloseBidPreviewSlider = () => {
		this.setState({
			sliderTranslate: ""
		});
	}

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    render(){
		
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
                </SweetAlert>
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="fa fa-gavel cus-i"></i>&nbsp;<span>Bid Details</span>
                        <Favourites />
                    </h5>
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="collg-12 col-md-12">
                                <div className='row justify-content-between card-body'>
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <h1 className="h4 text-gray-800">Setup Bid Parameters</h1>
                                    </div>
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <h1 className="h4 text-gray-800">Invite Participants</h1>
                                    </div>
                                </div>
                                <div className='row mx-5 mb-3 justify-content-between progressbar'>
                                    <div className={`progress ${this.state.activeStep == 2 || this.state.activeStep == 3 ? "progress-step-active" : ""}`} style={this.state.activeStep == 2 ? {width: "47%"} : this.state.activeStep == 3 ? {width: "94%"} : {}}></div>
                                    <div className={`progress-step ${this.state.activeStep == 1 || this.state.activeStep == 2 || this.state.activeStep == 3 ? "progress-step-active" : ""}`}>
                                        
                                    </div>
                                    <div className={`progress-step mr-5 ${this.state.activeStep == 2 || this.state.activeStep == 3 ? "progress-step-active" : ""}`}>
                                        
                                    </div>
                                    <div className={`progress-step mr-5 ${this.state.activeStep == 3 ? "progress-step-active" : ""}`}>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
				<div className="row">
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <form className="user" onSubmit={this.onSubmitConfigurations.bind(this)}>
                                    <div className={" "+(this.state.viewBidDetailsForm)}>
                                        <div className="row form-group no-gutters align-items-center">
                                            <div className='col-2 text-right pr-3'>
                                                <label>Bid Subject <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-9'>
                                                <div className="form-group">
                                                    <input
                                                        type="text" 
                                                        className="form-control" 
                                                        name="bidSubject"
                                                        value={this.state.bidSubject}
                                                        onChange={(e) => this.setState({bidSubject: e.target.value})}
                                                        required
                                                    />
                                                </div>
                                            </div>  
                                        </div>
                                        <div className="row form-group no-gutters align-items-center">
                                            <div className='col-2 text-right pr-3'>
                                                <label>Bid Description <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-9'>
                                                <div className="form-group">
                                                    <textarea
                                                        type="text" 
                                                        className="form-control pb-4" 
                                                        name="bidDesc"
                                                        value={this.state.bidDesc}
                                                        onChange={(e) => this.setState({bidDesc: e.target.value})}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group no-gutters align-items-center">
                                            <div className='col-2 text-right pr-3'>
                                                <label>Bid Date <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <Datetime
                                                        name="bidDate"
                                                        disableCloseOnClickOutside={false}
                                                        closeOnSelect={true}
                                                        value={this.state.bidDate}
                                                        dateFormat="YYYY-MM-DD"
                                                        onChange={this.handlerBidDateTime}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-3 text-right pr-3'>
                                                <label>Currency <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <Select 
                                                        closeMenuOnSelect={true} 
                                                        name="currency"
                                                        className="border-radius-0" 
                                                        onChange={this.newFilter.bind(this, "currency")}
                                                        options={[{value: "INR", label: "INR"}, {value: "USD", label: "USD"}]} 
                                                        value={this.state.currency}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group no-gutters align-items-center">
                                            <div className='col-2 text-right pr-3'>
                                                <label>Auction Type <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <Select 
                                                        closeMenuOnSelect={true} 
                                                        name="auctionType"
                                                        className="border-radius-0" 
                                                        onChange={this.newFilter.bind(this, "auctionType")}
                                                        options={[{value: "English", label: "English"}, {value: "Hindi", label: "Hindi"}]} 
                                                        value={this.state.auctionType}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-3 text-right pr-3'>
                                                <label>Conversion Rate <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <input
                                                        type="text" 
                                                        className="form-control" 
                                                        name="conversionRate"
                                                        value={this.state.conversionRate}
                                                        onChange={(e) => this.setState({conversionRate: e.target.value})}
                                                        required
                                                    />
                                                </div>
                                            </div> 
                                        </div>
                                        <div className="row form-group no-gutters align-items-center">
                                            <div className="col-2 text-right pr-3">
                                                <label>Terms & Conditions <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className='form-group'>
                                                    <input 
                                                        type="file" 
                                                        name="tcDoc" 
                                                        id="tcDoc" 
                                                        onChange={this.changeTCFileHandler} 
                                                        className="form-control" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3 text-right pr-3">
                                                <label>Attachments (if any)</label>
                                            </div>
                                            <div className='col-3'>
                                                <div className='form-group'>
                                                    <input 
                                                        type="file" 
                                                        name="attachmentsDoc" 
                                                        id="attachmentsDoc" 
                                                        onChange={this.changeAttachmentsFileHandler} 
                                                        className="form-control" 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group no-gutters align-items-center">
                                            <div className='col-2 text-right pr-3'>
                                                <label>Bid Duration (in minutes) <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <input
                                                        type="text" 
                                                        className="form-control" 
                                                        name="bidDuration"
                                                        value={this.state.bidDuration}
                                                        onChange={(e) => this.setState({bidDuration: e.target.value})}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-3 text-right pr-3'>
                                                <label>Bid Closing Type <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <Select 
                                                        closeMenuOnSelect={true} 
                                                        name="bidClosingType"
                                                        className="border-radius-0" 
                                                        onChange={this.newFilter.bind(this, "bidClosingType")}
                                                        options={[{value: "All Items in one go", label: "All Items in one go"}, {value: "One at a time", label: "One at a time"}]} 
                                                        value={this.state.bidClosingType}
                                                        required
                                                    />
                                                </div>
                                            </div> 
                                        </div>
                                        <div className="row form-group no-gutters align-items-center">
                                            <div className='col-2 text-right pr-3'>
                                                <label>Max No. of Bid Extensions</label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <Select 
                                                        closeMenuOnSelect={true} 
                                                        name="maxBidExtCount"
                                                        className="border-radius-0" 
                                                        onChange={this.newFilter.bind(this, "maxBidExtCount")}
                                                        options={[{value: "5", label: "5"}, {value: "10", label: "10"}, {value: "Unlimited", label: "Unlimited"}]} 
                                                        value={this.state.maxBidExtCount}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-3 text-right pr-3'>
                                                <label>Vendor Rank Display  <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <Select 
                                                        closeMenuOnSelect={true} 
                                                        name="vendorRankDisplay"
                                                        className="border-radius-0" 
                                                        onChange={this.newFilter.bind(this, "vendorRankDisplay")}
                                                        options={[{value: "As L1, L2, L3 etc", label: "As L1, L2, L3 etc"}, {value: "Not as L1, L2, L3 etc", label: "Not as L1, L2, L3 etc"}]} 
                                                        value={this.state.vendorRankDisplay}
                                                        required
                                                    />
                                                </div>
                                            </div>   
                                        </div>
                                        <div className="row form-group no-gutters align-items-center">
                                            <div className='col-2 text-right pr-3'>
                                                <label>Approvers <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <Select 
                                                        closeMenuOnSelect={true} 
                                                        name="approvers"
                                                        className="border-radius-0" 
                                                        onChange={this.newFilter.bind(this, "approvers")}
                                                        options={[{value: "sairam.reddy@enmovil.in", label: "Sai Ram Reddy"}, {value: "venkatesh.jalla@enmovil.in", label: "Venkatesh Jalla"}]} 
                                                        value={this.state.approvers}
                                                        required
                                                    />
                                                </div>
                                            </div>  
                                            <div className='col-3 text-right pr-3'>
                                                <label>Mask Participants During Bidding  <span className='text-danger'>*</span></label>
                                            </div>
                                            <div className='col-3'>
                                                <div className="form-group">
                                                    <Select 
                                                        closeMenuOnSelect={true} 
                                                        name="maskParticipants"
                                                        className="border-radius-0" 
                                                        onChange={this.newFilter.bind(this, "maskParticipants")}
                                                        options={[{value: "Yes", label: "Yes"}, {value: "No", label: "No"}]} 
                                                        value={this.state.maskParticipants}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group no-gutters align-items-center mt-4">
                                            <div className="col-12">
                                                <div className="form-group text-center">
                                                    <button type="button" onClick={this.handleBidDetailsNextBtnClick.bind(this)} className="btn btn-info">Save &amp; Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={" "+(this.state.viewSetupBidParamForm)}>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className="row form-group no-gutters align-items-center">
                                                    <div className='col-2 text-right pr-3'>
                                                        <label>Item/ Product/ Service <span className='text-danger'>*</span></label>
                                                    </div>
                                                    <div className='col-9'>
                                                        <div className="form-group">
                                                            <input
                                                                type="text" 
                                                                className="form-control" 
                                                                name="biddingItem"
                                                                value={this.state.biddingItem}
                                                                onChange={(e) => this.setState({biddingItem: e.target.value})}
                                                                required
                                                            />
                                                        </div>
                                                    </div>  
                                                </div>
                                                <div className="row form-group no-gutters align-items-center">
                                                    <div className='col-2 text-right pr-3'>
                                                        <label>Quantity  <span className='text-danger'>*</span></label>
                                                    </div>
                                                    <div className='col-2 mr-4'>
                                                        <div className="form-group">
                                                            <input
                                                                type="text" 
                                                                className="form-control" 
                                                                name="bidItemQuantity"
                                                                value={this.state.bidItemQuantity}
                                                                onChange={(e) => this.setState({bidItemQuantity: e.target.value})}
                                                                required
                                                            />
                                                        </div>
                                                    </div>  
                                                    <div className='col-1'>
                                                        <div className="form-group">
                                                            <Select 
                                                                closeMenuOnSelect={true} 
                                                                name="bidItemUOM"
                                                                placeholder={"UOM"}
                                                                className="border-radius-0" 
                                                                onChange={this.newFilter.bind(this, "bidItemUOM")}
                                                                options={[{value: "meters", label: "meters"}, {value: "litres", label: "litres"}]} 
                                                                value={this.state.bidItemUOM}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 text-right pr-3'>
                                                        <label>Remarks</label>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className="form-group">
                                                            <input
                                                                type="text" 
                                                                className="form-control" 
                                                                name="bidItemRemarks"
                                                                value={this.state.bidItemRemarks}
                                                                onChange={(e) => this.setState({bidItemRemarks: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>  
                                                </div>
                                                <div className="row form-group no-gutters align-items-center">
                                                    <div className='col-2 text-right pr-3'>
                                                        <label>Target Unit Price  <span className='text-danger'>*</span></label>
                                                    </div>
                                                    <div className='col-2 mr-4'>
                                                        <div className="form-group">
                                                            <input
                                                                type="text" 
                                                                className="form-control" 
                                                                name="targetUnitPrice"
                                                                value={this.state.targetUnitPrice}
                                                                onChange={(e) => this.setState({targetUnitPrice: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>  
                                                    <div className='col-1'>
                                                        <div className="form-group">
                                                            <Select 
                                                                closeMenuOnSelect={true} 
                                                                name="targetUnitPriceHideShow"
                                                                className="border-radius-0" 
                                                                onChange={this.newFilter.bind(this, "targetUnitPriceHideShow")}
                                                                options={[{value: "Hide", label: "Hide"}, {value: "Show", label: "Show"}]} 
                                                                value={this.state.targetUnitPriceHideShow}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 text-right pr-3'>
                                                        <label>Selected Currency</label>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className="form-group">
                                                            <input
                                                                // name="currency"
                                                                className="form-control" 
                                                                // onChange={this.newFilter.bind(this, "currency")}
                                                                // options={[{value: "INR", label: "INR"}, {value: "USD", label: "USD"}]} 
                                                                value={this.state.currency.label}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row form-group no-gutters align-items-center">
                                                    <div className='col-2 text-right pr-3'>
                                                        <label>Bid Start Price  <span className='text-danger'>*</span></label>
                                                    </div>
                                                    <div className='col-2 mr-4'>
                                                        <div className="form-group">
                                                            <input
                                                                type="text" 
                                                                className="form-control" 
                                                                name="bidStartPrice"
                                                                value={this.state.bidStartPrice}
                                                                onChange={(e) => this.setState({bidStartPrice: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>  
                                                    <div className='col-1'>
                                                        <div className="form-group">
                                                            <Select 
                                                                closeMenuOnSelect={true} 
                                                                name="bidStartPriceHideShow"
                                                                className="border-radius-0" 
                                                                onChange={this.newFilter.bind(this, "bidStartPriceHideShow")}
                                                                options={[{value: "Hide", label: "Hide"}, {value: "Show", label: "Show"}]} 
                                                                value={this.state.bidStartPriceHideShow}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 text-right pr-3'>
                                                        <label>Minimum Decrement  <span className='text-danger'>*</span></label>
                                                    </div>
                                                    <div className='col-2 mr-4'>
                                                        <div className="form-group">
                                                            <input
                                                                type="text" 
                                                                className="form-control" 
                                                                name="minimumDecrement"
                                                                value={this.state.minimumDecrement}
                                                                onChange={(e) => this.setState({minimumDecrement: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>  
                                                    <div className='col-1'>
                                                        <div className="form-group">
                                                            <Select 
                                                                closeMenuOnSelect={true} 
                                                                name="minimumDecrementUnit"
                                                                className="border-radius-0" 
                                                                onChange={this.newFilter.bind(this, "minimumDecrementUnit")}
                                                                options={[{value: "Amount", label: "Amount"}, {value: "Percentage", label: "Percentage"}]} 
                                                                value={this.state.minimumDecrementUnit}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row form-group no-gutters align-items-center">
                                                    <div className='col-2 text-right pr-3'>
                                                        <label>Item Code</label>
                                                    </div>
                                                    <div className='col-3 mr-4'>
                                                        <div className="form-group">
                                                            <input
                                                                type="text" 
                                                                className="form-control" 
                                                                name="bidItemCode"
                                                                value={this.state.bidItemCode}
                                                                onChange={(e) => this.setState({bidItemCode: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 text-right pr-3'>
                                                        <label>Show L1 Price  <span className='text-danger'>*</span></label>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className="form-group">
                                                            <Select 
                                                                closeMenuOnSelect={true} 
                                                                name="showL1Price"
                                                                className="border-radius-0" 
                                                                onChange={this.newFilter.bind(this, "showL1Price")}
                                                                options={[{value: "Yes", label: "Yes"}, {value: "No", label: "No"}]} 
                                                                value={this.state.showL1Price}
                                                                required
                                                            />
                                                        </div>
                                                    </div>  
                                                </div>                                   
                                            </div>
                                            <div className='col-lg-12 col-md-12 border-bottom'>
                                                <div className="d-sm-flex border-bottom align-items-center justify-content-between mb-4">
                                                    <h1 className="h5 mb-0 text-gray-800">Last PO Details (Optional)</h1>
				                                </div>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className='text-left'>
                                                            <label>PO Number <span className='text-danger'>*</span></label>
                                                        </div>
                                                        <div className=''>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="poNumber"
                                                                    value={this.state.poNumber}
                                                                    onChange={(e) => this.setState({poNumber: e.target.value})}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className='text-left'>
                                                            <label>Vendor Name</label>
                                                        </div>
                                                        <div className=''>
                                                            <div className="form-group">
                                                                <Select 
                                                                    closeMenuOnSelect={true} 
                                                                    name="searchVendorName"
                                                                    className="border-radius-0" 
                                                                    onChange={this.newFilter.bind(this, "searchVendorName")}
                                                                    options={this.state.vendorNameOptions} 
                                                                    value={this.state.searchVendorName}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className='text-left'>
                                                            <label>Unit Rate</label>
                                                        </div>
                                                        <div className=''>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="unitRate"
                                                                    value={this.state.unitRate}
                                                                    onChange={(e) => this.setState({unitRate: e.target.value})}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className='text-left'>
                                                            <label>PO Date <span className='text-danger'>*</span></label>
                                                        </div>
                                                        <div className=''>
                                                            <div className="form-group">
                                                                <Datetime
                                                                    name="poDate"
                                                                    disableCloseOnClickOutside={false}
                                                                    closeOnSelect={true}
                                                                    value={this.state.poDate}
                                                                    dateFormat="YYYY-MM-DD"
                                                                    onChange={this.handlerPODateTime}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className='text-left'>
                                                            <label>PO Value</label>
                                                        </div>
                                                        <div className=''>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="poValue"
                                                                    value={this.state.poValue}
                                                                    onChange={(e) => this.setState({poValue: e.target.value})}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='border-bottom'>
                                                <div className='row align-items-center justify-content-end m-2'>
                                                    <button type='button' className='btn btn-primary mr-1'>Pull RFQ</button>
                                                    <button type='button' className='btn btn-success mr-1' onClick={this.handleImportExcelBtnClick.bind(this)}>Import Excel</button>
                                                    <button type='button' className='btn btn-warning mr-1'>Modify</button>
                                                    <button type='button' className='btn btn-danger mr-1'>Clear</button>
                                                </div>
                                            </div>
                                            <div className={" "+(this.state.viewPODetailsGrid)}>
                                                <div className={"row "}>
                                                    <div className="col-xl-12 col-md-12 mb-4">
                                                        <div className="card shadow h-100 py-2">
                                                            <div className="card-body">
                                                                <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                                                <AgGridReact 
                                                                    rowData={this.state.rowPOData} 
                                                                    columnDefs={this.state.columnDefs}
                                                                    defaultColDef ={this.state.defaultColDef }
                                                                    enableRangeSelection={true}
                                                                    enableCharts={true}
                                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                                    masterDetail={true}
                                                                    onGridReady={this.onGridReady}
                                                                    sideBar={this.state.sideBar}
                                                                    // frameworkComponents={this.state.frameworkComponents}
                                                                    // statusBar={this.state.statusBar}
                                                                    stopEditingWhenGridLosesFocus= {true}
                                                                    paginationPageSize={this.state.paginationPageSize}
                                                                    pagination={true}
                                                                    gridOptions={{
                                                                        context: { componentParent: this }
                                                                    }}     
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group no-gutters align-items-center mt-4">
                                                <div className="col-12">
                                                    <div className="row align-items-center justify-content-center form-group text-center">
                                                        <button type="button" onClick={this.handleSetupBidParamsBackBtnClick.bind(this)} className="btn btn-secondary mr-2">Back</button>
                                                        <button type="button" onClick={this.handleSetupBidParamsNextBtnClick.bind(this)} className="btn btn-info">Save &amp; Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={" "+(this.state.viewInviteVendorsForm)}>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className='row align-items-center justify-content-center'>
                                                <div className='col'>
                                                    <div className='col text-left'>
                                                        <label>Vendor Group</label>
                                                    </div>
                                                    <div className='col'>
                                                        <div className="form-group">
                                                            <Select 
                                                                closeMenuOnSelect={true} 
                                                                name="vendorGroup"
                                                                className="border-radius-0" 
                                                                onChange={this.newFilter.bind(this, "vendorGroup")}
                                                                options={this.state.vendorGroupOptions} 
                                                                value={this.state.vendorGroup}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className='col text-left'>
                                                        <label>Vendor Name <span className='text-danger'>*</span></label>
                                                    </div>
                                                    <div className='col'>
                                                        <div className="form-group">
                                                            <Select 
                                                                closeMenuOnSelect={true} 
                                                                name="inviteVendorName"
                                                                className="border-radius-0" 
                                                                onChange={this.newFilter.bind(this, "inviteVendorName")}
                                                                options={this.state.vendorNameOptions} 
                                                                value={this.state.inviteVendorName}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div className="col">
                                                    <div className="row mt-3 text-center">
                                                        <button type="button" onClick={this.handleVendorSearchBtnClick.bind(this)} className="btn btn-primary mr-2">Search</button>
                                                    </div>
                                                </div>                 
                                            </div>
                                            <div className={" "+(this.state.viewVendorDetailsGrid)}>
                                                <div className={"row "}>
                                                    <div className="col-xl-12 col-md-12 mb-4">
                                                        <div className="card shadow h-100 py-2">
                                                            <div className="card-body">
                                                                <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                                                <AgGridReact 
                                                                    rowData={this.state.rowVendorData} 
                                                                    columnDefs={this.state.columnDefs}
                                                                    defaultColDef ={this.state.defaultColDef }
                                                                    enableRangeSelection={true}
                                                                    enableCharts={true}
                                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                                    masterDetail={true}
                                                                    onGridReady={this.onGridReady}
                                                                    sideBar={this.state.sideBar}
                                                                    // frameworkComponents={this.state.frameworkComponents}
                                                                    // statusBar={this.state.statusBar}
                                                                    stopEditingWhenGridLosesFocus= {true}
                                                                    paginationPageSize={this.state.paginationPageSize}
                                                                    pagination={true}
                                                                    gridOptions={{
                                                                        context: { componentParent: this }
                                                                    }}     
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group no-gutters align-items-center mt-4">
                                                <div className="col-12">
                                                    <div className="row align-items-center justify-content-center form-group text-center">
                                                        <button type="button" onClick={this.handleInviteVendorsBackBtnClick.bind(this)} className="btn btn-secondary mr-2">Back</button>
                                                        <button type="button" onClick={this.handleBidPreviewBtnClick.bind(this)} className="btn btn-info">Preview</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"slide-rfq " + (this.state.sliderTranslate)} style={{ overflow: "auto" }}>
                                        <div className="slide-rfq-title">
                                            <h4 className='h4 row align-items-center justify-content-between ml-2'>
                                                Bid Preview <span className="float-right closebtn mr-5" style={{ marginRight: "25px" }} onClick={this.onCloseBidPreviewSlider.bind(this)} >X</span>
                                            </h4>
                                        </div>
                                        <div className="slide-rfq-body" style={{ position: "relative" }}>
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="card shadow h-100">
                                                    <div className='card-body'>
                                                        <div className="d-sm-flex align-items-center justify-content-between">
                                                            <h1 className="h5 text-gray-800 font-weight-bold">Bid Summary</h1>
                                                        </div>
                                                        <div className='p-3 border'>
                                                            <div className='col'>
                                                                <div className='row pl-2'>
                                                                    <label className='h6 text-black-700 font-weight-bold mr-2'>Bid Subject :</label>
                                                                    <label className='h6 text-black-700'>{this.state.bidSubject}</label>
                                                                </div>
                                                                <div className='row pl-2'>
                                                                    <label className='h6 text-black-700 font-weight-bold mr-2'>Bid Description :</label>
                                                                    <label className='h6 text-black-700'>{this.state.bidDesc}</label>
                                                                </div>
                                                            </div>
                                                            <div className='row align-items-center justify-content-between mt-1'>
                                                                <div className='col' style={{ marginLeft: "20px" }}>
                                                                    <div className='row'>
                                                                        <label className='h6 text-black-700 font-weight-bold mr-2'>Event ID :</label>
                                                                        <label className='h6 text-black-700'>{this.state.eventId}</label>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <label className='h6 text-black-700 font-weight-bold mr-2'>Bid For :</label>
                                                                        <label className='h6 text-black-700'>Price ({this.state.auctionType.value})</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col'>
                                                                    <div className='row'>
                                                                        <label className='h6 text-black-700 font-weight-bold mr-2'>Bid Date :</label>
                                                                        <label className='h6 text-black-700'>{this.state.bidDate}</label>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <label className='h6 text-black-700 font-weight-bold mr-2'>Bid Duration :</label>
                                                                        <label className='h6 text-black-700'>{this.state.bidDuration} min</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col'>
                                                                    <div className='row'>
                                                                        <label className='h6 text-black-700 font-weight-bold mr-2'>Bid Type :</label>
                                                                        <label className='h6 text-black-700'>Reverse Auction</label>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <label className='h6 text-black-700 font-weight-bold mr-2'>Currency :</label>
                                                                        <label className='h6 text-black-700'>{this.state.currency.value}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col ml-2 mt-1'>
                                                                <div className='row'>
                                                                    <label className='h6 text-black-700 font-weight-bold mr-2'>Terms & Conditions :</label>
                                                                    <label className='h6 text-black-700'>{this.state.tcDoc}</label>
                                                                </div>
                                                            </div>
                                                            <div className='col ml-2'>
                                                                <div className='row'>
                                                                    <label className='h6 text-black-700 font-weight-bold mr-2'>Attachments (if any) :</label>
                                                                    <label className='h6 text-black-700'>{this.state.attachmentsDoc}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <div className="card shadow h-100">
                                                    <div className='card-body'>
                                                        <div className="d-sm-flex align-items-center justify-content-between">
                                                            <h1 className="h5 text-gray-800 font-weight-bold">Bid Details</h1>
                                                        </div>
                                                        <div className='p-3 border'>
                                                            <div className={" "+(this.state.viewBidPreviewGrid)}>
                                                                <div className={"row "}>
                                                                    <div className="col-xl-12 col-md-12 mb-4">
                                                                        <div className="card shadow h-100 py-2">
                                                                            <div className="card-body">
                                                                                <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                                                                <AgGridReact 
                                                                                    rowData={this.state.rowBidPreviewData} 
                                                                                    columnDefs={this.state.columnDefs}
                                                                                    defaultColDef ={this.state.defaultColDef }
                                                                                    enableRangeSelection={true}
                                                                                    enableCharts={true}
                                                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                                                    masterDetail={true}
                                                                                    onGridReady={this.onGridReady}
                                                                                    sideBar={this.state.sideBar}
                                                                                    // frameworkComponents={this.state.frameworkComponents}
                                                                                    // statusBar={this.state.statusBar}
                                                                                    stopEditingWhenGridLosesFocus= {true}
                                                                                    paginationPageSize={this.state.paginationPageSize}
                                                                                    pagination={true}
                                                                                    gridOptions={{
                                                                                        context: { componentParent: this }
                                                                                    }}     
                                                                                />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <div className="card shadow h-100">
                                                    <div className='card-body'>
                                                        <div className="d-sm-flex align-items-center justify-content-between">
                                                            <h1 className="h5 text-gray-800 font-weight-bold">Participants Details</h1>
                                                        </div>
                                                        <div className='p-3 border'>
                                                            <div className={" "+(this.state.viewParticipantsGrid)}>
                                                                <div className={"row "}>
                                                                    <div className="col-xl-12 col-md-12 mb-4">
                                                                        <div className="card shadow h-100 py-2">
                                                                            <div className="card-body">
                                                                                <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                                                                <AgGridReact 
                                                                                    rowData={this.state.rowParticipantsData} 
                                                                                    columnDefs={this.state.columnDefs}
                                                                                    defaultColDef ={this.state.defaultColDef }
                                                                                    enableRangeSelection={true}
                                                                                    enableCharts={true}
                                                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                                                    masterDetail={true}
                                                                                    onGridReady={this.onGridReady}
                                                                                    sideBar={this.state.sideBar}
                                                                                    // frameworkComponents={this.state.frameworkComponents}
                                                                                    // statusBar={this.state.statusBar}
                                                                                    stopEditingWhenGridLosesFocus= {true}
                                                                                    paginationPageSize={this.state.paginationPageSize}
                                                                                    pagination={true}
                                                                                    gridOptions={{
                                                                                        context: { componentParent: this }
                                                                                    }}     
                                                                                />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row m-2'>
                                                                <label className='h6 text-gray-900'>Remarks</label>
                                                                <textarea
                                                                    type="text" 
                                                                    className="form-control pb-4" 
                                                                    name="finalRemarks"
                                                                    value={this.state.finalRemarks}
                                                                    onChange={(e) => this.setState({finalRemarks: e.target.value})}
                                                                />
                                                            </div>
                                                            <div className="row form-group no-gutters align-items-center mt-4">
                                                                <div className="col-12">
                                                                    <div className="row align-items-center justify-content-center form-group text-center">
                                                                        <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                                                        <button type='button' className='btn btn-success mr-2' onClick={this.handleExportExcelBtnClick.bind(this)}>Export To Excel</button>
                                                                        <button type='button' className='btn btn-warning mr-2' onClick={this.handleAddApproversBtnClick.bind(this)}>Add Approvers</button>
                                                                        <button type="button" onClick={this.handleCancelBidBtnClick.bind(this)} className="btn btn-danger mr-2">Cancel Bid</button>
                                                                        <button type="button" onClick={this.onCloseBidPreviewSlider.bind(this)}className="btn btn-secondary mr-2">Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}