
import React, { Component } from 'react';
 
const DeleteItem = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickDeleteItem(props);
    };
// console.log("props ", props.data)
    return (
        <div>
            <button type="button"
              className={"f14 btn btn-danger"} 
              title="Update" 
              onClick={handleClick}
              style={{textAlign:"center"}}>
                  Delete
            </button>
          
        </div>
    );
};

export default DeleteItem;
