import React ,{ useState }from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from "moment";

const RfqDateCellEditor = (props) => {
  const [dateValue, setDateValue] = useState(moment(props.value).format("YYYY-MM-DD"));
  console.log("test props in date cellEditor - ",props);

  const ondateChange = (e)=>{
    // e.stopPropagation();
    // console.log("test editor",e);
    let val= moment(e._d).format("YYYY-MM-DD");
    // props.onValueChange(val);
    // props.node.updateData({...props.node.data, "rfq_valid_till": val})
    // props.setValue(val);
    setDateValue(val);
    props.context.componentParent.handleRFQValidTill(e,props.node.data)
  }
  return (
    <div  tabIndex={1}>
        {/* <input type='date' value={props.value} onChange={ondateChange} /> */}
        <Datetime
          value={dateValue}
          disableCloseOnClickOutside={false}
          closeOnSelect={true}
          
          inputProps={{
            type:"date",
            placeholder: "valid till",
            name: "rfq_valid_till",
            style: {width:"100%", maxWidth:"100%"},
            autoComplete: "off",
          }}
          timeFormat={false}
          dateFormat="YYYY-MM-DD"
          onChange={ondateChange} 
        />
    </div>
  )
}

export default RfqDateCellEditor