import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import icn from "../../img/undraw_profile.svg";
import $, { param } from 'jquery';
import 'jquery-ui';
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import withRouter from './withRouter';
import ViewDoc from "./viewdocaction";
import DownloadDoc from "./downloadDocaction";


class BillingFreightLogs extends Component {
    constructor(props){
        super(props);
        // console.log(props)
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
			},
            frameworkComponents:{
                ViewDoc:ViewDoc,
                DownloadDoc:DownloadDoc
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            conf:"",            
            showUploadCSVForm:"show-n",
            slider:"",
            freightlogs:[]
        }
        
        this.loadFreightLogs = this.loadFreightLogs.bind(this);
    }
	componentDidMount = async () => {
        // console.log(this.props.params)
        var params = {
            document_no:this.props.params.document_no
        }
        this.loadFreightLogs(params);
	}
   
    loadFreightLogs(params){
        redirectURL.post("/billing/freightlogs",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    rowData:records
                })
            }
		})
    }
    
    
    render(){
        var otherCosts = this.state.otherCosts;
	
        var closedcolumnDefs=[
            {
                headerName:Constants.FREIGHT_APPROVALS_DOCUMENT_NO,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_STATUS,
                field:"log_message"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_APPROVED_BY,
                field:"approval_by",
                valueGetter:function(params){
                    try {
                        if(params.data.approval_by !== "" && params.data.approval_by !== undefined)
                        {
                            return params.data.approval_by+" ( Level - "+params.data.level_no+" )";
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_APPROVED_DATETIME,
                field:"approval_datetime",
                valueGetter:function(params){
                    try{
                        if(params.data.approval_datetime !== "" && params.data.approval_datetime !== undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.approval_datetime);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_REMARKS,
                field:"approval_reason"
            },
           
        ]
       
        return (
            	<div className={"row "} style={{marginTop:"20px"}}>
					<div className="col-xl-12 col-md-12 mb-4">
                        <h5>Freight Approvals - Log Report</h5>
                        <div style={{width:"100%",height:"40vh"}} className="ag-theme-balham">
                            <AgGridReact 
                                rowData={this.state.rowData} 
                                columnDefs={closedcolumnDefs}
                                defaultColDef ={this.state.defaultColDef }
                                enableRangeSelection={true}
                                enableCharts={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                masterDetail={true}
                                onGridReady={this.onGridReady}
                                // sideBar={this.state.sideBar}
                                frameworkComponents={this.state.frameworkComponents}
                                // statusBar={this.state.statusBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                
                            />
						</div>
					</div>					
                
                </div>
                
                
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
// console.log("icn ", icn)
function pushNot(){
    // showNotification();
    
	// setInterval(function(){ showNotification(); }, 20000);
}


function showNotification() {	
	if (!Notification) {
		$('body').append('<h4 style="color:red">*Browser does not support Web Notification</h4>');
		return;
	}
	if (Notification.permission !== "granted") {		
		Notification.requestPermission();
	} else {		
		$.ajax({
            url:"http://localhost:5005/billing",
			type: "GET",
			success: function(data, textStatus, jqXHR) {
				
                var theurl = "http://localhost:3005/";
                var notifikasi = new Notification("Freight Rates", {
                    icon: icn,
                    body: "You are checking Freight Rates information.",
                });
                notifikasi.onclick = function () {
                    window.open(theurl); 
                    notifikasi.close();     
                };
                setTimeout(function(){
                    notifikasi.close();
                }, 5000);
        
			},
			error: function(jqXHR, textStatus, errorThrown)	{}
		}); 
	}
};
export default withRouter(BillingFreightLogs) ;