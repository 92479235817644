
import {React, useState, useMemo, useCallback, useEffect, } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import STATUS from "./Constant";
import {transitdelaycols} from "./columnDefs_transitdelay";
import {nogpscols} from "./columnDefs_no_gps";
import {getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY} from '../common/utils';
import MapIcon from "./Gridaction_icon_map";
import editButtonRenderer from "./EditButtonRenderer";

import AccidenIcon from "./Gridaction_icon_accident";
import BreakDownIcon from "./Gridaction_icon_breakdown";
import DayTripIcon from "./Gridaction_icon_daywisetrip";
import GPSIcon from "./Gridaction_icon_gps";
import LSPDeviationIcon from "./Gridaction_icon_lspdeviation";
import NightDrivingIcon from "./Gridaction_icon_nightdriving";
import OverspeedIcon from "./Gridaction_icon_overspeed";
import StoppageIcon from "./Gridaction_icon_stoppage";
import TollsIcon from "./Gridaction_icon_tolls";
import TransitDelayIcon from "./Gridaction_icon_transitdelay";
import TripDeviationIcon from "./Gridaction_icon_tripdeviation";
import ButtonComp from "./Button_action"
import Edit from './Gridaction_icon_edit';

var gridLegColumns = [];
 /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Based on the props AgGrid will be use along with action actions like icons, showing legs etc..
    
    **/
function AgGrid(props) {
    var [gridColumnApi, setgridColumnApi] = useState([]);
    var [gridColumnsList,setGridColumns] = useState([]);
    var [gridApi,setgridApi] =useState([]);
    var [gActionfield, setgActionfield] = useState("");
    var [detailCellData, setDetailsParams] = useState(null);
    var [detailCellRendererParams, setdetailCellRendererParams] = useState(null);
    var gridStyle = useMemo(() => ({ height: '70VH', width: '100%' }), []);

    const defaultColDef = useMemo(() => {
      return {
      //   flex: 1,
        resizable:true,
        filter:true,
        sortable:true,
        wrapText:true
      };
    }, []);
    const onGridReady = useCallback((params) => {
  
    }, []);
  
    const onGridState = () => {
      // Get Current Columns State and Store in this.colState
      const colState = gridColumnApi.getColumnState();
   
      // Get Current RowGroup Columns State and Store in this.rowGroupState
      const rowGroupState = gridColumnApi.getRowGroupColumns();
   
      // Get Current Pivot Columns State and Store in this.pivotColumns
      // User should enable the Pivot mode.
      const pivotColumns = gridColumnApi.getPivotColumns();
   
      // Get Current Filter State and Store in window.filterState
      const filterState = gridApi.getFilterModel();
      gridApi.setFilterModel(filterState);
    };
    useEffect(() => {
        loadColumns();
    },[props.columnDefs])
    /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Generates the grid columns dynamically based on field defined in columnDefs.js
    
    **/
    var loadColumns = () => {
        var jsonCols = props.columnDefs;
        var columns = [];
        if (jsonCols.length > 0) {
            jsonCols.forEach((col) => {
                var cl = {};
                if (col.type === "datetime") {
                    cl.headerName = col.headerName
                    cl.field = col.field
                    cl.pinned = col.isPinned
                    cl.width = 150
                    cl.editable = col.editable
                    cl.valueGetter = (function(field) { 
                        // console.log("field", field)
                        return function(params) {
                            try {
                                // console.log('Field value:', params.data[field]); 
                                if (params.data && field in params.data) {
                                    // return getHyphenDDMMYYYY(params.data[field]);
                                    return getHyphenDDMMMYYYYHHMM(params.data[field]);
                                } else {
                                    return "";
                                }
                            } catch (error) {
                                console.error('Error in valueGetter for datetime:', error);
                                return "";
                            }
                        };
                    })(col.field);
                    // cl.valueGetter= (function(field) { 
                    //     // console.log("field", field)
                    //     return function(params) {
                    //         try {
                    //             // console.log('Field value:', params.data[field]); 
                    //             if (params.data && field in params.data) {
                    //                 // return getHyphenDDMMYYYY(params.data[field]);
                    //                 return getHyphenDDMMMYYYYHHMM(params.data[field]);
                    //             } else {
                    //                 return "";
                    //             }
                    //         } catch (error) {
                    //             console.error('Error in valueFormatter for datetime:', error);
                    //             return "";
                    //         }
                    //     };
                    // })(col.field);
                    
                    columns.push(cl);
                }
                else if(col.type === 'string_status'){
                    if(col.width === undefined && col.width === "")
                    {
                        var w = 180;    
                    }
                    else{
                        var w = col.width;
                    }
                    cl.headerName = col.headerName
                    cl.field = col.field
                    cl.pinned = col.isPinned
                    cl.width = w
                    cl.editable = col.editable
                    cl.valueGetter = (function(field) { 
                        // console.log("field", field)
                        return function(params) {
                            try {
                                // console.log('Field value:', params.data[field]); 
                                if (params.data && field in params.data) {
                                    if(params.data[field] == 1)
                                    {
                                        return STATUS.INSIDE_PLANT;
                                    }
                                    if(params.data[field] == 2)
                                    {
                                        if(params.data.trip_status == 1)
                                        {
                                            return STATUS.INTRANSIT_TRANSPORT_YARD;
                                        }
                                        
                                        else if(params.data.trip_status == 2)
                                        {
                                            return STATUS.AT_TRANSPORTER_YARD;
                                        }
                                        else if(params.data.trip_status == 3)
                                        {
                                            return STATUS.INTRANSIT;
                                        }
                                        else{
                                            return STATUS.INTRANSIT;
                                        }
                                    }
                                    
                                    if(params.data[field] == 3)
                                    {
                                        return STATUS.NEAR_DESTINATION;
                                    }
                                    
                                    if(params.data[field] == 4)
                                    {
                                        return STATUS.REACH_DESTINATION;
                                    }
                                    
                                    if(params.data[field] == 5)
                                    {
                                        return STATUS.MANUAL_CLOSE;
                                    }
                                    if(params.data[field] == 6)
                                    {
                                        return STATUS.FORCE_CLOSE;
                                    }
                                    if(params.data[field] == 7)
                                    {
                                        return STATUS.PROXIMATE_TRIP_CLOSE;
                                    }
                                } else {
                                    return "";
                                }
                            } catch (error) {
                                console.error('Error in valueGetter for datetime:', error);
                                return "";
                            }
                        };
                    })(col.field);
                    
                    columns.push(cl);
                }
                else if(col.type === 'actions'){
                    // console.log("col.actionlist", col.actionlist)
                    if(col.actionlist.length > 0)
                    {
                        col.actionlist.forEach((ic) => {
                            if(ic.viewmode === "show")
                            {
                                if(ic.width === undefined && ic.width === "")
                                {
                                    var w = 50;    
                                }
                                else{
                                    var w = ic.width;
                                }
                                
                                var ac = {}
                                // console.log("ic ",ic)
                                ac.headerName = ic.headerName
                                ac.field = ic.field
                                ac.pinned = ic.isPinned
                                ac.width = w
                                ac.cellRendererSelector = (function(field) { 
                                    // console.log("field", field)
                                    return function(params) {
                                        try {
                                            // console.log("ic.actionfor ", field.actionfor)
                                            if(field.viewmode === "show")
                                            {
                                                if(field.actionfor === "map")
                                                {
                                                    return {
                                                        component:MapIcon
                                                    }
                                                }
                                                if(field.actionfor === "kpi_transit")
                                                {
                                                    return {
                                                        component:TransitDelayIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "kpi_gps")
                                                {
                                                    return {
                                                        component:GPSIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "kpi_overspeed")
                                                {
                                                    return {
                                                        component:OverspeedIcon
                                                    }
                                                }
                                                if(field.actionfor === "editButton")
                                                    {
                                                        return {
                                                            component:editButtonRenderer
                                                        }
                                                    }
                                                
                                                if(field.actionfor === "kpi_nightdriving")
                                                {
                                                    return {
                                                        component:NightDrivingIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "kpi_stoppages")
                                                {
                                                    return {
                                                        component:StoppageIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "kpi_accident")
                                                {
                                                    return {
                                                        component:AccidenIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "kpi_trip_deviation")
                                                {
                                                    return {
                                                        component:TripDeviationIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "kpi_lsp_deviation")
                                                {
                                                    return {
                                                        component:LSPDeviationIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "info_break_down")
                                                {
                                                    return {
                                                        component:BreakDownIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "info_day_trip_distance")
                                                {
                                                    return {
                                                        component:DayTripIcon
                                                    }
                                                }
                                                
                                                if(field.actionfor === "info_tolls")
                                                {
                                                    return {
                                                        component:TollsIcon
                                                    }
                                                }
                                                if(field.actionfor === "edit")
                                                {
                                                    return {
                                                        component:Edit
                                                    }
                                                }
                                            }

                                        } catch (error) {
                                            console.error('Error in valueGetter for datetime:', error);
                                            return "";
                                        }
                                    };
                                })(ic);
                            
                                columns.push(ac);
                            }
                        })
                    }
                    
                }
                else if(col.type === 'button'){
                    // console.log("col.actionlist", col.actionlist)
        
                
                    if(col.width === undefined && col.width === "")
                    {
                        var w = 100;    
                    }
                    else{
                        var w = col.width;
                    }
                    
                    var ac = {}
                    // console.log("ic ",ic)
                    ac.headerName = col.headerName
                    ac.field = col.field
                    ac.pinned = col.isPinned
                    ac.width = w
                    ac.cellRendererSelector = (function(field) { 
                        // console.log("field", field)
                        return function(params) {
                            try {
                                // console.log("ic.actionfor ", field.actionfor)
                                return {
                                    component:ButtonComp,
                                    params:{
                                        button_action:col.buttonAction,
                                        button_title:col.buttonTitle,
                                    }
                                }

                            } catch (error) {
                                console.error('Error in valueGetter for datetime:', error);
                                return "";
                            }
                        };
                    })(col);
                
                    columns.push(ac);
                            
                    
                    
                }
                else{
                    if(col.width === undefined && col.width === "")
                    {
                        var w = 180;    
                    }
                    else{
                        var w = col.width;
                    }
                    cl.headerName = col.headerName
                    cl.field = col.field
                    cl.pinned = col.isPinned
                    cl.width = w
                    cl.editable = col.editable
                    columns.push(cl);
                }
                // console.log("cl ", cl)
            });
        }
        // console.log("columns ", columns)
        setGridColumns(columns);
    };
     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Used to add new record if required
    
    **/
    var onClickAddBlock =() =>{
    }
    
     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Used to update /edit  record data if required
    
    **/
    var onClickEditBlock =(rownode) =>{
        console.log("rownode Edit", rownode)
        return props.onClickShowEditItem(rownode);
        
    }

     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Used to View respective rownode data if required
    
    **/
    var onClickViewBlock = (rownode) => {
        console.log("rownode View", rownode)
        
    }
    
     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Used to show the map route for the record.
    
    **/
    var onShowRouteData = (rownode) => {
        return props.onShowRouteDiv(rownode);
    }
    
     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Used to show the legs data which are transit delayed
    
    **/
    
    var onShowTransitData = (rownode) => {
        gridLegColumns = transitdelaycols;
        setDetailsParams({legFor:"transit_delay",rownode:rownode});
       
        // return props.onShowTransit(rownode);
    }
  
     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Used to show No Gps / GPS data as grid legs
    
    **/
    var onShowTransitGPS = (rownode) => {
        gridLegColumns = nogpscols;
        setDetailsParams({legFor:"no_gps",rownode:rownode});

    }
  
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show Overspeed data as grid legs
   
   **/
    var onShowOverspeedData = (rownode) => {
        return props.onShowOverspeed(rownode);
    }
  
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show Nightdriving data as grid legs
   
   **/
    var onShowNightDrivingData = (rownode) => {
        return props.onShowNightDriving(rownode);
    }
  
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show Stoppage data as grid legs
   
   **/
    var onShowStoppageData = (rownode) => {
        return props.onShowStoppage(rownode);
    }
  
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show Accident data as grid legs
   
   **/
    var onShowAccidentData = (rownode) => {
        return props.onShowAccident(rownode);
    }
  
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show LSP Deviation data as grid legs
   
   **/
    var onShowLSPDeviationData = (rownode) => {
        return props.onShowLSPDeviation(rownode);
    }
  
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show Trip Deviation data as grid legs
   
   **/
    var onShowTripDeviationData = (rownode) => {
        return props.onShowTripDeviation(rownode);
    }
  
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show Day wise trip data as grid legs
   
   **/
    var onShowDaywiseData = (rownode) => {
        return props.onShowDaywise(rownode);
    }
  
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show Tolls data as grid legs
   
   **/
    var onShowTollsData = (rownode) => {
        return props.onShowTolls(rownode);
    }
    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: Used to show Breakdown data as grid legs
   
   **/
    var onShowBreaksDownData = (rownode) => {
        return props.onShowBreakDown(rownode);
    }

    /** 
    * Dev Name: Jalla Venkatesh
    * Bug No: #
    * Description: To perform Button action and send to parent component
   
   **/
    var onClickButtonAction =(rownode) => {

        return props.onShowButtonAction(rownode);
    }
    /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Whenever detailsCellData state update useEffect function will call and execute code block accordingly.

    **/   
    useEffect(() => {
        // console.log("params ", params)
        showGridLegs(detailCellData);
    },[detailCellData])

  
     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: To show the respective grid icon data as child grid.
    
    **/
    var showGridLegs = (params) => {
        try {
            
            var rownode = params.rownode;
            // console.log("rownode params", rownode)
            // console.log("gridLegColumns , ",gridLegColumns)
            if(params.legFor == "transit_delay")
            {
                
                if (rownode.column.colDef.field == 'transit_delay') {
                    rownode.node.setExpanded(!rownode.node.expanded);
                }

                setdetailCellRendererParams({
                    detailGridOptions: {
                    columnDefs: gridLegColumns,
                    defaultColDef: {
                        flex: 1,
                    },
                    },
                    getDetailRowData: (params) => {
                        var rowdata = [{callID:"T001", direction:"South", number:"123123",duration:"1231",switchCode:"S21321"}, {callID:"ET01", direction:"East", number:"564",duration:"432",switchCode:"S21234"}]
                        params.successCallback(rowdata);
                    },
                })
            }
            if(params.legFor == "no_gps")
            {
                
                if (rownode.column.colDef.field == 'no_gps') {
                    rownode.node.setExpanded(!rownode.node.expanded);
                }

                setdetailCellRendererParams({
                    detailGridOptions: {
                    columnDefs: gridLegColumns,
                    defaultColDef: {
                        flex: 1,
                    },
                    },
                    getDetailRowData: (params) => {
                        var rowdata = [{GPSID:"GPS001", direction:"South", number:"123123",duration:"1231",switchCode:"S21321"}, {GPSID:"GP01", direction:"East", number:"564",duration:"432",switchCode:"S21234"}]
                        params.successCallback(rowdata);
                    },
                })
            }
            
        } catch (error) {
            
        }
    }

  
     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Used to save the user selected grid format
    
    **/
  var saveGridLayout = (e) => {
    // console.log(e.target)
    var pth = props.currentPage;
    var others = props.others;
    console.log(pth,"Save Grid Layout")
    console.log(others.tab,"Tab Name")
    console.log("gridColumnApi",gridColumnApi)
  }
  
  
     /** 
     * Dev Name: Jalla Venkatesh
     * Bug No: #
     * Description: Used to reset the predefined grid format.
    
    **/
  var resetGridLayout = (e) => {
    // console.log(e.target)
    var pth = props.currentPage;
    var others = props.others;
    console.log(pth,"Reset Grid Layout")
    console.log(others.tab,"Tab Name")
  }

    
  return (
    <div>
        {(props.saveoptions === "Y")?
        <div className="row">
            <div className="col-sm-12">
                <button type="button" title="Reset Grid Layout" name="resetgrid" className="btn btn-warning float-right action_btn" style={{marginLeft:"10px"}} onClick={resetGridLayout}> <i className="fa fa-reply"></i> </button>
                <button type="button" title="Save Grid Layout" name="savegrid" className="btn btn-success float-right action_btn" onClick={saveGridLayout}> <i className="fa fa-save"></i> </button>
            </div>
        </div>
        
        :""}
        <div
            style={gridStyle}
            className={
            "ag-theme-balham"
            }
        >
            <AgGridReact
            rowData={props.rowData}
            columnDefs={gridColumnsList}
            defaultColDef={defaultColDef}
            // animateRows={true}
            onGridReady={onGridReady}
            onGridState={onGridState}
            pagination={true}
            // paginationPageSize={paginationPageSize}
            // rowHeight={35}
            enableRangeSelection={true}
            enableCellContextMenu={true}
            // allowContextMenuWithControlKey={true}
            context={{onClickAddBlock, onClickEditBlock, onShowRouteData, onShowTransitData, onShowTransitGPS, onShowOverspeedData, onShowNightDrivingData, onShowStoppageData, onShowAccidentData, onShowLSPDeviationData, onShowTripDeviationData, onShowDaywiseData, onShowTollsData, onShowBreaksDownData, onClickButtonAction}}
          
            masterDetail={true}
            detailCellRendererParams={detailCellRendererParams}
            sideBar={{
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            }}
            statusBar={{
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            }}
            />
        </div>

    </div>
  );
}

export default AgGrid;