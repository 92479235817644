import React, { Component } from "react";
import "./rfqPreviewAndApprovePage.css";
import withRouter from "../withRouter";
import redirectURL from "../redirectURL";
import moment from "moment";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Datetime from "react-datetime";
import Modal from "react-responsive-modal";
import "react-datetime/css/react-datetime.css";
import SweetAlert from "react-bootstrap-sweetalert";
import StepOneIcon from "../../assets/icons/step1-inactive.svg";
import StepTwoIcon from "../../assets/icons/step6-inactive.svg";
import StepThreeIcon from "../../assets/icons/step8-inactive.svg";
import StepFourIcon from "../../assets/icons/step10-inactive.svg";
import ClipIcon from "../../assets/icons/clip-icon.svg";
import PlaceholderIcon from "../../assets/icons/placeholder.svg";
import PlaceholderIcon2 from "../../assets/icons/placeholder2.svg";
import PlaceholderIcon3 from "../../assets/icons/yes-no-icon.svg";
import AddIcon from "../../assets/icons/add-icon.svg";
import SignatureIcon from "../../assets/icons/signature-icon-inactive.svg";
import SignatureIconActive from "../../assets/icons/signature-icon.svg";
import InvitedIcon from "../../assets/icons/invited.svg";
import SendInviteIcon from "../../assets/icons/WeightAndVolume/send-invite.svg";
import { Link } from "react-router-dom";

class RfqPreviewAndApprovePage extends Component {
  constructor(props) {
    super(props);
    console.log("this.props.match.params.id", this.props);

    this.state = {
      rfqRemarks: "",
      documentUrl: "",
      openPreviewModal: false,
      show1: false,
      basicType1: "default",
      basicTitle1: "",
      show: false,
      openModal: false,
      selectedFileName: "No File Selected",
      commercialFileName: "No File Selected",
      fileSelected: "",
      basicTitle: "",
      acceptedCurrency: 0,
      inputArr: [
        {
          type: "number",
          id: 1,
          value: "",
        },
      ],
      basicType: "default",
      defaultColDef: {
        // flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: true,
      },
      paginationPageSize: 200,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            { field: "callId" },
            { field: "direction" },
            { field: "number", minWidth: 150 },
            { field: "duration", valueFormatter: "x.toLocaleString() + 's'" },
            { field: "switchCode", minWidth: 150 },
          ],
          defaultColDef: {
            flex: 1,
          },
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.callRecords);
        },
      },
      rowPOData: [],
      rowVendorData: [],
      rowBidPreviewData: [],
      rowParticipantsData: [],
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      rfqEventId: "",
      rfqSubject: "",
      rfqDesc: "",
      rfqStartDate: "",
      rfqEndDate: "",
      baseCurrency: [],
      currenciesList: [{ value: "", label: "Select" }],
      // acceptedCurrencies : [],
      // acceptedConversionRates: [],
      acceptedCurrenciesList: [{}],
      tcDoc: "",
      tcDocFileUrl: "",
      // auctionType: {"value": "", "label": "Select"},
      // bidDuration: "",
      // maxBidExtCount: {"value": "", "label": "Select"},
      approvers: [],
      conversionRate: "",
      commercialTermsTitleArr: [],
      commercialTermsTitle: "",
      commercialTermsDocArr: [],
      commercialTermsDoc: "",
      attachmentsTitleArr: [],
      attachmentTitle: "",
      attachmentsDocArr: [],
      attachmentsDoc: "",
      questionsArr: [],
      questionTitle: "",
      // bidClosingType: {"value": "", "label": "Select"},
      // vendorRankDisplay: {"value": "", "label": "Select"},
      technicalApproval: { value: "", label: "" },
      viewRFQDetailsForm: "show-m",
      viewRFQParamForm: "show-n",
      viewAdditionalDetailsForm: "show-n",
      viewInviteVendorsForm: "show-n",
      viewPODetailsGrid: "show-m",
      viewVendorDetailsGrid: "show-m",
      viewBidPreviewGrid: "show-m",
      viewParticipantsGrid: "show-m",
      rfqItem: "",
      rfqItemDesc: "",
      rfqItemQuantity: "",
      rfqItemUOM: { value: "meters", label: "Meters" },
      rfqTargetPrice: "",
      // targetUnitPriceHideShow: {"value": "", "label": "Select"},
      // bidStartPrice: "",
      // bidStartPriceHideShow: {"value": "", "label": "Select"},
      rfqItemCode: "",
      deliveryLocation: "",
      rfqItemRemarks: "",
      // minimumDecrement: "",
      // minimumDecrementUnit: {"value": "Amount", "label": "Amount"},
      tatDays: "",
      // showL1Price: {"value": "Yes", "label": "Yes"},
      poNumber: "",
      unitRate: "",
      searchVendorName: [],
      poDate: "",
      poValue: "",
      activeStep: 1,
      vendorGroup: { value: "", label: "" },
      vendorGroupOptions: [],
      inviteVendorName: [],
      selectedVendorListForInvitation: [],
      vendorNameOptions: [{ value: "", label: "Select" }],
      approverNameOptions: [{ value: "", label: "Select" }],
      finalRemarks: "",
      isComAccActive: false,
      isAttAccActive: false,
      isQuesAccActive: false,
      isFormSubmitted: 0,
      isIdGenerated: false,
      isTCDocChanged: false,
      isAttachmentsDocChanged: false,
      isCommercialTermsDocChanged: false,
      sliderRso: "",
      overly: "show-n",
      loadshow: "show-n",
      startDateFormatFxd: true,
      startDateFormatBuf: false,
      startDateFxdInp: "show-m",
      startDateBufInp: "show-n",
      startDateBuffer: 0,
      startDateBufferType: { value: "HRS", label: "HRS" },
      rfqStartTimeType: { value: "fixed_time", label: "Fixed Time" },
      rfqEndTimeType: { value: "fixed_time", label: "Fixed Time" },
      endDateFormatFxd: true,
      endDateFormatBuf: false,
      endDateFxdInp: "show-m",
      endDateBufInp: "show-n",
      endDateBuffer: 0,
      endDateBufferType: { value: "HRS", label: "HRS" },
      rowApprData: [],
      disableApproverButton: null,
      disableRequestChangeButton: "disabled",
    };
  }

  previewDocument = (documentUrl) => {
    // console.log("document", document);
    const parts = documentUrl.split(".");
    const fileExtension = parts[parts.length - 1];
    this.setState({
      openPreviewModal: true,
      documentUrl: documentUrl,
      documentType: fileExtension,
    });
  };

  closePreviewModal = () => {
    this.setState({
      openPreviewModal: false,
    });
  };

  componentDidMount() {
    // const navigate = useNavigate();
    this.setState({
      rfqEventId: this.props.match.params.id,
    });

    if (this.props.match.params.id) {
      let params = {
        rfq_event_id: this.props.match.params.id,
        is_form_submitted_status: 1,
      };

      redirectURL
        .post("/procurement/getrfqdetailsinfo", params)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("response.data", response.data);
            if (response.data.eventData) {
              let eventData = response.data.eventData;

              if(eventData.rfq_start_date_is_buffer){
                this.setState({
                  rfqStartDate: `${eventData.rfq_start_date_buffer_hrs} Hrs Buffer`
                })
                
              }else{
                this.setState({
                  rfqStartDate: moment(eventData.rfq_start_time)
                    .utcOffset(0)
                    .format("YYYY-MM-DD hh:mm:ss")
                })
              }


              if(eventData.rfq_end_date_is_buffer){
                this.setState({
                  rfqEndDate: `${eventData.rfq_end_date_buffer_hrs} Hrs Buffer`
                })
                
              }else{
                this.setState({
                  rfqEndDate: moment(eventData.rfq_end_time)
                    .utcOffset(0)
                    .format("YYYY-MM-DD hh:mm:ss")
                })
              }

              this.setState({
                rfqSubject: eventData.rfq_subject,
                rfqDesc: eventData.rfq_description,
                baseCurrency: {
                  value: eventData.currency,
                  label: eventData.currency,
                },
                conversionRate: eventData.conversion_rate,
                tcDocFileUrl: eventData.tc_doc,
                technicalApproval: {
                  value: eventData.technical_approval,
                  label: eventData.technical_approval,
                },
              });

              if (response.data.eventItemData) {
                let eventItemData = response.data.eventItemData;
                let latestEventItemData =
                  eventItemData[eventItemData.length - 1];
                // let searchVendorName =
                //   latestEventItemData.rfq_item_search_vendor_name.map(
                //     (s_v_name) => {
                //       return { value: s_v_name, label: s_v_name };
                //     }
                //   );
                this.setState({
                  rfqItem: latestEventItemData.rfq_item,
                  rfqItemDesc: latestEventItemData.rfq_item_description,
                  rfqItemQuantity: latestEventItemData.rfq_item_quantity,
                  rfqItemRemarks: latestEventItemData.rfq_item_remarks,
                  rfqItemCode: latestEventItemData.rfq_item_code,
                  rfqItemUOM: {
                    value: latestEventItemData.rfq_item_uom,
                    label: latestEventItemData.rfq_item_uom,
                  },
                  rfqTargetPrice: latestEventItemData.rfq_item_target_price,
                  deliveryLocation:
                    latestEventItemData.rfq_item_delivery_location,
                  tatDays: latestEventItemData.rfq_item_tat_days,
                  // poNumber: latestEventItemData.rfq_item_po_number,
                  // poDate: moment(latestEventItemData.rfq_item_po_date)
                  //   .utcOffset(0)
                  //   .format("YYYY-MM-DD hh:mm:ss"),
                  // searchVendorName: searchVendorName,
                  // unitRate: latestEventItemData.rfq_item_unit_rate,
                  // poValue: latestEventItemData.rfq_item_po_value,
                  rowPOData: [...this.state.rowPOData, ...eventItemData],
                });
              }

              if (response.data.eventCommercialTermsData) {
                let eventCommercialTermsData =
                  response.data.eventCommercialTermsData;
                let commercialTermsDocArr = eventCommercialTermsData.map(
                  ({
                    rfq_commercial_terms_title,
                    rfq_commercial_terms_doc,
                  }) => ({
                    rfq_commercial_terms_title,
                    rfq_commercial_terms_doc,
                  })
                );
                this.setState({
                  commercialTermsDocArr: commercialTermsDocArr,
                });
              }

              if (response.data.eventAttachmentsData) {
                let eventAttachmentsData = response.data.eventAttachmentsData;
                let attachmentsDocArr = eventAttachmentsData.map(
                  ({ rfq_attachments_title, rfq_attachments_doc }) => ({
                    rfq_attachments_title,
                    rfq_attachments_doc,
                  })
                );
                this.setState({
                  attachmentsDocArr: attachmentsDocArr,
                });
              }

              if (response.data.eventQuestionsData) {
                let eventQuestionsData = response.data.eventQuestionsData;
                let questionsArr = eventQuestionsData.map(
                  ({ rfq_questions_title }) => ({ rfq_questions_title })
                );
                this.setState({
                  questionsArr: questionsArr,
                });
              }

              if (response.data.eventInvitedVendorData) {
                let row_data = [];
                let inviteVendorName = [];
                response.data.eventInvitedVendorData.map((vendor_list) => {
                  row_data.push({
                    invite_vendor_name: vendor_list.company_name,
                    invite_vendor_email: vendor_list.email,
                    invite_vendor_id: vendor_list.vendor_id,
                    is_invitation_sent: vendor_list.is_invitation_sent
                      ? "Sent"
                      : "Not Sent",
                  });

                  inviteVendorName = [
                    ...inviteVendorName,
                    {
                      value: vendor_list.vendor_id,
                      label: vendor_list.company_name,
                    },
                  ];
                });

                this.setState({
                  rowVendorData: row_data,
                  inviteVendorName: inviteVendorName,
                });
              }

              if (response.data.eventApproverData) {
                let approverEmail = localStorage.getItem("email");
                let approvers = [];
                response.data.eventApproverData.map((approver_list) => {
                  approvers = [
                    ...approvers,
                    {
                      value: approver_list.approver_id,
                      label: approver_list.name,
                    },
                  ];
                  if (
                    approver_list.email === approverEmail &&
                    approver_list.is_event_approved === 1
                  ) {
                    this.setState({
                      disableApproverButton: "disable",
                    });
                  }
                });
                this.setState({
                  approvers: approvers,
                  rowApprData: response.data.eventApproverData,
                });
              }
            }
            if(response.data.eventApproverData && response.data.eventApproverData.length > 0){
              let approverEmail = localStorage.getItem("email");
              let approverSequence;
              response.data.eventApproverData.forEach((each, index)=>{
                if(each.email === approverEmail){
                  approverSequence = index
                }
              })

              if(approverSequence && approverSequence === 0){
                // disableApproverButton
                this.setState({
                  disableApproverButton: null
                })
              }else{
                let previousApprovers = response.data.eventApproverData.slice(0, approverSequence);
                for (let eachApprover of previousApprovers) {
                  if (eachApprover.is_event_approved === 0) {
                    this.setState({
                      disableApproverButton: "disabled"
                    })
                    break;
                  }
                }
              }

              
              

            }
          }
        });

      this.setState({
        // rfqEventId: this.props.match.params.id,
        // isIdGenerated: true,
        loadshow: "show-n",
        overly: "show-n",
      });
    }
  }

  approveEvent = () => {
    // console.log(this.state.rfqEventId);
    let payload = {
      rfq_event_id: this.state.rfqEventId,
      email: localStorage.getItem("email"),
    };
    redirectURL
      .post("/procurement/checkAndApproveEvent", payload)
      .then((resp) => {
        console.log("checkAndApprove resp", resp.data);
        this.setState({
          show1: true,
          basicType1:
            resp.data.message === "Event can't be approved"
              ? "danger"
              : "success",
          basicTitle1: resp.data.message,
        });
      })
      .catch((err) => {
        console.log("checkAndApprove err", err);
      });
  };

  requestChange = () => {
    if (this.state.rfqRemarks) {
      console.log("this.state.rfqEventId", this.state.rfqEventId);
      console.log("this.state.rfqRemarks", this.state.rfqRemarks);
      let payload = {
        rfq_event_id: this.state.rfqEventId,
        rfq_remarks: this.state.rfqRemarks,
        email: localStorage.getItem("email")
      };

      redirectURL
        .post("/procurement/requestChange", payload)
        .then((resp) => {
          console.log("requestChange data", resp.data);
          if( resp.data.status === "success"){
            this.setState({
              show1 : true, 
              basicTitle1 : "Changes are suggested to Organizer",
              basicType1 : "success"
            })
          }
        })
        .catch((err) => {
          console.log("requestChange err", err);
        });
    } else {
      return;
    }
  };

  closeAlert1 = () => {
    this.setState(
      {
        show1: false,
      },
      () => {
        if (this.state.basicTitle1 === "Event is Approved" || this.state.basicTitle1 === "Changes are suggested to Organizer") {
          this.props.match.navigate("/admin-dashboard");
        }
      }
    );
  };

  render() {
    const cellClassRules = {
      "cell-green": (params) => params.value == "Sent",
      "cell-red": (params) => params.value == "Not Sent",
    };
    const columnWithDefs = [
      {
        headerName: "Item Code",
        field: "rfq_item_code",
        width: 100,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Item/Product/Service",
        field: "rfq_item",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Target Price",
        field: "rfq_item_target_price",
        width: 110,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Quantity",
        field: "rfq_item_quantity",
        width: 75,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "UOM",
        field: "rfq_item_uom",
        width: 75,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Description",
        field: "rfq_item_description",
        width: 200,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Delivery Location",
        field: "rfq_item_delivery_location",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "TAT",
        field: "rfq_item_tat_days",
        width: 50,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Remarks",
        field: "rfq_item_remarks",
        width: 100,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //   headerName: "PO No.",
      //   field: "rfq_item_po_number",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Vendor Name",
      //   field: "rfq_item_search_vendor_name",
      //   width: 120,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "Unit Rate",
      //   field: "rfq_item_unit_rate",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "PO Date",
      //   field: "rfq_item_po_date",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
      // {
      //   headerName: "PO Value",
      //   field: "rfq_item_po_value",
      //   width: 100,
      //   hide: false,
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "",
      //   resizable: true,
      // },
    ];
    const columnWithVendorPreviewDetails = [
      {
        headerName: "Vendor Id",
        field: "invite_vendor_id",
        width: 250,
        hide: true,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vendor Name",
        field: "invite_vendor_name",
        width: 320,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vendor Email",
        field: "invite_vendor_email",
        width: 250,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Invitation Status",
        cellClassRules: cellClassRules,
        field: "is_invitation_sent",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //     headerName: "Target Price",
      // 	field: "rfq_item_target_price",
      // 	width: 110,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
      // {
      //     headerName: "Quantity",
      // 	field: "rfq_item_quantity",
      // 	width: 75,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
    ];
    return (
      <>
        <SweetAlert
          show={this.state.show1}
          type={this.state.basicType1}
          title={this.state.basicTitle1}
          onConfirm={this.closeAlert1}
        ></SweetAlert>
        <Modal
          open={this.state.openPreviewModal}
          onClose={this.closePreviewModal}
        >
          <div aria-disabled={true}>
            <h6
              className="bg-primary text-white"
              style={{
                paddingTop: "10px",
                height: "38px",
                paddingLeft: "253px",
              }}
            >
              File Preview
            </h6>
            {this.state.documentType === "pdf" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "csv" ||
            this.state.documentType === "xlsx" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "png" ||
            this.state.documentType === "jpeg" ||
            this.state.documentType === "jpg" ? (
              <div className="p-3">
                <img
                  src={this.state.documentUrl}
                  // frameborder="1"
                  height="500px"
                  width="100%"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>
        <div>
          {/* Card-1 Starts Here */}
          <div
            className="new__reference__form__container"
            style={{ width: "70vw", minHeight: "48vh" }}
          >
            <div className="new__reference__form__header">
              <div className="header__icon__container">
                <img src={StepOneIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 01/04</div>
                <div className="header__title">Reference</div>
              </div>
            </div>

            {/* First Row */}
            <div className="row" style={{ padding: "0px 4vw" }}>
              <div className="col-6">
                <div>
                  <label>
                    RFQ Subject <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">{this.state.rfqSubject}</div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label>
                    RFQ Description <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <div className="form-group">{this.state.rfqDesc}</div>
                </div>
              </div>
            </div>
            {/* Second Row */}
            <div className="row" style={{ padding: "10px 4vw" }}>
              <div className="col-6">
                <label>
                  Event Start Date<span className="text-danger">*</span>
                </label>
                <div className="event__schedule__datepicker">
                  <div className="start__date__picker">
                    {this.state.rfqStartDate}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <label>
                  Event End Date<span className="text-danger">*</span>
                </label>
                <div className="event__schedule__datepicker">
                  <div className="end__date__picker">
                    {this.state.rfqEndDate}
                  </div>
                </div>
              </div>
              {/* <div className="col-4">
                  <label>
                    Contract Starting Date <span className="redColor">*</span>
                  </label>
                  <div className="pickup__date__datepicker">
                    <div className="pickup__date__picker">
                      {this.state.rfqStartDate}
                    </div>
                  </div>
                </div> */}
            </div>
            {/* Third Row */}

            {/* <div className="row" style={{ padding: "0px 4vw" }}>
                <div className="col-4">
                  <label>Base Currency</label>
                  <div className="pickup__date__datepicker">
                    <div className="pickup__date__picker">
                      {this.state.baseCurrency.value
                        ? this.state.baseCurrency.value
                        : ""}
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
          {/* Card-1 Ends Here */}

          {/* Card-2 Starts Here */}
          <div
            className="new__product__details__form__container"
            style={{
              width: "70vw",
              minHeight: "48vh",
              paddingBottom: "25px",
            }}
          >
            {/* Form Header */}
            <div className="new__product__details__form__header">
              <div className="header__icon__container">
                <img src={StepTwoIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 02/04</div>
                <div className="header__title">Product Details</div>
              </div>
            </div>

            {/* Data */}
            <div
              style={{
                width: "100%",
                height: "475px",
                minHeight: "475px",
                padding: "0 3vw",
              }}
              className="ag-theme-alpine rfqDetails"
            >
              <AgGridReact
                // domLayout="autoHeight"
                headerClass="curved-header"
                rowData={this.state.rowPOData}
                columnDefs={columnWithDefs}
                defaultColDef={this.state.defaultColDef}
                enableRangeSelection={true}
                enableCharts={true}
                detailCellRendererParams={this.state.detailCellRendererParams}
                masterDetail={true}
                onGridReady={this.onGridReady}
                sideBar={this.state.sideBar}
                // frameworkComponents={this.state.frameworkComponents}
                // statusBar={this.state.statusBar}
                stopEditingWhenGridLosesFocus={true}
                paginationPageSize={10}
                pagination={true}
                animateRows={true}
                // allowScrolling={true}
                showNoRowsOverlay={false}
                suppressRowHoverHighlight={true}
                gridOptions={{
                  context: { componentParent: this },
                }}
              />
            </div>
          </div>
          {/* Card-2 Ends Here */}

          {/* Card-3 Starts Here */}
          <div
            className="additional__details__form__container"
            style={{
              width: "70vw",
              minHeight: "48vh",
              paddingBottom: "20px",
            }}
          >
            <div className="new__reference__form__header">
              <div className="header__icon__container">
                <img src={StepThreeIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 03/04</div>
                <div className="header__title">Additional Details</div>
              </div>
            </div>

            {/* Commercial Files Uploaded Info */}
            {/* <span
              style={{
                color: "blue",
                fontWeight: "bolder",
                padding: "0 3vw",
              }}
            >
              Commercial Terms
            </span>
            <div
              class="table-container"
              style={{ padding: "0 3vw", marginBottom: "20px" }}
            >
              <table
                class="table-scroll small-first-col"
                style={{
                  display: "block",
                  emptyCells: "show",
                  borderSpacing: 0,
                  overflow: "hidden",
                }}
              >
                <thead
                  style={{
                    backgroundColor: "black",
                    position: "relative",
                    display: "block",
                    width: "100%",
                    overflowY: "auto",
                  }}
                >
                  <tr style={{ width: "100%", display: "flex" }}>
                    <th id="tableHeader">
                      <p>File Name</p>
                    </th>
                    <th id="tableHeader" style={{ position: "sticky", top: 0 }}>
                      <p>File URL</p>
                    </th>
                  </tr>
                </thead>

                <tbody
                  style={{
                    display: "block",
                    position: "relative",
                    width: "100%",
                    overflowY: "scroll",
                    borderTop: "1px solid rgba(0,0,0,0.2)",
                    minHeight: "20vh",
                    maxHeight: "30vh",
                  }}
                >
                  {this.state.commercialTermsDocArr.map(function (feature, i) {
                    return (
                      <tr key={`commercialTermsTitle-${i}`}>
                        <td key={`commercialTermsTitle-${i}`}>
                          {feature.rfq_commercial_terms_title}
                        </td>
                        <td key={`commercialTermsUrl-${i}`}>
                          {feature.rfq_commercial_terms_doc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
            <span
                className=""
                style={{ fontWeight: "bold", marginLeft: "3vw", color: "blue" }}
              >
                Commercial Terms
              </span>

              <div
                className="termsContainer"
                style={{
                  height: "325px",
                  overflowY: "auto",
                  margin: "20px 4vw 40px",
                  border: "1px solid black",
                }}
              >
                {this.state.commercialTermsDocArr.map((each, index) => {
                  let {
                    rfq_commercial_terms_title,
                    rfq_commercial_terms_doc,
                    _id,
                  } = each;
                  return (
                    <>
                      <div class="row" key={`term-${index}`}>
                        <div class="col-6" style={{ paddingTop: "33px" }}>
                          <input
                            type="text"
                            value={rfq_commercial_terms_title}
                            className="input-style"
                            style={{ width: "100%", paddingLeft: "16px" }}
                            disabled
                          />
                        </div>

                        <div class="col-4 txt-center">
                          <label for="">
                            <label
                              className="upload__button upload"
                              style={{ zIndex: 1, width: "170px" }}
                              // onClick={this.docUploadBtnHandler.bind(
                              //   this,
                              //   "commercialTermsDoc"
                              // )}
                              onClick={this.previewDocument.bind(
                                this,
                                rfq_commercial_terms_doc
                              )}
                            >
                              {/* <img src={ClipIcon} alt="" /> */}
                              <i
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                class="fa fa-2x fa-eye txt-black"
                                aria-hidden="true"
                              ></i>
                              <h6 style={{ position: "relative", top: "4px" }}>
                                Preview Doc
                              </h6>
                            </label>

                            <input
                              type="file"
                              name="commercialTermsDoc"
                              id="commercialTermsDoc"
                              // onChange={this.changeCommercialTermsFileHandler.bind(
                              //   this
                              // )}
                              className=""
                              style={{ display: "none" }}
                              required
                            />
                          </label>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            {/* Commercial Files Uploaded Info */}

            {/* <span
              style={{
                color: "blue",
                fontWeight: "bolder",
                padding: "0 3vw",
              }}
            >
              Questions
            </span>
            <div
              class="table-container"
              style={{ padding: "0 3vw", marginBottom: "20px" }}
            >
              <table
                class="table-scroll small-first-col"
                style={{
                  display: "block",
                  emptyCells: "show",
                  borderSpacing: 0,
                  overflow: "hidden",
                }}
              >
                <thead
                  style={{
                    backgroundColor: "black",
                    position: "relative",
                    display: "block",
                    width: "100%",
                    overflowY: "auto",
                  }}
                >
                  <tr style={{ width: "100%", display: "flex" }}>
                    <th id="tableHeader">
                      <p>Question</p>
                    </th>
                  </tr>
                </thead>

                <tbody
                  style={{
                    display: "block",
                    position: "relative",
                    width: "100%",
                    overflowY: "scroll",
                    borderTop: "1px solid rgba(0,0,0,0.2)",
                    minHeight: "20vh",
                    maxHeight: "30vh",
                  }}
                >
                  {this.state.questionsArr.map(function (feature, i) {
                    return (
                      <tr key={`questionsTitle-${i}`}>
                        <td key={`questionsTitle--${i}`}>
                          {feature.rfq_questions_title}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
            <span
                className=""
                style={{ fontWeight: "bold", marginLeft: "3vw", color: "blue" }}
              >
                Questions
              </span>
              <div
                className="termsContainer"
                style={{
                  height: "325px",
                  overflowY: "auto",
                  margin: "20px 4vw 20px",
                  border: "1px solid black",
                }}
              >
                {this.state.questionsArr.map((each, index) => {
                  let { rfq_questions_title, _id } = each;
                  return (
                    <>
                      <div class="row" key={`questionterm-${index}`}>
                        <div class="col-9" style={{ paddingTop: "33px" }}>
                          <input
                            type="text"
                            value={rfq_questions_title}
                            className="input-style"
                            style={{ width: "100%", paddingLeft: "16px" }}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            {/* Questions Uploaded Info */}
          </div>
          {/* Card-3 Ends Here */}

          {/* Card-4 Starts Here */}
          <div
            className="new__invite__vendors__container"
            style={{
              width: "70vw",
              minHeight: "48vh",
              paddingBottom: "30px",
            }}
          >
            <div className="new__reference__form__header">
              <div className="header__icon__container">
                <img src={StepFourIcon} alt="" />
              </div>
              <div className="header__title__container">
                <div className="header__subtitle">Step 04/04</div>
                <div className="header__title">Participants Details</div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "400px",
                padding: "0px 3vw",
              }}
              className="ag-theme-alpine rfqDetails"
            >
              <AgGridReact
                rowData={this.state.rowVendorData}
                columnDefs={columnWithVendorPreviewDetails}
                defaultColDef={this.state.defaultColDef}
                enableRangeSelection={true}
                enableCharts={true}
                detailCellRendererParams={this.state.detailCellRendererParams}
                masterDetail={true}
                onGridReady={this.onGridReady}
                sideBar={this.state.sideBar}
                // frameworkComponents={this.state.frameworkComponents}
                // statusBar={this.state.statusBar}
                stopEditingWhenGridLosesFocus={true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                gridOptions={{
                  context: {
                    componentParent: this,
                  },
                }}
              />
            </div>
          </div>
          {/* Card-4 Ends Here */}
        </div>

        <div
          className="new__invite__vendors__container"
          style={{
            width: "70vw",
            minHeight: "17vh",
          }}
        >
          <div className="row" style={{ padding: "0px 3vw" }}>
            <div className="col-12">
              <div>
                <label>Remarks</label>
              </div>
              <div>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control pb-4"
                    style={{
                      resize: "none",
                      borderRadius: "10px",
                      overflowY: "hidden",
                      height: "75px",
                      paddingTop: "10px",
                    }}
                    name="rfqRemarks"
                    value={this.state.rfqRemarks}
                    onChange={(e) =>
                      this.setState({ rfqRemarks: e.target.value }, () => {
                        if (this.state.rfqRemarks) {
                          this.setState({
                            disableRequestChangeButton: null,
                          });
                        } else {
                          this.setState({
                            disableRequestChangeButton: "disabled",
                          });
                        }
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mb-3">
          <div class="col-1">
            <button
              class="btn btn-primary"
              style={{
                height: "51px",
                borderRadius: "10px",
                width: "90px",
                cursor: this.state.disableApproverButton
                  ? "not-allowed"
                  : "pointer",
              }}
              onClick={this.approveEvent}
              disabled={this.state.disableApproverButton}
            >
              Approve
            </button>
          </div>
          <div class="">
            <button
              class="btn btn-info"
              style={{
                height: "51px",
                borderRadius: "10px",
                width: "151px",
                cursor: this.state.disableRequestChangeButton
                  ? "not-allowed"
                  : "pointer",
              }}
              onClick={this.requestChange}
              disabled={this.state.disableRequestChangeButton}
            >
              Request Change
            </button>
          </div>
          <div class="col-1">
            <button
              class="btn btn-secondary"
              style={{
                height: "51px",
                borderRadius: "10px",
                width: "90px",
              }}
            >
              <Link
                to={"/admin-dashboard"}
                style={{ color: "white", textDecoration: "none" }}
              >
                Close
              </Link>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(RfqPreviewAndApprovePage);
