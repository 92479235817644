import React, { Component } from 'react';
import Select from 'react-select';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM } from "../utils";
import CSVFileValidator from 'csv-file-validator'

export default class UploadBillingTrips extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showApiInp: "show-n",
			csvcontent: "",
			isuploadcsv: 0,
			defaultColDef: {
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable: true,
				sortable: true
			},
			frameworkComponents: {},
			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{ field: 'callId' },
						{ field: 'direction' },
						{ field: 'number', minWidth: 150 },
						{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
						{ field: 'switchCode', minWidth: 150 },
					],
					defaultColDef: {
						flex: 1,
					},
				},
				getDetailRowData: (params) => {
					params.successCallback(params.data.callRecords);
				},
			},
			rowData: [],
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			showgrid: "show-n"
		}
		this.uploadCSVFile = this.uploadCSVFile.bind(this);
		this.changeCSVFile = this.changeCSVFile.bind(this);
		this.loadTrips = this.loadTrips.bind(this);
	}
	componentDidMount() {
		redirectURL.post("/billing/configurations")
			.then((response) => {
				//console.log("response ", response.data)
				var records = response.data.records;
				if (records.length > 0) {
					if (records[0].trips_data_from == "api") {
						this.setState({
							isuploadcsv: 0
						})
						this.loadTrips();
					}
					else {
						this.setState({
							isuploadcsv: 1
						})
						this.loadTrips();
					}
				}
				else {
					redirectToConfigurations()
				}
			})
	}
	loadTrips() {
		var params = {
			uploadtype: "csv"
		}
		redirectURL.post("/billing/trips", params)
			.then((response) => {
				// console.log("response ", response.data)
				var records = response.data.records;
				if (records.length > 0) {
					this.setState({
						showgrid: "show-m"
					})
				}
				else {

					this.setState({
						showgrid: "show-n"
					})
				}
				this.setState({
					rowData: records
				})

			})
	}
	// changeSelectItem(column,opt)
	// {
	//     // console.log("columnevent ", column)
	//     // console.log("column ", opt)
	//     this.setState({
	//         [column]:opt
	//     })
	// }
	changeCSVFile(e) {
		const config = {
			headers: [
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'recent_dealer_reported',
					inputName: 'recent_dealer_reported',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'consigner_name',
					inputName: 'consigner_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'consignee_code',
					inputName: 'consignee_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'consignee_name',
					inputName: 'consignee_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'consignee_state',
					inputName: 'consignee_state',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'consignee_city',
					inputName: 'consignee_city',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				
				{
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'transporter_name',
					inputName: 'transporter_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'total_travelled_trip_dist_km',
					inputName: 'total_travelled_trip_dist_km',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'trip_duration_days',
					inputName: 'trip_duration_days',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'total_approved_cost',
					inputName: 'total_approved_cost',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				},
				{
					name: 'data_from',
					inputName: 'data_from',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
					}
				}
			]

		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent: csvData.data
				});
			})
			.catch(err => { })


	}
	uploadCSVFile(event) {
		event.preventDefault();
		// console.log(this.state.csvcontent)
		if (this.state.csvcontent != "" && this.state.csvcontent != undefined) {
			var params = {
				csvcontent: JSON.stringify(this.state.csvcontent)
			}

			console.log(this.state.csvcontent)
			redirectURL.post("/billing/savecsvtrips",params)
			.then((response) => {
			    if(response.data.status == "success")
			    {
			        redirectToTripsSummaryPage()  
			    }
			})
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Please upload csv file",
				basicType: "danger"
			})
		}
	}
	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

	};
	render() {
		var columnDefs = [
			{
				headerName: Constants.TRIP_TRUCK_NO,
				field: "truck_no",
				width: 120,
			},
			{
				headerName: Constants.TRIP_CONSIGN_NO,
				field: "consignment_code",
				width: 160,
			},
			// {
			//     headerName:Constants.TRIP_INV_DATE,
			//     field:"invoice_date",
			//     width:140,
			//     valueGetter:function(params){
			//         try{
			//             if(params.data.invoice_date != undefined && params.data.invoice_date != "")
			//             {
			//                 return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
			//             }

			//         }
			//         catch(e){}
			//     }
			// },
			{
				headerName: Constants.TRIP_REACHED_DEST_DATE,
				field: "recent_dealer_reported",
				width: 140,
				valueGetter: function (params) {
					try {
						if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "") {
							return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
						}

					}
					catch (e) { }
				}
			},
			{
				headerName: Constants.TRIP_PLANT_NAME,
				field: "consigner_name",
				width: 100,
			},
			{
				headerName: Constants.TRIP_CUSTOMER_CODE,
				field: "consignee_code",
				width: 100,
			},
			{
				headerName: Constants.TRIP_CUSTOMER_NAME,
				field: "consignee_name",
				width: 180,
			},
			{
				headerName: Constants.TRIP_CUSTOMER_CITY,
				field: "consignee_city",
				width: 180,
			},
			{
				headerName: Constants.TRIP_CUSTOMER_STATE,
				field: "consignee_state",
				width: 180,
			},
			{
				headerName: Constants.TRIP_TRANSPORTER_CODE,
				field: "transporter_code",
				width: 180,
			},
			{
				headerName: Constants.TRIP_TRANSPORTER_NAME,
				field: "transporter_name",
				width: 180,
			},
			// {
			//     headerName:Constants.TRIP_TOTAL_DISTANCE_KM,
			//     field:"total_distance_travelled_km",
			//     width:140,
			//     valueGetter:function(params){
			//         try{
			//             if(params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "")
			//             {
			//                 return parseInt(parseInt(params.data.total_distance_travelled_km)/1000);
			//             }
			//         }
			//         catch(e){}
			//     }
			// },
			{
				headerName: Constants.TRIP_TOTAL_DISTANCE_KM,
				field: "total_travelled_trip_dist_km",
				width: 100,
			},
			{
				headerName: Constants.TRIP_DURATION_DAYS,
				field: "trip_duration_days",
				width: 100,
			},
			{
				headerName: "Total Approved Cost",
				field: "total_approved_cost",
				width: 100,
			},
			{
				headerName: "Data From",
				field: "data_from",
				width: 100,
			}
		]

		return (
			<div className="container-fluid">
				<div className="row">
					<h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="fa fa-upload cus-i"></i>&nbsp;<span>Upload Billing Trips Data</span>
                        <a href={"/trips"} className={"btn btn-success float-right " + (this.state.showgrid)}>Go To Trips Screen</a>
                    </h5>
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
								<div className="row">
									<div className="col-xl-4">
										<form onSubmit={this.uploadCSVFile}>
											<div className="row">
												<label className="col-xl-12">Upload CSV file
													<a href={require("../../assets/json/sampletempleteforuploadingtrips.csv")} target={"_blank"} className="f11 btn btn-warning float-right">
													<i className="fa fa-download"></i>&nbsp;Download Sample File</a>
												</label>
												<input type="file" name="csvcontent" onChange={this.changeCSVFile} className="form-control col-xl-12 ml-3" required />
												
											</div>
											<div className="form-group col-sm-12 mb-20p text-center">
												
											</div>
											<div className="form-group">
												<button type="submit" className="btn btn-success">Save &amp; Import</button>
											</div>
										</form>
									</div>

									<div className="col-xl-8" style={{ padding: "20px" }} id="csvdata"></div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div className={"row " + (this.state.showgrid)}>

					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
								<div style={{ width: "100%", height: "60vh" }} className="ag-theme-balham">
									<AgGridReact
										rowData={this.state.rowData}
										columnDefs={columnDefs}
										defaultColDef={this.state.defaultColDef}
										enableRangeSelection={true}
										enableCharts={true}
										detailCellRendererParams={this.state.detailCellRendererParams}
										masterDetail={true}
										onGridReady={this.onGridReady}
										sideBar={this.state.sideBar}
										frameworkComponents={this.state.frameworkComponents}
										statusBar={this.state.statusBar}
										stopEditingWhenGridLosesFocus={true}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										gridOptions={{
											context: { componentParent: this }
										}}

									/>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		);
	}
}
function redirectToTripsSummaryPage() {
	window.location.href = "/trips"
}
function redirectToConfigurations() {
	window.location.href = "/configurations"
}