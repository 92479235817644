import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import "./VendorRegistration.css";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SweetAlert from "react-bootstrap-sweetalert";
import redirectURL from "../redirectURL";
import { v4 as uuidv4 } from "uuid";
import withRouter from "../withRouter";
import Favourites from "../common/favouritesComponent";
class VendorRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVendorRegisteredByMailLink: 0,
      show: false,
      basicType: "default",
      basicTitle: "",
      vendor_address: "",
      company_name: "",
      contact_person: "",
      userRole: localStorage.getItem("role"),
      userEmail: localStorage.getItem("email"),
      panNumberInputDisabled: false,
      gstNumberInputDisabled: false,
      identificationType: "",
      item_categories: "",
      country: { value: "USA", label: "USA" },
      state: "",
      city: "",
      mobileNumberCode: { value: "+1", label: "(+1) USA" },
      mobile_no: "",
      identificationValue: "",
      zip_code: "",
      pan_no: "",
      gst_no: "",
      companyPhoneCode: { value: "+1", label: "(+1) USA" },
      alternate_mobile_no: "",
      email: "",
      alternate_email: "",
      preferredTimeZone: [
        { value: "-05:00", label: "America/NewYork" },  // Eastern Time
        { value: "-06:00", label: "America/Chicago" },   // Central Time
        { value: "-07:00", label: "America/Denver" },    // Mountain Time
        { value: "-08:00", label: "America/Los_Angeles" } // Pacific Time
      ],
      venderRegistrationFormVisibility: "show-n",
      alternateEmailCheckBoxStatus: false,
      is_activeCheckBoxStatus: false,
      isIdGenerated: false,
      isFormSubmitted: 0,
      vendorFormButtonName: "Submit",
      updateTimeDisable: false,
      actionType: "Edit",
      backgroundColor: "green",
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      rowData: [],
      paginationPageSize: 100,
    };
  }

  componentDidMount = () => {
    console.log(this.props)
    if (this.state.userRole === "admin") {
      redirectURL.get("/procurement/getAllVendors").then((resp) => {
        console.log("getAllVendors", resp.data.allVendors);
        this.setState({
          rowData: resp.data.allVendors,
        });
      });
    }
    if (this.state.userRole !== "admin") {
      this.setState({ venderRegistrationFormVisibility: "show-m" });
    }

    // this.setState({
    //   rowData: [
    //     {
    //       vendorId: "123qweASDZXC",
    //       action: "Edit",
    //       created_by: "Admin",
    //       extended: "YES",
    //       company_name: "Enmovil",
    //       contact_person: "Govind",
    //       vendor_address: "Gachibowli",
    //       city: "Hyderabad",
    //       pan_no: "CVBPM0358P",
    //       gst_no: "CVBPM0358P12345",
    //       mobile_no: "9390786924",
    //       alternate_mobile_no: "9390786924",
    //       email: "dummy@gmail.com",
    //       alternate_email: "dummy@gmail.com",
    //       is_active: "true",
    //     },
    //   ],
    // });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  dropDown = (filterType, selOption) => {
    this.setState({
      [filterType]: selOption,
      venderRegistrationFormVisibility: "show-n",
      identificationValue: "",
    });
  };

  newFilter = (filterType, selOption) => {
    this.setState({
      [filterType]: selOption,
    });
  };

  onSubmitRegisterVendorForm = (e) => {
    e.preventDefault();
    var invalidDataCount = 0;
    
    var vendorData = {
      company_name: this.state.company_name,
      contact_person: this.state.contact_person,
      vendor_address: this.state.vendor_address,
      item_categories: this.state.item_categories,
      country: this.state.country.value,
      state: this.state.state.value,
      city: this.state.city.value,
      mobileNumberCode: this.state.mobileNumberCode.value,
      companyPhoneCode: this.state.companyPhoneCode.value,
      email: this.state.email,
      preferredTimeZone: this.state.preferredTimeZone.value,
    };

    if(this.props.match.params.hasOwnProperty("rfq_event_id") && this.props.match.params.rfq_event_id){
      vendorData["rfq_event_id"] = this.props.match.params.rfq_event_id
    }

    if (this.state.is_activeCheckBoxStatus) {
      vendorData.is_active = "YES";
    } else {
      vendorData.is_active = "NO";
    }

    if (
      this.state.userEmail !== "" &&
      this.state.userEmail !== undefined &&
      this.state.userEmail !== null
    ) {
      vendorData.created_by = this.state.userEmail;
    }

    if (this.state.pan_no.length === 10) {
      vendorData.pan_no = this.state.pan_no;
    } else {
      invalidDataCount++;
    }

    if (this.state.gst_no.length === 15) {
      vendorData.gst_no = this.state.gst_no;
    } else {
      invalidDataCount++;
    }

    if (this.state.zip_code.length === 6) {
      vendorData.zip_code = this.state.zip_code;
    } else {
      invalidDataCount++;
    }

    if (this.state.mobile_no.length === 10) {
      vendorData.mobile_no = this.state.mobile_no;
    } else {
      invalidDataCount++;
    }

    if (this.state.alternate_mobile_no.length === 10) {
      vendorData.alternate_mobile_no = this.state.alternate_mobile_no;
    } else {
      invalidDataCount++;
    }

    if (this.state.alternateEmailCheckBoxStatus) {
      vendorData.alternate_email = this.state.alternate_email;
    } else {
      vendorData.alternate_email = this.state.email;
    }

    if (invalidDataCount !== 0) {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data is in invalid format",
      });
    }

    if (
      invalidDataCount === 0 &&
      this.state.vendorFormButtonName === "Submit"
    ) {
      var vendorID = uuidv4();
      vendorData.vendor_id = vendorID;
      redirectURL
        .post("/procurement/registerVendor", vendorData)
        .then((response) => {
          console.log("registerVendor Response", response);
          if(response.data.insertedCount === 1 && response.data.registeredVendorData.hasOwnProperty("self_registration") && response.data.registeredVendorData.self_registration === 1){
            this.setState({
              isVendorRegisteredByMailLink: 1
            })

          }
          if (response.data.insertedCount === 1) {
            this.componentDidMount();
            this.setState({
              // rowData: response.data.allVendors,
              show: true,
              basicType: "success",
              basicTitle: "Vendor is registered successfully",

              company_name: "",
              contact_person: "",
              vendor_address: "",
              // panNumberInputDisabled: false,
              // gstNumberInputDisabled: false,
              // identificationType: "",
              item_categories: "",
              // country: { value: "India", label: "India" },
              state: "",
              city: "",
              // mobileNumberCode: { value: "+91", label: "(+91) India" },
              zip_code: "",
              pan_no: "",
              mobile_no: "",
              gst_no: "",
              // companyPhoneCode: { value: "+91", label: "(+91) India" },
              alternate_mobile_no: "",
              email: "",
              alternate_email: "",
              venderRegistrationFormVisibility: "show-n",
              is_activeCheckBoxStatus: false,
            });
          } else if (response.data.insertedCount === 0) {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Vendor already exists",
            });
          }
        })
        .catch((err) => {
          console.log("registerVendor Error", err);
        });
    }

    if (
      invalidDataCount === 0 &&
      this.state.vendorFormButtonName === "Update"
    ) {
      redirectURL
        .post("/procurement/updateVendor", vendorData)
        .then((response) => {
          console.log("registerVendor Response", response);
          if (response.data.success === true) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Vendor details are modified successfully",
              updateTimeDisable:false,
              company_name: "",
              contact_person: "",
              vendor_address: "",
              // panNumberInputDisabled: false,
              // gstNumberInputDisabled: false,
              // identificationType: "",
              item_categories: "",
              // country: { value: "India", label: "India" },
              state: "",
              city: "",
              // mobileNumberCode: { value: "+91", label: "(+91) India" },
              zip_code: "",
              pan_no: "",
              mobile_no: "",
              gst_no: "",
              // companyPhoneCode: { value: "+91", label: "(+91) India" },
              alternate_mobile_no: "",
              email: "",
              alternate_email: "",
              venderRegistrationFormVisibility: "show-n",
              is_activeCheckBoxStatus: false,
            })
            this.componentDidMount();
          }
        })
        .catch((err) => {
          console.log("registerVendor Error", err);
        });
    }

    console.log(vendorData);
  };

  handleAlternateEmailCheckBoxStatus = () => {
    this.setState({
      alternateEmailCheckBoxStatus: !this.state.alternateEmailCheckBoxStatus,
    });
  };

  handleIsActiveCheckBoxStatus = () => {
    this.setState({
      is_activeCheckBoxStatus: !this.state.is_activeCheckBoxStatus,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridState = () => {
    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();

    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
  };

  handleProceedButton = () => {
    if (
      this.state.identificationType.value === "PAN" &&
      this.state.identificationValue.length === 10
    ) {
      this.setState({
        venderRegistrationFormVisibility: "show-m",
        pan_no: this.state.identificationValue,
        panNumberInputDisabled: true,
        gstNumberInputDisabled: false,
        gst_no: "",
        updateTimeDisable:false
      });
    } else if (
      this.state.identificationType.value === "GST" &&
      this.state.identificationValue.length === 15
    ) {
      this.setState({
        venderRegistrationFormVisibility: "show-m",
        gst_no: this.state.identificationValue,
        gstNumberInputDisabled: true,
        panNumberInputDisabled: false,
        pan_no: "",
      });
    } else {
      this.setState({ venderRegistrationFormVisibility: "show-n" });
    }
  };

  handleEditFunction = (params) => {
    console.log(params.data);
    var items_categories_options = [];
    params.data.item_categories.forEach((each) => {
      var eachCategory = { value: each, label: each };
      items_categories_options.push(eachCategory);
    });
    if (params.data.is_active === "YES") {
      this.setState({
        is_activeCheckBoxStatus: true,
      });
    } else if (params.data.is_active === "NO") {
      this.setState({
        is_activeCheckBoxStatus: false,
      });
    }
    this.setState({
      updateTimeDisable: true,
      gstNumberInputDisabled: true,
      vendorFormButtonName: "Update",
      venderRegistrationFormVisibility: "show-m",
      company_name: params.data.company_name,
      contact_person: params.data.contact_person,
      vendor_address: params.data.vendor_address,
      item_categories: items_categories_options,
      state: { value: params.data.state, label: params.data.state },
      city: { value: params.data.city, label: params.data.city },
      zip_code: params.data.zip_code,
      pan_no: params.data.pan_no,
      gst_no: params.data.gst_no,
      mobile_no: params.data.mobile_no,
      alternate_mobile_no: params.data.alternate_mobile_no,
      email: params.data.email,
      alternate_email: params.data.alternate_email,
    });
  };

  render() {
    const columnwithDefs = [
      {
        headerName: "Action",
        field: "action",
        width: 100,
        filter: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <button
              style={{
                backgroundColor: this.state.backgroundColor,
                cursor: "pointer",
                width: "76px",
                textAlign: "center",
                border: "none",
                color: "white",
              }}
              onClick={this.handleEditFunction.bind(this, params)}
            >
              <span className="f13">
                <i
                  className="icofont icofont-ui-edit mr-1"
                  style={{ color: "white" }}
                ></i>
                {this.state.actionType}
              </span>
            </button>
          );
        },
      },
      // {
      //   headerName: "Created By",
      //   field: "created_by",
      //   width: 100,
      //   filter: true,
      //   resizable: true,
      // },
      // {
      //   headerName: "Extended",
      //   field: "extended",
      //   width: 100,
      //   filter: true,
      //   resizable: true,
      // },
      {
        headerName: "Company Name",
        field: "company_name",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Contact Person",
        field: "contact_person",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Address",
        field: "vendor_address",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "City",
        field: "city",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "PAN No.",
        field: "pan_no",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "GST No.",
        field: "gst_no",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Mobile No.",
        field: "mobile_no",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Phone No.",
        field: "alternate_mobile_no",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Email",
        field: "email",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Alternate E-mail",
        field: "alternate_email",
        width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Is Active",
        field: "is_active",
        width: 100,
        filter: true,
        resizable: true,
      },
    ];

    return (
      <div className="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        />
        <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
          <i className="fa fa-users cus-i"></i>&nbsp;<span>Register Participants</span>
          <Favourites />
        </h5>
        <div className="card shadow">
         

          {this.state.userRole === "admin" ? (
            <>
              <div className="row justify-content-between mt-3 px-3">
                <div className="col-3 pt-2">
                  <span style={{ float: "right" }}>
                    Unique Identification Number
                    <span className="text-danger">*</span>
                  </span>
                </div>
                <div className="col-3">
                  <Select
                    closeMenuOnSelect={true}
                    name="identificationType"
                    className="border-radius-0"
                    onChange={this.dropDown.bind(this, "identificationType")}
                    options={[
                      { value: "PAN", label: "PAN No." },
                      { value: "GST", label: "GST No." },
                    ]}
                    value={this.state.identificationType}
                    required
                  ></Select>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="identificationValue"
                      value={this.state.identificationValue}
                      onChange={(e) => {
                        this.setState({ identificationValue: e.target.value });
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleProceedButton}
                  >
                    Proceed
                  </button>
                </div>
              </div>
              <hr />
            </>
          ) : (
            <></>
          )}

          <div
            className={`row h-75 p-3 px-5 ${this.state.venderRegistrationFormVisibility}`}
          >
            <div className="col-12">
              <form
                className="user"
                onSubmit={this.onSubmitRegisterVendorForm.bind(this)}
              >
                {/*Company Name*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Company Name <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8 pt-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="company_name"
                        placeholder="Enter company name"
                        value={this.state.company_name}
                        onChange={(e) => {
                          if (this.state.company_name.length === 0) {
                            this.setState({
                              company_name: e.target.value.trim(),
                            });
                          } else {
                            this.setState({ company_name: e.target.value });
                          }
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*Contact Person*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Contact Person <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8 pt-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="contact_person"
                        placeholder="Enter contact person name"
                        value={this.state.contact_person}
                        onChange={(e) =>
                          this.setState({
                            contact_person: e.target.value.trim(),
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*Address*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Address <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8 pt-3">
                    <div className="form-group">
                      <textarea
                        type="text"
                        className="form-control pb-4"
                        name="vendor_address"
                        placeholder="Enter Company Address"
                        value={this.state.vendor_address}
                        onChange={(e) =>
                          this.setState({
                            vendor_address: e.target.value.trim(),
                          })
                        }
                        style={{ resize: "none" }}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*Items/Services*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Items Categories
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={true}
                        name="item_categories"
                        className="border-radius-0"
                        isMulti={true}
                        onChange={this.newFilter.bind(this, "item_categories")}
                        options={[
                          { value: "Manufacturing", label: "Manufacturing" },
                          { value: "Chemical", label: "Chemical" },
                        ]}
                        value={this.state.item_categories}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*Country*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Country <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={true}
                        name="country"
                        className="border-radius-0"
                        // onChange={this.newFilter.bind(this, "country")}
                        // options={[
                        //   { value: "India", label: "India" },
                        //   { value: "UAE", label: "UAE" },
                        // ]}
                        value={this.state.country}
                        required
                        isDisabled={true}
                      />
                    </div>
                  </div>
                </div>
                {/*State*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      State <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={true}
                        name="state"
                        className="border-radius-0"
                        onChange={this.newFilter.bind(this, "state")}
                        options={[
                          { value: "California", label: "California" },
                          { value: "Texas", label: "Texas" },
                          { value: "New York", label: "New York" },
                          { value: "Florida", label: "Florida" },
                          { value: "Illinois", label: "Illinois" }
                        ]}
                        
                        value={this.state.state}
                        isDisabled={this.state.updateTimeDisable}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*City*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      City <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={true}
                        name="city"
                        className="border-radius-0"
                        onChange={this.newFilter.bind(this, "city")}
                        options={[
                          { value: "New York", label: "New York" },
                          { value: "Los Angeles", label: "Los Angeles" },
                          { value: "Chicago", label: "Chicago" },
                          { value: "Houston", label: "Houston" },
                          { value: "Miami", label: "Miami" }
                        ]}
                        
                        value={this.state.city}
                        isDisabled={this.state.updateTimeDisable}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*Zip code*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Postal code <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8 pt-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="zip_code"
                        placeholder="Enter Postal code"
                        value={this.state.zip_code}
                        onChange={(e) => {
                          const input = e.target.value;
                          const regex = /^[0-9]{0,6}$/;
                          // var regex = /^[6,7,8,9][0-9]$/;
                          if (regex.test(input)) {
                            this.setState({ zip_code: input });
                          }
                        }}
                        disabled={this.state.updateTimeDisable}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*PAN No.*/}
                {/* <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      PAN No. <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8 pt-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="pan_no"
                        placeholder="Enter PAN Number"
                        value={this.state.pan_no}
                        disabled={this.state.panNumberInputDisabled}
                        onChange={(e) =>
                          this.setState({ pan_no: e.target.value.trim() })
                        }
                        required
                      />
                    </div>
                  </div>
                </div> */}
                {/*Mobile No.*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Mobile No. <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-2 pt-3">
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={true}
                        name="mobileNumberCode"
                        className="border-radius-0"
                        // onChange={this.newFilter.bind(this, "mobileNumberCode")}
                        // options={[
                        //   { value: "+91", label: "(+91) India" },
                        //   { value: "+1", label: "(+1) USA" },
                        // ]}
                        value={this.state.mobileNumberCode}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-6 pt-3 pl-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="mobile_no"
                        placeholder="Enter Mobile Number"
                        value={this.state.mobile_no}
                        autoComplete="off"
                        onChange={(event) => {
                          const input = event.target.value;
                          var regex = /^[3,4,5,6,7,8,9][0-9]{0,9}$/;
                          if (
                            event.nativeEvent.inputType ===
                            "deleteContentBackward"
                          ) {
                            this.setState({ mobile_no: input });
                          } else if (
                            event.nativeEvent.inputType === "insertText"
                          ) {
                            if (regex.test(input)) {
                              this.setState({ mobile_no: input });
                            }
                          }
                        }}
                        disabled={this.state.updateTimeDisable}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*GST No.*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      GST No. <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8 pt-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="gst_no"
                        placeholder="Enter GST Number"
                        value={this.state.gst_no}
                        disabled={this.state.gstNumberInputDisabled}
                        onChange={(e) =>
                          this.setState({ gst_no: e.target.value.trim() })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*Company Phone ==> select for country code and input for mobile number*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Company Phone <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-2 pt-3">
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={true}
                        name="companyPhoneCode"
                        className="border-radius-0"
                        // onChange={this.newFilter.bind(this, "companyPhoneCode")}
                        // options={[
                        //   { value: "+91", label: "(+91) India" },
                        //   { value: "+1", label: "(+1) USA" },
                        // ]}
                        value={this.state.companyPhoneCode}
                        isDisabled={true}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6 pt-3 pl-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="alternate_mobile_no"
                        placeholder="Enter Company Number"
                        value={this.state.alternate_mobile_no}
                        autoComplete="off"
                        onChange={(event) => {
                          const input = event.target.value;
                          var regex = /^[4,5,6,7,8,9][0-9]{0,9}$/;
                          if (
                            event.nativeEvent.inputType ===
                            "deleteContentBackward"
                          ) {
                            this.setState({ alternate_mobile_no: input });
                          } else if (
                            event.nativeEvent.inputType === "insertText"
                          ) {
                            if (regex.test(input)) {
                              this.setState({ alternate_mobile_no: input });
                            }
                          }
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*Company Email Alternate Email. check box and input for another email*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Company Email <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-3 pt-3">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter Company email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value.trim() })
                        }
                        required
                        disabled={this.state.updateTimeDisable}
                      />
                    </div>
                  </div>

                  <div className="col-2 text-right pl-3">
                    <label>
                      Alternate Email
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="ml-1 my-4"
                        onClick={this.handleAlternateEmailCheckBoxStatus}
                      />
                    </label>
                  </div>
                  <div className="col-3 pt-3 pl-3">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="alternate_email"
                        placeholder="Enter Alternate email"
                        value={this.state.alternate_email}
                        onChange={(e) =>
                          this.setState({
                            alternate_email: e.target.value.trim(),
                          })
                        }
                        disabled={!this.state.alternateEmailCheckBoxStatus}
                      />
                    </div>
                  </div>
                </div>
                {/*Preferred Time Zone || is_active Checkbox*/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-3 text-right pr-3">
                    <label>
                      Preferred Time Zone <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-5 pt-3">
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={true}
                        name="preferredTimeZone"
                        className="border-radius-0"
                        // onChange={this.newFilter.bind(
                        //   this,
                        //   "preferredTimeZone"
                        // )}
                        // options={[
                        //   { value: "+05:30", label: "Asia/Calcutta" },
                        //   { value: "+00:00", label: "Greenwich" },
                        // ]}
                        value={this.state.preferredTimeZone}
                        isDisabled={true}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2 text-right pl-3">
                    <label>
                      Is active
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="ml-1 my-4"
                        onClick={this.handleIsActiveCheckBoxStatus}
                        checked={this.state.is_activeCheckBoxStatus}
                      />
                    </label>
                  </div>
                </div>
                {/**/}
                <div className="row form-group no-gutters align-items-center mb-0">
                  <div className="col-1 mx-auto">
                    <button className="btn btn-warning">
                      {this.state.vendorFormButtonName}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="col-4">Types</div> */}
          </div>
        </div>

        {/*AG-GRID*/}
        {this.state.userRole === "admin" ? (
          <>
            <div className="card shadow p-2">
              <div
                id="myGrid"
                style={{ height: "400px", width: "100%" }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  columnDefs={columnwithDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.rowData}
                  enableCharts={true}
                  enableRangeSelection={true}
                  autoGroupColumnDef={this.state.autoGroupColumnDef}
                  onGridReady={this.onGridReady}
                  onGridState={this.onGridState}
                  statusBar={this.state.statusBar}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  sideBar={this.state.sideBar}
                  animateRows={true}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {
          this.state.isVendorRegisteredByMailLink === 1 ? <>
          <div className="card mb-3 shadow txt-center" style={{width: "50vw", margin:"auto", height: "200px", paddingTop: "50px"}}>
            <label for="">
            <i class="fa fa-2x fa-check txt-success"></i>
            </label>
             <label for="">Thanks for the registration, Login Details and Event Invitation Link have been sent to registered mail</label>
             <label for="">Please click <Link to={"/login"}>here</Link> to login </label>
          </div>
          </> : <></>
        }
      </div>
    );
  }
}

export default withRouter(VendorRegistration);
