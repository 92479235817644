import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; 
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-balham.css'; 
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMYYYY} from "../utils";
import { createStaticHandler } from '@remix-run/router';
import ViewPOD from "./viewpodbutton";
import moment from "moment";
import IOCLChart from "./IOCLCharts";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Favourites from "../common/favouritesComponent";
const weekOfTheMonth = (myMomentDate) => {
    const startDay = moment(myMomentDate).startOf('week');
    const day = parseInt(startDay.format('DD'),10);
    if(day > 28){
      return 5;
    }

    if((day > 21) && (day <= 28) ){
      return 4;
    }

    if((day > 14) && (day <= 21) ){
      return 3;
    }

    if((day > 7) && (day <= 14) ){
      return 2;
    }

    return 1;
  }
export default class BillingDieselPrices extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
				sortable:true
			},
            frameworkComponents:{
                ViewPOD:ViewPOD
            },
            paginationPageSize:200,
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            columnchartseries:[],
            columnchartcategories:[],
            from_date:"",
            to_date:"",
            cities:[],
            city:{"value":"","label":"Select"},
            rocode:{"value":"","label":"Select"},
            columnDefs:[],
            rocodes:[],
            statelist:[],
            dState:{"value":"ALL","label":"ALL"},            
            overlay:"show-n",
            vdata:"btn-active",
            vsummary:"btn-default",
            vdatash:"show-m",
            vsummarysh:"show-n"
        }
        this.loadData = this.loadData.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.loadCols = this.loadCols.bind(this);
        // this.loadRows = this.loadRows.bind(this);
    }
	componentDidMount(){
        $( ".datepicker" ).datepicker({
            dateFormat:"dd-mm-yy"
        });
        var weekOfCurrentMonth = (moment().week() - (moment().month()*4));
    
        var sdate = moment.parseZone().subtract(7,"days").format('DD-MM-YYYY');
        var edate = moment.parseZone().format('DD-MM-YYYY');
        $("#from_date").val(sdate);
        $("#to_date").val(edate);
        this.setState({
            from_date:sdate,
            to_date:edate,
            overlay:"show-m"
        })
        redirectURL.post("/billing/configurations")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {            
                var p = {
                    startDate:sdate,
                    endDate:edate,
                    diesel_state:this.state.dState.value
                }
                this.loadData(p);
            
            }
            else{
                redirectToConfigurations()
            }
		})
	}
    
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    
    changeHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    formSubmit(event){
        event.preventDefault();
        var startDate = $("#from_date").val();
        var endDate = $("#to_date").val();
        var city = this.state.city.value;
        var diesel_state = this.state.dState.value;
        this.setState({
            overlay:"show-m"
        })
        // var rocode = this.state.rocode.value;
        var params = {
            startDate:startDate,
            endDate:endDate,
            // city:city,
            diesel_state:diesel_state
            // rocode:rocode
        }

        // console.log("endDate ", params)
        this.loadData(params);
    }
    loadData(params){
        params.source = "IOCL";
        redirectURL.post("/billing/dieselpricesbyparams",params)
        .then(async (response) => {
            // console.log("data ", response.data)
            var records = response.data.records;
        
            // console.log(records , "line_no_339")
            this.setState({
                rowData:response.data.records,
                
                overlay:"show-n",
                
            })
            this.loadCols(records);
            
        })
    }
    loadCols(rowdata){
        var cols = [
            
            {
                headerName:"Diesel State",
                field:"name",
                width:120,
            },
            
            
        ];
        var ss = $("#from_date").val();
        var tt = $("#to_date").val();
        // console.log(ss,tt , "line_no_397")
        var ts = tt.split("-");
        var ed = ts[2]+"-"+ts[1]+"-"+ts[0];

        
        var s12 = ss.split("-");
        var st = s12[2]+"-"+s12[1]+"-"+s12[0];

        var s1date = moment.parseZone(new Date(st+" 00:00:00")).format('YYYY-MM-DD');
        
        // console.log("s1date ", s1date)
        var e1date = moment.parseZone(new Date(ed+" 00:00:00")).format('YYYY-MM-DD');
        // console.log("e1date ", e1date)
        try {
            var currDate = moment(s1date).startOf('day');
            var lastDate = moment(e1date).startOf('day');
            var c1 = moment.parseZone(currDate).format("DD-MM-YYYY");
            var cX= moment.parseZone(currDate).format("YYYY-MM-DD");

            cols.push({
                headerName:c1,
                field:c1
            })
            var diff = lastDate.diff(currDate,'days');
           

            while(diff > 0) {
                var d= moment.parseZone(currDate).add(1, 'days').format("YYYY-MM-DD");
                var da= moment.parseZone(currDate).add(1, 'days').format("DD-MM-YYYY");
                var dX= moment.parseZone(currDate).add(1, 'days').format("x");
                
                currDate = d
                // console.log(da , d)
                cols.push({
                    headerName:da +" (USD)",
                    field:da
                });
                diff = lastDate.diff(d,'days'); 
                // console.log("diff ", diff)
            }
            
        } catch (error) {
            
        }
        // console.log("cols ", cols)


        this.setState({
            columnDefs:cols
        })
    }
    loadRows(rowdata){
        try {
            var groupByROData = groupBy(rowdata, rdata => (rdata.diesel_ro_code));
            var rows = []
            groupByROData.forEach((items,key) => {
                
                var rw = {}
                rw["diesel_ro_code"] = key;
                if(items.length > 0)
                {
                   
                    items.map((itm) => {
                        
                        rw[itm.order] = itm.diesel_price;
                        rw["diesel_city"] = itm.diesel_city;
                        rw["diesel_state"] = itm.diesel_state;
                        rw["diesel_address"] = itm.diesel_address;
                        rw["diesel_price_diff_from_previous_date"] = itm.diesel_price_diff_from_previous_date;
                        rw["source"] = itm.source;
                        rw["diesel_price_date"] = itm.diesel_price_date;
                    })
                    
                    rows.push(rw);
                }
            })
           
            this.setState({
                rowData:rows,
                overlay:"show-n",
             
            })
        } catch (error) {
            
        }
        
        
    }
    onChangeCity(city){
        this.setState({city})
    }
    onChangeRoCode(rocode){
        this.setState({rocode});
    }
    onChangeStates(dState){
        this.setState({dState})
    }
    
    hideSlideBlock(){
        this.setState({
            showUploadCSVForm:"show-n",
            showApproveDocumentReason:"",
            showRejectDocumentReason:"",
            slider:"",
            overlay:"show-n"
        })
    }
    showTabView(oVal){
        if(oVal === 1)
        {
            this.setState({
                vdata:"btn-active",
                vsummary:"btn-default",
                vdatash:"show-m",
                vsummarysh:"show-n"
            })
        }
        if(oVal === 2)
        {
            this.setState({
                vdata:"btn-default",
                vsummary:"btn-active",
                vdatash:"show-n",
                vsummarysh:"show-m"
            })
        }
    }
    render(){
		var columnDefs = [
            
            {
                headerName:Constants.DIESEL_PRICE_DATE,
                field:"diesel_price_date",
                width:160,
                valueGetter:function(params){
                    try{
                        if(params.data.diesel_price_date !== "" && params.data.diesel_price_date !== undefined)
                        {
                            return getHyphenDDMMYYYY(params.data.diesel_price_date);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:"Constants.DIESEL_STATE",
                field:"diesel_state",
                width:160,
            },
           
            {
                headerName:Constants.DIESEL_PRICE,
                field:"diesel_price",
                width:150,
            },
            // {
            //     headerName:Constants.DIESEL_CITY,
            //     field:"diesel_city",
            //     width:160,
            // },
           
            // {
            //     headerName:Constants.DIESEL_ADDR,
            //     field:"diesel_address",
            //     width:200,
            // }
        ]
        return (
            <div className="container-fluid">
				
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="fa fa-tint cus-i"></i>&nbsp;<span>Diesel Prices</span>
                        <Favourites />
                    </h5>
                	<div className="col-xl-12 col-md-12 mb-4">
						<form className="user" onSubmit={this.formSubmit.bind(this)}>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Start Date</label>
                                    <input type="text" name="from_date" id="from_date" className="form-control datepicker" onChange={this.changeHandler} placeholder="Start Date" required />
                                </div>
                                <div className="col-md-2">
                                    <label>End Date</label>
                                    <input type="text" placeholder="End Date" name="to_date" id="to_date"  className="form-control datepicker" onChange={this.changeHandler} required />
                                </div>
                                
                                <div className="col-md-2">
                                    <label>State</label>
                                    <Select
                                        value={this.state.dState} 
                                        className="" 
                                        onChange={this.onChangeStates.bind(this)}
                                        options={ [
                                            
                                                { label: "Alabama", value: "Alabama" },
                                                { label: "Alaska", value: "Alaska" },
                                                { label: "Arizona", value: "Arizona" },
                                                { label: "Arkansas", value: "Arkansas" },
                                                { label: "California", value: "California" },
                                                { label: "Colorado", value: "Colorado" },
                                                { label: "Connecticut", value: "Connecticut" },
                                                { label: "Delaware", value: "Delaware" },
                                                { label: "Florida", value: "Florida" },
                                                { label: "Georgia", value: "Georgia" },
                                                { label: "Hawaii", value: "Hawaii" },
                                                { label: "Idaho", value: "Idaho" },
                                                { label: "Illinois", value: "Illinois" },
                                                { label: "Indiana", value: "Indiana" },
                                                { label: "Iowa", value: "Iowa" },
                                                { label: "Kansas", value: "Kansas" },
                                                { label: "Kentucky", value: "Kentucky" },
                                                { label: "Louisiana", value: "Louisiana" },
                                                { label: "Maine", value: "Maine" },
                                                { label: "Maryland", value: "Maryland" },
                                                { label: "Massachusetts", value: "Massachusetts" },
                                                { label: "Michigan", value: "Michigan" },
                                                { label: "Minnesota", value: "Minnesota" },
                                                { label: "Mississippi", value: "Mississippi" },
                                                { label: "Missouri", value: "Missouri" },
                                                { label: "Montana", value: "Montana" },
                                                { label: "Nebraska", value: "Nebraska" },
                                                { label: "Nevada", value: "Nevada" },
                                                { label: "New Hampshire", value: "New Hampshire" },
                                                { label: "New Jersey", value: "New Jersey" },
                                                { label: "New Mexico", value: "New Mexico" },
                                                { label: "New York", value: "New York" },
                                                { label: "North Carolina", value: "North Carolina" },
                                                { label: "North Dakota", value: "North Dakota" },
                                                { label: "Ohio", value: "Ohio" },
                                                { label: "Oklahoma", value: "Oklahoma" },
                                                { label: "Oregon", value: "Oregon" },
                                                { label: "Pennsylvania", value: "Pennsylvania" },
                                                { label: "Rhode Island", value: "Rhode Island" },
                                                { label: "South Carolina", value: "South Carolina" },
                                                { label: "South Dakota", value: "South Dakota" },
                                                { label: "Tennessee", value: "Tennessee" },
                                                { label: "Texas", value: "Texas" },
                                                { label: "Utah", value: "Utah" },
                                                { label: "Vermont", value: "Vermont" },
                                                { label: "Virginia", value: "Virginia" },
                                                { label: "Washington", value: "Washington" },
                                                { label: "West Virginia", value: "West Virginia" },
                                                { label: "Wisconsin", value: "Wisconsin" },
                                                { label: "Wyoming", value: "Wyoming" }
                                            
                                            
                                            ]
                                            }
                                        />
                                </div>
                               
                                <div className="col-md-2">
                                    <button type="submit" className="btn btn-success" style={{marginTop:"30px"}}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
				
                <div className={" "+(this.state.vdatash)}>
                    <div className={"row "}>
                        <div className="col-xl-12 col-md-12 mb-4">
                            <div className="card shadow h-100 py-2">
                                <div className="card-body">
                                    <div style={{width:"100%",height:"70vh"}} className="ag-theme-balham">
                                    <AgGridReact 
                                        rowData={this.state.rowData} 
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef ={this.state.defaultColDef }
                                        enableRangeSelection={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        
                                    />
                                    </div> 
                                    
                                </div>
                            </div>
                        </div>
					</div>
					
				</div>
                <div className={" "+(this.state.vsummarysh)}>
                    <div className={"row "}>

                        <div className="col-xl-12 col-md-12 mb-4">
                            <div className="card shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                        {(this.state.columnchartseries.length > 0)?
                                            <IOCLChart
                                                chartType="pie"
                                                title={"Daily Avg. Diesel Prices"}
                                                subtitle={""}
                                                context={this}
                                                series={this.state.columnchartseries}
                                                categories={this.state.columnchartcategories}
                                                xtitle={""}
                                                ytitle={"Price (INR)"}
                                                yaxistitle={""}
                                            />
                                        :""}
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    

                {/* <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div> */}
			</div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}
$(function(){
    
    $( ".datepicker" ).datepicker({
        dateFormat:"dd-mm-yy"
    });
})

function getDatesBewteenDates(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        console.log(currDate.toDate());
        dates.push(currDate.clone().toDate());
    }

    return dates;
};