import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import TrackingTile from "./trackingTile";
import redirectURL from "../redirectURL";
import $, { each } from "jquery";
import { countries } from "country-flags-svg";
import ProgressbarCard from "./ProgressbarCard";
import BLCounters from "./BLCounters";
import SubTile from "./SubTile";
import DocumentsCard from "./DocumentsCard";
import DocumentPreviewCard from "../DocumentPreviewCard";
import Mapcard2 from "./Mapcard2";
import TrackingTile2 from "./TrackingTile2";
import moment from "moment";
// import TrackingDetailsCard from "./TrackingDetailsCard";
import  TrackingDetailsCard from "./TrackingDetailsTabNew";
import EventsCard from "./EventsCard";
import ExceptionsCard from "./ExceptionsCard";

import BootstrapLoader from "./BootstrapLoader";
import EventTab from "./EventTab";
import DetailsTab from "./DetailsTab";
// import redirectURL from "../redirectURL"

const ContainerTrackingDetails = () => {
//  console.log(useParams(),"mbl_number");

  let { searchType, searchNumber } = useParams();
  const [exceptionShipmentID,setExceptionShipmentID]=useState();
  const [state, setState] = useState({
    shipmentNo: "",
    showLoader: false,
    eventsData: [],
    eventsTab:[],
    excpetionData: [],
    tabActive: "Container",
    containerTabCard: "show-m",
    detailsTabCard: "show-n",
    documentsTabCard: "show-n",
    eventsTabCard: "show-n",
    exceptionTabCard: "show-n",
    searchType,
    searchNumber,
    pageName: searchType === "BL" ? "BL Details" : "Container Details",
    mapinfo: {
      coords: [
        // { lat: 8.76735, lng: 78.13425 },
        // { lat: 18.76735, lng: 88.13425 },
      ],
      coords2:[],
      coords3:[],
      breaks: [],
      route_events: [],
      last_event_coord: [],
    },
    allShipmentLocations: {
      coords: [],
      coords2:[],
      coords3:[],
      breaks: [],
      route_events: [],
    },
    container_data: [],
    all_data: [],
    bl_data: [],
    detailType: "",
    subMaps: false,
    counterData: {
      all: 0,
      origin: 0,
      in_transit: 0,
      destination: 0,
      completed: 0,
      delayed: 0,
      detained: 0,
    },
    geoLocShipmentsMap: {},
    bl_all_data: [],
    eventShow: "show-n",
    eventData: [],
    progressCardVisibility: "none",
    mapHeight: "94vh",
    shipment_unique_id: "",
    uploadedDocx: [],
    most_delayed_container_emission:"",
    active:'',
    api:"NA"
  });

  const [containerData, setContainerData] = useState([]);
  const [containerLength, setContainerLength] = useState(0);

  // useEffect(() => {
  //   getEvents();
  // }, []);

  // const getEvents = () => {
  //   let payload = {};
  //   let urlType = "";
  //   if (state.searchType === "BL") {
  //     payload["mbl_number"] = state.searchNumber;
  //     urlType = "/exim/mblTrackingDetails";
  //   } else {
  //     payload["shipment_no"] = state.searchNumber;
  //     urlType = "/exim/containerTrackingDetails";
  //   }

  //   redirectURL
  //     .post(urlType, payload)
  //     .then((resp) => {
  //       console.log("trackingDetails data", resp.data);
  //     })
  //     .catch((err) => {
  //       console.log("trackingDetails err", err);
  //     });
  // };

  // const activeCardChange = (containerNum) => {
  //   console.log("containerNum",containerNum)
  //   // let restCards = containerData.filter((each) => {
  //   //   return each !== containerNum;
  //   // });
  //   // restCards.forEach((each) => {
  //   //   $(`#${each}`).removeClass("selected-card-shadow");
  //   // });

  //   // $(`#${containerNum}`).addClass("selected-card-shadow");
  // };
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const changeContainerSubData = (shipmentNo) => {
    console.log("changeContainerSubData", shipmentNo);
    let filteredData = state.bl_all_data.filter((each) => {
      if (each.shipment_no === shipmentNo) {
        return each;
      }
    });
    console.log("changeContainerSubData", filteredData);
    if (filteredData.length > 0) {
      let allCoordinates = [];
      let allCoordinates2 = [];
      let allCoordinates3 = [];
      let {
        discharge_port_coordinates,
        latest_location,
        loading_port_coordinates,
      } = filteredData[0];
      let api_provider="NA"
      // console.log("allCoordinates",geoPoints.length)
      
      if (filteredData[0].geoPoints.length > 0) {
        let duplicateData = [];
        let duplicateData2 = [];
        let duplicateData3 = [];
        let uniqueCoords = [];
        let uniqueCoords2 = [];
        let uniqueCoords3 = [];
        let parseCoord = ""
        let parseCoord2=""
        let parseCoord3=""
        let route
        let route2
        let route3

        // if(filteredData[0].geoPoints[0].hasOwnProperty("source_land_route")){
        //   route2=filteredData[0].geoPoints[0].source_land_route
        //   api_provider=filteredData[0].geoPoints[0].api_provider
        // }
        // if(filteredData[0].geoPoints[0].hasOwnProperty("destination_land_route")){
        //   route3=filteredData[0].geoPoints[0].destination_land_route
        //   api_provider=filteredData[0].geoPoints[0].api_provider
        // }
        if(filteredData[0].geoPoints[0].hasOwnProperty("ldb_route")) {
          route2=filteredData[0].geoPoints[0].ldb_route
        }
        try{
          parseCoord=JSON.parse(route2);
          parseCoord.forEach((eachCoord) => {
            let { lat, lng } = eachCoord;
            let latlng = `${lat}${lng}`;
            if (!duplicateData.includes(latlng)) {
              uniqueCoords.push({
                lat: lat,
                lng: lng,
              });
              duplicateData.push(latlng);
            }
          });
          allCoordinates.push(...uniqueCoords);
        }catch(err){
          allCoordinates=[]
        }
        
        try{
          parseCoord3=JSON.parse(route3);
          parseCoord3.forEach((eachCoord) => {
            let { lat, lng } = eachCoord;
            let latlng = `${lat}${lng}`;
            if (!duplicateData3.includes(latlng)) {
              uniqueCoords3.push({
                lat: lat,
                lng: lng,
              });
              duplicateData3.push(latlng);
            }
          });
          allCoordinates3.push(...uniqueCoords3);
        }catch(err){
          allCoordinates3=[]
        }
        try{
          parseCoord2=JSON.parse(filteredData[0].geoPoints[0].route); 
          parseCoord2.forEach((eachCoord) => {
            let { lat, lng } = eachCoord;
            let latlng = `${lat}${lng}`;
            if (!duplicateData2.includes(latlng)) {
              uniqueCoords2.push({
                lat: lat,
                lng: lng,
              });
              duplicateData2.push(latlng);
            }
          });
          allCoordinates2.push(...uniqueCoords2);
        }catch(err){
        allCoordinates2=[]
        }
      
        // allCoordinates.push(...JSON.parse(filteredData[0].geoPoints[0].route));
      }

      //Push Loading coords at front
      if (
        loading_port_coordinates.latitude &&
        loading_port_coordinates.longitude
      ) {
        allCoordinates2.unshift({
          lat: loading_port_coordinates.latitude,
          lng: loading_port_coordinates.longitude,
        });
      }

      //Push Loading coords at last
      if (
        discharge_port_coordinates.latitude &&
        discharge_port_coordinates.longitude
      ) {
        allCoordinates2.push({
          lat: discharge_port_coordinates.latitude,
          lng: discharge_port_coordinates.longitude,
        });
      }

      // console.log("allCoordinates georoutes", JSON.parse(geoPoints[0].route));
      // console.log("allCoordinates dest", {
      //   lat: discharge_port_coordinates.latitude,
      //   lng: discharge_port_coordinates.longitude,
      // });
      // console.log("allCoordinates load", {
      //   lat: loading_port_coordinates.latitude,
      //   lng: loading_port_coordinates.longitude,
      // });
      // console.log("allCoordinates latest", latest_location);
      // console.log("allCoordinates all", allCoordinates);

      let latestCoords = [];
      if (latest_location.latitude && latest_location.longitude) {
        latestCoords.push({
          lat: latest_location.latitude,
          lng: latest_location.longitude,
        });
      }
      console.log("changeContainerSubData", allCoordinates);
      console.log("changeContainerSubData", latestCoords);

      setState({
        ...state,
        // eventData: filteredData[0].event_data,
        mapinfo: {
          ...state.mapinfo,
          coords: allCoordinates,
          coords2:allCoordinates2,
          coords3:allCoordinates3,
          last_event_coord: latestCoords,
        },
        api:api_provider,
        subMaps: true,
      });
    } else {
      setState({
        ...state,
        eventData: [],
        mapinfo: {
          ...state.mapinfo,
          coords: [],
          coords2:[],
          coords3:[]
        },
      });
    }
  };

  const changeSubTileData = (shipmentNo) => {
    // console.log("allCoordinates", shipmentNo);
    let data = state.bl_all_data;
    setExceptionShipmentID(shipmentNo)
    let filteredData = data.filter((each) => {
      if (each.shipment_no === shipmentNo) {
        return each;
      }
    });

    // console.log("allCoordinates", filteredData);
    // console.log("allCoordinates", filteredData[0].geoPoints.length);
    
    if (filteredData.length > 0) {
      let allCoordinates = [];
      let allCoordinates2 = [];
      let allCoordinates3 = [];
      let {
        discharge_port_coordinates,
        latest_location,
        loading_port_coordinates,
      } = filteredData[0];
      let api_provider="NA"
      // console.log("allCoordinates",geoPoints.length)

      if (filteredData[0].geoPoints.length > 0) {
        let duplicateData = [];
        let uniqueCoords = [];
        let duplicateData2 = [];
        let duplicateData3 = [];
        let uniqueCoords2 = [];
        let uniqueCoords3 = [];
        let parseCoord
        let parseCoord2
        let parseCoord3
        let route2
        let route3
        // if(filteredData[0].geoPoints[0].hasOwnProperty("source_land_route")){
        //   route2=filteredData[0].geoPoints[0].source_land_route
        //   api_provider=filteredData[0].geoPoints[0].api_provider
        // }
        // if(filteredData[0].geoPoints[0].hasOwnProperty("destination_land_route")){
        //   route3=filteredData[0].geoPoints[0].destination_land_route
        //   api_provider=filteredData[0].geoPoints[0].api_provider
        // }
        if(filteredData[0].geoPoints[0].hasOwnProperty("ldb_route")) {
          route2=filteredData[0].geoPoints[0].ldb_route
        }
        try{
        parseCoord3 = JSON.parse(route3);
        parseCoord3.forEach((eachCoord) => {
          let { lat, lng } = eachCoord;
          let latlng = `${lat}${lng}`;
          if (!duplicateData3.includes(latlng)) {
            uniqueCoords.push({
              lat: lat,
              lng: lng,
            });
            duplicateData3.push(latlng);
          }
        });
        allCoordinates3.push(...uniqueCoords3);
      }catch(err){
        allCoordinates3=[]
      }
        try{
        parseCoord = JSON.parse(route2);
        parseCoord.forEach((eachCoord) => {
          let { lat, lng } = eachCoord;
          let latlng = `${lat}${lng}`;
          if (!duplicateData.includes(latlng)) {
            uniqueCoords.push({
              lat: lat,
              lng: lng,
            });
            duplicateData.push(latlng);
          }
        });
        allCoordinates.push(...uniqueCoords);
      }catch(err){
        allCoordinates=[]
      }
      try{
        parseCoord2 = JSON.parse(filteredData[0].geoPoints[0].route);
        parseCoord2.forEach((eachCoord) => {
          let { lat, lng } = eachCoord;
          let latlng = `${lat}${lng}`;
          if (!duplicateData2.includes(latlng)) {
            uniqueCoords2.push({
              lat: lat,
              lng: lng,
            });
            duplicateData2.push(latlng);
          }
        });
        allCoordinates2.push(...uniqueCoords2);
      }catch(err){
        allCoordinates2=[]
      }
        // allCoordinates.push(...JSON.parse(filteredData[0].geoPoints[0].route));
      }

      //loading port at first
      if (
        loading_port_coordinates.latitude &&
        loading_port_coordinates.longitude
      ) {
        allCoordinates2.unshift({
          lat: loading_port_coordinates.latitude,
          lng: loading_port_coordinates.longitude,
        });
      }

      //discharge at last
      if (
        discharge_port_coordinates.latitude &&
        discharge_port_coordinates.longitude
      ) {
        allCoordinates2.push({
          lat: discharge_port_coordinates.latitude,
          lng: discharge_port_coordinates.longitude,
        });
      }

      // console.log("allCoordinates georoutes", JSON.parse(geoPoints[0].route));
      // console.log("allCoordinates dest", {
      //   lat: discharge_port_coordinates.latitude,
      //   lng: discharge_port_coordinates.longitude,
      // });
      // console.log("allCoordinates load", {
      //   lat: loading_port_coordinates.latitude,
      //   lng: loading_port_coordinates.longitude,
      // });
      // console.log("allCoordinates latest", latest_location);
      // console.log("allCoordinates all", allCoordinates);

      let latestCoords = [];
      if (latest_location.latitude && latest_location.longitude) {
        latestCoords.push({
          lat: latest_location.latitude,
          lng: latest_location.longitude,
        });
      }

      setState({
        ...state,
        eventData: filteredData[0].event_data,
        mapHeight: "35vh",
        progressCardVisibility: "block",
        mapinfo: {
          ...state.mapinfo,
          coords: allCoordinates,
          coords2:allCoordinates2,
          coords3:allCoordinates3,
          last_event_coord: latestCoords,
        },
        subMaps: true,
        eventsData: filteredData[0].last_event_data,
        eventsTab:filteredData[0],
        active:filteredData[0].shipment_no,
        api:api_provider
      });
    } else {
      setState({
        ...state,
        eventData: [],
        mapHeight: "35vh",
        progressCardVisibility: "block",
        mapinfo: {
          ...state.mapinfo,
          coords: [],
          coords2: [],
          coords3: [],
        },
        subMaps: false,
        eventsData: filteredData[0].last_event_data,
        eventsTab:filteredData[0],
        active:filteredData[0].shipment_no
      });
    }

    // state.bl_all_data.forEach((each) => {
    //   let allEvents = [];
    //   let { shipment_no, last_event_data } = each;
    //   if (shipment_no === shipmentNo) {
    //     last_event_data.forEach((event) => {
    //       let statusCode = "";
    //       let { actual_time, estimated_time, event_group, event_name, status } =
    //         event;
    //       if (actual_time && estimated_time) {
    //         let timeDiff = moment(estimated_time, "YYYY-MM-DD hh:mm:ss A").diff(
    //           moment(actual_time, "YYYY-MM-DD hh:mm:ss A")
    //         );
    //         if (timeDiff > 0) {
    //           statusCode = "Early";
    //         } else if (timeDiff < 0) {
    //           statusCode = "Delay";
    //         } else {
    //           statusCode = "On Time";
    //         }
    //       } else if (actual_time && !estimated_time) {
    //         statusCode = "On Time";
    //       }
    //       let eachEvent = {
    //         event_name,
    //       };

    //       let eventGroup = event_group
    //         .replace(/_/g, " ")
    //         .replace(/\b\w/g, (char) => char.toUpperCase());

    //       if (actual_time) {
    //         eachEvent["actual_time"] = moment(
    //           actual_time,
    //           "YYYY-MM-DD hh:mm:ss A"
    //         ).format("Do MMM, YYYY");
    //         eachEvent["eventGroup"] = eventGroup;
    //         eachEvent["statusCode"] = statusCode;

    //       }
    //       allEvents.push(eachEvent)
    //     });
    //   }
    //   each["event_data"] = allEvents;
    // });
  };

  const getFlagIcon = (countryName) => {
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return srcFlag[0]?.flag;
    } else {
      return "";
    }
  };

  const showTabView = (tab) => {
    if (tab == "Details") {
      setState({
        ...state,
        tabActive: tab,
        containerTabCard: "show-n",
        detailsTabCard: "show-m",
        documentsTabCard: "show-n",
        eventsTabCard: "show-n",
        exceptionTabCard: "show-n"
        // filterModeType: { value: "bl_number", label: "BL number" }
      });
    } else if (tab == "Container") {
      setState({
        ...state,
        tabActive: "Container",
        containerTabCard: "show-m",
        detailsTabCard: "show-n",
        documentsTabCard: "show-n",
        eventsTabCard: "show-n",
        exceptionTabCard: "show-n"
        // filterModeType: { value: "container_number", label: "Container number" }
      });
    } else if(tab == "Documents"){
      setState({
        ...state,
        tabActive: "Documents",
        containerTabCard: "show-n",
        detailsTabCard: "show-n",
        documentsTabCard: "show-m",
        eventsTabCard: "show-n",
        exceptionTabCard: "show-n"
        // filterModeType: { value: "container_number", label: "Container number" }
      });
    } else if(tab == "Events"){
      setState({
        ...state,
        tabActive: "Events",
        containerTabCard: "show-n",
        detailsTabCard: "show-n",
        documentsTabCard: "show-n",
        eventsTabCard: "show-m",
        exceptionTabCard: "show-n"
        // filterModeType: { value: "container_number", label: "Container number" }
      });
    } else if(tab == "Exceptions"){
      setState({
        ...state,
        tabActive: "Exceptions",
        containerTabCard: "show-n",
        detailsTabCard: "show-n",
        documentsTabCard: "show-n",
        eventsTabCard: "show-n",
        exceptionTabCard: "show-m"
        // filterModeType: { value: "container_number", label: "Container number" }
      });
    }

    // let inTransitTrackingData = []
    // if (tab == "transit") {
    //   let allTrackingData = [...this.state.allTrackingData]
    //   inTransitTrackingData = allTrackingData.filter(container => container.trip_completed == 0)
    // } else {
    //   let allTrackingData = [...this.state.allTrackingData]
    //   inTransitTrackingData = allTrackingData.filter(container => container.trip_completed == 1)
    // }
    // let trackingDataLength = inTransitTrackingData.length
    // console.log("trackingDataLength", trackingDataLength)
    // console.log(inTransitTrackingData, "inTransitTrackingData")
    // this.setState({
    //   loadshow: 'show-m',
    //   showFilterBox: false,
    //   tabActive: tab,
    //   showFilterBox: false,
    //   searchFilterValue: '',
    //   filterStartDate: '',
    //   filterOriginRegion: '',
    //   filterDestinationRegion: '',
    //   completedCounter: 0,
    //   onTimeCounter: 0,
    //   delayedCounter: 0,
    //   emptyPickupCounter: 0,
    //   dipatchCounter: 0,
    //   gateInCounter: 0,
    //   originCounter: 0,
    //   transhipmentCounter: 0,
    //   destinationCounter: 0,
    //   gateOutCounter: 0,
    //   emptyReturnCounter: 0,
    //   trackingDataLength: trackingDataLength
    // }, () => {
    //   // isTransitTrackingData has 12 records
    //   this.loadTrackingPageData(inTransitTrackingData, true)
    // })
  };

  useEffect(() => {
    changeContainerSubData(state.shipmentNo);
  }, [state.shipmentNo]);

  const trackingData = () => {
    setState({
      ...state,
      showLoader: true
    })
    // let payload;
    if (searchType === "BL") {
      // payload.mbl_number = searchNumber ;
      let payload = {
        mbl_number: searchNumber,
        user_type: localStorage.getItem('user_type'), 
        email: localStorage.getItem('email')
      };
      redirectURL
        .post("/exim/trackingdetails", payload)
        .then((resp) => {
          console.log("trackingdetails data", resp.data);
          let {
            status,
            trackingData,
            mostDelayedContainer,
            latestGeoLocations,
            counterData,
            geoLocShipmentsMap,
            all_data,
          } = resp.data;

          let uploadedDocx = mostDelayedContainer.documents;

          latestGeoLocations = JSON.parse(latestGeoLocations);
          if (status === "success") {
            setExceptionShipmentID(all_data[0].shipment_no)
            setState({
              ...state,
              showLoader: false
            })
            let delay=0;
            let detention=0;
            all_data.forEach((each) => {
              let allEvents = [];
              let foundLastEvent = false;
              let { shipment_no, last_event_data } = each;
              let last_location = "";
              last_event_data.forEach((event, i) => {
                let statusCode = "";
                let {
                  actual_time,
                  estimated_time,
                  event_group,
                  event_name,
                  status,
                  location,
                } = event;

                if (actual_time && estimated_time) {
                  let timeDiff = moment(
                    estimated_time,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).diff(moment(actual_time, "YYYY-MM-DD hh:mm:ss A"));
                  if (timeDiff > 0) {
                    statusCode = "Early";
                  } else if (timeDiff < 0) {
                    statusCode = "Delay";
                  } else {
                    statusCode = "On Time";
                  }
                } else if (actual_time && !estimated_time) {
                  statusCode = "On Time";
                } else {
                  statusCode = "On Time";
                }
                let eachEvent = {
                  event_name,
                };

                let eventGroup = event_group
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase());

                if (actual_time) {
                  eachEvent["actual_time"] = moment(
                    actual_time,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("Do MMM, YYYY");
                  eachEvent["eventGroup"] = eventGroup;
                  eachEvent["statusCode"] = statusCode;
                  eachEvent["is_last_event"] = false;
                  eachEvent["status"] = status;
                  eachEvent["location"] = location;
                  each["last_location"] = location;
                } else {
                  eachEvent["is_last_event"] = false;
                  eachEvent["eventGroup"] = eventGroup;
                  eachEvent["statusCode"] = statusCode;
                  eachEvent["status"] = status;
                  eachEvent["location"] = location;
                  
                }
                allEvents.push(eachEvent);
                if (
                  actual_time === "" &&
                  i > 0 &&
                  allEvents[i - 1]["actual_time"] != "" &&
                  status === "ACTIVE" &&
                  !foundLastEvent
                ) {
                  allEvents[i - 1]["is_last_event"] = true;
                  foundLastEvent = true;
                  
                }
              });
              each["event_data"] = allEvents;
              let statusCode="NA"
              try{
                const delay=(moment(each.last_port_eta)).diff(moment(each.last_port_ata));
                const duration=moment.duration(delay)
                const days = duration.days();
                const hours2 = duration.hours();
                if(each.last_port_eta===""||each.last_port_ata===""||each.last_port_eta===undefined||each.last_port_ata===undefined){
                  statusCode="NA"
                }
                if(days<0){
                  statusCode="Delay"
                }
                else if(days>0){
                  statusCode="Early"
                  }
                else{
                  if(days===0){
                    if(hours2<0){
                      statusCode="Delay"
                    }
                    else if(hours2>0){
                      statusCode="Early"
                    }
                  }
                }
                }catch(err){
                  console.log(err,"errorrrrrrr");
                }
                if(statusCode==="Delay"){
                  delay++;
                }
                if(each.hasOwnProperty("in_detention")){
                  if(each.in_detention===1)
                  detention++
                }
            });
            counterData["delayed"]=delay
            counterData["detained"]=detention
            delay=0
            console.log("all_data", all_data);
            setState({
              ...state,
              // rowData: trackingData,
              // trackingData: trackingData,
              bl_data: [mostDelayedContainer],
              most_delayed_container_emission:mostDelayedContainer.emissions.co2e.value,
              detailType: "BL/Booking",
              mapinfo: {
                ...state.mapinfo,
                coords2: latestGeoLocations,
                last_event_coord: latestGeoLocations,
              },
              allShipmentLocations: {
                ...state.mapinfo,
                coords2: latestGeoLocations,
              },
              counterData: counterData,
              geoLocShipmentsMap: geoLocShipmentsMap,
              bl_all_data: all_data,
              shipment_unique_id: mostDelayedContainer.shipment_unique_id,
              uploadedDocx: uploadedDocx,
              eventsData: mostDelayedContainer.events_data,
              eventsTab:{
                ...mostDelayedContainer,
                last_event_data:mostDelayedContainer.events_data
              },
            });
            setContainerData(all_data);
            setContainerLength(all_data.length);
          }
        })
        .catch((err) => {
          console.log("getTrackingData err", err);
        });
    } else {
      let payload = {
        shipment_no: searchNumber,
        user_type: localStorage.getItem('user_type'), 
        email: localStorage.getItem('email')
      };
      // payload.shipment_no = searchNumber;
      redirectURL
        .post("/exim/trackingdetails", payload)
        .then((resp) => {
          console.log("trackingdetails data", resp.data);
          let {
            status,
            trackingData,
            mostDelayedContainer,
            latestGeoLocations,
            counterData,
            geoLocShipmentsMap,
            all_data,
          } = resp.data;

          // changeSubTileData(all_data[0].shipment_no)

          let uploadedDocx = mostDelayedContainer.documents;

          latestGeoLocations = JSON.parse(latestGeoLocations);
          let foundLastEvent = false;
          if (status === "success") {
            all_data.forEach((each) => {
              let allEvents = [];
              let { shipment_no, last_event_data } = each;
              last_event_data.forEach((event, i) => {
                let statusCode = "";
                let {
                  actual_time,
                  estimated_time,
                  event_group,
                  event_name,
                  status,
                  location,
                } = event;

                if (actual_time && estimated_time) {
                  let timeDiff = moment(
                    estimated_time,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).diff(moment(actual_time, "YYYY-MM-DD hh:mm:ss A"));
                  if (timeDiff > 0) {
                    statusCode = "Early";
                  } else if (timeDiff < 0) {
                    statusCode = "Delay";
                  } else {
                    statusCode = "On Time";
                  }
                } else if (actual_time && !estimated_time) {
                  statusCode = "On Time";
                } else {
                  statusCode = "On Time";
                }
                try{
                  const delay=(moment(each.last_port_eta)).diff(moment(each.last_port_ata));
                  const duration=moment.duration(delay)
                  const days = duration.days();
                  const hours2 = duration.hours();
                  if(each.last_port_eta===""||each.last_port_ata===""||each.last_port_eta===undefined||each.last_port_ata===undefined){
                    statusCode="NA"
                  }
                  if(days<0){
                    statusCode="Delay"
                  }
                  else if(days>0){
                    statusCode="Early"
                    }
                  else{
                    if(days===0){
                      if(hours2<0){
                        statusCode="Delay"
                      }
                      else if(hours2>0){
                        statusCode="Early"
                      }
                    }
                  }
                  }catch(err){
                    console.log(err,"errorrrrrrr");
                  }
                let eachEvent = {
                  event_name,
                };

                let eventGroup = event_group
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase());

                if (actual_time) {
                  eachEvent["actual_time"] = moment(
                    actual_time,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("Do MMM, YYYY");
                  eachEvent["eventGroup"] = eventGroup;
                  eachEvent["statusCode"] = statusCode;
                  eachEvent["is_last_event"] = false;
                  eachEvent["status"] = status;
                  eachEvent["location"] = location;
                } else {
                  eachEvent["is_last_event"] = false;
                  eachEvent["eventGroup"] = eventGroup;
                  eachEvent["statusCode"] = statusCode;
                  eachEvent["status"] = status;
                  eachEvent["location"] = location;
                }
                allEvents.push(eachEvent);
                if (
                  actual_time === "" &&
                  i > 0 &&
                  allEvents[i - 1]["actual_time"] != "" &&
                  status === "ACTIVE" &&
                  !foundLastEvent
                ) {
                  allEvents[i - 1]["is_last_event"] = true;
                  foundLastEvent = true;
                }
              });
              each["event_data"] = allEvents;
            });
            
            setExceptionShipmentID(all_data[0].shipment_no)
            
            setState({
              ...state,
              // rowData: trackingData,
              // trackingData: trackingData,
              bl_data: [mostDelayedContainer],
              detailType: "Container",
              // mapinfo: {
              //   ...state.mapinfo,
              //   coords: latestGeoLocations,
              // },
              allShipmentLocations: {
                ...state.mapinfo,
                coords: latestGeoLocations,
              },
              counterData: counterData,
              geoLocShipmentsMap: geoLocShipmentsMap,
              bl_all_data: all_data,
              shipment_unique_id: mostDelayedContainer.shipment_unique_id,
              uploadedDocx: uploadedDocx,
              mapHeight: "54vh",
              eventData: all_data[0].event_data,
              subMaps: true,
              progressCardVisibility: "block",
              searchNumber: mostDelayedContainer.container_no,
              shipmentNo: all_data[0].shipment_no,
              eventsData: mostDelayedContainer.events_data,
              eventsTab:{
                ...mostDelayedContainer,
                last_event_data:mostDelayedContainer.events_data
              },
              active:mostDelayedContainer.shipment_no
            });
            // setContainerData(all_data);
            setContainerLength(1);
          }
        })

        .catch((err) => {
          console.log("getTrackingData err", err);
        });
      setState({
        ...state,
        detailType: "Container",
      });
    }
  };

  const handleView = (viewType) => {
    setState({
      ...state,
      showSortCard: "show-n",
    });

    $("#sort-filter")
      .removeClass("show-m")
      .addClass("show-n")
      .removeClass("sort-filter-slider-close")
      .removeClass("sort-filter-slider-open")
      .removeClass("card");

    if (viewType === "tileView") {
      setState({
        ...state,
        isTileView: true,
      });
      // $("#sort-filter").css({"top":"276px"})
    } else {
      setState({
        ...state,
        isTileView: false,
      });
      // $("#sort-filter").css({"top":"495px"})
    }
  };

  const getSearchItem = () => {
    console.log(state.filterModeType);
    console.log(state.searchInput);

    let { label } = state.filterModeType;
    let acceptedFormat = "";
    let payload = {};
    if (label === "Container number") {
      acceptedFormat = "ABCD1234567";
      var regexPattern = /^[A-Z]{4}\d{7}$/;
      payload["container_no"] = state.searchInput;
    } else {
      acceptedFormat = "BL123456789";
      var regexPattern = /^BL\d{9}$/;
      payload["container_no"] = state.searchInput;
    }

    if (!regexPattern.test(state.searchInput)) {
      alert(`Invalid ${label} and valid format is ${acceptedFormat}`);
      return;
    }

    redirectURL
      .post("/exim/getSearchItem", payload)
      .then((resp) => {
        console.log("getSearchItem", resp.data);
      })
      .catch((err) => {
        console.log("getSearchItem err", err);
      });
  };

  const handleFilterOptions = (selOption, dropdownName) => {
    console.log("selOption", selOption);
    console.log("dropdownName", dropdownName.name);
    setState({
      ...state,
      [dropdownName.name]: selOption,
    });
  };

  const showSortCard = () => {
    // sort-filter-slider-close
    //left - open
    //right - close
    let finalClass = "";
    if ($("#sidebarToggleIcon").hasClass("fa-angle-left")) {
      finalClass = "sort-filter-slider-open card";
    } else {
      finalClass = "sort-filter-slider-close card";
    }

    if (state.showSortCard === "show-n") {
      setState({
        ...state,
        showSortCard: "show-m " + finalClass,
      });
    } else {
      setState({
        ...state,
        showSortCard: "show-n",
      });
    }
  };

  const sortData = (dataType) => {
    console.log(dataType);
    setState({
      ...state,
      showSortCard: "show-n",
    });
  };

  const [docxLength, setDocxLength] = useState(0);
  const setDocumentsLength = (length) => {
    setDocxLength(length);
  };
  const updateDocumentsDataFn = (docxData) => {
    setState({
      ...state,
      uploadedDocx: docxData,
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [document_type, setDocument_type] = useState("");
  const [document_url, setDocument_url] = useState("");

  const previewCard = (document_type, document_url) => {
    setOpenModal(true);
    setDocument_type(document_type);
    setDocument_url(document_url);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const filterCardData = (groupName) => {
    console.log("allShipmentLocations", groupName)
    setState({
      ...state,
      mapHeight: "94vh",
      progressCardVisibility: "none",
      subMaps: false,
      mapinfo: state.allShipmentLocations,
    });

    let data = state.bl_all_data;
    if (groupName === "all") {
      setContainerData(state.bl_all_data);
      return;
    }

    if (groupName === "in_transit") {
      // setContainerData(state.bl_all_data);
      let filteredData = data.filter((each) => {
        if (each.event_group === "in_transit" || each.event_group === "transshipment") {
          return each;
        }
      });
      setContainerData(filteredData);
      return;
    }
    if (groupName === "delayed") {
      // setContainerData(state.bl_all_data);
      let filteredData = data.filter((each) => {
        if (each.delay_status === "Delay" || each.delay_status === "delay" || each.delay_status === "DELAY") {
          return each;
        }
      });
      setContainerData(filteredData);
      return;
    }
    if(groupName==="detained"){
      // setContainerData(state.bl_all_data);
      let filteredData = data.filter((each) => {
        if (each.in_detention === 1) {
          return each;
        }
      });
      setContainerData(filteredData);
      return;
    }
   


    let filteredData = data.filter((each) => {
      if (each.event_group === groupName) {
        return each;
      }
    });
    setContainerData(filteredData);
  };

  useEffect(() => {
    trackingData();
  }, [openModal]);

  return (
    <>
    <BootstrapLoader showLoader={state.showLoader} />
      <div className="container-fluid">
        <DocumentPreviewCard
          openModal={openModal}
          documentType={document_type}
          documentUrl={document_url}
          closeModal={closeModal}
        />
        {/* <div
            style={{
              color: "#263238",
              fontSize: "24px",
              fontWeight: 700,
              marginBottom: "20px",
              paddingLeft: "10px",
            }}
          >
            Container Details
          </div> */}
        {/* <nav aria-label="breadcrumb"> */}
        <ol
          className="breadcrumb"
          style={{ background: "none", height: "52px", marginLeft: "8px" }}
        >
          <li className="breadcrumb-item" style={{ height: "40px" }}>
            <Link
              to={"/container-tracking"}
              style={{
                textDecoration: "none",
                position: "relative",
                top: "2px",
              }}
            >
              Container Tracking
            </Link>
          </li>
          <li
            className="breadcrumb-item"
            aria-current="page"
            style={{
              color: "#263238",
              fontSize: "15px",
              fontWeight: 700,
              marginBottom: "20px",
              paddingLeft: "10px",
            }}
          >
            {state.pageName}
          </li>
        </ol>
        {/* </nav> */}

        {/* Top Card */}
        {searchType === "BL" ? (
          <TrackingTile2
            bl_data={state.bl_data}
            tabActive={state.detailType}
            container_data={state.container_data}
            trackingDataFun={trackingData}
            most_delayed_container_emission = {state.most_delayed_container_emission}
          />
        ) : (
          <>
            <TrackingTile2
              bl_data={state.bl_data}
              tabActive={state.detailType}
              container_data={state.container_data}
              trackingDataFun={trackingData}
            />
          </>
        )}
        {/* Top Card */}

        <div className="d-flex flex-row justify-content-between ml-4">
          <div className="">
            <ul className={"nav nav-tabs"}>
              <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Details" ? "tab-active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => showTabView("Details")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Details
                </a>
              </li>
              <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Container" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Container")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Container ({containerLength})
                </a>
              </li>

              {/* <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Documents" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Documents")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Documents({docxLength})
                </a>
              </li> */}

              <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Events" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Events")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Events
                </a>
              </li>
              {/* <li className={"nav-item"} style={{display:(state.api!=null && state.api==="searates")?"none":""}}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Exceptions" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Exceptions")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Exceptions
                </a>
              </li> */}
            </ul>
          </div>
          {/* <div className="d-flex mr-4">
                    <button className="d-flex justify-content-center align-items-center mr-3 px-4 mb-2" style={{ borderRadius: "5px", color: "white", background: "#407bff", border: "none", fontSize: "12px", fontWeight: 600 }}>
                        <img src={CreateShipmentIcon}></img>
                        <div className="ml-2">Create Shipment</div>
                    </button>
                    <button className="d-flex justify-content-center align-items-center px-4 mb-2" style={{ borderRadius: "5px", color: "#407bff", background: "white", border: "2px solid #407bff", fontSize: "12px", fontWeight: 600 }}>
                        <img src={BulkUploadIcon}></img>
                        <div className="ml-2">Bulk Upload</div>
                    </button>
                </div>                 */}
        </div>
      </div>

      {/* Details Card */}
      <div className={`${state.detailsTabCard} mx-3 pt-3`}>
        {/* <TrackingDetailsCard shipment_unique_id={state.shipment_unique_id} searchType={searchType} containers={(state.bl_all_data!=null)?(state.bl_all_data.map(item=>item.container_no)):([])} /> */}
        <DetailsTab  shipment_unique_id={state.shipment_unique_id} searchType={searchType} containers={(state.bl_all_data!=null)?(state.bl_all_data.map(item=>item.container_no)):([])} />
      </div>
      {/* Details Card */}

      {/* Container Card */}
      <div className={`${state.containerTabCard} mx-3 pt-3`}>
        <div className="row" style={{ width: "auto", margin: "auto" }}>
          <div className={searchType === "BL" ? "col-7": "col-7"}>
            {/* <MapCard mapinfo={state.mapinfo}/> */}
            <Mapcard2
              mapinfo={state.mapinfo}
              subMaps={state.subMaps}
              geoLocShipmentsMap={state.geoLocShipmentsMap}
              mapHeight={state.mapHeight}
              api={state.api}
            />
            {searchType === "BL" ? (
              <div className="counters-card">
                <ProgressbarCard
                  searchType={state.searchType}
                  searchNumber={state.searchNumber}
                  eventData={state.eventData}
                  progressCardVisibility={state.progressCardVisibility}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          {searchType === "BL" ? (
            <div className="col-5" style={{paddingInline:"0px"}}>
              <div className="counters-card">
                <BLCounters
                  counterData={state.counterData}
                  filterCardData={filterCardData}
                />
              </div>
              <div className="pt-2 px-1 subtile" >
                {(!containerData || containerData.length===0 || containerData===undefined)?
                <>
                <div
                    key={""}
                    className="card py-2 pr-3"
                    style={{
                      borderRadius: "18px",
                      textAlign:"center",
                    }}
                  >
                    No data found.
                    </div>
                </>:
                <>{
                containerData.map((each) => {
                  // console.log("containerData", each);
                  let textDecoration;
                  let active=false
                  if(each.shipment_no==state.active)
                  active=true
                  // if (delay_status === "On Time") {
                  //   shipIcon = TrackingShipBlue;
                  //   progressLine = "tracking-progress-blue-line";
                  //   textDecoration = "ontime-text";
                  // } else if (delay_status === "Early") {
                  //   shipIcon = TrackingShipGreen;
                  //   progressLine = "tracking-progress-green-line";
                  //   textDecoration = "early-text";
                  // } else {
                  //   //Delay
                  //   shipIcon = TrackingShipRed;
                  //   progressLine = "tracking-progress-red-line";
                  //   textDecoration = "delay-text";
                  // }
                  return (
                    <SubTile
                      key={each.shipment_no}
                      cardId={each.shipment_no}
                      cardData={each}
                      active={active}
                      // changeCardStatus={activeCardChange}
                      changeSubTileData={changeSubTileData}
                    />
                  );
                  // <React.Fragment key={each}>

                  // </React.Fragment>
                })}
                </>
                }
                {/* <SubTile id="TCLU7861041"/>
              <SubTile id="TCLU7861042" />
              <SubTile id="TCLU7861043" /> */}
              </div>
            </div>
          ) : (
            <div className="col-5" style={{paddingInline:"0px"}}>
              <div className="counters-card">
                <ProgressbarCard
                  searchType={state.searchType}
                  searchNumber={state.searchNumber}
                  eventData={state.eventData}
                  progressCardVisibility={state.progressCardVisibility}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Container Card */}

      {/* Documents Card */}
      {/* <div className={`${state.documentsTabCard} mx-3 pt-3`}>
        <DocumentsCard
          updateDocxCount={setDocumentsLength}
          uploadedDocx={state.uploadedDocx}
          previewCard={previewCard}
          updateDocumentsDataFn={updateDocumentsDataFn}
          shipment_unique_id={state.shipment_unique_id}
        />
      </div> */}
      {/* Documents Card */}


      {/* Events Card */}
      <div className={`${state.eventsTabCard} mx-3 pt-3 mb-3`}>
        
      <div className="row" style={{ width: "auto", margin: "auto" }}>
            <div className={`${searchType === "BL" ? "col-7":"col-12"}`}>
            <EventTab eventData={state.eventsTab} searchType={searchType}/>
            </div>
            <div className="col-5" style={{paddingInline:"0px"}}>
            {
          searchType === "BL" ? <>
          <div className="counters-card">
                <BLCounters
                  counterData={state.counterData}
                  filterCardData={filterCardData}
                />
              </div>
              <div className="pt-2 px-1 subtile" >
              {(!containerData || containerData.length===0 || containerData===undefined)?
                <>
                <div
                    key={""}
                    className="card py-2 pr-3"
                    style={{
                      borderRadius: "18px",
                      textAlign:"center",
                    }}
                  >
                    No data found.
                    </div>
                </>:
                <>
                {containerData.map((each) => {
                  
                  let active=false
                  if(each.shipment_no==state.active)
                  active=true

                  return (
                    <SubTile
                      key={each.shipment_no}
                      cardId={each.shipment_no}
                      cardData={each}
                      active={active}
                      // changeCardStatus={activeCardChange}
                      changeSubTileData={changeSubTileData}
                    />
                  );
                })}</>}
              </div>
          </>:<></>
        }
            </div>
      </div> 
            {/* <EventsCard eventsData={state.eventsData} /> */}
      </div>
      {/* Events Card */}
      {/* Exceptions Card */}
      <div className={`${state.exceptionTabCard} mx-3 pt-3 mb-3`}>
      <div className="row" style={{ width: "auto", margin: "auto" }}>
            {/* <div className={`${searchType === "BL" ? "col-6":"col-12"}`}>
              <ExceptionsCard searchType={searchType} shipmentNo={exceptionShipmentID} />
            </div> */}
            <div className="col-5" style={{paddingInline:"0px"}}>
            {
          searchType === "BL" ? <>
          <div className="counters-card">
                <BLCounters
                  counterData={state.counterData}
                  filterCardData={filterCardData}
                />
              </div>
              <div className="pt-2 px-1 subtile" >
              {(!containerData || containerData.length===0 || containerData===undefined)?
                <>
                <div
                    key={""}
                    className="card py-2 pr-3"
                    style={{
                      borderRadius: "18px",
                      textAlign:"center",
                    }}
                  >
                    No data found.
                    </div>
                </>:
                <>
                {containerData.map((each) => {
                  let active=false
                  if(each.shipment_no==state.active)
                  active=true
                  return (
                    <SubTile
                      key={each.shipment_no}
                      cardId={each.shipment_no}
                      cardData={each}
                      active={active}
                      // changeCardStatus={activeCardChange}
                      changeSubTileData={changeSubTileData}
                    />
                  );
                })}</>}
              </div>
          </>:<></>
        }
            </div>
      </div>        
      </div>
      {/* Exceptions Card */}
    </>
  );
};

export default ContainerTrackingDetails;
