import React, { useState, useEffect } from "react";
import EmptyPickupBlue from "../../assets/icons/empty-pickup-blue.svg";
import EmptyPickupWhite from "../../assets/icons/empty-pickup-white.svg";

import truckBlue from '../../assets/icons/Subcounters/truckBlue.svg'
import truckWhite from '../../assets/icons/Subcounters/truckWhite.svg'
import consigneeBlue from '../../assets/icons/Subcounters/atConsignmentBlue.svg'
import consigneeWhite from '../../assets/icons/Subcounters/atConsignmentWhite.svg'

import anchorBlue from '../../assets/icons/Subcounters/anchorBlue.svg'
import anchorWhite from '../../assets/icons/Subcounters/anchorWhite.svg'

import pol_pod_Blue from '../../assets/icons/Subcounters/pol_pod_blue.svg'
import pol_pod_White from '../../assets/icons/Subcounters/pol_pod_white.svg'

import highSeasBlue from '../../assets/icons/Subcounters/highSeasBlue.svg'
import highSeasWhite from '../../assets/icons/Subcounters/highSeasWhite.svg'

import transhipmentBlue from '../../assets/icons/Subcounters/transhipmentBlue.svg'
import transhipmentWhite from '../../assets/icons/Subcounters/transhipmentWhite.svg'

import flagBlue from '../../assets/icons/Subcounters/flagBlue.svg'
import flagWhite from '../../assets/icons/Subcounters/flagWhite.svg'

import customClearanceBlue from '../../assets/icons/Subcounters/customClearanceBlue.svg'
import customClearanceWhite from '../../assets/icons/Subcounters/customClearanceWhite.svg'

import customClearedBlue from '../../assets/icons/Subcounters/customClearedBlue.svg'
import customClearedWhite from '../../assets/icons/Subcounters/customClearedWhite.svg'

import atCustomerBlue from '../../assets/icons/Subcounters/atCustomerBlue.svg'
import atCustomerWhite from '../../assets/icons/Subcounters/atCustomerWhite.svg'



import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const SubTrackingCounters = (props) => {
  // console.log("SubTrackingCounters", props);
  const [state, setState] = useState({
    emptyPickupCount: 25,
    dispatchCount: 2,
    gateInCount: 5,
    vesselDepartCount: 2,
    transshipmentCount: 3,
    destinationCount: 5,
    gateOutCount: 10,
    emptyReturnCount: 1,
    activeCounterId: null,
    activeCounterName: "",
    isLoading: false,
  });
  useEffect(()=>{
    setState({
      ...state,
      activeCounterId: "",
      activeCounterName: "",
    });
  },[props.parentCounter])

  const handleCounterClick = (id, name) => {
    
    localStorage.setItem("sub_counter",name);
    props.filterDataByCounter("sub", name); //for filtering added by "govind"
    setState({
      ...state,
      activeCounterId: id,
      activeCounterName: name,
    });
  };

  let origin = [    
    {
      id: 1,
      name: "in_transit_to_consignee",
      event_name: "Rail Departure",
      title: "In Transit to Consignor",
      count: state.emptyPickupCount,
      imgSrc: truckBlue,
      activeImgSrc: truckWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    {
      id: 2,
      name: "at_consignee",
      event_name: "Rail Arrival",
      title: "At Consignor",
      count: state.emptyPickupCount,
      imgSrc: consigneeBlue,
      activeImgSrc: consigneeWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    {
      id: 3,
      name: "in_transit_to_first_port",
      event_name: "Discharge from Rail",
      title: "In-Transit to First Port",
      count: state.emptyPickupCount,
      imgSrc: truckBlue,
      activeImgSrc: truckWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    {
      id: 4,
      name: "at_port",
      event_name: "Load on Vessel",
      title: "At Port(Other than POL)",
      count: state.emptyPickupCount,
      imgSrc: anchorBlue,
      activeImgSrc: anchorWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    {
      id: 5,
      name: "in_transit_to_pol",
      event_name: "Full Container Available For Delivery",
      title: "In-Transit to POL",
      count: state.emptyPickupCount,
      imgSrc: truckBlue,
      activeImgSrc: truckWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    {
      id: 6,
      name: "at_pol",
      event_name: "Barge Arrival",
      title: "At POL",
      count: state.emptyPickupCount,
      imgSrc: pol_pod_Blue,
      activeImgSrc: pol_pod_White,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
  ];

  let in_transit = [
    {
      id: 7,
      name: "high_seas",
      event_name: "Vessel Departure",
      title: "High Seas",
      count: state.emptyPickupCount,
      imgSrc: highSeasBlue,
      activeImgSrc: highSeasWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    {
      id: 8,
      name: "at_transshipment",
      event_name: "Vessel Arrival",
      title: "At Transhipment Port",
      count: state.emptyPickupCount,
      imgSrc: transhipmentBlue,
      activeImgSrc: transhipmentWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    // {
    //   id: 5,
    //   name: "vesselDeparture",
    //   event_name: "Vessel Departure",
    //   title: "Vessel Departure",
    //   count: state.emptyPickupCount,
    //   imgSrc: EmptyPickupBlue,
    //   activeImgSrc: EmptyPickupWhite,
    //   color: "#405178",
    //   width: "45px",
    //   height: "45px",
    // },
  ];

  let destination = [
    {
      id: 9,
      name: "at_pod",
      event_name: "Discharge from Vessel",
      title: "At POD",
      count: state.emptyPickupCount,
      imgSrc: pol_pod_Blue,
      activeImgSrc: pol_pod_White,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    {
      id: 10,
      name: "in_transit_to_final_port",
      event_name: "Load on Vessel",
      title: "In-Transit to Final Port",
      count: state.emptyPickupCount,
      imgSrc: flagBlue,
      activeImgSrc: flagWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    {
      id: 11,
      name: "at_final_port",
      event_name: "Full Container Gate Out",
      title: "At Final Port",
      count: state.emptyPickupCount,
      imgSrc: anchorBlue,
      activeImgSrc: anchorWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    // {
    //   id: 12,
    //   name: "pending_for_custom_clearance",
    //   event_name: "Load on Rail",
    //   title: "Pending for Custom Clearence",
    //   count: state.emptyPickupCount,
    //   imgSrc: customClearanceBlue,
    //   activeImgSrc: customClearanceWhite,
    //   color: "#405178",
    //   width: "45px",
    //   height: "45px",
    // },

    // {
    //   id: 13,
    //   name: "custom_cleared",
    //   event_name: "Container Mount on Truck",
    //   title: "Custom Cleared",
    //   count: state.emptyPickupCount,
    //   imgSrc: customClearedBlue,
    //   activeImgSrc: customClearedWhite,
    //   color: "#405178",
    //   width: "45px",
    //   height: "45px",
    // },
    {
      id: 14,
      name: "out_for_final_delivery",
      event_name: "Load on Barge",
      title: "Out For Delivery",
      count: state.emptyPickupCount,
      imgSrc: EmptyPickupBlue,
      activeImgSrc: EmptyPickupWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },

    {
      id: 15,
      name: "at_customer",
      event_name: "Rail Departure",
      title: "At Customer",
      count: state.emptyPickupCount,
      imgSrc: atCustomerBlue,
      activeImgSrc: atCustomerWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    }
  ];

  let completed = [
    {
      id: 16,
      name: "empty_return",
      event_name: "Empty Container Gate In",
      title: "Empty Return",
      count: state.emptyPickupCount,
      imgSrc: EmptyPickupBlue,
      activeImgSrc: EmptyPickupWhite,
      color: "#405178",
      width: "45px",
      height: "45px",
    },
    // {
    //   id: 2,
    //   name: "dischargeFromTruckContainerEmpty",
    //   event_name: "Empty Container Dropoff by Truck",
    //   title: "Empty Dropoff",
    //   count: state.emptyPickupCount,
    //   imgSrc: EmptyPickupBlue,
    //   activeImgSrc: EmptyPickupWhite,
    //   color: "#405178",
    //   width: "45px",
    //   height: "45px",
    // },
  ];

  let delayed = [];

  let counters = {
    origin: [...origin],
    in_transit: [...in_transit],
    // transhipment: [{}, {}],
    destination: [...destination],
    completed: [...completed],
    untrackable: [],
    delayed: [],
    detained: [
      {
        id: 1,
        name: "0-5",
        event_name: "0-5",
        title: "0-5 (days)",
        count: 0,
        imgSrc: EmptyPickupBlue,
        activeImgSrc: EmptyPickupWhite,
        color: "#405178",
        width: "45px",
        height: "45px",
      },
      {
        id: 2,
        name: "6-10",
        event_name: "6-10",
        title: "6-10 (days)",
        count: 0,
        imgSrc: EmptyPickupBlue,
        activeImgSrc: EmptyPickupWhite,
        color: "#405178",
        width: "45px",
        height: "45px",
      },
      {
        id: 3,
        name: "11-15",
        event_name: "11-15",
        title: "11-15 (days)",
        count: 0,
        imgSrc: EmptyPickupBlue,
        activeImgSrc: EmptyPickupWhite,
        color: "#405178",
        width: "45px",
        height: "45px",
      },
      {
        id: 4,
        name: "16-21",
        event_name: "16-21",
        title: "16-21 (days)",
        count: 0,
        imgSrc: EmptyPickupBlue,
        activeImgSrc: EmptyPickupWhite,
        color: "#405178",
        width: "45px",
        height: "45px",
      },
      {
        id: 5,
        name: ">21",
        event_name: ">21",
        title: ">21 (days)",
        count: 0,
        imgSrc: EmptyPickupBlue,
        activeImgSrc: EmptyPickupWhite,
        color: "#405178",
        width: "45px",
        height: "45px",
      },
    ],
    all: {
      origin: [...origin],
      in_transit: [...in_transit],
      destination: [...destination],
      completed: [...completed],
      // {
      //   id: 1,
      //   name: "emptyPickup",
      //   title: "Empty Pickup",
      //   count: state.emptyPickupCount,
      //   imgSrc: EmptyPickupBlue,
      //   activeImgSrc: EmptyPickupWhite,
      //   color: "#405178",
      //   width: "45px",
      //   height: "45px",
      // },
      // {
      //   id: 2,
      //   name: "dispatch",
      //   title: "Dispatch",
      //   count: state.dispatchCount,
      //   imgSrc: DispatchBlue,
      //   activeImgSrc: DispatchWhite,
      //   color: "#405178",
      //   width: "45px",
      //   height: "45px",
      // },
      // {
      //   id: 3,
      //   name: "gateIn",
      //   title: "Gate-In",
      //   count: state.gateInCount,
      //   imgSrc: GateInBlue,
      //   activeImgSrc: GateInWhite,
      //   color: "#405178",
      //   width: "45px",
      //   height: "45px",
      // },
      // {
      //   id: 4,
      //   name: "departure",
      //   title: "Departure",
      //   count: state.vesselDepartCount,
      //   imgSrc: OriginDepartureBlue,
      //   activeImgSrc: OriginDepartureWhite,
      //   color: "#405178",
      //   width: "45px",
      //   height: "45px",
      // },
      // {
      //   id: 5,
      //   name: "transshipment",
      //   title: "Transhipment",
      //   count: state.transshipmentCount,
      //   imgSrc: TransshipmentBlue,
      //   activeImgSrc: TransshipmentWhite,
      //   color: "#405178",
      //   width: "45px",
      //   height: "45px",
      // },
      // {
      //   id: 6,
      //   name: "destination",
      //   title: "Destination",
      //   count: state.destinationCount,
      //   imgSrc: DestinationBlue,
      //   activeImgSrc: DestinationWhite,
      //   color: "#405178",
      //   width: "45px",
      //   height: "45px",
      // },
      // {
      //   id: 7,
      //   name: "gateOut",
      //   title: "Gate-Out",
      //   count: state.gateOutCount,
      //   imgSrc: GateOutBlue,
      //   activeImgSrc: GateOuteWhite,
      //   color: "#405178",
      //   width: "45px",
      //   height: "45px",
      // },
      // {
      //   id: 8,
      //   name: "emptyReturn",
      //   title: "Empty Return",
      //   count: state.emptyReturnCount,
      //   imgSrc: EmptyPickupBlue,
      //   activeImgSrc: EmptyPickupWhite,
      //   color: "#405178",
      //   width: "45px",
      //   height: "45px",
      // },
    },
  };
  let mainCounters = ["origin", "in_transit", "destination", "completed"];
  return (
    <div
      // className="row justify-content-between px-3"
      className="sub_counters_exim"
      // style={{ gap: "20px" }}
    >
      {props?.parentCounter != "all" ? (
        <>
          {counters[`${props?.parentCounter}`].map((item, idx) => {
            let subCounterKey = props.parentCounter + "_" + item.name;
            let countValue = 0;
            if (props.counters.hasOwnProperty(subCounterKey)) {
              countValue = props.counters[subCounterKey];
            }
            // if (countValue > 0 || props.parentCounter === "all") {
              if(!props.subCountersTab.includes(item.name) && !(props.parentCounter=="completed" || props.parentCounter=="detained")){
                return <></>
              }
           else { 
            return (
              <div className="" 
              key={idx}>
                <div
                  id={item.id}
                  // className={`card d-flex flex-row justify-content-between align-items-center mr-3 py-2 px-3 card-container`}
                  className="card flex-row justify-content-around p-2 col"
                  style={{
                    borderRadius: "17px",
                    color:
                      state.activeCounterId == item.id ? "white" : item.color,
                    background:
                      state.activeCounterId == item.id ? item.color : "white",
                    // minWidth: "118px",
                    // width:"134px",
                    // overflow: "scroll",
                    cursor: "pointer",
                    width: "12rem",
                    border: "none"
                  }}
                  onClick={() => handleCounterClick(item.id, item.name)}
                  data-tooltip-id={item.name}
                  data-tooltip-content={item.title}
                >
                  <div className="icon-container">
                    <img
                      src={
                        state.activeCounterId == item.id
                          ? item.activeImgSrc
                          : item.imgSrc
                      }
                      style={{
                        width: item.width,
                        height: item.height,
                        // filter: "saturate(0.5)",
                      }}
                    ></img>
                  </div>

                  <div
                    // className='d-flex flex-column justify-items-center align-items-left ml-0p'
                    className="d-flex flex-column justify-content-around"
                    style={{ fontWeight: 700, marginLeft: "10px" }}
                  >
                    <div
                      style={{
                        fontSize: "10px",
                        lineHeight: "18px",
                        fontFamily: "inherit",
                        whiteSpace: "nowrap",
                        color:
                          state.activeCounterId == item.id
                            ? "white"
                            : "#858796",
                      }}
                    >
                      {item.title}
                    </div>

                    <div
                      style={{
                        fontSize: "15px",
                        lineHeight: "28px",
                        textAlign: "center",
                        color:
                          state.activeCounterId == item.id
                            ? "white"
                            : "#858796",
                      }}
                    >
                      {/* {item.count} */}
                      {countValue}
                    </div>
                  </div>
                </div>
                <Tooltip id={item.name} />
              </div>
            );
          }
            // }
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SubTrackingCounters;
