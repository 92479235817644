import React, { Component } from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM } from "../utils";
import { createStaticHandler } from '@remix-run/router';
import BillingTripsData from "./BillingTripsDataGrid";
import $ from "jquery"
import CountUp from 'react-countup';
import ViewItems from '../booking/viewitems';
import UploadPOD from "./uploadpodbutton";
// import UploadInvoice from "./uploadInvoicebutton.js";
import ViewPOD from "./viewpodbutton";
import ViewInvoice from "./viewinvoicebutton.js";
import ViewBatchItems from "./viewbatchitems.js";
import Datetime from 'react-datetime';
import SweetAlert from 'react-bootstrap-sweetalert';
import { row } from 'mathjs';
import Favourites from "../common/favouritesComponent";



export default class BillingTrips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isuploadcsv: 0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            rowData: [],
            liable: "btn-success",
            pendinglsp: "btn-default",
            pendingcust: "btn-default",
            submitfin: "btn-default",
            paystat: "btn-default",
            liableshow: "show-m",
            pendinglspshow: "show-n",
            pendingcustshow: "show-n",
            submitfinshow: "show-n",
            paystatshow: "show-n",
            actiontype: "liable",
            overlay: "show-n",
            pendingPODCnt: [],
            pendinglspCnt: [],
            pendingcustomerCnt: [],
            counterpendcust: [],
            counterpendfin: [],
            counterpendclose: [],
            submitFinanceCnt: [],
            paymentCloseCnt: [],
            oRowData: [],
            dieselFreightRate: 0,
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'callId' },
                        { field: 'direction' },
                        { field: 'number', minWidth: 150 },
                        { field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
                        { field: 'switchCode', minWidth: 150 },
                    ],
                    defaultColDef: {
                        flex: 1,
                    },
                },
                getDetailRowData: (params) => {
                    params.successCallback(params.data.callRecords);
                },
            },
            frameworkComponents: {
                UploadPOD: UploadPOD,
                // UploadInvoice:UploadInvoice,
                ViewPOD: ViewPOD,
                ViewInvoice: ViewInvoice,
                ViewBatchItems: ViewBatchItems
            },
            delivered_date: "",
            goods_condition: "",
            invoice_date:""
        }
        this.onClickUploadPOD = this.onClickUploadPOD.bind(this);
        // this.onClickUploadInvoice = this.onClickUploadInvoice.bind(this);
        this.loadTrips = this.loadTrips.bind(this);
        this.onApproveLSPData = this.onApproveLSPData.bind(this);
        this.latestDieselPrice = this.latestDieselPrice.bind(this);
        this.onRejectCustomer = this.onRejectCustomer.bind(this);
    }
    componentDidMount() {
        redirectURL.post("/billing/configurations")
            .then((response) => {
                //console.log("response ", response.data)
                var records = response.data.records;
                if (records.length > 0) {
                    if (records[0].trips_data_from == "api") {
                        this.setState({
                            isuploadcsv: 0
                        })
                    }
                    else {
                        this.setState({
                            isuploadcsv: 1
                        })
                    }
                    this.latestDieselPrice();
                }
                else {
                    redirectToConfigurations()
                }
            })
    }
    latestDieselPrice() {
        // redirectURL.post("/billing/latestapproveddieselprice")
        //     .then(async (response) => {
        //         var dieselFreightRate = 0;
        //         if (response.data.status === "success") {
        //             var records = response.data.records;

        //             dieselFreightRate = (records[0].changed_diesel_price !== "" && records[0].changed_diesel_price !== undefined) ? records[0].changed_diesel_price : records[0].applicable_diesel_price;
        //             console.log(dieselFreightRate, "dieselFreightRate")
        //             await this.setState({
        //                 dieselFreightRate: dieselFreightRate
        //             })

        this.loadTrips(1);
        //     }

        // })
    }
    loadTrips = (tab) => {
        redirectURL.post("/billing/trips")
            .then((response) => {
                // console.log("response ", response.data)
                var records = response.data.records;
                var pendingPODCnt = [];
                var pendinglspCnt = [];

                var counterpendcust = [];
                var counterpendcustbatch = []
                var counterpendfinbatch = []
                var counterpendfin = [];
                var counterpendclosebatch = []
                var counterpendclose = [];

                records.length > 0 && records.map((e, index) => {
                    // console.log(index, e)
                    if (e.trip_status == 0) pendingPODCnt.push(e)
                    else if (e.trip_status == 1 || e.trip_status == 5) pendinglspCnt.push(e)
                    else if (e.trip_status == 2 || e.trip_status == 6) {
                        counterpendcust.push(e);

                        // Check if the invoice already exists in counterpendcustbatch
                        var filter = counterpendcustbatch.filter(f => f.invoice_no === e.invoice_no);

                        if (filter.length === 0) {
                            // If not found, create a new object and add it to counterpendcustbatch
                            var obj = {
                                invoice_no: e.invoice_no,
                                invoice_date: e.invoice_date,
                                invoice_uploaded_by_user_name: e.invoice_uploaded_by_user_name,
                                total_invoice_amount: e.total_invoice_amount,
                                invoice_doc: e.invoice_doc,
                                transporter_remarks: e.transporter_remarks,
                                finance_remarks : e.finance_remarks,
                                total_approved_cost: e.total_approved_cost,
                                trip_status_txt: e.trip_status_txt,
                                trip_status:e.trip_status
                            };
                            counterpendcustbatch.push(obj);
                        } else {
                            // If found, update the total_approved_cost of the existing object
                            filter[0].total_approved_cost += e.total_approved_cost;
                        }

                    }
                    else if (e.trip_status == 3) {
                        counterpendfin.push(e)
                        var filter = counterpendfinbatch.filter(f => f.invoice_no === e.invoice_no);

                        if (filter.length === 0) {

                            // If not found, create a new object and add it to counterpendcustbatch
                            var obj = {
                                invoice_no: e.invoice_no,
                                invoice_date: e.invoice_date,
                                invoice_uploaded_by_user_name: e.invoice_uploaded_by_user_name,
                                total_invoice_amount: e.total_invoice_amount,
                                invoice_doc: e.invoice_doc,
                                customer_remarks: e.customer_remarks,
                                total_approved_cost: e.total_approved_cost,
                                trip_status_txt: e.trip_status_txt,
                                invoice_approved_date : e.invoice_approved_date,
                                invoice_approved_by_user_name : e.invoice_approved_by_user_name,
                                finance_remarks : e.finance_remarks,
                                trip_status:e.trip_status

                            };
                            counterpendfinbatch.push(obj);
                        } else {
                            // If found, update the total_approved_cost of the existing object
                            filter[0].total_approved_cost += e.total_approved_cost;
                        }
                    }
                    else if (e.trip_status == 4) {
                        counterpendclose.push(e)
                        var filter = counterpendclosebatch.filter(f => f.invoice_no === e.invoice_no);


                        if (filter.length === 0) {

                            // If not found, create a new object and add it to counterpendcustbatch
                            var obj = {
                                invoice_no: e.invoice_no,
                                invoice_date: e.invoice_date,
                                invoice_uploaded_by_user_name: e.invoice_uploaded_by_user_name,
                                total_invoice_amount: e.total_invoice_amount,
                                invoice_doc: e.invoice_doc,
                                transporter_remarks: e.transporter_remarks,
                                total_approved_cost: e.total_approved_cost,
                                trip_status_txt: e.trip_status_txt,
                                invoice_approved_date : e.invoice_approved_date,
                                invoice_approved_by_user_name : e.invoice_approved_by_user_name,
                                finance_aproved_date : e.finance_aproved_date,
                                finance_aproved_by_user_name : e.finance_aproved_by_user_name,
                                finance_remarks:e.finance_remarks,
                                trip_status:e.trip_status


                            };
                            counterpendclosebatch.push(obj);
                        } else {
                            // If found, update the total_approved_cost of the existing object
                            filter[0].total_approved_cost += e.total_approved_cost;
                        }

                    }
                })

                console.log(pendinglspCnt , "pendinglspCnt")
                this.setState({
                    oRowData: records,
                    pendingPODCnt: pendingPODCnt,
                    pendinglspCnt: pendinglspCnt,
                    counterpendcustbatch: counterpendcustbatch,
                    counterpendcust: counterpendcust,
                    counterpendfinbatch: counterpendfinbatch,
                    counterpendfin: counterpendfin,
                    counterpendclosebatch: counterpendclosebatch,
                    counterpendclose: counterpendclose,
                })
                this.showTabView(tab);
            })
    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

    };
    showTabView(oVal) {
        // this.setState({

        //     overlay: "show-m"
        // })
        setTimeout(() => {
            if (oVal === 1) {
                this.setState({
                    liable: "btn-success",
                    pendinglsp: "btn-default",
                    pendingcust: "btn-default",
                    submitfin: "btn-default",
                    paystat: "btn-default",
                    liableshow: "show-m",
                    pendinglspshow: "show-n",
                    pendingcustshow: "show-n",
                    submitfinshow: "show-n",
                    paystatshow: "show-n",
                    actiontype: "liable",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 0,
                    showSendRejectBtn: 0,
                    rowData: this.state.pendingPODCnt
                });
            }

            if (oVal === 2) {
                this.setState({
                    liable: "btn-default",
                    pendinglsp: "btn-success",
                    pendingcust: "btn-default",
                    submitfin: "btn-default",
                    paystat: "btn-default",
                    liableshow: "show-m",
                    pendinglspshow: "show-n",
                    pendingcustshow: "show-n",
                    submitfinshow: "show-n",
                    paystatshow: "show-n",
                    actiontype: "pendinglsp",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 1,
                    showSendRejectBtn: 0,
                    rowData: this.state.pendinglspCnt,
                });
            }

            if (oVal === 3) {
                this.setState({
                    liable: "btn-default",
                    pendinglsp: "btn-default",
                    pendingcust: "btn-success",
                    submitfin: "btn-default",
                    paystat: "btn-default",
                    liableshow: "show-m",
                    pendinglspshow: "show-n",
                    pendingcustshow: "show-n",
                    submitfinshow: "show-n",
                    paystatshow: "show-n",
                    actiontype: "pendingcust",
                    overlay: "show-n",
                    showSendBackBtn: 1,
                    showSendApproveBtn: 1,
                    showSendRejectBtn: 1,
                    rowData: this.state.counterpendcustbatch,
                });
            }

            if (oVal === 4) {
                this.setState({
                    liable: "btn-default",
                    pendinglsp: "btn-default",
                    pendingcust: "btn-default",
                    submitfin: "btn-success",
                    paystat: "btn-default",
                    liableshow: "show-m",
                    pendinglspshow: "show-n",
                    pendingcustshow: "show-n",
                    submitfinshow: "show-n",
                    paystatshow: "show-n",
                    actiontype: "submitfin",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 1,
                    showSendRejectBtn: 1,
                    rowData: this.state.counterpendfinbatch,
                });
            }

            if (oVal === 5) {
                this.setState({
                    liable: "btn-default",
                    pendinglsp: "btn-default",
                    pendingcust: "btn-default",
                    submitfin: "btn-default",
                    paystat: "btn-success",
                    liableshow: "show-m",
                    pendinglspshow: "show-n",
                    pendingcustshow: "show-n",
                    submitfinshow: "show-n",
                    paystatshow: "show-n",
                    actiontype: "paystatus",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 0,
                    showSendRejectBtn: 0,
                    rowData: this.state.counterpendclosebatch,
                });
            }
        }, 500)
    }

    hideSlideBlock() {
        this.setState({
            slidershow: "",
            sliderInvoiceshow: "",
            sliderForApprovePendingCustomerOrFinnace:"",
            sliderForRejectPendingCustomerOrFinnace:"",
            overlay: "show-n"
        })
    }
    onApproveLSPData = (rownodes, actiontype) => {
        // console.log("paramets", rownodes)
        if (rownodes.length > 0) {
            var params = {
                rownodelist: JSON.stringify(rownodes),

                email: localStorage.getItem("email"),
                user_name: localStorage.getItem("user_name")
            }
            // if (actiontype === "pendinglsp") {
            //     redirectURL.post("/billing/lspaction", params)
            //         .then((response) => {
            //             if (response.data.status === "success") {
            //                 // this.loadTrips();
            //                 window.location.reload()
            //             }
            //             else {

            //             }
            //         })
            // }

            if (actiontype === "pendingcust") {
                params.customer_remarks = this.state.approve_remarks
                    redirectURL.post("/billing/customerapprv", params)
                        .then((response) => {
                            if (response.data.status === "success") {
                                this.loadTrips(3);

                                this.setState({
                                    sliderForApprovePendingCustomerOrFinnace:"",
                                    show: true,
                                    basicType: "success",
                                    basicTitle: "Data Updated Successfully",
                                    overlay:"show-n"

                                })

                                $("#approve_remarks").val("")

                            }
                            else {

                            }
                        })
            }
            if (actiontype === "submitfin") {
                params.finance_remarks = this.state.approve_remarks
                    redirectURL.post("/billing/finapprv", params)
                        .then((response) => {
                            if (response.data.status === "success") {
                                this.loadTrips(4);

                                this.setState({
                                    sliderForApprovePendingCustomerOrFinnace:"",
                                    show: true,
                                    basicType: "success",
                                    basicTitle: "Data Updated Successfully",
                                    overlay:"show-n"

                                })
                                $("#approve_remarks").val("")

                            }
                            else {

                            }
                        })
            }
        }
    }
    onRejectCustomer = (rownodes, actiontype) => {
        console.log('rownodes ', rownodes , actiontype)
        if (rownodes.length > 0) {
            var params = {
                rownodelist: JSON.stringify(rownodes),
            }

            console.log(params, "params")

            if (actiontype === "pendingcust") {
                params.customer_remarks = this.state.rejected_remarks
                    redirectURL.post("/billing/customeraction", params)
                        .then((response) => {
                            if (response.data.status === "success") {
                                this.loadTrips(3);

                                this.setState({
                                    sliderForRejectPendingCustomerOrFinnace:"",
                                    show: true,
                                    basicType: "success",
                                    basicTitle: "Data Updated Successfully",
                                    overlay:"show-n"

                                })
                                $("#rejected_remarks").val("")

                            }
                            else {

                            }
                        })
            }
            if (actiontype === "submitfin") {
                params.finance_remarks = this.state.rejected_remarks
                    redirectURL.post("/billing/finrej", params)
                        .then((response) => {
                            if (response.data.status === "success") {
                                this.loadTrips(4);

                                this.setState({
                                    sliderForRejectPendingCustomerOrFinnace:"",
                                    show: true,
                                    basicType: "success",
                                    basicTitle: "Data Updated Successfully",
                                    overlay:"show-n"

                                })
                                $("#rejected_remarks").val("")

                            }
                            else {

                            }
                        })
            }
        }
    }


    changeDateTimeValue = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            delivered_date: getHyphenDDMMMYYYYHHMM(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    changeDateTimeValue2 = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            invoice_date: getHyphenDDMMMYYYYHHMM(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }


    changeFileHandler = (event) => {

        this.setState({
            [event.target.name]: event.target.files[0]
        })
    }

    uploadDocument = (event) => {
        event.preventDefault();
        var delivered_date = this.state.delivered_date;
        var podDoc = this.state.podDoc;
        if (this.state.rownode.length > 0) {
            // console.log(this.state.upload_pod_remarks , "upload_pod_remarks")
            var rowid = this.state.rownode[0]._id;
            const formData = new FormData();
            formData.append("podDoc", podDoc);
            formData.append("delivered_date", delivered_date);
            formData.append("rowid", rowid);
            formData.append("email", localStorage.getItem("email"))
            formData.append("user_name", localStorage.getItem("user_name"))
            formData.append("goods_condition", this.state.goods_condition.value)
            formData.append("upload_pod_remarks", this.state.upload_pod_remarks)
            // console.log("FormData ", formData);
            redirectURL.post("/billing/savepoddoc", formData)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.loadTrips(1)

                        this.setState({
                            slidershow: "",
                            show: true,
                            basicType: "success",
                            basicTitle: "Data Updated Successfully",
                            delivered_date: "",
                            goods_condition: "",
                            upload_pod_remarks: "",
                            podDoc: "",
                            overlay:"show-n"

                        })

                        $("#upload_pod_remarks").val("")
                        $("#podDoc").val("")

                    }
                    else {
                        this.setState({
                            show: true,
                            basicType: "danger",
                            basicTitle: "Something went wrong"
                        })
                    }
                    // window.location.reload();
                })
        }
    }

    onClickUploadPOD(rownode) {
        this.setState({
            slidershow: "slide30",
            overlay: "show-m",
            rownode: [rownode.data]
        });
    }



    changeHandler = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        })
    }

    onApproveInvoiceData = (e) => {
        e.preventDefault();
        var rownodes = this.gridApi.getSelectedRows();
        var confirmToapprove = 0;
        // var params = {
        //     invoice_no : this.state.invoice_no,
        //     invoice_date : this.state.invoice_date,
        //     freight_amount:this.state.freight_amount,
        //     gst_amount:this.state.gst_amount,
        //     invoiceDocument : this.state.invoiceDoc 
        // }
        var row_ids = rownodes.map(e => e._id)
        // console.log(row_ids)
        const formData = new FormData();
        formData.append("invoiceDoc", this.state.invoiceDoc);
        formData.append("invoice_no", this.state.invoice_no);
        formData.append("invoice_date", this.state.invoice_date);
        formData.append("total_invoice_amount", this.state.total_invoice_amount);
        formData.append("rowIds", JSON.stringify(row_ids))
        formData.append("email", localStorage.getItem("email"))
        formData.append("user_name", localStorage.getItem("user_name"))
        formData.append("transporter_remarks", this.state.transporter_remarks)

        // // console.log("FormData ", formData);
        // console.log(params , "params_300")
        // if(rownodes.length > 0)
        // {
        //     rownodes.map((item) => {
        //         console.log(item.is_approve_status , "is_approve_status")
        //         if(item.is_approve_status === 2)
        //         {

        //         }
        //         else{
        //             confirmToapprove = 1
        //         }

        //     })
        // }
        console.log("confirmToapprove ", confirmToapprove, formData)
        setTimeout(() => {
            if (confirmToapprove === 0) {
                // this.onApproveLSPData(rownodes, this.props.actiontype ,);
                redirectURL.post("/billing/lspaction", formData)
                    .then((response) => {
                        console.log(response.data)
                        if (response.data.status === "success") {
                            this.loadTrips(2);

                            this.setState({
                                sliderInvoiceshow: "",
                                show: true,
                                basicType: "success",
                                basicTitle: "Data Updated Successfully",
                                overlay:"show-n",
                                // invoice_date:null,
                            })
                            $("#invoice_no").val("")
                            $("#total_approved_cost").val("")
                            $("#total_invoice_amount").val("")
                            $("#invoiceDoc").val("")
                            $("#transporter_remarks").val("")
                            $("#invoice_date").val("")


                            // window.location.reload()
                        }
                        else {
                            this.setState({
                                show: true,
                                basicType: "danger",
                                basicTitle: "Something went wrong"
                            })
                        }
                    })
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: "Please complete deviation process.",
                    basicType: "info"
                })
            }
        }, 500)
    }

    onClickApprove() {
        var rownodes = this.gridApi.getSelectedRows();
        // console.log(rownodes)

        if (rownodes.length > 0) {
            if (this.state.actiontype === 'pendinglsp') {
                var total_approved_cost = rownodes.reduce((sum, item) => sum + item.total_approved_cost, 0)
                this.setState({
                    sliderInvoiceshow: "slide30",
                    overlay: "show-m",
                    total_approved_cost: total_approved_cost,

                });
                $("#total_approved_cost").val(total_approved_cost)

            }
            else {
                // var cnf = window.confirm("Are you sure you want to approve?")
                this.setState({
                    show1:true,
                    basicType1:"info",
                    basicTitle1:"Are you sure you want to approve?"
                })
                // if (cnf == true) {
                //     this.setState({
                //         sliderForApprovePendingCustomerOrFinnace: "slide30",
                //         approvePendingCustomerOrFinnaceData: rownodes,
                //         overlay: "show-m",
                //     });
                // }


            }
        }



    }

    onClickReject() {
        var rownodes = this.gridApi.getSelectedRows();
        // console.log(rownodes)

        if (rownodes.length > 0) {

            // var cnf = window.confirm("Are you sure you want to reject?")
            this.setState({
                show2:true,
                basicType2:"info",
                basicTitle2:"Are you sure you want to reject?"
            })
            // if (cnf == true) {
            //     this.setState({
            //         sliderForRejectPendingCustomerOrFinnace: "slide30",
            //         rejectPendingCustomerOrFinnaceData: rownodes,
            //         overlay: "show-m",
            //     });
            // }



        }



    }

    onApprovePendingCustomerOrFinnace = (e) => {
        e.preventDefault()
        var confirmToapprove = 0;

        console.log("confirmToapprove ", confirmToapprove)
        setTimeout(() => {
            if (confirmToapprove === 0) {
                this.onApproveLSPData(this.state.approvePendingCustomerOrFinnaceData, this.state.actiontype);
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: "Please complete deviation process.",
                    basicType: "info"
                })
            }
        }, 500)
    }
    onRejectPendingCustomerOrFinnace = (e) => {
        e.preventDefault()
        var confirmToapprove = 0;

        console.log("confirmToapprove ", confirmToapprove)
        setTimeout(() => {
            if (confirmToapprove === 0) {
                this.onRejectCustomer(this.state.rejectPendingCustomerOrFinnaceData, this.state.actiontype);
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: "Please complete deviation process.",
                    basicType: "info"
                })
            }
        }, 500)
    }

    onClickViewBatchItems(rownode) {
        console.log("rownode ", rownode);

        this.setState({

            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [

                        {
                            headerName: Constants.TRIP_TRUCK_NO,
                            field: "truck_no",
                            width: 150,
                            // checkboxSelection:checkSelect
                            // checkboxSelection: (this.state.actiontype == "pendinglsp" || this.state.actiontype == "pendingcust") ? true : false

                        },
                        {
                            headerName: Constants.TRIP_CONSIGN_NO,
                            field: "consignment_code",
                            width: 160,
                        },
                        {
                            headerName: Constants.TRIP_REACHED_DEST_DATE,
                            field: "recent_dealer_reported",
                            width: 140,
                            valueGetter: function (params) {
                                try {
                                    if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "") {
                                        return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                                    }

                                }
                                catch (e) { }
                            }
                        },

                        // {
                        //     headerName: Constants.TRIP_INV_DATE,
                        //     field: "invoice_date",
                        //     width: 140,
                        //     valueGetter: function (params) {
                        //         try {
                        //             if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
                        //                 return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                        //             }

                        //         }
                        //         catch (e) { }
                        //     }
                        // },

                        {
                            headerName: Constants.TRIP_PLANT_NAME,
                            field: "consigner_name",
                            width: 100,
                        },
                        {
                            headerName: Constants.TRIP_CUSTOMER_CODE,
                            field: "consignee_code",
                            width: 100,
                        },
                        {
                            headerName: Constants.TRIP_CUSTOMER_NAME,
                            field: "consignee_name",
                            width: 180,
                        },
                        {
                            headerName: Constants.TRIP_CUSTOMER_STATE,
                            field: "consignee_state",
                            width: 180,
                        },
                        {
                            headerName: Constants.TRIP_CUSTOMER_CITY,
                            field: "consignee_city",
                            width: 180,
                        },

                        {
                            headerName: Constants.TRIP_TRANSPORTER_CODE,
                            field: "transporter_code",
                            width: 180,
                        },
                        {
                            headerName: Constants.TRIP_TRANSPORTER_NAME,
                            field: "transporter_name",
                            width: 180,
                        },
                        // {
                        //     headerName: Constants.TRIP_TOTAL_DISTANCE_KM,
                        //     field: "total_distance_travelled_km",
                        //     width: 140,
                        //     valueGetter: function (params) {
                        //         try {
                        //             if (params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "") {
                        //                 return parseInt(parseInt(params.data.total_distance_travelled_km) / 1000);
                        //             }
                        //         }
                        //         catch (e) { }
                        //     }
                        // },
                        {
                            headerName: "Total Distance Travelled (Mile)",
                            field: "total_travelled_trip_dist_km",
                            width: 150,
                        },
                        {
                            headerName: Constants.TRIP_DURATION_DAYS,
                            field: "trip_duration_days",
                            width: 120,
                        },
                        {
                            headerName: "Total Approved Cost",
                            field: "total_approved_cost",
                            width: 140,
                        },

                    ],
                    defaultColDef: {
                        // flex: 1,
                        minWidth: 100,
                        filter: true,
                        resizable: true,
                        initialWidth: 200,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        sortable: true
                    },
                    context: this,
                    overlayNoRowsTemplate: 'No rows to show',

                },
                getDetailRowData: async (param) => {
                    console.log(this.state.oRowData, rownode.data, "srinu")
                    const rowitems = this.state.oRowData.filter(e => e.invoice_no === rownode.data.invoice_no);
                    param.successCallback(rowitems);
                },

                masterDetail: true
            }
        });
        // console.log(propsdata);
        if (rownode.column.colDef.field == 'viewitems') {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else {

            rownode.node.setExpanded(false);
        }

    }

    render() {


        if (this.state.actiontype === 'liable') {
            var columnDefs = [

                {
                    headerName: Constants.TRIP_TRUCK_NO,
                    field: "truck_no",
                    width: 150,
                    // checkboxSelection:checkSelect
                    checkboxSelection: (this.state.actiontype == "pendinglsp" || this.state.actiontype == "pendingcust") ? true : false

                },
                {
                    headerName: Constants.TRIP_CONSIGN_NO,
                    field: "consignment_code",
                    width: 160,
                },
                {
                    headerName: Constants.TRIP_REACHED_DEST_DATE,
                    field: "recent_dealer_reported",
                    width: 140,
                    valueGetter: function (params) {
                        try {
                            if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "") {
                                return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                            }

                        }
                        catch (e) { }
                    }
                },
                {
                    headerName: Constants.TRIP_STATUS,
                    field: "trip_status_txt",
                    width: 160,
                },
                // {
                //     headerName: Constants.TRIP_INV_DATE,
                //     field: "invoice_date",
                //     width: 140,
                //     valueGetter: function (params) {
                //         try {
                //             if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
                //                 return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                //             }

                //         }
                //         catch (e) { }
                //     }
                // },

                {
                    headerName: Constants.TRIP_PLANT_NAME,
                    field: "consigner_name",
                    width: 100,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_CODE,
                    field: "consignee_code",
                    width: 100,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_NAME,
                    field: "consignee_name",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_STATE,
                    field: "consignee_state",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_CITY,
                    field: "consignee_city",
                    width: 180,
                },

                {
                    headerName: Constants.TRIP_TRANSPORTER_CODE,
                    field: "transporter_code",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_TRANSPORTER_NAME,
                    field: "transporter_name",
                    width: 180,
                },
                // {
                //     headerName: Constants.TRIP_TOTAL_DISTANCE_KM,
                //     field: "total_distance_travelled_km",
                //     width: 140,
                //     valueGetter: function (params) {
                //         try {
                //             if (params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "") {
                //                 return parseInt(parseInt(params.data.total_distance_travelled_km) / 1000);
                //             }
                //         }
                //         catch (e) { }
                //     }
                // },
                {
                    headerName: "Total Distance Travelled (Mile)",
                    field: "total_travelled_trip_dist_km",
                    width: 150,
                },
                {
                    headerName: Constants.TRIP_DURATION_DAYS,
                    field: "trip_duration_days",
                    width: 120,
                },
                {
                    headerName: "Total Approved Cost",
                    field: "total_approved_cost",
                    width: 140,
                },
                {
                    headerName: "",
                    field: "",
                    width: 120,
                    pinned: "right",
                    cellRendererSelector: function (params) {
                        return {
                            component: "UploadPOD"
                        }
                    },
                }

            ]
        }
        else {
            if (this.state.actiontype === 'pendinglsp') {
                var columnDefs = [

                    {
                        headerName: Constants.TRIP_TRUCK_NO,
                        field: "truck_no",
                        width: 150,
                        // checkboxSelection:checkSelect
                        checkboxSelection: (this.state.actiontype == "pendinglsp" || this.state.actiontype == "pendingcust") ? true : false

                    },
                    {
                        headerName: Constants.TRIP_CONSIGN_NO,
                        field: "consignment_code",
                        width: 160,
                    },
                    {
                        headerName: Constants.TRIP_REACHED_DEST_DATE,
                        field: "recent_dealer_reported",
                        width: 140,
                        valueGetter: function (params) {
                            try {
                                if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "") {
                                    return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                                }

                            }
                            catch (e) { }
                        }
                    },
                    {
                        headerName: Constants.TRIP_STATUS,
                        field: "trip_status_txt",
                        width: 160,
                    },
                    // {
                    //     headerName: Constants.TRIP_INV_DATE,
                    //     field: "invoice_date",
                    //     width: 140,
                    //     valueGetter: function (params) {
                    //         try {
                    //             if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
                    //                 return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                    //             }

                    //         }
                    //         catch (e) { }
                    //     }
                    // },

                    {
                        headerName: Constants.TRIP_PLANT_NAME,
                        field: "consigner_name",
                        width: 100,
                    },
                    {
                        headerName: Constants.TRIP_CUSTOMER_CODE,
                        field: "consignee_code",
                        width: 100,
                    },
                    {
                        headerName: Constants.TRIP_CUSTOMER_NAME,
                        field: "consignee_name",
                        width: 180,
                    },
                    {
                        headerName: Constants.TRIP_CUSTOMER_STATE,
                        field: "consignee_state",
                        width: 180,
                    },
                    {
                        headerName: Constants.TRIP_CUSTOMER_CITY,
                        field: "consignee_city",
                        width: 180,
                    },

                    {
                        headerName: Constants.TRIP_TRANSPORTER_CODE,
                        field: "transporter_code",
                        width: 180,
                    },
                    {
                        headerName: Constants.TRIP_TRANSPORTER_NAME,
                        field: "transporter_name",
                        width: 180,
                    },
                    // {
                    //     headerName: Constants.TRIP_TOTAL_DISTANCE_KM,
                    //     field: "total_distance_travelled_km",
                    //     width: 140,
                    //     valueGetter: function (params) {
                    //         try {
                    //             if (params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "") {
                    //                 return parseInt(parseInt(params.data.total_distance_travelled_km) / 1000);
                    //             }
                    //         }
                    //         catch (e) { }
                    //     }
                    // },
                    {
                        headerName: "Total Distance Travelled (Mile)",
                        field: "total_travelled_trip_dist_km",
                        width: 150,
                    },
                    {
                        headerName: Constants.TRIP_DURATION_DAYS,
                        field: "trip_duration_days",
                        width: 120,
                    },
                    {
                        headerName: "Total Approved Cost",
                        field: "total_approved_cost",
                        width: 140,
                    },

                    {
                        headerName: "Goods Condition",
                        field: "goods_condition",
                        width: 120,

                    },
                    {
                        headerName: "POD Remarks",
                        field: "upload_pod_remarks",
                        width: 120,

                    },
                    {
                        headerName: "POD Uploaded Date",
                        field: "pod_upload_date",
                        width: 150,
                        valueGetter: function (params) {
                            try {
                                if (params.data.pod_upload_date != undefined && params.data.pod_upload_date != "") {
                                    return getHyphenDDMMMYYYYHHMM(params.data.pod_upload_date);
                                }

                            }
                            catch (e) { }
                        }
                    },
                    {
                        headerName: "POD uploaded by",
                        field: "pod_uploaded_by_user_name",
                        width: 150,

                    },
                    {
                        headerName: "",
                        field: "view_invoice",
                        width: 120,
                        pinned: "right",
                        cellRendererSelector: function (params) {
                            return {
                                component: "ViewPOD"
                            }
                        },
                    },
                    {
                        headerName: "Remarks",
                        field: "transporter_remarks",
                        width: 120,
                        valueGetter: function (params) {
                            if (params.data.trip_status == 5) {
                                return params.data.customer_remarks
                            }
                            else {
                                return params.data.transporter_remarks
                            }
                        }
                    },

                ]
            }


            if (this.state.actiontype === 'pendingcust') {
                columnDefs = [
                    {
                        headerName: "Invoice No",
                        field: "invoice_no",
                        width: 150,
                        checkboxSelection: (this.state.actiontype == "pendinglsp" || this.state.actiontype == "pendingcust") ? true : false

                    },
                    {
                        headerName: "Invoice Uploaded Date",
                        field: "invoice_date",
                        width: 150,
                        valueGetter: function (params) {
                            try {
                                if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
                                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                                }

                            }
                            catch (e) { }
                        }
                    },
                    {
                        headerName: Constants.TRIP_STATUS,
                        field: "trip_status_txt",
                        width: 160,
                    },
                    {
                        headerName: "Invoice Uploaded By",
                        field: "invoice_uploaded_by_user_name",
                        width: 150,
                    },
                    {
                        headerName: "Total Approved Amount",
                        field: "total_approved_cost",
                        width: 150,


                    },
                    {
                        headerName: "Invoice Amount",
                        field: "total_invoice_amount",
                        width: 120,
                    },
                    {
                        headerName: "Difference Amount",
                        field: "difference_amount",
                        width: 120,
                        valueGetter: function (params) {
                            try {
                                if (params.data.total_approved_cost != undefined && params.data.total_invoice_amount != undefined) {
                                    var difference = params.data.total_invoice_amount >= params.data.total_approved_cost ? params.data.total_invoice_amount - params.data.total_approved_cost : params.data.total_approved_cost - params.data.total_invoice_amount;      
                                    return  difference
                                }

                            }
                            catch (e) {
                                return ""
                            }
                        }

                    },
                    {
                        headerName: "Remarks",
                        field: "transporter_remarks",
                        width: 120,
                        valueGetter: function (params) {
                            if (params.data.trip_status == 6) {
                                return params.data.finance_remarks  
                            }
                            else {
                                return params.data.transporter_remarks
                            }
                        }
                    },
                    {
                        headerName: "",
                        field: "ViewInvoice",
                        width: 120,
                        pinned: "right",
                        cellRendererSelector: function (params) {
                            return {
                                component: "ViewInvoice"
                            }
                        },
                    },
                    {
                        headerName: "",
                        field: "viewitems",
                        width: 120,
                        pinned: "right",
                        cellRendererSelector: function (params) {
                            return {
                                component: "ViewBatchItems"
                            }
                        },
                    }
                ]
            }
            if (this.state.actiontype === 'submitfin') {
                columnDefs = [
                    {
                        headerName: "Invoice No",
                        field: "invoice_no",
                        width: 150,
                        checkboxSelection: (this.state.actiontype == "pendinglsp" || this.state.actiontype == "pendingcust" || this.state.actiontype == "submitfin") ? true : false

                    },
                    {
                        headerName: "Invoice Uploaded Date",
                        field: "invoice_date",
                        width: 150,
                        valueGetter: function (params) {
                            try {
                                if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
                                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                                }

                            }
                            catch (e) { }
                        }
                    },
                    {
                        headerName: Constants.TRIP_STATUS,
                        field: "trip_status_txt",
                        width: 160,
                    },
                    {
                        headerName: "Invoice Uploaded By",
                        field: "invoice_uploaded_by_user_name",
                        width: 150,
                    },
                    {
                        headerName: "Total Approved Amount",
                        field: "total_approved_cost",
                        width: 150,


                    },
                    {
                        headerName: "Invoice Amount",
                        field: "total_invoice_amount",
                        width: 120,
                    },
                    {
                        headerName: "Difference Amount",
                        field: "difference_amount",
                        width: 120,
                        valueGetter: function (params) {
                            try {
                                if (params.data.total_approved_cost != undefined && params.data.total_invoice_amount != undefined) {
                                    var difference = params.data.total_invoice_amount >= params.data.total_approved_cost ? params.data.total_invoice_amount - params.data.total_approved_cost : params.data.total_approved_cost - params.data.total_invoice_amount;      
                                    return  difference
                                }

                            }
                            catch (e) {
                                return ""
                            }
                        }

                    },
                    {
                        headerName: "Invoice Approved By",
                        field: "invoice_approved_by_user_name",
                        width: 150,
                    },
                    {
                        headerName: "Invoice Approved Date",
                        field: "invoice_approved_date",
                        width: 150,
                        valueGetter: function (params) {
                            try {
                                if (params.data.invoice_approved_date != undefined && params.data.invoice_approved_date != "") {
                                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_approved_date);
                                }

                            }
                            catch (e) { }
                        }
                    },
                    {
                        headerName: "Remarks",
                        field: "customer_remarks",
                        width: 120,

                    },

                    {
                        headerName: "",
                        field: "ViewInvoice",
                        width: 120,
                        pinned: "right",
                        cellRendererSelector: function (params) {
                            return {
                                component: "ViewInvoice"
                            }
                        },
                    },
                    {
                        headerName: "",
                        field: "viewitems",
                        width: 120,
                        pinned: "right",
                        cellRendererSelector: function (params) {
                            return {
                                component: "ViewBatchItems"
                            }
                        },
                    }
                ]
            }
            if (this.state.actiontype === 'paystatus') {
                columnDefs = [
                    {
                        headerName: "Invoice No",
                        field: "invoice_no",
                        width: 150,
                        checkboxSelection: (this.state.actiontype == "pendinglsp" || this.state.actiontype == "pendingcust" || this.state.actiontype == "submitfin") ? true : false

                    },
                    {
                        headerName: "Invoice Uploaded Date",
                        field: "invoice_date",
                        width: 150,
                        valueGetter: function (params) {
                            try {
                                if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
                                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                                }

                            }
                            catch (e) { }
                        }
                    },
                    {
                        headerName: Constants.TRIP_STATUS,
                        field: "trip_status_txt",
                        width: 160,
                    },
                    {
                        headerName: "Invoice Uploaded By",
                        field: "invoice_uploaded_by_user_name",
                        width: 150,
                    },
                    {
                        headerName: "Total Approved Amount",
                        field: "total_approved_cost",
                        width: 150,


                    },
                    {
                        headerName: "Invoice Amount",
                        field: "total_invoice_amount",
                        width: 120,
                    },
                    {
                        headerName: "Difference Amount",
                        field: "difference_amount",
                        width: 120,
                        valueGetter: function (params) {
                            try {
                                if (params.data.total_approved_cost != undefined && params.data.total_invoice_amount != undefined) {
                                    var difference = params.data.total_invoice_amount >= params.data.total_approved_cost ? params.data.total_invoice_amount - params.data.total_approved_cost : params.data.total_approved_cost - params.data.total_invoice_amount;      
                                    return  difference
                                }

                            }
                            catch (e) {
                                return ""
                            }
                        }

                    },
                    {
                        headerName: "Invoice Approved By",
                        field: "invoice_approved_by_user_name",
                        width: 150,
                    },
                    {
                        headerName: "Invoice Approved Date",
                        field: "invoice_approved_date",
                        width: 150,
                        valueGetter: function (params) {
                            try {
                                if (params.data.invoice_approved_date != undefined && params.data.invoice_approved_date != "") {
                                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_approved_date);
                                }

                            }
                            catch (e) { }
                        }
                    },
                    {
                        headerName: "Payment Status Updated by",
                        field: "finance_aproved_by_user_name",
                        width: 150,
                    },
                    {
                        headerName: "Payment Completed on",
                        field: "finance_aproved_date",
                        width: 150,
                        valueGetter: function (params) {
                            try {
                                if (params.data.finance_aproved_date != undefined && params.data.finance_aproved_date != "") {
                                    return getHyphenDDMMMYYYYHHMM(params.data.finance_aproved_date);
                                }

                            }
                            catch (e) { }
                        }
                    },
                    {
                        headerName: "Remarks",
                        field: "finance_remarks",
                        width: 120,

                    },

                    {
                        headerName: "",
                        field: "ViewInvoice",
                        width: 120,
                        pinned: "right",
                        cellRendererSelector: function (params) {
                            return {
                                component: "ViewInvoice"
                            }
                        },
                    },
                    {
                        headerName: "",
                        field: "viewitems",
                        width: 120,
                        pinned: "right",
                        cellRendererSelector: function (params) {
                            return {
                                component: "ViewBatchItems"
                            }
                        },
                    }
                ]
            }
            // else {
            //     columnDefs.push({
            //         headerName: "",
            //         field: "invoice_no",
            //         width: 120,
            //         pinned: "right",
            //         cellRendererSelector: function (params) {
            //             return {
            //                 component: "ViewPOD"
            //             }
            //         },
            //     });
            // }
        }

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show || false}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={() => {
                        // if (this.state.basicType == "success" && this.state.actiontype === 'liable') {
                        //     window.location.reload()
                        // }
                        this.setState({
                            show: false
                        })

                    }}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1 || false}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    showCancel={true}
                    onCancel={() => {
                        this.setState({
                            show1: false,
                        })
                    }}
                    onConfirm={() => {
                        // if (this.state.basicType == "success" && this.state.actiontype === 'liable') {
                        //     window.location.reload()
                        // }
                        this.setState({
                            show1: false,
                            sliderForApprovePendingCustomerOrFinnace: "slide30",
                            approvePendingCustomerOrFinnaceData: this.gridApi.getSelectedRows(),
                            overlay: "show-m",
                        })

                    }}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show2 || false}
                    type={this.state.basicType2}
                    title={this.state.basicTitle2}
                    showCancel={true}
                    onCancel={() => {
                        this.setState({
                            show2: false,
                        })
                    }}
                    onConfirm={() => {
                        // if (this.state.basicType == "success" && this.state.actiontype === 'liable') {
                        //     window.location.reload()
                        // }
                        this.setState({
                            show2: false,
                            sliderForRejectPendingCustomerOrFinnace: "slide30",
                                rejectPendingCustomerOrFinnaceData: this.gridApi.getSelectedRows(),
                                overlay: "show-m",
                        })

                    }}
                >
                </SweetAlert>
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="fa fa-truck cus-i"></i>&nbsp;<span>Bill verification</span>
                        <Favourites />
                        {this.state.isuploadcsv == 1 ?
                            <a href={"/upload-trips"} className="float-right btn btn-warning">Upload Trips</a>
                        : ""}
                    </h5>
                    <div className="col-xl-12 col-md-12 ">
                        <div className="card">
                            <div className="card-body" style={{padding:"10px 0"}}>
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cursorPointer">
                                            <div className=" cirlce-d">
                                                <span className="sptile"><i className="fa fa-cubes f18 greenfont"></i><br /> Total  &nbsp;&nbsp;</span>
                                                <h4 className="txt-warning f30"><span className="counter"><CountUp end={this.state.oRowData.length} /></span></h4>
                                                <p>Total Pending Amount<br/><h6 className="txt-warning f20"><span className="counter"><CountUp end={this.state.oRowData.reduce((sum, item) => sum + item.total_approved_cost, 0)} /></span></h6>  </p>
                                                
                                            </div>
                                        </div>
                                        <div className="col cursorPointer">
                                            <div className=" cirlce-d">
                                                <span className="sptile"><i className="fa fa-upload f18 greenfont"></i><br />Pending Upload POD </span>
                                                <h4 className="txt-danger f30"><span className="counter"><CountUp end={this.state.pendingPODCnt.length} /></span></h4>
                                                <p>Pending EPOD <br/><h6 className="txt-danger f20"><span className="counter"><CountUp end={this.state.pendingPODCnt.reduce((sum, item) => sum + item.total_approved_cost, 0)} /></span></h6>  </p>

                                            </div>
                                        </div>
                                        <div className="col cursorPointer">
                                            <div className=" cirlce-d">
                                                <span className="sptile"><i className="fa fa-truck f18 greenfont"></i><br /> Pending With Transporters</span>
                                                <h4 className="txt-primary f30"><span className="counter"><CountUp end={this.state.pendinglspCnt.length} /></span></h4>
                                                <p>Pending Invoice <br/><h6 className="txt-primary f20"><span className="counter"><CountUp end={this.state.pendinglspCnt.reduce((sum, item) => sum + item.total_approved_cost, 0)} /></span></h6>  </p>

                                            </div>
                                        </div>
                                        <div className="col cursorPointer">
                                            <div className=" cirlce-d">
                                                <span className="sptile"><i className="fa fa-users f18 greenfont"></i><br /> Pending With Operations</span>
                                                <h4 className="txt-secondary f30"><span className="counter"><CountUp end={this.state.counterpendcust.length} /></span></h4>
                                                <p>Pending operation approval <br/><h6 className="txt-secondary f20"><span className="counter"><CountUp end={this.state.counterpendcust.reduce((sum, item) => sum + item.total_approved_cost, 0)} /></span></h6>  </p>

                                            </div>
                                        </div>
                                        <div className="col cursorPointer">
                                            <div className=" cirlce-d">
                                                <span className="sptile"><i className="fa fa-braille f18 greenfont"></i><br /> Submitted to Finance</span>
                                                <h4 className="txt-info f30"><span className="counter"><CountUp end={this.state.counterpendfin.length} /></span></h4>
                                                <p>Pending payment processing <br/><h6 className="txt-info f20"><span className="counter"><CountUp end={this.state.counterpendfin.reduce((sum, item) => sum + item.total_approved_cost, 0)} /></span></h6>  </p>

                                            </div>
                                        </div>
                                        <div className="col cursorPointer">
                                            <div className=" cirlce-d">
                                                <span className="sptile"><i className="fa fa-check f18 greenfont"></i><br /> Payment Close</span>
                                                <h4 className="txt-success f30"><span className="counter"><CountUp end={this.state.counterpendclose.length} /></span></h4>
                                                <p>Payment Completed <br/><h6 className="txt-success f20"><span className="counter"><CountUp end={this.state.counterpendclose.reduce((sum, item) => sum + item.total_approved_cost, 0)} /></span></h6>  </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 ">
                        <button onClick={this.showTabView.bind(this, 1)} type="button" className={"btn f14 " + (this.state.liable)}>Pending Upload POD</button>
                        <button onClick={this.showTabView.bind(this, 2)} type="button" className={"btn f14 " + (this.state.pendinglsp)}>Pending With Transporter</button>
                        <button onClick={this.showTabView.bind(this, 3)} type="button" className={"btn f14 " + (this.state.pendingcust)}>Pending With Operations </button>
                        <button onClick={this.showTabView.bind(this, 4)} type="button" className={"btn f14 " + (this.state.submitfin)}>Submitted To Finance</button>
                        <button onClick={this.showTabView.bind(this, 5)} type="button" className={"btn f14 " + (this.state.paystat)}>Payment Close</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-md-12 mb-4">
                        <div className="card shadow h-100 py-2">
                            <div className="card-body">
                                <div style={{ width: "100%", height: "60vh" }} className="ag-theme-balham">
                                    <AgGridReact
                                        rowData={this.state.rowData}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        enableRangeSelection={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        rowSelection={"multiple"
                                        }

                                    />
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12" style={{ textAlign: "right" }}>
                                        {/* {(this.props.showSendBackBtn === 1)?
                                            <button type="button" className="btn btn-primary btncm" onClick={this.onClickSendBack.bind(this)}>Send Back</button>
                                        :""} */}
                                        {(this.state.actiontype == "pendinglsp" || this.state.actiontype == "pendingcust" || this.state.actiontype == "submitfin") ?
                                            <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >{this.state.actiontype == "pendinglsp" ? "Upload Inovice" : "Approve"}</button>
                                            : ""}
                                        {(this.state.actiontype == "pendingcust" || this.state.actiontype == "submitfin") ?
                                            <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button>
                                            : ""}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-block " + (this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
                <div className={"sliderBlock2 " + (this.state.slidershow)}>
                    <h5 className="crd-bg p-10p">Upload POD</h5>
                    <div className="row">

                        <div className="col-sm-12">

                            <div className="slide-body">
                                <form className="theme" method="POST" enctype="multipart/form-data" onSubmit={this.uploadDocument}>
                                    <div className="row p-20p">
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Delivered Date<span className="rclr fbold">*</span></label>
                                            {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
                                            <Datetime
                                                inputProps={{ placeholder: 'Delivered Date', name: 'startDate', autoComplete: 'off' }}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat="HH:mm"
                                                name="startDate"
                                                closeOnSelect={true}
                                                id="delivered_date"
                                                // value={this.state.delivered_date}
                                                onChange={this.changeDateTimeValue.bind(this)}
                                            />

                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Goods Condition<span className="rclr fbold">*</span></label>
                                            {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
                                            <Select
                                                placeholder={"select goods condition"}
                                                closeMenuOnSelect={true}
                                                value={this.state.goods_condition}
                                                className={"border-radius-0"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        goods_condition: e
                                                    })
                                                }}
                                                style={{ borderRadius: "0px" }}
                                                options={[
                                                    { label: "Intact", value: "Intact" },
                                                    { label: "Damaged", value: "Damaged" },
                                                    { label: "Shortage", value: "Shortage" },
                                                    { label: "Lost", value: "Lost" },
                                                    { label: "Others", value: "Others" }
                                                ]}
                                                required />



                                        </div>

                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Remarks</label>
                                            <input type="text" name="upload_pod_remarks" id="upload_pod_remarks" className="form-control"  onChange={this.changeHandler}  />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Upload Document<span className="rclr fbold">*</span></label>
                                            <input type="file" name="podDoc" id="podDoc" className="form-control" onChange={this.changeFileHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">SUBMIT</button>
                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"sliderBlock2 " + (this.state.sliderInvoiceshow)}>
                    <h5 className="crd-bg p-10p">Upload Invoice </h5>
                    <div className="row">

                        <div className="col-sm-12">

                            <div className="slide-body">
                                <form className="theme" method="POST" enctype="multipart/form-data" onSubmit={this.onApproveInvoiceData.bind(this)} >
                                    <div className="row p-20p">

                                        <div className="form-group col-sm-12 mb-20p">
                                            <label> Invoice No<span className="rclr fbold">*</span></label>
                                            <input type="text" name="invoice_no" id="invoice_no" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Invoice Date<span className="rclr fbold">*</span></label>
                                            {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
                                            <Datetime
                                                inputProps={{ placeholder: 'Invoice Date', name: 'startDate', autoComplete: 'off' }}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat="HH:mm"
                                                name="invoiceDate"
                                                closeOnSelect={true}
                                                id="invoice_date"
                                                onChange={this.changeDateTimeValue2.bind(this)}
                                            />

                                        </div>

                                        <div className="form-group col-sm-12 mb-20p">
                                            <label> Total Approved Cost<span className="rclr fbold">*</span></label>
                                            <input type="text" name="total_approved_cost" id="total_approved_cost" className="form-control" onChange={this.changeHandler} disabled={true} />
                                        </div>

                                        <div className="form-group col-sm-12 mb-20p">
                                            <label> Total Invoice Amount<span className="rclr fbold">*</span></label>
                                            <input type="text" name="total_invoice_amount" id="total_invoice_amount" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Upload Invoice<span className="rclr fbold">*</span></label>
                                            <input type="file" name="invoiceDoc" id="invoiceDoc" className="form-control" onChange={this.changeFileHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Remarks</label>
                                            <input type="text" name="transporter_remarks" id="transporter_remarks" className="form-control" onChange={this.changeHandler}  />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">SUBMIT</button>
                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"sliderBlock2 " + (this.state.sliderForApprovePendingCustomerOrFinnace)}>
                    <h5 className="crd-bg p-10p">Upload Remarks </h5>
                    <div className="row">

                        <div className="col-sm-12">

                            <div className="slide-body">
                                <form className="theme" method="POST" enctype="multipart/form-data" onSubmit={this.onApprovePendingCustomerOrFinnace.bind(this)} >
                                    <div className="row p-20p">
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Remarks</label>
                                            <textarea type="text" name="approve_remarks" id="approve_remarks" className="form-control" onChange={this.changeHandler}  />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">SUBMIT</button>
                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"sliderBlock2 " + (this.state.sliderForRejectPendingCustomerOrFinnace)}>
                    <h5 className="crd-bg p-10p">Upload Remarks </h5>
                    <div className="row">

                        <div className="col-sm-12">

                            <div className="slide-body">
                                <form className="theme" method="POST" enctype="multipart/form-data" onSubmit={this.onRejectPendingCustomerOrFinnace.bind(this)} >
                                    <div className="row p-20p">
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Remarks<span className="rclr fbold">*</span></label>
                                            <textarea type="text" name="rejected_remarks" id="rejected_remarks" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">SUBMIT</button>
                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={this.state.liableshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={this.state.actiontype}
                        showSendBackBtn={this.state.showSendBackBtn}
                        showSendApproveBtn={this.state.showSendApproveBtn}
                        showSendRejectBtn={this.state.showSendRejectBtn}
                        context={this}
                    />
                </div> */}
                {/* <div className={this.state.pendinglspshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={"transporterpending"}
                    />
				</div>
                <div className={this.state.pendingcustshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={"customerpending"}
                    />
				</div>
                <div className={this.state.submitfinshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={"finance"}
                    />
				</div>
                <div className={this.state.paystatshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={"payment"}
                    />
				</div> */}


                <div className={"overlay-block " + (this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
            </div>
        );
    }
}
function redirectToConfigurations() {
    window.location.href = "/configurations"
}
function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map11.get(key);
        if (!collection) {
            map11.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map11;
}