/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */


import React, { Component } from 'react';
import { render } from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import ColorPicker from "../manage/routeColorPicker.js";
import { getHyphenYYYYMMDDHHMMSS } from '../common/utils';
// import InputColor from 'react-input-color';
import redirectURL from "../redirectURL";
import Favourites from "../common/favouritesComponent";
var infoBox = require('../../common/google-infowindow');
var plant_flag = 0
var plant_falg_markers = []
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var map;
const hexacolors = ["#000000", "#FF4040", "#CD3333", "#8B2323", "#B22222", "#FF3030", "#EE2C2C", "#CD2626", "#8B1A1A", "#FF0000", "#EE0000", "#CD0000", "#8B0000", "#800000", "#8E388E"]
var plantMarkers = []
export default class DealerZoneWiseRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadshow: 'show-n',
            overly: 'show-n',
            show: false,
            defaultColDef: {
                //flex: 1,
                minWidth: 50,
                filter: true,
                sortable: true,
                resizable: true
            },
            rowData: [],
            startDate: "",
            endDate: "",
            filter_by: { "label": "Invoice", "value": 1 },
            selected_dealer: {},
            selected_zone: {},
            zoneOptions: [],
            dealerOptions: [],
            routeOptions: [],
            selectedRoutes: [],
            routeToChangeColor: {},
            rowHexaColors: {},
            showDetails: false,
            frameworkComponents: {
                ColorPicker: ColorPicker,
            },
            columnDefs: [],
            currentQuery: {}

        };
        this.onChangeRouteColor = this.onChangeRouteColor.bind(this)
    }

    componentDidMount() {
        this.renderMapWeather()        
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        redirectURL.post("/consignments/getDistinctDealerZones").then((resp) => {
            var zoneOptions = [];
            resp.data.zones.map((e) => { zoneOptions.push({ "label": e.consigner_name+" - "+e.consigner_code , "value": e.consigner_code }) })
            var dealerOptions = [];
            resp.data.dealers.map((e) => { dealerOptions.push({ "label": e.consignee_name+" - "+e.consignee_code, "value": e.consignee_code }) })
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                dealerOptions: dealerOptions,
                zoneOptions: zoneOptions
            })

        })

    };

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

    };
    // handlerStartDateTime = (event, currentDate, selectedDate) => {
    //     var d = new Date(event._d);
    //     this.setState({
    //         startDate: event
    //     });
    //     //let value = event.target.value;
    //     //this.setState({'startDate':value});
    // }
    // handlerEndDateTime = (event, currentDate, selectedDate) => {
    //     var d = new Date(event._d);

    //     var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
    //     this.setState({
    //         endDate: event
    //     });
    // }

    handlerStartDateTime = (date) => {
        const selectedDate = moment(date).format('YYYY-MM-DD');

        if (this.state.endDate && moment(selectedDate).isAfter(this.state.endDate)) {
            this.setState({
                show: true,
                basicTitle: 'Start date should be less than End date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: null,
            });
        } else {
            this.setState({
                startDate: date
            });
        }
    };

    handlerEndDateTime = (date) => {
        const selectedDate = moment(date).format('YYYY-MM-DD');

        if (this.state.startDate && moment(selectedDate).isBefore(this.state.startDate)) {
            this.setState({
                show: true,
                basicTitle: 'End date should be greater than Start date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: null,
            });
        } else {
            this.setState({
                endDate: date
            });
        }
    };

    onClickSummaryData(event) {
        try {
            event.preventDefault();
        } catch (e) { }
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        });
        var from_date = "";
        var to_date = "";
        if (this.state.startDate != "" && this.state.startDate != undefined && this.state.startDate != null) {
            from_date = moment.parseZone(new Date(this.state.startDate)).format("YYYY-MM-DD HH:mm:ss")
        }
        if (this.state.endDate != "" && this.state.endDate != undefined && this.state.endDate != null) {
            to_date = moment.parseZone(new Date(this.state.endDate)).format("YYYY-MM-DD HH:mm:ss")
        }
        var params = {
            "filter_by": this.state.filter_by.value,
            from_date: from_date,
            to_date: to_date
        };
        var flag = 0;
        if (this.state.selected_dealer == null || this.state.selected_zone == null ||
            this.state.selected_dealer == "" || this.state.selected_zone == "" ||
            Object.keys(this.state.selected_zone).length == 0 ||
            Object.keys(this.state.selected_dealer).length == 0) {
            flag = 1;
        }
        if (flag == 0) {
            params.dealer = this.state.selected_dealer.value;
            params.plant = this.state.selected_zone.value;
            redirectURL.post("/consignments/getDealerZoneWiseRoutes", params).then((resp) => {
                var rowData = [];
                var routeOptions = [];
                var rowHexaColors = this.state.rowHexaColors;
                // console.log(resp.data, "resp.data")
                resp.data.map((e, index) => {
                    e.route_no = "Route - " + (index + 1).toString();
                    routeOptions.push({ "label": e.route_no, "value": e._id })
                    // e.hexa_color = hexacolors[index]
                    rowHexaColors[e._id] = hexacolors[index]
                    rowData.push(e)
                });
                var routeToChangeColor = {}
                if (routeOptions.length) {
                    routeToChangeColor = routeOptions[0]
                }
                // console.log("rowData ",rowData)
                this.setState({
                    rowData: rowData,
                    routeOptions: routeOptions,
                    rowHexaColors: rowHexaColors,
                    routeToChangeColor: routeToChangeColor,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    showDetails: true,
                    currentQuery: params
                },()=>{
                    this.createColumns();
                    
                })
                
                try {
                    plant_falg_markers.map(e => e.setMap(null));
                    plant_flag = 0
                } catch (e) { }
                this.renderMapWeather()
                // this.createColumns()
            })
        }
        else {
            this.setState({
                basicTitle: "Please select all fields",
                basicType: "warning",
                show: true,
                loadshow: 'show-n',
                overly: 'show-n',
            })
        }

    }

    createColumns() {
        // console.log(this.state.routeToChangeColor, "routeToChangeColor")
        this.setState({
            columnDefs: [
                {
                    headerName: '',
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    width: 60
                },
                {
                    headerName: 'Routes',
                    field: "route_no",
                    colDef: "route_no",
                    width: 150,
                    cellRendererSelector: function (params) {
                        return { component: 'ColorPicker' }
                    },
                    cellRendererParams: (params) => {
                        return {
                            route_color: this.state.rowHexaColors[params.data._id],
                            route_no: params.data.route_no
                        }
                    }
                },
                {
                    headerName: 'Base Route',
                    field: "is_base_route",
                    colDef: "is_base_route",
                    width: 135,
                    cellRenderer: (params) => {
                        // console.log("oarams", params.data)
                        if (params.data.is_base_route == 1) {
                            return <button className="f12 btn btn-info">Enabled</button>;
                        }
                        else {
                            return <button className="f12 btn btn-warning">Mark as Route</button>
                        }
                    }
                },
                {
                    headerName: 'Truck No',
                    field: "truck_no",
                    colDef: "truck_no",
                    width: 120
                },
                {
                    headerName: 'Invoice Numbers',
                    field: "invoice_nos",
                    colDef: "invoice_nos",
                    width: 120
                },
                {
                    headerName: 'Consignment Code',
                    field: "consignment_codes",
                    colDef: "consignment_codes",
                    width: 120
                },
                {
                    headerName: 'Consigner Code',
                    field: "consigner_code",
                    colDef: "consigner_code",
                    width: 120
                },
                {
                    headerName: 'Transporter Code',
                    field: "transporter_code",
                    colDef: "transporter_code",
                    width: 120
                },
                {
                    headerName: 'Transporter Name',
                    field: "transporter_name",
                    colDef: "transporter_name",
                    width: 120
                },
                {
                    headerName: 'Consignee Code',
                    field: "consignee_code",
                    colDef: "consignee_code",
                    width: 120
                },
                {
                    headerName: 'Consignee Name',
                    field: "consignee_name",
                    colDef: "consignee_name",
                    width: 120
                },
                {
                    headerName: 'Invoice Time',
                    field: "invoice_time",
                    colDef: "invoice_time",
                    width: 120,
                    valueGetter: (params) => {
                        if (params.data.invoice_time != undefined || params.data.invoice_time != "" || params.data.invoice_time != null) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
                        }
                        else {
                            return ""
                        }
                    }
                },
                {
                    headerName: 'Recent Dealer Reported',
                    field: "recent_dealer_reported",
                    colDef: "recent_dealer_reported",
                    width: 120,
                    valueGetter: (params) => {
                        if (params.data.recent_dealer_reported != undefined || params.data.recent_dealer_reported != "" || params.data.recent_dealer_reported != null) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.recent_dealer_reported)
                        }
                        else {
                            return ""
                        }
                    }
                },
                {
                    headerName: 'Trip Distance',
                    field: "total_google_distance_travelled_km",
                    colDef: "total_google_distance_travelled_km",
                    width: 120,
                    valueGetter: (params) => {
                        var distance = params.data.total_google_distance_travelled_km;
                        if (distance != "" & distance != null && distance != undefined && distance != 0) {
                            return Math.round(distance / 1000)
                        }
                        else { return distance }
                    }
                },
                // {
                //     headerName: 'Vehicle Mode',
                //     field: "vehicle_mode",
                //     colDef: "vehicle_mode",
                //     width: 120
                // },
                // {
                //     headerName: 'Zone',
                //     field: "zone",
                //     colDef: "zone",
                //     width: 120
                // },

            ]
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    renderMapWeather = () => {
        loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMapWeather");
        window.initMapWeather = this.initMapWeather
    }

    initMapWeather = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n"
        })
        try {
            var map_type = window.google.maps.MapTypeId.ROADMAP;
            map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 8,
                center: new window.google.maps.LatLng(39.15052593354956, -92.36858752701599),
                mapTypeId: map_type,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },
                disableDefaultUI: true,
                fullscreenControl: false,
                zoomControl: true,
                panControl: false,

            });

        } catch (e) {
            console.log(e)
        }

    }

    onRowSelected(row) {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        if (row.node.selected) {
            var bounds = new window.google.maps.LatLngBounds();
            var line = new window.google.maps.Polyline(
                {
                    map: map,
                    strokeColor: this.state.rowHexaColors[row.data._id],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    // icons: [{
                    //     icon: {
                    //             path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    //             strokeColor:'#ff8c52',
                    //             fillColor:'#ff8c52',
                    //             fillOpacity:1,
                    //             strokeWeight: 2
                    //         },
                    //     repeat:'100px',
                    // path:[]
                    // }]
                });
            var path = line.getPath().getArray();
            var coords = []
            if(row.data.route_coords !== "" && row.data.route_coords !== undefined)
            {
                coords = JSON.parse(row.data.route_coords);
            }
            // console.log("row.data.route_coords ", coords)
            var coordarr = [] 
            var ratio = 20;
            if(coords.length > 0)
            {
                coordarr = coords.filter(function (value, index, arr) {
                    return (index % ratio == 0);
                });
            }
            coordarr.map((e, index) => {
                let latLng = new window.google.maps.LatLng(e.lat, e.lng);
                path.push(latLng);
                bounds.extend(latLng);
                line.setPath(path);
                try {
                    if (index == 0) {
                        // Starting point
                        var marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(e.lat, e.lng),
                            icon: require('../../assets/icons/track_start.png'),
                            map: map,
                        });
                        plantMarkers.push(marker)
                        var infowindow = new window.google.maps.InfoWindow();
                        window.google.maps.event.addListener(marker, 'click', (function (marker) {
                            return function () {
                                //	console.log(clusters[0])

                                //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                                var contentarr = []
                                //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                                var header = "Starting Point"
                                var contentString = infoBox(marker.icon, header, contentarr, '')

                                infowindow.setContent(contentString);
                                var currentwindow = infowindow;
                                infowindow.open(map, marker);
                            }
                        })(marker));
                    }

                    if (index == (coordarr.length - 1)) {
                        // Ending point
                        var marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(e.lat, e.lng),
                            icon: require('../../assets/icons/truck-end.png'),
                            map: map,
                        });
                        plantMarkers.push(marker)
                        var infowindow = new window.google.maps.InfoWindow();
                        window.google.maps.event.addListener(marker, 'click', (function (marker) {
                            return function () {
                                //	console.log(clusters[0])

                                //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
                                var contentarr = []
                                //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                                var header = "End Point"
                                var contentString = infoBox(marker.icon, header, contentarr, '')
                                infowindow.setContent(contentString);
                                infowindow.setContent(contentString);
                                var currentwindow = infowindow;
                                infowindow.open(map, marker);
                            }
                        })(marker))
                    }
                } catch (e) { }


            })

            map.fitBounds(bounds)
            var selectedRoutes = this.state.selectedRoutes;
            selectedRoutes[row.data._id] = line;
            this.setState({
                selectedRoutes: selectedRoutes,
                loadshow: "show-n",
                overly: "show-n"
            })
            if (plant_flag == 0) {
                try {
                    var consigner_coords = JSON.parse(row.data.consigner_coordinates)
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(eval(consigner_coords).lat, eval(consigner_coords).lng),
                        icon: require('../../assets/icons/business.png'),
                        map: map,
                    });
                    plant_falg_markers.push(marker)
                    var infowindow = new window.google.maps.InfoWindow();
                    window.google.maps.event.addListener(marker, 'click', (function (marker) {
                        return function () {
                            //	console.log(clusters[0])

                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Consigner"
                            var contentString = infoBox(marker.icon, header, contentarr, '')

                            infowindow.setContent(contentString);
                            var currentwindow = infowindow;
                            infowindow.open(map, marker);
                        }
                    })(marker));
                    var consignee_coords = JSON.parse(row.data.consignee_coordinates)
                    console.log("consigner", consignee_coords)

                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(eval(consignee_coords).lat, eval(consignee_coords).lng),
                        icon: require('../../assets/icons/customer_pin.png'),
                        map: map,
                    });
                    plant_falg_markers.push(marker)
                    var infowindow = new window.google.maps.InfoWindow();
                    window.google.maps.event.addListener(marker, 'click', (function (marker) {
                        return function () {
                            //	console.log(clusters[0])

                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Consignee"
                            var contentString = infoBox(marker.icon, header, contentarr, '')

                            infowindow.setContent(contentString);
                            var currentwindow = infowindow;
                            infowindow.open(map, marker);
                        }
                    })(marker));
                    plant_flag = 1
                } catch (e) { console.log(e, "plant error") }
            }

        }
        else {
            try {
                this.state.selectedRoutes[row.data._id].setMap(null)
                var selectedRoutes = this.state.selectedRoutes;
                delete selectedRoutes[row.data._id];
                this.setState({
                    selectedRoutes: selectedRoutes,
                    loadshow: "show-n",
                    overly: "show-n"
                })
                plantMarkers.map(e => e.setMap(null))
            } catch (e) { }

        }
    }

    onChangeRouteColor(e, row) {
        var rowHexaColors = this.state.rowHexaColors;
        if (Object.keys(this.state.routeToChangeColor).length) {
            rowHexaColors[row._id] = e.target.value;
            this.setState({ "rowHexaColors": rowHexaColors })
            this.gridApi.refreshCells({ columns: ['Routes', "route_no"], force: true });
            try {
                this.state.selectedRoutes[row._id].setOptions({ "strokeColor": e.target.value })
            } catch (e) { }
        }
    }

    onCellClicked(cell) {
        // console.log("cell ", cell)
        if (cell.colDef.field == "is_base_route") {
            if(cell.data.is_base_route == 1)
            {

            }
            else{
                var params = {
                    "query": this.state.currentQuery,
                    "row_id": cell.data._id
                }
                redirectURL.post("/consignments/markBaseRouteForMultiTrips", params).then((resp) => {
                    this.onClickSummaryData()
                    this.setState({
                        basicTitle: "Success",
                        basicType: "success",
                        show: true
                    })
                })
            }
        }

    }

    render() {

        const dStyles = {
            width: '65%',
            height: '30vh',
            // marginTop : "20px"
        }
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Multi Trip Routes</span>
                        <Favourites />
                    </h5>
                </div>
                <div className="card">
                    <div className="row pt-10px col-xl-12 col-lg-12">
                        {/* <div className="col-xl-2 col-lg-2 form-group">
                            <label>Filter By </label>
                            <Select
                                value={this.state.filter_by}
                                closeMenuOnSelect={true}
                                className={"border-radius-0"}
                                style={{ borderRadius: "0px" }}
                                options={[{ "label": "Invoice", "value": 1 },
                                { "label": "POD", "value": 2 }]}
                                onChange={(e) => { this.setState({ "filter_by": e }) }}
                            />
                        </div> */}
                        <div className="col-xl-3 col-lg-3 form-group">
                            <label>From Date</label>

                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handlerStartDateTime.bind(this)}
                                name="startDate"
                                className="form-control datepicker-custom"
                                autoComplete='off'
                            />
                        </div>

                        <div className="col-xl-3 col-lg-3 form-group">
                            <label>To Date</label>

                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handlerEndDateTime.bind(this)}
                                name="endDate"
                                className="form-control datepicker-custom"
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 form-group">
                            <label>Select Plant <span style={{ color: "red" }}>*</span> </label>
                            <Select
                                value={this.state.selected_zone}
                                closeMenuOnSelect={true}
                                className={"border-radius-0"}
                                style={{ borderRadius: "0px" }}
                                options={this.state.zoneOptions}
                                onChange={(e) => { this.setState({ "selected_zone": e }) }}
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 form-group">
                            <label>Select Destination <span style={{ color: "red" }}>*</span> </label>
                            <Select
                                value={this.state.selected_dealer}
                                closeMenuOnSelect={true}
                                className={"border-radius-0"}
                                style={{ borderRadius: "0px" }}
                                options={this.state.dealerOptions}
                                onChange={(e) => { this.setState({ "selected_dealer": e }) }}
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label></label><br />
                            <button type="button" className="btn btn-success mt-8p" onClick={this.onClickSummaryData.bind(this)}>Submit</button>
                        </div>
                    </div>

                </div>
                {/* {(this.state.showDetails) ? */}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div id="myGrid" style={{ height: '250px', width: '100%', padding: "0px" }} className="ag-theme-balham card-body">
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            sideBar={false}
                                            groupUseEntireRow={false}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}
                                            onRowSelected={this.onRowSelected.bind(this)}
                                            rowSelection="multiple"
                                            suppressRowClickSelection={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            frameworkComponents={this.state.frameworkComponents}
                                            onCellClicked={this.onCellClicked.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row" style={{paddingTop:"2em"}}>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 row">
                                <div className="col-xl-5 col-lg-5 form-group" style={{justifyContent: "Center"}}>
                                    <label style={{float: "left", width: "20%", paddingTop: "2%"}}>Select Route : </label>
                                    <Select
                                        value={this.state.routeToChangeColor}
                                        closeMenuOnSelect={true}
                                        className={"border-radius-0 col-xl-12 col-lg-12"}
                                        style={{borderRadius:"0px"}}
                                        options={this.state.routeOptions}
                                        onChange={(e)=>{
                                            this.setState({"routeToChangeColor": e})
                                        }}
                                    />    
                                </div>
                                <div className="col-xl-6 col-lg-6 form-group">
                                    <label>Change color : </label>&nbsp;&nbsp;
                                    <input type="color" onChange={(e)=>{
                                            var rowHexaColors = this.state.rowHexaColors;
                                            if(Object.keys(this.state.routeToChangeColor).length)
                                            {
                                                rowHexaColors[this.state.routeToChangeColor.value] = e.target.value;
                                                this.setState({"rowHexaColors": rowHexaColors})
                                                try{
                                                    this.state.selectedRoutes[this.state.routeToChangeColor.value].setOptions({"strokeColor": e.target.value})
                                                }catch(e){}
                                            }
                                        }} value={(Object.keys(this.state.routeToChangeColor).length)?
                                            this.state.rowHexaColors[this.state.routeToChangeColor.value]:"#000000"}/>
                                    
                                </div>
                                
                            </div>
                        </div> */}
                            <div className="row" style={{ paddingTop: "2em" }}>
                                <div id="map" className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={dStyles}></div>
                            </div>
                        </div>


                    </div>
                    {/*
                    : ""}
                    */}


                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)}></div>

            </div>
        );
    }
}

// function loadScriptWeather(url) {
//     // console.log(window.screen.availHeight,
//         // window.screen.availWidth)
//     if (window.screen.availHeight <= 1024) {
//         $("#mapweather").removeClass("col-md-8");
//         $("#weathermapgrid").removeClass("col-md-4");
//         $("#mapweather").addClass("col-md-8");
//         $("#weathermapgrid").addClass("col-md-8");
//     }
//     var index = window.document.getElementsByTagName("script")[1]
//     var script = window.document.createElement("script")
//     script.src = url
//     script.async = true
//     script.defer = true
//     index.parentNode.insertBefore(script, index)
// }

function loadScriptWeather(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
