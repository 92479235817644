// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numberCircle {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    padding: 10px;
    background: #fff;
    /* border: 2px solid #060505; */
    text-align: center;
    font: 32px Arial, sans-serif;
}

/* .rfq-response-box{
    min-height: 50vh;
} */

.td {
    padding-left: 20px;
    border: 1px solid black;
  }
  
  .td-2 {
    border: 1px solid black;
    padding: 0.2rem !important;
    text-align: center;
    /* font-size: 0.8rem; */
  }
  
  .td-2 input {
    width: 100px;
  }
  
  .activeStep2Table {
    width: 100% !important;
    font-size: 1rem;
  }
  
  .td-3 {
    border: 1px solid black;
    padding: 0.2rem2;
  }

  .bottomTable{
    width: 100%;
  }
  
  .bottomTable td {
    border: 2px solid white;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    /* background-color: #cbc7c7; */
    /* text-align: center; */
  }
  
  .bottomTable td input {
    width: 100%;
  }
  
  .modal-Heading {
    border-right: 1px solid white;
  }
  
  .slider-translate-70p {
    width: 70%;
    transform: translateX(0%);
  }
  
  #previewTable tr td{
      border: 2px solid black;
      color: black;
      /* background-color: rgb(148, 146, 146); */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/procurement/rfqComparitiveAnalysis.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,+BAA+B;IAC/B,kBAAkB;IAClB,4BAA4B;AAChC;;AAEA;;GAEG;;AAEH;IACI,kBAAkB;IAClB,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;IACvB,0BAA0B;IAC1B,kBAAkB;IAClB,uBAAuB;EACzB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,uBAAuB;IACvB,oBAAoB;IACpB,qBAAqB;IACrB,+BAA+B;IAC/B,wBAAwB;EAC1B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,UAAU;IACV,yBAAyB;EAC3B;;EAEA;MACI,uBAAuB;MACvB,YAAY;MACZ,0CAA0C;EAC9C","sourcesContent":[".numberCircle {\n    border-radius: 50%;\n    width: 55px;\n    height: 55px;\n    padding: 10px;\n    background: #fff;\n    /* border: 2px solid #060505; */\n    text-align: center;\n    font: 32px Arial, sans-serif;\n}\n\n/* .rfq-response-box{\n    min-height: 50vh;\n} */\n\n.td {\n    padding-left: 20px;\n    border: 1px solid black;\n  }\n  \n  .td-2 {\n    border: 1px solid black;\n    padding: 0.2rem !important;\n    text-align: center;\n    /* font-size: 0.8rem; */\n  }\n  \n  .td-2 input {\n    width: 100px;\n  }\n  \n  .activeStep2Table {\n    width: 100% !important;\n    font-size: 1rem;\n  }\n  \n  .td-3 {\n    border: 1px solid black;\n    padding: 0.2rem2;\n  }\n\n  .bottomTable{\n    width: 100%;\n  }\n  \n  .bottomTable td {\n    border: 2px solid white;\n    padding-left: 0.9rem;\n    padding-right: 0.9rem;\n    /* background-color: #cbc7c7; */\n    /* text-align: center; */\n  }\n  \n  .bottomTable td input {\n    width: 100%;\n  }\n  \n  .modal-Heading {\n    border-right: 1px solid white;\n  }\n  \n  .slider-translate-70p {\n    width: 70%;\n    transform: translateX(0%);\n  }\n  \n  #previewTable tr td{\n      border: 2px solid black;\n      color: black;\n      /* background-color: rgb(148, 146, 146); */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
