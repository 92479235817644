
import React, { Component } from 'react';
 
const UpdateItem = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickUpdateItem(props);
    };
// console.log("props ", props.data)
    return (
        <div>
            <button type="button"
              className={"f14 btn btn-info"} 
              title="Update" 
              onClick={handleClick}
              style={{textAlign:"center"}}>
                   Update
            </button>
          
        </div>
    );
};

export default UpdateItem;
