import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import ViewPOD from "./viewpodbutton";
import ViewRequest from "./viewrequestbutton";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var moment = require('moment');

export default class BillingDeviationFlow extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
				sortable:true
			},
            frameworkComponents:{
                ViewPOD:ViewPOD,
                ViewRequest:ViewRequest
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
            rowSelection:"multiple",
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            liable:"btn-active-y",
            pendinglsp:"btn-default",
            pendingcust:"btn-default",
            submitfin:"btn-default",
            paystat:"btn-default",
            liableshow:"show-m",
            pendinglspshow:"show-n",
            pendingcustshow:"show-n",
            submitfinshow:"show-n",
            paystatshow:"show-n",
            actiontype:"liable",
            overlay:"show-n",            
            sliderFormula:"",
            remarks:"",
            rowId:"",
            vtransportersh:"show-m",
            vcustomersh:"show-n",
            requestFormula:"",
            transporter:"btn-info",
            customer:"btn-default",
            rownode:[],
            showButtons:'show-n'
        }
        this.loadTrips = this.loadTrips.bind(this);
        this.onClickShowFormual =this.onClickShowFormual.bind(this);
        this.onClickViewRequest = this.onClickViewRequest.bind(this);
        this.onClickSubmitFormula = this.onClickSubmitFormula.bind(this);
    }
	componentDidMount(){
        redirectURL.post("/billing/configurations")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {
                if(records[0].trips_data_from == "api")
                {                    
                    this.setState({
                        isuploadcsv:0
                    })
                    this.loadTrips();
                }
                else{
                    this.setState({
                        isuploadcsv:1
                    })
                    this.loadTrips();
                }
            }
            else{
                redirectToConfigurations()
            }
		})
	}
    loadTrips(){
        redirectURL.post("/billing/trips")
		.then((response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            var recordsarr = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    item.penality_cost = 1000;
                    // item.no_of_days = hourDifference;
                    if(item.detention_cost !== "" && item.detention_cost !== undefined)
                    {
                    }
                    else{
                        item.detention_cost = 0;
                    }
                    
                    recordsarr.push(item)
                })
            }
            this.setState({
                rowData:recordsarr
            })
            
		})
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    showTabView(oVal){
        if(oVal === 1)
        {
            this.gridApi.deselectAll();
            this.setState({
                transporter:"btn-info",
                customer:"btn-default",
                vtransportersh:"show-m",
                vcustomersh:"show-n"
            })
        }
        if(oVal === 2)
        {
            this.gridApi.deselectAll();
            this.setState({
                transporter:"btn-default",
                customer:"btn-info",
                vtransportersh:"show-n",
                vcustomersh:"show-m"
            })
        }
    }
    
    hideSlideBlock(){
        this.setState({
            slider:"",
            requestFormula:"",
            sliderFormula:"",
            overlay:"show-n"
        })
    }
    onClickSubmitFormula(event){
       event.preventDefault();
    //    console.log("remarks",this.state.remarks)
    //    console.log("rownode",this.state.rownode)

        if(this.state.remarks != "" && this.state.remarks != undefined)
       {
         var params ={
             remarks:this.state.remarks,
             rowId:this.state.rownode[0]._id
        }
        redirectURL.post("/billing/submitrequestwaiver",params)
         .then((response) => {
             if(response.data.status == "success")
                {
                 window.location.reload();
                } 
            })
        }
     else{
         this.setState({
             show:true,
             basicTitle:"Please write comment",
             basicType:"danger"
           })
        }

    }
    onClickViewRequest(props){
        // console.log("props ", props.data)
        var rowId = props.data._id;
        // console.log("rowId",rowId)
         this.setState({
            rowId:rowId,
            rownode:[props.data],
            requestFormula:"slide30",
            overlay:"show-m"
        })
       
    }
    onClickSendBack(){

    }
    onClickApprove(){
        var rownodes = this.gridApi.getSelectedRows();
        // console.log("rownodes ", rownodes)
        var params = {
            rownodes : JSON.stringify(rownodes)
        }
        if(this.state.transporter === "btn-active")
        {
            
            redirectURL.post("/billing/updatetransporterstatus",params)
            .then((response) => {
                if(response.data.status === "success")
                {
                    this.loadTrips();
                }
            })
        }
        if(this.state.customer === "btn-active")
        {
            redirectURL.post("/billing/updatecustomerstatus",params)
            .then((response) => {
                if(response.data.status === "success")
                {
                    this.loadTrips();
                }
            })
        }

    }
    onClickReject(){
        
    }
    onClickShowFormual(){
        this.setState({
            sliderFormula:"slide60",
            overlay:"show-m"
        })
    }
    changeHandler = (event) =>{
        // console.log("event",event);
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name] :value
        })
    }
    
    appendVal = async (oVal) =>{
        // var dd = document.getElementById("formulas").innerHTML;
         var equaton = this.state.equaton;
        //  console.log("equaton ", equaton)
         var currentTrack  = "";
         var previousTrack = "";
         for (let i = 0; i < equaton.length; i++) {
             currentTrack = equaton[i];
             previousTrack = equaton[i];
             // if (i === 0) {
             // //   console.log('previousTrack of ' + currentTrack.key + ' is:', undefined);
             // } else {
             //   previousTrack = equaton[i - 1];
             // }
         }
         if( previousTrack != oVal)
         {
             
         }
        //  console.log("previousTrack ", previousTrack)
        await equaton.push({
             field:oVal
         })
         setTimeout(() => {
             this.setState({
                 equaton:equaton
             })  
             this.appendForm();
         },500)
        
     }
     appendForm(){
         var equaton = this.state.equaton;
         // console.log("equaton ", equaton)
         var html='';
         var listitesm = '';
         // var ul = document.getElementById("formulasList");
         if(equaton.length > 0)
         {
             equaton.map((item) => {
                 html += item.field;
                 // var li = document.createElement("li");
                 // var b = '<a href="javascript:;" >Delete</a>'
                 // li.appendChild(document.createTextNode(item.field));
                 // ul.appendChild(li);
                 // listitesm += '<li>'+item.field+'</li>';
             })
         }
         document.getElementById("formulas").value = html
         this.setState({
             formulas:html
         })
         // document.getElementById("formulasList").appendChild = listitesm
     }
     onSaveFormula = async () =>{
        var inps = $(".inpfrom");
        var arr = [];
        inps.each((function(){
            var dataID = $(this).attr("data-id");
            var fromc = $("#inp_from_"+dataID).val();
            var toc = $("#inp_to_"+dataID).val();
            arr.push({
                fromcost:fromc,
                tocost:toc
            })
        }));
        // console.log("arr ", arr)
        // var equaton = this.state.equaton;
        // var arr = [];
        // if(equaton.length > 0)
        // {
        //  equaton.map((itm) => {
        //      arr.push(itm.field);
        //  })
        // }
        //  var aprams = {
        //      equation : JSON.stringify(arr)
        //  }
        //  redirectURL.post("/billing/saveDeviationEquation",aprams)
        //  .then((response) => {
        //      if(response.data.status === "success")
        //      {
        //          this.setState({
        //              sliderFormula:"",
        //              overlay:"show-n"
        //          });
        //          // this.loadFreightRates();
        //          window.location.reload();
        //      }
             
        //  })
         
    }
    onSelectedRowShowButtons(event){
        // console.log("event ", event)
        var isSelected = event.node.selected;
        if(isSelected === true)
        {
            this.setState({
                showButtons:'show-m'
            })
        }
        else{
            this.setState({
                showButtons:'show-n'
            })
        }
    }
    onCellEdit(rownode){
        // console.log("rownode ", rownode);
        if(rownode.colDef.field === "customer_penality_waiver")
        {
            if(rownode.value !== undefined)
            {
                var wcost = rownode.value;
            }
            else{
                var wcost = "";
            }
            var params = {
                rowid:rownode.data._id,
                waiver_cost:wcost
            }
            redirectURL.post("/billing/updatecustomerwaivercost",params)
            .then((response) => {
                if(response.data.status === "success")
                {
                    this.loadTrips();
                }
            })
        }
    }
    render(){
        if(this.state.customer === 'btn-active')
        {
            var tabFor = "customer";
        }
        else{
            var tabFor = "transporter";
        }
          
        let isCustomerTab = this.state.customer === 'btn-active'
		if(isCustomerTab){
        var columnDefs = [
            {
                headerName:"",
                field:"",
                pinned:"left",
                width:30,
                checkboxSelection:true
                // checkboxSelection:function(params){
                //     try {
                //         if(params.data.is_approve_status === 1 || params.data.is_approve_status === 0 || params.data.is_approve_status === undefined || params.data.is_approve_status === "")
                //         {
                //             return true;
                //         }
                //         else{
                //             return false;
                //         }
                //     } catch (error) {
                        
                //     }
                // }
            },
           
            {
                headerName:Constants.FREIGHT_APPROVALS_STATUS,
                field:"is_approve_status",
                width:180,
                cellStyle: {'white-space': 'normal'},
                autoHeight: true,
                valueGetter:function(params){
                    try {
                        if(params.data.is_approve_status !== "" && params.data.is_approve_status !== undefined)
                        {
                            if(tabFor === "transporter")
                            {
                                if(params.data.is_approve_status === 1)
                                {
                                    return "Send for Approval";
                                }
                                else if(params.data.is_approve_status === 2)
                                {
                                    return "Approved";
                                }
                                else if(params.data.is_approve_status === 3)
                                {
                                    return "Rejected";
                                }
                                else{
                                    return "Pending";
                                }
                            }
                            else{
                                if(params.data.is_approve_status === 1)
                                {
                                    return "Requested for Approval";
                                }
                                else if(params.data.is_approve_status === 2)
                                {
                                    return "Approved";
                                }
                                else if(params.data.is_approve_status === 3)
                                {
                                    return "Rejected";
                                }
                                else{
                                    return "Pending";
                                }
                            }
                            
                        }
                        else{
                            return "Pending";
                        }
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName:Constants.TRIP_TRUCK_NO,
                field:"truck_no",
                width:120,
            },
            
            {
                headerName:Constants.TRIP_CONSIGN_NO,
                field:"consignment_code",
                width:160,
            },
            {
                headerName:Constants.TRIP_INV_DATE,
                field:"invoice_date",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.invoice_date != undefined && params.data.invoice_date != "")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                        }

                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_REACHED_DEST_DATE,
                field:"recent_dealer_reported",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                        }
                        
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_DELIVERED_DATE,
                field:"delivered_date",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.delivered_date != undefined && params.data.delivered_date != "")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.delivered_date);
                        }
                        
                    }
                    catch(e){}
                }
                
            },
        
            {
                headerName:Constants.TRIP_NO_OF_DAYS,
                field:"no_of_days",
                width:100,
                valueGetter:function(params){
                    
                    var hourDifference = 0;
                    if (params.data.recent_dealer_reported && params.data.delivered_date){
                    var reacheddDateOnlyMoment = moment(params.data.recent_dealer_reported, 'YYYY-MM-DD'); 
                    var deliveredDateOnlyMoment = moment(params.data.delivered_date,'YYYY-MM-DD');
                    return deliveredDateOnlyMoment.diff(reacheddDateOnlyMoment, 'days'); 
                    //    console.log("diff= ", hourDifference);
                    } 
                }
        
            },
            {
                headerName:Constants.TRIP_PLANT_NAME,
                field:"consigner_name",
                width:100,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_CODE,
                field:"consignee_code",
                width:100,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_NAME,
                field:"consignee_name",
                width:180,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_CITY,
                field:"consignee_city",
                width:180,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_STATE,
                field:"consignee_state",
                width:180,
            },
            {
                headerName:Constants.TRIP_TRANSPORTER_CODE,
                field:"transporter_code",
                width:180,
            },
            {
                headerName:Constants.TRIP_TRANSPORTER_NAME,
                field:"transporter_name",
                width:180,
            },
            {
                headerName:Constants.TRIP_TOTAL_DISTANCE_KM,
                field:"total_distance_travelled_km",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "")
                        {
                            return parseInt(parseInt(params.data.total_distance_travelled_km)/1000);
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_TOTAL_TRIP_DISTANCE_KM,
                field:"total_travelled_trip_dist_km",
                width:100,
            },
            {
                headerName:Constants.TRIP_DURATION_DAYS,
                field:"trip_duration_days",
                width:100,
            },
            {
                headerName:Constants.DEVIATION_FLOW_PENALITY_COST,
                field:"penality_cost",
                width:100,
            },
            {
                headerName:Constants.DEVIATION_FLOW_PENALITY_WAIVER_REMARKS,
                field:"transporter_waiver_remarks",
                width:200,
                cellStyle: {'white-space': 'normal'},
                autoHeight: true,
            },
          
            {
                headerName:Constants.DEVIATION_FLOW_WAIVER_COST,
                field:"customer_penality_waiver",
                width:100,
                editable:function(params){
                    try {
                        if(params.data.is_transporter_waiver_request !== "" && params.data.is_transporter_waiver_request !== undefined)
                        {
                            if(params.data.is_transporter_waiver_request === 1)
                            {
                                return true;
                            }
                            else{                                
                                return false;
                            }
                        }
                        else{
                            return false;
                        }
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName:"POD Doc",
                field:"invoice_no",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewPOD"
                    }
                },
            }
        ]
   
    } else {
		var columnDefs = [
            {
                headerName:"",
                field:"transporter",
                width:50,
                pinned:"left",
                checkboxSelection:true
            },
            
            {
                headerName:Constants.FREIGHT_APPROVALS_STATUS,
                field:"is_approve_status",
                width:180,
                valueGetter:function(params){
                    try {
                        if(params.data.is_approve_status !== "" && params.data.is_approve_status !== undefined)
                        {
                            if(tabFor === "transporter")
                            {
                                if(params.data.is_approve_status === 1)
                                {
                                    return "Send for Approval";
                                }
                                else if(params.data.is_approve_status === 2)
                                {
                                    return "Approved";
                                }
                                else if(params.data.is_approve_status === 3)
                                {
                                    return "Rejected";
                                }
                                else{
                                    return "Pending";
                                }
                            }
                            else{
                                if(params.data.is_approve_status === 1)
                                {
                                    return "Requested for Approval";
                                }
                                else if(params.data.is_approve_status === 2)
                                {
                                    return "Approved";
                                }
                                else if(params.data.is_approve_status === 3)
                                {
                                    return "Rejected";
                                }
                                else{
                                    return "Pending";
                                }
                            }
                        }
                        else{
                            return "Pending";
                        }
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName:Constants.TRIP_TRUCK_NO,
                field:"truck_no",
                width:120,
            },
            {
                headerName:Constants.TRIP_CONSIGN_NO,
                field:"consignment_code",
                width:160,
            },
            {
                headerName:Constants.TRIP_INV_DATE,
                field:"invoice_date",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.invoice_date != undefined && params.data.invoice_date != "")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                        }

                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_REACHED_DEST_DATE,
                field:"recent_dealer_reported",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                        }
                        
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_DELIVERED_DATE,
                field:"delivered_date",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.delivered_date != undefined && params.data.delivered_date != "")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.delivered_date);
                        }
                        
                    }
                    catch(e){}
                }
               
            },
        
            {
                headerName:Constants.TRIP_NO_OF_DAYS,
                field:"no_of_days",
                width:100,
                valueGetter:function(params){
                    
                            var hourDifference = 0;
                            if (params.data.recent_dealer_reported && params.data.delivered_date){
                            var reacheddDateOnlyMoment = moment(params.data.recent_dealer_reported, 'YYYY-MM-DD'); 
                            var deliveredDateOnlyMoment = moment(params.data.delivered_date,'YYYY-MM-DD');
                            return deliveredDateOnlyMoment.diff(reacheddDateOnlyMoment, 'days'); 
                            //    console.log("diff= ", hourDifference);
                            }
                
                }                       
       
            },
            {
                headerName:Constants.TRIP_PLANT_NAME,
                field:"consigner_name",
                width:100,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_CODE,
                field:"consignee_code",
                width:100,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_NAME,
                field:"consignee_name",
                width:180,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_CITY,
                field:"consignee_city",
                width:180,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_STATE,
                field:"consignee_state",
                width:180,
            },
            {
                headerName:Constants.TRIP_TRANSPORTER_CODE,
                field:"transporter_code",
                width:180,
            },
            {
                headerName:Constants.TRIP_TRANSPORTER_NAME,
                field:"transporter_name",
                width:180,
            },
            {
                headerName:Constants.TRIP_TOTAL_DISTANCE_KM,
                field:"total_distance_travelled_km",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "")
                        {
                            return parseInt(parseInt(params.data.total_distance_travelled_km)/1000);
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_TOTAL_TRIP_DISTANCE_KM,
                field:"total_travelled_trip_dist_km",
                width:100,
            },
            {
                headerName:Constants.TRIP_DURATION_DAYS,
                field:"trip_duration_days",
                width:100,
            },
            {
                headerName:Constants.DEVIATION_FLOW_PENALITY_COST,
                field:"penality_cost",
                width:100,
            },
            {
                headerName:"",
                field:"_id",
                width:180,
                cellStyle: {'white-space': 'normal'},
                autoHeight: true,
                cellRendererSelector:function(params){
                return {
                   component:"ViewRequest"
                }
              }
            },
            
            {
                headerName:Constants.DEVIATION_FLOW_PENALITY_WAIVER_REMARKS,
                field:"transporter_waiver_remarks",
                width:200,
                cellStyle: {'white-space': 'normal'},
                autoHeight: true,
            },
            {
                headerName:Constants.DEVIATION_FLOW_DETNETION_COST,
                field:"customer_penality_waiver",
                width:100,
                editable:false
            },
            {
                headerName:"POD Doc",
                field:"invoice_no",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewPOD"
                    }
                },
            }
        ]
    }  
        return (
            <div className="container-fluid">
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="fa fa-truck cus-i"></i>&nbsp;<span>Deviation Work Flow</span>
                    </h5>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 ">
                        <button onClick={this.showTabView.bind(this,1)} type="button" className={"f13 btn "+(this.state.transporter)}>TRANSPORTER</button>
                        <button onClick={this.showTabView.bind(this,2)} type="button" className={"f13 btn "+(this.state.customer)}>CUSTOMER</button>
                     </div>   
                    <div className="col-xl-12 col-md-12 ">
                        <div className={" "+(this.state.vtransportersh1)}>
                            <div className={"row"}>
                                <div className="col-xl-12 col-md-12 mb-4">
                                    <div className="card shadow h-100 py-2">
                                        <div className="card-body">
                                            <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                                <AgGridReact 
                                                    rowData={this.state.rowData} 
                                                    columnDefs={columnDefs}
                                                    defaultColDef ={this.state.defaultColDef }
                                                    enableRangeSelection={true}
                                                    enableCharts={true}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    masterDetail={true}
                                                    onGridReady={this.onGridReady}
                                                    sideBar={this.state.sideBar}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    statusBar={this.state.statusBar}
                                                    stopEditingWhenGridLosesFocus= {true}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    onRowSelected={this.onSelectedRowShowButtons.bind(this)}
                                                    onCellEditingStopped={this.onCellEdit.bind(this)}
                                                    rowSelection={this.state.rowSelection}
                                                    
                                                />
                                            </div> 
                                            <div className={"col-md-12 "+(this.state.showButtons)} style={{textAlign:"right"}}>
                                                
                                                <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>
                                                {/* <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button> */}
                                            </div> 
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>    
                {/* <div className={" "+(this.state.vcustomersh)}>
                    <div className={"row"}>
                        <div className="col-xl-12 col-md-12 mb-4">
                            <div className="card shadow h-100 py-2">
                                <div className="card-body">
                                    <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                        <AgGridReact 
                                            rowData={this.state.rowData} 
                                            columnDefs={columnDefs}
                                            defaultColDef ={this.state.defaultColDef }
                                            enableRangeSelection={true}
                                            enableCharts={true}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            masterDetail={true}
                                            onGridReady={this.onGridReady}
                                            sideBar={this.state.sideBar}
                                            frameworkComponents={this.state.frameworkComponents}
                                            statusBar={this.state.statusBar}
                                            stopEditingWhenGridLosesFocus= {true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            
                                        />
                                    </div> 
                                        
                                    <div className="col-md-12" style={{textAlign:"right"}}>
                                        <button type="button" className="btn btn-primary btncm" onClick={this.onClickSendBack.bind(this)}>Send Back</button>
                                        <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>
                                        <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                

               
                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
                
                <div className={"sliderBlock2 "+(this.state.requestFormula)}>
                    <h2 className="crd-bg p-10p">Remarks</h2>
                    <div className="form-group col-sm-12 mb-20p mt-20p" style={{fontSize:"16px"}}>
                                       Add Any Remarks:
                                    </div>
                                    <div className="form-group col-sm-12 mb-20pt mt-20p brkwrod" style={{fontSize:"25px"}} >
                                       <textarea style={{height:"100px"}} className="form-control"  name="remarks" id="remarks" value={this.state.remarks} onChange={this.changeHandler} rows="8"></textarea>
                                    </div>
                                    {/* <div className="form-group col-sm-4 mb-20pt mt-20p" style={{fontSize:"25px"}}>
                                       <ul id="formulasList" >

                                       </ul>
                                    </div> */}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="button" className="btn btn-success" onClick={this.onClickSubmitFormula.bind(this)}>SUBMIT</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                </div> 
                 

                 {/* <div className={"row"}>
                     <div className="col-xl-12 col-md-12 mb-4">
                         <div className="card shadow h-100 py-2">
                            <div className="card-body">
                                 <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                  <AgGridReact 
                                        rowData={this.state.rowData} 
                                        columnDefs={columnDefs}
                                        defaultColDef ={this.state.defaultColDef }
                                        enableRangeSelection={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        
                                    />
                                    </div> 
                                <div className="col-md-12" style={{textAlign:"right"}}>
                                    <button type="button" className="btn btn-primary btncm" onClick={this.onClickSendBack.bind(this)}>Send Back</button>
                                    <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>
                                    <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button>
                                </div> 
                            </div>
                        </div>
                    </div>
				</div> */}
        
            </div>
			
        );
    }
  }

function redirectToConfigurations()
{
    window.location.href="/configurations"
}