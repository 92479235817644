import React, { useEffect } from 'react'
import moment from "moment";
import { countries } from "country-flags-svg";
import { Tooltip } from "react-tooltip";

const EventTab = (props) => {

  console.log(props, "EventsTab");
  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return {
        img: srcFlag[0]?.flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };
  if (!props.eventData.last_event_data || props.eventData.last_event_data.length === 0 || props.eventData.last_event_data === undefined) {
    return (
      <></>
    )
  }
  const dataArray = []
  let temp2 = props.eventData.last_event_data.filter(e => e.event_name != "unknown")
  temp2.sort((a, b) => {
    if (a.actual_time !== "" && b.actual_time !== "") {
      return moment(a.actual_time, 'YYYY-MM-DD hh:mm:ss A').diff(moment(b.actual_time, 'YYYY-MM-DD hh:mm:ss A'))
    }
    else if (a.actual_time !== "") {
      return -1;
    }
    else if (b.actual_time !== "") {
      return 1;
    }
    else {
      return 0;
    }
  }
  );
  // console.log(temp2, "tttttttt");

  const temp = [...temp2].reverse()
  if (props.eventData.discharge_port == props.eventData.last_port) {

  }
  temp.forEach((each, i) => {
    let status = ""
    if (each.actual_time == '')
      status = 'active'
    else
      status = 'completed'
    if (each.location == props.eventData.first_port) {
      dataArray.push({ port: props.eventData.first_port, portType: "Origin Port", status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
    }
    else if (each.location == props.eventData.loading_port) {
      dataArray.push({ port: props.eventData.loading_port, portType: "Ocean Port", status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
    }
    else if (each.location == props.eventData.discharge_port) {
      dataArray.push({ port: props.eventData.discharge_port, portType: ((props.eventData.discharge_port == props.eventData.last_port) ? "Destination Port" : "Discharge Port"), status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
    }
    else if (each.location == props.eventData.last_port) {
      dataArray.push({ port: props.eventData.last_port, portType: "Destination Port", status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
    }
    else {
      if (i == (temp.length - 1)) {
        dataArray.push({ port: props.eventData.first_port, portType: "Origin Port", status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
      }
      else if (i == 0) {
        dataArray.push({ port: props.eventData.last_port, portType: "Destination Port", status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
      }
      else {
        if (props.eventData.loading_port_code == each.location_code)
          dataArray.push({ port: props.eventData.first_port, portType: "Origin Port", status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
        else if (props.eventData.discharge_port_code == each.location_code)
          dataArray.push({ port: props.eventData.last_port, portType: "Destination Port", status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
        else
          dataArray.push({ port: each.location, portType: "Transshipment Port", status: status, eventName: each.event_name, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, _id: each._id })
      }
    }
  })
  const groupedData = dataArray.reduce((groups, item) => {
    const group = item.port;
    groups[group] = groups[group] || [];
    groups[group].push(item);
    return groups;
  }, {});
  const result = Object.entries(groupedData).map((each) => ({
    group: each[0],
    data: each[1]
  }));
  const detailsMap = new Map()
  const test = sortData([...props.eventData.last_event_data])
  test.map((value, j) => {
    let previousDate = ""
    try {
      if (j == 0) {
        previousDate = "first"
      }
      else if (j > 0) {
        previousDate = test[j - 1].actual_time
      }
      const delay = (moment(value.actual_time)).diff(moment(previousDate));
      const duration = moment.duration(delay)
      const days = duration.days();
      const hours2 = duration.hours();
      let details = ""
      if (previousDate === "first") {
        details = moment(value.actual_time, "YYYY-MM-DD hh:mm:ss A").format("Do MMM, YYYY")
      }
      if (days < 0) {
        if (Math.abs(days) === 1) {
          details = Math.abs(days) + " day"
        }
        else {
          details = Math.abs(days) + " days"
        }
        if (hours2 !== 0)
          details = details + " " + Math.abs(hours2) + " hour(s)"
      }
      else if (days > 0) {
        if (Math.abs(days) === 1) {
          details = Math.abs(days) + " day"
        }
        else {
          details += Math.abs(days) + " days"
        }
        if (hours2 !== 0)
          details = details + " " + Math.abs(hours2) + " hour(s)"
      }
      else if (days === 0) {
        details = hours2 + " hour (s) " + duration.minutes() + " min (s)"
      }
      detailsMap.set(value._id, details)
    } catch (err) {

    }
  })
  function getValue(inputString) {
    if (inputString === "")
      return ""
    if (inputString.length < 30)
      return inputString
    var words = inputString.slice(0, 35) + "...";

    return words;
  }
  return (
    <div className="card pl-4" style={{ borderRadius: "16px", display: "block", whiteSpace: "nowrap" }}>
      <div className="pt-4">
        <div className="row" style={{ paddingLeft: ((props.searchType === "BL") ? "100px" : "150px"), fontWeight: "bold", fontSize: "14px" }}>
          <div className="col-4 text-left">
            Location activities
          </div>
          <div className="col-4 text-center">
            Date & time
          </div>
          <div className="col-4">
            Duration
          </div>
        </div>
        <br />
        {
          (!dataArray || dataArray.length === 0 || dataArray === undefined) ? <></> :
            <>
              <div className='row'>
                <div className='col-1' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <ProgressBar data={result} />
                </div>
                <div className='col-11'>
                  <ul style={{ paddingLeft: "0px" }}>
                    {
                      (!dataArray || dataArray.length === 0 || dataArray === undefined) ? <></> :
                        <>
                          {result.map((each, i) => {
                            let srcIcon = ""

                            if (each.data[0].location_code) {
                              srcIcon = getFlagIcon(each.data[0]?.location_code.slice(0, 2));
                            }
                            return (
                              <>
                                <Tooltip id="info_name2" />
                                <div
                                  className={`pb-3 info_name2`}
                                  data-tooltip-id="info_name2"
                                  data-tooltip-content={(each.group.length >= 30) ? (`${each.group}, (${each.data[0].portType})`) : ("")}>
                                  {srcIcon.hasOwnProperty("img") &&
                                    srcIcon.hasOwnProperty("name") ? (
                                    <>
                                      <img
                                        src={srcIcon?.img}
                                        alt="SRC"
                                        className="flag_class"
                                        style={{
                                          transform: `${srcIcon.name === "China" ? "rotateX(45deg)" : ""
                                            }`,
                                        }}
                                      />&nbsp;&nbsp;&nbsp;
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                                    {`${getValue(each.group)}, (${each.data[0].portType})`}
                                  </label>
                                  {
                                    result[i].data.map((e) => {
                                      if (each.group == e.port) {
                                        return (
                                          <>
                                            <div className={`row`} style={{ paddingLeft: "20px", paddingBottom: "10px", fontSize: "12px" }}>
                                              <div className="col text-left"
                                              >
                                                {e.eventName}
                                              </div>
                                              <div className="col text-center">
                                                {e.actual_time ? (moment(e.actual_time, "YYYY-MM-DD hh:mm:ss A").format("Do MMM, YYYY  h:mma")) : (moment(e.current_time, "YYYY-MM-DD hh:mm:ss A").format("Do MMM, YYYY h:mma"))}
                                              </div>
                                              <div className="col">
                                                {(detailsMap.get(e._id) == "" || detailsMap.get(e._id) == "Invalid date") ? "Yet to occur" : detailsMap.get(e._id)}
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                    })
                                  }
                                </div>
                              </>
                            );
                          })}
                        </>
                    }
                  </ul>
                </div>
              </div>
            </>
        }
      </div>
    </div>
  )
}

const ProgressBar = ({ data }) => {
  let ydot = 1 + (0 * 11);
  let fillColor = "#cccc"
  let line = ""
  let y2line;
  let yline;
  return (
    <svg height={`100%`} width={`100%`}>
      {
        data.map((each, i) => {
          if (i != 0) {
            ydot = y2line + 1;
          }
          if (each.data.some(obj => obj.status == "active"))
            fillColor = "#cccc"
          else
            fillColor = "#3e64ff"
          return (
            <>
              <circle cx="70%" cy={`${ydot}rem`} r="0.8rem" stroke={fillColor} fill={fillColor} />
              <text x="70%" y={`${ydot + 0.1}rem`} dominant-baseline="middle" text-anchor="middle" fill='#ffff' font-size="20">
                ✔
              </text>
              {
                data[i].data.map((value, j) => {
                  if (value.actual_time == "") {
                    line = "2,2";
                    fillColor = "#cccc"
                  } else {
                    line = "none";
                    if (value.actual_time && value.estimated_time) {
                      let timeDiff = moment(
                        value.estimated_time,
                        "YYYY-MM-DD hh:mm:ss A"
                      ).diff(moment(value.actual_time, "YYYY-MM-DD hh:mm:ss A"));
                      if (timeDiff > 0) {
                        fillColor = "#40ec1d";
                      } else if (timeDiff < 0) {
                        fillColor = "#f01515";
                      } else {
                        fillColor = "#3e64ff";
                      }
                    }
                    else {
                      fillColor = "#3e64ff"
                    }
                  }
                  if (j == 0 && data[i].data.length > 1) {
                    yline = (ydot + 0.8) + (j * 2.3)
                    y2line = yline + 2
                  }
                  else if (data[i].data.length == 1 && j == 0) {
                    yline = (ydot + 0.8) + (j * 2.3)
                    y2line = yline + 3.3
                  }
                  else if (j == (data[i].data.length - 1) && i != (data.length - 1)) {
                    yline = y2line
                    y2line = yline + 3.3
                  }
                  else if (j == (data[i].data.length - 1) && i == (data.length - 1)) {
                    yline = y2line
                    y2line = yline + 2
                  }
                  else {
                    yline = y2line
                    y2line = yline + 2
                  }
                  return (
                    <>
                      <line x1="70%" y1={`${yline}rem`} x2="70%" y2={`${y2line}rem`} stroke={fillColor} strokeWidth="3" stroke-dasharray={line} />
                    </>
                  )

                })
              }
            </>
          )

        })}
    </svg>
  )
}
function sortData(array) {
  return array.sort((a, b) => {
    const timeA = a.actual_time || a.current_time;  // Use 'ctime' if 'atime' is falsy
    const timeB = b.actual_time || b.current_time;  // Use 'ctime' if 'atime' is falsy
    return new Date(timeA) - new Date(timeB);
  });
}

export default EventTab