import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';

// import { AgGridReact } from '@ag-grid-community/react';
// import {AllModules} from '@ag-grid-enterprise/all-modules';

// import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import icn from "../../img/undraw_profile.svg";
import $, { param } from 'jquery';
import 'jquery-ui';
import moment from 'moment';
import withRouter from './withRouter';
import UpdateTransporterGSTAction from "./updatetransportergstaction";
import ViewGST from "./viewgstdata";
import Favourites from "../common/favouritesComponent";


class BillingTransporterGST extends Component {
    constructor(props){
        super(props);
        // console.log(props)
        this.state={
            // modules:AllModules,
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
			},
            frameworkComponents:{
                ViewGST:ViewGST,
                UpdateTransporterGSTAction:UpdateTransporterGSTAction
            },
			detailCellRendererParams : {},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            conf:"",            
            showUploadCSVForm:"show-n",
            slider:"",
            closedDocs:[],
            otherCosts:[],
            overlay:"show-n",
            
            gsctcnt:0,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            components:{
                // UpdateTransporterGSTAction:UpdateTransporterGSTAction 
            },
            chidldata:[]
        }
        this.onClickAddGridCoulmn = this.onClickAddGridCoulmn.bind(this);
        // this.onClickShowGSTData = this.onClickShowGSTData.bind(this);
        this.loadTransporters = this.loadTransporters.bind(this);
        // this.onClickUpdateTransporterGSTData = this.onClickUpdateTransporterGSTData.bind(this);
        this.onCellEditingStopped = this.onCellEditingStopped.bind(this);
        
    }
	componentDidMount = async () => {
        
        // var cols = this.state.columnDefs;
        // cols.push({
        //     headerName:"GST",
        //     field:"transporter_gst",
        //     width:"140",
        //     editable:true
        // });
        redirectURL.post("/billing/configurations")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            var cols = this.state.columnDefs;
            if(records.length > 0)
            {
                
                // if(records[0].lsp_gst_multi === "yes" || records[0].lsp_gst_multi === "Yes")
                // {
                //     cols.push({
                //         headerName:"GST No 2",
                //         field:"transporter_gst_1",
                //         width:"140",
                //         editable:true
                //     },{
                //         headerName:"GST No 3",
                //         field:"transporter_gst_2",
                //         width:"140",
                //         editable:true
                //     },{
                //         headerName:"GST No 4",
                //         field:"transporter_gst_3",
                //         width:"140",
                //         editable:true
                //     },{
                //         headerName:"GST No 5",
                //         field:"transporter_gst_4",
                //         width:"140",
                //         editable:true
                //     });
                // }
                // console.log("cols ",cols)
                this.setState({
                    conf:records[0],
                    // columnDefs:cols
                })
            }
            else{
            }
		})
        
        var currentURL = window.location.pathname;
       
        await setTimeout(() => {
            this.loadTransporters()
            
        },500)
        
	}
    loadTransporters(){
        redirectURL.post("/billing/transporters")
        .then((response) => {
            var records = response.data.records;
        //    console.log("records ", records)
            var cols = this.state.columnDefs;
            
            var conf = this.state.conf;
            // console.log("[0] ", conf)
            var recordsarr = [];
            if(records.length > 0)
            {
            records.map((item) => {
                if(conf.lsp_gst === "both")
                {
                    item.gst_configuration = "RCM";
                    item.cgst = "6";
                    item.sgst = "6";
                    item.igst = "12";
                }
                else if(conf.lsp_gst === "rcm"){
                    item.gst_configuration = "RCM";
                    item.cgst = "2.5";
                    item.sgst = "2.5";
                    item.igst = "5";
                }
                else{
                    item.gst_configuration = "Both";
                    item.cgst = "";
                    item.sgst = "";
                    item.igst = "";
                }
                recordsarr.push(item);
            })
            }
            this.setState({
                rowData:recordsarr
            })
        })
    }
     /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };


	onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
          
          
          
   }

    onClickAddGridCoulmn(){
        var cols = this.state.columnDefs;
        
        var gsctcnt = this.state.conf.gsctcnt;
        // if(this.state.gsctcnt === 0)
        // {
        //     cols.push({
        //         headerName:"GST",
        //         field:"transporter_gst",
        //         width:"140",
        //         editable:true
        //     });
        // }
        // else{
            if(this.state.conf.lsp_gst_multi === "yes" || this.state.conf.lsp_gst_multi === "Yes")
            {
                // cols.push({
                //     headerName:"GST",
                //     field:"transporter_gst_1",
                //     width:"140",
                //     editable:true
                // },{
                //     headerName:"GST",
                //     field:"transporter_gst_2",
                //     width:"140",
                //     editable:true
                // },{
                //     headerName:"GST",
                //     field:"transporter_gst_3",
                //     width:"140",
                //     editable:true
                // },{
                //     headerName:"GST",
                //     field:"transporter_gst_4",
                //     width:"140",
                //     editable:true
                // });
                // var gsctcnt = parseInt(this.state.gsctcnt)+1;
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Multiple GST selections are not allowed. Please change GST settings in configuration.",
                    basicType:"info"
                })
                var gsctcnt = 0;
            }
            
        // }
        
        var rowData = this.state.rowData;
        var records = [];
        if(rowData.length > 0)
        {
            rowData.map((itm) => {
                itm.gst = "";
                records.push(itm);
            })
        }
        
        this.gridApi.setColumnDefs(cols);
        // console.log("cols ", cols)
        // this.gridApi.refreshView();
        this.setState({
            columnDefs:cols,
            rowData:records,
            gsctcnt:gsctcnt
        })
        this.appendGSTCol();
    }

    appendGSTCol(){
        var cols = this.state.columnDefs;
        cols.push({
            headerName:"GST",
            field:"transporter_gst",
            width:"140",
            editable:true
        });
        this.setState({
            columnDefs:cols
        })
    }
   
    onClickUpdateItem = async (rownod) =>{
        await this.setState({
            overlay:"show-m"
        })
        await redirectURL.post("/billing/saveuseraccess",rownod.data)
        .then((response) => {
            this.setState({
                overlay:"show-n"
            })
        })
    }
    onClickUpdateRowData(){
        var rowdata = [];
        this.gridApi.forEachNode((rownode) => {
            // console.log("rownode ", rownode)
            rowdata.push(rownode.data)
        })
        redirectURL.post("/billing/updatelspgst",{rowdata:JSON.stringify(rowdata)}  )
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger"
                })
            }
            
        })
    }
    onClickShowGSTData = async (rownode) =>{
        console.log("rownode ",rownode)
        var conf = this.state.conf;
        var ismulti = this.state.conf.lsp_gst_multi;
        await this.getRowData(rownode.data);
        setTimeout(async () => {
            
            var rows = this.state.chidldata;
            await this.setState({
					
                detailCellRendererParams:{
                    
                    detailGridOptions: {
                            columnDefs: [{ 
                                headerName: "GST No",
                                field: "transporter_gst", 
                                filter:true,
                                resizable: true,
                                editable:true
                            },
                            { 
                                headerName: "GST Place of Service",
                                field: "transporter_gst_place", 
                                filter:true,
                                resizable: true,
                                editable:true
                            },
                            { 
                                headerName: "GST Registered State",
                                field: "transporter_gst_state", 
                                filter:true,
                                resizable: true,
                                editable:true
                            },
                            { 
                                headerName: "",
                                field: "_id", 
                                cellRendererFramework:UpdateTransporterGSTAction
                                // suppressSizeToFit: true,
                            },
                        ],
                    },
                   
                    getDetailRowData: params => {
                           console.log("rows ", rows)
                        params.successCallback(rows);
                            
                        
                    }
    
                }
            });
            if(rownode.column.colDef.field == 'gstdata')
            {
    
                rownode.node.setExpanded(!rownode.node.expanded);
            }
            else{
    
                rownode.node.setExpanded(false);
            }
        },1000)
        
    }
    getRowData(params){
        var rows=[];
        console.log("parms", params)
        var ismulti = this.state.conf.lsp_gst_multi;
        redirectURL.post('/transportersgst', {
            transporter_code:params.transporter_code, 
            ismulti:ismulti
        })
        .then(async (response) =>{
            var records=response.data.records;
            console.log('records ', records)
            if(records.length > 0)
            {
                if(ismulti === "yes" || ismulti === "Yes")
                {
                    if(records.length === 1)
                    {
                        records.push({
                            transporter_code:params.transporter_code,
                            transporter_gst:"",
                            transporter_gst_place:"",
                            transporter_gst_state:""
                        },
                        {
                            transporter_code:params.transporter_code,
                            transporter_gst:"",
                            transporter_gst_place:"",
                            transporter_gst_state:""
                        },
                        {
                            transporter_code:params.transporter_code,
                            transporter_gst:"",
                            transporter_gst_place:"",
                            transporter_gst_state:""
                        },
                        {
                            transporter_code:params.transporter_code,
                            transporter_gst:"",
                            transporter_gst_place:"",
                            transporter_gst_state:""
                        })
                        rows = records;
                    }
                    else{
                        rows = records;
                    }
                }
                else{
                    rows = records;
                }
                
            }
            else{
                
                if(ismulti === "yes" || ismulti === "Yes")
                {
                    rows.push({
                        transporter_code:params.transporter_code,
                        transporter_gst:"",
                        transporter_gst_place:"",
                        transporter_gst_state:""
                    },
                    {
                        transporter_code:params.transporter_code,
                        transporter_gst:"",
                        transporter_gst_place:"",
                        transporter_gst_state:""
                    },
                    {
                        transporter_code:params.transporter_code,
                        transporter_gst:"",
                        transporter_gst_place:"",
                        transporter_gst_state:""
                    },
                    {
                        transporter_code:params.transporter_code,
                        transporter_gst:"",
                        transporter_gst_place:"",
                        transporter_gst_state:""
                    })
                }
                else{
                    rows.push({
                        transporter_code:params.transporter_code,
                        transporter_gst:"",
                        transporter_gst_place:"",
                        transporter_gst_state:""
                    })
                }
            }
            this.setState({
                chidldata:rows
            })
        });
    }
    onClickUpdateTransporterGSTData(rownode)
    {
        console.log("rownoderownode ", rownode)
        // redirectURL.post("/billing/savetransportergst",rownode.data)
        // .then((response) => {
        //     this.setState({
        //         overlay:"show-n"
        //     })
        // })
    }
    onCellEditingStopped(p){
        console.log("p ", p)
    }
    render(){
        var conf = this.state.conf;
        
        var columnDefs=[
            {
                headerName:"Transporter Code",
                field:"transporter_code",
                width:"140"
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:"240"
            },
            {
                headerName:"GST Configuration",
                field:"gst_configuration"
            },{
                headerName:"CGST (%)",
                field:"cgst"
            },{
                headerName:"SGST (%)",
                field:"sgst"
            },{
                headerName:"IGST (%)",
                field:"igst"
            },
            {
                headerName:"",
                field:"gstdata",
                cellRendererSelector:function(params){
                    return {
                        component:"ViewGST"
                    }
                }
            }
        ]
        // if(conf.lsp_gst_multi === "yes" || conf.lsp_gst_multi === "Yes")
        // {
        //     columnDefs.push(
        //         {
        //             headerName:"GST No 2",
        //             field:"transporter_gst_1",
        //             width:"140",
        //             editable:true
        //         },
        //         {
        //             headerName:"GST No 3",
        //             field:"transporter_gst_2",
        //             width:"140",
        //             editable:true
        //         },
        //         {
        //             headerName:"GST No 4",
        //             field:"transporter_gst_3",
        //             width:"140",
        //             editable:true
        //         },
        //         {
        //             headerName:"GST No 5",
        //             field:"transporter_gst_4",
        //             width:"140",
        //             editable:true
        //         })
        // }
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
                </SweetAlert>
                    {this.state.alert}
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="fa fa-truck cus-i"></i>&nbsp;<span>{Constants.PAGE_TITLE_TRANSPORTER_GST}</span>
                        <Favourites />
                        {/* <button type="button" className="btn btn-info float-right" onClick={this.onClickAddGridCoulmn}>Add GST</button> */}
                    </h5>
                </div>
                
				<div className="row">
                    
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <div id="myGrid" style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                <AgGridReact 
                                
		          		            // modules={this.state.modules}
		          		            columnDefs={columnDefs}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
                                    animateRows={true}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
                                    isSuppressAnimationFrame={true}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									stopEditingWhenGridLosesFocus={true}
									enableCellChangeFlash={true}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
                                    // context = {this }
									// onCellEditingStopped={this.onCellEditingStopped}
									
		          		          />

                                </div> 
                                {/* <button type="button" className="btn btn-danger" onClick={this.onClickUpdateRowData.bind(this)}>Update Data</button> */}
                                
							</div>
						</div>
					</div>
					
				</div>

                <div className={"overlay-block "+(this.state.overlay)}></div>
			</div>
                
                
        );
    }
}
const options = {
    '':"",
    Yes: 'Yes',
    No: 'No'
  };
function extractValues(mappings) {
    return Object.keys(mappings);
  }
  
export default withRouter(BillingTransporterGST) ;