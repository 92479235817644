import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
// import { AllModules } from "@ag-grid-enterprise/all-modules";
import GridButton from "./gridButton";
import React, { Component } from "react";
import redirectURL from "../redirectURL";
import $ from "jquery";
import * as XLSX from "xlsx";
import { verifyext } from "../common/verifyext";
import CSVFileValidator from "csv-file-validator";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getHyphenDDMMMYYYYHHMM,
  getHyphenYYYYMMDDHHMMSS,
} from "../common/utils";
import CountDownTimer from "./timerComponent";
import StaticButton from "./static-button";
import NAButton from "./naButton";
var moment = require("moment");

export default class ConsolidatedRunPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        NAButton: NAButton,
        GridButton: GridButton,
        StaticButton: StaticButton,
      },
      rowData: [],
      detailCellRendererParams: {},
      locationNames: [],
      location: "",
      runNewPlanTab: "activet",
      vehicleAvailabilityTab: "",
      runNewPlanData: true,
      vehicleAvailabilityData: false,
      loadshow: "show-m",
      overly: "show-m",
      showSlideBlockUpload: "",
      loadFile: "",
      systemPlanning: 1,
      manualPlanning: 0,
      vehicleDataDownld: false,
      vehiclesFile: "",
      show: false,
      basicTitle: "",
      basicType: "default",
      conPlanData: [],
      inputDetails: [],
      showInputSlider: "sidebarcls",
      vehicleConfigs: [],
      allconsolidatedTrnsxId: [],
      is_sunday_planning_enabled: 0,
      inputXlFile: false,
      plantCodes: [],
      simulationTime: "",
      //   fromDate: "",
      //   toDate: "",
      toDate: moment.parseZone().utcOffset("+05:30").format("YYYY-MM-DD"),
      fromDate: moment
        .parseZone()
        .subtract(1, "months")
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
    };
  }
  async componentDidMount() {
    // var toDate = moment().format("YYYY-MM-DD");
    // var fromDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    // console.log(toDate, "didmout", fromDate);
    // $("#fromDate").val(fromDate);
    // $("#toDate").val(toDate);
    await this.setState({
      loadshow: "show-n",
      overly: "show-n",
      // toDate,
      // fromDate,
    });
    await this.getConsolidatedVehicleData();
    await this.getConsolidatedPlansData();
    await this.getVehicleConfigsForPlanning();
    await this.enablesundayplanning();
    await this.boundTypeLocations();
    await this.removeDatepickerClass();
  }

  componentWillUnmount() {
    clearInterval(this.updateplanintervalId);
  }

  enablesundayplanning = () => {
    var currentDate = new Date();
    if (this.state.simulationTime !== "") {
      currentDate = new Date(this.state.simulationTime);
      // console.log("simult", currentDate, this.state.simulationTime);
    }

    const currentDay = currentDate.getDay();

    const currentHour = currentDate.getHours();

    const enableCheckbox =
      (currentDay === 6 && currentHour >= 16) ||
      (currentDay === 0 && currentHour < 16);

    if (enableCheckbox) {
      this.setState({
        enable_sunday_planning: true,
      });
    } else {
      this.setState({
        enable_sunday_planning: false,
      });
    }
  };

  getConsolidatedVehicleData = async () => {
    await redirectURL
      .post("master/getVehiclesForConsolidatedPlanning")
      .then(async (response) => {
        await this.setState({ vehiclesData: response.data });
      })
      .catch(function (error) {
        console.log(error);

        var errDetails = {
          url: "/master/getVehiclesForConsolidatedPlanning",
          error,
          screen: "Consolidated run plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getConsolidatedPlansData = async () => {
    // var fromDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    // var toDate = moment().format("YYYY-MM-DD");
    var { fromDate, toDate } = this.state;
    await redirectURL
      .post("master/getConsolidatedPlanData", { fromDate, toDate })
      .then(async (response) => {
        let conPlanData = response.data.sort(GetSortDescOrder("createDate"));
        this.setState({ conPlanData });
        this.runcounterforpendingtransx(conPlanData);
      })
      .catch(function (error) {
        console.log(error);

        var errDetails = {
          url: "/master/getConsolidatedPlanData",
          error,
          screen: "Consolidated run plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getVehicleConfigsForPlanning = async () => {
    await redirectURL
      .post("master/getVehiclesData", { data_type: 2 })
      .then(async (response) => {
        // console.log(response.data,'hello there')
        await this.setState({ vehicleConfigs: response.data.vehiclesData });
      })
      .catch(function (error) {
        console.log(error);
        var errDetails = {
          url: "/master/getVehiclesData",
          error,
          screen: "Consolidated run plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  hideSlideBlock = () => {
    this.setState({
      showSlideBlockUpload: "",
      overly: "show-n",
      loadFile: "",
      vehiclesFile: "",
      vehicleDataDownld: false,
      systemPlanning: 1,
      manualPlanning: 0,
      showInputSlider: "",
      simulationTime: "",
    });
    $("#uploadFile").val("");
    $("#vehiclesFile").val("");
    document.getElementById("inValidDataInfo").innerHTML = "";

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "Clicked on Cancel Button",
      event: "Closed Upload File Slider",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onClickDowloadFile = async () => {
    await this.getConsolidatedVehicleData();
    const data = this.state.vehiclesData.map((item) => {
      return {
        "Pickup Location": item.location_code,
        "Vehicle No": item.vehicle_no,
        "Vehicle Next Availability Date(DC)": item.vehicle_next_available_date,
        // "Assigned To OT": "No",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "csv",
      type: "array",
    });

    const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");

    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = `vehicles_data_${timestamp}.csv`;
    const downloadLink = document.createElement("a");

    downloadLink.href = window.URL.createObjectURL(dataBlob);
    downloadLink.download = fileName;
    downloadLink.click();
    this.setState({ vehicleDataDownld: true });
  };
  onClickUploadFile = () => {
    this.setState({ showSlideBlockUpload: "slide25", overly: "show-m" });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on Upload Input file Button",
      event: "Opened Upload file Slider",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  csvFileValidationsForInputFile = (csvData) => {
    var { plantCodes } = this.state;
    const config = {
      headers: [
        {
          name: "Division",
          inputName: "pickup_location_code",
          required: true,
          validate: function (code) {
            return isPlantValid(code, plantCodes);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Customer Code",
          inputName: "drop_location_code",
          required: true,
          validate: function (code) {
            return isDropCodeValid(code, plantCodes);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Customer Name",
          inputName: "drop_location_name",
        },
        {
          name: "D.O. Number",
          inputName: "order_number",
          required: true,
          // unique: true,
          // uniqueError: function (headerName) {
          //   return `${headerName} is not unique`;
          // },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        // {
        //   name: "Invoicing Date",
        //   inputName: "Invoicing Date",
        //   required: true,
        //   requiredError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        //   validate: function (date) {
        //     return isDateFormatValid(date);
        //   },
        //   validateError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        // },
        {
          name: "D.O. Date",
          inputName: "invoicing_date",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (date) {
            return isDateFormatValid(date);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Item Code",
          inputName: "item_code",
        },
        {
          name: "Item Details",
          inputName: "item_details",
        },
        {
          name: "Last Updated Date",
          inputName: "last_updated_date",
        },
        {
          name: "Last Updated by",
          inputName: "last_updated_by",
        },
        {
          name: "D.O. Details",
          inputName: "do_details",
        },
        {
          name: "D.O. Type",
          inputName: "do_type",
        },
        {
          name: "D.O. Type Name",
          inputName: "do_type_name",
        },
        {
          name: "D.O. Quantity",
          inputName: "do_quantity",
        },
        {
          name: "Cancelled Quantity",
          inputName: "cancelled_do_quantity",
        },
        {
          name: "Net D.O. Quantity",
          inputName: "net_do_quantity",
        },
        {
          name: "Executed Qty (Advice)",
          inputName: "executed_do_quantity",
        },
        {
          name: "Balance D.O. Quantity",
          inputName: "balance_do_quantity",
        },
        // {
        //   name: "Quantity",
        //   inputName: "Quantity",
        //   required: true,
        //   requiredError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        //   validate: function (quantity) {
        //     return parseFloat(quantity) < 1000;
        //   },
        //   validateError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 1000`;
        //   },
        // },
        // {
        //   name: "Demand",
        //   inputName: "Demand",
        //   required: true,
        //   requiredError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        //   validate: function (demand) {
        //     return parseFloat(demand) < 100;
        //   },
        //   validateError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 100`;
        //   },
        // },
        // {
        //   name: "Weight",
        //   inputName: "Weight",
        //   required: true,
        //   requiredError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
        //   },
        //   validate: function (weight) {
        //     return isValidWeight(weight);
        //   },
        //   validateError: function (headerName, rowNumber, columnNumber) {
        //     return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 50000`;
        //   },
        // },
        // {
        //   name: "Dealer Available Time (From 00:00)",
        //   inputName: "Dealer Available Time (From 00:00)",
        //   required: false,
        // },
        // {
        //   name: "Dealer Available Time (To 23:59)",
        //   inputName: "Dealer Available Time (To 23:59)",
        //   required: false,
        // },
        
      ],
    };
    CSVFileValidator(csvData, config)
      .then(async (csvData) => {
        this.setState({loadFile:csvData.data})
        // console.log(csvData,'csvdata')
        document.getElementById("bulkUploadBtn").type = "button";
        document.getElementById("bulkUploadBtn").classList.remove("btn-info");
        document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
          // Assuming 'csvData' is an array of objects representing CSV rows
          var totalData = groupBy(
            csvData.data,
            (rdata) => rdata["pickup_location_code"]
          );
          var plantsWithDrops = {};
          totalData.forEach((rec, key) => {
            var dropCodes = [];
            rec.map((item) => {
              dropCodes.push(item["drop_location_code"]);
              return "";
            });
            // console.log("key", key);
            plantsWithDrops[key] = dropCodes;
          });
          var invalidMsg = [];
          // console.log(plantsWithDrops, "data", totalData);
          // await redirectURL
          //   .post("/master/validateDealersForConslplan", plantsWithDrops)
          //   .then(async (response) => {
          //     // console.log("res", response.data);
          //     var data = response.data;
          //     for (var loc in data) {
          //       if (data[loc]) {
          //         invalidMsg.push({
          //           // columnIndex: "4,10",
          //           message: `All Drop locations for ${loc} pickup location is invalid!`,
          //           // rowIndex:  2,
          //         });
          //       }
          //     }
          //   });
          // csvData.data.forEach((row, idx) => {
          //   const invoicingDate = row["Invoicing Date"];
          //   const deliveryDate = row["Delivery Date"];
          //   var parseInvoiceD = moment(invoicingDate, "M/D/YYYY");
          //   var parseOrderD = moment(deliveryDate, "M/D/YYYY");
          //   const formattedInVdate = parseInvoiceD.format("M/D/YYYY");
          //   var formattedOdD = parseOrderD.format("M/D/YYYY");
          //   const minDate = moment().subtract(30, "days");
          //   const maxDate = moment().add(30, "days");
          //   const isInRange = moment(formattedInVdate, "M/D/YYYY").isBetween(
          //     minDate,
          //     maxDate,
          //     null,
          //     "[]"
          //   );
          //   const isDeliverRange = moment(formattedOdD, "M/D/YYYY").isBetween(
          //     minDate,
          //     maxDate,
          //     null,
          //     "[]"
          //   );
          //   // console.log(isInRange,'dfji')
          //   if (new Date(formattedInVdate) && new Date(formattedOdD)) {
          //     if (new Date(formattedInVdate) > new Date(formattedOdD)) {
          //       csvData.inValidData.push({
          //         columnIndex: "4,10",
          //         message: "Invoice date should be less than delivery date",
          //         rowIndex: idx + 2,
          //       });
          //     }
          //   }
          //   if (!isInRange) {
          //     csvData.inValidData.push({
          //       columnIndex: invoicingDateIndex + 1,
          //       message: "Invoice date is out of range",
          //       rowIndex: idx + 1,
          //     });
          //   }
          //   if (!isDeliverRange) {
          //     csvData.inValidData.push({
          //       columnIndex: idx,
          //       message: "Delivery date is out of range",
          //       rowIndex: idx,
          //     });
          //   }
          // });
        document.getElementById("inValidDataInfo").innerHTML = "";
        if (invalidMsg.length) {
          this.setState({
            basicTitle: "All Drop Locations are Invalid for some Plants",
            basicType: "danger",
            show: true,
          });
        }
        csvData.inValidData = [...csvData.inValidData, ...invalidMsg];
        if (csvData.inValidData.length > 0) {
          $("#uploadFile").val("");
          $("#vehiclesFile").val("");
          document.getElementById("bulkUploadBtn").type = "button";
          document.getElementById("bulkUploadBtn").classList.remove("btn-info");
          document
            .getElementById("bulkUploadBtn")
            .classList.add("btn-secondary");
          let invalidData = csvData.inValidData;
          let element = document.getElementById("inValidDataInfo");
          invalidData.map((item) => {
            let row, column;
            if (item.rowIndex == undefined) {
              row = "NA";
            } else {
              row = item.rowIndex;
            }
            if (item.columnIndex == undefined) {
              column = "NA";
            } else {
              column = item.columnIndex;
            }

            element.innerHTML +=
              "Column : " +
              column +
              "," +
              " Row : " +
              row +
              "--" +
              item.message +
              "<br></br>";
          });
          let throwMsg = 0;
          invalidData.map((itm) => {
            if (itm.columnIndex == 17) {
              throwMsg = 1;
            }
          });
          // if (throwMsg) {
          //   this.setState({
          //     uploadFile: "",
          //     show: true,
          //     basicType: "danger",
          //     basicTitle:
          //       "Please Give Valid Input Data and also Ensure Date is in this Format only DD-MM-YYYY",
          //   });
          // }
        } else {
          document.getElementById("bulkUploadBtn").type = "submit";
          document
            .getElementById("bulkUploadBtn")
            .classList.remove("btn-secondary");
          document.getElementById("bulkUploadBtn").classList.add("btn-info");
        }
      })
      .catch((err) => {
        var errDetails = {
          url: "CSVFileValidator",
          err,
          screen: "Run New Plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  validateAllValues = (jsonData) => {
    // console.log(jsonData,'jasondata')
    var inValidData = [];
    jsonData.map((rec, idx) => {
      if (rec["Division"] == "" || rec["Division"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Division is required",
        });
      }
      if (rec["Customer Code"] == "" || rec["Customer Code"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 2,
          message: "Customer Code is required",
        });
      }
      if (rec["Customer Name"] == "" || rec["Customer Name"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 3,
          message: "Customer Name is required",
        });
      }
      if (rec["D.O. Number"] == "" || rec["D.O. Number"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 4,
          message: "D.O. Number is required",
        });
      }
      if (rec["D.O. Date"] && rec["D.O. Date"].includes("-")) {

        if (
          rec["D.O. Date"] === "" ||
          rec["D.O. Date"] === undefined

        ) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 5,
            message: "D.O. Date is required",
          });
        } else if (!isDateFormatValid(rec["D.O. Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 5,
            message: "D.O. Date Format is not Valid",
          });
        }
      } else {
        if (
          rec["D.O. Date"] === "" ||
          rec["D.O. Date"] === undefined

        ) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 5,
            message: "D.O. Date is required",
          });
        } else if (!isDateFormatValid(rec["D.O. Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 5,
            message: "D.O. Date Format is not Valid",
          });
        }
      }

      if (rec["Item Code"] == "" || rec["Item Code"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 6,
          message: "Item Code is required",
        });
      }
      if (rec["Item Details"] == "" || rec["Item Details"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 7,
          message: "Item Details is required",
        });
      }
      if (rec["Last Updated Date"] == "" || rec["Last Updated Date"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 8,
          message: "Last Updated Date is required",
        });
      }
      if (rec["Last Updated by"] == "" || rec["Last Updated by"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 9,
          message: "Last Updated by is required",
        });
      }
      if (rec["D.O. Details"] == "" || rec["D.O. Details"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 10,
          message: "D.O. Details is required",
        });
      }
      if (rec["D.O. Type"] == "" || rec["D.O. Type"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 11,
          message: "D.O. Type is required",
        });
      }
      if (rec["D.O. Type Name"] == "" || rec["D.O. Type Name"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 12,
          message: "D.O. Type Name is required",
        });
      }
      if (rec["D.O. Quantity"] == "" || rec["D.O. Quantity"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 13,
          message: "D.O. Quantity is required",
        });
      }
      if (rec["Cancelled Quantity"] == "" || rec["Cancelled Quantity"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 14,
          message: "Cancelled Quantity is required",
        });
      }
      if (rec["Net D.O. Quantity"] == "" || rec["Net D.O. Quantity"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 15,
          message: "Net D.O. Quantity is required",
        });
      }
      if (rec["Executed Qty (Advice)"] == "" || rec["Executed Qty (Advice)"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 16,
          message: "Executed Qty (Advice) is required",
        });
      }
      if (rec["Balance D.O. Quantity"] == "" || rec["Balance D.O. Quantity"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 17,
          message: "Balance D.O. Quantity is required",
        });
      }

      // if (rec["Order Number"] == "" || rec["Order Number"] == undefined) {
      //   inValidData.push({
      //     rowIndex: idx + 2,
      //     columnIndex: 1,
      //     message: "Order Number  is required",
      //   });
      // }
      // console.log(!(isDateFormatValidforXLSX(rec["Invoicing Date"]) || isDateFormatValid(rec["Invoicing Date"])))
      // if (rec["Invoicing Date"].includes("-")) {
      //   if (
      //     rec["Invoicing Date"] === "" ||
      //     rec["Invoicing Date"] === undefined ||
      //     !isDateFormatValid(rec["Invoicing Date"])
      //   ) {
      //     inValidData.push({
      //       rowIndex: idx + 2,
      //       columnIndex: 1,
      //       message: "Invoicing Date is required",
      //     });
      //   }
      // } else {
      //   if (
      //     rec["Invoicing Date"] === "" ||
      //     rec["Invoicing Date"] === undefined ||
      //     !isDateFormatValidforXLSX(rec["Invoicing Date"])
      //   ) {
      //     inValidData.push({
      //       rowIndex: idx + 2,
      //       columnIndex: 1,
      //       message: "Invoicing Date is required",
      //     });
      //   }
      // }
      // if (rec["Quantity"] == "" || rec["Quantity"] == undefined) {
      //   inValidData.push({
      //     rowIndex: idx + 2,
      //     columnIndex: 1,
      //     message: "Quantity  is required",
      //   });
      // }
      // if (rec["Demand"] == "" || rec["Demand"] == undefined) {
      //   inValidData.push({
      //     rowIndex: idx + 2,
      //     columnIndex: 1,
      //     message: "Demand  is required",
      //   });
      // }
      // if (rec["Weight"] == "" || rec["Weight"] == undefined) {
      //   inValidData.push({
      //     rowIndex: idx + 2,
      //     columnIndex: 1,
      //     message: " Weight is required",
      //   });
      // }
      // if (
      //   rec["Dealer Available Time (From 00:00)"] == "" ||
      //   rec["Dealer Available Time (From 00:00)"] == undefined
      // ) {
      //   inValidData.push({
      //     rowIndex: idx + 2,
      //     columnIndex: 1,
      //     message: "Dealer Available Time is required",
      //   });
      // }
      // if (
      //   rec["Dealer Available Time (To 23:59)"] == "" ||
      //   rec["Dealer Available Time (To 23:59)"] == undefined
      // ) {
      //   inValidData.push({
      //     rowIndex: idx + 2,
      //     columnIndex: 1,
      //     message: "Dealer Available Time (To 23:59)  is required",
      //   });
      // }
      // console.log(!isDateFormatValidforXLSX(rec["Delivery Date"]),'delivery',isDateFormatValid(rec["Delivery Date"]))
      // console.log('first',(rec['Delivery Date']).includes('-'))
      // if (rec["Delivery Date"].includes("-")) {
      //   if (
      //     rec["Delivery Date"] === "" ||
      //     rec["Delivery Date"] === undefined ||
      //     !isDateFormatValid(rec["Delivery Date"])
      //   ) {
      //     inValidData.push({
      //       rowIndex: idx + 2,
      //       columnIndex: 1,
      //       message: "Delivery Date is required",
      //     });
      //   }
      // } else {
      //   if (
      //     rec["Delivery Date"] === "" ||
      //     rec["Delivery Date"] === undefined ||
      //     !isDateFormatValidforXLSX(rec["Delivery Date"])
      //   ) {
      //     inValidData.push({
      //       rowIndex: idx + 2,
      //       columnIndex: 1,
      //       message: "Delivery Date is required",
      //     });
      //   }
      // }
    });
    document.getElementById("inValidDataInfo").innerHTML = "";
    if (inValidData.length > 0) {
      $("#uploadFile").val("");
      document.getElementById("bulkUploadBtn").type = "button";
      document.getElementById("bulkUploadBtn").classList.remove("btn-info");
      document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
      let invalidData = inValidData;
      let element = document.getElementById("inValidDataInfo");
      invalidData.map((item) => {
        let row, column;
        if (item.rowIndex == undefined) {
          row = "NA";
        } else {
          row = item.rowIndex;
        }
        if (item.columnIndex == undefined) {
          column = "NA";
        } else {
          column = item.columnIndex;
        }

        element.innerHTML +=
          "Column : " +
          column +
          "," +
          " Row : " +
          row +
          "--" +
          item.message +
          "<br></br>";
      });
      let throwMsg = 0;
      invalidData.map((itm) => {
        if (itm.columnIndex == 17) {
          throwMsg = 1;
        }
      });
      if (throwMsg) {
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle:
            "Please Input Valid Data",
        });
      }
      return false;
    } else {
      document.getElementById("bulkUploadBtn").type = "submit";
      document
        .getElementById("bulkUploadBtn")
        .classList.remove("btn-secondary");
      document.getElementById("bulkUploadBtn").classList.add("btn-info");
      return true;
    }
  };

  changeFileHandler = (e) => {
    this.setState({ loadFile: e.target.files[0] });
    document.getElementById("inValidDataInfo").innerHTML = "";

    var extentions = [
      "config",
      "exe",
      "js",
      "jsx",
      "svg",
      "JPG",
      "jpg",
      "jpeg",
      "GIF",
      "gif",
      "PNG",
      "png",
      "BMP",
      "bmp",
      "html",
      "xls",
      "doc",
      "docx",
      "ppt",
      "pptx",
      "pdf",
      "pdfx",
      "html",
      "css",
      "sh",
      "php5",
      "pht",
      "phtml",
      "shtml",
      "asa",
      "cer",
      "asax",
      "swf",
      "xap",
      "php",
      "htaccess",
      "xml",
      "xds",
      "asp",
      "aspx",
      "java",
      "c",
      "c++",
      "ctl",
      "conf",
      "qewe",
      "encrypted",
      "enc",
      "crypted",
      "locked",
      "crypto",
      "crypt",
      "txt",
    ];
    var check = !extentions.includes(e.target.files[0].name);
    if (check) {
      // console.log("hit");
      if (
        e.target.files[0].type === "" ||
        e.target.files[0].type === "text/csv" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // console.log("hit");
        // console.log(fileData);
        // console.log(typeof fileData);

        if (
          e.target.files[0].type === "" ||
          e.target.files[0].type === "text/csv"
        ) {
          this.setState({ inputXlFile: false });
          // console.log('hit')
          this.csvFileValidationsForInputFile(e.target.files[0]);
        } else if (
          e.target.files[0].type === "application/vnd.ms-excel" ||
          e.target.files[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const file = e.target.files[0];
          const reader = new FileReader();
          var jsondata = [];
          reader.onload = async (evt) => {
            const data = evt.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false });
            // console.log(jsonData, "json data");
            // jsonData.map((item)=>{
            var result = this.validateAllValues(jsonData);
            // })
            // Convert JSON data to CSV format with custom date formatting
            var csvData = jsonData.reduce((csv, row, index) => {
              if (index === 0) {
                // Add header row
                csv += Object.keys(row).join(",") + "\n";
              }

              // Format the invoicing_date field to mm-dd-yyyy
              // if (row["Invoicing Date"]) {
              //   const dateParts = row["Invoicing Date"].split("/");
              //   if (dateParts.length === 3) {
              //     const month = dateParts[0].padStart(2, "0");
              //     const day = dateParts[1].padStart(2, "0");
              //     const year = dateParts[2];
              //     row["Invoicing Date"] = `${month}/${day}/${year}`;
              //   }
              // }
              // if (row["Delivery Date"]) {
              //   const dateParts = row["Delivery Date"].split("/");
              //   if (dateParts.length === 3) {
              //     const month = dateParts[0].padStart(2, "0");
              //     const day = dateParts[1].padStart(2, "0");
              //     const year = dateParts[2];
              //     row["Delivery Date"] = `${month}/${day}/${year}`;
              //   }
              // }
              // Add row data
              csv +=
                Object.values(row)
                  .map((value) => {
                    // Check if the value is a number and convert it to a number type
                    if (!isNaN(value)) {
                      if (value.includes(".")) {
                        return parseFloat(value);
                      } else {
                        return parseInt(value, 10);
                      }
                    }
                    return `"${value}"`;
                  })
                  .join(",") + "\n";
              return csv;
            }, "");
            // console.log(csvData,'csv')
            await this.setState({
              loadFile: csvData,
              inputXlFile: true,
            });
            if (result) {
              // console.log('hit after vcalidation')
              this.csvFileValidationsForInputFile(csvData);
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle:
            "Please upload file having extensions .csv or .xlsx only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv or .xlsx only.",
      });
    }

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on Choose file Button",
      event: "Chosen Input file ",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };
  closeAlert = () => {
    this.setState({ show: false, overly: "show-n", loadshow: "show-n" });
  };
  onClickSystemPlanning = (e) => {
    this.setState({ systemPlanning: 1, manualPlanning: 0 });
  };
  onClickManualPlanning = (e) => {
    this.setState({ manualPlanning: 1, systemPlanning: 0 });
  };
  onClickRunPlan = async (e) => {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
      showSlideBlockUpload: "",
    });
    await this.getVehicleConfigsForPlanning();
    // console.log(this.state.vehicleConfigs,'lll')
    let planData = new FormData();
    if (this.state.inputXlFile) {
      const csvBlob = new Blob([this.state.loadFile], { type: "text/csv" });
      // console.log('hello',this.state.loadFile)
      planData.append("loadFile", csvBlob, "input.csv");
    } else {
      planData.append("loadFile", this.state.loadFile);
    }
    planData.append("module_type", 1);
    planData.append("data_type", 2);
    planData.append("sla", "");
    planData.append("location_code", "");
    planData.append("no_of_runs", 1);
    planData.append("order_id", "");
    planData.append("inputselectedData", "");
    planData.append("opti_type", "");
    planData.append("average_speed", "");
    planData.append("time_limit", "");
    // planData.append("order_date", "");
    // planData.append("routeprefer", 1);
    planData.append("gpsVehiclesSelectedData", []);
    planData.append("useremail", localStorage.getItem("email"));
    planData.append("client", localStorage.getItem("client"));
    planData.append("run_with_RDD", 0);
    planData.append("run_with_transit", 1);
    planData.append("run_with_transit_cityclub", 0);
    planData.append("currentAvailableVehicles", "");
    planData.append("no_of_spot_vehicles_given", []);
    planData.append("plan_with_spot_vehicles", 0);
    planData.append("no_of_vehicles_required", 0);
    // planData.append("selectReload", 0);
    planData.append("min_VFR", 50);
    planData.append("simulation_time", this.state.simulationTime);
    planData.append("total_vehicles", []);
    planData.append("vehiclesLoadFile", this.state.vehiclesFile);
    planData.append("considersystemPlanning", this.state.systemPlanning);
    planData.append("considermanualPlanning", this.state.manualPlanning);
    planData.append(
      "is_sunday_planning_enabled",
      this.state.is_sunday_planning_enabled
    );
    planData.append("vehicleData", JSON.stringify(this.state.vehicleConfigs));
    this.sendPlanRunMail();
    await redirectURL
      .post("/dispatch/consolidatedData", planData)
      .then(async (response) => {
        // console.log(response.data,'datta')
        if (response.data.status === 1) {
          await this.setState({
            showSlideBlockUpload: "show-n",
            basicTitle: "Plan Is Processing",
            basicType: "success",
            show: true,
            systemPlanning: 1,
            manualPlanning: 0,
            loadFile: "",
            vehiclesFile: "",
            vehicleDataDownld: false,
            loadshow: "show-n",
            overly: "show-n",
          });
        } else {
          this.setState({
            showSlideBlockUpload: "show-n",
            basicTitle: "Plan Execution Failed",
            basicType: "danger",
            show: true,
            systemPlanning: 1,
            manualPlanning: 0,
            loadFile: "",
            vehiclesFile: "",
            vehicleDataDownld: false,
            loadshow: "show-n",
            overly: "show-n",
          });
          this.sendPlanTimeOutMail();
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          showSlideBlockUpload: "show-n",
          basicTitle: "Plan Execution Failed",
          basicType: "danger",
          show: true,
          systemPlanning: 1,
          manualPlanning: 0,
          loadFile: "",
          vehiclesFile: "",
          vehicleDataDownld: false,
        });
        this.sendPlanTimeOutMail();
      });
    // this.setState({
    //   showSlideBlockUpload: "show-n",
    //   basicTitle: "Plan Is Processing",
    //   basicType: "success",
    //   show: true,
    //   systemPlanning: 1,
    //   manualPlanning: 0,
    //   loadFile: "",
    //   vehiclesFile: "",
    //   vehicleDataDownld: false,
    // });
    $("#uploadFile").val("");
    $("#vehiclesFile").val("");
    await this.getConsolidatedPlansData();
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on Run Plan Button",
      event: "Generated New Plan",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  updatePlanDetails = async () => {
    let counter = 0;
    this.updateplanintervalId = setInterval(async () => {
      await redirectURL.post("master/updatePlanDetails").then((response) => {
        // console.log(response, "hello");
      });
      await this.getConsolidatedPlansData();
      // console.log("hit there");

      counter++;
      if (counter === 16) {
        clearInterval(this.updateplanintervalId);
      }
    }, 30000);
  };

  sendPlanRunMail = () => {
    var url = window.location.host;
    var environment = "";
    if (url === "216.48.191.229:3011") {
      environment = "In DEV";
    } else if (url === "164.52.218.255:3009") {
      environment = "In QA";
    } else if (url === "nestle.enmovil.net") {
      environment = "In UAT";
    } else if (url === "nestle.optirun.in") {
      environment = "In PROD";
    } else {
      environment = "In Local";
    }
    var params = {};
    params.environment = environment;
    params.e = localStorage.getItem("email");
    params.msg = "Run Consolidated Plan";
    // console.log(params, "params");
    redirectURL
      .post("/master/triggerEmail", params)
      .then((response) => {})
      .catch(function (error) {
        console.log(error);
        var errDetails = {
          url: "/master/triggerEmail",
          error,
          screen: "Consolidated run plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  sendPlanTimeOutMail = () => {
    var url = window.location.host;
    var environment = "";
    if (url === "216.48.191.229:3011") {
      environment = "In DEV";
    } else if (url === "164.52.218.255:3009") {
      environment = "In QA";
    } else if (url === "nestle.enmovil.net") {
      environment = "In UAT";
    } else if (url === "nestle.optirun.in") {
      environment = "In PROD";
    } else {
      environment = "In Local";
    }
    var params = {};
    params.env = environment;
    params.email = localStorage.getItem("email");
    params.plant_code = "";
    params.tran_id = "";
    // console.log(params, "params");
    redirectURL
      .post("/master/triggerEmailOnTimeOut", params)
      .then((response) => {})
      .catch(function (error) {
        console.log(error);
        var errDetails = {
          url: "/master/triggerEmailOnTimeOut",
          error,
          screen: "Consolidated run plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  onChangeVehicleFile = (e) => {
    this.setState({
      vehiclesFile: e.target.files[0],
    });
    var check = verifyext(e.target.files[0].name);
    if (check) {
      if (
        e.target.files[0].type == "" ||
        e.target.files[0].type == "text/csv" ||
        e.target.files[0].type == "application/vnd.ms-excel"
      ) {
        const config = {
          headers: [
            {
              name: "Pickup Location",
              inputName: "Pickup Location",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Vehicle No",
              inputName: "Vehicle No",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Vehicle Next Availability Date(DC)",
              inputName: "Vehicle Next Availability Date(DC)",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
              validate: function (date) {
                return availabilityForValidation(date);
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column, format s/b YYYY-MM-DD HH:MM`;
              },
            },
            // {
            //   name: "Assigned To OT",
            //   inputName: "Assigned To OT",
            //   required: true,
            //   requiredError: function (headerName, rowNumber, columnNumber) {
            //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
            //   },
            //   validate: function (value) {
            //     return ["Yes", "No"].includes(value);
            //   },
            //   validateError: function (headerName, rowNumber, columnNumber) {
            //     return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column,s/b 'Yes or No`;
            //   },
            // },
          ],
        };
        CSVFileValidator(e.target.files[0], config)
          .then((csvData) => {
            document.getElementById("inValidDataInfo").innerHTML = "";
            if (csvData.inValidData.length > 0) {
              $("#vehiclesFile").val("");
              document.getElementById("bulkUploadBtn").type = "button";
              document
                .getElementById("bulkUploadBtn")
                .classList.remove("btn-info");
              document
                .getElementById("bulkUploadBtn")
                .classList.add("btn-secondary");
              let invalidData = csvData.inValidData;
              let element = document.getElementById("inValidDataInfo");
              invalidData.map((item) => {
                let row, column;
                if (item.rowIndex == undefined) {
                  row = "NA";
                } else {
                  row = item.rowIndex;
                }
                if (item.columnIndex == undefined) {
                  column = "NA";
                } else {
                  column = item.columnIndex;
                }

                element.innerHTML +=
                  "Column : " +
                  column +
                  "," +
                  " Row : " +
                  row +
                  "--" +
                  item.message +
                  "<br></br>";
              });
              let throwMsg = 0;
              invalidData.map((itm) => {
                if (itm.columnIndex == 10) {
                  throwMsg = 1;
                }
              });
              if (throwMsg) {
                this.setState({
                  uploadFile: "",
                  show: true,
                  basicType: "danger",
                  basicTitle:
                    "Please Input Valid Data and also Ensure Date is in this Format only DD-MM-YYYY (or) DD/MM/YYYY",
                });
              }
            } else {
              document.getElementById("bulkUploadBtn").type = "submit";
              document
                .getElementById("bulkUploadBtn")
                .classList.remove("btn-secondary");
              document
                .getElementById("bulkUploadBtn")
                .classList.add("btn-info");
            }
          })
          .catch((err) => {
            var errDetails = {
              url: "CSVFileValidator",
              err,
              screen: "Run New Plan",
            };
            redirectURL.post("/master/logErrorsForApiCalls", errDetails);
          });
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle: "Please upload file having extensions .csv only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv only.",
      });
    }
  };

  viewOrderDetails = (params) => {
    var reqparams = {};
    reqparams.consolidatedTrnsxId = params.data.consolidatedTrnsxId;
    redirectURL
      .post("master/getConsolTranxDetails", reqparams)
      .then((response) => {
        this.setState({
          inputDetails: response.data,
          showInputSlider: "slide45",
          overly: "show-m",
        });
      });

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on View Input Button",
      event: `Shown ConsolTranxDetails for ${params.data.consolidatedTrnsxId} `,
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  getplandetails = (propdata) => {
    // var data = propdata.data;
    // console.log("here get details", propdata.data);
    if (
      propdata.data.data_processed === 1 ||
      propdata.data.data_processed === 2 ||
      propdata.data.data_processed === 3
    ) {
      if (propdata.colDef.field === "transaction_details") {
        let data_type = this.state.data_type;
        let location = this.state.location.value;
        let qry = {
          dispatch_planning_type: data_type,
          plant_location_code: location,
        };
        localStorage.setItem("qry", JSON.stringify(qry));

        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        // window.location.href = '/viewdispatchplandetails?'+encryptedstring;
        window.open(
          window.location.origin +
            "/viewdispatchplandetails?" +
            encryptedstring,
          "_blank"
        );
        let logParams = {
          location_code: this.state.location.value,
          location_name: this.state.location.label,
          user_name: localStorage.getItem("username"),
          useremail: localStorage.getItem("email"),
          client: localStorage.getItem("client"),
          screen: "Consolidated Run Plan ",
          activity: "clicked on View Details Button",
          event: `Redirected to view dispatch plan page for ${encryptedstring} id `,
          data_type: data_type,
        };
        redirectURL.post("/master/loguserUpdatesorChanges", logParams);
      }
    }
  };
  viewHistoryDetails = async (row) => {
    var viewhistorycolDefs = [
      {
        pinned: "left",
        headerName: "View Details",
        field: "transaction_details",
        width: 120,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          // console.log("params", params.data);
          if (
            params.data.data_processed === 1 ||
            params.data.data_processed === 2 ||
            params.data.data_processed === 3
          ) {
            return <StaticButton />;
          } else {
            return <NAButton />;
          }
        },
      },
      {
        headerName: "Plan Id",
        field: "order_id",
        width: 180,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Generated On",
        field: "createDate",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          if (params.data.createdon !== "") {
            return getHyphenDDMMMYYYYHHMM(params.data.createDate);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Status",
        field: "data_processed",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          let givenDateTime = moment
            .parseZone(params.data.createDate)
            .format("DD-MM-YYYY HH:mm");
          let currentDateTime = moment
            .parseZone(new Date())
            .format("DD-MM-YYYY HH:mm");

          givenDateTime = moment(givenDateTime, "DD-MM-YYYY HH:mm");
          currentDateTime = moment(currentDateTime, "DD-MM-YYYY HH:mm");
          const differenceInMinutes = currentDateTime.diff(
            givenDateTime,
            "minutes"
          );

          const targetTimeDifferenceInMinutes = 35;
          let flag = true;

          if (differenceInMinutes >= targetTimeDifferenceInMinutes) {
            flag = false;
          }
          if (params.data.data_processed == 0) {
            if (flag) {
              return "Processing";
            } else {
              return "Please Contact Admin";
            }
          } else if (params.data.data_processed == 1) {
            return "Completed";
          } else if (params.data.data_processed == 2) {
            return "SOLVER_RECO_PROCESSED";
          } else if (params.data.data_processed == 3) {
            return "SOLVER_SOB_DONE";
          } else if (params.data.data_processed == 4) {
            return "SOLVER_INVALID_INPUT_DEALER_LOCATION";
          } else if (params.data.data_processed == 5) {
            return "SOLVER_INVALID_INPUT_MATERIALS";
          } else if (params.data.data_processed == 6) {
            return "SOLVER_INVALID_INPUT_DEALER_LOCATION_AND_MATERIALS";
          } else if (params.data.data_processed == 7) {
            return "SOLVER_INPUT_DATA_NOT_VALID";
          } else if (params.data.data_processed == 8) {
            return "SOLVER_FIX_DROPED_DEALERS";
          } else if (params.data.data_processed == 9) {
            return "SOLVER_VEHICLE_DETAILS_MISSING";
          } else if (params.data.data_processed == 10) {
            return "SOLVER_INPUT_FILE_DATA_ERROR";
          } else if (params.data.data_processed == 11) {
            return "SOLVER_INVALID_FILE_TEMPLATE";
          } else if (params.data.data_processed == 12) {
            return "PROCESS_TIMED_OUT";
          } else if (params.data.data_processed == 13) {
            return "SOLVER_SOB_FAIL";
          } else if (params.data.data_processed == 14) {
            return "SOLVER_RECO_FAILED";
          } else if (params.data.data_processed == 15) {
            return "SOLVER_NO_TOOL_CONFIG";
          } else if (params.data.data_processed == 16) {
            return "SOLVER_INVALID_TOOL_CONFIG";
          } else {
            return "Please Contact Admin";
          }
        },
      },
      {
        headerName: "Optimization Type",
        field: "opti_type",
        width: 200,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          if (
            params.data.run_with_transit === 1 ||
            params.data.run_with_transit === "1"
          ) {
            return "Transit Time With Multiple City Clubing";
          } else if (
            params.data.run_with_RDD === 1 ||
            params.data.run_with_RDD === "1"
          ) {
            return "RDD Preference";
          } else if (
            params.data.run_with_transit_cityclub === 1 ||
            params.data.run_with_transit_cityclub === "1"
          ) {
            return "Transit Time With Single City Clubing";
          }
        },
      },
      // {
      //   headerName: "No. of Trucks Planned",
      //   field: "no_of_vehicles_planned",
      //   width: 160,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      // {
      //   headerName: "Total Planned Volume (M3)",
      //   field: "planned_load_volume",
      //   width: 200,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     if (params.data.planned_load_volume) {
      //       return params.data.planned_load_volume.toFixed(2);
      //     }
      //   },
      // },
      // {
      //   headerName: "Total Planned Weight (Kgs)",
      //   field: "planned_load_weight",
      //   width: 200,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     if (params.data.planned_load_weight) {
      //       return params.data.planned_load_weight.toFixed(2);
      //     }
      //   },
      // },
      {
        headerName: "Plan Run By",
        field: "useremail",
        width: 170,
        filter: true,
        resizable: true,
        editable: false,
      },
    ];
    await this.setState({
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: viewhistorycolDefs,
          overlayNoRowsTemplate: "No rows to show",
          // defaultColDef: this.state.defaultColDef,
          // statusBar: this.state.statusBar,
          // sideBar: this.state.sideBar,
          onCellClicked: this.getplandetails,
          height: 100,
          // enableRangeSelection: true,
        },
        getDetailRowData: async function (param) {
          param.successCallback([]);
          var reqparams = {
            consolidatedTrnsxId: param.data.consolidatedTrnsxId,
          };
          await redirectURL
            .post("/master/getconsolidatedplanhistory", reqparams)
            .then(async (response) => {
              param.successCallback(response.data);
            })
            .catch(function (error) {
              console.log(error);
              var errDetails = {
                url: "/master/getconsolidatedplanhistory",
                error,
                screen: "Consolidated run plan",
              };
              redirectURL.post("/master/logErrorsForApiCalls", errDetails);
            });
        },
        masterDetail: true,
      },
    });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on Plan History Button",
      event: `Opened Sub Grid to show Plan ${row.data.consolidatedTrnsxId} History`,
      data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    if (row.colDef.field == "run_history") {
      row.node.setExpanded(!row.node.expanded);
    } else {
      row.node.setExpanded(false);
    }
  };

  downloadOutput = (params) => {
    var reqparams = {};
    reqparams.consolidatedTrnsxId = params.data.consolidatedTrnsxId;
    redirectURL
      .post("master/getConsoliOutputDetls", reqparams)
      .then((response) => {
        var conslOutput = response.data.sort(GetSortDescOrder("pickup_node"));
        const data = conslOutput.map((item) => {
          return {
            "Trip No": item.trip_no,
            "Pickup Location": item.pickup_node,
            "Drop Sequence": item.drop_sequence,
            "Vehicle Type": item.vehicle_type,
            "Order Number": item.order_number,
            "Vehicle No": item.vehicle_no,
            "Invoicing Date": item.invoicing_date,
            Quantity: item.quantity,
            "Drop Location Code (Destination)": item.drop_node,
            "Drop Location Name (Destination)": item.drop_node_name,
            "Destination Location City": item.demand_node_location,
            "Volume (M³)": item.demand_volume,
            "Weight (Kgs)": item.demand_weight,
            "Planned Day": item.drop_node_total_time_day,
            "Max Dispatch Time (HH:MM)": item.vehicle_start_time_mins,
            "Distance To Destination (Kms)": item.distance,
            "Time To Destination": item.drop_node_total_time_hrs,
            "Delivery Date": item.delivery_date,
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");

        const dataBlob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileName = `output_data_${params.data.consolidatedTrnsxId}.xlsx`;
        const downloadLink = document.createElement("a");

        downloadLink.href = window.URL.createObjectURL(dataBlob);
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch(function (error) {
        console.log(error);

        var errDetails = {
          url: "/master/getConsoliOutputDetls",
          error,
          screen: "Consolidated run plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on Download Button",
      event: `Downloaded ${params.data.consolidatedTrnsxId} Plan data`,
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  // onGridReady = (params) => {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;
  // };
  // onGridState = () => {
  //   this.colState = this.gridColumnApi.getColumnState();
  //   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
  //   this.pivotColumns = this.gridColumnApi.getPivotColumns();
  //   window.filterState = this.gridApi.getFilterModel();
  //   this.gridApi.setFilterModel(window.filterState);
  // };

  runcounterforpendingtransx = (data) => {
    // console.log(this.updateplanintervalId);
    if (this.updateplanintervalId != undefined) {
      clearInterval(this.updateplanintervalId);
    }
    let allconsolidatedTrnsxId = [];
    data.map((itm) => {
      if (itm.execution_status == 0 || itm.execution_status == 2) {
        let givenDateTime = moment
          .parseZone(itm.createDate)
          .format("DD-MM-YYYY HH:mm");
        let currentDateTime = moment
          .parseZone(new Date())
          .format("DD-MM-YYYY HH:mm");

        givenDateTime = moment(givenDateTime, "DD-MM-YYYY HH:mm");
        currentDateTime = moment(currentDateTime, "DD-MM-YYYY HH:mm");
        const differenceInMinutes = currentDateTime.diff(
          givenDateTime,
          "minutes"
        );

        const targetTimeDifferenceInMinutes = 25;
        let flag = true;

        if (differenceInMinutes >= targetTimeDifferenceInMinutes) {
          flag = false;
        }

        if (differenceInMinutes <= 8) {
          if (localStorage.getItem(itm.consolidatedTrnsxId) != 0 && flag) {
            allconsolidatedTrnsxId.push({
              id: itm.consolidatedTrnsxId,
              duration: (8 - differenceInMinutes) * 60,
            });
          }
        } else {
          if (localStorage.getItem(itm.consolidatedTrnsxId) != 0 && flag) {
            allconsolidatedTrnsxId.push({
              id: itm.consolidatedTrnsxId,
              duration: 8 * 60,
            });
          }
        }

        if (localStorage.getItem(itm.consolidatedTrnsxId) == 0) {
          localStorage.removeItem(itm.consolidatedTrnsxId);
        }
      } else {
        localStorage.removeItem(itm.consolidatedTrnsxId);
      }
    });
    this.updatePlanDetails();
    if (allconsolidatedTrnsxId.length == 0) {
      clearInterval(this.updateplanintervalId);
    }
    this.setState({ allconsolidatedTrnsxId: allconsolidatedTrnsxId });
  };

  handlesundayplanning = () => {
    if (this.state.is_sunday_planning_enabled == 1) {
      this.setState({ is_sunday_planning_enabled: 0 });
    } else {
      this.setState({ is_sunday_planning_enabled: 1 });
    }
  };

  boundTypeLocations = async (param) => {
    var plantCodes = [];
    await redirectURL
      .post("master/getBoundTypeLocations", { data_type: 2 })
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              plantCodes.push(item.location_name);
            });
            await this.setState({
              plantCodes,
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  onChangeSimuTime = (event) => {
    var simulationTime = getHyphenYYYYMMDDHHMMSS(event.target.value);
    // console.log(event.target.value,'hello',simulationTime)
    this.setState({ simulationTime });
    this.enablesundayplanning();
  };

  onclickUploadFileButton = () => {
    $("#uploadFile").val("");
    let logParams = {
      // location_code: this.state.location.value,
      // location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan",
      activity: "clicked on Upload File Option in the slide",
      event: "File Upload slide opened",
      // data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onClickDownloadSample = () => {
    let logParams = {
      // "location_code" : this.state.location.value,
      // "location_name" : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Run New Plan",
      activity: "clicked on Download Sample Data Button in the slider",
      event: "Sample template has been Downloaded",
      // "data_type" : this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    // const hours = String(now.getHours()).padStart(2, "0");
    // const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  onClickGetData = async () => {
    if (this.state.fromDate > this.state.toDate) {
      this.setState({
        show: true,
        basicTitle: "From Date should be less than To Date",
        basicType: "danger",
      });
      return;
    }
    await this.getConsolidatedPlansData();
  };
  onChangeDateHandle = (event) => {
    // console.log("first", event.target.value, event.target.name);
    var name = event.target.name,
      value = event.target.value;
    this.setState({ [name]: value });
  };
  componentDidUpdate() {
    this.removeDatepickerClass();
  }
  removeDatepickerClass() {
    var toDateElement = document.getElementById("toDate");
    var fromDateElement = document.getElementById("fromDate");
    if (toDateElement) {
      // console.log("hit heere");
      toDateElement.classList.remove("hasDatepicker");
      if (window.jQuery && toDateElement.datepicker) {
        window.jQuery(toDateElement).datepicker("destroy");
      }
    }
    if (fromDateElement) {
      fromDateElement.classList.remove("hasDatepicker");
      if (window.jQuery && fromDateElement.datepicker) {
        window.jQuery(fromDateElement).datepicker("destroy");
      }
    }
    const datepickerDiv = document.getElementById("ui-datepicker-div");
    if (datepickerDiv) {
      datepickerDiv.parentNode.removeChild(datepickerDiv);
      // console.log("Datepicker div removed");
    } else {
      // console.log("No datepicker div found");
    }
  }

  uploadOrderInputData = (e) =>{
    e.preventDefault();
    let data = this.state.loadFile
    let module_type = 1;
    let data_type = 2;
    let useremail = localStorage.getItem("email");
    let client = localStorage.getItem("client")
    // let params = new FormData();
    let cleandata = trimValues(data)
    let params = {
      loadFile:cleandata,
      module_type,data_type,useremail,client
    }
    redirectURL.post("/mlapis/storeObdRawData",params)
    .then(async res =>{
      if (res.data.status === 1) {
        await this.setState({
          showSlideBlockUpload: "show-n",
          basicTitle: "File Uploaded Successfully",
          basicType: "success",
          show: true,
          systemPlanning: 1,
          manualPlanning: 0,
          loadFile: "",
          vehiclesFile: "",
          vehicleDataDownld: false,
          loadshow: "show-n",
          overly: "show-n",
        });
      } else {
        this.setState({
          showSlideBlockUpload: "show-n",
          basicTitle: "File Upload Failed",
          basicType: "danger",
          show: true,
          systemPlanning: 1,
          manualPlanning: 0,
          loadFile: "",
          vehiclesFile: "",
          vehicleDataDownld: false,
          loadshow: "show-n",
          overly: "show-n",
        });
      }
    })
  }

  render() {
    var templatePath = require("../../assets/json/cpil_new_input_data_template.csv");
    var colDefs = [
      {
        headerName: "Plan History",
        field: "run_history",
        width: 150,
        params: {
          buttonName: "View",
          iconName: "fa fa-eye",
          buttonCls: "btn btn-danger",
          onClickFunction: this.viewHistoryDetails,
        },
        cellRenderer: GridButton,
      },
      {
        headerName: "View Input",
        width: 140,
        params: {
          buttonName: "View",
          iconName: "fa fa-eye",
          buttonCls: "btn btn-info",
          onClickFunction: this.viewOrderDetails,
        },
        cellRenderer:GridButton,
      },
      {
        headerName: "Consolidation ID",
        field: "consolidatedTrnsxId",
        width: 180,
      },
      {
        headerName: "Created Date",
        field: "createDate",
        width: 130,
        valueGetter: (params) => {
          if (
            params.data.createDate !== "" &&
            params.data.createDate !== undefined
          ) {
            return getHyphenDDMMMYYYYHHMM(params.data.createDate);
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "Run By",
        field: "useremail",
        width: 150,
      },
      {
        headerName: "No of Executions",
        headerTooltip: "No of Executions",
        // field: "useremail",
        width: 150,
        valueGetter: (params) => {
          // console.log("first", params.data);
          return Object.keys(params.data.plant_wise_executions).length;
        },
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "Execution Status",
        field: "execution_status",
        width: 160,
        valueGetter: (params) => {
          if (
            params.data.execution_status !== "" &&
            params.data.execution_status !== undefined
          ) {
            if (params.data.execution_status == 1) {
              return "Completed";
            } else if (params.data.execution_status == 0) {
              const referenceTimeUTC = new Date(params.data.createDate);
              const currentTimeUTC = new Date();
              const utcOffsetInMillis = 5.6 * 60 * 60 * 1000;
              const durationInMillis = 20 * 60 * 1000;

              const timeExceedsUTC = new Date(
                referenceTimeUTC.getTime() - utcOffsetInMillis
              );
              // console.log(timeExceedsUTC,'run',currentTimeUTC)
              const timeDifference = currentTimeUTC - timeExceedsUTC;
              // console.log(timeDifference,durationInMillis)
              if (timeDifference > durationInMillis) {
                return "Please contact admin";
              }
              return "Processing";
            } else if (params.data.execution_status == 2) {
              return "Partially Executed";
            } else {
              return "N/A";
            }
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "Download Output",
        // field:'useremail',
        width: 150,
        params: {
          buttonName: "Download",
          iconName: "fa fa-download",
          buttonCls: "btn btn-danger",
          onClickFunction: this.downloadOutput,
        },
        cellRenderer:GridButton,
      },
    ];
    var inputDetailscolsDefs = [
      {
        headerName: "Pick Up Location (Source)",
        field: "pickup_location_code",
        width: "150",
        // valueGetter: (params) => {
        //   console.log(params.data, "params");
        // },
      },
      {
        headerName: "Drop Location Code (Destination)",
        field: "drop_location_code",
        width: "150",
      },
      {
        headerName: "Drop Location Name (Destination)",
        field: "drop_location_name",
        width: "200",
      },
      {
        headerName: "Order Number",
        field: "order_number",
        width: "100",
      },
      {
        headerName: "Invoicing Date",
        field: "invoicing_date",
        width: "120",
      },
      {
        headerName: "Quantity",
        field: "quantity",
        width: "100",
      },
      {
        headerName: "Demand Volume (M³)",
        field: "demand_cmt",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Weight (Kgs)",
        field: "weight_tons",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Dealer Available From",
        field: "dealer_available_start",
        width: "110",
      },
      {
        headerName: "Dealer Available To",
        field: "dealer_available_end",
        width: "120",
      },
      {
        headerName: "Delivery Date",
        field: "delivery_date",
        width: "120",
      },
    ];
    var userCheck =
      localStorage.getItem("role") === "admin" ? "show-m" : "show-n";
    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <div className="col-sm-12 ">
          <h5 className="fbold  d-flex justify-content-between">
            <span>Consolidated Run Plan Screen</span>
          </h5>
        </div>
        <div className="col-md-12 ml-0 mb-0 pb-0">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group ">
                <label htmlFor="fromDate" className="col-form-label f12">
                  From Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="fromDate"
                  id="fromDate"
                  autoComplete="off"
                  onKeyDown={(e) => e.preventDefault()}
                  max={this.getCurrentDateTime()}
                  onChange={this.onChangeDateHandle}
                  onClick={this.removeDatepickerClass}
                  value={this.state.fromDate}
                  // readonly
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group ">
                <label htmlFor="toDate" className="col-form-label f12">
                  To Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="toDate"
                  id="toDate"
                  autoComplete="off"
                  onKeyDown={(e) => e.preventDefault()}
                  max={this.getCurrentDateTime()}
                  onChange={this.onChangeDateHandle}
                  value={this.state.toDate}
                  onClick={this.removeDatepickerClass}
                  // readonly
                />
              </div>
            </div>
            <div className="col-md-2">
              <button
                type="button"
                onClick={this.onClickGetData}
                className="btn btn-success mt-30px f12"
                style={{ marginTop: "35px" }}
              >
                Get Data
              </button>
            </div>
            <div className="col-md-2 float-right">
              {this.state.allconsolidatedTrnsxId.length > 0 ? (
                <CountDownTimer
                  allTransactionIds={this.state.allconsolidatedTrnsxId}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* <div className="col-sm-12 float-right mt-0 pt-0">
          <button
            onClick={this.onClickUploadFile}
            className="btn-warning float-right"
            style={{ fontWeight: "bold", borderRadius: "2px" }}
          >
            Upload Input File
          </button>
        </div> */}
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Recent Plans</h5>
            </div>
            <div class="card-body p-15px">
              <div className={"mxheit col-sm-12 "}>
                <div
                  id="myGrid1"
                  style={{ width: "100%", height: "80vh" }}
                  className={"ag-theme-balham"}
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    columnDefs={colDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.conPlanData}
                    enableCharts={false}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus={true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                      context: { componentParent: this },
                    }}
                    enableRangeSelection={true}
                    enableCellContextMenu={true}
                    // allowContextMenuWithControlKey={true}
                    suppressContextMenu={false}
                    masterDetail={true}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    rowClassRules={this.state.rowClassRules}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"loader " + this.state.loadshow}></div>
        <div
          style={{ color: "black" }}
          className={"sliderBlock2 " + this.state.showSlideBlockUpload}
        >
          <h5 className="crd-bg p-10p">Upload Input File</h5>
          <div className="row">
            <form onSubmit={this.uploadOrderInputData}>
              <div className="col-sm-12">
                <div className="form-group ml-2">
                  <a
                    className="btn btn-warning"
                    href={templatePath}
                    target="_blank"
                    onClick={this.onClickDownloadSample}
                  >
                    Download Sample Template
                  </a>
                </div>

                <div className="form-group col-sm-12 mb-10p">
                  <label style={{ color: "#000" }}>Upload File</label>
                  <input
                    type="file"
                    name="uploadFile"
                    id="uploadFile"
                    onChange={this.changeFileHandler}
                    className="form-control"
                    onClick={this.onclickUploadFileButton}
                    required
                  />
                </div>

                <div className="form-group col-sm-12 note-font">
                  Disclaimer : Date Format for D.o Date s/b DD-MM-YYYY
                </div>
                {/* <div className={"form-group col-sm-12 mb-20p " + userCheck}>
                  <div>
                    <label>
                      Simulation Time: &nbsp;
                      <input
                        type="datetime-local"
                        value={this.state.simulationTime}
                        onChange={this.onChangeSimuTime}
                      />
                    </label>
                  </div>
                </div> */}
                {/* {this.state.loadFile !== "" ? (
                  <div className="form-group col-sm-12 mb-20p ">
                    <h6>Vehicle Availability</h6>
                    <div className="d-flex align-items-center  ml-3">
                      <input
                        type="radio"
                        id="systemPlanning"
                        name="planningtype"
                        onChange={this.onClickSystemPlanning}
                        value={this.state.systemPlanning}
                        style={{ marginRight: "5px" }}
                        checked={this.state.systemPlanning == 1}
                      />
                      <label style={{ margin: "0" }} htmlFor="systemPlanning">
                        &nbsp;Current Vehicles Availability
                      </label>
                    </div>
                    <div className="d-flex align-items-center ml-3">
                      <input
                        type="radio"
                        id="manualPlanning"
                        name="planningtype"
                        value={this.state.manualPlanning}
                        style={{ marginRight: "5px" }}
                        onChange={this.onClickManualPlanning}
                        checked={this.state.manualPlanning == 1}
                      />
                      <label style={{ margin: "0" }} htmlFor="manualPlanning">
                        &nbsp;Manual Vehicle Availability
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.manualPlanning == 1 ? (
                  <div>
                    <button
                      type="button"
                      className="btn btn-danger ml-10p"
                      onClick={this.onClickDowloadFile}
                    >
                      <i className="fa fa-download"></i> Download Vehicles Data
                    </button>
                    <div className="form-group col-sm-12 mb-10p mt-10p">
                      <label style={{ color: "#000" }}>
                        Upload Vehicles Data{" "}
                        <span style={{ color: "red" }}>
                          *File Upload will be enabled after vehicle data file
                          downloaded
                        </span>
                      </label>
                      <input
                        type="file"
                        name="uploadFile"
                        id="vehiclesFile"
                        onChange={this.onChangeVehicleFile}
                        className="form-control"
                        disabled={!this.state.vehicleDataDownld}
                        required
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </div>

              <div className="form-group col-sm-12 mb-20p ml-3 mt-40p">
                {/* {this.state.enable_sunday_planning===true?<> */}
                {/* <input
                  className="ml-2 mr-0"
                  type="checkbox"
                  id="sundayplanning"
                  checked={
                    this.state.is_sunday_planning_enabled === 1 ? true : false
                  }
                  onClick={this.handlesundayplanning}
                  name="is_sunday_planning_enabled"
                  value={this.state.is_sunday_planning_enabled}
                  disabled={!this.state.enable_sunday_planning}
                />
                <label className="" style={{ margin: "0px" }} for="css">
                  &nbsp;&nbsp;Enable DC Week Off planning &nbsp;&nbsp;
                </label> */}
                {/* </>:''} */}
                <div className="mt-10p">
                  <button
                    // disabled
                    type="submit"
                    id="bulkUploadBtn"
                    className="btn btn-info"
                  >
                    RUN PLAN
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.hideSlideBlock.bind(this)}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
              <div
                id="inValidDataInfo"
                className="col-sm-12 pl-15p"
                style={{ color: "red" }}
              ></div>
            </form>
          </div>
        </div>
        <div className={"sliderBlock2 " + this.state.showInputSlider}>
          <h5 className="crd-bg p-10p">Input Details</h5>
          <div className="col-sm-12">
            <div
              id="myGrid2"
              style={{ width: "100%", height: "80vh" }}
              className="col-sm-12 ag-theme-balham dropdown"
            >
              <AgGridReact
                // modules={this.state.modules}
                rowData={this.state.inputDetails}
                columnDefs={inputDetailscolsDefs}
                gridOptions={{ context: { componentParent: this } }}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                enableRangeSelection={true}
              />
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.hideSlideBlock.bind(this)}
        ></div>
      </>
    );
  }
}

function isDateFormatValid(date) {
  const dateParts = date.split("-");
  if (dateParts.length !== 3) {
    return false;
  }

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  const formattedDate = new Date(year, month - 1, day);

  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

// function availabilityForValidation(time) {
//   var [date, time] = time.split(" ");
//   // console.log(date, "datae",time);
//   // var timeparts=time.split(':')
//   const dateParts = date.split(/[-/]/);
//   if (dateParts.length !== 3) {
//     return false;
//   }

//   const day = parseInt(dateParts[0], 10);
//   const month = parseInt(dateParts[1], 10);

//   const year = parseInt(dateParts[2], 10);
//   if (isNaN(day) || isNaN(month) || isNaN(year)) {
//     return false;
//   }

//   const formattedDate = new Date(year, month - 1, day);

//   if (
//     formattedDate.getDate() !== day ||
//     formattedDate.getMonth() !== month - 1 ||
//     formattedDate.getFullYear() !== year
//   ) {
//     return false;
//   }

//   return true;
// }

function availabilityForValidation(time) {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
  if (!regex.test(time)) {
    return false; // Invalid format
  }

  const [dateStr, timeStr] = time.split(" ");
  const [year, month, day] = dateStr.split("-");
  const [hour, minute] = timeStr.split(":");

  // Convert parts to integers and validate
  const parsedYear = parseInt(year, 10);
  const parsedMonth = parseInt(month, 10);
  const parsedDay = parseInt(day, 10);
  const parsedHour = parseInt(hour, 10);
  const parsedMinute = parseInt(minute, 10);

  if (
    isNaN(parsedYear) ||
    isNaN(parsedMonth) ||
    isNaN(parsedDay) ||
    isNaN(parsedHour) ||
    isNaN(parsedMinute) ||
    parsedMonth < 1 ||
    parsedMonth > 12 ||
    parsedDay < 1 ||
    parsedDay > 31 ||
    parsedHour < 0 ||
    parsedHour > 23 ||
    parsedMinute < 0 ||
    parsedMinute > 59
  ) {
    return false; // Invalid date or time values
  }

  // Validate the date using a Date object
  const formattedDate = new Date(
    parsedYear,
    parsedMonth - 1,
    parsedDay,
    parsedHour,
    parsedMinute
  );
  return (
    formattedDate.getFullYear() === parsedYear &&
    formattedDate.getMonth() === parsedMonth - 1 &&
    formattedDate.getDate() === parsedDay &&
    formattedDate.getHours() === parsedHour &&
    formattedDate.getMinutes() === parsedMinute
  );
}

function isValidWeight(weight) {
  if (weight.includes(",")) {
    return false;
  } else if (parseFloat(weight) > 50000) {
    return false;
  } else {
    return true;
  }
}

function GetSortDescOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

function isDateFormatValidforXLSX(date) {
  // console.log(date, 'date');
  let dateParts = date.split("/");

  if (dateParts.length !== 3) {
    return false;
  }

  var month = parseInt(dateParts[0], 10);
  var day = parseInt(dateParts[1], 10);
  var year = parseInt(dateParts[2], 10);

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return false;
  }

  // Adjust the year if it's two digits
  if (year >= 0 && year < 100) {
    // Assuming if the year is less than 50, it belongs to the 21st century, otherwise 20th century
    year += year < 50 ? 2000 : 1900;
  }

  var formattedDate = new Date(year, month - 1, day);
  // console.log(formattedDate,'formated date')
  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

function isDropCodeValid(code, plantCodes) {
  if (plantCodes.includes(code)) {
    return false;
  } else {
    return true;
  }
}

function isPlantValid(code, plantCodes) {
  if (plantCodes.includes(code)) {
    return true;
  } else {
    return false;
  }
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function trimValues(arr){
  return arr.map(obj => {
    const trimmedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        trimmedObj[key] = obj[key].trim();
      }
    }
    return trimmedObj;
  });
};