import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './GroupIconRenderer.css';

const GroupIconRenderer = (props) => {
    const [visibleGroupID, setVisibleGroupID] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const iconRef = useRef(null);
    const tooltipRef = useRef(null);

    const style = {
        transit_delay: "#000",
        no_gps_data: "#cccc",
        enroute_exception: "#cccc",
        overspeeding_exception: "#cccc",
        nightdriving_exception:"#ccc",
        loading_delay_exception: "#cccc",
        unloading_delay_exception: "#cccc",
        consignment: "#cccc",
        tpt_deviation_status:"#cccc"
    };

    // Update styles based on props.data
    if (props?.data?.transit_delay === 1) {
        style.transit_delay = "#FF5370";
        style.consignment = "#FF5370";
    }
    if (props?.data?.no_gps_data === 1) {
        style.no_gps_data = "#FF5370";
        style.consignment = "#FF5370";
    } else if (props?.data?.no_gps_data === 0) {
        style.no_gps_data = "#26c6da";
        style.consignment = "#FF5370";
    }
    if (props?.data?.enroute_exception === 1) {
        style.enroute_exception = "#a34518";
        style.consignment = "#FF5370";
    }
    if (props?.data?.overspeeding_exception === 1) {
        style.overspeeding_exception = "#3300c2";
        style.consignment = "#FF5370";
    }
    if (props?.data?.loading_delay_exception === 1) {
        style.loading_delay_exception = "#d3a337";
        style.consignment = "#FF5370";
    }
    if (props?.data?.unloading_delay_exception === 1) {
        style.unloading_delay_exception = "#7ad337";
        style.consignment = "#FF5370";
    }
    
    if (props?.data?.nightdriving_exception === 1) {
        style.nightdriving_exception = "#7ad337";
        style.consignment = "#FF5370";
    }
    if (props?.data?.trip_deviation === 1) {
        style.tpt_deviation_status = "#ff1a40";
        style.consignment = "#FF5370";
    }
    

    const handleGroupIconClick = (divID) => {
       props.node.setExpanded(false)
        if (divID !== null && divID !== undefined) {
            const iconPosition = document.getElementById("rw_" + divID);

            if (!iconPosition) {
                console.error("Element with ID 'rw_" + divID + "' not found");
                return;
            }

            const rect = iconPosition.getBoundingClientRect();

            setTooltipPosition({
                top: rect.bottom + window.scrollY, // Adjusted for better positioning
                left: rect.left + window.scrollX
            });

            setVisibleGroupID((prevID) => (prevID === divID ? null : divID));
        }
    };

    const handleClick = (e) => {
        e.stopPropagation()
        console.log('Transit Delay icon clicked');
        // console.log('props:', props);    
        props.onShowTransitData(props);
        setVisibleGroupID(null);
    };

    const handleGPSClick = (e) => {
        e.stopPropagation()
        console.log('No GPS Data icon clicked');
        props.onShowNoGPSData(props);
        setVisibleGroupID(null);
    };

    const handleOverspeed = (e) => {
        e.stopPropagation()
        console.log('Overspeeding icon clicked');
        props.onShowOverspeedingData(props);
        setVisibleGroupID(null);
    };

    const handleStoppage = (e) => {
        e.stopPropagation()
        console.log('Stoppages icon clicked');
        props.onShowStoppagesData(props);
        setVisibleGroupID(null);
    };

    const handleNightDriving = (e) => {
        e.stopPropagation()
        console.log('Night Driving icon clicked');
        props.onShowNightDrivingData(props);
        setVisibleGroupID(null);
    }

    const handleLoading = (e) => {
        e.stopPropagation()
        console.log('Loading Delay icon clicked');
        props.onShowLoadingData(props);
        setVisibleGroupID(null);
    };

    const handleUnloading = (e) => {
        e.stopPropagation()
        console.log('Unloading Delay icon clicked');
        props.onShowUnloadingData(props);
        setVisibleGroupID(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (iconRef.current && 
                !iconRef.current.contains(event.target) &&
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target)) {
                setVisibleGroupID(null); // Close the tooltip if clicking outside
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="icon-container" ref={iconRef} id={"rw_" + props.node.rowIndex}>
            <span className="group-icon" title='Exception Flags' onClick={() => handleGroupIconClick(props.node.rowIndex)}>
                <i className="fa fa-cubes" style={{ color: style.consignment }}></i>
            </span>
            {visibleGroupID === props.node.rowIndex && ReactDOM.createPortal(
                <div 
                    className="tooltip-icons" 
                    style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
                    ref={tooltipRef}  
                    onClick={(e) => e.stopPropagation()} // Prevent clicks inside tooltip from closing it
                >
                    <span title='Transit Delay' onClick={handleClick}>
                        <i className="icofont icofont-clock-time f20" style={{ color: style.transit_delay }}></i>
                    </span>
                    <span title='No GPS Data' onClick={handleGPSClick}>
                        <i className="icofont icofont-social-google-map f20" style={{ color: style.no_gps_data }}></i>
                    </span>
                    <span title='Overspeeding' onClick={handleOverspeed}>
                        <i className="fa fa-tachometer f20" style={{ color: style.overspeeding_exception }}></i>
                    </span>
                    <span title='NightDriving' onClick={handleNightDriving}>
                        <i className="icofont icofont-full-night f20" style={{ color: style.nightdriving_exception }}></i>
                    </span>
                    <span title='Stoppages' onClick={handleStoppage}>
                        <i className="icofont icofont-pause f20" style={{ color: style.enroute_exception }}></i>
                    </span>
                    <span title='Loading Delay' onClick={handleLoading}>
                        <i className="icofont icofont-truck-alt f20" style={{ color: style.loading_delay_exception }}></i>
                    </span>
                    {!(props.data?.status === 1 || props.data?.status === 2 || props.data?.status === 3 || props.data?.status === 4) && (
                        <span title='Unloading Delay' onClick={handleUnloading}>
                            <i className="icofont icofont-truck-loaded f20" style={{ color: style.unloading_delay_exception }}></i>
                        </span>
                    )}
                    <span title='Trip Deviation' onClick={handleLoading}>
                        {/* {console.log("style ",style)} */}
                        <i className="icofont icofont-map-pins f20" style={{ color: style.tpt_deviation_status }}></i>
                    </span>
                </div>,
                document.body
            )}
        </div>
    );
};

export default GroupIconRenderer;


