import React from "react";
import Modal from "react-responsive-modal";

const DocumentPreviewCard = (props) => { 

  return (
    <Modal open={props.openModal} onClose={props.closeModal}>
      <div aria-disabled={true}>
        <h6
          className="bg-primary text-white"
          style={{
            paddingTop: "10px",
            height: "38px",
            paddingLeft: "253px",
          }}
        >
          File Preview
        </h6>
        {props.documentType === "pdf" ? (
          <div className="p-3">
            <iframe
              src={`https://docs.google.com/viewer?url=${props.documentUrl}&embedded=true`}
              frameborder="1"
              height="500px"
              width="100%"
            ></iframe>
          </div>
        ) : (
          <></>
        )}

        {props.documentType === "csv" || props.documentType === "xlsx" ? (
          <div className="p-3">
            <iframe
              src={`https://docs.google.com/viewer?url=${props.documentUrl}&embedded=true`}
              frameborder="1"
              height="500px"
              width="100%"
            ></iframe>
          </div>
        ) : (
          <></>
        )}

        {props.documentType === "png" ||
        props.documentType === "jpeg" ||
        props.documentType === "jpg" ? (
          <div className="p-3">
            <img
              src={props.documentUrl}
              // frameborder="1"
              height="500px"
              width="100%"
            ></img>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default DocumentPreviewCard;
