import React,{Component} from "react";
import Constant from "../common/constants";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import getToolConfiguration from "./commonFunctions";
import VehiclesAvailabilityMaster from './vehicleAvailabilityMaster';
import VehiclesAvailability from './vehicleTimings';
import VehiclesLocation from './vehicleLocation';
import VehicleDistaces from './vehicleDistaces'



export default class VehicleAvailabilityDetails extends Component{
    constructor(props){
        super(props);
        this.state={
            locationNames:[],
            location:'',
            vehicleMasterTab:"",
            vehicleAvailabilityTab : "",
            vehicleDistanceTab:'',
            vehicleMasterData: false,
            vehicleAvailabilityData:false,
            vehicleLocationTab:"activet",
            vehicleLocationData:true,
            loadshow:'show-m',
            vehicleDistanceData:false,
        }
        
    }

    async componentDidMount(){
        this.setState({loadshow:'show-m'})
        var gps_availability= 0;

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                gps_availability = toolConfig[0].gps_availability;
            }
        }
        if(gps_availability === 1)
        {
            var vehicleLocationTab = "activet";
            var vehicleLocationData = true;
            this.setState({
                vehicleLocationTab:vehicleLocationTab,
                vehicleLocationData:vehicleLocationData
            })
        }
        else{
            var vehicleMasterTab = "activet";
            var vehicleMasterData = true;
            this.setState({
                vehicleMasterTab:vehicleMasterTab,
                vehicleMasterData:vehicleMasterData
            })
        }

        var curl = window.location.search;
        var ul= curl.split("?");
        let url_type = ul[parseInt(ul.length)-1];
        let data_type
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})

        let params = {
            data_type : data_type
        }
        let toolparameters = {
            data_type : data_type
        }
        await getToolConfiguration(toolparameters)
        await this.boundTypeLocations(params)
    }

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = [];

        if(location_access == null){
            locationNames.push({label:'ALL',value:''})
        }
        
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      
                      if(locationNames.length>0){
                        await this.setState({
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                  }
              }
              this.setState({loadshow:'show-n'})
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'Vehicle Availability Configurations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
          
      }

    locationChanged =async (location) =>{
        // console.log(location, "location changed to")
        this.setState({
            location : location
        }, ()=>{
            getToolConfiguration({plant_code:location.value})
        })
    }


    onClickTab = (pageType) => {
        // console.log(pageType,'helo')
        if(pageType == "vehicleLocationData")
        {
            var vehicleLocationTab = "activet";
            var vehicleMasterTab = "";
            var vehicleAvailabilityTab = "";
            var vehicleDistanceTab='';
            var vehicleLocationData = true;
            var vehicleMasterData = false;
            var vehicleAvailabilityData = false;
            var vehicleDistanceData = false;
            var activity = "Clicked on vehicle Locations tab";
            var action = "Navigated to vehicle locations tab";
            
        }
        if(pageType == "vehicleMasterData")
        {
            var vehicleMasterTab = "activet";
            var vehicleLocationTab = "";
            var vehicleAvailabilityTab = "";
            var vehicleDistanceTab='';
            var vehicleMasterData = true;
            var vehicleLocationData = false;
            var vehicleAvailabilityData = false;
            var vehicleDistanceData = false;
            var activity = "Clicked on vehicle Master tab";
            var action = "Navigated to vehicle Master tab"

        }
        else if(pageType == "vehicleAvailabilityData")
        {
            var vehicleMasterTab = "";
            var vehicleAvailabilityTab = "activet";
            var vehicleDistanceTab='';
            var vehicleLocationTab = "";
            var vehicleMasterData = false;
            var vehicleLocationData = false;
            var vehicleAvailabilityData = true;
            var vehicleDistanceData = false;
            var activity = "Clicked on vehicle Availability tab";
            var action = "Navigated to vehicle Availability tab";

        }else if(pageType == "vehicleDistanceData")
        {
            var vehicleMasterTab = "";
            var vehicleAvailabilityTab = "";
            var vehicleLocationTab = "";
            var vehicleDistanceTab='activet';
            var vehicleAvailabilityData = false;
            var vehicleMasterData = false;
            var vehicleLocationData = false;
            var vehicleDistanceData = true;
            var activity = "Clicked on vehicle Distances tab";
            var action = "Navigated to vehicle Distances tab";

        }
        this.setState({
            vehicleLocationTab:vehicleLocationTab,
            vehicleMasterTab:vehicleMasterTab,
            vehicleAvailabilityTab:vehicleAvailabilityTab,
            vehicleMasterData:vehicleMasterData,
            vehicleLocationData:vehicleLocationData,
            vehicleAvailabilityData:vehicleAvailabilityData,
            vehicleDistanceData:vehicleDistanceData,
            vehicleDistanceTab            
        })

        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Consolidated Run Plan ",
            "activity" : activity,
            "event" : action,
            "data_type" : this.state.data_type,
        }
      
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }


    render(){
        var gps_availability= 0;

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                gps_availability = toolConfig[0].gps_availability;
            }
        }


        const {location,locationNames,vehicleMasterTab,vehicleAvailabilityTab,vehicleMasterData,vehicleAvailabilityData,vehicleLocationTab,vehicleLocationData,vehicleDistanceTab,vehicleDistanceData}=this.state 

        var userCheck=localStorage.getItem('role')==='admin'
        // console.log(userCheck,'hellop',localStorage.getItem('email'))
        return(
            <>
            <div className="">
            <div className="row mb-20p pt-20p">
                    <div className="col-sm-12">
                        
                            <h5 className='fbold  d-flex justify-content-between'>
                                <span>Vehicle Availability Configurations</span>
                            </h5>
                        
                    </div>
                </div> 
                    <div className="row mb-1">
                        <div className="col-md-8 col-sm-8 mt-33p">
                            <ul className="d-tabs">
                                {gps_availability === 1?
                                <li onClick={this.onClickTab.bind(this,"vehicleLocationData")} className={"lttabs "+(vehicleLocationTab)}>
                                    <button type="button" className="btn">Vehicle Location </button>
                                </li>
                                
                                :""}
                                
                                <li onClick={this.onClickTab.bind(this,"vehicleMasterData")} className={"lttabs "+(vehicleMasterTab)}>
                                    <button type="button" className="btn">Vehicle Master </button>
                                </li>
                                
                                <li onClick={this.onClickTab.bind(this,"vehicleAvailabilityData")}  className={"lttabs "+(vehicleAvailabilityTab)}>
                                {/* style={{ pointerEvents: !this.state.location.value ? 'none' : 'auto', backgroundColor: !this.state.location.value ? '#ddd' : '#fff' }} */}
                                    <button type="button" className="btn">Vehicle Availability</button>
                                </li>

                                {/* {userCheck?<li onClick={this.onClickTab.bind(this,"vehicleDistanceData")} className={"lttabs "+(vehicleDistanceTab)} >
                                    <button type="button" className="btn">Vehicle Distances</button>
                                </li>:''} */}
                            </ul>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="form-group row">
                                <label className="col-form-label col-sm-4 f12" style={{textAlign:"right"}}>
                                    {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}
                                </label>
                                <div className="col-sm-8">
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.locationChanged}
                                        name="location"
                                        value={location}
                                        options={locationNames} 
                                    />
                                    {(!this.state.location.value && this.state.vehicleAvailabilityTab === "activet") ? <p style={{color:"#ff0000", fontSize:"12px"}}>Select plant to check vehicle availability</p>: ""}
                                </div>
                            </div>
                        </div>
                    </div>


                    {(gps_availability === 1 )?vehicleLocationData ? <VehiclesLocation plantLocation={location}/> :'': ''}
                    {vehicleMasterData ? <VehiclesAvailabilityMaster plantLocation={location}/> :''}
                    {vehicleAvailabilityData ? <VehiclesAvailability plantLocation={location}/> :''}
                    {vehicleDistanceData ? location != '' ? <VehicleDistaces plantLocation={location}/> :'': ''}

            </div> 
                <div className={"loader " + this.state.loadshow}></div>	
            </>
        )
    }
}