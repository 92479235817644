import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import redirectURL from "../redirectURL";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapLoader from "./BootstrapLoader";
import { Link } from "react-router-dom";
import { ToggleSwitch } from "./ToggleSwitch";
import Modal from "react-responsive-modal";
import { countries } from "country-flags-svg";
import Select from "react-select";
import { GoDotFill } from "react-icons/go";
import Pagination from 'react-bootstrap/Pagination';
import { parsePhoneNumber } from 'libphonenumber-js';
import { FaEdit } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa6";
import { FaLessThan } from "react-icons/fa6";
import Favourites from "../common/favouritesComponent"


const ClientUsers = () => {

    const [clientList, setClientList] = useState([])
    const [show, setShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [basicType, setBasicType] = useState("default");
    const [basicTitle, setBasicTitle] = useState("");
    const [show2, setShow2] = useState(false);
    const [basicType2, setBasicType2] = useState("default");
    const [basicTitle2, setBasicTitle2] = useState("");
    const [openAddUser, setOpenAddUser] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [countriesList, setCountriesList] = useState([]);
    const [country, setCountry] = useState();
    const [lastName, setLastName] = useState("");
    const [emailID, setEmailID] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [openEditUser, setOpenEditUser] = useState(false)
    const [editFirstName, setEditFirstName] = useState('')
    const [editLastName, setEditLastName] = useState('')
    const editEmailID = useRef(null)
    const [editCountry, setEditCountry] = useState('')
    const [editPhoneNumber, setEditPhoneNumber] = useState('')



    useEffect(() => {
        onLoadData()
    }, [])

    const onLoadData = async () => {

        let countriesArray = []
        countries.filter(each => {
            let option =
                <div>
                    <img
                        src={each.flag}
                        alt={each.name}
                        style={{ width: "20px", height: "16px" }}
                    />
                </div>
            if (each.code != "UM")
                countriesArray.push({ label: option, value: each.name, code: each.iso2 })
        })
        setCountriesList(countriesArray)

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
                const data = await response.json();
                setCountry(countriesArray.find(e => e.code == data.countryCode))
            },
                (error) => {
                    setCountry({
                        "label":
                            <div>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                                    alt="India"
                                    style={{ width: "20px", height: "16px" }}
                                />
                            </div>,
                        "value": "India",
                        "code": "IN"
                    })
                });
        } else {
            setCountry({
                "label":
                    <div>
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                            alt="India"
                            style={{ width: "20px", height: "16px" }}
                        />
                    </div>,
                "value": "India",
                "code": "IN"
            })
        }


        // let user_type = localStorage.getItem('user_type')
        // let email = localStorage.getItem('email')
        
        let user_type = "client"
        let email = "nagendra.g@enmovil.in"
        await redirectURL.post("exim/getClientUsers", { email: email, user_type: user_type, user: true })
            .then((response) => {
                if (response.data.status == "success") {
                    setClientList(response.data.userData)
                    setCurrentItems(response.data.userData.slice(0, 5))
                }
                else {
                    // setShow2(true);
                    // setBasicType2("danger");
                    // setBasicTitle2(response.data.message);
                }
            }).catch(err => {
                setShow2(true);
                setBasicType2("danger");
                setBasicTitle2("No Users Data Found");
            })
    }


    const customStyles2 = {
        option: (provided, state) => ({
            ...provided,
        }),
        control: (base, stat) => ({
            ...base,
            borderRadius: "6px",
            height: "3rem",
            width: "2.5rem",
            padding: "0px"
        }),
        menuList: (base, state) => ({
            ...base,
            maxHeight: '10rem',
            padding: "0px",
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
        }),
        dropdownIndicator: base => ({
            ...base,
            display: 'none',
            padding: "0px"
        }),
        indicatorsContainer: base => ({
            ...base,
            display: 'none !important'
        }),
        menu: base => ({
            ...base,
            padding: "0px",
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
        }),
    };


    const closeModal = () => {
        setOpenAddUser(false)
        setShowLoader(false)
        setOpenEditUser(false)
    };


    const openModal = async () => {
        setFirstName('')
        setLastName('')
        setEmailID('')
        setPhoneNumber('')
        // setAddress('')
        setOpenAddUser(true)
    };

    const changeStatus = async (record, toStatus) => {
        setShowLoader(true)
        await redirectURL.post("exim/changeUserStatus", { email: record.email, status: toStatus, name: `${record.firstname} ${record.lastname}` })
            .then((response) => {
                setShowLoader(false)
                if (response.data.status == "success") {
                    setShow(true);
                    setBasicType("success");
                    setBasicTitle(toStatus == 0 ? `Disabled User  ${record.firstname} ${record.lastname}` : `Activated User ${record.firstname} ${record.lastname}`);
                }
                else {
                    setShow2(true);
                    setBasicType2("danger");
                    setBasicTitle2(response.data.message);
                }
            }).catch(err => {
                setShow2(true);
                setBasicType2("danger");
                setBasicTitle2("No Users Data Found");
            })
    }

    const closeAlert = () => {
        setShow(false);
        setShowLoader(false);
        onLoadData();
    };

    const closeAlert2 = () => {
        setShow2(false);
        setShowLoader(false);
    };

    function changeData(i) {
        setCurrentPage(i + 1)
        setCurrentItems(clientList.slice(((i) * 5), ((i) * 5) + 5))
    }

    function openEditModal(user) {
        // console.log(user);
        setEditFirstName(user.firstname)
        setEditLastName(user.lastname)
        editEmailID.current = user.email
        try {
            let numberObj = parsePhoneNumber(user.mobile_no)

            setEditPhoneNumber(numberObj.nationalNumber)
        } catch (e) {
            setEditCountry({
                "label":
                    <div>
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                            alt="India"
                            style={{ width: "20px", height: "16px" }}
                        />
                    </div>,
                "value": "India",
                "code": "IN"
            })
            setEditPhoneNumber(user.mobile_no)
        }
        setOpenEditUser(true)
    }

    const onEditSubmitHandler = async (e) => {
        e.preventDefault();
        if (editPhoneNumber != "") {
            let numberObj = parsePhoneNumber(editPhoneNumber, editCountry.code)
            if (!numberObj.isPossible()) {
                setShow2(true)
                setBasicType2("danger")
                setBasicTitle2("Please provide a valid phone number")
            }
            else {
                setShowLoader(true)
                let body = {
                    firstname: editFirstName,
                    lastname: editLastName,
                    email: editEmailID.current,
                    mobile_no: editPhoneNumber,
                    client_email: "nagendra.g@enmovil.in",
                }
                await redirectURL.post("exim/editUser", body)
                    .then((response) => {
                        if (response.data.status == "success") {
                            setShow(true);
                            setBasicType("success");
                            setBasicTitle(response.data.message);
                            setOpenEditUser(false)
                            onLoadData()
                        }
                        else {
                            setShow(true);
                            setBasicType("danger");
                            setBasicTitle(response.data.message);
                        }
                    })
                setShowLoader(false)
            }
        }
        else {
            setShowLoader(true)
            let body = {
                firstname: editFirstName,
                lastname: editLastName,
                email: editEmailID.current,
                mobile_no: editPhoneNumber,
                client_email: "nagendra.g@enmovil.in",
            }
            await redirectURL.post("exim/editUser", body)
                .then((response) => {
                    if (response.data.status == "success") {
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle(response.data.message);
                        setOpenEditUser(false)
                        onLoadData()
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle(response.data.message);
                    }
                })
            setShowLoader(false)
        }
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (phoneNumber != "") {
            let numberObj = parsePhoneNumber(phoneNumber, country.code)
            if (!numberObj.isPossible()) {
                setShow2(true)
                setBasicType2("danger")
                setBasicTitle2("Please provide a valid phone number")
            }
            else {
                setShowLoader(true)
                let body = {
                    firstname: firstName,
                    lastname: lastName,
                    email: emailID,
                    mobile_no: phoneNumber,
                    client_email: "nagendra.g@enmovil.in",
                }
                await redirectURL.post("exim/addUser", body)
                    .then((response) => {
                        // console.log(response, "resss");
                        if (response.data.status == "success") {
                            setShow(true);
                            setBasicType("success");
                            setBasicTitle(response.data.message);
                            setOpenAddUser(false)
                            onLoadData()
                        }
                        else {
                            setShow(true);
                            setBasicType("danger");
                            setBasicTitle(response.data.message);
                        }
                    })
                setShowLoader(false)
            }
        }
        else {
            setShowLoader(true)
            let body = {
                firstname: firstName,
                lastname: lastName,
                email: emailID,
                mobile_no: phoneNumber,
                client_email: "nagendra.g@enmovil.in",
            }
            await redirectURL.post("exim/addUser", body)
                .then((response) => {
                    if (response.data.status == "success") {
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle(response.data.message);
                        setOpenAddUser(false)
                        onLoadData()
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle(response.data.message);
                    }
                })
            setShowLoader(false)
        }
    }


    const changeHandler = (e) => {
        let number = ""
        if (e.target.value.match(/\d+/g) !== null) {
            number = e.target.value.match(/\d+/g).join()
        }
        setPhoneNumber(number)
    }

    const changeHandler2 = (selectedOption) => {
        setCountry(selectedOption);
        setPhoneNumber("")
    };

    const changeHandler3 = (selectedOption) => {
        setEditCountry(selectedOption);
        setEditPhoneNumber("")
    };


    const changeHandler4 = (e) => {
        let number = ""
        if (e.target.value.match(/\d+/g) !== null) {
            number = e.target.value.match(/\d+/g).join()
        }
        setEditPhoneNumber(number)
    }


    const formatPhoneNumber = () => {
        if (phoneNumber != "") {
            let numberObj = parsePhoneNumber(phoneNumber, country.code)
            if (!numberObj.isPossible()) {
                setShow2(true)
                setBasicType2("danger")
                setBasicTitle2("Please provide a valid phone number")
            }
            else
                setPhoneNumber(numberObj.number)
        }
    }


    const editFormatPhoneNumber = () => {
        if (editPhoneNumber != "") {
            let numberObj = parsePhoneNumber(editPhoneNumber, editCountry.code)
            if (!numberObj.isPossible()) {
                setShow2(true)
                setBasicType2("danger")
                setBasicTitle2("Please provide a valid phone number")
            }
            else
                setEditPhoneNumber(numberObj.number)
        }
    }

    return (
        <div className="container-fluid">
            <BootstrapLoader showLoader={showLoader} />
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            ></SweetAlert>
            <SweetAlert
                show={show2}
                type={basicType2}
                title={basicTitle2}
                onConfirm={closeAlert2}
            ></SweetAlert>
             <h5 className="col-sm-12 mb-15p fbold f20">
                <i className="fa fa-users cus-i"></i>&nbsp;<span>Users</span>
                <Favourites />
                <button className="f14 add-user-button float-right" type="button"  onClick={openModal}><CreateUserIcon color={"var(--colorOnM)"} />&nbsp; Create User</button>
            </h5>
            <Modal open={openAddUser} onClose={closeModal}
                center
                classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                }}
                animationDuration={400}>
                <div className="">
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Create User</h5>
                </div>
                <div className="px-2 mb-4">

                    {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end mr-40p">
                        <button className="btn btn-primary" type="button" onClick={(e) => setViewFileUpload(true)}><i className="fa fa-upload"></i>  Bulk Upload</button>
                    </div> */}

                    <form className="" onSubmit={onSubmitHandler}>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        First Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        pattern="[A-Za-z\s]+"
                                        title="Only alphabetic characters are allowed"
                                        maxLength={30}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Last Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        pattern="[A-Za-z\s]+"
                                        title="Only alphabetic characters are allowed"
                                        maxLength={30}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Email ID
                                    </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email ID"
                                        value={emailID}
                                        onChange={(e) => setEmailID(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label className="custom-label">
                                        Phone Number
                                    </label>

                                    <div className="col-xl-12 col-lg-12" style={{ display: "flex", paddingInline: "0px" }}>
                                        <Select
                                            placeholder=""
                                            closeMenuOnSelect={true}
                                            styles={customStyles2}
                                            onChange={changeHandler2}
                                            options={countriesList}
                                            value={country} />
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Phone Number"
                                            minLength={8}
                                            maxLength={15}
                                            value={phoneNumber}
                                            onBlur={formatPhoneNumber}
                                            onChange={changeHandler}
                                            style={{ height: "3rem" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="col-xl-12 col-lg-12">
                                <button type="submit" className="btn btn-primary btn-user mt-4 pt-2">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>



            <Modal open={openEditUser} onClose={closeModal}
                center
                classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                }}
                animationDuration={400}>
                <div className="">
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Edit User</h5>
                </div>
                <div className="px-2 mb-4">
                    <form className="" onSubmit={onEditSubmitHandler}>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        First Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="First Name"
                                        value={editFirstName}
                                        pattern="[A-Za-z\s]+"
                                        title="Only alphabetic characters are allowed"
                                        maxLength={30}
                                        onChange={(e) => setEditFirstName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Last Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Last Name"
                                        value={editLastName}
                                        pattern="[A-Za-z\s]+"
                                        title="Only alphabetic characters are allowed"
                                        maxLength={30}
                                        onChange={(e) => setEditLastName(e.target.value)}
                                        required
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group row">
                                <div className="col-xl-6 col-lg-6">
                                    <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*&nbsp;</label>
                                    <label className="custom-label">
                                        Email ID
                                    </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email ID"
                                        value={editEmailID.current}
                                        disabled
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <label className="custom-label">
                                        Phone Number
                                    </label>

                                    <div className="col-xl-12 col-lg-12" style={{ display: "flex", paddingInline: "0px" }}>
                                        <Select
                                            placeholder=""
                                            closeMenuOnSelect={true}
                                            styles={customStyles2}
                                            onChange={changeHandler3}
                                            options={countriesList}
                                            value={editCountry} />
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Phone Number"
                                            minLength={8}
                                            maxLength={15}
                                            value={editPhoneNumber}
                                            onBlur={editFormatPhoneNumber}
                                            onChange={changeHandler4}
                                            style={{ height: "3rem" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="col-xl-12 col-lg-12">
                                <button type="submit" className="btn btn-primary btn-user mt-4 pt-2">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* <div className="row">
                <div className="col">
                    <ol
                        className="breadcrumb"
                        style={{ background: "none", height: "52px", marginLeft: "21px" }}
                    >
                        <li className="breadcrumb-item" style={{ height: "40px" }}>
                            <Link
                                to={"/container-tracking"}
                                style={{
                                    textDecoration: "none",
                                    position: "relative",
                                    top: "2px",
                                }}
                            >
                                Container Tracking
                            </Link>
                        </li>
                        <li
                            className="breadcrumb-item"
                            aria-current="page"
                            style={{
                                color: "#263238",
                                fontSize: "15px",
                                fontWeight: 700,
                                marginBottom: "20px",
                                paddingLeft: "10px",
                            }}>
                            Users
                        </li>
                    </ol>
                </div>
                <div className="col" style={{ marginRight: "7rem", marginTop: "1rem" }}>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mr-40p">
                        <button className="add-user-button" type="button" onClick={openModal}><CreateUserIcon color={"var(--colorOnM)"} />&nbsp; Create User</button>
                    </div>
                </div>
            </div> */}
            <div
                className=""
                style={{ minHeight: "87vh", margin: "auto" }}>
                <div className="row">
                    <div className="col-md-12">
                        <div
                            className="card custom-card"
                            style={{
                                padding: "40px",
                                borderRadius: "18px",
                                paddingTop: "30px",
                            }}>
                            <div className="card-body" style={{ padding: "0px" }}>
                                <table id="clientTable" className="mt-4" style={{ width: '100%' }}>
                                    <thead>
                                        <tr style={{ borderSpacing: '1rem', background: "#eef3ff" }}>
                                            <th style={{ padding: "1.5rem", textAlign: "left" }}>First Name</th>
                                            <th style={{ textAlign: "left" }}>Last Name</th>
                                            <th style={{ textAlign: "left" }}>Email ID</th>
                                            <th style={{ textAlign: "left" }}>Active Status</th>
                                            <th style={{ textAlign: "left" }}>Change Status</th>
                                            <th style={{ textAlign: "left" }}>Mobile Number</th>
                                            <th style={{ textAlign: "left" }}>Registered Date</th>
                                            <th style={{ textAlign: "left" }}></th>
                                        </tr>
                                    </thead>
                                    {
                                        (clientList != null && clientList != undefined && clientList.length != 0) ?
                                            <>
                                                {
                                                    currentItems.map((each, index) => {
                                                        return (
                                                            <tbody key={each.email}>
                                                                <tr style={{ borderBottom: "1px solid #808080" }}>
                                                                    <td className="px-2" style={{ padding: "1.5rem", textAlign: 'left' }}>{each.firstname}</td>
                                                                    <td className="px-2" style={{ textAlign: 'left' }}>{each.lastname}</td>
                                                                    <td className="px-2" style={{ textAlign: 'left' }}>{each.email}</td>
                                                                    <td className="px-2" style={{ textAlign: 'left', alignItems: "flex-start" }}>{(each.is_active == 1) ? <><GoDotFill color="#00c853" size={20} />&nbsp;Active</> : <><GoDotFill size={20} />&nbsp;In-Active</>}</td>
                                                                    <td className="px-2" style={{ textAlign: 'left' }}>
                                                                        {
                                                                            (each.is_active == 1) ?
                                                                                <ToggleSwitch toggled={true} onClick={e => changeStatus(each, 0)} />
                                                                                :
                                                                                <ToggleSwitch toggled={false} onClick={e => changeStatus(each, 1)} />
                                                                        }
                                                                    </td>
                                                                    <td className="px-2" style={{ textAlign: 'left' }}>{each.mobile_no == "" ? "NA" : each.mobile_no}</td>
                                                                    <td className="px-2" style={{ textAlign: 'left' }}>{moment(each.created_date).format("Do MMM, YYYY")}</td>
                                                                    <td className="px-3" style={{ textAlign: 'left', cursor: "pointer" }} onClick={e => openEditModal(each)} ><FaEdit size={20} color="#407bff" /></td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={8}>
                                                            <div
                                                                className="card py-2 pr-3"
                                                                style={{
                                                                    borderRadius: "18px",
                                                                    textAlign: "center",
                                                                    width: '100%',
                                                                    marginTop: "40px",
                                                                }}
                                                            >
                                                                No data found.
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                    }
                                </table>
                            </div>
                            <br />
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FaLessThan color="#407bff" className="pagenation-custom2" size={20}
                                    onClick={
                                        (currentPage > 1) ?
                                            () => changeData(currentPage - 2) :
                                            () => { }
                                    } />&nbsp;&nbsp;&nbsp;
                                {
                                    (Math.ceil(clientList.length / 5) > 5 && currentPage < (Math.ceil(clientList.length / 5) - 5)) ?
                                        <>
                                            {
                                                (currentPage >= 5) ?
                                                    <>
                                                        <span className={`pagenation-custom`}>
                                                            .....
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage - 4) }}>
                                                            {currentPage - 3}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage - 3) }}>
                                                            {currentPage - 2}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage - 2) }}>
                                                            {currentPage - 1}
                                                        </span>
                                                        <span className={`pagenation-custom activepg`}
                                                            onClick={() => { changeData(currentPage - 1) }}>
                                                            {currentPage}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage) }}>
                                                            {currentPage + 1}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage + 1) }}>
                                                            {currentPage + 2}
                                                        </span>
                                                        <span className={`pagenation-custom`}
                                                            onClick={() => { changeData(currentPage + 2) }}>
                                                            {currentPage + 3}
                                                        </span>
                                                        <span className={`pagenation-custom`}>
                                                            .....
                                                        </span>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (currentPage < 5) ?
                                                    <>
                                                        <span className={`pagenation-custom ${(1 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(0) }}>
                                                            {1}
                                                        </span>

                                                        <span className={`pagenation-custom ${(2 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(1) }}>
                                                            {2}
                                                        </span>

                                                        <span className={`pagenation-custom ${(3 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(2) }}>
                                                            {3}
                                                        </span>

                                                        <span className={`pagenation-custom ${(4 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(3) }}>
                                                            {4}
                                                        </span>

                                                        <span className={`pagenation-custom ${(5 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData(4) }}>
                                                            {5}
                                                        </span>
                                                        <span className={`pagenation-custom`}>
                                                            .....
                                                        </span>
                                                    </>
                                                    : <></>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                (Math.ceil(clientList.length / 5) > 5 && currentPage >= (Math.ceil(clientList.length / 5) - 5)) ?
                                                    <>
                                                        <span className={`pagenation-custom ${(1 === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 5)) }}>
                                                            {1}
                                                        </span>
                                                        <span className={`pagenation-custom ${((Math.ceil(clientList.length / 5) - 1) === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 4)) }}>
                                                            {Math.ceil(clientList.length / 5) - 1}
                                                        </span>
                                                        <span className={`pagenation-custom ${((Math.ceil(clientList.length / 5) - 2) === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 3)) }}>
                                                            {Math.ceil(clientList.length / 5) - 2}
                                                        </span>
                                                        <span className={`pagenation-custom ${((Math.ceil(clientList.length / 5) - 1) === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 2)) }}>
                                                            {Math.ceil(clientList.length / 5) - 1}
                                                        </span>
                                                        <span className={`pagenation-custom ${(Math.ceil(clientList.length / 5) === currentPage) ? "activepg" : ""}`}
                                                            onClick={() => { changeData((Math.ceil(clientList.length / 5) - 1)) }}>
                                                            {Math.ceil(clientList.length / 5)}
                                                        </span>
                                                    </> :
                                                    <>
                                                        {
                                                            (Math.ceil(clientList.length / 5) < 5) ?
                                                                Array.from({ length: Math.ceil(clientList.length / 5) }, (_, i) => (
                                                                    <span className={`pagenation-custom ${(i + 1 === currentPage) ? "activepg" : ""}`}
                                                                        key={i + 1}
                                                                        onClick={() => { changeData(i) }}>
                                                                        {i + 1}
                                                                    </span>
                                                                ))
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                                &nbsp;&nbsp;&nbsp;<FaGreaterThan className="pagenation-custom2" color="#407bff" size={20}
                                    onClick={
                                        (currentPage < Math.ceil(clientList.length / 5)) ?
                                            () => changeData(currentPage) :
                                            () => { }
                                    } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientUsers;


const CreateUserIcon = ({ color }) => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.58309 7.90638C5.58309 7.44991 5.21305 7.07987 4.75658 7.07987H1.45053C0.994064 7.07987 0.624023 6.70983 0.624023 6.25336C0.624023 5.79689 0.994065 5.42684 1.45053 5.42684H4.75658C5.21305 5.42684 5.58309 5.0568 5.58309 4.60033V1.29428C5.58309 0.837814 5.95314 0.467773 6.40961 0.467773C6.86608 0.467773 7.23612 0.837815 7.23612 1.29428V4.60033C7.23612 5.0568 7.60616 5.42684 8.06263 5.42684H11.3687C11.8251 5.42684 12.1952 5.79689 12.1952 6.25336C12.1952 6.70983 11.8251 7.07987 11.3687 7.07987H8.06263C7.60616 7.07987 7.23612 7.44991 7.23612 7.90638V11.2124C7.23612 11.6689 6.86608 12.0389 6.40961 12.0389C5.95314 12.0389 5.58309 11.6689 5.58309 11.2124V7.90638Z" fill={color} />
    </svg>

);