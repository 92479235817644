import React, { useEffect, useLayoutEffect, useRef } from "react";
import TrackingShipGreen from "../../assets/icons/tracking-ship-green.svg";
import TrackingShipRed from "../../assets/icons/tracking-ship-red.svg";
import TrackingShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import FlagGrey from "../../assets/icons/flag-grey.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import ExportIcon from "../../assets/icons/export-arrow.svg";
import ImportIcon from "../../assets/icons/import-arrow.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CompletedIcon from "../../assets/icons/completed-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import RefreshIcon from "../../assets/icons/refresh-icon.svg";
import moment from "moment";
import { countries } from "country-flags-svg";
import { useState } from "react";
import emissionIcon from '../../assets/icons/co2-1.svg'
import InfoIcon from '../../assets/icons/info.png';
import { Tooltip } from "react-tooltip";

const SubTile = (props) => {
  console.log("SubTile", props);
  const getFlagIcon = (countryName) => {
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.name === countryName) {
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return srcFlag[0]?.flag;
    } else {
      return "";
    }
  };
  let [lastEvent,setLastEvent] = useState("");
  let [textDecoration,setTextDecoration] = useState("");
  const last_location=useRef(null)
  useLayoutEffect(()=>{
    try{
      if(props.cardData.last_location.length>14){
        last_location.current=(props.cardData.last_location).slice(0,11)+"..."
        }
        else{
          last_location.current=null
        }
    }catch(err){
      console.log(err);
    }
  },[props])
  useEffect(()=>{
    let s_etd = "NA";
          let a_etd = "NA";
          let p_eta = "NA";
          let a_eta = "NA";
          let delay_state=""

          // if (loading_port_etd) {
          //   s_etd = moment(loading_port_etd).format("Do MMM, YYYY");
          // }
          // if (loading_port_atd) {
          //   a_etd = moment(loading_port_atd).format("Do MMM, YYYY");
          // }
          // if (last_port_eta) {
          //   p_eta = moment(last_port_eta).format("Do MMM, YYYY");
          // }
          // if (last_port_ata) {
          //   a_eta = moment(last_port_ata).format("Do MMM, YYYY");
          // }

          // const aData=actualDuration.find(item=>item.container_no===container_no)
          // // console.log("aData.loading_port_etd ", aData)
          // if(aData !== undefined)
          // {
            if(props.cardData.loading_port_etd)
            {
                s_etd=moment(props.cardData.loading_port_etd).format("Do MMM, YYYY")
            }

          
            if(props.cardData.loading_port_atd)
             {
              a_etd=moment(props.cardData.loading_port_atd).format("Do MMM, YYYY")
             }

            if(props.cardData.last_port_eta){
              p_eta=moment(props.cardData.last_port_eta).format("Do MMM, YYYY")
            }

            if(props.cardData.last_port_ata)
            {
               a_eta=moment(props.cardData.last_port_ata).format("Do MMM, YYYY")
            }
            
          try{
          const delay=(moment(props.cardData.last_port_eta)).diff(moment(props.cardData.last_port_ata));
          const duration=moment.duration(delay)
          const days = duration.days();
          const hours2 = duration.hours();
          if(props.cardData.last_port_eta===""||props.cardData.last_port_ata===""){
            delay_state="NA"
          }
          if(days<0){
          delay_state="Delay"
          }
          else if(days>0){
            delay_state="Early"
            }
          else{
            if(days===0){
              if(hours2<0){
                delay_state="Delay"
              }
              else if(hours2>0){
                delay_state="Early"
              }
            }
          }
          // props.cardData.event_data.forEach(element =>{
          //   if(element["status"]==="COMPLETED"){
          //     text=props.cardData.event_data[props.cardData.event_data.length-1].event_name;
          //   }
          // })
          let text=null
          text=props.cardData.event_data[props.cardData.event_data.length-1].event_name;
          
          if(props.cardData.hasOwnProperty("force_close") && props.cardData.force_close===1){
          text="Force Closed"
          delay_state="Delay"
        }
          if(text===null||text===undefined || text==="")
          text=props.cardData.event_data.find(element => element["is_last_event"]===true).event_name;
          setLastEvent(text)
          }catch(err){
            console.log(err,"errorrrrrrr");
          }
          if (delay_state === "Early") {
            setTextDecoration("early-text")
          } else if(delay_state === "Delay"){
            setTextDecoration("delay-text")
          }
          else{
            setTextDecoration("ontime-text")
          }
    //      }
    // for(let i=0; i < props.cardData.event_data.length; i++){
    //   let statusCode=props.cardData.delay_status
    //   let {event_name, is_last_event, actual_time } = props.cardData.event_data[i]
    //   if(is_last_event && props.cardData.event_data[i].hasOwnProperty("actual_time")){
    //     setLastEvent(event_name)
    //     break;
    //   }else if(props.cardData.event_data[i].hasOwnProperty("actual_time")){
    //     setLastEvent(event_name)
    //   }

    //   if (statusCode === "On Time") {
    //     // textDecoration = "ontime-text";
    //     setTextDecoration("ontime-text")
    //    } else if (statusCode === "Early") {
    //     // textDecoration = "early-text";
    //     setTextDecoration("early-text")
    //     } else {
    //     // textDecoration = "delay-text";
    //     setTextDecoration("delay-text")
    //     }
    // }

  },[props])
  return (
    <div
      className={`align-items-center card flex-row justify-content-between mr-3 pr-3 py-4 main-card ${(props?.active)?" subtileborder":""}`}
      key={props.cardId}
      onClick={() => {
        props.changeSubTileData(props.cardId)
      }}
      id={props.cardId}
      style={{
        borderRadius: "18px",
        cursor: "pointer",
        // cursor: "none",
        // width: "89vw",
        // margin: "auto",
      }}
    >
              <div className="d-flex justify-content-start align-items-start">
                {props.cardData.movement_type === "Import" ? (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center p-2"
                      style={{ background: "#407bff2e" }}
                    >
                      <img
                        style={{
                          width: "10px",
                          marginBottom: "5px",
                        }}
                        src={ImportIcon}
                      />
                      <div
                        style={{
                          color: "#407bff",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        IM
                      </div>

                      {/* <div style={{ color: "white", fontSize: "10px", fontWeight: 700 }}>{props.trackingType}</div> */}
                    </div>
                  </>
                ) : (props.cardData.movement_type==="Export"?((
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center p-2"
                      style={{ background: "#407bff" }}
                    >
                      <img
                        style={{
                          width: "10px",
                          marginBottom: "5px",
                        }}
                        src={ExportIcon}
                      />
                      <div
                        style={{
                          color: "white",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        EX
                      </div>

                      {/* <div style={{ color: "white", fontSize: "10px", fontWeight: 700 }}>{props.trackingType}</div> */}
                    </div>
                  </>
                )):<></>)}
              </div>
              {/*<div className="d-flex flex-row cursor-class" style={{}}>
         <img
            src={emissionIcon}
            width="40px"
          ></img>
          <span style={{ marginLeft: "10px", lineHeight: "30px", fontSize: "12px" }}>
            {props.cardData.emissions.co2e.value ? parseFloat(props.cardData.emissions.co2e.value).toFixed(2) +" "+ props.cardData.emissions.co2e.unit: "NA" }
          </span>

           <Tooltip id="info_card_tracking" style={{zIndex:"9999", display:"none"}}/> 
          <img src={InfoIcon} class="info_card_tracking" data-tooltip-id="info_card_tracking" alt="" width="15px" data-tooltip-content={"Port to Port emission calculation based on GLEC framework using default factors."} />
           <div class="info_card_tracking" style={{display:"inline-block"}}data-tooltip-id="info_card_tracking" data-tooltip-content={info_msg}>
            <img src={InfoIcon} alt="" width="15px" />
          </div>               
      </div> */}

      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          {/* <img src={props.carrierIcon}></img> */}
          <div style={{ fontSize: "13px", color: "black", fontWeight: 800 }}>
            {props.cardData?.container_no ? props.cardData.container_no : ""}
          </div>
        </div>
        <div style={{ fontSize: "10px", fontWeight: 700 }}>
          <div>
            {props.cardData?.container_size
              ? props.cardData.container_size
              : ""}
          </div>
        </div>
      </div>

      {/* <div className="d-flex flex-column justify-content-start"> */}
        {/* <div className="d-flex flex-row justify-content-between">
          <div style={{ color: "#858796", fontSize: "10px" }}>BL No:</div> */}
          {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
          {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>
            {props.cardData?.mbl_number ? props.cardData.mbl_number : ""}
          </div>
        </div> */}
        {/* <div className="d-flex flex-row justify-content-between">
          <div style={{ color: "#858796", fontSize: "10px" }}>Booking No:</div> */}
          {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
          {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>
          {props.cardData?.booking_no ? props.cardData.booking_no : ""}
          </div>
        </div> */}
        {/* <div className="d-flex flex-row justify-content-between">
          <div style={{ color: "#858796", fontSize: "10px" }}>
            Container No:
          </div>
          <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>
            {"TLLU2132349"}
          </div>
        </div> */}
      {/* </div> */}

      <div className="d-flex flex-column justify-content-start">
        <div className="d-flex flex-row justify-content-between mb-1">
          <div style={{ color: "#858796", fontSize: "10px" }}>
            Last Location:
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between mb-1">
          <div data-tooltip-id="info_card_tracking"
            data-tooltip-content={(last_location.current===null)?"":props.cardData.last_location}
            style={{ color: "#858796", fontSize: "10px", fontWeight: "bolder" }}
          >
            {(last_location.current===null)?props.cardData.last_location:last_location.current}
          </div>
        </div>
        {/* <div className="d-flex flex-row justify-content-between mb-1">
          <div style={{ color: "#858796", fontSize: "10px" }}>Planned ETA:</div>
          <div
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              fontWeight: 700,
            }}
          >
            {"23rd May 2023"}
          </div>
        </div> */}
        {/* <div className="d-flex flex-row justify-content-between">
          <div style={{ color: "#858796", fontSize: "10px" }}>Actual ETA:</div>
          <div
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              fontWeight: 700,
            }}
          >
            {"23rd May 2023"}
          </div>
        </div> */}
      </div>

      <div className="d-flex flex-column justify-content-between">
        <div>
          <div
            className={`${textDecoration} py-1 px-3`}
            style={{
              fontSize: "10px",
              // color: "#FF0000",
              fontWeight: 600,
              // background: "#ffeded",
              borderRadius: "14px",
            }}
          >
            {/* {props.cardData?.delay_message ? props.cardData.delay_message : ""} */}
            {lastEvent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubTile;
