import React, { Component } from "react";
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {Navigate} from 'react-router-dom';
import moment from "moment";
import $ from 'jquery'
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance

class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {successLogin:0,isNav:0};
        
		this.cipher =this.cipher.bind(this);
		this.decipher = this.decipher.bind(this);
    }

    async componentDidMount() {
        console.log(this.props,'efop')
        // const token = this.props.match.params.token;
        let url_type = window.location.href
        // console.log(url_type,"url_type")
        let path_type = url_type.split('/')
        const token = path_type[path_type.length -1]
        // console.log(path_type,"path_type")
        // var ppwwdd = this.cipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
		// var usrtoken = ppwwdd(token)
        var params = {
            token: token
        };
        try {
            const resp = await redirectURL.post("/authenticateUserToken", params);
            // console.log("After login ", resp);

            // if (resp.data.status === "success") {
				// console.log("login ", resp.data)
                var ppwwdd = this.decipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
                var ppwwdd2 = this.cipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
                var encrypted_res = ppwwdd(resp.data.encrypted_res)
                // console.log(encrypted_res, "encrypted_res");
                encrypted_res=JSON.parse(encrypted_res)
                
                var params = {
                    e: ppwwdd2(encrypted_res.user[0].email),
                    d: ppwwdd2(encrypted_res.user[0].password_txt)
				}
                redirectURL.post('/tracking/verifyUser', params)
            .then((response) => {
                if (response.data.status == "success") {
                    var decode = this.decipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
                    var uData = decode(response.data.output);
                    // console.log("uData ",uData)
                    var user = JSON.parse(uData);
                    // console.log("user ",user)
                    localStorage.setItem("email", user.email);
                    localStorage.setItem("k", response.data.output);
                    var c = moment.parseZone().format("YYYY-MM-DD")
                    var s = moment.parseZone().format("HH:mm:ss")
                    localStorage.setItem("date", c);
                    localStorage.setItem("time", s);
                    localStorage.setItem("role",user.roles)
                    localStorage.setItem("roles",user.roles)
                    localStorage.setItem("token",user.token)
                    localStorage.setItem("dept_code","SNDG")
                    // localStorage.setItem("user_type", user.user_type);
                    // localStorage.setItem("role", user.role);
                    // localStorage.setItem("user_name", user.username);
                    this.setState({
                        isNav: true,
                    })
                }
                else {
                    this.setState({
                        show: true,
                        title: "Invalid Email / Password.",
                        style: { display: 'none' },
                        style2: { display: 'block' },
                    })
                }
            }).catch(err => console.log(err))

				// redirectURL.post('/verifyUser', params,{
                //     //redirectURL.post('/applogin', fdata,{
                //         headers:{
                //             'Content-Disposition': 'application/x-www-form-urlencoded',
                //             'Access-Control-Allow-Methods': 'POST'
                //             // 'Content-Type': 'application/x-www-form-urlencoded',
                //             // 'Content-Type':'application/json'
                //         }
                //     })
                //         .then( async (response) => {
                //             // console.log("After login ",response);
                //             if(response.statusText == 'OK')
                //             {
                //                 if(response.data.auth == true)
                //                 {							
                //                     if(response.data.otpAuthentication == 1)
                //                     {
                //                         this.setState({
                //                             "otpAuthentication" : response.data.otpAuthentication,
                //                             //"otp" : response.data.otp,
                //                             "otpMessage" : "OTP has been sent to your Email",
                //                             "userData" : response.data,
                //                             "successLogin" : 0,
                //                         })
                //                         $("#entered_otp").val("");
                //                     }
                //                     else
                //                     {
                //                         var params1 = {
                //                             data_type : 2
                //                         }
                //                         redirectURL.post('master/getToolconfigurations',params1)
                //                         .then(responsed =>{
                //                             if(responsed.data.status == 'success'){
                //                                 let toolConfigData = responsed.data.toolConfigData
                //                                 localStorage.setItem("tlcfg",JSON.stringify(toolConfigData))
                //                             }
                //                         })
                //                         localStorage.setItem("email",response.data.user[0].email);
                //                         localStorage.setItem("token",response.data.token);
                //                         localStorage.setItem("user_type",response.data.user[0].user_type);
                //                         localStorage.setItem("role",response.data.user[0].role);
                //                         localStorage.setItem("roles",response.data.user[0].role);
                //                         localStorage.setItem("user_code",response.data.user[0].user_code);
                //                         localStorage.setItem("pc","HZ");
                //                         var md = this.cipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
                //                         var m = md(JSON.stringify(response.data.user))
                //                         localStorage.setItem('m', m);
                            
                //                         this.setState({
                //                             isNav : 1
                //                         })


                //                         var decode = this.decipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
                //                         var uData = decode(response.data.output);
                //                         var user = JSON.parse(uData);
                //                         localStorage.setItem("email", user.email);
                //                         localStorage.setItem("k", response.data.output);
                //                         var c = moment.parseZone().format("YYYY-MM-DD")
                //                         var s = moment.parseZone().format("HH:mm:ss")
                //                         localStorage.setItem("date", c);
                //                         localStorage.setItem("time", s);
                //                         localStorage.setItem("role",user.role)
                //                         localStorage.setItem("dept_code","SNDG")
                //                         // localStorage.setItem("user_type", user.user_type);
                //                         // localStorage.setItem("role", user.role);
                //                         // localStorage.setItem("user_name", user.username);
                //                         this.setState({
                //                             isNav: true,
                //                         })


                //                     }
                                    
                //                 }
                //             else{
                            
                //                 this.setState({
                //                     username:'',
                //                     email:'',
                //                     password:'',
                //                     loginMessage:response.data.msg
                //                 });
                                
                //             }
                                
                                
                //             }
                //             else
                //             {
                //                 this.setState({
                //                     username:'',
                //                     email:'',
                //                     password:'',
                //                     loginMessage:response.data.msg
                //                 });
                //             }
                //         //console.log(localStorage.getItem('token'));
                //         })
                //         .catch(function (error) {
                //             console.log(error);
                //         });
            // }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }

    getUserDashboardUrl(pathToMatch = null) {
 
		var uroles = localStorage.getItem("roles");
		var arr = []
		
        var dashboardUrl = "/activenewconsignments";		
		
		let currentDepartmentCode = null;
		let departmentCode = ["SNDG"];

		//console.log("IN render, departmentCode ", departmentCode);
		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0]; 
		} else {
			currentDepartmentCode = departmentCode; 
		}
		var userroles = localStorage.getItem("roles");
		
		
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}

    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    
    decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }

    render() {


        
        if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
            {
                var isNav = this.state.isNav;
                if(isNav == 1)
                {
                    let dashboardUrl = this.getUserDashboardUrl();
                    //let dashboardUrl = this.state.landingpage;
                    // return <Redirect to="dashboard/snddefault" push={true} />
    
                    return <Navigate to={dashboardUrl} push={true} />
                }
            }

		// if(localStorage.getItem('_t') != '' && localStorage.getItem('_t') != null)
		// {
		// 	var successLogin = this.state.successLogin;
        //     console.log(successLogin,"successLogin")
		// 	if(successLogin == 1)
		// 	{
		// 		let dashboardUrl = this.getUserDashboardUrl();
		// 		console.log(dashboardUrl,"dashboardUrl1143");
		// 		//let dashboardUrl = this.state.landingpage;
		// 		// return <Redirect to="dashboard/snddefault" push={true} />

		// 		return <Redirect to={dashboardUrl} push={true} />
		// 	}
		// }
		// console.log(Cookies.get('token'),'hello')
		
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}
            </div>
        );
    }
}

export default EnmUserLogin;