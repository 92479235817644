import React from 'react';

const EditButtonRenderer = (props) => {
    const onClick = () => {
        props.onClick(props.node.data);
    };

    return (
        <button onClick={onClick} className="btn btn-link">
            <i className="fa fa-edit">Edit</i>
        </button>
    );
};

export default EditButtonRenderer;
