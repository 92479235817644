
import React, { Component } from 'react';

const ViewPODButton = (props) => {
    const handleLinkClick = (e) => {
        e.preventDefault(); // Prevent default anchor click behavior
        const url = props.data.pod_document;
        // console.log(url)
        if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };
    console.log("props ", props.data.pod_document)
    return (
        <div>
            <a
                href="#"
                onClick={handleLinkClick}
                className="btn btn-info nopadding font12"
                title="View POD"
            >
                <i className="icofont icofont-eye f16"></i>&nbsp; View POD
            </a>

        </div>
    );
};

export default ViewPODButton;
