export const MENUITEMS2 = [
    // {
    //     "_id": "5e4bdce18e94f52259e3356b",
    //     "code": "an",
    //     "name": "Dispatch Planning",
    //     "description": "All",
    //     "icon": "fa fa-random",
    //     "is_group_header": true,
    //     "group_heading": "Autometrics",
    //     "parent_code": null,
    //     "is_link": false,
    //     "path": null,
    //     "env_show": "prod",
    //     "active": false,
    //     "is_active": 1,
    //     "display_order": 0,
    //     "level1": "main",
    //     "created_datetime": "2020-02-18T12:16:14.000Z",
    //     "modified_datetime": "2020-02-18T12:42:14.000Z",
    //     "children": [
    //         {
    //             "_id": "5e4bdd748e94f52259e33742",
    //             "code": "an",
    //             "name": "Pickup/Drop Locations",
    //             "description": "Pickup/Drop Locations",
    //             "icon": "support",
    //             "is_group_header": false,
    //             "group_heading": null,
    //             "parent_code": "all",
    //             "is_link": true,
    //             "path": "/pickup_and_drop",
    //             "env_show": "prod",
    //             "active": false,
    //             "is_active": true,
    //             "display_order": 0,
    //             "level1": "All",
    //             "level2": "All",
    //             "created_datetime": "2020-04-20T11:09:38.532Z",
    //             "modified_datetime": "2020-04-20T11:09:38.532Z",
    //             "is_mobile": true,
    //             "children": []
    //         },

    //         {
    //             "_id": "5e4bdd748e94f52259e33742",
    //             "code": "an",
    //             "name": "Vehicle Configurations",
    //             "description": "Vehicle Configurations",
    //             "icon": "support",
    //             "is_group_header": false,
    //             "group_heading": null,
    //             "parent_code": "all",
    //             "is_link": true,
    //             "path": "/vehicles_data",
    //             "env_show": "prod",
    //             "active": false,
    //             "is_active": true,
    //             "display_order": 0,
    //             "level1": "All",
    //             "level2": "All",
    //             "created_datetime": "2020-04-20T11:09:38.532Z",
    //             "modified_datetime": "2020-04-20T11:09:38.532Z",
    //             "is_mobile": true,
    //             "children": []
    //         },

    //         {
    //             "_id": "5e4bdd748e94f52259e33742",
    //             "code": "an",
    //             "name": "Vehicle Availability",
    //             "description": "Vehicle Availability",
    //             "icon": "support",
    //             "is_group_header": false,
    //             "group_heading": null,
    //             "parent_code": "all",
    //             "is_link": true,
    //             "path": "/vehicle_availability",
    //             "env_show": "prod",
    //             "active": false,
    //             "is_active": true,
    //             "display_order": 0,
    //             "level1": "All",
    //             "level2": "All",
    //             "created_datetime": "2020-04-20T11:09:38.532Z",
    //             "modified_datetime": "2020-04-20T11:09:38.532Z",
    //             "is_mobile": true,
    //             "children": []
    //         },
    //         {
    //             "_id": "5e4bdd748e94f52259e33742",
    //             "code": "SKU Master",
    //             "name": "SKU Master",
    //             "description": "Consignments",
    //             "icon": "support",
    //             "is_group_header": false,
    //             "group_heading": null,
    //             "parent_code": "all",
    //             "is_link": true,
    //             "path": "/sku_master",
    //             "env_show": "prod",
    //             "active": false,
    //             "is_active": true,
    //             "display_order": 0,
    //             "level1": "All",
    //             "level2": "All",
    //             "created_datetime": "2020-04-20T11:09:38.532Z",
    //             "modified_datetime": "2020-04-20T11:09:38.532Z",
    //             "is_mobile": true,
    //             "children": []
    //         },
    //         {
    //             "_id": "5e4bdd748e94f52259e33742",
    //             "code": "dash",
    //             "name": "Transporters Configurations",
    //             "description": "Transporters Configurations",
    //             "icon": "support",
    //             "is_group_header": false,
    //             "group_heading": null,
    //             "parent_code": "all",
    //             "is_link": true,
    //             "path": "/transporter_details",
    //             "env_show": "prod",
    //             "active": false,
    //             "is_active": true,
    //             "display_order": 0,
    //             "level1": "All",
    //             "level2": "All",
    //             "created_datetime": "2020-04-20T11:09:38.532Z",
    //             "modified_datetime": "2020-04-20T11:09:38.532Z",
    //             "is_mobile": true,
    //             "children": []
    //         },
    //         {
    //             "_id": "5e4bdd748e94f52259e33742",
    //             "code": "an",
    //             "name": "Freight Configurations",
    //             "description": "Freight Configurations",
    //             "icon": "support",
    //             "is_group_header": false,
    //             "group_heading": null,
    //             "parent_code": "all",
    //             "is_link": true,
    //             "path": "/freightconfigurations",
    //             "env_show": "prod",
    //             "active": false,
    //             "is_active": true,
    //             "display_order": 0,
    //             "level1": "All",
    //             "level2": "All",
    //             "created_datetime": "2020-04-20T11:09:38.532Z",
    //             "modified_datetime": "2020-04-20T11:09:38.532Z",
    //             "is_mobile": true,
    //             "children": []
    //         },
    //         {
    //             "_id": "5e4bdd748e94f52259e33742",
    //             "code": "Consolidated Run Plan",
    //             "name": "Consolidated Run Plan",
    //             "description": "Consignments",
    //             "icon": "support",
    //             "is_group_header": false,
    //             "group_heading": null,
    //             "parent_code": "all",
    //             "is_link": true,
    //             "path": "/consolidatedrun",
    //             "env_show": "prod",
    //             "active": false,
    //             "is_active": true,
    //             "display_order": 0,
    //             "level1": "All",
    //             "level2": "All",
    //             "created_datetime": "2020-04-20T11:09:38.532Z",
    //             "modified_datetime": "2020-04-20T11:09:38.532Z",
    //             "is_mobile": true,
    //             "children": []
    //         },
    //         {
    //             "_id": "5e4bdd748e94f52259e33742",
    //             "code": "Order Management",
    //             "name": "Order Management",
    //             "description": "Transporters Configurations",
    //             "icon": "support",
    //             "is_group_header": false,
    //             "group_heading": null,
    //             "parent_code": "all",
    //             "is_link": true,
    //             "path": "/ordermanagement",
    //             "env_show": "prod",
    //             "active": false,
    //             "is_active": true,
    //             "display_order": 0,
    //             "level1": "All",
    //             "level2": "All",
    //             "created_datetime": "2020-04-20T11:09:38.532Z",
    //             "modified_datetime": "2020-04-20T11:09:38.532Z",
    //             "is_mobile": true,
    //             "children": []
    //         },
            
    //     ]
    // },
    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "all",
        "name": "Track & Trace",
        "description": "All",
        "icon": "icofont icofont-ui-browser",
        "is_group_header": false,
        "group_heading": "Autometrics",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "gps_integration_status",
                "name": "Consignments",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/activenewconsignments",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            
            {
                "_id": "5e4bdce18e94f52259e3356b",
                "code": "utilities",
                "name": "Utilities",
                "description": "All",
                "icon": "fa fa-cubes",
                "is_group_header": false,
                "group_heading": "Route Analytics",
                "parent_code": null,
                "is_link": false,
                "path": null,
                "env_show": "prod",
                "active": false,
                "is_active": 1,
                "display_order": 0,
                "level1": "main",
                "created_datetime": "2020-02-18T12:16:14.000Z",
                "modified_datetime": "2020-02-18T12:42:14.000Z",
                "children": [
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "utilities",
                        "name": "Vehicles Within Geofence",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/snddepartmentvicinity/sndg",
                        "env_show": "prod",
                    "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "utilities",
                        "name": "Vehicle Location(Map)",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/nearbysndtrucks",
                        "env_show": "prod",
                    "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "utilities",
                        "name": "Geofence Incursions",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/sndgeofence",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    // {
                    //     "_id": "5e4bdd748e94f52259e33742",
                    //     "code": "utilities",
                    //     "name": "GPS Integration Status",
                    //     "description": "Consignments",
                    //     "icon": "support",
                    //     "is_group_header": false,
                    //     "group_heading": null,
                    //     "parent_code": "all",
                    //     "is_link": true,
                    //     "path": "/tpttruckgpsstatus",
                    //     "env_show": "prod",
                    //     "active": false,
                    //     "is_active": true,
                    //     "display_order": 0,
                    //     "level1": "All",
                    //     "level2": "All",
                    //     "created_datetime": "2020-04-20T11:09:38.532Z",
                    //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                    //     "is_mobile": true,
                    //     "children": []
                    // },
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "utilities",
                        "name": "Dealer Unloading Locations",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/sndvicinity",
                        "env_show": "prod",
                    "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                
                ]
            },

            {
                "_id": "5e4bdce18e94f52259e3356b",
                "code": "all",
                "name": "Route Analytics",
                "description": "All",
                "icon": "icon icon-map-alt",
                "is_group_header": false,
                "group_heading": "Route Analytics",
                "parent_code": null,
                "is_link": false,
                "path": null,
                "env_show": "prod",
                "active": false,
                "is_active": 1,
                "display_order": 0,
                "level1": "main",
                "created_datetime": "2020-02-18T12:16:14.000Z",
                "modified_datetime": "2020-02-18T12:42:14.000Z",
                "children": [
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "gps_integration_status",
                        "name": "Optimized Routes",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/optimizeroutes",
                        "env_show": "prod",
                    "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "gps_integration_status",
                        "name": "Multi Trips Routes",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/dealerzonewiseroutes",
                        "env_show": "prod",
                    "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "gps_integration_status",
                        "name": "Base Routes Summary",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/deviationanalysisdata",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    // {
                    //     "_id": "5e4bdd748e94f52259e33742",
                    //     "code": "gps_integration_status",
                    //     "name": "SAP Routes",
                    //     "description": "Consignments",
                    //     "icon": "support",
                    //     "is_group_header": false,
                    //     "group_heading": null,
                    //     "parent_code": "all",
                    //     "is_link": true,
                    //     "path": "/saproutes",
                    //     "env_show": "prod",
                    //     "active": true,
                    //     "active": false,
                    //     "display_order": 0,
                    //     "level1": "All",
                    //     "level2": "All",
                    //     "created_datetime": "2020-04-20T11:09:38.532Z",
                    //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                    //     "is_mobile": true,
                    //     "children": []
                    // },
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "gps_integration_status",
                        "name": "Top Base Routes Summary",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/topbaseroutessummary",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                
                ]
            },

            {
                "_id": "5e4bdce18e94f52259e3356b",
                "code": "dash",
                "name": "Dashboards",
                "description": "All",
                "icon": "fa fa-pie-chart",
                "is_group_header": false,
                "group_heading": "Dashboard",
                "parent_code": null,
                "is_link": false,
                "path": null,
                "env_show": "prod",
                "active": false,
                "is_active": 1,
                "display_order": 0,
                "level1": "main",
                "created_datetime": "2020-02-18T12:16:14.000Z",
                "modified_datetime": "2020-02-18T12:42:14.000Z",
                "children": [
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "dash",
                        "name": "Loading Dashboard",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/sndloadingdashboard",
                        "env_show": "prod",
                    "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    // {
                    //     "_id": "5e4bdd748e94f52259e33742",
                    //     "code": "dash",
                    //     "name": "Unloading Dashboard",
                    //     "description": "Consignments",
                    //     "icon": "support",
                    //     "is_group_header": false,
                    //     "group_heading": null,
                    //     "parent_code": "all",
                    //     "is_link": true,
                    //     "path": "/sndunloadingdashboard",
                    //     "env_show": "prod",
                    // "active": false,
                    //     "is_active": true,
                    //     "display_order": 0,
                    //     "level1": "All",
                    //     "level2": "All",
                    //     "created_datetime": "2020-04-20T11:09:38.532Z",
                    //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                    //     "is_mobile": true,
                    //     "children": []
                    // },

                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "dash",
                        "name": "Daily Running Dashboard",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/dailyrunningdashboard",
                        "env_show": "prod",
                    "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "dash",
                        "name": "Transit Analytics",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/sndtransitanalytics",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "dash",
                        "name": "Transporter Dashboard",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/sndtransportdashboard",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    // {
                    //     "_id": "5e4bdd748e94f52259e33742",
                    //     "code": "dash",
                    //     "name": "Transporter Dashboard",
                    //     "description": "Consignments",
                    //     "icon": "support",
                    //     "is_group_header": false,
                    //     "group_heading": null,
                    //     "parent_code": "all",
                    //     "is_link": true,
                    //     "path": "/sndtransportdashboard",
                    //     "env_show": "prod",
                    //     "active": false,
                    //     "is_active": true,
                    //     "display_order": 0,
                    //     "level1": "All",
                    //     "level2": "All",
                    //     "created_datetime": "2020-04-20T11:09:38.532Z",
                    //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                    //     "is_mobile": true,
                    //     "children": []
                    // },


                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "dash",
                        "name": "Ontime Performance",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/ontimedashboard",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                
                
                
                ]
            },
            {
                "_id": "5e4bdce18e94f52259e3356b",
                "code": "all",
                "name": "Configuration",
                "description": "All",
                "icon": "fa fa-cogs",
                "is_group_header": false,
                "group_heading": "Configuration",
                "parent_code": null,
                "is_link": true,
                "path": "/configospd",
                "env_show": "prod",
                "active": false,
                "is_active": 1,
                "display_order": 0,
                "level1": "main",
                "created_datetime": "2020-02-18T12:16:14.000Z",
                "modified_datetime": "2020-02-18T12:42:14.000Z",
                // "children": [
                //     {
                //         "_id": "5e4bdd748e94f52259e33742",
                //         "code": "Configuration",
                //         "name": "Configuration",
                //         "description": "Configuration",
                //         "icon": "support",
                //         "is_group_header": false,
                //         "group_heading": null,
                //         "parent_code": "all",
                //         "is_link": true,
                //         "path": "/configospd",
                //         "env_show": "prod",
                //         "active": false,
                //         "is_active": true,
                //         "display_order": 0,
                //         "level1": "All",
                //         "level2": "All",
                //         "created_datetime": "2020-04-20T11:09:38.532Z",
                //         "modified_datetime": "2020-04-20T11:09:38.532Z",
                //         "is_mobile": true,
                //         "children": []
                //     },
                //     // {
                //     //     "_id": "5e4bdd748e94f52259e33742",
                //     //     "code": "gps_integration_status",
                //     //     "name": "Over Speeding",
                //     //     "description": "Consignments",
                //     //     "icon": "support",
                //     //     "is_group_header": false,
                //     //     "group_heading": null,
                //     //     "parent_code": "all",
                //     //     "is_link": true,
                //     //     "path": "/configospd",
                //     //     "env_show": "prod",
                //     //     "active": false,
                //     //     "is_active": true,
                //     //     "display_order": 0,
                //     //     "level1": "All",
                //     //     "level2": "All",
                //     //     "created_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "is_mobile": true,
                //     //     "children": []
                //     // },
                //     // {
                //     //     "_id": "5e4bdd748e94f52259e33742",
                //     //     "code": "gps_integration_status",
                //     //     "name": "No GPS Data",
                //     //     "description": "Consignments",
                //     //     "icon": "support",
                //     //     "is_group_header": false,
                //     //     "group_heading": null,
                //     //     "parent_code": "all",
                //     //     "is_link": true,
                //     //     "path": "/confignogps",
                //     //     "env_show": "prod",
                //     //     "active": false,
                //     //     "is_active": true,
                //     //     "display_order": 0,
                //     //     "level1": "All",
                //     //     "level2": "All",
                //     //     "created_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "is_mobile": true,
                //     //     "children": []
                //     // },
                //     // {
                //     //     "_id": "5e4bdd748e94f52259e33742",
                //     //     "code": "gps_integration_status",
                //     //     "name": "Enroute Stoppages",
                //     //     "description": "Consignments",
                //     //     "icon": "support",
                //     //     "is_group_header": false,
                //     //     "group_heading": null,
                //     //     "parent_code": "all",
                //     //     "is_link": true,
                //     //     "path": "/configstoppages",
                //     //     "env_show": "prod",
                //     //     "active": false,
                //     //     "is_active": true,
                //     //     "display_order": 0,
                //     //     "level1": "All",
                //     //     "level2": "All",
                //     //     "created_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "is_mobile": true,
                //     //     "children": []
                //     // },
                //     // {
                //     //     "_id": "5e4bdd748e94f52259e33742",
                //     //     "code": "gps_integration_status",
                //     //     "name": "Loading Delay",
                //     //     "description": "Consignments",
                //     //     "icon": "support",
                //     //     "is_group_header": false,
                //     //     "group_heading": null,
                //     //     "parent_code": "all",
                //     //     "is_link": true,
                //     //     "path": "/configldelay",
                //     //     "env_show": "prod",
                //     //     "active": false,
                //     //     "is_active": true,
                //     //     "display_order": 0,
                //     //     "level1": "All",
                //     //     "level2": "All",
                //     //     "created_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "is_mobile": true,
                //     //     "children": []
                //     // },
                //     // {
                //     //     "_id": "5e4bdd748e94f52259e33742",
                //     //     "code": "gps_integration_status",
                //     //     "name": "UnLoading Delay",
                //     //     "description": "Consignments",
                //     //     "icon": "support",
                //     //     "is_group_header": false,
                //     //     "group_heading": null,
                //     //     "parent_code": "all",
                //     //     "is_link": true,
                //     //     "path": "/configudelay",
                //     //     "env_show": "prod",
                //     //     "active": false,
                //     //     "is_active": true,
                //     //     "display_order": 0,
                //     //     "level1": "All",
                //     //     "level2": "All",
                //     //     "created_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                //     //     "is_mobile": true,
                //     //     "children": []
                //     // },
                
                // ]
            },

            {
                "_id": "5e4bdce18e94f52259e3356b",
                "code": "master",
                "name": "Masters",
                "description": "All",
                "icon": "fa fa-server",
                "is_group_header": false,
                "group_heading": "Masters",
                "parent_code": null,
                "is_link": false,
                "path": null,
                "env_show": "prod",
                "active": false,
                "is_active": 1,
                "display_order": 0,
                "level1": "main",
                "created_datetime": "2020-02-18T12:16:14.000Z",
                "modified_datetime": "2020-02-18T12:42:14.000Z",
                "children": [
                
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "master",
                        "name": "Transit Times",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/transittimes",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "master",
                        "name": "Geofence",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/geofence",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "master",
                        "name": "Destinations",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/dealers",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                    
                    // {
                    //     "_id": "5e4bdd748e94f52259e33742",
                    //     "code": "master",
                    //     "name": "Vehicle Master",
                    //     "description": "Consignments",
                    //     "icon": "support",
                    //     "is_group_header": false,
                    //     "group_heading": null,
                    //     "parent_code": "all",
                    //     "is_link": true,
                    //     "path": "/vehicles",
                    //     "env_show": "prod",
                    //     "active": false,
                    //     "is_active": true,
                    //     "display_order": 0,
                    //     "level1": "All",
                    //     "level2": "All",
                    //     "created_datetime": "2020-04-20T11:09:38.532Z",
                    //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                    //     "is_mobile": true,
                    //     "children": []
                    // },

                    
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "master",
                        "name": "Transporters",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/transporters",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                
                ]
            },



            {
                "_id": "5e4bdce18e94f52259e3356b",
                "code": "an",
                "name": "Reports",
                "description": "All",
                "icon": "fa fa-file",
                "is_group_header": false,
                "group_heading": "Reports",
                "parent_code": null,
                "is_link": false,
                "path": null,
                "env_show": "prod",
                "active": false,
                "is_active": 1,
                "display_order": 0,
                "level1": "main",
                "created_datetime": "2020-02-18T12:16:14.000Z",
                "modified_datetime": "2020-02-18T12:42:14.000Z",
                "children": [
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "an",
                        "name": "Night Driving Report",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/nightdriving",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "an",
                        "name": "Overspeed Report",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/overspeedreport",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },

                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "an",
                        "name": "Stoppage Report",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/stoppagereport",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    // {
                    //     "_id": "5e4bdd748e94f52259e33742",
                    //     "code": "dash",
                    //     "name": "Dealer Detention Report",
                    //     "description": "Consignments",
                    //     "icon": "support",
                    //     "is_group_header": false,
                    //     "group_heading": null,
                    //     "parent_code": "all",
                    //     "is_link": true,
                    //     "path": "/dealerDetentionReport",
                    //     "env_show": "prod",
                    //     "active": false,
                    //     "is_active": true,
                    //     "display_order": 0,
                    //     "level1": "All",
                    //     "level2": "All",
                    //     "created_datetime": "2020-04-20T11:09:38.532Z",
                    //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                    //     "is_mobile": true,
                    //     "children": []
                    // },
                    {
                        "_id": "5e4bdd748e94f52259e33742",
                        "code": "dash",
                        "name": "Day Wise Distance (Monthly)",
                        "description": "Consignments",
                        "icon": "support",
                        "is_group_header": false,
                        "group_heading": null,
                        "parent_code": "all",
                        "is_link": true,
                        "path": "/trucksdistancematrix",
                        "env_show": "prod",
                        "active": false,
                        "is_active": true,
                        "display_order": 0,
                        "level1": "All",
                        "level2": "All",
                        "created_datetime": "2020-04-20T11:09:38.532Z",
                        "modified_datetime": "2020-04-20T11:09:38.532Z",
                        "is_mobile": true,
                        "children": []
                    },
                    // {
                    //     "_id": "5e4bdd748e94f52259e33742",
                    //     "code": "an",
                    //     "name": "GPS Uptime Report",
                    //     "description": "Consignments",
                    //     "icon": "support",
                    //     "is_group_header": false,
                    //     "group_heading": null,
                    //     "parent_code": "all",
                    //     "is_link": true,
                    //     "path": "/gpsuptimereport",
                    //     "env_show": "prod",
                    //     "active": false,
                    //     "is_active": true,
                    //     "display_order": 0,
                    //     "level1": "All",
                    //     "level2": "All",
                    //     "created_datetime": "2020-04-20T11:09:38.532Z",
                    //     "modified_datetime": "2020-04-20T11:09:38.532Z",
                    //     "is_mobile": true,
                    //     "children": []
                    // },
                ]
            },


            // {
            //     "_id": "5e4bdce18e94f52259e3356b",
            //     "code": "st",
            //     "name": "Sim Tracking",
            //     "description": "All",
            //     "icon": "support",
            //     "is_group_header": true,
            //     "group_heading": "Sim Tracking",
            //     "parent_code": null,
            //     "is_link": false,
            //     "path": null,
            //     "env_show": "prod",
            //     "active": false,
            //     "is_active": 1,
            //     "display_order": 0,
            //     "level1": "main",
            //     "created_datetime": "2020-02-18T12:16:14.000Z",
            //     "modified_datetime": "2020-02-18T12:42:14.000Z",
            //     "children": [
            //         {
            //             "_id": "5e4bdd748e94f52259e33742",
            //             "code": "st",
            //             "name": "Sim Tracking",
            //             "description": "Consignments",
            //             "icon": "support",
            //             "is_group_header": false,
            //             "group_heading": null,
            //             "parent_code": "all",
            //             "is_link": true,
            //             "path": "/simtrackingdrivers",
            //             "env_show": "prod",
            //             "active": false,
            //             "is_active": true,
            //             "display_order": 0,
            //             "level1": "All",
            //             "level2": "All",
            //             "created_datetime": "2020-04-20T11:09:38.532Z",
            //             "modified_datetime": "2020-04-20T11:09:38.532Z",
            //             "is_mobile": true,
            //             "children": []
            //         },

                
                
                
            //     ]
            // },


            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "e_pod",
                "name": "E-Pod",
                "description": "All",
                "icon": "icofont icofont-law-document",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": null,
                "is_link": true,
                "path": "/epod",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "main",
                "created_datetime": "2020-02-18T12:16:14.000Z",
                "modified_datetime": "2020-02-18T12:42:14.000Z"
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "tms",
                "name": "Ticket Management System",
                "description": "Ticket Management System",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": null,
                "is_link": true,
                "path": "/ticketmanagementsystem",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "main",
                "created_datetime": "2020-02-18T12:16:14.000Z",
                "modified_datetime": "2020-02-18T12:42:14.000Z"
            },
            // {
            //     "_id": "5e4bdce18e94f52259e3356b",
            //     "code": "tms",
            //     "name": "SIM Tracking",
            //     "description": "All",
            //     "icon": "fa fa-mobile",
            //     "is_group_header": false,
            //     "group_heading": "SIM Tracking",
            //     "parent_code": null,
            //     "is_link": true,
            //     "path": "/simtrackingdrivers",
            //     "env_show": "prod",
            //     "active": false,
            //     "is_active": 1,
            //     "display_order": 0,
            //     "level1": "main",
            //     "created_datetime": "2020-02-18T12:16:14.000Z",
            //     "modified_datetime": "2020-02-18T12:42:14.000Z"
            // },
            

            // {
            //     "_id": "5e4bdce18e94f52259e3356b",
            //     "code": "eway",
            //     "name": "E-wayBills",
            //     "description": "All",
            //     "icon": "support",
            //     "is_group_header": true,
            //     "group_heading": "E-WayBills",
            //     "parent_code": null,
            //     "is_link": false,
            //     "path": null,
            //     "env_show": "prod",
            //     "active": false,
            //     "is_active": 1,
            //     "display_order": 0,
            //     "level1": "main",
            //     "created_datetime": "2020-02-18T12:16:14.000Z",
            //     "modified_datetime": "2020-02-18T12:42:14.000Z",
            //     "children": [
            //         {
            //             "_id": "5e4bdd748e94f52259e33742",
            //             "code": "eway",
            //             "name": "Generate New",
            //             "description": "Consignments",
            //             "icon": "support",
            //             "is_group_header": false,
            //             "group_heading": null,
            //             "parent_code": "all",
            //             "is_link": true,
            //             "path": "/generatenewewaybill",
            //             "env_show": "prod",
            //            "active": false,
            //             "is_active": true,
            //             "display_order": 0,
            //             "level1": "All",
            //             "level2": "All",
            //             "created_datetime": "2020-04-20T11:09:38.532Z",
            //             "modified_datetime": "2020-04-20T11:09:38.532Z",
            //             "is_mobile": true,
            //             "children": []
            //         },

            //         {
            //             "_id": "5e4bdd748e94f52259e33742",
            //             "code": "eway",
            //             "name": "E-Way Bill Reports",
            //             "description": "Consignments",
            //             "icon": "support",
            //             "is_group_header": false,
            //             "group_heading": null,
            //             "parent_code": "all",
            //             "is_link": true,
        //             "path": "/ewaybilldata/1",
        //             "env_show": "prod",
        //            "active": false,
        //             "is_active": true,
        //             "display_order": 0,
        //             "level1": "All",
        //             "level2": "All",
        //             "created_datetime": "2020-04-20T11:09:38.532Z",
        //             "modified_datetime": "2020-04-20T11:09:38.532Z",
        //             "is_mobile": true,
        //             "children": []
        //         },
        //         {
        //             "_id": "5e4bdd748e94f52259e33742",
        //             "code": "eway",
        //             "name": "E-Waybill Requests",
        //             "description": "Consignments",
        //             "icon": "support",
        //             "is_group_header": false,
        //             "group_heading": null,
        //             "parent_code": "all",
        //             "is_link": true,
        //             "path": "/ewaybillrequests",
        //             "env_show": "prod",
        //             "active": false,
        //             "is_active": true,
        //             "display_order": 0,
        //             "level1": "All",
        //             "level2": "All",
        //             "created_datetime": "2020-04-20T11:09:38.532Z",
        //             "modified_datetime": "2020-04-20T11:09:38.532Z",
        //             "is_mobile": true,
        //             "children": []
        //         },

        //         {
        //             "_id": "5e4bdd748e94f52259e33742",
        //             "code": "eway",
        //             "name": " Consolidate E-Way Bills",
        //             "description": "Consignments",
        //             "icon": "support",
        //             "is_group_header": false,
        //             "group_heading": null,
        //             "parent_code": "all",
        //             "is_link": true,
        //             "path": "/consolidateewaybill",
        //             "env_show": "prod",
        //             "active": false,
        //             "is_active": true,
        //             "display_order": 0,
        //             "level1": "All",
        //             "level2": "All",
        //             "created_datetime": "2020-04-20T11:09:38.532Z",
        //             "modified_datetime": "2020-04-20T11:09:38.532Z",
        //             "is_mobile": true,
        //             "children": []
        //         },
        //         {
        //             "_id": "5e4bdd748e94f52259e33742",
        //             "code": "eway",
        //             "name": "Consolidate Requests",
        //             "description": "Consignments",
        //             "icon": "support",
        //             "is_group_header": false,
        //             "group_heading": null,
        //             "parent_code": "all",
        //             "is_link": true,
        //             "path": "/consolidateewaybillrequests",
        //             "env_show": "prod",
        //             "active": false,
        //             "is_active": true,
        //             "display_order": 0,
        //             "level1": "All",
        //             "level2": "All",
        //             "created_datetime": "2020-04-20T11:09:38.532Z",
        //             "modified_datetime": "2020-04-20T11:09:38.532Z",
        //             "is_mobile": true,
        //             "children": []
        //         },

        //         {
        //             "_id": "5e4bdd748e94f52259e33742",
        //             "code": "eway",
        //             "name": "EwayBill Consolidated Reports",
        //             "description": "Consignments",
        //             "icon": "support",
        //             "is_group_header": false,
        //             "group_heading": null,
        //             "parent_code": "all",
        //             "is_link": true,
        //             "path": "/ewaybillreport",
        //             "env_show": "prod",
        //             "active": false,
        //             "is_active": true,
        //             "display_order": 0,
        //             "level1": "All",
        //             "level2": "All",
        //             "created_datetime": "2020-04-20T11:09:38.532Z",
        //             "modified_datetime": "2020-04-20T11:09:38.532Z",
        //             "is_mobile": true,
        //             "children": []
        //         },
            
            
        //     ]
        // },
        
        ]
    },
    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "an",
        "name": "Freight Management",
        "description": "All",
        "icon": "fa fa-truck",
        "is_group_header": false,
        "group_heading": "Freight Management",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Configurations",
                "description": "Configurations",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/freight-configurations",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Diesel Prices",
                "description": "Diesel Prices",
                "icon": "fa fa-tint",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/dieselprices",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Freight Rates",
                "description": "Freight Rates",
                "icon": "fa fa-truck",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/freightrates",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Freight Approvals",
                "description": "Freight Approvals",
                "icon": "fa fa-truck",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/dieselpriceapprovals",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "User Access",
                "description": "User Access",
                "icon": "fa fa-users",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/user-access",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Transporters GST",
                "description": "Transporters GST",
                "icon": "fa fa-users",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/transporter-gst",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Bill verification",
                "description": "Bill verification",
                "icon": "fa fa-truck",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/trips",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

        ]
    },
    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "an",
        "name": "RFQ",
        "description": "All",
        "icon": "fa fa-gavel",
        "is_group_header": false,
        "group_heading": "RFQ",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Admin Dashboard",
                "description": "Admin Dashboard",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/admin-dashboard",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Request for Quotation",
                "description": "Request for Quotation",
                "icon": "fa fa-tint",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/request-quotation",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Register Participants",
                "description": "Request Participants",
                "icon": "fa fa-users",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/vendorRegistration",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Reverse Auction",
                "description": "Reverse Auction",
                "icon": "fa fa-truck",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/reverse-auction",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            
        ]
    },


    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "ct",
        "name": "Container Tracking",
        "description": "All",
        "icon": "fa fa-ship",
        "is_group_header": false,
        "group_heading": "Container Tracking",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "ct1",
                "name": "Tracking",
                "description": "Tracking",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/container-tracking",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "ct2",
                "name": "Create Shipment",
                "description": "Create Shipment",
                "icon": "fa fa-tint",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/create-shipment",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Add Clients",
                "description": "Add Clients",
                "icon": "fa fa-users",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/clients",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "User Management",
                "description": "User Management",
                "icon": "fa fa-truck",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/users",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            
        ]
    },

]