import { React, useEffect, useState } from 'react';
import AgGrid from "./AgGrid"
import { columnwithDefs } from "./columnDefs_vehicles";
import Constants from "./Masters_constants";
import redirectURL from "../redirectURL";
// import EditItem from './EditItem'
import SweetAlert from 'react-bootstrap-sweetalert';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import { format } from 'date-fns';
import Favourites from "../common/favouritesComponent";

const niprocols = [
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        width: 240,
        filter: true,

    },

    {
        headerName: "Transporter Code",
        field: "transporter_code",
        width: 240,
        filter: true,

    },
    // {
    //     headerName: "Plant Code",
    //     field: "location_code",
    //     width: 240,
    //     filter: true,

    // },
    // {
    //     headerName: "Plant Name",
    //     field: "location_name",
    //     width: 240,
    //     filter: true,

    // },
    // {
    //     headerName: "Created Date",
    //     field: "created_date",
    //     width: 220,
    //     type: "datetime",
    //     filter: true,
    //     valueGetter: (params) => {
    //         if (!params.value) return '';
    //         const date = new Date(params.value);
    //         return format(date, 'dd-MM-yyyy');
    //     },
    // }

];

function Masters() {
    var [rowData, setRowData] = useState([])
    var [columnDefs, setColumnDefs] = useState(niprocols)
    // var [showloaderbg, setshowloaderbg] = useState("show-m");
    var [showloader, setshowloader] = useState("show-m");
    var [showAddSlider, setshowAddSlider] = useState("");
    var [overlay, setoverlay] = useState("show-n");
    var [sliderText, setSliderText] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    var [showEditSlider, setshowEditSlider] = useState("");
    var [transporter_name, settransportername] = useState("");
    var [transporter_code, settransportercode] = useState("");
    var [is_active, setstatus] = useState("");

    var [show, setShow] = useState(false);
    var [basicType, setBasicType] = useState("");
    var [basicTitle, setBasicTitle] = useState("");

    const getCurrentFormattedDate = () => {
        const date = new Date();
        return format(date, 'dd-MM-yyyy');
    };

    const getCurrentISODate = () => {
        const date = new Date();
        return date.toISOString();
    };

    useEffect(() => {
        setTimeout(() => {
            // setshowloaderbg("show-n")
            setshowloader("show-n")
        }, 2000)
        loadData();

        // 
    }, [])

    var loadData = async () => {
        var parameters = {}
        await redirectURL.post('tracking/transporterlist', parameters)
            .then(response => {
                if (response.data.status == "success") {
                    setRowData(response.data.records);
                }
                // setshowloaderbg("show-n");
                setshowloader("show-n");

            })
            .catch(error => console.error('Error fetching data:', error));
    }

    var showAddBlock = async (rownode) => {
        setshowAddSlider("slider-translate-30p");
        setoverlay("show-m")
        setSliderText("Add Transporter");
        setIsEditMode(false)
    }

    var hideAll = () => {
        setoverlay("show-n");
        setshowEditSlider("");
        setshowAddSlider("");
    }


    var saveMasterForm = async (e) => {
        e.preventDefault();
        let user_email = localStorage.getItem('email');
        // setshowloaderbg("show-m");
        const createdDate = getCurrentISODate();
        setshowloader("show-m")
        var params = {
            transporter_name: transporter_name,
            transporter_code: transporter_code,
            // is_active: is_active,
            created_date: createdDate,

        }
        // console.log(params, "params");
        // console.log(isEditMode, "edit mode");
        if (isEditMode) {
            await redirectURL.post('/updatetransporterdata', params).then((resp) => {
                console.log(resp, "edit response");
                if (resp.data.status == "Success") {
                    setoverlay("show-n");
                    setshowAddSlider("");
                    settransportercode("");
                    settransportername("");
                    setstatus("");
                    setIsEditMode(false);
                    setShow(true);
                    setBasicTitle("Updated data");
                    setBasicType("success");
                }
            });
        } else {
            await redirectURL.post('tracking/addtransporter', params).then((resp) => {
                if (resp.data.status == "success") {
                    setoverlay("show-n");
                    setshowAddSlider("");
                    settransportercode("");
                    settransportername("");
                    setstatus("");
                    setIsEditMode(false);
                    setShow(true);
                    setBasicTitle("Added data");
                    setBasicType("success");
                    loadData();
                } else if (resp.data.status === "failure") {
                    setoverlay("show-n");
                    setshowloader("show-n");
                    setIsEditMode(false);
                    setshowAddSlider("");
                    settransportercode("");
                    settransportername("");
                    setstatus("");
                    setShow(true);
                    setBasicTitle(resp.data.message);
                    setBasicType("danger");
                }
            }).catch(err => {
            });
        }
    };

    var handleTransporterName = (e) => {
        var inputValue = e.target.value;
        if (inputValue != "") {
            var isValid = /^[A-Za-z ]*$/;
            if (isValid.test(inputValue)) {
                if (inputValue.length <= 100) {
                    settransportername(inputValue)
                }
            }
        }
        else {
            settransportername("");
        }
    };


    var handleTransporterCode = (e) => {
        if (isEditMode) return;

        var inputValue = e.target.value;
        var numericInput = inputValue.replace(/[^0-9A-Z]/g, '');

        if (numericInput !== "") {
            if (numericInput.length <= 10) {
                settransportercode(numericInput);
            } else {
                settransportercode(numericInput.substring(0, 10));
            }
        } else {
            settransportercode("");
        }
    }


    return (
        <div>
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={() => {
                        setShow(false)
                    }}>
                </SweetAlert>


            </div>
            <div className="container-fluid">
                <div className="row">
                    <h5 class="col-sm-12 mb-15p mt-15p fbold f20">
						<i class="icofont icofont-vehicle-delivery-van cus-i"></i>&nbsp;
						<span>Transporters</span>
                        <Favourites />
                        <button className="btn btn-warning pull-right f12" onClick={showAddBlock}>Add Transporter</button>
                                       
					</h5>

                    <div className="col-xl-12 col-md-12 xl-60">
                        <div className="card">
                            <div className="card-body">


                                <AgGrid
                                    rowData={rowData}
                                    //  columnDefs={columnwithDefs}
                                    columnDefs={columnDefs}
                                    currentPage={window.location.pathname}
                                    others={""}
                                    // onClickEditBlock={onClickShowEditItem}
                                    saveoptions={"N"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"overlay-part " + (overlay)} onClick={hideAll}></div>

            <div className={"slide-r " + showAddSlider}>
                <div className="slide-r-title">
                    <h4 className="">{sliderText}
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={hideAll} >X</span>

                    </h4>

                </div>
                <div className="slide-r-body">

                    <form className="" style={{ padding: "20px" }} onSubmit={saveMasterForm}>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Transporter Name<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" name="transporter_name" value={transporter_name} style={{
                                width: "100%",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                backgroundColor: "#f9f9f9",
                            }} onChange={handleTransporterName} required />
                        </div>

                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>Transporter Code<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="transporter_code"
                                id="transporter_code"
                                value={transporter_code}
                                onChange={handleTransporterCode}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div>

                        <div className="form-group" style={{ textAlign: "center" }}>
                            <button type="submit" className="btn btn-success"
                                style={{
                                    padding: "10px 20px",
                                    border: "none",
                                    borderRadius: "4px",
                                    backgroundColor: "#28a745",
                                    color: "white",
                                }}
                            >Add Transporter</button>
                        </div>

                    </form>
                </div>

            </div>


        </div>
    );
}

export default Masters;

