import React, { useEffect, useRef, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { countries } from "country-flags-svg";
import moment from "moment";


const EximGrid = ({ gridData, bl_data, tabActive, container_data, paginationSize, all_bl_data, all_container_data }) => {
  console.log("eximgrid", paginationSize, tabActive, bl_data, container_data)

  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return {
        img: srcFlag[0]?.flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };

  const gridRef = useRef();
  const flagImage = prop => (prop.value.countryFlag == "" || prop.value.countryFlag == undefined) ? <>NA</> :
    <><img src={prop.value.countryFlag} alt="SRC" className="flag_class" />&nbsp;&nbsp;{prop.value.countryName}</>
  const [state, setState] = useState({
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      pivot: true,
      enableValue: true,
      enableRowGroup: false,
    },
    rowData: gridData,
    columnDefs: [
      {
        headerName: "Container No",
        field: "container_no",
        filter: true,
        width: 130,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.container_no === "") ? "NA" : params.data.container_no;
          } else {
            return "NA";
          }
        },
      },
      // {
      //   headerName: "BL No",
      //   field: "bl_no",
      //   filter: true,
      //   width: 130,
      // },
      {
        headerName: "Ocean Line",
        field: "shipping_line",
        filter: true,
        width: 130,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.shipping_line === "") ? "NA" : params.data.shipping_line;
          } else {
            return "NA";
          }
        },
      },
      // {
      //   headerName: "Tracking Type",
      //   field: "tracking_type",
      //   filter: true,
      //   width: 180,
      // },
      {
        headerName: "Booking No",
        field: "booking_number",
        filter: true,
        width: 150,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.booking_number === "") ? "NA" : params.data.booking_number;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Shipment No",
        field: "shipment_no",
        filter: true,
        width: 150,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.shipment_no === "") ? "NA" : params.data.shipment_no;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Origin",
        field: "origin",
        filter: true,
        width: 200,
        valueGetter: function (params) {
          if (params) {
            return (params.data.origin === "") ? "NA" : params.data.origin;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Destination",
        field: "destination",
        filter: true,
        width: 200,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.destination === "") ? "NA" : params.data.destination;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "ETD",
        field: "etd",
        filter: true,
        width: 150,
        resizable: true,
      },
      {
        headerName: "ATD",
        field: "atd",
        filter: true,
        width: 150,
        resizable: true,
      },
      {
        headerName: "ETA",
        field: "eta",
        filter: true,
        width: 150,
        resizable: true,
      },
      {
        headerName: "ATA",
        field: "ata",
        filter: true,
        width: 150,
        resizable: true,
      },
    ],
    bl_columnDefs: [
      {
        headerName: "BL No",
        field: "mbl_number",
        filter: true,
        width: 130,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.mbl_number === "" ? "NA" : params.data.mbl_number);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Total Containers",
        field: "total_containers",
        filter: true,
        width: 130,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.total_containers === "" ? "NA" : params.data.total_containers);
          } else {
            return "";
          }
        },
      },
      // {
      //   headerName: "Delyed Container",
      //   field: "container_no",
      //   filter: true,
      //   width: 130,
      // },
      {
        headerName: "Ocean Line",
        field: "shipping_line",
        filter: true,
        width: 130,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.shipping_line === "" ? "NA" : params.data.shipping_line);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Origin Country",
        field: "loading_port",
        filter: true,
        width: 180,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            let countryFlag = getFlagIcon(params.data.loading_port_code.slice(0, 2))
            return (countryFlag === null ? "NA" : { countryFlag: countryFlag?.img, countryName: countryFlag?.name });
          } else {
            return "";
          }
        },
        cellRenderer: flagImage,
      },
      {
        headerName: "Destination Country",
        field: "discharge_port",
        filter: true,
        resizable: true,
        width: 180,
        valueGetter: function (params) {
          if (params) {
            let countryFlag = getFlagIcon(params.data.discharge_port_code.slice(0, 2))
            return (countryFlag === null ? "NA" : { countryFlag: countryFlag?.img, countryName: countryFlag?.name });
          } else {
            return "";
          }
        },
        cellRenderer: flagImage,
      },
      {
        headerName: "Last Event",
        field: "event_name",
        filter: true,
        resizable: true,
        width: 250,
        valueGetter: function (params) {
          if (params) {
            if (params.data.status === "NEW")
              return "Newly Added"
            else if (params.data.status === "UNTRACKABLE")
              return "Untrackable"
            return params.data.last_event_data[0].event_name;
            // return "Event name";
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Event Status",
        field: "event_status",
        filter: true,
        resizable: true,
        width: 150,
        valueGetter: function (params) {
          if (params) {
            return (params.data.last_event_data[0].status === "" ? "NA" : params.data.last_event_data[0].status);
            // return ""
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Tracking Update",
        field: "tracking_updated_at",
        filter: true,
        resizable: true,
        width: 200,
        comparator: (date1, date2) => {
          if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
            let d1 = moment(date1, 'DD/MM/YYYY HH:mm')
            let d2 = moment(date2, 'DD/MM/YYYY HH:mm')
            return d2.isBefore(d1) ? 1 : -1
          }
        },
        valueGetter: function (params) {
          if (params) {
            if (params.data.status === "NEW" || params.data.status === "UNTRACKABLE")
              return "NA"
            return moment
              .parseZone(params.data.tracking_updated_at)
              .utcOffset("+05:30")
              .format("DD/MM/YYYY HH:mm");
          } else {
            return "";
          }
        },
      },
      // {
      //   headerName: "Event Status",
      //   field: "",
      //   filter: true,
      //   width: 150,
      //   valueGetter: function (params) {
      //     if (params) {
      //       if()
      //       return moment.parseZone(params.data.tracking_updated_at).utcOffset("+05:30").format("DD/MM/YYYY HH:mm")
      //       // return params.data.last_event_data[0].status;
      //       // return ""
      //     } else {
      //       return "";
      //     }
      //   },
      // },
    ],
    container_columnDefs: [
      {
        headerName: "Container No",
        field: "container_no",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.container_no === "") ? "NA" : params.data.container_no;
          } else {
            return "";
          }
        },
      },
      {
        headerName: "BL No",
        field: "mbl_number",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.mbl_number === "") ? "NA" : params.data.mbl_number;
          } else {
            return "";
          }
        },
      },

      {
        headerName: "Booking No",
        field: "booking_no",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.booking_no === "") ? "NA" : params.data.booking_no;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Ocean Line",
        field: "shipping_line",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.shipping_line === "") ? "NA" : params.data.shipping_line;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Origin Country",
        field: "loading_port",
        filter: true,
        width: 180,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            let countryFlag = getFlagIcon(params.data.loading_port_code.slice(0, 2))
            return (countryFlag === null ? "NA" : { countryFlag: countryFlag?.img, countryName: countryFlag?.name });
          } else {
            return "";
          }
        },
        cellRenderer: flagImage,
      },
      {
        headerName: "Destination Country",
        field: "discharge_port",
        filter: true,
        width: 180,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            let countryFlag = getFlagIcon(params.data.discharge_port_code.slice(0, 2))
            return (countryFlag === null ? "NA" : { countryFlag: countryFlag?.img, countryName: countryFlag?.name });
          } else {
            return "";
          }
        },
        cellRenderer: flagImage,
      },
      {
        headerName: "Last Event",
        field: "event_name",
        filter: true,
        resizable: true,
        width: 250,
        valueGetter: function (params) {
          if (params) {
            if (params.data.status === "NEW")
              return "Newly Added"
            else if (params.data.status === "UNTRACKABLE")
              return "Untrackable"
            return (params.data.last_event_data[0].event_name === "" ? "NA" : params.data.last_event_data[0].event_name);
            // return "Event name";
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Event Status",
        field: "event_status",
        filter: true,
        resizable: true,
        width: 150,
        valueGetter: function (params) {
          if (params) {
            return (params.data.last_event_data[0].status === "" ? "NA" : params.data.last_event_data[0].status);
            // return ""
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Tracking Update",
        field: "tracking_updated_at",
        filter: true,
        width: 200,
        resizable: true,
        comparator: (date1, date2) => {
          if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
            let d1 = moment(date1, 'DD/MM/YYYY HH:mm')
            let d2 = moment(date2, 'DD/MM/YYYY HH:mm')
            return d2.isBefore(d1) ? 1 : -1
          }
        },
        valueGetter: function (params) {
          if (params) {
            if (params.data.status === "NEW" || params.data.status === "UNTRACKABLE")
              return "NA"
            return moment
              .parseZone(params.data.tracking_updated_at)
              .utcOffset("+05:30")
              .format("DD/MM/YYYY HH:mm");
          } else {
            return "NA";
          }
        },
      },
      // {
      //   headerName: "Event Status",
      //   field: "",
      //   filter: true,
      //   width: 150,
      //   valueGetter: function (params) {
      //     if (params) {
      //       if()
      //       return moment.parseZone(params.data.tracking_updated_at).utcOffset("+05:30").format("DD/MM/YYYY HH:mm")
      //       // return params.data.last_event_data[0].status;
      //       // return ""
      //     } else {
      //       return "";
      //     }
      //   },
      // },
    ],
  });

  // useEffect(()=>{
  //   setState({
  //     ...state,
  //     paginationSize: paginationSize
  //   })
  // },[paginationSize])


  useEffect(() => {

  }, [bl_data, container_data])


  const onGridReady = (params) => {
    const gridApi = params.api;
    const gridColumnApi = params.columnApi;
    console.log("Grid is ready");
  };

  const renderCellClick = (rowData) => {
    console.log(rowData);
  };
  const gridOptions = {
    getRowStyle: params => ({ verticalAlign: 'middle' }),
    getCellStyle: params => ({ verticalAlign: 'middle' }),
    // other grid options...
  };
  return (
    <div
      id="container-grid"
      className="ag-theme-alpine mb-3 txt-center"
      style={{ width: "100%", height: "400px" }}
    >
      <AgGridReact
        // rowHeight={rowHeight}
        defaultColDef={state.defaultColDef}
        pagination={true}
        gridOptions={gridOptions}
        ref={gridRef}
        rowData={tabActive === "BL/Booking" ? bl_data : container_data}
        // columnDefs={state.columnDefs}
        columnDefs={
          tabActive === "BL/Booking" ? state.bl_columnDefs : state.container_columnDefs
        }
        rowHeight={70}
        animateRows={true}
        //paginationPageSize={parseInt((tabActive === "BL/Booking" ? bl_data : container_data).length)}
        paginationPageSize={paginationSize.value == 0 ? 50 : paginationSize.value}
        suppressCellFocus={true}
        suppressMovableColumns={true}
        allowScrolling={true}
        showNoRowsOverlay={false}
        suppressRowHoverHighlight={true}
        onGridReady={onGridReady}
        onCellClicked={(e) => {
          renderCellClick(e.data);
        }}
      />
    </div>
  );
};

export default EximGrid;
