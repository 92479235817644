import react,{Component} from 'react';
// import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import { logFormChanges  } from "./auditLogfunction";

export default class SKUMaster extends Component{
    constructor(props){
        super(props);
        this.state={
            // modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            // location:this.props.plantLocation,
            rowId:'',
            previousData:"",
            item_id:'',
            item_description:'',
            item_weight_per_unit:'',
            item_volume_per_unit:'',
        }
    }

    async componentDidMount(){
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        // const {plantLocation} = this.props
        await this.setState({
            data_type : data_type,
        // location:plantLocation
        })
        
        await this.getSkuMasterData(data_type)

    }

    getSkuMasterData = (data_type) =>{
        let params={
            data_type:data_type
        }
        redirectURL.post('/master/getSkuMasterData',params)
        .then(res=>{
            if(res.data.skuData){
                this.setState({
                rowData:res.data.skuData
            })
            }
        })
    }

    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    editMaterialConfiguration = async(propdata) =>{
        let data = propdata.data
        // var previousData={
        //     "vehicle_type" : data.vehicle_type,
        //     "fixed_vehicle_cost" : data.fixed_vehicle_cost,            
        // }
        await this.setState({
            
            "rowId" : data._id,
            item_id:data.item_id,
            item_description:data.item_description,
            item_weight_per_unit:data.item_weight_per_unit,
            item_volume_per_unit:data.item_volume_per_unit
            // previousData:previousData
        })
        this.openModelForMaterialConfiguration()
    }

    materialDetailsInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            rowId:'',
            item_id:'',
            item_description:'',
            item_weight_per_unit:'',
            item_volume_per_unit:''
        }))

    }

    formMaterialDetails = (e)=>{
        e.preventDefault();
        
        let data_type = this.state.data_type;
        // let location = this.state.location;
        let item_id = this.state.item_id;
        let item_description = this.state.item_description;
        let item_weight_per_unit = this.state.item_weight_per_unit;
        let item_volume_per_unit = this.state.item_volume_per_unit;
        let rowId = this.state.rowId;

        let params = {
            item_id:item_id,
            item_description:item_description,
            item_weight_per_unit:parseFloat(item_weight_per_unit),
            item_volume_per_unit:parseFloat(item_volume_per_unit),
            data_type : data_type,
            // location_code : location.value,
            // location_name : location.label,
        }

        if(rowId != ''){

            params.row_id = rowId
            
                this.updateSkuDetails(params)
        }else{
                this.saveSkuDetails(params)   
        }
    }

    saveSkuDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveSkuDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getSkuMasterData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        item_id:'',
                        item_description:'',
                        item_weight_per_unit:'',
                        item_volume_per_unit:''
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    updateSkuDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/updateSkuDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                let logParams = {
                    "user_name": localStorage.getItem("username"),
                    "useremail": localStorage.getItem("email"),
                    "client": localStorage.getItem("client"),
                    "screen": "SKU Master",
                    // "location_code":this.state.location.value,
                    "activity":{}
                }
                // let previousData = this.state.previousData
                // logFormChanges(logParams,params,previousData)

                this.getSkuMasterData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        item_id:'',
                        item_description:'',
                        item_weight_per_unit:'',
                        item_volume_per_unit:'',
                        rowId:'',
                        previousData:""
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }

    render(){

        var colsDefs=[
            {
                headerName:"Code",
                field:"item_id",
                width:"120"
            },
            {
                headerName:"Description",
                field:"item_description",
                width:"230"
            },
            {
                headerName:"Weight (Kgs)",
                field:"item_weight_per_unit",
                width:"130"
            },
            {
                headerName:"Volume (M³)",
                field:"item_volume_per_unit",
                width:"130"
            },
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.editMaterialConfiguration},
                cellRenderer:GridButton,
            },
            // {
            //     headerName:"",
            //     field:"cluster",
            //     width:"80",
            //     pinned:'left',
            //     params:{buttonName:'Delete',iconName:"fa fa-trash",
            //             onClickFunction:this.deleteFixedVehicleCostDetails},
            //     cellRendererSelector:function(params){
                    
            //         var rendComponent = {
            //             component: "GridButton"
            //         };
            //         return rendComponent
                
            //     },
            // },
        ]
        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
            <div className="row">
                <div className="col-sm-12">
                                    
                    <h5 className='fbold  d-flex justify-content-between'>
                        <span>SKU Details</span>
                        <div className='float-right'>
                            <a href="javascript:;" onClick={this.openModelForMaterialConfiguration} className="btn btn-default f12 float-right themefont fbold mr-10p mb-10p">+ Add SKU Details </a>
                        </div> 

                    </h5>
                    <div className="card">
                                                
                        <div className="card-body p-15px
                        ">
                            <div id="myGrid" style={{width:"100%",height:"70vh"}} className="ag-theme-balham dropdown">
                            <AgGridReact
                            // modules={this.state.modules} 
                                rowData={this.state.rowData}
                                columnDefs={colsDefs}
                                gridOptions={{context:{componentParent:this}}}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                enableRangeSelection={true}
                                
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>SKU Details</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formMaterialDetails}>

                        <div className="row number">
                        {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div> */}

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_TYPE}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.vehicleTypeChanged}
                                        name="vehicle_type"
                                        value={this.state.vehicle_type}
                                        options={this.state.vehicleTypeList} 
                                        />

                                </div>
                            </div> */}

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Code </label>
                                    <input required type="text"   className="forminp form-control" id="item_id" name="item_id" value={this.state.item_id} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Description</label>
                                    <input required type="text"  className="forminp form-control" id="item_description" name="item_description" value={this.state.item_description} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Weight (Kgs)</label>
                                    <input required type="number" min='0' step={0.0001} className="forminp form-control" id="item_weight_per_unit" name="item_weight_per_unit" value={this.state.item_weight_per_unit} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Volume (M³)</label>
                                    <input required type="number" min='0' step={0.0001} className="forminp form-control" id="item_volume_per_unit" name="item_volume_per_unit" value={this.state.item_volume_per_unit} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                           

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
} 