import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import icn from "../../img/undraw_profile.svg";
import $, { param } from 'jquery';
import 'jquery-ui';
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import withRouter from './withRouter';
import ViewDoc from "./viewdocument";

class BillingPendingApprovalDocs extends Component {
    constructor(props){
        super(props);
        
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
			},
            frameworkComponents:{
                ViewDoc:ViewDoc
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            conf:"",            
            showUploadCSVForm:"show-n",
            slider:"",
            closedDocs:[],
            otherCosts:[]
        }
        
        this.loadApprovedDocuments = this.loadApprovedDocuments.bind(this);
    }
	componentDidMount = async () => {
        var currentURL = window.location.pathname;
        if(currentURL.includes("view-document") === true)
        {
            if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
            {
                this.setState({
                    showUserApproveBtn:"show-m",
                    hideUserApproveTbl:"show-m"
                })
            }
        }
        redirectURL.post("/billing/freightothercosts")
		.then(async (response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                otherCosts:records
            })
        });
        await setTimeout(() => {
            redirectURL.post("/billing/configurations")
            .then((response) => {
                // console.log("response ", response.data)
                var records = response.data.records;
                if(records.length > 0)
                {
                    this.setState({
                        conf:records[0]
                    })
                   
                    if(records[0].diesel_price_capture_val === "1")
                    {
                        var diesel_percent = records[0].diesel_price_x;
                    }
                    
                    if(records[0].diesel_price_capture_val === "2")
                    {
                        var diesel_percent = "";
                    }
                    // this.loadDieselPrices({diesel_percent:diesel_percent})
                    var params = {
                        document_no:this.props.params.document_no
                    }
                }
                else{
                    redirectToConfigurations()
                }
            })
            
        },500)
        
        this.loadApprovedDocuments();
	}
   
    loadApprovedDocuments(){
        redirectURL.post("/billing/freightpendingapprovals")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            records.sort(GetSortAscOrder("level"));
            // console.log("records" ,records)
            var recordsarr = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    try {
                        var nextlevel = item.nextlevels;    
                        // console.log("nextlevel ", nextlevel)
                        if(nextlevel.length > 0)
                        {
                            nextlevel.map((itm) => {
                                if(itm.approval_status === 0 || itm.approval_status === "0")
                                {
                                    item.nextlevelStatus = "Pending for Approval";
                                    item.nextlevelEmail = itm.email;
                                }
                            })
                        }
                        recordsarr.push(item);
                    } catch (error) {
                        
                    }
                    
                })
            }
            // console.log("recordsarr ", recordsarr)
            recordsarr = recordsarr.sort(GetSortAscOrder("level"));
            this.setState({
                closedDocs:recordsarr
            })
                
            
            this.props.context.onLoadTabCount(records.length);
		})
    }
    
    
    render(){
        var otherCosts = this.state.otherCosts;
        var isDocumentCreator = this.props;
        // console.log("isDocumentCreator ", isDocumentCreator)
        var closedcolumnDefs=[
            {
                headerName:"",
                field:"document_no",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewDoc"
                    }
                },
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_DOCUMENT_NO,
                field:"document_no",
                width:120
            },
            // {
            //     headerName:Constants.FREIGHT_APPROVALS_APPROVAL_STATUS,
            //     field:"approval_status",
            //     width:160,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.approval_status === 1 || params.data.approval_status === "1")
            //             {
            //              return "Approved";   
            //             }
            //         }
            //         catch(e){}
            //     }
            // },
            {
                headerName:Constants.FREIGHT_APPROVALS_NEXTLEVEL_EMAIL,
                field:"nextlevelEmail",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_NEXTLEVEL_STATUS,
                field:"nextlevelStatus",
                width:180,
            }
        ];
       
        return (
            	<div className={"row "} >
					<div className="col-xl-12 col-md-12 mb-4">
                        <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                        <AgGridReact 
                            rowData={this.state.closedDocs} 
                            columnDefs={closedcolumnDefs}
                            defaultColDef ={this.state.defaultColDef }
                            enableRangeSelection={true}
                            enableCharts={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            masterDetail={true}
                            onGridReady={this.onGridReady}
                            sideBar={this.state.sideBar}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            
                        />
                        </div> 
					</div>					
                
                </div>
                
                
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
// console.log("icn ", icn)
function pushNot(){
    // showNotification();
    
	// setInterval(function(){ showNotification(); }, 20000);
}


function showNotification() {	
	if (!Notification) {
		$('body').append('<h4 style="color:red">*Browser does not support Web Notification</h4>');
		return;
	}
	if (Notification.permission !== "granted") {		
		Notification.requestPermission();
	} else {		
		$.ajax({
            url:"http://localhost:5005/billing",
			type: "GET",
			success: function(data, textStatus, jqXHR) {
				
                var theurl = "http://localhost:3005/";
                var notifikasi = new Notification("Freight Rates", {
                    icon: icn,
                    body: "You are checking Freight Rates information.",
                });
                notifikasi.onclick = function () {
                    window.open(theurl); 
                    notifikasi.close();     
                };
                setTimeout(function(){
                    notifikasi.close();
                }, 5000);
        
			},
			error: function(jqXHR, textStatus, errorThrown)	{}
		}); 
	}
};
function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 
export default withRouter(BillingPendingApprovalDocs) ;