import React, {Component} from 'react';
import Select from 'react-select';
import redirectURL from '../redirectURL';
import {isNonEmpty, isObjFieldNonEmpty} from "./util";

export default class ApiConfigurations extends Component {
    constructor(props){
        super(props);
        this.state={
            showApiInp:"show-n",            
            _id:{"value":"","label":"Select"},
            consignment_code:{"value":"","label":"Select"},
            dept_code:{"value":"","label":"Select"},
            truck_no:{"value":"","label":"Select"},
            consigner_code:{"value":"","label":"Select"},
            consigner_name:{"value":"","label":"Select"},
            invoice_date:{"value":"","label":"Select"},
            invoice_time:{"value":"","label":"Select"},
            gate_out_time:{"value":"","label":"Select"},
            consignee_code:{"value":"","label":"Select"},
            consignee_name:{"value":"","label":"Select"},
            invoice_no:{"value":"","label":"Select"},
            consignee_area:{"value":"","label":"Select"},
            consignee_city:{"value":"","label":"Select"},
            consignee_state:{"value":"","label":"Select"},
            distance_in_km:{"value":"","label":"Select"},
            gate_in_time:{"value":"","label":"Select"},
            transit_time:{"value":"","label":"Select"},
            transporter_code:{"value":"","label":"Select"},
            transporter_name:{"value":"","label":"Select"},
            zone:{"value":"","label":"Select"},
            zone_code:{"value":"","label":"Select"},
            distance_travelled_in_24_hrs_kms:{"value":"","label":"Select"},
            aprox_dist_travelled_from_start:{"value":"","label":"Select"},
            approx_dist_calc_till:{"value":"","label":"Select"},
            recent_dealer_reported:{"value":"","label":"Select"},
            total_travelled_trip_dist_km:{"value":"","label":"Select"},
            trip_duration_days:{"value":"","label":"Select"},
            total_distance_travelled_km:{"value":"","label":"Select"},
            total_google_distance_travelled_km:{"value":"","label":"Select"},
            pods:{"value":"","label":"Select"},
            apicolumns:[]
        }
        this.loadAPITrips = this.loadAPITrips.bind(this);
        this.loadUploadedTrips = this.loadUploadedTrips.bind(this);
    }
	componentDidMount(){
        // console.log("react version: ",React.version)
		redirectURL.post("/billing/configurations")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {
                if(records[0].trips_data_from == "api")
                {
                    this.loadAPITrips(records[0].api_url);
                }
                else{
                    this.setState({
                        isuploadcsv:1
                    })
                    this.loadUploadedTrips();
                }
            }
            else{
            }
		})
        redirectURL.post("/billing/apiconfigurations")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {
                this.setState({
                    _id:{"value":records[0].row_id,"label":records[0].row_id},
                    consignment_code:{"value":records[0].consignment_code,"label":records[0].consignment_code},
                    dept_code:{"value":records[0].dept_code,"label":records[0].dept_code},
                    truck_no:{"value":records[0].truck_no,"label":records[0].truck_no},
                    consigner_code:{"value":records[0].consigner_code,"label":records[0].consigner_code},
                    consigner_name:{"value":records[0].consigner_name,"label":records[0].consigner_name},
                    invoice_date:{"value":records[0].invoice_date,"label":records[0].invoice_date},
                    invoice_time:{"value":records[0].invoice_time,"label":records[0].invoice_time},
                    gate_out_time:{"value":records[0].gate_out_time,"label":records[0].gate_out_time},
                    consignee_code:{"value":records[0].consignee_code,"label":records[0].consignee_code},
                    consignee_name:{"value":records[0].consignee_name,"label":records[0].consignee_name},
                    invoice_no:{"value":records[0].invoice_no,"label":records[0].invoice_no},
                    consignee_area:{"value":records[0].consignee_area,"label":records[0].consignee_area},
                    consignee_city:{"value":records[0].consignee_city,"label":records[0].consignee_city},
                    consignee_state:{"value":records[0].consignee_state,"label":records[0].consignee_state},
                    distance_in_km:{"value":records[0].distance_in_km,"label":records[0].distance_in_km},
                    // gate_in_time:{"value":records[0].gate_in_time,"label":records[0].gate_in_time},
                    transit_time:{"value":records[0].transit_time,"label":records[0].transit_time},
                    transporter_code:{"value":records[0].transporter_code,"label":records[0].transporter_code},
                    transporter_name:{"value":records[0].transporter_name,"label":records[0].transporter_name},
                    zone:{"value":records[0].zone,"label":records[0].zone},
                    zone_code:{"value":records[0].zone_code,"label":records[0].zone_code},
                    distance_travelled_in_24_hrs_kms:{"value":records[0].distance_travelled_in_24_hrs_kms,"label":records[0].distance_travelled_in_24_hrs_kms},
                    // aprox_dist_travelled_from_start:{"value":records[0].aprox_dist_travelled_from_start,"label":records[0].aprox_dist_travelled_from_start},
                    // approx_dist_calc_till:{"value":records[0].approx_dist_calc_till,"label":records[0].approx_dist_calc_till},
                    recent_dealer_reported:{"value":records[0].recent_dealer_reported,"label":records[0].recent_dealer_reported},
                    total_travelled_trip_dist_km:{"value":records[0].total_travelled_trip_dist_km,"label":records[0].total_travelled_trip_dist_km},
                    trip_duration_days:{"value":records[0].trip_duration_days,"label":records[0].trip_duration_days},
                    total_distance_travelled_km:{"value":records[0].total_distance_travelled_km,"label":records[0].total_distance_travelled_km},
                    total_google_distance_travelled_km:{"value":records[0].total_google_distance_travelled_km,"label":records[0].total_google_distance_travelled_km},
                    pods:{"value":records[0].pods,"label":records[0].pods},
                })
            }
            else{
            }
		})
        
	}
    loadAPITrips(apiURL){
        redirectURL.post(apiURL)
		.then((response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            var apicolumns = [];
            if(records.length > 0)
            {
                for(var obj in records[0]){
                    apicolumns.push({
                        value:obj,label:obj});
                }
            }
            this.setState({
                rowData:records,
                apicolumns:apicolumns
            })
            
		})
    }
    loadUploadedTrips(){
        redirectURL.post("/billing/trips")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {
                this.loadTrips();
            }
            else{
            }
		})
    }
    changeSelectItem(column,opt)
    {
        // console.log("columnevent ", column)
        // console.log("column ", opt)
        this.setState({
            [column]:opt
        })
    }
    onClickSaveAPIMapColumns(){
        var _id = this.state._id;
        var consignment_code = this.state.consignment_code;
        var dept_code = this.state.dept_code;
        var truck_no = this.state.truck_no;
        var consigner_code = this.state.consigner_code;
        var consigner_name = this.state.consigner_name;
        var invoice_date = this.state.invoice_date;
        var invoice_time = this.state.invoice_time;
        var gate_out_time = this.state.gate_out_time;
        var consignee_code = this.state.consignee_code;
        var consignee_name = this.state.consignee_name;
        var invoice_no = this.state.invoice_no;
        var consignee_area = this.state.consignee_area;
        var consignee_city = this.state.consignee_city;
        var consignee_state = this.state.consignee_state;
        var distance_in_km = this.state.distance_in_km;
        var gate_in_time = this.state.gate_in_time;
        var transit_time = this.state.transit_time;
        var transporter_code = this.state.transporter_code;
        var transporter_name = this.state.transporter_name;
        var zone = this.state.zone;
        var zone_code = this.state.zone_code;
        var distance_travelled_in_24_hrs_kms = this.state.distance_travelled_in_24_hrs_kms;
        var aprox_dist_travelled_from_start = this.state.aprox_dist_travelled_from_start;
        var approx_dist_calc_till = this.state.approx_dist_calc_till;
        var recent_dealer_reported = this.state.recent_dealer_reported;
        var total_travelled_trip_dist_km = this.state.total_travelled_trip_dist_km;
        var trip_duration_days = this.state.trip_duration_days;
        var total_distance_travelled_km = this.state.total_distance_travelled_km;
        var total_google_distance_travelled_km = this.state.total_google_distance_travelled_km;
        var pods = this.state.pods;
        var params = {
            _id:_id.value,
            consignment_code:consignment_code.value,
            dept_code:dept_code.value,
            truck_no:truck_no.value,
            consigner_code:consigner_code.value,
            consigner_name:consigner_name.value,
            invoice_date:invoice_date.value,
            invoice_time:invoice_time.value,
            gate_out_time:gate_out_time.value,
            consignee_code:consignee_code.value,
            consignee_name:consignee_name.value,
            invoice_no:invoice_no.value,
            consignee_area:consignee_area.value,
            consignee_city:consignee_city.value,
            consignee_state:consignee_state.value,
            distance_in_km:distance_in_km.value,
            gate_in_time:gate_in_time.value,
            transit_time:transit_time.value,
            transporter_code:transporter_code.value,
            transporter_name:transporter_name.value,
            zone:zone.value,
            zone_code:zone_code.value,
            distance_travelled_in_24_hrs_kms:distance_travelled_in_24_hrs_kms.value,
            aprox_dist_travelled_from_start:aprox_dist_travelled_from_start.value,
            approx_dist_calc_till:approx_dist_calc_till.value,
            recent_dealer_reported:recent_dealer_reported.value,
            total_travelled_trip_dist_km:total_travelled_trip_dist_km.value,
            trip_duration_days:trip_duration_days.value,
            total_distance_travelled_km:total_distance_travelled_km.value,
            total_google_distance_travelled_km:total_google_distance_travelled_km.value,
            // pods:pods.value
        }
        var rowData = this.state.rowData;
        console.log("poarams ", params)
        // if(isObjFieldNonEmpty(params) === true)
        // {
            redirectURL.post("/billing/mapAPIColumns", params)
            .then((response) => {
                if(response.data.status == "success")
                {
                    redirectToTripsSummaryPage()    
                }

            })
        // }
        // else{
        //     this.setState({
        //         show:true,
        //         basicTitle:"Some fields are not mapped",
        //         basicType:"danger"
        //     })
        // }
        
    }
    render(){
		
        return (
            <div className="container-fluid">
				<div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold f20">
                        <i className="fa fa-cog cus-i"></i>&nbsp;<span>Billing API Setup - Mapping API Fields</span>
                    </h5>
                	<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <div className="row">
                                    <div className="col-xl-7">
                                        <ul className="list-itm">
                                            <li>
                                                <b>
                                                Default Columns:
                                                <span style={{float:"right"}}>
                                                    API columns:
                                                </span>
                                                </b>
                                            </li>
                                            <li id="_id">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        ID: <span style={{fontSize:"12px",color:"#8b9395"}}>_id</span> 
                                                    </div>
                                                    <div className="col-xl-4" style={{float:"right"}}>
                                                        <Select 
                                                            className="w200"
                                                            options={this.state.apicolumns} 
                                                            value={this.state._id}
                                                            onChange={this.changeSelectItem.bind(this, "_id")}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li id="consignment_code">
                                                
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Consignment Code: <span style={{fontSize:"12px",color:"#8b9395"}}>consignment_code</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}>
                                                        <span style={{float:"right"}}>
                                                            <Select 
                                                                className="w200"
                                                                options={this.state.apicolumns} 
                                                                value={this.state.consignment_code}
                                                                onChange={this.changeSelectItem.bind(this, "consignment_code")}
                                                            />
                                                        </span>  
                                                    </div>
                                                </div>  
                                            
                                            
                                            </li>
                                            <li id="dept_code">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Department Code:  <span style={{fontSize:"12px",color:"#8b9395"}}>dept_code</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.dept_code}
                                                        onChange={this.changeSelectItem.bind(this, "dept_code")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="truck_no">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Truck No: <span style={{fontSize:"12px",color:"#8b9395"}}>truck_no</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}> <span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.truck_no}
                                                        onChange={this.changeSelectItem.bind(this, "truck_no")}
                                                    />
                                                </span>   
                                                    </div>
                                                </div> 
                                            </li>
                                            <li id="consigner_code">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Plant Code: <span style={{fontSize:"12px",color:"#8b9395"}}>consigner_code</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.consigner_code}
                                                        onChange={this.changeSelectItem.bind(this, "consigner_code")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="consigner_name">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Plant Name: <span style={{fontSize:"12px",color:"#8b9395"}}>consigner_name</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.consigner_name}
                                                        onChange={this.changeSelectItem.bind(this, "consigner_name")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="invoice_no">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Invoice No: <span style={{fontSize:"12px",color:"#8b9395"}}>invoice_no</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.invoice_no}
                                                        onChange={this.changeSelectItem.bind(this, "invoice_no")}
                                                    />
                                                </span>   
                                                    </div>
                                                </div> 
                                            </li>
                                            <li id="invoice_date">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Invoice Date <span style={{fontSize:"12px",color:"#8b9395"}}>invoice_date</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.invoice_date}
                                                        onChange={this.changeSelectItem.bind(this, "invoice_date")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            {/* <li id="invoice_time">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Invoice Time: <span style={{fontSize:"12px",color:"#8b9395"}}>invoice_time</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.invoice_time}
                                                        onChange={this.changeSelectItem.bind(this, "invoice_time")}
                                                    />
                                                </span>   
                                                    </div>
                                                </div> 
                                            </li> */}
                                            {/* <li id="gate_in_time">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Gate In Time: <span style={{fontSize:"12px",color:"#8b9395"}}>gate_in_time</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.gate_in_time}
                                                        onChange={this.changeSelectItem.bind(this, "gate_in_time")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li> */}
                                            <li id="gate_out_time">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Gate Out Time: <span style={{fontSize:"12px",color:"#8b9395"}}>gate_out_time</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.gate_out_time}
                                                        onChange={this.changeSelectItem.bind(this, "gate_out_time")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="consignee_code">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Dealer Code:  <span style={{fontSize:"12px",color:"#8b9395"}}>consignee_code</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.consignee_code}
                                                        onChange={this.changeSelectItem.bind(this, "consignee_code")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="consignee_name">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Dealer Name: <span style={{fontSize:"12px",color:"#8b9395"}}>consignee_name</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.consignee_name}
                                                        onChange={this.changeSelectItem.bind(this, "consignee_name")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="consignee_area">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Dealer Area: <span style={{fontSize:"12px",color:"#8b9395"}}>consignee_area</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.consignee_area}
                                                        onChange={this.changeSelectItem.bind(this, "consignee_area")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="consignee_city">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Dealer City: <span style={{fontSize:"12px",color:"#8b9395"}}>consignee_city</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}> <span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.consignee_city}
                                                        onChange={this.changeSelectItem.bind(this, "consignee_city")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="consignee_state">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        Dealer State: <span style={{fontSize:"12px",color:"#8b9395"}}>consignee_state</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.consignee_state}
                                                        onChange={this.changeSelectItem.bind(this, "consignee_state")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="distance_in_km">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Distance (Km): <span style={{fontSize:"12px",color:"#8b9395"}}>distance_in_km</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.distance_in_km}
                                                        onChange={this.changeSelectItem.bind(this, "distance_in_km")}
                                                    />
                                                </span>   
                                                    </div>
                                                </div> 
                                            </li>
                                            <li id="transit_time">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Transit Time: <span style={{fontSize:"12px",color:"#8b9395"}}>transit_time</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.transit_time}
                                                        onChange={this.changeSelectItem.bind(this, "transit_time")}
                                                    />
                                                </span> 
                                                    </div>
                                                </div>   
                                            </li>
                                            <li id="transporter_code">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Transporter Code: <span style={{fontSize:"12px",color:"#8b9395"}}>transporter_code</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.transporter_code}
                                                        onChange={this.changeSelectItem.bind(this, "transporter_code")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="transporter_name">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Transporter Name: <span style={{fontSize:"12px",color:"#8b9395"}}>transporter_name</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.transporter_name}
                                                        onChange={this.changeSelectItem.bind(this, "transporter_name")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="zone">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Zone: <span style={{fontSize:"12px",color:"#8b9395"}}>zone</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.zone}
                                                        onChange={this.changeSelectItem.bind(this, "zone")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="zone_code">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Zone Code: <span style={{fontSize:"12px",color:"#8b9395"}}>zone_code</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.zone_code}
                                                        onChange={this.changeSelectItem.bind(this, "zone_code")}
                                                    />
                                                </span>   
                                                    </div>
                                                </div> 
                                            </li>
                                            <li id="distance_travelled_in_24_hrs_kms">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Distance Travelled In 24 hrs (km): <span style={{fontSize:"12px",color:"#8b9395"}}>distance_travelled_in_24_hrs_kms</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.distance_travelled_in_24_hrs_kms}
                                                        onChange={this.changeSelectItem.bind(this, "distance_travelled_in_24_hrs_kms")}
                                                    />
                                                </span>    
                                                    </div>
                                                </div>
                                            </li>
                                            {/* <li id="aprox_dist_travelled_from_start">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Approx. Distance Travelled from start: <span style={{fontSize:"12px",color:"#8b9395"}}>aprox_dist_travelled_from_start</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.aprox_dist_travelled_from_start}
                                                        onChange={this.changeSelectItem.bind(this, "aprox_dist_travelled_from_start")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="approx_dist_calc_till">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Approx. distance calculate till now: <span style={{fontSize:"12px",color:"#8b9395"}}>approx_dist_calc_till</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.approx_dist_calc_till}
                                                        onChange={this.changeSelectItem.bind(this, "approx_dist_calc_till")}
                                                    />
                                                </span>   
                                                    </div>
                                                </div> 
                                            </li> */}
                                            <li id="recent_dealer_reported">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Reached Destination At: <span style={{fontSize:"12px",color:"#8b9395"}}>recent_dealer_reported</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.recent_dealer_reported}
                                                        onChange={this.changeSelectItem.bind(this, "recent_dealer_reported")}
                                                    />
                                                </span>    
                                                    </div>
                                                </div>
                                            </li>
                                            <li id="total_travelled_trip_dist_km">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Total Trip Travelled Distance (km): <span style={{fontSize:"12px",color:"#8b9395"}}>total_travelled_trip_dist_km</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.total_travelled_trip_dist_km}
                                                        onChange={this.changeSelectItem.bind(this, "total_travelled_trip_dist_km")}
                                                    />
                                                </span>   
                                                    </div>
                                                </div> 
                                            </li>
                                            <li id="trip_duration_days">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Trip Duration (days): <span style={{fontSize:"12px",color:"#8b9395"}}>trip_duration_days</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.trip_duration_days}
                                                        onChange={this.changeSelectItem.bind(this, "trip_duration_days")}
                                                    />
                                                </span>   
                                                    </div>
                                                </div> 
                                            </li>
                                            <li id="total_distance_travelled_km">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Total Distance Travelled (Km): <span style={{fontSize:"12px",color:"#8b9395"}}>total_distance_travelled_km</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.total_distance_travelled_km}
                                                        onChange={this.changeSelectItem.bind(this, "total_distance_travelled_km")}
                                                    />
                                                </span>  
                                                    </div>
                                                </div>  
                                            </li>
                                            <li id="total_google_distance_travelled_km">
                                            <div className="row">
                                                    <div className="col-xl-8">
                                                        Total Google Distance Travelled (km): <span style={{fontSize:"12px",color:"#8b9395"}}>total_google_distance_travelled_km</span>
                                                        </div>
                                                    <div className="col-xl-4" style={{float:"right"}}><span style={{float:"right"}}>
                                                    
                                                    <Select 
                                                        className="w200"
                                                        options={this.state.apicolumns} 
                                                        value={this.state.total_google_distance_travelled_km}
                                                        onChange={this.changeSelectItem.bind(this, "total_google_distance_travelled_km")}
                                                    />
                                                </span> 
                                                    </div>
                                                </div>   
                                            </li>
                                            {/* <li id="pods">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        POD Related Data: <span style={{fontSize:"12px",color:"#8b9395"}}>pods</span>
                                                    </div>
                                                    <div className="col-xl-4" style={{float:"right"}}>
                                                        <span style={{float:"right"}}>
                                                    
                                                            <Select 
                                                                className="w200"
                                                                options={this.state.apicolumns} 
                                                                value={this.state.pods}
                                                                onChange={this.changeSelectItem.bind(this, "pods")}
                                                            />
                                                        </span> 
                                                    </div>
                                                </div>   
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div className="col-xl-12" style={{textAlign:"center", marginTop:"30px", marginBottom:"30px"}}>
                                        <button type="button" onClick={this.onClickSaveAPIMapColumns.bind(this)} className="btn btn-danger">Save &amp; Import Data</button>
                                    </div>
                                </div>
							</div>
						</div>
					</div>
					
				</div>
                    

			</div>
        );
    }
}
function redirectToTripsSummaryPage()
{
    window.location.href="/trips"
}