import React, { useEffect } from "react";
import '../../assets/css/BootStrapLoader.css'

const BootstrapLoader = (props) => {
  useEffect(()=>{},[props])
  // console.log("BootstrapLoader",props)
  return (
    <div
      className={`${
        props.showLoader ? "show-m loader_styling overlay-part" : "show-n"
      }`}
      style={{background: "#f6f6f6"}}
    >
      <div className="loading_card">
        
<div align="center" className="fond">
  <div className="contener_general">
      <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
      <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
      <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
      <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
  </div>
</div>
      </div>
    </div>
  );
};

export default BootstrapLoader;
