import { React, useEffect, useState } from "react";
import redirectURL from "../redirectURL";
import CargoShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import CargoShipWhite from "../../assets/icons/cargo-ship-white.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import LocationWhite from "../../assets/icons/location-white.svg";
import ShipmentBlue from "../../assets/icons/shipment-blue.svg";
import ShipmentWhite from "../../assets/icons/shipment-white.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import FlagWhite from "../../assets/icons/flag-white.svg";
import ContainerCompletedBlue from "../../assets/icons/container-completed-blue.svg";
import ContainerCompletedWhite from "../../assets/icons/container-completed-white.svg";
import ContainerDelayedRed from "../../assets/icons/container-delayed-red.svg";
import ContainerDelayedWhite from "../../assets/icons/container-delayed-white.svg";
import ContainerDetainedRed from "../../assets/icons/container-detained-red.png";
import ContainerDetainedWhite from "../../assets/icons/container-detained-white.png";

const BLCounters = ({counterData, filterCardData}) => {
  //console.log("counterData", counterData)
  const [state, setState] = useState({
    all: 0,
    origin: 0,
    in_transit: 0,
    destination: 0,
    completed: 0,
    delayed: 0,
    detained: 0,
    activeCounterId: 1,
    activeCounterName: "all",
    isLoading: false,
  });

  useEffect(() => {
    // setState({
    //   ...state,
    //   isLoading: true,
    // });
    // fetchCounterData();
  }, []);

  const handleCounterClick = (id, name) => {
    setState({
      ...state,
      activeCounterId: id,
      activeCounterName: name,
    });
    filterCardData(name)

  };

  let counters = [
    {
      id: 1,
      name: "all",
      title: "All",
      count: counterData.all,
      imgSrc: CargoShipBlue,
      activeImgSrc: CargoShipWhite,
      color: "#407bff",
      width: "21px",
      height: "22px",
    },
    {
      id: 2,
      name: "origin",
      title: "Origin",
      count: counterData.origin,
      imgSrc: LocationBlue,
      activeImgSrc: LocationWhite,
      color: "#407bff",
      width: "21px",
      height: "23px",
    },
    {
      id: 3,
      name: "in_transit",
      title: "In Transit",
      count: counterData.in_transit,
      imgSrc: ShipmentBlue,
      activeImgSrc: ShipmentWhite,
      color: "#407bff",
      width: "21px",
      height: "29px",
    },
    {
      id: 4,
      name: "destination",
      title: "Destination",
      count: counterData.destination,
      imgSrc: FlagBlue,
      activeImgSrc: FlagWhite,
      color: "#407bff",
      width: "1vw",
      height: "27px",
      fontSize: "10px",
    },
    {
      id: 5,
      name: "completed",
      title: "Completed",
      count: counterData.completed,
      imgSrc: ContainerCompletedBlue,
      activeImgSrc: ContainerCompletedWhite,
      color: "#407bff",
      width: "1.4vw",
      height: "22px",
    },
    {
      id: 6,
      name: "delayed",
      title: "Delayed",
      count: counterData.delayed,
      imgSrc: ContainerDelayedRed,
      activeImgSrc: ContainerDelayedWhite,
      color: "#ff0000",
      width: "21px",
      height: "22px",
    },
    {
      id: 7,
      name: "detained",
      title: "Detention",
      count: counterData.detained,
      imgSrc: ContainerDetainedRed,
      activeImgSrc: ContainerDetainedWhite,
      color: "#ff0000",
      width: "21px",
      height: "22px",
    },
  ];
  {
    if(!counters || counters.length === 0 || counters===undefined){
      return(
    <div
    key={""}
    className="card py-2 pr-3"
    style={{
      borderRadius: "18px",
      textAlign:"center",
    }}
  >
    No data found.
    </div>
      )}}
  return (
    <div className="d-flex">
      {counters.map((item, idx) => {
        return (
          <div
            key={item + idx}
            id={item.id}
            className={`card d-flex flex-row justify-content-around align-items-center mr-1 py-2 card-container each-counter`}
            style={{
              borderRadius: "17px",
              color: state.activeCounterId == item.id ? "white" : item.color,
              background:
                state.activeCounterId == item.id ? item.color : "white",
              height: "58px",
              cursor: "pointer",
              padding:"0px !important"
              //   paddingRight: "33px"
              //   width: "103px",
            }}
            onClick={() => handleCounterClick(item.id, item.name)}
          >
            <div className="icon-container cimg">
              <img
                src={
                  state.activeCounterId == item.id
                    ? item.activeImgSrc
                    : item.imgSrc
                }
                className=""
                style={{height: item.height, width:item.width, marginLeft:"0px !important" }}
              />
            </div>

            <div
              className="d-flex flex-column justify-items-around align-items-left ml-0p blCounterEdit"
              style={{ fontWeight: 700, padding:"0px 15px 0 0" }}
            >
              <div
                style={{
                  fontSize: "0.6vw",
                  //lineHeight: "21px",
                  fontFamily: "inherit",
                  whiteSpace: "nowrap",
                  // fontSize: item.fontSize ? item.fontSize : "11px",
                }}
                className=""
              >
                {item.title}
              </div>
              <div style={{ fontSize: "1vw",textAlign: "center"  }} >
                {item.count}
              </div>
            </div>
          </div>
        );
      })}
    </div>

    // <div className="col-12 justify-content-start align-items-center">
    //   <div className="row justify-content-between">
    //     {counters.map((item, idx) => {
    //       return (
    //         <div className="col">
    //           <div
    //             key={item + idx}
    //             id={item.id}
    //             className={`card d-flex flex-row justify-content-between align-items-center mr-3 py-2 px-3 card-container`}
    //             style={{
    //               borderRadius: "17px",
    //               color:
    //                 state.activeCounterId == item.id ? "white" : item.color,
    //               background:
    //                 state.activeCounterId == item.id ? item.color : "white",
    //               height: "58px",
    //               cursor: "pointer",
    //               width: "103px"
    //             }}
    //             onClick={() => handleCounterClick(item.id, item.name)}
    //           >
    //             <div className="icon-container">
    //               <img
    //                 src={
    //                   state.activeCounterId == item.id
    //                     ? item.activeImgSrc
    //                     : item.imgSrc
    //                 }
    //                 style={{ width: item.width, height: item.height }}
    //               />
    //             </div>

    //             <div
    //               className="d-flex flex-column justify-items-center align-items-left ml-0p"
    //               style={{ fontWeight: 700 }}
    //             >
    //               <div
    //                 style={{
    //                   fontSize: "14px",
    //                   lineHeight: "21px",
    //                   fontFamily: "inherit",
    //                   whiteSpace: "nowrap",
    //                 }}
    //               >
    //                 {item.title}
    //               </div>
    //               <div style={{ fontSize: "16px", lineHeight: "21px" }}>
    //                 {item.count}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     })}
    //   </div>
    // </div>
  );
};

export default BLCounters;
