import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import { fetchGridLayout, getHyphenDDMMMYYYYHHMM, saveGridLayout } from '../common/utils';
import RoutemapTransitAction from './RoutemapTransitAction.js'
import RoutemapenrouteAction from './routemapenroute.js';
import moment from "moment";
import { Modal } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SweetAlert from "react-bootstrap-sweetalert";
import Filter from '../../assets/icons/SEARCH.png'
import redirectURL from "../redirectURL";
import MainCounters from './mainCounters.js'
import MainCountersforDelivered from './maincountersfordelivered.js';
import ViewMap from './ViewMap';
import TransitDelay from './TransitDelay';
import EnrouteException from './EnrouteException';
import Overspeeding from './Overspeeding';
import NightDriving from './NightDriving';
import LoadingException from './LoadingException';
import UnloadingException from './UnloadingException';
import LoadingAreaLegs from './LoadingAreaLegs.js';
import DrawMap from '../common/drawmap';
import RoutemapAction from './routemapActionComponent.js';
import RouteNightDrivemap from './routenightdrivemap.js';
import NoGPS from './NoGPS.js';
import NoGPSMap from './NoGPSMap.js';
import ForceClose from './forceClose.jsx';
import Constant from './Constant.js';
import debounce from 'lodash.debounce';
import GroupIconRenderer from './GroupIconRenderer.js';
import Breadcrumb from '../common/breadcrumb.component.js';

import Favourites from "../common/favouritesComponent";
var map = ''
function Consignments() {
    const decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }
    const decode = decipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr")
    const user = useMemo(() => JSON.parse(decode(localStorage.getItem("k"))), []);

    const gridApi = useRef();
    const gridApiConsignments = useRef();
    const columnApiConsignments = useRef(null);
    const reloadPage = useRef(false);
    var [rowData, setRowData] = useState([])
    const navigate = useNavigate(); // Initialize navigate
    const location = useLocation(); 
    var [filterToggle, setFilterToggle] = useState(false)
    var [key, setKey] = useState('')
    const [gridColumnState, setGridColumnState] = useState([]);
    const [overly, setOverlay] = useState("show-n")
    const [loadshow, setLoadShow] = useState("show-n")
    const [forcecloseModal, setForcecloseModal] = useState(false);
    const [favouriteModel, setFavouriteModel] = useState("show-n");
    const [screen_title,setScreenTitle] = useState("");
    const [reasonForceClose, setReasonForceClose] = useState("");
    const [filterCities, setFilterCities] = useState([]);
    const [filterStates, setFilterStates] = useState([]);
    const [filterTransporters, setFilterTransporters] = useState([]);
    const [searchCity, setSearchCity] = useState({ label: '', value: '' });
    const [searchState, setSearchStates] = useState({ label: '', value: '' });
    const [searchTransporters, setSearchTransporters] = useState({ label: '', value: '' });
    const [searchConsigner, setSearchConsigner] = useState([{ label: 'All', value: 'all' }]);
    const [consignersList, setConsignersList] = useState([]);
    const [searchConsignee, setSearchConsignee] = useState([{ label: 'All', value: 'all' }]);
    const [movementtype, setMovementtype] = useState({ label: '', value: '' });
    const [searchValue, setSeachValue] = useState('');
    const [searchType, setSearchType] = useState({ label: '', value: '' });
    const [approveStatus, setApproveType] = useState({ label: '', value: '' });
    const [driverName, setDriverName] = useState("");
    const [driverMobile, setDriverMobile] = useState("");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tabName, setTabName] = useState({ label: "Active", value: "active" });
    const [counter, setCounter] = useState({ label: "Consignments", value: "consignments" });
    const [expenses, setExpenses] = useState({ policeEntry: '', borderExp: '', onRouteRepairing: '', rtoChallan: '', cashToll: '', driverExp: '', ureaPurchase: '', other: '' });
    const [rowShipmentId, setShipmentId] = useState(null);
    const [rowTruckNo, setTruckNo] = useState(null);
    var [detailCellRendererParams, setdetailCellRendererParams] = useState(null);
    const [tabOptions, setTabOptions] = useState([
        { label: "Active", value: "active" },
        { label: "Completed", value: "completed" },
        { label: "All", value: "all" }
    ])
    const [counterOptions, setCounterOptions] = useState([
        { label: "Consignments", value: "consignments" },
        { label: "Transit Delays", value: "transit_delays" },
        { label: "No GPS Available", value: "no_gps_data" },
        { label: "No GPS Available Lifetime", value: "no_gps_data_lifetime" },
        { label: "Overspeeding", value: "overspeeding" },
        // { label: "Night Driving", value: "night_driving" },
        { label: "Stoppages", value: "stoppages" },
        { label: "Accidents", value: "accidents" },
        { label: "Trip Deviations", value: "trip_deviations" },
        { label: "Loading Delay", value: "loading_delay" },
        { label: "Unloading Delay", value: "unloading_delay" }
    ]);

    var filterCardStyle1 = { minHeight: "234px" };
    var filterCardStyle2 = { padding: '20px' };
    var mnFilterClass = { marginLeft: "0px", padding: "0px" };
    const [rownode, setRownode] = useState([]);
    var [mapDetails, setMapDetails] = useState([]);
    const [activeTrips, setActiveTrips] = useState({
        all: [],
        transit_delay: [],
        overspeeding: [],
        night_driving: [],
        stoppages: [],
        accidents: [],
        trip_deviations: [],
        loading_delay: [],
        unloading_delay: [],
        no_gps_data: [],
        no_gps_data_lifetime: []
    })
    const [completedTrips, setCompletedTrips] = useState({
        all: [],
        transit_delay: [],
        overspeeding: [],
        night_driving: [],
        stoppages: [],
        accidents: [],
        trip_deviations: [],
        loading_delay: [],
        unloading_delay: [],
        no_gps_data: [],
        no_gps_data_lifetime: []
    })
    const [allTrips, setAllTrips] = useState({
        all: [],
        transit_delay: [],
        overspeeding: [],
        night_driving: [],
        stoppages: [],
        accidents: [],
        trip_deviations: [],
        loading_delay: [],
        unloading_delay: [],
        no_gps_data: [],
        no_gps_data_lifetime: []
    })
    var [columnDefs, setcolumnDefs] = useState([]);
    const [modalloadData, setModalLoadData] = useState([])
    const [plantData, setPlantData] = useState([])
    const [transporters, setTransporters] = useState([])
    const [dealers, setDealers] = useState([])
    const [previousData, setPreviousData] = useState([])
    const fileRef = useRef()
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState('')
    const [type, setType] = useState('default')
    const [loader, setLoader] = useState(false)
    const [bulkUploadModal, setBulkUploadModal] = useState({
        overly: "show-n",
        bulkPrioritySlide: ""
    })
    const [addExpensesModal, setAddExpensesModal] = useState({
        overly: "show-n",
        addExpensesSlide: ""
    })
    const [approveExpensesModal, setApproveExpensesModal] = useState({
        overly: "show-n",
        approveExpensesSlide : ""
    })
    const [addDriverModal, setaddDriverModal] = useState({
        overly: "show-n",
        setAddDriverSlide : ""
    })
    const [comment, setComment] = useState('');
    const [openMap, setOpenMap] = useState({
        sliderRouteTranslate: "",
        showDiv: 'show-n',
        mapinfo: [],
        dealer: "",
        consignment_code: "",
        maptruckno: "",
        geofence_coordinates: [],
        parking_coordinates: [],
        routeTruck: { "truck_no": '', "startTime": '', "endTime": '' },
        loadshow: 'show-n',
        sidebarSubHeader: "",
        overly: 'show-m',
        rownode: {},
        leg_no: 0,
        tolls: []
    })
    const [countersActive, setCountersActive] = useState({
        consignments: 0,
        transit_delays: 0,
        overspeeding: 0,
        night_driving: 0,
        stoppages: 0,
        accidents: 0,
        trip_deviations: 0,
        loading_delay: 0,
        unloading_delay: 0,
        no_gps_data: 0,
        no_gps_data_lifetime: 0
    })
    const [countersCompleted, setCountersCompleted] = useState({
        consignments: 0,
        ontime:0,
        delay:0,
        transit_delays: 0,
        overspeeding: 0,
        night_driving: 0,
        stoppages: 0,
        accidents: 0,
        trip_deviations: 0,
        loading_delay: 0,
        unloading_delay: 0,
        no_gps_data: 0,
        no_gps_data_lifetime: 0
    })
    const [countersAll, setCountersAll] = useState({
        consignments: 0,
        transit_delays: 0,
        overspeeding: 0,
        night_driving: 0,
        stoppages: 0,
        accidents: 0,
        trip_deviations: 0,
        loading_delay: 0,
        unloading_delay: 0,
        no_gps_data: 0,
        no_gps_data_lifetime: 0
    })
    const transitdelaycols = [
        {
            headerName: "", field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: RoutemapTransitAction,
                            params: {
                                onShowTransitRouteMap: onShowTransitRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }
            }
        },
        {
            headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.leg_start);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Leg End Time", field: "leg_end_eta",
            width: 120, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.leg_end_eta);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }
            }
        },
        {
            headerName: "Exp. Leg Distance (Mile)",
            field: "leg_expected_distance", width: 140, resizable: true,
            valueGetter: function (params) {
                return Math.round(params.data.leg_expected_distance/1.60934);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Actual Leg Distance Covered (Mile)",
            field: "actual_leg_distance", width: 140, resizable: true,
            valueGetter: function (params) {
                if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
                    return Math.round(params.data.actual_leg_distance/1.60934);
                }
                else {
                    return 0;
                }
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Exp. Distance from Trip Start(Mile)",
            field: "expected_distance_start_to_leg", width: 140, resizable: true,
            valueGetter: function (params) {
                if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
                    return Math.round(params.data.expected_distance_start_to_leg/1.60934);
                }
                else {
                    return 0;
                }

            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }

        },
        {
            headerName: "Actual Distance from Trip Start (Mile)",
            field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
            valueGetter: function (params) {
                if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
                    return Math.round(params.data.actual_distance_traveled_start_to_leg/1.60934);
                }
                else {
                    return 0;
                }

            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }


        },

        {
            headerName: "Google Distance from Start (Mile)",
            field: "actual_start_to_leg_google_distance",
            width: 140,
            resizable: true,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            },
            
            valueGetter:function(params){
                try{
                    if(params.data.actual_start_to_leg_google_distance !== "" && params.data.actual_start_to_leg_google_distance !== undefined)
                    {
                        return Math.round(params.data.actual_start_to_leg_google_distance/1.60934);
                    }
                    else{
                        return 0;
                    }
                }
                catch(e){}
            }


        },
        {
            headerName: "GPS Data Available",
            field: "no_gps_data",
            width: 140,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.no_gps_data == 1) {
                    return "Not Available";
                }
                if (params.data.no_gps_data == 0) {
                    return "Available";
                }

            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }


        },


        {
            headerName: "Trip Completed",
            field: "trip_completed",
            width: 140,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.trip_completed == 1) {
                    return "Yes";
                }
                if (params.data.trip_completed == 2) {
                    return "";
                }
                if (params.data.trip_completed == 0) {
                    return "";
                }

            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        }
    ]
    const stoppagescols = [
        {
            headerName: "",
            field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: RoutemapenrouteAction,
                            params: {
                                onShowEnrouteRouteMap: onShowEnrouteRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            suppressSizeToFit: true,
        },
        {
            headerName: "Break Start",
            field: "stoppage_start_time",
            width: 150,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.stoppage_start_time != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.stoppage_start_time)
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Break End",
            field: "reset_datetime",
            width: 150,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.reset_datetime != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.reset_datetime)
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Exception Closed On",
            field: "modified_date",
            width: 220,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.exception_closed == 1) {

                    if (params.data.modified_date != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
                    }
                    else {
                        return '';
                    }
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Break Time",
            field: "elaped_time_from_last_moved_seconds",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.exception_closed == 1) {
                    var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
                    if (typeof params.data.reset_datetime == 'undefined') {
                        var edate = moment.parseZone().format("x");
                    }
                    else {
                        var edate = moment.parseZone(params.data.reset_datetime).format("x");
                    }
                    var diff = (edate - sdate) / 1000;
                    return secondsToString(diff);
                }
                else {
                    return '';
                }
            }
        }
    ]
    const overspeedcols = [
        {
            headerName: "", field: "_id",
            width: 50,
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: RoutemapAction,
                            params: {
                                onShowRouteMap: onShowRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            resizable: true
        },
        { headerName: "Truck No", field: "truck_no", filter: true, resizable: true },
        { headerName: "Speed (m/h)", field: "speed", filter: true, resizable: true },
        {
            headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
            }
        },
    ]
    const nightdrivingcols = [
        {
            headerName: "", field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: RouteNightDrivemap,
                            params: {
                                onShowNightDriveRouteMap: onShowNightDriveRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            suppressSizeToFit: true
        },
        { headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
        {
            headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
            }
        },
        {
            headerName: "Traveled Until", field: "recent_instance", resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.recent_instance);
            }
        },
        {
            headerName: "Distance Traveled (mile)", field: "distance_from_first_instance", width: 180,
            resizable: true,
            valueGetter: function (params) {
                return Math.round(params.data.distance_from_first_instance/1.60934)
            }
        }
    ]
    const unloadingcols = [
        {
            headerName: "From Date",
            field: "from_date",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                // console.log("child params ", params)
                if (params.data.from_date != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.from_date);
                }
                else {
                    return "NA"
                }

            }
        },
        {
            headerName: "To Date",
            field: "to_date",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.to_date != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.to_date);
                }
                else {
                    return "NA"
                }
            }
        },
        {
            headerName: "Unloading Time",
            field: "unloading_time",
            width: 180,
        }
    ]
    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        pivot: false,
    }
    let gridLegColumns = [];
    const columnDef = [
        // {
        //     headerName: "Entity",
        //     field: "entity",
        //     editable: true,
        //     // cellStyle: params => {
        //     //     if (params.data.plant_code == undefined || params.data.plant_code == "" || !plantData.some(e => e.consigner_code == params.data.plant_code)) {
        //     //         return {
        //     //             "backgroundColor": '#c85f62'
        //     //         };
        //     //     }
        //     // }
        // },
        // {
        //     headerName: "Division Name",
        //     field: "division_name",
        //     editable: true,
        //     // cellStyle: params => {
        //     //     if (params.data.fo_number == undefined || params.data.fo_number == "") {
        //     //         return {
        //     //             "backgroundColor": '#c85f62'
        //     //         };
        //     //     }
        //     //     if (params.data.hasOwnProperty("exists") && params.data.exists == 1) {
        //     //         return {
        //     //             "backgroundColor": '#ffcc00'
        //     //         };
        //     //     }
        //     // }
        // },
        {
            headerName: "Sale Bill Number",
            field: "sale_bill_number",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.order_number == undefined || params.data.order_number == "") {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            //     if (params.data.hasOwnProperty("exists") && params.data.exists == 1) {
            //         return {
            //             "backgroundColor": '#ffcc00'
            //         };
            //     }
            // }
        },
        {
            headerName: "Sale Bill Date",
            field: "sale_bill_date",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.sale_bill_date == undefined || params.data.sale_bill_date == "" || (!moment(params.data.sale_bill_date, "YYYY-MM-DD HH:mm:ss", true).isValid())) {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Customer Code",
            field: "customer_code",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.vehicle_no == undefined || params.data.vehicle_no == "") {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Sale Type Name",
            field: "sale_type_name",
            editable: true
        },
        {
            headerName: "To State Name",
            field: "to_state_name",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.transporter_code == undefined || params.data.transporter_code == "" || !transporters.some(e => e.transporter_code == params.data.transporter_code)) {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Godown Name",
            field: "godown_name",
            editable: true
        },
        {
            headerName: "Party City",
            field: "party_city",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.dealer_code == undefined || params.data.dealer_code == "" || !dealers.some(e => e.consignee_code == params.data.dealer_code)) {

            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Plant Location Code",
            field: "plant_code",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.dealer_code == undefined || params.data.dealer_code == "" || !dealers.some(e => e.consignee_code == params.data.dealer_code)) {

            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Consignment Code",
            field: "consignment_code",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.dealer_code == undefined || params.data.dealer_code == "" || !dealers.some(e => e.consignee_code == params.data.dealer_code)) {

            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Order Number",
            field: "order_no",
            editable: true
        },
        {
            headerName: "Invoice Date",
            field: "invoice_date",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.order_date == undefined || params.data.order_date == "" || (!moment(params.data.order_date, "YYYY-MM-DD HH:mm:ss", true).isValid())) {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Order Date",
            field: "order_date",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.order_date == undefined || params.data.order_date == "" || (!moment(params.data.order_date, "YYYY-MM-DD HH:mm:ss", true).isValid())) {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Truck No",
            field: "truck_no",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.quantity == undefined || params.data.quantity == "" || isNaN(params.data.quantity)) {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "From Place Name",
            field: "from_place_name",
            editable: true
        },
        {
            headerName: "From State Name",
            field: "from_place_name",
            editable: true
        },
        {
            headerName: "Transaction Nature",
            field: "transaction_nature",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.execution_date == undefined || params.data.execution_date == "" || (!moment(params.data.execution_date, "YYYY-MM-DD HH:mm:ss", true).isValid())) {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Customer Name",
            field: "customer_name",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.expected_date == undefined || params.data.expected_date == "" || (!moment(params.data.expected_date, "YYYY-MM-DD HH:mm:ss", true).isValid())) {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        {
            headerName: "Item Code",
            field: "item_code",
            editable: true,
            // cellStyle: params => {
            //     if (params.data.execution_date == undefined || params.data.execution_date == "" || (!moment(params.data.execution_date, "YYYY-MM-DD HH:mm:ss", true).isValid())) {
            //         return {
            //             "backgroundColor": '#c85f62'
            //         };
            //     }
            // }
        },
        // {
        //     headerName: "Short Name",
        //     field: "short_name",
        //     editable: true
        // },
        {
            headerName: "Quantity",
            field: "quantity",
            editable: true
        },
        {
            headerName: "Rate",
            field: "rate",
            editable: true
        },
        {
            headerName: "Invoice Value",
            field: "invoice_value",
            editable: true
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            editable: true
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            editable: true
        },
    ]
    const nogpscols = [
        {
            headerName: "",
            field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: NoGPSMap,
                            params: {
                                onShowNoGPSRouteMap: onShowNoGPSRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            suppressSizeToFit: true,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Last Data Receieved",
            field: "last_packet_datetime",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.last_packet_datetime);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Data Received on",
            field: "packet_received_on",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.packet_received_on);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        }
    ]
    var columnDefs2 = [
        {
            headerName: "",
            field: "_id",
            colId:"_id",
            width: 50,
            pinned: "left",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: ViewMap,
                            params: {
                                onClickShowRoute: onClickShowRoute
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
        // {
        //     headerName: "",
        //     field: "loadingarealegs",
        //     width: 50,
        //     pinned: 'left',
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: LoadingAreaLegs,
        //                     params: {
        //                         onClickShowLoadingAreaLegs : onClickShowLoadingAreaLegs
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        //     // cellRenderer:'LoadingAreaLegs',
            
        // },
        {
            headerName: "",
            field: "_id",
            colId:"actions",
            width: 50,
            pinned: "left",
            cellRendererSelector: function (param) {
                try {
                    // Check if param.data exists and _id is available
                    if (param.data && param.data._id) {
                        return {
                            component: GroupIconRenderer,
                            params: {
                                // Ensure these functions are defined and passed correctly
                                onShowTransitData: onShowTransitData,
                                onShowNoGPSData: onShowNoGPSData,
                                onShowOverspeedingData: onShowOverspeedingData,
                                onShowNightDrivingData: onShowNightDrivingData,
                                onShowStoppagesData: onShowStoppagesData,
                                onShowLoadingData: onShowLoadingData,
                                onShowUnloadingData: onShowUnloadingData  //tmsssssssss
                                
                                // onShowTransitData: ()=>{onShowTransitData()},
                                // onShowNoGPSData: ()=>{onShowNoGPSData()},
                                // onShowOverspeedingData: ()=>{onShowOverspeedingData()},
                                // onShowNightDrivingData: ()=>{onShowNightDrivingData()},
                                // onShowStoppagesData: ()=>{onShowStoppagesData()},
                                // onShowLoadingData: ()=>{onShowLoadingData()},
                                // onShowUnloadingData: ()=>{onShowUnloadingData()}
                            }
                        };
                    }
                } catch (err) {
                    // Log additional details if needed
                    console.error("Error in cellRendererSelector:", err);
                }
            },
            cellStyle: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
            }
        },
        
        // {
        //     headerName: "",
        //     field: "transit_delay",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: TransitDelay,
        //                     params: {
        //                         onShowTransitData: onShowTransitData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "no_gps_data",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: NoGPS,
        //                     params: {
        //                         onShowNoGPSData: onShowNoGPSData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "enroute_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: EnrouteException,
        //                     params: {
        //                         onShowStoppagesData: onShowStoppagesData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "overspeeding_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: Overspeeding,
        //                     params: {
        //                         onShowOverspeedingData: onShowOverspeedingData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // // {
        // //     headerName: "",
        // //     field: "nightdriving_exception",
        // //     width: 50,
        // //     pinned: "left",
        // //     cellRendererSelector: function (param) {
        // //         try {
        // //             if (param.data._id != null && param.data._id != undefined) {
        // //                 return {
        // //                     component: NightDriving,
        // //                     params: {
        // //                         onShowNightDrivingData: onShowNightDrivingData
        // //                     }
        // //                 }
        // //             }
        // //         } catch (err) {
        // //             console.log(err);
        // //         }
        // //     },
        // //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // // },
        // {
        //     headerName: "",
        //     field: "loading_delay_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: LoadingException,
        //                     params: {
        //                         onShowLoadingData: onShowLoadingData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "unloading_delay_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: UnloadingException,
        //                     params: {
        //                         onShowUnloadingData: onShowUnloadingData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        {
            headerName: "",
            field: "add_expenses",
            colId:"add_expenses",
            width: 150,
            hide: tabName.value == "completed" && ["admin","SPOC"].includes(localStorage.getItem("role")) ? false : true,
            pinned: "left",
            cellRenderer: (params) => {
                const handleClick = (e) => {
                    e.stopPropagation();
                    onClickAddExpensesSlider(params);
                };
                return (
                    // <button
                    //     onClick={handleClick}
                    //     className="btn"
                    //     style={{ padding: "2px 10px", fontSize: "18px", margin: "0" ,color: "#007bff",cursor: "pointer",border: "none",background: "none",outline:"none"}}
                    //     title="Update Expenses"
                    // >
                    //     <i className="fa fa-usd" style={{ color: "#007bff" }}></i>
                    // </button>

                    <button
                        onClick={handleClick}
                        className="btn btn-info"
                        style={{ padding: "2px 10px", fontSize: "13px", margin: "0" }}
                        title="Update Expenses"
                    >
                        Update Expenses
                    </button>
                );
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
        {
            headerName: "Status",
            field: "status",
            colId:"status",
            width:100,
               valueGetter: function (params) {
                if (params.data.status == 1) {
                    return "At Plant";
                }
                else if (params.data.status == 2) {
                    return "InTransit";
                }
                else if (params.data.status == 3) {
                    return "Near Dealer";
                }
                else if (params.data.status == 4) {
                    return "Reached Dealer";
                }
                else if (params.data.status == 5) {
                    return "Left Dealer";
                }
                else if (params.data.status == 6) {
                    return "Force Closed";
                }
                else if (params.data.status == 7) {
                    return "Proximity Trip Closure";
                }
                else if (params.data.status == 8) {
                    return "Force Closed";
                }
                else {
                    return "NA";
                }
            }
        
        },
        {
            headerName: "Original ETA",
            field: "expected_trip_end",
            colId: "expected_trip_end",
            id: "expected_trip_end",
            width: 120,
            //pinned: 'left',
            resizable: true,
            valueGetter: function(params) {
              if (
                params.data.expected_trip_end != "" &&
                params.data.expected_trip_end != undefined &&
                params.data.expected_trip_end != null
              ) {
                return getHyphenDDMMMYYYYHHMM(
                  params.data.expected_trip_end
                );
              } else {
                return "";
              }
            },
            //filter: "agDateColumnFilter",
            comparator: dateComparator,
          },
          {
            headerName: "Revised ETA",
            field: "revised_trip_end",
            colId: "revised_trip_end",
            width: 120,
            //pinned: 'left',
            resizable: true,
            // cellClass : "dateFormat",
            valueGetter: function(params) {
              //console.log(" ETA ", params.data)
              if (
                params.data.revised_trip_end == "" ||
                params.data.revised_trip_end == undefined
              ) {
                return getHyphenDDMMMYYYYHHMM(
                  params.data.expected_trip_end
                );
              } else {
                if(params.data.status >= 4)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                }
                else{
                    return getHyphenDDMMMYYYYHHMM(
                    params.data.revised_trip_end
                    );
                }
              }
            },
            //filter: "agDateColumnFilter",
            comparator: dateComparator,

            cellClass: function(params) {
              let hourDifference = 0;
              if (
                params.data.revised_trip_end &&
                params.data.expected_trip_end
              ) {
                let revisedDateOnlyMoment = moment(
                  params.data.revised_trip_end
                );
                let expectedDateOnlyMoment = moment(
                  params.data.expected_trip_end
                );
                hourDifference = revisedDateOnlyMoment.diff(
                  expectedDateOnlyMoment,
                  "hours"
                );
                //console.log("diff= ", params.data.consignment_code, hourDifference);
              } else {
                //console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
              }
// console.log('hourDifference', hourDifference)
              if (hourDifference >= 1) {
                return "bgColorDangerMedium";
              }

              if (hourDifference <= -1) {
                return "bgColorSuccessLight";
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Consignment Code",
            field: "consignment_code",
            colId: "consignment_code",
            width:180
        },
        {
            headerName: "Vehicle No",
            field: "truck_no",
            colId: "truck_no",
            width: 140,
        },
        {
            headerName: "Order Number",
            field: "order_number",
            colId: "order_number",
            width:140
        },
       

        // {
        //     headerName: "FO Number",
        //     field: "fo_number"
        // },
        
        {
            headerName: "Invoice Date",
            field: "invoice_time",
            colId: "invoice_time",
            width:150,
            valueGetter: function (params) {
                if (params.data.invoice_time != undefined && params.data.invoice_time != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                }
            }
        },
        {
            headerName: "Source Code",
            field: "consigner_code",
            colId: "consigner_code",
            width:140
        },
        {
            headerName: "Source Name",
            field: "consigner_name",
            colId: "consigner_name",
            width:140
        },
        {
            headerName: "Source City",
            field: "consigner_city",
            colId: "consigner_city",
            width:140
        },
        // {
        //     headerName: "Source State",
        //     field: "consigner_state",
        //     width:140
        // },
        {
            headerName: "Destination Code",
            field: "consignee_code",
            colId: "consignee_code",
            width:140
        },
        {
            headerName: "Destination name",
            field: "consignee_name",
            colId: "consignee_name",
            width:140
        },
        {
            headerName: "Destination City",
            field: "consignee_city",
            colId: "consignee_city",
            width:140
        },
        // {
        //     headerName: "Destination State",
        //     field: "consignee_state",
        //     width:140
        // },
        // {
        //     headerName: "Gate-in Time",
        //     field: "gate_in_time",
        //     width:150,
        //     valueGetter:function(params){
        //         try {
        //             if(params.data.gate_in_time !== "" && params.data.gate_in_time !== undefined)
        //             {
        //                 return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
        //             }
        //             else{
        //                 return "";
        //             }
        //         } catch (error) {
                    
        //         }
        //     }
        // },
        {
            headerName: "Gate-out Time",
            field: "gate_out_time",
            colId: "gate_out_time",
            width:150,
            valueGetter:function(params){
                try {
                    if(params.data.gate_out_time !== "" && params.data.gate_out_time !== undefined)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else{
                        return "";
                    }
                } catch (error) {
                    
                }
            }
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            colId: "transporter_code",
            width:140
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            colId: "transporter_name"
        },
        {
            headerName: "Transit Times (Days)",
            field: "transit_time",
            colId: "transit_time",
            width: 200,
            filter: "agSetColumnFilter",
            resizable: true,
            valueGetter: function(params) {
              
                return params.data.transit_time;
              
            },
        },
        {
            headerName: "Total Distance (Miles)",
            field: "distance_in_km",
            colId: "distance_in_km",
            width: 200,
            filter: "agSetColumnFilter",
            resizable: true,
            valueGetter:function(params){
                try{
                    if(params.data.distance_in_km !== "" && params.data.distance_in_km !== undefined)
                    {
                        return Math.round(params.data.distance_in_km/1.60934);
                    }
                    else{
                        return 0;
                    }
                }
                catch(e){}
            }
        },
        
        {
            headerName: "Travelled Distance (Miles)",
            field: "aprox_dist_travelled_from_start",
            colId: "aprox_dist_travelled_from_start",
            width: 200,
            resizable: true,
            valueGetter:function(params){
                try{
                    if(params.data.aprox_dist_travelled_from_start !== "" && params.data.aprox_dist_travelled_from_start !== undefined)
                    {
                        return Math.round(params.data.aprox_dist_travelled_from_start/1.60934);
                    }
                    else{
                        return 0;
                    }
                }
                catch(e){
                    return 0;
                }
            }
        },
        {
            headerName: "Transit Delay (Days)",
            field: "consignment_code",
            colId: "consignment_code",
            width: 180,
            filter: "agSetColumnFilter",
            resizable: true,
            valueGetter: function (params) {
                let expected=params.data.expected_trip_end
                let revised=''
                
                if (params.data.recent_dealer_reported == '' || params.data.recent_dealer_reported == undefined) {
                    revised=params.data.expected_trip_end;
                }
                else {
                    revised=params.data.recent_dealer_reported;
                }
                var s = moment.parseZone(expected).format("x");
                var ed = moment.parseZone(revised).format("x");
                var diff = (ed - s)
                var days = secondsToDays(diff)
                var totaldays = Math.round(days / 1000);
                if (totaldays > 0) {
                    return totaldays;
                }
                else {
                    return 0;
                }
               
            }
        },
        // {
        //     headerName: "Last Location",
        //     field: "address",
        //     width: 170,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         //// console.log(params.data.transporter);
        //         try {
        //             // // console.log("params.data.truck.length ", params.data.truck.length)
        //             if (params.data.truck.length > 0) {
        //                 // // console.log("params.data.truck ", params.data.truck)
        //                 if (params.data.truck[0].last_known_address != undefined) {
        //                     if (params.data.truck_no != "" && params.data.truck_no != "") {
        //                         return params.data.truck[0].last_known_address;
        //                     }
        //                     else {
        //                         return "";
        //                     }
        //                 }
        //                 else {
        //                     return "";
        //                 }
        //             }
        //             else {
        //                 return "";
        //             }
        //         }
        //         catch (e) {

        //         }
        //     }
        // },
        {
            headerName: "Travel Status",
            field: "elaped_time_from_last_moved_seconds",
            colId: "elaped_time_from_last_moved_seconds",
            width: 150,
            filter: "agSetColumnFilter",
            cellClass:function(params){
                try {
                    if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
                    {
                        if(params.data.duplicate_invoice === 1)
                        {
                            return ["cellstylegridO"]
                        }
                    }
                    else{
                        return "";
                    }
                } catch (error) {
                    
                }
            },
            valueGetter: function (params) {
                try {
                    if (params.data.truck_no != "" && params.data.truck_no != "") {
                        if (params.data.elaped_time_from_last_moved_seconds != "" && params.data.elaped_time_from_last_moved_seconds != undefined) {
                            return "Idle (" + params.data.elaped_time_from_last_moved_seconds + ") secs";
                        }

                        else {
                            if (params.data.elaped_time_from_last_moved_seconds == "" && params.data.elaped_time_from_last_moved_seconds == undefined) {

                                return "";
                            }
                            else {
                                if(params.data.status >= 4)
                                {
                                    return "Trip End";
                                } 
                                else{
                                    return "Running";
                                }
                                
                            }

                        }
                    }
                }
                catch (e) {

                }
            }
        },

        {
            headerName: "Speed (MPH)",
            field: "speed",
            colId: "speed",
            width: 150,
            valueGetter:function(params){
                try {
                    if(params.data.speed !== "" && params.data.speed !== undefined)
                    {
                        return Math.round(params.data.speed*0.621371);
                    }
                    else{
                        return 0;
                    }
                } catch (error) {
                    return 0;
                }
            },
            cellClass:function(params){
                try {
                    if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
                    {
                        if(params.data.duplicate_invoice === 1)
                        {
                            return ["cellstylegridO"]
                        }
                    }
                    else{
                        return "";
                    }
                } catch (error) {
                    
                }
            },
            filter: "agSetColumnFilter"
        },

        {
            headerName: "Last Packet Time",
            field: "timestamp",
            colId: "timestamp",
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
            },
           
        },
        // {
        //     headerName: "Vehicle Type",
        //     field: "truck_type",
        //     width:120
        // },
        // {
        //     headerName: "Quantity",
        //     field: "quantity",
        //     width:120
        // },
        // // {
        // //     headerName: "Gross Weight (VU)",
        // //     field: "gross_weight",
        // //     width:160
        // // },
        // // {
        // //     headerName: "Gross Volume (VU)",
        // //     field: "gross_volume",
        // //     width:160
        // // },
        // {
        //     headerName: "Driver Name",
        //     field: "driver_name",
        //     width:160
        // },
        // {
        //     headerName: "Driver Mobile No",
        //     field: "driver_mobile_no",
        //     width:160
        // },
        // {
        //     headerName: "Update Driver", 
        //     field: "add_driver",
        //     filter: true,
        //     hide : ["admin","SPOC"].includes(localStorage.getItem("role")) ? false : true,
        //     width: 150, 
        //     resizable: true,
        //     cellRenderer: (params) => {
        //         const handleClick = (e) => {
        //             e.stopPropagation();
        //             onClickAddDriverSlider(params)
        //         };
        //         return (
        //             <button
        //                 onClick={handleClick}
        //                 className="btn btn-info"
        //                 style={{ padding: "2px 10px", fontSize: "13px", margin: "0" }}
        //                 title="Update Driver"
        //                 disabled = {tabName.value == "completed" && (params.data.driver_name == "" ||  params.data.driver_mobile_no == "")}
        //             >
        //                 Update Driver
        //             </button>
        //         );
        //     }, 
        // },
        // {
        //     headerName: "Diesel Charges",
        //     field: "diesel_cost_rs",
        //     hide:tabName.value == "completed" && ["admin","SPOC"].includes(localStorage.getItem("role")) ? false : true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.diesel_cost_rs) {
        //                 return params.data.diesel_cost_rs.toFixed(2)
        //             }
        //             else {
        //                 return "";
        //             }
        //         } catch (error) {

        //         }
        //     },
        //     width:120
        // },
        // {
        //     headerName: "Landing Charges",
        //     field: "landing_cost_rs",
        //     hide:tabName.value == "completed" && ["admin","SPOC"].includes(localStorage.getItem("role")) ? false : true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.landing_cost_rs) {
        //                 return params.data.landing_cost_rs.toFixed(2)
        //             }
        //             else {
        //                 return "";
        //             }
        //         } catch (error) {

        //         }
        //     },
        //     width:120
        // },
        // {
        //     headerName: "Total Expenses",
        //     field: "total_expenses",
        //     width:120,
        //     hide: tabName.value == "completed" && ["admin","SPOC"].includes(localStorage.getItem("role")) ? false : true,
        //     valueGetter: function (params) {
        //         try {
        //             var diesel_cost_rs = parseFloat(params.data.diesel_cost_rs) || 0; 
        //             var landing_cost_rs = parseFloat(params.data.landing_cost_rs) || 0
        //             if (params.data.truck_expenses) {
        //                 return (diesel_cost_rs + landing_cost_rs + parseFloat(params.data.truck_expenses.totalExpenses)).toFixed(2)
        //             }
        //             else {
        //                 return (diesel_cost_rs + landing_cost_rs).toFixed(2)
        //             }
        //         } catch (error) {

        //         }
        //     },
        //     tooltipValueGetter: function(params) {
        //         return params.data.expenses_approval_status;
        //     },
        //     cellStyle: function(params) {
        //         let backgroundColor;
        //         switch (params.data.expenses_approval_status) {
        //             case "Approved":
        //                 backgroundColor = '#D4EDDA'; 
        //                 break;
        //             case "Rejected":
        //                 backgroundColor = '#F8D7DA'; 
        //                 break;
        //             case "Pending":
        //                 backgroundColor = '#FFF3CD'; 
        //                 break;
        //         }
        //         return { backgroundColor, cursor: 'pointer' };
        //     }
        // },
        
        // {
        //     headerName: "Created Date",
        //     field: "created_date",
        //     valueGetter: function (params) {
        //         if (params.data.created_date != undefined && params.data.created_date != '') {
        //             return getHyphenDDMMMYYYYHHMM(params.data.created_date)
        //         }
        //     }
        // },
        {
            headerName: "",
            field: "_id",
            colId: "forceclose",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: ForceClose,
                            params: {
                                onShowForceCloseModal: onShowForceCloseModal
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        }
    ];

    useEffect(() => {
        if (window.location.pathname == "/activenewconsignments") {
            // console.log(activeTrips.all,"checkkk")
            // var records =  groupBy(activeTrips.all,(rdata) => rdata.truck_no);
            // console.log(records,"check_records")
            setRowData(activeTrips.all)
            setTabName(tabOptions[0])
        }
        else if (window.location.pathname == "/completednewconsignments") {
            setRowData(completedTrips.all)
            setTabName(tabOptions[1])
        }
        else if (window.location.pathname == "/allnewconsignments") {
            setRowData(allTrips.all)
            setTabName(tabOptions[2])
        }
        setcolumnDefs(columnDefs2);
    }, [window.location.pathname])

    const onClickHideAll = () => {
        setOpenMap({
            sliderRouteTranslate: "",
            showDiv: 'show-n',
            mapinfo: [],
            dealer: "",
            consignment_code: "",
            maptruckno: "",
            geofence_coordinates: [],
            parking_coordinates: [],
            routeTruck: { "truck_no": '', "startTime": '', "endTime": '' },
            loadshow: 'show-n',
            sidebarSubHeader: "",
            overly: 'show-m',
            rownode: {},
            leg_no: 0,
            tolls: []
        })
        setBulkUploadModal({
            overly: "show-n",
            bulkPrioritySlide: ""
        })
        setAddExpensesModal({
            overly: "show-n",
            addExpensesSlide: ""
        })
        setApproveExpensesModal({
            overly: "show-n",
            approveExpensesSlide : ""
        })
        setaddDriverModal({
            overly: "show-n",
            setAddDriverSlide : ""
        })
        setOverlay("show-n");
        setLoadShow("show-n");
        setFavouriteModel("show-n");
    }

    const closeForceCloseModal = (e) => {
        setForcecloseModal(false)
        setReasonForceClose('')
        setRownode([])
    }

    const onShowForceCloseModal = (e) => {
        setForcecloseModal(true)
        setRownode(e)
        setReasonForceClose('')
    }

    const saveGrid = async (e) => {
        let coloumnState1 = gridApiConsignments.current.columnModel.gridColumns;
        // console.log('columnApiConsignments.columnApi', coloumnState1)
        // let coloumnState = columnApiConsignments.current.api.columnModel.columnDefs;
        var scols = []
        if(coloumnState1.length > 0)
        {
            coloumnState1.map((itm) => {
                console.log("itm", itm["userProvidedColDef"])
                scols.push(itm["userProvidedColDef"]);
            })
        }
        // console.log("coloumnState ", scols)
        let temp = await saveGridLayout(user.email, "consignments", scols)
        if (temp.data.status == "success") {
            setShow(true)
            setTitle("Grid successfully updated")
            setType("success")
        }
        else {
            setShow(true)
            setTitle("Failed to update grid")
            setType("danger")
        }
    }

    const resetGrid = async (e) => {
        let coloumnState = []
        let temp = await saveGridLayout(user.email, "consignments", coloumnState)
        if (temp.data.status == "success") {
            setShow(true)
            setTitle("Grid successfully updated")
            setType("success")
            reloadPage.current = true
        }
        else {
            setShow(true)
            setTitle("Failed to update grid")
            setType("danger")
        }
    }

    const formForceCloseHandler = (e) => {
        e.preventDefault()
        var consignemnt = rownode;
        var consignment_code = consignemnt.consignment_code;
        var truck_no = consignemnt.truck_no;
        var dept_code = "SNDG";
        var email = user?.email;
        var reqdata = {
            dept_code: dept_code,
            consignment_code: [consignment_code],
            truck_no: truck_no,
            email: email,
            reasonforceclose: reasonForceClose,
        }
        redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
            .then((response) => {
                if (response.data.status == 'Success') {
                    setShow(true)
                    setTitle("Successfully Force Closed the consignment")
                    setType("success")
                    closeForceCloseModal()
                    reloadPage.current = true
                }
                else {
                    setShow(true)
                    setTitle("Failed to Force Close")
                    setType("danger")
                }
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

    }

    const handleStartDateChange = (date) => {
        // console.log('date ', date)
        var d = new Date(date);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var sdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

        if (endDate && sdate > endDate) {
            setShow(true)
            setTitle("To Date should be greater than From Date")
            setType("danger")
        } else {
            setStartDate(sdate)
        }
    };

    const handleEndDateChange = (date) => {
        var d = new Date(date);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

        if (startDate && edate < startDate) {
            setShow(true)
            setTitle("To Date should be greater than From Date")
            setType("danger")
        } else {
            setEndDate(edate)
        }
    };


    const onShowTransitRouteMap = (rownode) => {
        var d = new Date(rownode.leg_start);
        var r = new Date(rownode.leg_end_eta);
        var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            consignee_code: rownode.consignee_code,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment"
        }
        redirectURL.post('/consignments/nightdriveroutes', params)
            .then((response) => {
                if (response.data.coords.length == 0) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                }
                else {
                    var sdate = '';
                    var edate = '';

                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
                    }
                    setOpenMap({
                        overly: "show-m",
                        sliderRouteTranslate: "slider-translate-60p",
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        consignment_code: "Vehicle No. : " + rownode.truck_no,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls,
                        defTransitCoords: rownode.consignee_coordinates,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const onShowEnrouteRouteMap = (rownode) => {
        var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        if (rownode.reset_datetime) {
            var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        }
        else {
            var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        }
        var params = {
            truck_no: rownode.truck_no,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment"
        }
        redirectURL.post('/consignments/nightdriveroutes', params)
            .then((response) => {
                let records = response.data
                if (response.data.coords.length == 0) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                }
                else {
                    var sdate = '';
                    var edate = '';
                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
                    }
                    if (response.data.route_details.start_time != "" && response.data.route_details.start_time != undefined) {
                        sdate = records.route_details.start_time;
                    }
                    if (response.data.route_details.end_time != "" && response.data.route_details.end_time != undefined) {
                        edate = records.route_details.end_time;
                    }
                    setOpenMap({
                        overly: "show-m",
                        sliderRouteTranslate: "slider-translate-60p",
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        consignment_code: "Vehicle No. : " + rownode.truck_no,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls,
                        defTransitCoords: rownode.consignee_coordinates,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const onShowNightDriveRouteMap = (rownode) => {
        var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment"
        }
        redirectURL.post('/consignments/nightdriveroutes', params)
            .then((response) => {
                if (response.data.coords.length == 0) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                }
                else {
                    setOpenMap({
                        overly: "show-m",
                        sliderRouteTranslate: "slider-translate-60p",
                        mapinfo: response.data,
                        consignment_code: rownode.truck_no,
                        defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
                        maptruckno: rownode.truck_no,
                        routeTruck: {
                            "truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
                            "endTime": response.data['route_details']['end_time']
                        },
                        loadshow: 'show-n',
                        tolls: response.data.tolls
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const onShowRouteMap = (rownode) => {
        var d = new Date(rownode.first_instance);
        var r = new Date(rownode.recent_instance);
        var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            consignee_code: rownode.consignee_code,
            first_instance: finstance,
            recent_instance: rinstance,
            screen: "consignment"
        }
        redirectURL.post('/consignments/overspeedgpsroutes', params)
            .then((response) => {
                setOpenMap({
                    overly: "show-m",
                    sliderRouteTranslate: "slider-translate-60p",
                    mapinfo: response.data,
                    dealer: rownode.consignee_code,
                    consignment_code: "Vehicle No. : " + rownode.truck_no,
                    maptruckno: rownode.truck_no,
                    routeTruck: { "truck_no": rownode.truck_no, "startTime": finstance, "endTime": rinstance },
                    loadshow: 'show-n',
                    sidebarSubHeader: "Consignment Leg Information",
                    overly: 'show-m',
                    rownode: rownode,
                    leg_no: rownode.leg_no,
                    tolls: response.data.tolls,
                    defTransitCoords: rownode.consignee_coordinates,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const onShowNoGPSRouteMap = (rownode) => {
        var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        eintance = eintance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim()
        if ((rownode.hasOwnProperty("exception_closed") && rownode.exception_closed == 1) || rownode.hasOwnProperty("packet_received_on"))
            eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment"
        }
        redirectURL.post('/consignments/nightdriveroutes', params)
            .then((response) => {
                if (response.data.coords.length == 0) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                }
                else {
                    var sdate = '';
                    var edate = '';
                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
                    }
                    setOpenMap({
                        overly: "show-m",
                        sliderRouteTranslate: "slider-translate-60p",
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        consignment_code: "Vehicle No. : " + rownode.truck_no,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls,
                        defTransitCoords: rownode.consignee_coordinates,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const onClickOpenBulkUploadModal = (e) => {
        setOverlay("show-m")
        setBulkUploadModal({
            overly: "show-m",
            bulkPrioritySlide: "slider-translate-84p"
        })
    }
    const closeBulkUploadModal = () => {
        setBulkUploadModal({
            overly: "show-n",
            bulkPrioritySlide: ""
        })
        setOverlay("show-n")
        setModalLoadData([])
    }

    const onCellDoubleClicked = (event) => {
        // if (event.colDef.field === "total_expenses" && event.data.truck_expenses && event.data.truck_expenses["totalExpenses"]) {
            if (event.colDef.field === "total_expenses") {
            const rowNode = event.node;

            // Ensure rowNode is defined and has the setExpanded method
            if (rowNode && rowNode.setExpanded) {
                rowNode.setExpanded(!rowNode.expanded);

                setdetailCellRendererParams({
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: "Shipment ID", field: "shipment_id", width: 150, resizable: true, filter: true },
                            { headerName: "Diesel Charges", field: "diesel_cost_rs", width: 150, resizable: true, filter: true },
                            { headerName: "Landing Charges", field: "landing_cost_rs", width: 150, resizable: true, filter: true },
                            { headerName: "Police Entry", field: "policeEntry", width: 150, resizable: true, filter: true },
                            { headerName: "Border Exp", field: "borderExp", width: 150, resizable: true, filter: true },
                            { headerName: "On Route Repairing", field: "onRouteRepairing", width: 150, resizable: true, filter: true },
                            { headerName: "RTO Challan", field: "rtoChallan", width: 150, resizable: true, filter: true },
                            { headerName: "Cash Toll", field: "cashToll", width: 150, resizable: true, filter: true },
                            { headerName: "Driver Exp", field: "driverExp", width: 150, resizable: true, filter: true },
                            { headerName: "Urea Purchase", field: "ureaPurchase", width: 150, resizable: true, filter: true },
                            { headerName: "Other", field: "other", width: 150, resizable: true, filter: true },
                            { headerName: "Total Expenses", field: "totalExpenses", width: 150, resizable: true, filter: true },
                            { headerName: "Validate Expenses", field: "validateExpense", width: 150, resizable: true, 
                                cellRenderer: (params) => {
                                const handleClick = (e) => {
                                    e.stopPropagation();
                                    // onClickAddExpensesSlider(params);
                                    onClickApproveSlider(params)
                                };
                                return (
                                    <button
                                        onClick={handleClick}
                                        className="btn btn-info"
                                        style={{ padding: "2px 10px", fontSize: "13px", margin: "0" }}
                                        title = {params.data.expenses_approval_status}
                                        disabled = {["Approved","Rejected"].includes(params.data.expenses_approval_status) }
                                    >
                                        Verify
                                    </button>
                                );
                            },filter: true,hide:localStorage.getItem("role") != "admin" && true },
                        ],
                    },
                    getDetailRowData: (params) => {
                        const truckExpenses = params.data.truck_expenses || {};
                        params.successCallback([{
                            shipment_id : params.data.shipment_id,
                            truck_no: params.data.truck_no,
                            expenses_approval_status : params.data.expenses_approval_status,
                            diesel_cost_rs: params.data.diesel_cost_rs || "0",
                            landing_cost_rs: params.data.landing_cost_rs || "0",
                            policeEntry: truckExpenses.policeEntry || "0",
                            borderExp: truckExpenses.borderExp || "0",
                            onRouteRepairing: truckExpenses.onRouteRepairing || "0",
                            rtoChallan: truckExpenses.rtoChallan || "0",
                            cashToll: truckExpenses.cashToll || "0",
                            driverExp: truckExpenses.driverExp || "0",
                            ureaPurchase: truckExpenses.ureaPurchase || "0",
                            other: truckExpenses.other || "0",
                            totalExpenses: truckExpenses.totalExpenses || (params.data.diesel_cost_rs + params.data.landing_cost_rs).toFixed(2)
                        }]);
                    },
                });
            } else {
                console.error("rowNode or rowNode.setExpanded is undefined");
            }
        }
    };
    

    const closeAddExpensesModal = () => {
        setAddExpensesModal({
            overly: "show-n",
            addExpensesSlide: ""
        })
        setOverlay("show-n")
    }

    const closeApproveExpensesModal = () => {
        setApproveExpensesModal({
            overly: "show-n",
            approveExpensesSlide: ""
        })
        setOverlay("show-n")
    }

    const closeAddDriverModal = () => {
        setaddDriverModal({
            overly: "show-n",
            setAddDriverSlide: ""
        })
        setOverlay("show-n")
    }


    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        if (/^\d*\.?\d*$/.test(value)) {  
          setExpenses((prevExpenses) => ({
            ...prevExpenses,
            [name]: value
          }));
        }
      }, []);

    const validateForm = () => {
        return Object.values(expenses).every(value => value.trim() !== '');
    };

    const saveExpenses = (e) => {
        e.preventDefault();
        if (validateForm()) {
            var totalExpensesValue = Object.values(expenses).map(value => parseFloat(value) || 0).reduce((acc, curr) => acc + curr, 0);
            const updatedExpenses = {
                ...expenses,
                totalExpenses: totalExpensesValue,
            };
            const params = {
                shipment_id: rowShipmentId,
                truck_expenses: updatedExpenses
            }
            redirectURL.post('/consignments/saveTruckExpenses', params)
                .then((response) => {
                    if (response.data.statusMessage == "success") {
                        console.log("check_herree")
                        setShow(true)
                        setTitle("Successfully Updated")
                        setType("success")
                        setAddExpensesModal({
                            overly: "show-n",
                            addExpensesSlide: ""
                        })
                        setOverlay("show-n")
                        window.location.reload()
                    }
                    else {
                        setShow(true)
                        setTitle("Failed to update Data")
                        setType("danger")
                        setAddExpensesModal({
                            overly: "show-n",
                            addExpensesSlide: ""
                        })
                        setOverlay("show-n")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            setShow(true)
            setTitle("Please fill in all the required fields.")
            setType("danger")
        }
    };

    const saveApprovalStatus = (e) => {
        e.preventDefault();
        if (approveStatus.value != "" && comment != "") {
            const params = {
                shipment_id: rowShipmentId,
                truck_no : rowTruckNo,
                approval_status: approveStatus.value,
                approval_comment: approveStatus.value === "Approved"
                    ? `Approved - ${comment}`
                    : approveStatus.value === "Rejected"
                    ? `Rejected - ${comment}`
                    : "",
                approved_by : localStorage.getItem("email")

            }
            redirectURL.post('/consignments/saveApprovalStatus', params)
                .then((response) => {
                    if (response.data.statusMessage == "success") {
                        console.log("check_herree")
                        setShow(true)
                        setTitle("Successfully Updated")
                        setType("success")
                        setApproveExpensesModal({
                            overly: "show-n",
                            approveExpensesSlide: ""
                        })
                        setOverlay("show-n")
                        window.location.reload()
                    }
                    else {
                        setShow(true)
                        setTitle("Failed to update Data")
                        setType("danger")
                        setApproveExpensesModal({
                            overly: "show-n",
                            approveExpensesSlide: ""
                        })
                        setOverlay("show-n")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            setShow(true)
            setTitle("Please fill in all the required fields.")
            setType("danger")
        }
    };

    const saveDriverDetails = (e) => {
        e.preventDefault();
        if (!["",undefined,"undefined",null].includes(driverName) && !["",undefined,"undefined",null].includes(driverMobile)) {
            const params = {
                shipment_id: rowShipmentId,
                truck_no : rowTruckNo,
                driver_name: driverName,
                driver_mobile_no: driverMobile,
                updated_by : localStorage.getItem("email")

            }
            redirectURL.post('/consignments/saveDriverDetails', params)
                .then((response) => {
                    if (response.data.statusMessage == "success") {
                        console.log("check_herree")
                        setShow(true)
                        setTitle("Successfully Updated")
                        setType("success")
                        setaddDriverModal({
                            overly: "show-n",
                            setAddDriverSlide: ""
                        })
                        setOverlay("show-n")
                        window.location.reload()
                    }
                    else {
                        setShow(true)
                        setTitle("Failed to update Data")
                        setType("danger")
                        setaddDriverModal({
                            overly: "show-n",
                            setAddDriverSlide: ""
                        })
                        setOverlay("show-n")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            setShow(true)
            setTitle("Please fill in all the required fields.")
            setType("danger")
        }
    };

    const closeMapModal = () => {
        setOverlay("show-n")
        setOpenMap({
            sliderRouteTranslate: "",
            showDiv: 'show-n',
            mapinfo: [],
            dealer: "",
            consignment_code: "",
            maptruckno: "",
            geofence_coordinates: [],
            parking_coordinates: [],
            routeTruck: { "truck_no": '', "startTime": '', "endTime": '' },
            loadshow: 'show-n',
            sidebarSubHeader: "",
            overly: 'show-m',
            rownode: {},
            leg_no: 0,
            tolls: []
        })
        setMapDetails()
    }
    const openMapModal = () => {

    }

    const CounterClick = (ecounter) => {
        if (tabName.value == "active") {
            switch (ecounter) {
                case "consignments":
                    setRowData(activeTrips.all)
                    setCounter({label:"Consignments",value:"Consignments"})
                    break;
                case "transit_delays":
                    setRowData(activeTrips.transit_delays)
                    setCounter({label:"Transit Delays",value:"Transit Delays"})
                    break;
                case "overspeeding":
                    setRowData(activeTrips.overspeeding)
                    setCounter({label:"Overspeeding",value:"Overspeeding"})
                    break;
                case "night_driving":
                    setRowData(activeTrips.night_driving)
                    setCounter({label:"Night Driving",value:"Night Driving"})
                    break;
                case "stoppages":
                    setRowData(activeTrips.stoppages)
                    setCounter({label:"Stoppages",value:"Stoppages"})
                    break;
                case "accidents":
                    setRowData(activeTrips.accidents)
                    setCounter({label:"Accidents",value:"Accidents"})
                    break;
                case "trip_deviations":
                    setRowData(activeTrips.trip_deviations)
                    setCounter({label:"Deviated Routes",value:"Deviated Routes"})
                    break;
                case "loading_delay":
                    setRowData(activeTrips.loading_delay)
                    setCounter({label:"Loading Delay",value:"Loading Delay"})
                    break;
                case "unloading_delay":
                    setRowData(activeTrips.unloading_delay)
                    setCounter({label:"Unload Delay",value:"Unload Delay"})
                    break;
                case "ontime_status":
                    setRowData(activeTrips.ontime_status)
                    setCounter({label:"On Time",value:"On Time"})
                    break;
                case "no_gps_data_lifetime":
                    setRowData(activeTrips.no_gps_data_lifetime)
                    setCounter({label:"Delayed",value:"Delayed"})
                    break;
                    case "no_gps_data":
                        setRowData(activeTrips.no_gps_data)
                        setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                        break;
                   
                default:
                    break;
            }
        }
        else if (tabName.value == "completed") {
            switch (ecounter) {
                case "consignments":
                    setRowData(completedTrips.all)
                    setCounter({label:"Consignments",value:"Consignments"})
                    break;
                case "transit_delays":
                    setRowData(completedTrips.transit_delay)
                    setCounter({label:"Transit Delays",value:"Transit Delays"})
                    break;
                case "overspeeding":
                    setRowData(completedTrips.overspeeding)
                    setCounter({label:"Overspeeding",value:"Overspeeding"})
                    break;
                case "night_driving":
                    setRowData(completedTrips.night_driving)
                    setCounter({label:"Night Driving",value:"Night Driving"})
                    break;
                case "stoppages":
                    setRowData(completedTrips.stoppages)
                    setCounter({label:"Stoppages",value:"Stoppages"})
                    break;
                case "accidents":
                    setRowData(completedTrips.accidents)
                    setCounter({label:"Accidents",value:"Accidents"})
                    break;
                case "trip_deviations":
                    setRowData(completedTrips.trip_deviations)
                    setCounter({label:"Deviated Routes",value:"Deviated Routes"})
                    break;
                case "loading_delay":
                    setRowData(completedTrips.loading_delay)
                    setCounter({label:"Loading Delay",value:"Loading Delay"})
                    break;
                case "unloading_delay":
                    setRowData(completedTrips.unloading_delay)
                    setCounter({label:"Unloading Delay",value:"Unloading Delay"})
                    break;
                case "no_gps_data":
                    setRowData(completedTrips.no_gps_data)
                    setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                    break;
                case "no_gps_data_lifetime":
                    setRowData(completedTrips.no_gps_data_lifetime)
                    setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                    break;
                default:
                    break;
            }
        }
        else if (tabName.value == "all") {
            switch (ecounter) {
                case "consignments":
                    setRowData(allTrips.all)
                    setCounter({label:"Consignments",value:"Consignments"})
                    break;
                case "transit_delays":
                    setRowData(allTrips.transit_delays)
                    setCounter({label:"Transit Delays",value:"Transit Delays"})
                    break;
                case "overspeeding":
                    setRowData(allTrips.overspeeding)
                    setCounter({label:"Overspeeding",value:"Overspeeding"})
                    break;
                case "night_driving":
                    setRowData(allTrips.night_driving)
                    setCounter({label:"Night Driving",value:"Night Driving"})
                    break;
                case "stoppages":
                    setRowData(allTrips.stoppages)
                    setCounter({label:"Stoppages",value:"Stoppages"})
                    break;
                case "accidents":
                    setRowData(allTrips.accidents)
                    setCounter({label:"Accidents",value:"Accidents"})
                    break;
                case "trip_deviations":
                    setRowData(allTrips.trip_deviations)
                    setCounter({label:"Deviated Routes",value:"Deviated Routes"})
                    break;
                case "loading_delay":
                    setRowData(allTrips.loading_delay)
                    setCounter({label:"Loading Delay",value:"Loading Delay"})
                    break;
                case "unloading_delay":
                    setRowData(allTrips.unloading_delay)
                    setCounter({label:"Unloading Delay",value:"Unloading Delay"})
                    break;
                case "no_gps_data":
                    setRowData(allTrips.no_gps_data)
                    setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                    break;
                case "no_gps_data_lifetime":
                    setRowData(allTrips.no_gps_data_lifetime)
                    setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                    break;
                default:
                    break;
            }
        }
    }
    
    const CounterClick1 = (ecounter) => {
        var data = activeTrips.all 
        if (tabName.value == "active") {
            switch (ecounter) {
                case "consignments":
                    setRowData(activeTrips.all)
                    setCounter({label:"Consignments",value:"Consignments"})
                    break;
                case "ontime":
                    var ontimedata = data.filter(e=>e.ontime_status==1)
                    setRowData(ontimedata)
                    setCounter({label:"On Time",value:"On Time"})
                    break;
                case "delayed":
                    var delayed = data.filter(e=>e.ontime_status==0)
                    setRowData(delayed)
                    setCounter({label:"Delayed",value:"Delayed"})
                    break;
                case "night_driving":
                    setRowData(activeTrips.night_driving)
                    setCounter({label:"Night Driving",value:"Night Driving"})
                    break;
                case "stoppages":
                    setRowData(activeTrips.stoppages)
                    setCounter({label:"Stoppages",value:"Stoppages"})
                    break;
                case "accidents":
                    setRowData(activeTrips.accidents)
                    setCounter({label:"Accidents",value:"Accidents"})
                    break;
                case "trip_deviations":
                    setRowData(activeTrips.trip_deviations)
                    setCounter({label:"Deviated Routes",value:"Deviated Routes"})
                    break;
                case "loading_delay":
                    setRowData(activeTrips.loading_delay)
                    setCounter({label:"Loading Delay",value:"Loading Delay"})
                    break;
                case "unloading_delay":
                    setRowData(activeTrips.unloading_delay)
                    setCounter({label:"Unload Delay",value:"Unload Delay"})
                    break;
                case "ontime_status":
                    setRowData(activeTrips.ontime_status)
                    setCounter({label:"On Time",value:"On Time"})
                    break;
                case "no_gps_data_lifetime":
                    setRowData(activeTrips.no_gps_data_lifetime)
                    setCounter({label:"Delayed",value:"Delayed"})
                    break;
                default:
                    break;
            }
        }
        else if (tabName.value == "completed") {
            var data = completedTrips.all
            switch (ecounter) {
                case "consignments":
                    setRowData(completedTrips.all)
                    setCounter({ label: "Consignments", value: "Consignments" })
                    break;
                case "ontime":
                    var ontimedata = data.filter(e => e.ontime_status == 1)
                    setRowData(ontimedata)
                    setCounter({ label: "On Time", value: "On Time" })
                    break;
                case "delayed":
                    var delayed = data.filter(e => e.ontime_status == 0)
                    setRowData(delayed)
                    setCounter({ label: "Delayed", value: "Delayed" })
                    break;
                case "night_driving":
                    setRowData(completedTrips.night_driving)
                    setCounter({ label: "Night Driving", value: "Night Driving" })
                    break;
                case "stoppages":
                    setRowData(completedTrips.stoppages)
                    setCounter({label:"Stoppages",value:"Stoppages"})
                    break;
                case "accidents":
                    setRowData(completedTrips.accidents)
                    setCounter({label:"Accidents",value:"Accidents"})
                    break;
                case "trip_deviations":
                    setRowData(completedTrips.trip_deviations)
                    setCounter({label:"Deviated Routes",value:"Deviated Routes"})
                    break;
                case "loading_delay":
                    setRowData(completedTrips.loading_delay)
                    setCounter({label:"Loading Delay",value:"Loading Delay"})
                    break;
                case "unloading_delay":
                    setRowData(completedTrips.unloading_delay)
                    setCounter({label:"Unloading Delay",value:"Unloading Delay"})
                    break;
                case "no_gps_data":
                    setRowData(completedTrips.no_gps_data)
                    setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                    break;
                case "no_gps_data_lifetime":
                    setRowData(completedTrips.no_gps_data_lifetime)
                    setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                    break;
                default:
                    break;
            }
        }
        else if (tabName.value == "all") {
            switch (ecounter) {
                case "consignments":
                    setRowData(allTrips.all)
                    setCounter({label:"Consignments",value:"Consignments"})
                    break;
                case "transit_delays":
                    setRowData(allTrips.transit_delays)
                    setCounter({label:"Transit Delays",value:"Transit Delays"})
                    break;
                case "overspeeding":
                    setRowData(allTrips.overspeeding)
                    setCounter({label:"Overspeeding",value:"Overspeeding"})
                    break;
                case "night_driving":
                    setRowData(allTrips.night_driving)
                    setCounter({label:"Night Driving",value:"Night Driving"})
                    break;
                case "stoppages":
                    setRowData(allTrips.stoppages)
                    setCounter({label:"Stoppages",value:"Stoppages"})
                    break;
                case "accidents":
                    setRowData(allTrips.accidents)
                    setCounter({label:"Accidents",value:"Accidents"})
                    break;
                case "trip_deviations":
                    setRowData(allTrips.trip_deviations)
                    setCounter({label:"Deviated Routes",value:"Deviated Routes"})
                    break;
                case "loading_delay":
                    setRowData(allTrips.loading_delay)
                    setCounter({label:"Loading Delay",value:"Loading Delay"})
                    break;
                case "unloading_delay":
                    setRowData(allTrips.unloading_delay)
                    setCounter({label:"Unloading Delay",value:"Unloading Delay"})
                    break;
                case "no_gps_data":
                    setRowData(allTrips.no_gps_data)
                    setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                    break;
                case "no_gps_data_lifetime":
                    setRowData(allTrips.no_gps_data_lifetime)
                    setCounter({label:"GPS Not Available",value:"GPS Not Available"})
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        let key = ''
        redirectURL.post('/tracking/details')
            .then((response) => {
                if (response.data.status === 'success') {
                    setKey(response.data.output[0].g_key);
                    key = response.data.output[0].g_key
                    loadScript("https://maps.googleapis.com/maps/api/js?key=" + key + "&libraries=places,drawing&callback=initMap");
                    window.initMap = async () => {
                        var lt = 28.4519751;
                        var ln = 77.0310713;
                        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
                            zoom: 13,
                            center: new window.google.maps.LatLng(lt, ln),
                            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                        });
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        redirectURL.post('consignments/getConsigner')
            .then((response) => {
                setConsignersList(response.data.records)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: "SNDG" })
            .then((resp) => {
                var cities = resp.data.cities;
                var filterCities = [];
                if (cities != undefined) {
                    if (cities.length > 0) {
                        cities.map(function (c) {
                            filterCities.push({
                                label: c,
                                value: c,
                            });
                        });
                    }
                }
                var states = resp.data.states;
                var filterStates = [];
                if (states != undefined) {
                    if (states.length > 0) {
                        states.map(function (s) {
                            filterStates.push({
                                label: s,
                                value: s,
                            });
                        });
                    }
                }
                var clusters = resp.data.clusters;
                var filterClusters = [];
                if (clusters != undefined) {
                    if (clusters.length > 0) {
                        clusters.map(function (cl) {
                            filterClusters.push({
                                label: cl,
                                value: cl,
                            });
                        });
                    }
                }
                var transporters = resp.data.transporters;
                var filterTransporters = [];
                if (transporters != undefined) {
                    if (transporters.length > 0) {
                        transporters.map(function (t) {
                            filterTransporters.push({
                                label: t,
                                value: t,
                            });
                        });
                    }
                }
                setFilterCities(filterCities)
                setFilterStates(filterStates)
                setFilterTransporters(filterTransporters)
            })
            setLoadShow("show-m")
        loadConsignmentsData()
        fetchGridLayout(user.email, "consignments")
            .then(res => {
                if (res.data.status == "success" && Array.isArray(res.data.data[0].columnState) && res.data.data[0].columnState.length > 0) {
                    setGridColumnState(res.data.data[0].columnState)

                    // const columnState = columnApiConsignments.current.getColumnState();
                    // console.log('Column State after move:', columnState);
                    // // Update the column definitions in state based on the new column order
                    // const newColumnDefs = columnState.map(colState => ({
                    //     field: colState.colId, // Get the field based on the colId
                    //     sortable: true,
                    //     filter: true,
                    // }));
                    // setcolumnDefs(newColumnDefs); 

                    setcolumnDefs(res.data.data[0].columnState);
                }
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (gridColumnState.length > 0 && columnApiConsignments.current) {
            columnApiConsignments.current.applyColumnState({
                state: gridColumnState,
                applyOrder: true,
            })
        }
    }, [gridColumnState, columnApiConsignments])


    const changeFileHandler = (e) => {
        setLoader(true)
        const formData = new FormData()
        formData.append("fileUpload", e.target.files[0])
        redirectURL.post("/tracking/readExcel", formData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Headers': 'Origin, Content-Type'
            }
        })
            .then((response) => {
                if (response.data.status == "success") {
                    if (response.data.data.length > 0) {
                        setModalLoadData(response.data.data)
                        setPlantData(response.data.plantData)
                        setTransporters(response.data.transporters)
                        setDealers(response.data.dealers)
                        setPreviousData(response.data.previousData)
                    }
                    else {
                        setModalLoadData([])
                    }
                }
                else {
                    setShow(true)
                    setTitle(response.data.message)
                    setType("danger")
                }
            })
            .catch(error => {
                console.log(error);
            })
        setLoader(false)
    }

    const onClickShowRoute = (rownode) => {
        setLoader(true)
        setOverlay("show-m")
        setLoadShow("show-m")
        let e = rownode.data
        var gateouttime = moment.parseZone(e.invoice_time).format('YYYY-MM-DD HH:mm:ss');
        var edate = '';
        var end_date;
        if (e.status <= 2) {
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
            edate = getHyphenDDMMMYYYYHHMM(end_date);
        }
        else if ((e.status == 3 || e.status == 4)) {
            if (e.recent_dealer_reported) {
                // end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                end_date = moment.parseZone(e.recent_dealer_reported).format('YYYY-MM-DD HH:mm:ss')
                edate = getHyphenDDMMMYYYYHHMM(e.recent_dealer_reported);
            }
        }
        else if (e.status == 5) {
            if (e.tvp_gate_in) {
                end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = getHyphenDDMMMYYYYHHMM(e.tvp_gate_in);
            }
            else if (e.left_dealer_marked) {
                // end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                end_date = moment.parseZone(e.left_dealer_marked).format('YYYY-MM-DD HH:mm:ss')
                edate = getHyphenDDMMMYYYYHHMM(e.left_dealer_marked);
            }
        }
        else if (e.status == 6) {
            if (e.tvp_gate_in) {
                // end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = getHyphenDDMMMYYYYHHMM(e.tvp_gate_in);
            } else if (e.force_closure_time) {
                end_date = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss')
                // end_date = getHyphenDDMMMYYYYHHMM(e.force_closure_time);
            }
        }
        else {
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
            edate = getHyphenDDMMMYYYYHHMM(end_date);
        }
        if ((e.transit_time) > 0) {
            var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time + 1) * 24 * 60 * 60 * 1000));
        }
        else {
            var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
        }

        var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

        if (!end_date) {
            // When end date is unavailable then current date is considered as end date.
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            //console.log("Defining end_date ", end_date);
        }
        var reqData = {
            truck_no: e.truck_no,
            consignee_code: e.consignee_code,
            shipment_id:e.shipment_id,
            gate_out_time: gateouttime,
            seconddate: end_date,
            screen: "consignment"
        }
        if (e.consignee_code !== undefined) reqData.consignee_code = e.consignee_code
        if (e.consignment_code !== undefined) reqData.consignment_code = e.consignment_code
        redirectURL.post('/consignments/maproutes', reqData, {
            headers: {
                'content-type': 'application/json'
            }
        })
            .then((response) => {
                var records = response.data;
                // this.setState({
                //     loadshow: 'show-m'
                // });
                try {
                    if(records.status === "Failure" || records.status === "failure")
                    {
                        setOverlay("show-n")
                        setLoadShow("show-n")
                        setShow(true)
                        setTitle("No GPS data available")
                        setType("danger")
                    }
                    else{
                        if (records.coords.length == 0) {
                            setOverlay("show-n")
                            setLoadShow("show-n")
                            setShow(true)
                            setTitle("No GPS data available")
                            setType("danger")
                        }
                        else {
                            if (records != '') {
                                var sdate = e.gate_out_time;
                                if (e.invoice_time) {
                                    var sdate = e.invoice_time;
                                }
                                else {
                                    if (e.gate_in_time) {
                                        var sdate = e.gate_in_time;
                                    }
                                    else {
                                        if (e.gate_out_time) {
                                            var sdate = e.gate_out_time;
                                        }
                                    }
                                }
                                var edate = '';
                                if (e.status <= 4) {
                                    end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                    edate = getHyphenDDMMMYYYYHHMM(end_date);
                                }
                                else if (e.status == 5) {
                                    if (e.left_dealer_marked) {
                                        edate = getHyphenDDMMMYYYYHHMM(e.left_dealer_marked);
                                    }
                                    else {
                                        end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                        edate = getHyphenDDMMMYYYYHHMM(end_date);
                                    }
                                }
                                if (e.deviation_flag == 1) {
                                    let consignee_city;
                                    consignee_city = e.consignee_city
                                    var params = {
                                        "base_route_id": e.base_route_id,
                                        "consigner_code": e.consigner_code,
                                        "consignee_city": consignee_city
                                    }
                                    redirectURL.post("/consignments/getDeviationRouteBaseRoute",
                                        params).then((resp) => {
                                            var baseRouteCoords = eval(resp.data.route_coords);
                                            setOpenMap({
                                                overly: "show-m",
                                                sliderRouteTranslate: "slider-translate-60p",
                                                mapinfo: records,
                                                dealer: e.consignee_code,
                                                consignment_code: "View Route #" + e.truck_no,
                                                maptruckno: e.truck_no,
                                                routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
                                                loadshow: 'show-n',
                                                sidebarSubHeader: "Route Information",
                                                rownode: e,
                                                leg_no: 0,
                                                tolls: records.tolls,
                                                baseRouteCoords: baseRouteCoords
                                            })
                                        })
                                    setLoadShow("show-n")
                                }
                                else {
                                    var params =
                                    {
                                        consignee_code: e.consignee_code,
                                        dept_code: e.dept_code
                                    }
                                    redirectURL.post('/consignments/geofencedata', params).then((response) => {
                                        var records1 = response.data.records;
                                        let parkingGeodata = []
                                        if (response.data.parkingGeofence[0] !== undefined) parkingGeodata = response.data.parkingGeofence[0]
                                        var geofence_coords = []
                                        if (records1.length) {
                                            geofence_coords = records1[0].geofence_coordinates;
                                        }
                                        setOpenMap({
                                            sliderRouteTranslate: "slider-translate-60p",
                                            showDiv: 'show-m',
                                            mapinfo: records,
                                            dealer: e.consignee_code,
                                            consignment_code: "View Route #" + e.truck_no,
                                            maptruckno: e.truck_no,
                                            geofence_coordinates: geofence_coords,
                                            parking_coordinates: parkingGeodata,
                                            routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
                                            loadshow: 'show-n',
                                            sidebarSubHeader: "Route Information",
                                            overly: 'show-m',
                                            rownode: e,
                                            leg_no: 0,
                                            tolls: records.tolls
                                        })
                                        setLoadShow("show-n")
                                    })
                                }
                            }
                        }
                    }
                }
                catch (e) {
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                }

            })
            .catch(function (error) {
                console.log(error);
            });
        setLoader(false)
    }

    const onShowTransitData = (params) => {
        
        // params.node.setExpanded(!params.node.expanded)
        let query = {
            truck_no: params.data.truck_no,
            consignment_code: params.data.consignment_code,
            consignee_code: params.data.consignee_code
        }
        redirectURL.post('/consignments/transitdelay', query)
            .then(resp => {
                // console.log('resp.data ', resp.data)
                if (resp.data?.length > 0) {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: transitdelaycols,
                            defaultColDef: {
                            },
                        },
                        getDetailRowData: (paramsDef) => {
                            paramsDef.successCallback(resp.data);
                        },
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onShowStoppagesData = (params) => {
        let query = {
            truck_no: params.data.truck_no,
            consignment_code: params.data.consignment_code
        }
        redirectURL.post('/consignments/enroutes', query)
            .then(resp => {
                if (resp.data?.length > 0) {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: stoppagescols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback(resp.data);
                        },
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onShowOverspeedingData = (params) => {
        let query = {
            truck_no: params.data.truck_no,
            consignment_code: params.data.consignment_code
        }
        redirectURL.post('/consignments/overspeed', query)
            .then(resp => {
                if (resp.data?.length > 0) {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: overspeedcols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback(resp.data);
                        },
                    })
                }
                else{
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: overspeedcols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback([]);
                        },
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onShowNightDrivingData = (params) => {
        params.node.setExpanded(!params.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: nightdrivingcols,
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                let nightdriving = []
                redirectURL.post('/consignments/nightdriving', {
                    consignment_code: params.data.consignment_code,
                    gate_out_time: params.data.gate_out_time,
                    truck_no: params.data.truck_no
                })
                    .then(async (response) => {
                        //console.log("Step 1 ",response.data)
                        nightdriving = response.data;
                        params.successCallback(nightdriving);
                        //console.log("Step 2 ",response.data)

                    });
            },
        })
    }

    const onShowLoadingData = (params) => {
        params.node.setExpanded(!params.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: [

                    {
                        headerName: "Gate In Time",
                        field: "gate_in_time",
                        width: 180,
                        resizable: true,
                        valueGetter: function (params) {
                            if (params.data.gate_in_time) {
                                return params.data.gate_in_time;
                            }
                            else {
                                return "NA"
                            }

                        }
                    },
                    {
                        headerName: "Gate Out Time",
                        field: "gate_out_time",
                        width: 180,
                        resizable: true,
                        valueGetter: function (params) {
                            if (params.data.gate_out_time) {
                                return params.data.gate_out_time;
                            }
                            else {
                                return "NA"
                            }
                        }
                    },
                    {
                        headerName: "Loading Time",
                        field: "loading_time",
                        width: 180,
                    }
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                if (params.data.gate_in_time != undefined) {
                    var g1 = getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                    var gdate1 = new Date(params.data.gate_in_time);
                    var fromDate = g1;
                    var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
                }
                else {

                    var g1 = getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
                    var gdate1 = new Date(params.data.inside_fence);
                    var fromDate = g1;
                    var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")

                }
                if (params.data.gate_out_time != undefined) {
                    var g2 = getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    var gdate2 = new Date(params.data.gate_out_time);
                    var toField = getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")
                }
                else {
                    var c = new Date();
                    var todaydate = moment(new Date(c)).format("x");
                    var g2 = getHyphenDDMMMYYYYHHMM(todaydate);
                    var gdate2 = new Date(todaydate);
                    var toField = getHyphenDDMMMYYYYHHMM(new Date());
                    var secondsgate2 = todaydate

                }

                var total = secondsgate2 - secondsgate1;
                var diffMs = total;
                var diffDays = Math.ceil(diffMs / 86400000); // days
                var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
                let loadingTimeDifference = null;
                var dataarr = []
                dataarr.push({
                    "gate_in_time": fromDate,
                    "gate_out_time": toField,
                    "loading_time": secondsToString(diffMs / 1000)
                })
                params.successCallback(dataarr);
            },
        })
    }


    const onClickShowLoadingAreaLegs = async (rownode) => {
        // console.log("rownode", rownode);
        rownode.node.setExpanded(!rownode.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs : [
                    {
                        headerName: Constant.COL_CONSIGNMENT_CODE,
                        field: "consignment_code",
                        resizable: true,
                        width: 160,
                        filter: true
                    },
                    {
                        headerName: Constant.COL_STATUS,
                        field: "consignment_status",
                        resizable: true,
                        width: 140,
                        filter: true,
                        valueGetter: function (params) {
                            //console.log("GPS ", params.data.gps_data_available);
                            if (params.data.consignment_status == 1) {
                                return Constant.INSIDE_TPA;
                            }
                            else if (params.data.consignment_status== 2) {
                                return Constant.INSIDE_MY_PMILL;
                            }
            
                            // if (params.data.status == 2 || params.data.status == "2") {
                            //     return Constant.INTRANSIT_TO_MY;
                            // }
                            else if (params.data.consignment_status == 3 || params.data.consignment_status== "3" ) {
                                return Constant.NEAR_CUSTOMER;
                            }
                            
                            else if (params.data.consignment_status == 4) {
                                return Constant.REACHED_CUSTOMER;
                            }
                            else if (params.data.consignment_status == 5) {
                                return Constant.LEFT_CUSTOMER;
                            }
                            
                            else if (params.data.consignment_status == 6) {
                                return Constant.FORCE_CLOSE;
                            }
                            else if (params.data.consignment_status == 7) {
                                return Constant.PROXIMITY_TRIP;
                            }
                            
                            else if (params.data.consignment_status == 8) {
                                return Constant.INTRANSIT_TO_MY;
                            }
                            else {
                                return ""
                            }
                        }
                    },
                    // {
                    //     headerName: Constant.COL_DELIVERY_NO,
                    //     field: "delivery_no",
                    //     width: 120,
                    //     resizable: true,
                    //     filter: true,
                    // },
                    {
                        headerName: Constant.COL_SALE_ORDER,
                        field: "order_number",
                        width: 120,
                        resizable: true,
                        filter: true,
                    },
                    {
                        headerName: "Original ETA",
                        field: "expected_trip_end",
                        width: 150,
                        filter:true,
                        resizable: true,
                        valueGetter: function (params) {
                            if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined) {
                                return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
                            } else {
                                return "N/A"
                            }
                        },
                        comparator: dateComparator,
                    },
                    {
                        headerName: "Revised ETA",
                        field: "revised_trip_end",
                        width: 150,
                        filter:true,
                        resizable: true,
                        valueGetter: function (params) {
                            //console.log(" ETA ", params.data)
                            if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
                                if (params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined) {
                                    return "N/A"
                                }
                                else {
                                    return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
                                }
                            }
                            else {
                                return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
                            }
                        },
                        // filter: "agDateColumnFilter",
                        comparator: dateComparator,
                        cellClass: function (params) {
                            let hourDifference = 0;
                            if (params.data.revised_trip_end && params.data.expected_trip_end) {
                                let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
                                let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
                                hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
                                //console.log("diff= ", params.data.consignment_code, hourDifference);
                            } else {
                                //console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
                            }

                            if (hourDifference >= 1) {
                                return 'bgColorDangerMedium'
                            }

                            if (hourDifference <= -1) {
                                return 'bgColorSuccessMedium'
                            }
                            else {
                                return 'N/A'
                            }
                        }

                    },
                   
                    // {
                    //     headerName: Constant.COL_ITEM_NO,
                    //     field: "item_no",
                    //     width: 120,
                    //     resizable: true,
                    //     filter: true,
                    // },
                    // {
                    //     headerName: Constant.COL_ITEM_DESC,
                    //     field: "item_desc",
                    //     width: 200,
                    //     resizable: true,
                    //     filter: true,
                    // },
                    {
                        headerName: Constant.COL_ITEM_QTY,
                        field: "quantity",
                        width: 120,
                        resizable: true,
                        filter: true,
                    },
                    // {
                    //     headerName: Constant.MATERIAL_INFO,
                    //     field: "material_desc",
                    //     resizable: true,
                    //     filter: true,
                    //     width: 120,
                    // },

                    // {
                    //     headerName: Constant.SR_GRADE,
                    //     field: "sr_grade",
                    //     resizable: true,
                    //     filter: true,
                    //     width: 120,
                    // },
                    {
                        headerName: Constant.COL_CUSTOMER_NAME,
                        field: "consignee_name",
                        width: 200,
                        resizable: true,
                        filter: true,
                    },
                    {
                        headerName: Constant.COL_CUSTOMER_CODE,
                        field: "consignee_code",
                        width: 150,
                        resizable: true,
                        filter: true,
                    },
                    {
                        headerName: Constant.COL_CUSTOMER_CITY,
                        field: "consignee_city",
                        width: 140,
                        resizable: true,
                        filter: true,
                    },
                    {
                        headerName: Constant.COL_CONSIGNEE_STATE,
                        field: "consignee_state",
                        width: 140,
                        valueGetter: function (params) {
                            try {
                                if (params.data.consignee_state != "" && params.data.consignee_state != undefined) {
                                    return params.data.consignee_state;
                                }
                                else {
                                    return "No Data Available";
                                }

                            }
                            catch (e) { }
                        },
                        resizable: true,
                    },
                    {
                        headerName: Constant.COL_CONSIGNEE_ADDRESS,
                        field: "consignee_area",
                        width: 200,
                        valueGetter: function (params) {
                            try {
                                if (params.data.consignee_area != "" && params.data.consignee_area != undefined) {
                                    return params.data.consignee_area;
                                }
                                else {
                                    return "No Data Available";
                                }

                            }
                            catch (e) { }
                        },
                        resizable: true,
                    },
                    
                    {
                        headerName: Constant.COL_EXPECTED_DISTANCE_KM,
                        field: "distance_in_km",
                        width: 180,
                        resizable: true,
                        filter: true,
                        valueGetter:function(params){
                            try{
                                if(params.data.distance_in_km !== "" && params.data.distance_in_km !== undefined)
                                {
                                    return Math.round(params.data.distance_in_km/1.60934);
                                }
                                else{
                                    return 0;
                                }
                            }
                            catch(e){}
                        }
                    },
                    {
                        headerName: "Reached Dealer",
                        field: "recent_dealer_reported",
                        hide:tabName.value != "completed",
                        width:160
                    },
                    {
                        headerName: "Left Dealer",
                        field: "left_dealer_marked",
                        hide:tabName.value != "completed",
                        width:160
                    },
                    // {
                    //     headerName: "Remaining Distance To Customer (KM)",
                    //     field: "distance_from_dealer_location",
                    //     width: 180,
                    //     filter: "agSetColumnFilter",
                    //     cellRenderer: '', resizable: true,
                    //     valueGetter: function (params) {
                    //         try {
                    //             if (params.data.distance_from_dealer_location != "" && params.data.distance_from_dealer_location != undefined) {
                    //                 return params.data.distance_from_dealer_location;
                    //             }

                    //             else {
                    //                 return "No Data Available";
                    //             }

                    //         }
                    //         catch (e) { }
                    //     }
                    // },
                    // {
                    //     headerName: "ETA",
                    //     field: "revised_trip_end",
                    //     width: 120,
                    //     resizable: true,
                    //     // hide: hideForNotCustomer,
                    //     valueGetter: function (params) {
                    //         //console.log(" ETA ", params.data)
                    //         if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
                    //             if (params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined) {
                    //                 return "N/A"
                    //             }
                    //             else {
                    //                 return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
                    //             }
                    //         }
                    //         else {
                    //             return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
                    //         }
                    //     },

                    // }



                    // {
                    // 	headerName: "AMNS Distance (KM)",
                    // 	field: "distance_in_km",
                    // 	width: 200,
                    // 	filter: "agSetColumnFilter",
                    // 	cellRenderer: '', resizable: true
                    // },

                    // {
                    // 	headerName: "Transit Time (Days)",
                    // 	field: "transit_time",
                    // 	width: 200,
                    // 	filter: "agSetColumnFilter",
                    // 	cellRenderer: '', resizable: true
                    // },

                ]
            },

            getDetailRowData: (params) => {
                let truckConsignments = []
                // console.log("params", params)
                if(params.data.consignmentlegs !== "" && params.data.consignmentlegs !== undefined)
                {
                    var consignmentLegs = JSON.parse(params.data.consignmentlegs);
                }
                else{
                    var consignmentLegs = [];
                }
                params.successCallback(consignmentLegs);
                // redirectURL.post('/consignments/getTruckConsignments', {
                //     truck_no: params.data.truck_no
                // })
                //     .then(async (response) => {
                //         console.log("Step 1 ", response.data)
                //         truckConsignments = response.data.truckConsignments;
                //         params.successCallback(truckConsignments);
                //         //console.log("Step 2 ",response.data)

                //     });
            }

        })
    };

    const onShowUnloadingData = (params) => {
        // params.node.setExpanded(!params.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: unloadingcols,
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                // console.log(params.data,"params")
                if (params.data.locked_dealer_reported == undefined) {
                    var g1 = getHyphenDDMMMYYYYHHMM(params.data.first_dealer_reported);
                    var fromField = g1;
                }
                else {
                    var g1 = getHyphenDDMMMYYYYHHMM(params.data.locked_dealer_reported);
                    var fromField = g1;
                }
                if (params.data.left_dealer_marked == undefined) {
                    var c = new Date();
                    var todaydate = c;
                    var g2 = getHyphenDDMMMYYYYHHMM(todaydate);
                    var toField = g2;
                }
                else {
                    var g2 = getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                    var toField = g2;
                }
                var fstr = fromField.split("-");
                var gdate1 = new Date(fromField);
                var gdate2 = new Date(toField);
                var frmd = gdate1.getTime();
                var tod = gdate2.getTime();
                var total = tod - frmd;
                var diffMs = total;
                var diffDays = Math.floor(diffMs / 86400000); // days
                var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
                var dataarr = []
                let loadingTimeDifference = null;
                loadingTimeDifference = secondsToString(diffMs / 1000)
                if(fromField){
                    params.node.setExpanded(!params.node.expanded)
                    dataarr.push({
                        "from_date": fromField,
                        "to_date": toField,
                        "unloading_time": loadingTimeDifference
                    })
                    params.successCallback(dataarr);
                }
            },
        })
    }

    const onClickAddExpensesSlider = (params)=> {
        setOverlay("show-m")
        setAddExpensesModal({
            overly: "show-m",
            addExpensesSlide: "slider-translate",
        })
        setShipmentId(params.data.shipment_id);
    }

    const onClickApproveSlider = (params) => {
        setOverlay("show-m")
        setApproveExpensesModal({
            overly: "show-m",
            approveExpensesSlide : "slider-translate"
        })
        console.log(params.data,"params.data")
        setShipmentId(params.data.shipment_id);
        setTruckNo(params.data.truck_no)
    }

    const onClickAddDriverSlider = (params) => {
        setOverlay("show-m")
        setaddDriverModal({
            overly: "show-m",
            setAddDriverSlide : "slider-translate"
        })
        setShipmentId(params.data.shipment_id);
        setTruckNo(params.data.truck_no)
    }

    // const onShowNoGPSData = (params) => {
    //     // params.node.setExpanded(!params.node.expanded)
    //     setdetailCellRendererParams({
    //         detailGridOptions: {
    //             columnDefs: nogpscols,
    //             defaultColDef: {
    //                 flex: 1,
    //             },
    //         },
    //         getDetailRowData: (params) => {
    //             let nogpsrecords = []
    //             redirectURL.post('/consignments/nogpsdata', {
    //                 consignment_code: params.data.consignment_code,
    //                 truck_no: params.data.truck_no
    //             })
    //                 .then(async (response) => {
    //                     if (response.data?.length > 0) {
    //                         params.node.setExpanded(!params.node.expanded)
    //                         nogpsrecords = response.data;
    //                         params.successCallback(nogpsrecords);
    //                     }
    //                 });
    //         },
    //     })
    // }
    
    const onShowNoGPSData = (params) => {
        // params.node.setExpanded(!params.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: nogpscols,
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                let nogpsrecords = []
                redirectURL.post('/consignments/nogpsdata', {
                    consignment_code: params.data.consignment_code,
                    truck_no: params.data.truck_no
                })
                    .then(async (response) => {
                        nogpsrecords = response.data;
                        params.successCallback(nogpsrecords);

                    });
            },
        }) 
    }

    const fragmentData = (resp) => {
        if (resp.data.status == "success") {
            // setRowData(resp.data.consignments)
            var allconsignments = resp.data.consignments;
            let allactive = resp.data.consignments.filter(e => (e.status == 1 || e.status == 2 || e.status == 3))
            // var records =  groupBy(active_trips,(rdata) => rdata.truck_no);
            // var allactive=[]
            // try {
            //     records.forEach((consignments,truckno)=>{
            //         var rw = {}
            //         rw = consignments[0]
            //         rw.consignmentlegs = consignments;
            //         allactive.push(rw)
            //     })
            // } catch (error) {
                
            // }
            let allcomplete = resp.data.consignments.filter(e => (e.status == 4 || e.status == 5 || e.status == 6 || e.status == 7))
            // var recordcomplete =  groupBy(completed_trips,(rdata) => rdata.truck_no);
            // var allcomplete=[]
            // try {
            //     recordcomplete.forEach((consignments,truckno)=>{
            //         var rw = {}
            //         rw = consignments[0]
            //         rw.consignmentlegs = consignments;
            //         allcomplete.push(rw)
            //     })
            // } catch (error) {
                
            // }

            // var allconsignments =  groupBy(resp.data.consignments,(rdata) => rdata.truck_no);
            // var allconsignments=[]
            // try {
            //     recordall.forEach((consignments,truckno)=>{
            //         var rw = {}
            //         rw = consignments[0]
            //         rw.consignmentlegs = consignments;
            //         allconsignments.push(rw)
            //     })
            // } catch (error) {
                
            // }
            let active_transit_delay = []
            let active_overspeeding = []
            let active_night_driving = []
            let active_stoppages = []
            let active_accidents = []
            let active_trip_deviations = []
            let active_loadingdelay = []
            let active_unloadingdelay = []
            let active_no_gps_data = []
            let active_no_gps_data_lifetime = []
            let completed_transit_delay = []
            let completed_overspeeding = []
            let completed_delay = []
            let completed_ontime = []
            let completed_night_driving = []
            let completed_stoppages = []
            let completed_accidents = []
            let completed_trip_deviations = []
            let completed_loadingdelay = []
            let completed_unloadingdelay = []
            let completed_no_gps_data = []
            let completed_no_gps_data_lifetime = []
            let transit_delay = []
            let overspeeding = []
            let night_driving = []
            let stoppages = []
            let accidents = []
            let trip_deviations = []
            let loading_delay = []
            let unloading_delay = []
            let no_gps_data = []
            let no_gps_data_lifetime = []
            allactive.map(each => {
                if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    active_transit_delay.push(each)
                }
                if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    active_overspeeding.push(each)
                }
                if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    active_night_driving.push(each)
                }
                if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    active_stoppages.push(each)
                }
                if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    active_accidents.push(each)
                }
                if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                    active_trip_deviations.push(each)
                }
                if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    active_loadingdelay.push(each)
                }
                if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    active_unloadingdelay.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    active_no_gps_data.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    active_no_gps_data_lifetime.push(each)
                }
            })
            allcomplete.map(each => {
                if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    completed_transit_delay.push(each)
                }
                if (each.hasOwnProperty("ontime_status") && each.ontime_status == 1) {
                    completed_ontime.push(each)
                }
                if (each.hasOwnProperty("ontime_status") && each.ontime_status == 0) {
                    completed_delay.push(each)
                }
                if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    completed_overspeeding.push(each)
                }
                if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    completed_night_driving.push(each)
                }
                if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    completed_stoppages.push(each)
                }
                if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    completed_accidents.push(each)
                }
                if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                    completed_trip_deviations.push(each)
                }
                if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    completed_loadingdelay.push(each)
                }
                if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    completed_unloadingdelay.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    completed_no_gps_data.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    completed_no_gps_data_lifetime.push(each)
                }
            })
            allconsignments.map(each => {
                if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    transit_delay.push(each)
                }
                if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    overspeeding.push(each)
                }
                if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    night_driving.push(each)
                }
                if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    stoppages.push(each)
                }
                if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    accidents.push(each)
                }
                if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                    trip_deviations.push(each)
                }
                if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    loading_delay.push(each)
                }
                if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    unloading_delay.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    no_gps_data.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    no_gps_data_lifetime.push(each)
                }
            })
            setCountersActive({
                consignments: allactive.length,
                transit_delays: active_transit_delay.length,
                overspeeding: active_overspeeding.length,
                night_driving: active_night_driving.length,
                stoppages: active_stoppages.length,
                accidents: active_accidents.length,
                trip_deviations: active_trip_deviations.length,
                loading_delay: active_loadingdelay.length,
                unloading_delay: active_unloadingdelay.length,
                no_gps_data: active_no_gps_data.length,
                no_gps_data_lifetime: active_no_gps_data_lifetime.length
            })
            setCountersCompleted({
                consignments: allcomplete.length,
                ontime:completed_ontime.length,
                delay:completed_delay.length,
                transit_delays: completed_transit_delay.length,
                overspeeding: completed_overspeeding.length,
                night_driving: completed_night_driving.length,
                stoppages: completed_stoppages.length,
                accidents: completed_accidents.length,
                trip_deviations: completed_trip_deviations.length,
                loading_delay: completed_loadingdelay.length,
                unloading_delay: completed_unloadingdelay.length,
                no_gps_data: completed_no_gps_data.length,
                no_gps_data_lifetime: completed_no_gps_data_lifetime.length
            })
            setCountersAll({
                consignments: allconsignments.length,
                transit_delays: transit_delay.length,
                overspeeding: overspeeding.length,
                night_driving: night_driving.length,
                stoppages: stoppages.length,
                accidents: accidents.length,
                trip_deviations: trip_deviations.length,
                loading_delay: loading_delay.length,
                unloading_delay: unloading_delay.length,
                no_gps_data: no_gps_data.length,
                no_gps_data_lifetime: no_gps_data_lifetime.length
            })
            setActiveTrips({
                all: allactive,
                transit_delays: active_transit_delay,
                overspeeding: active_overspeeding,
                night_driving: active_night_driving,
                stoppages: active_stoppages,
                accidents: active_accidents,
                trip_deviations: active_trip_deviations,
                loading_delay: active_loadingdelay,
                unloading_delay: active_unloadingdelay,
                no_gps_data: active_no_gps_data,
                no_gps_data_lifetime: active_no_gps_data_lifetime
            })
            setCompletedTrips({
                all: allcomplete,
                ontime:completed_ontime.length,
                delay:completed_delay.length,
                transit_delays: completed_transit_delay,
                overspeeding: completed_overspeeding,
                night_driving: completed_night_driving,
                stoppages: completed_stoppages,
                accidents: completed_accidents,
                trip_deviations: completed_trip_deviations,
                loading_delay: completed_loadingdelay,
                unloading_delay: completed_unloadingdelay,
                no_gps_data: completed_no_gps_data,
                no_gps_data_lifetime: completed_no_gps_data_lifetime
            })
            setAllTrips({
                all: allconsignments,
                transit_delays: transit_delay,
                overspeeding: overspeeding,
                night_driving: night_driving,
                stoppages: stoppages,
                accidents: accidents,
                trip_deviations: trip_deviations,
                loading_delay: loading_delay,
                unloading_delay: unloading_delay,
                no_gps_data: no_gps_data,
                no_gps_data_lifetime: no_gps_data_lifetime
            })
            // console.log("allactive", allactive)
            if (window.location.pathname == "/activenewconsignments") {
                setRowData(allactive)
                setTabName(tabOptions[0])
            }
            else if (window.location.pathname == "/completednewconsignments") {
                setRowData(allcomplete)
                setTabName(tabOptions[1])
            }
            else if (window.location.pathname == "/allnewconsignments") {
                setRowData(allconsignments)
                setTabName(tabOptions[2])
            }
            setLoadShow("show-n")
        }
    }

    const loadConsignmentsData = async () => {
        var query = {};
       
        // query["sdate"] = moment.parseZone().subtract(1,"month").format("YYYY-MM-DD")
        // query["edate"] = moment.parseZone().format("YYYY-MM-DD")
        await redirectURL.post("consignments/getNewConsignments", query)
            .then(resp => {
                // console.log("response", resp.data)
                if (resp.data.status == "success") {
                    // setRowData(resp.data.consignments)
                    // let active_trips = resp.data.consignments.filter(e => (e.status == 1 || e.status == 2 || e.status == 3 || e.status == 4))
                    // // console.log("active_trips", active_trips)
                    // var records =  groupBy(active_trips,(rdata) => rdata.truck_no);
                    // var allactive=[]
                    // try {
                    //     records.forEach((consignments,truckno)=>{
                    //         var rw = {}
                    //         rw = consignments[0]
                    //         rw.consignmentlegs = consignments;
                    //         allactive.push(rw)
                    //     })
                    // } catch (error) {
                        
                    // }
                    // // console.log("allactive", allactive)
                    // let completed_trips = resp.data.consignments.filter(e => (e.status == 5 || e.status == 6 || e.status == 7))
                    // let active_transit_delay = []
                    // let active_overspeeding = []
                    // let active_night_driving = []
                    // let active_stoppages = []
                    // let active_accidents = []
                    // let active_trip_deviations = []
                    // let active_loadingdelay = []
                    // let active_unloadingdelay = []
                    // let active_no_gps_data = []
                    // let active_no_gps_data_lifetime = []
                    // let completed_transit_delay = []
                    // let completed_overspeeding = []
                    // let completed_night_driving = []
                    // let completed_stoppages = []
                    // let completed_accidents = []
                    // let completed_trip_deviations = []
                    // let completed_loadingdelay = []
                    // let completed_unloadingdelay = []
                    // let completed_no_gps_data = []
                    // let completed_no_gps_data_lifetime = []
                    // let transit_delay = []
                    // let overspeeding = []
                    // let night_driving = []
                    // let stoppages = []
                    // let accidents = []
                    // let trip_deviations = []
                    // let loading_delay = []
                    // let unloading_delay = []
                    // let no_gps_data = []
                    // let no_gps_data_lifetime = []
                    // active_trips.map(each => {
                    //     if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    //         active_transit_delay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    //         active_overspeeding.push(each)
                    //     }
                    //     if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    //         active_night_driving.push(each)
                    //     }
                    //     if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    //         active_stoppages.push(each)
                    //     }
                    //     if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    //         active_accidents.push(each)
                    //     }
                    //     if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                    //         active_trip_deviations.push(each)
                    //     }
                    //     if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    //         active_loadingdelay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    //         active_unloadingdelay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    //         active_no_gps_data.push(each)
                    //     }
                    //     if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    //         active_no_gps_data_lifetime.push(each)
                    //     }
                    // })
                    // completed_trips.map(each => {
                    //     if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    //         completed_transit_delay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    //         completed_overspeeding.push(each)
                    //     }
                    //     if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    //         completed_night_driving.push(each)
                    //     }
                    //     if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    //         completed_stoppages.push(each)
                    //     }
                    //     if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    //         completed_accidents.push(each)
                    //     }
                    //     if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                    //         completed_trip_deviations.push(each)
                    //     }
                    //     if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    //         completed_loadingdelay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    //         completed_unloadingdelay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    //         completed_no_gps_data.push(each)
                    //     }
                    //     if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    //         completed_no_gps_data_lifetime.push(each)
                    //     }
                    // })
                    // resp.data.consignments.map(each => {
                    //     if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    //         transit_delay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    //         overspeeding.push(each)
                    //     }
                    //     if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    //         night_driving.push(each)
                    //     }
                    //     if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    //         stoppages.push(each)
                    //     }
                    //     if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    //         accidents.push(each)
                    //     }
                    //     if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                    //         trip_deviations.push(each)
                    //     }
                    //     if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    //         loading_delay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    //         unloading_delay.push(each)
                    //     }
                    //     if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    //         no_gps_data.push(each)
                    //     }
                    //     if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    //         no_gps_data_lifetime.push(each)
                    //     }
                    // })
                    // setCountersActive({
                    //     consignments: allactive.length,
                    //     transit_delays: active_transit_delay.length,
                    //     overspeeding: active_overspeeding.length,
                    //     night_driving: active_night_driving.length,
                    //     stoppages: active_stoppages.length,
                    //     accidents: active_accidents.length,
                    //     trip_deviations: active_trip_deviations.length,
                    //     loading_delay: active_loadingdelay.length,
                    //     unloading_delay: active_unloadingdelay.length,
                    //     no_gps_data: active_no_gps_data.length,
                    //     no_gps_data_lifetime: active_no_gps_data_lifetime.length
                    // })
                    // setCountersCompleted({
                    //     consignments: completed_trips.length,
                    //     transit_delays: completed_transit_delay.length,
                    //     overspeeding: completed_overspeeding.length,
                    //     night_driving: completed_night_driving.length,
                    //     stoppages: completed_stoppages.length,
                    //     accidents: completed_accidents.length,
                    //     trip_deviations: completed_trip_deviations.length,
                    //     loading_delay: completed_loadingdelay.length,
                    //     unloading_delay: completed_unloadingdelay.length,
                    //     no_gps_data: completed_no_gps_data.length,
                    //     no_gps_data_lifetime: completed_no_gps_data_lifetime.length
                    // })
                    // setCountersAll({
                    //     consignments: resp.data.consignments.length,
                    //     transit_delays: transit_delay.length,
                    //     overspeeding: overspeeding.length,
                    //     night_driving: night_driving.length,
                    //     stoppages: stoppages.length,
                    //     accidents: accidents.length,
                    //     trip_deviations: trip_deviations.length,
                    //     loading_delay: loading_delay.length,
                    //     unloading_delay: unloading_delay.length,
                    //     no_gps_data: no_gps_data.length,
                    //     no_gps_data_lifetime: no_gps_data_lifetime.length
                    // })
                    // setActiveTrips({
                    //     all: allactive,
                    //     transit_delays: active_transit_delay,
                    //     overspeeding: active_overspeeding,
                    //     night_driving: active_night_driving,
                    //     stoppages: active_stoppages,
                    //     accidents: active_accidents,
                    //     trip_deviations: active_trip_deviations,
                    //     loading_delay: active_loadingdelay,
                    //     unloading_delay: active_unloadingdelay,
                    //     no_gps_data: active_no_gps_data,
                    //     no_gps_data_lifetime: active_no_gps_data_lifetime
                    // })
                    // setCompletedTrips({
                    //     all: completed_trips,
                    //     transit_delays: completed_transit_delay,
                    //     overspeeding: completed_overspeeding,
                    //     night_driving: completed_night_driving,
                    //     stoppages: completed_stoppages,
                    //     accidents: completed_accidents,
                    //     trip_deviations: completed_trip_deviations,
                    //     loading_delay: completed_loadingdelay,
                    //     unloading_delay: completed_unloadingdelay,
                    //     no_gps_data: completed_no_gps_data,
                    //     no_gps_data_lifetime: completed_no_gps_data_lifetime
                    // })
                    // setAllTrips({
                    //     all: resp.data.consignments,
                    //     transit_delays: transit_delay,
                    //     overspeeding: overspeeding,
                    //     night_driving: night_driving,
                    //     stoppages: stoppages,
                    //     accidents: accidents,
                    //     trip_deviations: trip_deviations,
                    //     loading_delay: loading_delay,
                    //     unloading_delay: unloading_delay,
                    //     no_gps_data: no_gps_data,
                    //     no_gps_data_lifetime: no_gps_data_lifetime
                    // })
                    // console.log("allactive", allactive)
                    // setRowData(allactive)

                }
                fragmentData(resp)
            })
            .catch(err => console.log(err))
    }
    const getTruckConsignments = async (e) => {
        e.preventDefault();
        let params = {
            searchtype: searchType?.value,
            searchnumber: searchValue
        }
        await redirectURL.post("consignments/getNewConsignments", params)
            .then(resp => {
                // if (resp.data.status == "success") {
                //     // setRowData(resp.data.consignments)
                //     if (resp.data.consignments.length > 0) {
                //         let active_trips = resp.data.consignments.filter(e => (e.status == 2 || e.status == 3 || e.status == 4))
                //         let completed_trips = resp.data.consignments.filter(e => (e.status == 5 || e.status == 6 || e.status == 7))
                //         let active_transit_delay = []
                //         let active_overspeeding = []
                //         let active_night_driving = []
                //         let active_stoppages = []
                //         let active_accidents = []
                //         let active_trip_deviations = []
                //         let active_loadingdelay = []
                //         let active_unloadingdelay = []
                //         let active_no_gps_data = []
                //         let active_no_gps_data_lifetime = []
                //         let completed_transit_delay = []
                //         let completed_overspeeding = []
                //         let completed_night_driving = []
                //         let completed_stoppages = []
                //         let completed_accidents = []
                //         let completed_trip_deviations = []
                //         let completed_loadingdelay = []
                //         let completed_unloadingdelay = []
                //         let completed_no_gps_data = []
                //         let completed_no_gps_data_lifetime = []
                //         let transit_delay = []
                //         let overspeeding = []
                //         let night_driving = []
                //         let stoppages = []
                //         let accidents = []
                //         let trip_deviations = []
                //         let loading_delay = []
                //         let unloading_delay = []
                //         let no_gps_data = []
                //         let no_gps_data_lifetime = []
                //         active_trips.map(each => {
                //             if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                //                 active_transit_delay.push(each)
                //             }
                //             if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                //                 active_overspeeding.push(each)
                //             }
                //             if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                //                 active_night_driving.push(each)
                //             }
                //             if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                //                 active_stoppages.push(each)
                //             }
                //             if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                //                 active_accidents.push(each)
                //             }
                //             if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                //                 active_trip_deviations.push(each)
                //             }
                //             if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                //                 active_loadingdelay.push(each)
                //             }
                //             if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                //                 active_unloadingdelay.push(each)
                //             }
                //             if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                //                 active_no_gps_data.push(each)
                //             }
                //             if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                //                 active_no_gps_data_lifetime.push(each)
                //             }
                //         })
                //         completed_trips.map(each => {
                //             if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                //                 completed_transit_delay.push(each)
                //             }
                //             if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                //                 completed_overspeeding.push(each)
                //             }
                //             if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                //                 completed_night_driving.push(each)
                //             }
                //             if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                //                 completed_stoppages.push(each)
                //             }
                //             if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                //                 completed_accidents.push(each)
                //             }
                //             if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                //                 completed_trip_deviations.push(each)
                //             }
                //             if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                //                 completed_loadingdelay.push(each)
                //             }
                //             if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                //                 completed_unloadingdelay.push(each)
                //             }
                //             if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                //                 completed_no_gps_data.push(each)
                //             }
                //             if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                //                 completed_no_gps_data_lifetime.push(each)
                //             }
                //         })
                //         resp.data.consignments.map(each => {
                //             if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                //                 transit_delay.push(each)
                //             }
                //             if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                //                 overspeeding.push(each)
                //             }
                //             if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                //                 night_driving.push(each)
                //             }
                //             if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                //                 stoppages.push(each)
                //             }
                //             if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                //                 accidents.push(each)
                //             }
                //             if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                //                 trip_deviations.push(each)
                //             }
                //             if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                //                 loading_delay.push(each)
                //             }
                //             if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                //                 unloading_delay.push(each)
                //             }
                //             if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                //                 no_gps_data.push(each)
                //             }
                //             if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                //                 no_gps_data_lifetime.push(each)
                //             }
                //         })
                //         setCountersActive({
                //             consignments: active_trips.length,
                //             transit_delays: active_transit_delay.length,
                //             overspeeding: active_overspeeding.length,
                //             night_driving: active_night_driving.length,
                //             stoppages: active_stoppages.length,
                //             accidents: active_accidents.length,
                //             trip_deviations: active_trip_deviations.length,
                //             loading_delay: active_loadingdelay.length,
                //             unloading_delay: active_unloadingdelay.length,
                //             no_gps_data: active_no_gps_data.length,
                //             no_gps_data_lifetime: active_no_gps_data_lifetime.length
                //         })
                //         setCountersCompleted({
                //             consignments: completed_trips.length,
                //             transit_delays: completed_transit_delay.length,
                //             overspeeding: completed_overspeeding.length,
                //             night_driving: completed_night_driving.length,
                //             stoppages: completed_stoppages.length,
                //             accidents: completed_accidents.length,
                //             trip_deviations: completed_trip_deviations.length,
                //             loading_delay: completed_loadingdelay.length,
                //             unloading_delay: completed_unloadingdelay.length,
                //             no_gps_data: completed_no_gps_data.length,
                //             no_gps_data_lifetime: completed_no_gps_data_lifetime.length
                //         })
                //         setCountersAll({
                //             consignments: resp.data.consignments.length,
                //             transit_delays: transit_delay.length,
                //             overspeeding: overspeeding.length,
                //             night_driving: night_driving.length,
                //             stoppages: stoppages.length,
                //             accidents: accidents.length,
                //             trip_deviations: trip_deviations.length,
                //             loading_delay: loading_delay.length,
                //             unloading_delay: unloading_delay.length,
                //             no_gps_data: no_gps_data.length,
                //             no_gps_data_lifetime: no_gps_data_lifetime.length
                //         })
                //         setActiveTrips({
                //             all: active_trips,
                //             transit_delays: active_transit_delay,
                //             overspeeding: active_overspeeding,
                //             night_driving: active_night_driving,
                //             stoppages: active_stoppages,
                //             accidents: active_accidents,
                //             trip_deviations: active_trip_deviations,
                //             loading_delay: active_loadingdelay,
                //             unloading_delay: active_unloadingdelay,
                //             no_gps_data: active_no_gps_data,
                //             no_gps_data_lifetime: active_no_gps_data_lifetime
                //         })
                //         setCompletedTrips({
                //             all: completed_trips,
                //             transit_delays: completed_transit_delay,
                //             overspeeding: completed_overspeeding,
                //             night_driving: completed_night_driving,
                //             stoppages: completed_stoppages,
                //             accidents: completed_accidents,
                //             trip_deviations: completed_trip_deviations,
                //             loading_delay: completed_loadingdelay,
                //             unloading_delay: completed_unloadingdelay,
                //             no_gps_data: completed_no_gps_data,
                //             no_gps_data_lifetime: completed_no_gps_data_lifetime
                //         })
                //         setAllTrips({
                //             all: resp.data.consignments,
                //             transit_delays: transit_delay,
                //             overspeeding: overspeeding,
                //             night_driving: night_driving,
                //             stoppages: stoppages,
                //             accidents: accidents,
                //             trip_deviations: trip_deviations,
                //             loading_delay: loading_delay,
                //             unloading_delay: unloading_delay,
                //             no_gps_data: no_gps_data,
                //             no_gps_data_lifetime: no_gps_data_lifetime
                //         })
                //         setRowData(active_trips)
                //     }
                // }
                fragmentData(resp)
            })
            .catch(err => console.log(err))
    }
    const resetSearchFilter = () =>{
        setSeachValue("");
        setSearchType("");
        loadConsignmentsData()
    }
    const resetFilter = () =>{
        setSearchConsigner([]);
        setStartDate("");
        setEndDate("");
        setSearchCity({ label: '', value: '' });
        setSearchStates({ label: '', value: '' });
        setSearchTransporters({ label: '', value: '' });
        loadConsignmentsData()
    }
    
    const saveConsignments = async (e) => {
        e.preventDefault();
        if (gridApi.current) {
            gridApi.current.stopEditing();
            let allFieldsPresent = true;
            const gridRowData = [];
            gridApi.current.forEachNode((node) => {
                if(node.data){
                    // console.log(node.data,"check_nodedata")
                    const requiredFields = {
                        // entity: "Entity",
                        // division_name: "Division Name",
                        sale_bill_number: "Sale Bill Number",
                        sale_bill_date: "Sale Bill Date",
                        customer_code: "Customer Code",
                        sale_type_name: "Sale Type Name",
                        to_state_name: "To State Name",
                        godown_name: "Godown Name",
                        party_city: "Party City",
                        plant_code: "Plant Location Code",
                        consignment_code: "Consignment Code",
                        order_no: "Order Number",
                        invoice_date: "Invoice Date",
                        order_date: "Order Date",
                        truck_no: "Truck No",
                        from_place_name: "From Place Name",
                        from_state_name: "From State Name",
                        transaction_nature: "Transaction Nature",
                        customer_name: "Customer Name",
                        item_code: "Item Code",
                        // short_name: "Short Name",
                        quantity: "Quantity",
                        rate: "Rate",
                        invoice_value: "Invoice Value",
                        transporter_code: "Transporter Code",
                        transporter_name: "Transporter Name",
                    };
                    
                for (const field in requiredFields) {
                    if (!node.data[field]) {
                        setShow(true);
                        setTitle(`Please fill the required field: ${requiredFields[field]}`);
                        setType("danger");
                        gridApi.current.refreshCells();
                        allFieldsPresent = false;
                        return;
                    }
                }}
                if (allFieldsPresent) {
                    const cleanedData = Object.fromEntries(
                        Object.entries(node.data).map(([key, value]) => [key, value.trim()])
                    );
                    gridRowData.push(cleanedData);
                }
            })
            // console.log(gridRowData,"gridRowData")
            if (gridRowData.length > 0) {
                await redirectURL.post("/tracking/bulkUploadConsignments", { consignments: gridRowData, email: "" })
                    .then(response => {
                        if (response.data.status == "success") {
                            setShow(true)
                            setTitle(response.data.message)
                            setType("success")
                            setLoader(false)
                            setOverlay("show-n")
                            setBulkUploadModal(false)
                            setModalLoadData([])
                            loadConsignmentsData()
                        }
                        else {
                            setShow(true)
                            setTitle(response.data.message)
                            setType("danger")
                            setLoader(false)
                        }
                    })
                    .catch(err => console.log(err))
            }
        }
    }
    const onConfirm = () => {
        setShow(false);
        setLoader(false)
        if (reloadPage.current) {
            window.location.reload()
        }
    };

    const onGetConsignments = async (event) => {
        let query = {}
        if (searchCity.value != '')
            query["consignee_city"] = searchCity.value
        if (startDate == '' || startDate == "NaN-NaN-NaN") {
            var sDate = "";
        }
        else {
            query["sdate"] = startDate
        }
        if (endDate == '' || startDate == "NaN-NaN-NaN") {
            var eDate = "";
        }
        else {
            query["edate"] = endDate
        }
        if (searchConsigner == null || searchConsigner[0]?.value == '' || searchConsigner[0]?.value == 'all') {
        }
        else {
            query["consigner_code"] = searchConsigner.map(e => e.value)
        }
        if (searchState.value != '') {
            query["consignee_state"] = searchState.value
        }
        if (searchTransporters.value != "") {
            query["transporter_name"] = searchTransporters.value
        }
        await redirectURL.post("consignments/filterNewConsignments", query)
            .then(resp => {
                // if (resp.data.status == "success") {
                //     let active_trips = resp.data.consignments.filter(e => (e.status == 2 || e.status == 3 || e.status == 4))
                //     let completed_trips = resp.data.consignments.filter(e => (e.status == 5 || e.status == 6 || e.status == 7))
                //     let active_transit_delay = []
                //     let active_overspeeding = []
                //     let active_night_driving = []
                //     let active_stoppages = []
                //     let active_accidents = []
                //     let active_trip_deviations = []
                //     let active_loadingdelay = []
                //     let active_unloadingdelay = []
                //     let active_no_gps_data = []
                //     let active_no_gps_data_lifetime = []
                //     let completed_transit_delay = []
                //     let completed_overspeeding = []
                //     let completed_night_driving = []
                //     let completed_stoppages = []
                //     let completed_accidents = []
                //     let completed_trip_deviations = []
                //     let completed_loadingdelay = []
                //     let completed_unloadingdelay = []
                //     let completed_no_gps_data = []
                //     let completed_no_gps_data_lifetime = []
                //     let transit_delay = []
                //     let overspeeding = []
                //     let night_driving = []
                //     let stoppages = []
                //     let accidents = []
                //     let trip_deviations = []
                //     let loading_delay = []
                //     let unloading_delay = []
                //     let no_gps_data = []
                //     let no_gps_data_lifetime = []
                //     active_trips.map(each => {
                //         if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                //             active_transit_delay.push(each)
                //         }
                //         if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                //             active_overspeeding.push(each)
                //         }
                //         if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                //             active_night_driving.push(each)
                //         }
                //         if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                //             active_stoppages.push(each)
                //         }
                //         if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                //             active_accidents.push(each)
                //         }
                //         if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                //             active_trip_deviations.push(each)
                //         }
                //         if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                //             active_loadingdelay.push(each)
                //         }
                //         if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                //             active_unloadingdelay.push(each)
                //         }
                //         if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                //             active_no_gps_data.push(each)
                //         }
                //         if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                //             active_no_gps_data_lifetime.push(each)
                //         }
                //     })
                //     completed_trips.map(each => {
                //         if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                //             completed_transit_delay.push(each)
                //         }
                //         if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                //             completed_overspeeding.push(each)
                //         }
                //         if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                //             completed_night_driving.push(each)
                //         }
                //         if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                //             completed_stoppages.push(each)
                //         }
                //         if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                //             completed_accidents.push(each)
                //         }
                //         if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                //             completed_trip_deviations.push(each)
                //         }
                //         if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                //             completed_loadingdelay.push(each)
                //         }
                //         if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                //             completed_unloadingdelay.push(each)
                //         }
                //         if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                //             completed_no_gps_data.push(each)
                //         }
                //         if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                //             completed_no_gps_data_lifetime.push(each)
                //         }
                //     })
                //     resp.data.consignments.map(each => {
                //         if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                //             transit_delay.push(each)
                //         }
                //         if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                //             overspeeding.push(each)
                //         }
                //         if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                //             night_driving.push(each)
                //         }
                //         if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                //             stoppages.push(each)
                //         }
                //         if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                //             accidents.push(each)
                //         }
                //         if (each.hasOwnProperty("trip_deviations") && each.trip_deviations == 1) {
                //             trip_deviations.push(each)
                //         }
                //         if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                //             loading_delay.push(each)
                //         }
                //         if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                //             unloading_delay.push(each)
                //         }
                //         if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                //             no_gps_data.push(each)
                //         }
                //         if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                //             no_gps_data_lifetime.push(each)
                //         }
                //     })
                //     setCountersActive({
                //         consignments: active_trips.length,
                //         transit_delays: active_transit_delay.length,
                //         overspeeding: active_overspeeding.length,
                //         night_driving: active_night_driving.length,
                //         stoppages: active_stoppages.length,
                //         accidents: active_accidents.length,
                //         trip_deviations: active_trip_deviations.length,
                //         loading_delay: active_loadingdelay.length,
                //         unloading_delay: active_unloadingdelay.length,
                //         no_gps_data: active_no_gps_data.length,
                //         no_gps_data_lifetime: active_no_gps_data_lifetime.length
                //     })
                //     setCountersCompleted({
                //         consignments: completed_trips.length,
                //         transit_delays: completed_transit_delay.length,
                //         overspeeding: completed_overspeeding.length,
                //         night_driving: completed_night_driving.length,
                //         stoppages: completed_stoppages.length,
                //         accidents: completed_accidents.length,
                //         trip_deviations: completed_trip_deviations.length,
                //         loading_delay: completed_loadingdelay.length,
                //         unloading_delay: completed_unloadingdelay.length,
                //         no_gps_data: completed_no_gps_data.length,
                //         no_gps_data_lifetime: completed_no_gps_data_lifetime.length
                //     })
                //     setCountersAll({
                //         consignments: resp.data.consignments.length,
                //         transit_delays: transit_delay.length,
                //         overspeeding: overspeeding.length,
                //         night_driving: night_driving.length,
                //         stoppages: stoppages.length,
                //         accidents: accidents.length,
                //         trip_deviations: trip_deviations.length,
                //         loading_delay: loading_delay.length,
                //         unloading_delay: unloading_delay.length,
                //         no_gps_data: no_gps_data.length,
                //         no_gps_data_lifetime: no_gps_data_lifetime.length
                //     })
                //     setActiveTrips({
                //         all: active_trips,
                //         transit_delays: active_transit_delay,
                //         overspeeding: active_overspeeding,
                //         night_driving: active_night_driving,
                //         stoppages: active_stoppages,
                //         accidents: active_accidents,
                //         trip_deviations: active_trip_deviations,
                //         loading_delay: active_loadingdelay,
                //         unloading_delay: active_unloadingdelay,
                //         no_gps_data: active_no_gps_data,
                //         no_gps_data_lifetime: active_no_gps_data_lifetime
                //     })
                //     setCompletedTrips({
                //         all: completed_trips,
                //         transit_delays: completed_transit_delay,
                //         overspeeding: completed_overspeeding,
                //         night_driving: completed_night_driving,
                //         stoppages: completed_stoppages,
                //         accidents: completed_accidents,
                //         trip_deviations: completed_trip_deviations,
                //         loading_delay: completed_loadingdelay,
                //         unloading_delay: completed_unloadingdelay,
                //         no_gps_data: completed_no_gps_data,
                //         no_gps_data_lifetime: completed_no_gps_data_lifetime
                //     })
                //     setAllTrips({
                //         all: resp.data.consignments,
                //         transit_delays: transit_delay,
                //         overspeeding: overspeeding,
                //         night_driving: night_driving,
                //         stoppages: stoppages,
                //         accidents: accidents,
                //         trip_deviations: trip_deviations,
                //         loading_delay: loading_delay,
                //         unloading_delay: unloading_delay,
                //         no_gps_data: no_gps_data,
                //         no_gps_data_lifetime: no_gps_data_lifetime
                //     })
                //     setRowData(active_trips)

                // }
                fragmentData(resp)
            })
            .catch(err => console.log(err))
    }

    const onGridReady = useCallback((params) => {
        gridApi.current = params.api
    }, []);

    const onGridReadyConsignments = useCallback((params) => {
        console.log("params ", params)
        gridApiConsignments.current = params.api
        columnApiConsignments.current = params.columnApi
        console.log("columnApiConsignments ", columnApiConsignments)
    }, []);

    const onGridState = () => {
        console.log('gridApiConsignments ', gridApiConsignments)
        var gridColumnApiRef = gridApiConsignments;
        if (gridColumnApiRef.current && gridApiRef.current) {
          // Get and store column state
          const currentColState = gridColumnApiRef.current.getColumnState();
          setColState(currentColState);
    
          // Get and store row group state
          const currentRowGroupState = gridColumnApiRef.current.getRowGroupColumns();
          setRowGroupState(currentRowGroupState);
    
          // Get and store pivot columns state
          const currentPivotColumns = gridColumnApiRef.current.getPivotColumns();
          setPivotColumns(currentPivotColumns);
    
          // Get and store filter state
          const filterState = gridApiRef.current.getFilterModel();
          window.filterState = filterState;
          gridApiRef.current.setFilterModel(filterState);
    
          console.log('Column State:', currentColState);
          console.log('Row Group State:', currentRowGroupState);
          console.log('Pivot Columns:', currentPivotColumns);
          console.log('Filter State:', filterState);
        }
    };

    const setAsFavourite = () =>{
        setFavouriteModel("show-m");
        setOverlay("show-m");
        setScreenTitle('')
    }
    const closeFavouriteModel = (e) => {
        setFavouriteModel("show-n")
        setOverlay("show-n");
        setScreenTitle('')
    }
    const formFavourite = (e) =>{
        e.preventDefault();
        var screenTitle = document.getElementById("screen_title").value;
        var pagePath = window.location.pathname;
        var params = {
            screen_title:screenTitle,
            page_path:pagePath,
            user_email:localStorage.getItem("email")
        }
        // console.log("parmas", params)
        redirectURL.post("/app/setScreenAsFavaourite",params)
        .then((response)=>{
            if(response.data.status == "success")
            {
             setShow("show-m");
             setTitle("Successfully added your favorites");
             setType("success");
             setFavouriteModel("show-n");
             setScreenTitle("");   
             setOverlay("show-n"); 
            }
            else{
                
             setShow("show-m");
             setTitle("Something went wrong.");
             setType("danger");
             setFavouriteModel("show-n");
             setScreenTitle("");   
             setOverlay("show-n");
            }
        })
    }

    var filterClassName = "form-group col-xl-12 col-lg-12";
    return (
        <div className="container-fluid">
            <SweetAlert
                show={show}
                title={<span style={{ fontSize: "1rem" }}>{title}</span>}
                onConfirm={onConfirm}
                confirmBtnBsStyle={type}
                confirmBtnCssClass="sweetButton"
                customClass='sweetWidth'
                type={type}>
            </SweetAlert>
            <Modal
                onHide={closeForceCloseModal}
                dialogClassName="my-modal"
                show={forcecloseModal}
                centered>
                <Modal.Header>
                    <Modal.Title>Force Close</Modal.Title>
                    <div className="btn-box d-flex gap-3">
                        <button type="button" className="btn btn-sm btn-icon btn-outline-primary" data-bs-dismiss="modal" aria-label="Close" onClick={closeForceCloseModal}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="view-task">
                    <div>
                        <form onSubmit={formForceCloseHandler}>
                            <div className="col-xl-12 col-lg-12">
                                <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "inherit" }}>*</label>
                                <label className="custom-label">
                                    Reason For Force Close
                                </label>
                                <textarea
                                    type="text"
                                    value={reasonForceClose}
                                    onChange={e => setReasonForceClose(e.target.value)}
                                    autoComplete="off"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div style={{ paddingTop: "2rem", display: "flex", justifyContent: "flex-end" }}>
                                <button type='submit' className='primary-button'>Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="mbread">
                <Breadcrumb
                    parent="Track & Trace"
                    title=""
                />
            </div>
            <div className="row" >
                <h5 class="col-sm-12 mt-15p fbold f20">
                    <i class="icofont icofont-vehicle-delivery-van cus-i"></i>&nbsp;
                    <span>Consignments Information</span>
                    <Favourites />
                    
                    <button
                        title="Bulk Upload"
                        onClick={onClickOpenBulkUploadModal}
                        className={"float-right btn btn-warning mr-2 f12"}
                        type="button">
                        <i className="fa fa-upload"></i>&nbsp;&nbsp;&nbsp;Upload Consignments
                    </button>
                    {/* <button
                        // onClick={this.reloadPageData.bind(this)}
                        className={"btn btn-info"}
                        style={{ float: "right" }}>
                        Reset Filters
                    </button> */}
                    <button type="button" className="btn btn-default float-right" style={{padding:"8px 0px",marginRight:"10px"}}>
                        <img className='mx-2' onClick={(e) => setFilterToggle(prev => !prev)} src={Filter} style={{ height: "16px", width: "16px", cursor: "pointer", float: "right"}} title="Filters" />
                    </button>
                </h5>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <button
                        onClick={(e) => { setTabName(tabOptions[0]); setCounter({label:"Consignments",value:"Consignments"});setRowData(activeTrips.all); navigate('/activenewconsignments'); }}
                        className={(tabName.value == "active") ? "btn btn-h pgtab" : "btn pgtab "}
                        style={{
                            display: (tabName.value == "active") ? "inline-flex" : ""
                        }}
                    >
                        Active
                    </button>
                    <button
                        onClick={(e) => { setTabName(tabOptions[1]); setCounter({label:"Consignments",value:"Consignments"});setRowData(completedTrips.all); navigate('/completednewconsignments');}}
                        className={(tabName.value == "completed") ? "btn btn-h pgtab" : "btn pgtab "}
                        style={{
                            display: (tabName.value == "completed") ? "inline-flex" : ""
                        }}
                    >
                        Delivered
                    </button>
                    <button
                        onClick={(e) => { setTabName(tabOptions[2]); setCounter({label:"Consignments",value:"Consignments"});setRowData(allTrips.all); navigate('/allnewconsignments'); }}
                        className={(tabName.value == "all") ? "btn btn-h pgtab" : "btn pgtab "}
                        style={{
                            display: (tabName.value == "all") ? "inline-flex" : ""
                        }}
                    >
                        All
                    </button>
                    
                    {/* <i className='fa fa-filter mx-2' onClick={(e) => setFilterToggle(prev => !prev)} style={{ cursor: "pointer", float: "right", fontSize: "2rem" }} /> */}
                </div>
            </div>
            <div className={filterToggle ? "filter-in" : "filter-out"}>
                {
                    filterToggle &&
                    <div className={filterToggle ? "row" : "row"}>
                        <div className={"col-xl-4 col-lg-4 "}>
                            <div className="card" style={filterCardStyle1}>
                                <div className="row card-body" style={{ padding: "15px 30px" }}>
                                    <form className="row" onSubmit={getTruckConsignments}>
                                        <div className={filterClassName}>
                                            <label>Select Type  </label>
                                            <Select
                                                className="border-radius-0"
                                                isMulti={false}
                                                id="selectedFilter"
                                                value={searchType}
                                                onChange={e => setSearchType(e)}
                                                style={{ borderRadius: "0px" }}
                                                styles={{
                                                    option: (provided) => ({
                                                        ...provided,
                                                        fontSize: '14px',
                                                    }),
                                                }}
                                                options={[
                                                    { label: "Vehicle", value: "truck_no" },
                                                    { label: "Consignment Code", value: "consignment_code" },
                                                ]} required />
                                        </div>
                                        <div className={filterClassName}>
                                            <label className="reason">Vehicle No/ Consignment Code</label>
                                            <input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" value={searchValue} onChange={e => setSeachValue(e.target.value)} />
                                        </div>
                                        <div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                            <button onClick={resetSearchFilter} className="btn btn-default ml-10p">Reset</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className={"col-xl-8 col-lg-8"}>
                            <div className="card" style={filterCardStyle1}>
                                <div className="row card-body" style={filterCardStyle2}>
                                    <div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <label>Select Consigner </label>
                                            <Select placeholder={"All"} closeMenuOnSelect={false}
                                                value={searchConsigner}
                                                // isMulti={true}
                                                className="border-radius-0"
                                                onChange={e => setSearchConsigner(e)}
                                                // style={{ borderRadius: "0px" }}
                                                // styles={{
                                                //     option: (provided) => ({
                                                //         ...provided,
                                                //         fontSize: '14px',
                                                //     }),
                                                // }}
                                                options={consignersList}
                                                required />

                                        </div>
                                        {/* <div className="col-xl-4 col-lg-4 form-group">
                                            <div className=""><label>From Date</label></div>
                                            <div className="">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={handleStartDateChange}
                                                    name="startDate"
                                                    autoComplete="off"
                                                    dateFormat="dd/MM/yyyy"
                                                    className="datepicker-custom"
                                                />
                                            </div>
                                        </div> */}


                                        <div className="col-xl-4 col-lg-4 form-group">
                                            <div className=""><label>From Date</label></div>
                                            <div className="">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={handleStartDateChange}
                                                    name="startDate"
                                                    autoComplete="off"
                                                    dateFormat="dd/MM/yyyy"
                                                    className="datepicker-custom"
                                                />
                                            </div>
                                        </div>

                                        
                                        
                                        <div className="col-xl-4 col-lg-4 form-group">
                                            <div className=""> <label>To Date</label></div>
                                            <div className="">
                                                <DatePicker
                                                    selected={endDate}
                                                    autoComplete="off"
                                                    onChange={handleEndDateChange}
                                                    name="endDate"
                                                    dateFormat="dd/MM/yyyy"
                                                    className="datepicker-custom"
                                                />
                                            </div>
                                        </div>

                                        
                                    </div>
                                    <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <label>Consignee City   </label>
                                            <Select
                                                className="border-radius-0"
                                                // isMulti={false}
                                                id="selectedCity"
                                                // style={{ borderRadius: "0px" }}
                                                // styles={{
                                                //     option: (provided) => ({
                                                //         ...provided,
                                                //         fontSize: '14px',
                                                //     }),
                                                // }}
                                                value={searchCity}
                                                onChange={e => setSearchCity(e)}
                                                options={filterCities}
                                            />
                                        </div>
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <label>Consignee State</label>
                                            <Select
                                                className="border-radius-0"
                                                // isMulti={false}
                                                id="selectedState"
                                                // style={{ borderRadius: "0px" }}
                                                // styles={{
                                                //     option: (provided) => ({
                                                //         ...provided,
                                                //         fontSize: '14px',
                                                //     }),
                                                // }}
                                                value={searchState}
                                                onChange={e => setSearchStates(e)}
                                                options={filterStates}
                                            />
                                        </div>
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <label>Transporter  </label>
                                            <Select
                                                className="border-radius-0"
                                                // isMulti={false}
                                                id="selectedTransporter"
                                                style={{ borderRadius: "0px" }}
                                                blurInputOnSelect={true} 
                                                styles={{
                                                    option: (provided) => ({
                                                        ...provided,
                                                        fontSize: '14px',
                                                    }),
                                                }}
                                                value={searchTransporters}
                                                onChange={(e) => setSearchTransporters(e)}
                                                options={filterTransporters}
                                            />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
                                        <button type="button" className="btn btn-success" onClick={onGetConsignments}>Submit</button>
                                        
                                        <button onClick={resetFilter} className="btn btn-default ml-10p">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            
                {(window.location.pathname == "/completednewconsignments")?<div className={""}>
                    <MainCountersforDelivered setCounter={CounterClick1} data={tabName.value == "active" ? countersActive : (tabName.value == "completed" ? countersCompleted : countersAll)} tabName={tabName} />
                </div>:
                <div className={""}>
                    <MainCounters setCounter={CounterClick} data={tabName.value == "active" ? countersActive : (tabName.value == "completed" ? countersCompleted : countersAll)} tabName={tabName} />
                </div>}
            
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{`${tabName?.label} - ${counter?.label}`}</span>
                                <span className="layoutbtns float-right">
                                    <div className="col-sm-12">
                                        <button type="button" onClick={resetGrid} title="Reset Grid Layout" name="resetgrid" className="btn btn-info float-right action_btn" > <i className="fa fa-reply"></i> </button>
                                        <button type="button" onClick={saveGrid} title="Save Grid Layout" name="savegrid" className="btn btn-success float-right action_btn"> <i className="fa fa-save"></i> </button>
                                    </div>
                                </span>
                            </h5>
                        </div>
                        <div className="card-body pt-10px" >
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 mb-10p">
                                    <span className="float-right" style={{ fontSize: "13px", fontWeight: "100" }}>
                                    
                                        <button type="button" className="nobx btn btn-default mybtn">
                                            <i style={{ color: "#333" }} className="icofont icofont-map-pins"></i> View Map Route
                                        </button>
                                        <button type="button" className="nobx btn btn-default mybtn">
                                            <i style={{ color: "#ccc" }} className="fa fa-cubes"></i> KPIs Status
                                        </button>
                                        {/* <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-clock-time" style={{ color: '#60ef48d9' }}></i> On Time
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-clock-time txt-danger" style={{ }}></i>  Delayed
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="fa fa-tachometer darkblue" style={{  }}></i>  Overspeeding
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-full-night txt-primary" style={{  }}></i>  Night Driving
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-truck-alt darkorange" style={{  }}></i>  Loading Delay
                                        </button> */}
                                        {/* {(tabName.value !== "active")?
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-vehicle-delivery-van mildgreen" style={{ }}></i>  Unloading Delay
                                        </button>
                                    
                                        :""} */}
                                        {/* <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-pause maroon" style={{ }}></i>  Stoppages
                                        </button>
                                        
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-map-pins txt-danger" style={{ }}></i>  Deviated Routes
                                        </button> */}
                                    </span>
                                </div>

                            </div>
                            <div className={"ag-theme-balham mt-8"} style={{ height: '500px', width: '100%', paddingTop: "1rem" }}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={50}
                                    onGridReady={onGridReadyConsignments}
                                    onGridState={onGridState}
                                    enableRangeSelection={true}
                                    enableCellContextMenu={true}
                                    masterDetail={true}
                                    detailCellRendererParams={detailCellRendererParams}
                                    onCellDoubleClicked={onCellDoubleClicked}
                                    sideBar={{
                                        toolPanels: [
                                            {
                                                id: "columns",
                                                labelDefault: "Columns",
                                                labelKey: "columns",
                                                iconKey: "columns",
                                                toolPanel: "agColumnsToolPanel",
                                                toolPanelParams: {
                                                    suppressPivotMode: true,
                                                    suppressRowGroups: true,
                                                    suppressValues: true,
                                                }
                                            },
                                            {
                                                id: "filters",
                                                labelDefault: "Filters",
                                                labelKey: "filters",
                                                iconKey: "filter",
                                                toolPanel: "agFiltersToolPanel"
                                            }
                                        ]
                                    }}
                                    statusBar={{
                                        statusPanels: [
                                            {
                                                statusPanel: "agTotalAndFilteredRowCountComponent",
                                                align: "left"
                                            },
                                            {
                                                statusPanel: "agTotalRowCountComponent",
                                                align: "center"
                                            },
                                            { statusPanel: "agFilteredRowCountComponent" },
                                            { statusPanel: "agSelectedRowCountComponent" },
                                            { statusPanel: "agAggregationComponent" }
                                        ]
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openMap.sliderRouteTranslate != '' ?
                <div className={"slide-r " + (openMap.sliderRouteTranslate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            {(openMap.consignment_code)}
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={closeMapModal} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            <DrawMap
                                rownode={openMap.rownode}
                                mapFor={"consignment"}
                                tolls={openMap.tolls}
                                googleroutes={openMap.googelRoutes}
                                truckno={openMap.maptruckno}
                                dealer={openMap.dealer}
                                mapinfo={openMap.mapinfo}
                                deptcode={openMap.deptcode}
                                defTransitCoords={openMap.defTransitCoords}
                                baseRouteCoords={openMap.baseRouteCoords}
                                geofence_coordinates={openMap.geofence_coordinates}
                                parking_coordinates={openMap.parking_coordinates}
                            />
                            <div className="col-xl-12 col-lg-12 n-p-0">
                                <div className="crm-numbers pb-0">
                                    <h3 className="subH">{openMap.sidebarSubHeader}</h3>
                                    <div className="col-xl-12 col-lg-12 row">
                                        <div className="col route-block">
                                            <label className="sidebar-label">Truck No</label>
                                            <div>{openMap.routeTruck.truck_no}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">Start Time</label>
                                            <div>{(!isEmpty(openMap.mapinfo.route_details)) ? getHyphenDDMMMYYYYHHMM(openMap.mapinfo.route_details.start_time) : "-"}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">End Time</label>
                                            <div>{(!isEmpty(openMap.mapinfo.route_details)) ? getHyphenDDMMMYYYYHHMM(openMap.mapinfo.route_details.end_time) : "-"}</div>
                                        </div>

                                        <div className="col route-block">
                                            <label className="sidebar-label">GPS Distance</label>
                                            <div>{(!isEmpty(openMap.mapinfo.route_details)) ? Math.round((openMap.mapinfo.route_details.distance_without_google / 1000)/1.60934) : "0"} Miles</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                : ""
            }
            <div className={"slide-r " + (bulkUploadModal.bulkPrioritySlide)} style={{ overflow: "auto" }}>
                <h3 className="subH">Bulk Upload Consignments
                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={closeBulkUploadModal} >X</span>
                </h3>

                <div className="slide-r-body" style={{ position: "relative" }}>

                    <div className="container-fluid">
                        <form method="POST" id="upform" className="theme-form">
                            <div className="col-xl-6 col-lg-6 form-group">
                                <label className="">Upload File</label>
                                <input type="file" name="uploadFile" id="bulkUploadCritical" onChange={changeFileHandler} className="form-control" required />
                            </div>
                        </form>
                        <div className='col-xl-12 col-lg-12' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/files/Autometrics_Consignments_Format.xlsx')} target="_blank">Sample Template</a>
                            </div>
                            {/* <div className='form-group' style={{ marginRight: "8px" }}>
                                <div><i className="fa fa-circle" style={{ color: "#c85f62" }}></i>&nbsp; Invalid Field Value</div>
                                <div><i className="fa fa-circle" style={{ color: "#ffcc00" }}></i>&nbsp; Order Number and FO Number combination already exists</div>
                            </div> */}
                        </div>
                        <div className="ag-theme-balham mt-2" style={{ height: '58vh', width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnDef}
                                rowData={modalloadData}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                pagination={true}
                                paginationPageSize={50}
                                enableRangeSelection={true}
                                enableCellContextMenu={true}
                                stopEditingWhenGridLosesFocus={true}
                                sideBar={{
                                    toolPanels: [
                                        {
                                            id: "columns",
                                            labelDefault: "Columns",
                                            labelKey: "columns",
                                            iconKey: "columns",
                                            toolPanel: "agColumnsToolPanel",
                                            toolPanelParams: {
                                                suppressPivotMode: true,
                                                suppressRowGroups: true,
                                                suppressValues: true,
                                            }
                                        },
                                        {
                                            id: "filters",
                                            labelDefault: "Filters",
                                            labelKey: "filters",
                                            iconKey: "filter",
                                            toolPanel: "agFiltersToolPanel"
                                        }
                                    ]
                                }}
                                statusBar={{
                                    statusPanels: [
                                        {
                                            statusPanel: "agTotalAndFilteredRowCountComponent",
                                            align: "left"
                                        },
                                        {
                                            statusPanel: "agTotalRowCountComponent",
                                            align: "center"
                                        },
                                        { statusPanel: "agFilteredRowCountComponent" },
                                        { statusPanel: "agSelectedRowCountComponent" },
                                        { statusPanel: "agAggregationComponent" }
                                    ]
                                }} />
                        </div>
                        <br />
                        <div className="form-group">
                            <button type="submit" className="btn btn-success" onClick={saveConsignments}>Submit</button>
                            <button type="button" onClick={closeBulkUploadModal} className="btn btn-default">Cancel</button>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <div className={"slide-r " + (addExpensesModal.addExpensesSlide)} style={{ overflow: "auto" }}>
                <h3 className="subH text-center">Update Expenses
                    <span className="float-right closebtn" style={{ marginRight: "0px" }} onClick={closeAddExpensesModal} >X</span>
                </h3>

                <div className="slide-r-body" style={{ position: "relative"}}>

                    <div className="container-fluid">
                        <form method="POST" id="upform" className="theme-form">
                            <div className="form-group">
                                <label>Police Entry</label>
                                <input type="text" name="policeEntry" id="police_entry" onChange={handleInputChange} className="form-control" value={expenses.policeEntry} required />

                            </div>
                            {/* <div className="form-group">
                                <label>Diesel Expenses</label>
                                <input type="text" name="dieselExpenses" onChange={handleInputChange} className="form-control" value={expenses.dieselExpenses} required />
                            </div>
                            <div className="form-group">
                                <label>Landing Expenses</label>
                                <input type="text" name="loadingExpenses" onChange={handleInputChange} className="form-control" value={expenses.loadingExpenses} required />
                            </div> */}
                            {/* <div className="form-group">
                                <label>Total Expenses</label>
                                <input type="text" name="totalExpenses" onChange={handleInputChange} className="form-control" value={expenses.totalExpenses} required />
                            </div> */}
                            <div className="form-group">
                                <label>Border Exp</label>
                                <input type="text" name="borderExp" onChange={handleInputChange} className="form-control" value={expenses.borderExp} required />
                            </div>
                            <div className="form-group">
                                <label>On Route Repairing</label>
                                <input type="text" name="onRouteRepairing" onChange={handleInputChange} className="form-control" value={expenses.onRouteRepairing} required />
                            </div>
                            <div className="form-group">
                                <label>RTO Challan</label>
                                <input type="text" name="rtoChallan" onChange={handleInputChange} className="form-control" value={expenses.rtoChallan} required />
                            </div>
                            <div className="form-group">
                                <label>Cash Toll</label>
                                <input type="text" name="cashToll" onChange={handleInputChange} className="form-control" value={expenses.cashToll} required />
                            </div>
                            <div className="form-group">
                                <label>Driver Exp</label>
                                <input type="text" name="driverExp" onChange={handleInputChange} className="form-control" value={expenses.driverExp} required />
                            </div>
                            <div className="form-group">
                                <label>Urea Purchase</label>
                                <input type="text" name="ureaPurchase" onChange={handleInputChange} className="form-control" value={expenses.ureaPurchase} required />
                            </div>
                            <div className="form-group">
                                <label>Other</label>
                                <input type="text" name="other" onChange={handleInputChange} className="form-control" value={expenses.other} required />
                            </div>
                        </form>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success" onClick={saveExpenses} > Submit </button>
                            <button type="button" onClick={closeAddExpensesModal} className="btn btn-default" > Cancel </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"slide-r " + (approveExpensesModal.approveExpensesSlide)} style={{ overflow: "auto" }}>
                <h3 className="subH text-center">Validate Expenses
                    <span className="float-right closebtn" style={{ marginRight: "10px" }} onClick={closeApproveExpensesModal} >X</span>
                </h3>

                <div className="slide-r-body" style={{ position: "relative" }}>

                    <div className="container-fluid">
                        <form method="POST" id="upform" className="theme-form">
                            <div className="form-group">
                                <label>Approval Status</label>
                                <Select
                                    className="border-radius-0"
                                    isMulti={false}
                                    id="approve_status"
                                    value={approveStatus}
                                    onChange={e => setApproveType(e)}
                                    style={{ borderRadius: "0px" }}
                                    options={[
                                        { label: "Approve", value: "Approved" },
                                        { label: "Reject", value: "Rejected" },
                                    ]} required />
                            </div>
                            <div className="form-group">
                                <label>
                                    {approveStatus?.value === "Rejected" ? "Reason for Rejection" : "Comment"}
                                </label>
                                <textarea
                                    name="comment"
                                    id="comment"
                                    onChange={(e) => setComment(e.target.value)}
                                    className="form-control"
                                    value={comment}
                                    required={approveStatus?.value === "Rejected"}
                                />
                            </div>
                        </form>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success" onClick={saveApprovalStatus} > Submit </button>
                            <button type="button" onClick={closeApproveExpensesModal} className="btn btn-default" > Cancel </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"slide-r " + (addDriverModal.setAddDriverSlide)} style={{ overflow: "auto" }}>
                <h3 className="subH text-center">Update Driver Details
                    <span className="float-right closebtn" style={{ marginRight: "10px" }} onClick={closeAddDriverModal} >X</span>
                </h3>

                <div className="slide-r-body" style={{ position: "relative" }}>

                    <div className="container-fluid">
                        <form method="POST" id="upform" className="theme-form">
                            <div className="form-group">
                                <label>Driver Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="form-control"
                                    onChange={(e) => setDriverName(e.target.value)}
                                    value={driverName}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Driver Mobile No</label>
                                <input
                                    type="tel"
                                    name="mobile"
                                    id="mobile"
                                    className="form-control"
                                    onChange={(e) => setDriverMobile(e.target.value)}
                                    value={driverMobile}
                                    required
                                    pattern="[0-9]{10}" 
                                    title="Please enter a valid 10-digit mobile number"
                                />
                            </div>
                        </form>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success" onClick={saveDriverDetails} > Submit </button>
                            <button type="button" onClick={closeAddDriverModal} className="btn btn-default" > Cancel </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"overlay-part " + (overly)} style={{ zIndex: "10" }}
                onClick={onClickHideAll}
            ></div>
            <div className={"dataLoadpage " + (loadshow)}></div>
            <div className={"dataLoadpageimg " + (loadshow)} style={{ top: "21em" }}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>

        </div >
    );
}

export default Consignments;

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
} 

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }