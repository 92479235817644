import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import FavouriteFilled from "../../../assets/icons/favourite_filled_icon.svg";
import FavouriteOutlined from "../../../assets/icons/favourite_outlined_icon.svg";

import redirectURL from "../../redirectURL";

// const FavouriteOutlined = require("../../../assets/icons/favourite_outlined_icon.svg");
// const FavouriteFilled = require("../../../assets/icons/favourite_filled_icon.svg");

class StarIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavorited: false,
      screenName: "",
      loading: false,
      favorites: [],
      showConfirm: false, // To show confirmation SweetAlert
      showAlert: false, // To show alert SweetAlert
      showInputPrompt: false, // State to control input prompt visibility
      manualScreenName: "", // State to store user-input screen name
    };
  }

  componentDidMount() {
    try {
      // this.fetchData();
      this.updateFavoriteStatus();
      // this.getScreenName();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the screen prop has changed
    // if (prevProps.screen !== this.props.screen) {
    //     this.fetchData();  // Refetch the data when screen changes
    // }

    // Recheck the favorite status when the screen or favorites prop changes
    if (
      prevProps.screen !== this.props.screen ||
      prevProps.favorites !== this.props.favorites
    ) {
      this.updateFavoriteStatus();
      // this.getScreenName();
    }
  }

  updateFavoriteStatus = () => {
    const { screen, favorites } = this.props;
    const isFavorited = favorites.some((fav) => fav.screenPath === screen);
    this.setState({ isFavorited });
  };

  fetchData = async () => {
    try {
      // Show loading state
      this.setState({ loading: true });

      // Fetch both favorites and screen name concurrently using Promise.all
      await Promise.all([this.getFavorites()]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Hide loading state
      this.setState({ loading: false });
    }
  };

  getFavorites = async () => {
    const email = localStorage.getItem("email"); // Assuming userId is stored in localStorage

    await redirectURL
      .post("dashboard/getFavorites", { email }) // Call the getFavorites API
      .then((response) => {
        const { screen } = this.props;
        if (
          !response ||
          !response.data ||
          !Array.isArray(response.data.favorites)
        ) {
          console.error("Invalid response or favorites data.");
          return;
        }
        console.log("Favorites:", response.data.favorites); // Debugging log
        console.log("Current screen:", screen); // Debugging log

        // Check if the screenPath matches the current screen in the favorites array
        const isFavorited = response.data.favorites.some(
          (fav) => fav.screenPath === screen
        );

        this.setState({
          isFavorited: isFavorited, // Update favorite status
          favorites: response.data.favorites,
        });
      })
      .catch((error) => {
        console.error("Error fetching favorites:", error);
      });
  };

  getScreenName = async () => {
    const { screen } = this.props;
    const screenName = this.getScreenNameFromMenuItems(screen);
    console.log("Name: ", screenName);
    if (!screenName) {
      this.setState({ showInputPrompt: true }); // Show input prompt if screenName is not found
    } else {
      this.setState({ screenName });
    }
    // this.setState({ screenName });
  };

  getScreenNameFromMenuItems = (pathname) => {
    // Get menuItems from localStorage
    const menuItems = JSON.parse(localStorage.getItem("usermenus")) || [];

    console.log("Menu Items: ", menuItems);

    // Recursive function to search for the path in menu items and nested children
    const findScreenName = (items) => {
      for (const menuItem of items) {
        // console.log("Menu Item: ", menuItem);

        // Check if current item's path matches the pathname
        if (menuItem.path === pathname) {
          // console.log("Matching Path: ", menuItem.path);
          return menuItem.name;
        }

        // If the item has children, search recursively
        if (menuItem.children && menuItem.children.length > 0) {
          // console.log("Inside children check for: ", menuItem.name);
          const childName = findScreenName(menuItem.children);
          if (childName) {
            return childName; // Return the name if found in children
          }
        }
      }
      return null; // If no match is found in this branch
    };

    // Start searching from the root level
    const screenName = findScreenName(menuItems);

    // Return the found screen name, or 'Unknown Page' if not found
    return screenName || "";
  };

  addOrRemoveFavorite = async () => {
    const { screen, updateFavorites } = this.props;
    const { screenName } = this.state;
    const email = localStorage.getItem("email");
    // console.log(redirectURL);
    // console.log("Screen Name in API: ", screenName);

    try {
      const response = await redirectURL.post("dashboard/addOrRemoveFavorite", {
        email,
        screenPath: window.location.pathname,
        screenName,
      });

      if (response && response.data && Array.isArray(response.data.favorites)) {
        const updatedFavorites = response.data.favorites;
        const isFavorited = updatedFavorites.some(
          (fav) => fav.screenPath === screen
        );

        this.setState({ isFavorited });
        updateFavorites(updatedFavorites);
      }
    } catch (error) {
      console.error("Error updating favorites:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleConfirmRemove = async () => {
    // Proceed with removing a favorite after confirmation
    await this.addOrRemoveFavorite();
    this.setState({ showConfirm: false });
  };

  handleCancelRemove = () => {
    // Cancel the removal operation
    this.setState({ loading: false, showConfirm: false });
  };

  toggleFavorite = async () => {
    const { screen, favorites } = this.props;
    const email = localStorage.getItem("email");

    // Check if the screen is already favorited
    const favoritedItem = favorites.find((fav) => fav.screenPath === screen); // Get the favorited item (if it exists)
    const isFavorited = !!favoritedItem; // Boolean to check if already favorited

    if (!isFavorited) {
      // If the screen is not favorited, we need to get or prompt for the screen name
      const screenName = this.getScreenNameFromMenuItems(screen);

      console.log("Screen Name in toggle: ", screenName);
      console.log("Screen Path in toggle: ", window.location.pathname);
      console.log("this.props: ", this.props);

      // If screen name is still not available, show input prompt
      if (!screenName) {
        this.setState({ showInputPrompt: true });
        return;
      } else {
        // Set screenName and use callback to wait for state update before proceeding
        this.setState({ screenName }, async () => {
          // Now that screenName is set, proceed with adding the favorite
          if (favorites.length >= 8) {
            this.setState({ showAlert: true }); // Prevent adding more than 8 favorites
            return;
          }
          await this.addOrRemoveFavorite();
        });
        return; // Prevent further execution until the state is updated
      }
    }

    try {
      this.setState({ loading: true });

      if (screen !== "" && email) {
        if (isFavorited) {
          // If already favorited, show confirmation SweetAlert for removing the favorite
          this.setState({
            showConfirm: true,
            screenName: favoritedItem.screenName,
          }); // Use the screenName from the favorited item
        } else {
          // Show alert SweetAlert if the user already has 8 favorites
          if (favorites.length >= 8) {
            this.setState({ showAlert: true });
            return;
          }

          // Proceed with adding the favorite
          await this.addOrRemoveFavorite();
        }
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  handleScreenNameSubmit = async (manualScreenName) => {
    // Reset the manual screen name before proceeding with the new one
    this.setState({
      manualScreenName: manualScreenName,
      screenName: manualScreenName,
      showInputPrompt: false,
    });

    // Add the 8 favorites limit check here
    const { favorites } = this.props;
    if (favorites.length >= 8) {
      this.setState({ showInputPrompt: false, showAlert: true }); // Show alert if limit is exceeded
      return;
    }

    // Ensure valid input is provided
    if (manualScreenName.trim() !== "") {
      this.setState({ screenName: manualScreenName }, async () => {
        // Call addOrRemoveFavorite API after setting the screen name
        await this.addOrRemoveFavorite();

        // Clear the manualScreenName state once submitted
        this.setState({ manualScreenName: "" });
      });
    }
  };
  removeFavItem(oItem) {
    // console.log("oItem", oItem)
    var params = {
      user_email: localStorage.getItem("email"),
      page_path: oItem,
    };
    // console.log("params ", params)
    redirectURL.post("app/deleteFavouriteItem", params).then((response) => {
      this.props.context.loadFavourites();
    });
  }
  render() {
    const {
      isFavorited,
      loading,
      showConfirm,
      showAlert,
      showInputPrompt,
      manualScreenName,
    } = this.state;
    const { favorites } = this.props;

    return (
      <li className="onhover-dropdown">
        <a className="txt-dark">
          <img
            className="align-self-center pull-right"
            src={isFavorited ? FavouriteFilled : FavouriteOutlined}
            alt="favourite-icon"
            onClick={this.toggleFavorite}
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
              outline: "none",
              padding: "0",
              opacity: loading ? 0.5 : 1,
            }}
            width={"31px"}
            disabled={loading}
          />
        </a>
        <ul
          className="notification-dropdown onhover-show-div favorites-list"
          style={{ height: "425px", overflowY: "auto" }}
        >
          <li className="favorites-list-header">
            <img src={FavouriteFilled} alt="" width={"24px"} />
            <p style={{ fontSize: "15px", marginBottom: "0" }}>Favourites</p>
          </li>
          {favorites.length > 0 ? (
            favorites.map((fav, index) => (
              <li className="favorites-list-item" key={index}>
                <Link to={fav.screenPath} className="favourite_link">
                  <p>
                    {fav.screenName}
                    {/* <button className="btn btn-default float-right f10" style={{background:"transparent"}} type="button" onClick={this.removeFavItem.bind(this,fav.screenPath)}><i className="f12 fa fa-trash"></i> </button> */}
                  </p>
                </Link>
              </li>
            ))
          ) : (
            <li>No favorites added yet</li>
          )}
        </ul>

        {/* SweetAlert for confirmation when removing a favorite */}
        {showConfirm && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, remove it!"
            confirmBtnBsStyle="danger"
            onConfirm={this.handleConfirmRemove}
            onCancel={this.handleCancelRemove}
            focusCancelBtn
          >
            Are you sure you want to remove this screen from favorites?
          </SweetAlert>
        )}

        {/* SweetAlert for alert when trying to add more than 8 favorites */}
        {showAlert && (
          <SweetAlert
            danger
            onConfirm={() => this.setState({ showAlert: false })}
          >
            You cannot add more than 8 favorite screens.
          </SweetAlert>
        )}

        {/* Input prompt for manual screen name entry */}
        {showInputPrompt && (
          <SweetAlert
            input
            showCancel
            inputValue={manualScreenName}
            onCancel={() => this.setState({ showInputPrompt: false })}
            onConfirm={(value) => this.handleScreenNameSubmit(value)}
          >
            Please enter a screen name
          </SweetAlert>
        )}
      </li>
    );
  }
}

export default StarIcon;
