import React, { Component } from 'react';

class Breadcrumb extends Component {
    setPageAsFav(){
        var pathname = window.location.pathname;
        console.log("pathname",pathname)
    }
    render(){
        return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <ol className="breadcrumb ">
                        <li className="breadcrumb-item"><a href="/"><i className="fa fa-home"></i></a></li>
                        {
                            (this.props.parent !== "Home" && this.props.parent !== "home" && this.props.parent !== '' & this.props.parent !== '/')?
                            <li className="breadcrumb-item">{this.props.parent}</li>
                        :""}
                        {
                            (this.props.title !== "" && this.props.parent !== "undefined")?
                            <li className="breadcrumb-item active">{this.props.title}</li>
                        :""}
                        {/* <li className="breadcrumb-item">
                            <span className="favic float-right">
                                <a href="javascript:;" onClick={this.setPageAsFav.bind(this)}><i className="fa fa-star-o"></i> Add to Favourites</a>
                            </span>
                        </li> */}
                    </ol>
                    
                </div>
            </div>
        </div>
        );
    }
}

export default Breadcrumb