
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMYYYYHHMMSS,
} from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import redirectURL from '../redirectURL';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { NavItem } from 'reactstrap';
import { isInteger, lte } from 'lodash';
import EbillWayInfo from './ewaybillinfo';
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class EwayBillData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            sliderTranslate: '',
            sliderRouteTranslate: '',
            sliderCommentTranslate: "",
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            vehicleList: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "E-Way Bill Monitoring",
            bulkslide: "",
            consignee: "",
            truck_no: "",
            startDate: "",
            endDate: "",
            countersjson: "",
            //components : {datepicker:getDatePicker()},
            frameworkComponents: {
                
            },
            showDiv: 'show-n',
            mapinfo: "",
            dealer: "",
            consignment_code: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            leg_no: 0,
            rowId: "",
            csvData: [],
            bulkMarkData: [],
            tabOneactive: "activet",
            tabTwodelivered: '',
            tabThreeconsignmentform: '',
            activeClass: 'col-xl-6 col-lg-6 show-m',
            tabOneActiveData: "show-m",
            tabTwoDeliveredData: "show-n",
            tabThreeCreateConsgData: "show-n",
            transporterName: '',
            vehicleNo: '',
            consignerCode: '',
            gateInTime: '',
            gateOutTime: '',
            trucksList: [],
            transportersList: [],
            vin_no: '',
            gridRowData: [],
            vinIsDisabled: true,
            source: '',
            destination: '',
            sourceList: [],
            digi_lock:[],
            latLngArray : [],
            mapShow : 0,
            sliderTitle : "Bulk Upload",
			open: false,
            digiAlertsData:[],
            dataType : 0,
            activeCnt : 0,
            expiredCnt : 0,
            expiringtomorrow : 0,
            expiringtoday : 0,
            cancelledCnt : 0,
            cancelparams:"",
            inputDetails:[],
            sliderType:1,
            sidebarSubHeader:"",
            transporter_gstin : "",
            lastRunTime : "",
            lastRunRowId : "",
            time: {}, 
            seconds: 60,
            lastSyncedOn:"",
            VehiclListDetails:[],
            pagetype:1
        }
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
        this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
        this.getTruckConsignments = this.getTruckConsignments.bind(this);
		this.startTimer = this.startTimer.bind(this);
		this.countDown = this.countDown.bind(this);
		this.timer = 0;      
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
       
    }
    async componentDidMount() {
        // loadDateTimeScript();
        var currentPath = window.location.pathname;
        currentPath = currentPath.split("/");
        var pagetype = currentPath[currentPath.length-1];
        // var pagetype = (this.props.match.params.pagetype);
        console.log(pagetype,"pagetype")
        this.setState({
            seconds : 0,
            pagetype : pagetype
        });
        var from_date = moment.parseZone(new Date()).subtract(7 , 'days').format("YYYY-MM-DD");
        var to_date = moment.parseZone(new Date()).format("YYYY-MM-DD")
        this.setState({
            eway_from_date : from_date,
            eway_to_date : to_date
        })
        var params = {
            transporter_code : localStorage.getItem("transportercode")
        }
        redirectURL.post("/ewaybill/getTransporterGSTDetails",params).then((response)=>{
            var resp = response.data;
            // console.log('resp',resp)
            if(resp.length > 0)
            {
                this.setState({
                    transporter_gstin : resp[0].gstin
                });
            }
        }).catch((e)=>{
            console.log(e);
        })
        this.onLoadPageData(0)
    }
    onLoadPageData = dataType => {
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })
        var reqparams = {
            dataType : dataType
        }
        if(dataType == 1)
        {
            reqparams.startDate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD');
            reqparams.endDate = moment.parseZone().format('YYYY-MM-DD');
        }
        if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !='')
        {
            reqparams.transporter_code = JSON.parse(localStorage.getItem("transportercode"));
        }
        var currentPath = window.location.pathname;
        currentPath = currentPath.split("/");
        var pagetype = currentPath[currentPath.length-1];
        reqparams.pagetype = pagetype;
        if(pagetype == 3){
             reqparams.from_date = moment.parseZone(new Date()).subtract(7 , 'days').format("YYYY-MM-DD");
             reqparams.to_date = moment.parseZone(new Date()).format("YYYY-MM-DD")
        }
        redirectURL.post("/ewaybill/getTransporterEwayBills", reqparams).then(async(response) => {
            var gridData = response.data;
            // console.log("gridData", gridData)
            var lastSyncedOn = "";
            if(gridData.length > 0)
            {
                if(gridData[0].last_synced_on !=undefined && gridData[0].last_synced_on !="")
                {
                    lastSyncedOn = getHyphenDDMMYYYYHHMMSS(gridData[0].last_synced_on);
                }
            }
            // await this.setState({
            //     rowData: gridData,
            //     gridRowData: gridData,
            //     lastSyncedOn: lastSyncedOn,
            //     //countersjson: pagecounters,
            //     loadshow:"show-n",
            //     overly:"show-n",
            // })//tmssssssssssssssssssss
            
            await this.setState({
                rowData: [],
                gridRowData: [],
                lastSyncedOn: [],
                //countersjson: pagecounters,
                loadshow:"show-n",
                overly:"show-n",
            })
            await this.loadCountersData();
        })
    }
    loadCountersData = async() => {
        var gridData = this.state.rowData;
        var activeData = [];
        var expiringtomorrowData = [];
        var expiringtodayData = [];
        var expiredData = [];
        var cancelledData = [];
        await gridData.map(function(e){
            if(e.eway_bill_status == "Cancelled")
            {
                cancelledData.push(e);
            }
            else
            {
                if(e.eway_bill_extended_validity_date !=undefined && e.eway_bill_extended_validity_date !='')
                {
                    var validityDate = e.eway_bill_extended_validity_date;
                    var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                    var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                    var d = moment.duration(ms);
                    var difference = parseFloat(d.asDays());
                    if(difference > 0 && difference <= 1)
                    {
                        expiringtodayData.push(e);
                    }
                    else if(difference > 1 && difference <= 2)
                    {
                        expiringtomorrowData.push(e);
                    }
                    else if(difference <= 0)
                    {
                        expiredData.push(e);
                    }
                    else{
                        activeData.push(e);
                    }
                }
                else
                {
                    if(e.eway_bill_valid_date !=undefined && e.eway_bill_valid_date !='')
                    {
                        var validityDate = e.eway_bill_valid_date;
                        var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                        var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                        var d = moment.duration(ms);
                        var difference = parseFloat(d.asDays());
                        if(difference > 0 && difference <= 1)
                        {
                            expiringtodayData.push(e);
                        }
                        else if(difference > 1 && difference <= 2)
                        {
                            expiringtomorrowData.push(e);
                        }
                        else if(difference <= 0)
                        {
                            expiredData.push(e);
                        }
                        else{
                            activeData.push(e);
                        }
                    }
                }
            }
        });
        await this.setState({
            activeData : activeData,
            expiringtomorrowData : expiringtomorrowData,
            expiringtodayData : expiringtodayData,
            expiredData : expiredData,
            cancelledData : cancelledData,
            activeCnt : activeData.length,
            expiredCnt : expiredData.length,
            expiringtomorrow : expiringtomorrowData.length,
            expiringtoday : expiringtodayData.length,
            cancelledCnt : cancelledData.length,
        });
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridReady1 = params => {
        this.gridApi1 = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onGridState1 = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi1.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi1.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi1.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState);
    }

    changeSelectedConsignee = (e) => {
        // console.log("e", e)
        this.setState({
            transporterName: e,
        })
    }
    changeVehicleNo = (e) => {

        this.setState({
            vehicleNo: e,
        })
    }

    changeSource = (e) => {
        this.setState({
            source: e,
        })
    }

    changeDestination = (e) => {
        this.setState({
            destination: e,
        })
    }

    
    insertdttconsignments = (event) => {
        event.preventDefault();
        let eventLabel = googleAnalytics.page.action.insertdttconsignments;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        // googleAnalytics.logEvent(eventOptions);

        var vin_no = [];
        if (this.state.vin_no.length != 0) {
            this.state.vin_no.map((e) => { vin_no.push(e.value) })
        }
        let gateInTime = this.state.gateInTime
        let gateOutTime = this.state.gateOutTime
        let consignmentCode = $("#consignmentCode").val();
        let quantity = $("#Quantity").val();
        let driver_name= $("#driverName").val();
        let driver_phone_no= $("#driverPhoneNumber").val();
        let dg_serial_no= $("#dgSerialNum").val();
        let transporterCode = this.state.transporterName.value;
        let transporterName = this.state.transporterName.label;
        let vehicleNo = this.state.vehicleNo.label;
        let consignerCode = this.state.source.value;
        let consignee_code = this.state.destination.value;
        if (gateInTime == '' || gateInTime == "NaN-NaN-NaN") {
            gateInTime = "";
        }
        consignmentCode = removeSplChars(consignmentCode)
        driver_name = removeSplChars(driver_name)
        driver_phone_no = removeSplChars(driver_phone_no)
        dg_serial_no = removeSplChars(dg_serial_no)
        consignmentCode = consignmentCode.toUpperCase()
        if (vin_no.length == quantity) {
            const reqparams =
            {
                consignment_code: consignmentCode,
                gate_in_time: gateInTime,
                gate_out_time: gateOutTime,
                transporter_name: transporterName,
                transporter_code: transporterCode,
                truck_no: vehicleNo,
                consigner_code: consignerCode,
                consignee_code: consignee_code,
                quantity: quantity,
                dept_code: "SND22",
                status: 2,
                vin_no: vin_no,
                driver_name: driver_name,
                driver_phone_no: driver_phone_no,
                dg_serial_no : dg_serial_no
            };
            let userId = localStorage.getItem('userid');
            if (userId != undefined && userId != '') {
                reqparams['userId'] = userId
            }
            reqparams['created_by'] = localStorage.getItem('username');
            // console.log("reqparams", reqparams);
            if (consignmentCode != "" && consignmentCode != undefined && quantity != null && quantity !== '' && transporterName !== undefined && transporterName !== "" && vehicleNo !== undefined && vehicleNo !== ""
                && consignerCode !== "" && consignerCode !== undefined && consignee_code !== undefined && consignee_code !== ""
                && gateOutTime != "__-__-____"
            ) {

                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m"
                });
                redirectURL.post("/ewaybill/insertD22consignments", reqparams).then((response) => {
                    if (response.data.message == "success") {
                        this.onLoadPageInitialRowData()
                        this.setState({
                            basicTitle: "D22Consignment Created Successfully..!!!",
                            basicType: "success",
                            show: true,
                            rowId: "",
                            loadshow: 'show-n',
                            overly: "show-n",
                            transporterName: '',
                            vehicleNo: '',
                            source: '',
                            destination: '',
                            vin_no: ''
                        })
                        $("#consignmentCode").val(" ");
                        $("#Quantity").val(" ");
                        $("#driverName").val(" ");
                        $("#driverPhoneNumber").val(" ");
                        $("#dgSerialNum").val(" ");
                    }
                    else if (response.data.message == "D22Consignment Already Exists.. !!!") {
                        this.setState({
                            basicTitle: "D22Consignment Already Exists.. !!!",
                            basicType: "warning",
                            show: true,
                            loadshow: 'show-n',
                            overly: "show-n"
                        })
                    }
                    // console.log("",response.data);

                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                console.log("empty check")
                this.setState({
                    basicTitle: "Fields Should not be Empty",
                    basicType: "warning",
                    show: true
                })
            }


        } else {
            this.setState({
                basicTitle: "Please Enter VinNo's matching Quantity",
                basicType: "warning",
                show: true

            })
        }


    }

    quantityHandler = e => {
        // console.log("event",e)
        this.setState({
            vinIsDisabled: false
        })
    }
    onChangeVins = (e) => {
        let quantity = $("#Quantity").val();
        // console.log("e",e)
        this.setState({ vin_no: e })
        // if(e !== null){
        //     if(e.length == quantity){
        //         this.setState({ vin_no: e })
        //     }       
        //     else{
        //         this.setState({
        //             basicTitle : "Please Enter VinNo's matching Quantity",
        //             basicType : "warning",
        //             show : true
        //         })
        //     }
        // }
    }

    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: '',
            bulkMarkData: []
        })
        $('.upform')[0].reset();
    }

    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                {
                    name: 'eway_bill_number',
                    inputName: 'eway_bill_number',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'eway_bill_extended_validity_date',
                    inputName: 'eway_bill_extended_validity_date',
                    required: false,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                let csvList = csvData.data;
                var flag = 0;
                // let promiseVar = csvList.map((e) => {
                //     e['consignment_code'] = removeSplChars(e.consignment_code)
                //     let vinList = (e['vin_no']).split(',')
                //     if (isNaN(parseInt(e.quantity))) {
                //         flag = 1;
                //         this.setState({
                //             basicTitle: "Please Enter Quantity as Number",
                //             basicType: "warning",
                //             show: true
                //         })
                //     }
                //     else {
                //         if (e.quantity != vinList.length) {
                //             flag = 1;
                //             this.setState({
                //                 basicTitle: "Please Enter VinNo's matching Quantity",
                //                 basicType: "warning",
                //                 show: true
                //             })
                //         }
                //     }
                // })
                // console.log(csvList,'csvList')
                if (flag == 0) {
                        this.setState({
                            bulkMarkData: csvList
                        });
                }

            })
            .catch(err => {
                console.log(err, "error")
            })
        // console.log("e.target.files[0]",e.target.result);
        if (e.target.files[0] != undefined) {
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);

            if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }

    }

    async uploadBulkFormHandler(e) {
        e.preventDefault();
        var dataType = this.state.dataType;
        var fileData = this.state.bulkMarkData;
        var flag = 0;
        // console.log(fileData, "fileData");
        if (flag == 0) {
            if (fileData.length > 0) {
                var reqParams = {
                    csvData: fileData,
                    user_email: localStorage.getItem("email"),
                    user_name: localStorage.getItem("username"),
                    page_type: 1
                }
                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m"
                });
                redirectURL.post("/ewaybill/bulkUpdateEwayBillExtendedValidity", reqParams).then((response) => {
                    var resp = response.data.message;
                    // console.log('resp', response.data)
                    this.setState({
                        basicTitle1: resp,
                        basicType1: "success",
                        show1: true,
                        bulkslide: '',
                        overly: 'show-n',
                        file: "",
                        loadshow: 'show-n',
                        bulkMarkData: []
                    })
                    $('.upform')[0].reset();
                    this.onLoadPageData(dataType);
                    //window.location.reload();
                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                this.setState({
                    basicTitle: "Invalid Data",
                    basicType: "warning",
                    show: true,
                    file: "",
                    bulkMarkData: []
                })
                $('.upform')[0].reset();
            }
        }
        else {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "warning",
                show: true,
                file: "",
                bulkMarkData: []
            })
            $('.upform')[0].reset();
        }
    };
    closeAlert = () => {
        this.setState({
            show: false
        });
        //window.location.reload();
    }
    closeAlert1 = () => {
        this.setState({
            show1: false
        });
        var dataType = this.state.dataType;
        this.onLoadPageData(dataType);
        //window.location.reload();
    }

    onClickHideAll() {
        // console.log("cliekc");
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: '',
            consignee: "",
            truck_no: "",
            rowId: "",
            sliderRouteTranslate: ""
        });

    }

    onClickTab(pageType) {
        if (pageType == "active") {
            $("#selectedFilter").val("");
            $("#inputTruckConsignment").val(" ");
            var tabOneactive = "activet";
            var tabTwodelivered = "";
            var tabThreeconsignmentform = "";
            var tabOneActiveData = "show-m";
            var tabTwoDeliveredData = "show-n";
            var tabThreeCreateConsgData = "show-n";
            this.onLoadPageData(0);
            var activeClass = 'col-xl-6 col-lg-6 show-m'
            this.setState({
                dataType:0,
                startDate: '',
                endDate: '',
                movementtype: {value:"all",label:"All"},
                consigner: [{value:"all",label:"All"}],
                flCity: [{value:"all",label:"All"}],
                flState: [{value:"all",label:"All"}],
                flTransporter: [{value:"all",label:"All"}],
            })
        }
        else if (pageType == "delivered") {
            $("#selectedFilter").val("");
            $("#inputTruckConsignment").val(" ");
            tabOneactive = "";
            tabTwodelivered = "activet";
            tabThreeconsignmentform = "";
            tabOneActiveData = "show-n";
            tabTwoDeliveredData = "show-m";
            tabThreeCreateConsgData = "show-n";
            this.onLoadPageData(1);
            activeClass = 'col-xl-6 col-lg-6 show-m';
            var sdate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD');
            var edate = moment.parseZone().format('YYYY-MM-DD');
            this.setState({
                dataType:1,
                startDate: sdate,
                endDate: edate,
                movementtype: {value:"all",label:"All"},
                consigner: [{value:"all",label:"All"}],
                flCity: [{value:"all",label:"All"}],
                flState: [{value:"all",label:"All"}],
                flTransporter: [{value:"all",label:"All"}],
            })
        }
        else if (pageType == "createConsignments") {

            loadDateTimeScript();
            tabOneactive = "";
            tabTwodelivered = "";
            tabThreeconsignmentform = "activet";
            tabOneActiveData = "show-n";
            tabTwoDeliveredData = "show-n";
            tabThreeCreateConsgData = "show-m";
            activeClass = 'col-xl-6 col-lg-6 show-n'

            // this.onLoadPageData(2);
        }

        this.setState({
            tabOneactive,
            tabTwodelivered,
            tabThreeconsignmentform,
            tabOneActiveData,
            tabTwoDeliveredData,
            tabThreeCreateConsgData,
            activeClass
        })
    }

    gateOutDateTime = event => {
        let d = new Date(event._d);
        let startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        // console.log("gateOutdate", startdate)
        this.setState({
            gateOutTime: startdate
        })
    }

    gateInDateTime = event => {
        let d = new Date(event._d);
        let startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        // console.log("gateOutdate", startdate)
        this.setState({
            gateInTime: startdate
        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            eway_from_date: startdate
        });

    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        this.setState({
            eway_to_date: edate
        });
    }

    getTruckConsignments=(event)=>{
		this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").val();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if(selectedFilter == "" || inputTruckConsignment == "")
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true,
				loadshow : "show-n",
				overly : "show-n"
			})
		}
		else
		{
			var transporter_code = "";
			var transportercode = localStorage.getItem('transportercode');
			if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
			{
				var transporter_code = JSON.parse(transportercode);
				//var transporter_code = tptCode[0];
			}
			var parameters = {
				"selectedFilter":selectedFilter,
				"inputTruckConsignment":inputTruckConsignment,
				"dept_code" : this.state.deptcode,
                "transporter_code":transporter_code,
                "dataType" : this.state.dataType
			}
			redirectURL.post("/ewaybill/filterPRTEwayBillConsignments",parameters)
			.then(async(response)=>{
				var gridData = response.data;
				// console.log(gridData,"gridData")
				await this.setState({
					rowData: gridData,
                    gridRowData: gridData,
					loadshow : "show-n",
					overly : "show-n",
                    movementtype: {value:"all",label:"All"},
                    consigner: [{value:"all",label:"All"}],
                    flCity: [{value:"all",label:"All"}],
                    flState: [{value:"all",label:"All"}],
                    flTransporter: [{value:"all",label:"All"}],
				})
                await this.loadCountersData();
			}).catch=((e)=>
			{
				console.log(e);
			})	
		}
		
	}

    reloadPageData = () => {
        var dataType = this.state.dataType;
        $("#selectedFilter").val("");
        $("#inputTruckConsignment").val("");
        if(dataType == 0)
        {
            this.setState({
                startDate: '',
                endDate: '',
                movementtype: {value:"all",label:"All"},
                consigner: [{value:"all",label:"All"}],
                flCity: [{value:"all",label:"All"}],
                flState: [{value:"all",label:"All"}],
                flTransporter: [{value:"all",label:"All"}],
            })
        }
        else
        {
            this.setState({
                movementtype: {value:"all",label:"All"},
                consigner: [{value:"all",label:"All"}],
                flCity: [{value:"all",label:"All"}],
                flState: [{value:"all",label:"All"}],
                flTransporter: [{value:"all",label:"All"}],
            })
        }
        this.onLoadPageData(dataType);        
    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            tabsliderTranslate: '',
            showDiv: 'show-n',
            sliderTranslatesidebar: "",
            sliderCommentTranslate: "",
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n',
            loadshow: 'show-n',
            slideuploadeway: ""
        });
    }

    onCloseRouteDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderRouteTranslate: "",
            sliderCommentTranslate: "",
            sliderSegmentTranslate: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n'
        });
    }

    handleRouteClick = e => {
        // console.log("e",e)
        // e.stopPropagation();
        this.onCloseUploadDiv(e);
        this.onCloseRouteDiv(e);
        //    this.onShowRouteDiv();
    }

    onDigiAlertsClicked = () => {
        this.setState({            
            open:true,
            loadshow:'show-n'
        });
    }

    onCellUpdateData = e => {
        if (e.colDef.field == 'eway_bill_extended_validity_date') {
            var dataType = this.state.dataType;
            if (e.data.eway_bill_extended_validity_date !== undefined && e.data.eway_bill_extended_validity_date !== '') {
                var eway_bill_extended_validity_date = e.data.eway_bill_extended_validity_date;
                var validityDate = e.data.eway_bill_valid_date;
                var d1 = moment.parseZone(new Date(eway_bill_extended_validity_date)).format("YYYY-MM-DD HH:mm:ss")
                var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                var ms = moment(d1,"YYYY-MM-DD HH:mm:ss").diff(moment(d2,"YYYY-MM-DD HH:mm:ss"));
                var d = moment.duration(ms);
                var difference = parseFloat(d.asDays());
                console.log(difference,d1,d2)
                // console.log("e",e.data.eway_bill_extended_validity_date)
                if (dataType == 0) {
                    if(difference > 0)
                    {
                        var reqparams = {
                            consignment_code: e.data.consignment_code,
                            eway_bill_extended_validity_date: eway_bill_extended_validity_date,
                            user_email: localStorage.getItem("email"),
                            user_name: localStorage.getItem("username"),
                            page_type: 1
                        }
                        redirectURL.post("/ewaybill/updateEwayBillExtendedValidity", reqparams).then(response => {
                            let responseData = response.data.message;
                            // console.log("timerowData", responseData)
                            if (responseData == "success") {
                                this.setState({
                                    basicType: "success",
                                    basicTitle: "Success",
                                    show: true,
                                })
                                this.onLoadPageData(dataType);
                            }
                            else {
                                this.setState({
                                    basicType: "warning",
                                    basicTitle: responseData,
                                    show: true,
                                })
                                e.data.eway_bill_extended_validity_date = "";
                                this.gridApi.refreshCells();
                            }
                        })
                    }
                    else
                    {
                        this.setState({
                            basicType: "warning",
                            basicTitle: "E-Way Extended Validity Time should be greater than E-Way Bill Expiry Time",
                            show: true,
                        })
                        e.data.eway_bill_extended_validity_date = "";
                        this.gridApi.refreshCells();
                    }
                }
            }
        }
    }

    onSetCritical(params, status) {
        // console.log("onSetCritical ", params);
        // console.log("onSetCritical value ", status);
        redirectURL.post('/ewaybill/setCriticalStatusForD22', {
            params: params,
            is_critical: status,
            token: localStorage.getItem("token"),
            userId: localStorage.getItem("userid"),
        })
            .then((response) => {

                //var records = JSON.parse(JSON.stringify(response)).data;
                // console.log(response.data);
            });

    }

    validDateTime = current => {
        // var tommorow = moment().add( 1, 'day' );
        return current.isBefore();
    }

    
    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

    changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			// () => console.log(`Movement Option selected:`, this.state.movementtype)
		);
		
	}
	changeModeHandler = modetype => {
        this.setState(
			{ modetype },
			// () => console.log(`Movement Option selected:`, this.state.modetype)
		);
    }
	selectMovementTypeOptionsItems(){
        let items = [];     
		items.push({value:"all",label:"All"});
		items.push({value:true,label:"Local Movement"});
		items.push({value:false,label:"Non Local Movement"});
		return items;
	}
	selectModeOptionsItems(){
        let items = [];     
		items.push({value:"all",label:"All"});
		items.push({value:"AIR COURIER",label:"AIR COURIER"});
		items.push({value:"SURFACE COURIER",label:"SURFACE COURIER"});
		return items;
	}
    changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	    //   () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	changeConsigneeArrayHandler = consignee =>{
        this.setState(
	      { consignee },
	    //   () => console.log(`Consignee Option selected:`, this.state.consignee)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
    selectConsignerOptionsItems(){
        let items = [];    
		var consigners=[];
        var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
    
        // An array of values 
        var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
        
        // Object created 
        var obj = {}; 
        
        // Using loop to insert key 
        // value in Object 
        for(var i = 0; i < keys.length; i++){ 
            consigners.push({"code":keys[i],"value":values[i]}); 
        }
        items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
        return items;
	}
    newFilter=(filterType,selOption)=>{
		// console.log(filterType,selOption)
		this.setState({ [filterType]: selOption });
	}
    onGetConsigners = async (event) => {
		
        if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            //var sDate = this.state.defaultsdate;
            var sDate = "";
        }
        else{
            var sDate = this.state.startDate;
        }
        if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            //var eDate = this.state.defaultedate;
            var eDate = "";
        }
        else{
            var eDate = this.state.endDate;
        }		

		var selectconsigners=[]
		if(this.state.consigner == null)
		{
			selectconsigners = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsigners=this.state.consigner
		}

		var selectconsignees=[]
		if(this.state.consignee == null)
		{
			selectconsignees = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsignees=this.state.consignee
		}

		var mvmtype = []
		if(this.state.movementtype == null)
		{
			mvmtype = [{"value":'all', "label":'All'}]
		}
		else{
			mvmtype=[this.state.movementtype]
		}

		var selecttrip=[]
        selecttrip=[this.state.triptype]
		
        //console.log("S2 ")
        var requestparams = {
            consigner: selectconsigners,
            dept_code: this.state.deptcode,
            startDate:sDate,
            endDate:eDate,
            movement:mvmtype
        }
		let consignmentState = this.state.flState
		let consigneeCity = this.state.flCity
		let transporter = this.state.flTransporter;
        if(consignmentState !== '' && consignmentState !== undefined){
            requestparams['consignee_state'] = consignmentState.value
        }
        if(consigneeCity !== '' && consigneeCity !== undefined){
            requestparams['consignee_city'] = consigneeCity.value
        }
		if(transporter !== '' && transporter !== undefined){
            requestparams['transporter_name'] = transporter.value
        }
		if(localStorage.getItem("is_sales_type") == 1)
		{
			requestparams.sales_type = localStorage.getItem("sales_type");
			requestparams.region = localStorage.getItem("region");
		}
		// console.log("requestparamsCheck", requestparams)
		this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })
        requestparams.dataType = this.state.dataType;
        if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !="")
        {
            requestparams.transporter_code = JSON.parse(localStorage.getItem("transportercode"))
        }
        redirectURL.post("/ewaybill/getFilterPRTEwayBillConsignments", requestparams).then(async(response) => {
            var gridData = response.data;
            console.log("gridData", gridData)
            $("#selectedFilter").val("");
            $("#inputTruckConsignment").val("");
            await this.setState({
                rowData: gridData,
                gridRowData: gridData,
                //countersjson: pagecounters,
                loadshow:"show-n",
                overly:"show-n",
            })            
            await this.loadCountersData();
        })
	}
    async onclickCounter(counterType)
    {
        this.setState({
            loadshow : "show-m",
            overly : "show-m",
        });
        var rowData = [];
        if(counterType == "1")
        {
            rowData = this.state.activeData;
        }
        else if(counterType == "2")
        {
            rowData = this.state.expiringtomorrowData;
        }
        else if(counterType == "3")
        {
            rowData = this.state.expiringtodayData;
        }
        else if(counterType == "4")
        {
            rowData = this.state.expiredData;
        }
        else if(counterType == "5")
        {
            rowData = this.state.rowData;
        }
        else
        {
            rowData = this.state.cancelledData;
        }
        this.setState({
            gridRowData : rowData,
            loadshow : "show-n",
            overly : "show-n",
        });
        //this.gridApi.setRowData(rowData);
    }
    onCellClicked(e)
	{
        var eway_bill_number = e.data.eway_bill_number;
		if(e.colDef.field == "download_ewaybill")
		{
			var status = e.data.status;
			console.log(status)
			if(status == "Success")
			{
				console.log(status)
				if(e.data.file_url !=undefined && e.data.file_url !="")
				{
					console.log(e.data.file_url);
					if(e.data.file_url !=undefined && e.data.file_url !="")
					{
						console.log(e.data.file_url);
						let a= document.createElement('a');
						a.target= '_blank';
						a.href= e.data.file_url;
						a.click();
					}
				}
			}
		}
		if(e.colDef.field == "cancel")
		{
			var rowId = e.data._id;
			console.log(e.data._id);
			var qry = e.data;
			this.setState({
				cancelparams : qry,
				bulkslide:"slider-translate-30p",
				sliderType:1,
				loadshow:'show-n',
				sidebarSubHeader:"Cancel Eway Bill",
				overly:'show-m',
			});
			//var confirm = confirm("Are You Sure! Do you want really delete?");
			
		}
		if(e.colDef.field == "partb")
		{
			var rowId = e.data._id;
			console.log(e.data._id);
			var qry = e.data;  
			this.setState({
				cancelparams : qry,
				bulkslide:"slider-translate-40p",
				sliderType:3,
				loadshow:'show-n',
				sidebarSubHeader:"Update Vehicle Details For #"+eway_bill_number,
				overly:'show-m',
			});
			//var confirm = confirm("Are You Sure! Do you want really delete?");
		}
		if(e.colDef.field == "vehicle_details")
		{
			var VehiclListDetails = e.data.VehiclListDetails;
			if(VehiclListDetails !=undefined && VehiclListDetails !="")
            {
                this.setState({
                    bulkslide:"slider-translate-60p",
                    sliderType:4,
                    loadshow:'show-n',
                    sidebarSubHeader:"Vehicle Details Of #"+eway_bill_number,
                    overly:'show-m',
                    VehiclListDetails:VehiclListDetails
                });
            }
		}
		if(e.colDef.field == "eway_bill_number")
		{
            if(e.data !=undefined && e.data !="")
            {
                // var ipData = [];
                // var inputDetails = e.data;
                // ipData.push(inputDetails);
                // var content = [];
                // ipData.map(function(i){
                //     var keys = Object.keys(i);
                //     keys.map(function(k){
                //         if(k !="_id")
                //         {
                //             var hed = k.split("_").join(" ");
                //             content.push({
                //                 "label":hed.toUpperCase(),
                //                 "value":i[k]
                //             });
                //         }
                //     })
                // })
                // console.log(content,"content")
                this.setState({
                    inputDetails : e.data,
                    bulkslide:"slider-translate-60p",
                    sidebarSubHeader:"Eway Bill Details Of #"+eway_bill_number,
                    sliderType:2,
                });
            }
        }
	}

	cancelEwayBill = (e) => {
		e.preventDefault();
		var qry = this.state.cancelparams;
		qry.reason_of_cancel = $("#reason_of_cancel").val();
		redirectURL.post("/ewaybill/cancelEwayBill",qry).then((response)=>{
			console.log(response.data);
			this.setState({
				basicTitle:response.data.message,
				basicType : "success",
				show : true,
                lastRunTime : moment.parseZone(new Date()).subtract(5, 'seconds').format("YYYY-MM-DD HH:mm:ss"),
                seconds : 60
            })
            this.timer = 0;
            this.countDownTimer();
			//window.location.reload();
            this.onClickHideAll();
			this.onLoadPageData(0);
		}).catch((e)=>{
			console.log(e);
		})
	}
	
	updatePartBDetails = (e) => {
        e.preventDefault();
		var qry = this.state.cancelparams;
        var mode_of_transport = $("#mode_of_transport").val();
        var vehicle_number = $("#vehicle_number").val();
        var vehicle_type = $("#vehicle_type").val();
        var reason_for_vehicle_updation = $("#reason_for_vehicle_updation").val();
        var transporter_document_number = $("#transporter_document_number").val();
        var transporter_document_date = $("#transporter_document_date").val();
        if(mode_of_transport !=undefined && mode_of_transport !="" && vehicle_number !="" && vehicle_number !=undefined && vehicle_type !=undefined && vehicle_type !="" && reason_for_vehicle_updation !=undefined && reason_for_vehicle_updation !="" && transporter_document_number !=undefined && transporter_document_date !="" && transporter_document_date !=undefined)
        {
            qry.mode_of_transport = mode_of_transport;
            qry.vehicle_number = vehicle_number;
            qry.vehicle_type = vehicle_type;
            qry.reason_for_vehicle_updation = reason_for_vehicle_updation;
            qry.reason_code_for_vehicle_updation = $("#reason_for_vehicle_updation option:selected").text();
            qry.transporter_document_number = $("#transporter_document_number").val();
            qry.transporter_document_date = $("#transporter_document_date").val();
            redirectURL.post("/ewaybill/updateEwayBillVehicleDetails",qry).then((response)=>{
                console.log(response.data);
               
                    this.setState({
                        basicTitle:response.data.message,
                        basicType : "success",
                        show : true,
                        lastRunTime : moment.parseZone(new Date()).subtract(5, 'seconds').format("YYYY-MM-DD HH:mm:ss"),
                        seconds : 60
                    })
                    this.timer = 0;
                    this.countDownTimer();
                    //window.location.reload();
                    $("#mode_of_transport").val("");
                    $("#vehicle_type").val("");
                    $("#vehicle_number").val("");
                    $("#reason_for_vehicle_updation").val("");
                    $("#transporter_document_number").val("");
                    $("#transporter_document_date").val("");
                    this.onClickHideAll();
                    this.onLoadPageData(0);
                
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                basicTitle:"All fields are mandatory.",
                basicType : "warning",
                show : true
            })
        }
	}
    onClickSyncData =()=>{
        var transporter_gstin = this.state.transporter_gstin;
        if(transporter_gstin !=undefined && transporter_gstin !="")
        {
            var tptCode = JSON.parse(localStorage.getItem("transportercode"));
            var qry = {
                gstin_of_generator : transporter_gstin,
                transporter_code : tptCode[0]
            }
            redirectURL.post("/ewaybill/fetchEwayBillData",qry).then((response)=>{
                console.log(response.data);
                this.setState({
                    basicTitle:response.data.message,
                    basicType : "success",
                    show : true,
                    lastRunTime : moment.parseZone(new Date()).subtract(20, 'seconds').format("YYYY-MM-DD HH:mm:ss"),
                    seconds : 60,
                })
                this.timer = 0;
                this.countDownTimer();
                this.onLoadPageData(0);                
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                basicTitle:"Something went wrong. Try again after sometime",
                basicType : "warning",
                show : true
            })
        }
    }
    countDownTimer = async() => {
		var startdate = this.state.lastRunTime;
		var currentDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var diffreetime = moment.duration(moment(currentDate).diff(startdate));
		var runseconds = Math.round(diffreetime.asSeconds());
		console.log(runseconds,startdate,currentDate,"runseconds");
		if(parseInt(runseconds) < 60)
		{
            console.log(runseconds,startdate,currentDate,"runseconds--1");
			$("#timerDiv").show();
			this.startTimer();
		}
		else
		{
			$("#timerDiv").hide();
		}
	}
	
	secondsToTime(secs){
		let hours = Math.floor(secs / (60 * 60));
	
		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);
	
		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);
	
		let obj = {
		  "h": hours,
		  "m": minutes,
		  "s": seconds
		};
		return obj;
	}
	
	startTimer() {
		if (this.timer == 0 && this.state.seconds > 0) {
        console.log(this.timer,this.state.seconds,"startTimer")
		  this.timer = setInterval(this.countDown, 1000);
		}
	}
	
	countDown() {
		// Remove one second, set state so a re-render happens.
		let seconds = this.state.seconds - 1;
		this.setState({
		  time: this.secondsToTime(seconds),
		  seconds: seconds,
		});
		if(this.state.seconds == 30 || this.state.seconds == 60 || this.state.seconds == 90)
		{
			//console.log(this.state.seconds,"this.state.seconds")
			this.onLoadPageData(0);
		}
		// Check if we're at zero.
		if (seconds == 0) { 
			clearInterval(this.timer);
			$("#timerDiv").hide();
			this.onLoadPageData(0);
		}
	}
    
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
        });
    }

    getEWayBillsAssignedtomeforTransportData = () => {
      
        var from_date = this.state.eway_from_date
        var to_date = this.state.eway_to_date
        if (from_date != "") {
            if (to_date != "") {
                var reqparams = {
                    dataType:0,
                    from_date : from_date,
                    to_date:to_date,
                    transporter_code : JSON.parse(localStorage.getItem("transportercode"))
                }
                this.setState({
                    loadshow:"show-m",
                    overly:"show-m",
                })
                redirectURL.post("/ewaybill/getTransporterEwayBills", reqparams).then(async(response) => {
                    var gridData = response.data;
                    console.log(gridData)
                    var lastSyncedOn = "";
                    if(gridData.length > 0)
                    {
                        if(gridData[0].last_synced_on !=undefined && gridData[0].last_synced_on !="")
                        {
                            lastSyncedOn = getHyphenDDMMYYYYHHMMSS(gridData[0].last_synced_on);
                        }
                    }
                    await this.setState({
                        rowData: gridData,
                        gridRowData: gridData,
                        lastSyncedOn: lastSyncedOn,
                        loadshow:"show-n",
                        overly:"show-n",
                    })
                    await this.loadCountersData();
                })
            } else {
                this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Please Select To Date"
                })
            }
        } else {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Please Select From Date"
            })
        }
    }


   
    render() {
        const modalStyles  = {
            width:'500px !important',
            }
        const { open } = this.state;
        var maptransporter = '';
        try {
            if (this.state.rownode.transporter) {
                ///console.log("map rownode ",this.state.rownode.transporter[0])
                maptransporter = this.state.rownode.transporter[0].transporter_name;
            }
        }
        catch (e) {
            console.log(e)
        }
        var dataType = this.state.dataType;
        const columnwithDefs = [
            // {
            //     headerName: "",
            //     field: "download_ewaybill",
            //     width: 50,
            //     pinned : "left",
            //     resizable: true,
			// 	cellRenderer: function(e){
			// 		var htmloption = '<i title="Download E-Way Bill" style="font-size:20px" class="icofont icofont-download"></i>';
			// 		var status = e.data.status;
			// 		var check = 0;
            //         if(e.data.file_url !=undefined && e.data.file_url !="")
            //         {
            //             check = 1;
            //         }
			// 		if(check == 1)
			// 		{
			// 			return htmloption;
			// 		}
			// 		else
			// 		{
			// 			return "";
			// 		}
			// 	}
            // },
			{
                headerName: "Cancel",
                field: "cancel",
                width: 120,                 
                resizable: true,
				cellRenderer:function(params){
					var htmloption = '<button class="btn btn-danger" style="padding:0 6px 6px">Cancel</button>';
                    if(params.data.eway_bill_status !="Cancelled")
                    {
                        if(params.data.eway_bill_valid_date !=undefined && params.data.eway_bill_valid_date !='')
                        {
                            var validityDate = params.data.eway_bill_valid_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            if(difference > 0)
                            {
                                return htmloption;
                            }
                            else
                            {
                                return '<button class="btn btn-danger" style="padding:0 6px;color:#ccc;cursor:not-allowed" disabled>Cancel</button>';
                            }
                        }
                        else
                        {
                            return htmloption;
                        }
                    }
                    else
                    {
                        return '<button class="btn btn-danger" style="padding:0 6px;color:#ccc;cursor:not-allowed" disabled>Cancel</button>';
                    }
				}
            },
			{
                headerName: "Update Vehicle",
                field: "partb",
                width: 130,                 
                resizable: true,
				cellRenderer:function(params){
					var htmloption = '<button class="btn btn-info" style="padding:0 6px 6px">Update</button>';
                    if(params.data.eway_bill_status !="Cancelled")
                    {
                        if(params.data.eway_bill_valid_date !=undefined && params.data.eway_bill_valid_date !='')
                        {
                            var validityDate = params.data.eway_bill_valid_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            if(difference > 0)
                            {
                                return htmloption;
                            }
                            else
                            {
                                return '<button class="btn btn-info" style="padding:0 6px 6px;color:#ccc;cursor:not-allowed" disabled>Update</button>';
                            }
                        }
                        else
                        {
                            return htmloption;
                        }
                    }
                    else
                    {
                        return '<button class="btn btn-info" style="padding:0 6px 6px;color:#ccc;cursor:not-allowed" disabled>Update</button>';
                    }
				}
            },
			{
                headerName: "Extend Validity",
                field: "extend",
                width: 130,                 
                resizable: true,
				cellRenderer:function(params){
                    let basestring = params.data.eway_bill_number;
                    let encryptedstring = window.btoa(basestring);
					var htmloption = '<a href="/tptlogin/extendewaybill/'+encryptedstring+'" class="btn btn-success" style="padding:0 6px 6px">Extend</a>';
                    if(params.data.eway_bill_status !="Cancelled")
                    {
                        if(params.data.eway_bill_valid_date !=undefined && params.data.eway_bill_valid_date !='')
                        {
                            var validityDate = params.data.eway_bill_valid_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asHours());
                            if(difference <= 8 && difference >= -8)
                            {
                                return htmloption;
                            }
                            else
                            {
                                return '<button class="btn btn-success" style="padding:0 6px 6px;color:#ccc;cursor:not-allowed" disabled>Extend</button>';
                            }
                        }
                        else
                        {
                            return '<button class="btn btn-success" style="padding:0 6px 6px;color:#ccc;cursor:not-allowed" disabled>Extend</button>';
                        }
                    }
                    else
                    {
                        return '<button class="btn btn-success" style="padding:0 6px 6px;color:#ccc;cursor:not-allowed" disabled>Extend</button>';
                    }
				}
            },
            {
                headerName: "Vehicle Number",
                field: "vehicle_number",
                colId: "vehicle_number",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function(params){
                    if(params.data.VehiclListDetails !=undefined && params.data.VehiclListDetails !="")
                    {
                        var VehiclListDetails = params.data.VehiclListDetails;
                        var vehicleNumber = [];
                        VehiclListDetails.map(function(v){
                            if(v.vehicle_number !=undefined && v.vehicle_number !="")
                            {
                                vehicleNumber.push(v.vehicle_number);
                            }
                        })
                        if(vehicleNumber.length > 0)
                        {
                            return vehicleNumber.join(",");
                        }
                    }
                }
            },
            {
                headerName: "E-Way Bill No",
                field: "eway_bill_number",
                colId: "eway_bill_number",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                cellStyle: {'font-weight': 'bold'},
            },
            {
                headerName: "E-Way Bill Date",
                field: "eway_bill_date",
                colId: "eway_bill_date",
                filter: true,
                width: 150,
                resizable: true,
                comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_date);
                },
                hide: false
            },
            {
                headerName: "E-Way Bill Validity",
                field: "eway_bill_valid_date",
                colId: "eway_bill_valid_date",
                filter: true,
                width: 150,
                resizable: true,
                comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_valid_date);
                },
                hide: false
            },
            {
                headerName: "E-Way Bill Status",
                field: "eway_bill_status",
                colId: "eway_bill_status",
                width: 130,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function(params){
                    if(params.data.eway_bill_status == "Cancelled")
                    {
                        return params.data.eway_bill_status;
                    }
                    else
                    {
                        if(params.data.eway_bill_valid_date !=undefined && params.data.eway_bill_valid_date !='')
                        {
                            var validityDate = params.data.eway_bill_valid_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            if(difference > 0 && difference <= 1)
                            {
                                return "Expiring Today";
                            }
                            else if(difference > 1 && difference <= 2)
                            {
                                return "Expiring Tomorrow";
                            }
                            else if(difference <= 0)
                            {
                                return "Expired";
                            }
                            else{
                                return "Active";
                            }
                        }
                        else{
                            return "N/A";
                        }
                    }
                }
            },
            {
                headerName: "Total Invoice Value",
                field : 'total_invoice_value',
                colId : 'total_invoice_value',
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "GST Of Generator",
                field: "gstin_of_generator",
                colId: "gstin_of_generator",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Eway Bill Type",
                field: "eway_bill_type",
                colId: "eway_bill_type",
                width: 190,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function(params){
                    if(params.data.eway_bill_type !=undefined && params.data.eway_bill_type !="")
                    {
                        var gen = params.data.eway_bill_type.split('_').join(' ');
                        return gen.toUpperCase();
                    }
                }
            },
            {
                headerName: "Document Number",
                field: "document_number",
                colId: "document_number",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Document Date",
                field: "document_date",
                colId: "document_date",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Vehicle Details",
                field: "vehicle_details",
                colId: "vehicle_details",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                resizable: true,
                cellRenderer:function(params){
                    return '<button class="btn btn-info" style="padding:0 6px 6px">View</button>';
                }
            },
            {
                headerName: "Consignee State",
                field: "state_name_of_delivery",
                colId: "state_name_of_delivery",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignee Place",
                field: "place_of_delivery",
                colId: "place_of_delivery",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignee Pincode",
                field: "pincode_of_delivery",
                colId: "pincode_of_delivery",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignor Name",
                field: "legal_name_of_consignor",
                colId: "legal_name_of_consignor",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignor State",
                field: "state_of_consignor",
                colId: "state_of_consignor",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },            
            {
                headerName: "Consignor Pincode",
                field: "pincode_of_consignor",
                colId: "pincode_of_consignor",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
        ]

        var columnwithDefsForvehicle = [
            {
                headerName: "Vehicle No",
                field: "vehicle_number",
                colId: "vehicle_number",
                width: 110,
                hide: false,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Updated On",
                field: "vehicle_number_update_date",
                colId: "vehicle_number_update_date",
                width: 160,
                hide: false,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Transportation Mode",
                field: "transportation_mode",
                colId: "transportation_mode",
                width: 140,
                hide: false,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Document Number",
                field: "transporter_document_number",
                colId: "transporter_document_number",
                width: 140,
                hide: false,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Document Date",
                field: "transporter_document_date",
                colId: "transporter_document_date",
                width: 140,
                hide: false,
                cellRenderer: '', resizable: true
            },
        ];
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>

                {/* <div className="row col-sm-12 f16" style={{ marginBottom: "10px" }}>
                    <ul className="col-sm-10 d-tabs">
                        <li onClick={this.onClickTab.bind(this, "active")} className={"lttabs " + (this.state.tabOneactive)}>
                            <button type="button" className="btn">Active</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this, "delivered")} className={"lttabs " + (this.state.tabTwodelivered)}>
                            <button type="button" className="btn">Delivered</button>
                        </li>
                    </ul>
                    <div className="col-sm-2">
                        <button type="button" className="btn btn-danger" onClick={this.reloadPageData.bind(this)}>Reset All Filters</button>
                    </div>
                </div>  */}

               { this.state.pagetype == 3 ?
               <div className="row col-xl-12 col-lg-12">
                    <div class="col-xl-3 col-lg-3 form-group">
                        <label>From Date</label>
                        {/* <input type="text" name="date" autoComplete="off" id="ewayFromDate" className="eway_flters_date form-control" /> */}
                            {/* <Datetime
                                required={true}
                                disableCloseOnClickOutside={true}
                                closeOnSelect={true}
                                timeFormat={false}
                                minDate={true}
                                inputProps={{ placeholder: 'Date', name: "eway_from_date", id: "eway_from_date", autoComplete: 'off', }}
                                dateFormat="YYYY-MM-DD"
                                //format="y-MM-dd"
                                //isValidDate={this.validation}
                                // name={"eway_from_date"}
                                // id={"eway_from_date"}
                                value = {this.state.eway_from_date}
                                onChange={this.handlerStartDateTime.bind(this)} 
//tmssssssssss
                            /> */}
                    </div>
                    <div class="col-xl-3 col-lg-3 form-group">
                        <label>To Date</label>
                        {/* <input type="text" name="date" autoComplete="off" id="ewayToDate" className="eway_flters_date form-control" /> */}
                        {/* <Datetime
                                required={true}
                                disableCloseOnClickOutside={true}
                                closeOnSelect={true}
                                timeFormat={false}
                                minDate={true}
                                inputProps={{ placeholder: 'Date', name: "eway_to_date", id: "eway_to_date", autoComplete: 'off', }}
                                dateFormat="YYYY-MM-DD"
                                //format="y-MM-dd"
                                //isValidDate={this.validation}
                                // name={"eway_to_date"}
                                // id={"eway_to_date"}
                                value = {this.state.eway_to_date}
                                onChange={this.handlerEndDateTime.bind(this)} 
//tmssssssssss
                            /> */}
                    </div>
                    <div class="col-xl-2 col-lg-2 mr-0 form-group">
                        <label>&nbsp;</label>
                        <br />
                        <button type="button" onClick={this.getEWayBillsAssignedtomeforTransportData} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div> 
                :""}

                
                <div className="row col-xl-12 col-lg-12">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect" style={{padding:"0"}}>
                        <div className="card">
                            <div className="card-body n-p-0">
                                <div className="crm-numbers pb-0">
                                    <div className="row col-xl-12 col-lg-12 col-sm-12">
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>All</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"5",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"5")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"#4099ff"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.rowData.length)?this.state.rowData.length:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Active</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"1")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"#008000"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.activeCnt)?this.state.activeCnt:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Expiring Tomorrow </span>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"2")}>
                                                    <h5 className={"f25 mt-20p"} style={{color:"#FFFF00"}}>
                                                        <span className="counter">
                                                            <CountUp end={(this.state.expiringtomorrow)?this.state.expiringtomorrow:0}/>
                                                        </span>
                                                    </h5> 
                                                </div>
                                        </div>                                
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Expiring Today</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"3")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"#FFA500"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.expiringtoday)?this.state.expiringtoday:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Expired</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"4")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"#FF0000"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.expiredCnt)?this.state.expiredCnt:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Cancelled</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"5",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"6")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"red"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.cancelledCnt)?this.state.cancelledCnt:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                    </div>                                
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <div className="card col-xl-12 col-lg-12">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                                {(this.state.pagetype == 1) ?
                                <span>E-Way Bills</span>
                                : ""}
                                {(this.state.pagetype == 2) ?
                                <span>E-Way Bills For Completed Trips</span>
                                : ""}
                                {(this.state.pagetype == 3) ?
                                <span>E-Way Bills Assigned to me for Transport</span>
                                : ""}
                                    {(this.state.pagetype == 1) ?
                                    <button
                                        type="button"
                                        title="Sync Data"
                                        onClick={this.onClickSyncData}
                                        className={"float-right custom-btn white btn-danger mr-2 "}
                                        >
                                        Sync Data
                                    </button>
                                    : ""}
                                    
									{(this.state.seconds > 0 && this.state.gridRowData.length > 0) ?
									<span id="timerDiv" style={{float:"right",marginRight:"2%",marginTop:"8px"}}>Request processing in - <span style={{color:"red"}}>0{this.state.time.m} : {this.state.time.s}</span></span>
									:""}
                                    {(this.state.lastSyncedOn !='' && this.state.lastSyncedOn !=undefined && this.state.pagetype == 1) ?
									<span style={{float:"right",marginRight:"2%",marginTop:"4px"}}>Last Synced On - <span style={{color:"#3f3fc7",fontWeight:"boldDatalastSyncedOn "}}>{this.state.lastSyncedOn}</span></span>
									:""}
                                    
                            </h5>
                        </div>

                        <div className="card-body">

                            <div className={this.state.tabOneActiveData}                            >
                                <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.gridRowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        // components={this.state.components}
                                        enableRangeSelection={true}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                        onCellEditingStopped={this.onCellUpdateData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-theme col-xl-12 col-lg-12 row">

                        <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "auto" }}>
                            <h3 className="subH">
                                {this.state.sidebarSubHeader}                                
                                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                            </h3>                            
                            <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="container-fluid">
                                {(this.state.sliderType == 1) ?
                                <form method="POST" className="theme-form upform" onSubmit={this.cancelEwayBill.bind(this)}>
                                    
                                    <div className="form-group mt-20p">
                                        <label>Reason Of Cancel</label>
                                        <select name="reason_of_cancel" id="reason_of_cancel" placeholder="" className="form-control" required>
                                            <option value="">Select Reason</option>
                                            <option value="Duplicate">Duplicate</option>
                                            <option value="Order Cancelled">Order Cancelled</option>
                                            <option value="Data Entry Mistake">Data Entry Mistake</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                    </div>
                                </form>     
                                :""}
                                {(this.state.sliderType == 2) ?
                                    <EbillWayInfo ebillInfo={this.state.inputDetails}/>
                                :""}
                                {(this.state.sliderType == 3) ?
                                <form method="POST" id="partbform" className="theme-form upform" onSubmit={this.updatePartBDetails}>
                                    <div className="form-group">
                                        <label>Mode</label>
                                        <select class="form-control" name="mode_of_transport" id="mode_of_transport"  onChange={this.changeHandler}  >
                                            <option value="">-- Select Mode --</option>
                                            <option value="road">Road</option>
                                            <option value="rail">Rail</option>
                                            <option value="air">air</option>
                                            <option value="ship or ship cum road/rail">Ship or Ship Cum Road/Rail</option>
                                        </select>
                                    </div>
                                    <div className="form-group mt-20p">
                                        <label>Vehicle Type</label>
                                        <select class="form-control" name="vehicle_type" id="vehicle_type">
                                            <option value="">-- Select Vehicle Type --</option>
                                            <option value="regular">Regular</option>
                                            <option value="over dimensional cargo">Over Dimensional Cargo</option>
                                        </select>
                                    </div>
                                    <div className="form-group mt-20p">
                                        <label>Vehicle No : </label>
                                        <input type="text" name="vehicle_number" id="vehicle_number" className="form-control" />
                                    </div>
                                    <div className="form-group mt-20p">
                                        <label>Reason</label>
                                        <select class="form-control" name="reason_for_vehicle_updation" id="reason_for_vehicle_updation">
                                            <option value="">-- Reason --</option>
                                            <option value="First Time">First Time</option>
                                            <option value="Due to Break Down">Due to Break Down</option>
                                            <option value="Due to Transhipment">Due to Transhipment</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <div className="form-group mt-20p">
                                        <label>Transporter Doc. No : </label>
                                        <input type="text" name="transporter_document_number" id="transporter_document_number" className="form-control requestFields" />
                                    </div>
                                    <div className={"form-group mt-20p"}>
                                        <label>Transporter Doc. Date : </label>
                                        {/* <input type="text" name="transporter_document_date" id="transporter_document_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} /> */}
                                        {/* <Datetime 
                                            required={true}
                                            disableCloseOnClickOutside={true} 
                                            closeOnSelect={true} 
                                            timeFormat={false}
                                            minDate={true}
                                            inputProps={{ placeholder: 'Date', name: "transporter_document_date", id : "transporter_document_date", autoComplete:'off', }} 
                                            dateFormat="DD/MM/YYYY"
                                            //format="y-MM-dd"
                                            //isValidDate={this.validation}//tmssssssssss
                                            name={"transporter_document_date"}
                                            id={"transporter_document_date"}
                                        /> */}
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                    </div>
                                </form> 
                                :""}
                                {(this.state.sliderType == 4) ?
                                <div className='row col-sm-12'>
                                    <div id="myGrid" style={{ width: "100%", height: "300px" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            columnDefs={columnwithDefsForvehicle}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.VehiclListDetails}
                                            enableCharts={false}
                                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady1}
                                            onGridState={this.onGridState1}
                                            frameworkComponents={this.state.frameworkComponents}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            // components={this.state.components}
                                            enableRangeSelection={true}
                                        />
                                    </div>
                                </div>
                                :""}
                            </div>
                            </div>
                        </div>

                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>

                    </div>
                    <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                </div>
                
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box">
                            <div className="loader-box">
                                <div className="rotate dashed colored">
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});



function loadDateTimeScript() {
    // alert("timeDate");
    // $("#gate_in_time").onBlur(function(){
    //     alert("testttt");
    // });
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format:'d/m/Y',
    });
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format:'d/m/Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(e) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = e.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        timepicker: false,
        changeMonth: true,
		changeYear: true,
		minDate:"0"
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    var inpVal = this.eInput.value.split(' ');
    return inpVal[0]+" 23:59";
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}


function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function removeSplChars(inputString) {
    // console.log(inputString);
    if (inputString != "" && inputString != null && inputString != undefined) {
        return inputString.replace(/[^\w]/g, "");
    }
    else {
        return "";
    }

}

function getDatePicker() {
    function Timepicker() { }
    Timepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datetimepicker({
            datepicker: false,
            format: 'H:i'
        });
    };
    Timepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Timepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Timepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Timepicker.prototype.destroy = function () { };
    Timepicker.prototype.isPopup = function () {
        return false;
    };
    return Timepicker;
}
