import { useState } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from "../redirectURL";

const ChangePassword = () => {

    const [loader, setLoader] = useState(false)
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState('')
    const [type, setType] = useState('default')
    const [showp1, setShowp1] = useState(false)
    const [showp2, setShowp2] = useState(false)
    const [showp3, setShowp3] = useState(false)
    const [openPasswordModel, setOpenPasswordModel] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [rePassword, setRePassword] = useState('')


    const decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }
    var decode = decipher("Z744cRBHYjQTsI6cOlNaUKbuEQF6MiEr");
    let user = decode(localStorage.getItem("k"));
    // let user_data = JSON.parse(user)//tmsssssssssssssssssssssss
    let user_data = {
        email: ''
    }

    const cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    var encode = cipher("Z744cRBHYjQTsI6cOlNaUKbuEQF6MiEr");
    const onConfirm = () => {
        setShow(false);
        setLoader(false)
    };

    const changePassword = async (e) => {
        e.preventDefault()
        if (newPassword !== rePassword) {
            setShow(true)
            setTitle("Password's does not match")
            setType("danger")
        }
        else {
            setLoader(true)
            let body = {
                r: encode(oldPassword),
                f: encode(newPassword),
                u: encode(user_data.email)
            }
            await redirectURL.post("/tracking/updatePassword", body)
                .then(response => {
                    if (response.data.status == "success") {
                        setLoader(false)
                        setShow(true)
                        setTitle(response.data.message)
                        setType("success")
                        setOpenPasswordModel(false)
                        setOldPassword('')
                        setNewPassword('')
                        setRePassword('')
                    }
                    else {
                        setLoader(false)
                        setShow(true)
                        setTitle(response.data.message)
                        setType("danger")
                    }
                })
                .catch(err => console.log(err))
        }
        setLoader(false)
    }

    return (
        <div className="card">
            <SweetAlert
                show={show}
                title={<span style={{ fontSize: "1rem" }}>{title}</span>}
                onConfirm={onConfirm}
                confirmBtnBsStyle={type}
                confirmBtnCssClass="sweetButton"
                customClass='sweetWidth'
                type={type}>
            </SweetAlert>
            <div className="col-6" style={{ marginInline: "2rem", marginBlock: "2rem", border: "2px solid #cccc", borderRadius: "12px" }}>
                <div className="pl-3 pt-3">

                </div>
                <div className="pl-3 pt-3">
                    <div className="flex-left-center">
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50px", height: "50px", backgroundColor: "#84D2F6", borderRadius: "50%" }}><i className="fa fa-lock" style={{ color: "#ffff" }} /></div>
                        <div style={{ fontSize: "22px", fontWeight: "700" }}>&nbsp;&nbsp;&nbsp;Change Password</div>
                    </div>
                    <p className="mt-2">
                        To change your password. Your new password should contain at least one lowercase letter, one uppercase letter, one number, and one special character. It must be between 8 and 14 characters long.</p>
                    <form className="" onSubmit={changePassword}>
                        <div className="m-4 text-center">
                            <div className="col-xl-12 col-lg-12">
                                <div style={{ display: "flex" }}>
                                    <label style={{ color: "#EB1D54", fontSize: "16px" }}>*&nbsp;</label>
                                    <label className="custom-label" style={{ fontSize: "13px" }}>
                                        Old Password
                                    </label>
                                </div>
                                <div className="password-input-wrapper">
                                    <input
                                        className="form-control"
                                        type={(showp1) ? "text" : "password"}
                                        placeholder="Old Password"
                                        name='oldPassword'
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        required />
                                    {
                                        showp1 ?
                                            <i className="fa fa-eye eye-icon2" onClick={() => setShowp1(prev => !prev)} /> :
                                            <i className="fa fa-eye-slash eye-icon2" style={showp1 ? { top: "60%" } : {}} onClick={() => setShowp1(prev => !prev)} />
                                    }
                                </div>
                            </div>
                            <br />
                            <div className="col-xl-12 col-lg-12">
                                <div style={{ display: "flex" }}>
                                    <label style={{ color: "#EB1D54", fontSize: "16px" }}>*&nbsp;</label>
                                    <label className="custom-label" style={{ fontSize: "13px" }}>
                                        New Password
                                    </label>
                                </div>
                                <div className="password-input-wrapper">
                                    <input
                                        className="form-control"
                                        type={(showp2) ? "text" : "password"}
                                        placeholder="New Password"
                                        name='newPassword'
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,14}$"
                                        title="Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character, and be between 8 and 14 characters long"
                                        minLength={8}
                                        maxLength={14}
                                        required />
                                    {
                                        showp2 ?
                                            <i className="fa fa-eye eye-icon2" onClick={() => setShowp2(prev => !prev)} /> :
                                            <i className="fa fa-eye-slash eye-icon2" style={showp2 ? { top: "60%" } : {}} onClick={() => setShowp2(prev => !prev)} />
                                    }
                                </div>
                            </div>
                            <br />
                            <div className="col-xl-12 col-lg-12">
                                <div style={{ display: "flex" }}>
                                    <label style={{ color: "#EB1D54", fontSize: "16px" }}>*&nbsp;</label>
                                    <label className="custom-label" style={{ fontSize: "13px" }}>
                                        Re-Enter New Password
                                    </label>
                                </div>
                                <div className="password-input-wrapper">
                                    <input
                                        className="form-control"
                                        type={(showp3) ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        name='reNewPassword'
                                        value={rePassword}
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,14}$"
                                        title="Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character, and be between 8 and 14 characters long"
                                        minLength={8}
                                        maxLength={14}
                                        onChange={(e) => setRePassword(e.target.value)}
                                        required />
                                    {
                                        showp3 ?
                                            <i className="fa fa-eye eye-icon2" onClick={() => setShowp3(prev => !prev)} /> :
                                            <i className="fa fa-eye-slash eye-icon2" style={showp3 ? { top: "60%" } : {}} onClick={() => setShowp3(prev => !prev)} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="m-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button type='submit' className='config-button btn' style={{ background: "#a064ba", color: "#ffff" }}>Apply</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default ChangePassword